import React from 'react';
import styled from "styled-components";

export const SplashContainer = styled.div`
    max-width: 960px;
    margin: 0 auto;
`;

export const SplashInner = styled.div`
    margin: 0 24px;
    position: relative;
`;

export const AuthContainer = styled.div`
    max-width: 400px;
    margin: 0 auto;
`;

export const AuthTitle = ({children}) => {
  return <div className="text-3xl mt-1.5 pb-1.5 font-bold text-gray-800">
      {children}
  </div>
};

export const SplashTextContainer = styled.div`
    max-width: 760px;
    margin: 0 auto;
`;