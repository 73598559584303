import {authFetch} from "../../../config/network";

export function interested(id,loading,setLoading,community,cb=()=>{}) {
    if(loading) {
        return;
    }
    const payload = {
        community_uid: community.uid,
        member_id: community.member_id,
        id
    };

    setLoading(true);
    const res = (result) => {
        setLoading(false);
        cb();
    };

    authFetch("/members/interested", res, res, "POST", {payload});
}

export function notInterested(id,loading,setLoading,community,cb=()=>{}) {
    if(loading) {
        return;
    }
    const payload = {
        community_uid: community.uid,
        member_id: community.member_id,
        id
    };

    setLoading(true);
    const res = (result) => {
        setLoading(false);
        cb();
    };

    authFetch("/members/not-interested", res, res, "POST", {payload});
}