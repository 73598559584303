import React from "react";
import {SettingsGrid} from "../../../../library/components/old/settings-grid";
import {PageContainer} from "../../../../library/components/old/page-container";
import {LockClosedIcon,InformationCircleIcon} from "@heroicons/react/solid";
import {useIntl} from "react-intl";
import {PageHeader} from "../../../../library/components/core";

/*
To add
- time and date
2fa

 */
const items = [
    {
        title: 'account',
        icon: <InformationCircleIcon/>,
        links: {
            about: {
                text: 'general',
                desc: '',
                path: 'account'
            },

        }
    },
    {
        title: 'security',
        icon: <LockClosedIcon/>,
        desc: '',
        links: {
            defaults: {
                text: 'general',
                desc: '',
                path: 'security'
            }
        }
    }
];

export default function UserPrefsOverview() {
    const {formatMessage: f} = useIntl();

    return <PageContainer>
        <PageHeader title={f({id:'preferences.title'})}/>
        <SettingsGrid items={items}/>
    </PageContainer>;
}