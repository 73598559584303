import React from 'react';
import styled from "styled-components";
import {TextArea} from "../../core";

const Bio = styled.div`
  
    line-height: 1.4;
> textarea {
border: none;
    padding: 4px;
    width: 100%;
    resize: none;
        line-height: 1.4;
        outline: none;
    border-radius: 4px;
    margin: -4px;
}
> textarea:focus-within {
    box-shadow: rgba(0,0,0,0) 0px 0px 0px 0px, rgba(58,151,212,0.36) 0px 0px 0px 3px, rgba(0,0,0,0) 0px 0px 0px 0px, rgba(60,66,87,0.16) 0px 0px 0px 1px, rgba(0,0,0,0) 0px 0px 0px 0px, rgba(0,0,0,0) 0px 0px 0px 0px, rgba(0,0,0,0) 0px 0px 0px 0px;
}
> textarea:not(:focus-within):hover {
    background-color: #efefef;
}
`;

var handle_regex = /^(?!.*\bRT\b)(?:.+\s)?@\w+/i;

const max_len = 30;

function shortenURL(url) {
    const len = url.length;
    if(len>max_len) {
        return `${url.slice(0,max_len)}..`
    }
    return url;
}

function urlify(text) {
    var url_regex = /(https?:\/\/[^\s]+)/g;
    return text.replace(url_regex, function(url) {
        return '<a target="_blank" class="color-link" href="' + url + '">' + shortenURL(url) + '</a>';
    });
}

function cleanBio(bio) {
    const len = bio.length;
    const fv = bio.substring(0,200);
    if(len>200) {
        return `${fv}..`;
    }
    return fv;
}

export function PCBio({editing=false,changeBio,bio=''}) {
    if(bio===''&&!editing) {
        return null;
    }
    if(editing) {
        return <Bio className="pt-2 pb-0.5 w-full">
            <TextArea minimal fill rows={3} value={bio} onChange={v=>changeBio(v)} max={200} />
                </Bio>
    }
    const cleaned = cleanBio(bio);
    return <Bio style={{fontSize:'15px'}} dangerouslySetInnerHTML={{__html: urlify(cleaned)}} className='text-gray-800 pt-1.5 pb-0.5'>

    </Bio>
}