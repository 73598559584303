import React from "react";
import {ArchiveIcon, UserIcon} from "@heroicons/react/solid";
import styled from "styled-components";

const IconOuter = styled.div`
 path {
 }
`;

export function IconLowerRightAddon({primary=<UserIcon />,secondary=<ArchiveIcon />}) {

    return <div className="relative h-full w-full flex items-center justify-center">
            <div className="" style={{width:'90%',height:'90%'}}>
                {primary}
            </div>
            <IconOuter className="absolute rounded-full ml-auto bottom-0 top-0 w-2/5 flex items-center -mt-px pb-px -right-0.5">
                {secondary}
            </IconOuter>
        </div>;
}