import React, {useState} from "react";
import styled from "styled-components";
import {useIntl} from "react-intl";
import {GroupDisplayIcon} from "../../../../utilities/groups";
import {PlusIcon} from "@heroicons/react/solid";
import {sidebar_getBackgroundClasses, sidebar_getIconClasses, sidebar_getTextClasses} from "../community-sidebar-main";

const standard_hover = `hover:bg-gray-200 cursor-pointer duration-100 transition-colors`;
const CloseSectionIcon = styled.div`
  display: flex;
  cursor: pointer;
  align-items: center;

  svg {
    height: 16px;
  }

  :hover {
    opacity: 0.7 !important;
  }
`;

const GDIIcon = styled.div`
  height: 1.25rem;
  display: flex;
`;

const Wrapper = styled.div`
  color: ${props=>props.active ? props.theme.color : props.theme.body_text};
  :hover {
    color: ${props=>props.theme.color};
  }
`;

function getSmartViewBase(data) {
    if(data.type==='group') {
        return 'groups'
    } else if(data.type==='role'||data.type==='team') {
        return 'leaders'
    } else {
        return 'directory'
    }
}

function buildGoTo(type,value,data,community_handle) {
    if(type==='group'||type==='team') {
        return `/${community_handle}/group/${value}`;
    } else if(type==='role') {
        return `/${community_handle}/role/${value}`;
    } else if(type==='member') {
        return `/${community_handle}/member/${value}`;
    } else if(type==='smart-view') {
        // group
        return `/${community_handle}/${getSmartViewBase(data)}?view=${value}`;
    } else {
        return `/${community_handle}/home`;
    }
}

function pathIsActive(path, full_path, it) {
    if (it.fuzzy_match) {
        return path.startsWith(full_path);
    } else {
        return full_path === path;
    }
}

function ListItem({theme, meta={}, type, value, fuzzy_match, current_path, community_handle = '', goTo, path, has_update}) {
    const full_path = buildGoTo(type,value,meta,community_handle);
    const active = pathIsActive(current_path, full_path, {type,fuzzy_match,value});
    const {emoji, name, icon, color, profile_picture_color, profile_picture} = meta;
    return <div className={`py-px`}>
        <Wrapper onClick={() => goTo(full_path)} active={active} theme={theme}
            className={`w-7 group xl:w-auto w-full mx-auto py-1 gap-2 relative grid duration-100 ${active?"":""} transition-opacity h-7 cursor-pointer pl-1.5 py-1`}
            style={{gridTemplateColumns: "24px 1fr auto"}}>
            <div className="items-center justify-center pl-px">
                <div style={{width:'20px'}}>
                    <GDIIcon>
                        <GroupDisplayIcon emoji={emoji} profile_picture={profile_picture} size="mini"
                                          profile_picture_color={profile_picture_color} color={active ? theme.color : color}
                                          name={name}/>
                    </GDIIcon>
                </div>

            </div>
            <div className={`${sidebar_getTextClasses(has_update, false, active)} text-smbase flex items-center hidden-1250`}>
                <span className="menu-overflow-span" style={{maxWidth:'180px'}}>{name}</span>
            </div>
            <div className={`${has_update ? "w-4 flex items-center justify-center" : ""} hidden-1250`}>
                {has_update && true && <div className="h-2 w-2 bg-blue-400 rounded-full"/>}
            </div>
            <div className={`absolute ${active?"flex":"hidden group-hover:flex"}  items-center right-0 top-0 bottom-0`}>
                <div className="w-1 rounded-l-lg h-5 " style={{backgroundColor:`${active?theme.color:theme.hover_bold}`}} />
            </div>
        </Wrapper>
    </div>

}
function MenuSection({children}) {
    return <div className="">
        {children}
    </div>
}

function MenuItems({children}) {

    return <div>
        {children}
    </div>
}



function MenuHeader({title="Groups",theme,community_handle,history,base_path,add_path}) {
    const full_add_path = `/${community_handle}/${add_path}`;
    const full_base_path = `/${community_handle}/${base_path}`;
    return <div className="pl-1 pr-2 pb-0.5 pt-0.5 flex hidden-1250">
        <div className="flex-none"></div>
        <div onClick={()=>{
            if(base_path) {
                history.push(full_base_path)
            }

        }} style={{color:theme.body_text_mid}} className={` pl-1 flex  ${base_path?"cursor-pointer":""} text-xssm items-center font-medium flex-grow`}>
            <span>{title}</span>
        </div>
        {add_path&&<div onClick={()=>{
        history.push(full_add_path)
        }} className={` -m-0.5 p-1 rounded-md ${standard_hover} text-gray-500 hover:text-gray-700  flex items-center justify-center flex-none`}>
            <div className="h-svg-4">
                <PlusIcon/>
            </div>
        </div>}
    </div>
}

/*

    function toggle(open, val) {
        if (open) {
            return updateClosed({...closed, [val]: true});
        } else {
            return updateClosed({...closed, [val]: false});
        }
    }

 */
export function SidebarEntitiesList({
                                        sections,
                                        community_handle = '',
                                        history,
                                        theme,
                                        goTo,
                                        location,
                                        name = 'Groups'
                                    }) {
    const [closed, updateClosed] = useState({});

    const {formatMessage: f} = useIntl();

    return sections.map((sec, i) => {
        const is_open = !closed[name];
        return <MenuSection key={`${sec.title}`}>
            <MenuHeader theme={theme} history={history} title={sec.title} community_handle={community_handle} add_path={sec.add_path} base_path={sec.base_path} />
            {is_open && <MenuItems>
                {sec.items.map((it, i) => {
                    return  <ListItem {...it} current_path={`${location.pathname}${location.search}`} community_handle={community_handle} type={it.type} fuzzy_match={it.fuzzy_match} value={it.value} path={it.path} theme={theme} key={i} goTo={goTo}
                                  has_update={false}/>
                })}
            </MenuItems>}
        </MenuSection>
    })
}