import React, {useContext, useEffect, useRef, useState} from "react";
import {SelectInterests} from "../../../../../app/community/interests/select-interests";
import {authFetch} from "../../../../../config/network";
import {CommunityContext} from "../../../../../app/community/community-context";

export function ObScMyInterests({

                                }) {
    const community = useContext(CommunityContext);
    const val = useRef();
    const [selected, setSelected] = useState(community.member.interests ? community.member.interests : {});

    function handleSaveInterests(dt) {
        if(dt&&community&&community.member_id) {
            // members/edit-interests
            const res = () => null;

            const payload = {
                community_uid: community.uid,
                member_id: community.member_id,
                id: community.member_id,
                user_uid: community.member.user_uid,
                interests: val.current
            }
            authFetch('/members/edit-interests', res, res, "POST", {payload});
        }
    }

    useEffect(function () {
        return () => {
            val.current = selected;
        }
    }, [selected]);

    useEffect(function () {
        return () => {
            handleSaveInterests(val.current);
        }
    }, []);

    return <div className="overflow-y-auto" style={{maxHeight: '72vh'}}>
        <SelectInterests onSelect={(s) => setSelected(s)} initial={selected} max={18}/>
    </div>
}