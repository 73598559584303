import {analytics} from "firebase";
import {logEngagement} from "../../../api/analytics";
import {authFetch} from "../../../config/network";
import {convertToRaw} from "draft-js";

export function composer_handleSendPost(payload, {community},res) {
    analytics().logEvent('send_message', {
        event_id: 'send_message',
        content_type: 'email',
        community_uid: community.uid,
        content_id: community.member.user_uid
    });

    logEngagement("send_post", {
        community_uid: community.uid,
        member_id: community.member_id,
        user_uid: community.member.user_uid
    });

    authFetch("/posts/new-post", res, res, "POST", {payload});
}