import React, {useEffect} from 'react';
import {confetti} from "../../../libs/confetti";

export default function BirthdayConfetti() {

    useEffect(function () {
        confetti.start();
        setTimeout(() => {
            confetti.stop();
        }, 2500);
    }, []);


    return null;
}