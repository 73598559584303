import React, {useContext, useEffect, useRef, useState} from 'react';
import {getEntityTypeIcon} from "../../entity-list";
import {authFetch} from "../../../../config/network";
import {CommunityContext} from "../../../../app/community/community-context";
import {LiveSegmentToggle} from "../live-segment";
import {ESGeneralEntityItem} from "../../reorganize/entity-select/item";

export function SegmentPreviewWrapper({
    conditions = [],
    match = 'any',
    label = '',
    sub_label = '',
    notifyParent = ()=>{},
    loading = false,
    onAdd = ()=>{}
                                      }) {
    const community = useContext(CommunityContext);
    const [data,setData] = useState(null);
    const [live,setLive] = useState(true);
    const isFirstRun = useRef(true);

    useEffect(function() {
        if (isFirstRun.current&&conditions.length>0) {
            isFirstRun.current = false;
            getPreview();
            return;
        }

    }, [conditions]);

    function getPreview() {
        const payload = {
            community_uid: community.uid,
            member_id: community.member_id,
            user_uid: community.member.user_uid,
            conditions,
            match
        };

        const res = (resp) => {
            setData(resp.data);
            // only show if data.count > 0 to only show relevant segments
                notifyParent();
        };

        authFetch('/filters/preview', res, res, "POST", {payload});
    }

    if(!data) {
        return null;
    }

    return <ESGeneralEntityItem shadow loading={loading} data={{user_uids:data.member_ids}} meta={`~${data.count}`} id={"preview"} cid={community.uid}
                              label={label} sub_label={sub_label} can_expand={data.count>0} icon={{type: 'icon', icon: getEntityTypeIcon('segments')}}
                              add={()=>{
                                  onAdd(live,data);
                              }} type='filters' suffix={<LiveSegmentToggle live={live} onToggle={()=>setLive(!live)} />} />
}