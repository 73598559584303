import React, {useState} from "react";
import {activity_types} from "./types";
import {AdminActivityItem} from "./item";
import {aa_formatDate} from "../../utilities/general/dates";
import {ListExpander} from "../list-expander";

function getConnector(len,index) {
    if((index+1)===len) {
        // last one
        return 'none';
    } else {
        return 'line'
    }
}

function AAFeedSection({events, title, context}) {
    const len = events.length;
    const [show,setShow] = useState(5);
    return <div >
        <div className="text-gray-800 text-base font-medium pt-1.5 pb-1">
            {title}
        </div>
        {events.slice(0,show).map((event,k) => {
            const show_time = k===0?true:events[k-1].date_formatted!==event.date_formatted;
            return <AdminActivityItem show_time={show_time} connector={getConnector(events.length, k)} context={context} key={`${event.id}-${k}`} {...event} />
        })}
        {events.length>show&&<div style={{backgroundImage:'linear-gradient(rgba(255,255,255,0), #ffffff, #ffffff, #ffffff)'}} className="px-1.5 relative pb-1 pt-3 -mt-2"><ListExpander count={events.length-show} onClick={()=>setShow(len)} /></div>}
    </div>
}

export function AdminActivityFeed({events=[],context}) {
    const final_events = events.filter(e=>!!e&&!!e.ts).map(evt=>{
        return {
            ...evt,
             ...aa_formatDate(evt?evt.ts:Date.now())
        }
    });

    let obj = {
        'this-week': {events:[],title: 'This Week'},
        'last-month': {events:[],title: 'Last Month'},
        'last-year': {events:[],title: 'Last Year'},
        'beyond': {events:[],title: 'Beyond'},
    };

    final_events.forEach(evt=>{
        obj[evt.sort].events.push(evt);
    });

    const sections = Object.values(obj).filter(a=>a.events.length>0);

    if(final_events.length===0) {
        return <div className="text-sm text-gray-500 p-4 text-center">
            No events found
        </div>
    }

    return <div>
        {sections.map(sec=><AAFeedSection title={sec.title} key={sec.title} context={context} events={sec.events}/>)}
    </div>;
}