import React from 'react';
import {InfoState, InlineLoader} from "../../../library/components/core";
import {GlobeIcon} from "@heroicons/react/solid";
import {GlobalEntityInfo} from "../../../library/utilities/localstorage/entity-localstorage";
import CommunityItem from "./item";
import {useHistory} from "react-router-dom";

export function ActiveMemberships(props) {
    const {
        loading,
        sorted = [],
        loading_communities,
        community_data,
        member_ids,
        members_data,
        member_type_data
    } = props;
    const history = useHistory();
    return <>
        {loading&&<InlineLoader />}
        {sorted.length === 0 && !loading_communities && <InfoState fill icon={<GlobeIcon/>} title='No Communities Found'
                                                                   subtitle={`Join or create a community to get started.`}/>}

        {!loading&&sorted.length>0&&<div style={{gridTemplateColumns:`repeat(auto-fill, minmax(240px, 1fr))`}} className="grid gap-4">
            {sorted.map((comm) => {
                const comm_data = community_data[comm] ? community_data[comm] : {name:'',profile_picture:''};
                const member_data = members_data[comm] ? members_data[comm] : {name:'',profile_picture:''};
                const mt_data = member_type_data[comm] ? member_type_data[comm] : {singular:''};
                const card_obj = {
                    community: {
                        emoji: comm_data.emoji,
                        name: comm_data.name,
                        logo: `${comm_data&&comm_data.profile_picture?`${comm_data.profile_picture}_small?alt=media`:""}`
                    },
                    member: {
                        name: member_data.name,
                        member_id: member_ids[comm],
                        member_type: mt_data.singular
                    }
                };
                const card_style = comm_data && comm_data.member_card_design ? comm_data.member_card_design : {background: {color:comm_data?comm_data.color:"lightgrey",type:'color',image:''}};

                return <GlobalEntityInfo key={comm} type='communities' community_uid={comm} id={comm}>
                    <CommunityItem card_style={card_style} card_obj={card_obj} goTo={(path) => {
                        history.push(path)
                    }} />
                </GlobalEntityInfo>;
            })}
        </div>}
    </>
}