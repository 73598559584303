import {Button, Field, Input, TextArea} from "../../library/components/core";
import React, {useEffect, useState} from "react";
import {validateEmail} from "../../library/utilities/validation";
import {TrashIcon} from "@heroicons/react/solid";
import * as API from "./api";

export function SimpleManageEmails({initial=[],updateEmails}) {
    const [emails,setEmails] = useState(initial);
    const [new_email,setNewEmail] = useState('');

    useEffect(function () {
updateEmails(emails);
    }, [emails]);

    function addEmail() {
        setNewEmail('');
        setEmails([...emails,new_email]);
    }

    function removeEmail(index) {
        let new_emails = emails;
        new_emails.splice(index,1);
        setEmails([...new_emails]);
    }

    function disableAddEmail() {
        const is_valid_email = validateEmail(new_email);

        const is_unique = emails.indexOf(new_email) === -1;

        return !is_unique || !is_valid_email;
    }

    return <div className="w-full max-w-2xl">
        <div className="space-y-1 divide-y">
            {emails.length===0&&<div className="p-2 text-gray-600">
                No email added yet
            </div>}
            {emails.map((it,k)=>{
                return <div className="flex items-center p-2" key={it}>
                    <div className="flex-grow">
                        {it}
                    </div>
                    <div>
                        <Button onClick={()=>removeEmail(k)} size="xs" left_icon={<TrashIcon />} />
                    </div>
                </div>
            })}
        </div>
        <div className="flex ">
            <div className="flex-grow">
            <Input placeholder="New Email" value={new_email} onChange={(v)=>setNewEmail(v)} type="email" />
            </div>
            <div className="flex-none">
                <Button fill intent="secondary" onClick={()=>addEmail()} text="Add Email" disabled={disableAddEmail()} />
            </div>
        </div>
    </div>
}


export function NewProductUpdate({onCreate}) {
    const [subject,setSubject] = useState('');
    const [loading,setLoading] = useState('');
    const [version,setVersion] = useState('');
    const [video,setVideo] = useState('');
    const [changelog_link,setChangelogLink] = useState('https://welcome.getunaty.com/whats-new');
    const [desc,setDesc] = useState('');

    function resetStates() {
        setSubject('');
        setVideo('');
        setVersion('');
        setDesc('');
    }

    function handleSend() {
        const obj = {
            version,
            subject,
            video,
            desc,
            changelog_link
        };
        setLoading('sending');
        const cb = () => {
            resetStates();
            setLoading('');
            onCreate();
        };
        API.gp_pu_newProductUpdate(obj,cb);
    }

    function disableSend() {
        const subject_too_short = subject.length < 3;

        return subject_too_short||!version||!changelog_link;
    }

    return <div>
        <div className="space-y-2">
            <Field label="Version">
            <Input placeholder="2.5.4" value={version} onChange={(v)=>setVersion(v)} type="text" />
            </Field>
            <Field label="Video">
                <Input placeholder="url of video" value={video} onChange={(v)=>setVideo(v)} type="url" />
            </Field>
            <Field label="Update Title">
                <Input placeholder="TLDR of the update" value={subject} onChange={(v)=>setSubject(v)} type="text" />
            </Field>
            <Field label="Short Description">
                <TextArea placeholder="Description" value={desc} onChange={(v)=>setDesc(v)} type="text" />
            </Field>
            <div>
                <Button disabled={disableSend()} loading={loading==='sending'} text="Create Update" onClick={()=>handleSend()} intent="secondary" />
            </div>
        </div>
    </div>
}