import {
    COMMUNITY_LOGOS_FOLDER, EMAIl_HEADER_PICS_FOLDER, GROUP_COVER_PHOTO_FOLDER,
    MEMBER_CARD_PICS_FOLDER, STORAGE_ACHIEVEMENTS_PICS_FOLDER, STORAGE_COVER_PICS_FOLDER,
    STORAGE_PROFILE_PICS_FOLDER,
    STORAGE_SIGN_IN_PAGE_FOLDER
} from "../../../../config/defaults";

const profile_picture_sizes = {
    medium: {
        height: 128,
        width: 128
    },
    small: {
        height: 48,
        width: 48
    },
    display: {
      height: 400,
      width: 400,
        border_radius: '42%',
        scale: '0.67'
    },
    edit: {
        height: 600,
        width: 600
    }
};

const community_logo_sizes = {
    medium: {
        height: 128,
        width: 128
    },
    small: {
        height: 48,
        width: 48
    },
    display: {
        height: 400,
        width: 400,
        border_radius: '8px',
        scale: '0.67'
    },
    edit: {
        height: 600,
        width: 600
    }
};

const email_header_sizes = {
    medium: {
        height: 110,
        width: 556
    },
    small: {
        height: 55,
        width: 278
    },
    display: {
        height: 82.5,
        width: 417,
        border_radius: '0',
        scale: 1
    },
    edit: {
        height: 110,
        width: 556
    }
};

// scale (X/1.5) for editing
// apply 0.75 transformation
const cover_photo_sizes = {
    medium: {
        width: 600,
        height: 200
    },
    small: {
        width: 300,
        height: 100
    },
    display: {
        height: 280,
        border_radius: '16px',
        scale: '0.94'
    },
    edit: {
        width: 900,
        height: 300
    }
};

const sign_in_page_sizes = {
    medium: {
        width: 500,
        height: 410
    },
    small: {
        width: 250,
        height: 205
    },
    display: {
        height: 420,
        border_radius: '2px',
        scale: '0.48'
    },
    edit: {
        width: 1000,
        height: 820
    }
};

const member_card_sizes = {
    medium: {
        width: 450,
        height: 240
    },
    small: {
        width: 225,
        height: 120
    },
    display: {
        height: 360,
        border_radius: '16px',
        scale: '0.67'
    },
    edit: {
        width: 675,
        height: 360
    }
};

const group_cover_sizes = {
    medium: {
        width: 600,
        height: 200
    },
    small: {
        width: 300,
        height: 100
    },
    display: {
        height: 280,
        border_radius: '16px',
        scale: '0.94'
    },
    edit: {
        width: 900,
        height: 300
    }
};

export function iu_getModalSize(type) {
    switch (type) {
        case 'profile-picture':
            return 'small'
        case 'achievements':
            return 'small'
        case 'email-header':
            return 'medium'
        case 'member-card':
            return 'medium'
        case 'sign-in-page':
            return 'plus'
        case 'community-logo':
            return 'small'
        case 'cover-photo':
            return 'plus'
        case 'group-cover':
            return 'plus'
        default :
            return 'small'
    }
}

export function iu_getBorderRadius(type) {
    switch (type) {
        case 'profile-picture':
            return '42%'
        case 'achievements':
            return '0'
        case 'member-card':
            return '16px'
        case 'email-header':
            return '0'
        case 'sign-in-page':
            return '16px'
        case 'community-logo':
            return '18%'
        case 'cover-photo':
            return '12px'
        case 'group-cover':
            return '12px'
        default :
            return '12px'
    }
}

export function iu_getAvatarContainerHeight(type,dims) {
    switch (type) {
        case 'profile-picture':
            return dims.height+2
        case 'achievements':
            return dims.height+2
        case 'member-card':
            return dims.height+2
        case 'email-header':
            return dims.height+2
        case 'sign-in-page':
            return dims.height+2
        case 'community-logo':
            return dims.height+2
        case 'group-cover':
            return dims.height+2
        case 'cover-photo':
            return dims.height+2
        default :
            return dims.height+2
    }
}

export function iu_getCanvasDimensions(type) {
    switch (type) {
        case 'profile-picture':
            return profile_picture_sizes
        case 'achievements':
            return profile_picture_sizes
        case 'sign-in-page':
            return sign_in_page_sizes
        case 'email-header':
            return email_header_sizes
        case 'group-cover':
            return group_cover_sizes
        case 'member-card':
            return member_card_sizes
        case 'community-logo':
            return community_logo_sizes
        case 'cover-photo':
            return cover_photo_sizes
        default :
            return profile_picture_sizes
    }
}

// get original image from canvas
const profile_picture_sharpness = {
    medium: {
        unsharpAmount: 20,
        unsharpRadius: 0.6,
       // alpha: true,
        unsharpThreshold: 1
    },
    small: {
        unsharpAmount: 80,
    //    alpha: true,
        unsharpRadius: 0.6,
        unsharpThreshold: 2
    }
};

const cover_photo_sharpness = {
    medium: {
        unsharpAmount: 100,
      //  alpha: true,
        unsharpRadius: 1,
        unsharpThreshold: 1
    },
    small: {
        unsharpAmount: 100,
        //alpha: true,
        unsharpRadius: 0.6,
        unsharpThreshold: 2
    }
};

export function iu_getSharpnessSettings(type) {
    switch (type) {
        case 'profile-picture':
            return profile_picture_sharpness
        case 'achievements':
            return profile_picture_sharpness
        case 'sign-in-page':
            return cover_photo_sharpness
        case 'group-cover':
            return cover_photo_sharpness
        case 'member-card':
            return cover_photo_sharpness
        case 'email-header':
            return cover_photo_sharpness
        case 'community-logo':
            return profile_picture_sharpness
        case 'cover-photo':
            return cover_photo_sharpness
        default :
            return cover_photo_sharpness
    }
}

export function iu_getImageUploadingFolder(type) {
    switch (type) {
        case 'profile-picture':
            return STORAGE_PROFILE_PICS_FOLDER
        case 'achievements':
            return STORAGE_ACHIEVEMENTS_PICS_FOLDER
        case 'sign-in-page':
            return STORAGE_SIGN_IN_PAGE_FOLDER
        case 'group-cover':
            return GROUP_COVER_PHOTO_FOLDER
        case 'member-card':
            return MEMBER_CARD_PICS_FOLDER
        case 'email-header':
            return EMAIl_HEADER_PICS_FOLDER
        case 'community-logo':
            return COMMUNITY_LOGOS_FOLDER
        case 'cover-photo':
            return STORAGE_COVER_PICS_FOLDER
        default :
            return STORAGE_COVER_PICS_FOLDER
    }
}
