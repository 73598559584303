import {validateEmail} from "../../../utilities/validation";
import {getPhoneNumberData} from "../../../utilities/phone";
import moment from "moment";
import {displayBday} from "../../old/edit-fields-section/fields";
import {countries} from "../../../data/general/countries";
import {splitName} from "../../../utilities/name-splitter";

export const sanitization_codes = {
    'invalid-email': {
        desc: "This is not a valid email; please check for any spelling for formatting mistakes like a space in the email"
    }
};

function getDeliminator(str) {
    const comma_count = (str.match(/,/g) || []).length;
    if(comma_count>0) {
        return ',';
    }
    const semicolon_count = (str.match(/;/g) || []).length;
    if(semicolon_count>0) {
        return ';';
    }
    return ' ';
}

export function sanitizationPipeline(raw, type, mapping_obj, value_only = false) {
    let temp = raw.trim(), t1, t2, t3, t4;

    if (!raw && type !== 'select') {
        return value_only ? '' : {
            value: '',
            valid: true,
            code: 'empty'
        }
    }

    switch (type) {
        case 'select':
            // t1 = ID of select
            t1 = mapping_obj.mapping[temp];
            if (t1) {
                // ok it's mapped
                t2 = mapping_obj.field_details.options.filter(a => a.id === t1)[0];
                return value_only ? t2.id : {value: t2.label, valid: true};
            } else if (mapping_obj.field_details.default_selection) {
                // show default val
                t2 = mapping_obj.field_details.options.filter(a => a.id === mapping_obj.field_details.default_selection)[0];
                return value_only ? t2.id : {value: t2.label, valid: true, default_value: true};
            }
            return value_only ? '' : {value: temp, valid: false, code: 'select-not-mapped'};
        case 'boolean':
            t1 = temp;
            t2 = !!t1;
            return value_only ? t2.toString() : {value: t2.toString(), valid: true}
        case 'boolean-label':
            // incoming value is either false or true or On or Off, find by label, match to true/false bool
            t1 = mapping_obj.field_details.options.filter(a => a.label === temp)[0];
            t2 = t1 ? t1.id : false;
            t3 = mapping_obj.field_details.options.filter(a => a.id === t2)[0].label;
            return value_only ? t2 : {value: t3, valid: true}
        case 'multi-email':
            t1 = temp.split(getDeliminator(temp));

            t2 = t1.filter(a => validateEmail(a));

            return value_only ? t2.join(",") : {value: t2.join(", "), valid: true};
        case 'full-name':
            t1 = splitName(temp);
            return value_only ? t1 : {value: t1.divided, valid: true};
        case 'email':
            // check if is valid email
            if (validateEmail(temp)) {
                return value_only ? temp : {value: temp, valid: true};
            } else {
                return value_only ? '' : {value: '', valid: false, code: 'invalid-email'};
            }
        case 'id':
            if (raw.length === 10 && raw.indexOf(" ") === -1) {
                return value_only ? temp : {value: temp, valid: true};
            } else {
                return value_only ? '' : {
                    value: '',
                    valid: false,
                    code: 'invalid'
                }
            }
        case 'phone':
            // check if is valid phone number
            t1 = getPhoneNumberData(raw);
            if (t1.valid) {
                return value_only ? temp : {value: t1.display, valid: true};
            } else {
                return value_only ? temp : {value: raw, valid: true, code: 'formatting'};
            }
        case 'country':
            temp = temp.toLowerCase();
            if (temp.length === 2) {
                if (countries[temp]) {
                    return value_only ? temp : {value: countries[temp], valid: true};
                } else {
                    return value_only ? '' : {value: temp, valid: false, code: 'invalid'};
                }
            }

            t1 = Object.values(countries).map(a => a.toLowerCase());

            if (t1.includes(temp)) {
                t2 = Object.entries(countries);
                t3 = t2.findIndex(a => a[1].toLowerCase() === temp);
                t4 = t2[t3][0];
                return value_only ? t4 : {value: countries[t4], valid: true};
            }

            return value_only ? '' : {value: temp, valid: false, code: 'invalid'};
        case 'number':
            t1 = parseInt(temp);
            if (!isNaN(t1)) {
                return value_only ? t1.toString() : {value: t1.toString(), valid: true};
            }
            return value_only ? '' : {value: temp, valid: false, code: 'invalid'};
        case 'date':
            t1 = moment(temp, ['DD-MM-YYYY', 'MM-DD-YYYY', 'DD.MM.YYYY', 'DD-M-YYYY']);
            if (t1.isValid()) {
                return value_only ? t1.format('DD-MM-YYYY') : {value: t1.format('DD-MM-YYYY'), valid: true};
            }
            return value_only ? temp : {value: temp, valid: false, code: 'invalid'};
        case 'birthday':
            t1 = moment(temp, ['DD-MM-YYYY', 'MM-DD-YYYY', 'DD.MM.YYYY', 'DD-M-YYYY']);
            if (t1.isValid()) {
                return value_only ? {
                    date: t1.date(),
                    month: t1.month(),
                    year: t1.year()
                } : {
                    valid: true,
                    value: displayBday({
                        date: t1.date(),
                        month: t1.month(),
                        year: t1.year()
                    })
                }
            }
            return value_only ? '' : {value: temp, valid: false, code: 'invalid'};
        case 'string':
            return value_only ? temp : {value: temp, valid: true};
        default:
            return value_only ? '' : {value: temp, valid: true};
    }
}