import React, {useContext} from "react";
import styled from "styled-components";
import {CommunityContext} from "../../../../app/community/community-context";
import {SimpleListbox} from "../../core";

const Container = styled.div`
    position: relative;
   
`;

function getOptions(member_types) {
    let items = [];
    let keys = Object.keys(member_types);
    if (keys.length === 0) {
        return items;
    }
    keys.forEach(function (key) {
        const data = member_types[key];

        items.push({
            value: key,
            label: data.singular
        });

    });
    return items;
}

function getLabel(map,val) {
    if(!val) {
        return '';
    }
    const v = map[val];
    if(!v) {
        return ''
    }
    return v.singular;
}

export default function MemberTypeSelect(props) {
    const community = useContext(CommunityContext);
    const {member_types} = community;
    const {
        value,
        onBlur = () => {},
        onEnter = () => {},
        onEscape = () => {},
        onChange,
        usePortal = true
    } = props;

    let options = getOptions(member_types);
    return <Container>
        <SimpleListbox onEnter={onEnter} onEscape={onEscape} usePortal={usePortal} openMenuOnFocus autofocus={props.autofocus} onChange={(v,t,e)=>{
            onChange(v.value);
        }} value={{value,label:getLabel(member_types,value)}} searchable options={options} />
    </Container>
}