import React, {useState,useEffect} from 'react';
import {FirebaseDB} from "../../../../config/setup-firestore";
import {BasicSelect} from "../../core/basic-select";

function process(docs) {
    return docs.map(doc=>{
        return {
            value: doc.id,
            label: doc.data().label
        }
    })
}

function getLabel(value,map) {
    return map[value];
}

function getValue(value,map) {
    if(!value||value==='') {
        return null;
    }
    return {
        value,
        label: getLabel(value,map)
    }
}

export default function TaxonomySelector({value=null,onChange=()=>{}}) {
    const [options,setOptions] = useState(null);
    const [map,setMap] = useState(null);

    useEffect(function () {
        getTaxonomies()
            .then(res=>process(res))
            .then(data=>setOptions(data));
    }, []);

    useEffect(function () {
        let nm = {};
        for(let i in options) {
            const opt = options[i];
            nm[opt.value] = opt.label
        }
        setMap(nm);
    }, [options]);

    async function getTaxonomies() {
        return await FirebaseDB
            .collection('taxonomies')
            .get()
            .then(snap=>snap.docs)
    }

    if(!options||!map) {
        return null;
    }

    return <BasicSelect placeholder="Taxonomy" autofocus={false} value={getValue(value,map)} searchable clearable={false} onChange={(v) => {
        onChange(v.value);
    }} options={options} />
}