import React, {useEffect, useState} from 'react';
import ReactDOM from 'react-dom';
import {useIsMobile} from "../../../app/global/global-router";
import {Popover2} from "@blueprintjs/popover2";

export function SimpleOverlay(props) {

    const {
        canEscapeKeyClose = false,
        onClose = () => {},
        enforceFocus = false,
        portalClassName = "",
        capture_dismiss = true,
        className = "",
        canOutsideClickClose = false,
        isOpen = false
    } = props;

    const [ref, setRef] = useState(React.createRef());

    if(!isOpen) {
        return null;
    }

    useEffect(function () {
        if(enforceFocus&&ref.current) {
            ref.current.focus();
        }
    }, [])

    const is_mobile = useIsMobile();

    const domNode = document.body;

    const content = <div onKeyDown={(e)=>{
        if(canEscapeKeyClose&&e.keyCode===27) {
onClose(e);
        }
    }} ref={ref} tabIndex="1" className={`bp4-portal ${is_mobile?"z-9999":""} ${portalClassName}`}>
        <div className={`bp4-overlay bp4-overlay-open ${className}`}>
            <div tabIndex="0" className="bp4-overlay-appear-done bp4-overlay-enter-done" />
            <div className="bp4-overlay-backdrop bp4-overlay-appear-done bp4-overlay-enter-done" onClick={(e)=>{
               e.stopPropagation();
               if(canOutsideClickClose) {
                    onClose(e);
                }
            }} />
        {props.children}
        </div>
    </div>;

    return ReactDOM.createPortal(
        content,
        domNode
    );
}