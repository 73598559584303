import React, {useContext} from "react";
import AdminImport, {AdminMemberPage} from "./index";
import {StandardPageHeight} from "../../../../library/playground/standard-page-framework";
import {EntityHeader} from "../../../../library/components/core";
import {useHistory} from "react-router-dom";
import {CommunityContext} from "../../community-context";

export function AdminImportWrapper(props) {
    const history = useHistory();
    const community = useContext(CommunityContext);
 return <StandardPageHeight full content>
        <AdminImport goBackTo={()=>history.push(`/${community.data.handle}/admin/settings`)} scrollable={true} {...props} />
    </StandardPageHeight>
}