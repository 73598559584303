import React, {Component} from 'react';
import styled from "styled-components";
import moment from 'moment';
import {StandardTag} from "../standard-tag";
import {FULL_DATE_FORMAT} from "../../../../config/defaults";
import {validateEmail} from "../../../utilities/validation";
import {EntityInfo} from "../../../utilities/localstorage/entity-localstorage";

const HeaderFrame = styled.div`
        padding: 6px 10px 6px 10px;
      line-height: 20px;
`;

const HeaderText = styled.span`
    text-transform: uppercase;
    font-size: 11px;
    color: #1A1F35;
    letter-spacing: 0.2px;
    font-weight: 500;
`;

const BodyFrame = styled.div`
       padding: 9px 10px 9px 10px;
      line-height: 1.2;
      max-width: 95%;
      display: flex;
      
`;

const Text = styled.span`
color: #1A1F35;
`;

const Meta = styled.span`
color: #697487;
`;

const Title = styled.span`
color: #1A1F35;
font-weight: 500;
`;

const Tag = styled.span`
color: #1A1F35;
margin-top: -2px;
    margin-bottom: -2px;
`;

// todo unify field renderer

function RenderName({data}) {
    return data.name;
}

function displayValue(type,value,ctx) {
    switch (type) {
        case 'title' : {
            return <Title>
                {value}
            </Title>
        }
        case 'meta' : {
            return <Meta>
                {value}
            </Meta>
        }
        case 'handle' : {
            return <Meta>
                @{value}
            </Meta>
        }
        case 'assignee' : {
            if(value&&value!=='') {
                return <Meta>
                    <EntityInfo community_uid={ctx.community_uid} id={value} type={'members'}>
                    <RenderName />
                </EntityInfo>
                </Meta>
            } else {
                return <Meta>-</Meta>;
            }
        }
        case 'email' : {
            return <Meta>
                {validateEmail(value)?<a href={`mailto:${value}`}>{value}</a>:value}
            </Meta>
        }
        case 'array-count' : {
            return <Meta>
                {value.length}
            </Meta>
        }
        case 'key-count' : {
            return <Meta>
                {Object.keys(value).length}
            </Meta>
        }
        case 'date' : {
            return <Meta>
                {moment(value).format(FULL_DATE_FORMAT)}
            </Meta>
        }
        case 'tag' : {
            return <Tag>
                <StandardTag text={value.value} intent={value.intent} />
            </Tag>
        }
        default : {
            return <Text>
                {value}
            </Text>
        }
    }
}

export const HeaderCell = ({text}) => {
    return <HeaderFrame>
        <HeaderText>
        {text}
        </HeaderText>
    </HeaderFrame>
};

export const BodyCell = ({type,value,ctx}) => {
    return <BodyFrame className="truncate">
        {displayValue(type,value,ctx)}
    </BodyFrame>
};