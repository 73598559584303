import React, {useState} from 'react';
import {getMemberJoinDate, MemberJoinDate} from "../../../../../library/components/reorganize/member-join-date";
import {Popover2} from "@blueprintjs/popover2";
import {ProfileBadge} from "../../../../../library/components/profile-badge";

export function AdminMemberJoinedDate({member,join_date_init,handleBlur=()=>{},handleChange=()=>{}}) {
    if(!member) {
        return null;
    }

    const [jd,setJd] = useState({...join_date_init});
    const [open,setOpen] = useState(false);
    const val = getMemberJoinDate(jd);

    const content = <MemberJoinDate onBlur={()=>handleChange(jd)} onChange={(njd)=>setJd({...njd})} existing={{...jd}}/>

    return <Popover2 interactionKind={'click'} canEscapeKeyClose onInteraction={((nextOpenState, e) => {
          if(nextOpenState) {
              setOpen(true);
          } else {
              // closing, make sure to catch react selelct
              // no e.target on open
              if(e&&e.target&&(e.target.id.indexOf('option')===-1&&e.target.className.indexOf('option')===-1)) {
                setOpen(false);
                  handleBlur(jd);
              }
          }
        })} hoverOpenDelay={0} hoverCloseDelay={0} enforceFocus usePortal isOpen={open} content={content} placement={'bottom-start'} minimal popoverClassName='minimal-popover margin-top-8'>
        <ProfileBadge onClick={()=>{}} type="join-date" text={val} />
        </Popover2>
}