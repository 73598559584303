import React, {useContext} from "react";
import {Button, EntityHeader, PageHeader} from "../../../../../library/components/core";
import EditFieldsSection from "../../../../../library/components/old/edit-fields-section";
import {EyeIcon} from '@heroicons/react/solid';
import {CommunityContext} from "../../../community-context";
import {StandardPageHeight} from "../../../../../library/playground/standard-page-framework";

export default function SettingsAbout(props) {
    const community = useContext(CommunityContext);
    const {history} = props;

    const obj = {
        base_link: `/${community.data.handle}/admin/settings`,
        data: community.data,

        single_line: true,

        endpoint: '/communities/update-about',
        update_fields: ['about', 'hq_address', 'profile_picture_color', 'cover_photo_color', 'cover_photo', 'branding', 'profile_picture', 'name', 'emoji', 'social', 'contact_info'],

        sections: [
            {
                title: 'General',
                fields: [
                    {
                        name: 'Name',
                        type: 'text',
                        field: 'name'
                    },
                    {
                        name: 'Symbol',
                        edit_only: true,
                        type: 'symbol',
                        field: 'emoji'
                    },
                    {
                        name: 'Bio',
                        type: 'textarea',
                        field: 'about.bio'
                    },
                    {
                        name: 'Location',
                        type: 'text',
                        field: 'about.location'
                    },
                    {
                        name: 'Founding Year',
                        type: 'text',
                        field: 'about.founded'
                    },
                    {
                        name: 'Category',
                        type: 'organization-category',
                        field: 'about'
                    }
                ]
            },
            {
                title: 'Branding',
                fields: [
                    {
                        name: 'Accent Color',
                        edit_only: true,
                        type: 'color',
                        field: 'branding.color'
                    },
                    {
                        name: 'Topbar Color',
                        edit_only: true,
                        type: 'color',
                        field: 'branding.topbar_bg'
                    },
                    {
                        name: 'Logo',
                        edit_only: true,
                        type: 'community-logo',
                        field: 'url:profile_picture,color:profile_picture_color'
                    },
                    {
                        name: 'Cover Photo',
                        edit_only: true,
                        type: 'cover-photo',
                        field: 'url:cover_photo,color:cover_photo_color'
                    }
                ]
            },
            {
                title: 'Contact Info',
                fields: [
                    {
                        name: 'Email',
                        field: 'contact_info.email',
                        type: 'email',
                        visibility: 'visibility.email'
                    },
                    {
                        name: 'Phone Number',
                        field: 'contact_info.phone',
                        type: 'phone',
                        visibility: 'visibility.phone'
                    },
                    {
                        name: 'HQ Address',
                        type: 'address',
                        field: 'hq_address'
                    },
                ]
            },
            {
                title: 'Social',
                fields: [
                    {
                        name: 'Website',
                        type: 'url',
                        field: 'social.website'
                    },
                    {
                        name: 'Facebook Page',
                        type: 'text',
                        field: 'social.phone'
                    },
                    {
                        name: 'Twitter Handle',
                        type: 'text',
                        field: 'social.twitter'
                    },
                    {
                        name: 'LinkedIn Page',
                        type: 'text',
                        field: 'social.linkedin'
                    }
                ]
            },

        ]
    };

    return (<StandardPageHeight content>
            <EntityHeader title="About" goBackTo={()=>history.push(`/${community.data.handle}/admin/settings`)} actions={<Button onClick={() => {
                history.push(`/${community.data.handle}`);
            }} icon={<EyeIcon/>} text='Community Profile'/>} />

            <EditFieldsSection history={props.history} {...obj} />
        </StandardPageHeight>
    );
}