import {flattenMessages} from "./util";

export const intl_messages_de = flattenMessages({
    auth: {
        signin: {
            title: 'Log in',
            email_label: 'Email',
            password_label: 'Passwort',
            login_error: 'Email, handle oder Passwort falsch',
            forgot_password_hint: 'Passwort vergessen?',
            signin_button: 'Weiter',
            no_password_message: 'Log in ohne Passwort?'
        }
    },
    languages: {
        en: 'English',
        de: 'Deutsch'
    },
    welcome_card: {
        welcome: 'Wilkommen',
        good_morning: 'Guten Morgen',
        good_evening: 'Guten Abend',
        good_afternoon: 'Guten Tag',
        greeting: 'Schӧn dich wiederzusehen!',

        actions: {
            secondary_email: {
                title: 'Zweite Email',
                subtitle: 'Nutze mehrere emails um auf nachrichten zu antworten.'
            },
            add_your_interests: {
                title: 'Folge deine interessen',
                subtitle: 'Entdecke Mitglider und Gruppen mit ӓhnlichen interessen.'
            }
        }
    },
    topbar: {
        feedback: 'Ideen?',
        improve_unaty: 'Verbesserungsvorschlag?',
        improve_issue: 'Probleme mit Unaty? Kontaktiere den Support oder lies die Dokumentation.',
        search: 'Durchsuche',
        the_unaverse: 'das Unaverse',
        view_my_profile: 'Mein Profil',
        edit_my_profile: 'Profil bearbeiten',
        sign_out: 'Log out',
        my_preferences: 'Einstellungen'
    },
    sidebar: {
        communities: 'Communities',
        explore: 'Entdecken',
        sign_in: 'Einloggen',
        memberships: 'Mitgliedschaften',
        profile: 'Profil',
        add_a_community: 'Neue Community',
        switch_community: 'Community wechseln',
        help_center: 'Hilfe',
        contact_support: 'Support kontaktieren',
        find_a_community: 'Community finden',
        create_a_community: 'Community erstellen',
        home: 'Start',
        members: 'Mitglieder',
        directory: 'Verzeichnis',
        manage: 'Verwalten',
        admin: 'Admin',
        groups: 'Gruppen',
        leaders: 'Leitung',
        teams: 'Teams',
        events: 'Veranstaltungen',
        roles: 'Rollen',
        files: 'Dateien',
        calendar: 'Kalender',
        notifications: 'Benachrichtigungen',
        create_group: 'Gruppe erstellen',
        my_groups: 'Meine Gruppen',
        manage_community: 'Admin bereich',
        browse_groups: 'Alle Gruppen',
        invite_people_to: 'Personen zu {name} einladen',
        community_settings: 'Community Einstellungen',
        settings: 'Settings',
        drive: 'Drive',
        customize: '{name} anpassen',
        designer: 'Designer',
        policies: 'Richtlinien',
        interests: 'Interessen',
        back_to_the_unaverse: 'Zurück zum Unaverse'
    },
    shared: {
        field_labels: {
            account_email: 'Konto Email',
            first_name: 'Vorname',
            last_name: 'Nachname',
            title: 'Titel',
            middle_name: 'Weiterer Name',
            phone: 'Handy',
            handle: 'Handle',
            timezone: 'Zeitzone',
            language: 'Sprache',
            region: 'Region',
            military_time: '24h Zeit',
            member_type: 'Mitgliedsart',
            bio: 'Bio',
            email: 'Email',
            founded: 'Gegründet',
            website: 'Website',
            linkedin: 'LinkedIn',
            secondary_phone: '2. Telefonnummer',
            facebook: 'Facebook',
            meetup: 'Meetup',
            trello: 'Trello',
            age: 'Alter',
            twitter: 'Twitter',
            symbol: 'Symbol',
            phone_number: 'Telefonnummer',
            name: 'Name',
            purpose: 'Zweck',
            description: 'Beschreibung',
            address: 'Addresse',
            career: 'Laufbahn',
            interests: 'Interessen',
            eduction: 'Ausbildung',
            full_name: 'Vollständiger Name',
            birthday: 'Geburtstag',
            country: 'Land',
            zip_code: 'PLZ',
            state: 'Bundesland / Kanton',
            line_one: 'Zeile 1',
            line_two: 'Zeile 2',
            city: 'Stadt',
            sessions: 'Sitzungen',
            last_login: 'Letzter Login',
        },
        actions: {
            show_menu: 'Einstellungen',
            edit: 'Bearbeiten',
            edit_profile: 'Profil bearbeiten',
            save_changes: 'Speichern',
            delete: 'Lӧschen',
            selected: 'Ausgewӓhlen',
            deselected: 'Abwӓhlen',
            add_cover: 'Neues Cover',
            change_handle: 'Handle ӓndern',
            create: 'Erstellen',
            save: 'Speichern',
            move: 'Verschieben',
            send: 'Versenden',
            cancel: 'Abbrechen',
            remove: 'Entfernen'
        },
        common: {
            joined: 'Hinzugefügt',
            born: 'Geboren',
            permissions: 'Berechtigungen'
        },
        entities: {
            groups: 'Gruppen',
            group: 'Gruppe',
            events: 'Veranstaltungen',
            event: 'Veranstaltung',
            owner: 'Owner',
            leaders: 'Leitung',
            sent: 'Sent',
            threads: 'Threads',
            drafts: 'Drafts',
            custom_field: 'Eigenes Feld',
            custom_fields: 'Eigene Felder',
            folders: 'Ordner',
            folder: 'Ordner',
            files: 'Dateien',
            file: 'Datei',
            member_types: 'Mitgliedsarten',
            member_type: 'Mitgliedsart',
            community: 'Community',
            communities: 'Communities',
            posts: 'Posts',
            post: 'Post',
            filter: 'Filter',
            filters: 'Filters',
            member: 'Mitglied',
            directory: 'Verzeichnis',
            members: 'Mitglieder',
            user: 'Nutzer',
            users: 'Nutzer',
            team: 'Team',
            teams: 'Teams',
            role: 'Rolle',
            roles: 'Rollen',
            admin: 'Admin',
            admins: 'Admins',
            moderator: 'Moderator',
            moderators: 'Moderatoren',
        },
        advanced_table: {
            fields: 'Felder',
            filter: 'Feld',
            sort: 'Sortieren',
            search: 'Suchen'
        }
    },
    preferences: {
        title: 'Meine Prӓferenzen',
        preferences: 'Prӓferenzen',
        community_settings: 'Community Einstellungen',
        applications: 'Bewerbungen',
        general: 'Allgemeines',
        roles_and_teams: 'Rollen & Teams',
        teams_rank: 'Teams Reihenfolge',
        members: 'Mitglieder',
        notifications: 'Benachrichtigungen',
        messages: 'Messages',
        security: 'Sicherheit',
        about: 'Details',
        branding: 'Branding',
        time_date: 'Zeit & Datum',
        customize: 'Community anpassen',
        account: 'Konto',
        designer: 'Designer',
        custom_fields: 'Profil Felder',
        filters: 'Filter',
        defaults: 'Standardeinstellungen',
        build_mode: 'Build Mode',
        developers: 'Developers',
        api_keys: 'API Keys',
        webhooks: 'Webhooks',
        events: 'Events',
        logs: 'Logs',
        plan: 'Plan',
        privacy: 'Privatsphäre',
        admins: 'Admins',
        member_types: 'Arten',
        community: 'Gemeinschaft',
        delegated_access: 'Zugang Delegieren',
        duplicates: 'Duplikate finden',
        data_toolkit: 'Daten Werkzeuge',
        segments: 'Segmente',
        rules: 'Regeln'
    },
    global: {
        user: {
            submenu: {
                profile: "Profil",
                about_yourself: 'Über dich..'
            }
        },

        communities: {
            title: 'Communities',
            create_button: 'Erstellen',
            enter: 'Eingabe'
        }
    },
    widgets: {
        birthdays: {
            title: 'Nächste Geburtstage',
            calendar: 'Kalender'
        },
        pinboard: {
            title: 'Pinnwand',
            add_action: 'Neu'
        },
        recent_files: {
            title: 'Letzte Dateien',
            view_all: 'Zeige alle'
        }
    },
    home: {
        customize_home: 'Startseite bearbeiten'
    },
    policies: {
        privacy_policy: 'Privatspähre',
        community_guidelines: 'Verhaltensregeln',
    },
    community: {
        preferences: {
            account: {
                title: 'Zugang',
                general: {
                    title: 'Allgemein'
                },
                notifications: {
                    title: 'Benachrichtigungen'
                }
            },
            security: {
                title: 'Sicherheit',
                general: {
                    title: 'Allgemein'
                }
            }
        },
        home: {
            customize: 'Start anpassen'
        },
        calendar: {
            title: 'Kalendar',
            today: 'Heute',
            short_days: {
                sunday: 'So',
                monday: 'Mo',
                tuesday: 'Di',
                wednesday: 'Mi',
                thursday: 'Do',
                friday: 'Fr',
                saturday: 'Sa'
            }
        },
        group: {
            joined: 'Mitglied',
            join: 'Beitreten',
            leave_group: 'Verlassen',
            open_group: 'Offene Gruppe',
            team_group: 'Team',
            closed_group: 'Geschlossene Group',
            email: 'Email',
            message: 'Nachricht',
            settings: {
                about_the_group: 'Über die Gruppe',
                edit_name: 'Namen bearbeiten',
                moderators: 'Moderatoren',
                email_settings: 'Email Einstellungen',
                pinboard: 'Pinboard'
            },
        },
        members: {
            add_members: 'Mitglied hinzufügen'
        },
        groups: {

        },
        roles: {

        },
        member: {},
        admin: {
            admins: {

            },
            member_type: {

            },
            member_types: {

            },
            about: {

            },
            branding: {

            },
            custom_fields: {

            },
            build_mode: {

            },
            plan: {

            },
            privacy: {

            }
        },
        event: {},
        interests: {
            title: 'Entdecke Mitglieder mit ähnlichen Interessen',
            interested_in: 'Interessiert an',
            other_user: 'anderer Nutzer',
            other_users: 'andere Nutzer',
            // todo add number
            limit: 'Wähle bis zu 15 Interessen - fehlt noch etwas?',
            let_us_know: 'Schlag es vor!',
            explore: 'Entdecke',
            popular: 'Beliebt'
        },
        interest: {},
        role: {},
        folder: {},
        files: {
            title: 'Meine Dateien',
            table: {
                name: 'Name',
                last_modified: 'Zuletzt geändert',
                size: 'Größe',
                add_a_field: 'Feld hinzufügen',
                fields_for: 'Felder für'
            },
            actions: {
                move: 'Verschieben',
                go_up: 'Übergeordnet',
                folder: 'Ordner',
                upload: 'Hochladen',
                delete: 'Löschen'
            }
        },
        modals: {
            new_folder: {
                title: 'Ordner erstellen'
            },
            create_group: {

            },
            add_pin: {
                preview: 'Vorschau',
                create_pin: 'Pin erstellen'
            },
            add_cover: {
                gallery: 'Galerie',
                custom: 'Eigene',
                categories: {
                    nature: 'Natur',
                    architecture: 'Architektur'
                }
            },
            move_folder: {
                a: 'Wohin verschieben?',
                b: 'Dieser Ordner erhält die Zugriffsrechte des Zielordners, seine Inhalte können dadurch anderen Mitgliedern als zuvor sichtbar sein.'
            },
            delete_folder: {

            }
        }
    },
    interests: {
        fencing: 'Fechten',
        beer: 'Bier',
        volunteering: 'Soziales Engagement',
        bears: 'Bären',
        comedy: 'Comedy',
        history: 'Geschichte',
        camping: 'Camping',
        architecture: 'Architektur',
        startups: 'Startups',
        fashion: 'Mode',
        politics: 'Politik',
        foodie: 'Essen',
        religious: 'Religion',
        sailing: 'Segeln',
        skateboarding: 'Skateboard',
        art: 'Kunst',
        surfing: 'Surfen',
        coffee: 'Kaffee',
        economics: 'Ökonomie',
        cars: 'Autos',
        martial_arts: 'Kampfsport',
        board_games: 'Brettspiele',
        baseball: 'Baseball',
        biking: 'Fahrrad',
        tv_shows: 'Serien',
        photography: 'Fotografie',
        fishing: 'Angeln',
        dogs: 'Hunde',
        tennis: 'Tennis',
        wine: 'Wein',
        boxing: 'Boxen',
        jogging: 'Joggen',
        podcasts: 'Podcasts',
        golf: 'Golf',
        baking: 'Backen',
        horseback_riding: 'Reiten',
        painting: 'Malen',
        makeup: 'Schminken',
        travelling: 'Reisen',
        swimming: 'Schwimmen',
        dancing: 'Tanzen',
        yoga: 'Yoga',
        brunch: 'Brunch',
        piano: 'Klavier',
        hiking: 'Wandern',
        basketball: 'Basketball',
        shopping: 'Shopping',
        skiing: 'Ski',
        reading: 'Lesen',
        cooking: 'Kochen',
        theatre: 'Theater',
        singing: 'Singen',
        badminton: 'Badminton',
        coding: 'Coding',
        bowling: 'Bowlen',
        cats: 'Katzen',
        hunting: 'Jagen',
        movies: 'Filme',
        music: 'Musik',
        football: 'Football',
        ice_skating: 'Eislauf',
        scuba_diving: 'Tauchen',
        video_games: 'Videospiele',
        writing: 'Schreiben',
        chess: 'Schach',
        sushi: 'Sushi',
        ufos: 'UFOs',
        science: 'Wissenschaft',
        beach: 'Strand',
        fitness: 'Fitness',
        motorcycles: 'Motorrad',
        data_science: 'Data Science',
        mathematics: 'Mathematik',
        soccer: 'Fußball',
        gardening: 'Garten',
        environment: 'Umwelt'
    }
});