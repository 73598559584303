import React, {useState, useContext} from 'react';
import {PolicyViewer} from "../community/policy";
import {Button, Toggle} from "../../library/components/core";
import {authFetch} from "../../config/network";
import {useIsMobile} from "../global/global-router";
import {demo_policy, inDemoMode} from "./user";
import {RemoteMdViewer} from "./remote-md-viewer";

export function CommunityPolicyGate({community, member_id = '', community_uid, member}) {
    const [loading, setLoading] = useState('');
    const is_mobile = useIsMobile();
    const [contents, setConsent] = useState(false);

    if (!member || !community) {
        return null;
    }

    let member_policies = member.policies ? member.policies : {};

    let community_policies = community.policies ? Object.entries(community.policies) : [];

    let needs_to_accept = [];

    for (let i = 0; i < community_policies.length; i++) {
        const [slug, ver] = community_policies[i];
        const uid = `${slug}__${ver}`;
        if (!member_policies[uid]) {
            needs_to_accept.push({
                slug,
                version: ver
            });
        }
    }

    const demo_mode = inDemoMode("member_policy_demo");

    if (needs_to_accept.length === 0 && !demo_mode) {
        return null;
    }

    function snapToTop() {
        let content_cont = document.getElementById('policy-viewer-container');
        if (content_cont) {
            content_cont.scrollTop = 0;
        }
    }

    function acceptPolicy(slug, version) {
        setLoading('accepting');
        const res = (result) => {
            setConsent(false);
            setLoading('');
            snapToTop();
        };

        const payload = {
            community_uid,
            member_id,
            slug, version
        };

        authFetch("/members/accept-policy", res, res, "POST", {payload});
    }

    const policy_to_accept = needs_to_accept[0] ? needs_to_accept[0] : null;

    if (!policy_to_accept && !demo_mode) {
        return null;
    }

    return <div
        className={`z-9999 fixed top-0 left-0 h-screen right-0 bottom-0 bg-black bg-opacity-70 ${is_mobile ? "mobile-policy-gate" : ""}`}>
        <div className="max-w-2xl mx-auto relative h-screen pt-8 pb-16">
            <div className={`${is_mobile ? "h-screen flex flex-col" : "rounded-md"} bg-white`}>

                <div id="policy-viewer-container" style={{paddingBottom: '40vh'}}
                     className={`${is_mobile ? "flex-grow max-h-100vh" : "max-h-70vh"} overflow-y-auto relative`}>
                    {!demo_mode &&
                    <PolicyViewer viewer_only is_update={true} show_logo={true} can_edit={false} slug={policy_to_accept.slug}/>}
                    {demo_mode && <RemoteMdViewer demo={true} url={demo_policy.link}/>}
                </div>
                <div
                    className={`${is_mobile?"fixed bottom-0 left-0 right-0 ":"rounded-b-md"} bg-white flex space-x-6 items-center p-4 border-t border-gray-200`}>
                    <div className={`space-y-2 flex-grow sm:flex sm:space-x-6 items-center `}>
                        <div className="flex-grow flex sm:justify-end">
                            <Toggle checked={contents} onToggle={(v) => setConsent(v)} label={<>
                                I agree to the terms of the policy above.
                            </>}/>
                        </div>
                        <Button size="lg" disabled={!contents} loading={loading === 'accepting'}
                                onClick={() => acceptPolicy(policy_to_accept.slug, policy_to_accept.version)}
                                text="Accept & Continue" intent="secondary"/>
                    </div>
                </div>
            </div>
        </div>
    </div>
}