import React, {useContext} from "react";
import {Route, Switch, Redirect} from "react-router-dom";
import FilesTopLevel from "./top-level";
import {CommunityContext} from "../community-context";
import {FolderWrapper} from "./folder/wrapper";

export default function CommmunityFiles() {

    const community = useContext(CommunityContext);

    return (<Switch>
            <Route path="/:handle/explore/drive" exact component={FilesTopLevel}/>
            <Route path="/:handle/explore/drive/folder/:folderId" component={FolderWrapper}/>
            <Redirect from="*" to={`/${community.data.handle}/explore/drive`}/>
        </Switch>
    );
}