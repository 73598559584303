import React, {useContext, useEffect, useState} from 'react';
import {Button, InlineLoader} from "../../../../library/components/core";
import Modal from "../../../../library/components/old/modal";
import {FeedItem} from "../../../../library/components/posts-feed/feed-item";
import {api_getPostReplies} from "../../../../api/community/posts/get-post-replies";
import {api_getPost} from "../../../../api/community/posts/get-post";
import {api_getPostReply} from "../../../../api/community/posts/get-reply";
import {CommunityContext} from "../../community-context";
import {useIsMobile} from "../../../global/global-router";
import {ComposeReply} from "../compose-reply";

function FeedDataLoader({
    type = "post",
    id,
                            community_uid = "",
    parent_id
                        }) {
    const community = useContext(CommunityContext);
    const is_mobile = useIsMobile();
    const [data,setData] = useState(null);

    useEffect(function () {
        if(type==="post") {
            api_getPost(community_uid,id)
                .then(dt=>setData(dt))
        } else if(type==='reply') {
                api_getPostReply(community_uid,parent_id,id)
                    .then(dt=>setData(dt))
        }
    }, []);
    if(!data) {
        return <div>
<InlineLoader padding />
        </div>
    }

    const feed_item_props = {
        is_mobile,
        community_handle: community.data.handle,
        community_uid: community.uid,
        activity_meta: {
            community
        },
        disable_actions: true
    };

    return <div className="">
        <FeedItem {...data} type={type} threaded {...feed_item_props} />
    </div>
}

export function ReplyModal({
    reply_to_type = "post",
    reply_to_id = "",
    reply_to_parent_id = "",
                               community_uid = "",
    onClose=()=>{}
                           }) {
    const [loading, setLoading] = useState("");

    function sendReply() {
        onClose();
    }
    const body = <div className="-mt-3">
        <FeedDataLoader community_uid={community_uid} type={reply_to_type} id={reply_to_id} parent_id={reply_to_parent_id} />
        <div>
            <ComposeReply onSendReply={()=>sendReply()} init_state="reply-modal" post_id={reply_to_parent_id} type={reply_to_type} reply_to_id={reply_to_id} />
        </div>
    </div>;

    return <Modal title={``} size='medium' body={body} footerRight={null} footerLeft={null}
                  onClose={onClose} open={true} />
}