import React from "react";
import {TipItem} from "../../app/global/right-context-menu/help-and-tips-menu/tip";
import {all_tips} from "../../library/components/education/tips";

export function GPTips() {

    return <div className="gap-4 grid grid-cols-3">
        {all_tips.map(tip=><div key={tip.id}>
            <TipItem {...tip} />
            <div className="p-2">
                <div className="text-gray-800 font-semibold text-sm py-1">Info</div>
                <div>
                    <div className="text-gray-500 text-xs">
                        Visible for: {tip.for.join(", ")}
                    </div>
                </div>
            </div>
        </div>)}
         </div>
}