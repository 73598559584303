import React from "react";
import {ProfileBadge} from "../../../../../../library/components/profile-badge";

export function MemberTypeBadge({community={},member={},member_id=""}) {
    if(!member_id) {
        return null;
    }

    const member_type_data = community.member_types[member.member_type];

    return <ProfileBadge type="member-type" text={member_type_data.singular} />
}