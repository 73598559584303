import React, {useEffect, useState} from 'react';
import {InfoState, InlineLoader} from "../../../library/components/core";
import MyApplications from "../../../library/components/join-community/my-applications";
import {getManyItems} from "../../../library/utilities/get-many-items";

// communities is an array of community_uid
export function PendingMemberships({communities=[],applications=[]}) {
    const [community_data,setCommunityData] = useState({});

    useEffect(function () {
        if(applications.length>0) {
            getManyItems('communities', applications, (data) => {
                let fnc = {};
                data.forEach(b=>{
                    fnc[b.id] = b;
                });
                setCommunityData(fnc);
            })
        }

    }, [applications])

    let content;
    if(Object.keys(community_data).length!==applications.length) {
        content = <InlineLoader />;
    } else if(applications.length===0) {
        // this shouldn't happen because we hide this page
        content = <InfoState fill subtitle="No Pending Applications" />;
    } else {
        let items = [];
        applications.forEach(id=>{
            if(community_data[id]) {
                items.push({
                    status: 'pending',
                    data: community_data[id],
                    community_uid: id
                });
            }
        })
        content = <MyApplications items={items} />
    }

    return <div className={` pt-2 pb-10 px-4 md:px-0`}>
        {content}
    </div>
}