import React, {useContext, useEffect, useState} from "react";
import {CommunityContext} from "../../community-context";
import {FirebaseDB} from "../../../../config/setup-firestore";
import {CommonLayoutProfile} from "../../../../library/components/layout/page/profile";
import {InlineLoader, Tabs} from "../../../../library/components/core";
import {NewAdminMemberSidebar} from "./new-sidebar";
import {NewAdminMemberActions} from "./actions";
import {profileQualityCalculator} from "../../../../library/utilities/profile-quality-calculator";
import {memberPoliciesCalculator} from "../../../../library/utilities/policies-calculator";
import {setDocumentTitle} from "../../../../library/utilities/set-document-title";
import EditFieldsSection from "../../../../library/components/old/edit-fields-section";
import {getPhoneServices} from "../../member";
import {memberEditObj} from "./edit-obj";
import {displayBday, getAge} from "../../../../library/components/old/edit-fields-section/fields";
import {NewAdminMemberAccount} from "./components/account";
import {authFetch} from "../../../../config/network";
import {AMGroupsList} from "./groups-list";
import {GetRelatedMembers} from "./get-related-members";
import {AMSegmentsList} from "./segments-list";
import {logError} from "../../../../api/errors/log-error";
import AddSecondaryEmail from "../../../global/preferences/notifications/add-email";
import {GlobalContext} from "../../../global/global-context";

const update_changes = ['member_type'];

export function canTryAutocompleteAddress(a) {
    if(!a.line_one) {
        return false;
    } else if(!a.zip_code&&!a.city) {
        return false;
    } else if(a.line_one&&a.city&&a.zip_code&&a.country&&a.state) {
        return false;
    } else if(a.city&&a.line_one) {
        return true
    } else if(a.zip_code&&a.country&&a.line_one) {
        return true;
    } else {
        return false;
    }
}

function getMemberMeta(id, member, community) {
    let is_admin = false, is_owner = false;
    is_admin = community.admins.includes(id);

    let age, formatted_bday;

    formatted_bday = displayBday(member.birthday);

    age = getAge(member.birthday);

    return {
        formatted_bday,
        age,
        is_owner,
        is_admin
    }
}

export function AdminMemberPage(props) {
    const global = useContext(GlobalContext);
    const community = useContext(CommunityContext);
    let id = props.id;
    const [tab, setTab] = useState("Profile");
    const [modal, setModal] = useState(null);
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState('member');

    useEffect(function () {
        id = props.id;
        getMember(id);
    }, [props.id]);

    function getMember(id, cb) {
        console.log('get member',id);
        if(!id) {
            console.error("NO DATA");
            logError({id,code:'no-id-for-member'},{community_uid:community.uid,member_id:community.member_id})
            setLoading(null);
            return;
        }
        FirebaseDB
            .collection('community_members')
            .doc(community.uid)
            .collection('members')
            .doc(id)
            .get()
            .then(doc => {
                const data = doc.data();
                if (cb) {
                    cb(data);
                }
                if(!data) {
                    const logged = JSON.stringify({id,uid:community.uid},undefined,2);
                    console.error("NO DATA",logged,);
                    logError(logged,{community_uid:community.uid,member_id:community.member_id})
                }
                setData(data);
                setLoading(null);
            })
    }

    function updateMember() {
        getMember(id)
    }

    if (loading === 'member') {
        return <div>
            <InlineLoader padding/>
        </div>;
    }

    if (!data) {
        return <div className="p-2 text-gray-500">
            Oops! Couldn't find the right member here..
        </div>;
    }

    const _actions = {
        changeAccountEmail: () => {

        }
    };

    const obj = memberEditObj(data, id, community.custom_fields, community, null, _actions);
    const pq = profileQualityCalculator(data, community);
    const pc = memberPoliciesCalculator(data, community, id);
    const phone_services = getPhoneServices(data);
    const meta = getMemberMeta(id, data, community);

    function handleChange(f, v) {
        let m1 = {...data};

        const sp = f.split('.');
        if (sp.length === 2) {
            m1[sp[0]][sp[1]] = v;
        } else {
            m1[f] = v;
        }
        setData(m1);

        if (update_changes.includes(f)) {
            getMember(id);
        }
    }

    function autocompleteAddress(dt) {
        setLoading('autocomplete-address');

        const res = () => {
            getMember(id);
            setLoading('');
        };

        const payload = {
            member_id: community.member_id,
            community_uid: community.uid,
            id,
            ...dt
        };

        authFetch("/members/autocomplete-address", res, res, "POST", {payload});
    }

    let sidebar = <NewAdminMemberSidebar pc={pc} pq={pq} hc={handleChange} member_id={id} member={data} meta={meta}/>;

    setDocumentTitle(data.name, community.data.name);

    const metadata = {
        phone_services,
        email_validation: true,
        ev_data: {target_user_uid: data.user_uid, email: data.account_email},
        id,
        updateMember
    };

    const _action = {
        addEmail: ()=>{

        }
    };

    return <CommonLayoutProfile scrollable={props.scrollable} sidebar={sidebar} layout="admin-entity">
        <Tabs action={<NewAdminMemberActions member_id={id} hc={handleChange} member={data} community={community}
                                             modal={modal} setModal={setModal}/>} active={tab} onSelect={t => setTab(t)}
              tabs={["Profile", "Groups", "Collections", "Preferences"]} layout="large-bottom"/>
        <div>
            {tab === 'Profile' && <EditFieldsSection metadata={metadata} show_vis={false} data={data}
                                                     member_types={community.member_types} id={id}
                                                     autocompleteAddress={autocompleteAddress}
                                                     parent_loading={loading} single_line={false}
                                                     onSaved={()=>getMember(id)}
                                                     profile_sections={community.custom_fields}
                                                     history={props.history} {...obj} />}
            {tab==='Groups'&&<div>
                <AMGroupsList member={data} community_uid={community.uid} group_ids={data.groups?Object.keys(data.groups):[]} />
            </div>}
            {tab==='Collections'&&<div>
                <AMSegmentsList member={data} id={id} community_uid={community.uid} />
            </div>}
            {tab === 'Preferences' &&
            <div className="pt-3 space-y-3">
                <NewAdminMemberAccount pc={pc} hc={handleChange} pq={pq} member_id={id}
                                                         community={community} member={data}
                                                         meta={meta}/>
                <div>
                    <GetRelatedMembers id={id} />
                </div>
            </div>}
        </div>

        {modal === "add-secondary" && <AddSecondaryEmail
            member_id={id}
            user_uid={data.user_uid}
            community_uid={community?community.uid:""}
            onClose={() => {
                setModal(null);
                getMember();
            }}/>}
    </CommonLayoutProfile>
}