import React from 'react';
import {
    AdjustmentsIcon,
    ArchiveIcon, BadgeCheckIcon, BanIcon,
    BellIcon, BriefcaseIcon,
    ChartPieIcon,
    CloudDownloadIcon,
    CogIcon,
    ExclamationCircleIcon,
    ExclamationIcon,
    FireIcon, FolderIcon, GlobeIcon, KeyIcon,
    LightBulbIcon,
    LinkIcon, LocationMarkerIcon, LockClosedIcon,
    QuestionMarkCircleIcon, StarIcon,
    TagIcon
} from "@heroicons/react/solid";
import {CIMembership} from "../../icons/custom/Membership";
import {CICalendar} from "../../icons/custom/Calendar";
import {CIFolder} from "../../icons/custom/Folder";
import {CIMembers} from "../../icons/custom/Members";
import {CakeIcon, ClipboardCheckIcon, FlagIcon, TrashIcon} from "@heroicons/react/outline";
import ModeratorIcon from "../old/moderator-icon";
import {CILeaders} from "../../icons/custom/Leaders";
import {CIDirectory} from "../../icons/custom/Directory";
import {CIMember} from "../../icons/custom/Member";
import {CIAdmin} from "../../icons/custom/Admin";
import {CIGroups} from "../../icons/custom/Groups";
import {CIDrive} from "../../icons/custom/Drive";
import {CIInterests} from "../../icons/custom/Interests";
import {CIUser} from "../../icons/custom/User";
import {CIIntegrations} from "../../icons/custom/Integrations";
import {CIRole} from "../../icons/custom/Role";
import {CIModerator} from "../../icons/custom/Moderator";
import {CIMemberTypes} from "../../icons/custom/MemberTypes";
import {CIMemberType} from "../../icons/custom/MemberType";
import {CICommunityPreferences} from "../../icons/custom/CommunityPreferences";
import {CISegment} from "../../icons/custom/Segment";
import {CIProfileQuality} from "../../icons/custom/ProfileQuality";
import {CIOwner} from "../../icons/custom/Owner";

export const icon_reference = {
    'role': {
        icon: <CIRole />,
        title: 'Role'
    },
    'moderator': {
        icon: <CIModerator />,
      title: 'Moderator'
    },
    'owner': {
        icon: <CIOwner />,
        title: 'Owner'
    },
    'member-type': {
        icon: <CIMemberType />,
        title: 'Member Type'
    },
    'member_type': {
        icon: <CIMemberType />,
        title: 'Member Type'
    },
    'member-types': {
        icon: <CIMemberTypes />,
        title: 'Member Types'
    },
    'community-preferences': {
        icon: <CICommunityPreferences />,
        title: 'Community Preferences'
    },
    'verified': {
        icon: <BadgeCheckIcon />,
        title: 'Verified'
    },
    'key': {
        icon: <KeyIcon />,
        title: 'Key'
    },
    'suspend': {
        icon: <BanIcon />,
        title: 'Suspend'
    },
    'leaders': {
        top_level: true,
        icon: <CILeaders />,
        title: 'Leaders'
    },
    'tag': {
        icon: <TagIcon />,
        title: 'Tag'
    },
    'notifications': {
        icon: <BellIcon />,
        title: 'Notifications'
    },
    'preferences': {
        icon: <AdjustmentsIcon />,
        title: 'Preferences'
    },
    'settings': {
        icon: <CogIcon />,
        title: 'Settings'
    },
    'archive': {
        icon: <ArchiveIcon />,
        title: 'Archive'
    },
    'profile-quality': {
        icon: <CIProfileQuality />,
        title: 'Profile Quality'
    },
    'policies': {
        icon: <ClipboardCheckIcon />,
        title: 'Policies'
    },
    'member_directory': {
        top_level: true,
        icon: <CIDirectory />,
        title: 'Member Directory'
    },
    'segment': {
        icon: <CISegment />,
        title: 'Segment'
    },
    'member': {
        icon: <CIMember />,
        title: 'Member'
    },
    'admin': {
        icon: <CIAdmin />,
        title: 'Admin'
    },
    'starred': {
        icon: <StarIcon />,
        title: 'Starred'
    },
    'integrations': {
        top_level: true,
        icon: <CIIntegrations />,
        title: 'Integrations'
    },
    'groups': {
        top_level: true,
        icon: <CIGroups />,
        title: 'Groups'
    },
    'team': {
        icon: <BriefcaseIcon />,
        title: 'Team'
    },
    'delete': {
        icon: <TrashIcon />,
        title: 'Delete'
    },
    'location': {
        icon: <LocationMarkerIcon />,
        title: 'Location'
    },
    'public': {
        icon: <GlobeIcon />,
        title: 'Public'
    },
    'private': {
        icon: <LockClosedIcon />,
        title: 'Private'
    },
    'founded': {
        icon: <FlagIcon />,
        title: 'Founded'
    },
    'birthday': {
        icon: <CakeIcon />,
        title: 'Birthday'
    },
    'link': {
        icon: <LinkIcon />,
        title: 'Link'
    },
    'memberships': {
        icon: <CIMembership />,
        top_level: true,
        title: 'Memberships'
    },
    'user': {
        icon: <CIUser />,
        top_level: true,
        title: 'User'
    },
    'feedback': {
        icon: <LightBulbIcon />,
        title: 'Feedback'
    },
    'help': {
        icon: <QuestionMarkCircleIcon />,
        title: 'Help'
    },
    'trending': {
        icon: <FireIcon />,
        title: 'Trending'
    },
    'download': {
        icon: <CloudDownloadIcon />,
        title: 'Download'
    },
    'warning': {
        icon: <ExclamationIcon />,
        title: 'Warning'
    },
    'error': {
        icon: <ExclamationCircleIcon />,
        title: 'Error'
    },
    'calendar': {
        title: 'Calendar',
        top_level: true,
        icon: <CICalendar />
    },
    'drive': {
        title: 'Drive',
        top_level: true,
        icon: <CIDrive />
    },
    'folder': {
        icon: <FolderIcon />,
        title: 'Folder'
    },
    'interests': {
        icon: <CIInterests />,
        top_level: true,
        title: 'Interests'
    }
};

export default function SGIconography() {
const entries = Object.entries(icon_reference);
    return <div className="space-y-8">
        <div>
        <div className="font-bold text-gray-800 text-base mb-2">
            Standard
        </div>
        <div className="grid gap-4 grid-cols-6 max-w-3xl">
        {entries.filter(a=>!a[1].top_level).map(([id,data])=><div className="p-2 flex flex-col justify-center" key={id}>
            <div className="h-8 w-8 mx-auto ">
                {data.icon}
            </div>
            <div className="pt-2 w-full h-12 text-center">{data.title}</div>
        </div>)}
    </div>
        </div>
        <div>
        <div className="font-bold text-gray-800 text-base mb-2">
            Top Level
        </div>
        <div className="grid gap-4 grid-cols-6 max-w-3xl">
            {entries.filter(a=>a[1].top_level).map(([id,data])=><div className="p-2 flex flex-col justify-center" key={id}>
                <div className="h-8 w-8 mx-auto">
                    {data.icon}
                </div>
                <div className="pt-2 h-12 w-full text-center">{data.title}</div>
            </div>)}
        </div>
        </div>

    </div>
}