import React, {useContext, useEffect, useRef, useState} from 'react';
import {
    ActionWithIcon,
    Badge,
    Button,
    InfoState,
    InlineLoader,
    SectionHeader,
    Toggle
} from "../../../library/components/core";
import {authFetch} from "../../../config/network";
import {CommunityContext} from "../community-context";
import {EntityInfo} from "../../../library/utilities/localstorage/entity-localstorage";
import {GlobalContext} from "../../global/global-context";
import {isAnonymousUnatyEmail} from "../../../library/utilities/anonymous-emails";
import {account_status_tags} from "../../../library/playground/advanced-table/wrapper";

function Meta({label, value}) {
    return <div className="grid grid-cols-4 text-sm">
        <div className="text-gray-500">{label}</div>
        <div className="col-span-3 text-gray-800">{value}</div>
    </div>
}

function hasNumber(myString) {
    return /\d/.test(myString);
}

const select_statuses = ['archived','missing-email','not-invited'];

function shouldSelect(data) {
    if(isAnonymousUnatyEmail(data.account_email)&&(select_statuses.includes(data.account_status))&&(!data.phone||hasNumber(data.handle))) {
        return true;
    }
    return false;
}

function MemberPreview({data, enable_actions, id, auto_select, any_selected, selected, handleSelect, markAsDupAndDelete}) {
    if (!data) {
        return null;
    }
    const done = useRef(false);

    useEffect(function () {
        if(auto_select&&!any_selected&&!done.current) {
            if(shouldSelect(data)) {
                console.log("IS DUPLICATE",id)
                handleSelect();
            }
            done.current = true;
        }
    }, [auto_select,any_selected])

    function handleClick() {
        window.confirm('Are you sure you wish to delete this item?') ? markAsDupAndDelete(data.id, data) : ()=>{}
    }

    const as = account_status_tags.filter(a=>a.id===data.account_status)[0];
    return <div className="grid gap-2 p-4 rounded-md border border-gray-200">
        <div className="pb-1 flex">
            <div className="flex-grow">
                <Toggle checked={selected} onToggle={()=> {
                    handleSelect()
                }} />
            </div>
            <div>
                {enable_actions&&<ActionWithIcon inverse onClick={handleClick}
                                text="Delete Duplicate"/>}
            </div>
        </div>
        <Meta label="ID" value={data.id}/>
        <Meta label="Name" value={data.name}/>
       <Meta label="Email" value={data.account_email}/>
        <Meta label="Handle" value={`@${data.handle}`}/>
        <Meta label="Phone" value={data.phone}/>
        <Meta label="Account Status" value={!as?"-":<Badge size="sm" color={as.color} text={as.label} key={as.id}/>}/>
    </div>
}

function DuplicateItem({community_uid, auto_select, one, handleSelect, two, selected, markAsDupAndDelete}) {
    const [deleted,setDeleted] = useState(false);
    if(deleted) {
        return null;
    }
    const any_selected = selected.includes(one)||selected.includes(two);
    return <div className="grid gap-2 grid-cols-2">
        <EntityInfo id={one} type="members" community_uid={community_uid}>
            <MemberPreview enable_actions={!any_selected} id={one} any_selected={any_selected} auto_select={auto_select} handleSelect={()=>handleSelect(one)} selected={selected.includes(one)} markAsDupAndDelete={()=>{
                markAsDupAndDelete(one)
                setDeleted(true);
            }}/>
        </EntityInfo>
        <EntityInfo id={two} type="members" community_uid={community_uid}>
            <MemberPreview enable_actions={!any_selected} id={two} any_selected={any_selected} auto_select={auto_select} handleSelect={()=>handleSelect(two)} selected={selected.includes(two)} markAsDupAndDelete={()=>{
                markAsDupAndDelete(two)
                setDeleted(true);
            }}/>
        </EntityInfo>
    </div>
}

export function MemberDuplicates(props) {
    const global = useContext(GlobalContext);
    const community = useContext(CommunityContext);
    const [data, setData] = useState(null);
    const [selected,setSelected] = useState([]);
    const [auto_select,setAutoselect] = useState(false);
    const [deleted,setDeleted] = useState([]);

    const selected_store = useRef([]);

    useEffect(function () {
        selected_store.current = selected;
    }, [selected])

    useEffect(function () {
        if(!data) {
            callAPI()
        }
    }, [data])

    function markAsDupAndDelete(id) {
        const res = (resp) => {

        };
        global.addToast({text: "Member being deleted"})
        const payload = {
            id,
            community_uid: community.uid,
            member_id: community.member_id,
            user_uid: community.member.user_uid
        };

        authFetch("/members/mark-as-duplicate-delete", res, res, "POST", {payload});
    }

    function markManyAsDupDelete() {
        const res = (resp) => {

        };

        const payload = {
            ids: selected,
            community_uid: community.uid,
            member_id: community.member_id,
            user_uid: community.member.user_uid
        };

       authFetch("/members/mark-many-as-duplicate-delete", res, res, "POST", {payload});

        global.addToast({text: "Members being deleted"})
        setDeleted([...deleted,...selected])
        setSelected([])
    }

    function callAPI() {
        const res = (resp) => {
            setData(resp.data.duplicates);
        };

        const payload = {
            community_uid: community.uid,
            member_id: community.member_id,
            user_uid: community.member.user_uid
        };

        authFetch("/members/find-duplicates", res, res, "POST", {payload});
    }

    let action = null;

    if(selected.length>0) {
        action = <div className="flex space-x-2">
            <ActionWithIcon text="Deselect all" inverse onClick={()=>setSelected([])} />
            <ActionWithIcon text="Delete Duplicates" inverse onClick={()=> {
                window.confirm(`Are you sure you wish to delete ${selected.length} members?`) ? markManyAsDupDelete() : ()=>{}
            }} />
        </div>
    } else if(data&&data.length>0) {
        action = <div className="flex space-x-2">
            <ActionWithIcon text="Smart auto-select" inverse onClick={()=>setAutoselect(true)} />

        </div>
    }

    const title = !data?"":selected.length>0?`${selected.length} selected`:(`${data.length > 99 ? `Over ${data.length}` : data.length} duplicates found`);

    return <div className="pt-2">
        {!data && <div>
            <InfoState button={<InlineLoader mini/>} fill subtitle="Loading, this can take up to 30 seconds"/>

        </div>}
        {data && data.length === 0 && <div>
            <InfoState fill subtitle="No duplicates found"/>
        </div>}
        {data && data.length > 0 && <div>
            <div className='sticky top-0 bg-white z-10'>
            <SectionHeader fill padded={false} action={action}
                           title={title}/>
            </div>
            <div className="grid gap-3 pt-3">
                {data.map((dt,i)=>{
                    if(deleted.includes(dt.one)||deleted.includes(dt.two)) {
                        return null;
                    }
                    return <DuplicateItem auto_select={auto_select} key={`${dt.one}-${dt.two}`} {...dt} selected={selected} handleSelect={(id)=>{
                        console.log("SEEL",id,selected_store.current,dt.one,dt.two)
                        if(selected_store.current.includes(dt.two)||selected_store.current.includes(dt.one)) {
                            // one of two is already selected
                            const sindex = selected_store.current.indexOf(id);

                            const other_id = dt.one === id ? dt.two : dt.one;

                            if(sindex===-1) {
                                // OTHER is already selected, now we're switching
                                let ns = [...selected_store.current];

                                const otherindex = selected_store.current.indexOf(other_id);

                                ns.splice(otherindex,1);

                                ns.push(id);

                                selected_store.current = ns;

                                setSelected([...ns]);
                            } else {
                                // just deselecting the current one
                                let ns3 = [...selected_store.current];

                                ns3.splice(sindex,1);

                                selected_store.current = ns3;

                                setSelected([...ns3]);
                            }
                        } else {
                            let ns2 = [...selected_store.current,id];
                            selected_store.current = ns2;
                            setSelected([...ns2])
                        }

                    }} markAsDupAndDelete={markAsDupAndDelete} community_uid={community.uid}/>
                })}
            </div>
        </div>}
    </div>
}