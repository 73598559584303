import React from 'react';
import {StandardPageHeight} from "../../../../../library/playground/standard-page-framework";
import {NewMemberApplication} from "./index";

export function AdminMemberApplicationWrapper(props) {


    return <StandardPageHeight>
        <NewMemberApplication {...props} id={props.match.params.id} />
    </StandardPageHeight>
}