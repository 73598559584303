import React, {useState} from 'react';
import {Attachment, Avatar, Button, Meta} from "../../core";
import {DEFAULT_USER_PHOTO} from "../../../../config/defaults";
import styled from "styled-components";
import {ChevronDoubleUpIcon, ChevronRightIcon, EyeIcon, PlayIcon, StarIcon} from "@heroicons/react/solid";
import {useHistory} from "react-router-dom";
import {EntityInfo} from "../../../utilities/localstorage/entity-localstorage";
import {FeedItemRichBlocks} from "../rich-block";
import ModeratorIcon from "../../old/moderator-icon";
import {ActivityPostItem} from "../activity-item";
import {api_likePost} from "../../../../api/community/posts/like";
import {api_unlikePost} from "../../../../api/community/posts/unlike";
import {formatFileSize} from "../../old/file-uploader/helpers";
import {AnnotationIcon, HeartIcon} from "@heroicons/react/outline";
import {api_unlikeReply} from "../../../../api/community/replies/unlike";
import {api_likeReply} from "../../../../api/community/replies/like";
import {ReplyModal} from "../../../../app/community/posts/reply-modal";
import {ViewPostLikes} from "../../../posts/view-post-likes";
import {ViewDraftJsContent} from "../../../../app/community/posts/view-content";

function Left({
                  onAuthorClick=()=>{},
                  default_profile_picture = "",
                  author_image = DEFAULT_USER_PHOTO
              }) {

    const final = author_image ? author_image : (default_profile_picture ? default_profile_picture : DEFAULT_USER_PHOTO);

    return <div className="h-10">
        <Avatar onClick={onAuthorClick} size="md" type="member" url={`${final}_medium?alt=media`}/>
    </div>
}

function Context({data, id, subject, onContextClick}) {

    // <span>&nbsp;in&nbsp;</span>
    return <span className="pl-1 items-baseline space-x-0.5 inline-flex">
        <div style={{height: '11px'}} className="h-svg-3 text-gray-500">
            <ChevronRightIcon/>
        </div>
        <div onClick={(e) => {
            e.stopPropagation();
            onContextClick(`group/${id}`);
        }}
             className={`hover:underline font-semibold cursor-pointer`}>{data.name}</div>
    </span>
}

function AuthorItem({data, subject, onAuthorClick}) {

    return <span className={`font-semibold hover:underline cursor-pointer`}
                 onClick={(e) => {
                     e.stopPropagation();
                     onAuthorClick(e,data.handle);
                 }}>{data.name}</span>
}

export function FeedItemAuthor({
                                   formatted_date = {},
                                   onAuthorClick = () => {
                                   },
                                   author_data,
                                   series,
                                   history,
                                   read = false,
                                   show_series = true,
                                   show_context = true,
                                   onContextClick = () => {
                                   },
                                   handleGoToSeries = () => {
                                   },
                                   context_type = "",
                                   context_id = "",
                                   community_handle = "",
                                   subject = "",
                                   community_uid = "",
                                   series_name = "",
                                   author_type = "",
                                   author_id = "",
                                   context_name = ""
                               }) {

    const has_series = !!series;
    return <div className="h-4 text-gray-800 leading-4 flex items-baseline"
                style={{marginTop: '', fontSize: '14px'}}>

        <AuthorItem subject={subject} data={author_data} onAuthorClick={onAuthorClick}/>
        {context_id && show_context && context_type === 'group' && typeof context_id === 'string' &&
        <EntityInfo type={`${context_type}`} id={context_id} community_uid={community_uid}>
            <Context subject={subject} id={context_id} onContextClick={(p) => {
                history.push(`/${community_handle}/${p}`);
            }}/>
        </EntityInfo>}
        {formatted_date.relative&&<span style={{fontSize:'13px'}} className="pl-1.5 text-gray-500">·</span>}
        {formatted_date.relative&&<span  style={{fontSize:'13px'}} className="pl-1.5 text-gray-500">
            {formatted_date.relative}
        </span>}
    </div>
}

function AttachmentItem(props) {
    return <Attachment {...props} size={formatFileSize(props.size)}/>
}

export function FeedItemAttachments({
                                        item_actions,
                                        attachments = []
                                    }) {
    if (attachments.length === 0) {
        return null;
    }

    const files_set = attachments;
    return <div className="mt-1.5">
        {attachments.map((a, k) => <AttachmentItem
            key={k}
            onClick={(e) => {
                if (a.id && item_actions.openFileViewer) {
                    e.preventDefault();
                    e.stopPropagation();
                    item_actions.openFileViewer(a.id, files_set);
                }
            }}
            {...a}
        />)}
    </div>
}

function Subject({subject, line_clamp, read}) {
    return <div style={{fontSize: '15px'}}
                className={`text-gray-900 pt-0.5 hover:underline font-bold ${line_clamp}`}>{subject}</div>
}

export function FeedBodyPreview({preview_text, block_preview_count, block_count, content_state, read, has_subject, line_clamp}) {
    if(content_state) {
        return <div className="">
            <ViewDraftJsContent block_preview_count={block_preview_count} block_count={block_count} classes='prose prose-base text-gray-800' style={{}} content_state={content_state}/>
        </div>
    }
    return <div style={{lineHeight: 1.4, fontSize: '15px'}}
                className={`text-gray-700 ${has_subject ? "pt-px" : ""} ${line_clamp}`}>{preview_text}</div>
}

const max_height = 40;
const max_width = max_height * 1.5475;

function ImageItem({
                       url, height, onClick = () => {
    }, width
                   }) {
    const ar = height === width ? 'square' : (height > width) ? 'portrait' : 'landscape';

    let h, w;

    if (ar === 'square') {
        h = max_height;
        w = max_height;
    } else if (ar === 'portrait') {
        h = max_height;
        w = (max_height / height) * width;
    } else {
        w = max_width;
        h = (max_width / width) * height;
    }

    return <div
        onClick={onClick}
        className={`rounded-md bg-gray-200`}
        style={{
            height: `${h}px`,
            width: `${w}px`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundImage: `url(${url})`
        }}
    >

    </div>
}

export function FeedItemImages({
                                   item_actions = {},
                                   images = []
                               }) {
    if (images.length === 0) {
        return null;
    }
    const len = images.length > 4 ? 4 : images.length;
    const spl = [...images].slice(0, len);

    //    {spl.map((it,k)=><ImageItem key={k} {...it} />)}

    return <div className="flex mt-1.5 space-x-1.5">
        {spl.map((it, k) => <ImageItem
            key={k}
            {...it}
            onClick={(e) => {
                if (item_actions.openImageViewer) {
                    e.preventDefault();
                    e.stopPropagation();
                    item_actions.openImageViewer(it.url, "#000000", images.map(a => {
                        return {
                            ...a,
                            bg_color: "#000000"
                        }
                    }));
                }

            }}
        />)}
    </div>
}

function Content({
                     read = true,
                     is_mobile = false,
                     content_state = null,
                     attachments = [],
                     item_actions = {},
                     images = [],
                     rich_blocks = [],
                     subject = "",
                     preview = "..."
                 }) {

    //     <FeedItemImages item_actions={item_actions} images={images}/>
    return <div className=" pb-0.5">
        <div className="">
            <FeedBodyPreview content_state={content_state} has_subject={!!subject} read={read} line_clamp={is_mobile ? "line-clamp-1" : "line-clamp-3"}
                         preview_text={preview}/>
        </div>
        <FeedItemAttachments item_actions={item_actions} attachments={attachments}/>

        <FeedItemRichBlocks rich_blocks={rich_blocks}/>
    </div>
}

function Right({
                   formatted_date = {},
                   read = false,
                   starred = false,
               }) {

    return <div className="flex pr-1 flex-col items-end">

    </div>
}

function Replies({
    disable_actions,
                     layout='feed',
                     type='post',
                     community_uid = "",
    parent_post_id = "",
    post_id = "",
                     init_count = 0
                 }) {
    const [count, setCount] = useState(init_count);
    const [reply, setReply] = useState(null);

    function handleAction() {
setReply("reply");
    }
    return <div style={{fontSize: '13px'}} className={`"text-gray-500 space-x-1.5 ${layout==='post'?"justify-center":""} flex items-center"`} onClick={(e) => {
        e.stopPropagation();
        if(disable_actions) {
            return;
        }
        handleAction()
    }}>
        <ReplyIcon className={`${layout==='post'?"h-svg-6":"h-svg-5"} -m-1 p-1 cursor-pointer rounded-full`}>
            <AnnotationIcon/>
        </ReplyIcon>
        {count > 0 && layout==='feed'&& <div className="pt-px text-gray-500 flex items-center">{`${count}`}</div>}

        {reply&&<ReplyModal community_uid={community_uid} onClose={(e)=> {
             if(e) {
                e.stopPropagation();
            }
            setReply(null)
        }} reply_to_parent_id={parent_post_id} reply_to_type={type} reply_to_id={post_id} />}
    </div>
}

const ReplyIcon = styled.div`
  svg path {
    color: ${props => props.replied ? "#4DB0F1" : "#536471"};
    stroke-width: 1.5px;
    fill: ${props => props.replied ? "#4DB0F1" : ""};
  }

  :hover {
    background: ${props => props.replied ? "#E1EEF6" : "#E1EEF6"};
  }

  :hover svg path {
    stroke-width: 1.5px;
    fill: ${props => props.replied ? "" : "#E1EEF6"};
    stroke: ${props => props.replied ? "" : "#4DB0F1"};
  }
`;

const LikeHeart = styled.div`
  svg path {
    color: ${props => props.liked ? "#dd300f" : "#536471"};
    stroke-width: 1.5px;
    fill: ${props => props.liked ? "#dd300f" : ""};
  }

  :hover {
    background: ${props => props.liked ? "#f1d6cf" : "#f1d6cf"};
  }

  :hover svg path {
    stroke-width: 1.5px;
    fill: ${props => props.liked ? "" : "#f1d6cf"};
    stroke: ${props => props.liked ? "" : "#dd300f"};
  }
`;

function Likes({community, disable_actions, parent_post_id="", type, post_id, layout='feed', init_liked = false, init_count}) {
    const [liked, setLiked] = useState(init_liked);

    const [count, setCount] = useState(init_count);
    function handleAction() {
        if (liked) {
            setLiked(false);
            setCount(count - 1);
            if(type==="reply") {
                api_unlikeReply(community, parent_post_id, post_id)
                    .then(() => {

                    })
            } else {
                api_unlikePost(community, post_id)
                    .then(() => {

                    })
            }
        } else {
            setLiked(true);
            setCount(count + 1);
            if(type==="reply") {
                api_likeReply(community, parent_post_id, post_id)
                    .then(() => {

                    })
            } else {
                api_likePost(community, post_id)
                    .then(() => {

                    })
            }

        }
    }

    return <div style={{fontSize: '13px'}} className={`text-gray-500 ${layout==='post'?"justify-center":""} space-x-1.5 flex items-center`} onClick={(e) => {
        e.stopPropagation();
        if(disable_actions) {
            return;
        }
        handleAction()
    }}>
        <LikeHeart liked={liked} className={`${layout==='post'?"h-svg-6":"h-svg-5"} cursor-pointer p-1 -m-1 rounded-full`}>
            <HeartIcon/>
        </LikeHeart>
        {count > 0 && layout==='feed' && <div className="pt-px ">{`${count}`}</div>}

    </div>
}

export function PostItemMeta({post_id, disable_actions, parent_post_id="", type="post", community, layout="feed", likes_count, likes_members, replies_count}) {

    const init_liked = likes_members ? likes_members[community.member_id] : false;
    const init_likes_count = likes_count ? likes_count : 0;
    const init_replies_count = replies_count ? replies_count : 0;

    return <div className="grid gap-4 grid-cols-4 pt-1 pb-0.5">
        <Likes disable_actions={disable_actions} community_uid={community.uid} parent_post_id={parent_post_id} type={type} post_id={post_id} community={community} layout={layout} init_count={init_likes_count} init_liked={init_liked}/>
        {type==='post'&&<Replies disable_actions={disable_actions} community_uid={community.uid} parent_post_id={parent_post_id} type={type} post_id={post_id} community={community} layout={layout} init_count={init_replies_count}/>}
    </div>
}

const Wrapper = styled.div`
  grid-template-columns: auto minmax(320px,1fr) auto;
`;

function LoadingMock() {
    return <Wrapper className={`grid gap-2 px-1.5 py-2`}>
        <div>
            <Avatar text={' '} size="sm" type="member"/>
        </div>
        <div>
            <div>
                <div className="bg-gray-300 mt-0.5 rounded-md h-2 w-1/5"/>
            </div>
            <div>
                <div className="bg-gray-400 mt-1.5 rounded-md h-2.5 w-1/3"/>
            </div>
            <div>
                <div className="bg-gray-300 mt-2 rounded-md h-2 w-1/2"/>
            </div>
        </div>
        <div>
            <div>
                <div className="bg-gray-200 rounded-md h-2 w-5"/>
            </div>
        </div>
    </Wrapper>
}

const IconContainer = styled.div`

  > div {
    height: 14px;
    width: 14px;

    > svg {
      height: 14px;
    }
  }
`;

function MetaRow({
                     icon = <EyeIcon/>,
                     text = "Only visible to you"
                 }) {

    return <>
        <div className="flex justify-end h-3.5 items-center" style={{minWidth: '32px'}}>
            <IconContainer className="h-svg-3 flex items-center h-3.5 text-gray-500">
                {icon}
            </IconContainer>
        </div>
        <div style={{fontSize: '11px'}} className="text-gray-500 h-3.5 font-medium">
            {text}
        </div>
        <div/>
    </>
}

function getMetaRowInfo(visibility) {

    if (visibility && visibility === 'mods') {
        return {
            icon: <ModeratorIcon height={'14px'}/>,
            text: 'Visible for Moderators only'
        }
    } else if (visibility && visibility === 'admins') {
        return {
            icon: <ChevronDoubleUpIcon/>,
            text: 'Visible for Admins only'
        }
    }

    return null;
}

function StandardPost({
                          read,
                          item_actions = {},
                          is_mobile,
                          preview,
                          attachments,
                          content_state,
                          rich_blocks,
                          images,
                          subject
                      }) {

    return <Content
        read={read}
        is_mobile={is_mobile}
        item_actions={item_actions}
        content_state={content_state}
        preview={preview}
        attachments={attachments}
        rich_blocks={rich_blocks}
        images={images}
        subject={subject}
    />
}

function PostAuthorData({data, threaded, onAuthorClick=()=>{}, subject, is_mobile, read, post_props, default_profile_picture}) {


    const {onItemClick, handleGoToSeries} = post_props;

    return <Wrapper onClick={() => {
        if (onItemClick) {
            onItemClick();
        }
    }} className={`grid relative gap-2.5 ${!(onItemClick) ? "" : "cursor-pointer"} `}>
        <Left onAuthorClick={onAuthorClick} default_profile_picture={default_profile_picture} author_image={data.image}/>
        <div>
            <FeedItemAuthor onAuthorClick={onAuthorClick} author_data={data} {...post_props}/>
            {subject &&
            <Subject read={read} line_clamp={is_mobile ? "line-clamp-1" : "line-clamp-2"} subject={subject}/>}
            {post_props.children}
        </div>
        <Right {...post_props}/>
        {threaded&&<div className="absolute" style={{left:'2.5rem', top: '3.4rem', width: '2px', bottom: '-0.4rem', backgroundColor: '#cbcbcb'}}>
        </div>}
    </Wrapper>
}

export function FeedItemPostContainer(props) {
    const {
        meta_row, onItemClick = null, history, threaded, community_handle, subject, read, is_mobile
    } = props;
    const bg = `bg-white ${threaded?"":"feed-item-bg"}`;
    const header = {
        author: {
            type: "",
                id: "",
                link: `/${subject}/member-id/${subject}`,
        },

        entity: {
            type: "",
                id: "",
                link: `/${subject}/group/${subject}`,
        },

        message: {
            type: 'sent-group-message'
        },
    };
    /*
    <NFIHeader actions={actions} context={context} created_at={created_at} {...header} />
     */
    return <div className={`${props.type==='activity'?"py-3 px-4":""}`}>
        <EntityInfo type={`${props.author_type||"member"}`} id={props.author_id} community_uid={props.community_uid}>
            <PostAuthorData onAuthorClick={(e) => {
                if(e) {
                    e.stopPropagation();
                }

                history.push(`/${community_handle}/member-id/${props.author_id}`);
            }} threaded={threaded} onItemClick={onItemClick} subject={subject} is_mobile={is_mobile} read={read}
                            default_profile_picture={props.activity_meta.community.default_profile_picture}
                            post_props={props}/>
        </EntityInfo>
    </div>
}

function StandardPostItem({
                              history,
                              activity_meta = {},
                              loading = false,
                              page_ref = "group",
                              formatted_date = {},
                              is_mobile = true,
                              show_series = true,
                              show_context = true,
                              read = true,
                              archive_page = false,
                              disable_actions = false,
                              starred = false,
                              threaded = false,
                              subject = "",
                              activity_type = "",
                              content_state = null,
                              item_actions = {},
                              series = '',
                              series_name = "",
                              preview = "",
                              author_name = "",
                              community_handle = "",
                              community_uid = "",
                              author_image = "",
                              author_type = "",
                              id = "",
                              author_id = "",
                              parent_post_id = "",
                              context_id = "",
                              context_type = "",
    padding = "",
                              context_link = "",
                              type = "post",
                              rich_blocks = [],
                              attachments = [],
                              images = [],
                              published_at = Date.now(),
                              likes_members = {},
                              likes_count = 0,
                              replies_count = 0

                          }) {

    const meta_row = getMetaRowInfo("");

    const author_meta = {
        series,
        formatted_date,
        read,
        community_handle,
        author_image,
        author_type,
        author_name,
        author_id,
        show_context,
        community_uid,
        activity_meta,
        starred,
        history,
        context_type,
        context_id,
        context_link,
        onContextClick: () => {
        }
    }

    function handleGoToSeries(e) {
        e.stopPropagation();
        if (series) {
            history.push(`/${community_handle}/group/${context_id}/series/${series}`);
        }
    }

    return <div className={`${padding}`}>
        <FeedItemPostContainer threaded={threaded} history={history} is_mobile={is_mobile} subject={subject} show_series={show_series}
                                  handleGoToSeries={handleGoToSeries} series={series} series_name={series_name}
                                  author_image={author_image} {...author_meta} onItemClick={() => {
        if (page_ref === 'home') {
            history.push(`/${community_handle}/post/${id}?ref=home`);
        } else {
            history.push(`/${community_handle}/post/${id}`);
        }
    }} meta_row={meta_row} community_handle={community_handle} activity_meta={activity_meta}>

        <StandardPost read={read}
                      is_mobile={is_mobile}
                      item_actions={item_actions}
                      preview={preview}
                      content_state={content_state}
                      attachments={attachments}
                      rich_blocks={rich_blocks}
                      images={images}
                      subject={subject}/>

        <PostItemMeta type={type} community={activity_meta.community} post_id={id} likes_members={likes_members}
                      likes_count={likes_count} parent_post_id={parent_post_id} disable_actions={disable_actions}
                      replies_count={replies_count} can_reply={false}/>

    </FeedItemPostContainer>
    </div>
}

export function FeedItem(props) {
    const {
        loading,
        type,
        archive_page
    } = props;
    const history = useHistory();

    if (loading) {
        return <LoadingMock/>
    }

    if (type === 'activity') {
        return <div className="border-b border-gray-200">
            <ActivityPostItem history={history} {...props} />
        </div>
    }

    return <div className="border-b border-gray-200">
        <StandardPostItem history={history} {...props} />
    </div>
}