export const organization_types = [
    {
        text: "Association",
        value: "association"
    },
    {
        text: "Company",
        value: "company"
    },
    {
        text: "Educational",
        value: "education"
    }
];

export const organization_types_object = {
    "association": 'Association',
    "company": 'Company',
    "education": 'Educational'
};