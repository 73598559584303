import React, {useState, useContext} from 'react';
import CommonCard from "../../../../library/components/old/card";
import {PlusIcon} from '@heroicons/react/solid';
import {Button} from "../../../../library/components/core";
import {AddEducationModal} from "./add";

import {authFetch} from "../../../../config/network";
import {GlobalContext} from "../../../global/global-context";
import {CommunityContext} from "../../community-context";
import {ExperienceHistory} from "../../../../library/components/reorganize/experience-history";
import {getSectionVisibilityNote} from "../../../../library/components/old/edit-fields-section";
import {useIsMobile} from "../../../global/global-router";
import {ProfileFieldsContentSection} from "../../../../library/components/edit-profile-mobile";

function validateEducation(obj) {
    let to_return = Object.assign({},obj);

    if(obj.org_data.title==='') {
        to_return.org_data.title = '-';
    }

    return to_return;
}

export function MemberEducation({can_add, education, my_profile=false,visibility='all-members', cb, user_uid}) {
    const global = useContext(GlobalContext);
    const community = useContext(CommunityContext);
    const [modal, setModal] = useState(null);
    const [loading, setLoading] = useState(null);
    const is_mobile = useIsMobile();

    function addEducation(ed,id) {
        setLoading('add');
        const payload = {
            education: validateEducation(ed),
            community_uid: community.uid,
            user_uid: user_uid,
            education_id: id
        };

        const res = async () => {
            cb();
            setTimeout(() => {
            global.addToast({text:'Education Added',intent:'success'});
            setLoading(null);
            setModal(null);
            }, 1500);
        };

        authFetch('/members/education-add', res, res, "POST", {payload});
    }

    function editEducation(ed,id) {
        setLoading('edit');
        const payload = {
            education: validateEducation(ed),
            community_uid: community.uid,
            user_uid: user_uid,
            education_id: id
        };

        const res = async () => {
            cb();
            setTimeout(() => {
            global.addToast({text:'Education Updated',intent:'success'});
            setLoading(null);
            setModal(null);
            }, 1500);
        };

        authFetch('/members/education-edit', res, res, "POST", {payload});
    }

    function deleteEducation(id) {
        setLoading('add');
        const payload = {
            community_uid: community.uid,
            user_uid: user_uid,
            education_id: id
        };

        const res = async () => {
            cb();
            setTimeout(() => {
            global.addToast({text:'Education Deleted',intent:'success'});
            setLoading(null);
            setModal(null);
            }, 1500);
        };

        authFetch('/members/education-delete', res, res, "POST", {payload});
    }

    let actions = can_add ? {onClick: () => setModal('add-education'),label:'Add'} : null;

;    return <ProfileFieldsContentSection block={true} add={actions} layout={is_mobile?"":"desktop"} section={{autocomplete:[]}} visibility={visibility} title="Education">
        <ExperienceHistory type='education' can_add={can_add} loading={loading} editEducation={editEducation} deleteEducation={deleteEducation} modal={modal} updateModal={(a)=>{console.log('modal',a);setModal(a)}} data={education} />
        {modal === 'add-education' &&
        <AddEducationModal loading={loading==='add'} onAdd={addEducation.bind(this)} onClose={() => setModal(null)}/>}

    </ProfileFieldsContentSection>
}