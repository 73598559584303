export function getGreetingFromUserTimezone() {
    let today = new Date();
    let curHr = today.getHours();

    if (curHr < 12) {
        return 'good_morning';
    } else if (curHr < 18) {
        return 'good_afternoon';
    } else {
        return 'good_evening';
    }
}