import React, {useContext, useLayoutEffect} from "react";
import LandingFrame from "../../../app/landing/layout";
import AppFrame from "./frame";
import {UserSuspended} from "../../../app/global/states/suspended";
import {UserArchived} from "../../../app/global/states/archived";
import {GlobalContext} from "../../../app/global/global-context";
import {useLocation} from "react-router-dom";
import {useIsMobile} from "../../../app/global/global-router";
import {LatestFrame} from "../latest-frame";

function guessLayout(route,parts,ctx) {

    if(route) {
        return route.layout;
    }

    if(parts.length<3) {
        return 'app';
    }

    if(parts[1]==='policies') {
        return 'landing';
    }

    if(parts[1]==='preferences') {
        return 'app';
    }

    if(parts[2]==='sign-in') {
        return 'none';
    }

    if(ctx==='global'&&parts.length>3) {
        return 'none';
    }

    if(ctx==='community'&&parts.length>3) {
        return 'app';
    }

    return 'none';
}

function getFinalPath(path) {
    const sp = path.split("/");

    if(sp.length===3&&(sp[2]==='sign-in'||sp[2]==='join'||sp[2]==='forgot-password')) {
        return `/:handle/${sp[2]}`
    } else {
        return path;
    }
}

export default function AppLayout(props) {
const location = useLocation();
    const global = useContext(GlobalContext);
    const is_mobile = useIsMobile();

    const path = location.pathname;

    const final_path = getFinalPath(path);

    const route = props.routes.filter(obj => {
        return obj.path === final_path
    })[0];

    const parts = path.split('/');

    const layout = guessLayout(route,parts,props.context);

    const styles = {
        topbar: {
            bg: "#0A66C2",
            border: "#bcd4e8"
        },
        body: {
            bg: "#F6F8FA",
            content: "#ffffff",
        }
    };

    if (layout === 'landing') {
        return <LandingFrame {...props}>
            {props.children}
        </LandingFrame>
    } else if(layout==='app') {
        return <AppFrame {...props} {...props.app_frame_config} styles={styles}>
            {props.children}
        </AppFrame>
    } else if (layout === 'none') {
        return props.children;
    } else if (layout === 'handle') {
        return props.children;
    }

    if (global.suspended) {
        return <UserSuspended/>;
    }

    if (global.archived) {
        return <UserArchived/>;
    }

    return <AppFrame {...props} {...props.app_frame_config}>
        {props.children}
    </AppFrame>
}