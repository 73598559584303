import React, {useContext, useEffect, useRef, useState} from 'react';
import {FirebaseDB} from "../../../../config/setup-firestore";
import {FeedItem} from "../../../../library/components/posts-feed/feed-item";
import {InboxPagination} from "../../../../library/components/posts-feed/home-wrapper";
import {CommunityContext} from "../../community-context";
import {getGroupInboxPosts} from "../../../../library/components/posts-feed/api";
import {InlineLoader} from "../../../../library/components/core";
import {useIsMobile} from "../../../global/global-router";
import {GlobalContext} from "../../../global/global-context";
import {api_recordPostImpressions} from "../../../../api/community/posts/record-impressions";
import useInfiniteScroll from "../../../../library/playground/advanced-table/use-infinite-scroll";
import {useHistory} from "react-router-dom";
import {NFIMockWrapper} from "../../../../library/components/new-feed-item/mock-wrapper";

const per_page = 12;

export function GroupInboxWrapper({
                                      is_moderator,
                                      is_admin,
                                      is_member,
                                      _actions = {},
                                      show_series = true,
                                      composer = null,
                                      series_id = "",
                                      group_id = "",
                                      group = {}
                                  }) {

    if (!group_id) {
        return null;
    }

    const global = useContext(GlobalContext);
    const community = useContext(CommunityContext);
    const community_uid = community.uid;
    const history = useHistory();
    const is_mobile = useIsMobile();
    const [inbox, setInbox] = useState(null);
    const [loading, setLoading] = useState(false);
    const [items, setItems] = useState([]);
    const [page, setPage] = useState(0);
    const has_more = useRef(false);
    const total_posts = useRef(0);
    const [last_page_ref, setLastPageRef] = useState(null);

    const scroll_id = "main-content";
    const [isFetching, setIsFetching] = useInfiniteScroll("infinite", scroll_id, {
        padding: 400,
        container: true
    }, () => {
        if (!loading && has_more.current) {
            handleNextPage();
        }
    });

    function handleNextPage() {
        setPage(page + 1);
        getPosts(inbox, page + 1, true);
    }

    function handlePrevPage() {
        setPage(page - 1);
        getPosts(inbox, page - 1);
    }

    function getPosts(inb, pg, concat) {
        setLoading(true);
        getGroupInboxPosts(community_uid, group_id, series_id, community.member_id, last_page_ref, pg, inb, per_page)
            .then(result => {
                has_more.current = (items.length+result.posts.length) < total_posts.current;
                if(concat) {
                    setItems([...items].concat(result.posts));
                } else {
                    setItems(result.posts);
                }
                setLoading(false);
                setLastPageRef(result.last_ref);
                setIsFetching(false);
                api_recordPostImpressions(community, result.posts.map(it => it.id)).then(() => {
                });
            })
    }

    useEffect(function () {
        let listener = FirebaseDB
            .collection('community_content')
            .doc(community_uid)
            .collection('inboxes')
            .doc(`group-${group_id}`)
            .onSnapshot((doc) => {
                if (doc.exists) {
                    setInbox(doc.data());
                    total_posts.current = doc.data().total_count;
                    if (page === 0) {
                        getPosts(doc.data(), 0);
                    }
                } else {
                    setInbox({
                        unread: 0,
                        total_count: 0,
                        last_activity: null
                    });
                }
            })

        return () => listener();
    }, []);

    const final_items = !items ? [] : items.map(it => {
        let obj = {...it};
        if (!obj.author_image) {
            obj.author_image = community.default_member_profile_picture;
        }
        return obj;
    })

    const item_actions = {
        goToPath: (p)=>{
            history.push(p)
        },
        openFileViewer: global.openFileViewer,
        openImageViewer: global.openImageViewer,
    }

    const activity_meta = {
        group,
        community,
        is_moderator,
        is_member,
        _actions,
        is_admin
    };

    const context = {
        community_uid: community_uid,
        community
    };

    /*
      {inbox && final_items.map(it => {
                return <FeedItem show_series={show_series} item_actions={item_actions} is_mobile={is_mobile} id={it.id}
                                 community_handle={community.data.handle} show_context={false}
                                 community_uid={community.uid} formatted_date={it.formatted_date} read={it.seen}
                                 key={it.id} activity_meta={activity_meta} {...it.data} />
            })}
     */
    return <>
        {composer && <div className="">
            {composer}
        </div>}
        <div className={`${is_mobile ? '-mt-px' : ``} `}>
            {(!inbox || (inbox.total_count > 0 && final_items.length === 0)) && <>
                <FeedItem key="1" loading/>
                <FeedItem key="2" loading/>
                <FeedItem key="3" loading/>
            </>}
            {inbox && items.length === 0 && inbox.total_count === 0 &&
            <div className="p-4 text-gray-500 text-sm text-center">
                No messages found
            </div>}
            {inbox&&final_items&&<NFIMockWrapper show_context={false} activity_meta={activity_meta} debug={false} actions={item_actions} context={context} messages={final_items} />}

            {inbox && final_items.length > 0 &&
            <InboxPagination per_page={per_page} onNextPage={handleNextPage} onPrevPage={handlePrevPage}
                             total={inbox.total_count} start={page * per_page}/>}
        </div>
    </>
}