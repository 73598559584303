import React, {useState} from "react";
import styled from "styled-components";

const Tag = styled.div`
    display: inline-flex;
        padding: 2px 5px;
    font-size: 11px;
    font-weight: 700;
    border-radius: 6px;
    letter-spacing: 0.75px;
    color: ${props => props.color};
    background-color: ${props => props.bkg};
`;

function getColor(intent) {
    switch (intent) {
        case 'success' : {
            return '#0F6245';
        }
        case 'info' : {
            return '#ffffff';
        }
        case 'error' : {
            return '#E01E5A';
        }
        case 'warning' : {
            return '#E47401';
        }
        default : {
            return '#697487';
        }
    }
}

function getBkg(intent) {
    switch (intent) {
        case 'success' : {
            return '#CBF4CA';
        }
        case 'info' : {
            return '#1B1F36';
        }
        case 'error' : {
            return '#fcdee5';
        }
        case 'warning' : {
            return '#FEF7E0';
        }
        default : {
            return '#F7F8FA';
        }
    }

}

export const StandardTag = ({text,intent='standard'}) => {

    return (
       <Tag color={getColor(intent)} bkg={getBkg(intent)}>
           {text}
       </Tag>
    );
};