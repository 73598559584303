// section can also be community
const div = '/';
export function setDocumentTitle(page,section) {
    document.title = `${page?`${page} `:''}${section?`${div} ${section}`:''}`;
}

export function setFavicon(logo_url,emoji=false) {
    if(!logo_url) {
        return;
    }
    let links = document.querySelectorAll("link[rel~='icon']"), link;
    if (!links) {
        link = document.createElement('link');
        link.rel = 'icon';
        document.getElementsByTagName('head')[0].appendChild(link);
        links = [link];
    }
    if(links.length>0) {
        for(let i=0;i<links.length;i++) {
            const l = links[i];

            if(emoji&&logo_url.indexOf('https://')===-1) {
                l.href = `data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100"><text y=".9em" font-size="90">${logo_url}</text></svg>`;
            } else if(l.sizes[0]==='16x16') {
                l.href = logo_url.replace('32x32','16x16');
            } else {
                l.href = logo_url;
            }

        }
    }
}