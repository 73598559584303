function buildAllYears() {
    const n = 110;
    const in_2_years = new Date().getFullYear() + 2;
    let a = [];
    for(let i=0;i<n;i++) {
        const year = in_2_years-i;
        a.push({
            text: year,
            value: year
        });
    }
    return a;
}

export const years_array = buildAllYears();