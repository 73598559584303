import React, {useState} from "react";
import {Button, Field, PinEntry} from "../../../library/components/core";
import {CheckIcon} from "@heroicons/react/solid";
import {externalAuthFetch} from "../../../config/network";

export function VerifyAccountPin({
    username = "",
    onSuccess = () => {},
                                 }) {
    const [code,setCode] = useState('');
    const [loading,setLoading] = useState('');
    const [phase,setPhase] = useState('');

    function sendCode() {
        setLoading('sending-code');

        const res = (resp) => {
            setPhase('sent-code');
            setLoading('');
        };

        const payload = {
            username: username
        };

        externalAuthFetch("/send-verification-code", res, res, "POST", payload);
    }

    function resendCode() {
        setPhase('resent-code')
    }

    function handlePinSubmit(c) {
        setCode(c);
verifyCode(c);
    }

    function verifyCode(c) {
        if(loading==='verifying-code') {
            return;
        }

        setLoading('verifying-code');

        const res = (resp) => {
            console.log("RESP",resp);
            if(resp.data.status==='invalid') {
                setPhase('invalid-code')
            } else {
                onSuccess(resp.data.auth_code);
            }
            setLoading('');
        };

        const payload = {
            code: `${c}`,
            username: username
        };

        externalAuthFetch("/check-verification-code", res, res, "POST", payload);
    }

    return <div className="pt-4 space-y-4">
        {!phase&&<div className="space-y-4">
            <div className="text-base text-gray-700">
                Welcome! It looks like this is your first time signing in. To continue we need to verify your email first.
            </div>

            <Button intent='secondary' fill size='large'
                    onClick={sendCode} text={"Send Verification Code"}
                    loading={loading==='sending-code'}/>
        </div>}

        {phase==='invalid-code'&&<div className="space-y-4">
            <div className="text-base text-gray-700">
            That code didn't check out, would you like to try another code?
            </div>

            <Button intent='secondary' fill size='large'
            onClick={sendCode} text={"Send Verification Code"}
            loading={loading==='sending-code'}/>
            </div>}

        {(phase==='sent-code'||phase==='resent-code')&&<div className="space-y-4">
            <Button size="sm" intent="success" minimal text={phase==='resent-code'?"Verification Email Resent":"Verification Email Sent"} icon={<CheckIcon/>} />
            <div className="text-base text-gray-700">
                {phase==='sent-code'&&<div>If you haven't received an email after 2 minutes, you can <a onClick={()=>resendCode()} className="color-link">get a new code</a>.</div>}
                {phase==='resent-code'&&<div>If you still don't received an email, check your spam folder.</div>}
            </div>
            <Field label="Enter the six-digit code">
                <PinEntry len={6} onSubmit={handlePinSubmit} setCode={c=>setCode(c)} />
            </Field>
            <Button intent='secondary' fill size='large'
                    onClick={verifyCode} text={"Continue"} disabled={code.length!==6}
                    loading={loading==='verifying-code'}/>
        </div>}

    </div>
}