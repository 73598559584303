import React, {useState} from "react";
import {AuthContainer, AuthTitle, SplashContainer, SplashInner} from "../../landing/layout/splash-container";

import ForgotPasswordBlock from "./ForgotPasswordBlock";
import {setDocumentTitle} from "../../../library/utilities/set-document-title";


export default function ForgotPassword(props) {

    setDocumentTitle('Forgot Password');
    
    return (
        <SplashContainer>
            <SplashInner>
                <AuthContainer>
                    <AuthTitle>Forgot your Password?</AuthTitle>
                    <ForgotPasswordBlock {...props} />
                </AuthContainer>
            </SplashInner>
        </SplashContainer>
    );
}