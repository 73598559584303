import React from 'react';
import {ArchiveIcon} from '@heroicons/react/solid';
import styled from "styled-components";

const IconBox = styled.div`
> svg {
    height: 16px;
}
`;

const Item = ({icon = <ArchiveIcon/>,label="Archive"}) => {
  return <div className="block">
      <div className="inline-flex cursor-pointer  items-center rounded-md py-1 px-2 bg-black text-white bg-opacity-70 hover:bg-opacity-90 transform transition-transform hover:translate-x-2">
      <IconBox className="">
          {icon}
      </IconBox>
      <div className="text-sm pl-2">{label}</div>
  </div>
  </div>
};

const MainIconBox = styled.div`
> svg {
    height: 28px;
}
`;

export default function SGContextualActionsPage() {

    return <div>
        <div>
            <div className="space-x-1 p-1">
                <div className="p-2 cursor-pointer w-28 rounded-md flex items-center flex-col bg-black bg-opacity-80 hover:bg-opacity-100 text-white">
                    <MainIconBox className="MainIconBox mb-1">
                        <ArchiveIcon/>
                    </MainIconBox>
                    <div className="text-md font-semibold">
                        Archive
                    </div>
                </div>
            </div>
        </div>
        <div className="bg-gray-100">
            <div className="space-y-1">
                <Item />
                <Item />
            </div>
        </div>
    </div>
}