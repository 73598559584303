import React from 'react';
import {CommunityVerificationTag} from "../../../library/components/custom/community-verification-tag";
import {DEFAULT_COMMUNITY_LOGO} from "../../../config/defaults";
import {SubtitleMetaLink} from "../../../library/components/old/subtitle-meta-link";
import {displayOrganizationType} from "../../../library/components/custom/organization-type-selectors";
import {handleProfileSocialFields} from "../../community/member/helpers";

function getCommunityMeta(comm) {
    let arr = [

    ];

    if(comm.about.location) {
        arr.push({
            type: 'new-location',
            value: `${comm.about.location}`
        });
    }

    if(comm.about.founded!=='') {
        arr.push({
            type: 'founded',
            value: comm.about.founded
        });
    }

    arr = arr.concat(handleProfileSocialFields(comm.social));

    return arr;
}

function buildCardMeta(data) {
    let d = data;
    d.short_birthday = '';
    let meta = getCommunityMeta(d);
    return meta;
}

const community_profile_mock = {
    type: 'community',
    appearance: {
        show_color: true,

        cover_photo: "",
        cover_photo_color: "",

        emoji: '', // group only
        event_date: { // event only
            date: 0,
            month: 11
        },

        profile_picture: `${DEFAULT_COMMUNITY_LOGO}`,
        profile_picture_color: ""
    },

    main: {
        title: "Corps Isaria",
        suffix: <CommunityVerificationTag key="verified" size="6" />,
        status: {
            emoji: '',
            text: ''
        },
        subtitle: [
            "Fraternal Association",
            "2.1k Members"
        ],
        description: "Das Corps Isaria gehört zu den ältesten Studentenverbindungen Deutschlands und ist seitdem eine Plattform für Kontakte und Freundschaften.",
        meta: buildCardMeta({
            about: {
              location: 'Munich'
            },
            social: {
                website: '',
                linkedin: '',
                twitter: '',
                facebook: ''
            },
            created_at: Date.now()
        }),
    },

    actions: [

    ],

    rich_meta: {
        primary: null,
        secondary: null,
        tertiary: null
    },

    stats: [

    ]

};

export function buildCommunityProfileCard(data) {

    return {
        type: 'community',

        appearance: {
            show_color: true,

            cover_photo: data.cover_photo,
            cover_photo_color: data.cover_photo_color,

            emoji: '', // group only
            event_date: { // event only
                date: 0,
                month: 11
            },

            profile_picture: data.profile_picture,
            profile_picture_color: data.profile_picture_color
        },

        main: {
            title: data.name,
            suffix: data.plan.account_owner?<CommunityVerificationTag key="verified" size="5" />:<CommunityVerificationTag verified={false} key="verified" size="5" />,
            status: {
                emoji: '',
                text: ''
            },
            subtitle: [
                <SubtitleMetaLink key='type' text={displayOrganizationType(data.about.category,data.about.type,'Organization')} />,
             //   <SubtitleMetaLink key='members' text={'267 Members'} />
            ],
            description: data.about.bio,
            meta: buildCardMeta(data),
        },

        actions: [

        ],

        rich_meta: {
            primary: null,
            secondary: null,
            tertiary: null
        },

        stats: [

        ]
    }
}