import React from 'react';
import {Button, PageHeader} from "../../core";

export default function SGPageHeadingsPage() {

    return <div className="space-y-6">
        <PageHeader title="Title only" />
        <PageHeader title="Title with subtitle" subtitle="Default text" />
        <PageHeader title="Title with divider" subtitle="Default text" divider />
        <PageHeader title="Title with button" action={<>
            <Button text="Update" size="sm" />
            <Button text="Publish" size="sm" intent="secondary" />
        </>} />
    </div>
}