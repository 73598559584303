import React, {useEffect, useState} from "react";
import {NewTooltip} from "../../../../reorganize/new-tooltip";
import {BellIcon} from "@heroicons/react/outline";
import styled from "styled-components";

const HoverDiv = styled.div`
:hover {
  background: ${props=>props.theme.bg_contrast_alpha};
}
`;

export function NotificationsIcon({has_update=false,theme,open,onOpen=()=>{},onClose}) {
    const [active,setActive] = useState(false);
    useEffect(function() {
        setActive(open);
    }, [open]);
    const color = active ? `text-gray-800` : `text-gray-500 hover:text-gray-700`;
    return <NewTooltip margin message="Notifications">
        <HoverDiv theme={theme} onClick={()=>{
        if(active) {
            onClose();
        } else {
            onOpen();
        }
        }} style={{color:theme.bg_contrast_text}} className={`h-8 w-8 inline-flex items-center justify-center rounded-full transition-colors cursor-pointer`}>
        <div className={`transform ${active?`scale-125`:``} relative h-5 w-5 transition-transform`}>
        <BellIcon />
            {has_update&&!active&&<div className="bg-gray-700 pt-px pr-px -top-0.5 -right-0.5 rounded-full h-2.5 w-2.5 absolute border-2 border-white" />}
        </div>
    </HoverDiv>
    </NewTooltip>
}