import React from 'react';
import styled from "styled-components";
import {EntityInfo, getMiniEntity} from "../../../utilities/localstorage/entity-localstorage";
import {DEFAULT_USER_PHOTO} from "../../../../config/defaults";
import {PlusIcon} from '@heroicons/react/solid';
import {Button} from "../../core";
import {NewTooltip} from "../../reorganize/new-tooltip";

const Container = styled.div`

`;

const Item = styled.div`
  background-size: cover;
  background-position: center;
  background-color: #8093a8;
  color: #fff;
`;

const Number = styled.div`
`;

const Text = styled.div`
`;

function getUrl(url) {
    return `${url}_small?alt=media`;
}

const UserImageItem = ({zIndex, data, name, marginRight}) => {
    const img = data && data.image ? data.image : DEFAULT_USER_PHOTO;
    return <Item style={{
            zIndex,
            marginRight,
            backgroundImage: `url(${getUrl(img)})`,
width: 'calc(1.5rem + 4px)',
height: 'calc(1.5rem + 4px)',
        }} className={`rounded-42 border-2 border-white transition-colors group-hover:border-gray-100`}>

        </Item>;
};

const MemberItems = ({community_uid, id1, id2, id3}) => {
    let to_return = [];

    if (id1) {

        to_return.push(
            <EntityInfo key={'id1'} community_uid={community_uid} id={id1} type={'members'}>
                <UserImageItem marginRight={id2 ? "-6px" : ""} key={3} zIndex={3}/>
            </EntityInfo>
        )

    }

    if (id2) {
        let item2 = getMiniEntity('members', id2, () => {
        }, {community_uid});
        if (item2) {
            to_return.push(
                <EntityInfo key={'id2'} community_uid={community_uid} id={id2} type={'members'}>

                    <UserImageItem marginRight={id3 ? "-6px" : ""} key={2} zIndex={2}/>

                </EntityInfo>
            )
        }
    }

    if (id3) {
        let item3 = getMiniEntity('members', id3, () => {
        }, {community_uid});
        if (item3) {
            to_return.push(
                <EntityInfo key={'id3'} community_uid={community_uid} id={id3} type={'members'}>

                    <UserImageItem key={1} zIndex={1}/>
                </EntityInfo>
            )
        }
    }

    return to_return;
};

//<UserAddIcon/>
export function EntitiesPreview({user_uids, openEntities, type_name = "Members", community_uid, simple = false, can_manage}) {

    let keys = !Array.isArray(user_uids) ? Object.keys(user_uids) : user_uids;

    const len = keys.length;

    const bkg = simple ? "" : " group transition-colors rounded-xl border border-gray-300 p-px";

    let content = <div className={` ${simple?"":"hover:bg-gray-100 rounded-xl cursor-pointer"} flex items-center`}>
        <div className={`${bkg} flex items-center`} onClick={() => {
            openEntities()
        }}>
            <MemberItems community_uid={community_uid} id1={keys[0]} id2={keys[1]} id3={keys[2]}/>
            {len > 3 && <Number className="text-gray-500 text-xs font-medium pl-1 pr-2">{len}</Number>}
            {len === 0 && <Text className="text-gray-500 text-xs font-medium px-1.5">No {type_name}</Text>}
        </div>
    </div>;

    if(simple) {
        return content;
    }

    return <div className="flex items-center h-8">
        <NewTooltip message={`${!can_manage?"View":"Manage"} Members`}>
            {content}
        </NewTooltip>
    </div>
}