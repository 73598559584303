import React, {useContext} from 'react';
import {useHistory} from "react-router-dom";
import {GlobalContext} from "../../../app/global/global-context";
import MemberProfile from "../../../app/community/member";
import {XIcon} from "@heroicons/react/solid";

export function RightDetailOverlay({
                                       onClose = () => {
                                       },
                                       id = "",
                                       type = "member"
                                   }) {
    let content;
    const history = useHistory();
    const global = useContext(GlobalContext);

    if(type==='member-preview') {
        if (!id) {
            return null;
        }
        content = <MemberProfile preview scrollable={false} handle={id}/>;
    }

    return <div style={{maxWidth:'450px',}} className="z-99 w-full fixed top-16 rounded-xl shadow-lg overflow-x-hidden right-3 bottom-3 overflow-y-auto bg-white">
        <div className="relative">
        {content}
            <div onClick={() => onClose()}
                 className="z-9999 absolute bg-white top-2 right-2 h-8 flex items-center justify-center transition-opacity hover:bg-opacity-70 cursor-pointer w-8 rounded-full">
                <div className="text-gray-500 h-6 w-6">
                    <XIcon/>
                </div>
            </div>
        </div>
    </div>
}