import React, {useContext} from "react";
import {RichSelect} from "../../../core";
import {GlobalContext} from "../../../../../app/global/global-context";
import {useIntl} from "react-intl";

const language_options = [
    {
        value: 'en',
        label: "English",
        emoji: "🇺🇸"
    },
    {
        value: 'de',
        label: "German",
        emoji: "🇩🇪"
    },
];

export function LanguageSwitcher(props) {
    const global = useContext(GlobalContext);
    const {formatMessage: f} = useIntl();
    const color = `text-gray-600 hover:text-gray-800`;

    function changeLanguage(c) {
        global.setLanguage(c);
    }

    const data = language_options.filter(a=>a.value===global.language)[0];

    const trigger = <div
        className={`h-8 inline-flex pl-2 pr-2.5 space-x-2 items-center justify-center rounded-md bg-gray-200 hover:bg-gray-100 transition-colors cursor-pointer ${color}`}>
        <div className={`h-5 w-5 text-xl flex items-center`}>
            {data.emoji}
        </div>
        <div className="text-sm font-medium">
            {f({id:`languages.${global.language}`})}
        </div>
    </div>;

    return <RichSelect placement={'top-start'} custom_trigger={trigger} onSelect={(v) => {
        changeLanguage(v);
    }} options={language_options} selected={global.language}/>;
}