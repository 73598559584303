import React, {useContext, useState} from 'react';
import {GlobalContext} from "../../global/global-context";
import {CommonLayoutProfile} from "../../../library/components/layout/page/profile";
import {ProfileCard} from "../../../library/components/reorganize/profile-card";
import {buildCommunityProfileCard} from "./build-profile-card";
import {Button, EntityHeader, Tabs} from "../../../library/components/core";
import {ArrowRightIcon, PlusIcon} from "@heroicons/react/solid";
import {StandardPageFramework, StandardPageHeight} from "../../../library/playground/standard-page-framework";
import {PublicCommunityLeaders} from "./tabs/leaders";
import {PublicCommunityGroupsPage} from "./tabs/groups";
import {useHistory} from "react-router-dom";
import {PublicCommunityGroupPage} from "./tabs/group";

export default function GlobalCommunityProfile(props) {
    const global = useContext(GlobalContext);
    const history = useHistory();
    const [tab, setTab] = useState("");
    const {data} = props;

    const {memberships} = global;

    const sidebar = <div></div>;

    let card = buildCommunityProfileCard(data);

    let actions = {};
    let context = {};
    let features = {
        can_manage: false
    };

    let tabs = ["Leaders", "Groups"];

    // tabs.push("About");

    const is_member = memberships.includes(props.data.id);

    if (data.contact_info.email) {
        card.actions.push(<Button key="contact" text='Contact'
                                  onClick={() => window.open(`mailto:${data.contact_info.email}`)}/>);
    }

    if(!global.logged_in) {
        card.actions.push(<Button key="sign-in" text='Sign in' intent="secondary" right_icon={<ArrowRightIcon/>}
                                  onClick={() => props.history.push(`/${props.data.handle}/sign-in`)}/>);
    } else if (is_member) {
        card.actions.push(<Button key="sign-in" text='Sign in' intent="secondary" right_icon={<ArrowRightIcon/>}
                                  onClick={() => props.history.push(`/${props.data.handle}/home`)}/>);
    }

    const urlParams = new URLSearchParams(window.location.search);

    const group_selected = urlParams.get('group');

    if(group_selected) {
        return <PublicCommunityGroupPage id={group_selected} community_uid={props.data.id} />
    }

    let content;

    const selected_tab = tab ? tab : tabs[0]

    if (selected_tab === "About") {
        content = <div>

        </div>
    } else if (selected_tab === "Leaders") {
        content = <div>
            <PublicCommunityLeaders community_uid={props.data.id}/>
        </div>
    } else if (selected_tab === "Groups") {
        content = <div>
            <PublicCommunityGroupsPage community_uid={props.data.id}/>
        </div>
    }

    return <StandardPageFramework content layout="sidebar" sidebar={sidebar}>
        <EntityHeader single onClick={() => {
            const el = document.getElementById("main-content");
            el.scrollTo(0, 0);
        }} title={data.name} actions={null}/>
        <ProfileCard context={context} actions={actions} data={card} features={features}/>
        <div className="sticky px-4 border-b border-gray-200 pt-1.5 top-0 bg-white z-10">
            <div className="-mb-px">
                <Tabs onSelect={t => {
                    setTab(t);
                }} active={selected_tab} tabs={tabs} compact layout="entity"/>
            </div>
        </div>
        <div>
            {content}
        </div>
    </StandardPageFramework>
}