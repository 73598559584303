import React, {useEffect, useRef, useState} from 'react';

export function ComposerSubjectBlock(props) {
    const subjectRef = useRef(null);
    const [editing, setEditing] = useState('subject'); // to, subject
    const [subject, setSubject] = useState(props.subject ? props.subject : '');

    useEffect(() => {
        if(props.autoFocus) {
            setTimeout(function () {
                if (subjectRef.current) {
                    subjectRef.current.focus();
                }
            }, 100);
        }
    }, [props.autoFocus]);

    useEffect(function () {
        props.updateSubject(subject);
    }, [subject]);

    function handleSubjectChange(e) {
        const new_val = e.target.value;
        setEditing('subject');
        setSubject(new_val);
    }

    function handleKeyDown(e) {
        if (e.key === "Tab") {
            e.preventDefault();
            props.focusEditor();
        }
    }

    return <div>
        <input ref={subjectRef} onFocus={() => setEditing('subject')}
               className={`w-full font-semibold text-gray-800 placeholder-gray-500 text-xl `}
               placeholder={"Untitled Message"} onKeyDown={handleKeyDown}
               value={subject} onChange={handleSubjectChange.bind(this)}/>
    </div>
}