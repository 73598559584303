import {FirebaseDB} from "../../../config/setup-firestore";
import {cleanQuery} from "../reorganize/entity-search/new-entity-search";

function checkIfEmailInString(text) {
    const re = /(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/;
    return re.test(text);
}

const data_types = [
    'member',
    'role',
    'group',
    'team',
    'member-type',
    'segment',
    'user',
    'community'
];

export function us_getQueryMeta(query) {
    let contains_email = false, contains_id = false, contains_is = false, is_type = '';

    let a, b, c, d, fq;
    c = query.trim();
    contains_email = checkIfEmailInString(query);
    if (c.indexOf('is:') !== -1) {
        contains_is = true;
        a = c.split('is:');
        d = a[1];
        b = d.split(" ")[0].trim();
        if (data_types.includes(b)) {
            is_type = b;
            fq = c.split(b)[1].trim();
        } else {
            fq = "";
        }
    } else if (c.length === 11 && c.indexOf(" ") === -1) {
        // could be ID
        contains_id = true;
        fq = c
    } else {
        fq = c;
    }

    let query_type = 'standard';

    if(!contains_id&&fq.trim().split(' ').length>1) {
        query_type = 'first-last';
    }

    return {
        final_query: cleanQuery(fq),
        cleaned_query: fq.trim(),
        query_type,
        contains_email,
        contains_is,
        contains_id,
        is_type
    }
}

function getStandardGroupQueries() {
    return [
        {
            type: 'groups',
            field: 'handle'
        },
        {
            type: 'groups',
            field: 'search_name'
        }
    ]
}

function getStandardTeamQueries() {
    return [
        {
            type: 'teams',
            field: 'handle'
        }
    ]
}

function getStandardSmartViewsQueries() {
    return [
        {
            type: 'smart_views',
            field: 'search_name'
        }
    ]
}

function getStandardMemberTypeQueries() {

}

function getStandardSegmentQueries() {
    return [
        {
            type: 'segments',
            field: 'search_name'
        }
    ]
}

function getStandardFilesQueries() {
    return [
        {
            type: 'files',
            field: 'search_name'
        }
    ]
}

function getStandardFoldersQueries() {
    return [
        {
            type: 'folders',
            field: 'search_name'
        }
    ]
}

function getStandardUsersQueries() {
    return [
        {
            type: 'users',
            field: 'handle'
        },
        {
            type: 'users',
            field: 'search_name'
        }
    ]
}

function getStandardMemberQueries(meta) {
    return [
        {
            type: 'members',
            field: 'first_name',
            value: (!meta || meta.query_type === 'standard') ? "" : meta.cleaned_query.trim().split(" ")[0]
        },
        {
            type: 'members',
            field: 'last_name',
            value: (!meta || meta.query_type === 'standard') ? "" : meta.cleaned_query.trim().split(" ")[1]
        },
        {
            type: 'members',
            field: 'handle'
        }
    ]
}

export function us_getAsyncSearches(query, context = ['user'],searchable={}) {
    let queries = [];
    const meta = us_getQueryMeta(query);

    // USER
    if (context.includes('user')) {
        // what if string is email
        if (meta.contains_id) {
            // cannot search users by id
        } else if (meta.is_type === 'user') {
            queries = queries.concat(getStandardUsersQueries())
        } else if (meta.is_type === 'community') {
            queries.push({
                type: 'communities',
                field: 'handle'
            });
            queries.push({
                type: 'communities',
                field: 'search_name'
            });
        } else if (meta.contains_email) {
            // skip
        } else {
            queries = queries.concat(getStandardUsersQueries())
            queries.push({
                type: 'communities',
                field: 'handle'
            });
            queries.push({
                type: 'communities',
                field: 'search_name'
            });
        }

    }

    // MEMBER
    if (context.includes('member')) {
        queries.push({
            type: 'members',
            field: 'email'
        });
      if (meta.contains_id) {
            queries.push({
                type: 'members',
                field: 'docid'
            });
            queries.push({
                type: 'groups',
                field: 'docid'
            });
            queries.push({
                type: 'files',
                field: 'docid'
            });
            queries.push({
                type: 'folders',
                field: 'docid'
            });
            /*
            queries.push({
                type: 'segments',
                field: 'docid'
            });

             */
        } else if (meta.contains_is) {
            if (meta.is_type === 'member') {
                queries = queries.concat(getStandardMemberQueries(meta))
            } else if(meta.is_type==='group') {
                queries = queries.concat(getStandardGroupQueries())
            } else if(meta.is_type==='team') {
              //  queries = queries.concat(getStandardTeamQueries())
            } else if(meta.is_type==='file') {
                queries = queries.concat(getStandardFilesQueries())
            } else if(meta.is_type==='folder') {
                queries = queries.concat(getStandardFoldersQueries())
            } else if(meta.is_type==='role') {

            } else if(meta.is_type==='segment') {
              //  queries = queries.concat(getStandardGroupQueries())
            }
        } else {
            // members
            queries = queries.concat(getStandardMemberQueries(meta));

            // groups
            queries = queries.concat(getStandardGroupQueries());

            //teams are already loaded
          //  queries = queries.concat(getStandardTeamQueries())

            // folders and files
            queries = queries.concat(getStandardFilesQueries());
            queries = queries.concat(getStandardFoldersQueries());

            if (context.includes('admin')) {
              //  queries = queries.concat(getStandardSegmentQueries())
            }
        }
    }

    if (context.includes('group')) {
        queries = queries.concat(getStandardMemberQueries())
        if(searchable&&searchable.filters) {
            queries = queries.concat(getStandardSegmentQueries())
        }
    }

    if (context.includes('group-mods')) {
        queries = queries.concat(getStandardMemberQueries())

    }

    if(context.includes('groups')) {
        queries = queries.concat(getStandardGroupQueries());
    }

    if (context.includes('team')) {
        // this is roles only
    }

    if(context.includes('member-directory')) {
        queries.push({
            type: 'members',
            field: 'email'
        });
        queries = queries.concat(getStandardMemberQueries(meta))
    }

    return {
        meta,
        final_query: meta.final_query,
        async: queries.length > 0,
        queries: queries
    }
}

async function searchForType(query, search_ctx, type, field, extras) {
    let base;
    switch (type) {
        case 'users':
            return await FirebaseDB.collection('search')
                .doc('global')
                .collection('users')
                .orderBy(field)
                .startAt(query.toLowerCase())
                .endAt(query.toLowerCase() + "\uf8ff")
                .limit(4)
                .get()
                .then((snap) => snap.docs.map(doc => {
                    const data = doc.data();
                    return {
                        ...doc.data(),
                        match_str: `${data.first_name} ${data.last_name}`,
                        id: doc.id,
                        data_type: 'user'
                    }
                }));
        case 'members':
            base = FirebaseDB.collection('search')
                .doc(search_ctx)
                .collection('members')
            if(extras&&extras.group_id) {
                base = base.where(`group_ids`,'array-contains', extras.group_id);
            }
            return await base
                .orderBy(field)
                .startAt(query.toLowerCase())
                .endAt(query.toLowerCase() + "\uf8ff")
                .limit(4)
                .get()
                .then((snap) => {
                    return snap.docs.filter(a=>!a.data().archived).map(doc => {
                        const data = doc.data();
                        return {
                            ...data,
                            match_str: `${data.search_name} ${data.first_name} ${data.last_name} ${data.handle} ${data.email}`,
                            id: doc.id,
                            data_type: 'member'
                        }
                    })
                });
        case 'groups':
            return await FirebaseDB.collection('community_entities')
                .doc(search_ctx)
                .collection('groups')
                .orderBy(field)
                .startAt(query.toLowerCase())
                .endAt(query.toLowerCase() + "\uf8ff")
                .limit(4)
                .get()
                .then((snap) => snap.docs.map(doc => {
                    const data = doc.data();
                    return {
                        ...doc.data(),
                        match_str: `${data.name} ${data.search_name} ${data.handle}`,
                        id: doc.id,
                        data_type: 'group'
                    }
                }));
        case 'teams':
            return await FirebaseDB.collection('community_entities')
                .doc(search_ctx)
                .collection('groups')
                .orderBy(field)
                .startAt(query.toLowerCase())
                .endAt(query.toLowerCase() + "\uf8ff")
                .limit(4)
                .get()
                .then((snap) => snap.docs.map(doc => {
                    const data = doc.data();
                    return {
                        ...doc.data(),
                        match_str: `${data.name} ${data.search_name} ${data.handle}`,
                        id: doc.id,
                        data_type: 'group'
                    }
                }));
        case 'smart_views':
            return await FirebaseDB.collection('community_entities')
                .doc(search_ctx)
                .collection('smart_views')
                .orderBy(field)
                .startAt(query.toLowerCase())
                .endAt(query.toLowerCase() + "\uf8ff")
                .limit(4)
                .get()
                .then((snap) => snap.docs.map(doc => {
                    const data = doc.data();
                    return {
                        ...doc.data(),
                        match_str: `${data.search_name}`,
                        id: doc.id,
                        data_type: 'smart_view'
                    }
                }));
        case 'segments':
            return await FirebaseDB.collection('community_entities')
                .doc(search_ctx)
                .collection('collections')
                .orderBy(field)
                .startAt(query.toLowerCase())
                .endAt(query.toLowerCase() + "\uf8ff")
                .limit(4)
                .get()
                .then((snap) => snap.docs.map(doc => {
                    const data = doc.data();
                    return {
                        ...doc.data(),
                        match_str: `${data.search_name}`,
                        id: doc.id,
                        data_type: 'filter'
                    }
                }));
        case 'files':
            return await FirebaseDB.collection('community_entities')
                .doc(search_ctx)
                .collection('files')
                .orderBy(field)
                .startAt(query.toLowerCase())
                .endAt(query.toLowerCase() + "\uf8ff")
                .limit(4)
                .get()
                .then((snap) => snap.docs.map(doc => {
                    const data = doc.data();
                    return {
                        ...doc.data(),
                        match_str: `${data.search_name}`,
                        id: doc.id,
                        data_type: 'file'
                    }
                }));
        case 'folders':
            return await FirebaseDB.collection('community_entities')
                .doc(search_ctx)
                .collection('folders')
                .orderBy(field)
                .startAt(query.toLowerCase())
                .endAt(query.toLowerCase() + "\uf8ff")
                .limit(4)
                .get()
                .then((snap) => snap.docs.map(doc => {
                    const data = doc.data();
                    return {
                        ...doc.data(),
                        match_str: `${data.search_name}`,
                        id: doc.id,
                        data_type: 'folder'
                    }
                }));
        case 'communities':
            return await FirebaseDB.collection('search')
                .doc('global')
                .collection('communities')
                .orderBy(field)
                .startAt(query.toLowerCase())
                .endAt(query.toLowerCase() + "\uf8ff")
                .limit(4)
                .get()
                .then((snap) => snap.docs.map(doc => {
                    const data = doc.data();
                    return {
                        ...doc.data(),
                        match_str: `${data.search_name} ${data.handle}`,
                        id: doc.id,
                        data_type: 'community'
                    }
                }));
        default:
            return [];
    }
}

export function us_asyncSearch(query, search_ctx, meta, original_query, extras) {
    return new Promise(resolve => {
        searchForType(query, search_ctx, meta.type, meta.field, extras)
            .then(results => {
                resolve({
                    query: original_query ? original_query: query,
                    type: meta.type,
                    field: meta.field,
                    items: results
                });
            })
    });
}