import React, {useState} from 'react';
import Modal from "../../../../library/components/old/modal";
import {Button, Field, TextArea} from "../../../../library/components/core";

export function GroupEditPurposeModal({loading,data={},onConfirm,onClose,onCancel}) {
const [purpose,setPurpose] = useState(data.purpose);
    const disabled = false;
    const body = <div>
        <Field label="New Purpose">
            <TextArea id="purpose" value={purpose} onChange={(val)=>setPurpose(val)} placeholder="Start typing.."/>
        </Field>
    </div>;
    return <Modal title={"Edit Purpose"} size='small' body={body} footerRight={<div className="space-x-2 flex">
        <Button onClick={()=>{
            onClose();
        }} text='Cancel' />
        <Button intent='success' loading={loading} disabled={disabled} onClick={()=>{
            onConfirm(purpose,()=>onClose());
        }} text='Change' />
    </div>} footerLeft={null}
                  onClose={onClose} open={true} lazy />
}