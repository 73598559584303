import React, {useState, useEffect, useContext} from 'react';

import {FirebaseDB} from "../../../../config/setup-firestore";
import {GlobalContext} from "../../../global/global-context";
import {CommunityContext} from "../../community-context";
import styled from "styled-components";
import {InfoState, InlineLoader} from "../../../../library/components/core";

const ImageGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
  grid-gap: 0.5rem;
  > div {
    padding: 0.75rem;
    display: grid;
    place-items: center;
    &::before {
      content: "";
      display: block;
      padding-bottom: 100%;
      grid-area: 1 / 1 / 2 / 2;
    }
    img {
      width: 100%;
      // height: 100%;
      // object-fit: contain;
      grid-area: 1 / 1 / 2 / 2;
    }
  }
`;

export function ImagesViewer({images=[],openImageViewer}) {
    return <ImageGrid>
        {images.map(img=>{
            return <div key={img.id}
                        onClick={()=>{
                            openImageViewer(img.url, "#000000", images.map(a=>{
                                return {
                                    ...a,
                                    bg_color: "#000000"
                                }
                            }));
                        }}
                        className={`rounded-md cursor-pointer bg-gray-200`}
                        style={{
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                            backgroundImage: `url(${img.url})`
                        }}
            >

            </div>
        })}
    </ImageGrid>
}

export function GroupMediaTab({group_id, community_uid, history}) {
    const [images, setImages] = useState([]);
    const community = useContext(CommunityContext);
    const global = useContext(GlobalContext);
    const [loading, setLoading] = useState(true);

    useEffect(function () {
        setLoading(true);
        getRecentImages(group_id, community.uid);
    }, [community.uid, group_id]);

    function getRecentImages(id, cid) {
        FirebaseDB
            .collection('community_content')
            .doc(cid)
            .collection('images')
            .where('group_id', '==', id)
            .orderBy('created_at', 'desc')
            .limit(64)
            .get()
            .then((snap) => {
                setImages(snap.docs.map((doc) => {
                    const data = doc.data();
                    return {
                        id: doc.id,
                        ...data
                    }
                }));
                setLoading(false);
            })
    }

    if(loading) {
        return <InlineLoader />
    }

    if (images.length === 0&&!loading) {
        return <div className="p-4">
            <InfoState fill subtitle="No Images or Videos found" />
        </div>;
    }

    return <div className="px-3 py-2">
            <ImagesViewer images={images} openImageViewer={global.openImageViewer} />
    </div>
}