import React, {useContext, useState, useEffect} from 'react';
import {GlobalContext} from "../global/global-context";
import {FirebaseDB} from "../../config/setup-firestore";
import GlobalUserProfile from "./user";
import {OtherUserPage} from "./user/other-user";
import GlobalCommunityProfile from "./community";
import ProfileLoadingCard from "../../library/components/layout/loading/profile";

export function ExternalEntity(props) {
    const [entity_data,setEntityData] = useState({my_profile:false,not_found:false});
    const [loading,setLoading] = useState(true);
    const global = useContext(GlobalContext);
    const handle = props.match.params.handle;

    useEffect(function () {
        if(global.logged_in) {
            if(handle!==global.user.handle) {
// get entity
                getEntity(handle);
            } else {
// it's my profile
                setLoading(false);
                setEntityData({
                    type: 'user',
                    data: global.user,
                    my_profile: true
                })
            }
        } else {
            // get entity
            getEntity(handle);
        }
    }, [handle]);

    function getEntity(h) {
        FirebaseDB
            .collection('handles')
            .doc('global')
            .collection('handles')
            .where('handle', '==', h)
            .where('scope', '==', 'global')
            .limit(1)
            .get()
            .then(snap=>{
                const entity = snap.docs[0].data();
                if(entity.type==='community') {
                    getCommunity(entity.community_uid);
                } else {
                    getUser(entity.user_uid);
                }
            })
            .catch((error)=>{
                console.error('not found',error);
                setLoading(false);
                setEntityData({
                    not_found: true
                });
            });
    }

    function getUser(uid) {
        FirebaseDB
            .collection('users')
            .doc(uid)
            .get()
            .then((doc)=>{
                const data = doc.data();
                setLoading(false);
                setEntityData({
                    type: 'user',
                    data
                })
            })
            .catch((error)=>{
                console.error('not found',error);
                setLoading(false);
                setEntityData({
                    not_found: true
                });
            })
    }

    function getCommunity(cid) {
        FirebaseDB
            .collection('communities')
            .doc(cid)
            .get()
            .then((doc)=>{
                let data = doc.data();
                data.id = cid;
                setLoading(false);
                setEntityData({
                    type: 'community',
                    data
                })
            })
            .catch((error)=>{
                console.error('not found',error);
                setLoading(false);
                setEntityData({
                    not_found: true
                });
            });
    }

    if(loading) {
        return <ProfileLoadingCard/>;
    }

    if(entity_data.not_found) {
        return <div>
            not found
        </div>
    }

    // if is my own profile and is logged in
    if(entity_data.my_profile) {

        return <GlobalUserProfile {...props} {...entity_data} />
    } else if(entity_data.type==='user') {
        return <OtherUserPage {...props} {...entity_data} />
    } else if(entity_data.type==='community') {
        return <GlobalCommunityProfile {...props} {...entity_data} />
    }

    return <div>
        I don't know.
    </div>
}