import React from "react";
import {ChevronDownIcon} from "@heroicons/react/solid";
import {Avatar} from "../../../core";
import styled from "styled-components";

const HoverDiv = styled.div`
  background: ${props=>props.theme.bg_contrast_alpha};
:hover {
  background: ${props=>props.theme.bg_contrast_alpha_hover};
}
`;

export function TopbarUserIcon({name,url,theme}) {
    return <HoverDiv theme={theme} className={`px-1 py-1 rounded-xl cursor-pointer duration-100 transition-colors grid items-center lg:gap-1.5`}
                    style={{gridTemplateColumns: `26px 1fr auto`}}>
        <div className="relative" style={{height: '26px'}}>
            <Avatar size="26px" url={url}/>
        </div>
        <div className="flex-grow hidden lg:flex">
            <div className="flex items-center  rounded-md ">
                <div style={{color:theme.bg_contrast}} className={`text-smbase font-medium line-clamp-1 truncate text-gray-800`}>
                    <span>{name}</span>
                </div>

            </div>
        </div>
        <div className="flex-none -ml-1 pl-0.5 hidden lg:flex">
            <div className="">
                <div style={{color:theme.bg_contrast_text}} className={`h-svg-4  text-gray-600 hover:text-gray-800 cursor-pointer`}>
                    <ChevronDownIcon/>
                </div>
            </div>
        </div>
    </HoverDiv>
}