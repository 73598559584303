import React, {useState, useEffect, useContext} from 'react';
import {ActionWithIcon, SectionHeader, SimpleTable} from "../../../../library/components/core";

import {getFileTypeIcon} from "../../../../library/components/old/file-uploader/helpers";
import {FirebaseDB} from "../../../../config/setup-firestore";
import {LinkIcon,DownloadIcon} from "@heroicons/react/solid";
import {copyTextToClipboard} from "../../../../library/utilities/general/copy-to-clipboard";
import {APP_BASE_URL} from "../../../../config/defaults";
import {GlobalContext} from "../../../global/global-context";
import {CommunityContext} from "../../community-context";

export default function GroupRecentFiles({group_id, community_uid, folder_id, history}) {
    const [files, setFiles] = useState([]);
    const community = useContext(CommunityContext);
    const global = useContext(GlobalContext);
    const [loading, setLoading] = useState(true);

    useEffect(function () {
        setLoading(true);
        getRecentFiles(folder_id, community.uid);
    }, [folder_id, community.uid, group_id]);

    function getRecentFiles(id, cid) {
        FirebaseDB
            .collection('community_entities')
            .doc(cid)
            .collection('files')
            .where('top_level_folder', '==', id)
            .orderBy('updated_at', 'desc')
            .limit(6)
            .get()
            .then((snap) => {
                setFiles(snap.docs.map((doc) => {
                    const data = doc.data();
                    return {
                        id: doc.id,
                        icon: getFileTypeIcon(data.type),
                        type: 'file',
                        data: {
                            link: data.link,
                            folder: data.folder,
                            name: data.name
                        }
                    }
                }));
                setLoading(false);
            })
    }

    if (files.length === 0) {
        return null;
    }

    return <div>
        <SectionHeader title="Recent Files" action={<ActionWithIcon size="xs"
                                                                    onClick={() => history.push(`/${community.handle}/explore/drive/folder/${folder_id}`)} text="View all" inverse/>}/>
        <SimpleTable actions={[{
            label: 'Copy Link',
            icon: <LinkIcon/>,
            types: ['folder', 'file'],
            onClick: (id, entry) => {
                const path = `/${community.handle}/group/${group_id}/drive/folder/${entry.data.folder}/file/${id}`;
                copyTextToClipboard(`${APP_BASE_URL}${path}`);
                global.addToast({text: "Link copied to clipboard!", intent: 'success'});
            }
        },
            {
                label: 'Download',
                icon: <DownloadIcon/>,
                types: ['file'],
                onClick: (id, entry) => {
                    window.open(entry.data.link, '_blank');
                }
            }]} onItemClick={(id, entry) => {

            history.push(`/${community.handle}/group/${group_id}/drive/folder/${entry.data.folder}/file/${id}`)
        }} items={files} headers={[{
            width: '2fr',
            label: 'Name',
            field: 'name',
            title: true
        }]} hide_headers={true} can_select={false}/>
    </div>
}