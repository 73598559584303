import React, {useContext, useRef, useEffect, useState} from "react";
import {ContentState, convertToRaw} from 'draft-js';
import {
    CheckIcon,
    EyeIcon,
    InformationCircleIcon,
    MailIcon,
    MailOpenIcon,
    PaperAirplaneIcon,
    XIcon
} from "@heroicons/react/outline";
import {Button, InlineLoader, Tabs} from "../core";
import {CommunityContext} from "../../../app/community/community-context";
import {QuillIcon} from "../../icons/custom/Quill";
import {composer_handleSendPost} from "./handle-send-post";
import {composer_handleSendInvite} from "./handle-send-invite";
import {GlobalContext} from "../../../app/global/global-context";
import {composer_handleSendDataCheck} from "./handle-send-data-check";
import {composer_handleSendEmail} from "./handle-send-email";
import {useIsMobile} from "../../../app/global/global-router";
import {FullScreenModal} from "../old/full-screen-modal";
import {ComposerShareTab} from "./tabs/share";
import {ComposerWriteTab} from "./tabs/write";
import {ComposerPreviewTab} from "./tabs/preview";
import {ComposerSettingsTab} from "./tabs/settings";
import {composer_merge_tags} from "./merge-tags";
import {NewTooltip} from "../reorganize/new-tooltip";
import {TempTip} from "../../playground/temp-tip";

export const post_type_info_spec = {
    'post': {
        type: 'post',
        success_title: "Your Message was sent!",
        show_email_to: true,
        placeholder: 'Untitled Message',
        label: 'Message',
        content_placeholder: "What's on your mind?",
        action: 'Send Message',
        icon: <div className="h-5 w-5 flex items-center justify-center rounded-md bg-secondary h-svg-3">
            <QuillIcon fill="#ffffff"/>
        </div>
    },
    'data-check': {
        type: 'data-check',
        success_title: "Your Data Check Email was sent!",
        show_email_to: true,
        placeholder: 'Message to members..',
        label: 'Data Check',
        content_placeholder: "What's on your mind?",
        action: 'Send Data Check',
        icon: <div className="h-5 w-5 flex items-center justify-center rounded-md bg-success h-svg-3">
            <CheckIcon fill="#ffffff"/>
        </div>
    },
    'email': {
        type: 'email',
        success_title: "Your Email was sent!",
        show_email_to: true,
        placeholder: 'Subject..',
        label: 'Email',
        content_placeholder: "Compose your message",
        action: 'Send Email',
        icon: <div className="h-5 w-5 flex items-center justify-center rounded-md bg-secondary h-svg-3">
            <MailIcon fill="#ffffff"/>
        </div>
    },
    'invite': {
        label: 'Invite',
        success_title: "Your Invite was sent!",
        show_email_to: false,
        placeholder: 'Invite Subject..',
        type: 'invite',
        content_placeholder: 'Personalize your invite..',
        action: 'Send Invite',
        icon: <div className="h-5 w-5 flex items-center justify-center rounded-md bg-blue-400 h-svg-3">
            <PaperAirplaneIcon fill="#ffffff"/>
        </div>
    },
    'access-details': {
        label: 'Access',
        success_title: "Your message was sent!",
        show_email_to: false,
        placeholder: 'Access details Subject..',
        type: 'access-details',
        content_placeholder: 'Personalize your message..',
        action: 'Send',
        icon: <div className="h-5 w-5 flex items-center justify-center rounded-md bg-blue-400 h-svg-3">
            <PaperAirplaneIcon fill="#ffffff"/>
        </div>
    }
};

export function getImagesFromContentState(cs) {
    let images = [];

    const bma = cs.getBlocksAsArray();

    bma.forEach(cb => {
        const data = Object.fromEntries(cb.getData());

        if (cb.getType() === 'image-with-caption') {
            images.push({
                url: data.url,
                width: data.width,
                name: "",
                height: data.height,
                caption: data.text,
            });
        }
    })

    return images;
}

function uploadFilesToFolder(type) {
    if (type === 'post') {
        // folder id of group...
        return true;
    } else {
        return false;
    }
}

export function prefillContentState(content) {
    if (typeof content === 'string') {
        return ContentState.createFromText(content);
    } else if(content) {
        return content;
    } else {
        return null;
    }
}

function filterMergeTags(message_type, mt) {
    let a = [];

    mt.forEach(sec => {
        if (sec.scope === '*' || sec.scope === message_type) {
            a.push(sec);
        }
    });

    return a;
}

function getMessageContent(message_type, alt) {
    if (message_type === 'group') {
        if (alt) {
            return "Members will only see this message on Unaty."
        }
        return "Members will be sent this message via Email.";
    }
    return "Your message will delivered individually to members via Email.";
}

function getMessageType(message_type, alt) {
    if (message_type === 'group') {
        return 'group-message-send'
    }
    return 'direct-message-send'
}

function getMessageIcon(message_type, alt) {
    if (message_type === 'group') {
        if (alt) {
            return <InformationCircleIcon/>
        }
        return <MailOpenIcon/>;
    }
    return <MailOpenIcon/>
}

function getSendEmailInitial(type, to) {
    if (type === 'group') {
        return !!to[0].email;
    }
    return true;
}

const allowed_email_design = ['group', 'direct', 'email', 'post'];

export default function Composer({
                                     layout, message_type = "direct",
                                     handleClose,
                                     prefill,
                                 }) {
    const [mode, setMode] = useState(prefill.mode ? prefill.mode : "full-screen");
    const type = prefill.type ? prefill.type : 'post';

    const [tab, setTab] = useState("Write");

    const [split_screen, setSplitScreen] = useState(false);

    const is_mobile = useIsMobile();
    const template = prefill.template ? prefill.template : null;
    const folder_id = prefill.folder_id ? prefill.folder_id : "posts";
    const [to, setTo] = useState(prefill.to ? prefill.to : []);
    const [cc, setCC] = useState(prefill.cc ? prefill.cc : []);
    const global = useContext(GlobalContext);
    const community = useContext(CommunityContext);
    const subjectRef = useRef(null);
    const [editing, setEditing] = useState(prefill.to && prefill.to.length > 0 ? 'subject' : 'to'); // to, subject
    const [subject, setSubject] = useState(prefill.subject ? prefill.subject : '');
    const [series, setSeries] = useState(prefill.series ? prefill.series : '');
    const [subtitle, setSubtitle] = useState(prefill.subtitle ? prefill.subtitle : '');
    const [loading, setLoading] = useState('');
    const default_settings = {
        header_style: "default",
        header_custom: "",
        from_name: community.member.name,
        reply_to: community.member.account_email,
        track_delivery: true,
        enable_replies: true
    };
    const [settings, setSettings] = useState({...default_settings});
    const [initial_action, setInitialAction] = useState('');
    const [send_email, setSendEmail] = useState(getSendEmailInitial(message_type, to));
    const [hide_note, setHideNote] = useState(false);
    const [focus_count, setFocusCount] = useState(0);
    const [attachments, setAttachments] = useState({});
    const [selected_template, setSelectedTemplate] = useState(null);
    const [editorState, setEditorState] = useState(null);

    const initial_content = editorState ? editorState.getCurrentContent() : prefillContentState(prefill.content);
    const run = useRef(0);

    useEffect(function () {
        run.current++;
    }, [tab])

    function onClose() {
        if (handleClose) {
            handleClose();
        }
        if (layout && layout === 'group') {
            resetState()
            setMode("share");
        }
        setInitialAction('');
    }

    function resetState() {
        setSubject("");
        console.log("RESET STATE")
        setSeries("");
    }

    function handleUpdateAttachments(na) {
        setAttachments({...na})
    }

    function changeSettings(f, v) {
        setSettings({...settings, [f]: v})
    }

    function loadTemplate(template) {
        if (template.raw) {
            const new_subject = template.subject ? template.subject : "";
            setSelectedTemplate(template);
            setSubject(new_subject);
        }
    }

    function handleSend() {
        if (loading) {
            return;
        }
        const contentState = editorState.getCurrentContent();

        const payload = {
            series,
            community_uid: community.uid,
            member_id: community.member_id,

            message_type,

            cc: cc.filter(a => a.email).map(it => `${it.label ? it.label : it.name} <${it.email}>`).join(','),
            cc_data: cc,

            to: to.filter(a => a.email).map(it => `${it.label ? it.label : it.name} <${it.email}>`).join(','),
            to_data: to,
            send_email,
            settings,
            images: getImagesFromContentState(contentState),
            attachments: Object.entries(attachments).filter(a => a[1].status === 'done').map(ent => {
                return {
                    id: ent[1].id,
                    folder: ent[1].folder,
                    url: ent[1].url,
                    name: ent[1].name,
                    type: ent[1].type,
                    size: ent[1].size
                }
            }),
            subject,
            subtitle,
            raw: convertToRaw(contentState),
            plain_text: contentState.getPlainText()
        };

        setTab("Write");
        setSplitScreen(false);
        setLoading('sending');

        const res = (result) => {
            setLoading('sent');
            global.addToast({intent: "success", text: "Message sent"})
            onClose();
        };

        if (type === 'invite') {
            composer_handleSendInvite(payload, {community, global}, res);
        } else if (type === 'access-details') {
            composer_handleSendInvite(payload, {community, global}, res);
        } else if (type === 'email') {
            composer_handleSendEmail(payload, {community, global}, res);
        } else if (type === 'data-check') {
            composer_handleSendDataCheck(payload, {community, global}, res);
        } else {
            composer_handleSendPost(payload, {community, global}, res);
        }
    }

    const can_send = to.length > 0 && subject.length > 0;

    let content;

    const actions = <div className="flex space-x-2">
        {window.innerWidth > 1200 && <div>
            <NewTooltip usePortal={false} message={`${split_screen ? "Hide Preview" : "Live Preview"}`}>
                <Button icon={<EyeIcon/>} onClick={() => {
                    setTab("Write");
                    setSplitScreen(!split_screen);
                }} active={split_screen} minimal intent={split_screen ? "secondary" : ""} size="sm"/>
            </NewTooltip>
        </div>}
        <Button onClick={() => handleSend()} loading={loading === 'sending'} size="sm" disabled={!can_send}
                intent={loading === 'sending' ? "success" : "secondary"}
                text={loading === 'sending' ? "Sending" : "Send Message"}/>
    </div>;

    const available_merge_tags = filterMergeTags(message_type, composer_merge_tags);

    const show_alt_message = (message_type === 'group' && !to[0].email);

    const can_enable_send_email = message_type === 'direct' || (message_type === 'group' && to[0].email);

    const can_customize_email_design = can_enable_send_email && allowed_email_design.includes(type);

    const tab_props = {
        to,
        actions,
        prefill,
        setSubject,
        can_customize_email_design,
        can_enable_send_email,
        setSeries,
        onClose,
        editorState,
        setFocusCount,
        cc,
        setCC,
        is_mobile,
        merge_tags: available_merge_tags,
        setTo,
        setSendEmail, send_email,
        focus_count,
        community,
        setInitialAction,
        mode,
        setMode,
        initial_action: run.current < 2 ? initial_action : "",
        editing,
        message_type,
        type,
        setEditing,
        setEditorState,
        initial_content,
        can_send,
        uploadFilesToFolder,
        selected_template, handleUpdateAttachments, handleSend, folder_id,
        subject,
        settings,
        changeSettings,
        series, loading, attachments
    };

    if (mode === 'share') {
        return <ComposerShareTab {...tab_props} />
    }

    if (loading === 'sent') {
        content = <div>

        </div>
    } else if (loading === 'sending') {
        content = <div>
            <InlineLoader padding="p-12"/>
        </div>
    } else {
        content = <>
            <div className={tab==="Write"?"":"hidden"}>
                <ComposerWriteTab {...tab_props} />
            </div>
            <div className={tab==="Preview"?"":"hidden"}>
                {editorState&&<ComposerPreviewTab {...tab_props} />}
            </div>
            <div className={tab==="Settings"?"":"hidden"}>
                <ComposerSettingsTab {...tab_props} />
            </div>
        </>
    }

    const tabs = is_mobile ? null : <div>
        {(loading !== 'send' && loading !== 'sending') &&
        <Tabs onSelect={(t) => setTab(t)} tabs={split_screen ? ['Write', 'Settings'] : ['Write', 'Preview', 'Settings']}
              active={tab} layout="pills-solid"/>}
    </div>;

    const show_message = (show_alt_message && !hide_note) || (send_email && tab === "Write" && !hide_note);

    const message_content = getMessageContent(message_type, show_alt_message);
    const m_type = getMessageType(message_type, show_alt_message);
    const message_icon = getMessageIcon(message_type, show_alt_message);

    return <FullScreenModal message={!show_message || is_mobile ? null :
        <TempTip icon={message_icon} tip={m_type} custom={message_content}/>} tabs={tabs} close={onClose}
                            size={split_screen ? "full" : "editor"} actions={actions}
                            title={`${message_type === 'group' ? "Group" : "Direct"} Message`}>
        <div className={`grid gap-4 ${split_screen ? "grid-cols-2 pl-1" : ""}`}>
            <div className="pt-2">
                {content}
            </div>
            {split_screen && <div className="pt-4 pr-4">
                {<ComposerPreviewTab {...tab_props} />}
            </div>}
        </div>
    </FullScreenModal>;
}