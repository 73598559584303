import React from "react";
import styled from "styled-components";
import {fileFormats, formatFileSize, getFileTypeIcon} from "./helpers";
import {ActionsPopup} from "../actions-popup";
import {downloadFile} from "../../../utilities/download-file";
import {TrashIcon, CloudDownloadIcon, UploadIcon} from '@heroicons/react/solid';

const Item = styled.div`
  border: 1px solid #e3e8ee;
  border-radius: 6px;
  display: grid;
  position: relative;
  width: 264px;
  grid-template-columns: ${props => props.compact ? "32px" : "40px"} 1fr;
  grid-gap: 4px;
  padding: ${props => props.compact ? "4px 6px 4px 4px" : "8px 12px 8px 8px"};

  svg {
    height: ${props => props.compact ? "28px" : "40px"};
  }
`;

const Left = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: center;
`;

const Right = styled.div`
  display: flex;
  flex-flow: row;
`;

const Title = styled.div`
`;

const Subtitle = styled.div`

`;

function getSubtitle(meta, size, type) {
    if (meta) {
        return meta
    }
    return `${fileFormats[type] ? fileFormats[type] : type} - ${formatFileSize(size)}`
}

export const FileItem = ({
                             name,
                             compact = false,
                             color,
                             size,
                             type,
                             inline_actions = false,
                             meta,
                             can_download = true,
                             url,
                             onDelete,
                             onChange
                         }) => {
    let actions = [];
    if (can_download) {
        actions.push({
            icon: <CloudDownloadIcon/>,
            text: 'Download',
            onClick: () => {
                downloadFile(url, name);
            }
        })
    }
    if (onDelete) {
        actions.push({
            icon: <TrashIcon/>,
            text: 'Delete',
            onClick: onDelete
        })
    }
    if (onChange) {
        actions.push({
            icon: <UploadIcon/>,
            text: 'Change',
            onClick: onChange
        })
    }

    return <Item compact={compact} className='hover-bkg bg-white group actions-popup-container'>
        <Left>
            {getFileTypeIcon(type, color)}
        </Left>
        <Right>
            <div className="flex flex-col justify-center flex-grow">
                <Title className='text-gray-900 text-sm font-semibold line-clamp-1'>
                    {name}
                </Title>
                <Subtitle className='text-gray-600 text-xs -mt-0.5'>
                    {getSubtitle(meta, size, type)}
                </Subtitle>
            </div>
            {actions.length>0 && <ActionsPopup inline_actions={inline_actions} actions={actions}/>}
        </Right>
    </Item>;
};