
// emojiImage - You can use this component to implement your own emoji library
import React from "react";
import createEmojiPlugin from "@draft-js-plugins/emoji";

export const ScInlineEmojiImage = ({emoji, theme, unicode}) => {
    return <span className="emoji-font">
        {unicode}
    </span>
};

export const emoji_theme = {
    emojiSuggestions: 'sc-emojiSuggestions',
    emojiSuggestionsEntry: 'sc-emojiSuggestionsEntry',
    emojiSuggestionsEntryFocused: 'sc-emojiSuggestionsEntryFocused',
    emojiSuggestionsEntryText: 'sc-emojiSuggestionsEntryText',
    emojiSuggestionsEntryIcon: 'sc-emojiSuggestionsEntryIcon',
};

export const scPluginEmoji = createEmojiPlugin({
    emojiImage: ScInlineEmojiImage,
theme: emoji_theme,
    useNativeArt: true
});