export function saveItemToLocalStorage(key,value) {
    return localStorage.setItem(key, JSON.stringify(value));
}

export function getItemFromLocalStorage(key,default_value) {
    const item = localStorage.getItem(key);
    if (item) {
        return JSON.parse(item);
    } else {
        return default_value;
    }
}