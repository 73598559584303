import {flattenMessages} from "./util";

export const intl_messages_en = flattenMessages({
    auth: {
        signin: {
            title: 'Sign In',
            email_label: 'Email or handle',
            password_label: 'Password',
            login_error: 'Incorrect email, handle, or password.',
            forgot_password_hint: 'Forgot your Password?',
            signin_button: 'Continue',
            no_password_message: 'Don\'t want to enter your password?'
        }
    },
    languages: {
        en: 'English',
        de: 'Deutsch'
    },
    welcome_card: {
        welcome: 'Welcome',
        greeting: 'Nice to see you again!',

        good_morning: 'Good Morning',
        good_evening: 'Good Evening',
        good_afternoon: 'Good Afternoon',

        actions: {
            secondary_email: {
                title: 'Add a secondary email',
                subtitle: 'This lets you send and respond to group threads with multiple emails.'
            },
            add_your_interests: {
                title: 'Add your interests',
                subtitle: 'Also discover members and groups with mutual interests.'
            }
        }
    },
    topbar: {
        feedback: 'Feedback?',
        improve_unaty: 'What would make Unaty better?',
        improve_issue: 'Have a specific issue? Contact Unaty Support or browse our docs.',
        search: 'Search',
        the_unaverse: 'the Unaverse',
        view_my_profile: 'My Profile',
        edit_my_profile: 'Edit my Profile',
        sign_out: 'Sign out',
        my_preferences: 'Preferences'
    },
    sidebar: {
        communities: 'Communities',
        explore: 'Explore',
        sign_in: 'Sign in',
        memberships: 'Memberships',
        profile: 'Profile',
        add_a_community: 'Add a Community',
        manage_community: 'Manage Community',
        switch_community: 'Switch Community',
        help_center: 'Help Center',
        contact_support: 'Contact Support',
        find_a_community: 'Find a Community',
        create_a_community: 'Create a Community',
        home: 'Home',
        members: 'Members',
        directory: 'Directory',
        leaders: 'Leaders',
        sent: 'Sent',
        threads: 'Threads',
        drafts: 'Drafts',
        manage: 'Manage',
        admin: 'Admin',
        groups: 'Groups',
        teams: 'Teams',
        events: 'Events',
        roles: 'Roles',
        files: 'Files',
        calendar: 'Calendar',
        messages: 'Messages',
        notifications: 'Notifications',
        create_group: 'Create Group',
        my_groups: 'My Groups',
        browse_groups: 'Browse Groups',
        invite_people_to: 'Invite people to {name}',
        community_settings: 'Community Settings',
        settings: 'Settings',
        drive: 'Drive',
        customize: 'Customize {name}',
        designer: 'Designer',
        policies: 'Policies',
        interests: 'Interests',
        back_to_the_unaverse: 'Back to the Unaverse'
    },
    home: {
        customize_home: 'Customize Home'
    },
    shared: {
        field_labels: {
            account_email: 'Account Email',
            first_name: 'First Name',
            last_name: 'Last Name',
            title: 'Title',
            middle_name: 'Middle Name',
            phone: 'Phone',
            handle: 'Handle',
            timezone: 'Timezone',
            language: 'Language',
            region: 'Region',
            military_time: '24 Hour Time',
            member_type: 'Member Type',
            bio: 'Bio',
            email: 'Email',
            founded: 'Founded',
            website: 'Website',
            linkedin: 'LinkedIn',
            secondary_phone: 'Secondary Phone',
            facebook: 'Facebook',
            meetup: 'Meetup',
            trello: 'Trello',
            age: 'Age',
            twitter: 'Twitter',
            symbol: 'Symbol',
            phone_number: 'Phone Number',
            name: 'Name',
            purpose: 'Purpose',
            description: 'Description',
            address: 'Address',
            career: 'Career',
            interests: 'Interests',
            eduction: 'Education',
            full_name: 'Full Name',
            birthday: 'Birthday',
            country: 'Country',
            zip_code: 'ZIP Code',
            state: 'State',
            line_one: 'Line One',
            line_two: 'Line Two',
            city: 'City',
            sessions: 'Sessions',
            last_login: 'Last Login',
        },
        actions: {
          show_menu: 'Show Menu',
            edit: 'Edit',
            edit_profile: 'Edit Profile',
            save_changes: 'Save Changes',
            delete: 'Delete',
            more_options: 'More Options',
            selected: 'Selected',
            rename: 'Rename',
            deselected: 'Deselected',
            add_cover: 'Add Cover',
            change_handle: 'Change Handle',
            create: 'Create',
            save: 'Save',
            move: 'Move',
            send: 'Send',
            cancel: 'Cancel',
            remove: 'Remove'
        },
        common: {
            joined: 'Joined',
          born: 'Born',
            permissions: 'Permissions'
        },
        entities: {
            groups: 'Groups',
            group: 'Group',
            events: 'Events',
            event: 'Event',
            leaders: 'Leaders',
            owner: 'Owner',
            custom_field: 'Custom Field',
            custom_fields: 'Custom Fields',
            folders: 'Folders',
            folder: 'Folder',
            files: 'Files',
            file: 'File',
            member_types: 'Member Types',
            member_type: 'Member Type',
            community: 'Community',
            communities: 'Communities',
            posts: 'Posts',
            post: 'Post',
            filter: 'Filter',
            filters: 'Filters',
            member: 'Member',
            members: 'Members',
            directory: 'Directory',
            user: 'User',
            users: 'Users',
            team: 'Team',
            teams: 'Teams',
            role: 'Role',
            roles: 'Roles',
            admin: 'Admin',
            admins: 'Admins',
            moderator: 'Moderator',
            moderators: 'Moderators',
        },
        advanced_table: {
         fields: 'Fields',
         filter: 'Filter',
         sort: 'Sort',
         search: 'Search'
        }
    },
    preferences: {
        title: 'My Preferences',
        preferences: 'Preferences',
        community_settings: 'Community Settings',
        applications: 'Applications',
        roles_and_teams: 'Roles & Teams',
        teams_rank: 'Teams Rank',
        general: 'General',
        members: 'Members',
        notifications: 'Notifications',
        security: 'Security',
        about: 'About',
        branding: 'Branding',
        time_date: 'Time & Date',
        customize: 'Customize',
        designer: 'Designer',
        account: 'Account',
        custom_fields: 'Custom Fields',
        filters: 'Filters',
        defaults: 'Defaults',
        build_mode: 'Build Mode',
        developers: 'Developers',
        api_keys: 'API Keys',
        webhooks: 'Webhooks',
        events: 'Events',
        logs: 'Logs',
        plan: 'Plan',
        privacy: 'Privacy',
        admins: 'Admins',
        member_types: 'Types',
        delegated_access: 'Delegated Access',
        data_toolkit: 'Data Toolkit',
        duplicates: 'Duplicates',
        community: 'Community',
        segments: 'Segments',
        rules: 'Rules'
    },
    global: {
        user: {
            submenu: {
                profile: "Profile",
                about_yourself: 'About yourself..'
            }
        },
        communities: {
            title: 'Communities',
            create_button: 'Create',
            enter: 'Enter'
        }
    },
    widgets: {
        birthdays: {
            title: 'Upcoming Birthdays',
            calendar: 'Calendar'
        },
        pinboard: {
            title: 'Pinboard',
            add_action: 'Add'
        },
        recent_files: {
            title: 'Recent Files',
            view_all: 'View all'
        }
    },
    policies: {
        privacy_policy: 'Privacy Policy',
        community_guidelines: 'Community Guidelines',
    },
    community: {
        preferences: {
            account: {
                title: 'Account',
                general: {
                    title: 'General'
                },
                notifications: {
                    title: 'Notifications'
                }
            },
            security: {
                title: 'Security',
                general: {
                    title: 'General'
                }
            }
        },
        home: {
            customize: 'Customize Home'
        },
        calendar: {
            title: 'Calendar',
          today: 'Today',
            short_days: {
                sunday: 'Sun',
                monday: 'Mon',
                tuesday: 'Tue',
                wednesday: 'Wed',
                thursday: 'Thu',
                friday: 'Fri',
                saturday: 'Sat'
            }
        },
        group: {
            joined: 'Joined',
            join: 'Join',
            leave_group: 'Leave Group',
            open_group: 'Open Group',
            team_group: 'Team',
            closed_group: 'Closed Group',
            email: 'Email',
            message: 'Message',
            settings: {
                about_the_group: '',
                edit_name: '',
                moderators: '',
                email_settings: '',
                pinboard: ''
            },
        },
        members: {
            add_members: 'Add Members'
        },
        groups: {

        },
        roles: {

        },
        member: {},
        admin: {
            admins: {

            },
            member_type: {

            },
            member_types: {

            },
            about: {

            },
            branding: {

            },
            custom_fields: {

            },
            build_mode: {

            },
            plan: {

            },
            privacy: {

            }
        },
        event: {},
        interests: {
            title: 'Discover members with mutual interests.',
            interested_in: 'Interested in',
            other_user: 'other user',
            other_users: 'other users',
            // todo add number
            limit: 'Choose up to 15 interests - see something missing?',
            let_us_know: 'Let us know!',
            explore: 'Explore',
            popular: 'Popular'
        },
        interest: {},
        role: {},
        folder: {},
        files: {
            title: 'My Files',
            table: {
                name: 'Name',
                last_modified: 'Last modified',
                size: 'Size',
                add_a_field: 'Add a Field',
                fields_for: 'Fields for'
            },
            actions: {
                move: 'Move',
                go_up: 'Go up',
                make_private: 'Make Private',
                delete_folder: 'Delete Folder',
                rename_file: 'Rename File',
                rename_caveat: 'We recommend keeping the file extension, like .png, in the name to make it easy to find and download files.',
                move_file: 'Move File',
                delete_file: 'Delete File',
                upload_new_version: 'Upload new version',
                download_file: 'Download File',
                make_public: 'Make Public',
                make_readonly: 'Make Readonly',
                make_readonly_desc: 'Currently members can edit any folder contents',
                allow_members_to_edit: 'Allow Members to edit',
                allow_edit_desc: 'Currently members can only view folder contents',
                folder: 'Folder',
                upload: 'Upload',
                delete: 'Delete'
            }
        },
        modals: {
            new_folder: {
              title: 'New Folder'
            },
            create_group: {

            },
            add_pin: {
                preview: 'Preview',
                create_pin: 'Create Pin'
            },
            add_cover: {
                gallery: 'Gallery',
                custom: 'Custom',
                categories: {
                    nature: '',
                    architecture: ''
                }
            },
            move_folder: {
a: 'Where do you want to move this?',
                b: 'This folder will assume the permissions of the folder you\'re moving it to, so it\'s contents may become private or public.'
            },
            delete_folder: {

            }
        }
    },
    interests: {
        fencing: 'Fencing',
        beer: 'Beer',
        volunteering: 'Volunteering',
        bears: 'Bears',
        comedy: 'Comedy',
        history: 'History',
        camping: 'Camping',
        architecture: 'Architecture',
        startups: 'Startups',
        fashion: 'Fashions',
        politics: 'Politics',
        foodie: 'Foodie',
        religious: 'Religious',
        sailing: 'Sailing',
        skateboarding: 'Skateboarding',
        art: 'Art',
        surfing: 'Surfing',
        coffee: 'Coffee',
        economics: 'Economics',
        cars: 'Cars',
        martial_arts: 'Martial Arts',
        board_games: 'Board Games',
        baseball: 'Baseball',
        biking: 'Biking',
        tv_shows: 'TV Shows',
        photography: 'Photography',
        fishing: 'Fishing',
        dogs: 'Dogs',
        tennis: 'Tennis',
        wine: 'Wine',
        boxing: 'Boxing',
        jogging: 'Jogging',
        podcasts: 'Podcasts',
        golf: 'Golf',
        baking: 'Baking',
        horseback_riding: 'Horseback Riding',
        painting: 'Painting',
        makeup: 'Making',
        travelling: 'Travelling',
        swimming: 'Swimming',
        dancing: 'Dancing',
        yoga: 'Yoga',
        brunch: 'Brunch',
        piano: 'Piano',
        hiking: 'Hiking',
        basketball: 'Basketball',
        shopping: 'Shopping',
        skiing: 'Skiing',
        reading: 'Reading',
        cooking: 'Cooking',
        theatre: 'Theatre',
        singing: 'Singing',
        badminton: 'Badminton',
        coding: 'Coding',
        bowling: 'Bowling',
        cats: 'Cats',
        hunting: 'Hunting',
        movies: 'Movies',
        music: 'Music',
        football: 'Football',
        ice_skating: 'Ice Skating',
        scuba_diving: 'Scuba Diving',
        video_games: 'Video Games',
        writing: 'Writing',
        chess: 'Chess',
        sushi: 'Sushi',
        ufos: 'UFOs',
        science: 'Science',
        beach: 'Beach',
        fitness: 'Fitness',
        motorcycles: 'Motorcycles',
        data_science: 'Data Science',
        mathematics: 'Mathematics',
        soccer: 'Soccer',
        gardening: 'Gardening',
        environment: 'Environment'
    }
});