import React, {Component} from "react";
import {externalAuthFetch} from "../../../config/network";
import {Button} from "../../../library/components/core";
import styled from "styled-components";

const Text = styled.div`
    margin-bottom: 16px;
    font-size: 16px;
`;

const Container = styled.div`
    display: inline-flex;
`;

class VerifyAccountBlock extends Component {
    constructor(props) {
        super(props);

        this.state = {
            sending: false,
            sent: false
        }
    }

    handleSend() {
        const {sending} = this.state;
        if (sending) {
            return;
        }

        this.setState({
            sending: true
        });

        // make the server call to create the org with the raw data

        // need to upload the photo though too hmmm

        // then generate a unique domain

        externalAuthFetch("/send-verification-email", this.handleResult.bind(this), this.handleError.bind(this), "POST", {email:this.props.email});
    }

    handleResult() {
        this.setState({
            sending: false,
            sent: true
        })
    }

    handleError() {
        this.setState({
            sent: true,
            sending: false
        })
    }

    renderAction(sending,sent) {
        if(sending) {
            return <Container>
                <Button text='Sending..' loading={true}/>
            </Container>
        } else if(sent) {
            return <Container>
                <Text className='text-gray-800'>Verification email sent, please check your inbox</Text>
            </Container>
        }
        return <Container>
            <Button text='Send verification email' loading={false} onClick={this.handleSend.bind(this)}/>
        </Container>
    }

    render() {
        const {sending,sent} = this.state;
        return (
            <div>
                <Text className='text-gray-800'>Please verify your account</Text>
                <Container>
                    {this.renderAction(sending, sent)}
                </Container>

            </div>
        );
    }
}

export default VerifyAccountBlock;