import React from 'react';
import styled from "styled-components";

const Outer = styled.div`
display: flex;
align-items: baseline;
> div {
    margin-bottom: 10px;
    font-size: 13px;
    font-weight: 500;
    > svg {
    height: 18px;
    color: #8792A1;
    display: inline-block;
    vertical-align: sub;
    margin: 0 4px;
    }
    }
`;

const Item = styled.a`
    color: #697487;
    text-decoration: none !important;
    :hover {
        color: #1A1F35;
    }
`;

const Slash = styled.span`
opacity: 0.6;
    font-weight: 400;
    padding: 0 8px;
`;

export const Breadcrumbs = ({items,loading}) => {
    const len = items.length;
    return <Outer>
        {loading&&<div><Item>Loading..</Item></div>}
        {!loading&&items.map((item,i)=> {
            return <div key={`${item.text}-${i}`}>
                <Item onClick={item.onClick}>
                    {item.text}
                </Item>
                {len!==(i+1)&&<Slash className='text-gray-700'>/</Slash>}
            </div>
        })}
    </Outer>
};