import React, {useContext, useEffect} from 'react';
import {FirebaseDB} from "../../../config/setup-firestore";
import {CommunityContext} from "../community-context";
import {useHistory} from "react-router-dom";
import ProfileLoading from "../../global/user/states/loading";

export function MemberIdRedirect(props) {
    const history = useHistory();
const community = useContext(CommunityContext);
    useEffect(function () {
        const id = props.match.params.memberid;

        FirebaseDB
            .collection('community_members')
            .doc(community.uid)
            .collection('members')
            .doc(id)
            .get()
            .then(doc=>{
                history.push(`/${community.handle}/member/${doc.data().handle}`);
            })
    }, []);

    return <div>

    </div>;
}