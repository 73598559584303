import {authFetch} from "../../../config/network";

export async function api_createSeries(community,pl) {
    return await new Promise((resolve, reject) => {
        const res = (r) => {
            resolve(r);
        };
        const payload = {
            community_uid: community.uid,
            member_id: community.member_id,
            group_id: pl.group_id,
            name: pl.name
        };
        authFetch("/posts/new-series", res, res, "POST", {payload});
    })
}