import React from 'react';
import styled from "styled-components";
import {UnatySVG} from "../../../icons/unaty/logos";
import {CIUnaverse} from "../../../icons/custom/Unaverse";
import {CIModerator} from "../../../icons/custom/Moderator";
import {CIUser} from "../../../icons/custom/User";
import {CIDrive} from "../../../icons/custom/Drive";
import {SharedFolderIcon} from "../../../icons/custom/SharedFolder";
import {PrivateFolderIcon} from "../../../icons/custom/PrivateFolder";
import {FIArchive} from "../../../icons/files/FileArchive";
import {FIAudio} from "../../../icons/files/FileAudio";
import {FIDocument} from "../../../icons/files/FileDocument";
import {FIPDF} from "../../../icons/files/FilePDF";
import {FIPresentation} from "../../../icons/files/FilePresentation";
import {FISpreadsheet} from "../../../icons/files/FileSpreadsheet";
import {FITxt} from "../../../icons/files/FileTxt";
import {FIVideo} from "../../../icons/files/FileVideo";
import {FIZip} from "../../../icons/files/FileZip";
import {FISheet} from "../../../icons/files/FileSheet";
import {FICode} from "../../../icons/files/FileCode";

const MainIcon = styled.div`
width: ${props=>props.height?props.height:'56'}px;
> svg {
    height: ${props=>props.height?props.height:'56'}px;
}
`;

export default function SGCustomIconsPage() {

    return <div>
<div className="space-y-4 items-start flex flex-col">

    <div>
        <div>
            Unaty Logo
        </div>
    <MainIcon>
        <UnatySVG />
    </MainIcon>
    </div>
    <div>
        <div>
            Unaverse
        </div>
    <MainIcon>
        <CIUnaverse/>
    </MainIcon>
    </div>
    <div>
        <div>
            Moderator
        </div>
    <MainIcon>
        <CIModerator/>
    </MainIcon>
    </div>
    <div>
        <div>
            User Icon
        </div>
    <MainIcon>
        <CIUser/>
    </MainIcon>
    </div>
    <div>
        <div>
            Group Icon
        </div>

    </div>
    <div>
        <div>
            Drive Icon
        </div>
        <MainIcon height={24} className="relative">
            <CIDrive />

        </MainIcon>

    </div>
    <div>
        <div>
            Shared Folder Icon
        </div>
        <SharedFolderIcon/>

    </div>
    <div>
        <div>
            Closed Folder Icon
        </div>
        <PrivateFolderIcon/>

    </div>

    <div>
        <FIArchive/>
    </div>
    <div>
        <FIAudio/>
    </div>
    <div>
        <FIDocument/>
    </div>
    <div>
        <FIPDF/>
    </div>
    <div>
        <FIPresentation/>
    </div>
    <div>
        <FISpreadsheet/>
    </div>
    <div>
        <FITxt/>
    </div>
    <div>
        <FIVideo/>
    </div>
    <div>
        <FIZip/>
    </div>
    <div>
        <FISheet/>
    </div>
    <div>
        <FICode/>
    </div>
</div>
    </div>
}