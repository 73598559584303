import React from 'react';
import {Field, SimpleListbox,RichSearch,RichSelect} from '../../core';
import {countries_array} from "../../../data/general/countries";

export default function SGListboxesPage() {

    return <div className="space-y-2 pt-4">
        <div>
            <Field label="Single Dropdown">
           <SimpleListbox options={countries_array} />
            </Field>
        </div>
        <div>
            <Field label="Clearable">
                <SimpleListbox clearable options={countries_array} />
            </Field>
        </div>
        <div>
            <Field label="Searchable">
                <SimpleListbox clearable searchable options={countries_array} />
            </Field>
        </div>
        <div>
            <Field label="Multi Dropdown">
                <SimpleListbox multi clearable searchable options={countries_array} />
            </Field>
        </div>
        <div>
            <Field label="Rich Select">
               <RichSelect />
            </Field>
        </div>
        <div>
            <Field label="Single with Avatar">
                <RichSearch />
            </Field>
        </div>
        <div>
            <Field label="Multi with Avatar">
                todo
            </Field>
        </div>
    </div>
}