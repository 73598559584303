import React, {useState} from 'react';
import {HexColorPicker} from "react-colorful";
import styled from "styled-components";
import {ACCENT_COLORS} from "../../../config/defaults";

const CP = styled.div`
> div {
    width: 100% !important;
}
`;

export function ColorPicker({updateColor = () => {}, simple=false, color = ACCENT_COLORS[0]}) {
    const [val, setVal] = useState(color);
    if(simple) {
        return <div className="space-x-2 flex p-1">
            {ACCENT_COLORS.map(def => <div onClick={() => {
                setVal(def);
                updateColor(def)
            }}
                                           className={`h-6 w-6 rounded-md transition-opacity hover:opacity-70 cursor-pointer ${val === def ? 'ring-2 ring-offset-2 ring-blue-500' : ''}`}
                                           style={{backgroundColor: def}} key={def}>

            </div>)}
        </div>
    }
    return <div className="shadow-md py-3 px-3 border border-gray-100 border-solid inline-grid rounded-md">
        <div className="grid grid-cols-6 gap-2 pb-2 border-b border-gray-100 border-solid">
            {ACCENT_COLORS.map(def => <div onClick={() => {
                setVal(def);
                updateColor(def)
            }}
                                      className={`h-6 w-6 rounded-md transition-opacity hover:opacity-70 cursor-pointer ${val === def ? 'ring-2 ring-offset-2 ring-blue-500' : ''}`}
                                      style={{backgroundColor: def}} key={def}>

            </div>)}
        </div>
        <CP className="mt-2">
            <HexColorPicker color={val} onChange={(c) => {
                setVal(c);
                updateColor(c)
            }}/>
        </CP>
    </div>
}