import React from 'react';
import styled from "styled-components";
import {TrashIcon} from '@heroicons/react/solid';
import {DEFAULT_USER_PHOTO} from "../../../config/defaults";

const Row = styled.div`
    position: relative;
        display: grid;
    grid-template-columns: 24px 1fr;
    padding: 6px 12px 8px 12px;
    grid-gap: 10px;
    cursor: pointer;
    :hover {
        background-color: #fafafa;
    }
    :hover > div > .action {
        display: flex;
    }
`;

const Image = styled.div`
    height: 24px;
    width: 24px;
        color: #112D42;
        background-color: #efefef;
        text-align: center;
    line-height: 24px;
    font-size: 12px;
    font-weight: 600;
    background-position: center;
    background-size: cover;
    border-radius: 8px;
        align-self: center;
        display: flex;
    align-items: center;
    flex-flow: column;
    justify-content: center;
    > img {
    height: 24px;
    width: 24px;
    border-radius: 12px;
    }
`;

const Title = styled.div`
  color: #112D42;
    line-height: 24px;
    font-weight: 500;
    font-size: 15px;
`;

const Subtitle = styled.div`
color: #8193a8;
    font-size: 12px;
    line-height: normal;
    margin-top: -2px;
`;

const Text = styled.div`
          display: flex;
    align-items: flex-start;
    flex-flow: column;
    justify-content: center;
`;

const Actions = styled.div`
       position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    padding-right: 12px;
    display: flex;
    align-items: center;
`;

const Action = styled.div`
        display: none;
    align-items: center;
    justify-content: center;
    height: 24px;
    color: #8193A8;
    width: 24px;
    border-radius: 6px;
    cursor: pointer;
    :hover {
     color: #112D42;
     background-color: #efefef;
    }
    svg {
        height: 20px;
    }
`;

const IconItem = styled.div`
     height: 24px;
    width: 24px;
        color: #112D42;
         line-height: 24px;
    font-size: 12px;
    align-self: center;
        display: flex;
    align-items: center;
    flex-flow: column;
    justify-content: center;
    font-weight: 600;
    svg {
            height: 20px;
    }
`;

export function TableEntity({onRemove, blockRemove = false, icon = null, image, title, subtitle, meta = null}) {
    return <Row>
        {icon && <IconItem>{icon}</IconItem>}
        {!icon && <Image style={{backgroundImage: image !== '' ? `url(${image}_small?alt=media)` : `url(${DEFAULT_USER_PHOTO}_small?alt=media)`}}>

        </Image>}
        <Text>
            <Title>
                {title}
            </Title>
            {subtitle && <Subtitle>
                {subtitle}
            </Subtitle>}
        </Text>
        <div className="flex space-x-2">
            {!blockRemove&&<Action className="action" onClick={onRemove}>
                <TrashIcon/>
            </Action>}
        </div>
    </Row>
}

export const SelectEntitiesFrame = styled.div`
    position: relative;
    max-width: 400px;
`;

const Table = styled.div`
    position: relative;
    max-width: 400px;
        height: 160px;
    border-bottom: 1px solid #ebf0f3;
    margin-top: 8px;
    overflow-y: scroll;
`;

const ES = styled.div`
    color: #112D42;
    padding: 20px;
    text-align: center;
`;

function isEmpty(entities) {
    for (let property in entities) {
        if (entities.hasOwnProperty(property)) {
            if(entities[property].length!==0) {
                return false;
            }
        }
    }
    return true;
}

export function TableOfEntities({entities, can_remove = true, data, community_uid = '', canEditEntities = true, loadEntities = false, onRemove, extras = {}}) {
    const {members, member_types, groups, filters, roles, teams} = entities;
    const is_empty = isEmpty(entities);


    return <Table>
        {is_empty&&<ES>
            No Members selected
        </ES>}
    </Table>
}


export function SearchResult({image, onClick, selected = false, title, subtitle = null}) {

    return <Row onClick={onClick} className={selected && 'selected-item'}>
        <Image style={{backgroundImage: image !== '' && `url(${image})`}}>
            {image === '' && title.charAt(0).toUpperCase()}
        </Image>
        <Text>
            <Title>
                {title}
            </Title>
            {subtitle && <Subtitle>
                {subtitle}
            </Subtitle>}
        </Text>
    </Row>;
}

export const LoadingBox = styled.div`
padding: 6px 12px 8px 12px;
color: #112D42;
    line-height: 24px;
    font-weight: 500;
    font-size: 15px;
`;

export const NoResultsBox = styled.div`
padding: 6px 12px 8px 12px;
color: #112D42;
    line-height: 24px;
    font-weight: 500;
    font-size: 15px;
`;

const ResultsBox = styled.div`
       position: absolute;
    z-index: 9;
    background-color: #fff;
    left: 0;
    right: 0;
    margin-top: 4px;
    box-shadow: 0 10px 15px -3px rgba(0,0,0,.1),0 4px 6px -2px rgba(0,0,0,.05)!important;
    border-radius: 6px;
    padding: 2px 0;
`;

export function SearchResultsBox(props) {

    return <ResultsBox>
        {props.children}
    </ResultsBox>;
}

const Box = styled.div`
    position: relative;
    input {
        box-shadow: rgba(0,0,0,0) 0px 0px 0px 0px, rgba(0,0,0,0) 0px 0px 0px 0px, rgba(0,0,0,0) 0px 0px 0px 0px, rgba(60,66,87,0.16) 0px 0px 0px 1px, rgba(0,0,0,0) 0px 0px 0px 0px, rgba(0,0,0,0) 0px 0px 0px 0px, rgba(0,0,0,0) 0px 0px 0px 0px;
    border-radius: 4px;
    -webkit-transition: border 80ms ease-out,box-shadow 80ms ease-out;
    transition: border 80ms ease-out,box-shadow 80ms ease-out;
    box-sizing: border-box;
    color: #112D42;
    padding: 10px 12px 10px 12px;
    height: 40px;
    font-size: 16px;
    line-height: 1.33333333;
    width: 100%;
    border: none;
    :focus {
        outline: none;
    }
    :focus-within {
        box-shadow: rgba(0,0,0,0) 0px 0px 0px 0px, rgba(58,151,212,0.36) 0px 0px 0px 4px, rgba(0,0,0,0) 0px 0px 0px 0px, rgba(60,66,87,0.16) 0px 0px 0px 1px, rgba(0,0,0,0) 0px 0px 0px 0px, rgba(0,0,0,0) 0px 0px 0px 0px, rgba(0,0,0,0) 0px 0px 0px 0px;
    }
    }
`;

export function SearchInputBox(props) {

    return <Box>
        {props.children}
    </Box>;
}