import React from "react";
import {Avatar} from "../core";
import {ChevronRightIcon} from "@heroicons/react/solid";

export function USResultsSection({title="",children,right_action,no_padding=false}) {
    return <div className={no_padding?"":"pb-1 px-1 pt-1"}>
        {title&&<USTitle right_action={right_action} title={title} />}
        <div className={`space-y-px flex flex-col`}>
            {children}
        </div>
    </div>
}

export function USPopupContainer({children,maxWidth="64vw",width="610px"}) {

    return <div style={{maxHeight:'450px',minWidth:'260px',maxWidth,width: width}} className="divide-y mt-1 bg-white w-full overflow-y-auto divide-gray-200 shadow-sm rounded-md py-0.5 border border-gray-200">
        {children}
    </div>
}

export function USTitle({title="Example",right_action}) {

    return <div className="flex px-2.5 pb-1 pt-2">
        <div className="flex-grow text-xs text-gray-500 font-medium">
            {title}
        </div>
        {right_action&&<div className={`text-xs cursor-pointer text-gray-500 font-medium underline`} onClick={right_action.onClick}>{right_action.label}</div>}
    </div>
}

// icon types: color, icon, icon-circle, image

function getSize(meta,icon) {
    if(meta) {
        return 'double'
    } else if(icon.type==='icon-circle') {
        return 'double'
    } else {
        return 'single'
    }
}

function getHighlightedText(text, highlight) {
    // Split text on highlight term, include term itself into parts, ignore case
    const parts = text.split(new RegExp(`(${highlight})`, 'gi'));
    return <span>{parts.map((part,a) => part.toLowerCase() === highlight.toLowerCase() ? <b key={a}>{part}</b> : part)}</span>;
}

export function USSimpleItemLabel({label, color = ""}) {
    let str = '';
    if(!label) {
        str = "";
    } else if(typeof label === 'string') {
        str = label;
    } else {
        const len = label.length;
        str = label.map((l,k)=>{
            return <span key={k} className="h-4 flex items-center">
                <span className='inline-block truncate'>
                    {l}
                    </span>
                {(k+1)!==len&&<span className="h-4 w-4 mx-1 inline-block text-gray-400">
                    <ChevronRightIcon />
                </span>}
            </span>
        });
    }

    return <div className={`text-sm ${color} inline-flex font-medium`}>
        {str}
    </div>
}

function ItemLabel({label,selected=false,color="",query=''}) {
    let str = '';
    if(!label) {
        str = "Empty";
    } else if(typeof label === 'string') {
        str = <span key='a' className="truncate">{getHighlightedText(label, query)}</span>;
    } else {
        const len = label.length;
        str = label.map((l,k)=>{
            return <span key={`${l}+${k}-${query}`} className="h-4 flex items-center">
                <span className='inline-block truncate'>
                {getHighlightedText(l, query)}
                    </span>
                {(k+1)!==len&&<span className="h-4 w-4 mx-1 inline-block text-gray-400">
                    <ChevronRightIcon />
                </span>}
            </span>
        });
    }

    return <div className={`text-sm ${color} inline-flex font-medium`}>
        {str}
    </div>
}

function getBgSize(size,def) {
    if(size==='md') {
        return 'h-10 w-10'
    } else {
        return def
    }
}

export function GeneralItemIcon(props) {
    const type = props.type;

    let content = null;
    if(type==='color') {
        content = <div className={`${getBgSize(props.size,'h-3.5 w-3.5')} border border-white rounded-full`} style={{backgroundColor: `${props.color}`}}>

            </div>;
    } else if(type==='icon') {
        content = <div className={`${getBgSize(props.size,'h-4 w-4')} svg-child-height-min items-center flex justify-center ${props.icon_color}`}>
            {props.icon}
        </div>;
    } else if(type==='icon-image') {
        content = <div className="relative">
            <div className="h-10">
            <Avatar size="md" url={props.image} />
            </div>
            <div className="absolute -bottom-1 -right-1.5 svg-child-height-min bg-white rounded-md">
                {props.icon}
            </div>
        </div>
    } else if(type==='icon-count') {
        content = <div className="relative">
            <div className="h-10 flex items-center justify-center text-gray-600 text-xs font-medium w-10 bg-gray-200 rounded-42">
                {props.count}
            </div>
            <div className="absolute -bottom-1 -right-1.5 bg-white svg-child-height-min rounded-md">
                    {props.icon}
            </div>
        </div>
    } else if(type==='emoji') {
        content = <div className={`${getBgSize(props.size,'h-4 w-4')} text-base flex items-center`}>
            {props.emoji}
        </div>;
    } else if(type==='icon-circle') {
        content = <div className={`${getBgSize(props.size,'h-8 w-8')} ${props.bg} justify-center flex items-center rounded-full`}>
            <div className={`h-5 w-5 svg-child-height-min ${props.color}`}>
            {props.icon}
            </div>
        </div>;
    } else if(type==='image') {
        if(props.size==='double') {
            content = <Avatar text={props.text?props.text:""} size="sm" url={props.url} />;
        } else if(props.size==='md') {
            content = <Avatar text={props.text?props.text:""} size="md" url={props.url} />;
        } else {
            content = <Avatar text={props.text?props.text:""} size="xs" url={props.url} />;
        }
    }

    return <div className={`${props.height} flex items-center justify-center`}>
        {content}
    </div>
}

function getStyles(selected,disabled) {
    if(disabled&&selected) {
        return {
            bg: 'bg-gray-100',
            label: 'text-gray-500'
        }
    } else if (disabled&&!selected) {
        return {
            bg: 'bg-gray-100',
            label: 'text-gray-500'
        }
    } else if(selected) {
        return {
            bg: 'bg-secondary-50',
            label: 'text-secondary-700'
        }
    } else {
        return {
            bg: 'bg-white hover:bg-gray-100',
            label: 'text-gray-700'
        }
    }
}

export function USItem({
    label,
    meta = '',
    query = '',
    note = '',
    disabled = false,
                           onClick = () => {},
    selected = false,
    icon = {
        type: 'color',
        color: 'red'
    }
                       }) {

    const styles = getStyles(selected,disabled);
    const size = getSize(meta,icon);

    const height = size === 'double' ? 'h-8' : 'h-5';
    const icon_box_height = size === 'double' ? 'h-8' : 'h-6';

    return <div onClick={(e)=>{
        e.stopPropagation();
        e.preventDefault();
        onClick()
    }} className={`flex us-option transition-colors space-x-2 rounded-md pl-1.5 pr-3 py-1.5 ${styles.bg} ${disabled?"":"cursor-pointer"}`}>
        <div className={`flex-none w-8 flex items-center justify-center ${icon_box_height} ${size==='double'?"":"-my-0.5"}`}>
        <GeneralItemIcon height={icon_box_height} icon_color={styles.label} size={size} {...icon} />
        </div>
        <div className={`flex-grow flex-col flex justify-center ${height}`}>
            <ItemLabel selected={selected} color={styles.label} query={query} label={label} />
            {meta&&<div className="text-gray-500 text-xs">{meta}</div>}
        </div>
        <div className={`flex-none flex items-center ${height}`}>
            {note&&<div className="text-xs text-gray-400">
                {note}
            </div>}
        </div>
    </div>
}