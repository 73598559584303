import React, {useEffect, useState} from 'react';
import classNames from "classnames";
import {BirthdaySelector} from "../../old/selectors/birthday";
import {months} from "../../../data/general/months";
import {seasonsObj} from "../../../data/general/seasons";
import {CheckIcon} from "@heroicons/react/solid";

const values = {
    full_date: {
        title: 'Full date',
        format: 'MM/DD/YYYY',
        displayValue: (v)=>{
            if(v.year===null) {
                return `-`
            } else if(v.month===null&&v.date===null) {
                return `${v.year}`
            } else if(v.date===null) {
                const month_name = months[(parseInt(v.month)+1)];
                return `${month_name.label} ${v.year}`;
            } else {
                const month_name = months[(parseInt(v.month)+1)];
                return `${month_name.label} ${v.date}, ${v.year}`;
            }
        },
        subtitle: 'Sep 12, 2013'
    },
    year_only: {
        title: 'Year',
        format: 'YYYY',
        displayValue: (v)=>{
            if(v&&v.year&&v.year.toString().length===4) {
                return v.year;
            }
            return '-';
        },
        subtitle: '2013'
    },
    // UU = Spring, Summer, Fall, Winter
    // U = 0, 1, 2, 3
    season_year: {
        title: 'Season',
        format: 'UU/YYYY',
        displayValue: (v)=>{
            if(v.year===null) {
                return `-`
            } else if(v.season===null) {
                return `${v.year}`
            } else {
                const season_name = seasonsObj[v.season];
                return `${season_name} ${v.year}`;
            }
        },
        subtitle: 'Spring 2013'
    },
    month_year: {
        title: 'Month',
        format: 'MM/YYYY',
        displayValue: (v)=>{
            if(v.year===null) {
                return `-`
            } else if(v.month===null) {
                return `${v.year}`
            } else {
                const month_name = months[(parseInt(v.month)+1)];
                return `${month_name.label} ${v.year}`;
            }
        },
        subtitle: 'Jan 1995'
    }
};

const formats = [
    'month_year',
    'season_year',
    'full_date',
    'year_only'
];


function FormatSelector({select, onSelect}) {
    return <div className="space-y-1">

        <div className="flex-grow flex space-x-2">
        {formats.map((id) => {
            const opt = values[id];
            const active = select === id ? "bg-selection" : "hover:bg-gray-200 opacity-70 hover:opacity-90";
            const active_text = select === id ? "text-white" : "text-gray-800";
            return <div onClick={()=>onSelect(id)} style={{minWidth:'64px'}} className={classNames("pl-2 pr-6 transition-opacity transition-colors py-1 cursor-pointer rounded-md", active)} key={id}>
                <div className={`text-xs font-semibold ${active_text}`}>{opt.title}</div>
                <div className={`text-xs ${active_text} opacity-80`}>{opt.subtitle}</div>
            </div>
        })}
        </div>
    </div>
}

export function getMemberJoinDate(date) {
    if(!date) {
        return 'Unknown'
    }
    const {type, value} = date;
    // need to parse based on format and values
    // also output unknown if unknown
    return type===''||!type?"Unknown":values[type].displayValue(value);
}

export function MemberJoinDate({onChange=()=>{},simple,existing = {type:'',value:{season:null,date:null,month:null,year:null}}}) {
    const [date, setDate] = useState({...existing});

    useEffect(function() {
        onChange(date);
    }, [date]);

    const {type, value} = date;

    const ty = type===''||!type ? formats[0] : type;

    const format = values[ty].format;

    return <div className={simple?"":`rounded-md shadow-lg border border-gray-200`} style={{width:'450px'}}>
        <div className={simple?"pb-3":`px-3 py-2`}>
            <BirthdaySelector autoFocus time_format={format} value={value} onChange={(v) => {
                let nd = {...date};
                nd.value = v;
                if(nd.type==='') {
                    nd.type = formats[0];
                }
                setDate(nd);
            }}/>

        </div>
        <div className={simple?"":`border-t border-gray-200 rounded-b-md bg-gray-100 px-2 py-2`}>
            <FormatSelector onSelect={(t)=>{
                let nd = {...date};
                nd.type = t;
                setDate(nd);
            }} select={ty}/>
        </div>
    </div>
}