import React from 'react';
import styled from "styled-components";

const Body = styled.div`
       min-height: 320px;
`;

export function TableBody({children}) {

    return <Body>
        {children}
    </Body>
}