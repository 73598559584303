import React, {useState, useEffect, useContext} from 'react';
import {FirebaseDB} from "../../../config/setup-firestore";
import {CommunityContext} from "../community-context";
import {ProfileCard} from "../../../library/components/reorganize/profile-card";
import {buildRoleProfileCard} from "./build-profile-card";
import {SubtitleMetaLink} from "../../../library/components/old/subtitle-meta-link";
import ProfileLoadingCard from "../../../library/components/layout/loading/profile";
import {getPhoneNumberData} from "../../../library/utilities/phone";
import {authFetch} from "../../../config/network";
import {Button, EntityHeader, MenuPanels, Pinboard} from "../../../library/components/core";
import {DEFAULT_USER_PHOTO} from "../../../config/defaults";
import {GlobalContext} from "../../global/global-context";
import {group_buildPinsExtras} from "../group/pins";
import {PastAssignees} from "./past-assignees";
import {isAnonymousUnatyEmail} from "../../../library/utilities/anonymous-emails";
import {useIsMobile} from "../../global/global-router";
import {SimplePageGridDivider} from "../group/simple-page-divider";
import {RolePreferencesModal} from "./modal/modal";
import {AddPinModal} from "../group/add-pin-modal";
import {StandardPageFramework} from "../../../library/playground/standard-page-framework";
import EntityNotFound from "../../../library/components/layout/non-ideal-states/not-found";


export default function RolePage(props) {
    const {history} = props;
    const community = useContext(CommunityContext);
    const global = useContext(GlobalContext);
    const is_mobile = useIsMobile();
    let id = props.match.params.id;
    const [data, setData] = useState(null);
    const [modal, setModal] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(null);
    const [assignee_data, setAssigneeData] = useState(null);

    useEffect(function () {
        id = props.match.params.id;
        getRole(id);
    }, [props.match.params.id]);

    function getRole(sid, cb) {
        let fid = sid ? sid : id;

        FirebaseDB
            .collection('community_entities')
            .doc(community.uid)
            .collection('roles')
            .doc(fid)
            .get()
            .then(res => {
                if (!res.exists) {
                    setError('not-found');
                    return;
                }
                const data = {...res.data()};
                if (data.assignee === '') {
                    setAssigneeData({
                        profile_picture: '',
                        profile_picture_color: ''
                    });
                } else {
                    getMember(data.assignee);
                }
                if (cb) {
                    cb();
                }
                setData(data);
            })
    }

    function getMember(id) {
        if (!id) {
            return;
        }
        FirebaseDB
            .collection('community_members')
            .doc(community.uid)
            .collection('members')
            .doc(id)
            .get()
            .then(res => {
                const data = res.data();

                setAssigneeData(data);
            })
    }

    function setCoverPhoto(url, color) {
        const img = url;
        let payload = {
            url: img,
            community_uid: community.uid,
            id: props.match.params.id,
            color
        };
        let d = Object.assign({}, data);
        d['cover_photo'] = url;
        d['cover_photo_color'] = color;
        setData({...d});
        setLoading(true);
        const res = () => {
            setLoading(false);

        };
        authFetch('/roles/set-cover-photo', res, res, "POST", {payload});
    }

    if (error === 'not-found') {
        return <EntityNotFound type='role'/>;
    }

    if (!assignee_data || !data) {
        return <ProfileLoadingCard/>;
    }

    const final = {
        ...data,
        profile_picture: `${assignee_data.profile_picture ? assignee_data.profile_picture : DEFAULT_USER_PHOTO}`,
        profile_picture_color: assignee_data.profile_picture_color,
    };

    let new_card = buildRoleProfileCard(final);


    if (data.assignee === '') {
        new_card.main.subtitle.push(
            <SubtitleMetaLink link={'#'} text="Unassigned"/>
        );
    } else {
        new_card.main.subtitle.push(
            <SubtitleMetaLink link={`/${community.data.handle}/member/${assignee_data.handle}`}
                              text={assignee_data.name}/>
        );

        let email = assignee_data.contact?assignee_data.contact.email:"";
        let phone = assignee_data.contact?assignee_data.contact.phone:"";

        if (email !== '' && !isAnonymousUnatyEmail(email)) {
            new_card.main.meta.push({
                type: 'email',
                value: email
            });
        }
        if (phone !== '') {
            const phone_data = getPhoneNumberData(phone);
            new_card.main.meta.push({
                type: 'phone',
                meta: phone_data.tel,
                value: phone_data.display
            });
        }
    }

    function addPin(pin_id, pin_data, cb) {

        const payload = {
            community_uid: community.uid,
            pin_id,
            pin_data,
            id: id,
        };

        const res = () => {
            setTimeout(() => {
                getRole(id, cb);
            }, 1000);
            setModal(null);
            global.addToast({text: 'Pin added', intent: 'success'});
        };

        const err = () => {
            setModal(null);
            global.addToast({text: 'Pin could not be added', intent: 'danger'});
        };

        authFetch("/roles/add-pin", res, err, "POST", {payload});
    }

    let actions = {
        setCoverPhoto: setCoverPhoto.bind(this),
    };

    let features = {
        edit_profile_picture: false,
        edit_cover_photo: true,
        can_manage: community.is_admin
    };

    let rpins = data.pins ? data.pins : [];
    let rpins_data = data.pins_data ? data.pins_data : {};
    let pins = [];

    const new_pins = group_buildPinsExtras(pins, rpins, rpins_data, history);

    const context = {};

    let settings_button;

    let topbar_actions = null;

    if (community.is_admin && !is_mobile) {
        settings_button = <Button size="sm" minimal compact intent="secondary" onClick={() => {
            global.handleSetRightMenu(`admin-role__${id}`,{onClosing:()=>{
                getRole(id);
            }});
        }} text="Manage"
        />;

        topbar_actions = <div className="flex space-x-2">
            {settings_button}
        </div>;
    }

    const topbar = <EntityHeader single title={data.name} actions={topbar_actions}/>;

    const sidebar = <div className="space-y-4 pr-2">
        <Pinboard title={"Pinboard"} show_empty community_uid={community.uid} can_add_pin={community.is_admin}
                  onAddPin={() => {
                      setModal('add-pin')
                  }} pins={new_pins}/>
        <PastAssignees data={data} id={id} getRole={() => {
            getRole(id);
        }}/>
    </div>;

    return <StandardPageFramework content sidebar={sidebar}>
        {topbar}
        <ProfileCard context={context} editing={false} actions={actions} data={new_card} features={features}/>

        {modal === 'add-pin' && <AddPinModal
            pins={rpins}
            onClose={() => setModal(null)}
            onConfirm={addPin} pins_data={rpins_data}/>}

        {modal === 'preferences' && data && <RolePreferencesModal getRole={getRole} id={id} role={data} onClose={() => {
            setModal(null)
        }}/>}
    </StandardPageFramework>
}