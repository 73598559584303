import React, {useContext, useEffect, useState} from "react";
import FilesScrollable from "./files-scrollable";
import {setDocumentTitle} from "../../../library/utilities/set-document-title";
import {CommunityContext} from "../community-context";
import {GlobalContext} from "../../global/global-context";
import {Button, EntityHeader} from "../../../library/components/core";
import {CreateFolderController} from "./create";
import {FirebaseDB} from "../../../config/setup-firestore";
import {useIntl} from "react-intl";
import {useIsMobile} from "../../global/global-router";

export default function FilesTopLevel(props) {
    const [open, setOpen] = useState(false);
    const {formatMessage: f} = useIntl();
    const is_mobile = useIsMobile();
    const community = useContext(CommunityContext);
    const global = useContext(GlobalContext);
    const [folder_names, setFolderNames] = useState([]);

    useEffect(function () {
        FirebaseDB
            .collection('community_entities')
            .doc(community.uid)
            .collection('folders')
            .where('folder', '==', '')
            .get()
            .then((snap) => {
                setFolderNames(snap.docs.map((doc) => doc.data().name.toLowerCase()));
            })
    }, [community.uid]);

    const mf = f({id: 'community.files.title'});

    setDocumentTitle(mf, community.data.name);

    let right_elements = null;

    return <div>
        <div className="sticky top-0 bg-white z-20">
        <EntityHeader onClick={() => {
            const el = document.getElementById("main-content");
            el.scrollTo(0, 0);
        }} title={"Drives"} actions={right_elements} />
        </div>

        <div className="px-3 pt-1.5 pb-16">
            <FilesScrollable display="drive" is_mobile={is_mobile} base={`/${community.data.handle}/explore/drive`} is_admin={community.is_admin} language={global.language} selected={[]} can_edit={false} onAddToast={t => global.addToast(t)} top_level
                             folder="" {...props} />
        </div>

        {open && <CreateFolderController base={`/${community.data.handle}/explore/drive`} handle={community.handle} folder={''}
                                         context={{
                                             is_admin: community.is_admin,
                                             member_id: community.member_id,
                                             folder: '',
                                             folder_names,
                                             community_uid: community.uid
                                         }}
                                         closeModal={() => setOpen(false)} history={props.history} open={open}/>}

    </div>
}