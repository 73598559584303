import React, {useContext} from 'react';
import {ActionWithIcon} from "./index"
import {copyTextToClipboard} from "../../utilities/general/copy-to-clipboard";
import {ClipboardIcon} from '@heroicons/react/solid';
import {GlobalContext} from "../../../app/global/global-context";
export function AdminEntityInfo({type_label,type_icon,id}) {
const global = useContext(GlobalContext);
  return <div className="flex px-4 pt-2">
      <div className="flex-grow">
          <ActionWithIcon size="sm" icon={type_icon} text={type_label} />
      </div>
      <div>
          <ActionWithIcon size="xs" icon={<ClipboardIcon/>} text={id} onClick={()=>copyTextToClipboard(id,()=>{
              global.addToast({intent:'info',text:'ID Copied to Clipboard'});
          })} />
      </div>
  </div>
}
