import React from "react";
import {SettingsGrid} from "../../../../../library/components/old/settings-grid";
import {useIntl} from "react-intl";
import {CodeIcon, FlagIcon, InformationCircleIcon, UsersIcon} from "@heroicons/react/solid";
import {CommunityDashboard} from "../../../../../library/components/metrics/community-dashboard";
import {StandardPageHeight} from "../../../../../library/playground/standard-page-framework";
import {EntityHeader} from "../../../../../library/components/core";

const items = [
    {
        title: 'community',
        icon: <InformationCircleIcon/>,
        links: {
            about: {
                text: 'about',
                desc: '',
                path: 'about'
            },
            designer: {
                text: 'designer',
                desc: '',
                path: 'designer'
            },
            delegated_access: {
                text: 'delegated_access',
                desc: '',
                path: 'delegated-access'
            },
            preferences: {
                text: 'preferences',
                desc: '',
                path: 'preferences'
            },
        }
    },
    {
        title: 'members',
        icon: <UsersIcon/>,
        desc: '',
        links: {
            membertypes: {
                text: 'member_types',
                desc: '',
                path: 'member-types'
            },

            applications: {
                text: 'applications',
                desc: '',
                path: 'applications'
            },
            /*
             rules: {
                 text: 'rules',
                 desc: '',
                 path: 'member-rules'
             },


            segments: {
                text: 'segments',
                desc: '',
                path: 'member-segments'
            },
             */

            preferences: {
                text: 'preferences',
                desc: '',
                path: 'preferences/members'
            },
            data_toolkit: {
                text: 'data_toolkit',
                desc: '',
                path: 'data-toolkit'
            },
        }
    },
    {
        title: 'account',
        icon: <FlagIcon/>,
        desc: '',
        links: {
            admins: {
                text: 'admins',
                desc: '',
                path: 'admins'
            },
            buildmode: {
                text: 'build_mode',
                desc: '',
                path: 'build-mode'
            },
            plan: {
                text: 'plan',
                desc: '',
                path: 'plan'
            }
        }
    },
    {
        title: 'developers',
        icon: <CodeIcon/>,
        desc: '',
        links: {
            api_keys: {
                text: 'api_keys',
                desc: '',
                path: 'developers/api-keys'
            },
            webhooks: {
                text: 'webhooks',
                desc: '',
                path: 'developers/webhooks'
            },
            events: {
                text: 'events',
                desc: '',
                path: 'developers/events'
            },
            logs: {
                text: 'logs',
                desc: '',
                path: 'developers/logs'
            }
        }
    }
];

export default function CommunitySettingsOverview({match}) {
    const {formatMessage: f} = useIntl();
    return <StandardPageHeight content full>
        <EntityHeader title="Community Settings" />
        <div className="space-y-2 px-4 pt-3">
            <div>
                <SettingsGrid base_link={`/${match.params.handle}/admin/settings`} items={items}/>
            </div>
            <CommunityDashboard/>
        </div>
    </StandardPageHeight>;
}