import React from 'react';
import {Field} from "./field";
import {Input} from "./text-input";

const props = {

};

const strength = {
  '0': {
      text: 'Weak',
      color: 'danger',
      w: '6'
  },
    '1': {
        text: 'Ok',
        color: 'yellow',
        w: '14'
    },
    '2': {
        text: 'Good',
        color: 'blue',
        w: '20'
    },
    '3': {
        text: 'Strong',
        color: 'success',
        w: '24'
    },
    '4': {
        text: 'Strong',
        color: 'success',
        w: '24'
    },
    '5': {
        text: 'Strong',
        color: 'success',
        w: '24'
    }
};

function StrengthMeter({str}) {
    if(!str) {
        return null;
    }
    const { text, color, w } = strength[`${str}`];


    return <div className="flex items-center">
        <div className={`pr-2 text-${color}-800 font-semibold`}>{text}</div>
        <div className="relative h-2 w-24">
            <div className={`absolute top-0 left-0 h-2 w-24 rounded-md bg-gray-200`}></div>
            <div className={`absolute top-0 left-0 transition-all h-2 w-${w} rounded-md bg-${color}-600`}></div>
        </div>
    </div>
}

export function PasswordInput({value="",placeholder="",score=0,onChange=()=>{},onEnter=()=>{},label="Password",autoFocus=false}) {
    const str = score.toString();
    const comp = value&&<StrengthMeter str={str} />;
    return <Field label={label} help_text="Minimum of 6 characters with uppercase, lowercase, and a number." corner_hint={comp}>
        <Input placeholder={placeholder} onEnter={onEnter} autoFocus={autoFocus} type="password" onChange={onChange} value={value} />
    </Field>
}