import React from 'react';
import styled from "styled-components";

const Wrapper = styled.div`
  
`;

// fit_height adjusts the height of the wrapper so it fits the window
export function CommonLayoutDirectory({children,scrollable=true,fit_height=false}) {

    return <Wrapper fit_height={fit_height} className={`${scrollable?"scrollable-content":""} ${fit_height?"":""} relative flex-grow`}>
       <div className="h-full">
            {children}
        </div>
    </Wrapper>
}