import React, {useContext, useEffect, useState} from 'react';
import {api_getSeries} from "../../../../api/community/posts/get-series";
import {GroupInboxWrapper} from "../inbox/wrapper";
import {api_getSeriesPosts} from "../../../../api/community/posts/get-series-posts";
import {FeedItem} from "../../../../library/components/posts-feed/feed-item";
import {GlobalContext} from "../../../global/global-context";
import {aa_formatDate} from "../../../../library/utilities/general/dates";
import {RecentImagesWidget} from "../widgets/recent-images";

export function GroupPostsSeriesPage({
                                         group,
                                         group_id,
                                         is_mobile,
                                         community_uid,
                                         is_moderator,
                                         is_admin,
                                         community,
                                         series_id,
                                         is_member
                                     }) {
    const global = useContext(GlobalContext);
    const [posts, setPosts] = useState(null);
    const [series_data, setSeriesData] = useState(null);

    useEffect(function () {
        api_getSeries(community_uid, series_id)
            .then(dt => {
                setSeriesData(dt);
                if (dt.post_count > 0) {
                    getPosts(dt.post_ids)
                }
            })
    }, [])

    function getPosts(ids) {
        api_getSeriesPosts(community_uid, series_id, ids)
            .then(posts => {
                const final = Object.entries(posts).sort((a, b) => (a.created_at < b.created_at) ? 1 : -1).map(([id, data]) => {
                    return {
                        id,
                        seen: false,
                        formatted_date: aa_formatDate(data.created_at),
                        data: data
                    }
                })
                setPosts(final);
            })
    }

    if (!series_data) {
        return null;
    }

    const has_items = !!posts;
    const count = series_data.post_count;

    const item_actions = {
        openFileViewer: global.openFileViewer,
        openImageViewer: global.openImageViewer,
    }

    const images = series_data.images ? series_data.images : [];
    const attachments = series_data.attachments ? series_data.attachments : [];

    return <div className={`block px-1.5 pt-4`}>
        <div>
            <div className="px-1">
                <div className="mb-1">
                    <div className="text-base font-bold text-gray-800">{series_data.name}</div>
                    <div className="text-sm text-gray-600">
                        {count} Post{count === 1 ? "" : "s"}
                    </div>
                </div>

                <div>
                    {has_items && posts.length === 0 &&
                    <div className="p-6 text-gray-500 text-sm text-center">
                        No messages found
                    </div>}
                    {has_items && posts.map(it => {
                        return <FeedItem archive_page show_series={false} item_actions={item_actions}
                                         is_mobile={is_mobile} id={it.id} community_handle={community.data.handle}
                                         show_context={false} community_uid={community.uid}
                                         formatted_date={it.formatted_date} read={it.seen} key={it.id} activity_meta={{
                            group,
                            community,
                            is_moderator,
                            is_member,
                            is_admin
                        }} {...it.data} />
                    })}
                </div>
            </div>
        </div>
        <div>
            {images.length>0&&<div>
                <RecentImagesWidget images={images} openImageViewer={global.openImageViewer} />
            </div>}
        </div>
    </div>
}