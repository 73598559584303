import React, {useState, useContext} from "react";
import {getCurrentLang, getCurrentTimezone} from '../../../library/utilities/general'
import 'firebase/auth';
import {authFetch} from "../../../config/network";
import {Button, Tabs} from "../../../library/components/core";
import Form from "../../../library/components/old/form";
import {FullScreenModal} from "../../../library/components/old/full-screen-modal";
import HandleSelector from "../../../library/components/old/handle-selector";
import Field from "../../../library/components/old/field";
import styled from "styled-components";
import VerifyAccountBlock from "../../auth/verify-account/VerifyAccountBlock";
import UnProgressBar from "../../community/home/un-progress-bar";
import {setDocumentTitle} from "../../../library/utilities/set-document-title";
import {GlobalContext} from "../global-context";
import {ColorInput} from "../../../library/components/core";
import {ACCENT_COLORS} from "../../../config/defaults";
import {TextAndEntityIcon} from "../../../library/components/old/text-and-status/entity";
import {analytics} from "firebase";

const Container = styled.div`

`;

const SetupCont = styled.div`
  padding: 32px;
`;

const SetupText = styled.div`
  padding: 12px;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
`;

const Text = styled.div`
  margin-bottom: 16px;
  font-size: 16px;
`;

function componentToHex(c) {
    let hex = parseInt(c).toString(16);
    return (hex.length === 1) ? "0" + hex : hex;
}

// todo unified colors utility sheet
export function rgbToHex(color) {
    const [r, g, b] = color.replace('rgb(', '').replace(')', '').split(',');
    const cr = componentToHex(r);
    const cg = componentToHex(g);
    const cb = componentToHex(b);
    return `#${cr}${cg}${cb}`;
}

export default function CreateCommunity(props) {
    const global = useContext(GlobalContext);
    const [community, setCommunity] = useState({
        handle_name: '',
        profile_picture: '',
        _customizing: false,
        profile_picture_color: '',
        handle: '',
        name: '',
        emoji: '',
        _checking_handle: false,
        bio: '',
        color: ACCENT_COLORS[0],
    });
    const [loading, setLoading] = useState(false);

    setDocumentTitle('Create Community');

    function disableBtn() {
        let {name, handle} = community;
        return name.length < 4 || handle === ''
    }

    function submit(disabled, e) {
        if (e) {
            e.preventDefault();
        }
        const {name, emoji, color, bio} = community;

        const handle = community.handle;

        if (disabled) {
            return;
        }

        if (name.length < 4) {
            return;
        }

        setLoading(true);

        const {history} = props;

        createCommunity(name, handle, emoji, color, bio, history);
    }

    function createCommunity(name, handle, emoji, color, bio, history) {

        const newUserData = {
            payload: {
                handle: handle,
                bio,
                emoji,
                name,
                profile_picture,
                profile_picture_color,
                color: color
            },
            options: {
                language: getCurrentLang(),
                is_claimed: true,
                timezone: getCurrentTimezone()
            }
        };

        authFetch("/communities/create", handleResult.bind(this), handleError.bind(this), "POST", newUserData)
    }

    function handleResult(result) {
        global.addToast({text: `Community was created.`, intent: 'success'});

        analytics().logEvent('create_community', {
            event_id: 'create_community',
            content_type: 'community'
        });

        props.history.push(`/${community.handle}/onboarding?flow=setup-community`);
    }

    function handleError(error) {
        global.addToast({text: `Community could not be created.`, intent: 'error'});
    }

    function handleChange(f, v) {
        let s = {...community};
        if (f === 'handle') {
            v = v.toLowerCase();
        }
        s[f] = v;
        setCommunity(s);
    }

    function handleBlur() {
        let s = community;
        s['handle_name'] = community.name;
        setCommunity({...s});
    }

    function updateData(f, v) {
        let nd = {...community};
        let val = v;

        if (typeof f === 'string') {
            if (f === 'entities') {
                setCommunity({...v});
            } else {
                nd[`${f}`] = val;
                setCommunity({...nd});
            }
        } else {
            nd = {...community, ...f};
            setCommunity({...nd})
        }
    }

    let {name, emoji, bio, color, _customizing, profile_picture, profile_picture_color, handle} = community;

    const {account_status, invites} = global.user;

    let content = null;

    const disabled = disableBtn();

    const actions = <>
        <Button intent='secondary' disabled={disabled} onClick={submit.bind(this, disabled)} text='Create'
                loading={loading}/>
    </>;

    if (account_status === 'account_confirmed' && invites > 0 && !loading) {

        content = <Form handleSubmit={submit.bind(this)}>
            <div className="mb-4">
                <TextAndEntityIcon onBlur={() => handleBlur()} label={"Name"} selector_type={'community-logo'}
                                   placeholder={"Book Club"} allowed_types={['emoji', 'gallery', 'image']}
                                   changeProfilePicture={(url, color) => {
                                       const new_color = community.color === ACCENT_COLORS[0] ? rgbToHex(color) : community.color;
                                       updateData({
                                           'profile_picture': url,
                                           'profile_picture_color': color,
                                           'emoji': "",
                                           'color': new_color
                                       })
                                   }}
                                   changeColor={(color) => {
                                       updateData({
                                           'profile_picture': "",
                                           'profile_picture_color': "",
                                           'emoji': "",
                                           'color': color
                                       });
                                   }} color={color} profile_picture={profile_picture}
                                   changeEmoji={(emoji) => {
                                       updateData({
                                           'profile_picture': "",
                                           'profile_picture_color': "",
                                           'emoji': emoji
                                       });
                                   }} autoFocus
                                   emoji={emoji} text={name} changeText={(v) => handleChange('name', v)}
                />

            </div>

            <Field optional type={"textarea"} label="About" onChange={handleChange.bind(this, 'bio')}
                   value={bio}/>

            <div className="mt-4">
                <HandleSelector invalid={handle === ''} checkingHandle={() => handleChange('_checking_handle', true)}
                                default_text={community.handle_name}
                                setHandle={(h) => {
                                    console.log('set handle', h)
                                    handleChange('_checking_handle', false);
                                    updateData({handle: h})
                                }} type={'community'}
                                scope={'global'} scope_id={''}/>
            </div>

            <div className="mt-4">
                <ColorInput value={color} onChange={handleChange.bind(this, 'color')}/>
            </div>
        </Form>
    } else if (loading) {
        content = <SetupCont>
            <UnProgressBar seconds={15}/>
            <SetupText className='text-gray-900'>
                Setting up {name}
            </SetupText>
        </SetupCont>;
    } else if (account_status !== 'account_confirmed') {
        content = <Container>

            <VerifyAccountBlock email={props.email}/>

        </Container>
    } else {
        content = <Container>
            <Text className='text-gray-800'>
                You need an invite
            </Text>

        </Container>
    }

    return <FullScreenModal size='small' actions={actions} close={() => {
        props.history.push('/memberships')
    }} title='Create Community' test="hello">

        {content}
    </FullScreenModal>;
}