import React, {useState} from "react";
import InputField from "../../library/components/old/input";
import {Button} from "../../library/components/core";
import styled from "styled-components";
import {authFetch} from "../../config/network";
import {TrashIcon} from '@heroicons/react/solid';

const Wrapper = styled.div`
 padding: 12px;
`;

const field_types = [
    {
        value: 'string',
        text: 'String'
    },
    {
        value: 'logo',
        text: 'Logo'
    },
    {
        value: 'community_uid',
        text: 'Community'
    }
];

function AddField({value, onChange, onAddField}) {
    return <div>
        <div>
            <div>ID</div>
            <InputField type="text" placeholder='ID' value={value.id} onChange={(val) => {
                onChange('id', val.toLowerCase());
            }}/>
        </div>
        <div>
            <div>Type</div>
            <select value={value.type} onChange={(e) => {
                onChange('type', e.target.value);
            }}>
                {field_types.map((r, i) => {
                    return <option key={r.value} value={r.value}>{r.text}</option>
                })}
            </select>
        </div>
        <div>
            <Button disabled={value.label === '' || value.id === ''} text='Add Field' onClick={() => {
                onAddField(value);
            }}/>
        </div>
    </div>
}

function TaxonomyFields({value, onChange, onRemoveField}) {
    return <div>
        <div>TaxonomyFields</div>
        {Object.values(value).map((item, i) => {
            return <div key={i}>
                <div>
                    <InputField type="text" placeholder='ID' value={item.id} onChange={(val) => {
                        onChange(item.id, 'id', val);
                    }}/>
                </div>
                <div>
                    <select disabled value={item.type} onChange={(e) => {
                        onChange(item.id, 'type', e.target.value.toLowerCase());
                    }}>
                        {field_types.map((r, i) => {
                            return <option key={r.value} value={r.value}>{r.text}</option>
                        })}
                    </select>
                </div>
                <div>
                    <div>Actions</div>
                    <div>
                        <div>
                            <Button onClick={() => {
                                onRemoveField(item.id)
                            }} text='Remove Field' icon={<TrashIcon/>}/>
                        </div>
                    </div>
                </div>
            </div>
        })}
    </div>
}

const field = {
    label: '',
    type: 'string'
};

export function AddTaxonomy() {
    const [label, setLabel] = useState('');
    const [label_id, setLabelId] = useState('');
    const [fields, setFields] = useState({});
    const [new_field, updateNewField] = useState({id: '', type: 'string'});
    const [loading, setLoading] = useState(false);

    function create() {
        setLoading(true);

        const res = () => {
            setLabel('');
            setFields({});
            updateNewField({id: '', type: 'string'});
            setLabelId('');
            setLoading(false);
        };

        let push_fields = {};

        const fi = Object.values(fields);

        for(let i=0;i<fi.length;i++) {
            const item = fi[i];
            push_fields[item.id] = item.type
        }

        const payload = {
            taxonomy_id: label_id, taxonomy_label: label, fields: push_fields
        };

        authFetch("/taxonomies/create", res, res, "POST", payload);
    }

    return <Wrapper>
        <div style={{marginBottom: '6px'}}>
            <div style={{marginBottom: '6px'}}>New Taxonomy</div>
            <InputField type="text" placeholder='Label: e.g. Interests' value={label} onChange={(val) => {
                setLabel(val);
            }}/>
        </div>
        <div style={{marginBottom: '6px'}}>
            <InputField type="text" placeholder='ID: e.g. interests' value={label_id} onChange={(val) => {
                setLabelId(val);
            }}/>
        </div>
        <div style={{marginBottom: '6px'}}>
            <TaxonomyFields value={fields} onChange={(id, f, v) => {
                let new_fields = fields;
                new_fields[id][f] = v;
                setFields({...new_fields});
            }} onRemoveField={(id) => {
                let new_fields = fields;
                delete new_fields[id];
                setFields({...new_fields});
            }}/>
        </div>
        <div style={{marginBottom: '6px'}}>
            <div>Add Field</div>
            <AddField value={new_field} onAddField={({id, type}) => {
                let new_fields = fields;
                if(fields[id]) {
                    alert('ID already exists');
                    return;
                }
                new_fields[id] = {
                    type,
                    label,
                    id
                };
                updateNewField({id: '', type: 'string'});
                setFields({...new_fields});
            }} onChange={(f, v) => {
                let new_field_data = new_field;
                new_field_data[f] = v;
                updateNewField({...new_field_data});
            }}/>
        </div>
        <div>
            <Button disabled={label === '' || label_id === ''} intent='primary' onClick={() => {
                create()
            }} loading={loading} text='Add Taxonomy'/>
        </div>
        <div>
            {JSON.stringify({label, label_id, fields})}
        </div>
    </Wrapper>
}