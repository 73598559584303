import React, {useEffect, useState} from 'react';
import {
    RichSelect,
    Field,
    InfoState,
    Input,
    Button
} from '../../../../library/components/core';
import ModeratorIcon from "../../../../library/components/old/moderator-icon";
import {copyTextToClipboard} from "../../../../library/utilities/general/copy-to-clipboard";
import {
    TrashIcon,
    MailIcon,
    AtSymbolIcon,
    GlobeIcon,
    UsersIcon,
    ReplyIcon,
    MailOpenIcon,
    UserGroupIcon
} from "@heroicons/react/solid";
const who_can_send = [
    {
        value: 'readonly',
        label: "Moderators",
        desc: "Mods can send emails to this group",
        icon: <ModeratorIcon height={20}/>
    },
    {
        value: 'members',
        label: "Group Members",
        desc: "Members can send emails to this group",
        icon: <UsersIcon/>
    },
    {
        value: 'community',
        label: "Community",
        desc: "All community members can send",
        icon: <UserGroupIcon />
    },
    {
        value: 'everyone',
        label: "Public",
        desc: "Anyone in the world",
        icon: <GlobeIcon/>
    }
];

const reply_to_email = [
    {
      value: 'list',
      label: "All Members",
      desc: "Replies go back to everyone",
      icon: <UsersIcon />
    },
    {
        value: 'sender',
        label: "Sender",
        desc: "Replies go to who sent the email",
        icon: <ReplyIcon/>
    },
    {
        value: 'custom',
        label: "Custom Email",
        desc: "Replies should go to a specific email",
        icon: <AtSymbolIcon/>
    }
];

function getReplyToOptions(access_level) {
    let a = [
        {
            value: 'list',
            label: "All Members",
            desc: "Replies go back to everyone",
            icon: <UsersIcon />
        },
        {
            value: 'sender',
            label: "Sender",
            desc: "Replies go to who sent the email",
            icon: <ReplyIcon/>
        },
        {
            value: 'custom',
            label: "Custom Email",
            desc: "Replies should go to a specific email",
            icon: <AtSymbolIcon/>
        }
    ];

    if(access_level==='readonly') {
        a[0].disabled=true;
        a[0].disabled_note="Unavailable";
    }

    return a;
}

export function MenuPanelGroupEmail({goBack, openChangeHandle, onCopy, data, createEmail, removeEmail, updateSettings}) {
    const [loading, setLoading] = useState(false);
    const [config, setConfig] = useState(data.config);

    useEffect(function () {
        setConfig(data.config);
    }, [data.config]);

    function saveChanges(co) {
        setTimeout(function () {
            updateSettings(co);
        },50)
    }

    const keys = Object.keys(config);

    if (keys.length === 0) {
        return <div>
            <InfoState icon={<MailOpenIcon/>} button={<Button onClick={() => {
                createEmail((group) => {
                    setLoading(false);
                    setConfig({...group.power_ups.unaty__mailing_lists.config});
                });
                setLoading(true);
            }} loading={loading} text="Create Email" size="sm"/>}
                       subtitle={"Get a real email address with all your group members that's always up-to-date."}
                       title={"Create Group Email"} intent="secondary"/>
        </div>;
    }

    return <div>
        <div className="space-y-4">
            <Field help_text={<a className="color-link" onClick={()=>openChangeHandle()}>Change Email Address</a>} label="Group Email"
                   corner_hint={<a className="link cursor-pointer" onClick={() => {onCopy();copyTextToClipboard(config.email_address)}}>Copy Email</a>}>
                <Input disabled placeholder="Text" type="text" value={config.email_address}/>
            </Field>
            <Field label="Who can send emails:">
                <RichSelect  onSelect={(v)=>{
                    let nc = {...config};
                    nc.access_level = v;
                    if(v==='readonly'&&nc.reply_preference==='list') {
                        nc.reply_preference = 'sender';
                    }
                    setConfig(nc)
                    saveChanges(nc);
                }} options={who_can_send} selected={config.access_level}/>
            </Field>
            <Field label="Default email reply goes to:">
                <RichSelect onSelect={(v)=> {
                    let nc = {...config, reply_preference: v};
                    setConfig(nc);
                    saveChanges(nc);
                }} options={getReplyToOptions(config.access_level)} selected={config.reply_preference}/>
            </Field>
            {config.reply_preference==='custom'&&<div className="">
                <Input onBlur={()=>{
                    saveChanges(config);
                }} leading={{
                    value: <MailIcon/>,
                    type: 'icon'
                }} placeholder="Custom Email" onChange={(v)=> {
                    setConfig({...config, custom_reply_to: v})
                }} value={config.custom_reply_to} type="text"/>
            </div>}
            <div className="pt-2 flex  ">
                <div className="flex-1">
                    <Button text="Delete Mailing List" minimal intent="danger"  size="sm" onClick={() => removeEmail(()=>{setConfig({});goBack()})}/>
                </div>
                <div className="">

                </div>
            </div>
        </div>
    </div>;
}