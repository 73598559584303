import {FirebaseDB} from "../../config/setup-firestore";

export function getManyItems(collection, ids, cb, community_uid = '') {
    let itemRefs = ids.map(id => {
        let split = collection.split('.');
        if (split.length === 2) {
            return FirebaseDB.collection(split[0]).doc(community_uid).collection(split[1]).doc(id).get();
        } else {
            return FirebaseDB.collection(collection).doc(id).get();
        }
    });
    Promise.all(itemRefs)
        .then(docs => {
            let items;

            items = docs.filter(d=>d.exists).map(doc => {
                let obj = doc.data();
                obj.id = doc.id;
                return obj;
            });

            cb(items);
        })
        .catch(error => console.log(error))
}

export function getManyItemsAsync(collection, ids, community_uid = '', return_doc) {
    let itemRefs = ids.map(id => {
        let split = collection.split('.');
        if (split.length === 2) {
            return FirebaseDB.collection(split[0]).doc(community_uid).collection(split[1]).doc(id).get();
        } else {
            return FirebaseDB.collection(collection).doc(id).get();
        }
    });
    return Promise.all(itemRefs)
        .then(docs => {
            if(return_doc) {
                return docs;
            }
            let items;

            items = docs.filter(d=>d.exists).map(doc => {
                let obj = doc.data();
                obj.id = doc.id;
                return obj;
            });

          return items;
        })
        .catch(error => console.log(error))
}