import React from 'react';
import styled from "styled-components";
import {CIModerator} from "../../../icons/custom/Moderator";

function getHeight(props) {
    const h = `${props.height}`
    if(h&&h.includes("rem")) {
        return `${h}`;
    } else if(h==='auto') {
        return 'auto';
    } else {
        return `${h}px`
    }
}

const ModIcon = styled.div`
display: ${props=>props.display};
    > svg {
    fill: ${props=>props.fill};
        height: ${props=>getHeight(props)};
    }
`;

export default function ModeratorIcon({fill="currentColor",display="inline-flex",height=24}) {

    return <ModIcon className={`${height===24?"icon-24px":""}`} display={display} height={height} fill={fill}>
        <CIModerator fill={fill} />
    </ModIcon>
}