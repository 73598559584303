import React, {useState} from 'react';
import {date_in_last_options, operators} from "../../../../app/community/reorganize/filtering-data";
import {ATEditFilter} from "./edit-filter";
import {Popover2} from "@blueprintjs/popover2";
import {ATPopoverContents} from "../components/shared";
import {ActionWithIcon, Field} from "../../core";
import {TrashIcon} from "@heroicons/react/outline";
import moment from "moment";
import {ATButtonBox} from "../views/select-view";

export function ATPopup({onOpen,usePortal=false,onClose,content,trigger,custom_classes='',open}) {
    return <Popover2 usePortal={usePortal} hoverOpenDelay={0} hoverCloseDelay={0} isOpen={open} enforceFocus={false} onInteraction={(nextOpenState,e)=>{
        if(nextOpenState) {
            onOpen(true);
        } else {
            if(e&&e.target&&e.target.className&&(e.target.id.indexOf('option')===-1&&e.target.className.indexOf('option')===-1)) {
                if(e.target.parentNode&&e.target.parentNode.className.indexOf('option')===-1) {
                    onClose();
                }
            }
        }
    }} placement='bottom-start' interactionKind='click' minimal content={content}
                     popoverClassName={`minimal-popover margin-top-8px py-1 ${custom_classes}`}>
        {trigger}
    </Popover2>
}

function Label({label}) {
    return <div >{label}</div>
}

function Operator({operator}) {
    return <div>{operator}</div>
}

function Value({value}) {
    return <div className="font-semibold">{value}</div>
}

function Filter({label,f_props,index,data,field,len,prev_join,onRemoveFilter,next_join,join,operator,type,value}) {
    const rounding = len<2 ? "rounded-lg" : join === 'and' ? `${next_join==='and'?"rounded-r":"rounded-r-lg"} ${prev_join==='and'?"rounded-l":"rounded-l-lg"}` : "rounded-lg";
    const padding = len<2 ? "px-2.5" : join === 'and' ? `${next_join==='and'?"pr-1":"pr-2.5"} ${prev_join==='and'?"pl-1":"pl-2.5"}` : "px-2.5";
const [open,setOpen] = useState(false);
const trigger = <ATButtonBox style="alt" onClick={()=>{}}>
    <div className="inline-flex space-x-1">
    <Label label={label} />
    <Operator operator={operator} />
    <Value value={value} />
    </div>
</ATButtonBox>

    const content = <ATPopoverContents size="xxl">
        <div className="p-2.5">
            <Field fill label="Edit Filter" corner_hint={<ActionWithIcon text="Delete" icon={<TrashIcon />} onClick={()=>f_props.onRemoveFilter(index)} />}>
        <ATEditFilter autoFocus={false} can_change_type={false} index={index} data={data} {...f_props} />
            </Field>
        </div>
    </ATPopoverContents>;

    return <ATPopup trigger={trigger} content={content} onClose={()=>setOpen(false)} onOpen={()=>setOpen(true)} open={open} placement='bottom-start' interactionKind='click' minimal
                    popoverClassName='minimal-popover py-3' targetClassName='popover-target-span' />
}

const mock = [
    {
        join: "and",
        label: "Member Type",
        field: 'member_type',
        operator: "is",
        type: "select",
        value: "Inaktive"
    },
    {
        join: "and",
        field: 'address.city',
        label: "City",
        type: "text",
        operator: "contains",
        value: "Munich"
    },
    {
        join: "or",
        field: 'address.country',
        label: "Country",
        type: "select",
        operator: "is",
        value: "Germany"
    }
];

function And({onClick}) {
    return <div onClick={onClick} className="bg-blue-100 cursor-pointer mx-1.5 text-gray-600 px-1 py-0.5 inline-block rounded-md text-xs font-medium">
        AND
    </div>
}

function Or({onClick}) {
    return <div onClick={onClick} className="bg-orange-200 cursor-pointer mx-1.5 text-gray-600 px-1 py-0.5 inline-block rounded-md text-xs font-medium">
OR
    </div>
}

const optionHelper = (vl,arr) => arr.filter(a=>(a.id===vl||a.value===vl))[0];

const validTs = (timestamp) => (new Date(timestamp)).getTime() > 0;

function getPrettyFilterValue(type,operator,value,def) {
    if(operator==='empty') {
        return 'empty'
    } else if(operator==='not_empty') {
        return 'empty'
    }

    if(type==='select'||type==='multi-select'||type==='data_integrity'||type==='account_status'||type==='member_type'||type==='gender'||type==='country') {

        if(operator==='in'||operator==='not-in') {
            let all = [];
            // if is object
            if(typeof value === 'object') {
                const keys = Object.keys(value);
                all = keys.map(key=>{
                    if(!key) {
                        return 'empty'
                    }
                    const opt = optionHelper(key,def.options)
                    if(!opt) {
                        return 'empty'
                    }
                    return opt.label
                });
                if(all.length>2) {
                    return `${all.length}`;
                }
                return all.join(', ');
            }
            return "";
        }

        const it = optionHelper(value,def.options);
        if(!it) {
            return "--"
        } else {
            return it.label;
        }
    } else if(type==='number') {
        return `${value}`
    } else if(type==='text') {
        const t_len = value.length;
        if(t_len>20) {
            return `"${value.substring(0,20)}.."`;
        }
        return `"${value}"`
    } else if(type==='date') {
        if(operator==='in_last') {
            const sp = value.split('-');
            return `${sp[0]} ${date_in_last_options.filter(a=>a.value===sp[1])[0].label}`
        }
        if(validTs(value)) {
            return moment(value).format('MMM DD, YYYY');
        }
        return "-"
    } else if(type==='boolean') {
        return `${JSON.stringify(value)}`
    }
    return "TODO"
}

function prettifyFilters(filters,definitions) {
    let final = [];

    filters.forEach(filter=>{

        const filter_join = filter.join?filter.join:"and";
        const filter_definition = definitions.filter_options[filter.field];
        if(!filter_definition) {
            return;
        }
        const filter_type = filter_definition.type;
        const op_def = operators[filter_type].filter(a=>a.value===filter.operator)[0];

        final.push({
            label: filter_definition.label,
            field: filter.field,
            operator: op_def&&op_def.desc?op_def.desc:filter.operator,
            join: filter_join,
            data: filter,
            type: filter_type,
            value: getPrettyFilterValue(filter_type,filter.operator,filter.value,filter_definition),
        })
    })
    return final;
}

export function ATPrettyFilters({filters,onRemoveFilter,filter_avail_opts,onEditFilter,context,s,index,filter_options,add_filter,can_add_filter,definitions}) {

    const final = prettifyFilters(filters,definitions);
    const len = filters.length;
    const f_props = {
        all_options: filter_options,
        avail_options: filter_avail_opts,
        onRemoveFilter,
        editFilter: (nd)=>onEditFilter(index,nd),
        context: context
    };
    return <div style={{maxWidth:'420px'}} className="flex items-center overflow-x-auto whitespace-nowrap no-scrollbars">
        {final.map((it,k)=>{
            const is_and = it.join==='and';
            const is_or = it.join==='or';
            return <div className="inline-flex items-center" key={`${it.field}`}>
                {is_and&&k>0&&len>1&&<div><And onClick={()=>{
                    onEditFilter(k, {
                        ...it.data,
                        join: 'or'
                    });
                }} /></div>}
                {is_or&&k>0&&len>1&&<div><Or onClick={()=>{
                    onEditFilter(k, {
                        ...it.data,
                        join: 'and'
                    });
                }} /></div>}
                <Filter f_props={f_props} index={k} data={it.data} len={len} prev_join={final[(k-1)]?.join?final[(k-1)].join:""} next_join={final[(k+1)]?.join?final[(k+1)].join:""} {...it} />
            </div>
        })}
        {can_add_filter&&<div className="pl-1.5">
            {add_filter}
        </div>}
    </div>
}