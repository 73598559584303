import React, {useContext, useEffect, useState} from 'react';
import {GlobalContext} from "../../../global/global-context";
import ModeratorIcon from "../../../../library/components/old/moderator-icon";
import {
    FlagIcon,
    GlobeAltIcon,
    LightningBoltIcon,
    PlusIcon,
    StarIcon,
    UserIcon,
    UsersIcon
} from "@heroicons/react/outline";
import {RulesInlineSelect} from "../../../../library/components/rules/rules-inline-select";
import {ActionWithIcon, SectionHeader} from "../../../../library/components/core";
import {CommunityAccessManager} from "../../../../library/components/community-access-manager";
import {Popover2} from "@blueprintjs/popover2";
import {getAccessMeta} from "../../../../library/components/delegated-access-block";
import {EntityInfo} from "../../../../library/utilities/localstorage/entity-localstorage";
import {authFetch} from "../../../../config/network";

const options = [
    {
        id: "viewer",
        label: "Viewer"
    },
    {
        id: "editor",
        label: "Editor"
    },
    {
        id: "restricted",
        label: "Restricted"
    }
];

function AccessSelector({selected, can_remove, setSelected}) {

    let fo = [...options];

    if(can_remove) {
        fo.push({
            id: "remove",
            label: "Remove"
        })
    }

    return <RulesInlineSelect placement="bottom-end" onSelect={(a) => setSelected(a)} layout="meta" size="sm" inline searchable={false}
                              options={fo} selected={selected}/>
}

function Item({icon, title, can_remove, value, onChange}) {
    return <div className="grid gap-2 py-1" style={{gridTemplateColumns: 'auto 1fr auto'}}>
        <div className="w-5 m-0.5 h-5 text-gray-600">
            {icon}
        </div>
        <div className="text-base font-medium text-gray-800">
            {title}
        </div>
        <div>
            <AccessSelector can_remove={can_remove} selected={value} setSelected={(v) => onChange(v)}/>
        </div>
    </div>
}

const spec = [
    {
        title: "Group",
        items: [
            {
                icon: <StarIcon/>,
                title: "Moderators",
                id: "moderators",
                initial: "editor"
            },
            {
                icon: <UsersIcon/>,
                title: "Members",
                id: "members",
                initial: "viewer"
            }
        ]
    },
    {
        title: "Community",
        items: [
            {
                icon: <LightningBoltIcon/>,
                title: "All Roles",
                id: "all_roles",
                initial: "editor"
            },
            {
                icon: <FlagIcon/>,
                title: "All Members",
                id: "community",
                initial: "viewer"
            }
        ]
    },
    {
        title: "External",
        items: [
            {
                icon: <GlobeAltIcon/>,
                title: "Public",
                id: "public",
                initial: "restricted"
            }
        ]
    }
];

function SmallTitle({children}) {
    return <div className='text-sm font-medium text-gray-600'>
        {children}
    </div>
}

export function getDriveAccess(fd) {
    return {
        general: {
            moderators: fd.access && fd.access.moderators || "editor",
            community: fd.access && fd.access.community || "viewer",
            all_roles: fd.access && fd.access.all_roles || "viewer",
            public: fd.access && fd.access.public || "restricted",
            members: fd.access && fd.access.members || "viewer"
        },
        individual: {
            member_types: fd.individual_access && fd.individual_access.member_types || {},
            roles: fd.individual_access && fd.individual_access.roles || {},
            teams: fd.individual_access && fd.individual_access.teams || {}
        }
    }
}

function AddIndividualEntity({init_access,addEntity,meta}) {
    const content = <CommunityAccessManager show_added={false} show={['roles','member_types']} show_search={false} updateAccess={(a,b)=>{
        addEntity(a);
    }} {...meta} types={['roles']} init_access={init_access}/>;

    return <Popover2 usePortal={false} targetTagName='div' interactionKind='click' placement={'bottom-end'} onClose={() => {}}
                     popoverClassName='minimal-popover my-0.5 w-96 rounded-md shadow-lg border border-gray-200 bg-white' minimal
                     content={content}>
        <ActionWithIcon stopPropogation={false} text="Add" inverse onClick={()=>{}} />
    </Popover2>
}

function Wrapper({indiv,icon,type,onChange,value,data}) {
    return <Item can_remove icon={icon} type={type} onChange={onChange} value={value} title={data.name} />
}

function getNewIndividualAccess(current,updated) {
    let final = {};

    Object.keys(updated).forEach(type=>{
        final[type] = {};
        Object.keys(updated[type]).forEach(id=>{
            if(current[type][id]) {
                final[type][id] = current[type][id];
            } else {
                final[type][id] = 'viewer';
            }
        });
    })

    return final
}

function getIconFromType(type) {

    if(type==='roles') {
        return <LightningBoltIcon />
    }

    if(type==='member_types') {
return <UserIcon />
    }

    return <StarIcon />
}

export function FolderModalAccessTab({
                                         setData,
                                         community, cb = () => {
    }, id, email_list_power_up, folder_data = {}
                                     }) {
    const [modal, setModal] = useState("");
    const [loading, setLoading] = useState("");
    const global = useContext(GlobalContext);

    const access = getDriveAccess(folder_data);

    function removeIndividual(value, eid, scope, type) {
        let pass = {
            access: {...access.general},
            individual_access: {...access.individual}
        };

        delete pass[scope][type][eid];

        setData(pass);
    }

    function saveAccess(value, eid, scope, type) {
        let pass = {
            access: {...access.general},
            individual_access: {...access.individual}
        };

        if(!type) {
            pass[scope][eid] = value;
        } else {
            pass[scope][type][eid] = value;
        }
        setData(pass);
    }

    function handleAddEntity(ac) {
        let pass = {
            access: {...access.general},
            individual_access: getNewIndividualAccess(access.individual,ac)
        };
        setData(pass);
    }

    const init_access = {
        roles: {...access.individual.roles},
        member_types: {...access.individual.member_types}
    };

    let individuals = [];

    const roles_keys = Object.keys(init_access.roles);
    const member_types_keys = Object.keys(init_access.member_types);

    roles_keys.forEach(a=>{
        individuals.push({
            type: 'roles',
            id: a
        })
    })

    member_types_keys.forEach(a=>{
        individuals.push({
            type: 'member_types',
            id: a
        })
    })

    const meta = getAccessMeta(community);

    return <div className={`space-y-2`}>
        <div>
            <SectionHeader padded={false} title="General"/>

            {spec.map(it => {
                return <div key={it.title}>
                    <SmallTitle>{it.title}</SmallTitle>
                    <div className="pt-0.5 pb-4">
                        {it.items.map(item => {
                            return <Item key={item.id} onChange={(b) => {
                                saveAccess(b, item.id, 'access')
                            }} value={access.general[item.id]} {...item} />
                        })}
                    </div>
                </div>
            })}
        </div>
        <div>
            <SectionHeader padded={false} title="Individual" action={<AddIndividualEntity addEntity={handleAddEntity} init_access={init_access} meta={meta} />} />
            <div className="pt-0.5 pb-4">
                {individuals.map(indiv=>{
                    return <EntityInfo key={`${indiv.type}-${indiv.id}`} community_uid={community.uid} id={indiv.id} type={indiv.type}>
                        <Wrapper onChange={(b) => {
                            if(b==='remove') {
                                removeIndividual(b,indiv.id,'individual_access', indiv.type);
                            } else {
                                saveAccess(b, indiv.id, 'individual_access', indiv.type)
                            }
                        }} icon={getIconFromType(indiv.type)} type={indiv.type} value={access.individual[indiv.type][indiv.id]} info={indiv} />
                    </EntityInfo>
                })}
            </div>
        </div>

    </div>
}