export const member_types_suggestions = [
    {
        plural: 'Coaches',
        emoji: '📋',
        singular: 'Coach',
        abbreviation: 'Co',
        tags: ['sports']
    },
    {
        plural: 'Players',
        emoji: '⛹️‍♂️',
        singular: 'Player',
        abbreviation: 'Pl',
        tags: ['sports']
    },
    {
        plural: 'Athletes',
        emoji: '🏃',
        singular: 'Athlete',
        abbreviation: 'A',
        tags: ['sports']
    },
    {
        plural: 'Parents',
        emoji: '👩‍👦',
        singular: 'Parent',
        abbreviation: 'Pa',
        tags: ['sports']
    },
    {
        plural: 'Volunteers',
        emoji: '✋',
        singular: 'Volunteer',
        abbreviation: 'V',
        tags: ['political']
    },
    {
        plural: 'Staff',
        singular: 'Staff Member',
        emoji: '🧑‍💼',
        abbreviation: 'S',
        tags: []
    },
    {
        plural: 'Advisors',
        singular: 'Advisor',
        emoji: '🧭',
        abbreviation: 'A',
        tags: []
    },
    {
        plural: 'Alumni',
        singular: 'Alum',
        abbreviation: 'A',
        emoji: '🎓',
        tags: ['educational','fraternal']
    },
    {
        plural: 'Donors',
        singular: 'Donor',
        emoji: '💰',
        abbreviation: 'D',
        tags: ['political']
    },
    {
        plural: 'Honorary Members',
        singular: 'Honorary Member',
        emoji: '🏅',
        abbreviation: 'HM',
        tags: ['service']
    },
    {
        plural: 'Elders',
        singular: 'Elder',
        abbreviation: 'E',
        emoji: '🧙',
        tags: ['religious']
    },
    {
        plural: 'Students',
        singular: 'Student',
        abbreviation: 'S',
        emoji: '🧑‍🎓',
        tags: ['educational']
    },
    {
        plural: 'Teachers',
        singular: 'Teacher',
        abbreviation: 'T',
        emoji: '👩‍🏫',
        tags: ['educational']
    },
    {
        plural: 'Ambassadors',
        singular: 'Ambassador',
        emoji: '👋',
        abbreviation: 'A',
        tags: ['social']
    },
    {
        plural: 'Guests',
        singular: 'Guest',
        abbreviation: 'G',
        emoji: '👫',
        tags: ['social']
    },
    {
        plural: 'Active Members',
        singular: 'Active Member',
        emoji: '😎',
        abbreviation: 'AM',
        tags: ['fraternal']
    },
    {
        plural: 'Pledges',
        singular: 'Pledge',
        emoji: '🦊',
        abbreviation: 'Pl',
        tags: ['fraternal']
    },
    {
        plural: 'Inactive Members',
        emoji: '👨‍💼',
        singular: 'Inactive Member',
        abbreviation: 'IM',
        tags: ['fraternal']
    },
    {
        plural: 'Aktive',
        singular: 'Aktiver',
        emoji: '👦',
        abbreviation: 'CB',
        tag_only: true,
        tags: ['corps']
    },
    {
        plural: 'Inaktive',
        emoji: '👨',
        singular: 'Inaktiver',
        abbreviation: 'iaCB',
        tag_only: true,
        tags: ['corps']
    },
    {
        plural: 'Füchse',
        emoji: '🦊',
        singular: 'Fuchs',
        abbreviation: 'F',
        tag_only: true,
        tags: ['corps']
    },
    {
        plural: 'Alte Herren',
        emoji: '👴🏽',
        singular: 'Alter Herr',
        abbreviation: 'AH',
        tag_only: true,
        tags: ['corps']
    },
];