import React from 'react';
import styled from "styled-components";
import {PrCoverPhoto} from "./cover-photo";
import {PrProfilePicture} from "./profile-picture";
import {PrActions} from "./actions";
import {PrTitle} from "./title";
import {PrBio} from "./bio";
import {PrMeta} from "./meta";

const Frame = styled.div`
    position: relative;
`;

export const PrContainer = ({data,changeBio,editing,pp_loading,cp_loading,setCoverPhoto,setProfilePicture}) => {
    return <Frame>
        <PrCoverPhoto loading={cp_loading} cover_photo_color={data.cover_photo_color} is_admin={data.is_admin} editing={editing} setCoverPhoto={setCoverPhoto} cover_photo={data.cover_photo} />
        <PrProfilePicture loading={pp_loading} profile_picture_color={data.profile_picture_color} setProfilePicture={setProfilePicture} editing={editing} color={data.color} text={data.title} profile_picture={data.profile_picture} />
        <PrActions actions={data.actions} />
        <PrTitle is_private={data.is_private} title={data.title} subtitle={data.subtitle} subtitle2={data.subtitle2} />
        <PrBio editing={editing} changeBio={changeBio} bio={data.bio} />
        <PrMeta meta={data.meta} />
    </Frame>
};