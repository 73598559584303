import React, {useEffect, useState} from "react";
import styled from "styled-components";
import ImageUploader from "../image-uploader";
import {PSCoverPhotos} from "./ps-cover-photos";
import {PSProfilePhotos} from "./ps-profile-photos";
import {Button} from "../../core";
import {EntityColorSelector} from "./color-selector";
import {EmojiPicker} from "../emoji-picker";
import {Popover2} from "@blueprintjs/popover2";
import {PsCommunityLogos} from "./ps-community-logos";
import {BackgroundPatternBuilder} from "../../background-pattern-builder";
import {NewTooltip} from "../../reorganize/new-tooltip";
import {useIsMobile} from "../../../../app/global/global-router";

const Wrapper = styled.div`
  width: 100%;
  min-width: 350px;
  max-width: 420px;
       box-shadow: 0 0 0 1px rgba(0,0,0,0.05),0 1px 3px 0 rgba(0,0,0,0.1),0 1px 2px 0 rgba(0,0,0,0.06) !important;
       background-color: #fff;
       border-radius: 6px;
`;

const Header = styled.div`
  display: flex;
  border-bottom: 1px solid #efefef;
    padding: 0 10px;
`;

const HeaderItem = styled.div`
        padding: 10px 8px 8px 8px;
    font-size: 15px;
    margin-right: 4px;
    border-bottom: ${props => props.active ? '2px solid #1b95e0' : '2px solid #fff'};
    opacity: ${props => props.active ? '1' : '0.5'};
    font-weight: 500;
`;

const Body = styled.div`
  padding: 8px 0px;
      max-height: 320px;
          min-height: 200px;
    overflow-y: auto;
`;

const Section = styled.div`
  padding: 4px 0px;
`;

const SectionTitle = styled.div`
      padding: 0 14px;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0.7px;
`;

const Grid = styled.div`
      display: grid;
    grid-template-columns: ${props => props.square ? '1fr 1fr 1fr 1fr 1fr' : '1fr 1fr 1fr'};
    > div > span > span {
        width: 100%;
        display: block;
    }
    grid-gap: 8px;
        padding: 0 14px;
       margin-top: 6px;
    margin-bottom: 8px;
`;

const ItemOuter = styled.div`
> span {
height: ${props => props.square ? '' : '56px'};
  padding-bottom: ${props => props.square ? '100%' : ''};
}
`;

function getBkg(url,square) {
    if (url.startsWith('https://')) {
        return `url('${url}_${square?'medium':'small'}?alt=media')`;
    } else {
        return `${url}`;
    }
}

const ItemDiv = styled.div`
  width: 100%;
  outline: none;
  height: ${props => props.square ? '' : '56px'};
  padding-bottom: ${props => props.square ? '100%' : ''};
  background: ${props => getBkg(props.url,props.square)};
 border-radius: 6px;
     background-position: center;
    background-size: cover;
  background-color: ${props => props.color};
`;

function Item(props) {
    return <ItemOuter onClick={(e)=> {
        e.stopPropagation();
        props.setPhoto(props.url, props.color);
    }} {...props}>
        <ItemPopover {...props}>
            <ItemDiv {...props} />
        </ItemPopover>
    </ItemOuter>
}

const CustomBox = styled.div`
  padding: 16px;
  text-align: center;
`;

const CustomMeta = styled.div`
  font-size: 15px;
  padding-bottom: 16px;
`;

const CustomButtonBox = styled.div`
  display: flex;
  justify-content: center;
`;

function getTooltipText(title, author) {
    if (title && author) {
        return `${title} - ${author}`;
    } else if (title) {
        return `${title}`;
    } else {
        return `${author}`;
    }
}

function ItemPopover({children, title, author}) {
    return <NewTooltip margin message={getTooltipText(title, author)}>
        {children}
    </NewTooltip>
}

function getInitMode(type) {
    if(type==='cover-photo') {
        return 'gallery'
    } else if(type==='group-cover') {
        return 'gallery'
    } else if(type==='color') {
        return 'color'
    } else if(type==='emoji') {
        return 'color'
    }  else {
        return 'custom'
    }
}

function getTitle(type) {
    if(type==='cover-photo') {
        return 'Cover Photo'
    } else if(type==='group-cover') {
        return 'Cover Photo'
    } else if(type==='community-logo') {
        return 'Community Logo'
    } else if(type==='sign-in-page') {
        return 'Sign In Page'
    } else {
        return 'Profile Picture'
    }
}
function getPassType(type) {
    if(type==='cover-photo') {
        return 'cover-photo'
    } else if(type==='group-cover') {
        return 'group-cover'
    } else if(type==='email-header') {
        return 'email-header'
    } else if(type==='community-logo') {
        return 'community-logo'
    } else if(type==='sign-in-page') {
        return 'sign-in-page'
    } else {
        return 'profile-picture'
    }
}

export function PhotoSelector({children,onOpen=()=>{},placement="bottom-start",type='profile-picture',modes=[{id:'gallery',label:'Gallery'},{label:'Custom',id:'custom'}],setPhoto}) {
    // cover-photo
    const [open, setOpen] = useState(false);
    const [temp, setTemp] = useState(null);
    const [editing_image, setEditingImage] = useState(null);
    useEffect(function() {
        onOpen(open);
    }, [open]);
    const is_mobile = useIsMobile();
    const square = type !== 'cover-photo' && type !== 'sign-in-page' && type !== 'group-cover' && type !== 'email-header';
    const content = type === 'cover-photo' || type==='sign-in-page' || type === 'group-cover' ? PSCoverPhotos : type === 'community-logo' ? PsCommunityLogos : PSProfilePhotos;
    const [mode, changeMode] = useState(getInitMode(type));
    // add / edit cover

    const body = <Wrapper>
        <Header>
            {modes.map((md,k)=>{
                return <HeaderItem key={k} onClick={(e) => {
                    e.stopPropagation();
                    changeMode(md.id)
                }} className='text-gray-900 hover-opacity' active={mode === md.id}>{md.label}</HeaderItem>
            })}
        </Header>
        <Body id="main-selector">
            {mode === 'gallery' && content.map((sec) => {
                return <Section key={sec.title}>
                    <SectionTitle>
                        <a className='text-gray-700 color-link-reverse-3' rel="noopener noreferrer" target='_blank'
                           href={sec.url}>{sec.title}</a>
                    </SectionTitle>
                    <Grid square={square}>
                        {sec.images.map((item) => {
                            return <Item setPhoto={(url,color)=>{setOpen(false);setPhoto(url,color)}} key={item.url} square={square} {...item} className='hover-opacity'/>
                        })}
                    </Grid>
                </Section>
            })}
            {mode==='pattern'&&<div className="px-4 py-2">
                <div className="w-full relative">
                <BackgroundPatternBuilder onSelectPattern={()=>{
                    let content_cont = document.getElementById('main-selector');
                    content_cont.scrollTop = 0;
                }} onSelect={(p)=>{
                    setTemp({
                        url: `pattern_${p.id}_${p.fore}`,
                        color: p.back
                    })
                }} />
                    <div className="absolute top-2 right-2">
                        {temp&&<Button onClick={()=>{
                            setPhoto(temp.url,temp.color)
                            setOpen(false);
                        }} size="sm" intent="success" text="Done" />}
                    </div>
                </div>
            </div>}
            {mode==='color'&&<div>
                <EntityColorSelector initial={"H"} onSelect={(color)=>{setOpen(false);setPhoto("color",color)}} />
            </div>}
            {mode==='emoji'&&<div>
                <EmojiPicker layout="photo-selector" changeEmoji={(emoji)=>{setOpen(false);setPhoto("emoji",emoji.native?emoji.native:emoji.imageUrl)}} />
            </div>}
            {mode === 'custom' && <CustomBox>
                <CustomMeta className='text-gray-800'>
                    Select a photo under 5mb.
                </CustomMeta>
                <CustomButtonBox>
                    <ImageUploader doneEditingImage={()=>setEditingImage(false)} onEditingImage={()=>setEditingImage(true)} style={{width: '100%'}} title={getTitle(type)} type={getPassType(type)}
                                   handleUrl={(url, color) => {
                                       setOpen(false)
                                       setPhoto(url, color);
                                       setEditingImage(false);
                                   }}>
                        <Button text={is_mobile?"Browse Photos":'Browse your Computer'} />
                    </ImageUploader>
                </CustomButtonBox>
            </CustomBox>}
        </Body>
    </Wrapper>;
    const target = <div onClick={(e)=>{e.stopPropagation();setOpen(true)}}>
        {children}
    </div>;
    if(!editing_image&&!open) {
        return target;
    }
    return <Popover2 portalClassName={`relative ${is_mobile?"mobile-photo-selector":""}`} content={body} usePortal={is_mobile} enforceFocus={true} placement={placement} onOpen={()=>setOpen(true)} isOpen={open||editing_image} onClose={()=>setOpen(false)} minimal popoverClassName={`popup-box-shadow popover-standard my-1 -mx-1 z-9999 `}>
        {target}
    </Popover2>
}