import React, {useContext, useEffect, useState} from 'react';
import {export_separator_ref, IntlExport, regional_date_formats} from "../reorganize/intl-export";
import {CSVLink} from "react-csv";
import moment from "moment";
import {
    buildMemberExportHeaders,
    es_exportMemberSelection, es_getAllMemberData
} from "../../../app/community/directory/export";
import {members_headers} from "../../../app/community/directory/headers";
import {analytics} from "firebase";
import {logEngagement} from "../../../api/analytics";
import {GlobalContext} from "../../../app/global/global-context";
import {FirebaseDB} from "../../../config/setup-firestore";
import {AddManyToGroup} from "../add-many-to-group";
import {EditAttributeMany} from "../edit-attribute-many";
import {authFetch} from "../../../config/network";
import {api_getNameMap} from "../../../api/community/members/get-name-map";

async function getNameMap(community_uid) {
    return await FirebaseDB
        .collection('communities')
        .doc(community_uid)
        .collection('meta')
        .doc('name-map')
        .get()
        .then(d => d.data());
}

export function ATMassActions({community, deselectAll, selected_ids = [], manage_actions, group_id, modal, handleCloseModal=()=>{}, definitions, view, context}) {
    const [loading, setLoading] = useState(false);
    const [_modal, setModal] = useState(null);
    const [export_data, setExportData] = useState([]);
    const [exportRef, setRef] = useState(React.createRef());
    const [separator, setSeparator] = useState(',');
    const headers = buildMemberExportHeaders(members_headers, community.custom_fields);
    const global = useContext(GlobalContext);

    const {addToast} = global;

    useEffect(function () {
        if (modal) {
            if(modal==='invite') {
                const act = manage_actions.filter(a=>a.id==='invite')[0];

                if(act) {
                    es_getAllMemberData(view,definitions,context,group_id)
                        .then(result=>{
                            act.onClick(result.ids,result.obj);
                        })
                }
                return;
            } else {
                setModal(modal)
            }
        }
    }, [modal])

    useEffect(function () {
        if(!_modal) {
            handleCloseModal();
        }
    }, [_modal]);

    function addToGroup(group_id,cb) {
        if (!group_id || !selected_ids || selected_ids.length === 0) {
            return;
        }

        const obj = {};
        global.addToast({text: 'Adding members to group', intent: 'info'});
        selected_ids.forEach(id => {
            obj[id] = true;
        })

        const payload = {
            entities: {
                members: obj
            },
            id: group_id,
            member_id: community.member_id,
            community_uid: community.uid
        };

        const res = () => {
            if(cb) {
                cb();
            }
            global.addToast({text: 'Members added to group', intent: 'success'});
        };

        authFetch("/groups/add-new-entities", res, res, "POST", {payload});
    }

    async function bulk_handleExport(payload, members) {
        addToast({text: 'Export has started', intent: 'info'});

        const format = {
            date_format: regional_date_formats[payload.date_format],
            delimiter: export_separator_ref[payload.delimiter].separator
        }

        const name_map = await getNameMap(community.uid);
        es_exportMemberSelection(headers, view, definitions, context, name_map, format)
            .then(members => {
                setExportData(members);
                setTimeout(
                    () => {
                        if (exportRef && exportRef.current) {
                            exportRef.current.link.click();
                        } else {
                            console.warn('cannot export')
                        }
                        setLoading('');
                        handleCloseModal();
                    }, 250);
                addToast({text: 'Export ready!', intent: 'success'});
                analytics().logEvent('export_members', {
                    event_id: 'export_members',
                    content_type: 'community',
                    community_uid: community.uid
                });
                logEngagement("export_members", {
                    user_uid: community.member.user_uid,
                    community_uid: community.uid,
                    member_id: community.member_id
                })
            })
    }

    return <div>

        {_modal === 'pre-export' && <IntlExport onClose={() => setModal(null)} handleContinue={(payload) => {
            setLoading('exporting');
            bulk_handleExport(payload)
                .then(() => {
                });
            setSeparator(export_separator_ref[payload.delimiter].separator)
            setModal(null);
            setLoading('');
        }}/>}

        {_modal === 'add-to-group'&&<AddManyToGroup onClose={() => setModal(null)} handleContinue={(payload) => {
            setLoading('adding-to-group');
            // payload.group === id
            addToGroup(payload.group,()=>{
                deselectAll();
            });
            setModal(null);
            setLoading('');
        }} />}

        {_modal === 'edit-attribute'&&<EditAttributeMany onClose={() => setModal(null)} handleContinue={(payload) => {
            setLoading('editing-many-attribute');
            // payload.attribute, value
            setModal(null);
            setLoading('');
        }} />}

        {export_data.length > 0 && <CSVLink style={{display: 'none'}} ref={exportRef} separator={separator}
                                            filename={`${community.data.name} Member Export ${moment().format('MMM Do, YYYY - hh:mm')}.csv`}
                                            data={export_data} headers={headers}/>}
    </div>
}