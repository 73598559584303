import React, {useContext, useEffect, useState} from "react";
import {FirebaseDB} from "../../../../../../config/setup-firestore";
import {CommunityContext} from "../../../../community-context";
import {getGeneralTime, getPrettyTime} from "../../../../../../library/utilities/general/dates";
import moment from "moment";
import {EntityInfo} from "../../../../../../library/utilities/localstorage/entity-localstorage";
import {DEFAULT_USER_PHOTO} from "../../../../../../config/defaults";
import {InfoState, InlineLoader} from "../../../../../../library/components/core";

function MemberData({data}) {
    const {image, name} = data;
    return <div className="flex">
        <div>
            <img src={image ? `${image}_small?alt=media` : `${DEFAULT_USER_PHOTO}_small?alt=media`}
                 className="h-5 w-5 rounded"/>
        </div>
        <div className="pl-2 font-medium text-gray-600">
            {name}
        </div>
    </div>
}

export function InlineMemberEntity({community_uid, id}) {

    return <EntityInfo community_uid={community_uid} id={id} type={'members'}>
        <MemberData/>
    </EntityInfo>
}

function Row({handle, url, created_at, created_by, community_uid}) {
    return <div className="grid gap-4 grid-cols-8 px-4 py-3 border-b border-gray-200">
        <div className="flex">

            <img src={url} className="h-5 w-5"/>

        </div>
        <div className="col-span-2">
            <div className="text-sm truncate font-semibold text-gray-800">{handle}</div>
        </div>
        <div className="col-span-2 text-sm text-gray-500">
            <InlineMemberEntity id={created_by} community_uid={community_uid}/>
        </div>
        <div className="col-span-2 text-sm text-gray-500">
            {getGeneralTime(moment.unix(created_at / 1000))}
        </div>
        <div>

        </div>
    </div>
}

function HeaderCol({children, col_span = 'col-span-1'}) {

    return <div className={`text-gray-600 font-medium text-sm ${col_span}`}>
        {children}
    </div>
}

export function EmojiTable({
    actions = []
                           }) {
    const community = useContext(CommunityContext);
    const [emojis, setEmojis] = useState(null);

    useEffect(function () {
        getEmojis();
    }, [community.uid]);

    function getEmojis() {
        FirebaseDB
            .collection('communities')
            .doc(community.uid)
            .collection('emojis')
            .orderBy('created_at', 'desc')
            .onSnapshot(snap => {
                const arr = snap.docs.map(doc => {
                    return {
                        ...doc.data()
                    }
                });
                community.updateCommunityEmojis(arr);
                setEmojis(arr)
            });
    }

    return <div className='relative'>
        <div className="grid gap-4 relative grid-cols-8 py-3 px-2.5 ">
            <HeaderCol>Image</HeaderCol>
            <HeaderCol col_span={'col-span-2'}>Name</HeaderCol>
            <HeaderCol col_span={'col-span-2'}>Added by</HeaderCol>
            <HeaderCol col_span={'col-span-2'}>Added</HeaderCol>
            <HeaderCol>
            </HeaderCol>
        </div>
        <div className="absolute h-10 top-0 right-0 flex items-center">
            <div className="flex space-x-2">
            {actions.map(a=>a)}
            </div>
        </div>
        <div>
            <div className="border-t border-gray-200">{emojis && emojis.map(em => <Row community_uid={community.uid}
                                                                                       key={em.handle} {...em} />)}</div>
            {!emojis && <div className="p-8"><InlineLoader/></div>}
            {emojis&&emojis.length===0&&<InfoState fill subtitle="No custom emojis added yet" />}
        </div>

    </div>
}