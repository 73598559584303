import React, {useContext, useState} from 'react';
import {CommunityContext} from "../../community-context";
import {ActionWithIcon, Button, InfoState, Toggle} from "../../../../library/components/core";
import {es_renderEntityType} from "../../../../library/components/reorganize/entity-select/item";
import {DoubleCheckModal} from "../../../../library/components/old/double-check-modal";
import {GlobalContext} from "../../../global/global-context";
import DeleteModal from "../../../../library/components/old/delete-modal";
import {ConvertToIcon} from "../../../../library/playground/convert-to-icon";
import {TempTip} from "../../../../library/playground/temp-tip";
import {ChevronDownIcon, ChevronUpIcon} from "@heroicons/react/outline";
import {at_array_move} from "../../../../library/components/advanced-table/utilities";
import {DragDropContext, Droppable} from "react-beautiful-dnd";
import {useDraggableInPortal} from "../../../../library/components/advanced-table/sort/sort";
import {authFetch} from "../../../../config/network";
import {CommunityAccessManager} from "../../../../library/components/community-access-manager";
import {Popover2} from "@blueprintjs/popover2";
import {getAccessMeta} from "../../../../library/components/delegated-access-block";

function Subtitle({title = "Segments"}) {
    return <div>
        <div className="text-xs font-semibold text-gray-500">{title}</div>
    </div>
}

function Section({title, action, children, empty, type = 'segments'}) {

    return <div className="">
        <div className="flex">
            <div className="flex-grow">
                <Subtitle title={title}/>
            </div>
            <div>
                {action}
            </div>
        </div>
        <div className="pt-1 -mx-1">
            {children}
            {empty && <InfoState subtitle={`No ${type} added`} fill/>}
        </div>
    </div>
}

function buildNewRolesOrder(roles_order, role_ids, source_index, destination_index, dir) {
    let current = [...roles_order];

    role_ids.forEach(rid => {
        if (!current.includes(rid)) {
            current.push(rid);
        }
    });

    return at_array_move(current, source_index, destination_index);
}

export function buildGroupDefaultRolesOrder(group) {
    let a = group.roles_order ? [...group.roles_order] : [];

    const r = Object.keys(group.entities.roles);

    r.forEach(id => {
        if (!a.includes(id)) {
            a.push(id);
        }
    });

    return a;
}

function SyncEntities({id}) {
    const community = useContext(CommunityContext);
    const global = useContext(GlobalContext);
    const [state, setState] = useState("");

    function sync() {
        if (state === 'loading') {
            return;
        }
        setState('loading')
        const res = () => {
            setState('')
            global.addToast({text: `Updated.`, intent: 'success'});
        };

        const err = (err1) => {
        };

        const payload = {
            community_uid: community.uid,
            id: id, member_id: community.member_id
        };

        authFetch("/groups/sync-members", res, err, "POST", {payload})
    }

    return <div>
        <Button minimal onClick={() => sync()} text="Sync Members" loading={state === 'loading'}/>
    </div>
}

function AddEntityElement({type, init_access, meta}) {

    const content = <CommunityAccessManager show={['roles']} show_search={false} updateAccess={() => {
    }} {...meta} types={['roles']} init_access={init_access}/>;

    return <Popover2 usePortal={false} targetTagName='div' interactionKind='click' placement={'bottom-start'}
                     onClose={() => {
                     }}
                     popoverClassName='minimal-popover my-0.5 w-96 rounded-md shadow-lg border border-gray-200 bg-white'
                     minimal
                     content={content}>
        <ActionWithIcon stopPropogation={false} text="Add" inverse onClick={() => {
        }}/>
    </Popover2>
}

export function GMMSegments({
                                handleMakeRoleModerator = () => {
                                },
                                updateRolesOrder = () => {
                                },
                                removeEntity = () => {
                                },
                                openAddMembers = () => {
                                },
                                can_manage,
                                data,
                                id
                            }) {
    const global = useContext(GlobalContext);
    const community = useContext(CommunityContext);

    const meta = getAccessMeta(community);

    const [modal, setModal] = useState(null);
    const [modal_data, setModalData] = useState(null);

    const entity_props = {
        can_remove: can_manage,
        community: community,
        onRemove: (ent_type, ent_id) => {
            setModalData({
                type: "rule_remove_from_group",
                data_type: ent_type,
                keep_members: true,
                id: ent_id
            });
            setModal('open_delete');
        },
        can_select: false,
        onSelect: () => {
        },
        onToggleSegmentLive: () => {
        },
        selected: [],
        block_removal: {}
    };

    function confirmRemove() {
        removeEntity(modal_data.data_type, modal_data.id, () => {

        }, modal_data.keep_members);

        setModal(null);
        setModalData(null);

        global.addToast({
            intent: 'info',
            text: 'Removing rule from group'
        });
    }

    function handleOnDragEnd(result) {
        if (!result.destination) return;

        const {destination, source} = result;

        let new_roles_order = buildNewRolesOrder(roles_order, Object.keys(data.entities['roles']), source.index, destination.index, 'down');

        updateRolesOrder(new_roles_order);
    }

    const segments_count = Object.keys(data.entities['filters']).length;
    const roles_count = Object.keys(data.entities['roles']).length;
    const member_types_count = Object.keys(data.entities['member_types']).length;

    const roles_order = buildGroupDefaultRolesOrder(data)

    let actions = can_manage ? [
        {
            key: 'make-moderator',
            tooltip: "Make Moderator",
            icon: <ConvertToIcon type="moderator"/>,
            show: (type, id, dt, index) => {
                if (type === 'roles' && !data.moderators.roles[id] && Object.keys(data.moderators.roles).length < 3) {
                    return true;
                }
                return false;
            },
            onClick: (type, id, dt, index) => {
                if (type === 'roles' && !data.moderators.roles[id]) {
                    handleMakeRoleModerator(id)
                }
            }
        }
    ] : [];

    const renderDraggable = useDraggableInPortal();

    const init_access = {
        roles: {...data.entities.roles},
        member_types: {...data.entities.member_types}
    };

    // <AddEntityElement init_access={init_access} meta={meta} type="roles" />
    return <div className="pt-3 px-4 pb-3 space-y-3 flex flex-col overflow-y-auto adaptive-modal-height">
        <TempTip tip="group-rules"/>

        <Section action={<ActionWithIcon text="Add" inverse onClick={() => openAddMembers('roles')}/>}
                 empty={roles_count === 0} title="Roles" type="roles">
            <DragDropContext onDragEnd={handleOnDragEnd}>
                <Droppable droppableId="group-roles">
                    {(provided) => (
                        <div {...provided.droppableProps} ref={provided.innerRef}>
                            {es_renderEntityType('roles', data.entities['roles'], community.uid, {
                                ...entity_props,
                                can_reorder: true
                            }, actions, roles_order, renderDraggable)}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
        </Section>
        <Section action={<ActionWithIcon text="Add" inverse onClick={() => openAddMembers('segments')}/>}
                 empty={segments_count === 0} title="Segments">
            {es_renderEntityType('filters', data.entities['filters'], community.uid, entity_props)}
        </Section>
        <Section action={<ActionWithIcon text="Add" inverse onClick={() => openAddMembers('member_types')}/>}
                 empty={member_types_count === 0} title="Member Types" type="member types">
            {es_renderEntityType('member_types', data.entities['member_types'], community.uid, entity_props)}
        </Section>

        <div className="pt-20">
            <SyncEntities id={id}/>
        </div>
        {modal === 'open_delete' && <DeleteModal
            deleting={false}
            text="Remove"
            can_delete={true}
            content={<>
                <p>
                    If you remove this rule, members will not longer be automatically added to this group if they meet
                    the criteria.
                </p>
                <div className="pt-2">
                    <Toggle label="Keep existing members" layout='switch' checked={modal_data.keep_members}
                            onBlur={() => {
                            }} onToggle={() => {
                        setModalData({
                            ...modal_data,
                            keep_members: !modal_data.keep_members
                        })
                    }}/>
                </div>
            </>}
            title={`Remove Rule`}
            onDelete={() => confirmRemove()}
            onClose={() => {
                setModal(null)
            }}/>}


        {modal === 'double-check' && <DoubleCheckModal onConfirm={() => confirmRemove()} onClose={() => {
            setModal(null);
            setModalData(null)
        }} onCancel={() => {
            setModal(null);
            setModalData(null)
        }} type={modal_data.type}/>}
    </div>
}