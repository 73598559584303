import React, {useState, useContext, useEffect} from "react";
import {Switch, Route} from 'react-router-dom';

import styled from "styled-components";
import FilesScrollable from "../files-scrollable";
import {
    convertEntityForLocalStorage, getEntityFromLocalStorage,
    saveEntityToLocalStorage
} from "../../../../library/utilities/localstorage/entity-localstorage";
import CommunityUploadFile from "../upload-file";
import {ListMembersEntity} from "../../reorganize/entities";
import {setDocumentTitle} from "../../../../library/utilities/set-document-title";
import DeleteModal from "../../../../library/components/old/delete-modal";
import {authFetch} from "../../../../config/network";
import {getFolderCrumbs} from "../utilities";
import FilePage from "../file";
import {UploadIcon} from '@heroicons/react/solid';
import {CommunityContext} from "../../community-context";
import {FolderAddEntities} from "./add-entities";
import {DoubleCheckModal} from "../../../../library/components/old/double-check-modal";
import {GlobalContext} from "../../../global/global-context";
import FolderPicker from "../../../../library/components/reorganize/folder-picker";
import {UploadProgress} from "../../../../library/components/reorganize/upload-progress";

import firebase from "firebase/app";
import {
    Button,
    ContextualActions,
    EntityHeader,
    Field, InfoState, InlineLoader,
    MenuPanels,
    PageHeader
} from "../../../../library/components/core";
import {PlusIcon} from '@heroicons/react/solid';
import {MenuPanelFolderMembers} from "./members/all";
import {CreateFolderController} from "../create";
import {CUSTOM_NANOID} from "../../../../config/defaults";
import {AdvancedEntityHeader} from "../../../../library/components/advanced-entity-header";
import {
    ArrowRightIcon,
    CogIcon,
    DotsVerticalIcon,
    DuplicateIcon, KeyIcon, LockClosedIcon,
    PencilAltIcon, QuestionMarkCircleIcon,
    TrashIcon
} from "@heroicons/react/outline";
import {FirebaseDB} from "../../../../config/setup-firestore";
import {useIsMobile} from "../../../global/global-router";
import {FolderSettingsModal} from "../settings/modal";
import {getDriveAccess} from "../settings/modal-access-tab";
import {PopupMenu} from "../../../../library/components/old/popup-menu";
import {Popover2} from "@blueprintjs/popover2";

function ensureExtension(str) {
    return str;
}

export function cleanDownloadURL(str) {
    return str.split('&token')[0];
}

function getStrParts(name) {
    if (name.includes('.')) {
        const split = name.split('.');
        return {
            base: `${split[0]}`,
            ext: `.${split[1]}`
        }
    }
    return {
        base: name,
        ext: ''
    }
}

/*

        {show_settings&&<MenuPanels initial={initial_panel} onClose={() => {showSettingsModal(false);setInitialPanel('');}} show={true} panels={[
            {
                id: 'overview',
                title: `Manage Folder`,
                component: <MenuPanelFolderOverview
                    can_make_public={data.type==='standard'&&data.security==='private'&&can_manage}
                    can_make_private={data.type==='standard'&&data.security==='public'&&can_manage}
                    onMakePrivate={()=>handleMakePrivate()}
                    onMakePublic={()=>handleMakePublic()}
                    can_delete={can_manage}
                    can_move={can_move_folder_permission}
                    permissions_viewers={data.folder===''&data.permissions_type==='editor'&&can_manage}
                    permissions_editors={data.folder===''&data.permissions_type==='viewer'&&can_manage}
                    onPermissionsEditors={()=>handleAllowMembersToEdit()}
                    onPermissionsViewers={()=>handleMakeViewOnly()}
                    can_rename={(is_top_level_folder&&data.type==='group')?false:can_edit_folder_info}
                    openDelete={()=>setModal('double-check-delete')} openMove={()=>setModal('_move_folder')}
                    context={mp_ctx}/>
            },
            {
                id: 'about-folder',
                title: `About Folder`,
                padded: true,
                component: <MenuPanelFolderAbout context={mp_ctx}/>
            },
            {
                id: 'rename',
                title: 'Rename Folder',
                padded: true,
                component: <MenuPanelFolderRename updateName={handleRename} value={data.name} emoji={data.emoji} context={mp_ctx} />
            }
        ]}/>}
 */

function getFinalName(name, block) {
    let final_name = name;
    let valid = !block.includes(final_name);
    const str_parts = getStrParts(name);
    let i = 1;
    while (!valid) {
        final_name = `${str_parts.base} (${i})${str_parts.ext}`;
        i++;
        valid = !block.includes(final_name);
    }
    return final_name;
}

const storage = firebase.storage().ref();

const PageWrapper = styled.div`
  position: relative;
  z-index: 1;

  > div:nth-child(1) {
    z-index: 9999;
    position: relative;
  }
`;

function tryToGetDataFromLS(community, id) {
    const ls_entity = getEntityFromLocalStorage(community.uid, "folders", id);

    // if not, get from db
    if (ls_entity !== null && ls_entity.raw) {
        //return ls_entity;
        return ls_entity.raw;
    }
    return null;
}

function canManageDrive(props, data, community) {
    if (props.in_group) {
        return props.manage_drive ? props.manage_drive : false;
    }
    if (community.is_admin) {
        return true;
    }
    return false;
}

function isAssignedToARole(member) {
    const r = member.roles || {};
    return Object.keys(r).length > 0;
}

const scores = {
    'owner': 5,
    'co-owner': 4,
    'editor': 3,
    'commentor': 2,
    'viewer': 1,
    'restricted': 0,
};

export function getDriveContentAccess(type, data, community, extras) {
    let final = 'restricted';

    const access = getDriveAccess(data);

    if (community.is_admin) {
        return 'editor';
    }

    // moderators
    if (extras.is_moderator && scores[final] < scores[access.general.moderators]) {
        final = access.general.moderators;
    }

    // members
    if (extras.is_member && scores[final] < scores[access.general.members]) {
        final = access.general.members;
    }


    // all roles
    const assigned_to_a_role = isAssignedToARole(community.member);
    if (assigned_to_a_role && scores[final] < scores[access.general.all_roles]) {
        final = access.general.all_roles;
    }

    // community
    if (scores[final] < scores[access.general.community]) {
        final = access.general.community;
    }

    // INDIVIDUAL
    const imta = access.individual.member_types[community.member.member_type];
    if (imta && scores[final] < scores[imta]) {
        final = imta;
    }

    // for each role
    Object.keys(community.member.roles || {}).forEach(id => {
        const ira = access.individual.roles[id];
        if (ira && scores[final] < scores[ira]) {
            final = ira;
        }
    });

    // members
    if (scores[final] < scores[access.general.public]) {
        final = access.general.public;
    }

    return final;
}

export default function FilesFolder(props) {
    const {is_moderator, is_member, in_group = false, show_title = true} = props;
    const community = useContext(CommunityContext);
    const global = useContext(GlobalContext);
    const is_mobile = useIsMobile();
    const [id, setId] = useState(props.id);
    const init_data = tryToGetDataFromLS(community, props.id);
    const [initial_panel, setInitialPanel] = useState('');
    const [show_members, setShowMembers] = useState(false);
    const [update_list, setUpdateList] = useState(false);
    const [uploading, updateUploading] = useState({});
    const [data, setData] = useState(init_data);
    const [assets_len, setAssetsLen] = useState({
        files: 0,
        folders: 0
    });
    const [modal, setModal] = useState(null);
    const [show_settings, showSettingsModal] = useState(false);
    const [selected, setSelected] = useState([]);
    const [move_to, setMoveTo] = useState('');
    const [error, setError] = useState(null);
    const [folder_names, setFolderNames] = useState({
        private: [],
        public: []
    });
    const [checked_url, setCheckedUrl] = useState(false);
    const [loading, setLoading] = useState(!init_data ? 'folder' : '');
    const [file_list, setFileList] = useState({
        map: {},
        ordered: [],
        map_names: {}
    });

    useEffect(function () {
        if (!checked_url) {
            const urlParams = new URLSearchParams(window.location.search);
            const action = urlParams.get('action');
            if (action === 'add-members') {
// check if is group admin
                setModal('_open_access');
                props.history.push(window.location.pathname);
            }
            setCheckedUrl(true);
        }
    }, []);

    useEffect(function () {
        let new_id = props.id;
        setId(new_id);
        showSettingsModal(false);
    }, [props.id]);

    useEffect(function () {
        getFolder();
        setFileList({
            map: {},
            ordered: [],
            map_names: {}
        });
        const td = tryToGetDataFromLS(community, id);
        setLoading(td ? '' : 'folder');
        setData(td);
        updateUploading({});
        setModal(null);
        setMoveTo('');
        setShowMembers(false);
        setInitialPanel('');
        setSelected([]);
        setFolderNames({
            private: [],
            public: []
        });
    }, [id]);

    function handleRename(name, emoji, cb) {

        const res = () => {
            getFolder(cb);
            setInitialPanel('');
            global.addToast({text: 'Name updated', intent: 'success'});
        };

        const payload = {
            name,
            emoji,
            permissions_type: data.permissions_type,
            id,
            security: data.security,
            member_id: community.member_id,
            community_uid: community.uid
        };

        authFetch("/folders/edit-about", res, res, "POST", {payload});
    }

    function handleMakePrivate() {
        const res = () => {
            getFolder();
            showSettingsModal(false);
            setInitialPanel('');
            global.addToast({text: 'Folder made private', intent: 'success'});
        };

        const payload = {
            name: data.name,
            emoji: data.emoji,
            permissions_type: data.permissions_type,
            id,
            security: 'private',
            member_id: community.member_id,
            community_uid: community.uid
        };

        authFetch("/folders/edit-about", res, res, "POST", {payload});
    }

    function handleMakePublic() {
        const res = () => {
            getFolder();
            showSettingsModal(false);
            setInitialPanel('');
            global.addToast({text: 'Folder made public', intent: 'success'});
        };

        const payload = {
            name: data.name,
            emoji: data.emoji,
            permissions_type: data.permissions_type,
            id,
            security: 'public',
            member_id: community.member_id,
            community_uid: community.uid
        };

        authFetch("/folders/edit-about", res, res, "POST", {payload});
    }

    function handleAllowMembersToEdit() {
        const res = () => {
            getFolder();
            showSettingsModal(false);
            setInitialPanel('');
            global.addToast({text: 'Members can edit folder contents', intent: 'success'});
        };

        const payload = {
            name: data.name,
            emoji: data.emoji,
            permissions_type: 'editor',
            id,
            security: data.security,
            member_id: community.member_id,
            community_uid: community.uid
        };

        authFetch("/folders/edit-about", res, res, "POST", {payload});
    }

    function handleMakeViewOnly() {
        const res = () => {
            getFolder();
            showSettingsModal(false);
            setInitialPanel('');
            global.addToast({text: 'Members can only view contents', intent: 'success'});
        };

        const payload = {
            name: data.name,
            emoji: data.emoji,
            permissions_type: 'viewer',
            id,
            security: data.security,
            member_id: community.member_id,
            community_uid: community.uid
        };

        authFetch("/folders/edit-about", res, res, "POST", {payload});
    }

    function handleFileChange(files) {
        const fs = [];
        for (let i = 0; i < files.length; i++) {
            var filesize = ((files[i].size / 1024) / 1024).toFixed(4); // MB

            if (filesize > 20) {
                alert(`${files[i].name} is too large, it must be under 20 MB`);
            } else {
                fs.push(files[i]);
            }
        }
        uploadFiles(fs);
    }

    function uploadFiles(files) {
        for (let i = 0; i < files.length; i++) {
            const file = files[i];
            uploadFile(file);
        }
    }

    function updateProgress(file_name, progress, meta) {
        let all = uploading;
        if (progress === -1) {
            delete all[file_name];
        } else {
            all[file_name] = {
                progress,
                ...meta
            };
        }

        updateUploading({...all});
    }

    function uploadFile(file) {
        const uid = CUSTOM_NANOID();
        let final_name = '';

        if (props.replace) {
            final_name = getFinalName(props.current_name, []);
        } else {
            final_name = getFinalName(file.name, Object.keys(file_list.map_names));
        }
        const file_name = `${uid}-${ensureExtension(final_name)}`;
        const path = `files/${community.uid}/${props.id}/${file_name}`;
        const metadata = {
            contentType: file.type
        };
        const task = storage.child(path).put(file, metadata);

        updateProgress(file_name, 0, {name: file.name, type: file.type, size: file.size});

        task.on(
            firebase.storage.TaskEvent.STATE_CHANGED,
            snap => {
                updateProgress(file_name, (snap.bytesTransferred / snap.totalBytes), {
                    name: final_name,
                    type: file.type,
                    size: file.size
                });
            },
            err => {
            },
            () => fileUploaded(path, file.type, final_name, file.size, file_name)
        );
    }

    function fileUploaded(path, type, name, size, file_name) {
        storage
            .child(path)
            .getDownloadURL()
            .then((url) => {
                // url
                createFileInDB(name, cleanDownloadURL(url), {type, size});

                updateProgress(file_name, 1, {name, type, size});

                setTimeout(() => {
                    updateProgress(file_name, -1, {name, type, size});
                }, 2000);
            })
    }

    function createFileInDB(name, downloadURL, metadata) {
        const {folder = '', replace} = props;

        const res = () => {

        };

        const payload = {
            name,
            type: metadata.type,
            folder: id,
            link: downloadURL,
            size: metadata.size,
            member_id: community.member_id,
            community_uid: community.uid
        };

        authFetch("/files/create", res, res, "POST", {payload});
    }

    function deleteMany() {
        if (loading === 'deleting') {
            return;
        }

        setLoading('deleting');

        const res = () => {
            setLoading('');
            setModal(null);
            setUpdateList(true);
            setSelected([]);
            getFolder();
            global.addToast({
                text: "Contents were deleted", intent: 'success'
            });
        };

        const payload = {
            items: selected.map((it) => {
                const split = it.split('-');
                return {
                    id: split[1],
                    type: split[0]
                }
            }),
            member_id: community.member_id,
            community_uid: community.uid
        };

        authFetch("/files/delete-many", res, res, "POST", {payload});
    }

    function moveMany() {
        if (loading === 'moving') {
            return;
        }

        setLoading('moving');

        const res = () => {
            // reset selection
            setSelected([]);
            setLoading('');
            setUpdateList(true);
            setModal(null);
            getFolder();
            global.addToast({
                text: "Contents were moved", intent: 'success'
            });
        };

        const payload = {
            items: selected.map((it) => {
                const split = it.split('-');
                return {
                    id: split[1],
                    type: split[0]
                }
            }),
            member_id: community.member_id,
            community_uid: community.uid
        };

        authFetch("/files/move-many", res, res, "POST", {payload});
    }

    function deleteFolder() {

        if (loading === 'deleting') {
            return;
        }

        setLoading('deleting');

        const payload = {
            community_uid: community.uid, id: id,
            member_id: community.member_id,
        };

        const redirect = () => {
            const ext = data.folder === '' ? `drive` : `drive/folder/${data.folder}`;

            global.addToast({text: "Folder was deleted", intent: 'success'});
            props.history.push(`${props.base}`);
        };

        const res = (result) => {
            redirect();
        };

        authFetch("/folders/delete", res, res, "POST", {payload});
    }

    function removeEntity(type, entity_id, cb) {
        if (loading === '_removing_entity') {
            return;
        }

        const payload = {
            community_uid: community.uid,
            id: id,
            entity_type: type,
            entity_id: entity_id,
            member_id: community.member_id,
        };

        const res = (result) => {
            setLoading('');
            global.addToast({text: "Member(s) we're removed", intent: 'success'});
            getFolder(cb);
        };

        setLoading('_removing_entity');

        authFetch("/folders/remove-entity", res, res, "POST", {payload});
    }


    function getFolder(cb) {
        FirebaseDB
            .collection('community_entities')
            .doc(community.uid)
            .collection('folders')
            .doc(id)
            .get()
            .then(res => gotFolder(res, cb))
            .catch(err => {
                console.log("ACCESS DNIED", err)
                setError('access_denied');
                setLoading('');
            })
    }

    function gotFolder(res, cb) {
        if (res.exists) {
            const data = res.data();
            const lse = convertEntityForLocalStorage(community.uid, 'folders', data);

            saveEntityToLocalStorage(community.uid, 'folders', res.id, lse);
            setData(data);
            setLoading('');
            if (cb) {
                cb(data);
            }
        } else {
            setError('does_not_exist');
            setLoading('');
        }
    }

    function moveFolder() {
        setLoading('moving');
        const res = () => {
            setLoading('');
            setModal(null);
            props.history.push(`${props.base}/folder/${move_to}`);
            global.addToast({text: "Folder was moved", intent: 'success'});
        };
        const payload = {
            current_folder: id,
            target_folder: move_to,
            community_uid: community.uid,
            member_id: community.member_id,
        };

        authFetch("/folders/move", res, res, "POST", {payload});
    }

    function updateFolderAccess(pass) {
        const payload = {
            member_id: community.member_id,
            id: id,
            community_uid: community.uid,
            ...pass
        };

        const res = () => {
            console.log('access updated')
        };

        const err = () => {

        };

        authFetch("/folders/update-access", res, err, "POST", {payload});
    }


    // nicer loading state
    if (loading === 'folder') {
        return <div>
            <InlineLoader />
        </div>;
    }

    const content_access = getDriveContentAccess('folder', data, community, {is_moderator, is_member});

    if (content_access === 'restricted') {
        return <div>
            <InfoState subtitle="Restricted" fill icon={<LockClosedIcon />} />
        </div>
    }

    // no longer exists
    if (error === 'does_not_exist') {
        return <div>
            <InfoState subtitle="Folder not found" fill icon={<QuestionMarkCircleIcon />} />
        </div>
    }

    let is_top_level_folder, can_create_folder, can_edit_folder_info, can_edit,
        can_upload_files = false;

    let can_view = props.view_drive ? props.view_drive : false;
    let can_manage = content_access === 'editor';

    let ctx_actions = [];

    let title = '', body = null, members, security = data.security ? data.security : 'public',
        crumbs = '';

    const access_denied = error === 'access_denied';

    const {is_admin} = community;

    if (access_denied) {
        title = 'Access Denied';
    }

    if (loading === 'folder') {
        title = 'Loading..';
    }

    let entities_filters, can_change_name = false;

    let len = 0;

    if (!access_denied && loading !== 'folder') {
        const {name, folder, permissions_type, user_uids, system_path, type} = data;

        len = user_uids ? Object.keys(user_uids).length : 0;

        is_top_level_folder = folder === '';

        entities_filters = is_top_level_folder ? data.entities : {
            entities: {}
        };

        can_edit = content_access === 'editor';

        can_edit_folder_info = is_top_level_folder === 'editor';

        const can_mass_edit_files = content_access === 'editor' && (assets_len.folders + assets_len.files) > 0;

        body = <FilesScrollable in_group={in_group} is_mobile={is_mobile}
                                group_top_level_folder={props.group_top_level_folder} base={props.base}
                                language={global.language} selected={selected}
                                updateSelected={(ids) => setSelected([...ids])}
                                community_handle={community.data.handle} onAddToast={t => global.addToast(t)}
                                setList={(maps) => {
                                    setFileList(maps);
                                    setUpdateList(false)
                                }} setLength={(l, t) => {
            let nal = {...assets_len};
            nal[t] = l;
            setAssetsLen(nal);
        }} setNames={(n, t) => {
            let no = {...folder_names};
            no[t] = n;
            setFolderNames(no);
        }} update_list={update_list} setUpdated={() => setUpdateList(false)}
                                parent_folder={folder} can_edit={can_mass_edit_files} hide_go_up={props.hide_go_up}
                                folder={id} {...props} />;

        can_change_name = content_access === 'editor';

    }

    let manage_options = [
        {
            icon: <PencilAltIcon/>,
            type: 'item',
            title: 'Rename',
            onClick: () => {
                showSettingsModal(true)
            }
        }

    ];

    if (!is_top_level_folder) {
        manage_options.push({
            icon: <DuplicateIcon/>,
            type: 'item',
            title: 'Move',
            onClick: () => {
                setModal('_move_folder');
            }
        });
    }

    manage_options.push({
        icon: <KeyIcon/>,
        type: 'item',
        title: 'Access',
        onClick: () => {
            showSettingsModal(true)
        }
    });

    manage_options.push({
        icon: <CogIcon/>,
        type: 'item',
        title: 'Settings',
        onClick: () => {
            showSettingsModal(true)
        }
    });

    if (selected.length > 0 && can_edit) {
        ctx_actions.push({
            label: 'Move',
            icon: <ArrowRightIcon/>,
            onClick: () => setModal('_move_many'),
        });

        ctx_actions.push({
            label: 'Delete',
            icon: <TrashIcon/>,
            onClick: () => setModal('double-check-delete-many'),
        });
    }

    const breadcrumbs = getFolderCrumbs(data.system_path, community.uid, community.data.handle, data.name, in_group, props.base);

    let all_crumbs = [];

    if (!in_group) {
        if (breadcrumbs.length === 0) {
            all_crumbs.push({
                label: "/",
                path: "#"
            });
        } else {
            all_crumbs.push({
                label: "Drives",
                path: props.base
            })
        }
        all_crumbs = all_crumbs.concat(breadcrumbs);
    } else if (in_group) {
        if (breadcrumbs.length === 1) {
            all_crumbs.push({
                label: "/",
                path: "#"
            });
        } else {
            all_crumbs.push({
                label: "Drive",
                path: props.base
            });
        }
        let b2 = [...breadcrumbs];
        b2.shift();
        all_crumbs = all_crumbs.concat(b2);
    }

    let right_elements = [];

    if (can_manage) {
        right_elements.push({
            comp: <Button text="Folder" left_icon={<PlusIcon/>} onClick={() => {
                setModal('_new_folder');
            }}/>,
            key: 'create-folder'
        });
    }
    if (can_manage) {
        right_elements.push({
            comp: <CommunityUploadFile handleFileChange={handleFileChange}>
                <Button left_icon={<UploadIcon/>} intent="secondary" text='Upload'/>
            </CommunityUploadFile>,
            key: 'upload-files'
        });
    }

    if (!is_mobile) {
        right_elements.push({
            key: 'settings',
            comp: <Popover2 content={<PopupMenu width={'200px'} items={manage_options}/>} placement={'bottom-end'}
                            minimal popoverClassName='minimal-popover my-1'>
                <Button icon={<DotsVerticalIcon/>}/>
            </Popover2>
        });
    }

    setDocumentTitle(data.name, community.data.name);

    const file_route_path = props.group_top_level_folder ? `/:handle/group/:id/drive/folder/:folderId/file/:fileId` : `/:handle/explore/drive/folder/:folderId/file/:fileId`;

    /*
     <FolderDropzone startDND={()=>showDND(true)} onResetDND={() => showDND(false)} maxFiles={100} accept='*' boost_z={dnd}
                                setFile={(files) => handleFileChange(files)}/>
     */
    const main_content = <div>
        <div className="px-4 pt-1.5 pb-16">
            {body}
        </div>

        <UploadProgress uploading={uploading}/>

        {can_manage && modal === '_open_access' &&
        <FolderAddEntities group_id={id} handleSuccess={() => {
            getFolder();
            setModal(null);
        }} existing={data.entities} open type={data.type} closeModal={() => setModal(null)}
                           data={data} context={{community_uid: community.uid, is_admin}} id={id}/>}

        {(can_edit || is_admin) && modal === '_move_folder' && <DeleteModal
            deleting={loading === 'moving'}
            can_delete={move_to !== ''}
            content={<>
                <Field label="Where do you want to move this?"
                       help_text="This folder will assume the permissions of the folder you're moving it to, so it's contents may become private or public.">
                    <FolderPicker value={move_to} current_folder={id}
                                  default_open={data.top_level_folder}
                                  handleSelect={(folder) => {
                                      setMoveTo(folder);
                                  }}/>
                </Field>
            </>}
            title={`Move Folder`}
            intent='secondary'
            text='Move'
            onDelete={() => moveFolder()}
            onClose={() => {
                setMoveTo('');
                setModal(null);
            }}/>}

        {(can_edit || is_admin) && modal === '_move_many' && <DeleteModal
            deleting={loading === 'moving'}
            can_delete={move_to !== ''}
            content={<>
                <Field label="Where do you want to move this?"
                       help_text="This folder will assume the permissions of the folder you're moving it to, so it's contents may become private or public.">
                    <FolderPicker value={move_to} current_folder={id}
                                  handleSelect={(folder) => {
                                      setMoveTo(folder);
                                  }}/>
                </Field>
            </>}
            title={`Move Contents`}
            intent='secondary'
            text='Move'
            onDelete={() => moveMany()}
            onClose={() => {
                setMoveTo('');
                setModal(null);
            }}/>}

        {modal === '_new_folder' && <CreateFolderController base={props.base} handle={community.handle} folder={id}
                                                            context={{
                                                                is_admin: community.is_admin,
                                                                folder: id,
                                                                folder_names: folder_names.private.concat(folder_names.public),
                                                                community_uid: community.uid
                                                            }}
                                                            closeModal={() => setModal('')} history={props.history}
                                                            open={true}/>}

        {modal === 'double-check-delete' &&
        <DoubleCheckModal onConfirm={() => deleteFolder()} onCancel={() => setModal(null)}
                          onClose={() => setModal(null)}
                          type='folder_delete'/>}

        {modal === 'double-check-delete-many' &&
        <DoubleCheckModal onConfirm={() => deleteMany()} onCancel={() => setModal(null)}
                          onClose={() => setModal(null)}
                          type='files_folders_delete'/>}


        <Switch>
            <Route path={file_route_path}
                   render={(p1) => <FilePage {...p1} file_list={file_list} base={props.base}/>}/>
        </Switch>


        {show_members && <MenuPanels onClose={() => {
            setShowMembers(false)
        }} show={show_members} panels={[
            {
                id: 'all',
                title: `${len} Member${len === 1 ? '' : 's'}`,
                hide_divider: true,
                component: <MenuPanelFolderMembers removeEntity={removeEntity} can_manage={can_manage}
                                                   init={"Members"} moderators={{}}
                                                   members={data.user_uids} entities={data.entities}
                                                   context={{
                                                       community_uid: community.uid,
                                                       community: community.data,
                                                       member: community.member
                                                   }}/>
            },
        ]}/>}
        {ctx_actions.length > 0 && <ContextualActions count={selected.length} actions={ctx_actions}/>}

        {show_settings && <FolderSettingsModal top_level_folder={data.top_level_folder === ''} id={id}
                                               onClose={() => showSettingsModal(false)} setData={nd => {
            setData({...data, ...nd});
            updateFolderAccess(nd);
        }} getFolder={() => getFolder(() => {
        }, id)} folder_data={data} initial_tab={"About"}/>}
    </div>

    if (in_group) {
        return <div>
            <AdvancedEntityHeader title={data.name}
                                  right_elements={is_mobile || !in_group ? [] : right_elements} onRename={(nn) => {
                handleRename(nn, data.emoji, () => {
                    getFolder();
                })
            }} can_change_icon={can_change_name}
                                  can_edit_title={can_change_name} hide_border
                                  breadcrumbs={all_crumbs} layout="breadcrumbs"/>

            {main_content}
        </div>
    }
    /*
         <pre className="text-xs p-2 bg-gray-100 border-gray-300 rounded-md overflow-y-scroll text-left"
             style={{maxHeight: '400px'}}>{JSON.stringify({can_manage,data}, undefined, 2)}</pre>

     */

    return <div>
        <div className="border-b border-gray-200 pb-1">
        <AdvancedEntityHeader title={data.name}
                              right_elements={right_elements} onRename={(nn) => {
            handleRename(nn, data.emoji, () => {
                getFolder();
            })
        }} can_change_icon={can_change_name}
                              can_edit_title={can_change_name}
                              breadcrumbs={all_crumbs} layout="breadcrumbs"/>
        </div>
        {main_content}
    </div>

}