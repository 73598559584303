import React, {useState, useContext} from "react";
import 'firebase/auth';
import {FormattedMessage, useIntl} from 'react-intl';
import {ActionWithIcon, Button, Field, Input} from "../../../library/components/core";
import ButtonSection from "../../../library/components/old/button-section";
import {Link, useHistory} from "react-router-dom";
import {GlobalContext} from "../../global/global-context";
import {EyeIcon, EyeOffIcon} from "@heroicons/react/outline";
import {TempTip} from "../../../library/playground/temp-tip";
import {validateEmail} from "../../../library/utilities/validation";
import {buildAuthUrlSuffix} from "../signup/SignUpBlock";

function getForgotPWLink(u) {
    if (u.length > 2) {
        return `/forgot-password?_email=${u}`;
    }
    return `/forgot-password`;
}

export default function SignInBlock(props) {
    const {formatMessage: f} = useIntl();
    const history = useHistory();
    const global = useContext(GlobalContext);
    const [username, setUsername] = useState(props.username);
    const [show_pw, setShowPW] = useState(false);
    const [password, setPassword] = useState("");

    function handleSubmit(disable, e) {
        if (e) {
            e.preventDefault();
        }
        if (disable) {
            return;
        }
        const {handleSubmit} = props;
        // todo trim handle
        let cb = () => {
            const queryString = window.location.search;
            const urlParams = new URLSearchParams(queryString);
            const rt = urlParams.get('_redirectTo');
            if (rt) {
                history.push(`${rt}`)
            }
        };
        handleSubmit(username, password, cb);
    }

    function hc(f, v) {
        if (f === 'username') {
            setUsername(v);
        } else {
            setPassword(v);
        }
        if(global.login_error) {
            global.setLoginError("");
        }
    }

    function disableBtn() {
        return (username.length < 2 || password.length < 8)
    }

    let {color = ''} = props;
    const {login_error} = global;

    const base_link = props.community_handle?`/${props.community_handle}`:``;

    const suffix = buildAuthUrlSuffix(username,props.group_data);
    const disable = disableBtn();

    return (
        <div>
            <div className="space-y-4 pt-4">
                <Field label={"Unaty ID"}>
                    <Input placeholder="Email or @handle" autoFocus={true} type="text"
                           onChange={hc.bind(this, 'username')} value={username}/>
                </Field>

                <div className={``}>
                    <Field corner_hint={<ActionWithIcon inverse text={"Forgot your password?"}
                                                        onClick={() => history.push(getForgotPWLink(username))}/>}
                           label={f({id: 'auth.signin.password_label'})}>
                        <Input trailing={{type: 'icon', onClick: ()=>{
                                setShowPW(!show_pw)
                            }, value: show_pw?<EyeOffIcon/>:<EyeIcon/>}} autoFocus onEnter={() => handleSubmit(disable)} type={show_pw?'text':`password`}
                               onChange={hc.bind(this, 'password')} value={password}/>
                    </Field>
                </div>
            </div>
            {login_error && login_error === 'not-found' && username && password && validateEmail(username) &&<div className="pt-3">
            <TempTip custom={<span>No account found with this email. <Link className="color-link-reverse" to={`${base_link}/sign-up${suffix}`}>Create an Account</Link></span>} />
            </div>
            }
            {login_error && (login_error !== 'not-found' && validateEmail(username)) &&
            <div className={`mt-3 red-error-message`}><FormattedMessage id="auth.signin.login_error"/></div>}

            <ButtonSection className='auth-actions' color={color}>
                <Button intent='secondary' fill size='large' disabled={disable}
                        onClick={handleSubmit.bind(this, disable)} text={f({id: 'auth.signin.signin_button'})}
                        loading={global.logging_in}/>
            </ButtonSection>

        </div>
    );
}