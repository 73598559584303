import React from 'react';
import {Link} from "react-router-dom";
import styled from "styled-components";

const MagicText = styled.div`
       margin-top: 24px;
    font-size: 13px;
    line-height: 1.4;
`;

export default function SignInMeta({phase="",community_handle}) {

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const rt = urlParams.get('_redirectTo');

    const suffix = rt ? `?_redirectTo=${rt}` : '';
    const sign_up_link = `${community_handle?`/${community_handle}`:""}/sign-up${community_handle?"":suffix}`;
    const sign_up_text = community_handle ? `Don't have an account?` : `New to Unaty?`;
    return <MagicText className='text-gray-800 text-center space-y-2'>
        {phase==='enter-password'&&<div>
            Get a <Link className='color-link' to={`/magic-sign-in${suffix}`}>Magic Sign In
            link</Link> sent to your email.
        </div>}
        {phase==='enter-username'&&<div>
            <Link className='color-link' to={`/forgot-password${suffix}`}>Forgot your Unaty ID or password?</Link>
        </div>}
        {phase==='enter-username'&&<div>
            {sign_up_text} <Link className='color-link' to={sign_up_link}>Sign up</Link>
        </div>}
    </MagicText>
}