import React, {useContext,useState} from "react";
import styled from "styled-components";
import {parsePhoneNumberFromString} from "libphonenumber-js/min";
import {Link} from "react-router-dom";
import {EntityInfo} from "../../../utilities/localstorage/entity-localstorage";
import {GlobalContext} from "../../../../app/global/global-context";
import {CommunityContext} from "../../../../app/community/community-context";
import {countries} from "../../../data/general/countries";
import {genders_object} from "../../../data/general/genders";
import {PhoneServicesFinder} from "../../reorganize/phone-services-finder";
import {isAnonymousUnatyEmail} from "../../../utilities/anonymous-emails";
import {EmptyFieldWarning} from "../../empty-field-warning";
import {cleanWebsiteForProfile} from "../../../utilities/shared";
import {ProfileFieldsContentSection} from "../../edit-profile-mobile";
import {useIsMobile} from "../../../../app/global/global-router";
import {getPhoneNumberData} from "../../../utilities/phone";
const Frame = styled.div`
`;

const NameSpan = styled.div`
margin-right: 4px;
`;

const Field = styled.div`
    :hover {
        .field-show-on-hover {
            opacity: 1;
        }
    }
`;

const Value = styled.div`
    font-size: 15px;
    line-height: 29px;
    display: flex;
`;

const Label = styled.div`
    font-size: 14px;
    display: flex;
    > .bp4-popover-wrapper {
            display: inline-flex;
                height: 12px;
    margin-top: 2px;
    }
`;

const AddressItem = styled.a`
    font-size: 14px;
        white-space: pre;
    display: inline-block;
    line-height: 1.6;
    margin-top: 4px;
`;

export function getAddressUrl(value) {
    return `http://maps.google.com/?q=${value.replace(/,/g, '+').replace(/ /g, '+').replace(/\n/g, '+')}`;
}

function buildMemberLink(handle) {
    let community_handle = window.location.pathname.split("/")[1];
    return `/${community_handle}/member/${handle}`;
}

function buildGroupLink(id) {
    let community_handle = window.location.pathname.split("/")[1];
    return `/${community_handle}/group/${id}`;
}

function MemberItem({data}) {
    return <Link className='color-link' to={buildMemberLink(data.handle)}>{data.name.trim()}</Link>
}

function GroupItem({data,id}) {
    return <Link className='color-link' to={buildGroupLink(id)}>{data.name.trim()}</Link>
}

function TextBlock({children}) {
    return <div className='text-gray-800 text-base leading-6 pt-0.5'>
        {children}
    </div>
}

const AnchorLink = styled.a`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 180px;
    display: block;
`;

function getCountryCodeFromValue(v) {
    if(!v) {
        return '';
    } else {

    }
}

// todo clean up
function displayValue({type, value, field, ctx, id}) {
    let fv;
    switch (type) {
        case 'email' : {
            if(isAnonymousUnatyEmail(value)) {
                return <div className="">
                    <EmptyFieldWarning id={id} type="Email" can_suggest={true} field={"email"} />
                </div>
            }
            return <TextBlock><a className='color-link-reverse' href={`mailto:${value}`}>{value}</a></TextBlock>
        }
        case 'gender' : {
            fv = value === '' ? '' : genders_object[value];
            return <TextBlock >{fv}</TextBlock>
        }
        case 'country' : {
            fv = !value ? '' : countries[value];
            return <TextBlock>{fv}</TextBlock>;
        }
        case 'text' : {
            if(!value&&field==='about.last_name') {
                return <div className="">
                    <EmptyFieldWarning id={id} type="Last Name" can_suggest={true} field={"about.last_name"} />
                </div>
            } else if(!value&&field==='about.first_name') {
                return <div className="">
                    <EmptyFieldWarning id={id} type="First Name" can_suggest={true} field={"about.first_name"} />
                </div>
            }
            return <TextBlock>{value}</TextBlock>
        }
        case 'multi-line-text' : {
            return <TextBlock>{value}</TextBlock>
        }
        case 'url' : {
            return <AnchorLink className='text-secondary-700' href={value} target={'_blank'}>{cleanWebsiteForProfile(value)}</AnchorLink>
        }
        case 'phone' : {
            const pn = getPhoneNumberData(value);
            let final_val = '';
            if(!pn) {
                final_val = value ? value : '';
            }
            final_val = pn ?
                <a className='color-link-reverse' href={pn.tel}>{pn.display}</a> : value ? value : '';

            return <TextBlock>
                {final_val}
            </TextBlock>
        }
        case 'address' : {
            return <AddressItem className='color-link-reverse' href={getAddressUrl(value)} target='_blank'>
                {value}
            </AddressItem>
        }
        case 'multi-select': {
            return <TextBlock>
                {value}
            </TextBlock>
        }
        case 'select': {
            return <TextBlock>
                {value}
            </TextBlock>
        }
        case 'multi-select-taxonomy': {
            return <TextBlock>
                {value}
            </TextBlock>
        }
        case 'checkbox': {
            return <TextBlock>
                {value}
            </TextBlock>
        }
        case 'select-taxonomy': {
            return <TextBlock>
                {value}
            </TextBlock>
        }
        case 'date': {
            return <TextBlock>
                {value}
            </TextBlock>
        }
        case 'number': {
            return <TextBlock>
                {value}
            </TextBlock>
        }
        case 'member' : {
            return <EntityInfo community_uid={ctx.community_uid} id={value} type={'members'}>
                <MemberItem/>
            </EntityInfo>;
        }
        case 'group' : {
            return <EntityInfo community_uid={ctx.community_uid} id={value} type={'groups'}>
                <GroupItem id={value}/>
            </EntityInfo>;
        }
        case 'mentor' : {
            return <EntityInfo community_uid={ctx.community_uid} id={value} type={'members'}>
                <MemberItem/>
            </EntityInfo>;
        }
        case 'mentees' : {
            const len = value.length;
            return <>
                {value.map((id, i) => {
                    return <NameSpan className='text-gray-600 whitespace-nowrap' key={`${id}${i}`}>
                        <EntityInfo community_uid={ctx.community_uid} id={id} type={'members'}>
                        <MemberItem/>
                    </EntityInfo>{(i + 1) !== len ? ',' : ''}</NameSpan>
                })}
            </>
        }
        default : {
            return <TextBlock>
                {value}
            </TextBlock>;
        }
    }
}

export function ProfileDetails({sections, community, member, is_open, data_suggestions=[], phone_services={}, cb, id, startEditing, community_uid, openMakeSuggestion, openReportProblem, my_profile, ctx}) {

    const is_mobile = useIsMobile();
    console.log("HELLO", sections)
    return <Frame className="space-y-3">
        {sections.map((sec, i) => {
            return <ProfileFieldsContentSection single_line={false} is_open={is_open} options={data_suggestions.map(a=>{return {...a,type:'item',title:a.label}})} layout={is_mobile?"":"desktop"} section={{autocomplete: []}} visibility={sec.visibility} title={sec.title}
                               key={i}>
                    {sec.fields.map((f, k) => {
                        if (!f.show) {
                            return null;
                        }
                        return <Field key={k}>
                            <Label className='text-gray-800'>
                                <div className="text-xs font-medium text-gray-700">
                                    {f.label}
                                </div>
                            </Label>
                            <Value className='flex-wrap	text-gray-600 line-clamp-2'>
                                {displayValue({...f, ctx, id})}
                            </Value>
                            {f.type === 'phone' && phone_services[`${f.field.replace('contact.','')}`] && phone_services[`${f.field.replace('contact.','')}`].length > 0 &&
                            <div className="-mt-0.5">
                                <PhoneServicesFinder active={phone_services[`${f.field.replace('contact.','')}`]}/>
                            </div>}
                        </Field>
                    })}
            </ProfileFieldsContentSection>;
        })}

    </Frame>
}