import React, {useContext, useEffect} from "react";
import {GlobalContext} from "../global-context";
import {FullScreenContentOverlay} from "../../../library/components/full-screen-content-overlay";
import {useLocation} from "react-router-dom";
import {CommunityPreferencesModal} from "../../community/community-preferences/modal";
import {MemberPreferencesModal} from "../../community/member-preferences/modal";
import CreateMember from "../../community/directory/create";
import {buildNewPathWithURLParam} from "../../community/directory";
import {RightDetailOverlay} from "../../../library/components/full-screen-content-overlay/right-detail-overlay";
import {CreateRoleWrapper} from "../../community/leaders/create-role-wrapper";

export function RightContextMenu(props) {
    const global = useContext(GlobalContext);

    const location = useLocation();

    useEffect(function () {

    }, [global.right_menu])

    useEffect(function () {
        global.handleCloseRightMenu();
    }, [location.pathname]);

    if (!global.right_menu || !global.right_menu.type) {
        return null;
    }

    if (global.right_menu.type.startsWith('admin-member')) {
        const id = global.right_menu.type.split("admin-member__")[1];
        if (!id || id === 'undefined') {
            console.error('no id', id)
            global.handleCloseRightMenu();
            return null;
        }
        return <FullScreenContentOverlay eprops={global.right_menu.props} type="member" id={id} onClose={() => global.handleCloseRightMenu()}/>
    }

    if (global.right_menu.type.startsWith('member-application')) {
        const id = global.right_menu.type.split("member-application__")[1];
        if (!id || id === 'undefined') {
            console.error('no id', id)
            global.handleCloseRightMenu();
            return null;
        }
        return <FullScreenContentOverlay eprops={global.right_menu.props} type="member-application" id={id} onClose={() => global.handleCloseRightMenu()}/>

    }

    if (global.right_menu.type.startsWith('preview-member')) {
        const id = global.right_menu.type.split("preview-member__")[1];
        if (!id || id === 'undefined') {
            console.error('no id', id)
            global.handleCloseRightMenu();
            return null;
        }
        return <RightDetailOverlay eprops={global.right_menu.props} type="member-preview" id={id} onClose={() => global.handleCloseRightMenu()}/>
    }

    if (global.right_menu.type.startsWith('admin-role')) {
        const id = global.right_menu.type.split("admin-role__")[1];
        if (!id || id === 'undefined') {
            console.error('no id', id)
            global.handleCloseRightMenu();
            return null;
        }
        return <FullScreenContentOverlay eprops={global.right_menu.props} type="role" id={id} onClose={() => global.handleCloseRightMenu()}/>
    }

    if (global.right_menu.type === ('member-import')) {
        return <FullScreenContentOverlay eprops={global.right_menu.props} type="member-import" onClose={() => global.handleCloseRightMenu()}/>
    }

    if (global.right_menu.type === 'create-members') {
        return <CreateMember {...global.right_menu.props} size='medium' open={true}
                             onClose={() => global.handleCloseRightMenu()}/>
    }

    if (global.right_menu.type === 'create-role') {
        return <CreateRoleWrapper {...global.right_menu.props} size='medium' open={true}
                             onClose={() => global.handleCloseRightMenu()}/>
    }

    if (global.right_menu.type === 'community-preferences') {
        return <CommunityPreferencesModal {...global.right_menu.props} onClose={() => global.handleCloseRightMenu()}/>
    }

    if (global.right_menu.type === 'member-preferences') {
        return <MemberPreferencesModal {...global.right_menu.props} onClose={() => global.handleCloseRightMenu()}/>
    }

    return null;
}