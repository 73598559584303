import React from "react";
import styled from "styled-components";
import {useHistory} from "react-router-dom";
import {Button} from "../../../../library/components/core";

const Container = styled.div`
    
`;

const Text = styled.div`
        font-size: 16px;
    line-height: 1.4;
`;

export const MemberArchived = () => {
    const history = useHistory();
    return <div className="px-4">
        <div className="pt-24 max-w-lg mx-auto">
        <Text className='text-gray-800'>
            Your account has been deactivated, if you believe this is a mistake or to learn more, please contact your Community leaders.
        </Text>
        <div className="pt-4">
            <Button intent="secondary" text="Back to Memberships" onClick={()=>history.push('/memberships')} />
        </div>
        </div>
    </div>;
};