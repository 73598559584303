export const MemberTypeDirectoryVisibility = {
    'default': {
        value: 'default',
        title: 'Default'
    },
    'separate': {
        value: 'separate',
        title: 'Separate'
    },
    'hidden': {
        value: 'hidden',
        title: 'Hidden'
    }
};

function transformToArr() {
    let arr = [];
    
    let values = Object.values(MemberTypeDirectoryVisibility);
    
    for(let i=0;i<values.length;i++) {
        const val = values[i];
        arr.push({
            label: val.title,
            value: val.value
        })
    }
    
    return arr;
}

export const MemberTypeDirectoryVisibilityArr = transformToArr();