import React from 'react';
import {ChevronDownIcon} from '@heroicons/react/solid';
const props = {
    title: 'string',
    action: null,
    collapsible: false
};

function getClassesFromSize(size) {
    if(size==='lg') {
        return "items-end text-lg"
    } else if(size==="xl") {
        return "items-center text-xl font-bold"
    } else {
        return "items-end text-base"
    }
}

export function SectionHeader({title = "", children, divider=false, size="md", fill, padded=true, open, collabsible, action}) {
const padding = padded ? "px-2.5 pb-1 pt-1 " : "";
const max_width = fill ? "" : "max-w-lg";
const divided = divider ? "pb-1 mb-2 border-b border-gray-200" : "";
    return <div>
        <div className={`flex items-end ${max_width} ${divided} user-select-none ${padding}`}>
        {collabsible && <div><ChevronDownIcon/></div>}
        <div className={`flex-grow flex ${getClassesFromSize(size)} text-gray-800 font-semibold line-clamp-1 tracking-tight`}>{title}</div>
        {action && <div className="pl-3 flex-none flex items-center pb-0.5">{action}</div>}
    </div>
        <div>
        {children}
        </div>
        </div>
}