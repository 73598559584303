import React, {useContext, useEffect, useState} from "react";
import {setDocumentTitle} from "../../../library/utilities/set-document-title";
import {CommunityContext} from "../community-context";
import {useIntl} from "react-intl";
import {Tabs} from "../../../library/components/core";
import {StandardPageHeight} from "../../../library/playground/standard-page-framework";

import CommmunityFiles from "../files/router";
import CommunityCalendar from "../calendar";
import {CommunityAllGroupsPage} from "../all-groups";
import CommunityInterests from "../interests";
import {Redirect, Route, Switch, useHistory, useLocation} from "react-router-dom";

function getTabFromPathname(p) {
    if (p.includes('/explore/birthdays')) {
        return 'Birthdays'
    }else if (p.includes('/explore/drive')) {
        return 'Drive'
    } else if (p.includes('/explore/interests')) {
        return 'Interests'
    } else {
        return 'Groups'
    }
}

function getPathnameFromTab(p) {
    if (p === "Birthdays") {
        return 'birthdays'
    } else if (p === "Directory") {
        return 'directory'
    } else if (p === "Drive") {
        return 'drive'
    } else if (p === "Interests") {
        return 'interests'
    } else {
        return 'groups'
    }
}

export default function CommunityExplore(props) {
    const community = useContext(CommunityContext);
    const location = useLocation();
    const history = useHistory();
    const {formatMessage: f} = useIntl();
    setDocumentTitle("Explore", community.data.name);
    const [tab, setTab] = useState(getTabFromPathname(location.pathname));

    useEffect(function () {
        const final_path = getPathnameFromTab(tab);
        const ext = location.pathname.split('explore/')[1];
        if (final_path&&ext&&!ext.startsWith(final_path)) {
            history.push(`/${community.data.handle}/explore/${final_path}`);
        }
    }, [tab])

    useEffect(function () {
        setTab(getTabFromPathname(location.pathname));
    }, [location.pathname])

    return (<StandardPageHeight content full>
            <div className='px-3 pt-1.5'>
                <Tabs layout="pills-solid" active={tab} onSelect={t => setTab(t)}
                      tabs={["Groups", "Drive", "Interests", "Birthdays"]}/>
            </div>
            <>
                <Switch>
                    <Route path='/:handle/explore/birthdays' component={CommunityCalendar}/>
                    <Route path='/:handle/explore/groups' component={CommunityAllGroupsPage}/>
                    <Route path='/:handle/explore/interests' component={CommunityInterests}/>
                    <Route path='/:handle/explore/drive' component={CommmunityFiles}/>
                    <Redirect from='/*' to={`/${community.data.handle}/explore/groups`}/>
                </Switch>
            </>
        </StandardPageHeight>
    );
}