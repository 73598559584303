import React from "react";
import InputField from "../input";
import {Link} from "react-router-dom";
import styled from "styled-components";
import TextareaField from "../textarea";
import SelectEntities from "../../../../app/community/reorganize/select-entities";
import Select from "../select";
import {countries, countries_array} from "../../../data/general/countries";
import {languages} from "../../../data/general/languages";
import MemberTypeSelect from "../selectors/member-type-select";
import {BirthdaySelector} from "../selectors/birthday";
import {SymbolEditor} from "../symbol-editor";
import {RadioGroup} from "../radio-group";
import {organization_types} from "../../../data/unaty/organization_types";
import ManageDisplayName from "../edit-fields-section/manage-display-name";
import {BasicSelect} from "../../core/basic-select";
import {PermissionsRef, PermissionsSelectOptions} from "../../../data/unaty/permission_ref";
import {MemberTypePermissionsArr, MemberTypePermissionPresets} from "../../../data/unaty/member_type_permission_presets";
import {
    MemberTypeDirectoryVisibility,
    MemberTypeDirectoryVisibilityArr
} from "../../../data/unaty/member_type_directory_visibility";
import {MultiMemberSelect} from "../multi-member-select";
import {FolderSelect} from "../folder-select";
import {folder_permissions_options, folder_security_options} from "../../../../app/community/files/new-folder";
import HandleSelector from "../handle-selector";

import {GroupTypeSelect} from "../../reorganize/group-type-selector";
import {NewEntitySearch} from "../../reorganize/entity-search/new-entity-search";
import {Badge, ColorInput, Toggle} from "../../core";
import {genders, genders_object} from "../../../data/general/genders";
import {week_days_object, week_starts_on} from "../../../data/general/week-days";
import {PFFieldRenderer} from "../../../../app/quick-profile-update/field-renderer";
import {MemberJoinDate} from "../../reorganize/member-join-date";
import {RichPhoneInput} from "../../inputs/rich-phone-input";
import {isAnonymousUnatyEmail} from "../../../utilities/anonymous-emails";
import {OrganizationTypeSelectors} from "../../custom/organization-type-selectors";
import {AdminImageEditorField} from "../../custom/admin-image-editor-field";
import {CreateableAsyncSelect} from "../../advanced-selects/createable-async-select";
import {DEFAULT_USER_PHOTO} from "../../../../config/defaults";
import {EditMemberSelect} from "../../edit-member-select";
import {TimezoneSwitcher} from "../../timezone-switcher";
import {TextAndEmoji} from "../text-and-status";
import {RulesEditor} from "../../rules-editor";

const Container = styled.div`
    
`;


const LinkText = styled.div`
    line-height: 20px;
    font-size: 12px;
    font-weight: 500;
   
    > a {
     color: #2F80ED;
     text-decoration: none;
    }
`;

const MetaText = styled.div`
    font-size: 13px;
    margin-top: 2px;
`;

const OptionalText = styled.div`
    color: #697386;
    display: inline;
`;

const LabelSection = styled.div`
    display: grid;
    grid-template-columns: 1fr auto;
`;

const InputSection = styled.div`
    margin-top: 6px;
    position: relative;
`;

const MetaSection = styled.div`
    margin-top: 4px;
    display: flex;
    > div:first-child {
        flex-grow: 1;
    }
`;

const CharCount = styled.div`
  font-size: 14px;
      position: absolute;
    top: 12px;
    right: 10px;
`;

const TagsBox = styled.div`
    > span {
        margin-right: 4px;
        margin-bottom: 4px;    
    }
`;

function convertToObj(arr) {
    if (!arr) {
        return {};
    }
    let obj = {};
    for (let i = 0; i < arr.length; i++) {
        obj[arr[i].value] = true;
    }
    return obj;
}

function convertToArr(obj, options, field, ref_obj) {
    if (!obj) {
        return [];
    }
    const keys = Object.keys(obj);
    let new_arr = [];
    for (let i = 0; i < keys.length; i++) {
        const key = keys[i];
        let label = field ? options[key][field] : options[key];
        if(ref_obj&&!ref_obj[key]) {
            continue;
        } else if(!label) {
            continue;
        }
        new_arr.push({
            value: key,
            label
        });
    }
    return new_arr;
}

function transformOptions(arr) {
    let new_arr = [],new_obj = {};
    for (let i = 0; i < arr.length; i++) {
        if(arr[i].hidden) {
            continue;
        }
        new_obj[arr[i].value] = arr[i].text;
        new_arr.push({
            value: arr[i].value,
            label: arr[i].text
        })
    }
    return {arr:new_arr,obj:new_obj};
}


function getMemberValue(v) {
    if (!v) {
        return null;
    }
    if (typeof v === 'string') {
        return {
            type: 'members',
            value: v
        }
    } else {
        return v;
    }
}

function getGroupValue(v) {
    if (!v) {
        return null;
    }
    if (typeof v === 'string') {
        return {
            type: 'groups',
            value: v
        }
    } else {
        return v;
    }
}

function getAddressValue(v) {
    if (!v) {
        return {
            line_one: '',
            line_two: '',
            city: '',
            state: '',
            zip_code: '',
            country: ''
        }
    }
    return v;
}

function getMultiSelectValue(val) {
    if(!val) {
        return [];
    }
    return val;
}

function getSelectValue(v) {
    if (!v) {
        return '';
    }
    if (typeof v === 'string') {
        return v;
    } else {
        return v.label;
    }
}

function getMentorValue(v) {
    if (!v) {
        return null;
    }
    if (typeof v === 'string') {
        return {
            type: 'members',
            value: v
        }
    } else {
        return v;
    }
}

export const getInputByType = (type, onChange, onBlur, value, {
    autoFocus, meta = {
        is_admin: false,
        user_uid: ''
    }, taxonomy = '', placeholder = '', services = null, exclude, disabled = false, _allowed, _exclusions, time_format, options_obj = {}, options, onSelect, hideExisting, blank_option, _existing, show_hidden, data, onAdd, onRemove, emoji
}, field="") => {
    let pv;
    switch (type) {
        case 'mainlogin' : {
            return <InputField id="email" type="username" autoComplete="email" autoFocus={autoFocus} value={value}
                               onBlur={onBlur} onChange={onChange}/>
        }
        case 'join-date' : {
            return <div>
                <MemberJoinDate simple onBlur={onBlur} onChange={(njd)=>onChange(njd)} existing={value}/>
            </div>
        }
        case 'week-starts-on': {
            const val = value ? value : 'sunday';
            return <BasicSelect value={{value: val, label: week_days_object[val]}} field={''} blank_option={false} onChange={(v) => {
                onChange(v.value)
            }} options={week_starts_on}/>
        }
        case 'group-type-select' : {
            return <GroupTypeSelect placeholder='Type' is_admin={meta.is_admin} value={value} onChange={onChange}/>;
        }
        case 'custom-add-select' : {
            return <CreateableAsyncSelect taxonomy={taxonomy} placeholder={placeholder} value={value} onChange={onChange}
                                    autoFocus={autoFocus}/>
        }
        case 'email' : {
            if(isAnonymousUnatyEmail(value)&&field) {
                pv = "";
            } else {
                pv = value;
            }
            return <InputField id="email" placeholder={placeholder} type="email" autoComplete="email"
                               autoFocus={autoFocus} value={pv} disabled={disabled}
                               onBlur={onBlur} onChange={onChange}/>
        }
        case 'radio-group' : {
            return <RadioGroup options={options} onSelect={(v) => {
                onChange(v);
            }} value={value}/>
        }
        case 'folder-permissions-type' : {
            return <Select value={value} field={''} blank_option={false} onChange={(f, v) => {
                onChange(v)
            }} options={folder_permissions_options}/>
        }
        case 'folder-security' : {
            return <Select value={value} field={''} blank_option={false} onChange={(f, v) => {
                onChange(v)
            }} options={folder_security_options}/>
        }
        case 'permissions-type' : {
            return <BasicSelect value={{value: value, label: MemberTypePermissionPresets[value].title}}
                                onChange={(v) => {
                                    onChange(v.value)
                                }} options={MemberTypePermissionsArr}/>
        }
        case 'directory-visibility' : {
            return <BasicSelect value={{value: value, label: MemberTypeDirectoryVisibility[value].title}}
                                onChange={(v) => {
                                    onChange(v.value)
                                }} options={MemberTypeDirectoryVisibilityArr}/>
        }
        case 'permissions' : {
            return <BasicSelect clearable={false} onChange={(val) => {
                // convert to obj
                onChange(convertToObj(val));
            }} multi value={convertToArr(value, PermissionsRef, 'title')} options={PermissionsSelectOptions}/>
        }
        case 'new-select' : {
            return <BasicSelect value={value?{value: value, label: options_obj[value]}:null}
                                onChange={(v) => {
                                    onChange(v.value)
                                }} options={options}/>
        }
        case 'switch' : {
            return <Toggle layout='switch' checked={value} onBlur={onBlur} onToggle={()=>{
                onChange(!value)
            }} />
        }
        case 'checkbox' : {
            return <div className="p-px">
                <Toggle checked={value}
                        label={value?options.checked_label:options.unchecked_label}
                        onToggle={() => {
                               onChange(!value)
                           }}
            />
            </div>
        }
        case 'text' : {
            return <InputField type="text" disabled={disabled} placeholder={placeholder} autoFocus={autoFocus}
                               value={value} onBlur={onBlur}
                               onChange={onChange}/>
        }
        case 'phone' : {
            return <RichPhoneInput autoFocus={autoFocus} onChange={onChange} value={value} onBlur={onBlur} />
        }
        case 'handle' : {
            return <InputField type="handle" disabled={disabled} placeholder={placeholder} autoFocus={autoFocus}
                               value={value} onBlur={onBlur}
                               onChange={onChange}/>
        }
        case 'file-name' : {
            return <InputField type="text" autoSelect='filename' disabled={disabled} placeholder={placeholder}
                               autoFocus={autoFocus}
                               value={value} onBlur={onBlur}
                               onChange={onChange}/>
        }
        case 'folder' : {
            return <FolderSelect value={value} onBlur={onBlur}
                                 onChange={onChange}/>
        }
        case 'number' : {
            return <InputField type="number" autoFocus={autoFocus} value={value?value.toString():""} onBlur={onBlur}
                               onChange={(v)=>{
                               const nv = parseInt(v);
                               if(isNaN(v)) {
                                   onChange("")
                               } else {
                                   onChange(nv)
                               }
                               }}/>
        }
        case 'user-handle' : {
            return <HandleSelector change_mode autoFocus default_text={value} setHandle={(handle) => {
                onChange(handle);
            }} type='user' scope='global'/>
        }
        case 'member-handle' : {
            return <HandleSelector change_mode default_text={value} setHandle={(handle) => {
                onChange(handle);
            }} type='member' scope={meta.community_uid}/>
        }
        case 'role-handle' : {
            return <HandleSelector change_mode default_text={value} setHandle={(handle) => {
                onChange(handle);
            }} type='role' scope={meta.community_uid}/>
        }
        case 'multi-select' : {
            const {arr,obj} = transformOptions(options)
            return <BasicSelect clearable={false} onChange={(val) => {
                // convert to obj
                onChange(convertToObj(val));
            }} multi value={convertToArr(value, options_obj, null, obj)} options={arr}/>
        }
        case 'date' : {
            return <InputField type='date' autoFocus={autoFocus} value={value} onChange={onChange} onBlur={onBlur}/>
        }
        case 'entities' : {
            return <SelectEntities hideExisting={hideExisting} _existing={_existing} onSelect={onSelect}
                                   onRemove={onRemove} value={value}/>
        }
        case 'color' : {
            return <ColorInput show_label={false} value={value} onChange={(v)=>onChange(v)} />;
        }
        case 'profile-picture' : {
            // todo get community default photo
            return <div>
                <AdminImageEditorField show_selector dims="w-12 h-12" title='Profile Picture' type="profile-picture" url={value.url?value.url:DEFAULT_USER_PHOTO} color={value.color} handleUrl={(url,color) => {
                onChange({url, color})
            }}/>
            </div>
        }
        case 'community-logo' : {
            return <div>
                <AdminImageEditorField title="Community Logo" dims="w-12 h-12" type="community-logo"
                                           handleUrl={(url, color) => onChange({url, color})} url={value.url} color={value.color}/>
            </div>
        }
        case 'text-emoji': {
             return <TextAndEmoji meta={null}
                                 onBlur={()=>{
                                     if(onBlur) {
                                         onBlur()
                                     }
                                 }} label={""} placeholder={placeholder}
                                 changeEmoji={(emoji) => {
                                     if(typeof emoji === 'string') {
                                         onChange({emoji,name:value.name})
                                     } else {
                                         onChange({emoji:emoji.native,name:value.name})
                                     }
                                 }} emoji={value.emoji} text={value.name} changeText={(v) => {
                 onChange({emoji:value.emoji,name:v})
            }}/>;
        }
        case 'symbol' : {
            return <div>
                <div className="inline-block">
                <SymbolEditor value={value} onChange={(emoji) => {
                onChange(emoji.native)
            }}/>
                </div>
            </div>;
        }
        case 'display-name' : {
            return <ManageDisplayName onChange={(val) => {
                onChange(val)
            }} edit value={value}/>
        }
        case 'select' : {
            return <Select usePortal value={value} field={''} blank_option={blank_option} onChange={(f, v) => {
                onChange(v)
            }} options={options}/>
        }
        case 'select-email' : {
            return <Select usePortal value={value} field={''} blank_option={false} onChange={(f, v) => {
                onChange(v)
            }} options={options}/>
        }
        case 'country' : {
            return <BasicSelect onBlur={onBlur} searchable={true} value={value === '' ? null : {value: value, label: countries[value]}}
                                onChange={(v) => {
                                    onChange(v.value)
                                }} options={countries_array}/>
        }
        case 'gender' : {
            return <BasicSelect onBlur={onBlur} placeholder="Sex" searchable={true} value={value === '' ? null : {value: value, label: genders_object[value]}}
                                onChange={(v) => {
                                    onChange(v.value)
                                }} options={genders}/>
        }
        case 'organization-type' : {
            return <Select value={value} field={''} blank_option={blank_option} onChange={(f, v) => {
                onChange(v)
            }} options={organization_types}/>
        }
        case 'organization-category' : {
            return <OrganizationTypeSelectors show_label={false} onChange={(p)=>{
                onChange(p)
            }} type={value.type} category={value.category} />
        }
        case 'timezone' : {
            return <TimezoneSwitcher value={value} onChange={(tz)=>onChange(tz)} />
        }
        case 'language' : {
            return <Select value={value} field={''} blank_option={false} onChange={(f, v) => {
                onChange(v)
            }} options={languages}/>
        }
        case 'member-type' : {
            return <MemberTypeSelect usePortal={false} onBlur={onBlur} value={value} onChange={(val) => {
                onChange(val)
            }}/>;
        }
        case 'member': {
            // need to exclude current user
            if(!meta.community_uid) {
                return <div>
                    missing community uid
                </div>
            }
            let excluded = meta.user_uid ? {[meta.user_uid]: true} : {};
            const mv = getMemberValue(value);

            return <NewEntitySearch placeholder={placeholder?placeholder:"Search.."} context={meta} is_multi={false}
                                    searchable={{members: true}}
                                    excluded={{members: excluded}} onChange={(val) => {
                                        onChange(val.value)
            }}
                                    value={mv}/>;
        }
        case 'assignee': {
            if(!meta.community_uid) {
                return <div>
                    missing community uid
                </div>
            }
            const mv = getMemberValue(value);

            return <NewEntitySearch placeholder={placeholder?placeholder:"Search.."} context={meta} is_multi={false}
                                    searchable={{members: true}}
                                    excluded={{members: {}}} onChange={(val) => {
                onChange(val.value)
            }}
                                    value={mv}/>;
        }
        case 'group': {
            // need to exclude current group
            return <NewEntitySearch placeholder={placeholder?placeholder:"Search for Groups"} context={meta} is_multi={false}
                                    searchable={{groups: true}} search_ctx={['groups']}
                                    excluded={{groups: {}}} onChange={(val) => onChange(val.value)}
                                    value={getGroupValue(value)}/>;
        }
        case 'moderators' : {
            return <MultiMemberSelect _allowed={_allowed} _exclusions={_exclusions} value={value} onChange={(val) => {
                onChange(val)
            }} onBlur={onBlur}/>
        }
        case 'cover-photo' : {
            return <AdminImageEditorField show_selector title="Cover Photo" dims="w-12 h-8" type="cover-photo"
                                          handleUrl={(url, color) => {
                                              onChange({url,color})
                                          }} color={value.color} url={value.url}/>
        }
        case 'community': {
            return <div>
                community select field
            </div>
        }
        case 'multi-communities': {
            return <div>
                multi-communities select field
            </div>
        }
        case 'address': {
            let final = getAddressValue(value);
console.log("ADDRESS",final)
            return <PFFieldRenderer meta={meta} onChange={(v, ex) => {

               onChange({...v});
            }} type={'address'} input_props={{autoFocus: true}} cf_data={null}
                                    value={final}/>
        }
        case 'multi-select-taxonomy': {
            const v1 = value ? getMultiSelectValue(value) : [];

            let tax = taxonomy ? taxonomy : options && options.taxonomy ? options.taxonomy : "";
            if(!tax) {
                return null;
            }
            return <div>
                {v1.length>0&&<TagsBox className="pb-2">
                    {v1.map((it,i)=><Badge can_remove text={it.label} handleRemove={()=>{
                        let new_arr = v1;
                        new_arr.splice(i,1);
                        onChange(new_arr);
                    }} key={`${i}-${it.label}`} />)}
                </TagsBox>}
                <div>
                    <CreateableAsyncSelect existing={v1} multi taxonomy={tax} placeholder={"Search to add"} value={""}
                                           onChange={(val) => {
                                               const labels = v1.map(a=>a.label);
                                               if(labels.indexOf(val)!==-1) {
                                                   return;
                                               }
                                         onChange([...v1, {label: val}]);
                                     }}
                                     autoFocus={autoFocus}/>
                </div>
            </div>
        }
        case 'select-taxonomy': {
            let tax = taxonomy ? taxonomy : options && options.taxonomy ? options.taxonomy : "";
            if(!tax) {
                return null;
            }
            return <CreateableAsyncSelect taxonomy={tax} placeholder={""} value={getSelectValue(value)}
                                    onChange={(val) => onChange({label: val})}
                                    autoFocus={autoFocus}/>
        }
        case 'mentor': {
            // need to exclude current user
            if(!meta.community_uid) {
                return <div>
                    missing community uid
                </div>
            }
            let excluded = meta.member_id ? {[meta.member_id]: true} : {};
            const mv = getMentorValue(value);

            return <NewEntitySearch placeholder={placeholder?placeholder:"Search.."} context={meta} is_multi={false}
                                    searchable={{members: true}} excluded_message={'(you)'}
                                    excluded={{members: excluded}} onChange={(val) => onChange(val.value)}
                                    value={mv}/>;
        }
        case 'mentees': {
            let excluded = meta.member_id ? {[meta.member_id]: true} : {};
            let temp_ids = value?{...value}:{};
            let all_ids = Object.keys(temp_ids);
            if(!meta.community_uid) {
                return <div>
                    missing community uid
                </div>
            }
            return <div>
                {all_ids.length>0&&<div className="mb-1.5"><EditMemberSelect community_uid={meta.community_uid} ids={all_ids} member_id={meta.member_id} onRemove={(id)=>{
                    let new_ids = {...temp_ids};
                   if(new_ids[id]) {
                       delete new_ids[id];
                   }
                   onChange(new_ids);
                }} /></div>}
                {all_ids.length<7&&<NewEntitySearch search_ctx={['member']} placeholder={"Add Members"} context={meta} is_multi={true}
                                    searchable={{members: true}} is_clearable={false}
                                    excluded={{members: excluded}} onChange={(val) => {

                const item_type = val.type;
                const item_value = val.value;
                let new_ids = Object.keys(temp_ids);
                let new_obj = {};
                for(let i=0;i<new_ids.length;i++) {
                    new_obj[new_ids[i]] = true;
                }
                new_obj[item_value] = true;
                onChange(new_obj)
            }} value={null}/>}
            </div>
        }
        case 'birthday': {
            return <BirthdaySelector time_format={time_format} value={value} onChange={(val) => {
                onChange(val)
            }}/>;
        }
        case 'password' : {
            return <InputField id="password" type="password" autoComplete="password" autoFocus={autoFocus}
                               onBlur={onBlur} value={value} onChange={onChange}/>
        }
        case 'new-password' : {
            return <InputField id="password" type="password" autoComplete="new-password" autoFocus={autoFocus}
                               onBlur={onBlur} value={value} onChange={onChange}/>
        }
        case 'textarea' : {
            return <TextareaField autoFocus={autoFocus} value={value} onChange={onChange} onBlur={onBlur}/>
        }
        default : {
            return <InputField placeholder={placeholder} autoFocus={autoFocus} value={value} onChange={onChange} onBlur={onBlur}/>
        }
    }
};

// input-is-erroneous
// input-is-valid
// input-warning
function getClassName(error, success, warning, disabled) {
    if (disabled) {
        return 'disabled-field'
    } else if (error) {
        return 'input-is-erroneous';
    } else if (success) {
        return 'input-is-valid';
    } else if (warning) {
        return 'input-warning';
    } else {
        return '';
    }
}

export default function Field(props) {
    const {
        label, value, action=null, max = 0,  disabled = false, taxonomy, community_uid, placeholder, is_admin, _allowed, _exclusions, hideExisting = false, optional = false, error = false, success = false, data, warning = false, blank_option = true, options, inputClassName = '', onSelect, _existing, onAdd, onRemove, type, onBlur = () => {
        }, actionLink, show_hidden = true, autoFocus = false, meta, onChange, exclude
    } = props;

    if (type === 'delete-action') {
        return <div>

        </div>
    }

    return (
        <Container>
            {label && <LabelSection>
                {label && <div className="text-sm text-gray-700"><span>{label}</span> {optional &&
                <OptionalText>(Optional)</OptionalText>}</div>}
                {actionLink && <LinkText><Link tabIndex='-1' to={actionLink.link}>{actionLink.text}</Link></LinkText>}
                {action && <LinkText><a className="cursor-pointer" onClick={action.onClick}>{action.text}</a></LinkText>}
            </LabelSection>}
            <InputSection className={getClassName(error, success, warning, disabled)}>
                {getInputByType(type, onChange, onBlur, value, {
                    autoFocus,
                    data,
                    options,
                    onSelect,
                    placeholder,
                    onRemove,
                    taxonomy,
                    exclude,
                    hideExisting,
                    _existing,
                    blank_option,
                    show_hidden,
                    meta: {community_uid, is_admin},
                    _allowed,
                    _exclusions,
                    onAdd
                })}
                {max > 0 && value.length > 0 && <CharCount className='text-gray-600'>
                    {max - value.length}
                </CharCount>}
            </InputSection>
            {(meta || (max > 0 && value.length > 0)) && <MetaSection>
                {meta && <MetaText className='text-gray-700'>
                    {meta}
                </MetaText>}

            </MetaSection>}
        </Container>
    );
}