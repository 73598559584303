import React from "react";
import {DEFAULT_USER_PHOTO} from "../../../config/defaults";
import {MemberTypeIcon} from "../icon-lower-right-addon/special";
import {ChartPieIcon, DocumentIcon, FolderIcon} from "@heroicons/react/solid";
import {getFileTypeIcon} from "../old/file-uploader/helpers";
import {GroupDisplayIcon} from "../../utilities/groups";

export const USFileTypeIcon = ({type="image/png"}) => {
    return <div className="w-6 h-6 -m-1">
        {getFileTypeIcon(type)}
    </div>
}

export const USGroupTypeIcon = ({emoji="",color="",profile_picture="",profile_picture_color="",name="CC-Mitglieder"}) => {
    return <div className="w-5 h-5 -m-0.5">
        <GroupDisplayIcon emoji={emoji} profile_picture={profile_picture}
                          profile_picture_color={profile_picture_color} color={color}
                          name={name}/>
    </div>
}

export const us_data_mocks = [
    {
        data_type: 'role',
        id: '1234',
        label: 'President',
        meta: 'Sean McCall',
        note: 'Role',
        icon: {
            type: 'image',
            url: `${DEFAULT_USER_PHOTO}_small?alt=media`
        },
    },
    {
        data_type: 'member',
        id: '1234',
        label: 'Ramin Rente',
        meta: 'ramin.rente@gmail.com',
        note: 'Member',
        icon: {
            type: 'image',
            url: `${DEFAULT_USER_PHOTO}_small?alt=media`
        },
    },
    {
        data_type: 'segment',
        id: '1234',
        label: 'Isaren Muenchen',
        meta: '3 members',
        note: 'Segment',
        icon: {
            type: 'icon',
            icon: <ChartPieIcon />
        },
    },
    {
        data_type: 'file',
        id: '6234',
        label: 'Protokoll.pdf',
        note: 'File',
        icon: {
            type: 'icon',
            icon: <USFileTypeIcon />
        },
    },
    {
        data_type: 'folder',
        id: '8324',
        label: 'My folder',
        note: 'Folder',
        icon: {
            type: 'icon',
            icon: <FolderIcon />
        },
    },
    {
        data_type: 'group',
        id: '7344',
        label: 'Isaren Muenchen',
        meta: '3 members',
        note: 'Group',
        icon: {
            type: 'icon',
            icon: <USGroupTypeIcon />
        },
    },
    {
        data_type: 'team',
        id: '5126',
        label: 'Isaren Muenchen',
        meta: '3 members',
        note: 'Team',
        icon: {
            type: 'icon',
            icon: <USGroupTypeIcon />
        },
    },
    {
        data_type: 'member',
        id: '6235',
        label: 'John Smith',
        note: 'Member mini',
        icon: {
            type: 'image',
            url: `${DEFAULT_USER_PHOTO}_small?alt=media`
        },
    },
    {
        data_type: 'tag',
        id: '9403',
        label: 'Trending Users',
        note: 'Tag',
        icon: {
            type: 'emoji',
            emoji: `🔥`
        },
    },
    {
        data_type: 'user',
        id: '9403',
        label: 'Fabian Ahrens',
        meta: '@ahrens',
        note: 'User',
        icon: {
            type: 'image',
            url: `${DEFAULT_USER_PHOTO}_small?alt=media`
        },
    },
    {
        data_type: 'community',
        id: '62435',
        label: 'Sandbox',
        meta: '@sandbox',
        note: 'Community',
        icon: {
            type: 'image',
            url: `${DEFAULT_USER_PHOTO}_small?alt=media`
        },
    },
    {
        data_type: 'member_type',
        id: '1454',
        label: 'Ambassadors',
        note: 'Member Type',
        icon: {
            type: 'icon',
            icon: <MemberTypeIcon />
        }
    }
];