import { useState, useEffect } from 'react';

const useInfiniteScroll = (style,id,options,callback) => {
    const [isFetching, setIsFetching] = useState(false);

    useEffect(() => {
        if(style!=='infinite') {
            return;
        }
        const el = document.getElementById(id);
        if(!el) {
            console.warn("Scroll ID not found",id)
            return;
        }
        el.addEventListener('scroll', handleScroll);
        return () => el.removeEventListener('scroll', handleScroll);
    }, [style,id]);

    useEffect(() => {
        if (!isFetching) return;
        callback();
    }, [isFetching]);

    function handleScroll() {
        const el = document.getElementById(id);

        const padding = options.padding;
        const container_el = document.documentElement;
        const visible_space = (container_el.offsetHeight-el.offsetTop);
        if (isFetching) {
            return
        }

        if(options.container) {
            const bottom_space = el.scrollHeight - (el.scrollTop + el.offsetHeight);

            if(bottom_space<padding) {
                setIsFetching(true);
            }

        } else {
            const scroll_top = el.scrollTop;
            const left = el.scrollHeight-visible_space - scroll_top;

            if(left<padding) {
                setIsFetching(true);
            }
        }
    }

    return [isFetching, setIsFetching];
};

export default useInfiniteScroll;