// type = list
const base = {
    method: 'email',
    muted: false,
    preference: 'every-post'
};
export function getEntityNotificationPreferences(n,type,id) {
    const field = `${type}-${id}`;
    if(n[field]) {
        return {
            method: n[field].method ? n[field] : 'email',
            muted: n[field].muted ? true : false,
            preference: n[field].preference ? n[field].preference : 'every-post'
        };
    }
    return {...base};
}