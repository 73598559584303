import React from 'react';
import styled from "styled-components";
const Frame = styled.div`
    padding: 0 22px;
    
    line-height: 1.4;
    margin-top: 2px;
`;

const Bio = styled.div`
max-width: 80%;
font-size: 15px;
> textarea {
border: none;
    padding: 4px;
    width: 100%;
    resize: none;
        line-height: 1.4;
        outline: none;
    border-radius: 4px;
    margin: -4px;
}
> textarea:focus-within {
    box-shadow: rgba(0,0,0,0) 0px 0px 0px 0px, rgba(58,151,212,0.36) 0px 0px 0px 3px, rgba(0,0,0,0) 0px 0px 0px 0px, rgba(60,66,87,0.16) 0px 0px 0px 1px, rgba(0,0,0,0) 0px 0px 0px 0px, rgba(0,0,0,0) 0px 0px 0px 0px, rgba(0,0,0,0) 0px 0px 0px 0px;
}
> textarea:not(:focus-within):hover {
    background-color: #efefef;
}
`;
var handle_regex = /^(?!.*\bRT\b)(?:.+\s)?@\w+/i;

const max_len = 30;

function shortenURL(url) {
    const len = url.length;
    if(len>max_len) {
        return `${url.slice(0,max_len)}..`
    }
    return url;
}

function urlify(text) {
    var url_regex = /(https?:\/\/[^\s]+)/g;
    return text.replace(url_regex, function(url) {
        return '<a target="_blank" class="color-link" href="' + url + '">' + shortenURL(url) + '</a>';
    });
}

export const PrBio = ({editing=false,changeBio,bio=''}) => {
    if(bio===''&&!editing) {
        return null;
    }
    if(editing) {
        return <Frame>
            <Bio>
                <textarea placeholder="About yourself.." rows={3} value={bio} onChange={(e)=>{changeBio(e.target.value)}} />
            </Bio>
        </Frame>
    }
    return <Frame>
        <Bio dangerouslySetInnerHTML={{__html: urlify(bio)}} className='text-gray-900' />
    </Frame>
};