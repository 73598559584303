import React from 'react';
import styled from "styled-components";
import {CIModerator} from "../../../icons/custom/Moderator";
import {CIRole} from "../../../icons/custom/Role";

const ModIcon = styled.div`
display: inline-flex;
    > svg {
    fill: ${props=>props.fill};
        height: ${props=>props.height}px;
    }
`;

export default function RoleIcon({fill="currentColor",height=24}) {

    return <ModIcon height={height} fill={fill}>
        <CIRole />
    </ModIcon>
}