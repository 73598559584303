export const PSCoverPhotos = [
    {
        title: 'Nature',
        desc: '',
        url: '',
        images: [
            {
                "url": "https://firebasestorage.googleapis.com/v0/b/unaty-staging.appspot.com/o/cover_photos%2Fo2mpbl3Za",
                "color": "rgb(44,45,39)",
                "author": "Ivana Cajina"
            },
            {
                "url": "https://firebasestorage.googleapis.com/v0/b/unaty-staging.appspot.com/o/cover_photos%2FRUlZTSKfI",
                "color": "rgb(253,248,245)",
                author: "Jay Mantri",
            }
        ]
    },
    {
        title: 'Architecture',
        desc: '',
        url: '',
        images: [
            {
                author: "Laura Adai",
                "url": "https://firebasestorage.googleapis.com/v0/b/unaty-staging.appspot.com/o/cover_photos%2FqeBWtrWee",
                "color": "rgb(191,212,218)"
            },
            {
                author: "Gabriel Tovar",
                "url": "https://firebasestorage.googleapis.com/v0/b/unaty-staging.appspot.com/o/cover_photos%2FNor1ISsGN",
                "color": "rgb(222,222,222)"
            }
        ]
    },
    {
        title: 'Gradients & Patterns',
        desc: '',
        url: '',
        images: [
            {
                title: "Dance to Forget",
                author: "UI Gradients",
                "url": "linear-gradient(to bottom, #ff4e50, #f9d423)",
                "color": "#ff4e50"
            },
            {
                title: "Cherryblossoms",
                author: "UI Gradients",
                "url": "linear-gradient(to bottom, #fbd3e9, #bb377d)",
                "color": "#fbd3e9"
            },
            {
                title: "Dark Ocean",
                author: "UI Gradients",
                "url": "linear-gradient(to top, #373b44, #4286f4)",
                "color": "#373b44"
            },
            {
                title: "Blue Skies",
                author: "UI Gradients",
                "url": "linear-gradient(to bottom, #56ccf2, #2f80ed)",
                "color": "#56ccf2"
            }
        ]
    },
    {
        title: 'American Art',
        desc: '',
        url: 'https://www.metmuseum.org/art/collection/search#!?perPage=20&offset=0&pageSize=0&sortBy=Relevance&sortOrder=asc&searchField=All&showOnly=highlights&material=Paintings&department=1',
        images: [
            {
                "url": "https://firebasestorage.googleapis.com/v0/b/unaty-staging.appspot.com/o/cover_photos%2FEdCfRV6Mj",
                "color": "rgb(185,190,200)",
                "title": "At the Seaside",
                "author": "William Merritt Chase"
            },
            {
                title: "Lake Squam from Red Hill",
                author: "William Trost Richards",
                "url": "https://firebasestorage.googleapis.com/v0/b/unaty-staging.appspot.com/o/cover_photos%2FLjLt7Hci7",
                "color": "rgb(218,211,180)"
            },
            {
                title: "The Palisades",
                author: "John William Hill",
                "url": "https://firebasestorage.googleapis.com/v0/b/unaty-staging.appspot.com/o/cover_photos%2Fto0WyNo3r",
                "color": "rgb(217,212,193)"
            },
            {
                title: "Stage Fort across Gloucester Harbor",
                author: "Fitz Henry Lane",
                "url": "https://firebasestorage.googleapis.com/v0/b/unaty-staging.appspot.com/o/cover_photos%2FfcpcFIe7R",
                "color": "rgb(0,0,2)"
            },
            {
                title: "The Thomas Hunt and the America",
                author: "James Bard",
                "url": "https://firebasestorage.googleapis.com/v0/b/unaty-staging.appspot.com/o/cover_photos%2F5s85pL_T5",
                "color": "rgb(76,75,73)"
            },
            {
                title: "Washington Crossing the Delaware",
                author: "Emanuel Leutze",
                "url": "https://firebasestorage.googleapis.com/v0/b/unaty-staging.appspot.com/o/cover_photos%2F6STvvoa9J",
                "color": "rgb(39,39,39)"
            },
            {
                title: "Landscape—Scene from Thanatopsis",
                author: "Asher Brown Durand",
                "url": "https://firebasestorage.googleapis.com/v0/b/unaty-staging.appspot.com/o/cover_photos%2FPSVOUdu3r",
                "color": "rgb(199,186,160)"
            }
        ]
    },
    {
        title: 'European Art',
        desc: '',
        url: '',
        images: [
            {
                title: "Bal du Moulin de la Galette",
                author: "Auguste Renoir",
                "url": "https://firebasestorage.googleapis.com/v0/b/unaty-staging.appspot.com/o/cover_photos%2Fk4SNXQtPW",
                "color": "rgb(34,44,53)"
            },
            {
                title: "The Creation of Adam",
                author: "Michelangelo",
                "url": "https://firebasestorage.googleapis.com/v0/b/unaty-staging.appspot.com/o/cover_photos%2FcmZvps4I_",
                "color": "rgb(172,183,185)"
            }
        ]
    },
    {
        title: 'Japanese Art',
        desc: '',
        url: '',
        images: [
            {
                title: "Under the Wave off Kanagawa",
                author: "Katsushika Hokusai",
                "url": "https://firebasestorage.googleapis.com/v0/b/unaty-staging.appspot.com/o/cover_photos%2FTGVsQAjun",
                "color": "rgb(248,240,219)"
            },
            {
                title: "Japanese Landscape with Mount Fuji",
                author: "Unknown",
                "url": "https://firebasestorage.googleapis.com/v0/b/unaty-staging.appspot.com/o/cover_photos%2FeNJiX1NKE",
                "color": "rgb(49,43,19)"
            }
        ]
    }
];