export function downloadFile(href,name) {
    const link = document.createElement('a');
    link.href = href;
    link.target = '_blank';
    link.download = name;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    link.remove();
    return true;
}