import React, {useContext, useState} from "react";
import styled from "styled-components";
import {authFetch} from "../../../config/network";
import UnProgressBar from "../home/un-progress-bar";
import Form from "../../../library/components/old/form";
import Modal from "../../../library/components/old/modal";
import {CommunityContext} from "../community-context";
import MemberImportSummary from "../../../library/components/reorganize/member-import-summary";
import {ActionWithIcon, Button} from "../../../library/components/core";
import {AddManyMembers} from "../../../library/components/add-many-members";
import {ArrowRightIcon} from "@heroicons/react/solid";
import {analytics} from "firebase";
import {membersBuildInviteTo} from "../../../library/playground/advanced-table/wrapper";
import {getManyItemsAsync} from "../../../library/utilities/get-many-items";
import {GlobalContext} from "../../global/global-context";
import {useHistory} from "react-router-dom";

const ProgressBarCont = styled.div`
  padding-top: 12px;
`;

async function prepSendInvites(community, ids) {
    return await getManyItemsAsync('community_members.members', ids, community.uid);
}

function Step({label,num,active,active_num}) {
const phase = active?"active":active_num>num?"disabled":"";
    return <div className="flex items-center space-x-1.5">
        <div className={`w-5 h-5 rounded-full  text-xs font-medium flex justify-center items-center ${phase==='active'?"bg-selection text-white":phase==='disabled'?"bg-gray-200 text-gray-400":"bg-gray-200 text-gray-600"}`}>{num}</div>
        <div className={`${phase==='active'?"text-selection font-semibold":phase==='disabled'?"text-gray-500 font-semibold":"text-gray-600 font-medium"} text-xssm `}>{label}</div>
    </div>
}

function SimpleSteps({steps,active}) {

    return <div className="flex space-x-4">
        {steps.map((st,k)=>{
            return <Step key={st.label} {...st} num={k+1} active={k===active} />
        })}
    </div>
}

export default function CreateMember({cb=()=>{},flow='generic',initial=[],onConfirmed=()=>{},onClose=()=>{},open}) {
    const history = useHistory();
    const global = useContext(GlobalContext);
    const community = useContext(CommunityContext);
    const [step, setStep] = useState(1);
    const [members, setMembers] = useState([]);
    const [results, setResults] = useState({
            added: [],
            existing: [],
            error: [],
        })
    const [stage, setStage] = useState('');

    function create() {
        
        if (stage==='creating') {
            return;
        }

        setStage('creating')
        setStep(2);

        const payload = {
            community_uid: community.uid,
            member_id: community.member_id,
            members: members
        };

        authFetch("/members/add-many", handleResult.bind(this), handleError.bind(this), "POST", {payload});
    }

    function handleResult(result) {
        global.addToast({text: `Members were added.`, intent: 'success'});

        analytics().logEvent('add_members', {
            event_id: 'add_members',
            content_type: 'community',
            community_uid: community.uid
        });

        if (cb && result && result.data && result.data.added && result.data.added.length) {
            cb(result.data.added.map(it => it.member_id));
        }

        if (result.ok) {
            setResults(result.data)
            setStep(3)
            setStage('');
        } else {
            setStep(3)
            setStage('');
        }

    }

    function handleError() {

        global.addToast({text: `Members could not be created.`, intent: 'error'});
       setStage('')
    }

    function sendInvite() {
        const ids = results.added.map(item => {
            return {
                id: item.member_id,
                invitee_user_uid: item.uid
            }
        });

        setStage('inviting')

        prepSendInvites(community, ids.map(it => it.id))
            .then(members => {
                const to = membersBuildInviteTo(members);

                // todo this should later be taken from a template
                const prefill = {
                    to,
                    config: {},
                    content: `${community.member.name} (${community.member.account_email}) invited you to join ${community.data.name} on Unaty.`,
                    subject: `${community.member.name} invited you to join ${community.data.name}`,
                    type: 'invite',
                    template: 'system-invite',
                    cb: () => {
                        setStage('')
                    }
                };

                community.openComposer(prefill)
            })
    }

    let steps = [
        {label: "Create"},
        {label: "Invite"},
    ];

    if(flow==='add-to-group') {
        steps.push({label: "Add to Group"});
    }

    const dmt = community.data.preferences.members.default_member_type;
    let body, footerRight, topper, footerLeft, title, subtitle;
    switch (step) {
        case 1 : {
            topper = <div className="pb-1.5">
                <SimpleSteps steps={steps} active={0} />
            </div>;
            title = "Create Members";
            subtitle = "New members won't automatically be sent an invitation, but you can choose to send one after creating them."
            footerRight = <div className=" flex space-x-2">
                <Button text="Cancel" intent='standard' onClick={()=>onClose()}/>
                <Button disabled={members.length === 0} text="Create Members" intent='success'
                        onClick={create.bind(this)}/>
            </div>;
            footerLeft = <div className="hidden md:flex items-center h-8 space-x-2">
                <div className="text-sm  text-gray-500">
                    Looking to create many members?
                </div>
                <ActionWithIcon onClick={() => {
                    history.push(`/${community.data.handle}/admin/members/import`);
                }} size="sm" text="Import from file" icon_right icon={<ArrowRightIcon/>} inverse/>
            </div>;
            body = <Form handleSubmit={() => {
            }}>
                <AddManyMembers init_members_list={initial} start_rows={1} setNewMembers={(nm) => {
                    setMembers([...nm])
                }} default_member_type={dmt} show_add_member show_member_type/>
            </Form>;
            break;
        }
        case 2: {
            topper = <div className="pb-1.5">
                <SimpleSteps steps={steps} active={0} />
            </div>;
            title = "Create Members";
            footerRight = <div className=" flex space-x-2">
                <Button text="Create" loading={true} intent='secondary' onClick={() => {
                }}/>
            </div>;
            footerLeft = <div>

            </div>;
            body = <ProgressBarCont>
                <UnProgressBar seconds={4}/>
            </ProgressBarCont>;
            break;
        }
        case 3: {
            topper = <div className="pb-1.5">
                <SimpleSteps steps={steps} active={1} />
            </div>;
            title = "Invite Members";
            footerRight = <div className=" flex space-x-2">
                {results && results.added && results.added.length > 0 &&
                <Button loading={stage==='inviting'} intent="success" text="Send invite to new members"
                        onClick={sendInvite.bind(this)}/>}

            </div>;
            footerLeft = <div>
                {flow==='add-to-group'&&<Button text="Skip this step"
                                                onClick={()=>{setStep(step+1)}}/>}
                {flow==='generic'&&<Button text="Done" intent=''
                                           onClick={() => onConfirmed(results.added.map(it => it.member_id))}/>}
                {flow==='directory'&&<Button text="Return to Directory" intent=''
                                             onClick={() => onConfirmed(results.added.map(it => it.member_id))}/>}
            </div>;
            body = <div style={{minHeight: '200px'}}>
                <MemberImportSummary handle={community.data.handle} {...results} />
            </div>
            break;
        }
        case 4: {
            topper = <div className="pb-1.5">
                <SimpleSteps steps={steps} active={2} />
            </div>;
            title = "Add to Group";
            footerRight = <div className=" flex space-x-2">
                <Button text="No" onClick={() => onConfirmed([])}/>
                <Button text="Yes, add to Group" intent='success'
                        onClick={() => onConfirmed(results.added.map(it => it.member_id))}/>
            </div>;
            footerLeft = <div>

            </div>;
            body = <div style={{minHeight: '200px'}}>
                <p className="text-base text-gray-700">
                    Would you also like to add these member(s) to the group?
                </p>
            </div>
            break;
        }
    }

    return (
        <Modal size={step !== 1 ? 'medium' : '840px'} open={open} onClose={onClose} title={title} subtitle={subtitle}
               body={body} topper={topper}
               footerLeft={footerLeft}
               footerRight={footerRight}
        />
    );
}
