import React from 'react';
import classNames from 'classnames';
import styled from "styled-components";
const props = {
    title: "",
    subtitle: "",
    intent: "",
    icon: null,
    button: null
};

const IconBox = styled.div`

> svg {
    height: ${props=>props.icon_only?'64px':'28px'};
}
`;

function getBkgColor(intent,icon_only) {
    if(icon_only) {
        return '';
    }
    switch (intent) {
        case 'primary': {
            return 'bg-primary-100 bg-opacity-20';
        }
        case 'success': {
            return 'bg-success-100 bg-opacity-20';
        }
        case 'secondary': {
            return 'bg-secondary-100 bg-opacity-20';
        }
        case 'danger': {
            return 'bg-danger-100 bg-opacity-20';
        }
        default: {
         return 'bg-gray-50';
        }
    }
}

function getColor(intent) {
    switch (intent) {
        case 'primary': {
            return 'text-primary-700';
        }
        case 'success': {
            return 'text-success-700';
        }
        case 'secondary': {
            return 'text-secondary-700';
        }
        case 'danger': {
            return 'text-danger-700';
        }
        default: {
            return 'text-gray-700';
        }
    }
}

export function InfoState({title,fill=false,icon_only,align="center",subtitle,intent,icon,button}) {

    return <div className={`p-4 ${fill?"":"max-w-md"} text-${align}`}>
        {icon&&<div className={`justify-${align} flex mb-4`}>
            <IconBox icon_only={icon_only} className={classNames("h-14 rounded-full flex items-center justify-center w-14",getBkgColor(intent,icon_only),getColor(intent,icon_only))}>
                {icon}
            </IconBox>
        </div>}
        {(title||subtitle)&&<div className={align==='center'?"max-w-sm mx-auto":"max-w-sm"}>
            {title&&<div className="text-gray-800 text-xl mb-1 font-semibold">{title}</div>}
           {subtitle&&<div className="text-gray-500 text-sm">{subtitle}</div>}
        </div>}
        {button&&<div className="pt-6">
            {button}
        </div>}
    </div>
}