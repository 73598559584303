import React, {Component} from 'react';
import styled from "styled-components";
import firebase from "firebase/app";
import {
    MAX_FILE_SIZE
} from "../../../../config/defaults";

import AvatarEditor from 'react-avatar-editor';

import {
    handlePositionChange,
    imageReady, resetPlacement,
    rotateLeft,
    rotateRight,
    startUpload,
    uploadFile,
    zoomIn,
    zoomOut
} from "./helpers";

import Modal from "../modal";
import {ActionsGroup} from "../actions-group";
import {ZoomInIcon, ZoomOutIcon, ArrowLeftIcon, ArrowRightIcon, RefreshIcon} from '@heroicons/react/solid';
import UnProgressBar from "../../../../app/community/home/un-progress-bar";
import {Button} from "../../core";
import {DropzoneWrapper} from "../dropzone-wrapper";
import {
    iu_getAvatarContainerHeight,
    iu_getBorderRadius,
    iu_getCanvasDimensions,
    iu_getImageUploadingFolder, iu_getModalSize,
    iu_getSharpnessSettings
} from "./config";
import {TempTip} from "../../../playground/temp-tip";

const Wrapper = styled.div`
   display: inline-block;
   position: relative;
user-select: none;
`;

const ButtonsLeft = styled.div`
   display: flex;
`;

function getTopMargin(type,divider) {
    switch (type) {
        case 'profile-picture':
            return `${-101/divider}px`
        case 'sign-in-page':
            return '-200px'
        case 'email-header':
            return '-10px';
        case 'member-card':
            return ''
        case 'group-cover':
            return '-10px'
        case 'community-logo':
            return `${-101/divider}px`
        case 'cover-photo':
            return '-10px'
        default :
            return '-101px'
    }
}

const AvatarWrapper = styled.div`
    display: flex;
    justify-content: center;
    > canvas {
    margin-top: ${props=>getTopMargin(props.type,props.divider)};
    }
`;

export default class ImageUploader extends Component {
    constructor(props) {
        super(props);

        this.fileRef = React.createRef();
        this.editorRef = React.createRef();
        this.originalCanvas = React.createRef();
        this.mediumCanvas = React.createRef();
        this.smallCanvas = React.createRef();

        this.pica = null;

        this.folder = iu_getImageUploadingFolder(props.type);

        this.storage = firebase.storage().ref();

        this.state = {
            preview: null,
            open: false,

            scale: 1,
            position: {x: 0.5, y: 0.5},
            image_url: '',
            rotate: 0,

            move_start_x: 0,
            move_start_y: 0,

            initial_height: 0,
            initial_width: 0,

            uploading: false,
            dragging: false,
            shift_key_pressed: false,
            boundary_checks_disabled: true,
            progress: 0.2,
            image: null,
            files: []
        };

       const dimensions = iu_getCanvasDimensions(props.type);

        this.display_dimensions = dimensions.display;
        this.edit_canvas_dimensions = dimensions.edit;
        this.medium_dimensions = dimensions.medium;
        this.small_dimensions = dimensions.small;

        this.sharpness = iu_getSharpnessSettings(props.type);

        this.zoomOut = zoomOut.bind(this);
        this.zoomIn = zoomIn.bind(this);
        this.rotateLeft = rotateLeft.bind(this);
        this.rotateRight = rotateRight.bind(this);
        this.handlePositionChange = handlePositionChange.bind(this);
        this.imageReady = imageReady.bind(this);
        this.startUpload = startUpload.bind(this);
        this.uploadFile = uploadFile.bind(this);
    }

    setEditorRef = (editor) => this.editor = editor;

    openFileSelector() {
        this.fileRef.current.click();
    }

    notifyParent(url,color) {
        this.props.handleUrl(url,color);
    }

    handleSetInitialPosition(height,width) {
        const ratio = width / height;
        const canvas_dims = this.edit_canvas_dimensions;

        // The x and y co-ordinates (in the range 0 to 1) of the center of the cropping area of the image
        let new_pos = this.state.position;
        // 3.75 for sandbox logo
        // 0.56 for vertical
        if(ratio<0.7) {
            // align to top
            new_pos.y = (0.5*canvas_dims.height)/height;
            new_pos.x = 0.5;

            this.setState({
                rotate: 0,
                scale: 1,
                position: new_pos,
                initial_height: height,
                initial_width: width
            });
        } else if(ratio > 1.5) {
            // align to left
            // need to account for scale in pos?
            // this has to be relative to the width so it forms a square along the left
            // width = 1200
            // need x = ~0.2
            // canvas width is relevant, so 0.5 * canvas width
            new_pos.x = (0.5*canvas_dims.width)/width;
            new_pos.y = 0.5;

            this.setState({
                rotate: 0,
                scale: 1,
                position: new_pos,
                initial_height: height,
                initial_width: width
            });
        } else {
            new_pos.y = 0.5;
            new_pos.x = 0.5;
            this.setState({
                position: new_pos,
                rotate: 0,
                scale: 1,
            });
        }
    }

    handleLoadSuccess({height,width}) {
      this.handleSetInitialPosition(height,width);
    }

    selectFiles(e) {

        const file = e.target ? e.target.files[0] : e[0];

        if(!file) {
            return;
        }
        var filesize = ((file.size / 1024) / 1024).toFixed(4); // MB

        if (filesize > MAX_FILE_SIZE) {
            alert('Your file is too large, it must be under 5 MB');
            return;
        }

        this.setState({
            files: [file],
            open: true
        },()=>{
            if(this.props.onEditingImage) {
                this.props.onEditingImage();
            }
        })
    }

    close() {
        this.setState({
            files: [],
            open: false
        },()=>{
            if(this.props.doneEditingImage) {
                this.props.doneEditingImage();
            }
        })
    }

    handleMouseMove(e) {
        if(e.shiftKey&&!this.state.shift_key_pressed) {
            this.setState({
                shift_key_pressed: true
            })
        } else if(!e.shiftKey&&this.state.shift_key_pressed) {
            this.setState({
                shift_key_pressed: false
            })
        }
    }

    render() {
        const {children, style, title, wrapper=true, child_styles={}, type} = this.props;
        const {open, scale, position, files, uploading, progress, boundary_checks_disabled} = this.state;

        const canvases = <>
            <canvas height={this.medium_dimensions.height} width={this.medium_dimensions.width}
                    ref={this.mediumCanvas}/>

            <canvas height={this.small_dimensions.height} width={this.small_dimensions.width} ref={this.smallCanvas}/>
        </>;

        const actions = [
            {
                text: 'Reset',
                icon: <RefreshIcon/>,
                onClick: ()=>{
                    this.handleSetInitialPosition(this.state.initial_height,this.state.initial_width)
                }
            },
            {
                text: 'Zoom out',
                icon: <ZoomOutIcon/>,
                onClick: () => {
                    this.zoomOut()
                }
            },
            {
                text: 'Zoom in',
                icon: <ZoomInIcon/>,
                onClick: () => {
                    this.zoomIn()
                }
            },
            {
                text: 'Left',
                icon: <ArrowLeftIcon/>,
                onClick: () => {
                    this.rotateLeft()
                }
            },
            {
                text: 'Right',
                icon: <ArrowRightIcon/>,
                onClick: () => {
                    this.rotateRight()
                }
            }
        ];

        let main_style = style ? style : {};

        let accept = 'image/png,image/jpeg,image/jpg,image/svg+xml';

        let modal_size = iu_getModalSize(type);
        let border_radius = iu_getBorderRadius(type);

        const container_height = iu_getAvatarContainerHeight(type,this.display_dimensions);

        const divider = window.innerWidth<768 ? 1 : 1;

        const is_square = this.edit_canvas_dimensions.height === this.edit_canvas_dimensions.width;

        return <Wrapper style={main_style}>
            <div style={child_styles} className={`${this.props.hover?"transition-opacity hover:opacity-70":""}`} onClick={(e)=> {
                e.preventDefault();
                e.stopPropagation();
                this.openFileSelector();
            }}>
            {children}
            </div>
            {wrapper&&<DropzoneWrapper accept={accept} setFile={this.selectFiles.bind(this)} />}

            <input id='profile-picture' name='profile-picture' onChange={this.selectFiles.bind(this)} ref={this.fileRef}
                   type="file" accept={accept} style={{display: 'none'}}/>

            {open && files[0] && <Modal canOutsideClickClose={false} size={modal_size} title={title} open={true}
                                        body={<div className="space-y-4">

                                            {!uploading && <div className="flex justify-center">
                                                <AvatarWrapper type={type} divider={divider} className="border bg-gray-200 border-gray-300 shadow-sm p-px overflow-hidden -mx-px" style={{borderRadius:border_radius,paddingBottom:'',height:`${container_height/divider}px`,width:`${is_square&&divider!==1?`${container_height/divider}px`:`${this.display_dimensions.width}px`}`}} is_cover={type==='cover-photo'}>
                                                <AvatarEditor
                                                    scale={parseFloat(this.state.scale)}
                                                    position={this.state.position}
                                                    backgroundColor={"#ffffff"}
                                                    onLoadSuccess={this.handleLoadSuccess.bind(this)}
                                                    onImageReady={()=>{

                                                    }}
                                                    onMouseMove={this.handleMouseMove.bind(this)}
                                                    onMouseUp={()=> {
                                                        this.setState({dragging: false})
                                                    }}
                                                    onLoadFailure={()=>alert('The image could not be loaded, please try again')}
                                                    onPositionChange={this.handlePositionChange}
                                                    ref={this.setEditorRef}
                                                    disableBoundaryChecks={boundary_checks_disabled}
                                                    image={files[0]}
                                                    height={this.edit_canvas_dimensions.height/divider}
                                                    style={{
                                                        transform: `scale(${this.display_dimensions.scale})`,
                                                        borderRadius: this.display_dimensions.border_radius,
                                                        background: '#ffffff'
                                                    }}
                                                    width={this.edit_canvas_dimensions.width/divider}
                                                    crossOrigin='anonymous'
                                                    border={0}
                                                    color={[255, 255, 255, 0.6]} // RGBA
                                                    rotate={this.state.rotate}
                                                />
                                            </AvatarWrapper>
                                            </div>}

                                            <TempTip tip="profile-picture-public" />

                                            {!uploading && <div className=""><ActionsGroup actions={actions}/></div>}

                                            {uploading && <UnProgressBar real_progress={progress}/>}

                                            <div style={{display: 'none'}}>
                                                {canvases}
                                            </div>
                                        </div>} onClose={this.close.bind(this)} footerLeft={<ButtonsLeft>
                {!uploading&&<Button text="Change Photo" intent='standard' onClick={this.openFileSelector.bind(this)}/>}
            </ButtonsLeft>} footerRight={<div className="flex space-x-2">
                {!uploading&&<Button text="Cancel" intent='standard' onClick={this.close.bind(this)}/>}
                <Button text="Save" loading={uploading} intent='secondary' onClick={() => {
                    this.startUpload();
                }}/>
            </div>}
            />}
        </Wrapper>;
    }
}