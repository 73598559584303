import React, {useContext, useState} from "react";
import {GlobalContext} from "../../app/global/global-context";
import {Button} from "../../library/components/core";
import {authFetch} from "../../config/network";

function PolicyItem({version,name,id,gate,handleSetPolicies,link,link_german=''}) {
    const [loading,setLoading] = useState('');
    const [v,setV] = useState(version);
    const [l,setL] = useState(link);
    const [lg,setLg] = useState(link_german);

    function updatePolicy() {
        setLoading('saving');

        const payload = {
            id, link: l, version: v, link_german: lg
        };

        const res = (resp) => {
            if(resp.data.policies_data) {
                handleSetPolicies(resp.data.policies_data);
            }
            setLoading('done');
            setTimeout(function () {
                setLoading('')
            }, 200);
        };

        authFetch("/superadmin/update-policy", res, res, "POST", {payload});
    }

    return <div className="py-4 px-2 border-b gap-4 border-gray-200 grid grid-cols-8">
        <div >{name}</div>
        <div>{gate}</div>
        <div>
            <input className="p-2 rounded border border-gray-200 w-full" value={v} onChange={(e)=>setV(e.target.value)} />
        </div>
        <div className="col-span-2">
            <input className="p-2 rounded border border-gray-200 w-full" value={l} onChange={(e)=>setL(e.target.value)} />
        </div>
        <div className="col-span-2">
            <input className="p-2 rounded border border-gray-200 w-full" value={lg} onChange={(e)=>setLg(e.target.value)} />
        </div>
        <div>
            {((l!==link)||(v!==version))&&loading!=='done'&&<Button onClick={()=>updatePolicy()} loading={loading==='saving'} text="Update Policy" size="sm" intent="secondary" />}
        </div>
    </div>
}

export function GPPolicies() {
    const global = useContext(GlobalContext);

    if(!global.policies_data) {
        return <div>
            Loading..
        </div>
    }

    const entries = Object.entries(global.policies_data);

    return <div>
        <div className="py-2 px-2 border-b font-semibold text-gray-700 gap-4 border-gray-200 grid grid-cols-8">
            <div>Name</div>
            <div>Gate</div>
            <div>Version</div>
            <div className="col-span-2">English</div>
            <div className="col-span-2">German</div>
            <div>

            </div>
        </div>
        {entries.map(([id,ent],k)=><PolicyItem handleSetPolicies={(np)=>global.setPoliciesData(np)} id={id} key={id} {...ent} />)}
        <div className="pt-4 text-sm text-gray-700">
            Links should look like: https://firebasestorage.googleapis.com/v0/b/unaty-prod.appspot.com/o/policies%2Fcurrent%2Fcustomer-terms-of-service.md?alt=media
        </div>
    </div>
}