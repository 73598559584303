import React, {useContext} from "react";
import {Button, EntityHeader, PageHeader} from "../../../../../library/components/core";
import {PageContainer} from "../../../../../library/components/old/page-container";
import RichList from "../../../../../library/components/old/rich-list";
import {CommunityContext} from "../../../community-context";
import {StandardPageHeight} from "../../../../../library/playground/standard-page-framework";
import {PlusIcon} from "@heroicons/react/solid";
import {useHistory} from "react-router-dom";

export function SettingsApplications(props) {
const community = useContext(CommunityContext);
const history = useHistory();
    const base_link = `/${community.data.handle}/admin/settings`;

    const options = {
        type: 'applications',
        need_to_load_data: true,
        allow_selection: false,
        scroll_style: 'table', // infinite
        per_page: 15,
        query: {
            parent_collection: 'community_members',
            parent: 'community_members',
            parent_doc_id: community.uid,
            parent_id: community.uid,
            collection: 'applications',
            where: [
                {
                 field: 'status',
                 operator: '==',
                 value: 'pending'
                }
            ]
        },

        link_base: `${base_link}/application`,
        link_id_field: 'docid',

        default_sort: {
            field: 'created_at',
            dir: 'desc'
        },

        renderer: {
            columns: [
                {
                    text: 'Name',
                    type: 'title',
                    field: 'name',
                    width: '1.2fr',
                    show: true
                },
                {
                    text: 'Email',
                    type: 'string',
                    field: 'account_email',
                    width: '1fr',
                    show: true
                },
                {
                    text: 'Phone Number',
                    type: 'string',
                    field: 'phone_number',
                    width: '1fr',
                    show: true
                },
                {
                    text: 'Applied',
                    type: 'date',
                    field: 'created_at',
                    width: '1.5fr',
                    show: true
                },
            ],
            row: {
                actions: []
            }
        },

        sort_options: [
            {
                name: 'Created At',
                field: 'created_at',
                type: 'date',
                modifiers: ['desc', 'asc']
            }
        ],

        filter_options: [],

        header: {
            show_properties: false,
            show_filter: false,
            show_sort: false,
            show_search: false,

            mass_actions: []
        }
    };

    return <StandardPageHeight content full>
        <EntityHeader title="New Member Applications" goBackTo={()=>history.push(`/${community.data.handle}/admin/settings`)} />

        <RichList {...props} options={options}/>
    </StandardPageHeight>
}