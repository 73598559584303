import React, {useState} from "react";
import {BriefcaseIcon, CogIcon, UserCircleIcon, UsersIcon} from "@heroicons/react/solid";
import {RichSelect} from "../core";

const member_profile_designer_preview = [
    {
        label: 'My Profile',
        value: 'my-profile',
        icon: <UserCircleIcon />
    },
    {
        label: 'Other Profile',
        value: 'other-profile',
        icon: <UsersIcon />
    },
    {
        label: 'Admin view',
        value: 'admin-view',
        icon: <BriefcaseIcon />
    }
];

const mock_options = member_profile_designer_preview.map(it=>{
    return it
});

export function PreviewTypeSelector({initial=mock_options[0].value,onSelect=()=>{},options=mock_options}) {
    const [selected,setSelected] = useState(initial);
    const selected_option = options.filter(function (a) {
        return a.value === selected;
    })[0];
    const trigger = <div className={`flex pl-2 w-40 py-1.5 space-x-1.5 bg-white inline-flex pr-6 rounded-md shadow-sm border border-gray-300 hover:bg-gray-100 cursor-pointer hover:border-gray-400 transition-colors`}>
        <div className="w-5 h-5 flex items-center justify-center">
            <div className="w-5 h-5 text-gray-600">
                {selected_option.icon}
            </div>
        </div>
        <div className="text-sm text-gray-700 font-semibold truncate">{selected_option.label}</div>
    </div>;
    function handleSelect(v) {
        setSelected(v);
        onSelect(v);
    }
    return <RichSelect custom_trigger={trigger} onSelect={handleSelect} options={options} selected={selected}/>;
}