import React from 'react';
import {PageHeader,Tabs} from "../../core";

export default function SGTabsPage() {

    return <div>

        <div className="space-y-4">
            <div>
                <PageHeader title="Latest"/>
                <Tabs/>
            </div>
            <div>
                <Tabs layout="pills"/>
            </div>
            <div>
                <Tabs layout="pills-color"/>
            </div>
            <div>
                <Tabs intent="secondary" layout="pills-connected"/>
            </div>

            <div>
                <Tabs intent="secondary" layout="rich-bottom"/>
            </div>
        </div>
    </div>
}