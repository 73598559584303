import styled from "styled-components";
import {EntityInfo, MemberTypeCounter} from "../../../utilities/localstorage/entity-localstorage";
import React, {useContext, useEffect, useState} from "react";
import {CommunityContext} from "../../../../app/community/community-context";
import {getMembersOfType} from "./api";
import {Button, InlineLoader} from "../../core";
import {ProfilePicture} from "../../old/profile-picture";
import {GeneralItemIcon} from "../../universal-search/components";
import {ChevronDownIcon, ChevronLeftIcon, XIcon} from "@heroicons/react/solid";
import {DEFAULT_UNASSIGNED_ROLE, DEFAULT_USER_PHOTO} from "../../../../config/defaults";
import {getEntityTypeIcon} from "../../entity-list";
import {LiveSegmentToggle} from "../../suggestions/live-segment";
import {getSegmentSemantics} from "../../suggestions/semantic-segments";
import {NewTooltip} from "../new-tooltip";
import {FirebaseDB} from "../../../../config/setup-firestore";
import {Draggable} from "react-beautiful-dnd";
import {IconGrabHandle} from "../../../icons/custom/GrabHandle";

const Row = styled.div`
  display: grid;
  grid-template-columns: ${props => props.size === 'md' ? "2.5rem 1fr auto" : "28px 1fr auto"};
`;

const IconCol = styled.div`
  display: flex;
  justify-content: center;
`;

const Title = styled.div`
  font-size: 15px;
  font-weight: 500;
`;

const Action = styled.div`
  outline: none;

  > svg {
    height: 16px;
  }
`;

function ESMemberWrapper({data, index, actions, size = 'md', id, small, cid, onRemove, onSelect, is_selected, community}) {
    const community_ctx = useContext(CommunityContext);
    const img = data.image ? `${data.image}_small?alt=media` : !community ? `${DEFAULT_USER_PHOTO}_small?alt=media` : `${community.default_member_profile_picture}_small?alt=media`;
    if (small) {
        return <ESSmallItem index={index} data={data} image={img} id={id} cid={cid}/>
    }
    return <ESGeneralEntityItem size={size} actions={actions} index={index}
                                sub_label={size === 'md' ? (data.member_type ? `${community_ctx.member_types[data.member_type].singular}` : '—') : ''}
                                is_selected={is_selected} onSelect={onSelect} data={data} id={id} cid={cid}
                                label={data.name} icon={{type: 'image', url: img}}
                                onRemove={onRemove} type='members'/>
}

function getTitle(data, type, id, cid) {
    return <Title
        className='text-gray-900'>{data.name}{data.count ? ` (${data.count})` : null}{type === 'member_types' &&
    <MemberTypeCounter id={id} community_uid={cid} type='member_types'/>}</Title>
}

const Loading = styled.div`

`;

const List = styled.div`
  max-height: 140px;
  overflow-y: auto;
`;

export function ESMembersList({id, data, padded, renderItem, cid, type}) {
    const [members, setMembers] = useState(null);
    const community = useContext(CommunityContext);
    useEffect(function () {
        if (!cid) {
            return;
        }
        if (type === 'member_types') {
            getMembersOfType(id, cid)
                .then((ids) => {
                    setMembers(ids);
                });
        } else if (type === 'roles') {
            if (data.assignee !== '') {
                setMembers([data.assignee]);
            } else if (id) {
                FirebaseDB
                    .collection('community_entities')
                    .doc(cid)
                    .collection('roles')
                    .doc(id)
                    .get()
                    .then(d => {
                        if (d.data().assignee) {
                            setMembers([d.data().assignee]);
                        }
                    })
            } else {
                setMembers([]);
            }
        } else {
            if (data.user_uids) {
                setMembers(Object.keys(data.user_uids));
            } else if (id) {
                FirebaseDB
                    .collection('community_entities')
                    .doc(cid)
                    .collection('groups')
                    .doc(id)
                    .get()
                    .then(d => {
                        setMembers(Object.keys(d.data().user_uids));
                    })
            } else {
                setMembers([]);
            }
        }
    }, [cid, id]);

    if (!members) {
        return <Loading>
            <InlineLoader mini padding='p-2'/>
        </Loading>
    }

    if (renderItem) {
        return members.map(m => <EntityInfo key={`members-${m}`} community_uid={cid} id={m} type='members'>
            {renderItem}
        </EntityInfo>)
    }

    return <List className={`space-y-1  ${padded ? "py-2" : ""}`}>
        {members.map(m => <EntityInfo key={`${type}-${m}`} community_uid={cid} id={m} type='members'>
            <ESMemberWrapper community={community} small id={m} cid={cid}/>
        </EntityInfo>)}
    </List>
}

function ItemSubItems({type, id, data, cid}) {
    return <ESMembersList data={data} type={type} id={id} cid={cid}/>;
}

function ESSmallItem({data, image, id, cid}) {
    return <Row className={`gap-2 pl-2.5 pr-2.5 py-0.5`} small>
        <IconCol>
            <ProfilePicture image={image} hide_initials size='custom' width='22px' height='22px'
                            text={data.name}
                            color={'dodgerblue'}/>
        </IconCol>
        <div className="flex pl-1.5 items-center">
            {getTitle(data, 'members', id, cid)}
        </div>
        <div>
        </div>

    </Row>
}

export function ESGeneralEntityItem({
                                        loading,
                                        data,
                                        size,
                                        id,
                                        icon,
                                        meta,
                                        type,
                                        can_expand,
                                        onClick,
    index,
                                        shadow = false,
                                        simple = false,
                                        add,
                                        actions,
                                        onRemove,
                                        suffix,
                                        label,
                                        sub_label,
                                        cid
                                    }) {

    const [expand, setExpand] = useState(false);
    return <div
        className={`border bg-white rounded-2xl ${shadow ? "shadow-md" : ""} ${expand ? "border-gray-400" : "border-white"}`}>
        <Row onClick={(e) => {
            if (e) {
                e.preventDefault();
            }
            if (onClick) {
                e.stopPropagation();
                onClick();
            }
        }} simple={simple} expanded={expand} size={size}
             className={`${expand ? "" : "hover:bg-gray-100"} rounded-2xl gap-2.5 px-1 py-1 transition-colors`}>
            <IconCol className="flex-none items-center">
                <GeneralItemIcon {...icon} size={size} text={label}/>
            </IconCol>
            <div className="flex justify-center flex-grow flex-col">
                <div className="space-x-1.5 flex items-center">
                    <span className="font-semibold pt-px text-gray-800 text-smbase truncate"
                          style={{maxWidth: '80%'}}>{label}</span>
                    {suffix && <span className="text-gray-700 text-sm pl-1 truncate">{suffix}</span>}
                </div>
                {sub_label && <div className={`text-sm ${expand?"line-clamp-3":"line-clamp-1"} pt-px text-gray-500`}>
                    {sub_label}
                </div>}
            </div>
            <div className="flex items-center flex-none space-x-1.5 pr-1.5">
                {meta && <span className="text-sm text-gray-600">{meta}</span>}
                {can_expand && <div>
                    <NewTooltip usePortal={false} message={expand ? `Hide Members` : `See Members`}>
                        <Action onClick={() => setExpand(!expand)}
                                className='h-6 w-6  flex items-center justify-center rounded-md hover:bg-gray-200 cursor-pointer transition-colors'>
                            {expand ? <ChevronDownIcon className='text-gray-600'/> :
                                <ChevronLeftIcon className='text-gray-500'/>}
                        </Action>
                    </NewTooltip>
                </div>}
                {actions&&actions.length>0&&actions.map(act=>{
                    if(!act.tooltip||!act.icon||!act.show(type,id,data,index)) {
                        return null;
                    }
                    return <div key={`act-${act.key}`}>
                        <NewTooltip usePortal={false} message={act.tooltip}>
                            <Action onClick={() => act.onClick(type, id, data, index)}
                                    className='h-6 w-6 flex bg-white items-center text-gray-500 justify-center rounded-md hover:bg-gray-200 cursor-pointer transition-colors'>
                                {act.icon}
                            </Action>
                        </NewTooltip>
                    </div>
                })}
                {onRemove && <div>
                    <NewTooltip usePortal={false} message="Remove">
                        <Action onClick={() => onRemove(type, id)}
                                className='h-6 w-6 flex items-center bg-white justify-center rounded-md hover:bg-gray-200 cursor-pointer transition-colors'>
                            <XIcon className='text-gray-500'/>
                        </Action>
                    </NewTooltip>
                </div>}

                {add && <div className="px-1"><Button loading={loading} onClick={() => {
                    if (loading) {
                        return;
                    }
                    add(type, id)
                }} size="sm" minimal text="Add" intent="secondary"/></div>}
            </div>
        </Row>
        {expand && <div className={`${expand ? "" : ""}`}>
            <ItemSubItems type={type} id={id} cid={cid} data={data}/>
        </div>}
    </div>;
}


function renderTeam(id, cid, {onRemove, size, is_selected, onSelect}, community, actions, index) {
    const s = size ? size : 'md';
    return <EntityInfo community_uid={cid} id={id} type='teams'>
        <ESGeneralEntityItem index={index} size={s} is_selected={is_selected} id={id} cid={cid} onSelect={onSelect}
                             onRemove={onRemove} type='teams'/>
    </EntityInfo>
}

function renderGroup(id, cid, {onRemove, size, is_selected, onSelect}, community, actions, index) {
    const s = size ? size : 'md';
    return <EntityInfo community_uid={cid} id={id} type='groups'>
        <ESGeneralEntityItem index={index} size={s} is_selected={is_selected} id={id} cid={cid} onSelect={onSelect}
                             onRemove={onRemove} type='groups'/>
    </EntityInfo>
}

function MemberTypeWrapper({data, size = 'md', onRemove, onSelect, index, is_selected, id, cid}) {
    const icon = buildIcon('member-types',data);
    return <ESGeneralEntityItem sub_label="Member Type" size={size} type='member_types' data={data} can_expand id={id}
                                cid={cid} label={data.name} is_selected={is_selected}
                                icon={icon} onSelect={onSelect} index={index}
                                onRemove={onRemove}/>
}

function renderMemberType(id, cid, {onRemove, size, is_selected = false, onSelect}, community, actions, index) {
    const s = size ? size : 'md';
    return <EntityInfo community_uid={cid} id={id} type='member_types'>
        <MemberTypeWrapper index={index} size={s} id={id} cid={cid} is_selected={is_selected} onSelect={onSelect}
                           onRemove={onRemove}/>
    </EntityInfo>
}

function buildIcon(type,data) {
    if(type==='segment') {
        return {
            type: 'icon-count',
            count: data.count||"—",
              icon: getEntityTypeIcon('segments')
        }
    }

    if(type==='member-types') {
        return {
            type: 'icon-count',
            count: data.count||"—",
            icon: getEntityTypeIcon('member-types')
        }
    }
    // role

    return {
        type: 'icon-image',
        image: data.image ? `${data.image}_small?alt=media` : `${DEFAULT_UNASSIGNED_ROLE}_small?alt=media`,
        icon: getEntityTypeIcon('roles')
    }
}

function RoleWrapper({data, index, actions, onRemove, size = 'md', onSelect, is_selected, id, cid}) {
    const icon = buildIcon('role',data);

    return <ESGeneralEntityItem actions={actions} index={index} size={size} can_expand={false} type='roles'
                                is_selected={is_selected} onSelect={onSelect}
                                sub_label={data.assignee ? `${data.assignee_name}` : `Unassigned`} data={data} id={id}
                                cid={cid} label={data.name}
                                icon={icon} onRemove={onRemove}/>
}

function renderRole(id, cid, {onRemove, size, is_selected, onSelect}, community, actions, index) {
    const s = size ? size : 'md';
    return <EntityInfo community_uid={cid} id={id} type='roles'>
        <RoleWrapper index={index} actions={actions} size={s} is_selected={is_selected} id={id} cid={cid} onSelect={onSelect}
                     onRemove={onRemove}/>
    </EntityInfo>
}

function renderMember(id, cid, {onRemove, can_remove, is_selected, onSelect, size}, community, actions, index) {
    const s = size ? size : 'md';
    return <EntityInfo community_uid={cid} id={id} type='members'>
        <ESMemberWrapper index={index} actions={actions} size={s} is_selected={is_selected} id={id} cid={cid} onSelect={onSelect}
                         onRemove={onRemove} community={community}/>
    </EntityInfo>
}

function SegmentWrapper({
                            data,
                            size = 'md',
                            id,
                            cid,
                            live = true,
                            onToggleSegmentLive = null,
                            onRemove,
                            onSelect,
                            is_selected,
                            community
                        }) {
    const icon = buildIcon('segment',data);
    return <ESGeneralEntityItem data={data} size={size}
                                suffix={onToggleSegmentLive ?
                                    <LiveSegmentToggle live={live} onToggle={() => onToggleSegmentLive(id)}/> : null}
                                id={id} cid={cid}
                                label={data.name} sub_label={getSegmentSemantics(data, community)} can_expand
                                icon={icon}
                                onRemove={onRemove} onSelect={onSelect} is_selected={is_selected} type='filters'/>
}

function renderFilter(id, cid, {
    onRemove,
    size,
    is_selected,
    onSelect
}, community, not_live, onToggleSegmentLive, actions) {
    const s = size ? size : 'md';
    return <EntityInfo community_uid={cid} id={id} type='filters'>
        <SegmentWrapper size={s} id={id} cid={cid} community={community}
                        onToggleSegmentLive={onToggleSegmentLive}
                        live={!not_live} onSelect={onSelect} is_selected={is_selected} onRemove={onRemove}/>
    </EntityInfo>;
}

function getProps(id, p2, item_id) {
    return {
        onRemove: !p2.can_remove || p2.block_removal[id] ? null : p2.onRemove,
        onSelect: !p2.can_select ? null : p2.onSelect,
        is_selected: p2.selected_items.includes(item_id),
        item_id: item_id
    }
}

const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: 'none',
    ...draggableStyle,

    // override z-index
    zIndex: 9999,
});

export function es_singleEntityRenderer(type, id, cid, props, community, ex = {
    non_live_ids: {},
    onToggleSegmentLive: null
}, actions, index) {
    let item_id;
    switch (type) {
        case 'members':
            item_id = `members-${id}`;
            return <div key={id}>{renderMember(id, cid, props, community, actions, index)}</div>;
        case 'roles':
            item_id = `roles-${id}`;
            return <div key={id}>{renderRole(id, cid, props, community, actions, index)}</div>;
        case 'filters':
            item_id = `filters-${id}`;
            return <div
                key={id}>{renderFilter(id, cid, props, community, ex.non_live_ids && ex.non_live_ids[id] ? ex.non_live_ids[id] : false, ex.onToggleSegmentLive, actions, index)}</div>;
        case 'member_types':
            item_id = `member_types-${id}`;
            return <div key={id}>{renderMemberType(id, cid, props, community, actions, index)}</div>;
        case 'groups':
            item_id = `groups-${id}`;
            return <div key={id}>{renderGroup(id, cid, props, community, actions, index)}</div>;
        case 'teams':
            item_id = `teams-${id}`;
            return <div key={id}>{renderTeam(id, cid, props, community, actions, index)}</div>;
        default:
            return null;
    }
}

function orderIds(all,recommended) {
    let ordered = [];

    if(recommended && recommended.length) {
        recommended.forEach(rid=>{
            if(all.includes(rid)) {
                ordered.push(rid);
            }
        })
    }

    all.forEach(aid=>{
        if(!ordered.includes(aid)) {
            ordered.push(aid);
        }
    })

    return ordered;
}
export function es_renderEntityType(type, ids_obj, cid, {
    can_remove,
    community,
    onRemove,
    can_select = false,
    onSelect = null,
    selected = [],
    block_removal,
    can_reorder,
    non_live_ids = {},
    onToggleSegmentLive = () => {
    }
}, actions, ordered, renderDraggable) {
    const ids = orderIds(Object.keys(ids_obj),ordered);

    /*
    useEffect(function () {
        if(ordered&&ordered.length) {
            const new_order = orderIds(Object.keys(ids_obj),ordered);
            if(JSON.stringify(new_order)!==JSON.stringify(ids)) {
                setIds(new_order);
            }
        }
    }, [ids_obj,ids,ordered])

     */

    const fns = {
        onRemove,
        onSelect,
        selected_items: selected,
        can_select,
        can_remove,
        block_removal
    };

    let item_id;

    // <IconGrabHandle/>
    /*

     */
    return ids.map((id,index) => {
        if(can_reorder) {
            return <Draggable isDragDisabled={false} key={id} draggableId={id}
                              index={index} style={{zIndex:4000}}>
                {renderDraggable((provided,snapshot) => <div ref={provided.innerRef}
                                                    key={id} {...provided.draggableProps} style={getItemStyle(
                    snapshot.isDragging,
                    provided.draggableProps.style,
                )}
                >
                    {es_singleEntityRenderer(type, id, cid, getProps(id, fns, item_id), community, {
                        non_live_ids,
                        onToggleSegmentLive
                    },[{
                        key: 'drag-handle',
                        tooltip: "Drag to Reorder",
                        icon: <div
                            className='w-5 text-gray-500 hover:text-gray-800 h-5' {...provided.dragHandleProps}>
                            <IconGrabHandle/>
                        </div>,
                        show: () => true,
                        onClick: () => {}
                    },...actions],index)}

                </div>)}
            </Draggable>
        }
        return es_singleEntityRenderer(type, id, cid, getProps(id, fns, item_id), community, {
            non_live_ids,
            onToggleSegmentLive
        },actions,index);
    })
}