import React from 'react';
import styled from "styled-components";

import {useHistory} from 'react-router-dom';
import {ArrowLeftIcon} from "@heroicons/react/solid";
import {ActionWithIcon} from "./action-with-icon";



export function EntityHeader({title,onTitleClick,loading,sticky,single,onClick,onClickSubtitle,goBackTo,title_suffix=null,breadcrumbs=[],subtitle,actions}) {

    const can_click_header = `${onClick?"cursor-pointer":""}`;
    const can_click_title = `${onTitleClick?"cursor-pointer":""}`;
  return <div onClick={(e)=>{
  e.stopPropagation();
      if(onClick) {
          onClick();
      }
  }} className={`${can_click_header} bg-white border-b border-gray-200 relative md:relative w-full z-99 px-4 z-20 pt-2.5 pb-2 flex h-12 items-center`}>
      {goBackTo&&<div className="flex-none -ml-1.5">
          <ActionWithIcon dims={"w-8 h-8 flex justify-center"} bg onClick={(e) => {
              if(e) {
                  e.stopPropagation();
              }

              goBackTo()
          }} icon={<ArrowLeftIcon/>} size="lg"/>
      </div>}
      <div className="flex-grow flex justify-center flex-col">
          <div className="flex items-center space-x-1 h-6">
              {title&&<div onClick={()=>{
                  if(onTitleClick) {
                      onTitleClick();
                  }
              }} className={`${subtitle?"text-base":single?"text-base":"text-xl"} ${can_click_title} text-gray-800 font-bold h-6 leading-6`}>{title}</div>}
              {title_suffix&&<div className="h-6 flex items-center">{title_suffix}</div>}
          </div>
          {subtitle&&<div onClick={(e)=>{
              e.stopPropagation();
              if(onClickSubtitle) {
                  onClickSubtitle();
              }
          }} className={`text-gray-600 text-xs ${onClickSubtitle?"hover:underline cursor-pointer":""}`}>{subtitle}</div>}
      </div>
      <div>
          {actions}
      </div>
  </div>;
}
