import React, {useContext} from "react";
import {AuthContainer, AuthTitle, SplashContainer, SplashInner} from "../../landing/layout/splash-container";
import {useIntl} from 'react-intl';
import {setDocumentTitle} from "../../../library/utilities/set-document-title";
import {GlobalContext} from "../../global/global-context";
import {SignInWrapper} from "./SignInWrapper";

export default function SignIn(props) {
    const global = useContext(GlobalContext);
    const {formatMessage: f} = useIntl();
    function handleSubmit(email, password, cb=()=>{}) {
        global.login(email,password,cb)
    }

    setDocumentTitle('Sign in');

    return (
        <SplashContainer>
            <SplashInner>
                <AuthContainer>
                    <AuthTitle>
                        Sign in to Unaty
                    </AuthTitle>
                    <SignInWrapper handleSubmit={handleSubmit.bind(this)} global={global} {...props} />
                </AuthContainer>
            </SplashInner>
        </SplashContainer>
    );
}