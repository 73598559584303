import React from "react";
import {CheckIcon} from "@heroicons/react/solid";
import styled from "styled-components";
import {useHistory} from "react-router-dom";


const SIBox = styled.div`
> svg {
    height: 18px;
}
`;

// TIPS
/*
violet = community settings
blue =
red =
teal =
amber =
 */
function getColorCombo(color) {
    switch (color) {
        case 'red' : {
            return {
                bg: 'bg-red-200',
                circle: 'bg-red-500'
            }
        }
        case 'blue' : {
            return {
                bg: 'bg-blue-200',
                circle: 'bg-blue-500'
            }
        }
        case 'gray' : {
            return {
                bg: 'bg-coolGray-200',
                circle: 'bg-coolGray-500'
            }
        }
        case 'teal' : {
            return {
                bg: 'bg-teal-200',
                circle: 'bg-teal-500'
            }
        }
        case 'amber' : {
            return {
                bg: 'bg-amber-200',
                circle: 'bg-amber-500'
            }
        }
        case 'emerald' : {
            return {
                bg: 'bg-emerald-200',
                circle: 'bg-emerald-500'
            }
        }
        case 'violet' : {
            return {
                bg: 'bg-violet-200',
                circle: 'bg-violet-500'
            }
        }
        case 'indigo' : {
            return {
                bg: 'bg-indigo-200',
                circle: 'bg-indigo-500'
            }
        }
        case 'pink' : {
            return {
                bg: 'bg-pink-200',
                circle: 'bg-pink-500'
            }
        }
        default : {
            return {
                bg: 'bg-teal-200',
                circle: 'bg-teal-500'
            }
        }
    }
}

export function TipItem({context,hello,onTipClick=()=>{},onClick=()=>{},title="Title",color='teal',subtitle="Subtitle",icon=<CheckIcon />}) {
    const history = useHistory();
    const {bg,circle} = getColorCombo(color);

    function handleClick() {
        if(context) {
            const {c,m} = context;
            onClick(history,c,m);
            onTipClick();
        } else {
            alert('cannot proceed');
        }
    }

    return <div className="flex-none" onClick={()=>handleClick()}>
        <div className={`flex py-1.5 border border-gray-200 pl-1.5 pr-6 cursor-pointer hover:bg-gray-100 hover:border-gray-300 transition-colors rounded-md`}>
            <div className={`${bg} px-2 flex justify-center py-3 items-center rounded-md`} style={{minHeight:'56px'}}>
                <SIBox className={`${circle} flex items-center justify-center rounded-full h-8 w-8 text-white`}>
                    {icon}
                </SIBox>
            </div>
            <div className="pt-0.5 pl-2.5" style={{maxWidth: '16rem'}}>
                <div className="text-sm truncate font-bold text-gray-700">{title}</div>
                <div className=" text-xs h-8 overflow-x-hidden pt-px flex flex-wrap text-gray-500">{subtitle}</div>
            </div>
        </div>
    </div>;
}