import React, {useContext, useState} from 'react';
import {CommunityContext} from "../../../community-context";
import {at_array_move} from "../../../../../library/components/advanced-table/utilities";
import {useDraggableInPortal} from "../../../../../library/components/advanced-table/sort/sort";
import {DragDropContext, Droppable} from "react-beautiful-dnd";
import {List} from "../../../../../library/components/core";
import {BriefcaseIcon} from "@heroicons/react/solid";
import {authFetch} from "../../../../../config/network";
import {GlobalContext} from "../../../../global/global-context";

function getTeamIdsArrayByRank(teams_obj) {
    const entries = Object.entries(teams_obj);
    const temp = entries.map(([id,obj])=>{
        return { id, rank: obj.rank ? obj.rank : 0 };
    });
    return [].concat(temp).sort((a, b) => (a.rank < b.rank) ? 1 : -1);
}

export function TeamsRanker() {
    const global = useContext(GlobalContext);
    const community = useContext(CommunityContext);
    const [teams_ids, setTeamsIds] = useState(getTeamIdsArrayByRank(community.all_teams));
    const [teams_data, setTeamsData] = useState(community.all_teams);

    function handleOnDragEnd(result) {
        if (!result.destination) return;

        const {destination, source} = result;
        // need to sort pins and save on done

        let new_teams = at_array_move(teams_ids, source.index, destination.index);

        const f = [...new_teams];

        setTeamsIds(f);
        handleSave(f);
    }

    function handleSave(tids) {
        let final = [];

        const len = tids.length;

        for(let i=0;i<len;i++) {
            const it = tids[i];

            const rank = (len - i) + 1;

            final.push({
                id: it.id,
                rank
            });
        }

        const payload = {
            community_uid: community.uid,
            teams: final, member_id: community.member_id
        };

        const res = () => {
            global.addToast({text: 'Changes saved', intent: 'success'});
        };

        const err = () => {

        };

        authFetch("/communities/rank-teams", res, err, "POST", {payload});
    }

    const renderDraggable = useDraggableInPortal();

    const context = {
      community_uid: community.uid
    };

    const items = teams_ids.map((it, k) => {
        const dt = teams_data[it.id];
        if(!dt) {
            return {blank:true};
        }
        const ln = Object.keys(dt.entities.roles).length;
        return {
            icon: <BriefcaseIcon/>,
            value: `team-${it.id}`,
            title: dt.name,
            subtitle: `${ln} role${ln===1?"":"s"}`,
            actions: []
        }
    });
    return <div>
        <DragDropContext onDragEnd={handleOnDragEnd}>
            <Droppable droppableId="teams-ranking">
                {(provided) => (
                    <div {...provided.droppableProps} ref={provided.innerRef}>
                        <List context={context} old_loader renderDraggable={renderDraggable} minimal items={items}
                              can_reorder={true}/>
                        {provided.placeholder}
                    </div>
                )}

            </Droppable>
        </DragDropContext>
    </div>
}