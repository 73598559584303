import React, {useState} from "react";
import {PlusCircleIcon, UserCircleIcon} from "@heroicons/react/solid";
import {Popover2} from "@blueprintjs/popover2";
import {LocalSearchBar} from "../../custom/local-search-bar";

const mock_options = [
    {
        label: 'Address',
        id: 'address',
        icon: <UserCircleIcon />,
        items: [
            {
                label: 'ZIP Code',
                id: 'zip_code'
            },
            {
                label: 'City',
                id: 'city'
            },
            {
                label: 'Country',
                id: 'country'
            }
        ]
    }
];

function Option({label,data,id,onSelect}) {

    return <div onClick={()=>onSelect(id,data)} className="py-1 text-base text-gray-600 hover:text-gray-800 -mx-3 px-3 hover:bg-gray-200 cursor-pointer transition-colors">
        <div className={"py-px font-medium"}>
        {label}
        </div>
    </div>
}

function OptionsSection({label,icon,id,onSelect,items}) {

    return <div className="py-2">
        <div className="h-6 flex space-x-2 items-center">
            <div className="h-6 flex items-center text-gray-700">
                <div className="w-4 h-4">
                    {icon}
                </div>
            </div>
            <div className="h-6 flex items-center text-sm font-semibold text-gray-700">
                {label}
            </div>
        </div>
        <div>
            {items.map(it=><Option onSelect={onSelect} key={it.id} {...it} data={it} />)}
        </div>
    </div>
}

function filterResults(q,arr) {
    if(!q) {
        return arr;
    }
    let final = [];
    for(let i=0;i<arr.length;i++) {
        const sec = arr[i];

        let section_results = []
        for(let k=0;k<sec.items.length;k++) {
            const item = sec.items[k];

            if(item.label.toLowerCase().indexOf(q) !== -1) {
                section_results.push(item);
            }
        }

        if(section_results.length>0) {
            final.push({
               label: sec.label,
               icon: sec.icon,
               id: sec.id,
               items: section_results
            });
        }
    }
    return final;
}

export function domHasOpenPortal() {
    const it = document.querySelector(".bp4-overlay.bp4-overlay-open");
    return !!it;
}

export function RulesAddItem({text='Condition',onSelect=()=>{},options=mock_options}) {
    const [open,setOpen] = useState(false);
    const [query,setQuery] = useState('');

    const trigger = <div className="p-4 border-2 transition-colors rounded-xl border-dashed border-gray-300 hover:bg-gray-100 hover:border-gray-300 hover:border-solid cursor-pointer">
        <div className="flex space-x-3 text-secondary-400">
            <div className='flex-none w-6 flex items-center justify-center'>
                <div className="w-5 h-5">
                    <PlusCircleIcon />
                </div>
            </div>
            <div className="text-base font-medium flex-grow">
                {text}
            </div>
        </div>
    </div>;

    const results = filterResults(query,options);

    const content = <div className="w-96 rounded-md overflow-y-auto">
        <div className="p-3 border-b border-gray-200">
            <LocalSearchBar onChange={(v)=>setQuery(v.toLowerCase())} autoFocus />
        </div>
        <div className="px-3" style={{maxHeight: '240px'}}>
            {query&&results.length===0&&<div className="p-2 text-center text-gray-500 text-sm">
                No results
            </div>}
            {results.map(sec=><OptionsSection onSelect={(a,b)=>{
                onSelect(a,b);
                setOpen(false);
                setQuery('');
            }} key={sec.id} {...sec} />)}
        </div>
    </div>;

    return <div>
        <Popover2 portalClassName="z-9999" usePortal={true} interactionKind="click" content={content} enforceFocus={false} placement={'bottom-start'} onOpen={()=>setOpen(true)} isOpen={open} onClose={()=>setOpen(false)} minimal popoverClassName="popup-box-shadow popover-standard my-2">
        <div onClick={(e)=>{e.stopPropagation();setOpen(true)}}>
            {trigger}
        </div>
    </Popover2>
    </div>
}