import {validateEmail} from "../../../utilities/validation";
import moment from "moment";
import {buildAddress, formatWebsiteForProfile} from "../../../utilities/shared";

export function convertToObj(arr,flag) {
    let obj = {};
    for(let i=0;i<arr.length;i++) {
        const item = arr[i];
        if(flag&&item.hidden) {
            continue;
        }
        obj[item.value] = item.text;
    }
    return obj;
}

export function buildMultiSelectString(keys,obj) {
    return keys.filter(function (k) {
        return obj[k];
    }).map(k=>{
        return obj[k];
    }).join(", ");
}

function getCheckboxLabel(on,options) {
    if(on) {
        return options.checked_label?options.checked_label:"Yes";
    } else {
        return options.unchecked_label?options.unchecked_label:"No";
    }
}

function getCFValue(member,field,{type,options},ex) {
    // member[`custom_fields`][`${ids[i]}`]
    const raw = member[`custom_fields`][`${field}`];
    if(raw===null||raw===undefined) {
        return '';
    }

    let data_item = member[`custom_fields`][`${field}`];
    let options_obj = {};
    let keys = [];

    switch (type) {
        case 'text' : {
            return data_item;
        }
        case 'checkbox' : {
            return getCheckboxLabel(data_item,options);
        }
        case 'country' : {
            return !data_item ? '' : data_item;
        }
        case 'textarea' : {
            return data_item;
        }
        case 'email': {
            return data_item;
        }
        case 'number' : {
            return data_item;
        }
        case 'address' : {

            return buildAddress(data_item);
        }
        case 'url' : {
            return formatWebsiteForProfile(data_item);
        }
        case 'multi-select-taxonomy' : {
            if(!data_item) {
                return '';
            }
            keys = data_item;
            if(keys.length===0) {
                return '';
            } else {
                return keys.map((val,i)=>`${val.label}${(i+1)!==keys.length?', ':''}`);
            }
        }
        case 'select-taxonomy' : {
            if(!data_item) {
                return '';
            }
            return data_item.label;
        }
        case 'multi-select' : {
            options_obj = convertToObj(options.choices, true);
            keys = Object.keys(data_item);
            if(keys.length===0) {
                return '';
            } else {
                return buildMultiSelectString(keys,options_obj);
            }
        }
        case 'select' : {
            options_obj = convertToObj(options.choices, true);
            if(data_item==='') {
                return '';
            } else {
                return options_obj[data_item]?options_obj[data_item]:'';
            }
        }
        case 'member' : {
            if(data_item==='') {
                return '';
            } else {
                return data_item;
            }
        }
        case 'group' : {
            if(data_item==='') {
                return '';
            } else {
                return data_item;
            }
        }
        case 'mentor' : {
            if(data_item==='') {
                return '';
            } else {
                return data_item;
            }
        }
        case 'mentees' : {
            if(!data_item||Object.keys(data_item).length===0) {
                return '';
            } else {
                keys = Object.keys(data_item);
                return keys;
            }
        }
        case 'community' : {
            return 'community'
        }
        case 'multi-communities' : {
            return 'multi-communities'
        }
        case 'date' : {
            if(data_item==='') {
                return '';
            } else {
                return moment(data_item).format('MM/DD/YYYY');
            }
        }
        default : {
            return '';
        }
    }
}

function showField(type, val) {
    return val !== '';
}

function transformFields(member,ids,data,ex) {
    let arr = [];
    for(let i=0;i<ids.length;i++) {
        const field = data[ids[i]];
        let val = getCFValue(member,ids[i],field,ex);

        arr.push({
            value: val,
            field: ids[i],
            type: field.type,
            label: field.name,
            show: showField(field.type,val)
        })
    }
    return arr;
}

function addDataQualityCheck(data,name,field,type) {

    if(!data.warnings) {
        return {
            status: 'unknown',
            field,
            name,
            type,
            at: null,
            by: [] // uids
        }
    }

    const dq_field_warnings = data.warnings.filter(id => {
        const warning = data.warnings_data[id];
        return warning.field === field
    });

    if(dq_field_warnings.length===0) {
        return {
            status: 'unknown',
            field,
            name,
            type,
            at: null,
            by: [] // uids
        }
    }

    const by = dq_field_warnings.map((id)=> {

        return data.warnings_data[id].by
    });

    return {
        status: 'incorrect',
        field,
        name,
        type,
        at: data.warnings_data[dq_field_warnings[dq_field_warnings.length-1]].ts,
        by: by
    }
}

function getBase(data, type, flags) {
    if(type==='member') {

        return [
            {
                title: 'About',
                visibility: 'all-members',
                fields: [
                    {
                        type: 'text',
                        show: true,
                        field: 'about.first_name',
                        value: data.about.first_name,
                        label: 'First Name'
                    },
                    {
                        type: 'text',
                        show: true,
                        field: 'about.last_name',
                        value: data.about.last_name,
                        label: 'Last Name'
                    },
                    {
                        type: 'text',
                        field: 'about.title',
                        show: data.about.title?data.about.title.length>0:false,
                        value: data.about.title,
                        label: 'Title'
                    },
                    {
                        type: 'text',
                        field: 'about.middle_name',
                        show: data.about.middle_name?data.about.middle_name.length>0:false,
                        value: data.about.middle_name,
                        label: 'Middle Name'
                    },
                    {
                        type: 'email',
                        label: 'Email',
                        field: 'contact.email',
                        show: validateEmail(data.contact.email),
                        value: data.contact.email,
                        dq: addDataQualityCheck(data,'Email','contact.email','email')
                    },
                    {
                        type: 'phone',
                        field: 'contact.phone',
                        label: 'Phone',
                        show: data.contact.phone.length>2,
                        value: data.contact.phone,
                        dq: addDataQualityCheck(data,'Phone','contact.phone','phone')
                    },
                    {
                        type: 'phone',
                        label: 'Secondary Phone',
                        field: 'contact.secondary_phone',
                        show: data.contact.secondary_phone?data.contact.secondary_phone.length>2:false,
                        value: data.contact.secondary_phone,
                        dq: addDataQualityCheck(data,'Secondary Phone','contact.secondary_phone','phone')
                    }
                ]
            }
        ]
    } else {
        return [

        ]
    }
}

export function getMemberProfileFlags(ex) {
    let address_visibility = 'full';
    let birthday_visibility = 'full';
    let social_visibility = 'full';

    if(ex&&ex.community&&ex.community.preferences) {
        if(ex.community.preferences.members.address_visibility) {
            address_visibility = ex.community.preferences.members.address_visibility;
        }
    }

    if(ex&&ex.community&&ex.community.preferences) {
        if(ex.community.preferences.members.birthday_visibility) {
            birthday_visibility = ex.community.preferences.members.birthday_visibility;
        }
    }

    return {
        address_visibility,
        birthday_visibility,
        social_visibility
    }
}

function getAddressLabel(val,raw) {
    if(raw.line_one||raw.zip_code||raw.state) {
        return "Address"
    } else if(raw.country&&raw.city) {
        return "City & Country"
    } else if(raw.city) {
        return "City"
    } else if(raw.country) {
        return "Country"
    }

    return "Address";
}

export function buildPersonProfile(data, type ='member', profile_sections, is_private = false, ex={}) {

    const social_fields = ex.social_fields ? ex.social_fields : [];
    const profile_flags = getMemberProfileFlags(ex);

    let base = getBase(data,type,profile_flags);

    if(profile_flags.address_visibility === 'full') {
        let address_val = buildAddress(data.address);
       base[0].fields.push({
           type: 'address',
           label: getAddressLabel(address_val,data.address),
           field: 'address',
           show: address_val!=='',
           value: address_val,
           dq: addDataQualityCheck(data,'Address','address','text')
       });
    } else if(profile_flags.address_visibility === 'simple') {
        base[0].fields.push({
            type: 'city',
            label: 'City',
            field: 'address.city',
            show: data.address.city!=='',
            value: data.address.city
        });
        base[0].fields.push({
            type: 'country',
            label: 'Country',
            field: 'address.country',
            show: data.address.country!=='',
            value: data.address.country
        });
    }

    let ps_keys = Object.entries(profile_sections).sort((a, b) => a[1].order - b[1].order).map(a=>a[0]);


    for(let i=0;i<ps_keys.length;i++) {
        const sec = profile_sections[ps_keys[i]];

        const fields_transformed = transformFields(data, sec.fields, sec.field_data, ex);

        base.push({
            title: sec.name,
            visibility: sec.visibility ? sec.visibility : 'all-members',
            fields: fields_transformed
        })
    }
    return base;
}