import firebase from "firebase/app";
import "firebase/auth";
import 'firebase/analytics';
import "firebase/firestore";
import "firebase/storage";
import "firebase/remote-config";
import config from './firebase-config';
import {APP_ENV} from "./defaults";

firebase.initializeApp(config);
// initialize analytics
firebase.analytics();

const remoteConfig = firebase.remoteConfig();

remoteConfig.settings.minimumFetchIntervalMillis = APP_ENV === 'prod' ? 3600000 : 3600;

remoteConfig.defaultConfig = {
    "privacy_policy_version": null,
    "cookie_policy_version": null,
    "user_terms_policy_version": null,
    "owner_community_policy_version": null
};