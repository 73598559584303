import React from 'react';

export const IconGrabHandle = () => {
    return <svg fill="currentColor" x="0" y="0" viewBox="0, 0, 24, 24">
        <g id="Layer_15">
            <path d="M8.5,14 C7.395,14 6.5,13.105 6.5,12 C6.5,10.895 7.395,10 8.5,10 C9.605,10 10.5,10.895 10.5,12 C10.5,13.105 9.605,14 8.5,14 z" />
            <path d="M15.5,14 C14.395,14 13.5,13.105 13.5,12 C13.5,10.895 14.395,10 15.5,10 C16.605,10 17.5,10.895 17.5,12 C17.5,13.105 16.605,14 15.5,14 z" />
            <path d="M8.5,20 C7.395,20 6.5,19.105 6.5,18 C6.5,16.895 7.395,16 8.5,16 C9.605,16 10.5,16.895 10.5,18 C10.5,19.105 9.605,20 8.5,20 z" />
            <path d="M15.5,20 C14.395,20 13.5,19.105 13.5,18 C13.5,16.895 14.395,16 15.5,16 C16.605,16 17.5,16.895 17.5,18 C17.5,19.105 16.605,20 15.5,20 z" />
            <path d="M8.5,8 C7.395,8 6.5,7.105 6.5,6 C6.5,4.895 7.395,4 8.5,4 C9.605,4 10.5,4.895 10.5,6 C10.5,7.105 9.605,8 8.5,8 z" />
            <path d="M15.5,8 C14.395,8 13.5,7.105 13.5,6 C13.5,4.895 14.395,4 15.5,4 C16.605,4 17.5,4.895 17.5,6 C17.5,7.105 16.605,8 15.5,8 z" />
        </g>
    </svg>
};