import React from "react";
import {ActionWithIcon, Button} from "../../../../../library/components/core";
import {ExternalLinkIcon} from "@heroicons/react/solid";
import {HTSearchTips} from "../search-tips";

export function HelpTipsHome({goToPanel}) {

    return <div className="flex flex-col">
        <div className="flex-grow">
            <HTSearchTips />
        </div>
        <div className="flex flex-none items-center pb-2 px-4 pt-3 border-t border-solid border-gray-200">
            <div className="flex-grow">
                <ActionWithIcon icon_right icon={<ExternalLinkIcon />} text="Help center" inverse onClick={()=>window.open("https://docs.google.com/document/d/12DyjbqDRL2u1-BIsjV6kqK7U1Z3_Sbtm0Qvc5jDRz4Q", "_blank")} />
            </div>
            <div>
                <Button text="Contact us" onClick={()=>window.open("mailto:hey@getunaty.com", "_blank")} />
            </div>
        </div>
    </div>
}