import React, {Component} from 'react';
import styled from "styled-components";
import {DesignAchievement} from "../achievements-designer";
import {RCETweet} from "../rich-content-embeds/tweet";
import {createPortal} from "react-dom";
import {LocationTest} from "../location";
import {ProfileSuggestions} from "../profile-suggestions";
import {PlaygroundQueryTesting} from "../query-testing";
import {AppFrameLoading} from "../app-frame-loading";
import {ConvertToIcon} from "../convert-to-icon";
import {TempTip} from "../temp-tip";
import {LinkedInTests} from "./linkedin";
import {PollView} from "../../components/poll/view";
import {AuthorPoll} from "../../components/poll/author";
import {voting_rankedChoiceResolver} from "../../components/poll/utilities";
import {TestUploader} from "./test-uploader";
import {GoogleCalendarAuth} from "../google-calendar-auth";

const Wrapper = styled.div`
  width: 70vw;
`;

class IFrame extends Component {
    constructor(props) {
        super(props)
        this.state = {
            mountNode: null
        }
        this.setContentRef = (contentRef) => {
            this.setState({
                mountNode: contentRef?.contentWindow?.document?.body
            })
        }
    }

    render() {
        const {children, ...props} = this.props
        const {mountNode} = this.state
        return (
            <iframe
                {...props}
                id="tweet-embed-1234"
                ref={this.setContentRef}
            >
                {mountNode && createPortal(children, mountNode)}
            </iframe>
        )
    }
}


export function PLAYScratch() {

    return <Wrapper className={`max-w-4xl p-4 space-y-8 overflow-y-auto max-h-70vh`}>
        {voting_rankedChoiceResolver(null,true)}
<TestUploader />

        <GoogleCalendarAuth />
    </Wrapper>;
}