import React from 'react';
import {ReplyIcon} from "@heroicons/react/solid";
import {NFIGrowGrid, NFIInner, NFIOuter} from "./shared";

const data = {
    'pinned': {
        label: "Pinned",
        icon: null
    }
};

export function NFITopbar({type}) {
    if (!type) {
        return null;
    }
    return <NFIOuter className="py-0.5">
        <NFIInner>
            <NFIGrowGrid>
                <div className="flex items-center justify-end">
                    <div className="h-svg-3 text-gray-600">
                        <ReplyIcon/>
                    </div>
                </div>
                <div>
                <span className="text-xs text-gray-600 font-medium">
                Pinned
                    </span>
                </div>
            </NFIGrowGrid>
        </NFIInner>
    </NFIOuter>
}