import React, {useState} from "react";
import styled from "styled-components";

const Textarea = styled.textarea`
  box-shadow: 0 1px 2px 0 #0000000d;
  border-color: #d4d4d8;
  border-width: 1px;
  border-style: solid;

  border-radius: 0.375rem;
  
    transition: border 80ms ease-out,box-shadow 80ms ease-out;
    box-sizing: border-box;
        color: #112D42;
        padding: 10px 12px 10px 12px;
    resize: none;
    font-size: 15px;
    line-height: 1.33333333;
    width: 100%;
    :focus {
        outline: none !important;
    }

  :hover {
    border-color: #b3b3b3;
  }
  :focus-within {
    box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(58, 151, 212, 0.36) 0px 0px 0px 4px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(60, 66, 87, 0.16) 0px 0px 0px 1px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px;
    border-color: #659adf;
  }
`;

const Container = styled.div`
    position: relative;
    > span {
    font-size: 15px;
        color: #112D42;
        font-weight: 500;
    }
`;

export default function TextareaField(props) {

    const {type='text', autoComplete='', onBlur, onChange, autoFocus = false} = props;
    return (
        <Container className={`inputtype-${type}`}>
            {type==='handle'&&<span>@</span>}
            <Textarea rows={3}  type={type} autoFocus={autoFocus} onBlur={onBlur} autoComplete={autoComplete} value={props.value} onChange={(e)=>{onChange(e.target.value)}} />
        </Container>
    );
}