import React from "react";
import styled from "styled-components";
import {useHistory} from 'react-router-dom';
import {EntityInfo} from "../../../utilities/localstorage/entity-localstorage";
import ModeratorIcon from "../../old/moderator-icon";
import {Popover2} from '@blueprintjs/popover2';

const Entity = {
    image: '',
    name: 'Accenture',
    type: '',
    handle: 'accenture'
};

const types = {
    networks: '',
    moderators: '',
    organizers: '',
    educations: '',
    parent: '',
    career: ''
};

const props = {
    type: 'networks', // networks (community), moderators (group), organizers (event), education (user/member)
    title: 'Networks', // parent community
    items: [
        Entity
    ]
};

const Wrapper = styled.div`
   
`;

const Item = styled.div`
   display: inline-flex;
   border-radius: 6px;
   max-width: 220px;
   cursor: pointer;
       padding: 4px 10px 4px 4px;
`;

const Image = styled.div`
   width: 24px;
    height: 24px;
    min-width: 24px;
    border-radius: 6px;
    background-color: #6d6d6d;
    background-image: url('${props => props.bkg}_small?alt=media');
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: 600;
    color: #fff;
    background-position: center;
    background-size: cover;
`;

const ModBox = styled.div`
   width: 24px;
    height: 24px;
    min-width: 24px;
    
    display: flex;
    align-items: center;
    justify-content: center;
`;

const Text = styled.div`
       font-size: 15px;
    font-weight: 600;
    padding-left: 8px;
    display: flex;
    align-items: center;
`;

const examples = [
    {
        type: 'education', // networks (community), moderators (group), organizers (event), education (user/member)
        title: 'Community', // parent community
        items: [
            {
                image: 'https://firebasestorage.googleapis.com/v0/b/unaty-prod.appspot.com/o/profile_pictures%2F8hvqXXAzF_small?alt=media',
                name: 'Corps Isaria',
                type: 'community',
                handle: 'isaria'
            }
        ]
    },
    {
        type: 'education', // networks (community), moderators (group), organizers (event), education (user/member)
        title: 'Networks', // parent community
        items: [
            {
                image: '',
                name: 'Blauer Kreis',
                type: 'community',
                handle: 'accenture'
            },
            {
                image: '',
                name: 'Muenchner Senioren Convent',
                type: 'community',
                handle: 'msc'
            }
        ]
    },
    {
        type: 'moderators', // networks (community), moderators (group), organizers (event), education (user/member)
        title: 'Moderators', // parent community
        items: [
            {
                image: '',
                name: 'Sean McCall',
                type: 'member',
                handle: 'mccall'
            },
            {
                image: '',
                name: 'Fabian Ahrens',
                type: 'member',
                handle: 'ahrens'
            }
        ]
    },
    {
        type: 'education', // networks (community), moderators (group), organizers (event), education (user/member)
        title: 'Education - long name', // parent community
        items: [
            {
                image: '',
                name: 'Ludwig-Maximillian-Universitaet Muenchen',
                type: 'community',
                handle: 'lmu'
            }
        ]
    },
];

function ImageItem({data}) {
    return <Image bkg={data.image}/>
}

function getImage(item, len, type, cid) {
    return <Image>{len}</Image>;
    if (len === 1) {
        return <EntityInfo type={type} community_uid={cid} id={item}>
            <ImageItem/>
        </EntityInfo>
    } else {
        return <Image>{len}</Image>
    }
}

function getText(item, len, title) {
    return <Text className='text-gray-900'>{title}{len!==1?'s':''}</Text>
}

const PopoverContents = styled.div`
  box-shadow: 0 0 0 1px rgba(0,0,0,.05), 0 1px 3px 0 rgba(0,0,0,.1), 0 1px 2px 0 rgba(0,0,0,.06) !important;
      border-radius: 6px;
          display: grid;
    grid-template-columns: 1fr;
    grid-gap: 2px;
        padding: 4px 5px 4px 4px;
            min-width: 200px;
    max-width: 300px;
`;

function RenderItem({data,history,community_handle}) {
    return <Item onClick={()=>history.push(`/${community_handle}/member/${data.handle}`)} className='hover-opacity hover-bkg-2'>
        <ImageItem data={data}/>
        <Text className='text-gray-900'>{data.name}</Text>
    </Item>
}

export function EntityPreview({sec, community_uid, onClick=()=>{}, community_handle, type}) {
    const history = useHistory();
    const len = sec.items.length;
    if(sec.type==='moderators') {
        return <Item onClick={()=>onClick()} className='hover-bkg-2'>
                <ModBox>
                <ModeratorIcon fill="#ff6b00"/>
                </ModBox>
                {getText(sec.items[0], 1, "Moderators")}
            </Item>

    }
    return <>
        {len === 1 && <Item className='hover-opacity hover-bkg-2'>
            {getImage(sec.items[0], 1, type, community_uid)}
            {getText(sec.items[0], 1, sec.title)}
        </Item>}
        {len > 1 && <Popover2 content={ <PopoverContents>
            {sec.items.map((item, k) => {
                return <EntityInfo key={k} community_uid={community_uid} type={type} id={item}>
                    <RenderItem/>
                </EntityInfo>
            })}
        </PopoverContents>} position={Position.BOTTOM} minimal popoverClassName='minimal-popover top-margin-4px'>
            <Item className='hover-bkg-2'>
                {getImage(sec.items[0], len, type, community_uid)}
                {getText(sec.items[0], len, sec.title)}
            </Item>

        </Popover2>}
    </>
}