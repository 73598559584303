// topbar
import {FirebaseDB} from "../../config/setup-firestore";

function getTS() {
    let d = new Date();
    d.setMonth(d.getMonth() - 3);

    return d.getTime();
}

export async function tp_pu_getProductUpdates() {
    return await FirebaseDB
        .collection('product_updates')
        .where('created_at', '>', getTS())
        .orderBy('created_at', 'desc')
        .limit(6)
        .get()
        .then(snap=>{
            return snap.empty ? [] : snap.docs.map(doc=>doc.data());
        })
}