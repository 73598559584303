import {languages, languages_object} from "../../../../data/general/languages";
import React from "react";
import {BasicSelect} from "../../../core/basic-select";

export const LanguageSelect = ({onChange, hide_blank=false, value}) => {
    let default_arr = [];
    if(!hide_blank) {
        default_arr.push({
            label: '--',
           value: ''
        });
    }
    const final = default_arr.concat(languages.map(lang=>{
        return {
            label: lang.text,
            value: lang.value
        }
    }));

    return <BasicSelect placeholder='Select Language' value={value?{value: value, label: languages_object[value]}:null} onChange={(val) => {
        if(val) {
            onChange(val.value);
        } else {
            onChange('');
        }
    }} options={final}/>

};