import React from "react";
import {SingleBody} from "../../../../../library/components/old/single-body";
import styled from "styled-components";
import {ProfileFrame} from "../../../../../library/components/old/profile-frame";
import {PageContainer} from "../../../../../library/components/old/page-container";
import {InlineLoader} from "../../../../../library/components/core";

const ProfileRightRail = styled.div`
position: relative;
`;

export default function ProfileLoading({handle='handle'}) {
    const title = 'Loading..';
    const nav = {
        can_go_back: false,
        history: {},
        breadcrumbs: [
            {
                label: "Loading",
                path: `#`
            }
        ]
    };

    return  <PageContainer nav={nav}>

        <SingleBody layout="member">
            <div style={{paddingTop: '32px',textAlign:'center'}}>
                <InlineLoader />
            </div>
            <ProfileRightRail >
                <ProfileFrame editing={false} data={{subtitle:`@${handle}`,title}}/>
                              
            </ProfileRightRail>
        </SingleBody>
    </PageContainer>;
}