import React, { Component } from "react";
import ReactDOM from "react-dom";

function getClassName(intent) {
    switch (intent) {
        case ('success') : {
            return "bg-success text-white";
        }
        case ('danger') : {
            return "bg-danger text-white";
        }
        case ('primary') : {
            return "bg-primary text-white";
        }
        case ('secondary') : {
            return "bg-secondary text-white";
        }
        default : {
            return "bg-gray-700 text-white";
        }
    }
}

class ToastItem extends Component {
    constructor(props) {
        super(props);

    }
    componentDidMount() {
        setTimeout(() => {
            this.props.onDismiss();
        },3000);
    }

    render() {
        const {text,intent='',onDismiss} = this.props;
        return (
            <div className={`mt-4 px-3.5 py-2.5 rounded-md shadow-md w-80 z-9999 flex ${getClassName(intent)}`}>
                <div className="flex-grow text-sm font-medium truncate">
                {text}
                </div>
                <div>

                </div>
            </div>
        );
    }

    shouldComponentUpdate() {
        return false;
    }
}

const mock_toasts = [
    {
        text: 'Hello',
        intent: 'info'
    },
    {
        text: 'Primary',
        intent: 'secondary'
    },
    {
        text: 'Success',
        intent: 'success'
    },
    {
        text: 'Danger',
        intent: 'danger'
    },
    {
        text: 'Warning',
        intent: 'warning'
    }
];

export function Toasts({toasts,actions}) {
    const len = Object.keys(toasts).length;

    if(len===0) {
        return null;
    }

    const content = <div className={`toasts fixed-important z-99999`}>
        <div>
        {toasts.map(toast => {
            const { id } = toast;
            return (
                <ToastItem {...toast} key={id} onDismiss={() => actions.removeToast(id)} />
            );
        })}
        </div>
    </div>

    return ReactDOM.createPortal(
        content,
        document.body
    );
}