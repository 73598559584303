import React, {useState} from 'react';
import {NFITopbar} from "./topbar";
import {NFIHeader} from "./header";
import {NFIBody} from "./body";
import {NFIActions} from "./actions";
import {nfi_blockRenderer} from "./block-renderer";
import {NFISubject} from "./subject";
import {NFIAttachments} from "./attachments";
import {FeedBodyPreview} from "../posts-feed/feed-item";
import {ActivityPostItem} from "../posts-feed/activity-item";

//    {blocks.map(block=>nfi_blockRenderer(block))}
export function NewFeedItem({blocks,read,metadata,activity_meta,activity_type,id,actions,history,block_count=5,block_preview_count=2,context,content_state,type,created_at,attachments,header,topbar,subject,debug}) {
    const [show_more,setShowMore] = useState(false);
    const p2 = {
        activity_type,read,history,metadata,activity_meta,community_uid:activity_meta?activity_meta.community.uid:""
    };
    const can_show_more = !show_more&&type!=='activity' && block_count>block_preview_count;
    return <div className={`py-3 hover:bg-gray-100 bg-white cursor-pointer transition-background`} onClick={()=>{
        if(type!=='activity') {
            actions.goToPath(`/${context.community.data.handle}/post/${id}`)
        }
    }}>
        <NFITopbar context={context} {...topbar} />
        {type!=='activity'&&<NFIHeader actions={actions} context={context} created_at={created_at} {...header} />}
        <NFIBody can_show_more={can_show_more} show_more={show_more} setShowMore={()=>setShowMore(true)}>
            {type==='activity'&&<ActivityPostItem history={history} {...p2} />}
            {subject&&<NFISubject subject={subject} />}
            {content_state&&<FeedBodyPreview block_count={block_count} block_preview_count={show_more?99:block_preview_count} show_more={show_more} setShowMore={setShowMore} content_state={content_state} has_subject={!!subject} read={false} />}
        </NFIBody>
        {attachments&&attachments.length>0&&<NFIAttachments actions={actions} attachments={attachments} />}
        <NFIActions />
        {debug&&<div className="p-4">
            <pre className="text-xs p-2 bg-gray-100 border-gray-300 rounded-md overflow-y-scroll text-left"
                 style={{maxHeight: '400px'}}>{JSON.stringify({blocks,subject,type,attachments,header,topbar}, undefined, 2)}</pre>
        </div>}
    </div>
}