import React from 'react';
import {logError} from "../../../api/errors/log-error";
import {ErrorPage} from "../../../app/error";

export class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        // You can also log the error to an error reporting service
        const logged = JSON.stringify({err:error, info:errorInfo},undefined,2);
        const ctx = this.props.ctx ? this.props.ctx : null;
        logError(logged,ctx);
    }

    render() {
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return <ErrorPage />;
        }

        return this.props.children;
    }
}