import React, {Component} from "react";
import styled from "styled-components";
import {FirebaseDB} from "../../../../../config/setup-firestore";
import {formatFileSize} from "../../../../../library/components/old/file-uploader/helpers";
import {COMMUNITY_PLANS} from "../../../../../config/defaults";

const Frame = styled.div`
        margin-top: 16px;
    font-size: 14px;
    max-width: 400px;
`;


export default class CommunityUsage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            data: {},
            loading: false
        };
    }

    componentDidMount() {
        FirebaseDB
            .collection('community_usage')
            .doc(this.props.community_uid)
            .get()
            .then((doc)=>{
                this.setState({
                    loading: false,
                    data: doc.data()
                })
            })
    }

    render() {
        const {data,loading} = this.state;
        if(loading) {
            return null;
        }
        if(data.storage===0||!data) {
            return null;
        }
        return <Frame className='text-gray-800'>
            Using {(data.storage/(COMMUNITY_PLANS.STANDARD.storage*1073741824)).toFixed(2)}% of available storage ({formatFileSize(data.storage)} of 25GB)
        </Frame>
    }
}