import React, {useState} from 'react';
import {Toggle} from "../../core";

export default function SGTogglesPage() {
const [on, setOn] = useState(false);

    return <div>

        <div>
            <div>Checkmark with label</div>
            <div>
                <Toggle checked={on} onToggle={(v)=>setOn(v)} label={<>
                    By selecting this, you agree to the&nbsp;
                    <a href="#" className="font-medium text-gray-600 underline">Privacy Policy</a>
                    &nbsp;and&nbsp;
                    <a href="#" className="font-medium text-gray-600 underline">Cookie Policy</a>.
                </>} />
            </div>
        </div>

        <div>
            <div>Switch</div>
            <div>
                <Toggle checked={on} onToggle={(v)=>setOn(v)} layout="switch" />
            </div>
        </div>
    </div>
}