import React, {useState} from "react";
import {LightBulbIcon, LocationMarkerIcon, QuestionMarkCircleIcon} from "@heroicons/react/solid";
import {SimpleListbox} from "../core";
import {timezones_obj} from "./data";
import {RulesInlineSelect} from "../rules/rules-inline-select";
import CustomCheckbox from "../old/checkbox";
import {languages} from "../../data/general/languages";
import Select from "../old/select";
function getTimezoneOffset(name) {
    // Generating the formatted text
    const zone = new Date().toLocaleTimeString('en-us',{timeZoneName:'short',timeZone:name}).split(' ')[2]

// Scraping the numbers we want from the text
   //  let timezoneAbbr = dateText.split(" ")[2];

// Getting the offset
    //let timezoneOffset = parseInt(timezoneString.split(':')[0])*60;

    return {
       // timezoneAbbr,
      //  timezoneString,
      //  timezoneOffset,
        zone
    }
}
export function TimezoneSwitcher({
    onChange=()=>{},
                                     set_tz_automatically,
                                     can_set_automatically,
    value = ""
                                 }) {
    const options = React.useMemo(() => {
        let items = Object.entries(timezones_obj).map(([val,cities])=>{
            const {zone} = getTimezoneOffset(val);
            return {
                label: `(${zone}) - ${cities}`,
                value: val
            }
        });
        return items;
    }, []);

    return <div>
        {can_set_automatically&&<div className="pb-1">
            <CustomCheckbox toggleCheck={()=>{
                const ftz = Intl.DateTimeFormat().resolvedOptions().timeZone;
                let final_pass = value;
                if(ftz&&ftz!==value) {
                    final_pass = ftz
                }
            onChange(final_pass,!set_tz_automatically)
            }} top_margin={false} checked={set_tz_automatically} content={"Set time zone automatically"} />
        </div>}
        <Select disabled={set_tz_automatically} value={value} field={''} blank_option={false} onChange={(f, v) => {
            onChange(v,set_tz_automatically)
        }} options={options}/>
    </div>;
}