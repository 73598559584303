import React from 'react';
import ModeratorIcon from "../components/old/moderator-icon";
import {ArrowCircleRightIcon, ArrowRightIcon} from "@heroicons/react/solid";
import RoleIcon from "../icons/custom/RoleIcon";

function getIcon(type) {
    if(type==='role') {
        return <div style={{height:'16px',width:'16px'}} className="h-svg-4 left-px absolute top-px">
            <RoleIcon fill="currentColor" />
        </div>
    }
    return <div style={{height:'16px',width:'16px'}} className="h-svg-4 left-px absolute top-px">
        <ModeratorIcon display="block" height={16} />
    </div>
}
// types = moderator, role
export function ConvertToIcon(props) {
    const {type='moderator',} = props;

    return <div className="relative rounded-md text-gray-500 w-5 h-5 bg-inherit">
        {getIcon(type)}
        <div className="absolute right-0 bottom-0 rounded-full bg-inherit">
            <div className="h-svg-25 rounded-full bg-inherit">
                <ArrowCircleRightIcon />
            </div>
        </div>
    </div>
}