import React from 'react';
import styled from "styled-components";
import {FPAbout} from "./about";
import {XIcon,CloudDownloadIcon,DotsHorizontalIcon,UploadIcon} from '@heroicons/react/solid';
import {PopupMenu} from "../../old/popup-menu";
import CommunityUploadFile from "../../../../app/community/files/upload-file";
import {NewTooltip} from "../new-tooltip";
import {Popover2} from "@blueprintjs/popover2";

const Header = styled.div`
    display: grid;
    grid-template-columns: 1fr auto;
`;

const Actions = styled.div`
padding: 8px 14px;
display: flex;
  height: 60px;
align-items: center;
`;

const ActionBox = styled.div`
height: 34px;
margin-left: 8px;
outline: none;
width: 34px;
align-items: center;
border-radius: 6px;
cursor: pointer;
    justify-content: center;
    display: flex;
    > svg {
        height: 22px;
    }
`;

const Divider = styled.div`
margin-left: 8px;
height: 34px;
width: 1px;
background: #efefef;
`;

export function FPHeader({closeModal,is_mobile,editName,handleFileChange,can_replace,data,context,options}) {
    return <Header className='border-bottom'>
        <div>
            <FPAbout editName={editName} data={data} context={context} />
        </div>
        <Actions>
            {can_replace&&!is_mobile&&<CommunityUploadFile current_name={data.name} handleFileChange={handleFileChange}>
                <NewTooltip message={`Upload new Version`}>
                <ActionBox className='text-gray-700  hover:bg-gray-200 transition-colors rounded-md'>
                    <UploadIcon/>
                </ActionBox>
                </NewTooltip>
            </CommunityUploadFile>}
            {!is_mobile&&<NewTooltip message={`Download File`}>
            <ActionBox onClick={() => {
                window.open(data.url, '_blank');
            }} className='text-gray-700  hover:bg-gray-200 transition-colors rounded-md'>
                <CloudDownloadIcon/>
            </ActionBox>
            </NewTooltip>}

            {!is_mobile&&<Popover2 content={<PopupMenu items={options}/>} usePortal={false} placement={'bottom-start'} minimal popoverClassName='minimal-popover'>
                <ActionBox className='text-gray-700  hover:bg-gray-200 transition-colors rounded-md'>
                    <DotsHorizontalIcon/>
                </ActionBox>
            </Popover2>}
            <Divider/>
            <NewTooltip message={`Close Viewer`}>
            <ActionBox onClick={()=>closeModal()} className='text-gray-700 hover:bg-gray-200 transition-colors rounded-md'>
                <XIcon/>
            </ActionBox>
            </NewTooltip>
        </Actions>
    </Header>;
}