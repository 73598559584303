import React from "react";
import {InfoSwitch} from "../info-switch";
import Field from "../../old/field";
import {TextAndEmoji} from "../../old/text-and-status";
import HandleSelector from "../../old/handle-selector";
import styled from "styled-components";
import {group_types} from "../group-type-selector";
import {disableField} from "./utilities";
import {NewEntitySelect} from "../entity-select";
import {BasicSelect} from "../../core/basic-select";
import {countries, countries_array} from "../../../data/general/countries";
import {cloneObject} from "./index";
import {TextAndEntityIcon} from "../../old/text-and-status/entity";

const CustomizeHandle = styled.div`
   font-size: 13px;
    margin-top: 6px;
    max-width: 90%;
   > b {
    font-weight: 600;
   }
`;

const Description = styled.div`
    font-size: 14px;
    margin-bottom: 20px;
`;

export function getNameMeta(handle, _customizing, onChange, type) {
    if (handle !== '' && !_customizing) {
        return <CustomizeHandle className='text-gray-700'>Handle will be <b>@{handle}</b>, <a
            className="color-link"
            onClick={() => onChange('_customizing', true)}>customize it</a></CustomizeHandle>
    }
    return null;
}

function getLabel(value,map) {
    return map[value];
}

function getValue(value,map) {
    if(!value||value==='') {
        return null;
    }
    return {
        value,
        label: getLabel(value,map)
    }
}

const Label = styled.div`
line-height: 20px;
    font-size: 14px;
        margin-bottom: 6px;
    font-weight: 500;
    color: #3c4257;
`;

// todo unify
export function blockRenderer(props, data, onChange, context, auto_focus = true) {
    const {type, meta} = props;
    let val, f1, f2, f3, f4, f5, mt;
    const is_disabled = disableField(props, cloneObject(data), {...context});
    switch (type) {
        // general
        case 'description':
            return <Description className='text-gray-700'>
                {props.content}
            </Description>;
        // standard inputs
        case 'info-switch':
            val = data[props.value];
            return <InfoSwitch disabled={is_disabled} texts={props.texts} on={val}
                               toggleOn={() => onChange(props.value, !val)}/>;
        case 'text':
            val = data[props.value];
            return <Field meta={props.meta} disabled={is_disabled} optional={props.optional} type={"text"}
                          label={props.label}
                          onChange={(v) => onChange(props.value, v)} placeholder={props.placeholder}
                          value={val}/>;
        case 'email':
            val = data[props.value];
            return <Field meta={props.meta} disabled={is_disabled} optional={props.optional} type={"email"}
                          label={props.label}
                          onChange={(v) => onChange(props.value, v)} placeholder={props.placeholder}
                          value={val}/>;
        case 'textarea':
            return <div></div>
        case 'date':
            return <div></div>

        // entity selects
        case 'member-type':
            return <div></div>
        case 'multi-member-type':
            return <div></div>
        case 'member':
            val = data[props.value];
            return <Field meta={props.meta} placeholder={props.placeholder} type="member" label={props.label}
                          onChange={(v) => onChange(props.value, v)} community_uid={context.community_uid}
                          value={{type: 'members', value: val}}/>;
        case 'assignee':
            val = data[props.value];
            return <Field action={{text:'Assign to me',onClick:()=>onChange(props.value, context.member_id)}} meta={props.meta} placeholder={props.placeholder} type="member" label={props.label}
                          onChange={(v) => onChange(props.value, v)} community_uid={context.community_uid}
                          value={{type: 'members', value: val}}/>;
        case 'multi-member':
            return <div></div>
        case 'role':
            return <Field meta={props.meta} placeholder={props.placeholder} type="role" label={props.label}
                          onChange={(v) => onChange(props.value, v)} community_uid={context.community_uid}
                          value={{type: 'roles', value: val}}/>;
        case 'multi-role':
            return <div></div>
        case 'team':
            return <Field meta={props.meta} placeholder={props.placeholder} type="team" label={props.label}
                          onChange={(v) => onChange(props.value, v)} community_uid={context.community_uid}
                          value={{type: 'teams', value: val}}/>;
        case 'multi-team':
            return <div></div>
        case 'group':
            return <Field meta={props.meta} placeholder={props.placeholder} type="group" label={props.label}
                          onChange={(v) => onChange(props.value, v)} community_uid={context.community_uid}
                          value={{type: 'groups', value: val}}/>;
        case 'multi-group':
            return <div></div>


        // selects
        case 'select':
            val = data[props.value];
            return <div>
                {props.label&&<Label>{props.label}</Label>}
                <BasicSelect autofocus={auto_focus} value={getValue(val,props.map)} searchable clearable={false} onChange={(v) => {
                onChange(props.value, v.value);
            }} options={props.options} />
            </div>;
        case 'multi-select':
            return <div></div>;

        case 'text-emoji' :
            f1 = data.name;
            f2 = data.emoji;
            mt = props.check_name ? (context[props.check_name].indexOf(f1.toLowerCase()) === -1 ? null : props.meta) : props.meta;
            return <TextAndEmoji meta={mt}
                                 onBlur={()=>{}} label={props.label} placeholder={props.placeholder}
                                 changeEmoji={(emoji) => {
                                     if(typeof emoji === 'string') {
                                         onChange('emoji', emoji)
                                     } else {
                                         onChange('emoji', emoji.native)
                                     }
                                 }} emoji={f2} text={f1} changeText={(v) => {
                onChange('name', v);
            }}/>;
        // special
        case 'group-type-select':
            val = data[props.value];
            const ops = {
                'standard': {
                    desc: 'For members that share a similar similar passion or topic.'
                },
                'team': {
                    desc: 'For members that work together on a regular basis.'
                }
            };
            const item = ops[val];
            return <Field is_admin={context.is_admin} meta={item.desc} type="group-type-select" label="Type"
                          onChange={(v, data) => {
                              if (v === 'team') {
                                  onChange({type: 'team', closed: true});
                              } else {
                                  onChange({type: v, closed: false});
                              }
                          }} value={val} disabled={is_disabled}/>;
        case 'name-emoji-handle':
            f1 = data.name;
            f2 = data.emoji;
            f3 = data.handle;

            return <>
                <div>
                    <TextAndEmoji onBlur={() => onChange('_helper_name', f1)} label={props.label}
                                  placeholder={props.placeholder}
                                  changeEmoji={(emoji) => {
                                      if(typeof emoji === 'string') {
                                          onChange('emoji', emoji)
                                      } else {
                                          onChange('emoji', emoji.native)
                                      }
                                  }} autoFocus
                                  emoji={f2} text={f1} changeText={(v) => onChange('name', v)}
                    />
                    {getNameMeta(data.handle, data._customizing, onChange, props.handle.type_name)}
                </div>
                <div style={{display: !data._customizing ? 'none' : 'block'}} className="pt-3">
                    <HandleSelector checkingHandle={() => onChange('_checking_handle', true)}
                                    default_text={data._helper_name}
                                    setHandle={(h) => {
                                        onChange({handle: h, _checking_handle: false})
                                    }} type={props.handle.type}
                                    scope={props.handle.scope} scope_id={context.community_uid}/>
                </div>
            </>;
        case 'name-icon-handle':
            f1 = data.name;
            f2 = data.emoji;
            f3 = data.handle;
            f4 = data.profile_picture;
            f5 = data.color;

            return <>
                <div>
                    <TextAndEntityIcon onBlur={() => onChange('_helper_name', f1)} label={props.label}
                                  placeholder={props.placeholder} allowed_types={props.meta.allowed_types?props.meta.allowed_types:['color','emoji','image']}
                                       changeProfilePicture={(url,color)=>{
                                           onChange({
                                               'profile_picture': url,
                                               'profile_picture_color': color,
                                               'emoji': "",
                                               'color': ""
                                           });
                                       }}
                                       changeColor={(color)=>{
                                           onChange({
                                               'profile_picture': "",
                                               'profile_picture_color': "",
                                               'emoji': "",
                                               'color': color
                                           });
                                       }} color={f5} profile_picture={f4}
                                  changeEmoji={(emoji) => {
                                      if(typeof emoji === 'string') {
                                          onChange({
                                              'profile_picture': "",
                                              'profile_picture_color': "",
                                              'emoji': emoji,
                                              'color': ""
                                          });
                                      } else {
                                          onChange({
                                              'profile_picture': "",
                                              'profile_picture_color': "",
                                              'emoji': emoji.native,
                                              'color': ""
                                          });
                                      }

                                  }} autoFocus
                                  emoji={f2} text={f1} changeText={(v) => onChange('name', v)}
                    />
                    {getNameMeta(data.handle, data._customizing, onChange, props.handle.type_name)}
                </div>
                <div style={{display: !data._customizing ? 'none' : 'block'}} className="pt-3">
                    <HandleSelector checkingHandle={() => onChange('_checking_handle', true)}
                                    default_text={data._helper_name}
                                    setHandle={(h) => {
                                        onChange({handle: h, _checking_handle: false})
                                    }} type={props.handle.type}
                                    scope={props.handle.scope} scope_id={context.community_uid}/>
                </div>
            </>;
        case 'name-emoji':
            f1 = data.name;
            f2 = data.emoji;
            return <>
                <div>
                    <TextAndEmoji onBlur={() => onChange('_helper_name', f1)} label={props.label}
                                  placeholder={props.placeholder}
                                  changeEmoji={(emoji) => {
                                      if(typeof emoji === 'string') {
                                          onChange('emoji', emoji)
                                      } else {
                                          onChange('emoji', emoji.native)
                                      }
                                  }} autoFocus
                                  emoji={f2} text={f1} changeText={(v) => onChange('name', v)}
                    />
                    {getNameMeta(data.handle, data._customizing, onChange, props.handle.type_name)}
                </div>
                <div style={{display: !data._customizing ? 'none' : 'block'}} className="pt-3">
                    <HandleSelector checkingHandle={() => onChange('_checking_handle', true)}
                                    default_text={data._helper_name}
                                    setHandle={(h) => {
                                        onChange({handle: h, _checking_handle: false})
                                    }} type={props.handle.type}
                                    scope={props.handle.scope} scope_id={context.community_uid}/>
                </div>
            </>;
        case 'entities-filters':
            f1 = data;
            return <NewEntitySelect {...props} placeholder={props.placeholder} searchable={props.allowed_entities} search_ctx={props.search_ctx} meta={meta} context={context} onChange={(f, v) => {
                onChange(f, v);
            }} value={{entities: f1}}/>;
        default:
            return null;
    }
}