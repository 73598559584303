import React, {useContext, useState} from "react";
import {CommunityContext} from "../../../../community-context";
import {PageContainer} from "../../../../../../library/components/old/page-container";
import {Button, PageHeader, SectionHeader} from "../../../../../../library/components/core";
import {AddEmoji} from "./add-emoji";
import {EmojiTable} from "./emoji-table";
import {PlusIcon} from "@heroicons/react/solid";


export function CustomizeEmojis(props) {
    const community = useContext(CommunityContext);
    const [open, setOpen] = useState('');
    const [tab, setTab] = useState('');

    return <div className="pt-2">

            <EmojiTable actions={[<Button size="xs" key='add-emoji' intent={"secondary"} onClick={() => setOpen('add-emoji')} text="Add Emoji"
                                          left_icon={<PlusIcon/>}/>]} />

        {open === 'add-emoji' && <AddEmoji open={open === 'add-emoji'} onClose={() => setOpen('')}/>}
    </div>
}