import React, {useState} from 'react';
import {Toasts} from "../utilities/toasts";
import createToast from "../utilities/create-toast";
import {ToastsContext} from "./toasts-context";

export function ToastsProvider({children}) {
    const [toasts, setToasts] = useState({});

    function removeToast(id) {
        let new_toasts = toasts;
        delete new_toasts[id];
        setToasts({...new_toasts});
    }

    function addToast(opt) {
        let nt = createToast(opt);
        setToasts({...toasts, [nt.id]: nt});
    }

    const value = React.useMemo(() => {
        return {
            addToast: addToast.bind(this)
        };
    }, []);

    return <ToastsContext.Provider
        value={value}>
        {children}
        <Toasts actions={{
            removeToast: removeToast.bind(this)
        }} toasts={Object.values(toasts)}/>
    </ToastsContext.Provider>
}