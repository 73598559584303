import React from 'react';
import {Button, Input} from "../../../core";

export function HWCPrompt({data,id,meta}) {

    return <div className="w-full h-full flex p-5 flex-col">
        <div className="flex-grow space-y-1">
        <div className="text-lg font-bold text-gray-800">What is your phone number?</div>
        <div>
            <Input value="" onChange={v=>{}} />
        </div>
        </div>
        <div className="flex justify-end space-x-2">
            <Button intent="" text="Skip" size="sm" />
            <Button disabled intent="success" text="Save" size="sm" />
        </div>
    </div>
}