import React from "react";

export const UnatySVG = ({fill='url(#linearGradient-36)'}) => {
    return <svg x="0" y="0" viewBox="0, 0, 32, 32">
        <defs xmlns="http://www.w3.org/2000/svg">
            <path
                d="M339.840847,290.200118 L313.051211,336.513934 C306.341003,348.11469 293.939959,355.26095 280.519543,355.260841 L226.556887,355.260841 L264.190375,290.200118 L339.840847,290.200118 Z M83.6547984,202.332907 L46.0284466,267.380841 L86.0177897,336.514063 C92.7280541,348.114786 105.128929,355.260974 118.549458,355.26087 L197.761116,355.260793 L235.394492,290.200287 L154.528148,290.200174 C142.12288,290.200399 130.659767,283.594619 124.457077,272.871351 L83.6547984,202.332907 Z M354.238451,90.2124034 L278.961788,90.2124034 L281.138579,93.9842196 L319.57443,160.431765 C322.214751,165.048789 323.808496,170.206219 324.153976,175.518411 L324.190134,177.759745 L324.15375,180.003497 C323.857834,185.387252 322.095236,190.377439 319.574656,195.090087 L278.956888,265.309392 L354.23862,265.309392 L393.753264,196.996429 C396.534559,191.927484 398.436273,186.452659 398.952577,180.68669 L399,177.75834 L398.956407,175.063792 C398.62895,169.368206 396.703356,163.999573 394.036113,159.014052 L354.238451,90.2124034 Z M31.8811043,112.598311 L5.03242428,159.014108 C-1.67749551,170.61491 -1.67751184,184.907336 5.03259775,196.507687 L31.6358387,242.499166 L69.4543781,177.554388 L31.8811043,112.598311 Z M198.262214,0.260925781 L118.549514,0.260925781 C105.129041,0.261150645 92.7279415,7.40744794 86.017677,19.0077432 L46.279029,87.7073036 L83.9003061,152.746805 L85.4599198,150.068391 L124.456739,82.6506133 C130.659711,71.9275695 142.122823,65.3217896 154.528317,65.3217896 L235.895983,65.3214523 L198.262214,0.260925781 Z M280.519093,0.260869565 L227.050043,0.260869565 L264.597133,65.3216209 L339.840678,65.3216209 L313.051099,19.0079681 C306.114251,7.38203828 296.139335,2.08940917 283.022326,0.34401311 L280.519093,0.260869565 Z"
                id="path-1"></path>
            <linearGradient x1="100.000014%" y1="49.9999842%" x2="1.57215157e-05%" y2="49.9999842%"
                            id="linearGradient-36">
                <stop stopColor="#F3934E" offset="0%"></stop>
                <stop stopColor="#EF6647" offset="100%"></stop>
            </linearGradient>
        </defs>
        <g>
            <path fill={fill}
                  d="M27.255,25.035 L25.107,28.756 C24.569,29.688 23.574,30.262 22.498,30.262 L18.17,30.262 L21.188,25.035 L27.255,25.035 z M6.709,17.974 L3.692,23.201 L6.899,28.756 C7.437,29.688 8.431,30.262 9.508,30.262 L15.861,30.262 L18.879,25.035 L12.393,25.035 C11.398,25.035 10.479,24.504 9.982,23.642 L6.709,17.974 z M28.41,8.965 L22.373,8.965 L22.547,9.268 L25.63,14.608 C25.842,14.979 25.97,15.393 25.997,15.82 L26,16 L25.997,16.18 C25.974,16.613 25.832,17.014 25.63,17.392 L22.372,23.035 L28.41,23.035 L31.579,17.546 C31.802,17.138 31.955,16.698 31.996,16.235 L32,16 L31.997,15.783 C31.97,15.326 31.816,14.894 31.602,14.494 L28.41,8.965 z M2.557,10.764 L0.404,14.494 C-0.135,15.426 -0.135,16.574 0.404,17.506 L2.537,21.202 L5.57,15.983 L2.557,10.764 z M15.901,1.738 L9.508,1.738 C8.431,1.738 7.437,2.312 6.899,3.244 L3.712,8.764 L6.729,13.99 L6.854,13.775 L9.981,8.358 C10.479,7.496 11.398,6.965 12.393,6.965 L18.919,6.965 L15.901,1.738 z M22.498,1.738 L18.21,1.738 L21.221,6.965 L27.255,6.965 L25.107,3.244 C24.551,2.31 23.751,1.884 22.699,1.744 L22.498,1.738 z"></path>
        </g>
    </svg>;
};