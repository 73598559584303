import moment from 'moment';
import {countries} from "../../data/general/countries";
import {getMemberJoinDate} from "../../components/reorganize/member-join-date";
import {buildAddress, cleanSocialForProfile, formatWebsiteForProfile} from "../shared";
import {getPhoneNumberData} from "../phone";

const mock_context = {
    'member-1234': 'Frodo Baggins',
    'member-5678': 'Samwise Gamgee',
    'label-1234': 'Red',
    'member-type-1234': 'Angels',
    'group-1234': 'Group Name'
};

const date_format = 'DD.MM.YYYY';

const allowed_custom_fields = [
    'number', 'select', 'multi-select', 'checkbox', 'textarea', 'url', 'member', 'mentor', 'mentee', 'text', 'date',
    'select-taxonomy', 'multi-select-taxonomy'
];

function buildChoicesMap(data) {
    let obj = {};

    if (data.options) {
        if (data.options.choices) {
            const c = data.options.choices;
            for (let i = 0; i < c.length; i++) {
                const ci = c[i];
                obj[ci.value] = ci.text;
            }

        }
    }

    return obj;
}

export function at_prepareCustomFields(sections = {}) {
    const entries = Object.entries(sections);
    let obj = {};

    for (let i = 0; i < entries.length; i++) {
        const [id, section] = entries[i];

        const field_entries = Object.entries(section.field_data);
        for (let k = 0; k < field_entries.length; k++) {
            const [field_id, field_data] = field_entries[k];

            const {type} = field_data;
            if (allowed_custom_fields.includes(type)) {
                obj[field_id] = {
                    type: type,
                    options: field_data.options,
                    choices_map: buildChoicesMap(field_data),
                    label: field_data.name
                };
            }
        }
    }

    return obj;
}

function getEntityExport(id, type, ctx, name_map={}) {
    let name = '';
    if(type==='member') {
        name = name_map[id] ? name_map[id] : 'Unknown';
    } else {
        name = ctx.options[id] ? ctx.options[id] : 'Unknown';
    }
    return `${name} <${type}-${id}>`;
}

export const field_export_types = {
    //// DATES
    // normal real date field

    'date': {
        export_fn: (v, ctx) => {
            if (!v) {
                return '';
            }
            return moment(v).format(ctx.date_format);
        }
    },
    'semantic-date': {
        export_fn: (v, ctx) => {
            if(!v) {
                return 'Unknown';
            }
            return getMemberJoinDate(v);
        }
    },
    // ts
    'timestamp': {
        export_fn: (v, ctx) => {
            if (!v) {
                return '';
            }
            return moment.unix(v / 1000).format(ctx.date_format);
        }
    },
    'timezone': {
        export_fn: (v, ctx) => {
            if (!v) {
                return '';
            }
            return v;
        }
    },
    // for YYYY-MM-DD
    'date-string': {
        export_fn: (v, ctx) => {
            if (!v) {
                return '';
            }
            const split = v.split('-');
            return v.replace('DD', split[2]).replace('MM', split[1]).replace('YYYY', split[0]);
        }
    },

    'number': {
        export_fn: (v, ctx) => {
            if (!v) {
                return '';
            }
            return `${v}`;
        }
    },

    'member-type': {
        export_fn: (v, ctx) => {
            if (!v) {
                return '';
            }
            // cases where we can't find memebrs or groups are handled as follows:
            // NAME <TYPE-ID>
            // TYPE = member, group, event, member_type
            return getEntityExport(v, 'member_type', ctx);
        }
    },

    'url': {
        export_fn: (v, ctx) => {
            if (!v) {
                return '';
            }
            return v;
        }
    },

    'address': {
        export_fn: (v, ctx) => {
            if (!v) {
                return '';
            }
            return buildAddress(v).replace(/\r?\n|\r/, '');
        }
    },
    'string': {
        export_fn: (v, ctx) => {
            if (!v) {
                return '';
            }
            return v;
        }
    },
    'email': {
        export_fn: (v, ctx) => {
            if (!v) {
                return '';
            }
            return v;
        }
    },
    'phone': {
        export_fn: (v, ctx) => {
            if (!v) {
                return '';
            }
            const phone_data = getPhoneNumberData(v);
            return phone_data.display;
        }
    },

    'member': {
        export_fn: (v, ctx, meta) => {
            if (!v) {
                return '';
            }
            return getEntityExport(v, 'member', ctx, meta&&meta.name_map?meta.name_map:{});
        }
    },
    'mentor': {
        export_fn: (v, ctx, meta) => {
            if (!v) {
                return '';
            }
            return getEntityExport(v, 'member', ctx, meta&&meta.name_map?meta.name_map:{});
        }
    },
    'group': {
        export_fn: (v, ctx) => {
            if (!v) {
                return '';
            }
            return getEntityExport(v, 'group', ctx);
        }
    },

    'multi-member': {
        export_fn: (v, ctx, meta) => {
            // separated with pipes

            if (!v) {
                return '';
            }
            const keys = Object.keys(v);
            return keys.map((slug, k) => {
                return `${getEntityExport(slug, 'member', ctx, meta.name_map ? meta.name_map : {})}`;
            }).join(' | ');
        }
    },

    'interests': {
        export_fn: (v, ctx) => {
            if (!v) {
                return '';
            }
            // it's an object of ids = true
            const keys = Object.keys(v);
            const len = keys.length;
            return keys.map((slug, k) => {
                const name = ctx.options[slug] ? ctx.options[slug] : 'Unknown';
                return `${name}`;
            }).join(' | ');
        }
    },

    'select': {
        export_fn: (v, ctx, meta) => {
            if (!v) {
                return '';
            }

            if(meta.field&&meta.field.startsWith('custom_fields.')&&meta.cf_label_map) {
                const f3 = meta.field.split('custom_fields.')[1];
                const f4 = meta.cf_label_map[`${f3}__${v}`];
                if(f4) {
                    return `${f4} <value-${v}>`;
                }
            }
            if (ctx.options&&ctx.options[v]) {
                return ctx.options[v];
            }
            return v;
        }
    },

    'select-label': {
        export_fn: (v, ctx) => {
            if (!v) {
                return '';
            }
            return v.label;
        }
    },

    'multi-select': {
        export_fn: (v, ctx) => {
            if (!v) {
                return '';
            }
            const keys = Object.keys(v);
            const len = keys.length;
            if (len === 0) {
                return '';
            }
            // need to do multiple labels
            return keys.map((key, k) => {
                const name = ctx.options[key] ? ctx.options[key] : 'Unknown';
                return `${name}`;
            }).join(' | ');
        }
    },

    'multi-select-labels': {
        export_fn: (v, ctx) => {
            if (!v) {
                return '';
            }
            const len = v.length;
            if (len === 0) {
                return '';
            }
            // need to do multiple labels
            return v.map((it, k) => {
                const name = it.label ? it.label : 'Unknown';
                return `${name}`;
            }).join(' | ');
        }
    },

    'profile_picture': {
        export_fn: (v, ctx) => {
            if (!v) {
                return '';
            }
            return `${v}?alt=media`;
        }
    },

    'id': {
        export_fn: (v, ctx) => {
            if (!v) {
                return '';
            }
            return v;
        }
    },

    'country': {
        export_fn: (v, ctx) => {
            if (!v) {
                return '';
            }
            return countries[v];
        }
    },

    'birthday': {
        export_fn: (v, ctx) => {

            // bday can be null...
            if (!v) {
                return '';
            }
            if (v.date && v.month && v.year) {
                return moment().month(v.month).year(v.year).date(v.date).format(ctx.date_format);
            }
            return '';
        }
    },

    'boolean': {
        export_fn: (v, ctx) => {
            return !v ? 'false' : 'true';
        }
    },

    'social-website': {
        export_fn: (v, ctx) => {
            if(!v) {
                return "";
            }
            return formatWebsiteForProfile(v,true)
        }
    },

    'social-twitter': {
        export_fn: (v, ctx) => {
            if(!v) {
                return "";
            }
            return cleanSocialForProfile(v,"twitter",true);
        }
    },

    'social-facebook': {
        export_fn: (v, ctx) => {
            if(!v) {
                return "";
            }
            return cleanSocialForProfile(v,"facebook",true);
        }
    },

    'social-discord': {
        export_fn: (v, ctx) => {
            if(!v) {
                return "";
            }
            return cleanSocialForProfile(v,"discord",true);
        }
    },

    'social-eth_address': {
        export_fn: (v, ctx) => {
            if(!v) {
                return "";
            }
            return v;
        }
    },

    'social-instagram': {
        export_fn: (v, ctx) => {
            if(!v) {
                return "";
            }
            return cleanSocialForProfile(v,"instagram",true);
        }
    },

    'social-github': {
        export_fn: (v, ctx) => {
            if(!v) {
                return "";
            }
            return cleanSocialForProfile(v,"github",true);
        }
    },

    'social-reddit': {
        export_fn: (v, ctx) => {
            if(!v) {
                return "";
            }
            return cleanSocialForProfile(v,"reddit");
        }
    },

    'social-skype': {
        export_fn: (v, ctx) => {
            if(!v) {
                return "";
            }
            return cleanSocialForProfile(v,"skype",true);
        }
    },

    'boolean-label': {
        export_fn: (v, ctx) => {
            return !v ? ctx.options.unchecked_label : ctx.options.checked_label;
        }
    }
};

export const selectable_custom_fields_ids_array = [
    'text',
    'textarea',
    'email',
    'phone',
    'url',
    'date',
    'number',
    'country',
    'checkbox',
    'select',
    'multi-select',
    'member',
    'mentor',
    //'mentees',
    'address',
    'group',
    'select-taxonomy',
    'multi-select-taxonomy'
];

export const custom_field_types = {
    'country': {
        default_value: '',
        example: 'us',
        label: 'Country',
        filter_type: 'multi-select',
        filter_default_operator: 'is',
        filter_default_value: '',
        filterable: true,
        edit_in_designer: false,
        can_be_imported: true,
        import_format: 'country',
        export_format: 'country'
    },
    'gender': {
      default_value: null,
      example: 'male',
      label: 'Sex',
      filter_type: 'multi-select',
        filter_default_operator: 'is',
        filter_default_value: '',
        filterable: false,
        edit_in_designer: false,
        can_be_imported: false,
        import_format: 'gender',
        export_format: 'gender'
    },
    'language': {
        default_value: '',
        example: 'en',
        label: 'Language',
        filter_type: 'multi-select',
        filter_default_operator: 'is',
        filter_default_value: '',
        filterable: true,
        edit_in_designer: true,
        can_be_imported: false,
        import_format: 'language',
        export_format: 'string'
    },
    'semantic-date': {
        default_value: {
            type: '',
            value: {
                month: null,
                year: null,
                date: null,
                season: null
            }
        },
        example: {
            type: 'month_year',
            value: {
                month: 5,
                year: 2015,
                date: null,
                season: null
            }
        },
        label: 'Semantic Date',
        edit_in_designer: false,
        filterable: false,
        can_be_imported: false,
        import_format: 'semantic-date',
        export_format: 'semantic-date'
    },
    'cover-photo': {
        default_value: '',
        example: '',
        label: 'Cover Photo',
        edit_in_designer: true,
        filterable: false,
        can_be_imported: false,
        import_format: 'url',
        export_format: 'url'
    },
    'profile-picture': {
        default_value: '',
        example: '',
        label: 'Profile Picture',
        edit_in_designer: true,
        filterable: false,
        can_be_imported: false,
        import_format: 'url',
        export_format: 'url'
    },
    'image': {
        default_value: '',
        example: '',
        label: 'Image',
        edit_in_designer: true,
        can_be_imported: false,
        filterable: false,
        import_format: 'url',
        export_format: 'url'
    },
    'display-name': {
        default_value: '',
        example: '',
        label: 'Display Name',
        edit_in_designer: true,
        can_be_imported: false,
        filterable: false,
        import_format: 'string',
        export_format: 'string'
    },
    'boolean': {
        default_value: false,
        can_be_imported: false,
        example: true,
        edit_in_designer: true,
        filter_type: 'boolean',
        filter_default_operator: 'true',
        filter_default_value: true,
        filterable: true,
        label: 'Boolean',
        import_format: 'boolean',
        export_format: 'boolean'
    },
    'birthday': {
        can_be_imported: true,
        label: 'Birthday',
        edit_in_designer: false,
        filterable: false,
        default_value: {
            date: null,
            month: null,
            year: null
        },
        example: {
            date: 15,
            month: 9,
            year: 1995
        },
        import_format: 'birthday',
        export_format: 'birthday'
    },
    'text': {
        // normal string
        default_value: '',
        filterable: true,
        filter_type: 'string',
        filter_default_operator: 'starts_with',
        filter_default_value: '',
        example: 'Hello there!',
        edit_in_designer: false,
        label: 'Text',
        can_be_imported: true,
        import_format: 'string',
        export_format: 'string'
    },
    'timezone': {
        // normal string
        default_value: '',
        filterable: true,
        filter_type: 'string',
        filter_default_operator: 'is',
        filter_default_value: '',
        example: 'America/Chicago',
        edit_in_designer: false,
        label: 'Timezone',
        can_be_imported: false,
        import_format: 'string',
        export_format: 'timezone'
    },
    'string': {
        // normal string
        default_value: '',
        example: 'Hello there!',
        filterable: true,
        filter_type: 'string',
        filter_default_operator: 'starts_with',
        filter_default_value: '',
        label: 'String',
        edit_in_designer: false,
        can_be_imported: true,
        import_format: 'string',
        export_format: 'string'
    },
    'textarea': {
        // normal string
        default_value: '',
        filterable: true,
        filter_type: 'string',
        filter_default_operator: 'starts_with',
        filter_default_value: '',
        example: 'Hello there!',
        edit_in_designer: false,
        can_be_imported: true,
        label: 'Multi-line text',
        import_format: 'string',
        export_format: 'string'
    },
    'checkbox': {
        // normal string
        default_value: '',
        filterable: true,
        filter_type: 'boolean',
        filter_default_operator: 'true',
        filter_default_value: '',
        example: false,
        edit_in_designer: true,
        can_be_imported: true,
        label: 'Checkbox',
        import_format: 'boolean-label',
        export_format: 'boolean-label'
    },
    'email': {
        // stored as email string
        default_value: '',
        edit_in_designer: false,
        example: 'test@example.com',
        can_be_imported: true,
        filterable: true,
        filter_type: 'string',
        filter_default_operator: 'starts_with',
        filter_default_value: '',
        label: 'Email',
        import_format: 'email',
        export_format: 'email'
    },
    'website': {
        default_value: '',
        edit_in_designer: false,
        filterable: true,
        filter_type: 'string',
        filter_default_operator: 'starts_with',
        filter_default_value: '',
        example: 'getunaty.com',
        can_be_imported: true,
        label: 'Website',
        import_format: 'string',
        export_format: 'url'
    },
    'url': {
        default_value: '',
        edit_in_designer: false,
        filterable: true,
        filter_type: 'string',
        filter_default_operator: 'starts_with',
        filter_default_value: '',
        example: 'getunaty.com',
        can_be_imported: true,
        label: 'Website',
        import_format: 'string',
        export_format: 'url'
    },
    'date': {
        // stored as 1995-12-12
        default_value: '',
        edit_in_designer: false,
        filterable: true,
        filter_type: 'date',
        filter_default_operator: 'is_before',
        filter_default_value: '',
        label: 'Date',
        example: '1995-12-12',
        can_be_imported: true,
        import_format: 'date',
        export_format: 'date-string'
    },
    'phone': {
        // stored as string without prefix
        default_value: '',
        example: '2628252883',
        filterable: true,
        filter_type: 'string',
        filter_default_operator: 'starts_with',
        filter_default_value: '',
        edit_in_designer: false,
        can_be_imported: true,
        export_format: 'phone',
        import_format: 'phone',
        label: 'Phone',
    },
    'member': {
        // stored as id of member
        default_value: '',
        edit_in_designer: false,
        filterable: false,
        example: 'member-1234',
        label: 'Member',
        can_be_imported: false,
        import_format: 'member',
        export_format: 'member'
    },
    'group': {
        // stored as id of group
        default_value: '',
        example: 'group-1234',
        filterable: false,
        edit_in_designer: false,
        label: 'Group',
        can_be_imported: false,
        import_format: 'group',
        export_format: 'group'
    },
    'mentees': {
        // stored as id of member = true
        default_value: {},
        edit_in_designer: false,
        filterable: false,
        example: {
            'member-1234': true,
            'member-5678': true
        },
        can_be_imported: false,
        import_format: 'multi-member',
        label: 'Mentees',
        export_format: 'multi-member'
    },
    'member-type': {
        default_value: '',
        can_be_imported: false,
        edit_in_designer: true,
        filterable: true,
        filter_type: 'multi-select',
        filter_default_operator: 'is',
        filter_default_value: '',
        example: 'member-type-1234',
        import_format: 'member-type',
        label: 'Member Type',
        export_format: 'member-type'
    },
    'mentor': {
        // stored as id of member
        default_value: '',
        can_be_imported: false,
        filterable: false,
        edit_in_designer: false,
        label: 'Mentor',
        example: 'member-1234',
        import_format: 'member',
        export_format: 'member'
    },
    'address': {
        // normal strings
        default_value: {
            line_one: '',
            line_two: '',
            city: '',
            zip_code: '',
            state: '',
            country: ''
        },
        edit_in_designer: false,
        can_be_imported: false,
        import_format: 'address',
        filterable: false,
        label: 'Address',
        example: {
            line_one: 'c/o Example',
            line_two: '123 Main St',
            city: 'Brighton',
            zip_code: '52342',
            state: 'New York',
            country: 'us'
        },
        export_format: 'address'
    },
    'number': {
        // stored as a string, for now..
        default_value: '',
        can_be_imported: true,
        filterable: true,
        import_format: 'number',
        filter_type: 'number',
        filter_default_operator: 'equals',
        filter_default_value: '',
        label: 'Number',
        edit_in_designer: false,
        example: '10',
        export_format: 'number'
    },
    'select': {
        // stored as the label of the item selected e.g. Red
        default_value: '',
        edit_in_designer: true,
        can_be_imported: true,
        filterable: true,
        filter_type: 'multi-select',
        filter_default_operator: 'is',
        filter_default_value: '',
        label: 'Select',
        example: 'Red',
        import_format: 'select',
        export_format: 'select'
    },
    'multi-select': {
        // stored only as IDs..
        // todo this is inconsistent
        default_value: {},
        edit_in_designer: true,
        label: 'Multi-select',
        can_be_imported: false,
        filterable: true,
        filter_type: 'multi-select',
        filter_default_operator: 'is',
        filter_default_value: '',
        import_format: 'multi-select',
        example: {
            'label-1234': true
        },
        export_format: 'multi-select'
    },
    'select-taxonomy': {
        // stored as an object with a label..
        default_value: {},
        can_be_imported: false,
        filterable: false,
        edit_in_designer: true,
        label: 'Taxonomy Select',
        import_format: 'select-taxonomy',
        example: {
            label: 'Taxonomy Label'
        },
        export_format: 'select-label'
    },
    'multi-select-taxonomy': {
        // stored as an array of objects, the object has a label
        default_value: [],
        edit_in_designer: true,
        filterable: false,
        can_be_imported: false,
        label: 'Taxonomy Multi-select',
        import_format: 'multi-select-taxonomy',
        example: [
            {
                label: 'Taxonomy Label'
            }
        ],
        export_format: 'multi-select-labels'
    },



    /// SOCIAL
    'social-facebook': {
        default_value: '',
        edit_in_designer: false,
        filterable: true,
        filter_type: 'string',
        filter_default_operator: 'starts_with',
        filter_default_value: '',
        example: '',
        placeholder: "facebook.com/my-user-page",
        can_be_imported: true,
        label: 'Facebook',
        import_format: 'string',
        export_format: 'string'
    },
    'social-skype': {
        default_value: '',
        edit_in_designer: false,
        filterable: true,
        filter_type: 'string',
        filter_default_operator: 'starts_with',
        filter_default_value: '',
        example: '',
        placeholder: "my-username",
        can_be_imported: true,
        label: 'Skype ID',
        import_format: 'string',
        export_format: 'string'
    },
    'social-linkedin': {
        default_value: '',
        edit_in_designer: false,
        filterable: true,
        filter_type: 'string',
        filter_default_operator: 'starts_with',
        filter_default_value: '',
        example: '',
        placeholder: "linkedin.com/in/my-profile",
        can_be_imported: true,
        label: 'LinkedIn',
        import_format: 'string',
        export_format: 'string'
    },
    'social-github': {
        default_value: '',
        edit_in_designer: false,
        filterable: true,
        filter_type: 'string',
        filter_default_operator: 'starts_with',
        filter_default_value: '',
        example: '',
        placeholder: "https://github.com/my-profile",
        can_be_imported: true,
        label: 'GitHub',
        import_format: 'string',
        export_format: 'string'
    },
    'social-reddit': {
        default_value: '',
        edit_in_designer: false,
        filterable: true,
        filter_type: 'string',
        filter_default_operator: 'starts_with',
        filter_default_value: '',
        example: '',
        placeholder: "reddit.com/user/my-profile",
        can_be_imported: true,
        label: 'Reddit',
        import_format: 'string',
        export_format: 'string'
    },
    'social-website': {
        default_value: '',
        edit_in_designer: false,
        filterable: true,
        filter_type: 'string',
        filter_default_operator: 'starts_with',
        filter_default_value: '',
        example: 'getunaty.com',
        placeholder: 'mysite.com',
        can_be_imported: true,
        label: 'Website',
        import_format: 'string',
        export_format: 'url'
    },
    'social-twitter': {
        default_value: '',
        edit_in_designer: false,
        filterable: true,
        filter_type: 'string',
        filter_default_operator: 'starts_with',
        filter_default_value: '',
        example: '',
        placeholder: "@handle or twitter.com/handle",
        can_be_imported: true,
        label: 'Twitter',
        import_format: 'string',
        export_format: 'string'
    },
    'social-instagram': {
        default_value: '',
        edit_in_designer: false,
        filterable: true,
        filter_type: 'string',
        filter_default_operator: 'starts_with',
        filter_default_value: '',
        example: '',
        placeholder: "instagram.com/my-username",
        can_be_imported: true,
        label: 'Instagram',
        import_format: 'string',
        export_format: 'string'
    },
    'social-eth_address': {
        default_value: '',
        edit_in_designer: false,
        filterable: true,
        filter_type: 'string',
        filter_default_operator: 'starts_with',
        filter_default_value: '',
        example: '',
        placeholder: "Your ENS or Eth address (0x...)",
        can_be_imported: true,
        label: 'Eth Address',
        import_format: 'string',
        export_format: 'string'
    },
    'social-discord': {
        default_value: '',
        edit_in_designer: false,
        filterable: true,
        filter_type: 'string',
        filter_default_operator: 'starts_with',
        filter_default_value: '',
        example: '',
        placeholder: "discordapp.com/users/my-id",
        can_be_imported: true,
        label: 'Discord',
        import_format: 'string',
        export_format: 'string'
    },
};

function buildMockOptions() {
    return mock_context;
}

// todo unify with sanitization pipeline
export function getFieldExportValue(value, format, context, meta={name_map:{},field:"",cf_label_map:{}}) {
    const cft = custom_field_types[format];
    if (!cft) {
        console.error('!cft', format);
        return '';
    }
    const item = field_export_types[cft.export_format];
    if (!item) {
        console.error('!item', cft.export_format);
        return '';
    }
    return item.export_fn(value, context, meta);
}

export function runExportTest() {
    const entries = Object.entries(custom_field_types);

    for (let i = 0; i < entries.length; i++) {
        const [id, entry] = entries[i];
        const export_type = field_export_types[entry.export_format];
        const ctx = {
            date_format: 'DD.MM.YYYY',
            options: buildMockOptions()
        };
        const sample_export = export_type.export_fn(entry.example, ctx);
    }

    return true;
}

export function cf_getSelectOptionsObject(options,blank=true) {
    let obj = {
    };
    if(blank) {
        obj['none'] = 'None';
    }
    const choices = options.choices;
    for (let i = 0; i < choices.length; i++) {
        const item = choices[i];
        if (item.hidden) {
            continue;
        }
        obj[item.value] = item.text;
    }
    return obj;
}

export function cf_getSelectOptionsArray(options,blank=true) {
    let arr = [];
    if(blank) {
        arr.push({
            label: 'None',
            value: ''
        })
    }
    const choices = options.choices;
    for (let i = 0; i < choices.length; i++) {
        const item = choices[i];
        if (item.hidden) {
            continue;
        }
        arr.push({
            label: item.text,
            value: item.value
        });
    }
    return arr;
}

export function cf_getSelectOption(v, options) {
    let text = '';

    if (!options) {
        return '';
    }
    const choices = options.choices;
    for (let i = 0; i < choices.length; i++) {
        const item = choices[i];
        if (item.value === v) {
            if (item.text) {
                text = item.text;
            } else {
                text = item.name;
            }
            break;
        }
    }
    return text;
}

export function cf_getCFValue(id, type, member_cfs) {
    const mv = member_cfs[id];

    if (type === 'member' || type === 'mentor') {
        return mv ? mv : '';
    } else if (type === 'multi-member' || type === 'mentees') {
        return mv ? mv : [];
    } else if (type === 'date') {
        return mv ? mv : '';
    } else if (type === 'checkbox') {
        return mv ? mv : false;
    } else if (type === 'text') {
        return mv ? mv : '';
    } else if (type === 'group') {
        return mv ? mv : '';
    } else if (type === 'phone') {
        return mv ? mv : '';
    } else if (type === 'email') {
        return mv ? mv : '';
    } else if (type === 'url') {
        return mv ? formatWebsiteForProfile(mv) : '';
    } else if (type === 'number') {
        return mv ? mv : '';
    } else if (type === 'address') {
        return mv ? mv : {
            line_one: '',
            line_two: '',
            city: '',
            country: '',
            zip_code: '',
            state: ''
        };
    } else if (type === 'select') {
        return mv ? mv : '-';
    } else if (type === 'multi-select') {
        return mv ? mv : [];
    } else if (type === 'select-taxonomy') {
        return mv ? mv : null;
    } else if (type === 'multi-select-taxonomy') {
        return mv ? mv : {};
    } else if (type === 'textarea') {
        return mv ? mv : '';
    }
}