import moment from 'moment';
// also save names of companies to user as obj, maybe also titles?

function fixMonth(m) {
    if (m > 9) {
        return `${m}`;
    } else {
        return `0${m}`;
    }
}

function sortByStartDates(data) {
    const items = Object.entries(data).map(([id, item],k) => {
        return {
            id: id,
            index: k,
            start_date: item.from_year==='' ? `1900` : `${item.from_year}${!item.from_month?'':fixMonth(item.from_month)}`,
            org_name: item.org_data.title
        };
    });
    return items.sort(function (a, b) {
        return parseFloat(b.start_date) - parseFloat(a.start_date);
    });
}

function groupArr(sa) {
    let obj = {};

    for (let i = 0; i < sa.length; i++) {
        const item = sa[i];
        if (!obj[item.org_name]) {
            obj[item.org_name] = [];
        }
        obj[item.org_name].push(item.id);
    }

    return obj;
}

function getDuration(f,l) {
    // return months
    if(f.from_year===''||!f.from_year) {
        return '';
    }
    const m1 = f.from_month !== null && f.from_month !== '' ? moment().month(f.from_month).year(f.from_year) : moment().year(f.from_year);
    if(!m1) {
        return '-';
    }
    const m2 = l.current ? moment() : (l.from_month !== null && l.from_month !== '') ? moment().month(l.to_month).year(l.to_year) : moment().year(l.to_year);
    if(!m2||typeof m2 === 'number') {
        return '-';
    }
    if(!m1.isValid()||!m2.isValid()) {
        return '-';
    }
    return m2.diff(m1,'months');
}

function getAbbr(b,n) {
    return `${n} ${b}${n===1?'':'s'}`;
}

function formatDuration(months,from_year) {
    const years = Math.floor(months / 12);
    const remainder = months % 12;
    if(from_year===''||isNaN(years)) {
        return ``;
    }
    if(years>0) {
        return `${getAbbr('yr',years)} ${remainder>0?`${getAbbr('mo',remainder)}`:``}`;
    }
    return `${getAbbr('mo', remainder)}`;
}

function getOrgInfo(ids, data) {
    const first_entity = data[ids[0]];
    const last_entity = data[ids[(ids.length-1)]];
    const total_duration = getDuration(last_entity,first_entity);
    return {
        total_duration: total_duration, // months
        duration_text: formatDuration(total_duration),
        org_data: first_entity.org_data
    }
}

export function formatStartDate(m,y) {
    let mom;
    if(!y) {
        return '';
    } else if(!m) {
        mom = moment().year(y);
        return `${mom.format('YYYY')}`;
    } else {
        mom = moment().month(m).year(y);
        return `${mom.format('MMM YYYY')}`;
    }
}

export function formatEndDate(m,y,c) {
    if(c) {
        return 'Present';
    }
    if(!y||y==='') {
        return '';
    }
    let mom;
    if(!m) {
        mom = moment().year(y);
        return `${mom.format('YYYY')}`;
    } else {
        mom = moment().month(m).year(y);
        return `${mom.format('MMM YYYY')}`;
    }

}

function buildPositions(ids,data, type) {
    if(type==='education') {
        return ids.map((id)=>{
            const p = data[id];
            const dur = getDuration(p,p);
            return {
                title: `${p.field_of_study} (${p.degree})`,
                id: id,
                start: formatStartDate(p.from_month,p.from_year),
                end: formatEndDate(p.to_month,p.to_year,p.current),
                from_month: p.from_month, // june
                from_year: p.from_year,
                to_month: p.to_month,
                to_year: p.to_year,
                duration_text: formatDuration(dur,p.from_year),
                duration: dur, // now
                current: p.current,
                location: '',
                description: p.description
            }
        })
    }
    return ids.map((id)=>{
        const p = data[id];
        const dur = getDuration(p,p);
        return {
            title: p.title,
            id: id,
            start: formatStartDate(p.from_month,p.from_year),
            end: formatEndDate(p.to_month,p.to_year,p.current),
            from_month: p.from_month, // june
            from_year: p.from_year,
            to_month: p.to_month,
            to_year: p.to_year,
            duration_text: formatDuration(dur,p.from_year),
            duration: dur, // now
            current: p.current,
            location: p.location,
            description: p.description
        }
    })
}

function buildEntry(position_ids, name, data, type) {
    const org_data = getOrgInfo(position_ids, data);
    return {
        ...org_data,
        positions: buildPositions(position_ids, data, type)
    };
}

function buildFinalArr(g, data, type) {
    let arr = [];
    for (let i = 0; i < g.length; i++) {
        const name = g[i][0];
        const positions_arr = g[i][1];
        arr.push(buildEntry(positions_arr, name, data, type));
    }
    return arr;
}

export function prepareExperiences(dm, type) {
    const sort_arr = sortByStartDates(dm);

    const grouped_obj = groupArr(sort_arr);

    const final_arr = buildFinalArr(Object.entries(grouped_obj), dm, type);

    return final_arr;
}