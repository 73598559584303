import moment from "moment";
import React from "react";

var REFERENCE = moment();
var TODAY = REFERENCE.clone().startOf('day');
var ONE_HOUR = REFERENCE.clone().subtract(1, 'hours');
var YESTERDAY = REFERENCE.clone().subtract(1, 'days').startOf('day');
var A_WEEK_OLD = REFERENCE.clone().subtract(7, 'days').startOf('day');
var ONE_MONTH = REFERENCE.clone().subtract(30, 'days').startOf('day');
var SIX_MONTHS = REFERENCE.clone().subtract(6, 'months').startOf('day');
var ONE_YEAR = REFERENCE.clone().subtract(365, 'days').startOf('day');
var TWO_YEARS = REFERENCE.clone().subtract(2, 'years').startOf('day');

function fromNowOrNow(a,r={}) {
    if (Math.abs(moment().diff(a)) < 25000) { // 25 seconds before or after now
        return r.now?r.now:'just now';
    }
    return moment(a).fromNow();
}

export function isToday(momentDate) {
    return momentDate.isSame(TODAY, 'd');
}

export function isYesterday(momentDate) {
    return momentDate.isSame(YESTERDAY, 'd');
}

export function isWithinAWeek(momentDate) {
    return momentDate.isAfter(A_WEEK_OLD);
}

export function isWithinAnHour(momentDate) {
    return momentDate.isAfter(ONE_HOUR);
}

export function isLessThanAYear(momentDate) {
    return momentDate.isAfter(ONE_YEAR);
}

export function isLessThanTwoYears(momentDate) {
    return momentDate.isAfter(TWO_YEARS);
}

export function isLessThanAMonth(momentDate) {
    return momentDate.isAfter(ONE_MONTH);
}

export function isLessThanSixMonths(momentDate) {
    return momentDate.isAfter(SIX_MONTHS);
}

function isTwoWeeksOrMore(momentDate) {
    return !isWithinAWeek(momentDate);
}

export function getGeneralTime(date) {
    let last_date = moment(date), text = ``;

    if (isToday(last_date)) {
        text = `today`;
    } else if (isYesterday(last_date)) {
        text = 'yesterday';
    } else if (isWithinAWeek(last_date)) {
        text = 'this week';
    } else if (isLessThanAMonth(last_date)) {
        text = 'this month';
    } else if (isLessThanAYear(last_date)) {
        text = 'months ago';
    } else if (isLessThanTwoYears(last_date)) {
        text = 'last year';
    } else {
        text = 'years ago';
    }

    return `${text}`;
}

export function getPrettyTime(date, extra = '',r) {
    // show time if today, otherwise just days
    let last_date = moment(date), text = '', suffix = '';

    if (isWithinAnHour(last_date)) {
        text = fromNowOrNow(last_date,r);
    } else if (isToday(last_date)) {
        text = last_date.format(r?r.today:'h:mm A');
    } else if (isYesterday(last_date)) {
        text = r?r.yesterday:'Yesterday';
    } else if (isWithinAWeek(last_date)) {
        text = last_date.format(r?r.week:'dddd');
    } else if (isLessThanAYear(last_date)) {
        text = last_date.format(r?r.year:'MMM Do');
    } else {
        text = last_date.format(r?r.beyond:'MMM YYYY')
    }

    return `${text}`;
}

export function get_short_time(tdate) {
    var system_date = new Date(Date.parse(tdate));
    var user_date = new Date();
    if (K.ie) {
        system_date = Date.parse(tdate.replace(/( \+)/, ' UTC$1'))
    }
    var diff = Math.floor((user_date - system_date) / 1000);
    if (diff <= 1) {
        return "1s";
    }
    if (diff < 60) {
        return diff + "s";
    }
    if (diff <= 90) {
        return "1m";
    }
    if (diff <= 3540) {
        return Math.round(diff / 60) + "m";
    }
    if (diff <= 5400) {
        return "1 hour ago";
    }
    if (diff <= 86400) {
        return Math.round(diff / 3600) + "h";
    }
    if (diff <= 129600) {
        return "1d";
    }
    if (diff < 604800) {
        return Math.round(diff / 86400) + "d";
    }
    if (diff <= 777600) {
        return "1w";
    }
    if (diff < 40435200) {
        return Math.round(diff / 604800) + "w";
    }
    if (diff <= 80870400) {
        return "1y";
    }
    return Math.round(diff / 40435200) + "y";
}

// from http://widgets.twimg.com/j/1/widget.js
var K = function () {
    var a = navigator.userAgent;
    return {
        ie: a.match(/MSIE\s([^;]*)/)
    }
}();

export function getDateFormats(lang) {
    if (lang === 'de') {
        return {
            date_time: 'D. MMMM YYYY [um] hh:mm',
            standard: 'D. MMMM',
            weekday: 'dddd D.MM.YY',
            short_date: 'D.MM.YY',
            relative: {
                now: 'gerade eben',
                today: 'hh:mm',
                yesterday: 'Gestern',
                week: 'dddd',
                year: 'D. MMMM',
                beyond: 'MMMM YYYY'
            }
        }
    }
    return {
        date_time: 'MMMM Do, YYYY [at] H:mm a',
        standard: 'MMMM Do',
        weekday: 'dddd, MMMM Do',
        short_date: 'M/D',
        relative: {
            now: 'just now',
            today: 'h:mm',
            yesterday: 'Yesterday',
            week: 'dddd',
            year: 'MMM Do',
            beyond: 'MMM YYYY'
        }
    }
}

/*
TODAY - 1:12PM

Yesterday, 12:46PM

Monday, 1:46PM

1 week ago

2 weeks ago

1 month ago

6 months ago

1 year ago
 */

export function aa_formatDate(ts = Date.now()) {
    const m2 = moment.unix(ts / 1000);
    const now = moment();
    let n, dt, sort, relative;
    if (isToday(m2)) {
        dt = m2.format(`h:mmA`);
        sort = 'this-week';
      //  relative = m2.fromNow();
    } else if (isYesterday(m2)) {
        dt = m2.format(`[Yesterday]`);
        sort = 'this-week';
      //  relative = m2.format('MMM D');
    } else if (isWithinAWeek(m2)) {
        dt = m2.format(`dddd`);
        sort = 'this-week';
    //    relative = m2.format('MMM D');
    } else if (isLessThanAMonth(m2)) {
        n = now.diff(m2, "weeks");
        dt = `${n} week${n===1?"":"s"} ago`;
        sort = 'last-month';
       // relative = m2.format('MMM D');
    } else if (isLessThanAYear(m2)) {
        n = now.diff(m2, "months");
        dt = `${n} month${n===1?"":"s"} ago`;
        sort = 'last-year';
       // relative = m2.format('MMM D');
    } else {
        n = now.diff(m2, "years");
        dt = `${n} year${n===1?"":"s"} ago`;
        sort = 'beyond';
     //   relative = m2.format('MMM D, YYYY');
    }
    relative = getPrettyTime(m2);
    return {
        full_date: m2.format('MMM Do, YYYY'),
        date_formatted: dt,
        relative,
        sort
    }
}