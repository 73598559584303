import React from 'react';
import {Button,ButtonGroup} from "../../core";
import {ArchiveIcon,ViewGridIcon,BookmarkIcon,ChevronLeftIcon,ChevronRightIcon,ExclamationIcon,ExclamationCircleIcon,TableIcon,EyeIcon} from '@heroicons/react/solid';

export default function SGButtonsPage() {

    return <div>
        <div className="text-lg font-semibold text-gray-700">
            Sizes
        </div>
        <div className="flex items-center space-x-4">
        <Button text="Extra small" size="xs" />
        <Button text="Small" size="sm" />
        <Button text="Standard" size="md" />
        <Button text="Large" size="lg" />
        <Button text="Extra large" size="xl" />
    </div>
        <div className="text-lg mt-4 font-semibold text-gray-700">
            Intents
        </div>
        <div className="flex items-center space-x-4">
            <Button text="Primary" intent='primary' />
            <Button text="Secondary" intent='secondary' />
            <Button text="Success" intent='success' />
            <Button text="Danger" intent='danger' />

        </div>

        <div className="text-lg mt-4 font-semibold text-gray-700">
            Minimal
        </div>
        <div className="flex items-center space-x-4">
            <Button text="Standard" minimal />
            <Button text="Primary" minimal intent='primary' />
            <Button text="Secondary" minimal intent='secondary' />
            <Button text="Success" minimal intent='success' />
            <Button text="Danger" minimal intent='danger' />

        </div>
        <div className="text-lg mt-4 font-semibold text-gray-700">
            Rounded
        </div>
        <div className="flex items-center space-x-4">
            <Button text="Extra small" circular size="xs" />
            <Button text="Small" circular size="sm" />
            <Button text="Standard" circular size="md" />
            <Button text="Large" circular size="lg" />
            <Button text="Extra large" circular size="xl" />
        </div>

        <div className="text-lg mt-4 font-semibold text-gray-700">
            Icon Buttons
        </div>
        <div className="flex items-center space-x-4">
            <Button text="Left Icon" left_icon={<ExclamationIcon/>} />
            <Button text="Right Icon" right_icon={<ExclamationIcon/>} />
            <Button right_icon={<EyeIcon/>} />
            <Button text="Primary" left_icon={<ExclamationCircleIcon/>} intent='primary' />
        </div>

        <div className="text-lg mt-4 font-semibold text-gray-700">
            Button Group
        </div>
        <div className="flex items-center space-x-4">
            <ButtonGroup>
            <Button text="Date" />
            <Button text="Month" />
            <Button text="Year" />
            </ButtonGroup>
            <ButtonGroup>
            <Button right_icon={<ChevronLeftIcon/>} />
            <Button right_icon={<ChevronRightIcon/>} />
            </ButtonGroup>
            <ButtonGroup>
                <Button left_icon={<BookmarkIcon/>} text="Bookmark" />
                <Button compact text="12k" />
            </ButtonGroup>
            <ButtonGroup>
                <Button left_icon={<ViewGridIcon/>} />
                <Button left_icon={<TableIcon/>} />
                <Button left_icon={<ArchiveIcon />} />
            </ButtonGroup>

        </div>
        <div className="text-lg mt-4 font-semibold text-gray-700">
            Disabled
        </div>
        <div className="flex items-center space-x-4">
            <Button disabled text="Standard" />
            <Button disabled text="Primary" intent='primary' />
            <Button disabled text="Secondary" intent='secondary' />
            <Button disabled text="Success" intent='success' />
            <Button disabled text="Danger" intent='danger' />

        </div>

        <div className="text-lg mt-4 font-semibold text-gray-700">
            Special
        </div>
        <div className="flex items-center space-x-4">
            <Button shortcut="M" text="Shortcuts" />
            <Button shortcut="M" intent="secondary" text="Shortcuts" />
            <Button loading intent="secondary" text="Shortcuts" />
            <Button loading text="Shortcuts" />

        </div>
    </div>
}