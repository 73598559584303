import React, {useContext, useState} from 'react';
import {GlobalContext} from "../../../global/global-context";
import {MenuPanelGroupModerators} from "./moderators";

export function GroupModalModeratorsTab({community,can_manage,setData=()=>{},getGroup=()=>{},cb=()=>{},id,email_list_power_up,group_data}) {

    const community_uid = community.uid;


    return <div >
        <MenuPanelGroupModerators group_data={group_data} can_manage={can_manage} excluded={{members: {}, roles: {}}} allowed={{
            members: group_data.user_uids,
            roles: group_data.entities.roles
        }} disabled={{members: group_data.moderators.members, roles: group_data.moderators.roles}}
                                  context={{community_uid: community_uid, member: community.member}}
                                  moderators={group_data.moderators}
                                  searchable={group_data.type === 'team' ? {roles: true} : {
                                      members: true,
                                      roles: true
                                  }}
                                  />
    </div>
}