import React, {useContext} from 'react';
import {Redirect, Route, Switch, useHistory} from "react-router-dom";
import {CADCommunityProfile} from "./community-profile";
import {CADMemberProfile} from "./member-profile";
import {CADMembershipCard} from "./membership-cards";
import {CommunityContext} from "../../../community-context";
import {EntityHeader, PageHeader, Tabs} from "../../../../../library/components/core";
import {PageContainer} from "../../../../../library/components/old/page-container";
import {CADSignInPage} from "./sign-in-page";
import {CADEmailDesigner} from "./emails/email-designer";
import {CADAchievements} from "./achievements";
import {StandardPageHeight} from "../../../../../library/playground/standard-page-framework";
import {CADGateDesigner} from "./gates";

const tabs = [
    {
        path: '/:handle/admin/settings/designer/member-profile',
        comp: CADMemberProfile,
        label: 'Member Profile'
    },
    {
        path: '/:handle/admin/settings/designer/membership-card',
        comp: CADMembershipCard,
        label: 'Membership Card'
    },

    /*
     {
        path: '/:handle/admin/settings/designer/achievements',
        comp: CADAchievements,
        label: 'Achievements'
    },

     */
    {
        path: '/:handle/admin/settings/designer/emails',
        comp: CADEmailDesigner,
        label: 'Emails'
    },
    {
        path: '/:handle/admin/settings/designer/gates',
        comp: CADGateDesigner,
        label: 'Gates'
    },

    {
        path: '/:handle/admin/settings/designer/sign-in-page',
        comp: CADSignInPage,
        label: 'Sign In Page'
    },
    /*
    {
        path: '/:handle/admin/settings/designer/community-profile',
        comp: CADCommunityProfile,
        label: 'Community Profile'
    }

     */
];

export function CommunityAdminDesigner(props) {
    const community = useContext(CommunityContext);
    const {handle} = community.data;
    const history = useHistory();
    const current_path = history.location.pathname;
    const selected = tabs.map((t)=>{
        return {
            ...t,
            path: t.path.replace(':handle',handle)
        }
    }).filter(function (a) {
        return a.path === current_path;
    })[0];
    return <StandardPageHeight full content>
        <div>
            <EntityHeader title="Community Designer"
                          goBackTo={() => history.push(`/${community.data.handle}/admin/settings`)}/>
            <div className="">
            <Tabs active={selected?selected.label:""} onSelect={(nl)=>{
                const new_selection = tabs.filter(function (a) {
                    return a.label === nl;
                })[0];
                history.push(new_selection.path.replace(':handle',handle));
            }} intent="secondary" tabs={tabs.map(t=>{
                return t.label
            })} layout="standard-bottom"/>
            </div>
        </div>

        <div>
            <Switch>
                {tabs.map(t=><Route path={t.path} key={t.path} exact component={t.comp}/>)}
                <Redirect from='/*' to={`/${handle}/admin/settings/designer/member-profile`}/>
            </Switch>
        </div>
    </StandardPageHeight>
}