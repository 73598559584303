import moment from "moment";

export const composer_merge_tags = [
    {
        label: "Date / Time",
        scope: "*",
        tags: [
            {
                label: "Date",
                subtitle: "{{date}}",
                fn: (m)=>`${moment().format('MMMM Do YYYY')}`
            },
            {
                label: "Date & Time",
                subtitle: "{{date:time}}",
                fn: (m)=>`${moment().format('MMMM Do YYYY, h:mm:ss a')}`
            },
        ]
    },
    {
        label: "Member",
        scope: "direct",
        tags: [
            {
                label: "Name",
                subtitle: "{{member.name}}",
                fn: (m)=>m?.name
            },
            {
                label: "First Name",
                subtitle: "{{member.first_name}}",
                fn: (m)=>m?.about?.first_name
            },
            {
                label: "Last Name",
                subtitle: "{{member.last_name}}",
                fn: (m)=>m?.about?.last_name
            },
            {
                label: "Account Email",
                subtitle: "{{member.account_email}}",
                fn: (m)=>m?.account_email
            },
            {
                label: "Phone",
                subtitle: "{{member.phone}}",
                fn: (m)=>m?.contact?.phone
            },
        ]
    },
    {
        label: "Sender",
        scope: "direct",
        tags: [
            {
                label: "Name",
                subtitle: "{{sender.name}}",
                fn: (m,c,s)=>s.name
            },
            {
                label: "First Name",
                subtitle: "{{sender.first_name}}",
                fn: (m,c,s)=>s?.about?.first_name
            },
            {
                label: "Last Name",
                subtitle: "{{sender.last_name}}",
                fn: (m,c,s)=>s?.about?.last_name
            },
            {
                label: "Account Email",
                subtitle: "{{sender.account_email}}",
                fn: (m,c,s)=>s?.account_email
            },
            {
                label: "Phone",
                subtitle: "{{sender.phone}}",
                fn: (m,c,s)=>s?.contact?.phone
            },
        ]
    },
    {
        label: "Community Info",
        scope: "*",
        tags: [
            {
                label: "Name",
                subtitle: "{{community.name}}",
                fn: (m,c)=>c?.name
            }
        ]
    }
];