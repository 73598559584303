import React from 'react';
import {SimplePageGridDivider} from "../../app/community/group/simple-page-divider";
import {EntityHeader, SectionHeader} from "../components/core";

function Topbar() {
    return <div className="h-12 unaty-frame fixed top-0 left-0 right-0 animate-pulse" style={{padding: 0}}>
        <div/>
        <div className="hidden sm:flex px-1.5 space-x-2 items-center">
            <div>
                <div style={{height: '34px', width: '34px'}} className="bg-gray-300 rounded-md">

                </div>
            </div>
            <div>
                <div className="hidden lg:block">
                    <div className="h-9 w-96 bg-gray-200 rounded-md"/>
                </div>
            </div>
        </div>
        <div className="flex-grow hidden sm:flex pr-3 space-x-2 items-center">
            <div className="flex-grow">
                <div className="show-1250 hidden">
                    <div className="h-9 w-1/2 bg-gray-200 rounded-md"/>
                </div>
            </div>
            <div className="flex items-center space-x-3">
                <div className="w-6 h-6 rounded-full bg-gray-200"/>
                <div className="w-6 h-6 rounded-full bg-gray-200"/>
                <div className="w-32 h-8 rounded-md bg-gray-200"/>
            </div>

        </div>
        <div/>
    </div>
}

function Sidebar() {
    return <div className="hidden-550">
        <div className="pt-4 space-y-3">
            <div className="flex space-x-2 pl-3">
                <div>
                    <div className="w-5 h-5 rounded-full bg-gray-200"/>
                </div>
                <div>
                    <div className="w-24 h-5 rounded-md hidden-1250 bg-gray-200"/>
                </div>
            </div>

            <div className="flex space-x-2 pl-3">
                <div>
                    <div className="w-5 h-5 rounded-full bg-gray-200"/>
                </div>
                <div>
                    <div className="w-32 h-5 rounded-md hidden-1250 bg-gray-200"/>
                </div>
            </div>
        </div>
    </div>
}

function Main() {

    return <SimplePageGridDivider sidebar={<div className="pr-3">
        <div className={"w-full h-40 bg-gray-100 rounded-lg"}>

        </div>
    </div>}>
        <div className="pt-2 pl-3">
            <div className="p-2">
                <div className="bg-gray-400 h-5 w-48 rounded"/>
            </div>
        </div>
    </SimplePageGridDivider>
}

export function AppFrameLoading() {

    return <div className="fixed z-9999 top-0 right-0 bottom-0 bg-white left-0">
        <Topbar/>
        <div className={"unaty-body unaty-frame top-padded animate-pulse"}>
            <div className='sidebar-bkg'/>
            <Sidebar/>

            <Main/>
            <div className='sidebar-bkg'/>
        </div>
    </div>
}