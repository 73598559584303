import React, {useContext} from 'react';
import {MembershipCardDesigner} from "../../../../../../library/components/reorganize/membership-card-designer";
import {GlobalContext} from "../../../../../global/global-context";
import {CommunityContext} from "../../../../community-context";

function getDefaults(community) {
    if(community.data.member_card_design) {
        return community.data.member_card_design;
    }
    return {
        background: {
            type: 'color',
            color: community.data.branding.color,
            image: ''
        }
    }
}

export function CADMembershipCard() {
    const global = useContext(GlobalContext);
    const community = useContext(CommunityContext);

    const preview = {
        community: {
            logo: community.data.profile_picture?`${community.data.profile_picture}_small?alt=media`:"",
            name: community.data.name
        },

        member: {
            name: community.member.name,
            member_type: community.member_types[community.member.member_type].singular,
            member_id: community.member_id,
            joined_year: '2012'
        }
    };

    const member_card = getDefaults(community);

    return <MembershipCardDesigner updateCommunityData={global.updateCommunityData} community={community} current={member_card} preview={preview} />
}