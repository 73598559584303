import React, {useState} from 'react';
import {SelectInterests} from "../../../../app/community/interests/select-interests";
import {Button} from "../../core";
import Modal from "../../old/modal";

export function InterestSelectorModal({open,max=3,initial,onClose,onSave}) {
    const [selected,setSelected] = useState(initial);
    return <Modal size="large" open={open} onClose={()=>onClose()} title={`Select up to ${max} interests`}
                  body={<SelectInterests onSelect={(s)=>setSelected(s)} initial={initial} max={max} />}
                  footerLeft={null}
                  footerRight={<div className="space-x-2">
                      <Button onClick={()=>onClose()} text="Cancel" />
                      <Button onClick={()=>{onSave(selected)}} text="Save" intent="success"/>
                  </div>} />
}