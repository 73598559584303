import React, {useContext} from 'react';
import {GlobalContext} from "../global/global-context";

import {EntityContext} from "./entity-context";
import {CommunityWrapper} from "../community/community-wrapper";
import {FullScreenLoader} from "../../library/components/old/full-screen-loader";
import {NotFoundPage} from "../global/not-found";

export function EntityRouter(props) {
    const global = useContext(GlobalContext);
    const entity = useContext(EntityContext);
    const { data, handle, not_found } = entity;

    if(!data&&!not_found) {
        return <FullScreenLoader />;
    }

    if(not_found) {
        return <NotFoundPage/>
    }

    if(data.type==='community') {
        return <CommunityWrapper community_uid={data.community_uid} {...props} />;
    } else if(data.type==='user') {
        return <div>
            user!
        </div>;
    }

    return <FullScreenLoader />;
}