import React from 'react';
import {CommonLayoutProfile} from "../page/profile";

import styled from "styled-components";
import {SimplePageGridDivider} from "../../../../app/community/group/simple-page-divider";

const Card = styled.div`
  
`;

const ProfilePicture = styled.div`

    background-position: center;
    background-size: cover;
  position: absolute;
  z-index: 15;
  bottom: -56px;
  left: 0.75rem;
  height: 138px;
  width: 138px;
    background-color: rgb(227, 232, 237);
    border-radius: 42%;
    border: 5px solid #fff;
`;

const Cover = styled.div`
    background-size: cover;
    background-color: rgb(227, 232, 237);
    height: 200px;
`;

const Content = styled.div`
height: 200px;
position: relative;
`;

const Title = styled.div`
  position: absolute;
  height: 18px;
  background: #7d7f82;
  width: 40%;
  left: 12px;
  top: 68px;
  border-radius: 3px;
`;

const Description = styled.div`
position: absolute;
    height: 14px;
    background: #cbd1d6;
    width: 30%;
    left: 12px;
    top: 94px;
    border-radius: 4px;
`;

export default function ProfileLoadingCard({
    type = 'member'
                                           }) {

    return <SimplePageGridDivider>

        <div className="h-12 pl-3 pt-3 pb-1.5">
            <div className="h-6 flex items-center">
            <div className="h-3 w-24 rounded-md bg-gray-300" />
            </div>
        </div>
        <Card className="relative">
            <div className="relative">
            <Cover className='rounded-xl' />
            {type==='member'&&<ProfilePicture />}
            </div>
            <Content>
                <Title />
                <Description />
            </Content>
        </Card>

    </SimplePageGridDivider>
}