import React, {Component, useState} from "react";
import styled from "styled-components";
import {TableHeader} from "./header";
import {FirebaseDB} from "../../../../config/setup-firestore";

import {TableFooter} from "./footer";
import {TableBody} from "./body";
import {BodyRow, HeaderRow} from "./table/row";
import {CheckboxCell} from "./checkboxes";
import {BodyCell, HeaderCell} from "./cell";
import {InlineLoader} from "../../core";


const Frame = styled.div`
  
`;

const EmptyBody = styled.div`
     padding: 24px;
     display: flex;
     align-items: center;
     justify-content: center;
`;

const EmptyText = styled.div`
   font-weight: 500;
   font-size: 18px;
`;


function getGrid(fields, can_select, has_actions) {
    let str = '';
    if(can_select) {
        str += '24px ';
    }
    for(let i=0;i<fields.length;i++) {
        const f = fields[i];
        str += ` ${f.width} `;
    }
    if(has_actions) {
        str += ' 32px';
    }
    return str;
}

export default class RichList extends Component {
    constructor(props) {
        super(props);

        this.state = {
            options: props.options,

            loading: props.options.need_to_load_data,
            data_ids: [],
            data_info: {},
            page: 1,

            max_count: 0,
            page_count: 0,

            more_available: false,

            search_query: '',
            empty: false,

            filter: {},
            sort: {},

            selected: []
        };

        this.start_refs = {
            // 1: DOCREF
        };
    }

    componentDidMount() {
        const {dispatch, match, options} = this.props;
        const {loading} = this.state;
        if (loading) {
            // initial load
            this.getItems();
        }
    }

    getItems() {
        const {options} = this.props;
        const { page, max_count } = this.state;
        const {query} = this.state.options;

        const where1 = query.where[0];

        const { default_sort } = options;

        if (page===1||page===0) {
            // page 0
            if(where1) {
                FirebaseDB
                    .collection(query.parent)
                    .doc(query.parent_id)
                    .collection(query.collection)
                    .where(where1.field, where1.operator, where1.value)
                    .orderBy(default_sort.field, default_sort.dir)
                    .limit(options.per_page)
                    .get()
                    .then(this.handleRes.bind(this, page, options, max_count))
            } else {
                FirebaseDB
                    .collection(query.parent)
                    .doc(query.parent_id)
                    .collection(query.collection)
                    .orderBy(default_sort.field, default_sort.dir)
                    .limit(options.per_page)
                    .get()
                    .then(this.handleRes.bind(this, page, options, max_count))
            }
        } else {
            if(where1) {
                FirebaseDB
                    .collection(query.parent)
                    .doc(query.parent_id)
                    .collection(query.collection)
                    .where(where1.field, where1.operator, where1.value)
                    .orderBy(default_sort.field, default_sort.dir)
                    .startAfter(this.start_refs[page-1])
                    .limit(options.per_page)
                    .get()
                    .then(this.handleRes.bind(this, page, options, max_count))
            } else {
                FirebaseDB
                    .collection(query.parent)
                    .doc(query.parent_id)
                    .collection(query.collection)
                    // .where(where1.field, where1.operator, where1.value)
                    .orderBy(default_sort.field, default_sort.dir)
                    .startAfter(this.start_refs[page-1])
                    .limit(options.per_page)
                    .get()
                    .then(this.handleRes.bind(this, page, options, max_count))
            }

        }
    }

    handleRes = (page, options, max_count, snap) => {
        if (page===1) {
            if (snap.empty) {
                this.start_refs[page] = null;
                // should go back a page
                this.setState({
                    max_count: (page-1)*options.per_page,
                });
                this.back();
            } else {
                let data_info = this.state.data_info;
                let data_ids = [];
                const docs = snap.docs;

                for (let i = 0; i < docs.length; i++) {
                    const doc = docs[i];
                    data_ids.push(doc.id);
                    data_info[doc.id] = doc.data();
                }

                this.start_refs[page] = snap.docs[snap.docs.length - 1];

                let new_max =((page-1)*options.per_page) + data_ids.length;

                this.setState({
                    loading: false,
                    data_info,
                    page_count: data_ids.length,
                    data_ids,
                    max_count: new_max > max_count ? new_max : max_count,
                    more_available: data_ids.length === options.per_page
                })
            }
        } else {
            if (snap.empty) {
                this.start_refs[page] = null;
                this.setState({
                    max_count: max_count,
                    page_count: 1,
                    more_available: false,
                    empty: true,
                    loading: false
                })
            } else {
                let data_info = this.state.data_info;
                let data_ids = [];
                const docs = snap.docs;

                for (let i = 0; i < docs.length; i++) {
                    const doc = docs[i];
                    data_ids.push(doc.id);
                    data_info[doc.id] = doc.data();
                }

                this.start_refs[page] = snap.docs[snap.docs.length - 1];

                let new_max = data_ids.length;

                this.setState({
                    loading: false,
                    page_count: new_max,
                    data_info,
                    data_ids,
                    max_count: new_max > max_count ? new_max : max_count,
                    more_available: data_ids.length === options.per_page
                })
            }
        }
    }

    back() {
        this.setState({
            page: this.state.page-1,
            loading: true
        },()=>{this.getItems()})
    }

    next() {
        this.setState({
            page: this.state.page+1,
            loading: true
        },()=>{this.getItems()})
    }

    handleToggle(id, e) {
        e.stopPropagation();

        let s = this.state.selected.slice();
        const index = s.indexOf(id);

        if (index > -1) {
            // remove
            s.splice(index, 1);
        } else {
            // add
            s.push(id);
        }

        this.setState({
            selected: s
        })
    }

    toggleAll() {
        let s = this.state.selected;
        if (s.length > 0) {
            this.setState({
                selected: []
            })
        } else {
            this.setState({
                selected: this.state.data_ids
            })
        }
    }

    render() {
        const {loading, empty, page, options, page_count, more_available, max_count, selected} = this.state;
        const {history} = this.props;

        const {allow_selection, scroll_style = 'infinite', link_suffix='', data_loading, link_base, link_id_field, renderer, need_to_load_data = true} = options;

        const grid_columns = getGrid(renderer.columns, allow_selection, true);

        let contents = <EmptyBody>

        </EmptyBody>;

        if (loading) {
            contents = <EmptyBody>
              <InlineLoader />
            </EmptyBody>
        }

        if (empty) {
            contents = <EmptyBody>
                <EmptyText>No content found</EmptyText>
            </EmptyBody>
        }

        let go_to_item = () => {
        };

        if (!need_to_load_data) {
            // the data is being provided
            const all_ids = this.props.options.ids;
            const all_data = this.props.options.data;
            contents = all_ids.map((cid) => {
                const item_data = all_data[cid];
                if (!item_data) {
                    return null;
                }
                go_to_item = () => {
                    if(link_id_field==='docid') {
                        history.push(`${link_base}/${cid}`)
                    } else {
                        history.push(`${link_base}/${item_data[link_id_field]}${link_suffix}`)
                    }
                };

                return <BodyRow key={cid} onClick={go_to_item} style={{gridTemplateColumns: grid_columns}}>
                    {allow_selection&&<CheckboxCell checked={selected.includes(cid)} onClick={this.handleToggle.bind(this, cid)} />}
                    {renderer.columns.map(f => {
                        const value = item_data[f.field];
                        return <BodyCell ctx={this.props.ctx} type={f.type} key={f.name} value={value}/>
                    })}
                </BodyRow>;
            })
        } else {
            const {data_ids, data_info} = this.state;
            contents = data_ids.map((data_id) => {
                const item_data = data_info[data_id];
                if (!item_data) {
                    return null;
                }
                if (link_id_field === 'docid') {
                    go_to_item = () => {
                        history.push(`${link_base}/${data_id}`)
                    };
                } else {
                    go_to_item = () => {
                        history.push(`${link_base}/${item_data[link_id_field]}${link_suffix}`)
                    };
                }

                //   <RowActions/>
                return <BodyRow key={data_id} onClick={go_to_item} style={{gridTemplateColumns: grid_columns}}>
                    {allow_selection&&<CheckboxCell checked={selected.includes(data_id)} onClick={this.handleToggle.bind(this, data_id)} />}
                    {renderer.columns.map(f => {
                        const value = item_data[f.field];
                        return <BodyCell ctx={this.props.ctx} type={f.type} key={f.text} value={value}/>
                    })}

                </BodyRow>;
            })
        }

        const root_count = ((page-1)*options.per_page+1);

        let pagination_props;

        if(need_to_load_data) {
            pagination_props = {
                back: this.back.bind(this),
                next: this.next.bind(this),
                allow_next: more_available,
                allow_back: page!==1,
                root: root_count,
                root_to: (options.per_page * (page-1)) + page_count,

                total: max_count
            };
        } else {
            const total_count = this.props.options.ids.length;
            const total_pages = Math.ceil(total_count/options.per_page);
            pagination_props = {
                back: this.back.bind(this),
                next: this.next.bind(this),
                allow_next: page<total_pages,
                allow_back: page!==1,
                root: root_count,
                root_to: total_count-(options.per_page*(page-1)),

                total: this.props.options.ids.length
            };
        }

        return (<Frame>
                <TableHeader >
                    <HeaderRow style={{gridTemplateColumns: grid_columns}}>
                        {allow_selection&&<CheckboxCell isHeader indeterminate={selected.length>0} onClick={this.toggleAll.bind(this)} />}
                        {renderer.columns.map(f => {
                            return <HeaderCell key={f.text} {...f} />
                        })}
                    </HeaderRow>
                </TableHeader>
                <TableBody>

                    {loading && <EmptyBody>
                        <InlineLoader />
                    </EmptyBody>}
                    {!loading&&contents}
                </TableBody>
            {scroll_style!=='infinite'&&<TableFooter pagination={pagination_props} />}

            </Frame>
        );
    }
}
