import React, {useState} from "react";
import {OnboardingActions, OnboardingTitle} from "../../components";
import {OnboardingHelpSection, OnboardingHelpToggle} from "../../components/help";
import {useIsMobile} from "../../../../../../app/global/global-router";

const mock = {
    title: 'Example large title',
    subtitle: 'This is an example subtitle'
};

export function OnboardingTemplateFeatured({
                                               title = mock.title,
                                               onConfirm = () => {
                                               },
                                               confirm_text = '',
                                               fns = {},
                                               featured_component = null,
                                               help = null,
                                               component = null,
                                               onCancel = () => {
                                               },
                                               cancel_text = '',
                                               subtitle = mock.subtitle
                                           }) {
    const [show_help,setShowHelp] = useState(false);
    const is_mobile = useIsMobile();
    return <div className="relative flex flex-col">
        <div className="flex-none" style={{height: is_mobile?"240px":'300px'}}>
            {featured_component}
        </div>
        <div className="px-8 pb-8 flex-grow pt-4 flex flex-col">
            <div className="flex-grow">
                <OnboardingTitle title={title} subtitle={subtitle}/>
            </div>
            {component && <div className="flex-grow">
                {component}
            </div>}
            <div className="pt-4">
                <OnboardingActions onConfirm={() => onConfirm({}, fns)} confirm_text={confirm_text}
                                   cancel_text={cancel_text} onCancel={() => onCancel({}, fns)}/>
            </div>
        </div>
        {show_help&&<OnboardingHelpSection comp={help} />}
        {help&&<OnboardingHelpToggle on={show_help} onClick={()=>setShowHelp(!show_help)} />}
    </div>
}