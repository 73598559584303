import React from 'react';
import styled from "styled-components";
import {Link} from "react-router-dom";

const Container = styled.div`
    max-width: 768px;
    padding: 0 24px;
    margin: 0 auto;
    text-align: center;
    padding-top: 10vh;
`;

const Title = styled.div`
        font-size: 28px;
    font-weight: 600;
    margin-bottom: 40px;
`;

const Desc = styled.div`
        font-size: 18px;
        max-width: 500px;
    line-height: 1.4;
    margin: 0 auto;
`;

export function NotFoundPage() {

    return <Container>
        <Title className='text-gray-900'>
            Uh oh. That page doesn’t exist.
        </Title>

        <Desc className='text-gray-700'>
            Head to our <Link to="/" className="color-link">homepage</Link> that does exist, or ask for help with and shoot us a
            message at <a className="color-link" href="mailto:hey@getunaty.com">hey@getunaty.com</a>.
        </Desc>
    </Container>
}