import React, {useState} from 'react';
import {authFetch, imageResizeRequest} from "../../../config/network";
import {Button} from "../../components/core";

export function TestUploader() {
const [file,setFile] = useState(null);
    const fileRef = React.createRef();

    function selectFile() {
        fileRef.current.click();
    }

    async function uploadImage() {
        const res = () => {};
        const payload = {

        };
        imageResizeRequest(file, res, "POST");
    }

    return <div>
        <input onChange={(e)=>setFile(e.target.files[0])} ref={fileRef} multiple={false} type="file"
               accept="image/jpg"/>
        <Button onClick={()=>uploadImage()} disabled={!file} text="Upload" />
    </div>
}