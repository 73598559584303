import React from 'react';
import styled from "styled-components";
import {FPHeader} from "./header";
import {FPPreviewer} from "./previewer";
import {SimpleOverlay} from "../../simple-overlay";
import {useIsMobile} from "../../../../app/global/global-router";

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  position: relative;
`;

const Wrapper = styled.div`
       box-shadow: 0 0 0 1px rgba(0,0,0,0.05),0 1px 3px 0 rgba(0,0,0,0.1),0 1px 2px 0 rgba(0,0,0,0.06) !important;
       background-color: #fff;
    margin: 0 auto;
       display: grid;
  max-width: ${props=>props.is_mobile?"":"1600px"};
       grid-template-rows:60px 1fr;
       > div:nth-child(2) {
           max-height: ${props=>props.is_mobile?"":"calc(100vh - 48px - 60px)"};
    overflow: hidden;
    > div {
        height: 100%;
    }
       }
`;

const mock2 = {
    name: 'File name for real.png',
    url: 'https://firebasestorage.googleapis.com/v0/b/unaty-prod.appspot.com/o/files%2FqKE5EUiLwxrAcOE4OusA%2Ffolder-folderId%2FAEhVHkdUH?alt=media',
    meta: {
        ext: '.pdf',
        type: 'application/pdf',
        size: 124355
    },
    updated_by: '12345',
    updated_at: Date.now()
};

const mock = {
    name: 'File name for real.png',
    url: 'https://firebasestorage.googleapis.com/v0/b/unaty-prod.appspot.com/o/files%2FqKE5EUiLwxrAcOE4OusA%2Ffolder-folderId%2FAG7efhSpNx?alt=media',
    meta: {
        ext: '.png',
        type: 'powerpoint',
        size: 124355
    },
    updated_by: '12345',
    updated_at: Date.now()
};


export function FilePreview({data=mock,handleFileChange,can_replace,onRename,onMove,onDelete,nav={prev:null,next:null},children,context,onClose}) {
    const is_mobile = useIsMobile();
    let options = [
        (onRename&& {
            type: 'item',
            title: 'Rename File',
            onClick: ()=>onRename()
        }),
        (onMove&& {
            type: 'item',
            title: 'Move File',
            onClick: ()=>onMove()
        }),
        (onDelete&&{
            type: 'item',
            intent: 'danger',
            title: 'Delete File',
            onClick: ()=>onDelete()
        })
    ];

    return <SimpleOverlay isOpen lazy usePortal onClose={() => onClose()} canEscapeKeyClose={true}
                 canOutsideClickClose={false} portalClassName='file-preview-portal' hasBackdrop>
            <Container is_mobile={is_mobile}>
                <Wrapper is_mobile={is_mobile} className={is_mobile?`absolute top-0 left-0 right-0 bottom-0`:`absolute top-6 left-6 right-6 bottom-6 rounded-md`}>
                    <FPHeader is_mobile={is_mobile} editName={()=>onRename()} handleFileChange={handleFileChange} can_replace={can_replace} data={data} context={context} options={options} closeModal={()=>onClose()} />
                    <FPPreviewer is_mobile={is_mobile} nav={nav} {...data} />

                </Wrapper>
                {children}
            </Container>
        </SimpleOverlay>
}