import React from 'react';

function getClasses(size) {
    if(size==='xl') {
        return `w-10 h-10 leading-8 text-2xl items-center`;
    } else if(size==='sm') {
        return `w-6 h-6 leading-6 text-sm`;
    } else if(size==='md') {
        return `w-6 h-6 leading-6 text-sm`;
    } else {
        return `w-5 h-5 leading-5 text-xs`;
    }
}

export function EntityIcon({initial="",shadow=false,size="xl",color="#a1a1aa"}) {

    return <div style={{background:color?color:"#a1a1aa"}} className={`${getClasses(size)} ${shadow?"shadow-sm":""} font-semibold pointer-events-none inline-flex justify-center ${color==='#ffffff'?"text-gray-600":"text-white"} rounded-md`}>
        {initial}
    </div>
}