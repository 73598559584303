import React, {useCallback, useEffect, useRef, useState} from 'react';
import {FirebaseDB} from "../../../config/setup-firestore";
import {Button, InlineLoader, Input} from "../../components/core";
import {memoize} from 'lodash'

function Query({query}) {
    return <div>
        `<pre className="text-xs p-2 bg-gray-100 border-gray-300 rounded-md overflow-y-scroll text-left"
             style={{maxHeight: '200px'}}>{JSON.stringify(query, undefined, 2)}</pre>`
    </div>
}

const q = {
    where: [
        {
            field: "age",
            operator: "==",
            value: 30
        },
        {
            field: "_tags.archived=true",
            operator: "==",
            value: true
        },
        {
            field: "_tags.group_id=3",
            operator: "==",
            value: true
        },
        {
            field: "_tags.member_type=2345",
            operator: "==",
            value: true
        }
    ],
    sort_by: {
        field: 'about.last_name',
        dir: 'asc'
    },
    limit: 50
};

async function getMembers(cid, query) {
    let base = FirebaseDB
        .collection('community_members')
        .doc(cid)
        .collection('members');

    q.where.forEach(p => {
        base = base
            .where(p.field, p.operator, p.value);
    })

    /*
    base = base
        .orderBy(query.sort_by.field,query.sort_by.dir);


     */
    base = base
        .limit(query.limit);

    return await base
        .get()
        .then(snap => {
            return snap.docs.map(d => {
                return {
                    ...d.data(),
                    id: d.id
                }
            })
        })
}

async function callSearchAPI() {
    const result = await FirebaseDB
        .collection('searches').add({
            request: {
                index: 'users',
                type: 'users',
                q: 'John' // Shorthand query syntax
            },
            response: null
        })
    result.onSnapshot(doc => {
        if (doc.response !== null) {
            // Do things
        }
    })
}

async function waitForResult(ref) {
    return new Promise((resolve, reject) => {
        ref.onSnapshot(doc => {
            console.log("SNAP", doc.data())
            if (doc.data().response !== null) {
                // Do things
                resolve(doc.data());
            }
        });
    });
}


// j5iJqzs9AxLC25ICzcZL
// qKE5EUiLwxrAcOE4OusA
export const requestData = memoize(async (query,community_uid) => {
    const ref = await FirebaseDB
        .collection('searches')
        .add({
            type: 'members',
            community_uid: community_uid,
            request: {
                result_fields: {
                    name: {
                        raw: {}
                    },
                    handle: {
                        raw: {}
                    },
                    bio: {
                        raw: {}
                    },
                },
                search_fields: {
                    name: {},
                    email: {},
                    handle: {}
                },
                query: query
            },
            response: null
        });

    return await waitForResult(ref);
});

export function AsyncSearchBar({
                            onSearchSubmit = () => {
                            },
                            changeSearch = () => {
                            },
                            clearResults = () => {
                            }
                        }) {
    const [term, setTerm] = useState('');
    const [debouncedTerm, setDebouncedTerm] = useState(term);

    useEffect(() => {
        const timer = setTimeout(() => setTerm(debouncedTerm), 200);
        return () => clearTimeout(timer);
    }, [debouncedTerm])

    useEffect(() => {
        if (term) {
            onSearchSubmit(term);
        } else {
            clearResults();
        }
    }, [term]);

    return (
        <div>
            <input
                className='searchbar-input'
                type='text'
                placeholder="Search.."
                onChange={e => {
                    const v = e.target.value;
                    setDebouncedTerm(v);
                    changeSearch(v);
                }}
                value={debouncedTerm}/>
        </div>
    );
}

export function PlaygroundQueryTesting() {
    const [community_uid, setCommunityUid] = useState("qKE5EUiLwxrAcOE4OusA");
    const [data, setData] = useState(null);
    const [query, setQuery] = useState("");
    const all_results = useRef({});
    const _term = useRef("");
    const [loading, setLoading] = useState(false);

    const onSearchSubmit = useCallback(async term => {

        _term.current = term;
        const results = await requestData(term.toLowerCase().trim(),community_uid);
        all_results.current[term] = results;
        setData(results);
        setLoading(false);
    }, [community_uid]);

    const clearResults = useCallback(() => setData([]), []);

    const is_loading = (loading || !all_results.current[_term.current]) && Object.keys(all_results.current).length > 0;

    return <div className="h-96">
        <AsyncSearchBar changeSearch={(t) => {
            if (t) {
                all_results.current[t] = null;
                setLoading(true)
            }
        }} clearResults={clearResults} onSearchSubmit={onSearchSubmit}/>
        <div>
            {is_loading && <InlineLoader/>}
        </div>
        <div>{data && data.response && data.response.results && data.response.results.map(result=>{
            return <div key={result.id}>
                {result.name} (score: {result.score})
            </div>
        })}</div>
        <br/>
        <br/>
        <div>
            Community ID:<br/>
            <Input value={community_uid} onChange={v=>setCommunityUid(v)} /><br/>
            <br/>Sandbox=j5iJqzs9AxLC25ICzcZL<br/>Isaria=qKE5EUiLwxrAcOE4OusA
        </div>
        <br/>
        <br/>

        <div>{JSON.stringify({loading, all_results: all_results.current})}</div>
    </div>
}