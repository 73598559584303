import React, {useContext, useState} from "react";
import {Button} from "../core";
import {AdjustmentsIcon, StarIcon, UsersIcon} from "@heroicons/react/solid";
import {CIAdmin} from "../../icons/custom/Admin";
import {Popover2} from "@blueprintjs/popover2";
import {CommunityAccessManager} from "../community-access-manager";
import {CommunityContext} from "../../../app/community/community-context";
import {authFetch} from "../../../config/network";
import {GlobalContext} from "../../../app/global/global-context";
import ModeratorIcon from "../old/moderator-icon";
import {MODERATOR_COLOR} from "../../../config/defaults";

const mock = [
    {
        field: 'add_members',
        title: 'Create Members',
        subtitle: 'Create new community members and send them an invite.'
    },
    {
        field: 'manage_members',
        title: 'Manage Members',
        subtitle: 'Edit member profile data for all members.'
    },
    {
        field: 'manage_roles',
        title: 'Manage Positions',
        subtitle: 'Create, edit, and assign member to positions.'
    },
    {
        field: 'create_spaces',
        title: 'Create Spaces',
        subtitle: 'Create new spaces in the community.'
    },
    {
        field: 'add_segments',
        title: 'Add Segments',
        subtitle: 'Create auto-updating segments based on member data.'
    }
];

const mock_access = {
    community: false,
    all_roles: false,
    admins: true,
    teams: {},
    members: {},
    roles: {},
    member_types: {}
};

function getGroupAccessInfo(access) {
    if(!access) {
        return 'group-members';
    }

    if (access.community) {
        return 'all-members';
    }

    let a = access && access.teams ? access : {teams:{},member_types:{},roles:{},members:{}};
    const tl = Object.keys(a.teams).length, rl = Object.keys(a.roles).length,
        mtl = Object.keys(a.member_types).length, ml = Object.keys(a.members).length;

    if (tl > 0 || rl > 0 || ml > 0 || mtl > 0) {
        return 'custom'
    }

    if(access.group_members) {
        return 'group-members'
    } else if(access.moderators&&access.all_roles) {
        return 'roles-and-mods'
    } else if(access.moderators) {
        return 'moderators';
    } else if(access.all_roles) {
        return 'admins-and-roles';
    }

    return 'admins-only';
}

function getAccessInfo(access,scope) {

    if(scope==='group') {
        return getGroupAccessInfo(access);
    }

    if(!access) {
        return 'all-members';
    }
    if (access.community) {
        return 'all-members';
    }

    let a = access && access.teams ? access : {teams:{},member_types:{},roles:{},members:{}};
    const tl = Object.keys(a.teams).length, rl = Object.keys(a.roles).length,
        mtl = Object.keys(a.member_types).length, ml = Object.keys(a.members).length;

    if (tl > 0 || rl > 0 || ml > 0 || mtl > 0) {
        return 'custom'
    }

    if(access.all_roles) {
        return 'admins-and-roles';
    }

    return 'admins-only';
}

function ManageAccessPopover({meta, scope='community', usePortal=false, types, access, updateAccess, handleSave}) {
    const content = <CommunityAccessManager updateAccess={updateAccess} {...meta} types={types} init_access={access}/>;
    return <Popover2 usePortal={usePortal} targetTagName='div' interactionKind='click' placement={'bottom-start'} onClose={() => handleSave()}
                     popoverClassName='minimal-popover my-0.5 w-96 rounded-md shadow-lg border border-gray-200 bg-white' minimal
                     content={content}>
        <AccessDisplayValue access={access} scope={scope}/>
    </Popover2>
}

function AccessDisplayValue({access = {...mock_access},scope}) {

    const type = getAccessInfo(access,scope);

    if (type === 'all-members') {
        return <Button text="All Members" icon={<UsersIcon/>}/>
    } else if(type==='group-members') {
        return <Button text="Group Members" icon={<UsersIcon/>}/>
    } else if(type==='roles-and-mods') {
        return <Button text="Mods & Roles" icon={<div className="flex">
            <ModeratorIcon fill={MODERATOR_COLOR} />
        </div>}/>
    } else if(type==='moderators') {
        return <Button text="Moderators" icon={<div className="flex">
            <ModeratorIcon fill={MODERATOR_COLOR} />
        </div>}/>
    } else if(type==='admins-and-roles') {
        return <Button text="Admins & Roles" icon={<StarIcon/>}/>
    } else if (type === 'custom') {
        return <Button text="Custom" icon={<AdjustmentsIcon/>}/>
    } else {
        return <Button text="Admins only" icon={<CIAdmin/>}/>
    }
}

export function getAccessMeta(community) {
    let reference = {
        member_types: {},
        teams: {},
        roles: {},
    }, suggestions = [];

    Object.entries(community.member_types).forEach(a => {
        reference.member_types[a[0]] = a[1];
        suggestions.push({
            type: 'member_types',
            id: a[0],
            label: a[1].plural
        });
    });

    Object.entries(community.all_roles).forEach(a => {
        reference.roles[a[0]] = a[1];
        suggestions.push({
            type: 'roles',
            id: a[0],
            label: a[1].name
        });
    });

    Object.entries(community.all_teams).forEach(a => {
        reference.teams[a[0]] = a[1];
        suggestions.push({
            type: 'teams',
            id: a[0],
            label: a[1].name
        });
    });

    return {
        reference,
        suggestions
    }
}

const community_types = [
    "admins",
    "all_roles",
    "teams",
    "roles",
    "members",
    "member_types",
    "community"
];

export function AccessBlock({field, cb=()=>{}, scope_id="", api="/community/settings/set-member-access", scope='community', usePortal=false, types=community_types, title, init_access, subtitle, meta}) {
    const community = useContext(CommunityContext);
    const global = useContext(GlobalContext);

    const [na, setNa] = useState({...init_access});
    const [changes_made,setChangesMade] = useState(false);

    function updateAccess(nai) {
        setNa({...nai});
        setChangesMade(true);
    }

    function handleSave() {

        if(!changes_made) {
            return;
        }
        const payload = {
            field: field.replace('access.',''),
            value: na,
            scope_id,
            scope,
            community_uid: community.uid,
            member_id: community.member_id
        };

        cb({field,value:na});

        const res = () => {
            global.addToast({
                text: 'Access updated',
                intent: 'success'
            });
            setChangesMade(false);
        };

       authFetch(api, res, res, "POST", {payload});
    }

    return <div className={`flex`}>
        <div className="flex-grow">
            <div className="text-sm text-gray-800 font-semibold">{title}</div>
            <div className="text-xs pt-0.5 text-gray-600">{subtitle}</div>
        </div>
        <div className="flex-none  flex items-center">
            <ManageAccessPopover scope={scope} usePortal={usePortal} types={types} handleSave={handleSave} updateAccess={updateAccess} meta={meta} access={na}/>
        </div>
    </div>
}

export function DelegatedAccessBlock({access_items = [...mock]}) {
    const community = useContext(CommunityContext);
    const meta = getAccessMeta(community);

    return <div className="space-y-2">
        {access_items.map(a => {
            const ia = community.access[a.field] ? {...community.access[a.field]} : {...mock_access};

            return <AccessBlock key={a.field}
        {...a}
                                types={community_types}
                         init_access={ia}
                         meta={meta}/>
        })}
    </div>
}