import React from 'react';
import {SectionHeader} from "../../../library/components/core";
import {
    AdjustmentsIcon,
    LinkIcon,
    PaperAirplaneIcon,
    TableIcon,
    UserAddIcon,
    UsersIcon
} from "@heroicons/react/outline";

function Item({label,icon,onClick}) {
    return <div onClick={onClick} className="py-1 mb-1 mr-1.5 flex space-x-1 items-center pl-1 pr-2 rounded-md hover:bg-gray-200 transition-colors cursor-pointer">
        <div className="h-svg-5 w-6 text-gray-600">{icon}</div>
        <div className="text-gray-600 text-sm font-medium">{label}</div>
    </div>
}

export function ManageGroupQuickActions({createMember,copyJoinLink,inviteMembers,manageAccess,exportMemberList,openGroupDirectory}) {
    const items = [
        {
            label: "Create Members",
            icon: <UserAddIcon />,
            onClick: ()=>createMember()
        },
        {
            label: "Export Member List",
            icon: <TableIcon />,
            onClick: ()=>exportMemberList()
        },
        {
            label: "Copy Join Link",
            icon: <LinkIcon />,
            onClick: ()=>copyJoinLink()
        },
        {
            label: "Group Directory",
            icon: <UsersIcon />,
            onClick: ()=>openGroupDirectory()
        },
        {
            label: "Invite existing Members",
            icon: <PaperAirplaneIcon />,
            onClick: ()=>inviteMembers()
        },
    ];
    return <div>
        <SectionHeader title="Manage Group" />
        <div className="flex flex-wrap px-1.5">
            {items.map(it=>{
                return <div key={it.label}>
                    <Item {...it} />
                </div>
            })}
        </div>
    </div>
}