import React from 'react';
import styled from "styled-components";
import {TrashIcon} from '@heroicons/react/solid';

import Select from "../../old/select";
import {ATAction} from "../components/shared";

const Wrapper = styled.div`
    display: grid;
    grid-template-columns: 1.2fr 1fr auto;
    grid-gap: 10px;
`;



function buildFields(arr) {
    return arr.map(item=>{
        return {
            value: item.field,
            text: item.label
        };
    })
}

function buildDirMap(arr) {
    let obj = {};

    for(let i=0;i<arr.length;i++) {
        obj[arr[i].field] = arr[i].options;
    }

    return obj;
}

function getItemFromArray(arr,prop,value) {
    for(let i=0;i<arr.length;i++) {
        if(arr[i][prop]===value) {
            return arr[i];
        }
    }
    return {};
}

function getExistingFieldPossibilities(can_change_to,all,current_field) {
    return [
        getItemFromArray(all,'value',current_field),
        ...can_change_to
    ];
}

export function ATSortItem({field,editItem,can_remove=true,change_options,onRemove,options,dir}) {


    const fields = buildFields(options);
    const change_fields = buildFields(change_options);
    const dirs = buildDirMap(options);

    function handleChange(f,v) {
        // if field chnages
        let item = {
            field, dir
        };
        item[f] = v;
        if(f==='field') {
            item.dir = dirs[v][0].value
        }
        editItem(item);
    }

    function handleDelete() {
        onRemove();
    }

    return <Wrapper>
        <div>
            <Select usePortal blank_option={false} placeholder='Sort Field' value={field} onChange={(f,val)=>{
                handleChange('field',val);
            }} options={getExistingFieldPossibilities(change_fields,fields,field)}/>
        </div>
        <div>
            <Select usePortal blank_option={false} placeholder='Direction' value={dir} onChange={(f,val)=>{
                handleChange('dir',val);
            }} options={dirs[field]}/>
        </div>
        <div>
            {can_remove&&<ATAction className='hover-opacity text-gray-700' onClick={()=>handleDelete()}>
                <TrashIcon/>
            </ATAction>}
        </div>
    </Wrapper>
}