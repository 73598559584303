import React from "react";
import {
    AdjustmentsIcon, BellIcon, CalendarIcon, ChartPieIcon, ClockIcon, CogIcon,
    ColorSwatchIcon, DocumentIcon, FolderIcon, GlobeIcon, HeartIcon,
    IdentificationIcon, LightningBoltIcon, ShieldCheckIcon,
    UserCircleIcon, UsersIcon
} from "@heroicons/react/solid";
import {MemberTypeIcon} from "../icon-lower-right-addon/special";

// contexts = user, member, admin, owner
export const us_all_navigation_routes = [
    {
        label: 'Community Designer',
        similar: ['Custom Fields','Profile Field'],
        context: 'admin',
        onClick: (h,ctx) => {
            h.push(`/${ctx.community_handle}/admin/settings/designer/member-profile`)
        },
        icon: {
            type: 'icon',
            icon: <ColorSwatchIcon />
        },
    },
    {
        label: 'Delegated Access',
        context: 'admin',
        onClick: (h,ctx) => {
            h.push(`/${ctx.community_handle}/admin/settings/delegated-access`)
        },
        icon: {
            type: 'icon',
            icon: <ShieldCheckIcon />
        },
    },
    {
        label: 'Member Types',
        context: 'admin',
        onClick: (h,ctx) => {
            h.push(`/${ctx.community_handle}/admin/settings/member-types`)
        },
        icon: {
            type: 'icon',
            icon: <MemberTypeIcon />
        },
    },
    {
        label: 'Memberships',
        context: 'user',
        onClick: (h,ctx) => {
            h.push(`/memberships`)
        },
        icon: {
            type: 'icon',
            icon: <IdentificationIcon />
        },
    },
    {
        label: 'My Profile',
        context: 'user',
        onClick: (h,ctx) => {
            h.push(`/${ctx.user_handle}`)
        },
        icon: {
            type: 'icon',
            icon: <UserCircleIcon />
        },
    },
    {
        label: 'Preferences',
        context: 'user',
        onClick: (h,ctx) => {
            h.push(`/preferences`)
        },
        icon: {
            type: 'icon',
            icon: <AdjustmentsIcon />
        },
    },
    {
        label: ['Preferences', 'Notifications'],
        context: 'user',
        onClick: (h,ctx) => {
            h.push(`/preferences/notifications`)
        },
        icon: {
            type: 'icon',
            icon: <BellIcon />
        },
    },
    {
        label: 'Policies',
        context: 'member',
        onClick: (h,ctx) => {
            h.push(`/${ctx.community_handle}/policies`)
        },
        icon: {
            type: 'icon',
            icon: <DocumentIcon />
        },
    },
    {
        label: 'Interests',
        context: 'member',
        onClick: (h,ctx) => {
            h.push(`/${ctx.community_handle}/explore/interests`)
        },

        icon: {
            type: 'icon',
            icon: <HeartIcon />
        },
    },
    {
        label: 'Drive',
        context: 'member',
        onClick: (h,ctx) => {
            h.push(`/${ctx.community_handle}/explore/drive`)
        },

        icon: {
            type: 'icon',
            icon: <FolderIcon />
        },
    },
    {
        label: 'My Profile',
        context: 'member',
        onClick: (h,ctx) => {
            h.push(`/${ctx.community_handle}/member/${ctx.member_handle}`)
        },

        icon: {
            type: 'icon',
            icon: <UserCircleIcon />
        },
    },
    {
        label: 'Calendar',
        context: 'member',
        onClick: (h,ctx) => {
            h.push(`/${ctx.community_handle}/calendar`)
        },
        icon: {
            type: 'icon',
            icon: <CalendarIcon />
        },
    },
    {
        label: 'Leaders',
        context: 'member',
        onClick: (h,ctx) => {
            h.push(`/${ctx.community_handle}/director/roles`)
        },
        icon: {
            type: 'icon',
            icon: <LightningBoltIcon />
        },
    },
    {
        label: 'Directory',
        similar: 'Members',
        context: 'member',
        onClick: (h,ctx) => {
            h.push(`/${ctx.community_handle}/directory`)
        },

        icon: {
            type: 'icon',
            icon: <UsersIcon />
        },
    },
    {
        label: 'Membership Card',
        context: 'admin',
        onClick: (h,ctx) => {
            h.push(`/${ctx.community_handle}/admin/settings/designer/membership-card`)
        },
        icon: {
            type: 'icon',
            icon: <IdentificationIcon />
        },
    },
    {
        label:[ 'Policies','Privacy Policy'],
        context: 'member',
        onClick: (h,ctx) => {
            h.push(`/${ctx.community_handle}/policies/privacy-policy`)
        },
        icon: {
            type: 'icon',
            icon: <DocumentIcon />
        },
    },
    {
        label: 'Segments',
        context: 'admin',
        onClick: (h,ctx) => {
            h.push(`/${ctx.community_handle}/admin/settings/member-segments`)
        },
        icon: {
            type: 'icon',
            icon: <ChartPieIcon />
        },
    },
    {
        label: 'Explore',
        context: 'user',
        onClick: (h,ctx) => {
            h.push(`/explore`)
        },
        icon: {
            type: 'icon',
            icon: <GlobeIcon />
        },
    },
    {
        label: 'Preferences',
        similar: 'Settings',
        context: 'admin',
        onClick: (h,ctx) => {
            h.push(`/${ctx.community_handle}/admin/settings`)
        },
        icon: {
            type: 'icon',
            icon: <AdjustmentsIcon />
        },
    },
    {
        label: ['Settings','Time & Date'],
        context: 'admin',
        onClick: (h,ctx) => {
            h.push(`/${ctx.community_handle}/admin/settings/about`)
        },
        icon: {
            type: 'icon',
            icon: <ClockIcon />
        },
    },
    {
        label: ['Settings','Member Defaults'],
        context: 'admin',
        onClick: (h,ctx) => {
            h.push(`/${ctx.community_handle}/admin/settings/defaults`)
        },
        icon: {
            type: 'icon',
            icon: <CogIcon />
        },
    },
    {
        label: ['Settings','Build Mode'],
        context: 'admin',
        onClick: (h,ctx) => {
            h.push(`/${ctx.community_handle}/admin/settings/build-mode`)
        },

        icon: {
            type: 'icon',
            icon: <CogIcon />
        },
    },
    {
        label: ['Settings','Teams Ranking'],
        context: 'admin',
        onClick: (h,ctx) => {
            h.push(`/${ctx.community_handle}/admin/preferences`)
        },
        icon: {
            type: 'icon',
            icon: <CogIcon />
        },
    },
];