import React from 'react';
import styled from "styled-components";
import {LockClosedIcon} from '@heroicons/react/solid';
const Frame = styled.div`
padding: 16px 22px 12px 22px;
`;

const Title = styled.div`
font-weight: 700;
    font-size: 28px;
`;

const Subtitle = styled.div`
    font-size: 16px;
    line-height: 1.4;
    margin-top: 2px;
`;

const Status = styled.div`
    font-size: 14px;
    line-height: 1.4;
    margin-top: 2px;
`;

const Lock = styled.span`
       display: inline-block;
    vertical-align: text-top;
   svg {
        height: 22px;
   }
`;

const LeaderIcon = ()=> {
    return <svg version="1.1" x="0" y="0" width="80" height="80" viewBox="0, 0, 80, 80">
        <g id="Layer_1">
            <path d="M40,-0 L40.407,0.007 C41.386,0.06 42.292,0.38 43.148,0.837 L72.339,17.69 C74.287,18.815 75.487,20.894 75.487,23.143 L75.487,56.85 C75.487,59.1 74.287,61.178 72.339,62.303 L43.148,79.156 C41.2,80.281 38.8,80.281 36.852,79.156 L7.661,62.303 C5.713,61.178 4.513,59.1 4.513,56.85 L4.513,23.143 C4.513,20.894 5.713,18.815 7.661,17.69 L36.852,0.837 C37.69,0.358 38.627,0.069 39.593,0.007 L40,-0 z M40,6.168 L39.596,6.175 C38.639,6.237 37.71,6.524 36.878,6.998 L12.984,20.794 C11.052,21.909 9.862,23.971 9.862,26.201 L9.862,53.792 C9.862,56.023 11.052,58.084 12.984,59.2 L36.878,72.995 C38.81,74.11 41.19,74.11 43.122,72.995 L67.016,59.2 C68.948,58.084 70.138,56.023 70.138,53.792 L70.138,26.201 C70.138,23.971 68.948,21.909 67.016,20.794 L43.122,6.998 C42.273,6.545 41.374,6.228 40.404,6.175 L40,6.168 z" fill="#FF8A3B"/>
            <path d="M40,9.453 L40.407,9.46 C41.386,9.514 42.292,9.833 43.148,10.29 L64.152,22.417 C66.1,23.542 67.301,25.621 67.301,27.87 L67.301,52.123 C67.301,54.373 66.1,56.452 64.152,57.577 L43.148,69.703 C41.2,70.828 38.8,70.828 36.852,69.703 L15.848,57.576 C13.9,56.452 12.699,54.373 12.699,52.123 L12.699,27.87 C12.699,25.621 13.9,23.542 15.848,22.417 L36.852,10.29 C37.69,9.812 38.627,9.523 39.593,9.46 L40,9.453 z M40,21.116 L34.255,32.757 L21.409,34.623 L30.704,43.684 L28.51,56.479 L40,50.438 L51.49,56.479 L49.296,43.684 L58.591,34.623 L45.745,32.757 L40,21.116 z" fill="#FF8A3B"/>
        </g>
    </svg>;
};

export const PrTitle = ({title='', subtitle, is_private=false, subtitle2}) => {
    return <Frame>
        <Title className='text-gray-900'>
            {title} {is_private&&<Lock className='text-gray-800'><LockClosedIcon /></Lock>}
        </Title>
        {subtitle&&<Subtitle className='text-gray-700'>
            {subtitle}
        </Subtitle>}
        {subtitle2&&<Status className='text-gray-600'>
            {subtitle2}
        </Status>}
    </Frame>
};