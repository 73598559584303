import React from 'react';
import classNames from 'classnames';
import styled from "styled-components";

const props = {
    minimal: false,
    intent: 'secondary',
    text: ''
};

const intents = ['primary', 'success', 'secondary', 'tertiary'];

const sizes = ['xs', 'sm', 'md', 'lg', 'xl'];

function getFontWeight(intent, minimal) {
    return (intent === '' || minimal) ? 'font-medium' : 'font-medium';
}

function getTextSize(size) {
    if(size==='sm') {
        return 'text-sm';
    } else if(size==='xs') {
        return 'text-xs';
    } else if(size==='lg') {
        return 'text-base';
    } else if(size==='xl') {
        return 'text-base';
    }
    return 'text-sm';
}

function getPadding(size,compact,no_padding) {
    let x = '';
    let y = '';
    if(no_padding) {

    } else if(size==='sm') {
        x = 'px-2.5';
        y = 'py-1.25';
    } else if(size==='xs') {
        x = 'px-2';
        y = 'py-0.5';
    } else if(size==='lg') {
        x = 'px-3';
        y = 'py-1.5';
    } else if(size==='xl') {
        x = 'px-6';
        y = 'py-3';
    } else {
        x = 'px-4';
        y = 'py-2';
    }
    if(compact) {
        x = 'px-2';
    }
   return `${x} ${y}`;
}

function getWidth(fill) {
    return fill ? 'w-full' : 'w-auto';
}

function getBkgColor(intent,disabled,minimal,active,inverted) {

    if(disabled) {
        return "bg-gray-100";
    }

    if(intent==='success') {
        if(minimal) {
            return `bg-transparent ${inverted?"hover:bg-success-700":"hover:bg-success-50"}`;
        }
        if(active) {
            return "bg-success-50 hover:bg-success-100"
        }
        return `bg-success hover:bg-success-700 active:bg-success-800`;
    } else if(intent==='secondary') {
        if(minimal) {
            return `bg-transparent ${inverted?"hover:bg-secondary-700":"hover:bg-secondary-50"}`;
        }
        if(active) {
            return "bg-secondary-50 hover:bg-secondary-100"
        }
        return `bg-secondary hover:bg-secondary-700 active:bg-secondary-800 `;
    } else if(intent==='manage') {
        if(minimal) {
            return `bg-transparent ${inverted?"hover:bg-dark-700":"hover:bg-dark-50"}`;
        }
        return `bg-dark hover:bg-dark-700 active:bg-dark-800 `;
    } else if(intent==='primary') {
        if(minimal) {
            return `bg-transparent ${inverted?"hover:bg-primary-700":"hover:bg-primary-50"}`;
        }
        if(active) {
            return "bg-primary-50 hover:bg-primary-100"
        }
        return `bg-primary  hover:bg-primary-700 active:bg-primary-800 `;
    } else if(intent==='warning') {
        if(minimal) {
            return `bg-transparent ${inverted?"hover:bg-yellow-700":"hover:bg-yellow-50"}`;
        }
        if(active) {
            return "bg-yellow-50 hover:bg-yellow-100"
        }
        return `bg-primary  hover:bg-primary-700 active:bg-primary-800 `;
    } else if(intent==='danger') {
        if(minimal) {
            return `bg-transparent ${inverted?"hover:bg-danger-700":"hover:bg-danger-50"}`;
        }
        if(active) {
            return "bg-danger-50 hover:bg-danger-100"
        }
        return `bg-danger  hover:bg-danger-700 active:bg-danger-800 `;
    } else if(intent==='selection') {
        if(minimal) {
            return `bg-transparent ${inverted?"hover:bg-selection-700":"hover:bg-selection-50"}`;
        }
        return `bg-selection hover:bg-selection active:bg-selection `;
    }
    if(minimal) {
        return `${active?"bg-gray-100 hover:bg-transparent":"bg-transparent hover:bg-gray-100"} hover:bg-gray-100`;
    }
    return "bg-white hover:bg-gray-100 active:bg-gray-200 ";
}

function getBorderColor(minimal,disabled,intent,inverted) {
    if(minimal&&!inverted) {
        return "border-transparent-imp";
    }
    if(disabled) {
        return "border-gray-100";
    }
    if(intent==='success') {
        return `border-success hover:border-success-700 active:border-success-800 `;
    } else if(intent==='secondary') {
        return `border-secondary hover:border-secondary-700 active:border-secondary-800 `;
    } else if(intent==='manage') {
        return `border-dark hover:border-dark-700 active:border-dark-800 `;
    } else if(intent==='primary') {
        return ` border-primary hover:border-primary-700 active:border-primary-800 `;
    } else if(intent==='danger') {
        return `border-danger hover:border-danger-700 active:border-danger-800 `;
    } else if(intent==='selection') {
        return `border-selection hover:border-selection active:border-selection `;
    }
    return "border-gray-300"
}

function getColor(intent,disabled,minimal,active,inverted) {
    if(disabled) {
        return 'text-gray-500';
    }
    if(intent==='success') {
        if(minimal) {
            return `text-success ${inverted?"hover:text-white":""}`;
        }
        return 'text-white';
    } else if(intent==='secondary') {
        if(minimal) {
            return `text-secondary ${inverted?"hover:text-white":""}`;
        }
        return 'text-white';
    } else if(intent==='warning') {
        if(minimal) {
            return `text-yellow-700 ${inverted?"hover:text-white":""}`;
        }
        return 'text-white';
    } else if(intent==='manage') {
        if(minimal) {
            return `text-dark ${inverted?"hover:text-white":""}`;
        }
        return 'text-white';
    } else if(intent==='primary') {
        if(minimal) {
            return `text-primary ${inverted?"hover:text-white":""}`;
        }
        return 'text-white';
    } else if(intent==='danger') {
        if(minimal) {
            return `text-danger ${inverted?"hover:text-white":""}`;
        }
        return 'text-white';
    } else if(intent==='selection') {
        if(minimal) {
            return `text-selection ${inverted?"hover:text-white":""}`;
        }
        return 'text-white';
    }
    if(minimal) {
        return `${active?"text-gray-700 hover:text-gray-800":"text-gray-600 hover:text-gray-800"}`;
    }
    return 'text-gray-700';
}

function getIconHeight(size,icon_only,compact) {
    if(size==='sm') {
        if(compact) {
            return `h-5 w-5 h-svg-5`
        } else if(icon_only) {
            return 'h-6 w-6 h-svg-6';
        }
        return 'h-4 w-4 h-svg-4';
    } else if(size==='xs') {
        if(icon_only) {
            return 'h-5 w-5 h-svg-5';
        }
        return 'h-4 w-4 h-svg-4';
    } else if(size==='lg') {
        return 'h-5 w-5 h-svg-5';
    } else if(size==='xl') {
        return 'h-6 w-6 h-svg-6';
    }
    return 'h-5 w-5 h-svg-5';
}

function getIconMargin(size,dir,has_text) {
    if(!has_text) {
        return '-ml-1.5 -mr-1.5';
    }
    if(size==='sm') {
        return dir === 'left' ? '-ml-1 mr-1.5' : '-mr-1 ml-1.5';
    } else if(size==='xs') {
        return dir === 'left' ? '-ml-1 mr-2' : '-mr-1 ml-2';
    } else if(size==='lg') {
        return dir === 'left' ? '-ml-1 mr-2' : '-mr-1 ml-2';
    } else if(size==='xl') {
        return dir === 'left' ? '-ml-1 mr-3' : '-mr-1 ml-2';
    }
    return dir === 'left' ? '-ml-1 mr-2' : '-mr-1 ml-2';
}

const Shortcut = styled.div`
       font-size: 12px;
       height: 19px;
       margin-right: 1px;
    text-align: center;
    font-weight: 500;
`;

function getStyle(size,text,icon,icon_left,height) {
    let s = {};

    if(size==='sm'&&!text&&(icon||icon_left)) {
        s.height = '32px';
        s.width = '32px';
    } else if(height) {
        s.height = height;
        if(!text&&(icon||icon_left)) {
            s.width = height;
        }
    }

    return s
}

export function Button({text, iconClassName='', hover_text, height, no_padding, inverted, className="", icon, active, loading, onClick=()=>{}, shortcut, compact, left_icon, right_icon, animate_up=false, size = 'sm', circular, intent = '', disabled, fill = false, minimal = false}) {

    let text_size = getTextSize(size);
    let font_weight = getFontWeight(intent, minimal);
    let padding = getPadding(size,compact,no_padding);
    let width = getWidth(fill);
    let color = getColor(intent,disabled,minimal,active,inverted);
    let bkg = getBkgColor(intent,disabled,minimal,active,inverted);
    const cursor = disabled ? "cursor-not-allowed" : loading ? "cursor-wait" : "cursor-pointer";
    const shadow = minimal ? "" : "shadow-sm";
    let border_radius = circular ? 'rounded-full' : 'rounded-lg';
    const border_color = getBorderColor(minimal,disabled,intent,inverted);

    const hidden = loading ? "opacity-0" : "";

    return <button style={getStyle(size,text,icon,left_icon,height)} onClick={(e)=>{if(!loading&&!disabled) {onClick(e);}}} disabled={disabled}
        className={classNames(text_size, disabled?'disabled':'', hover_text?"group":"", className, width, color, bkg, font_weight, padding, border_radius, "block real-outline-none relative transition-colors items-center transition-background select-none inline-flex justify-center border  ", border_color, cursor, shadow, animate_up?'transform transition-transform hover:-translate-y-0.5':'')}>
        {(left_icon||icon)&& !loading && <div className={classNames(getIconHeight(size,!text&&!shortcut,compact),color,hidden,"opacity-80","flex items-center",iconClassName,getIconMargin(size,'left',!!text))}>
       {left_icon&&!loading?left_icon:icon}
        </div>}
        <span className={`${hidden} ${hover_text?"group-hover:hidden":""}`}>
        {text}
        </span>
        {hover_text&&<span className="hidden group-hover:block">
            {hover_text}
        </span>}
        {right_icon&&!loading&&<div className={classNames(getIconHeight(size),color,"opacity-80","flex items-center",hidden,getIconMargin(size,'right',!!text))}>
            {right_icon}
        </div>}
        {shortcut&&<Shortcut className={classNames(" px-1 rounded ml-2 -mr-1",hidden,intent===""?"bg-gray-200":"bg-white bg-opacity-10")}>
            {shortcut}
        </Shortcut>}
        {loading&&<div className={classNames("absolute flex items-center justify-center top-0 left-2 right-2 bottom-0")}>
            <svg version="1.1" id="loader-1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                 width="24px" height="24px" viewBox="0 0 50 50" style={{enableBackground: "new 0 0 50 50",opacity:0.7}}>
                <path fill="currentColor" d="M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615c8.072,0,14.615,6.543,14.615,14.615H43.935z">
                    <animateTransform attributeType="xml"
                                      attributeName="transform"
                                      type="rotate"
                                      from="0 25 25"
                                      to="360 25 25"
                                      dur="0.6s"
                                      repeatCount="indefinite"/>
                </path>
            </svg>
        </div>}
    </button>
}
