import React, {useLayoutEffect, useState} from "react";
import {buildFinalSidebar, getSidebarBase} from "./helpers";
import {SidebarMainSection} from "./community-sidebar-main";
import {SidebarEntitiesList} from "./community-sidebar-entities";
import {ComposerWrapper} from "./composer-wrapper";
import {hexToRgbStr, setAlpha} from "../../../utilities/general/colors";
import {useIsMobile} from "../../../../app/global/global-router";

export default function AppSidebar(props) {
    const {
        context,
        bg,
        color = "#ffffff",
        community_handle,
        community_data,
        member_id,
        all_roles,
        starred_smart_views,
        all_teams,
        communities,
        member_type,
        history,
        is_member,
        groups,
        my_groups,
        teams,
        roles,
        is_admin,
        user,
        member,
        logged_in,
        theme,
        search_props,
        community_uid,
        community,
        handle,
        user_permissions
    } = props;
    const is_mobile = useIsMobile();
    const opposite = color === 'white' ? 'black' : 'white';
    const opposite_hex = opposite === 'white' ? '#ffffff' : '#000000';
    const border = setAlpha(hexToRgbStr(opposite_hex), color === 'white' ? 0.1 : 0.4);


    const sidebar_base = getSidebarBase(context, logged_in, {is_admin});

    const sidebar = buildFinalSidebar(sidebar_base, context, {
        community_handle,
        community_uid,
        starred_smart_views,
        member_id,
        is_member,
        all_teams,
        all_roles,
        groups,
        roles,
        my_groups,
        member,
        teams,
        handle,
        user_permissions
    });

    const composer = <ComposerWrapper/>;

    if (is_mobile && window.innerWidth < 451) {
        return <div className="hidden">
            {composer}
        </div>;
    }
    return (
        <div className={`flex-col flex pt-3 space-y-4 user-select-none`} >

            <SidebarMainSection is_admin={is_admin} community={community} theme={theme}
                                location={props.location} goTo={(path) => {
                history.push(path)
            }} items={sidebar.main}/>

            <div className="">
                {context === 'community' && sidebar.secondary &&
                <SidebarMainSection compact is_admin={is_admin} community={community} theme={theme}
                                    location={props.location} goTo={(path) => {
                    history.push(path)
                }} items={sidebar.secondary}/>}

                <div id={"sidebar-entities"} className="space-y-4">
                    {context === 'community' &&
                    <SidebarEntitiesList community_handle={community_handle} history={history}
                                         community_uid={community_uid}
                                         community={community}
                                         is_admin={is_admin} theme={theme} location={props.location}
                                         goTo={(path) => {
                                             history.push(path)
                                         }} sections={sidebar.entities}/>}
                    <div className="h-16"/>
                </div>
            </div>

            {context === 'community' && composer}
        </div>
    );
}