import React from "react";
import styled from "styled-components";
import {CheckIcon,MinusIcon} from '@heroicons/react/solid';

const CheckboxItem = styled.div`
    height: 16px;
    min-width: 16px;
    width: 16px;
    cursor: pointer;
        border-radius: 4px;
        
        > svg {
        color: #fff;
        height: 14px;
    display: block;
        }
`;

export const TableCheckbox = ({checked, onClick, label, indeterminate}) => {
  const active = checked||indeterminate;
  return <span className="inline-flex items-center space-x-2">
    <CheckboxItem className={`border ${active?"bg-selection border-selection":"bg-white border-gray-300"}`} onClick={onClick} active={checked||indeterminate} >
      {checked&&<CheckIcon/>}
      {indeterminate&&<MinusIcon/>}
  </CheckboxItem>
    {label&&<span onClick={onClick} className={`text-base font-medium cursor-pointer text-gray-700`}>{label}</span>}
    </span>
};