import React, {useState} from "react";
import {ActionWithIcon} from "../../core";
import {LockClosedIcon, TrashIcon} from "@heroicons/react/solid";
import {EmojiPopupSelector} from "../../old/emoji-popup-selector";

const edit_class = `py-0.5 cursor-pointer -my-0.5 px-1 -mx-1 rounded-md hover:bg-gray-100`;

function ActionButton({disabled, onSelect}) {
    const classes = disabled ? `bg-gray-200 text-gray-400` : `bg-gray-100 text-gray-400 cursor-pointer hover:bg-gray-200 hover:text-gray-600`;
    return <div onClick={() => {
        if (!disabled) {
            onSelect();
        }
    }} className={`h-6 w-6  flex items-center justify-center rounded-md    ${classes}`}>
        <div className={"h-4 w-4"}>
            {disabled ? <LockClosedIcon/> : <TrashIcon/>}
        </div>
    </div>
}

function Item({
                  tag, title, subtitle = [], emoji = '', index, onDelete = () => {
    }, updateParent = () => {
    }, can_delete
              }) {
    const [editing, setEditing] = useState('');
    const [nt, setNt] = useState(title);
    const [nst, setNst] = useState(subtitle);
    const [em, setEm] = useState(emoji);
    return <div className="bg-white py-2 px-3 space-x-2 flex">
        <div className="flex-none w-5">
            <div className={`${edit_class} flex items-center justify-center`}>

                <EmojiPopupSelector trigger={<div className={`h-5 w-5 text-lg flex items-center justify-center pt-0.5`}>
                    {em ? em : <div className="h-5 w-5 rounded-full bg-gray-200 mt-0.5"/>}
                </div>} emoji={em} changeEmoji={(em) => {
                    setEm(em.native);
                    updateParent('emoji', em.native)
                }}/>
            </div>
        </div>
        <div className="flex-grow">
            <div className={"text-sm font-bold text-gray-800 h-6"}>
                {editing !== 'title' &&
                <div onClick={() => setEditing('title')} className={`${edit_class} inline-flex h-6 pr-8`}>
                    {nt}
                </div>}
                {editing === 'title' && <div className="h-6 inline-flex">
                    <input style={{minWidth: '0px', maxWidth: '150px'}} onChange={(e) => setNt(e.target.value)}
                           autoFocus onBlur={() => {
                        setEditing('');
                        if (nt) {
                            updateParent('title', nt);
                        }
                    }} value={nt}
                           className="text-sm -my-0.5 py-0.5 font-bold text-gray-800 px-1 -mx-1 rounded-md focus-standard h-5"/>
                </div>}
            </div>
            <div className="flex space-x-2">
                {nst.map(st => <div key={st.field} className="h-4.5 "
                                    style={{maxWidth: `${st.field !== 'abbreviation' ? "100px" : "50px"}`}}>
                    {editing !== st.field && <div onClick={() => setEditing(st.field)}
                                                  className={`${edit_class} h-4.5 text-xs text-gray-600 font-medium`}>
                        {st.text?st.text:"-"}
                    </div>}
                    {editing === st.field && <div className="h-4.5 -mt-0.5">
                        <input autoFocus onBlur={() => {
                            setEditing('');
                            if (st.text) {
                                updateParent(st.field, st.text);
                            }
                        }} onChange={(e) => {
                            let nnst = [...nst];
                            const ind = nnst.findIndex(x => x.field === st.field);
                            nnst[ind].text = e.target.value;
                            setNst(nnst);
                        }} style={{minWidth: '0px', maxWidth: `${st.field !== 'abbreviation' ? "100px" : "50px"}`}}
                               value={st.text}
                               className="text-xs text-gray-600 rounded-md px-0.5 -mx-0.5 font-medium focus-standard"/>
                    </div>}
                </div>)}

            </div>
        </div>
        <div className="flex-none items-center h-6 flex space-x-1.5">
            {tag && <div>{tag}</div>}
            <div>
                <ActionButton onSelect={onDelete} disabled={!can_delete}/>
            </div>
        </div>
    </div>
}

export function SimpleEditList({
                                   items = [],
                                   action = null,
                                   onEditItem = () => {},
                                   onDelete = () => {},
                                   title = "Title",
    empty_state_text = 'No Results',
                                   note = null
                               }) {

    return <div style={{maxWidth: '420px', maxHeight: '380px', height: '380px'}}
                className={`flex flex-col`}
    >
        <div className="flex items-center flex-none px-3 pt-1 pb-1 border-b border-gray-200">
            <div className="flex-grow text-base text-gray-800 font-bold">{title}</div>
            <div>
                {action &&
                <ActionWithIcon onClick={() => action.onClick()} inverse icon={action.icon} text={action.text}/>}
            </div>
        </div>
        <div className="flex-grow flex flex-col divide-y divide-gray-200 overflow-y-auto hide-scrollbar">
            {items.map((it, k) => <Item updateParent={(f, v) => onEditItem(it.id, {field: f, value: v})} key={it.id}
                                        id={it.id} index={k} onDelete={() => onDelete(it.id)} {...it} />)}
            {items.length===0&&<div className="text-gray-500 text-sm p-4 text-center">
                {empty_state_text}
            </div>}
        </div>
        {note && <div className="flex space-x-3 flex-none mt-2 bg-gray-100 p-3 rounded-md">
            <div className="flex-none">
                {note.item}
            </div>
            <div className="flex-grow text-xs text-gray-700">{note.text}</div>
        </div>}
    </div>
}