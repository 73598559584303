import React from "react";
import {Avatar, Button} from "../core";
import {PhotoSelector} from "../old/photo-selector";
import {getAdvancedImageBackgroundStyle} from "../reorganize/profile-card/cover";
import ImageUploader from "../old/image-uploader";

import styled from "styled-components";

const EditButton = styled.div`

        
    > div {
    margin-bottom: 10px;
       display: inline-block;
           padding: 5px 12px;
    border-radius: 8px;
    background: #000000a6;
    color: #fff;
    cursor: pointer;
    font-size: 13px;
    font-weight: 500;
    letter-spacing: 0.25px;
    :hover { 
        opacity: 0.7;
    }
    }
`;

function getModes(type) {
    // community-logo
    if(type==='cover-photo') {
        return [{id:'gallery',label:'Gallery'},{id:'pattern',label:'Pattern'},{label:'Custom',id:'custom'}]
    } else {
        return [{id:'gallery',label:'Gallery'},{label:'Custom',id:'custom'}]
    }
}
function getUrl(url) {
    if(url.indexOf('?alt=media')!==-1) {
        return url;
    } else {
        return `${url}_medium?alt=media`;
    }
}

export function AdminImageEditorField({color='',handleUrl=()=>{},show_selector=false,layout='standard',url,dims="w-20 h-12",type='member-card',title='Member Card Background'}) {

    if(layout==='image-only') {
        return <div className="relative h-32 w-32">
            <Avatar url={url} size="4xl" />
            <PhotoSelector modes={getModes(type)} type={type} setPhoto={handleUrl}>
                <EditButton className="absolute bottom-1 w-full flex justify-center">
                    <div>Change</div>
                </EditButton>
            </PhotoSelector>
        </div>
    } else if(show_selector) {
        return <PhotoSelector modes={getModes(type)} type={type} setPhoto={handleUrl}>
            <div className="flex space-x-3 pt-1">
                <div>
                    <div style={getAdvancedImageBackgroundStyle(type,url,color,'25%','_medium')} className={`bg-gray-200 rounded-md ${dims} bg-center bg-cover`} />
                </div>
                <div className="flex items-center">
                    <div>
                        <Button text="Change" />
                    </div>
                </div>
            </div>

        </PhotoSelector>
    }

    return <div>
        <ImageUploader title={title} type={type} handleUrl={handleUrl}>
            <div className="flex space-x-3 pt-1">
                <div>
                    <div style={{backgroundImage:url?`url('${getUrl(url)}')`:''}} className={`bg-gray-200 rounded-md ${dims} bg-center bg-cover`} />
                </div>
                <div className="flex items-center">
                    <div>
                        <Button text="Change" />
                    </div>
                </div>
            </div>
        </ImageUploader>
    </div>
}