import React, {useState} from 'react';
import {UnatySVG} from "../../../icons/unaty/logos";
import {Avatar, Button, ColorInput, Field, Tabs} from "../../core";
import ImageUploader from "../../old/image-uploader";
import {authFetch} from "../../../../config/network";
import {CADGrid, CADPreviewBox} from "../../../../app/community/admin/settings/designer/shared";
import {DEFAULT_USER_PHOTO} from "../../../../config/defaults";
import {PhotoSelector} from "../../old/photo-selector";
import styled from "styled-components";
import {colorContrast} from "../../../utilities/general/colors";
import {getAdvancedImageBackgroundStyle, getAdvancedImageBkg} from "../profile-card/cover";
import {AdminImageEditorField} from "../../custom/admin-image-editor-field";

const mock = {
    color: '',
    url: ''
};

function SignInPagePreview({config}) {
    const {url,color} = config;
    return <div className="grid h-64 bg-white rounded-md border border-gray-200 shadow-sm" style={{gridTemplateColumns:'25% 75%'}}>
        <div />
        <div style={getAdvancedImageBackgroundStyle('cover-photo',url,color,'25%','')} className={`bg-gray-200 bg-center bg-cover`} />
    </div>
}

export function SignInPageDesigner({current = mock, updateCommunityData, community={}}) {
    const [config, setConfig] = useState(current);
    const [changes_made, setChangesMade] = useState(false);
    const [saving, setSaving] = useState(false);

    function saveChanges() {
        setSaving(true);
        if(!community) {
            return;
        }
        const payload = {
            community_uid: community.uid,
            url: config.url,
            color: config.color,
            member_id: community.member_id
        };

        const res = (result) => {
            updateCommunityData(community.uid,result.data.community);
           setSaving(false);
            setChangesMade(false);
        };

        const err = () => {
            setSaving(false);
            setChangesMade(false);
        };

        authFetch("/communities/update-sign-in-page-design", res, err, "POST", {payload});
    }

    return <CADGrid>
        <div>
        <div style={{minHeight:'48px'}}
            className={`rounded-t-md space-x-2 px-3 py-1.5 flex border group border-gray-200 bg-white`}>
            <div className="flex-grow flex items-center">
               <div
                   className={`text-lg rounded-md border border-white text-gray-800 font-semibold`}>
                    Customize
                </div>
            </div>
            <div className="flex space-x-2">
                {changes_made&&<div className="">
                    <Button onClick={()=>saveChanges()} loading={saving} text="Save" intent="success" />
                </div>}
            </div>
        </div>
            <div className={`bg-white space-y-2 px-3 py-3 border-l border-r border-b rounded-b-md`}>

                <Field label="Background">
                <AdminImageEditorField show_selector title="Sign In Cover Photo" dims="w-14 h-12" type="sign-in-page"
                                       handleUrl={(url, color) => {
                                           let nc = {...config};
                                           nc.url = url;
                                           nc.color = url;
                                           setConfig(nc);
                                           setChangesMade(true);
                                       }} color={config.color} url={config.url}/>
                </Field>
            </div>
    </div>
        <CADPreviewBox>
            <div className="w-96 mx-auto py-4">
<SignInPagePreview config={config} />
            </div>
        </CADPreviewBox>
    </CADGrid>
}