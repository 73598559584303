export function cleanEmail(email) {
    return email.toLowerCase();
}

export function getCurrentLang() {
    let userLang = navigator.language || navigator.userLanguage;

    return userLang === 'de' ? 'de' : 'en';
}

export function getCurrentTimezone() {
    return Intl.DateTimeFormat().resolvedOptions().timeZone
}

export function getUrlVars() {
    let vars = {};
    window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function(m,key,value) {
        vars[key] = value;
    });
    return vars;
}