import React from 'react';
import {PM_Item} from "./item";
import {PM_Divider} from "./divider";
import styled from "styled-components";

const Outer = styled.div`
border-radius: 5px;
width: ${props=>props.width};
`;

const Inner = styled.div`
    padding: 5px 0;
`;

export const PopupMenu = ({items=[],small,width='300px'}) => {

    return <Outer className={`popup-box-shadow user-select-none ${small?"overflow-y-auto max-h-80":""}`} width={width}>
        <Inner>
            {items.map((item,i)=>{
                const key = `${item.type}-${i}`;
                switch (item.type) {
                    case 'divider' : {
                        return <PM_Divider {...item} key={key} />
                    }
                    case 'submenu' : {
                        return <PM_Item {...item} has_submenu key={key} />;
                    }
                    case 'item' : {
                        return <PM_Item {...item} key={key} />
                    }
                    case 'custom' : {
                        return <div key={key}>{item.component}</div>;
                    }
                    case 'title' : {
                        return <div key={key}>
                            <div className="text-xs text-gray-600 px-4 pt-1 pb-px font-medium">
                                {item.title}
                            </div>
                        </div>;
                    }
                    default : {
                        return <PM_Item {...item} key={key} />
                    }
                }
            })}
        </Inner>
    </Outer>
};