export const languages = [
    {
        value: 'en',
        text: 'English'
    },
    {
        value: 'de',
        text: 'German'
    }
];

export const languages_object = {
    'en': 'English',
    'de': 'German',
    '': ''
};