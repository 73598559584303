import React from 'react';
import classNames from 'classnames';

const props = {
    header: {
        divider: true,
        title: 'Title',
        actions: null
    }
};

function CardHeader({divider = true, subtitle, title, actions = null}) {

    const border = divider ? 'border-b border-solid border-gray-100' : '';
    const padding = divider ? 'py-4 px-4' : 'py-3 px-4';

    return <div className={classNames(border, padding, "flex")}>
        <div className="flex-grow flex flex-col items-start">
            {title && <div className="text-gray-800 text-lg font-medium">
                {title}
            </div>}
            {subtitle && <div className="text-gray-500 text-sm">
                {subtitle}
            </div>}
        </div>
        <div className="flex items-center">
            {actions}
        </div>
    </div>
}

export function Card({header, animation='', overflowVisible, onClick, color, fill, padded=true, interactive, selected, children}) {

    const width = fill ? '' : 'max-w-sm';
    const sel = selected ? 'ring-1 ring-blue-400': '';
    const int = interactive ? 'cursor-pointer': '';

    const extra = interactive && selected ? "" : interactive ? "bg-gray-50 opacity-90 hover:opacity-100 hover:bg-white" : "";
    const padding = padded ? "py-3 px-4" : "";

    let border = selected ? "border border-solid border-blue-400" : "border border-solid border-gray-200";

    const bg = color ? `bg-${color}-50` : "bg-white";

    if(color) {
        border = "border border-solid border-blue-200";
    }

    const anim = animation === 'hover' ? "transform transition-transform hover:-translate-y-1" : "";

    return <div onClick={()=>onClick?onClick():null} className={classNames(width,sel,int,extra,border,bg,onClick?"cursor-pointer":"","flex transition-opacity flex-col rounded-md shadow-sm ",overflowVisible?"overflow-y-visible":"overflow-hidden",anim)}>
        {header && <CardHeader {...header} />}
        <div className={`"flex flex-col ${padding} ${overflowVisible?"overflow-y-visible":""}"`}>
        {children}
        </div>
    </div>
}