import React from 'react';
import styled from "styled-components";
import {PopupMenu} from "../popup-menu";
import {Popover2} from "@blueprintjs/popover2";

const Items = styled.div`
    display: flex;
`;

const Item = styled.div`
   display: flex;
    flex-flow: column;
    align-items: center;
    cursor: pointer;
    :hover {
        opacity: 0.7;
    }
`;

const IconItem = styled.div`
 display: inline-flex;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    > svg {
            height: 22px;
    }
`;

const Text = styled.div`
   font-size: 12px;
    margin-top: 6px;
    font-weight: 500;
`;

const ActionItem = ({icon,width,onClick,text,intent}) => {
  return <Item onClick={onClick} style={{width}}>
      <IconItem intent={intent} className={` ${intent==='secondary'?"bg-secondary text-white":"bg-gray-200 text-gray-700"}`}>{icon}</IconItem>
      <Text intent={intent} className={'text-gray-900'}>{text}</Text>
  </Item>
};

export const ActionsGroup = ({actions,intent=""}) => {
    const width = `${100/(actions.length+1)}%`;
    return <Items className={"justify-around px-4 py-1"}>
        {actions.map((it)=>{
            if(it.options) {
                return <Popover2 key={it.text} placement={'bottom-start'} minimal popoverClassName='minimal-popover'>
                    <ActionItem width={width} {...it} intent={intent} />
                    <PopupMenu items={it.options}/>
                </Popover2>
            } else {
                return <ActionItem width={width} key={it.text} {...it} intent={intent} />
            }

        })}
    </Items>
};