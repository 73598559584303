import React, {useEffect, useRef, useState} from 'react';
import {Button, InfoState} from "../../../library/components/core";
import {EntityCard} from "../../../library/components/entity-card";
import useInfiniteScroll from "../../../library/playground/advanced-table/use-infinite-scroll";

export function GenericInfiniteLoader({
                                          renderer = () => {
                                          },
                                          ctx,
                                          where,
                                          container_style = {},
                                          container_classes = "",
                                          params,
                                          extra,
                                          getData = () => {
                                          },
                                          overflow = false
                                      }) {
    const [loading,setLoading] = useState(false);
    const has_more = useRef(false);
    const [state, setState] = useState({
        has_next_page: true,
        initial_loading: true,
        last_item_ref: null,
        is_next_page_loading: false,
        items: []
    });

    const scroll_id = "main-content";

    const [isFetching, setIsFetching] = useInfiniteScroll("infinite", scroll_id, {
        padding: 400,
        container: true
    }, () => {
        if (!loading && has_more.current) {
            _loadNextPage();
        }
    });

    useEffect(function () {
        setState({
            ...state,
            initial_loading: true,
            items: []
        })
        getData(ctx, params, where, null, extra)
            .then(st => {
                has_more.current = st.has_next_page;
                setState({...st});
                setLoading(false);
                setIsFetching(false);
            });
    }, [where, params])

    function _loadNextPage(...args) {
        setState({
            ...state
        });

        setLoading(true);

        getData(ctx, params, where, state.last_item_ref, extra)
            .then(st => {
                let new_state = {
                    has_next_page: st.has_next_page,
                    initial_loading: st.initial_loading,
                    last_item_ref: st.last_item_ref,
                    is_next_page_loading: st.is_next_page_loading,
                    items: [...state.items, ...st.items]
                }

                has_more.current = st.has_next_page;
                setState(new_state)
                setLoading(false);
                setIsFetching(false);
            });
    }

    return <div className={`flex flex-col pb-20`}>
        <div  style={container_style} className={`flex-grow ${container_classes} ${overflow ? "overflow-y-auto" : ""}`}>
            {state.initial_loading && <>
                <EntityCard loading/>
                <EntityCard loading/>
                <EntityCard loading/>
            </>}
            {state.items.map((it, k) => {
                return renderer(it, k);
            })}
        </div>

        {!state.initial_loading && state.items.length === 0 && <div className="p-2 flex justify-center">
            <InfoState subtitle="No groups found"/>
        </div>}
        {state.is_next_page_loading && state.items.length > 0 && <div className="flex justify-center pt-2 pb-8">
            <Button loading onClick={() => {
            }} text="Loading"/>
        </div>}
        {state.has_next_page && state.items.length > 0 && !loading && !state.is_next_page_loading &&
        <div className="flex justify-center pt-4 pb-8">
            <Button intent="secondary" minimal onClick={() => _loadNextPage()} text="Load more"/>
        </div>}
    </div>
}