import React, {useContext, useState} from 'react';
import {Badge, Button, TextArea} from "../core";
import {ArrowRightIcon} from "@heroicons/react/solid";
import styled from "styled-components";
import {GlobalContext} from "../../../app/global/global-context";

const ChoiceEditOption = styled.div`
  box-shadow: rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(58 151 212 / 36%) 0px 0px 0px 4px, rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(60 66 87 / 16%) 0px 0px 0px 1px, rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px 0px !important;
    > input {
      
    }
`;

const handleFocus = (event) => event.target.select();

function Choice({
                    taken = [],
    onChoiceTaken = ()=>{},
                    text = "", editing = false, handleRemove = () => {
    },
    onChange = ()=>{}
                }) {
    const [ed,setEd] = useState(false);
    const [t,setT] = useState(text);

    function handleChange(e) {
        setT(e.target.value);
    }

    function handleBlur() {
        if(t&&t!==text) {
            const lowercase_items = taken.map(a=>a.toLowerCase());

            const unique = lowercase_items.findIndex(a=>a===t.toLowerCase()) === -1;

            if(unique) {
                onChange(t);
            } else {
                onChoiceTaken();
                setT(text);
            }
        } else {
            setT(text);
        }
        setEd(false);
    }

    return <div className="mr-2 mb-1">
        {!ed&&<Badge onClick={()=>{
            if(!editing&&!ed) {
                setEd(true);
            }
        }} handleRemove={handleRemove} can_remove={editing} rounded text={t}
                                             size="sm"/>}
        {ed&&<ChoiceEditOption className="inline-flex items-center cursor-pointer py-0.5 px-2.5 font-medium rounded-md text-gray-700 bg-white">
            <input onFocus={handleFocus} autoFocus onBlur={handleBlur} style={{width: '5rem'}} className="bg-white text-sm font-medium" value={t} onChange={handleChange} />
        </ChoiceEditOption>}
    </div>
}

function NoResults() {
    return <div className="p-4 w-full text-gray-500 text-sm">
        No choices found
    </div>
}

export function parseOptionsFromSelect(str) {
    if(!str) {
        return [];
    }
    return str.trim().replace(/ \ \ /g, ',').replace(/\n/g, ',').split(`,`).map(a => a.trim()).filter(a=>!!a);
}

export default function ChoiceEditor({
                                         initial = [],
                                         choice_max_length = 40,
                                         onAddChoice = () => {},
                                         onRenameChoice = () => {},
                                         onRemoveChoice = () => {}
                                     }) {
    const global = useContext(GlobalContext);
    const [choices, setChoices] = useState(initial);
    const [new_choice, setNewChoice] = useState('');
    const [editing, setEditing] = useState(false);

    function addChoice() {
        let nc = [...choices];
        let all_choices = [];
        const split_ac = parseOptionsFromSelect(new_choice);

        all_choices = split_ac.filter(a => !!a).filter(a => !nc.includes(a)).map(a => a.trim());

        nc = nc.concat(all_choices);

        setChoices(nc);
        onAddChoice(all_choices);

        setNewChoice(null);
        setTimeout( () => {
            setNewChoice('');
        }, 50);
    }

    function removeChoice(c, k) {
        let nc = [...choices];
        const remove_index = nc.indexOf(c);
        nc.splice(remove_index, 1);
        setChoices(nc);
        onRemoveChoice(c, nc);
    }

    function handleRename(c, c_names, new_text) {
        let nc = [...choices];
        const current_index = nc.indexOf(c);
nc[current_index] = new_text;
setChoices(nc);
        onRenameChoice(c,new_text);
    }

    // new choice must have len > 0 and not exist yet
    const choices_names = [...choices];
    const options = parseOptionsFromSelect(new_choice);
    const disabled = !new_choice ? true : options.length>0;

    const sorted = choices_names.sort((a, b) => a > b ? 1 : -1);

    const new_count = !new_choice ? 0 : options.length;

    return <div className="border rounded-md border-gray-200">
        <div className="relative">
            <div className={`pt-3 pl-3 pr-10 pb-2 flex flex-wrap ${editing ? "wobble-items" : ""}`}>
                {sorted.map((c, k) => <Choice key={c} editing={editing}
                                              handleRemove={() => removeChoice(c, k)}
                                              taken={choices_names}
                                              onChoiceTaken={()=>{
                                                  global.addToast({
                                                      text: 'Name already taken',
                                                      intent: 'info'
                                                  });
                                              }}
                                              onChange={(nt) => handleRename(c, choices_names, nt)}
                                              text={c}/>)}
                {choices.length === 0 && <NoResults/>}
            </div>
            <div className="absolute top-2 right-2">
                <div className="p-1">
                    {sorted.length > 0 &&
                    <span className="color-link text-sm font-medium" onClick={() => setEditing(!editing)}>{editing ? "Done" : "Edit"}</span>}
                </div>
            </div>
        </div>
        <div className="pb-2 px-3">
            <div className="w-full space-x-2 flex">
                <div className="flex-grow">
                    {new_choice!==null&&<TextArea rows={2} autoFocus onEnter={() => {
                        if (!disabled) {
                            addChoice();
                        }
                    }} placeholder="Add choices separated by commas" value={new_choice}
                              onChange={(v) => setNewChoice(v)}
                              custom={{maxLength: choice_max_length}}/>}
                </div>
                <div className="flex-none pt-1">
                    <Button onClick={() => addChoice()} right_icon={<ArrowRightIcon/>}
                            text={`${new_count < 2 ? "Add" : `Add ${new_count}`}`} intent="secondary"
                            disabled={new_count === 0}/>
                </div>
            </div>
        </div>
    </div>
}