import React, {useState,useEffect,useContext} from 'react';
import Composer from "../../composer";
import {CommunityContext} from "../../../../app/community/community-context";

export function ComposerWrapper() {
    const community = useContext(CommunityContext);
    const [prefill,setPrefill] = useState(null);

    useEffect(function() {
        if(community.open_composer) {
            setPrefill(community.open_composer);
        } else {
            setPrefill(null);
        }
    },[community.open_composer,prefill]);

    function handleClose() {
        community.closeComposer();
        if(prefill.cb) {
            prefill.cb();
        }
        setPrefill(null);
    }

    if(!prefill) {
        return null;
    }

    return <Composer prefill={prefill} handleClose={handleClose} />
}