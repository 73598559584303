import React, {useContext} from 'react';
import 'emoji-mart/css/emoji-mart.css';

import { Picker } from 'emoji-mart';
import styled from "styled-components";
import {CommunityContext} from "../../../../app/community/community-context";

const Container = styled.div`
margin-top: ${props=>props.layout==='photo-selector'?"-8px":"0"};
   > section {
    border: none !important;
        height: ${props=>props.layout==='photo-selector'?"280px !important":""};
    width: ${props=>props.layout==='photo-selector'?"100% !important":"0"};
    > div.emoji-mart-scroll {
        height: ${props=>props.layout==='photo-selector'?"243px !important":""};
    }
    .emoji-mart-bar {
    display: ${props=>props.layout==='photo-selector'?"none !important":""};
    }
   }
`;
function getCustomIcons(community) {
    if(!community.data) {
        // global
        return [];
    }

    if(!community.community_emojis) {
        return null;
    }

    const values = Object.values(community.community_emojis);

    return values.map((em)=>{
        const h = em.handle.replace(/:/g,'')
        return {
            name: h,
            short_names: [h],
            text: '',
            emoticons: [],
            keywords: h.split('-'),
            imageUrl: em.url
        }
    });
}
// emojiIndex.search('christmas').map((o) => o.native)
export const EmojiPicker = ({changeEmoji=()=>{},layout='standard'}) => {
    const community = useContext(CommunityContext);
    const custom = getCustomIcons(community);
    return <Container layout={layout}>
        {custom&&<Picker custom={custom} title='Choose an Emoji' set='apple' onSelect={(emoji,e)=>{
            changeEmoji(emoji);
            console.log('emoji',emoji)
        }} emojiTooltip autoFocus />}
    </Container>;
};