import React, {useState} from 'react';
import {fields, operators} from "../../../../app/community/reorganize/filtering-data";
import {AFAddNewFilter} from "./add-filter";

function getItemFromArray(arr,prop,value) {
    for(let i=0;i<arr.length;i++) {
        if(arr[i][prop]===value) {
            return arr[i];
        }
    }
    return {};
}

function getExistingFieldPossibilities(can_change_to,all,current_field) {
    return [
        getItemFromArray(all,'field',current_field),
        ...can_change_to
    ];
}
// type = boolean
export function at_getDefaultValueFromOperator(operator,type) {
    switch (operator) {
        case '==':
            return type === 'number' ? 0 : '';
        case 'true':
            return true;
        case 'false':
            return false;
        case '!=':
            return type === 'number' ? 0 : '';
        case '<=':
            return 0;
        case '>=':
            return 0;
        case '<':
            return 0;
        case 'is_between':
            return type === 'number' ? [0,0] : ['',''];
        case 'in':
            return {};
        case 'not-in':
            return {};
        case 'is_after':
            return '';
        case 'is_before':
            return '';
        case 'empty':
            return '';
        case 'not_empty':
            return '';
        case 'is_on':
            return '';
        case 'in_last':
            return '30-days';
        default:
            return '';
    }
}

export function ATEditFilter({data,autoFocus=true,can_change_type=true,all_options,avail_options,context,editFilter}) {

    function handleChange(f,v) {
        let d = data;
        d[f] = v;
        const type = all_options.filter(obj => {
            return obj.field === d.field
        })[0].type;
        if (f === 'field') {
            // if change the field, reset the operator and value
            d['operator'] = operators[type][0].value;
        }
        if (f === 'field' || f === 'operator') {
            d['value'] = at_getDefaultValueFromOperator(d['operator'],type);
        }

        editFilter(d);
    }

    return <AFAddNewFilter autoFocus={autoFocus} can_change_type={can_change_type} filter_options={getExistingFieldPossibilities(avail_options,all_options,data.field)} context={context} handleChange={handleChange} data={data}/>
}