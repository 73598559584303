

//   const new_current_content = nes.getCurrentContent();


//  const contentBlockSelectionState = SelectionState.createEmpty(new_current_content.getBlockForKey(new_block_of_type.key));

// const contentWithEntityApplied = Modifier.applyEntity(newContent,contentBlockSelectionState,entityKey);
// const contentWithEntityApplied = Modifier.insertText(new_current_content, nes.getSelection(), " ", null, entityKey);

// const textWithEntity = Modifier.applyEntity(new_current_content, nes.getSelection(), entityKey);

//    return EditorState.push(nes, contentWithEntityApplied, 'insert-characters');


import {CharacterMetadata,SelectionState,BlockMapBuilder,ContentBlock,convertToRaw,EditorState,Modifier} from 'draft-js';
import {List,Repeat} from 'immutable';
const seenKeys = {};
const MULTIPLIER = Math.pow(2, 24);

const ContentBlockRecord = ContentBlock;
function generateRandomKey() {
    let key;

    while (key === undefined || seenKeys.hasOwnProperty(key) || !isNaN(+key)) {
        key = Math.floor(Math.random() * MULTIPLIER).toString(32);
    }

    seenKeys[key] = true;
    return key;
}

export function es_insertCustomBlock(editorState,block_type,character,data,direction='after') {
    const contentState = editorState.getCurrentContent();

    const selectionState = editorState.getSelection();
    const afterRemoval = Modifier.removeRange(contentState, selectionState, 'backward');
    const targetSelection = afterRemoval.getSelectionAfter();
    const currentKey = selectionState.getAnchorKey();
    const afterSplit = Modifier.splitBlock(afterRemoval, targetSelection);
    const insertionTarget = afterSplit.getSelectionAfter();
    const asAtomicBlock = Modifier.setBlockType(afterSplit, insertionTarget, 'atomic');
    const charData = CharacterMetadata.create({
        entity: null
    });
    let atomicBlockConfig = {
        key: generateRandomKey(),
        type: block_type,
        data,
        text: character,
        characterList: List(Repeat(charData, character.length))
    };
    let atomicDividerBlockConfig = {
        key: generateRandomKey(),
        type: 'unstyled'
    };

    const fragmentArray = [new ContentBlockRecord(atomicBlockConfig), new ContentBlockRecord(atomicDividerBlockConfig)];
    const fragment = BlockMapBuilder.createFromArray(fragmentArray);
    const withAtomicBlock = Modifier.replaceWithFragment(asAtomicBlock, insertionTarget, fragment);
    const newContent = withAtomicBlock.merge({
        selectionBefore: selectionState,
        selectionAfter: withAtomicBlock.getSelectionAfter().set('hasFocus', true)
    });

    const full_content_editor_state = EditorState.push(editorState, newContent, 'insert-fragment');

    const fces_content_state = full_content_editor_state.getCurrentContent()

    const last_block = fces_content_state.getLastBlock();
    const block_before = fces_content_state.getBlockBefore(last_block.getKey());

    let final_selection_state;
    if(direction==='after') {
        final_selection_state = SelectionState.createEmpty(last_block.getKey());
    } else {
        final_selection_state = SelectionState.createEmpty(currentKey);
    }

    // EditorState.forceSelection(currentState, selectionState)

    return {
        editor_state: EditorState.forceSelection(full_content_editor_state, final_selection_state),
        block_key: block_before.getKey()
    }

}