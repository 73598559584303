import React, {useContext} from 'react';
import {GlobalContext} from "../global/global-context";
import {CommunityContext} from "./community-context";
import {Switch} from "react-router-dom";
import {RenderRoute} from "../utilities/utilities";
import AppLayout from "../../library/components/layout";
import {community_routes_nonmember,community_routes_member} from "./routes";
import {ErrorBoundary} from "../../library/components/layout/error-boundary";

function buildFinalRoutes(arr, ctx) {
    return arr.map((it)=>{
        return {
            ...it,
            path: it.path ? it.path.replace(':handle',ctx.handle) : '',
            to: it.to ? it.to.replace(':handle',ctx.handle) : '',
            from: it.from ? it.from.replace(':handle',ctx.handle) : '',
        }
    });
}

export function CommunityRouter(props) {
    const global = useContext(GlobalContext);
    const community = useContext(CommunityContext);

    const ctx = {
        handle: props.match.params.handle
    };

    const routes = buildFinalRoutes(!community.is_member?community_routes_nonmember:community_routes_member,ctx);

    const app_frame_config = {
        memberships: global.memberships,
        user: global.user,
        community: community.data,
        my_groups: community.my_groups,
        logged_in: global.logged_in,
        community_data: global.community_data,
        member_types: community.member_types,
        member: community.member,
        community_uid: community.uid,
        context: 'community',
        all_teams: community.all_teams,
        all_roles: community.all_roles,
        starred_smart_views: community.starred_smart_views,
        groups: community.entities.groups,
        teams: community.entities.teams,
        roles: community.entities.roles,
        member_id: community.member_id,
        qs_results: {
            member_types: community.member_types&&Object.entries(community.member_types).map(([id,entry])=>{
                return {
                    id,
                    data_type: 'member_type',
                    data: {},
                    label: entry.plural
                }
            }),
            roles: community.all_roles&&Object.entries(community.all_roles).map(([id,entry])=>{
                return {
                    id,
                    data_type: 'role',
                    data: {
                        assignee_data: entry.assignee_data,
                    },
                    label: entry.name
                }
            }),
            teams: community.all_teams&&Object.entries(community.all_teams).map(([id,entry])=>{
                return {
                    id,
                    data_type: 'team',
                    data: {},
                    label: entry.name
                }
            }),
        },
        is_member: community.is_member,
        is_owner: community.is_owner,
        is_admin:  community.is_admin
    };

    return <ErrorBoundary ctx={{
        community_uid: community.uid,
        member_id:community.member_id,
        user_uid: global.user_uid
    }}>
        <AppLayout {...props}  context='community' routes={routes} app_frame_config={app_frame_config}>
        <Switch>
            {routes.map(rt => <RenderRoute key={rt.key} {...rt} ctx={ctx}/>)}
        </Switch>
    </AppLayout>
    </ErrorBoundary>
}