import React, {useContext, useEffect, useRef, useState} from 'react';
import {
    Button, Field, InlineLoader, Input, Pinboard
} from '../core';
import {InterestField} from "../old/interest-field";
import {CommunityContext} from "../../../app/community/community-context";
import {
    pb_autodetectType,
    PB_options,
    PB_type_labels, PB_type_names,
    PB_type_placeholders,
    PB_validators
} from "../../utilities/pinboard";
import {CUSTOM_NANOID} from "../../../config/defaults";
import {externalAuthFetch} from "../../../config/network";

const default_state = {
    id: CUSTOM_NANOID(),
    label: 'My Pin',
    custom_category: '',
    value: '',
    type: 'link'
};

export function isValidHttpUrl(string) {
    let url;

    try {
        url = new URL(string);
    } catch (_) {
        return false;
    }

    return url.protocol === "http:" || url.protocol === "https:";
}

/*
JOBS
-  detect pasted in link and get data about valid url
- allow typing in a url with https://....
- allow typing in a website without a url
 */

function getFinalValue(value) {
    if(!value||value.length<4) {
        return "";
    }
    if(value.startsWith('http')) {
        return value
    }
    return !value ? "" : isValidHttpUrl(value) ? value : isValidHttpUrl(`https://${value}`) ? `https://${value}` : ""
}
export function PinboardAddPin({goBack, confirm_text="Create Pin", init_data={...default_state}, addPin, pins = [], pin_data = {}}) {
    const [loading, setLoading] = useState(false);
    const [fetching, setFetching] = useState(false);
    const [pin, setPin] = useState(init_data);
    const [local_result,setLocalResult] = useState(null);
    const community = useContext(CommunityContext);
    const vl = useRef();

    useEffect(function () {
        vl.current = pin;
    }, [pin]);

    function getPageMetadata(ul) {
        if ((pin.label&&pin.label!==init_data.label)||init_data.value||(!isValidHttpUrl(ul)&&!isValidHttpUrl(pin.value))||(local_result&&local_result[pin.value])||fetching) {
            return;
        }
        const payload = {
            url: ul?ul:pin.value
        };
setFetching(true);
        // build in 1s delay
        externalAuthFetch('/get-page-metadata', (resp) => {
            let lr = {[payload.url]: null};
            if(resp&&resp.data) {
                if(resp.data.title) {
                    updatePin('label', resp.data.title.slice(0,96), true)
                    lr[payload.url] = resp.data;
                }
            }
            setFetching(false);
            setLocalResult(lr)
        }, () => {
        }, 'POST', payload);
    }

    function updatePin(f, v, use_ref) {
        const pval = use_ref ? vl.current : pin;
        if (f === 'value') {
            // try to do some autodetection
            const type = pb_autodetectType(pval.type, v);
            setPin({...pval, [f]: v, 'type': type})
        } else if (f === 'type') {
            let p = {...pval};
            if (v.startsWith('custom-')) {
                p.type = 'custom';
                p.custom_category = v.split('custom-')[1];
            } else {
                p.type = v;
            }
            setPin({...p})
        } else {
            setPin({...pval, [f]: v})
        }

    }

    function handleAddPin() {
        setLoading(true);
        const cb = () => {
            let new_item = default_state;
            new_item.id = CUSTOM_NANOID();
            setPin(new_item);
            if (goBack) {
                goBack();
            }
            setLoading(false);
        };
        addPin(pin.id, {...pin,value:getFinalValue(pin.value)}, cb);
    }

    let type_options = [...PB_options];
    let custom_types = {};

    pins.forEach(p => {
        const dt = pin_data[p];
        if(!dt) {

        } else if (dt.type === 'custom') {
            custom_types[dt.custom_category] = true;
        }
    });

    const custom_categories = Object.keys(custom_types);

    custom_categories.forEach(a => {
        type_options.push({
            label: a,
            value: `custom-${a}`
        })
    });

    const already_changed_form = pin.label && (pin.label !== init_data.label);

    const show_pin_meta = pin.value&&!fetching || already_changed_form;

    const try_value = getFinalValue(pin.value);

    const value_is_valid = isValidHttpUrl(try_value);

    return <div className="space-y-4">

        <Field help_text={value_is_valid?`Full Link URL will be ${try_value}`:""} label={PB_type_labels[pin.type]}>
            <Input onPaste={(e)=>{
                if(e&&e.clipboardData) {
                    let pastedData = e.clipboardData.getData('text');
                    if(isValidHttpUrl(pastedData)) {
                        getPageMetadata(pastedData);
                    }
                }
            }} autoFocus onBlur={()=>getPageMetadata()} onChange={(v) => updatePin('value', v)} type="text" value={pin.value}
                   placeholder={PB_type_placeholders[pin.type]}/>
        </Field>

        {fetching&&!show_pin_meta&&<div>
          <InlineLoader padding={'p-4'} />
        </div>}

        {show_pin_meta && <Field label="Type & Label">
            <Input leading={{
                type: 'dropdown', props: {searchable: true}, onChange: (v) => {
                    updatePin('type', v.value);
                }, placeholder: "Type", value: pin.type, options: type_options
            }} type="text" value={pin.label} onChange={(v) => updatePin('label', v)}
                   placeholder={PB_type_placeholders[pin.type]}/>
        </Field>}

        {show_pin_meta && pin.type === 'custom' && <Field label="Custom Name">
            <Input type="text" value={pin.custom_category} onChange={(v) => updatePin('custom_category', v)}
                   placeholder={"My Label"}/>
        </Field>}

        {show_pin_meta && <Field label="Preview">
            <div className="rounded-md border border-gray-200 pt-2 pl-2 pr-2">
                <Pinboard community_uid={community.uid} pins={[
                    {
                        label: pin.type === 'custom' ? (pin.custom_category ? pin.custom_category : "Add Label") : PB_type_names[pin.type],
                        items: [
                            {
                                type: pin.type === 'email' ? 'email' : 'website',
                                value: `[${pin.label}](${pin.value})`,
                                onClick: (e) => {

                                }
                            }
                        ]
                    }
                ]}/>
            </div>
        </Field>}
        <div className="flex space-x-2 justify-end">
            {goBack && <Button text="Cancel" size="sm" onClick={() => goBack()}/>}
            <Button text={confirm_text}
                    disabled={fetching || pin.label === "" || (!PB_validators[pin.type](pin.value) && !PB_validators[pin.type](try_value)) || (pin.type === 'custom' && pin.custom_category === '')}
                    loading={loading} size="sm" intent="success" onClick={() => handleAddPin()}/>
        </div>
    </div>;
}