import React, {useContext, useEffect, useState} from 'react';
import {merge, intersection} from 'lodash';
import {EntityInfo} from "../../../utilities/localstorage/entity-localstorage";
import {Avatar, Button, InlineLoader} from "../../core";
import {CommunityContext} from "../../../../app/community/community-context";
import {buildFilterPromise} from "./filtering";

function toObject(arr) {
    let rv = {};
    for (let i = 0; i < arr.length; ++i)
        rv[arr[i]] = true;
    return rv;
}

function getCommonObj(o, n) {
    return toObject(intersection(Object.keys(o), Object.keys(n)));
}

function handleResults(id_arrays, match) {
    let obj = {};
    let steps = {};

    for (let i = 0; i < id_arrays.length; i++) {
        const item = id_arrays[i];
        const item_ids = item.ids;
        steps[item.id] = item_ids;
        let new_obj = toObject(item_ids);
        if (i === 0) {
            obj = new_obj;
        } else {
            if (match === 'any') {
                obj = merge(obj, new_obj);
            } else {
                obj = getCommonObj(obj, new_obj);
            }
        }
    }

    return {
        final: obj,
        steps
    };
}


function RenderName({data}) {
    return <div className="border rounded-md border-gray-200 pl-1.5 pr-3 py-1.5 flex space-x-2.5 items-center">
        <div>
            <Avatar size="xs" url={data.profile_picture?`${data.profile_picture}_small?alt=media`:""} text={data.name} />
        </div>
        <div className="font-semibold text-base text-gray-800">{data.name}</div>
    </div>;
}

function Item({id, cid}) {
    return <div className="mr-2 mb-1 ">
        <EntityInfo community_uid={cid} id={id} type={'members'}>
            <RenderName/>
        </EntityInfo>
    </div>
}

function Frame({members, cid}) {
    const keys = Object.keys(members);
    return <div style={{minHeight: '160px',maxHeight:'320px'}} className="overflow-y-auto">
        <div className="flex flex-wrap">
        {keys.map((id) => <Item key={id} cid={cid} id={id}/>)}
        </div>
    </div>;
}

function isValidStringQuery(operator, value) {
    // starts_with, is, is_not
    return !!value;
}

function isValidBooleanQuery(operator, value) {
    // starts_with, is, is_not
    return value !== null;
}

function isValidDateQuery(operator, value) {
    // starts_with, is, is_not
    return !!value;
}

function isValidSelectQuery(operator, value) {
    // starts_with, is, is_not
    return !!value;
}

function isValidMultiSelectQuery(operator, value) {
    // starts_with, is, is_not
    console.log('isValidMultiSelectQuery',operator,value)
    if(operator==='is'||operator==='is_not') {
        return typeof value === 'string' && value.length > 0;
    } else {
        return typeof value !== 'string' && value.length > 0;
    }
}

function isValidNumberQuery(operator, value) {
    // starts_with, is, is_not
    return !isNaN(value);
}

export function getValidConditions(arr,num=false) {
    let a = [];
    for (let i = 0; i < arr.length; i++) {
        const it = arr[i];

        const {type, operator, value, modifier} = it;

        if (type === 'string' && isValidStringQuery(operator, value)) {
            if(num) {
                a.push(i);
            } else {
                a.push(it);
            }

        } else if (type === 'number' && isValidNumberQuery(operator, value)) {
            if(num) {
                a.push(i);
            } else {
                a.push(it);
            }
        } else if (type === 'date' && isValidDateQuery(operator, value)) {
            if(num) {
                a.push(i);
            } else {
                a.push(it);
            }
        } else if (type === 'select' && isValidSelectQuery(operator, value)) {
            if(num) {
                a.push(i);
            } else {
                a.push(it);
            }
        } else if (type === 'country' && isValidSelectQuery(operator, value)) {
            if(num) {
                a.push(i);
            } else {
                a.push(it);
            }
        } else if (type === 'multi-select' && isValidMultiSelectQuery(operator, value)) {
            if(num) {
                a.push(i);
            } else {
                a.push(it);
            }
        } else if (type === 'boolean' && isValidBooleanQuery(operator, value)) {
            if(num) {
                a.push(i);
            } else {
                a.push(it);
            }
        }
    }
    return a;
}

export function FilterCalculator({match = 'any', sc = {}, conditions}) {
    const community = useContext(CommunityContext);
    const [members, setMembers] = useState({});
    const [loading, setLoading] = useState(false);

    const can_filter = conditions.length > 0;

    useEffect(function() {
        calc();
    }, [conditions,match]);

    async function filter() {
        let promises = [];

        // let's try getting all members, then running a comparison
        for (let i = 0; i < conditions.length; i++) {
            const condition = conditions[i];

            promises.push(buildFilterPromise(condition, community.uid));
        }

        // check if a condition is valud

        return await Promise.all(promises)
            .then(id_arrays => handleResults(id_arrays, match));
    }

    function calc() {
        setLoading(true);
        filter()
            .then(({final, steps}) => {
                setMembers(final);
                setLoading(false);
            })
    }

    if(loading) {
        return <div className="p-4">
            <InlineLoader />
        </div>
    }

    const len = Object.keys(members).length;

    if(len===0) {
        return <div className="p-4">
            <div className="text-center text-gray-600 text-sm">
                No matching members
            </div>
        </div>
    }

    return <div>
        <div className="mb-4">
            <div className="text-sm font-medium text-gray-700">{len} matching member{len!==1?"s":""} found</div>
        </div>
        <Frame members={members} cid={community.uid}/>
    </div>
}