import React, {useState} from 'react';
import {ATTextAction} from "../components/shared";
import {SearchIcon} from '@heroicons/react/solid';

import styled from "styled-components";
import {useIntl} from "react-intl";
import {Button, InlineLoader} from "../../core";
import {AdjustmentsIcon, XIcon} from "@heroicons/react/outline";
import {ATButtonBox} from "../views/select-view";

const IconBox = styled.span`
  display: inline-flex;
  padding-right: 4px;

  > svg {
    height: 16px;
  }
`;

const SearchInput = styled.input`
  border: none;
  outline: none;
  font-size: 15px;
  background: transparent;
  font-weight: 400;
`;

export function ATSearch({query, loading, setQuery}) {
    const [focus, setFocus] = useState(false);

    function renderSearch(q, f) {
        if (f) {
            return <SearchInput className='text-gray-800' onBlur={() => {
                if (q.length === 0) {
                    setFocus(false);
                }
            }} size={20} placeholder='Search' autoFocus value={q} onChange={(e) => {
                const v = e.target.value;
                let f = !v;
                setQuery(v,f);
            }}/>
        }
        return "Search";
    }

    return <div className="relative">
        <ATButtonBox icon={<SearchIcon/>} style={focus || query ? "default" : "alt"} onClick={() => setFocus(true)}>
            {renderSearch(query, focus)}
            {loading && query && <div className="absolute right-2 top-0 bottom-0 flex items-center">
                <InlineLoader mini padding=""/>
            </div>}
            {query && !loading && <div className="absolute right-2 top-0 bottom-0 flex items-center">
                <div onClick={() => {
                    setQuery("",true);
                    setTimeout(()=>{
                        setFocus(false);
                    },50)
                }} className="hover:text-gray-700 text-gray-500 h-svg-45">
                    <XIcon/>
                </div>
            </div>}
        </ATButtonBox>
    </div>
}