import React, {useState, useContext} from "react";
import {Button} from "../../../../library/components/core";
import styled from "styled-components";
import {makeProfilePrivate, makeProfilePublic} from "./helpers";

import {GlobalContext} from "../../global-context";

const Frame = styled.div`
        margin-top: 16px;
`;

const Buttons = styled.div`
   display: flex;
`;

const Text = styled.div`
      margin-bottom: 8px;
`;

const Item = styled.div`
     margin-bottom: 16px;
`;

export default function PrivitizeProfile(props) {

    const global = useContext(GlobalContext);

    const [loading, setLoading] = useState(false);
    const [is_private, setPrivate] = useState(global.user.is_private);

    const {user,addToast} = global;

    function profilePublic() {
        setLoading(true);
        const res = () => {
            setLoading(false);
            setPrivate(false);
            addToast({text: "Your Profile is now public", intent: 'success'})
        };
        makeProfilePrivate(res);
    }

    function profilePrivate() {
        setLoading(true);
        const res = () => {
            setLoading(false);
            setPrivate(true);
            addToast({text: "Your Profile is now private", intent: 'success'})
        };
        makeProfilePrivate(res);
    }

    let content = null;

    if (loading) {
        content = <div>
            Loading..
        </div>
    } else if (is_private) {
        content = <Item>
            <Text className='text-gray-800'>
                Your profile is currently <b>Private</b>, which means only your followers can see your Name, Profile
                Picture, Handle, and Bio as well as follow you. Everyone else can only see your Handle.
            </Text>
            <Buttons>
                <Button text={'Make my Profile Public'} onClick={profilePublic.bind(this)}/>
            </Buttons>
        </Item>
    } else {
        content = <Item>
            <Text className='text-gray-800'>
                Your profile is currently <b>Public</b>, which means any other Unaty user can see your Name, Profile
                Picture, Handle, and Bio as well as follow you.
            </Text>
            <Buttons>
                <Button text={'Make my Profile Private'} onClick={profilePrivate.bind(this)}/>
            </Buttons>
        </Item>
    }

    return <Frame>
        {content}
    </Frame>;
}