import React from "react";
import {getNewLocationValue, parseMemberLocation} from "../../../../member/helpers";
import {ProfileBadge} from "../../../../../../library/components/profile-badge";

export function LocationBadge({community={},member={},member_id=""}) {

    if(!member_id) {
        return null;
    }

    const loc = parseMemberLocation(member);

    if(!loc.text) {
        return <ProfileBadge type="location" text={"N/A"} />;
    }

    return <ProfileBadge type="location" emoji={loc.emoji} text={loc.text} />
}