import React from "react";
import Select, {components} from "react-select";
import styled from "styled-components";

const IconBox = styled.div`
 height: 24px;
    width: 24px;
    border-radius: 20%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    background-color: ${props => props.color};
  > svg {
    height: 16px;
  }
`;

const IconBoxMini = styled.div`
 height: 18px;
    width: 18px;
    border-radius: 20%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 12px;
    top: 10px;
    color: #fff;
    background-color: ${props => props.color};
  > svg {
    height: 12px;
  }
`;


const DescText = styled.span`
font-weight: 400;
font-size: 12px;
display:block;
`;

const ControlComponent = props => (
        <components.Control {...props} />
);

const SelectContainer = ({children, ...props}) => {
    return (
        <components.SelectContainer {...props}>
            {children}
        </components.SelectContainer>
    );
};

const SingleValue = props => {
const val = props.getValue()[0];
    const pad_left = val ? !!val.icon : false;
    return <div>
        {val.icon&&<IconBox color={val.color}>{val.icon}</IconBox>}
        <components.SingleValue {...props} pad_left={pad_left} />
    </div>
};

const Option = props => {
    const data = props.data;
    const pad_left = data ? !!data.icon : false;
    return <div style={{position:'relative'}}>
        {data.icon&&<IconBoxMini color={data.color}>{data.icon}</IconBoxMini>}
        <components.Option pad_left={pad_left} {...props} children={<span style={{display:'contents'}} className='text-gray-900'>{data.label}{data.desc&&<DescText className='text-gray-700'>{data.desc}</DescText>}</span>} />
    </div>
};

function getDropddownPadding(s,f) {
    if(s) {
        return '7px';
    } else if(f) {
        return '6px';
    } else {
        return '8px';
    }
}

function getFontSize(s,f) {
    if(s) {
        return '16px';
    } else if(f) {
        return '16px';
    } else {
        return '16px';
    }
}
function getMinHeight(m,s,f) {
    if(s) {
        return '34px';
    } else if(f) {
        return '32px';
    } else {
        return m;
    }
}
function getBoxShadow(focus,s,f) {
    if(focus) {
        return 'rgba(0,0,0,0) 0px 0px 0px 0px, rgba(58,151,212,0.36) 0px 0px 0px 4px, rgba(0,0,0,0) 0px 0px 0px 0px, rgba(60,66,87,0.16) 0px 0px 0px 1px, rgba(0,0,0,0) 0px 0px 0px 0px, rgba(0,0,0,0) 0px 0px 0px 0px, rgba(0,0,0,0) 0px 0px 0px 0px';
    } else if(s) {
        return '';
    } else if(f) {
        return '0 1px 2px 0 rgba(0, 0, 0, 0.05)';
    } else {
        return '0 1px 2px 0 rgba(0, 0, 0, 0.05)';
    }
}
// loadOptions
export const BasicSelect = ({onChange=()=>{}, usePortal = true, minHeight='40px', menuPortalTarget=null, autofocus=false, simple, filter, placeholder='', multi=false, searchable=false, disabled=false, loading=false, clearable=false, onBlur=()=>{}, options=[], value={}}) => {
    return <Select onBlur={onBlur} onChange={onChange} value={value} menuPlacement={'auto'}
                   isSearchable={searchable} autoFocus={autofocus} menuPortalTarget={menuPortalTarget?menuPortalTarget:usePortal?document.body:null}
                   components={{ControlComponent,Option,SelectContainer,SingleValue}}
                   styles={{
                       menuPortal: base => ({ ...base, zIndex: 9999 }),
                       singleValue: (base, state) => ({
                           ...base,
                           fontSize: getFontSize(simple,filter),
                           paddingLeft: state.pad_left ? '32px' : '',
                           margin: 0
                       }),
                       valueContainer: (base) => ({
                           ...base,
                           padding: '0 0 0 0.875rem'
                       }),
                       container: (base,state) => ({
                           ...base
                       }),
                       indicatorSeparator: base => ({
                          ...base,
                          display: 'none'
                       }),
                       dropdownIndicator: base => ({
                           ...base,
                           padding: getDropddownPadding(simple,filter)
                       }),
                       option: (base,state) => ({
                           ...base,
                           background: state.isSelected ? '#c5e1fb' : '',
                           paddingLeft: state.pad_left ? '38px' : ''
                       }),
                       control: (base, state) => ({
                           ...base,
                           outline: 'none',
                           borderRadius: '0.375rem',
                           boxShadow: getBoxShadow(state.isFocused,simple,filter),
                           border: state.isFocused?"1px solid #659adf":"1px solid #d4d4d8",
                           minHeight: getMinHeight(minHeight,simple,filter)
                       })
                   }}
                   isMulti={multi}
                   placeholder={placeholder}
                   isClearable={clearable}
                   isDisabled={disabled}
                   isLoading={loading}
                   options={options}/>;
};