import React from 'react';
import {Avatar} from "../../../core";
import {PhotoSelector} from "../../../old/photo-selector";
import {fv_getFormattedVal} from "../../../../utilities/format-values";
import {WCField} from "../index";

export function HWCSideSocial({data,important_social_fields,handleSave,editing,setEditing,id,member_type,meta}) {

    return <div className="w-full h-full flex flex-col">
        <div className="flex flex-col space-y-2 pt-4 px-3 pb-7">
            {important_social_fields.map(isf=>{
                const ff = isf.field.split(".")[1];
                const v = data.social[ff] ? data.social[ff] : "";
                return <div key={isf.field}>
                    <WCField handleSave={handleSave} type={isf.type} id={isf.field}
                             value_formatted={fv_getFormattedVal(isf.type, v, meta, {})} meta={meta}
                             editing={editing} placeholder={isf.placeholder}
                             setEditing={setEditing} value={v} label={isf.name?isf.name:isf.label}/>
                </div>
            })}
        </div>
    </div>
}