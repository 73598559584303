import React, {useContext, useEffect, useState} from 'react';
import {api_getPostReplies} from "../../../api/community/posts/get-post-replies";
import {CommunityContext} from "../community-context";
import {FeedItem, FeedItemAttachments} from "../../../library/components/posts-feed/feed-item";
import {useIsMobile} from "../../global/global-router";

export function RepliesContainer({
                                     replies_count = 0,
    post_id = ""
                                 }) {
    const is_mobile = useIsMobile();
    const community = useContext(CommunityContext);
    const [items, setItems] = useState([]);
    const [page, setPage] = useState(0);
    const [last_page_ref, setLastPageRef] = useState(null);

    function getReplies() {
        api_getPostReplies(community.uid,post_id,"",last_page_ref,page,25)
            .then(result=>{
                setItems(result.replies);
                setLastPageRef(result.last_ref);
            })
    }

    useEffect(function () {
        if(replies_count>0) {
            getReplies()
        }
    }, [replies_count,post_id])

    const feed_item_props = {
        is_mobile,
        community_handle: community.data.handle,
        community_uid: community.uid,
        activity_meta: {
            community
        },
        parent_post_id: post_id
    };

    return <div className="shared-top-border">
        {items.map(it=><div key={`${it.id}-${post_id}`}>
            <FeedItem {...it} padding={`px-4 py-2`} type="reply" {...feed_item_props} />
        </div>)}
    </div>
}