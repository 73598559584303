import {validateEmail} from "../../utilities/validation";
import {getPhoneNumberData} from "../../utilities/phone";
import {buildEmailOptions} from "../../../app/community/admin/member/edit-obj";

export function buildUserProfileSections(data,show) {
    let a = {}, ids = [];

    ids.push('about');

    a['about'] = {
        title: 'About',
        visibility: 'user-public',
        autocomplete: {},
        hidden_fields: {
            title: true,
            middle_name: true,
            show_title_middle_name: false,
        },
        fields: []
    };

    a['about'].fields.push({
        id: 'first_name',
        label: 'First Name',
        required: true,
        col_span: 1,
        suggestions: [],
        type: 'text',
        placeholder: 'George',
        field: 'about.first_name'
    });

    a['about'].fields.push({
        id: 'last_name',
        label: 'Last Name',
        required: true,
        col_span: 1,
        suggestions: [],
        type: 'text',
        placeholder: 'Washington',
        field: 'about.last_name'
    });

    if (data.about.title || data.about.middle_name || show.show_title_middle_name) {
        a['about'].fields.push({
            id: 'title',
            label: 'Title',
            col_span: 1,
            suggestions: [],
            type: 'text',
            placeholder: 'Mr.',
            field: 'about.title'
        });

        a['about'].fields.push({
            id: 'middle_name',
            label: 'Middle Name',
            col_span: 1,
            suggestions: [],
            type: 'text',
            placeholder: 'Carver',
            field: 'about.middle_name'
        });
    } else {
        a['about'].fields.push({
            id: 'show_title_middle_name',
            type: 'ACTION',
            col_span: 2,
            label: 'Add Title & Middle Name'
        });
    }

    a['about'].fields.push({
        id: 'birthday',
        label: 'Birthday',
        col_span: 2,
        type: 'birthday',
        placeholder: '',
        field: 'birthday'
    });

    a['about'].fields.push({
        id: 'gender',
        label: 'Sex',
        col_span: 2,
        type: 'gender',
        placeholder: '',
        field: 'gender'
    });

    a['about'].fields.push({
        id: 'bio',
        label: 'Bio',
        col_span: 2,
        type: 'textarea',
        placeholder: '',
        field: 'about.bio'
    });

    ids.push('contact');

    a['contact'] = {
        title: 'Contact',
        visibility: 'user-private',
        autocomplete: {},
        hidden_fields: {},
        fields: []
    };

    a['contact'].fields.push({
        id: 'email',
        required: true,
        cf_data: {
            options: {
                choices: buildEmailOptions(data)
            }
        },
        label: 'Email',
        col_span: 2,
        type: 'select-email',
        placeholder: 'myname@work.com',
        field: 'contact.email'
    });

    a['contact'].fields.push({
        id: 'phone',
        required: true,
        label: 'Phone',
        col_span: 2,
        type: 'phone',
        placeholder: '',
        field: 'contact.phone'
    });

    const main_phone_data = getPhoneNumberData(data.contact.data);

    if (!show.add_secondary_phone && !data.contact.secondary_phone && main_phone_data.valid) {
        a['contact'].fields.push({
            id: 'add_secondary_phone',
            type: 'ACTION',
            col_span: 2,
            label: 'Add Phone'
        });
    } else if (data.contact.secondary_phone || show.add_secondary_phone) {
        a['contact'].fields.push({
            id: 'secondary-phone',
            label: '2. Phone',
            col_span: 2,
            type: 'phone',
            placeholder: '',
            field: 'contact.secondary_phone'
        });
    }

    ids.push('address');

    a['address'] = {
        title: 'Address',
        visibility: 'user-private',
        autocomplete: {},
        hidden_fields: {},
        fields: []
    };

    a['address'].fields.push({
        id: 'address',
        label: 'Home Address',
        col_span: 2,
        type: 'address',
        placeholder: '',
        field: 'address'
    });

    ids.push('social');

    a['social'] = {
        title: 'Social',
        visibility: 'user-public',
        autocomplete: {},
        hidden_fields: {},
        fields: []
    };

    a['social'].fields.push({
        id: 'website',
        label: 'Website',
        col_span: 2,
        type: 'url',
        placeholder: '',
        field: 'social.website'
    });

    a['social'].fields.push({
        id: 'twitter',
        label: 'Twitter',
        col_span: 2,
        type: 'url',
        placeholder: '',
        field: 'social.twitter'
    });

    a['social'].fields.push({
        id: 'facebook',
        label: 'Facebook',
        col_span: 2,
        type: 'url',
        placeholder: '',
        field: 'social.facebook'
    });

    a['social'].fields.push({
        id: 'linkedin',
        label: 'LinkedIn',
        col_span: 2,
        type: 'url',
        placeholder: '',
        field: 'social.linkedin'
    });

    return {obj: a, ids: ids};
}