import React, {useState,useRef,useEffect} from 'react';
import {ATPopoverAddItem, ATPopoverContents, ATPopoverTitle, ATTextAction} from "../components/shared";
import { createPortal } from 'react-dom';
import Select from "../../old/select";
import {ATAddSort} from "./add-sort";
import {ATSortItem} from "./item";
import styled from "styled-components";
import {DragDropContext, Droppable, Draggable} from 'react-beautiful-dnd';
import {IconGrabHandle} from "../../../icons/custom/GrabHandle";
import {at_getFinalSort} from "./utilities";
import {useIntl} from "react-intl";
import {ActionWithIcon, Button, Field} from "../../core";
import {SortAscendingIcon, SortDescendingIcon} from "@heroicons/react/solid";
import {Popover2} from "@blueprintjs/popover2";

// up to 2 sorts
// cannot use the same field for sorting


const Content = styled.div`
`;

export const DNDList = styled.div`
    
`;

export const DNDItem = styled.div`
        display: grid;
    grid-template-columns: ${props=>props.hide?"1fr":"auto 1fr"};
`;

export const DragHandle = styled.div`
    outline: none !important;
        height: 44px;
    display: ${props=>props.hide?"none":"flex"};
    align-items: center;
    padding-left: 10px;
    width: 28px;
    > svg {
    opacity: ${props=>props.disabled?'0':'1'};
        height: 18px;
    }
`;

export const useDraggableInPortal = () => {
    const self = useRef({}).current;

    useEffect(() => {
        const div = document.createElement('div');
        div.style.position = 'absolute';
        div.style.pointerEvents = 'none';
        div.style.top = '0';
        div.style.width = '100%';
        div.style.height = '100%';
        self.elt = div;
        document.body.appendChild(div);
        return () => {
            document.body.removeChild(div);
        };
    }, [self]);

    return (render) => (provided, ...args) => {
        const element = render(provided, ...args);
        if (provided.draggableProps.style.position === 'fixed') {
            return createPortal(element, self.elt);
        }
        return element;
    };
};

function getButtonText(sort,defs) {
    if(!sort) {
        return "Add Sort"
    }
    const item = Object.values(defs.properties).filter(a=>a.field===sort.field)[0];
    if(!item) {
        console.warn('cannot find sort',sort)
        return <span>Sort</span>
    }
    return <span>Sort by <strong>{item.label}</strong></span>
}

export function ATSort({sort, build_options, definitions, all_sort_options, reorderSort, onAddSort, onEditSort, onRemoveSort}) {
    const {formatMessage: f} = useIntl();
    const [open_create, setCreateOpen] = useState(false);
    const [open_select, setSelectOpen] = useState(false);

    function handleOnDragEnd(result) {
        if (!result.destination) return;
        let current_sort = sort;

        let final_sort = at_getFinalSort(current_sort,result);
        reorderSort(final_sort);
    }

    const renderDraggable = useDraggableInPortal();

    const disable_drag = sort.length<2;

    const primary_sort = sort[0] ? sort[0] : null;

    const add = sort.length < 2 &&
            <Popover2 onInteraction={(nextOpenState,e)=>{
                if(nextOpenState) {
                    setCreateOpen(true);
                } else {
                    if(e&&e.target&&(e.target.id.indexOf('option')===-1&&e.target.className.indexOf('option')===-1)) {
                        setCreateOpen(false);
                    }
                }
            }} content={<ATAddSort options={build_options} addSort={(sort) => {
                setCreateOpen(false);
                onAddSort(sort)
            }}/>} isOpen={open_create} onOpen={() => setCreateOpen(true)}
                      placement='bottom-start' interactionKind='click' minimal usePortal
                      popoverClassName='minimal-popover my-1' targetClassName='popover-target-span'>
                <ActionWithIcon text="Add" inverse onClick={()=>setCreateOpen(true)} />
            </Popover2>

    const content = <div >
<Field label={getButtonText(primary_sort,definitions)} corner_hint={add}>
        <DragDropContext onDragEnd={handleOnDragEnd}>
            <Content>

                <Droppable droppableId="view-sort">
                    {(provided) => (
                        <DNDList {...provided.droppableProps} ref={provided.innerRef}>
                            {sort.map((s, i) => {
                                return <Draggable isDragDisabled={disable_drag} key={s.field} draggableId={s.field} index={i}>
                                    {renderDraggable((provided)=>{
                                        return <DNDItem ref={provided.innerRef} hide={sort.length<2}
                                                        key={s.field} {...provided.draggableProps}>
                                            <DragHandle disabled={disable_drag} hide={sort.length<2}
                                                        className={`text-gray-700 hover-opacity`} {...provided.dragHandleProps}>
                                                <IconGrabHandle/>
                                            </DragHandle>
                                            <ATSortItem can_remove={sort.length>1} options={all_sort_options} change_options={build_options}
                                                        editItem={(v) => onEditSort(i, v)}
                                                        onRemove={() => onRemoveSort(i)}
                                                        {...s} />
                                        </DNDItem>
                                    })}
                                </Draggable>
                            })}
                            {provided.placeholder}
                        </DNDList>
                    )}
                </Droppable>

            </Content>
        </DragDropContext>

</Field>
    </div>;

    return content;
}