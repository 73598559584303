import CommunityHome from './home';
import AdminSettings from './admin/settings';
import CommunityCalendar from './calendar';
import CommunityPreferences from "./member-preferences";
import {MemberProfilePage} from "./member";
import DirectoryMembers from "./directory";
import {GroupPage} from "./group";
import RolePage from "./role";
import CommunityInterests from "./interests";
import CommunityPolicies from "./policies";
import {CommunityPolicyPage} from "./policy";
import CommunityManagePolicyPage from "./policy/manage";
import CommunityInterestPage from "./interest";
import {OnboardingWrapper} from "../../library/components/education/onboarding/wrapper";
import {JoinCommunityPage} from "../entity/join";
import {AdminMemberWrapper} from "./admin/member/wrapper";
import {CommunitySearchPage} from "./search";
import CommunitySignIn from "../auth/sign-in/community";
import {CommunityPost} from "./posts";
import {CommunityGroupsPage} from "./my-groups";
import {CommunityMockPage} from "./mock";
import {CommunityNotifications} from "./notifications";
import {MemberIdRedirect} from "./member/member-id-redirect";
import {CommunityLeaders} from "./leaders";
import {CAdminTesting} from "./admin/settings/testing";
import CommunityMessages from "./messages";
import CommunityExplore from "./explore";
import {AdminRoleWrapper} from "./admin/role/wrapper";
import {AdminImportWrapper} from "./admin/import/wrapper";

export const community_routes_nonmember = [
    {
        type: 'route',
        key: 'sign-in-nonemmeber',
        path: '/:handle/sign-in',
        layout: 'none',
        exact: true,
        component: CommunitySignIn
    },
    {
        type: 'redirect',
        key: 'communities-redirect',
        from: '*',
        to: '/:handle/sign-in'
    }
];

export const community_routes_member = [
    {
        type: 'route',
        key: 'home-route',
        path: '/:handle/home',
        layout: 'app',
        exact: true,
        component: CommunityHome
    },
    {
        type: 'route',
        key: 'mock',
        path: '/:handle/mock',
        layout: 'app',
        exact: true,
        component: CommunityMockPage
    },
    {
        type: 'route',
        key: 'home-route',
        path: '/:handle/onboarding',
        layout: 'app',
        exact: true,
        component: OnboardingWrapper
    },
    {
        type: 'route',
        key: 'join-route',
        path: '/:handle/join',
        layout: 'app',
        exact: true,
        component: CommunitySignIn
    },
    {
      type: 'redirect',
      key: 'member-onboarding',
      path: '/:handle/onboarding-member',
        layout: 'app',
        from: '/:handle/onboarding-member',
        to: '/:handle/onboarding?flow=member'
    },
    {
        type: 'route',
        key: 'interests-route',
        path: '/:handle/interests',
        layout: 'app',
        exact: true,
        component: CommunityInterests
    },
    {
        type: 'route',
        key: 'interests-route',
        path: '/:handle/interests/:interest',
        layout: 'app',
        exact: true,
        component: CommunityInterestPage
    },
    {
        type: 'route',
        key: 'post-route',
        path: '/:handle/post/:postId',
        layout: 'app',
        exact: true,
        component: CommunityPost
    },
    {
        type: 'route',
        key: 'policies-route',
        path: '/:handle/policies',
        layout: 'app',
        exact: true,
        component: CommunityPolicies
    },
    {
        type: 'route',
        key: 'policies-route-single',
        path: '/:handle/policies/:slug',
        layout: 'app',
        exact: true,
        component: CommunityPolicyPage
    },
    {
        type: 'route',
        key: 'policies-route-single',
        path: '/:handle/policies/:slug/manage',
        layout: 'app',
        exact: true,
        component: CommunityManagePolicyPage
    },
    {
        type: 'route',
        key: 'members-route',
        path: '/:handle/directory',
        layout: 'app',
        exact: true,
        component: DirectoryMembers
    },

    {
        type: 'route',
        key: 'groups-route',
        path: '/:handle/groups',
        layout: 'app',
        exact: true,
        component: CommunityGroupsPage
    },
    {
        type: 'route',
        key: 'roles-route',
        path: '/:handle/leaders',
        layout: 'app',
        exact: true,
        component: CommunityLeaders
    },
    {
        type: 'route',
        key: 'demo-route',
        path: '/:handle/_demo',
        layout: 'app',
        exact: true,
        component: CAdminTesting
    },
    {
        type: 'route',
        key: 'roles-route',
        path: '/:handle/search',
        layout: 'app',
        exact: true,
        component: CommunitySearchPage
    },
    {
        type: 'redirect',
        key: 'members-redirect',
        from: '/:handle/directory',
        to: '/:handle/directory'
    },
    {
        type: 'route',
        key: 'mh-route',
        path: '/:handle/member/:memberhandle',
        layout: 'app',
        exact: true,
        component: MemberProfilePage
    },
    {
        type: 'route',
        key: 'mh-route',
        path: '/:handle/member-id/:memberid',
        layout: 'app',
        exact: true,
        component: MemberIdRedirect
    },
    {
        type: 'route',
        key: 'page-role',
        path: '/:handle/role/:id',
        layout: 'app',
        component: RolePage
    },
    {
        type: 'route',
        key: 'group-route',
        path: '/:handle/group/:id',
        layout: 'app',
        component: GroupPage
    },
    {
        type: 'route',
        key: 'prefs-route',
        path: '/:handle/preferences',
        layout: 'app',
        component: CommunityPreferences
    },
    {
        type: 'route',
        key: 'calendar-route',
        path: '/:handle/calendar',
        layout: 'app',
        exact: true,
        component: CommunityCalendar
    },
    {
        type: 'route',
        key: 'explore-route',
        path: '/:handle/explore',
        layout: 'app',
        exact: false,
        component: CommunityExplore
    },
    {
        type: 'route',
        key: 'directory-route',
        path: '/:handle/directory',
        layout: 'app',
        exact: false,
        component: DirectoryMembers
    },
    {
        type: 'route',
        key: 'messages-route',
        path: '/:handle/messages',
        layout: 'app',
        exact: true,
        component: CommunityMessages
    },
    {
        type: 'route',
        key: 'notifications-route',
        path: '/:handle/notifications',
        layout: 'app',
        exact: true,
        component: CommunityNotifications
    },
    {
        type: 'route',
        key: 'admin-member',
        path: '/:handle/admin/member/:id',
        layout: 'app',
        component: AdminMemberWrapper
    },
    {
        type: 'route',
        key: 'admin-role',
        path: '/:handle/admin/role/:id',
        layout: 'app',
        component: AdminRoleWrapper
    },
    {
        type: 'route',
        key: 'admin-import',
        path: '/:handle/admin/members/import',
        layout: 'app',
        component: AdminImportWrapper
    },
    {
        type: 'route',
        key: 'join-route',
        path: '/:handle/join',
        layout: 'app',
        exact: true,
        component: JoinCommunityPage
    },
    {
        type: 'route',
        key: 'admin-settings-route',
        path: '/:handle/admin/settings',
        layout: 'app',
        component: AdminSettings
    },
    {
        type: 'redirect',
        key: 'communities-redirect',
        from: '*',
        to: '/:handle/home'
    }
];