import React, {useEffect, useState} from "react";
import {PageContainer} from "../../../library/components/old/page-container";
import {PageHeader} from "../../../library/components/core";
import {SearchResults} from "./search-results";

export function GlobalSearchPage() {
    const urlParamsInit = new URLSearchParams(window.location.search);
    const [q,setQ] = useState(urlParamsInit.get('query'));
    const [t,setT] = useState(urlParamsInit.get('type'));

    return <PageContainer>
        <PageHeader title={`Search for "${q}"`} />
        <div className='border-top pt-2 pb-10'>
            <SearchResults q={q} t={t} />
        </div>
    </PageContainer>
}