import React, {Component, useContext, useState} from "react";

import {EntityHeader, PageHeader} from "../../../../../library/components/core";
import {PageContainer} from "../../../../../library/components/old/page-container";
import EditFieldsSection from "../../../../../library/components/old/edit-fields-section";
import {CommunityContext} from "../../../community-context";
import {StandardPageHeight} from "../../../../../library/playground/standard-page-framework";

const plans = {
    'basic': {
        name: 'Basic Plan'
    }
};

export default function BuildModeAdminPage(props) {
    const community = useContext(CommunityContext);
   
        const {history} = props;

        const base_link = `/${community.data.handle}/admin/settings`;

        const obj = {
            base_link: `/${community.data.handle}/admin/settings`,
            data: community.data,

            endpoint: '/communities/update-build-mode',
            update_fields: ['preferences'],

            sections: [
                {
                    title: 'General',
                    fields: [
                        {
                            name: 'Build Mode',
                            type: 'switch',
                            field: 'preferences.build_mode'
                        }
                    ]
                }
            ]
        };


        return (<StandardPageHeight content>
                <EntityHeader title="Build Mode" goBackTo={()=>history.push(`/${community.data.handle}/admin/settings`)} />

                <EditFieldsSection member_types={[]} history={history} {...obj} />
            </StandardPageHeight>
        );
}
