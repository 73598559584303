import React, {Component} from 'react';
import styled from "styled-components";

const BodyFrame = styled.div`
      border-bottom: 1px solid #e3e8ee;
      position: relative;
      display: grid;
      :hover {
        background: #F7F8FA;
        cursor: pointer;
      }
`;

const HeaderFrame = styled.div`
      display: grid;
`;


export const HeaderRow = ({children,style}) => {
    return <HeaderFrame style={style}>
        {children}
    </HeaderFrame>
};


export const BodyRow = ({children,onClick=()=>{},style}) => {
    return <BodyFrame onClick={onClick} className='actions-popup-container' style={style}>
        {children}
    </BodyFrame>
};