import {us_all_quick_actions} from "./quick-actions";
import {us_all_navigation_routes} from "./navigation";
import {BriefcaseIcon, ChartPieIcon, FolderIcon, SearchIcon} from "@heroicons/react/solid";
import React from "react";
import {DEFAULT_COMMUNITY_LOGO, DEFAULT_USER_PHOTO} from "../../../config/defaults";
import {MemberTypeIcon} from "../icon-lower-right-addon/special";
import {us_getRecentSearches} from "./recent-searches";
import {us_getQueryMeta} from "./async-search";
import {getFileTypeIcon} from "../old/file-uploader/helpers";
import {icon_reference} from "../reorganize/iconography";
import {USGroupTypeIcon} from "./data-mocks";
import {getEntityTypeIcon} from "../entity-list";
import {fuzzyContains, levenstein} from "../suggestions/segment-suggestions";

function labelTextFilter(query, label, similar = []) {
    if (typeof label === 'string') {
        const label_match = label.toLowerCase().indexOf(query) !== -1;
        if (label_match) {
            return true;
        }
    } else {
        for (let i = 0; i < label.length; i++) {
            const label_item = label[i];
            const li_match = label_item.toLowerCase().indexOf(query) !== -1;
            if (li_match) {
                return true;
            }
        }
    }
    if (similar.length > 0) {
        for (let k = 0; k < similar.length; k++) {
            const similar_item = similar[k];
            const si_match = similar_item.toLowerCase().indexOf(query) !== -1;
            if (si_match) {
                return true;
            }
        }
    }
    return false;
}

function contextFilter(arr, query, context, limit = 3, base_count = 0) {
    let results = [];
    const base = [...arr];
    if (query) {
        results = base.filter(a => labelTextFilter(query, a.label, a.similar)).filter(a => context.includes(a.context));
    } else {
        results = base.filter(a => context.includes(a.context));
    }

    const len = results.length;
    const cutoff = len > limit ? limit : len;
    return results.slice(0, cutoff).map((it, k) => {
        return {
            ...it,
            id: base_count + k
        }
    })
}

function getRecentSearches(ctx_id, query, base_count) {
    const raw = us_getRecentSearches(ctx_id);

    const final = raw.filter(a => {
        if (query) {
            return a.toLowerCase().indexOf(query) !== -1;
        } else {
            return a;
        }
    });

    const cut_len = final.length > 4 ? 4 : final.length;

    const arr = final.slice(0, cut_len);

    return arr.map((it, k) => {
        return {
            data_type: 'recent_search',
            label: it,
            meta: '',
            note: '',
            disabled: false,
            icon: {
                type: 'icon',
                icon: <SearchIcon/>
            },
            onClick: (h, ctx, actions) => {
                actions.setQuery(it);
            },
            id: base_count + k
        }
    });
}

const qs_path_ref = {
    'member_type': 'admin/settings/member-types',
    'role': 'role',
    'team': 'group'
};

function getQRIcon(data_type, data) {
    if (data_type === 'role') {
        return {
            type: 'icon',
            icon: getEntityTypeIcon('roles')
        }
    } else if (data_type === 'member_type') {
        return {
            type: 'icon',
            icon: getEntityTypeIcon('member-types')
        }
    } else if (data_type === 'filter') {
        return {
            type: 'icon',
            icon: getEntityTypeIcon('segments')
        }
    } else if (data_type === 'community') {
        return {
            type: 'image',
            url: data.profile_picture ? `${data.profile_picture}_small?alt=media` : `${DEFAULT_COMMUNITY_LOGO}_small?alt=media`
        }
    }
    const icon_ref = icon_reference[data_type];
    if (!icon_ref) {
        console.error('no data', data, data_type)
    }
    return {
        type: 'icon',
        icon: icon_ref.icon
    }
}

function buildQuickResult({label, data_type, id, data}, simple) {
    const oc = (h, ctx) => {
        if (data_type === 'community') {
            h.push(`/${id}/home`)
        } else {
            h.push(`/${ctx.community_handle}/${qs_path_ref[data_type]}/${id}`)
        }

    };
    let b = {
        label,
        data_id: id,
        data_type,
        onClick: oc,
        icon: getQRIcon(data_type, data),
    };
    if (simple) {
        return b;
    }
    return {
        ...b,
        meta: data_type === 'role' ? (data.assignee_data.name ? data.assignee_data.name : "Unassigned") : null
    }
}

function getAllowed(arr,allowed,type) {
    if(!allowed||!allowed[type]) {
        return arr;
    }
    let final = [...arr];

    return final.filter(b=>{
        return allowed[type][b.id];
    })
}

export function us_buildQuickSearches(query, context, qs_results, base_count, max = 4, simple = true, allowed) {
    const {teams = [], member_types = [], roles = [], communities = []} = qs_results;
    let ta, mta, ra, ca;

    ca = getAllowed(communities.filter(a => a.label.toLowerCase().indexOf(query) !== -1),allowed,"communities");
    ra = getAllowed(roles.filter(a => a.label.toLowerCase().indexOf(query) !== -1),allowed,"roles");
    ta = getAllowed(teams.filter(a => a.label.toLowerCase().indexOf(query) !== -1),allowed,"teams");
    mta = getAllowed(member_types.filter(a => a.label.toLowerCase().indexOf(query) !== -1),allowed,"member_types");
    let final = [];
    if (context.includes('member')) {
        final = final.concat(ra);
        final = final.concat(ta);
    }
    if (context.includes('admin')) {
        final = final.concat(mta);
    }
    if (context.includes('user')) {
        final = final.concat(ca);
    }
    if (context.includes('group')) {
        final = final.concat(mta);
        final = final.concat(ra);
    }
    if (context.includes('group-mods')) {
        final = final.concat(ra);
    }
    if (context.includes('team')) {
        final = final.concat(ra);
    }
    const len = final.length;
    const cutoff = len > max ? max : len;
    return final.slice(0, cutoff).map((it, k) => {
        return {
            ...buildQuickResult(it, simple),
            id: base_count + k
        }
    })
}

// need transform pipelines
function transformDataEntry(type, data_id, id, entry, simple) {
    let b;
    switch (type) {
        case 'members':
            b = {
                data_type: 'member',
                data_id,
                id,
                handle: entry.handle,
                label: entry.name,
                icon: {
                    type: 'image',
                    url: entry.profile_picture ? `${entry.profile_picture}_small?alt=media` : `${DEFAULT_USER_PHOTO}_small?alt=media`
                },
            };
            if (simple) {
                return {
                    ...b
                }
            }
            return {
                ...b,
                meta: entry.email,
                onClick: (h, ctx) => {
                    h.push(`/${ctx.community_handle}/member/${entry.handle}`)
                },
            }
        case 'roles':
            return {
                data_type: 'role',
                data_id,
                id,
                label: entry.name,
                meta: entry.email, // assignee / unassigned
                icon: {
                    type: 'image',
                    url: `${DEFAULT_USER_PHOTO}_small?alt=media`
                },
                onClick: (h, ctx) => {
                    h.push(`/${ctx.community_handle}/role/${data_id}`)
                },
            }
        case 'member_type':
            return {
                data_type: 'member_type',
                data_id,
                id,
                label: entry.name,
                meta: '',
                icon: {
                    type: 'icon',
                    icon: <MemberTypeIcon/>
                },
                onClick: (h, ctx) => {
                    h.push(`/${ctx.community_handle}/admin/settings/member-types/${data_id}`)
                },
            }
        case 'teams':
            return {
                data_type: 'team',
                data_id,
                id,
                label: entry.name,
                meta: '',
                icon: {
                    type: 'icon',
                    icon: <USGroupTypeIcon name={entry.name} emoji={entry.emoji} profile_picture={entry.profile_picture}
                                           profile_picture_color={entry.profile_picture_color}/>
                },
                onClick: (h, ctx) => {
                    h.push(`/${ctx.community_handle}/group/${data_id}`)
                },
            }
        case 'files':
            return {
                data_type: 'file',
                data_id,
                id,
                label: entry.name,
                meta: '',
                icon: {
                    type: 'icon',
                    icon: getFileTypeIcon(entry.type)
                },
                onClick: (h, ctx) => {
                    h.push(`/${ctx.community_handle}/explore/drive/folder/${entry.folder}/file/${data_id}`)
                },
            }
        case 'folders':
            return {
                data_type: 'folder',
                data_id,
                id,
                label: entry.name,
                meta: '',
                icon: {
                    type: 'icon',
                    icon: <FolderIcon/>
                },
                onClick: (h, ctx) => {
                    h.push(`/${ctx.community_handle}/explore/drive/folder/${data_id}`)
                },
            }
        case 'groups':
            return {
                data_type: 'group',
                data_id,
                id,
                label: entry.name,
                meta: '',
                icon: {
                    type: 'icon',
                    icon: <USGroupTypeIcon name={entry.name} emoji={entry.emoji} profile_picture={entry.profile_picture}
                                           profile_picture_color={entry.profile_picture_color}/>
                },
                onClick: (h, ctx) => {
                    h.push(`/${ctx.community_handle}/group/${data_id}`)
                },
            }
        case 'segments':
            return {
                data_type: 'filter',
                data_id,
                id,
                label: entry.name,
                //  meta: '3 members',
                icon: {
                    type: 'icon',
                    icon: getEntityTypeIcon('segments')
                },
                onClick: (h, ctx) => {
                    h.push(`/${ctx.community_handle}/admin/settings/segment/${data_id}`)
                },
            }
        case 'smart_views':
            return {
                data_type: 'smart_view',
                data_id,
                id,
                label: entry.name,
                icon: {
                    type: 'icon',
                    icon: <ChartPieIcon/>
                },
            }
        case 'users':
            return {
                data_type: 'user',
                data_id,
                id,
                label: entry.name,
                meta: `@${entry.handle}`,
                icon: {
                    type: 'image',
                    url: entry.profile_picture ? `${entry.profile_picture}_small?alt=media` : `${DEFAULT_USER_PHOTO}_small?alt=media`
                },
                onClick: (h, ctx) => {
                    h.push(`/${entry.handle}`)
                },
            }
        case 'communities':
            return {
                data_type: 'community',
                data_id,
                id,
                label: entry.name,
                meta: `@${entry.handle}`,
                icon: {
                    type: 'image',
                    url: entry.profile_picture ? `${entry.profile_picture}_medium?alt=media` : `${DEFAULT_COMMUNITY_LOGO}_small?alt=media`
                },
                onClick: (h, ctx) => {
                    h.push(`/${entry.handle}`)
                },
            }
        default:
            return null
    }
}

function sortByStrength(arr,query,meta) {
    let b = [];

    arr.forEach(it=>{
        const nit = {...it};
        if(meta&&meta.query_type==='first-last') {
            nit.score = levenstein(it.search_name,query)
        } else {
            nit.score = levenstein(it.match_str,query)
        }
        b.push(nit);
    });

    return b.sort((a, c) => (a.score > c.score) ? 1 : -1);
}

export function us_buildDataTypeResults(type, query, base_count, items = {}, simple = false, limit = 4, meta={}) {
    const values = Object.values(items);

    let arr = [];

    const final = values.filter(a => {
        if(meta&&meta.query_type!=='standard'&&meta.cleaned_query) {
            const sp = meta.cleaned_query.split(" ");
            let b = a.match_str.toLowerCase().indexOf(query) !== -1;
            sp.forEach(word=>{
                if(a.match_str.toLowerCase().indexOf(word) !== -1) {
                    b = true;
                }
            })
            return b;
        } else {
            return a.match_str.toLowerCase().indexOf(query) !== -1;
        }
    });

    const sorted_by_strength = sortByStrength(final,query,meta);

    for (let i = 0; i < sorted_by_strength.length; i++) {
        const entry = sorted_by_strength[i];
        arr.push(transformDataEntry(type, entry.id, (base_count + i), entry, simple));
    }

    // should sort by already added being downgraded

    let ff = [...arr].slice(0, limit);

    /*
    if(ff.length>2) {
        ff.push({
            data_type: 'see_all_results',
            label: 'See all results',
            meta: '',
            note: '',
            disabled: false,
            icon: {
                type: 'icon',
                icon: <SearchIcon />
            },
            id: base_count+3
        })
    }
     */
    return ff;
}

export function us_buildResults(query, context = ['member', 'owner', 'admin'], qs_results = {}, items = {}, ctx_id) {
    let sections = [];
    let count = 0;
    let qa = [], qs = [], rc = [], nv = [];

    const meta = us_getQueryMeta(query);

    qa = contextFilter(us_all_quick_actions, meta.final_query, context, 3, count);
    if (qa.length > 0) {
        count += qa.length;
        sections.push({
            id: 'quick-actions',
            title: 'Quick Actions',
            items: qa
        })
    }

    if (meta.final_query) {
        // quick searches
        qs = us_buildQuickSearches(meta.final_query, context, qs_results, count, 4);
        if (qs.length > 0) {
            count += qs.length;
            sections.push({
                id: 'quick-search',
                title: null,
                items: qs
            })
        }
    }

    // show live results
    const result_types = Object.keys(items);
    // id: object of id:data

    let type_data, type_results;
    if (result_types.includes('members') && meta.final_query) {
        type_data = items['members'];
        type_results = us_buildDataTypeResults('members', meta.final_query, count, type_data, false, 4, meta);
        if (type_results.length > 0) {
            count += type_results.length;
            sections.push({
                id: `live-members`,
                title: null,
                items: type_results
            })
        }
    }

    if (result_types.includes('segments') && meta.final_query) {
        type_data = items['filter'];
        type_results = us_buildDataTypeResults('segments', meta.final_query, count, type_data);
        if (type_results.length > 0) {
            count += type_results.length;
            sections.push({
                id: `live-segments`,
                title: null,
                items: type_results
            })
        }
    }

    if (result_types.includes('groups') && meta.final_query) {
        type_data = items['groups'];
        type_results = us_buildDataTypeResults('groups', meta.final_query, count, type_data);
        if (type_results.length > 0) {
            count += type_results.length;
            sections.push({
                id: `live-groups`,
                title: null,
                items: type_results
            })
        }
    }

    if (result_types.includes('files') && meta.final_query) {
        type_data = items['files'];
        type_results = us_buildDataTypeResults('files', meta.final_query, count, type_data);
        if (type_results.length > 0) {
            count += type_results.length;
            sections.push({
                id: `live-files`,
                title: null,
                items: type_results
            })
        }
    }

    if (result_types.includes('folders') && meta.final_query) {
        type_data = items['folders'];
        type_results = us_buildDataTypeResults('folders', meta.final_query, count, type_data);
        if (type_results.length > 0) {
            count += type_results.length;
            sections.push({
                id: `live-folders`,
                title: null,
                items: type_results
            })
        }
    }

    if (result_types.includes('users') && meta.final_query) {
        type_data = items['users'];
        type_results = us_buildDataTypeResults('users', meta.final_query, count, type_data);
        if (type_results.length > 0) {
            count += type_results.length;
            sections.push({
                id: `live-users`,
                title: null,
                items: type_results
            })
        }
    }

    if (result_types.includes('communities') && meta.final_query) {
        type_data = items['communities'];
        type_results = us_buildDataTypeResults('communities', meta.final_query, count, type_data);
        if (type_results.length > 0) {
            count += type_results.length;
            sections.push({
                id: `live-communities`,
                title: null,
                items: type_results
            })
        }
    }

    if (!query) {
        rc = getRecentSearches(ctx_id, meta.final_query, count);
        if (rc.length > 0) {
            count += rc.length;
            sections.push({
                id: 'recent-searches',
                title: 'Recent Searches',
                items: rc
            })
        }
    }

    // go by prio
    nv = contextFilter(us_all_navigation_routes, meta.final_query, context, 3, count);

    if (nv.length > 0) {
        count += nv.length;
        sections.push({
            id: 'navigational',
            title: null,
            items: nv
        })
    }

    return sections;
}