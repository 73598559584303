export const week_days = [
    {
        value: 'sunday',
        label: 'Sunday'
    },
    {
        value: 'monday',
        label: 'Monday'
    },
    {
        value: 'tuesday',
        label: 'Tuesday'
    },
    {
        value: 'wednesday',
        label: 'Wednesday'
    },
    {
        value: 'thursday',
        label: 'Thursday'
    },
    {
        value: 'friday',
        label: 'Friday'
    },
    {
        value: 'saturday',
        label: 'Saturday'
    }
];

export const week_starts_on = [
    {
        value: 'sunday',
        label: 'Sunday'
    },
    {
        value: 'monday',
        label: 'Monday'
    }
];

export const week_days_object = {
  'sunday': "Sunday",
  "monday": "Monday",
  "tuesday": "Tuesday",
  "wednesday": "Wednesday",
    "thursday": "Thursday",
    "friday": "Friday",
    "saturday": "Saturday"
};