import React, {useContext, useEffect} from "react";
import {useHistory} from "react-router-dom";
import {CommunityContext} from "../../../../../app/community/community-context";
import {OnboardingFrame} from "../frame";
import {ObScWelcomeToCommunity} from "../screens/welcome-to-community";

import {ObScMyInterests} from "../screens/my-interests";
import {ObScYourProfile} from "../screens/your-profile";
import {CommunityCreateSplashImage, ObScCommunityCreateSplash} from "../screens/community-create-splash";
import {ObScCommunityDefaults} from "../screens/community-defaults";
import {ObScSetupMemberTypes} from "../screens/setup-member-types";
import {ObScAddAdmins} from "../screens/add-admins";
import {ObScSetupTeamsAndRoles} from "../screens/setup-teams-and-roles";
import {ObScReviewOwnerPolicies} from "../screens/review-owner-policies";
import {MembershipCardPreview} from "../../../reorganize/membership-card-designer";
import {CommunityCard} from "../../../reorganize/community-card";
import {ObScCustomFields} from "../screens/custom-fields";

function getMemberType(community) {
    if(community.member_types[community.member.member_type]) {
        return community.member_types[community.member.member_type].singular;
    } else {
        return '';
    }
}

function buildOnboardingData(flow,community,history) {
    if(flow==='member') {
        const card_preview_data = {
            member: {
                member_type: getMemberType(community),
                name: community.member.name,
                member_id: community.member_id
            },
            community: {
                logo: community.data.profile_picture?`${community.data.profile_picture}?alt=media`:"",
                emoji: community.data.emoji,
                name: community.data.name
            }
        }
        const card_preview_style = community.data && community.data.member_card_design ? community.data.member_card_design : {background: {color:community.data?community.data.color:"lightgrey",type:'color',image:''}};

        let final_steps;

        if(community.custom_fields && Object.keys(community.custom_fields).length>0) {
            final_steps = [
                'welcome-to-community',
                'your-profile',
                'custom-fields',
                'my-interests',

                ];
        } else {
            final_steps = [
                'welcome-to-community',
                'your-profile',
                'my-interests',

            ];
        }

        return {
            name: 'Community Member Onboarding',
            id: 'member-onboarding',
            steps: [
                ...final_steps
            ],
            onSkip: () => {
                // todo call api
                history.push(`/${community.data.handle}/home?prev=onboarding-skipped`)
            },
            onComplete: () => {
                history.push(`/${community.data.handle}/home?prev=onboarding-complete`)
            },
            step_data: {
                'welcome-to-community': {
                    title: `Welcome to ${community.data.name} 🎉`,
                    subtitle: 'As a member you now have access to our private community hub',
                    layout: 'featured',
                    component: <ObScWelcomeToCommunity/>,
                    cancel_text: ``,
                    featured_component: <div className="h-full flex flex-col">
                        <div className="h-36 sm:h-56 bg-gray-200">
                            <div className="h-40 w-72 sm:h-64 sm:w-96 mx-auto pt-12">
                                <CommunityCard card_obj={card_preview_data} card_style={card_preview_style} onClick={()=>{}} />
                            </div>
                        </div>
                        <div></div>
                    </div>,
                    onCancel: (p, fns) => {
                        fns.nextStep();
                    },
                    confirm_text: 'Next',
                    onConfirm: (p, fns) => {
                        fns.nextStep();
                        // p = payload of settings from that step for further processing
                    }
                },
                'custom-fields': {
                    title: `A few last questions`,
                    subtitle: '',
                    layout: 'standard',
                    component: <ObScCustomFields/>,
                    cancel_text: ``,
                    onCancel: (p, fns) => {
                        fns.nextStep();
                    },
                    confirm_text: 'Next',
                    onConfirm: (p, fns) => {
                        fns.nextStep();
                        // p = payload of settings from that step for further processing
                    }
                },
                'my-interests': {
                    title: 'My Interests',
                    subtitle: 'Select up to 15 of your interests',
                    layout: 'standard',
                    component: <ObScMyInterests/>,
                    cancel_text: ``,
                    onCancel: (p, fns) => {
                        fns.nextStep();
                    },
                    confirm_text: 'Done',
                    onConfirm: (p, fns) => {
                        fns.nextStep();
                        // p = payload of settings from that step for further processing
                    }
                },
                'your-profile': {
                    title: 'Your Profile',
                    subtitle: 'Tell us a little about yourself for your profile',
                    layout: 'standard',
                    component: <ObScYourProfile/>,
                    cancel_text: `I'll do this later`,
                    onCancel: (p, fns) => {
                        fns.nextStep();
                    },
                    confirm_text: 'Next',
                    onConfirm: (p, fns) => {
                        fns.nextStep();
                        // p = payload of settings from that step for further processing
                    }
                },
            }
        };
    } else if(flow==='setup-community') {
        return {
            name: 'Owner Community Setup',
            id: 'owner-setup',
            steps: [
                'community-create-congrats',
                'accept-owner-policies',
                'your-profile',
                'member-types',
              //  'roles-teams',
                'community-defaults',
                'add-admins',
                'design-member-profile'
            ],
            onSkip: () => {
                history.push(`/${community.data.handle}/home?prev=onboarding-skipped`)
            },
            onComplete: () => {
                history.push(`/${community.data.handle}/admin/settings/designer/member-profile?prev=onboarding-complete`)
            },
            step_data: {
                'community-create-congrats': {
                    title: `${community.data.name} is now on Unaty 🚀`,
                    subtitle: 'Now let\'s get things setup before inviting members',
                    layout: 'right-image',
                    image: <CommunityCreateSplashImage color={community.data.branding.color} logo={community.data.profile_picture} emoji={community.data.emoji} name={community.data.name} />,
                    component: <ObScCommunityCreateSplash/>,
                    cancel_text: ``,
                    onCancel: (p, fns) => {
                        fns.nextStep();
                    },
                    confirm_text: 'Get Started',
                    onConfirm: (p, fns) => {
                        fns.nextStep();
                        // p = payload of settings from that step for further processing
                    }
                },
                'accept-owner-policies': {
                    title: 'Community Owner Policies',
                    subtitle: `As the owner of ${community.data.name} you must review relevant policies`,
                    layout: 'standard',
                    component: <ObScReviewOwnerPolicies/>,
                    cancel_text: ``,
                    onCancel: (p, fns) => {
                        fns.nextStep();
                    },
                    confirm_text: 'Continue',
                    onConfirm: (p, fns) => {
                        fns.nextStep();
                        // p = payload of settings from that step for further processing
                    }
                },
                'design-member-profile': {
                    title: 'Design the member profile',
                    subtitle: 'Add custom profile fields, hide or show fields or sections based on your needs and customize the member display name and more defaults',
                    layout: 'featured',
                    featured_component: <div>
                        <iframe src={"https://www.loom.com/embed/5498971c20f34429bdd425930dfd7ba1"} frameBorder="0"
                                webkitallowfullscreen mozallowfullscreen allowFullScreen
                                style={{height: '300px', width: '100%'}}></iframe>
                    </div>,
                    confirm_text: 'Design Member Profile',
                    onConfirm: (p, fns) => {
                        fns.nextStep();
                        // p = payload of settings from that step for further processing
                    }
                },
                'community-defaults': {
                    title: 'Set your community defaults',
                    subtitle: 'Let\'s set some sensible defaults for your members',
                    layout: 'standard',
                    component: <ObScCommunityDefaults/>,
                    cancel_text: `I'll do this later`,
                    onCancel: (p, fns) => {
                        fns.nextStep();
                    },
                    confirm_text: 'Next',
                    onConfirm: (p, fns) => {
                        fns.nextStep();
                        // p = payload of settings from that step for further processing
                    }
                },
                'your-profile': {
                    title: 'Your Profile',
                    subtitle: 'Tell us a little about yourself for your profile',
                    layout: 'standard',
                    component: <ObScYourProfile/>,
                    cancel_text: `I'll do this later`,
                    onCancel: (p, fns) => {
                        fns.nextStep();
                    },
                    confirm_text: 'Next',
                    onConfirm: (p, fns) => {
                        fns.nextStep();
                        // p = payload of settings from that step for further processing
                    }
                },
                'member-types': {
                    title: 'Setup Member Types',
                    subtitle: 'What different kinds of members does your community have?',
                    layout: 'standard',
                    component: <ObScSetupMemberTypes/>,
                    help: <div>
                        A member always has one type at a time and cannot have multiple types.
                        <br/><br/>
                        Member types are typically used to control access rights like creating a group with all active members that's always up to date.
                        <br/><br/>
                        Member types have a name which is the plural form of the name as well as a singular and abbreviation option so that these types can be customized and used across the community in ways that make sense.
                        <br/><br/>
                        For example, if Coaches is the member type, Coach would be the singular version, and C might be the abbreviation.
                        <br/><br/>
                        Then you could customize the display name of your community members so coaches are displayed as Coach Smith for example or C Smith.
                    </div>,
                    cancel_text: `I'll do this later`,
                    onCancel: (p, fns) => {
                        fns.nextStep();
                    },
                    confirm_text: 'Next',
                    onConfirm: (p, fns) => {
                        fns.nextStep();
                        // p = payload of settings from that step for further processing
                    }
                },
                'add-admins': {
                    title: 'Add Admins',
                    subtitle: 'Admins can help you build and run your online community',
                    layout: 'standard',
                    component: <ObScAddAdmins/>,
                    cancel_text: `I'll do this later`,
                    onCancel: (p, fns) => {
                        fns.nextStep();
                    },
                    confirm_text: 'Next',
                    onConfirm: (p, fns) => {
                        fns.nextStep();
                        // p = payload of settings from that step for further processing
                    }
                },
                'roles-teams': {
                    title: 'Teams and Roles',
                    subtitle: 'Add your working groups and positions like president or treasurer',
                    layout: 'standard',
                    component: <ObScSetupTeamsAndRoles/>,
                    confirm_text: 'Next',
                    onConfirm: (p, fns) => {
                        fns.nextStep();
                        // p = payload of settings from that step for further processing
                    },
                    cancel_text: `I'll do this later`,
                    onCancel: (p, fns) => {
                        fns.nextStep();
                    },
                }
            }
        }
    }
    return null;
}

export function OnboardingWrapper(props) {
    const community = useContext(CommunityContext);
    const history = useHistory();
    const urlParams = new URLSearchParams(window.location.search);
    const flow = urlParams.get('flow');
    useEffect(function () {
        if(!flow) {
            history.push(`/${community.data.handle}/home`)
        }
    }, [flow]);

    const data = buildOnboardingData(flow,community,history);

    if(!flow||!data) {
        return null;
    }

    return <OnboardingFrame data={data} />
}