import React from 'react';
import styled from "styled-components";
import {Link, useHistory} from "react-router-dom";
import {Button} from "../library/components/core";

const Container = styled.div`
    max-width: 768px;
    padding: 0 24px;
    margin: 0 auto;
    text-align: center;
    padding-top: 10vh;
`;

const Title = styled.div`
        font-size: 28px;
    font-weight: 600;
    margin-bottom: 40px;
`;

const Desc = styled.div`
        font-size: 18px;
        max-width: 500px;
    line-height: 1.4;
    margin: 0 auto;
`;

export function ErrorPage({error={}}) {
const history = useHistory();

    return <Container>
        <Title className='text-gray-900'>
            Uh oh. Something went wrong.
        </Title>

        <Desc className='text-gray-700'>
            We detected an error, could you refresh the page and try again? If that doesn't work try checking your internet connection or try another browser. Shoot us a
            message at <a className="color-link" href="mailto:hey@getunaty.com">hey@getunaty.com</a> if that doesn't help!
        </Desc>
        {error&&<div className="mt-4">
            <pre className="text-xs p-2 bg-gray-100 border-gray-300 rounded-md overflow-y-scroll text-left" style={{maxHeight:'200px'}}>{JSON.stringify(error, undefined, 2)}</pre>
        </div>}
        <div className="mt-4 w-96 flex justify-center mx-auto">
            <Button text="Back to Home" intent="secondary" onClick={()=> {
                window.location = "https://getunaty.com";
            }} />
        </div>
    </Container>
}