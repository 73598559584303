import React from "react";
import {
    BriefcaseIcon, ChartPieIcon,
    CheckIcon,
    ClipboardListIcon, ColorSwatchIcon,
    FilterIcon, IdentificationIcon,
    MailIcon,
    PencilIcon, TableIcon,
    UserAddIcon, UserCircleIcon
} from "@heroicons/react/solid";

export const all_tips = [
    {
        id: 'design-member-profile',
        title: 'Design the member profile',
        subtitle: 'Customize community profile fields and add custom fields as you need.',
        icon: <ClipboardListIcon />,
        onClick: (h,c,m) => {
            h.push(`/${c}/admin/settings/designer/member-profile`);
        },
        color: 'violet',
        for: ['admin'],
    },
    {
        id: 'brand-your-community',
        title: 'Brand your Community',
        subtitle: 'Upload your logo, choose a color, and update your community bio.',
        icon: <ColorSwatchIcon />,
        onClick: (h,c,m) => {
            h.push(`/${c}/admin/settings/about`);
        },
        color: 'green',
        for: ['admin'],
    },
    {
        id: 'complete-user-profile',
        title: 'Complete your profile',
        subtitle: 'Add your data and a profile picture.',
        icon: <UserCircleIcon />,
        onClick: (h,c,m) => {
            h.push(`/${m}`);
        },
        color: 'teal',
        for: ['user'],
    },
    /*
    {
        id: 'create-segment',
        title: 'Create Member Segments',
        subtitle: 'Filter your members with advanced conditions based on their profiles.',
        icon: <ChartPieIcon />,
        onClick: (h,c,m) => {
            h.push(`/${c}/admin/settings/member-segments`);
        },
        color: 'violet',
        for: ['admin'],
    },

     */
    {
        id: 'see-your-communities',
        title: 'See your Memberships',
        subtitle: 'View all the communities you are a part of.',
        icon: <IdentificationIcon />,
        onClick: (h,c,m) => {
            h.push(`/memberships`);
        },
        color: 'violet',
        for: ['user'],
    },
    {
        id: 'add-community-admins',
        title: 'Add Community Admins',
        subtitle: 'Admins can customize the community and manage other members.',
        icon: <UserAddIcon />,
        onClick: (h,c,m) => {
            h.push(`/${c}/admin/settings/admins`);
        },
        color: 'red',
        for: ['owner'],
    },
    {
        id: 'setup-teams-roles',
        title: 'Set up teams and roles',
        subtitle: 'Create your digital community structure for working groups and leaders.',
        icon: <BriefcaseIcon />,
        onClick: (h,c,m) => {
            h.push(`/${c}/leaders`);
        },
        color: 'violet',
        for: ['admin'],
    },
    {
        id: 'import-members',
        title: 'Add members from a spreadsheet',
        subtitle: 'Import many members at once quickly by uploading a csv file.',
        icon: <TableIcon />,
        onClick: (h,c,m) => {
            h.push(`/${c}/admin/members/import`);
        },
        color: 'emerald',
        for: ['admin'],
    },
    {
        id: 'add-secondary-emails',
        title: 'Add a secondary email',
        subtitle: 'Another email can be used to respond to emails.',
        icon: <MailIcon />,
        onClick: (h,c,m) => {
            h.push(`/${c}/preferences/notifications`);
        },
        color: 'teal',
        for: ['member'],
    },
    {
        id: 'edit-your-profile',
        title: 'Edit your profile',
        subtitle: 'Make changes to your profile and update your profile picture.',
        icon: <PencilIcon />,
        onClick: (h,c,m) => {
            h.push(`/${c}/member/${m}`);
        },
        color: 'blue',
        for: ['member'],
    },
    {
        id: 'add-a-member-filter',
        title: 'Add a member filter',
        subtitle: 'Create an auto-updating filter based on member attributes',
        icon: <FilterIcon />,
        onClick: (h,c,m) => {
            h.push(`/${c}/admin/settings/filters`);
        },
        color: 'gray',
        for: ['admin'],
    },
];