import {countries_array} from "../../data/general/countries";
import React from "react";
import {ProfileUpdatedIcon} from "../reorganize/profile-updated-icon";
import moment from "moment";
import {getMemberProfileUpdatedAt} from "../reorganize/data-quality-icon";
import {Tooltip2} from "@blueprintjs/popover2";
import {genders} from "../../data/general/genders";
import {account_status_tags, data_integrity_tags} from "../../playground/advanced-table/wrapper";

function TestIndicator({id, data}) {
    return <ProfileUpdatedIcon warnings={data.warnings?data.warnings:[]} is_you={false} name={data.name}
                               last_updated={getMemberProfileUpdatedAt(data)}
                               created_at={moment.unix(data.created_at / 1000)} show_label indicator/>;
}

function Suggestions({id, data}) {
    if (!data.suggestions || data.suggestions.length === 0) {
        return null
    }

    return <div>
        <Tooltip2 minimal popoverClassName='margin-top-4px' placement='bottom-center' content={<div
            className="text-xs font-medium rounded-md px-1.5 py-1 shadow-md border border-gray-200 text-gray-700">{data.suggestions.length} data
            suggestions</div>}>
            <div className="h-3.5 w-3.5 border-2 border-white bg-selection rounded-full"/>
        </Tooltip2>
    </div>
}

const member_def = {
    general: {
        title: 'Members',
        single: 'member',
        multi: 'members',

        admin_indicators: [

        ],

        bulk_endpoint: '/members/bulk-change',

        admin_link: `/:handle/admin/member/`,
        admin_id: 'docid',
        member_link: `/:handle/member/`,
        member_id: 'handle',
        type: 'members'
    },

    data_transforms: [
        {
            type: 'age_from_birthday',
            field: 'birthday',
            dt_type: 'number',
            dt_label: 'Age',
            value_type: 'number',
            id: 'dt_age'
        },
        /*
        {
            type: 'member_address',
            field: 'address',
            dt_type: 'address',
            dt_label: 'Address',
            value_type: 'text',
            id: 'dt_address'
        },
         */
        {
            type: 'data_quality_score',
            field: 'updated_at',
            dt_type: 'data-quality-score',
            dt_label: 'Data Quality',
            value_type: 'data-quality-tag',
            id: 'dt_dataqualityscore'
        }
    ],

    //
    allowed_layouts: [
        'list',
        'gallery',
      //  'map'
    ],
    default_layout: 'gallery',
    //

    //
    search_field: 'about.last_name',
    search_field_alt: 'about.first_name',
    //

    //
    layout_config: {
        'map': {
            required_properties: ['name'],
            image: 'profile_picture',
            name: 'name',
            // sort doesn't really apply..
            default_sort: [],
            default_filters: [

            ],
            // cannot group by
            default_group_by: null,
            default_features: {
                can_select: false,
            },
            default_properties: [
                {
                    id: 'name',
                    active: true
                }
            ]
        },
        'list': {
            required_properties: ['name'],
            image: 'profile_picture',
            name: 'name',
            default_sort: [
                {
                    dir: 'asc',
                    field: 'about.last_name'
                }
            ],
            default_filters: [

            ],
            default_group_by: null,
            default_features: {
                can_select: false,
            },
            default_properties: [
                {
                    id: 'name',
                    active: true
                }
            ]
        },
        'gallery': {
            required_properties: ['name', 'profile_picture'],
            image: 'profile_picture',
            name: 'name',
            default_sort: [],
            default_filters: [],
            default_group_by: null,
            default_features: {
                can_select: false,
            },
            default_properties: [
                {
                    id: 'name',
                    active: true
                },
            ]
        },
        'table': {
            required_properties: ['name'],
            name: 'name',
            image: 'profile_picture',
            default_sort: [],
            default_filters: [],
            default_group_by: null,
            default_features: {
                can_select: true,
            },
            default_properties: [
                {
                    id: 'name',
                    active: true
                },
            ]
        }
    },
    //

    //
    sort_options: {
        ['about.last_name']: {
            field: 'about.last_name',
            label: 'Last Name',
            type: 'text',
            default_value: 'desc',
            options: ['asc', 'desc']
        },
        ['last_sign_in']: {
            field: 'last_sign_in',
            label: 'Last Sign In',
            type: 'date',
            default_value: 'desc',
            options: ['asc', 'desc']
        },
        ['profile_updated_at']: {
            field: 'profile_updated_at',
            label: 'Profile last updated',
            type: 'date',
            default_value: 'desc',
            options: ['asc', 'desc']
        },
        ['stats.sessions']: {
            field: 'stats.sessions',
            label: 'Sessions',
            type: 'number',
            default_value: 'desc',
            options: ['asc', 'desc']
        },
        ['about.first_name']: {
            field: 'about.first_name',
            label: 'First Name',
            type: 'text',
            default_value: 'asc',
            options: ['asc', 'desc']
        },
        age: {
            field: 'age',
            label: 'Age',
            type: 'number',
            default_value: 'desc',
            options: ['asc', 'desc']
        }
    },
    //

    //
    group_options: {
        member_type: {
            label: 'Member Type',
            type: 'member_type',
            field: 'member_type',
            sort: 'plural',
            sort_type: 'text',
            dir: 'asc'
        },
        ['address.country']: {
            label: 'Country',
            type: 'select',
            field: 'address.country',
            sort: 'alphabetical',
            sort_type: 'text',
            dir: 'asc'
        }
    },
    //

    //
    filter_options: {
        member_type: {
            type: 'member_type',
            label: 'Member Type',
            field: 'member_type',
        },
        account_status: {
            type: 'account_status',
            label: 'Account Status',
            field: 'account_status',
        },
        data_integrity: {
            type: 'data_integrity',
            label: 'Data Integrity',
            field: 'data_integrity',
        },
        ['stats.sessions']: {
            field: 'stats.sessions',
            label: 'Sessions',
            type: 'number'
        },
        last_sign_in: {
            field: 'last_sign_in',
            label: 'Last Login',
            type: 'date'
        },
        created_at: {
            field: 'created_at',
            label: 'Created at',
            type: 'date'
        },
        profile_updated_at: {
            field: 'profile_updated_at',
            label: 'Updated profile',
            type: 'date'
        },
        age: {
            field: 'age',
            label: 'Age',
            type: 'number'
        },
        ['about.gender']: {
            field: 'about.gender',
            label: 'Sex',
            type: 'select',
            options: genders
        },
        ['address.zip_code']: {
            type: 'text',
            label: 'ZIP Code',
            field: 'address.zip_code'
        },
        ['address.city']: {
            type: 'text',
            label: 'City',
            field: 'address.city'
        },
        ['address.country']: {
            type: 'select',
            label: 'Country',
            field: 'address.country',
            options: countries_array
        }
    },
    //

    //
    properties: {
        name: {
            type: 'name',
            always_on: true,
            field: 'name',
            editable: false,
            default_on: true,
            col_width: 180,
            label: 'Full Name'
        },
        ['about.first_name']: {
            type: 'text',
            editable: true,
            sortable: true,
            field: 'about.first_name',
            col_width: 150,
            label: 'First Name'
        },
        handle: {
            type: 'handle',
            editable: false,
            field: 'handle',
            col_width: 150,
            label: 'Handle'
        },
        account_email: {
            type: 'account-email',
            editable: false,
            field: 'account_email',
            col_width: 225,
            label: 'Account Email'
        },
        ['about.last_name']: {
            type: 'text',
            sortable: true,
            editable: true,
            field: 'about.last_name',
            col_width: 120,
            label: 'Last Name'
        },
        ['id']: {
            type: 'id',
            sortable: false,
            editable: false,
            field: 'id',
            col_width: 120,
            label: 'ID'
        },
        member_type: {
            type: 'select',
            editable: true,
            filterable: true,
            field: 'member_type',
            default_on: true,
            col_width: 150,
            label: 'Member Type'
        },
        account_status: {
            type: 'tags',
            editable: true,
            filterable: true,
            field: 'account_status',
            default_on: false,
            col_width: 120,
            label: 'Account Status'
        },
        data_integrity: {
            type: 'tags',
            editable: true,
            filterable: true,
            field: 'data_integrity',
            default_on: false,
            col_width: 120,
            label: 'Data Integrity'
        },
        birthday: {
            type: 'birthday',
            editable: true,
            field: 'birthday',
            col_width: 100,
            label: 'Birthday'
        },
        /*
        dt_age: {
            type: 'number',
            editable: false,
            field: 'dt_age',
            col_width: 150,
            label: 'Age',
            // required for fields that can be calculated
            value_label: {
                single: 'year',
                multi: 'years'
            }
        },

         */
        /*
        dt_address: {
            type: 'address',
            editable: false,
            field: 'dt_address',
            col_width: 150,
            label: 'Address'
        },

         */
        /*
        location: {
            type: 'location',
            field: 'about.location',
            editable: true,
            default_on: true,
            col_width: 150,
            label: 'Location'
        },

         */
        ['contact.email']: {
            type: 'email',
            field: 'contact.email',
            editable: true,
            col_width: 120,
            label: 'Email'
        },
        ['contact.phone']: {
            type: 'phone',
            field: 'contact.phone',
            editable: true,
            col_width: 150,
            label: 'Phone'
        },
        /*
        ['interests']: {
            type: 'interests',
            field: 'interests',
            editable: false,
            col_width: 150,
            label: 'Interests'
        },

         */
        ['roles']: {
            type: 'roles',
            field: 'roles',
            editable: false,
            col_width: 180,
            label: 'Roles'
        },
        ['groups']: {
            type: 'groups',
            field: 'groups',
            editable: false,
            col_width: 180,
            label: 'Groups'
        },
        ['contact.secondary_phone']: {
            type: 'phone',
            field: 'contact.secondary_phone',
            editable: true,
            col_width: 120,
            label: '2nd Phone'
        },
        ['about.title']: {
            type: 'text',
            field: 'about.title',
            editable: true,
            col_width: 120,
            label: 'Title'
        },
        ['about.middle_name']: {
            type: 'text',
            editable: true,
            field: 'about.middle_name',
            col_width: 120,
            label: 'Middle Name'
        },
        ['about.line_one']: {
            type: 'text',
            editable: true,
            field: 'address.line_one',
            col_width: 120,
            label: 'Line One'
        },
        ['about.line_two']: {
            type: 'text',
            editable: true,
            field: 'address.line_two',
            col_width: 120,
            label: 'Line Two'
        },
        ['address.city']: {
            type: 'text',
            editable: true,
            filterable: true,
            field: 'address.city',
            col_width: 120,
            label: 'City'
        },
        ['address.zip_code']: {
            type: 'text',
            editable: true,
            filterable: true,
            field: 'address.zip_code',
            col_width: 120,
            label: 'ZIP Code'
        },
        ['address.state']: {
            type: 'text',
            field: 'address.state',
            filterable: true,
            col_width: 120,
            editable: true,
            label: 'State'
        },
        ['address.country']: {
            type: 'select',
            editable: true,
            filterable: true,
            field: 'address.country',
            col_width: 120,
            label: 'Country'
        },
        ['stats.sessions']: {
            type: 'number',
            field: 'stats.sessions',
            sortable: true,
            filterable: true,
            editable: false,
            col_width: 100,
            label: 'Sessions',
            value_label: {
                single: 'session',
                multi: 'sessions'
            }
        },
        profile_updated_at: {
            type: 'date',
            field: 'profile_updated_at',
            sortable: true,
            options: {
              display: 'relative'
            },
            filterable: true,
            editable: false,
            col_width: 100,
            label: 'Profile Updated'
        },
        joined_date: {
            type: 'joined-date',
            field: 'joined_date',
            sortable: true,
            filterable: true,
            editable: false,
            col_width: 100,
            label: 'Joined'
        },
        created_at: {
            type: 'date',
            options: {
                display: 'absolute'
            },
            field: 'created_at',
            sortable: true,
            filterable: true,
            editable: false,
            col_width: 100,
            label: 'Created'
        },

        age: {
            type: 'number',
            field: 'age',
            sortable: true,
            filterable: true,
            editable: false,
            col_width: 80,
            label: 'Age',
            value_label: {
                single: 'year',
                multi: 'years'
            }
        },
        ['last_sign_in']: {
            type: 'date',
            options: {
              display: 'relative'
            },
            field: 'last_sign_in',
            sortable: true,
            filterable: true,
            editable: false,
            action: {
                label: 'Send Invite',
                condition: 'equals-null',
                id: 'send-member-invite',
                props: ['id','user_uid']
            },
            col_width: 100,
            label: 'Last Login'
        },
        /*
        custom_field__XXXXXXXX:{}
         */
    },
    //
};

const role_def = {
    general: {
        title: 'Roles',
        single: 'role',
        multi: 'roles',

        bulk_endpoint: '/roles/bulk-change',

        admin_link: `/:handle/admin/role/`,
        admin_id: 'docid',
        member_link: `/:handle/role/`,
        member_id: 'docid',
        type: 'roles'
    },

    data_transforms: [
        {
            type: 'role-position',
            field: 'role-position',
            dt_type: 'position',
            dt_label: 'Position',
            value_type: 'number',
            id: 'dt_position'
        },
    ],

    //
    allowed_layouts: [
        'list',
        'gallery',
        'table'
    ],
    default_layout: 'gallery',
    //

    //
    search_field: 'name',
    search_field_alt: 'handle',
    //

    //
    // tranform roles to apply member_name, profile_picture at runtime
    layout_config: {
        'list': {
            required_properties: ['name'],
            image: 'member_profile_picture',
            name: 'name',
            default_sort: [
                {
                    dir: 'asc',
                    field: 'name'
                }
            ],
            default_filters: [],
            default_group_by: null,
            default_features: {
                can_select: false,
            },
            default_properties: [
                {
                    id: 'name',
                    active: true
                }
            ]
        },
        'gallery': {
            required_properties: ['name', 'profile_picture'],
            image: 'member_profile_picture',
            name: 'name',
            default_sort: [],
            default_filters: [],
            default_group_by: null,
            default_features: {
                can_select: false,
            },
            default_properties: [
                {
                    id: 'name',
                    active: true
                },
            ]
        },
        'table': {
            required_properties: ['name'],
            name: 'name',
            default_sort: [],
            default_filters: [],
            default_group_by: null,
            default_features: {
                can_select: true,
            },
            default_properties: [
                {
                    id: 'name',
                    active: true
                },
            ]
        }
    },
    //

    //
    sort_options: {
        name: {
            field: 'name',
            label: 'Name',
            type: 'text',
            default_value: 'desc',
            options: ['asc', 'desc']
        },
        position: {
            field: 'dt_position',
            label: 'Position',
            type: 'position',
            default_value: 'desc',
            options: ['asc', 'desc']
        }
    },
    //

    //
    group_options: {
        team: {
            label: 'Team',
            type: 'team',
            field: 'teams',
            sort: 'rank',
            sort_type: 'number',
            dir: 'asc'
        }
    },
    //

    //
    filter_options: {},
    //

    //
    properties: {
        name: {
            type: 'name',
            always_on: true,
            field: 'name',
            editable: true,
            default_on: true,
            col_width: 150,
            label: 'Name'
        },
        assignee: {
            type: 'assignee',
            editable: true,
            sortable: false,
            field: 'assignee',
            col_width: 150,
            label: 'Assignee'
        },
        assignee_email: {
            type: 'email',
            editable: false,
            sortable: false,
            field: 'assignee_data.email',
            col_width: 150,
            label: 'Email'
        },
        assignee_phone: {
            type: 'phone',
            editable: false,
            sortable: false,
            field: 'assignee_data.phone',
            col_width: 150,
            label: 'Phone'
        },
        purpose: {
            type: 'textarea',
            always_on: false,
            field: 'purpose',
            editable: true,
            default_on: false,
            col_width: 150,
            label: 'Purpose'
        },
        handle: {
            type: 'handle',
            editable: false,
            field: 'handle',
            col_width: 150,
            label: 'Handle'
        },
    },
    //
};

const group_def = {
    general: {
        title: 'Groups',
        single: 'group',
        multi: 'groups',

        bulk_endpoint: '/groups/bulk-change',

        admin_link: `/:handle/admin/group/`,
        admin_id: 'docid',
        member_link: `/:handle/group/`,
        member_id: 'docid',
        type: 'groups'
    },

    data_transforms: [],

    //
    allowed_layouts: [
        'list'
    ],
    default_layout: 'list',
    //

    //
    search_field: 'name',
    search_field_alt: 'purpose',
    //

    //
    // tranform roles to apply member_name, profile_picture at runtime
    layout_config: {
        'list': {
            required_properties: ['name'],
            image: null,
            rich_name: true,
            emoji: 'emoji',
            name: 'name',
            default_sort: [
                {
                    dir: 'desc',
                    field: 'created_at'
                }
            ],
            default_filters: [],
            default_group_by: null,
            default_features: {
                can_select: false,
            },
            default_properties: [
                {
                    id: 'name',
                    active: true
                }
            ]
        },
    },
    //

    //
    sort_options: {
        name: {
            field: 'created_at',
            label: 'Created',
            type: 'date',
            default_value: 'desc',
            options: ['asc', 'desc']
        }
    },
    //

    //
    group_options: {},
    //

    //
    filter_options: {

    },
    //

    //
    properties: {
        name: {
            type: 'name',
            always_on: true,
            field: 'name',
            editable: true,
            default_on: true,
            col_width: 150,
            label: 'Name'
        },
        created: {
            type: 'date',
            always_on: false,
            field: 'created_at',
            editable: false,
            filterable: false,
            default_on: false,
            col_width: 150,
            label: 'Created'
        },
        type: {
            type: 'type',
            always_on: false,
            field: 'type',
            editable: false,
            filterable: true,
            default_on: false,
            col_width: 150,
            label: 'Type'
        },
        purpose: {
            type: 'textarea',
            always_on: false,
            field: 'purpose',
            editable: true,
            default_on: false,
            col_width: 150,
            label: 'Purpose'
        }
    },
    //
};

export const AT_data_type_definitions = {
    member: member_def,
    group: group_def,
    role: role_def,
    event: {
        general: {
            title: ''
        },
        sort_options: {},
        filter_options: {},
        properties: {}
    },
    member_type: {
        general: {
            title: ''
        },
        sort_options: {},
        filter_options: {},
        properties: {}
    }
};