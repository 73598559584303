import React from 'react';
import {DotsVerticalIcon} from "@heroicons/react/outline";
import {Avatar} from "../core";
import {DEFAULT_USER_PHOTO} from "../../../config/defaults";
import {NFIGrowGrid, NFIInner, NFIOuter} from "./shared";
import {aa_formatDate} from "../../utilities/general/dates";
import {EntityInfo} from "../../utilities/localstorage/entity-localstorage";

function NFIHSender({data,onClick}) {
    return <span onClick={onClick} className="text-gray-800 font-semibold hover:underline">{data.name}</span>
}

const messages = {
    'sent-group-message': "sent a message to",
    'sent-you-message': "sent you a message"
};

function NFIHMessage({type}) {
    return <span className="text-gray-700">{messages[type]}</span>
}

function NFIHContext({data,onClick}) {
    return <span onClick={onClick} className="text-gray-800 font-semibold hover:underline">{data.name}</span>
}

function NFIHDate({created_at,onClick}) {
    const text = !created_at ? "Yesterday" : aa_formatDate(created_at).relative;
    return <span onClick={onClick} className="text-gray-500">{text}</span>
}

function NFIHSeries({series,onClick}) {
    return <span onClick={onClick} className="text-gray-500 hover:text-gray-800 hover:underline cursor-pointer">{series.name}</span>
}

function showExplainerMessage(message,author) {
    return author.type!=='bot'&&message&&message.type;
}

function HeaderContent({data,actions,message,series,author,created_at,context,entity}) {
    const show_entity = context && entity && entity.id && typeof entity.id === 'string' && typeof entity.type === 'string';
    const img = data.image || DEFAULT_USER_PHOTO;
    const sem = showExplainerMessage(message,author);
    const see = show_entity && author.type !== 'bot';
    return <>
        <div className="h-10">
            <Avatar size="md" text={author.name} url={`${img}_small?alt=media`}/>
        </div>
        <div onClick={(e)=>{
            if(e) {
                e.stopPropagation();
                e.preventDefault();
            }
        }} className="h-10 flex flex-col pt-px justify-start">
            <div className="text-sm pt-px">
                <NFIHSender onClick={()=>actions.goToPath(author.link)} data={data} />
                &nbsp;{sem&&<NFIHMessage {...message}/>}
                &nbsp;{see && <EntityInfo type={`${entity.type}`} id={entity.id} community_uid={context.community_uid}>
                <NFIHContext onClick={()=>actions.goToPath(entity.link)} />
            </EntityInfo>}
            </div>
            <div className="text-xs pt-px space-x-2">
                <NFIHDate  created_at={created_at}/>
                {series&&series.id&&<NFIHSeries onClick={()=>actions.goToPath(series.link)} series={series} />}
            </div>
        </div>
        <div className="hidden items-center h-10">
            <div className="h-svg-4 text-gray-600">
                <DotsVerticalIcon/>
            </div>
        </div>
    </>
}

function BotEntityInfo({id,children}) {

    let extras = {};
    const data = {
        name: "Leaderbot",
        type: "bot",
        image: `https://firebasestorage.googleapis.com/v0/b/unaty-prod.appspot.com/o/community_logos%2FS090VY3EF5`,
        id
    };

    return React.Children.map(children, child => {
        // checking isValidElement is the safe way and avoids a typescript error too
        if (React.isValidElement(child)) {
            return React.cloneElement(child, { data: data===null?{name:'Loading..',id,extras}:{...data,id,extras} });
        }
    });
}

export function NFIHeader({author, actions, series, message, entity, context, created_at}) {

    // author id, type
    let header = <HeaderContent actions={actions} series={series} message={message} author={author} context={context} entity={entity} created_at={created_at} />;
    return <NFIOuter className="pb-1.5">
        <NFIInner>
            <NFIGrowGrid>
                {author.type==='bot'&&<BotEntityInfo type={`${author.type}`} id={author.id} community_uid={context.community_uid}>
                    {header}
                </BotEntityInfo>}
                {author.type!=='bot'&&<EntityInfo type={`${author.type}`} id={author.id} community_uid={context.community_uid}>
                    {header}
                </EntityInfo>}
            </NFIGrowGrid>
        </NFIInner>
    </NFIOuter>
}