import React, {useContext, useEffect, useState} from 'react';
import {
    Badge,
    Button, Field, List
} from '../core';
import {CommunityContext} from "../../../app/community/community-context";
import {GlobeIcon, PencilIcon, PlusIcon, XIcon} from "@heroicons/react/solid";
import {ActionWithIcon, InfoState} from "../core";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import {DNDList, useDraggableInPortal} from "../advanced-table/sort/sort";
import {at_array_move} from "../advanced-table/utilities";
import {PB_type_names} from "../../utilities/pinboard";


export function PinboardList({goBack, data_obj, setPins, onEditPin, removePin, pin_data, pins, context, goToPanel}) {
    const [loading, setLoading] = useState('');
    const [pins_items, setPinsItems] = useState(pins);
    const [pins_data, setPinsData] = useState(pin_data);
    const [editing, setEditing] = useState(false);
    const community = useContext(CommunityContext);


    useEffect(function() {
        setPinsItems([...(pins?pins:[])]);
        setPinsData({...(pins_data?pins_data:{})});
    }, [pin_data,pins]);

    useEffect(function() {
        if(data_obj) {
            setPinsItems([...(data_obj.pins?data_obj.pins:[])]);
            setPinsData({...(data_obj.pins_data?data_obj.pins_data:{})});
        }
    }, [data_obj]);

    const items = pins_items.map((p, k) => {
        const dt = pins_data[p];
        if(!dt) {
            return {blank:true};
        }
        return {
            icon: <GlobeIcon/>,
            value: `website-${p}`,
            title: dt.label,
            subtitle: `${PB_type_names[dt.type]} • ${dt.value}`,
            actions: [
                (editing ? <Button icon={<PencilIcon />} loading={loading===p}  onClick={() => {
                    onEditPin(p);
                }} size="xs"/> : null),
                (editing ? <Button icon={<XIcon />} loading={loading===p}  onClick={() => {
                    setLoading(p);
                    removePin(p, () => {
                            setLoading('');
                            setPinsItems([...pins]);
                        }
                    );
                }} size="xs"/> : null)
            ]
        }
    });

    const edit_btn = items.length === 0 ? null : editing ? <ActionWithIcon onClick={() => setEditing(false)} text="Done"/> :
        <ActionWithIcon onClick={() => setEditing(true)} text="Edit"/>;

    function handleOnDragEnd(result) {
        if (!result.destination) return;

        const {destination,source} = result;
        // need to sort pins and save on done

        const new_pins = at_array_move(pins_items,source.index,destination.index);

        setPins([...new_pins],()=>{
            setPinsItems(pins);
        });
        setPinsItems([...new_pins]);
    }

    const renderDraggable = useDraggableInPortal();

    return <div className="space-y-4">
        <div>
            <Field corner_hint={edit_btn} label={"Pins"}>
                {items.length > 0 && <div className="-mt-2">
                    <DragDropContext onDragEnd={handleOnDragEnd}>
                        <Droppable droppableId="group-pinboard">
                            {(provided) => (
                                <div {...provided.droppableProps} ref={provided.innerRef}>
                                    <List old_loader={true} context={context} renderDraggable={renderDraggable} minimal items={items}
                                          can_reorder={editing && items.length > 0}/>
                                    {provided.placeholder}
                                </div>
                            )}

                        </Droppable>
                    </DragDropContext>
                </div>}
                {items.length === 0 && <InfoState intent="secondary" subtitle="No pins added yet"/>}
            </Field>
        </div>
        <div className="flex">
            <div className="flex-grow">
                <Button text="Add Pin" left_icon={<PlusIcon/>} size="sm" onClick={() => goToPanel('add-pin')}/>
            </div>
            <div>
            </div>
        </div>
    </div>;
}