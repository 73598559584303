import React, {useContext, useEffect, useState} from "react";
import {Button, EntityHeader, InlineLoader, SectionHeader, Tabs} from "../../../../../library/components/core";
import {CommunityContext} from "../../../community-context";
import {StandardPageHeight} from "../../../../../library/playground/standard-page-framework";
import {RulesEditor} from "../../../../../library/components/rules-editor";
import {FirebaseDB} from "../../../../../config/setup-firestore";
import {authFetch} from "../../../../../config/network";
import {GlobalContext} from "../../../../global/global-context";

export async function getRules(community_uid) {
    return await FirebaseDB
        .collection('communities')
        .doc(community_uid)
        .collection('meta')
        .doc('rules')
        .get()
        .then(d => {
            return d.exists ? d.data().rules : [];
        })
}

const _tabs = [
    {
        id: "group-moderators",
        context: "group",
        scope: "moderators",
        label: "Group Moderators"
    },
    {
        id: "group-moderators",
        context: "group",
        scope: "data-quality",
        label: "Group Data Quality"
    },
    {
        id: "community-data-quality",
        context: "community",
        scope: "data-quality",
        label: "Community Data Quality"
    }
]

function updateRules(current_arr,newarr,_tab_data) {
    const {context,scope} = _tab_data;

    let base = [...current_arr].filter(a=>`${a.context}${a.scope}`!==`${context}${scope}`);

    return [...base,...newarr];
}

export default function CommunitySettingsHidden(props) {
    const global = useContext(GlobalContext);
    const community = useContext(CommunityContext);
    const {history} = props;
    const [active, setActive] = useState(_tabs[0].label)
    const [rules, setRules] = useState(null);
    const [changes_made, setChangesMade] = useState(false);

    useEffect(function () {
        getRules(community.uid)
            .then(r => {
                setRules(r);
            })
    }, [])

    function saveChanges() {
        setChangesMade(false);
        const res = () => {
            global.addToast({text: "Changes saved", intent: 'success'});
        };
        const payload = {
            community_uid: community.uid,
            member_id: community.member_id,
            rules
        };
        authFetch("/communities/update-rules", res, res, "POST", {payload});
    }

    const _tab_data = _tabs.filter(a => a.label === active)[0];

    function handleChange(v) {
        setRules(updateRules(rules,v,_tab_data));
        setChangesMade(true);
    }

    if (!rules) {
        return <InlineLoader/>
    }



    const relevant_rules = rules.filter(r => r.context === _tab_data.context && r.scope === _tab_data.scope);

    return (<StandardPageHeight full content>
            <EntityHeader title="Hidden Settings"
                          goBackTo={() => history.push(`/${community.data.handle}/admin/settings`)}/>
            <div className="px-4 py-2 flex">
                <div className="flex-grow">
                    <Tabs layout="pills-color" onSelect={t1 => setActive(t1)} tabs={_tabs.map(t => t.label)}
                          active={active}/>
                </div>
                <div>
                    {changes_made && <Button intent="success" onClick={() => saveChanges()} text="Save"/>}
                </div>
            </div>
            <div className="px-4 py-2">
                <div className="">
                    <RulesEditor key={`content-${active}`} scope={_tab_data.scope} context={_tab_data.context}
                        value={relevant_rules}
                        onChange={handleChange}/>
                </div>
                <div className="pt-4">
                    <SectionHeader title="All Rules" padded={false} />
                      <pre className="text-xs p-2 bg-gray-100 border-gray-300 rounded-md overflow-y-scroll text-left"
                           style={{maxHeight: '400px'}}>{JSON.stringify(rules, undefined, 2)}</pre>
                </div>
            </div>
        </StandardPageHeight>
    );
}