import React, {useState, useRef, useCallback, useContext, useEffect} from 'react';
import {AdvancedTable2} from "./index";
import makeData from "./make-data";
import {at_transformMember} from "./api";
import {DEFAULT_USER_PHOTO} from "../../../config/defaults";
import {countries_array} from "../../data/general/countries";
import {CommunityContext} from "../../../app/community/community-context";
import {Badge, Button, PageHeader} from "../../components/core";
import {
    ArrowCircleRightIcon,
    ChevronDownIcon,
    FlagIcon, MenuAlt2Icon, PaperAirplaneIcon, PencilAltIcon,
    PlusIcon,
    RefreshIcon,
    UserIcon
} from "@heroicons/react/solid";
import {

    ClipboardCheckIcon, ClockIcon,
} from "@heroicons/react/outline";
import {GlobalContext} from "../../../app/global/global-context";
import {Popover2} from "@blueprintjs/popover2";
import {PopupMenu} from "../../components/old/popup-menu";
import {TableCheckbox} from "../../components/old/table-checkbox";
import CreateMember from "../../../app/community/directory/create";
import {buildNewPathWithURLParam} from "../../../app/community/directory";
import {useHistory} from "react-router-dom";
import {getManyMembers} from "../../../api/members/get-many";
import {getManyMembersPaginated} from "../../../api/members/get-many-paginated";
import {GenericCsvExport} from "./generic-csv-export";
import moment from "moment";
import {export_separator_ref, IntlExport, regional_date_formats} from "../../components/reorganize/intl-export";
import {buildMemberExportHeaders, EXPORTtransformMember} from "../../../app/community/directory/export";
import {members_headers} from "../../../app/community/directory/headers";
import {authFetch} from "../../../config/network";
import {isAnonymousUnatyEmail} from "../../utilities/anonymous-emails";
import {logEngagement} from "../../../api/analytics";
import {buildGroupColumns} from "./build-group-columns";
import {ViewsSelector} from "./views-selector";
import {buildGroupViews} from "./build-group-views";
import {advt2_getDefinitions} from "./build-definitions";
import {api_getNameMap} from "../../../api/community/members/get-name-map";
import {at_prepareCustomFields} from "../../utilities/community/custom-fields";
import {acceptDataSuggestions} from "../../../api/community/members/data-suggestions";


const account_status = {
    field: "account_status",
    operator: "in",
    value: ["logged-in", "invite-sent", "missing-email", "not-invited"]
};

const account_status_sus = {
    field: "account_status",
    operator: "==",
    value: "suspended"
};

const account_status_levels = {
    0: "missing-email", // is anonymous account
    1: "archived", // archived
    2: "not-invited", // never sent an invite and never logged in
    3: "invite-sent", // sent an invitation but not logged in
    4: "logged-in", // has logged in once,
    5: "suspended" // member has been suspended
}

export const account_status_tags = [
    {
        id: "missing-email",
        label: "Missing Email",
        color: "yellow"
    },
    {
        id: "archived",
        label: "Deactivated",
        color: "gray"
    },
    {
        id: "not-invited",
        label: "Not Invited",
        color: "gray"
    },
    {
        id: "invite-sent",
        label: "Invite Sent",
        color: "blue"
    },
    {
        id: "logged-in",
        label: "Logged In",
        color: "green"
    },
    {
        id: "suspended",
        label: "Suspended",
        color: "yellow"
    }
];

const data_integrity_levels = {
    0: "unknown", // never logged in
    1: "never-updated", // null last_profile_update
    2: "outdated", // older than 6 months
    3: "incomplete", // profile is not complete
    4: "good", // >0.75
    5: "perfect" // looking great!
}

const default_data_integrity_state = {
    "unknown": true,
    "never-updated": true,
    "outdated": true,
    "incomplete": true,
    "good": true,
    "perfect": true,
};

export const data_integrity_tags = [
    {
        id: "unknown",
        label: "Unknown",
        color: "yellow"
    },
    {
        id: "never-updated",
        label: "Never Updated",
        color: "yellow"
    },
    {
        id: "outdated",
        label: "Outdated",
        color: "gray"
    },
    {
        id: "incomplete",
        label: "Incomplete",
        color: "yellow"
    },
    {
        id: "up-to-date",
        label: "Up-to-date",
        color: 'blue'
    },
    {
        id: "good",
        label: "Up-to-date",
        color: "blue"
    },
    {
        id: "perfect",
        label: "Perfect",
        color: "green"
    }
];

const data_integrity = {
    field: "data_integrity",
    operator: "==",
    value: "perfect"
};

const custom_select_filter = {
    field: "custom_fields.Hbv9vwVld",
    operator: "==",
    value: "nVIHwwjpv"
};

const account_status_full = {
    field: 'account_status',
    operator: 'in',
    value: ['missing-email', 'not-invited', 'invite-sent', 'logged-in']
};

function buildFinalFilters(type, id, filters) {
    let arr = [];

    if (type === 'group') {
        arr.push({
            field: "group_ids",
            operator: "array-contains",
            value: id
        })
    }

    filters.forEach(a => {
        arr.push(a);
    })

    return arr;
}

function TableActions({cb, handleSendInvite, handleSendDataCheck, handleSendEmail, handleExport}) {
    let options = [];

    options.push({
        type: 'item',
        title: 'Send Community Invite',
        onClick: () => handleSendInvite()
    });

    options.push({
        type: 'item',
        title: 'Send Email',
        onClick: () => handleSendEmail()
    });


    options.push({
        type: 'item',
        title: 'Send Data Check',
        onClick: () => handleSendDataCheck()
    });

    options.push({
        type: 'item',
        title: 'Export Members to CSV',
        onClick: () => handleExport()
    });

    return <Popover2 content={<PopupMenu items={options}/>} placement={'bottom-start'} minimal
                     popoverClassName='minimal-popover'>
        <Button size="sm" text="Actions" right_icon={<ChevronDownIcon/>} minimal/>
    </Popover2>;
}

export function membersBuildInviteTo(members) {
    let a = [];
    console.log("INVITE TO",members)
    members.filter(b => !isAnonymousUnatyEmail(b.account_email)).forEach(m => {
        a.push({
            email: m.account_email,
            handle: m.handle,
            image: m.profile_picture,
            isDisabled: false,
            name: m.name,
            value: m.id,
            _type: "members"
        });
    });
    return a;
}

export function buildCustomFieldLabelMap(community) {
    const f = at_prepareCustomFields(community.custom_fields);

    let obj = {};

    const entries = Object.entries(f);

    entries.forEach(([id, ent]) => {
        if (ent.type === 'select') {
            const items = Object.entries(ent.choices_map);
            items.forEach(it => {
                obj[`${id}__${it[0]}`] = it[1];
            })
        }
    })

    return obj;
}

function FilterItem({
                        child = null, updateParent = () => {
    }, state = {}, reducer = (a) => {
        return a;
    }, options = []
                    }) {
    const [s, setS] = useState(state);
    if (options.length === 0) {
        return null;
    }
    useEffect(function () {
        const nv = reducer(s);
        updateParent(nv);
    }, [s]);
    const content = <div className="space-y-1 py-2 px-2.5">
        {options.map((opt, k) => {
            return <div key={k}>
                <TableCheckbox onClick={() => {
                    setS({...s, [opt.id]: !s[opt.id]})
                }} label={opt.label} checked={s[opt.id]}/>
            </div>
        })}
    </div>;

    return <Popover2 content={content} placement={'bottom-start'} minimal
                     popoverClassName='minimal-popover bg-white rounded-md shadow-md border border-gray-200 w-72 my-1'>
        {child}

    </Popover2>;
}

function buildSingleFilter(filter, view_options) {
    let f = {...filter};

    if (f.field === 'account_status') {
        if (view_options.include_archived) {
            f.value.push("archived");
        }
    }

    return f;
}

const config = {
    features: {
        create_view: false
    }
};

function setField(obj,field,value) {
    let a = {...obj};
    const fs = field.split('.');
    if(fs.length===2) {
        a[fs[0]][fs[1]] = value;
    } else {
        a[fs[0]] = value;
    }
    return a;
}

function applyDataSuggestion(member, suggestion) {
    let nm = {...member};

    const {type,field,value} = suggestion;

    switch (type) {
        case 'first-name':
            nm = setField(nm,field,value);
            break;
        case 'last-name':
            nm = setField(nm,field,value);
            break;
        case 'autocomplete-address':

            break;
        case 'phone-number-country':
            nm = setField(nm,field,value);
            break;
        case 'format-phone-number':
            nm = setField(nm,field,value);
            break;
        default:

            break;
    }

    return nm;
}

function applyAllDataSuggestion(arr, changes) {
    let ni = [...arr];

    changes.forEach(({_member, _row_index, suggestions}) => {
        let minus = 0;
        suggestions.forEach(({_suggestion, _s_index})=>{
            ni[_row_index] = applyDataSuggestion(_member, _suggestion);
            ni[_row_index]._suggestions.splice((_s_index-minus),1);
            minus++;
        })
    })

    return ni;
}

function Filters({filter1,setFilter1}) {
    if(!filter1||filter1.field!=='account_status') {
        return null;
    }
    let b = {};
    filter1.value.forEach(k=>{
        b[k] = true;
    })
    return <div>
        <FilterItem
        options={account_status_tags}
        child={<Button size="sm" text="Account Status" icon={<FlagIcon/>}/>}
        state={b} updateParent={(a) => {
        const new_keys = Object.entries(a).filter(b => b[1] === true);
        if (new_keys.length === 0) {
            setFilter1(null)
        } else {
            setFilter1({
                field: "account_status",
                operator: "in",
                value: new_keys.map(a => a[0])
            })
        }

    }}/>

    </div>
}

function buildInviteTitle(community,group) {
    if(group) {
        return {
            subject: `Join ${group.name} at ${community.data.name}`,
            content: `${community.member.name} (${community.member.account_email}) invited you to join ${group.name} on the ${community.data.name} community on Unaty.`
        };
    }
    return {
        subject: `Join ${community.data.name} on Unaty`,
        content: `${community.member.name} (${community.member.account_email}) invited you to join ${community.data.name} on Unaty.`
    };
}

export function AdvancedTableWrapper({
    cb,
                                         type = "group",
                                         can_manage,
                                         id = "",
                                         scroll_options = {
                                             padding: 500
                                         },
                                         scroll_id,
                                         data = null,
                                         per_page = 25,
                                     }) {

    const global = useContext(GlobalContext);
    const community = useContext(CommunityContext);
    const history = useHistory();
    const community_uid = community.uid;
    const default_profile_picture = DEFAULT_USER_PHOTO;
    const definitions = advt2_getDefinitions(community, {
        data_integrity_tags,
        default_profile_picture,
        account_status_tags
    });

    // this is the parent - can have context.
    let views = buildGroupViews(type, can_manage);

    const [view_id, setViewId] = useState(views[0].id);

    const view = views.filter(a => a.id === view_id)[0];

    const columns = buildGroupColumns(can_manage, view.columns, definitions, {community}, {
        data_integrity_tags,
        default_profile_picture,
        account_status_tags
    });

    // We'll start our table without any data
    const [items, setItems] = useState([])
    const [modal, setModal] = useState(null);

    const [export_data, setExportData] = useState(null);
    const [filter1, setFilter1] = useState(account_status);
    const [filter2, setFilter2] = useState(null);
    const [filter3, setFilter3] = useState(null);
    const [filter4, setFilter4] = useState(null);
    const [filters, setFilters] = useState([
        account_status,
        // data_integrity,
        // account_status_full,
        // country_filter,
        //   data_integrity,
        //   member_type_filter
    ])
    const [sort_by, setSortBy] = useState(view.sort)
    const [view_options, setViewOptions] = useState({
        include_archived: false,
        include_suspended: false,
    });
    const [separator, setSeparator] = useState(',');
    const [loading, setLoading] = useState(false);
    const [action, setAction] = useState(null);
    const [has_more, setHasMore] = useState(false);
    const updating_view = useRef(false)
    const fetchIdRef = useRef(-1)
    const last_ref = useRef(null)
    const isFirstRun = React.useRef(true);
    const [checked_url, setCheckedUrl] = useState(false);

    const filter_multiselect_count = filters.filter(a => a.operator === 'in' || a.operator === 'array-contains');

    useEffect(function() {
        if(!checked_url) {
            const urlParams = new URLSearchParams(window.location.search);
            const action = urlParams.get('action');
            if(action==='create') {
                handleLaunchAddMembers()
            } else if(action==='export') {
                setModal('pre-export');
            } else if(action==='invite') {
                handleSelectView('never-logged-in');
                handleSendInvite([
                    {
                        field: "account_status",
                        operator: "in",
                        value: ["not-invited","invite-sent"]
                    }
                ]);
            }
            setCheckedUrl(true);
        }
    }, []);

    function getMembers(pageIndex) {
        if (pageIndex !== fetchIdRef.current) {
            return;
        }
        // Give this fetch an ID
        const fetchId = ++fetchIdRef.current

        // Set the loading state
        setLoading(true)

        const final_filters = buildFinalFilters(type, id, filters);

        // items, sort_by, has_more, view, filters
        getManyMembersPaginated(community_uid, per_page, last_ref.current, final_filters, sort_by)
            .then(snap => {
                if (fetchId === fetchIdRef.current) {
                    const size = snap.size;
                    const new_entries = snap.docs.map(d => at_transformMember(d.data(), {}, d.id));
                    const new_items = fetchIdRef.current === 1 ? [...new_entries] : [...items].concat(new_entries);
                    if (size > 0) {
                        last_ref.current = snap.docs[(size - 1)];
                    }
                    setItems(new_items);
                    if (size === per_page) {
                        setHasMore(true);
                    } else {
                        setHasMore(false);
                    }
                    setLoading(false)

                }
            })

    }

    async function getMembersToSend(f2) {
        const final_filters = buildFinalFilters(type, id, f2?f2:filters);
        return await getManyMembers(community.uid, final_filters, sort_by)
            .then(members => {
                return membersBuildInviteTo(members);
            });
    }

    function handleSendEmail() {
        setAction("email-loading")

        getMembersToSend()
            .then(to => {
                if(to.length===0) {
                    alert("No members selected");
                    return;
                }
                const prefill = {
                    to,
                    config: {},
                    content: ``,
                    subject: ``,
                    type: 'email',
                    template: ''
                };

                community.openComposer(prefill)
            })
    }

    function handleSelectView(vw, cb) {

        const view_data = views.filter(v => v.id === vw)[0];

        updating_view.current = true;

        setFilter1(view_data.filter1);
        setFilter2(view_data.filter2);
        setFilter3(view_data.filter3);
        setFilter4(view_data.filter4);


        setSortBy(view_data.sort);
        setViewOptions(view_data.options);

        setViewId(view_data.id);
    }

    function handleSendDataCheck() {
        setAction("data-check-loading")

        getMembersToSend()
            .then(to => {
                if(to.length===0) {
                    alert("No members selected");
                    return;
                }
                const prefill = {
                    to,
                    config: {},
                    content: `Hi {{member.first_name}}, can you please make sure your information is up-to-date?`,
                    subject: `Check your Member Profile`,
                    type: 'data-check',
                    template: 'system-data-check'
                };

                community.openComposer(prefill)
            })
    }

    function handleSendInvite(f2) {
        setAction("invite-loading")

        getMembersToSend(f2)
            .then(to => {
                if(to.length===0) {
                    alert("No members selected");
                    return;
                }
                const ct = buildInviteTitle(community,data);
                const prefill = {
                    to,
                    config: {},
                    content: ct.content,
                    subject: ct.subject,
                    type: 'invite',
                    template: 'system-invite'
                };

                community.openComposer(prefill)
            })
    }

    function addToGroup(member_ids,lcb) {
        if (!member_ids || member_ids.length === 0) {
            return;
        }

        const obj = {};

        member_ids.forEach(id => {
            obj[id] = true;
        })

        const payload = {
            entities: {
                members: obj
            },
            id: id,
            member_id: community.member_id,
            community_uid: community_uid
        };

        const res = () => {
            if(lcb) {
                lcb();
            }
            global.addToast({text: 'Members added to group', intent: 'success'});
        };

        authFetch("/groups/add-new-entities", res, res, "POST", {payload});
    }

    function handleExportMembers(payload) {
        global.addToast({text: 'Export has started', intent: 'info'});

        const format = {
            date_format: regional_date_formats[payload.date_format],
            delimiter: export_separator_ref[payload.delimiter].separator
        }
        const final_filters = buildFinalFilters(type, id, filters);

        const custom_field_label_map = buildCustomFieldLabelMap(community);

        api_getNameMap(community_uid)
            .then(name_map => {

                getManyMembers(community.uid, final_filters, sort_by)
                    .then(members => {

                        let final_items = members.map(mem => {
                            return EXPORTtransformMember(mem, mem.id, headers, {
                                member_types: community.member_types,
                                cp_fields: community.custom_fields
                            }, format, name_map, custom_field_label_map)
                        })
                        setExportData(final_items);

                        logEngagement("export_members", {
                            user_uid: community.member.user_uid,
                            community_uid: community.uid,
                            member_id: community.member_id
                        })

                        global.addToast({text: 'Export ready!', intent: 'success'});
                    })
            });
    }

    const fetchItems = useCallback(({pageSize, pageIndex}) => {
        if (fetchIdRef.current === 0 || !has_more || pageIndex === 0 || !last_ref.current) {
            return;
        }

        getMembers(pageIndex)
    }, [items, sort_by, has_more, view, filters]);

    useEffect(function () {
        // wait until view is updated

        if (updating_view.current || fetchIdRef.current === 0) {
            return;
        }

        last_ref.current = null;
        setItems([]);
        setLoading(false);
        if (fetchIdRef.current !== 0) {
            // nothing
            fetchIdRef.current = 0;
        }

        getMembers(0);
    }, [filters, sort_by, view_id])

    useEffect(function () {
        if (isFirstRun.current) {
            isFirstRun.current = false;
            return;
        }
        let new_filters = [];

        if (filter1) {
            new_filters.push(buildSingleFilter(filter1, view_options));
        }

        if (filter2) {
            new_filters.push(buildSingleFilter(filter2, view_options));
        }

        if (filter3) {
            new_filters.push(buildSingleFilter(filter3, view_options));
        }

        if (filter4) {
            new_filters.push(buildSingleFilter(filter4, view_options));
        }

        setFilters([...new_filters]);

        updating_view.current = false;
    }, [filter1, filter2, filter3, filter4, view_options]);

    function updateItems(id, field, value) {
        const new_items = [...items];
        const index = items.findIndex(a => a.id === id);
        new_items[index][field] = value;
        setItems(new_items);
    }

    function handleLaunchAddMembers() {
        global.handleSetRightMenu('create-members',{
            cb: (ids) => {
                if(type==='group') {
                    addToGroup(ids, ()=>{
                        if(cb) {
                            cb()
                        }
                    });
                }
            },
            onConfirmed: (ids)=>{
                global.handleCloseRightMenu();
                const new_path = buildNewPathWithURLParam(history, 'reload=true');
                history.push(new_path);
                setModal(null);
            },
            initial: [],
            flow: 'directory'
        })
    }

    let actions = <>
        <TableActions handleExport={() => setModal('pre-export')} handleSendInvite={handleSendInvite}
                      handleSendEmail={handleSendEmail} handleSendDataCheck={handleSendDataCheck}/>

        <Button size="sm" onClick={() => {
            handleLaunchAddMembers();
        }} text="New Member"/>
    </>;

    const headers = buildMemberExportHeaders(members_headers, community.custom_fields);

    //     <PageHeader size={"2xl"} title="Member Directory" action={actions} />

    const context = {
        user_uid: community.member.user_uid,
        community_uid: community.uid,
        member_id: community.member_id
    };

    const row_actions = !can_manage ? [] : [
        {
            type: 'invite',
            tooltip: 'Send Invitation',
            onClick: (_member) => {
                const ct = buildInviteTitle(community,data);
                const _prefill = {
                    to: membersBuildInviteTo([_member]),
                    config: {},
                    content: ct.content,
                    subject: ct.subject,
                    type: 'invite',
                    template: 'system-invite'
                };

                community.openComposer(_prefill)
            },
            icon: <PaperAirplaneIcon/>
        },
        {
            type: 'edit',
            tooltip: 'Edit Member',
            onClick: (_member) => {
                global.handleSetRightMenu(`admin-member__${_member.id}`);
            },
            icon: <PencilAltIcon/>
        }
    ];

    const suggestions_handler = {
        handleDismiss: (_member, _row_index, _suggestion, _s_index) => {
            let ni = [...items];
            ni[_row_index]._suggestions.splice(_s_index,1);
            setItems(ni);
        },
        handleAccept: (_member, _row_index, _suggestion, _s_index) => {
            const ch = [{_member, _row_index, suggestions: [{_suggestion, _s_index}] }];
            acceptDataSuggestions(community,ch.map(c=>{
                return {
                    id: c._member.id,
                    suggestions: c.suggestions.map(sugg=>sugg._suggestion)
                }
            }))
                .then(()=>{
                    console.log("changes saved")
                });

            setItems(applyAllDataSuggestion(items, ch));
        },
        handleAcceptAll: (_member, _row_index, _suggestions) => {
            const ch = [{_member, _row_index, suggestions: _suggestions.map((s,k)=>{
                return {
                    _suggestion: s,
                    _s_index: k
                }
                }) }];
            acceptDataSuggestions(community,ch.map(c=>{
                return {
                    id: c._member.id,
                    suggestions: _suggestions
                }
            }))
                .then(()=>{
                    console.log("changes saved")
                });

            setItems(applyAllDataSuggestion(items, ch));
        },
        acceptManyRowChanges: (_rows) => {
            const ch = _rows.map(({_member,_row_index,_suggestions})=>{
                return {
                    _member,
                    _row_index,
                    suggestions: _suggestions
                }
            });
            setItems(applyAllDataSuggestion(items, ch));
        }
    };

    return <div className="relative">
        {can_manage && <div className="pt-1.5 pb-1 px-4">
            <div className="flex">
                <div className="flex-grow flex space-x-2">
                    <ViewsSelector handleSelectView={handleSelectView} view={view} config={config} views={views}
                                   definitions={definitions}/>

                  <Filters setFilter1={setFilter1} filter1={filter1} />
                </div>
                <div className="space-x-2 flex">
                    {actions}
                </div>
            </div>
        </div>}
        <div className="overflow-x-auto ">
            <AdvancedTable2
                can_filter={can_manage}
                scroll_options={scroll_options}
                scroll="infinite"
                row_actions={row_actions}
                context={context}
                scroll_id={scroll_id}
                onRowClick={(event, row) => {
                    event.stopPropagation();
                    const {original, index} = row;
                    if (can_manage) {
                        global.handleSetRightMenu(`admin-member__${original.id}`);
                    } else {
                        history.push(`/${community.data.handle}/member/${original.handle}`)
                    }
                }}
                has_more={has_more}
                setNewSort={(ns) => setSortBy({...ns})}
                sort_by={sort_by}
                filters={filters}
                suggestions_handler={suggestions_handler}
                suggestions={!can_manage ? "" : "_suggestions"}
                can_select={can_manage}
                meta={{
                    can_change_image: can_manage,
                    changeImageFn: (id, url, color) => {
                        let payload = {
                            url: url,
                            community_uid: community_uid,
                            member_id: community.member_id,
                            id: id,
                            color
                        };
                        const res = () => {
                        };
                        authFetch('/members/set-profile-picture', res, res, "POST", {payload});

                        updateItems(id, 'profile_picture', url);
                    }
                }}
                columns={columns}
                config={config}
                definitions={definitions}
                items={items}
                page_size={per_page}
                fetchItems={fetchItems}
                loading={loading || updating_view.current}
                pageCount={-1}
            />
        </div>
        {modal === 'pre-export' && <IntlExport onClose={() => setModal(null)} handleContinue={(payload) => {
            setAction('exporting');
            handleExportMembers(payload);
            setSeparator(export_separator_ref[payload.delimiter].separator)
            setModal(null);
        }}/>}
        <GenericCsvExport
            headers={headers}
            data={export_data}
            cb={() => {
                setExportData(null);
            }}
            separator={separator}
            file_name={`${community.data.name} Member Export ${moment().format('MMM Do, YYYY - hh:mm')}.csv`}
        />
    </div>
}