import React, {useState, useEffect} from "react";

export function MergeFlowHeader({}) {

    return  <div className="grid" style={{gridTemplateColumns: '1fr 320px'}}>
        <div className="flex">
            Title
        </div>
        <div>
            actions
        </div>
    </div>
}