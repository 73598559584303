import React from 'react';
import {ADMIN_COLOR} from "../../../config/defaults";

export const CIAdmin = () => {
    return <svg fill="currentColor" x="0" y="0" viewBox="0, 0, 24, 24">
        <g id="admin">
            <path fill={ADMIN_COLOR} d="M14.947,3 C18.29,3 21,5.71 21,9.053 L21,14.947 C21,18.29 18.29,21 14.947,21 L9.053,21 C5.71,21 3,18.29 3,14.947 L3,9.053 C3,5.71 5.71,3 9.053,3 L14.947,3 z M12.004,11.615 C11.8,11.615 11.604,11.696 11.46,11.841 L7.614,15.687 C7.469,15.831 7.388,16.027 7.388,16.231 C7.388,16.435 7.469,16.63 7.614,16.775 C7.758,16.919 7.954,17 8.158,17 C8.362,17 8.557,16.919 8.701,16.775 L12.004,13.472 L15.306,16.775 C15.451,16.915 15.646,16.992 15.847,16.991 C16.049,16.989 16.242,16.908 16.385,16.765 C16.527,16.623 16.608,16.43 16.61,16.228 C16.612,16.026 16.534,15.832 16.394,15.687 L12.548,11.841 C12.403,11.696 12.208,11.615 12.004,11.615 z M12.004,7 C11.8,7 11.604,7.081 11.46,7.225 L7.614,11.071 C7.469,11.216 7.388,11.411 7.388,11.615 C7.388,11.819 7.469,12.015 7.614,12.159 C7.758,12.303 7.954,12.384 8.158,12.384 C8.362,12.384 8.557,12.303 8.701,12.159 L12.004,8.857 L15.306,12.159 C15.451,12.299 15.646,12.377 15.847,12.375 C16.049,12.373 16.242,12.292 16.385,12.15 C16.527,12.007 16.608,11.814 16.61,11.612 C16.612,11.411 16.534,11.217 16.394,11.071 L12.548,7.225 C12.403,7.081 12.208,7 12.004,7 z"/>
        </g>
    </svg>

};