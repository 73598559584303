import {authFetch} from "../../../config/network";
import React from "react";
import {EntitiesPreview} from "../../../library/components/custom/entities-preview";

function getEntityID(type, id) {
    if (type === 'filters') {
        return `${id.field}.${id.num}`;
    }
    return id;
}

export function removeListEntity(type, id) {
    if (this.state._removing_entity) {
        return;
    }
    const payload = {
        community_uid: this.props.community_uid,
        id: this.id,
        entity_type: type,
        entity_id: getEntityID(type, id)
    };

    const res = (result) => {
        this.setState({
            _removing_entity: false
        });
    };

    this.setState({
        _removing_entity: true
    });

    authFetch("/lists/remove-entity", res, res, "POST", {payload});
}
/*
<Popover2
        content={<EntityListPopup loading={_removing_entity} title={title}>

            <ESTable onRemove={removeEntity} no_top_margin can_remove={can_manage} block_removal={block_removal} entities={entities} community_uid={community_uid}/>
        </EntityListPopup>}
        placement={"bottom-start"}
        enforceFocus={false}
        onClose={() => {
            close();
        }}
        isOpen={is_open} minimal popoverClassName="popup-box-shadow popover-standard"
    >
 */
export function ListMembersEntity({open,type_name="Members",openEntities,data,can_manage,community_uid}) {
    return <EntitiesPreview type_name={type_name} community_uid={community_uid} openEntities={openEntities} toggleOpen={() => {
            open();
        }} user_uids={data.user_uids} can_manage={can_manage} />

}