import React, {useContext, useEffect, useState} from "react";
import {useHistory} from 'react-router-dom';
import BirthdaysWidget from "../../../library/components/old/birthdays-widget";
import {setDocumentTitle} from "../../../library/utilities/set-document-title";
import {CommunityContext} from "../community-context";
import {GlobalContext} from "../../global/global-context";
import {HomeFooterLinks} from "./footer-links";
import {ActionWithIcon, EntityHeader, Pinboard} from "../../../library/components/core";
import {authFetch} from "../../../config/network";
import {useIntl} from "react-intl";
import {HomeWelcomeCard} from "../../../library/components/reorganize/home-welcome-card";
import {group_buildPinsExtras} from "../group/pins";
import {NewApplicantsWidget} from "./components/new-applicants";
import {processDataForWelcomeCard} from "./components/welcome-card/process-data";
import {useIsMobile} from "../../global/global-router";
import {MobileHomePage} from "./mobile";
import {InboxHomeWrapper} from "../../../library/components/posts-feed/home-wrapper";
import {StandardPageFramework, StandardPageHeight} from "../../../library/playground/standard-page-framework";
import {AddPinModal} from "../group/add-pin-modal";
import {QuillIcon} from "../../../library/icons/custom/Quill";
import {NFIMockWrapper} from "../../../library/components/new-feed-item/mock-wrapper";

export default function CommunityHome(props) {
    const history = useHistory();
    const {formatMessage: f} = useIntl();
    const [modal, setModal] = useState(null);
    const global = useContext(GlobalContext);
    const is_mobile = useIsMobile();
    const community = useContext(CommunityContext);
    const [refresh, setRefresh] = useState(0);

    useEffect(function () {
        setDocumentTitle(f({id: 'sidebar.home'}), community.data.name);
    }, []);

    function addPin(pin_id, pin_data, cb) {

        const payload = {
            member_id: community.member_id,
            community_uid: community.uid,
            pin_id,
            pin_data
        };

        const res = () => {
            setTimeout(() => {
                if (cb) {
                    cb();
                }
            }, 1000);

            global.addToast({text: 'Pin added', intent: 'success'});
        };

        const err = () => {
            global.addToast({text: 'Pin could not be added', intent: 'danger'});
        };

        authFetch("/communities/add-pin", res, err, "POST", {payload});
    }

    function newMessage() {
        const prefill = {
            to: [],
            config: {},
            content: ``,
            subject: ``,
            type: 'email',
            template: ''
        };

        community.openComposer(prefill)
    }

    let links = [];

    const data = community.data;

    let rpins = data.pins ? data.pins : [];
    let rpins_data = data.pins_data ? data.pins_data : {};
    let pins = [];

    const new_pins = group_buildPinsExtras(pins, rpins, rpins_data, history);

    links.push({
        text: f({id: 'home.customize_home'}),
        onClick: () => {
            global.handleSetRightMenu('community-preferences');
        }
    });

    links.push({
        text: f({id: 'policies.privacy_policy'}),
        onClick: () => {
            history.push(`/${community.data.handle}/policies/privacy-policy`)
        }
    });

    if (is_mobile) {

        return <div className='mobile-page-height overflow-y-auto '>
            <MobileHomePage/>
        </div>;
    }

    let content = <InboxHomeWrapper refresh={refresh}/>;

    const sidebar = <>
        <HomeWelcomeCard community_uid={community.uid} history={history} meta={{
            user_uid: community.member.user_uid,
            community_uid: community.uid,
            member_id: community.member_id
        }}
                         data={processDataForWelcomeCard(community.member, community.member_id, community.data, community.uid, community.important_custom_fields, community.custom_fields, community.updateMember)}/>

        {new_pins.length > 0 &&
        <Pinboard title={"Pinboard"} community_uid={community.uid} can_add_pin={true} onAddPin={() => {
            setModal('add-pin');
        }} pins={new_pins}/>}

        {community.is_admin && <NewApplicantsWidget/>}

        <BirthdaysWidget/>

        <HomeFooterLinks links={links}/>
    </>;

    // {content}
    //  <NFIMockWrapper />
    // <ActionWithIcon icon={<QuillIcon />} inverse onClick={newMessage} text="New Message" />
    return (<StandardPageFramework content sidebar={sidebar}>

            <EntityHeader sticky actions={community.member_access.manage_members ?
                <ActionWithIcon icon={<QuillIcon/>} inverse onClick={newMessage} text="New Message"/> : null}
                          title="Latest"/>
            <div id="message-content">
                {content}
            </div>
            {modal === 'add-pin' && <AddPinModal
                pins={rpins}
                onClose={() => setModal(null)}
                onConfirm={addPin} pins_data={rpins_data}/>}
        </StandardPageFramework>
    );

}