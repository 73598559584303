import React, {useState} from "react";
import {authFetch} from "../../config/network";
import {Button, Input} from "../../library/components/core";

export function GPCISendInvite({}) {
const [user_uid,setUserUID] = useState('');
const [status,setStatus] = useState('');
    function sendInvite() {
        const payload = {
            target_user_uid: user_uid
        };
        setStatus('sending');
        const cb = (it) => {
            setUserUID('');
            setStatus('sent');
            alert('sent!')
        };
        authFetch("/superadmin/send-community-invite", cb, cb, "POST", {payload});
    }

    return <div>
        <div className="text-lg font-semibold text-gray-800">
            Invite a user to create a community
        </div>
        <div className="flex max-w-lg space-x-2">
        <div className="flex-grow">
<Input value={user_uid} placeholder={"USER UID"} onChange={(uid)=>setUserUID(uid)} />
        </div>
        <div className="flex-none">
        <Button text="Send Invite" loading={status==='sending'} intent="secondary" disabled={user_uid.length<4} onClick={()=>sendInvite()} />
        </div>
    </div>
    </div>
}
