import React, {Component} from "react";

import {getUrlVars} from "../../../library/utilities/general";
import {validateEmail} from "../../../library/utilities/validation";
import {authFetch, externalAuthFetch} from "../../../config/network";
import Field from "../../../library/components/old/field";
import ButtonSection from "../../../library/components/old/button-section";
import Form from "../../../library/components/old/form";
import {Button} from "../../../library/components/core";
import styled from "styled-components";

const MetaText = styled.p`
      margin: 24px 0;
    line-height: 1.4;
`;

class MagicLinkBlock extends Component {
    constructor(props) {
        super(props);

        this.state = {
            sending: false,
            email: '',
            sent: false
        }
    }

    hc(f, v) {
        this.setState({
            [f]: v
        })
    }

    componentDidMount() {
        let email = getUrlVars()['email'];

        if (typeof email === 'string') {
            this.setState({
                email: email
            });
            this.emailInput.focus();
        }
    }

    handleSend(disable,e) {
        if(e) {
            e.preventDefault();
        }
        if(disable) {
            return;
        }

        const {sending, email} = this.state;
        if (sending) {
            return;
        }

        this.setState({
            sending: true
        });

        externalAuthFetch("/send-magic-email", this.handleResult.bind(this), this.handleError.bind(this), "POST", {email});
    }

    handleResult() {
        this.setState({
            sending: false,
            sent: true
        })
    }

    handleError() {
        this.setState({
            sent: true,
            sending: false
        })
    }

    renderAction(sending, sent) {
       if (sent) {
            return <div>
                <MetaText className='text-gray-800'>
                    If you have an account with us, you'll receive a Magic link sent, please check your spam if you don't see it as well
                </MetaText>
            </div>
        }

        const {email} = this.state;

        const disable = this.disableBtn();

        return <>
            <Form handleSubmit={ this.handleSend.bind(this,disable)}>

                <MetaText className='text-gray-800'>
                    Enter the email address associated with your account and we'll send you a simple sign-in link.
                </MetaText>

                <Field autoFocus={true} type={"email"} label="Email" onChange={this.hc.bind(this, 'email')} value={email} />

            </Form>

            <ButtonSection className='auth-actions'>
                <Button intent='secondary' fill size='large' disabled={disable} onClick={this.handleSend.bind(this, disable)} text='Get Magic Link' loading={sending} />
            </ButtonSection>

        </>;
    }

    disableBtn() {
        let {email} = this.state;
        return (!validateEmail(email))
    }

    render() {
        const {sending, sent} = this.state;
        return (
            <>
                {this.renderAction(sending, sent)}
            </>
        );
    }
}

export default MagicLinkBlock;