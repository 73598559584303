import {getSelectedBlocksList, getSelectionText} from "draftjs-utils";
import {EditorState, getDefaultKeyBinding, KeyBindingUtil, Modifier, RichUtils} from "draft-js";
const {hasCommandModifier} = KeyBindingUtil;
// x = 88
// v = 86
// c = 67
// s = 83
// u = 85
// k = 75
// add bold (66)
// add strikethrough
// add italic (73)

// 39 = right
// 37 = left
// 38 = up
// 40 = down

export function scOpenKeyBindingFn(es,open,e) {
    const cs = es.getCurrentContent();
    const selection = es.getSelection();
    const anchor_block_key = selection.getAnchorKey();
    const block_data = Object.fromEntries(cs.getBlockForKey(anchor_block_key));
console.log("keycode",e.keyCode)
console.log("open",open)

    return 'not-handled'
    // 186 = semicolon
   // return getDefaultKeyBinding(e);
}

const is_ordered_list_regex = /^(0?[1-9]|[1-9][0-9])+\./;

export function scEditorKeyBindingFn(es,sm=()=>{},last_key,updateLastKey,e) {
    const is_shift = !!e.shiftKey;

    const cs = es.getCurrentContent();
    const selection = es.getSelection();
    const anchor_block_key = selection.getAnchorKey();
    const block_data = Object.fromEntries(cs.getBlockForKey(anchor_block_key));
    const _last_key = last_key;
// console.log("KEYS",_last_key,e.keyCode)
    updateLastKey(e.keyCode);
    const could_be_md = e.keyCode===32&&block_data.type==='unstyled'&&block_data.text.length<4;
    if(e.keyCode===189&&_last_key===189) {
        return 'em';
    } else if (e.keyCode === 66 && hasCommandModifier(e)) {
        return 'bold';
    } else if (e.keyCode === 88 && is_shift && hasCommandModifier(e)) {
        return 'strikethrough';
    } else if (e.keyCode === 73 && hasCommandModifier(e)) {
        return 'italic';
    } else if (e.keyCode === 75 && hasCommandModifier(e)) {
        // also want to check to make sure there is text selected that does not contain any entities
        if(!es) {
            return getDefaultKeyBinding(e);
        }
        const selection_state = es.getSelection();
        const content_state = es.getCurrentContent();
        const current_block = content_state.getBlockForKey(selection_state.getStartKey());
        const blocks = getSelectedBlocksList(es);
        const selection_text = getSelectionText(es);
        // undefined if there are none
        if(blocks.size===1&&selection_text.length>0) {
            sm(selection_text,selection_state.getStartKey());
            return 'add-link';
        }
    } else if (e.keyCode === 85 && hasCommandModifier(e)) {
        return 'underline';
    } else if(could_be_md&&block_data.text==='##') {
        // remove str and convert block type
        return 'convert-to-h2';
    } else if(could_be_md&&block_data.text==='#') {
        // remove str and convert block type
        return 'convert-to-h1';
    } else if(could_be_md&&block_data.text==='>') {
        // remove str and convert block type
        return 'convert-to-blockquote';
    } else if(could_be_md&&block_data.text==='*') {
        return 'convert-to-unordered-list-item';
    } else if(could_be_md&&block_data.text==='-') {
        return 'convert-to-unordered-list-item';
    } else if(could_be_md) {
        if(is_ordered_list_regex.test(block_data.text)) {
            return 'convert-to-ordered-list-item';
        }
    }
    return getDefaultKeyBinding(e);
}