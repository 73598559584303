import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
    display: grid;
        border-radius: 0px 0px 6px 6px;
        padding: 16px 20px;
    grid-template-columns: 1fr auto;
`;

const Left = styled.div`
    display:flex;
`;

const Right = styled.div`
    display:flex;
    > div {
        margin-left: 12px;
    }
`;

export function AMFooter({primary, secondary, tertiary}) {

    return <Wrapper>
        <Left>
            {tertiary}
        </Left>
        <Right className="space-x-2">
            {secondary}
            {primary}
        </Right>
    </Wrapper>
}