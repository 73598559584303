import React, {useEffect, useState} from 'react';
import {InlineLoader} from "../../library/components/core";
import {getSuperAdminMetrics} from "./get-metrics";
import {GlobalEntityInfo} from "../../library/utilities/localstorage/entity-localstorage";

function UserItem({data}) {
    return <div>
        {data.name} ({data.account_email})<br/><br/>
    </div>
}

function MetricItem({label,value,keys,desc}) {
const [show,setShow] = useState(false);
    return <div className="rounded-lg border border-gray-200 shadow-md p-4">
        <div className="font-bold text-gray-900 text-3xl">
            {value}
        </div>
        <div className="font-medium text-sm mt-2 text-gray-800">
            {label}
        </div>
        <div className="text-xs text-gray-600 mt-1">
            {desc}
        </div>
        {value>0&&!show&&<div onClick={()=>setShow(true)} className={`text-xs text-gray-500 cursor-pointer hover:underline`}>See Users</div>}
        {show&&<div className=" text-xs text-gray-600 overflow-y-auto" style={{maxHeight:'200px'}}>
            {keys.map(id=><div key={id}>
                <GlobalEntityInfo type="user" id={id}>
                    <UserItem />
                </GlobalEntityInfo>
            </div>)}
        </div>}
    </div>
}

export function SuperadminMetricsPage() {

    const [data,setData] = useState(null);

    useEffect(function () {
        getSuperAdminMetrics()
            .then(metrics=>{
                setData(metrics)
            })
    }, [])

    if(!data) {
        return <div>
            <InlineLoader padding />
        </div>
    }

    return <div>
        <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5">
        {data.map((metric,k)=>{
        return <MetricItem key={k} {...metric} />
        })}
        </div>
        <div className="mt-12 text-xs text-gray-600">
            Note: Data collection started on Dec 29, 2021<br/><br/>
            Time frame: Last 2 weeks<br/><br/>
            Active users = Unique # of users last 2 weeks that took an important action<br/><br/>
            <b>Important Actions:</b>
            <pre>
                create_group<br/>
                add_member<br/>
                export_members<br/>
                add_member_to_group<br/>
                sent_member_invite<br/>
                update_member_profile_as_admin<br/>
                create_group_mailing_list<br/>
                sent_post<br/>
                send_group_email
            </pre>
        </div>
    </div>
}