import React from "react";
import styled from "styled-components";
import {UnatySVG} from "../../../icons/unaty/logos";
import {Avatar} from "../../core";

const LoadingScreen = styled.div`
        position: absolute;
    top: 0;
    z-index: 10000;
    left: 0;
    right: 0;
    height: 100vh;
    display: flex;
    align-items: center;
        background: #ffffff;
    justify-content: center;
    svg {
    
        path {
           
        }
    }
    div {
       
    }
`;

const LoadingText = styled.div`
  font-weight: 500;
  font-size: 16px;
  margin-top: 16px;
  color: #3b3b3b;
  text-align: center;
`;

const LoadingSVG = styled.div`
width: 80px;
`;

export const FullScreenLoader = ({community}) => {

    if(community&&community.profile_picture) {
        return <LoadingScreen>

            <div style={{paddingBottom:'20px'}} className="animate-flicker">
                <div>
                    <Avatar size="xl" url={`${community.profile_picture}?alt=media`} />
                </div>
                <LoadingText>Loading</LoadingText>
            </div>
        </LoadingScreen>;
    }

    return <LoadingScreen>

        <div style={{paddingBottom:'20px'}} className="animate-flicker">
            <LoadingSVG>
                <UnatySVG />
            </LoadingSVG>
            <LoadingText>Loading</LoadingText>
        </div>
    </LoadingScreen>;
};