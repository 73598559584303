import React, {useState} from 'react';
import classNames from 'classnames';
import styled from "styled-components";
import {useIsMobile} from "../../../app/global/global-router";
const props = {

};

const classes = {
  '0': 'rounded-r-none',
  '1': 'rounded-none -mr-px -ml-px',
  '2': 'rounded-l-none',
  '3': 'rounded-r-none',
  '4': 'rounded-none -mr-px -ml-px',
  '5': 'rounded-l-none'
};

const Dashbox = styled.div`

`;

export function PinEntry({len=6,onSubmit=()=>{},setCode=()=>{}}) {
    const [entries,updateEntries] = useState(Array.from(Array(len), (_,x) => {
        return {
            value: '',
            ref: React.createRef()
        }
    }));
    const [pin,setPin] = useState(new Array(len + 1).join("Z"));

    const is_mobile = useIsMobile();

    function handleChange(val,index) {
        // only allow numbers
        // if index + 1 === len, is last
        if(isNaN(val)) {
            return;
        }

        let ne = entries.slice();
        let np = `${pin}`;

        // PASTE ONLY
        if(val.length===len&&index===0) {
            // possible paste and is number, lets try

            for(let i=0;i<len;i++) {
                ne[i].value = val.charAt(i);
                np = `${np}${val.charAt(i)}`;
            }

            setPin(np);

            updateEntries(ne);

            entries[(len-1)].ref.current.focus();

            onSubmit(np);
            return;
        }

        ne[index].value = val.charAt(0);
        np = np.replaceAt(index,val.charAt(0));

        setPin(np);

        updateEntries(ne);

        if(val==='') {
// what happens here?
        } else if((index+1)===len) {
            onSubmit(np);
        } else {
            entries[(index+1)].ref.current.focus();
        }
    }

    return <form autoComplete="off" className="flex max-w-lg">
        {entries.map((en,k)=><div className="flex" key={k}>
            <input placeholder="•" className={classNames(`${is_mobile?"px-2 w-10 py-2 text-xl":"px-3 w-14 py-4 text-2xl"}`," focus:z-10 block border inline-flex  text-center  outline-none rounded-lg shadow-sm new-focus-standard sm:flex-1 border-gray-300 placeholder-gray-500 text-gray-800",classes[k.toString()])} autoFocus={k===0} ref={en.ref} value={en.value} type="text" onChange={e=>handleChange(e.target.value,k)} />
            {k===2&&<Dashbox className={` ${is_mobile?"px-2.5 text-2xl":"px-4 text-3xl"} flex items-center justify-center  text-gray-500`}>-</Dashbox>}

        </div>)}
    </form>
}