import React, {useContext, useEffect, useRef, useState} from 'react';
import {CommunityContext} from "../../../../community-context";
import {GlobalContext} from "../../../../../global/global-context";
import styled from "styled-components";
import {ActionWithIcon, InfoState, SectionHeader} from "../../../../../../library/components/core";
import {FirebaseDB} from "../../../../../../config/setup-firestore";
import moment from "moment";
import {aa_formatDate} from "../../../../../../library/utilities/general/dates";
import {uniqBy} from 'lodash';

const statuses_map = {
    200: '200 OK',
    500: '500 Server Error'
};

function Status({status_code}) {
    return <div className="uppercase inline-flex text-xs py-0.5 px-1 rounded-md bg-gray-100 font-medium text-gray-600">
        {statuses_map[status_code]}
    </div>
}


function LogListItem({active, id, status_code = 200, method = "POST", endpoint, ts, onClick}) {
    return <div onClick={onClick} className="relative cursor-pointer group">
        <div className="grid border-b px-2.5 gap-2 border-b shared-bottom-border py-2"
             style={{gridTemplateColumns: 'auto auto 1fr 0.5fr'}}>
            <div>
                <Status status_code={status_code}/>
            </div>
            <div className="uppercase text-sm font-medium text-gray-800">
                {method}
            </div>
            <div className=" text-sm font-medium text-gray-800">
                {endpoint}
            </div>
            <div className="text-xs flex justify-end items-center text-gray-500 text-right">
                {aa_formatDate(ts).date_formatted}
            </div>
        </div>
        <div
            className={`absolute top-1 w-1 left-0 bottom-1 rounded ${active ? "bg-selection" : "group-hover:bg-gray-400"}`}/>
    </div>
}

const DateHeader = styled.div`
  text-transform: uppercase;
  font-size: 11px;
  color: #1A1F35;
  letter-spacing: 0.2px;
  padding-bottom: 0.5rem;
  font-weight: 500;
`;

function LogList({setSelected, has_more, loading, onLoadMore, selected, chunks}) {

    return <div>
        <div></div>
        <div>
            {chunks.map(ch => {
                const fi = ch[0]._date;
                const key = `${fi}-${ch[0].id}`;
                return <div key={key}>
                    <DateHeader>{fi}</DateHeader>
                    <div className="border-t shared-top-border">
                        {ch.map(log => <LogListItem active={selected===log.id} key={`log-${log.id}`} onClick={() => setSelected(log.id)} {...log} />)}
                    </div>
                </div>
            })}
            <div className="p-2.5">
                {has_more&&!loading&&<ActionWithIcon text="Load more" onClick={onLoadMore}/>}
            </div>
        </div>
    </div>
}

function Metadata({title, val}) {
    return <div className="grid py-1 grid-cols-5">
        <div className="text-gray-500 text-sm col-span-2">{title}</div>
        <div className="text-gray-800 text-sm col-span-3">{val}</div>
    </div>
}

function CodeBlock({json}) {
    const parsed = JSON.parse(json);
    return <div className="">
        <pre className="text-xs p-2 bg-gray-100 border-gray-300 rounded-md overflow-y-scroll text-left"
             style={{maxHeight: '400px'}}>{JSON.stringify(parsed, undefined, 2)}</pre>
    </div>
}

function SelectedLog({
                         status,
                         request_body = "",
                         response_body = "",
                         status_code,
                         api_key_last4="",
                         ts,
                         api_version,
                         ip,
                         origin,
                         id,
                         method,
                         endpoint
                     }) {

    if(!method||!endpoint) {
        return null;
    }
    return <div className="space-y-4">
        <div>
            <div className="text-xl font-black text-gray-800">{method} {endpoint}</div>
        </div>
        <div>
            <Metadata title="Status" val={<Status status_code={status_code}/>}/>
            <Metadata title="ID" val={id}/>
            <Metadata title="Time" val={aa_formatDate(ts).date_formatted}/>
            <Metadata title="API Version" val={api_version}/>
            <Metadata title="IP" val={ip}/>
            <Metadata title="API Key" val={`sk_live_****************************${api_key_last4}`}/>
            <Metadata title="Origin" val={origin}/>
        </div>
        <div>
            <SectionHeader size="lg" padded={false} title={"Response Body"}/>
            <div className="pt-2">
                <CodeBlock json={response_body}/>
            </div>
        </div>
        <div>
            <SectionHeader size="lg" padded={false} title={"Request Body"}/>
            <div className="pt-2">
                <CodeBlock json={request_body}/>
            </div>
        </div>
    </div>
}

function processDocs(docs) {
    return docs.map(doc => {
        let id = doc.id;
        const dt = doc.data();
        return {
            ...dt,
            id,
            _date: moment(dt.ts).format('MMMM D, YYYY')
        }
    })
}

const page_size = 20;

export function DevsLogs(props) {
    const global = useContext(GlobalContext);
    const community = useContext(CommunityContext);

    const [loading, setLoading] = useState(true);
    const [selected, setSelected] = useState(null);
    const [has_more, setHasMore] = useState(false);
    const [data, setData] = useState(null);
    const last_ref = useRef(null)

    useEffect(function () {
        getLogItems()
    }, []);

    function getLogItems(lr) {
        if(lr&&loading) {
            return
        }
        if(!lr) {
            setData(null);
        }

        getLogs(lr)
            .then((snap) => {
                if (snap.size > 0) {
                    last_ref.current = snap.docs[(snap.size - 1)];
                }
                let nd = data ? data : [];
                setData(uniqBy([...nd, ...processDocs(snap.docs)],'id'));
                setHasMore(snap.size === page_size);
                setLoading(false);
            })
    }

    async function getLogs(lr) {
        let base = FirebaseDB
            .collection('api')
            .doc(community.uid)
            .collection('logs')
            .orderBy('ts', 'desc')

        if (lr) {
            base = base.startAfter(lr);
        }
        base = base.limit(page_size);
        return await base
            .get()
            .then(snap => {
                return snap;
            })
    }

    function getMoreLogs() {
        setLoading(true);
        getLogItems(last_ref.current)
    }

    const chunked = !data ? [] : Object.values(data.reduce((a, b) => {
        (a[b._date] = a[b._date] || []).push(b);
        return a;
    }, {}));

    const selected_data = selected && data ? (data&&data.filter(a => a.id === selected)[0]?data.filter(a => a.id === selected)[0]:{}) : {};

    return <div className="grid gap-4 grid-cols-2">
        <div className="p-4">
            {data&&data.length===0&&<div>
                <InfoState subtitle="No logs found" />
            </div>}
            <LogList selected={selected} setSelected={(sid) => setSelected(sid)} has_more={has_more} chunks={chunked}
                     onLoadMore={() => getMoreLogs()} loading={loading}/>
        </div>
        <div className="p-4">
            {selected && selected_data.endpoint && <SelectedLog {...selected_data} />}
        </div>
    </div>
}