import React, {useEffect, useContext, useState} from 'react';
import {GlobalContext} from "./global-context";
import firebase from "firebase/app";
import {FirebaseDB} from "../../config/setup-firestore";
import {getManyItems} from "../../library/utilities/get-many-items";
import {validateEmail} from "../../library/utilities/validation";
import {authFetch, externalAuthFetch} from "../../config/network";
import {ToastsContext} from "../toasts/toasts-context";
import {getCurrentLang} from "../../library/utilities/general";
import {setFavicon} from "../../library/utilities/set-document-title";

const default_value = {
    user_uid: '',
    handle: '',
    language: '',
    right_menu: null,
    timezone: '',
    policies_data: null,
    logging_in: true,
    logging_out: false,
    secondary_emails: [],
    suspended: false,
    logged_in: false,
    archived: false,
    auth_updated: null,
    full_memberships: {},
    community_data: {},
    user: null,
    refresh_token: ''
};

function getMiniCommunity(community) {
    return {
        member_card_design: community.member_card_design ? community.member_card_design : {background:{color:community.branding.color,type:'color',image:community.profile_picture}},
        name: community.name,
        cover_picture: community.cover_photo,
        emoji: community.emoji?community.emoji:'',
        color: community.branding.color,
        handle: community.handle,
        status: community.status,
        profile_picture: community.profile_picture
    }
}

function getLanguage(data) {
    const user_language = data.preferences.language;
    return user_language ? user_language : getCurrentLang();
}

function transformCommunities(arr) {
    let obj = {};
    let ids = [];
    for (let i = 0; i < arr.length; i++) {
        const item = arr[i];
        ids.push(item.id);
        obj[item.id] = getMiniCommunity(item);
    }
    return obj;
}

export function GlobalProvider({children}) {
    const toasts = useContext(ToastsContext);
    const [global, setGlobal] = useState(default_value);
    const [policies_data, setPoliciesData] = useState(null);
    const [logging_in, setLoggingIn] = useState(true);
    const [login_error, setLoginError] = useState(null);
    const [open_search, setOpenSearch] = useState(null);
    const [image_viewer, setImageViewer] = useState(null);
    const [file_viewer, setFileViewer] = useState(null);
    const [right_menu, setRightMenu] = useState(null);
    const [logged_in, setLoggedIn] = useState(false);
    const [logging_out, setLoggingOut] = useState(false);
    const [loading_communities, setLoadingCommunities] = useState(true);
    const [memberships, setMemberships] = useState([]);
    const [members_data, setMembersData] = useState({});
    const [full_memberships, setFullMemberships] = useState({});
    const [applications, setApplications] = useState({});
    const [member_type_ids, setMemberTypeIds] = useState({});
    const [member_type_data, setMemberTypeData] = useState({});
    const [member_ids, setMemberIds] = useState({});
    const [secondary_emails, setSecondaryEmails] = useState([]);
    const [community_data, setCommunityData] = useState({});

    function setToken() {
        firebase.auth().currentUser.getIdToken(true).then(function (idToken) {
            document.cookie = '__session=' + idToken + ';max-age=3600';
        });
    }

    async function awaitSetToken() {
        await firebase.auth().currentUser.getIdToken(true).then(function (idToken) {
            document.cookie = '__session=' + idToken + ';max-age=3600';
        });
    }

    useEffect(function () {
        const entries = Object.entries(member_ids);
        if(entries.length === 0) {
            return;
        }
        let itemRefs = entries.map(([cid, id]) => {
            if(typeof id !== 'string') {
                return;
            }
            return FirebaseDB.collection('community_members').doc(cid).collection('members').doc(id).get();
        });
        Promise.all(itemRefs)
            .then(docs => {
                let items = {};
                let member_type_ids = {};
                docs.map(doc => {
                    if(doc&&doc.exists) {
                        const data = doc.data();
                        member_type_ids[data.community_uid] = data.member_type;
                        items[data.community_uid] = data;
                    }
                });
                setMembersData(items);
                setMemberTypeIds(member_type_ids);
            })
            .catch(error => console.log(error))
    }, [member_ids]);

    useEffect(function () {
        const entries = Object.entries(member_type_ids);
        console.log('member_type_ids',member_type_ids);
        if(entries.length === 0) {
            console.log("no member type ids")
            return;
        }
        let itemRefs = entries.map(([cid, id]) => {
            if(typeof id !== 'string' || !id || !cid || typeof cid !== 'string') {
                // nothing
            } else {
                return FirebaseDB.collection('community_members').doc(cid).collection('member_types').doc(id).get();
            }
        });
        Promise.all(itemRefs)
            .then(docs => {
                let member_type_data = {};
                docs.map(doc => {
                    member_type_data[doc.data().community_uid] = doc.data();
                });
                setMemberTypeData(member_type_data);
            })
            .catch(error => console.log(error))
    }, [member_type_ids]);

    // get remote config data
    useEffect(function () {
        FirebaseDB
            .collection('remote_config')
            .doc('policies')
            .get()
            .then(doc => setPoliciesData(doc.data()));

        // set logo
        setFavicon("/favicon-32x32.png");
    }, []);

    useEffect(function () {
        setLoggingIn(false);
        setLoggingOut(false);
    }, [logged_in]);

    function recordSignIn() {
        if(global.user) {
            authFetch('/users/record-sign-in', () => {
            }, () => {
            }, 'POST', {});
        }
    }

    useEffect(function () {
        let auth_sub = firebase.auth().onAuthStateChanged(user => {
            if (user) {
                setToken();
                recordSignIn();
                setLoggedIn(true);
                setLoginError(null);
                setGlobal({
                    ...global,
                    user_uid: user.uid,
                    refresh_token: user.refreshToken,
                    auth_updated: Date.now()
                });

            } else {
                setLoggedIn(false);
                setLoginError(null);
                setGlobal({
                    ...default_value,
                    auth_updated: Date.now()
                });
                setSecondaryEmails([]);
            }
        });
        return () => auth_sub();
    }, []);

    function getUserData(uid) {
        FirebaseDB.collection("users").doc(uid)
            .onSnapshot((doc) => {
                if (doc.exists) {
                    const data = doc.data();
                    setGlobal({
                        ...global,
                        handle: data.handle,
                        logged_in: true,
                        timezone: data.preferences.timezone,
                        language: getLanguage(data),
                        archived: data.archived,
                        suspended: data.suspended,
                        user: data
                    });
                } else {
                    setGlobal({
                        ...global,
                        handle: '',
                        archived: false,
                        logged_in: false,
                        suspended: false,
                        not_found: true,
                        user: null
                    });
                }
            });
    }

    function getCommunityData(uid) {
        FirebaseDB.collection("user_memberships").doc(uid)
            .onSnapshot((doc) => {
                if (doc.exists) {
                    const memberships = doc.data().communities;
                    const ids = Object.keys(memberships);
                    setFullMemberships(doc.data().member_ids);
                    setApplications(doc.data().application_status?doc.data().application_status:{});
                    getManyItems('communities', ids, (data) => {
                        setMemberships(ids);
                        setMemberIds(doc.data().member_ids ? doc.data().member_ids : {});
                        setCommunityData(transformCommunities(data));
                        setLoadingCommunities(false);
                    })
                } else {
                    setMemberships([]);
                    setLoadingCommunities(false);
                }
            });
    }

    useEffect(function () {
        let community_memberships = () => {
        };
        let user_data_sub = () => {
        };

        if (global.user_uid !== '') {
            community_memberships = getCommunityData(global.user_uid);
            user_data_sub = getUserData(global.user_uid);
        }

        return () => {
            if (user_data_sub) {
                user_data_sub();
            }
            if (community_memberships) {
                community_memberships();
            }

        };
    }, [global.user_uid]);

    function logout(redirect) {
        setLoggingOut(true);
        setGlobal({...global, user: null, user_uid: '', handle: ''});
        firebase.auth().signOut().then(function () {
            setMemberships([]);
            setLoggedIn(false);
            setGlobal({...default_value});
            redirect();
        });
    }

    function login(username, password, redirect = () => {
    }) {
        setLoggingIn(true);
        setLoginError(null);
        if (validateEmail(username)) {
            tryLogin(username, password, redirect);
        } else {
            // might be a handle
            const res = (r) => {
                tryLogin(r.data.email, password, redirect);
            };
            const err = () => {
                setLoggingIn(false);
                setLoginError('not-found');
            };
            externalAuthFetch('/get-account-email-from-handle', res, err, "POST", {handle: username});
        }
    }

    function tryLogin(username, password, redirect, secondary) {
        firebase
            .auth()
            .signInWithEmailAndPassword(username, password)
            .then(async resp => {
                 setGlobal({
                    ...global,
                    user_uid: resp.user.uid,
                    refresh_token: resp.user.refreshToken,
                    auth_updated: Date.now()
                });
                await awaitSetToken();
                setLoggingIn(false);
                setLoggedIn(true);
                setLoginError(null);
                redirect();
            })
            .catch((error) => {
                if (!secondary) {
                    tryLoginAsSecondaryEmail(username, password, redirect);
                } else {
                    setLoggingIn(false);
                    setLoginError(error.code);
                }
            })
    }

    function tryLoginAsSecondaryEmail(username, password, redirect) {
        const res = (r) => {
            if (!r.data.email) {
                setLoggingIn(false);
                setLoginError('not-found');
            } else {
                tryLogin(r.data.email, password, redirect);
            }
        };
        const err = () => {
            setLoggingIn(false);
            setLoginError('not-found');
        };
        externalAuthFetch('/get-email-from-secondary-email', res, err, "POST", {email: username});
    }

    function setLanguage(code) {
        let payload = {
            id: global.user_uid,
            language: code
        };
        const res = () => {
        };
        authFetch('/users/set-language', res, res, "POST", {payload});
        setGlobal({
            ...global,
            language: code
        });
    }

    function setTimezone() {

    }

    return <GlobalContext.Provider
        value={{
            ...global,
            policies_data,
            members_data,
            member_type_data,
            full_memberships,
            memberships,
            secondary_emails,
            setLoginError: (e)=>{
                setLoginError(e)
            },
            member_ids,
            loading_communities,
            community_data,
            applications,
            updateCommunityData: (cid,data) => {
                let ncd = {...community_data};
                ncd[cid] = getMiniCommunity(data);
                setCommunityData(ncd);
            },
            open_search,
            setPoliciesData: (pd) => setPoliciesData({...pd}),
            addToast: toasts.addToast,
            openSearch: () => {
                setOpenSearch(true);
            },
            closeSearch: () => {
              setOpenSearch(null);
            },
            logout: logout.bind(this),
            setLanguage: setLanguage.bind(this),
            setTimezone: setTimezone.bind(this),
            login: login.bind(this),
            logging_in,
            right_menu,
            file_viewer,
            image_viewer,
            handleSetRightMenu: (id,data={}) => {
                setTimeout(()=>{
                    setRightMenu({
                        type: id,
                        props: data||{}
                    })
                }, 50)
            },
            handleCloseRightMenu: () => {
                setRightMenu(null);
            },
            openFileViewer: (id,files) => setFileViewer({id,files}),
            closeFileViewer: () => {
                if(file_viewer) {
                    setFileViewer(null);
                }
            },
            openImageViewer: (url,bg_color,photos) => setImageViewer({url,bg_color,photos}),
            closeImageViewer: () => {
                if(image_viewer) {
                    setImageViewer(null);
                }
            },
            logging_out,
            logged_in,
            login_error
        }}>
        {children}
    </GlobalContext.Provider>
}