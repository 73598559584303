import React from "react";
import styled from "styled-components";
import {XIcon} from '@heroicons/react/solid';
import ReactDOM from "react-dom";

const Frame = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 100vh;
`;

const Header = styled.div`
  background-color: #fff;
`;

const Body = styled.div`
  padding: 24px;
`;

const MaxWidthWrapper1 = styled.div`
  max-width: 960px;
  overflow-y: auto;
  margin: 0 auto;
`;


const MaxWidthWrapper = styled.div`
  max-width: ${props=>props.size==='full'?"1120px":"960px"};
  height: 100%;
  margin: 0 auto;
`;

const Navbar = styled.div`
  padding: 10px 16px;
  height: 48px;
  display: grid;
  align-items: center;
`;

const NavRight = styled.div`

`;

const NavLeft = styled.div`
  display: flex;
  flex-grow: 1;
`;

function getSize(size) {
    switch (size) {
        case 'small':
            return '500px'
        case 'editor':
            return '580px'
        case 'full':
            return '100%'
        default:
            return '768px'
    }
}

const Content = styled.div`
  max-width: ${props => getSize(props.size)};
  margin: 0 auto;
`;

const Title = styled.div`
  line-height: 24px;
  font-size: 15px;
  font-weight: 500;
  margin-left: 10px;
  height: 24px;
  padding-left: 10px;
  border-left: 1px solid #e3e8ed;
  margin-top: 2px;
`;

const CloseIcon = styled.div`
  height: 28px;
  cursor: pointer;
  display: flex;
  align-items: center;

  svg {
    height: 20px;
  }

  :hover {
    opacity: 0.7;
  }
`;

export const FullScreenModal = ({children, message, tabs=null, background = '', size = 'small', actions, title, close}) => {

    const content = <Frame className={`z-9999 w-screen  ${background?background:"bg-white"}`}>
        <Header className="h-12">
            <MaxWidthWrapper1>
                <Navbar className="grid-cols-2 sm:grid-cols-4">
                    <NavLeft>
                        <CloseIcon onClick={close} className='text-gray-700'>
                            <XIcon/>
                        </CloseIcon>
                        <Title className='text-gray-900'>
                            {title}
                        </Title>
                    </NavLeft>

                    <div className={"col-span-2 hidden sm:flex justify-center items-center"}>
                            {tabs}
                    </div>

                    <NavRight className="flex justify-end">
                        <div className="flex space-x-2">
                            {actions}
                        </div>
                    </NavRight>
                </Navbar>

            </MaxWidthWrapper1>
        </Header>
        <div className="mobile-page-height relative  overflow-y-auto">
            <div className={`relative p-2 sm:p-6`}>
                <MaxWidthWrapper size={size}>
                    <Content size={size}>
                        {children}
                    </Content>
                </MaxWidthWrapper>
                {message&&<div style={{maxWidth: '580px'}} className="absolute px-4 w-full top-0 mx-auto left-0 right-0">
                    {message}
                </div>}
            </div>
        </div>
    </Frame>;

    return ReactDOM.createPortal(
        content,
        document.body
    );
};