import React, {useContext, useEffect, useState} from 'react';
import {HelpTipsIcon} from "./icon";
import {GlobalContext} from "../../../../../app/global/global-context";
import {tp_pu_getProductUpdates} from "../../../../utilities/product-updates";
import {getSeenProductUpdates} from "../../../../../app/global/right-context-menu/help-and-tips-menu/whats-new";
import {Popover2} from "@blueprintjs/popover2";
import {GiveFeedbackIcon} from "../give-feedback/icon";
import {HelpAndTipsMenu} from "../../../../../app/global/right-context-menu/help-and-tips-menu";

export function TopbarHelpTips({theme}) {
    const global = useContext(GlobalContext);
    const [updates, setUpdates] = useState([]);
    const [open,setOpen] = useState(false);
    useEffect(function () {
        tp_pu_getProductUpdates()
            .then(arr => setUpdates([...arr]))
    }, []);

    useEffect(function () {
        setOpen(false);
    }, [window.location.pathname])

    let seen_updates, has_update = false;

    if(updates.length>0) {
        seen_updates = getSeenProductUpdates();

        has_update = updates[0] ? !seen_updates.includes(updates[0].version) : false;
    }

    let content = <div className="rounded-md border border-gray-100 shadow-md bg-white">
        <HelpAndTipsMenu onClose={()=>setOpen(false)} />
    </div>;

    return <Popover2 shouldReturnFocusOnClose={false} isOpen={open} onInteraction={((nextOpenState, e) => {
        if (!e) {
            return;
        }
        setOpen(nextOpenState);
    })} canEscapeKeyClose enforceFocus={false} interactionKind="click" fill content={content} placement={'bottom-end'} minimal popoverClassName='minimal-popover margin-top-8'>
        <div>
            <HelpTipsIcon theme={theme} has_update={has_update} onOpen={()=>{}} onClose={()=>{}} />
        </div>
    </Popover2>;
}