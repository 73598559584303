import React, {useContext} from "react";

import {SplashContainer, SplashInner} from "../../layout/splash-container";
import styled from "styled-components";
import {GlobalContext} from "../../../global/global-context";

const Title = styled.h1`
    font-size: 44px;
    color: #112D42;
    line-height: 1.35;
    font-weight: 700;
`;

const Paragraph = styled.p`
    font-size: 18px;
    line-height: 1.5;
    margin-top: 16px;
    max-width: 768px;
    color: #112D42;
`;

const Divider = styled.div`
    height: 1px;
    background-color: #efefef;
    margin: 48px 0;
    max-width: 760px;
`;

export default function Splash() {
    const global = useContext(GlobalContext);

    if(!global.logging_in&&!global.logged_in) {
        window.location.replace('https://welcome.getunaty.com?source=app-home');
    }
    return (
        <SplashContainer>
            <SplashInner>
                <Title>
                    We believe communities<br/>can change the world.
                </Title>

                <Paragraph>
                    As members and former leaders of social clubs, sports teams, and non-profits we understand the
                    challenges they face with communication, engagement, and admin work.
                </Paragraph>
                <Paragraph>
                    We spent years tinkering with processes, tweaking teams, and building tools that made community life
                    more engaging, improved conversations, and reduced the work for admins.
                </Paragraph>
                <Paragraph>
                    Now, we’re building Unaty - an online platform to help communities get organized, keep members
                    engaged,
                    and change the world.
                </Paragraph>
                <Divider/>
                <Paragraph>
                    Great community software is a key to growth and sustainability.
                </Paragraph>
                <Paragraph>
                    Community software has incredible potential to help save admins time, reach and engage people, and
                    make
                    the community more sustainable - so do we really need another community app?
                </Paragraph>
                <Paragraph>
                    Facebook and LinkedIn have set the bar high for social tools, yet lack management and privacy
                    features
                    to make them a good fit for communities. Slack, Twist, and MS Teams have their place in business,
                    but
                    aren’t really made for communities either and get hard to manage over time.
                </Paragraph>
                <Paragraph>
                    Most custom community software isn’t “better enough” to get people to log in regularly, and most
                    organic communities today are forming on top of more simplistic social platforms like YouTube,
                    Twitter,
                    Twitch, and others.
                </Paragraph>
                <Divider/>
                <Paragraph>
                    We’re building Unaty because you shouldn’t have to stitch together a bunch of different tools and
                    apps to build and run your community.
                </Paragraph>
                <Paragraph>
                    Unaty helps make communities self-managed, just by members using the platform. We also want to give
                    members the ability to choose how
                    and when they want to receive notifications, and have conversations without always having to log in.
                </Paragraph>
                <Divider/>
                <Paragraph>
                    If you're interested, drop us a <a href='mailto:hey@getunaty.com'>line</a>.
                </Paragraph>
            </SplashInner>
        </SplashContainer>
    );
};