import React from 'react';

function Item({text,onClick}) {
    return <div className="text-xs mb-2 text-gray-500 cursor-pointer hover:text-selection hover:underline" onClick={onClick}>
        {text}
    </div>
}

export function HomeFooterLinks({links=[]}) {

    return <div className="px-4 flex space-x-4 py-4 mt-2 justify-center border-t border-gray-200">
        {links.map((l,i)=><Item key={i} {...l} />)}
    </div>
}