import React, {Component, useState} from "react";

import styled from "styled-components";
import {authFetch} from "../../../../../config/network";

import UnProgressBar from "../../../home/un-progress-bar";
import {Button} from "../../../../../library/components/core";
import Modal from "../../../../../library/components/old/modal";
import Field from "../../../../../library/components/old/field";
import Form from "../../../../../library/components/old/form";
import {TextAndEmoji} from "../../../../../library/components/old/text-and-status";
import HandleSelector from "../../../../../library/components/old/handle-selector";
import {CommunityContext} from "../../../community-context";
import {getNameMeta} from "../../../../../library/components/reorganize/advanced-modal/block-renderer";
const Steps = styled.div`
    line-height: 32px;
    font-size: 14px;
    color: #697386;
`;

const ProgressBarCont = styled.div`
    padding-top: 12px;
`;

function getSuggestions(name) {

    if(name.length<2) {
        return {
            singular: '',
            abbreviation: ''
        }
    }

    let last4 = name.slice(-4);
    let last3 = name.slice(-3);
    let last2 = name.slice(-2);
    let last1 = name.slice(-1);
    // Alumni
    // Students
    // Mentors
    if(last4==='umni') {
        return {
            singular: `${name.split('umni')[0]}um`,
            abbreviation: name.charAt(0).toUpperCase()
        }
    } else if(last3==='ies') {
        return {
            singular: `${name.replace('ies','y')}`,
            abbreviation: name.charAt(0).toUpperCase()
        }
    } else if(last2==='es') {
        // Coaches -> Coach, -> C
        return {
            singular: name.slice(0,name.length-2),
            abbreviation: name.charAt(0).toUpperCase()
        }
    } else if(last3==='ing') {
        // Founding -> Founder -> F
        return {
            singular: `${name.slice(0,name.length-3)}er`,
            abbreviation: name.charAt(0).toUpperCase()
        }
    } else if(last1==='s') {
        // Ambassadors, Mentors, Students, Founder
        return {
            singular: name.slice(0,name.length-1),
            abbreviation: name.charAt(0).toUpperCase()
        }
    } else {
        return {
            singular: '',
            abbreviation: ''
        }
    }
}

function LabelButton({label,onClick,value}) {
    return <div onClick={onClick} className="rounded-md hover:opacity-70 cursor-pointer border text-sm py-0.5 px-1.5 border-gray-300 bg-gray-100">
        <span className="text-gray-600">{label}</span>
        <span className="font-bold text-gray-700">&nbsp;{value?value:"-"}</span>
    </div>
}

function MTSuggestions({name,showExtra}) {
    const suggestions = getSuggestions(name);

    return <div className="mt-2 flex space-x-2">
        <LabelButton onClick={showExtra} label="Singular version:" value={suggestions.singular} />
        <LabelButton onClick={showExtra} label="Abbreviation:" value={suggestions.abbreviation} />
    </div>
}

export default class CreateMemberType extends Component {
    static contextType = CommunityContext;
    constructor(props) {
        super(props);

        this.state = {
            step: 1,
            name: '',
            emoji: '',
            singular: '',
            abbreviation: '',

            show_extra: false,

            _helper_name: '',
            handle: '',

            _customizing: false,

            creating: false,

            handle_is_taken: false,
            handle_is_valid: true,
            checked_handle: false
        };
    }

    hc(f,v) {
        // todo auto trim the handles
        this.setState({
            [f]: v
        })
    }

    handleBlur() {
        const name = this.state.name;

        if(this.state.singular===''&&this.state.abbreviation===''&&name!=='') {
            const suggestions = getSuggestions(name);
            this.setState({
                abbreviation: suggestions.abbreviation,
                singular: suggestions.singular,
                _helper_name: name
            })
        } else {
            this.setState({
                _helper_name: name
            })
        }

    }

    step(n) {
        this.setState({
            step: (this.state.step+n)
        })
    }

    create() {
        const {creating, name, emoji, handle, singular, abbreviation} = this.state;
        if(creating) {
            return;
        }

        this.setState({
            creating: true,
            step: 2
        });

        const payload = {
            plural: name, handle, emoji, singular, abbreviation, community_uid: this.context.uid, member_id: this.context.member_id
        };

        authFetch("/member-types/create", this.handleResult.bind(this), this.handleError.bind(this), "POST", {payload});
    }

    handleResult(result) {
        //const {addToast} = this.props.actions;
       // addToast({text: `Member Type was created.`, intent: 'success'});

        this.props.history.push(`/${this.context.data.handle}/admin/settings/member-types/${result.data.id}`)
    }

    handleError() {
        //const {addToast} = this.props.actions;
        // addToast({text: `Member Type could not be created.`, intent: 'error'});
        this.setState({
            creating: false
        });
    }

    handleChange(f,v) {
        let preferences = this.state.preferences;
        preferences[f] = v;
        this.setState({
            preferences
        })
    }

    render() {

        // todo switch to modal block renderer

        const {step,emoji,show_extra,creating,singular,_helper_name,_customizing,abbreviation,handle,name} = this.state;
        const {onClose, open} = this.props;

        let body, footerRight, footerLeft, title;

        const disable_next = singular===''||abbreviation===''||name===''||handle==='';

        switch (step) {
            case 1 : {
                title = "Create Member Type";
                footerRight = <div className="flex space-x-2">
                    <Button text="Cancel" intent='standard' onClick={onClose} />
                    <Button disabled={!name} text={!name?"Confirm":"Create"} intent='secondary' onClick={()=>{
                        if(disable_next) {
                            this.handleBlur();
                        } else {
                            this.create();
                        }
                    }} />
                </div>;
                footerLeft = <Steps>

                </Steps>;
                body = <Form handleSubmit={()=>{}}>
                    <div>
                        <TextAndEmoji onBlur={this.handleBlur.bind(this)} label="Member Type Name"
                                      placeholder={"Coaches, Mentors, Alumni, Founding"}
                                      changeEmoji={(emoji) => {
                                          if(typeof emoji === 'string') {
                                              this.setState({emoji: emoji})
                                          } else {
                                              this.setState({emoji: emoji.native})
                                          }
                                      }} autoFocus
                                      emoji={emoji} text={name} changeText={(v) => this.setState({name: v})}
                        />
                        {getNameMeta(handle, _customizing, (f,v)=>this.setState({_customizing:true}), 'Member Type')}
                    </div>
                    <div className="mt-2" style={{display: !_customizing ? 'none' : 'block'}}>
                        <HandleSelector checkingHandle={() => this.setState({_checking_handle:true})}
                                        default_text={_helper_name}
                                        setHandle={(h) => {
                                            this.setState({handle: h, _checking_handle: false});
                                        }} type="member-type"
                                        scope={'community'} scope_id={this.context.uid}/>
                    </div>

                    {!show_extra&&<MTSuggestions name={name} showExtra={()=>{
                    this.setState({show_extra:true})
                    }} />}

                    {show_extra&&<div>
                        <Field onBlur={this.handleBlur.bind(this, 'singular')} type={"text"} label="Singular" placeholder="Coach, Mentor, Alum, Founder" onChange={this.hc.bind(this, 'singular')}
                             value={singular}/>
                        <Field onBlur={this.handleBlur.bind(this, 'abbreviation')} type={"text"} placeholder="C, M, A, F" label="Abbreviation" onChange={this.hc.bind(this, 'abbreviation')}
                               value={abbreviation}/>
                    </div>}

                </Form>;
                break;
            }
            case 2: {
                title = "Creating Member Type";
                footerRight = <div className="flex space-x-2">

                    <Button disabled={disable_next} text="Create" loading={creating} intent='secondary' onClick={()=>{}} />
                </div>;
                footerLeft = <Steps>

                </Steps>;
                body = <ProgressBarCont>
                    <UnProgressBar seconds={4} />
                </ProgressBarCont>;
                break;
            }
        }

        return (<Modal open={open} onClose={onClose} title={title}
                         body={body}
                         footerLeft={footerLeft}
                         footerRight={footerRight}
            />
        );
    }
}