import React, {useContext, useState} from "react";
import Modal from "../../../../../library/components/old/modal";
import {AdvancedFilter} from "../../../../../library/components/old/advanced-filter";
import {FilterCalculator} from "../../../../../library/components/old/advanced-filter/calculator";
import {CommunityContext} from "../../../community-context";
import {Button, Field, Input} from "../../../../../library/components/core";
import {ArrowLeftIcon, ArrowRightIcon} from "@heroicons/react/solid";
import {authFetch} from "../../../../../config/network";
import {useHistory} from "react-router-dom";
import {getSegmentTitle} from "../../../../../library/components/suggestions/semantic-segments";

export function CreateSegment({
    onClose = () => {}
                              }) {
    const community = useContext(CommunityContext);
    const history = useHistory();
    const [step,setStep] = useState('conditions');
    const [loading,setLoading] = useState('');
    const [name,setName] = useState('');
    const [match,setMatch] = useState('any');
    const [conditions,setConditions] = useState([]);

    let title = "New Member Segment", body, footerRight, footerLeft;

    function createSegment() {
        setLoading('creating');

        const res = (resp) => {
            if(!resp) {
                console.error('no response!')
                return;
            }
            history.push(`/${community.data.handle}/admin/settings/segment/${resp.data.id}`);
            setLoading('');
            onClose();
        };

        const payload = {
            conditions,
            match,
            name,
            member_id: community.member_id,
            user_uid: community.member.user_uid,
            community_uid: community.uid
        };

       authFetch("/filters/create", res, res, "POST", {payload});
    }

    switch (step) {
        case 'conditions':
body = <div>
    <AdvancedFilter m={match} c={conditions} updateParent={(nc,nm)=> {
        setConditions(nc);
        setMatch(nm);
    }} />
</div>;
        footerRight = <div>
            <Button onClick={()=>setStep('preview')} intent="secondary" text="Preview Segment" disabled={conditions.filter(c=>c.valid).length===0} right_icon={<ArrowRightIcon />} />
        </div>;
            break;
        case 'preview':
            body = <div>
                <AdvancedFilter m={match} c={conditions} updateParent={(nc,nm)=> {
                    setConditions(nc);
                    setMatch(nm);
                }} preview />
            </div>;
            footerRight = <div>
                <Button onClick={()=> {
                    if(!name) {
                        setName(getSegmentTitle({conditions},community))
                    }
                    setStep('name')
                }} intent="secondary" text="Next" right_icon={<ArrowRightIcon />} />
            </div>;
            footerLeft = <div>
                <Button onClick={()=>setStep('conditions')} text="Change conditions" left_icon={<ArrowLeftIcon />} />
            </div>;
            break;
        case 'name':
            body = <div>
                <Field label="Name">
                    <Input autoFocus placeholder="Give your segment a name.." value={name} onChange={(v)=>setName(v)} />
                </Field>
            </div>;
            footerRight = <div>
                <Button loading={loading==='creating'} onClick={()=>createSegment()} intent="secondary" text="Create" disabled={name.length===0} right_icon={!loading?<ArrowRightIcon />:null} />
            </div>;
            break;
        default:
            break;
    }

    return <Modal size={step==='name'?'small':'medium'} open={true} onClose={onClose} title={title}
                  body={body}
                  footerLeft={footerLeft}
                  footerRight={footerRight}
    />
}