import React, {useEffect, useState} from "react";
import {FirebaseDB} from "../../config/setup-firestore";

export function GPCIUsersWithInvites({}) {
    const [users,setUsers] = useState(null);
    useEffect(function() {
        FirebaseDB
            .collection('users')
            .where('invites', '>', 0)
            .get()
            .then(snap=>setUsers(
                snap.docs.map((doc)=>{
                    return {
                        id: doc.id,
                        ...doc.data()
                    }
                })
            ))
    },[]);
    return <div>
        <div className="text-lg font-semibold text-gray-800">
            Invite a user to create a community
        </div>
        {!users&&<div>None..</div>}
        {users&&<ul>
            {users.map((user,k)=>{
                return <li key={k}>
                    [{user.invites}] {user.name} ({user.account_email})
                </li>;
            })}
        </ul>}
    </div>
}