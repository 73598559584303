import React, {useContext, useEffect, useState} from 'react';
import {GlobalContext} from "../../../../../app/global/global-context";
import {NotificationsIcon} from "./icon";
import {Popover2} from "@blueprintjs/popover2";
import {PanelHeader} from "../../../core";
import {FirebaseDB} from "../../../../../config/setup-firestore";
import {Notifications} from "../../../notifications";
import styled from "styled-components";

export function TopbarNotifications({community_uid,theme,community,member_id}) {
    const [notifications, setN] = useState([]);
    const [open,setOpen] = useState(false);
    const global = useContext(GlobalContext);

    function handleResult(ns) {
        setN([...ns]);
    }

    useEffect(function () {
        setOpen(false);
    }, [window.location.pathname])

    useEffect(function () {
        let listener = () => {};
        if (community_uid) {
            listener = FirebaseDB
                .collection('community_members')
                .doc(community_uid)
                .collection('notifications')
                .where('context_id','==',`${member_id}`)
                .orderBy('ts', 'desc')
                .limit(6)
                .onSnapshot((snap) => {
                    if(snap.empty) {
                        handleResult([]);
                    } else {
                        handleResult(snap.docs.map(d=>{
                            let o = {...d.data()};
                            o.id = d.id;
                            return o;
                        }));
                    }
                })
        } else {
            setN([]);
        }

        return () =>  listener();
    }, [community_uid]);


    const unread = notifications.filter(a => !a.read).map(b => b.id);

    const has_unread = unread.length > 0;
    const has_update = false;

    const content = <div className="rounded-md border border-gray-100 shadow-md bg-white w-96">
        <PanelHeader onClose={()=>setOpen(false)} goToPanel={()=>{}} compact title={"Notifications"} />
        <div>
            <Notifications updates={notifications} context={{community_handle:community.handle}} />
        </div>
    </div>;

    return <Popover2 shouldReturnFocusOnClose={false} isOpen={open} onInteraction={((nextOpenState, e) => {
        if (!e) {
            return;
        }
        setOpen(nextOpenState);
    })} canEscapeKeyClose enforceFocus={false} interactionKind="click" fill content={content} placement={'bottom-end'} minimal popoverClassName='minimal-popover margin-top-8'>
        <div>
            <NotificationsIcon theme={theme} onOpen={()=>{}} onClose={()=>{setOpen(false)}} has_update={has_update} />
        </div>
    </Popover2>;
}