import React from "react";
import {Switch, Route, Redirect} from 'react-router-dom';
import MemberPrefsAccount from "./account";
import MemberPrefsNotifications from "./notifications";
import MemberPrefsOverview from "./overview";
import MemberPrefsTimeDate from "./time-date";

export default function MemberPreferences(props) {

    return (
        <Switch>
            <Route path='/:handle/preferences' exact component={MemberPrefsOverview}/>
            <Route path='/:handle/preferences/account' component={MemberPrefsAccount}/>
            <Route path='/:handle/preferences/time-date' component={MemberPrefsTimeDate}/>
            <Route path='/:handle/preferences/notifications' component={MemberPrefsNotifications}/>

            <Redirect from='/*' to={`/${props.match.params.handle}/preferences`}/>
        </Switch>
    );
}