import React from "react";
import styled from "styled-components";
import {SUPPORT_EMAIL} from "../../../../config/defaults";

const Container = styled.div`
    
`;

const Text = styled.div`
        font-size: 16px;
    line-height: 1.4;
    max-width: 600px;
    margin: 0 auto;
    padding-top: 16vh;
`;

export const UserSuspended = () => {
    return <Container>
        <Text className='text-gray-800'>
            Your account has been suspended, if you believe this is a mistake or to learn more, please contact us at <a
            href={`mailto:${SUPPORT_EMAIL}`}>{SUPPORT_EMAIL}</a>.
        </Text>
    </Container>;
};