import React from 'react';
import classNames from 'classnames';
import styled from "styled-components";
import {getFileTypeIcon} from "../old/file-uploader/helpers";
import {XIcon} from '@heroicons/react/solid';
const props = {
    title: "",
    subtitle: "",
    intent: "",
    icon: null,
    button: null
};

const Icon = styled.div`
      
    > svg {
        height: 24px;
    }
`;

const XItem = styled.div`
      
    > svg {
        height: 18px;
    }
`;

export function Attachment({name="Attachment.pdf",size="2mb",onClick=()=>{},type="application/pdf",can_remove,onRemove=()=>{}}) {

    return <div onClick={onClick} className={`inline-flex bg-white border border-gray-200 py-0.5 pr-1.5 items-center rounded-md hover:opacity-80 cursor-pointer transition-opacity`}>
        <Icon className="w-8 flex-none">
            {getFileTypeIcon(type)}
        </Icon>
        <div className="flex-grow text-gray-600 text-xs font-medium"><span style={{maxWidth:'10rem'}} className="inline-flex truncate">{name}</span>{size&&<>&nbsp;&nbsp;<span className="font-normal text-xs text-gray-500">({size})</span></>}</div>
        {can_remove&&<XItem className="ml-2 text-gray-500 hover:text-gray-800"><XIcon/></XItem>}
    </div>
}