import React from "react";
import {AdminEntitySummary} from "./admin-entity-summary";
import {CIProfileQuality} from "../../../../../library/icons/custom/ProfileQuality";
import {ProfileBadge} from "../../../../../library/components/profile-badge";
import {
    AtSymbolIcon, CakeIcon,
    CalendarIcon,
    ClipboardCheckIcon, IdentificationIcon, LocationMarkerIcon, LoginIcon, MailIcon, UserIcon,
} from "@heroicons/react/outline";
import {LocationBadge} from "./location-badge";
import {LastLoginBadge} from "./last-login-badge";
import {NewTooltip} from "../../../../../library/components/reorganize/new-tooltip";
import {ProsConsList} from "./pros-cons-list";
import {ChangeMemberTypeBadge} from "./change-member-type-badge";
import {AccountEmailBadge} from "./account-email-badge";
import {IdBadge} from "./id-badge";
import {HandleBadge} from "./handle-badge";
import {PoliciesBadge} from "./policies-badge";
import {MemberPoliciesTable} from "./member-policies-table";
import {JoinDateBadge} from "./join-date-badge";
import {BirthdayBadge} from "./birthday-badge";

function buildItems(props) {
    const {
        community, meta, member, member_id, pq, pc, hc = () => {
        }
    } = props;
    return [
        {
            icon: <CIProfileQuality/>,
            title: 'Profile Quality',
            badge: true,
            comp: <NewTooltip message={`Quality Score: ${pq.final_score}`}><ProfileBadge onClick={()=>{}} type={pq.level}/></NewTooltip>,
            expandable: true,
            expanded: <ProsConsList pros={pq.pros} cons={pq.cons} />
        },
        {
            icon: <ClipboardCheckIcon/>,
            title: 'Policies',
            badge: true,
            comp: <PoliciesBadge {...pc} />,
            expandable: pc.status!=='na',
            expanded: <MemberPoliciesTable {...pc} />
        },
        {
            icon: <UserIcon/>,
            title: 'Member Type',
            badge: true,
            comp: <ChangeMemberTypeBadge changeMemberType={(mt)=>{
                hc('member_type',mt);
            }} {...props}  />
        },
        {
            icon: <MailIcon/>,
            title: 'Account Email',
            badge: true,
            comp: <AccountEmailBadge changeEmail={(em)=>{
                hc('account_email',em);
            }} {...props}  />
        },
        /*
        {
            icon: <InboxInIcon/>,
            title: 'Application Status',
            badge: true,
            comp: <ProfileBadge type="application-pending"/>,
        },

         */
        {
            icon: <CakeIcon/>,
            title: 'Birthday',
            badge: true,
            comp: <BirthdayBadge meta={meta} changeBirthday={(nb)=>{
                console.log('changeBirthday',nb)
                hc('birthday',nb);
            }} community={community} member={member} member_id={member_id} />
        },
        {
            icon: <LocationMarkerIcon/>,
            title: 'Location',
            badge: true,
            comp: <LocationBadge community={community} member={member} member_id={member_id} />
        },
        {
            icon: <CalendarIcon/>,
            title: 'Joined',
            badge: true,
            comp: <JoinDateBadge member_id={member_id} community={community} member={member} hc={hc} />
        },
        {
            icon: <LoginIcon />,
            badge: true,
            title: 'Last Login',
            comp: <LastLoginBadge community={community} member={member} member_id={member_id} />
        },
        {
            icon: <IdentificationIcon/>,
            title: 'ID',
            badge: true,
            comp: <IdBadge {...props}  />
        },
        {
            icon: <AtSymbolIcon/>,
            title: 'Handle',
            badge: true,
            comp: <HandleBadge handleChange={(nh)=>{
                hc('handle',nh);
            }} {...props} />
        },
        /*
        {
            icon: <TagIcon/>,
            title: 'Tags',
            badge: true,
            comp: <AdminTagsSelector/>
        }

         */
    ];
}

export function NewAdminMemberSummary({member,pc,pq,hc,member_id,community,meta}) {

    const items = buildItems({community, meta, member, member_id, pq, pc, hc});

    return <AdminEntitySummary items={items} member_id={member_id} community={community} member={member} meta={meta}/>
}