import React, {useContext} from 'react';
import {PopupMenu} from "../../old/popup-menu";
import styled from "styled-components";
import {useIntl} from "react-intl";
import {TopbarUserMenuHeader} from "../../layout/topbar/topbar-user-menu-header";
import {Notifications} from "../../notifications";
import {GlobalContext} from "../../../../app/global/global-context";

export function SidebarBottomMenu({data,is_global,member_type,notifications,community,user_image='',goTo,community_handle}) {
    const {formatMessage: f} = useIntl();
const global = useContext(GlobalContext);
    let items = [
        {
            type: 'item',
            title: f({id:'topbar.view_my_profile'}),
            onClick: () => {if(is_global) {goTo(`/${data.handle}`)} else {goTo(`/${community_handle}/member/${data.handle}`)}}
        },
        {
            type: 'item',
            title: f({id:'topbar.my_preferences'}),
            onClick: () => {if(is_global) {goTo('/preferences')} else {
                global.handleSetRightMenu(`member-preferences`);
            }}
        },
        {
            type: 'divider'
        },
        {
            type: 'custom',
            component: <TopbarUserMenuHeader url={user_image} title={data.name} subtitle={is_global?data.email:member_type} />
        },

        {
            type: 'divider'
        },
        {
            type: 'item',
            title: f({id:'topbar.sign_out'}),
            onClick: () => {if(is_global) {goTo('/sign-out')} else {goTo(`/${community_handle}/sign-out`)}}
        },
    ];

    return <PopupMenu width={'320px'} items={items}/>;
}