import React, {useEffect, useState} from 'react';
import {api_getAllSeriesForGroup} from "../../../api/community/posts/get-group-series";
import {api_createSeries} from "../../../api/community/posts/create-series";
import {RulesInlineSelect} from "../rules/rules-inline-select";

const date = new Date();

const year = date.getFullYear();

const placeholders = [
    `Quarterly Announcements`,
    `${year} Upcoming Projects`,
    `Alumni Interviews`,
    `WS ${`${year}`.slice(1,3)}/${`${(year+1)}`.slice(1,3)} Events`,
    `Data Quality Initiative`,
    `Guatemala Mission Trip`,
    `Q2 Engineering Updates`,
    `${year} Community Digest`,
    `Leadership Announcements`,
    `${year} Monthly Updates`,
];

export function getRandomArrayItem(arr) {
    const min = 0, max = arr.length;
    const index = Math.floor(Math.random() * (max - min + 1) + min);
    return arr[index];
}

export function PostAuthorSeries({prefill,onChangeSeries=()=>{},can_create_series,community}) {
    const [series,setSeries] = useState(prefill.series||"");
    const [options,setOptions] = useState([]);
    const [loading, setLoading] = useState("");

    useEffect(function () {
        onChangeSeries(series)
    }, [series])

    useEffect(function () {
        api_getAllSeriesForGroup(community.uid,prefill.group_id)
            .then(series=>{
                setOptions(Object.values(series))
            })
    }, [])

    if(!prefill.group_id) {
        return null;
    }

    function createSeries(vl,cb) {
        setLoading("create");
        api_createSeries(community, {name:vl,group_id:prefill.group_id})
            .then(resp=>{
                let new_options = [...options,{
                    id: resp.data.id,
                    name: vl
                }];
                setOptions(new_options)
                setSeries(resp.data.id)
                setLoading("");
                cb(new_options,resp.data.id);
            })
    }

    const prep_options = options.map(opt=>{
        return {
            ...opt,
            label: opt.name
        }
    });

    return <RulesInlineSelect allow_deselect empty_state={{placeholder:"No Series Found"}} create_meta={{
            create_text: "Add Series",
        handleCreate: (vl,cb)=>createSeries(vl,cb),
            placeholder: `e.g. ${getRandomArrayItem(placeholders)}`,
            loading: loading==='create'
        }} can_create={true} searchable={prep_options.length>5} layout={"minimal"} size="sm" inline selected={series} options={prep_options} placeholder={"Add Series"}
                           onSelect={(id) => {
                            setSeries(id)
                           }}/>
}