import {FirebaseDB} from "../../../../config/setup-firestore";

export function buildFilterPromise(condition, cid) {

    const field = condition.field.replace('custom_fields-','custom_fields.');

    switch (condition.operator) {
        case 'starts_with' :
            return FirebaseDB
                .collection('community_members')
                .doc(cid)
                .collection('members')
                .orderBy(field)
                .startAt(condition.value)
                .endAt(condition.value + "\uf8ff")
                .limit(999)
                .get()
                .then(snap => {
                    return {
                        id: condition.id,
                        ids: snap.docs.map((doc) => doc.id)
                    };
                });
        case 'equals':
            return FirebaseDB
                .collection('community_members')
                .doc(cid)
                .collection('members')
                .where(field, '==', condition.value)
                .limit(999)
                .get()
                .then(snap => {
                    return {
                        id: condition.id,
                        ids: snap.docs.map((doc) => doc.id)
                    };
                });
        case 'is':

            return FirebaseDB
                .collection('community_members')
                .doc(cid)
                .collection('members')
                .where(field, '==', condition.value)
                .limit(999)
                .get()
                .then(snap => {
                    return {
                        id: condition.id,
                        ids: snap.docs.map((doc) => doc.id)
                    };
                });
        case 'is_not':
            return FirebaseDB
                .collection('community_members')
                .doc(cid)
                .collection('members')
                .where(field, '!=', condition.value)
                .limit(999)
                .get()
                .then(snap => {
                    return {
                        id: condition.id,
                        ids: snap.docs.map((doc) => doc.id)
                    };
                });
        case 'is_one_of':
            return FirebaseDB
                .collection('community_members')
                .doc(cid)
                .collection('members')
                .where(field, 'in', condition.value)
                .limit(999)
                .get()
                .then(snap => {
                    return {
                        id: condition.id,
                        ids: snap.docs.map((doc) => doc.id)
                    };
                });
        case 'is_not_one_of':
            return FirebaseDB
                .collection('community_members')
                .doc(cid)
                .collection('members')
                .where(field, 'not-in', condition.value)
                .limit(999)
                .get()
                .then(snap => {
                    return {
                        id: condition.id,
                        ids: snap.docs.map((doc) => doc.id)
                    };
                });
        case 'is_before':
            return FirebaseDB
                .collection('community_members')
                .doc(cid)
                .collection('members')
                .where(field, '<', condition.value)
                .limit(999)
                .get()
                .then(snap => {
                    return {
                        id: condition.id,
                        ids: snap.docs.map((doc) => doc.id)
                    };
                });
        case 'is_after':
            return FirebaseDB
                .collection('community_members')
                .doc(cid)
                .collection('members')
                .where(field, '>', condition.value)
                .limit(999)
                .get()
                .then(snap => {
                    return {
                        id: condition.id,
                        ids: snap.docs.map((doc) => doc.id)
                    };
                });
        case 'does_not_equal':
            return FirebaseDB
                .collection('community_members')
                .doc(cid)
                .collection('members')
                .where(field, '!=', condition.value)
                .limit(999)
                .get()
                .then(snap => {
                    return {
                        id: condition.id,
                        ids: snap.docs.map((doc) => doc.id)
                    };
                });
        case 'less_than':
            return FirebaseDB
                .collection('community_members')
                .doc(cid)
                .collection('members')
                .where(field, '<', condition.value)
                .limit(999)
                .get()
                .then(snap => {
                    return {
                        id: condition.id,
                        ids: snap.docs.map((doc) => doc.id)
                    };
                });
        case 'greater_than':
            return FirebaseDB
                .collection('community_members')
                .doc(cid)
                .collection('members')
                .where(field, '>', condition.value)
                .limit(999)
                .get()
                .then(snap => {
                    return {
                        id: condition.id,
                        ids: snap.docs.map((doc) => doc.id)
                    };
                });
        case 'greater_than_or_equal':
            return FirebaseDB
                .collection('community_members')
                .doc(cid)
                .collection('members')
                .where(field, '>=', condition.value)
                .limit(999)
                .get()
                .then(snap => {
                    return {
                        id: condition.id,
                        ids: snap.docs.map((doc) => doc.id)
                    };
                });
        case 'less_than_or_equal':
            return FirebaseDB
                .collection('community_members')
                .doc(cid)
                .collection('members')
                .where(field, '<=', condition.value)
                .limit(999)
                .get()
                .then(snap => {
                    return {
                        id: condition.id,
                        ids: snap.docs.map((doc) => doc.id)
                    };
                });
        case 'true':
            return FirebaseDB
                .collection('community_members')
                .doc(cid)
                .collection('members')
                .where(field, '==', true)
                .limit(999)
                .get()
                .then(snap => {
                    return {
                        id: condition.id,
                        ids: snap.docs.map((doc) => doc.id)
                    };
                });
        case 'false':
            return FirebaseDB
                .collection('community_members')
                .doc(cid)
                .collection('members')
                .where(field, '==', false)
                .limit(999)
                .get()
                .then(snap => {
                    return {
                        id: condition.id,
                        ids: snap.docs.map((doc) => doc.id)
                    };
                });
        default:
            return null;
    }
}