import React from 'react';
import {Field, Input, Button, ColorInput, FilesInput, Markdown, TextAndEmoji, TextArea} from "../../core";
import {input_formatCurrency} from "../../core/utilities";
import {MailIcon,QuestionMarkCircleIcon,ExclamationCircleIcon,ExclamationIcon,CheckCircleIcon} from '@heroicons/react/solid';

import {SGSectionHeader} from "../../../styleguide";

export default function SGInputsPage() {

    return <div>
        <SGSectionHeader>Types</SGSectionHeader>
        <div className="space-y-2">

            <Input placeholder="Text" type="text"/>

            <TextArea placeholder="Multi-line Text"/>

            <Input placeholder="URL" type="url"/>

            <Input type="number" placeholder="Number"/>

            <Input type="email" placeholder="Email"/>

            <Input placeholder="Phone Number"/>

            <Input type="currency" placeholder="Currency" leading={{type: 'text', value: '$'}}
                   transform={(v, r, t) => input_formatCurrency(v, r, t)}
                   custom={{pattern: "^\\$\\d{1,3}(,\\d{3})*(\\.\\d+)?$"}}/>

            <Input type="password" placeholder="Password"/>

            <Input type="date" placeholder="Date"/>

        </div>
        <SGSectionHeader>
            States
        </SGSectionHeader>
        <div className="space-y-4">
            <Input intent='success' trailing={{type: 'icon', intent: 'success', value: <CheckCircleIcon/>}}
                   type="text" placeholder="Success"/>
            <Input intent='danger' trailing={{type: 'icon', intent: 'success', value: <ExclamationCircleIcon/>}}
                   type="text" placeholder="Danger"/>
            <Input intent='warning' trailing={{type: 'icon', intent: 'warning', value: <ExclamationIcon/>}}
                   type="text" placeholder="Warning"/>
            <Input placeholder="Disabled" disabled type="text"/>
            <Input loading type="text" placeholder="Loading"/>
        </div>
        <SGSectionHeader>
            Groups
        </SGSectionHeader>
        <div className="space-y-4">

            <Field label="With Label">
                <Input type="text" placeholder="Johnny Appleseed"/>
            </Field>

            <Field label="Label & Help Text" help_text="Make your password good">
                <Input type="text" placeholder="Johnny Appleseed"/>
            </Field>

            <Field label="Corner Hint" corner_hint="Optional">
                <Input type="text" placeholder="Johnny Appleseed"/>
            </Field>

            <Field label="Leading Icon">
                <Input leading={{type: 'icon', value: <MailIcon/>}} type="text" placeholder="Johnny Appleseed"/>
            </Field>

            <Field label="Trailing Icon">
                <Input trailing={{type: 'icon', value: <QuestionMarkCircleIcon/>}} type="text"
                       placeholder="Johnny Appleseed"/>
            </Field>

            <Field label="Leading Add-on">
                <Input leading={{type: 'add-on', value: 'https://'}} type="text" placeholder="Johnny Appleseed"/>
            </Field>

            <Field label="Leading Inline Add-on">
                <Input leading={{type: 'inline-add-on', value: 'https://'}} type="text"
                       placeholder="Johnny Appleseed"/>
            </Field>

            <Field label="With Leading Dropdown">
                <Input
                    leading={{type: 'dropdown', value: {value: 'test',options: [{label: 'Slack', value: 'test'},{label: 'JIRA', value: 'test3'},{label: 'Trello', value: 'test4'}, {label: 'Link', value: 'test2'}]}}}
                    type="text" value="City name"
                    placeholder="Johnny Appleseed"/>
            </Field>
            <Field label="Trailing Button">
                <Input trailing={{type: 'button', value: <Button text="Post" intent="secondary"/>}} type="text"
                       placeholder="Johnny Appleseed"/>
            </Field>
            <Field label="Shared Borders">
                <Input input_classes="border-b-0  focus-within-z-trick rounded-b-none" type="text"
                       placeholder="Street Address"/>
                <Input input_classes="border-b-0  focus-within-z-trick rounded-none" type="text" placeholder="Apt"/>
                <div className="flex">
                    <div className="flex-auto">
                        <Input input_classes="border-b-0 border-r-0 focus-within-z-trick rounded-none" type="text"
                               placeholder="City"/>
                    </div>
                    <div className="flex-none">
                        <Input input_classes="border-b-0 focus-within-z-trick rounded-none" type="text"
                               placeholder="ZIP Code"/>
                    </div>
                </div>
                <Input type="text" input_classes="rounded-t-none  focus-within-z-trick" placeholder="Country"/>
            </Field>

            <Field label="Text & Emoji">
                <TextAndEmoji emoji={""} value={"Hello"}/>
            </Field>
<FilesInput />
            <ColorInput type="color" label="Accent Color" value={"#F15F31"}/>
        </div>
    </div>
}