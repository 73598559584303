import React from 'react';
import {SimpleTable, SectionHeader, ActionWithIcon} from '../../core';

export default function SGSimpleTablePage() {

    return <div className="space-y-4">
        <div className="p-4 bg-gray-50">
        <SimpleTable go_up onGoUp={()=>{}} stripe_color={"bg-white"} />
        </div>
        <div className="p-4 bg-gray-50 max-w-lg">
            <SectionHeader title="Pinboard" action={<ActionWithIcon size="xs" onClick={()=>{}} text="Edit" inverse />} />
            <SimpleTable hide_headers={true} can_select={false} stripe_color={"bg-white"} />
        </div>
    </div>
}