import React from "react";
import {GlobeIcon} from "@heroicons/react/solid/esm";
import {validateEmail} from "./validation";
import {APP_BASE_URL} from "../../config/defaults";

export function isValidURL(str) {
    let regexp = /(ftp|http|https|mailto):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/
    return regexp.test(str);
}

export const PB_type_icons = {
    'slack': <GlobeIcon/>,
    'link': "Website URL",
    'facebook': "Facebook",
    'twitter': "Twitter",
    'github': "GitHub",
    'linkedin': "LinkedIn",
    'meetup': "Meetup",
    'jira': "JIRA Board / Project URL",
    'trello': "Trello URL",
    'files': "File Page",
    'notion': "Notion Page",
    'members': "Member Page",
    'roles': "Role Page",
    'groups': "Group Page",
    'teams': "Team Page",
    'events': "Event Page",
    'views': "View Page",
    'email': "Email Address",
    'folders': "Folder Page",
    'discord': "Discord URL",
    'gdrive': "GDrive URL"
};

export const PB_options = [
    {label: 'Link', value: 'link'},
    {label: 'Email', value: 'email'},
    {label: 'Custom', value: 'custom'},
    {label: 'Slack', value: 'slack'},
    {label: 'Facebook', value: 'facebook'},
    {label: 'GitHub', value: 'github'},
    {label: 'Twitter', value: 'twitter'},
    {label: 'Meetup', value: 'meetup'},
    {label: 'LinkedIn', value: 'linkedin'},
    {label: 'JIRA', value: 'jira'},
    {label: 'Trello', value: 'trello'},
    {label: 'File', value: 'files'},
    {label: 'Notion', value: 'notion'},
    {label: 'Folder', value: 'folders'},
    {label: 'Event', value: 'events'},
    {label: 'View', value: 'views'},
    {label: 'Member', value: 'members'},
    {label: 'Role', value: 'roles'},
    {label: 'Group', value: 'groups'},
    {label: 'Team', value: 'teams'},
    {label: 'Discord', value: 'discord'},
    {label: 'GDrive Link', value: 'gdrive'}
];

export const PB_type_names = {
    'slack': "Slack",
    'link': "Link",
    'facebook': "Facebook",
    'github': "GitHub",
    'twitter': "Twitter",
    'linkedin': "LinkedIn",
    'meetup': "Meetup",
    'jira': "JIRA",
    'trello': "Trello",
    'files': "Files",
    'members': "Members",
    'roles': "Roles",
    'custom': "Custom",
    'notion': "Notion",
    'groups': "Groups",
    'teams': "Teams",
    'events': "Events",
    'views': "Views",
    'email': "Email",
    'folders': "Folders",
    'discord': "Discord",
    'gdrive': "GDrive"
};

export function pb_type_value_valid() {

}

export const PB_validators = {
    'slack': (v)=>{
        return isValidURL(v);
    },
    'custom': (v)=>{
        return true;
    },
    'link': (v)=>{
        return isValidURL(v);
    },
    'facebook': (v)=>{
        return isValidURL(v);
    },
    'twitter': (v)=>{
        return isValidURL(v);
    },
    'github': (v)=>{
        return isValidURL(v);
    },
    'linkedin': (v)=>{
        return isValidURL(v);
    },
    'instagram': (v)=>{
        return isValidURL(v);
    },
    'meetup': (v)=>{
        return isValidURL(v);
    },
    'jira': (v)=>{
        return isValidURL(v);
    },
    'trello': (v)=>{
        return isValidURL(v);
    },
    'notion': (v)=>{
        return isValidURL(v);
    },
    'files': (v)=>{
        return isValidURL(v);
    },
    'folders': (v)=>{
        return isValidURL(v);
    },
    'groups': (v)=>{
        return isValidURL(v);
    },
    'events': (v)=>{
        return isValidURL(v);
    },
    'members': (v)=>{
        return isValidURL(v);
    },
    'roles': (v)=>{
        return isValidURL(v);
    },
    'views': (v)=>{
        return isValidURL(v);
    },
    'teams': (v)=>{
        return isValidURL(v);
    },
    'gdrive': (v)=>{
        return isValidURL(v);
    },
    'discord': (v)=>{
        return isValidURL(v);
    },
    'email': (v)=>{
        return validateEmail(v);
    },
};

export const PB_type_labels = {
    'slack': "Channel URL",
    'link': "Link URL",
    'facebook': "Facebook",
    'twitter': "Twitter",
    'github': "GitHub",
    'linkedin': "LinkedIn",
    'meetup': "Meetup",
    'custom': "Full Link",
    'jira': "JIRA Board / Project URL",
    'trello': "Trello URL",
    'files': "File Page",
    'members': "Member Page",
    'roles': "Role Page",
    'groups': "Group Page",
    'notion': "Notion Page",
    'teams': "Team Page",
    'events': "Event Page",
    'views': "View Page",
    'email': "Email Address",
    'folders': "Folder Page",
    'discord': "Discord URL",
    'gdrive': "GDrive URL"
};

export const PB_type_placeholders = {
    'slack': "#channel",
    'link': "https://docs.community.com/",
    'facebook': "Group Page",
    'github': "Repo URL",
    'twitter': "Twitter Handle",
    'linkedin': "Org Name",
    'meetup': "Calendar",
    'notion': "Notion Link",
    'jira': "JIRA Board / Project URL",
    'trello': "Trello Link",
    'files': "Add a url",
    'custom': "Add anything",
    'members': "Add a url",
    'roles': "Add a url",
    'groups': "Add a url",
    'teams': "Add a url",
    'events': "Add a url",
    'views': "Add a url",
    'email': "my-email@",
    'folders': "Add a url",
    'discord': "Add a url",
    'gdrive': "Add a url"
};

export function pb_autodetectType(initial,url) {
    if(validateEmail(url)) {
        return 'email';
    } else if(url.includes(APP_BASE_URL)&&url.includes('/explore/drive/folder/')) {
        return 'folders';
    } else if(url.includes(APP_BASE_URL)&&url.includes('/member/')) {
        return 'members';
    } else if(url.includes(APP_BASE_URL)&&url.includes('?view=')) {
        return 'views';
    } else if(url.includes(APP_BASE_URL)&&url.includes('/explore/drive/folder/')&&url.includes('/file/')) {
        return 'files';
    } else if(url.includes(APP_BASE_URL)&&url.includes('/group/')) {
        return 'groups';
    } else if(url.includes(APP_BASE_URL)&&url.includes('/role/')) {
        return 'roles';
    } else if(url.includes(APP_BASE_URL)&&url.includes('/team/')) {
        return 'teams';
    } else if(url.includes('discord.')) {
        return 'discord';
    } else if(url.includes('drive.google.com/')) {
        return 'gdrive';
    } else if(url.includes('docs.google.com/')) {
        return 'gdrive';
    } else if(url.includes('notion.com/')) {
        return 'notion';
    } else if(url.includes('notion.so/')) {
        return 'notion';
    } else if(url.includes('gdrive.')) {
        return 'trello';
    } else if(url.includes('trello.')) {
        return 'trello';
    } else if(url.includes('linkedin.')) {
        return 'linkedin';
    } else if(url.includes('github.')) {
        return 'github';
    }  else if(url.includes('facebook.')) {
        return 'facebook';
    } else if(url.includes('jira.')) {
        return 'facebook';
    } else if(url.includes('slack.')) {
        return 'slack';
    } else if(url.includes('twitter.')) {
        return 'twitter';
    } else if(url.includes('meetup.')) {
        return 'meetup';
    } else {
        return initial
    }
}