import React from 'react';
import styled from "styled-components";
import {NewTooltip} from "../../reorganize/new-tooltip";

const Outer = styled.div`
  border-radius: 5px;
  display: none;
  background-color: #fff;
`;

const Inner = styled.div`
  display: flex;
   height: 30px;
  padding: 2px;
`;

const Action = styled.div`
      height: 26px;
      width: 26px;
      border-radius: 3px;
      align-items: center;
    justify-content: center;
    display: flex;
    cursor: pointer;
    color: #697487;
    margin-right: ${props=>props.last?'':'2px'};
    :hover {
    color: #44495E;
        background-color: #EEEFF2;
    }
  > svg {
    height: 20px !important;
  }
`;
export const ActionsPopup = ({actions=[],inline_actions=false}) => {
    const actions_items = actions.map((act,i)=>{
        return <NewTooltip usePortal={false} key={i} message={act.text}>
            <Action last={(i+1)===actions.length} className='action' onClick={act.onClick}>
                {act.icon}
            </Action>
        </NewTooltip>
    });
    if(inline_actions) {
        return <div className="flex space-x-2 items-center">
            {actions_items}
        </div>
    }
    return <Outer className='absolute -top-2.5 -right-1.5 actions-popup group-hover:block hidden popup-box-shadow'>
        <Inner>
            {actions_items}
        </Inner>
    </Outer>;
};