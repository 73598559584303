import React from 'react';
import {Badge,IconTag} from "../../core";
import {SGSectionHeader} from "../../../styleguide";

export default function SGBadgesPage() {

    return <div>
        <SGSectionHeader>
            Colors
        </SGSectionHeader>
        <div className="space-x-2">
        <Badge />
        <Badge color="red" />
        <Badge color="green" />
        <Badge color="yellow"  />
        <Badge color="emerald" />
        <Badge color="blue" />
        <Badge color="indigo" />
        <Badge color="purple" />
        <Badge color="pink" />
    </div>
        <SGSectionHeader>
            Sizes
        </SGSectionHeader>
        <div className="space-x-2">
            <Badge size="xs" />
            <Badge size="sm" />
            <Badge size="md" />
            <Badge size="lg" />

        </div>

        <SGSectionHeader>
            Rounded
        </SGSectionHeader>
        <div className="space-x-2">
            <Badge size="xs" rounded />
            <Badge size="sm" rounded />
            <Badge size="md" rounded />
            <Badge size="lg" rounded />
        </div>


        <SGSectionHeader>
            Can Delete
        </SGSectionHeader>
        <div className="space-x-2">
            <Badge size="xs" can_remove />
            <Badge size="xs" color="red" can_remove />
            <Badge size="xs" color="blue" can_remove />

        </div>

        <SGSectionHeader>
            Icon Tag
        </SGSectionHeader>
        <div className="space-x-2">
            <IconTag />

        </div>
    </div>
}