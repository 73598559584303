import React, {useContext, useState} from "react";
import {ProfileBadge} from "../../../../../../library/components/profile-badge";
import {authFetch} from "../../../../../../config/network";
import {GlobalContext} from "../../../../../global/global-context";
import {BirthdaySelector} from "../../../../../../library/components/old/selectors/birthday";
import {Popover2} from "@blueprintjs/popover2";
import {displayBday, getAge} from "../../../../../../library/components/old/edit-fields-section/fields";

function getText(birthday,empty) {
    let formatted_bday = displayBday(birthday, true, empty);
    let age = getAge(birthday);
    const age_suffix = formatted_bday===empty?"":`${age?` (${age})`:''}`;
return `${formatted_bday}${age_suffix}`;
}

export function BirthdayBadge({member,meta,changeBirthday,community,member_id}) {
    const [birthday, setBirthday] = useState({...member.birthday});

    const [open,setOpen] = useState(false);
    const global = useContext(GlobalContext);
    if (!member_id) {
        return null;
    }

    function handleChangeBirthday() {
        if(birthday.month===member.birthday.month&&birthday.year===member.birthday.year&&birthday.date===member.birthday.date) {
            return;
        }
        changeBirthday(birthday);
        const res = () => {
            global.addToast({text:"Changes saved",intent:'success'});
        };
        const payload = {
            community_uid: community.uid,
            member_id: community.member_id,
            id: member_id,
            _updated_fields: ["birthday"],
            birthday: birthday
        };
        authFetch("/members/update-profile", res, res, "POST", {payload});
    }

    const text = getText(birthday, "—");

    const content = <div className="max-w-xs bg-white shadow-md p-1">
        <BirthdaySelector autoFocus value={birthday} onChange={(v) => {
            setBirthday({...v});
    }}/>
    </div>;
    return <Popover2 interactionKind={'click'} canEscapeKeyClose onInteraction={((nextOpenState, e) => {
        if(nextOpenState) {
            setOpen(true);
        } else {
            // closing, make sure to catch react selelct
            // no e.target on open
            if(e&&e.target&&(e.target.id.indexOf('option')===-1&&e.target.className.indexOf('option')===-1)) {
                setOpen(false);
                handleChangeBirthday();
            }
        }
    })} hoverOpenDelay={0} hoverCloseDelay={0} enforceFocus usePortal isOpen={open} content={content} placement={'bottom-start'} minimal popoverClassName='minimal-popover margin-top-8'>
        <ProfileBadge onClick={()=>{}} text={text} type="birthday" />
    </Popover2>
}