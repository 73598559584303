import React, {useContext, useEffect, useState} from "react";
import {Button, Tabs} from "../../../library/components/core";
import moment from 'moment';
import {useHistory} from "react-router-dom";
import {buildMemberProfile, downloadProfilebuildBday} from "./helpers";
import {arrayCompare,} from "./save-changes";
import {buildPersonProfile} from "../../../library/components/old/person-profile";
import {ProfileDetails} from "../../../library/components/old/profile-details";
import {ProfileCard} from "../../../library/components/reorganize/profile-card";
import {buildMemberProfileCard} from "./build-profile-card";
import {CommunityContext} from "../community-context";
import ProfileLoadingCard from "../../../library/components/layout/loading/profile";
import {MemberEducation} from "./education/member-education";
import {MemberCareer} from "./career/member-career";
import EntityNotFound from "../../../library/components/layout/non-ideal-states/not-found";
import BirthdayConfetti from "../../../library/components/old/birthday-confetti";
import {EntityHeader} from "../../../library/components/core";
import {displayInterests, getMutualInterests} from "../group";
import {MemberViewInterests} from "./interests/view";
import {authFetch} from "../../../config/network";
import {communityGetSectionsToDisplay, getSocialFieldsToShow} from "../admin/member/edit-obj";
import {getMemberRoles} from "../../../library/utilities/member-roles";
import {RolesBadge} from "../admin/member/components/roles-badge";
import {GlobalContext} from "../../global/global-context";
import {setDocumentTitle} from "../../../library/utilities/set-document-title";
import {analytics} from "firebase";
import {generateVCard} from "../../../library/utilities/general/create-vcard";
import {FirebaseDB} from "../../../config/setup-firestore";
import {EditProfileMobile, ProfileFieldsContentSection} from "../../../library/components/edit-profile-mobile";
import {useIsMobile} from "../../global/global-router";
import {MakeDataSuggestionModal} from "../../../library/components/data-suggestions/make-data-suggestion-modal";
import {buildDataSuggestionsOptions} from "./build-data-suggestions-options";
import {StandardPageFramework} from "../../../library/playground/standard-page-framework";
import {InboxMemberActivityWrapper} from "../../../library/components/posts-feed/member-activity-wrapper";
import {ArrowRightIcon} from "@heroicons/react/outline";
import AddSecondaryEmail from "../../global/preferences/notifications/add-email";

function getBirthdayIsToday(bday) {
    const today = moment().format('M-D');
    const str = `${(bday.month + 1)}-${bday.date}`;
    return today === str;
}

export function getPhoneServices(member) {
    if (!member.phone_services) {
        return {
            phone: [],
            secondary_phone: []
        }
    } else {

        return {
            phone: member.phone_services.phone ? member.phone_services.phone.split(",") : [],
            secondary_phone: member.phone_services.secondary_phone ? member.phone_services.secondary_phone.split(",") : [],
        }
    }
}

export function MemberProfilePage(props) {

    return <MemberProfile {...props} handle={props.match.params.memberhandle} />
}

export default function MemberProfile(props) {
    const community = useContext(CommunityContext);
    const global = useContext(GlobalContext);
const history = useHistory();
    let member_interests = community.member.interests ? community.member.interests : {};

    const is_mobile = useIsMobile();
    let checked_url = false;

    const [active, setActive] = useState('Profile');
    const [member_id, setMemberId] = useState('');
    const [_updated_fields, setUpdatedFields] = useState([]);
    const [_updated_bio, setUpdatedBio] = useState(false);
    const [member, setMember] = useState({});
    const [entity, setEntity] = useState({});
    const [loading, setLoading] = useState('entity');
    const [error, setError] = useState('');
    const [saving, setSaving] = useState('');
    const [editing, setEditing] = useState('');
    const [modal, setModal] = useState(null);
    const [modal_data, setModalData] = useState(null);

    const [state, setState] = useState({
        new_interests: {},

        open: false,
        roles: [],
        mutual_interests: [],
        user_uid: '',

        pp_loading: false,
        cp_loading: false
    })

    function getMemberEntity(handle) {
        FirebaseDB.collection('handles')
            .doc(community.uid)
            .collection('handles')
            .where('handle', '==', handle)
            .get()
            .then(handleEntity.bind(this));
    }

    function getRoles(user_uid) {
        FirebaseDB
            .collection('community_entities')
            .doc(community.uid)
            .collection('roles')
            .where('assignee', '==', user_uid)
            .get()
            .then(snap => {
                let to_return = [];
                for (let i = 0; i < snap.docs.length; i++) {
                    const doc = snap.docs[i];
                    const data = doc.data();
                    to_return.push({
                        id: doc.id,
                        ...data
                    });
                }
                setState({
                    ...state,
                    roles: to_return
                })
            });
    }

    useEffect(function () {
        if (member_id) {
            getMember();
        }
    }, [member_id]);

    function getMember() {
        if (!community.uid || !member_id) {
            console.warn('missing', community, member_id)
            return;
        }
        FirebaseDB
            .collection('community_members')
            .doc(community.uid)
            .collection('members')
            .doc(member_id)
            .get()
            .then(handleMember.bind(this));
    }

    function handleMember(doc) {
        if (!doc.exists) {
            setError('not-found');
            setLoading('');
            setEditing('');
        } else {

            const document = doc.data();
            if (Object.keys(document).length === 0) {
                setError('not-found');
                setLoading('');
                setEditing('');
                return;
            }

            const member_data = buildMemberProfile(document, community.member_types, community.uid, {});

            setDocumentTitle(`${document.name} (@${document.handle})`, community.data.name);

            const int = document.user_uid === community.member.user_uid ? {} : document.interests ? document.interests : {};
            const mi = getMutualInterests(int, member_interests);
            getRoles(doc.id);
            setState({
                ...state,
                mutual_interests: mi,
                user_uid: document.user_uid,
                data: member_data
            });
            setEditing('');
            setMemberId(doc.id);
            setMember(document);
            setLoading('');

            if (!checked_url) {
                const urlParams = new URLSearchParams(window.location.search);
                const action = urlParams.get('action');
                if (action === 'edit') {
                    checked_url = true;
                    setEditing('profile');
                }
            }
        }
    }

    function handleEntity(result) {
        if (result.empty) {
            setError('not-found');
            setLoading('');
        } else {
            const document = result.docs[0].data();
            if (member_id !== document.user_uid) {
                setMemberId(document.user_uid);
                setEntity(document);
                setLoading('member');
            }
        }
    }

    function setProfilePicture(url, color) {
        const img = url;
        let payload = {
            url: img,
            community_uid: community.uid,
            id: member_id,
            member_id: community.member_id,
            color
        };
        setState({
            ...state,
            pp_loading: true
        });
        const res = () => {
            let new_member = {...member};
            new_member.profile_picture = img;
            new_member.profile_picture_color = color;
            setState({
                ...state,
                pp_loading: false
            })
            setMember(new_member);
            global.addToast({text: 'Profile Picture updated', intent: 'success'});
        };
        authFetch('/members/set-profile-picture', res, res, "POST", {payload});
    }

    function setCoverPhoto(url, color) {
        const img = url;
        let payload = {
            url: img,
            community_uid: community.uid,
            member_id: community.member_id,
            id: member_id,
            color
        };
        setState({
            ...state,
            cp_loading: true
        });
        const res = () => {
            setTimeout(function () {
                let new_member = {...member};
                new_member.cover_photo = img;
                new_member.cover_photo_color = color;
                setState({
                    ...state,
                    cp_loading: false
                })
                setMember(new_member);
                global.addToast({text: 'Cover Photo updated', intent: 'success'});
            }, 350)

        };
        authFetch('/members/set-cover-photo', res, res, "POST", {payload});
    }

    function saveChanges() {
        const new_member = {...member};

        const ni = state.new_interests;

        const same = arrayCompare(Object.keys(ni ? ni : {}), Object.keys(new_member.interests ? new_member.interests : {}));

        const no_other_update = _updated_fields.length === 0 && !_updated_bio;

        if (!same) {
            updateInterests(state.new_interests, {}, no_other_update ? () => {
                getMember();
            } : () => {
            });
        }

        if (no_other_update) {
            setEditing('');

            return;
        }

        setSaving('profile');

        let payload = {
            about: new_member.about,
            social: new_member.social,
            contact: new_member.contact,
            join_date: new_member.join_date,
            address: new_member.address,
            custom_fields: new_member.custom_fields,
            birthday: new_member.birthday,
            community_uid: community.uid,
            user_uid: new_member.user_uid,
            member_id: community.member_id,
            id: member_id
        };

        payload.about.bio = new_member.about.bio;

        const res = async () => {

            analytics().logEvent('update_own_member_profile', {
                event_id: 'update_own_member_profile',
                content_type: 'member',
                community_uid: community.uid,
                member_id: member_id,
                user_uid: member.user_uid
            });

            setSaving('');
            setUpdatedFields([]);
            setUpdatedBio(false);
            getMember();

            global.addToast({text: "Your changes were saved", intent: 'success'});
        };

        payload._updated_fields = _updated_fields;

        if (_updated_bio) {
            payload._updated_fields.push('about.bio');
        }

        authFetch('/members/update-own-profile', res, res, "POST", {payload});
    }

    function downloadProfile() {
        const new_member = {...member};
        const p = {
            first: new_member.about.first_name,
            last: new_member.about.last_name,
            title: community.member_types[new_member.member_type].singular,
            middle: new_member.about.middle_name,
            image: new_member.profile_picture,
            phone: new_member.contact.phone,
            birthday: downloadProfilebuildBday(new_member.birthday),
            email: new_member.contact.email,
            url: window.location.href,
            organization: community.data.name
        };
        generateVCard(p);
    }

    useEffect(function () {
        getMemberEntity(props.handle);
    }, [props.handle]);

    function updateInterests(p, e, cb) {
        const payload = {
            community_uid: community.uid,
            member_id: community.member_id,
            user_uid: member.user_uid,
            interests: p,
            id: member_id
        };

        const res = () => {
            cb();
            // global.addToast({text: 'Interests updated', intent: 'success'});
        };

        const err = () => {
            global.addToast({text: 'Interests could not be updated', intent: 'danger'});
        };

        authFetch("/members/edit-interests", res, err, "POST", {payload});
    }

    function changeBio(val) {
        let data = {...member};
        data.about.bio = val;
        setUpdatedBio(true);

        setMember(data);
    }

    function updateMember(b, f, v) {
        let nm = {...member};
        if (b) {
            if (nm[b]) {
                nm[b][f] = v;
            } else {
                nm[b] = {};
                nm[b][f] = v;
            }
        } else {
            nm[f] = v;
        }

        setMember(nm);
    }

    const {member_types, uid, custom_fields} = community;
    const current_member = community.member;

    if (loading === 'entity' || loading === 'member') {
        return <ProfileLoadingCard/>;
    }

    if (error === 'not-found') {
        return <EntityNotFound type='member'/>;
    }

    const my_profile = community.member_id === member.id;

    const subtitles = [
        {
            value: `@${props.handle}`
        }
    ];

    if (member.status !== '') {
        subtitles.push({
            value: member.status
        })
    }

    const social_fields = getSocialFieldsToShow(community.data);

    const member_roles = getMemberRoles(member_id, community.all_roles);

    const phone_services = getPhoneServices(member);

    const member_details = buildPersonProfile(member, 'member', custom_fields, false, {
        community_uid: uid,
        community: community.data,
        social_fields
    });

    const birthday_is_today = getBirthdayIsToday(member.birthday);

    let new_card = buildMemberProfileCard(member, community);

    const context = {
        community_uid: community.uid,
        loading_pp: state.pp_loading,
        loading_cp: state.cp_loading
    };

    const actions = {
        setCoverPhoto: setCoverPhoto.bind(this),
        setProfilePicture: setProfilePicture.bind(this),
        updateBio: changeBio.bind(this),
        startEditing: () => {
        },
        saveChanges: () => {
        }
    };

    const features = {
        edit_profile_picture: my_profile,
        edit_cover_photo: my_profile,
        can_manage: my_profile
    };

    const save_changes_button = editing ? <Button intent='success' onClick={saveChanges.bind(this)} text='Save Changes'
                                                  loading={saving === 'profile'}/> : null;

    if (my_profile&&!props.preview) {
        new_card.actions = [
            (editing ? save_changes_button :
                <Button intent='secondary' onClick={() => {
                    setActive('Profile');
                    setEditing('profile');
                }} text='Edit Profile'/>)
        ];
    } else {

        if(props.preview) {
            new_card.actions.push(<Button text="Profile" right_icon={<ArrowRightIcon />} onClick={() => history.push(`/${community.data.handle}/member/${member.handle}`)}/>);
        } else {
            new_card.actions.push(<Button text="Download Contact" onClick={() => downloadProfile()}/>);
        }

    }

    // build card subtitle

    if (member.archived) {
        new_card.note = {
            type: 'archived',
            message: `Member account has been deactivated`,
            intent: 'info'
        };
    }

    if (member_roles.length > 0) {
        new_card.rich_meta.primary = {
            type: 'roles', // networks (community), moderators (group), organizers (event), education (user/member)
            title: 'Roles', // parent community
            component: <RolesBadge roles={member_roles} community_handle={community.data.handle}
                                   history={props.history}/>
        }
    }
    /*
        new_card.rich_meta.secondary = {
            type: 'career',
            title: 'Technical University Munich', // parent community
            component: null
        }
    */
    const mutual_interests = state.mutual_interests;

    if (mutual_interests.length > 0) {
        new_card.main.meta.splice(mutual_interests.length - 1, 0, {
            type: 'interests',
            value: displayInterests(mutual_interests, community.data.handle)
        })
    }

    const cb = () => {
        setTimeout(() => {
            getMember(member_id)
        }, 1500);
    };

    let topbar_actions = null;

    if (community.member_access.manage_members && !is_mobile) {
        topbar_actions = <div>
            <Button text="Manage" intent="secondary" minimal onClick={() => {
                global.handleSetRightMenu(`admin-member__${member_id}`,{onClosing:()=>{
                        getMember(member_id);
                    }});
            }}/>
        </div>;
    }

    const topbar = <EntityHeader single title={member.name} actions={topbar_actions}/>;

    const {
        display_social,
        display_custom,
        social_vis,
        career_vis,
        education_vis,
        interests_vis,
        display_career,
        display_interests,
        display_education
    } = communityGetSectionsToDisplay(community, false);

    const data_suggestions = buildDataSuggestionsOptions(member, my_profile).map(a => {
        return {
            ...a,
            onClick: () => {
                setModalData(a.id);
                setModal('make-suggestion')
            }
        }
    });

    let tabs = ["Profile"];

    if(community.member_access.manage_members) {
        tabs.push("Activity");
    }

    let content;

    const _actions = {
        addEmail: () => {
            global.handleSetRightMenu('member-preferences', {
                beforeClose: ()=>{
                    getMember();
                },
                initial_tab: "Account",
            });
        }
    };

    if(active==="Profile") {
        content = <div className={`${is_mobile ? "px-3" : ""}`}>
            {!editing &&
            <ProfileDetails is_open={!!modal} phone_services={phone_services} member={member} cb={cb}
                            id={member_id}
                            startEditing={() => {
                                setEditing('profile');
                            }}
                            data_suggestions={data_suggestions}
                            community={community}
                            community_uid={community.uid}
                            openMakeSuggestion={(fid) => {
                                setModalData(fid);
                                setModal('make-suggestion');
                            }} my_profile={my_profile} ctx={{community_uid: uid}} sections={member_details}/>}

            {editing === 'profile' && <EditProfileMobile
                actions={<div>
                    {save_changes_button}
                </div>}
                _actions={_actions}
                important_fields={community.important_custom_fields}
                saving={saving}
                meta={{
                    community_uid: community.uid,
                    member_id: community.member_id
                }}
                onSaveChanges={() => saveChanges()}
                onCancel={() => {
                    setEditing('');
                }}
                display_sections={{display_custom}}
                layout={is_mobile ? "mobile" : "desktop"}
                init_member={member}
                social_fields={social_fields}
                metadata={{phone_services, id: member_id, updateMember: (b, f, v) => updateMember(b, f, v)}}
                phone_services={phone_services}
                handleUpdate={(nm, fields_changed) => {
                    setMember({...nm});
                    setUpdatedFields([...fields_changed]);
                }}
            />}

            <div className="space-y-3 pt-3">
                {display_interests&&<ProfileFieldsContentSection block={true} layout={is_mobile?"":"desktop"} section={{autocomplete:[]}} visibility={interests_vis} title="Interests">
                    <div>
                        <MemberViewInterests visibility={interests_vis}
                                             member_interests={my_profile ? {} : community.member.interests}
                                             updateParent={(p) => setState({...state, new_interests: p})} cb={cb}
                                             community={community} my_profile={my_profile} can_edit={my_profile && editing}
                                             interests={member.interests ? member.interests : {}}/>
                    </div>
                </ProfileFieldsContentSection>}

                {display_career &&
                <MemberCareer my_profile={my_profile} visibility={career_vis} cb={cb} career={member.career}
                              user_uid={member_id} can_add={my_profile}/>}

                {display_education &&
                <MemberEducation my_profile={my_profile} visibility={education_vis} cb={cb}
                                 education={member.education}
                                 user_uid={member_id}
                                 can_add={my_profile}/>}

            </div>
        </div>;
    } else if(active==="Activity") {
        content = <div>
            <InboxMemberActivityWrapper member_id={member_id} />
        </div>
    }

    let full_content = <div className="pb-20">
        {is_mobile && editing === 'profile' ? null : <div className="w-auto">
            <ProfileCard context={context} editing={editing === 'profile'} actions={actions} data={new_card}
                         features={features}/>
        </div>}
        <div className="sticky px-2.5 pt-1.5 top-0 bg-white z-10">
            <Tabs active={active} onSelect={t=>setActive(t)} tabs={tabs} layout="entity"/>
        </div>
        <div className={is_mobile ? "pt-3" : "-mt-px relative"}>
            {content}
        </div>
    </div>;

    if(props.preview) {
        return <div>
            {full_content}
        </div>
    }

    return <StandardPageFramework content>

        {topbar}

        {full_content}

        {birthday_is_today && <BirthdayConfetti/>}

        {modal === 'make-suggestion' && <MakeDataSuggestionModal initial_state={modal_data} onClose={() => {
            setModalData('');
            setModal(null);
        }} options={data_suggestions} profile_member_id={member_id}/>}

        {modal === "add-secondary" && <AddSecondaryEmail
            member_id={member_id}
            user_uid={member.user_uid}
            community_uid={community?community.uid:""}
            onClose={() => {
                setModal(null);
                setModalData(null);
                getMember();
            }}/>}

    </StandardPageFramework>;
}