import React from 'react';
import styled from "styled-components";
import {NewTooltip} from "../reorganize/new-tooltip";

const El = styled.div`

`;

export function LiveSegmentToggle({
    onToggle = ()=>{},
    live = true,
                                  }) {

    return <NewTooltip on={"hover"} usePortal={false} message={live?`Always up-to-date`:`Members get added once`}>
        <div className="flex cursor-pointer items-center px-px">
            <El onClick={onToggle} className={`w-2 h-2 rounded-full ${live?"bg-success":"bg-gray-400"}`} />
        </div>
    </NewTooltip>;
}