import React from 'react';
import {Meta} from "../../core";

export default function SGMetaPage() {

    return <div>
        <div>
            <div>simple</div>
            <Meta />
        </div>
admin meta complex and simple, member meta, group meta visual
    </div>
}