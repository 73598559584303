import React, {useState, useContext} from 'react';
import {GlobalContext} from "../../../global/global-context";
import {authFetch} from "../../../../config/network";
import {AdvancedModal} from "../../../../library/components/reorganize/advanced-modal";
const folder_add_entities = (type, existing, group_id) => {
    return {
        flow: 'update-folder-entities',
        step: 'add-entities',

        appearance: {
            size: 'medium',
        },

        state: {
            roles: {},
            teams: {},
            filters: {},
            groups: {},
            members: {},
            member_types: {}
        },

        header: {
            title: 'Add Members',
            show_close_icon: true
        },

        blocks: [
            {
                type: "entities-filters",
                placeholder: 'Add Members',
                value: ["entities"],
                meta: {
                    block_group_id: group_id,
                    existing: existing
                },
                allowed_entities: {
                    roles: true,
                    teams: false,
                    groups: false,
                    filters: true,
                    members: true,
                    member_types: true
                }
            },
        ],

        footer: {
            primary: {
                type: 'button',
                intent: 'secondary',
                text: 'Add Members',
                action: 'proceed',
                disabled: [
                    {
                        field: 'members',
                        check: 'values-length',
                        value: '0'
                    }
                ]
            },
            secondary: null,
            tertiary: null
        }
    };
};

function noNewEntities(en) {
    let roles = Object.keys(en.roles).length === 0;
    let member_types = Object.keys(en.member_types).length === 0;
    let members = Object.keys(en.members).length === 0;
    let groups = Object.keys(en.groups).length === 0;
    let teams = Object.keys(en.teams).length === 0;
    let filters = Object.keys(en.filters).length === 0;

    return roles && members && member_types && teams && groups && filters;
}

export function FolderAddEntities({closeModal, handleSuccess, group_id, context, open, type, existing}) {
    const [loading, setLoading] = useState('');
    const global = useContext(GlobalContext);
    const initial_data = folder_add_entities(type, existing, group_id);

    function addEntities(p) {
        if (noNewEntities(p)) {
            actionHandler('cancel');
            return;
        }

        setLoading('add-entities');

        const payload = {
            entities: p,
            id: group_id,
            community_uid: context.community_uid,
            member_id: context.member_id,
        };

        const res = () => {
            actionHandler('success');
            global.addToast({text: 'Group updated', intent: 'success'});
            setLoading('');
        };

        authFetch("/folders/add-new-entities", res, res, "POST", {payload});
    }

    function actionHandler(action, payload) {
        switch (action) {
            case 'proceed':
                addEntities(payload);
                return;
            case 'cancel':
                closeModal();
                return;
            case 'success':
                handleSuccess();
                return;
            case 'close-modal':
                closeModal();
                return;
            case 'created':
                closeModal();
                return;
            default:
                return;
        }
    }

    return open ? <AdvancedModal _loading={loading === 'add-entities'} context={context}
                                 actionHandler={(a, p) => actionHandler(a, p)} phase_data={initial_data}/> : null;
}