import React, {useContext, useState} from 'react';
import {
    ClipboardIcon,
    DotsVerticalIcon,
    KeyIcon,
    MailIcon,
    MenuAlt2Icon,
    CogIcon,
    EyeIcon
} from "@heroicons/react/outline";

import {DetailModal} from "../../../../library/playground/detail-modal";
import {ActionWithIcon, Button, Field} from "../../../../library/components/core";
import {CommunityContext} from "../../community-context";
import {FolderModalAboutTab} from "./modal-about-tab";
import {FolderModalAccessTab} from "./modal-access-tab";
import {PopupMenu} from "../../../../library/components/old/popup-menu";
import {Popover2} from "@blueprintjs/popover2";
import {GlobalContext} from "../../../global/global-context";
import {useHistory} from "react-router-dom";
import {DoubleCheckModal} from "../../../../library/components/old/double-check-modal";
import {authFetch} from "../../../../config/network";

export function DetailModalHoverField({
                                          label = "Name",
                                          can_edit = false,
                                          value = "Group Name",
                                          placeholder = "Add a Name",
                                          copy,
                                          onClick = () => {
                                          }
                                      }) {
    const content = <Field label={label}
                           corner_hint={copy ? <ActionWithIcon onClick={onClick} text="Copy"/> : can_edit ?
                               <ActionWithIcon onClick={onClick} text="Edit"/> : null}>
        {value && <div className="text-smbase line-clamp-1 text-gray-800 -mt-0.5">
            {value}
        </div>}
        {!value && <div className="text-gray-500 text-smbase">
            {placeholder}
        </div>}
    </Field>;

    if (!can_edit) {
        return content;
    }

    return <div onClick={onClick}
                className="-mx-1.5 py-1 -my-1 px-1.5 rounded-md hover:bg-gray-100 cursor-pointer transition-colors">
        {content}
    </div>
}

function RichTab({
                     active = false,
                     onClick = () => {
                     },
                     label,
                     icon
                 }) {

    return <div onClick={onClick}
                className={`px-2 h-8 flex space-x-2 py-1.5 ${active ? " bg-selection hover:bg-secondary-500" : "bg-white  hover:bg-gray-200"} rounded-md transition-colors cursor-pointer`}>
        <div className={`h-svg-5 h-8 flex-none ${active ? "text-gray-200" : "text-gray-600"}`}>
            {icon}
        </div>
        <div className={`text-sm flex-grow font-semibold ${active ? "text-white" : "text-gray-800"}`}>
            {label}
        </div>
    </div>
}

export function FolderSettingsModal({
                                        setData = () => {
                                        },
                                        getFolder = () => {
                                        },
                                        top_level_folder = false,
                                        onClose = () => {
                                        },
                                        id = "",
                                        initial_tab = 'About',
                                        folder_data = {}
                                    }) {
    const history = useHistory();
    const [modal, setModal] = useState('');
    const [tab, setTab] = useState(initial_tab);
    const global = useContext(GlobalContext);
    const community = useContext(CommunityContext);

    let can_manage = true;

    const meta = {
        community,
        folder_data,
        can_manage: true,
        getFolder,
        setData,
        id
    };

    function renderTab() {
        switch (tab) {
            case 'About':
                return <FolderModalAboutTab {...meta} />
            case 'Access':
                return <FolderModalAccessTab {...meta} />
            default : {
                return <div>
                    Sorry, nothing found.
                </div>
            }
        }
    }


    function deleteFolder() {

    }

    let tabs = [
        {
            icon: <MenuAlt2Icon/>,
            label: "About"
        }
    ];

    if (can_manage) {
        tabs.push({
            icon: <KeyIcon/>,
            label: "Access"
        });
    }

    let more_options = [];
console.log('top_level_folder',top_level_folder)
    if (can_manage&&!top_level_folder) {
        more_options.push({
            type: 'item',
            intent: 'danger',
            title: 'Delete Folder',
            onClick: () => setModal('group_delete')
        })
    }

    const actions = more_options.length > 0 ? <div className="pl-4 flex space-x-2 pb-3">
        <div>
            {more_options.length > 0 &&
            <Popover2 usePortal={false} placement={'bottom-start'} content={<PopupMenu items={more_options}/>} minimal
                      popoverClassName='minimal-popover my-2'>
                <Button icon={<div className="h-svg-5 pl-px"><DotsVerticalIcon/></div>}/>
            </Popover2>}
        </div>
        {can_manage && modal === 'group_delete' && <DoubleCheckModal type={'group_delete'} onConfirm={() => {
            if (modal === 'group_delete') {
                deleteFolder();
            } else {
                setModal(null);
            }
        }} onCancel={() => setModal(null)} onClose={() => setModal(null)}/>}
    </div> : null;

    const custom = <div className="grid grid-cols-3 gap-5 px-4 py-3">
        <div className="">
            <div className="space-y-1">
                {tabs.map((t, k) => {
                    return <RichTab onClick={() => {
                        setTab(t.label);
                    }} key={k} active={tab === t.label} {...t} />;
                })}
            </div>
        </div>
        <div className="col-span-2">
            {renderTab()}
        </div>
    </div>;

    return <DetailModal min_height="460px" size="plus" title={folder_data.name} actions={actions} onClose={onClose}
                        tabs={[{label: "Folder", type: "custom", component: custom}]}/>
}