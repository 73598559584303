import React, {useContext} from "react";
import {PageContainer} from "../../../library/components/old/page-container";
import {setDocumentTitle} from "../../../library/utilities/set-document-title";
import {MainCalendar} from "../../../library/components/reorganize/birthdays-list";
import {CommunityContext} from "../community-context";
import {useIntl} from "react-intl";
import {CommonLayoutDirectory} from "../../../library/components/layout/page/directory";
import {EntityHeader, PageHeader, Tabs} from "../../../library/components/core";
import {StandardPageFramework} from "../../../library/playground/standard-page-framework";
import styled from "styled-components";
import {HomeSent} from "./sent";

const Grid = styled.div`
  grid-template-columns: 320px 1fr;
`;

export default function CommunityMessages(props) {
    const community = useContext(CommunityContext);
    const {formatMessage: f} = useIntl();
    setDocumentTitle(f({id: 'sidebar.messages'}), community.data.name);

    return (<Grid className="grid h-screen">
            <div className="border-r flex flex-col shared-right-border">
                <div className="h-12 border-b shared-bottom-border">
                    SEARCH BAR
                </div>
                <div className="hide-scrollbar overflow-y-auto  mobile-page-height">
                    <Tabs layout="left-navbar" tabs={["Inbox","Sent"]} />
                    <HomeSent />
                </div>
            </div>
            <div>
                <EntityHeader title="Message"/>
                MESSAGE CONTENT
            </div>
        </Grid>
    );
}