import {authFetch} from "../../../../../config/network";

export async function getFullSecretKey(last4,community) {
    return new Promise(resolve => {
        const res = (resp) => {
            resolve(resp.data&&resp.data.key?resp.data.key:"")
        };

        const payload = {
            last4,
            community_uid: community.uid,
            member_id: community.member_id,
            user_uid: community.member.user_uid
        };

        authFetch("/developers/get-secret-key", res, res, "POST", {payload});
    });
}

export async function createSecretKey(community) {
    return new Promise(resolve => {
        const res = (data) => {
            resolve(data)
        };

        const payload = {
            name: 'Secret Key',
            type: 'secret',
            env: 'live',
            community_uid: community.uid,
            member_id: community.member_id,
            user_uid: community.member.user_uid
        };

        authFetch("/developers/create-api-key", res, res, "POST", {payload});
    });
}

export async function createPublishableKey(community) {
    return new Promise(resolve => {
        const res = (data) => {
resolve(data)
        };

        const payload = {
            name: 'Publishable Key',
            type: 'publishable',
            env: 'live',
            community_uid: community.uid,
            member_id: community.member_id,
            user_uid: community.member.user_uid
        };

        authFetch("/developers/create-api-key", res, res, "POST", {payload});
    });
}

export async function createWebhookSigningKey() {

}

export async function createWebhook(data,community) {
    return new Promise(resolve => {
        const res = (data) => {
            resolve(data)
        };

        const payload = {
            ...data,
            community_uid: community.uid,
            member_id: community.member_id,
            user_uid: community.member.user_uid
        };

        authFetch("/developers/create-webhook", res, res, "POST", {payload});
    });
}

export async function updateWebhook(id,data,community) {
    return new Promise(resolve => {
        const res = (data) => {
            resolve(data)
        };

        const payload = {
            id,
            ...data,
            community_uid: community.uid,
            member_id: community.member_id,
            user_uid: community.member.user_uid
        };

        authFetch("/developers/update-webhook", res, res, "POST", {payload});
    });
}

export async function deleteWebhook(id,community) {
    return new Promise(resolve => {
        const res = (data) => {
            resolve(data)
        };

        const payload = {
            id,
            community_uid: community.uid,
            member_id: community.member_id,
            user_uid: community.member.user_uid
        };

        authFetch("/developers/delete-webhook", res, res, "POST", {payload});
    });
}

export async function rollKey(id,community) {
    return new Promise(resolve => {
        const res = (data) => {
            resolve(data)
        };

        const payload = {
            id,
            community_uid: community.uid,
            member_id: community.member_id,
            user_uid: community.member.user_uid
        };

        authFetch("/developers/roll-api-key", res, res, "POST", {payload});
    });
}