import React from 'react';
import classNames from 'classnames';
import styled from "styled-components";

const Cont = styled.div`
   > *:not(:first-child) {
     border-top-left-radius: 0;
     border-bottom-left-radius: 0;
     border-left-width: 0;
   }
   > *:not(:last-child) {
      border-top-right-radius: 0;
     border-bottom-right-radius: 0;
   }
`;

export function ButtonGroup({children}) {
    const len = children.length;
    return <Cont className="flex items-center">
        {children}
    </Cont>
}