import React, {useEffect, useRef, useState} from "react";
import {EntityInfo} from "../../../utilities/localstorage/entity-localstorage";
import {DEFAULT_USER_PHOTO} from "../../../../config/defaults";
import {ESGeneralEntityItem} from "../entity-select/item";

function clickedOnOption(elements) {
    if(!elements||!elements[1]) {
        return false;
    }
    for(let el of elements.values()) {
        if(el&&el.className&&typeof el.className==='string'&&el.className.indexOf('us-option')!==-1) {
            return true;
        }
    }
    return false;
}

function MemberWrapper({clearValue=()=>{},id,data,community_uid,type}) {
    const icon = type==='groups'?{
        type: data.emoji?'emoji':data.image?'image':'color',
        emoji: data.emoji,
        color: data.color,
        url: data.image ? `${data.image}_small?alt=media` : ""
    }:{type:'image',url:data.image ? `${data.image}_small?alt=media` : `${DEFAULT_USER_PHOTO}_small?alt=media`}

return <ESGeneralEntityItem data={data} label={data.name} icon={icon} simple onRemove={()=>clearValue()} id={id} cid={community_uid} type={type}/>
}

export function EntitySearchInput({
    query = "",
    value = null,
    context = {},
                                      onChange = () => {},
                                      onEnter = () => {},
                                      clearValue = () => {},
                                      onArrowKey = () => {},
                                      auto_focus=false,
                                      onEscape = () => {},
                                      placeholder = "",
                                      onFocus = () => {},
                                      onBlur = () => {
                                      }
                                  }) {
    const [q, setQ] = useState(query);
    const [focus, setFocus] = useState(false);
    const input_ref = useRef(null);
    const isFirstRun = useRef(true);

    useEffect(function () {
        onChange(q);
    }, [q]);

    useEffect(function () {
        if(!focus&&q) {
            input_ref.current.focus();
        }
    }, [focus])

    useEffect(function () {
        // as long as it's invalid, keep trying to update
        if (isFirstRun.current) {
            isFirstRun.current = false;
            return;
        }
        if (!query||(query&&query!==q)) {
           // setQ(query);
        }
    }, [query]);

    function handleKeyDown(e) {
        // 27 === escape, alt = 18

        if (e.keyCode === 38 || e.keyCode === 37) {
            e.preventDefault();
            onArrowKey(-1);
        } else if (e.keyCode === 39 || e.keyCode === 40) {
            e.preventDefault();
            onArrowKey(1);
        } else if (e.keyCode === 9) {

        } else if (e.keyCode === 13) {
            e.preventDefault();
            onEnter();
        } else if (e.keyCode === 27) {
            e.preventDefault();
            onEscape();
        }
    }

    if(value&&value.type&&value.value) {
        return <div>
            <div style={{padding:'0.1rem'}} className={`bg-white h-10 text-base w-full rounded-md placeholder-gray-500 transition-colors new-focus-standard`}>
                <EntityInfo community_uid={context.community_uid} id={value.value} type={value.type}>
                    <MemberWrapper clearValue={()=>clearValue()} community_uid={context.community_uid} id={value.value} type={value.type} />
                </EntityInfo>
            </div>
        </div>
    }
    return <div key='esi-container  bg-white'>
        <input key='esi-input' autoFocus={auto_focus||focus} ref={input_ref} onBlur={(e) => {
            const elements = document.querySelectorAll( ":hover" );
            e.preventDefault();
            if(clickedOnOption(elements)) {
                // clicked on an option
            } else {
                setFocus(false);
                onBlur();
            }
        }} onFocus={(e) => {
            e.stopPropagation();
            e.preventDefault();
            setFocus(true);
            onFocus();
        }} onKeyDown={handleKeyDown}
               className={`new-padding-standard h-10 text-base w-full rounded-md placeholder-gray-500 transition-colors new-focus-standard`}
               placeholder={placeholder} value={q} onChange={(e) => {
            setQ(e.target.value);
        }}/>
    </div>
}