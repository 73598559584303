import React from 'react';
import {CIRequired} from "../../icons/custom/Required";
import styled from "styled-components";

function getSizes(size) {
    if(size==='xs') {
        return {
            container: 'w-3 h-3',
            icon: 'w-2 h-2'
        }
    } else if(size==='sm') {
        return {
            container: 'w-4 h-4',
            icon: 'w-3 h-3'
        }
    }
    return {
        container: 'w-5 h-5',
        icon: 'w-4 h-4'
    }
}

const Cont = styled.div`
  
`;

export default function RequiredIndicator({important=false,icon=<CIRequired />,size='base'}) {

    const colors = important ? "bg-red-800 text-white" : "bg-gray-200 text-gray-500";

    const sizes = getSizes(size);

    return <div className={`${colors} ${sizes.container} flex items-center justify-center rounded`}>
        <Cont className={`${sizes.icon}`}>
            {icon}
        </Cont>
    </div>
}