import {SubtitleMetaLink} from "../../../old/subtitle-meta-link";
import React from "react";
import {Button} from "../../../core";

export const member_profile = {
    type: 'member',

    appearance: {
        show_color: true,

        cover_photo: 'https://firebasestorage.googleapis.com/v0/b/unaty-staging.appspot.com/o/cover_photos%2F1-vmtPzzo?alt=media',
        cover_photo_color: '#7bbaff',

        emoji: '✨', // group only
        event_date: null,

        profile_picture: 'https://firebasestorage.googleapis.com/v0/b/unaty-prod.appspot.com/o/profile_pictures%2FGtT_dBpdz_medium?alt=media',
        profile_picture_color: '#ffffff'
    },

    main: {
        title: 'AH Ahrens',
        status: {
            emoji: '🇸🇱️',
            text: 'Saufen, Fechten, Deutschland.'
        },
        subtitle: [
            <SubtitleMetaLink link={'/'} text='Alter Herr'/>,
            <SubtitleMetaLink link={'/'} text='Admin'/>,
        ],
        description: 'Ich kann mehr saufen als Du!',
        meta: [
            {
                type: 'location',
                value: 'Munich'
            },
            {
                type: 'birthday',
                value: 'October 16th'
            }
        ],
    },

    actions: [
        <Button intent='primary' text='Edit Profile' />
    ],

    rich_meta: {
        primary: {
            type: 'parent', // networks (community), moderators (group), organizers (event), education (user/member)
            title: 'Parent Organization', // parent community
            items: [
                {
                    image: 'https://firebasestorage.googleapis.com/v0/b/unaty-staging.appspot.com/o/profile_pictures%2Fzex6-vXgU_small?alt=media',
                    name: 'Accenture',
                    type: 'community',
                    handle: 'accenture'
                }
            ]
        },
        secondary: {
            type: 'education', // networks (community), moderators (group), organizers (event), education (user/member)
            title: 'Education', // parent community
            items: [
                {
                    image: 'https://firebasestorage.googleapis.com/v0/b/unaty-staging.appspot.com/o/profile_pictures%2Fq6bOcf2kH_small?alt=media',
                    name: 'Technische Universität München (TUM)',
                    type: 'community',
                    handle: 'tum'
                }
            ]
        },
        tertiary: null
    },

    stats: [
        {
            text: 'Members',
            value: '250+'
        }
    ]
};