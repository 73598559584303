import React from "react";
import {FISheet} from "../../../icons/files/FileSheet";
import {FIDocument} from "../../../icons/files/FileDocument";
import {FICode} from "../../../icons/files/FileCode";
import {FIAudio} from "../../../icons/files/FileAudio";
import {FIPDF} from "../../../icons/files/FilePDF";
import {FIPresentation} from "../../../icons/files/FilePresentation";
import {FIArchive} from "../../../icons/files/FileArchive";
import {FITxt} from "../../../icons/files/FileTxt";
import {FIVideo} from "../../../icons/files/FileVideo";
import {PhotographIcon,LinkIcon,ArrowUpIcon,FolderIcon} from '@heroicons/react/solid';
import {SharedFolderIcon} from "../../../icons/custom/SharedFolder";
import {PrivateFolderIcon} from "../../../icons/custom/PrivateFolder";
import styled from "styled-components";

export function getFileTypeIcon(type,co) {
    let color = '';
    switch (type) {
        case 'link' : {
            color = co?co:'#737373';
            return <LinkIcon style={{color,transform:`scale(0.65)`}} />
        }
        case 'go-up' : {
            color = co?co:'#737373';
            return <ArrowUpIcon style={{color,transform:`scale(0.65)`}} />
        }
        case 'folder' : {
            color = co?co:'#1c4d8e';
            return <FolderIcon style={{color,transform:`scale(0.95)`}} />
        }
        case 'images' : {
            color = co?co:'#1c4d8e';
            return <PhotographIcon style={{color,transform:`scale(0.8)`,width:'100%'}} />
        }
        case 'group-folder' : {
            color = co?co:'#c14c27';
            return <SharedFolderIcon style={{color,transform:`scale(0.95)`}} />
        }
        case 'private-folder' : {
            color = co?co:'#1c4d8e';
            return <PrivateFolderIcon style={{color,transform:`scale(0.95)`}} />
        }
        case 'image/bmp' : {
            color = co?co:'#027581';
            return <PhotographIcon style={{color,transform:`scale(0.8)`}} />;
        }
        case 'image/tiff' : {
            color = co?co:'#027581';
            return <PhotographIcon style={{color,transform:`scale(0.8)`}} />;
        }
        case 'image/gif' : {
            color = co?co:'#027581';
            return <PhotographIcon style={{color,transform:`scale(0.8)`}} />;
        }
        case 'image/svg+xml' : {
            color = co?co:'#027581';
            return <PhotographIcon style={{color,transform:`scale(0.8)`}} />;
        }
        case 'image/png' : {
            color = co?co:'#027581';
            return <PhotographIcon style={{color,transform:`scale(0.8)`}} />;
        }
        case 'image/jpeg' : {
            color = co?co:'#027581';
            return <PhotographIcon style={{color,transform:`scale(0.8)`}} />;
        }
        case 'image/jpg' : {
            color = co?co:'#027581';
            return <PhotographIcon style={{color,transform:`scale(0.8)`}} />;
        }
        case 'text/csv' : {
            color = co?co:'#F4B500';
            return <FISheet style={{color}} />;
        }
        case 'application/msword' : {
            color = co?co:'#4385F4';
            return <FIDocument style={{color}} />;
        }
        case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document': {
            color = co?co:'#4385F4';
            return <FIDocument style={{color}} />;
        }
        case 'text/html' : {
            color = co?co:'#F4B500';
            return <FICode style={{color}} />;
        }
        case 'application/x-httpd-php': {
            color = co?co:'#4385F4';
            return <FICode style={{color}} />;
        }
        case 'application/json' : {
            color = co?co:'#4385F4';
            return <FICode style={{color}} />;
        }
        case 'text/javascript' : {
            color = co?co:'#4385F4';
            return <FICode style={{color}} />;
        }
        case 'audio/mpeg' : {
            color = co?co:'#4385F4';
            return <FIAudio style={{color}} />;
        }
        case 'video/mpeg' : {
            color = co?co:'#8632f4';
            return <FIVideo style={{color}} />;
        }
        case 'application/pdf' : {
            color = co?co:'#DA3025';
            return <FIPDF style={{color}} />;
        }
        case 'application/vnd.ms-powerpoint' : {
            color = co?co:'#FD7541';
            return <FIPresentation style={{color}} />;
        }
        case 'application/vnd.openxmlformats-officedocument.presentationml.presentation' : {
            color = co?co:'#FD7541';
            return <FIPresentation style={{color}} />;
        }
        case 'application/vnd.rar' : {
            color = co?co:'#737373';
            return <FIArchive style={{color}} />;
        }
        case 'application/zip' : {
            color = co?co:'#737373';
            return <FIArchive style={{color}} />;
        }
        case 'text/plain' : {
            color = co?co:'#494949';
            return <FITxt style={{color}} />
        }
        case 'application/vnd.ms-excel' : {
            color = co?co:'#0D9D59';
            return <FISheet style={{color}} />
        }
        case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' : {
            color = co?co:'#0D9D59';
            return <FISheet style={{color}} />
        }
        default : {
            color = co?co:'#494949';
            return <FIDocument style={{color}} />;
        }
    }
}

export const fileFormats = {
    'application/vnd.ms-excel': 'Excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'Excel',
    'text/plain': 'Plain Text',
    'application/vnd.rar': 'RAR File',
    'application/zip': 'ZIP File',
    'application/vnd.ms-powerpoint': 'Powerpoint',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation': 'Powerpoint',
    'application/pdf': 'PDF File',
    'video/mpeg': 'MP4 Video',
    'audio/mpeg': 'MP3 Audio',
    'text/javascript': 'Javascript File',
    'application/json': 'JSON File',
    'application/x-httpd-php': 'PHP File',
    'text/csv': 'CSV File',
    'application/msword': 'Word',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'Word',
    'text/html': 'HTML File',
    'image/bmp': 'BMP Image',
    'image/tiff': 'TIFF Image',
    'image/gif': 'GIF Image',
    'image/svg+xml': 'SVG Image',
    'image/png': 'PNG Image',
    'image/jpeg': 'JPEG Image',
    'image/jpg': 'JPG'
};

function humanFileSize(size) {
    var i = Math.floor( Math.log(size) / Math.log(1024) );
    return ( size / Math.pow(1024, i) ).toFixed(2) * 1 + ' ' + ['B', 'kB', 'MB', 'GB', 'TB'][i];
}

export function formatFileSize(bytes) {
    return humanFileSize(bytes);
}