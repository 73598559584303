import React from 'react';
import {Button, InfoState} from '../../core';
import {PlusIcon,CheckIcon} from '@heroicons/react/solid';
import {UnatySVG} from "../../../icons/unaty/logos";

export default function SGInfoStates() {

    return <div className="space-y-4">

        <div>
            <div>
                Success State
            </div>
        <InfoState icon={<CheckIcon/>} title="Payment Successful" intent="success"
                   subtitle="Are you sure you want to deactivate your account? All of your data will be permanently removed."
                   button={<Button text="Go back to dashboard" fill intent="secondary"/>}/>
        </div>
        <div>
            <div>
                Empty State
            </div>
            <InfoState icon={<PlusIcon/>} title="No Sections Found"
                       subtitle="Then you can add individual custom fields into sections."
                       button={<Button text="Create Custom Fields Sections"/>}/>
        </div>
        <div>
            <div>
                Leaving App
            </div>
            <InfoState align="left" icon={<UnatySVG/>} icon_only title="See you later!"
                       subtitle={
                           <>
                               <div className="mb-4">You are leaving Unaty and will be automatically redirected to <a href={"#"}>XXX</a> in a moment.</div>
                               <div>
                                   Changed your mind? <a href={"#"}>Go back to Unaty</a>.
                               </div>
                           </>
                       }
                       />
        </div>
    </div>
}