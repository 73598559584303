import React from 'react';
import {REQUIRED_COLOR} from "../../../config/defaults";

export function Field({children, title, fill, compact, required, corner_hint, help_text, label}) {

    return <div className={`${fill?"":"max-w-lg"} w-full`}>
        {(label||corner_hint||title)&&<div className="flex">
            {!label&&!title&&<div className="flex-grow"/>}
            {title&&<div className="block flex-grow text-lg font-bold text-gray-800">
                {title}{required&&<span className="pl-0.5" style={{color:REQUIRED_COLOR}}>*</span>}
            </div>}
            {label&&!title&&<label className="block flex-grow text-sm  text-gray-600">{label}{required&&<span className="pl-0.5" style={{color:REQUIRED_COLOR}}>*</span>}</label>}
            {corner_hint&&<div className="flex items-center text-xs text-gray-500">{corner_hint}</div>}
        </div>}
        <div className={`${compact?"":"mt-1"} `}>
        {children}
        </div>
        {help_text&&<div className={`block text-xs text-gray-500 ${compact?"mt-0.5 mb-0.5":"mt-1 mb-1"}`}>{help_text}</div>}
    </div>
}