import React, {useContext, useEffect, useState} from "react";
import {Button, Field, Input} from "../../../../../../library/components/core";
import styled from "styled-components";
import firebase from "firebase/app";
import {cleanDownloadURL} from "../../../../files/folder";
import {authFetch} from "../../../../../../config/network";
import {CommunityContext} from "../../../../community-context";
import Modal from "../../../../../../library/components/old/modal";
import {FirebaseDB} from "../../../../../../config/setup-firestore";

function hasLowerCase(str) {
    return (/[A-Z]/.test(str));
}

function handleValid(handle) {
    if (!handle.startsWith(':')) {
        return false;
    } else if (!handle.endsWith(':')) {
        return false;
    } else if (handle.indexOf(' ') !== -1) {
        return false;
    } else if (hasLowerCase(handle)) {
        return false;
    } else if (handle.length > 28) {
        return false;
    } else if (handle.length < 3) {
        return false;
    }
    return true;
}

const accepted_types = "image/jpg,image/png,image/gif";

function hasRightFileType(type) {
    return accepted_types.indexOf(type) !== -1;
}

function isTooLarge(size) {
    // 200 kb
    return size >= 200000;
}

const Preview = styled.div`
  > img {
    max-height: 32px;
    max-width: 32px;
  }
`;

const storage = firebase.storage().ref();


export function AddEmoji(props) {
    const community = useContext(CommunityContext);
    const [image, setImage] = useState(null);
    const [preview, setPreview] = useState(null);
    const [handle, setHandle] = useState('');
    const [error, setError] = useState('');
    const [loading, setLoading] = useState('');
    const [ref, setRef] = useState(React.createRef());
    const [handle_is_unique, setHandleIsUnique] = useState(false);

    const is_valid = handleValid(handle) && !!image && handle_is_unique;

    useEffect(function () {
        if (handleValid(handle)) {
            checkIfHandleIsUnique();
        }
    }, [handle]);

    function resetState() {
        setImage(null);
        setPreview(null);
        setHandle('');
        setHandleIsUnique(false);
        setError('');
    }

    function uploadImage(file) {
        const path = `emojis/${community.uid}/emoji-${handle}`;
        const metadata = {
            contentType: file.type
        };
        const task = storage.child(path).put(file, metadata);

        task.on(
            firebase.storage.TaskEvent.STATE_CHANGED,
            snap => {},
            err => {},
            () => {
                storage
                    .child(path)
                    .getDownloadURL()
                    .then((url) => {
                        const final_url = cleanDownloadURL(url);
                        createEmoji(final_url);
                    })
            }
        );
    }

    function createEmoji(url) {
        const payload = {
          url,
          handle,
          community_uid: community.uid, member_id: community.member_id
        };

        const res = () => {
            resetState();
setLoading('');
            props.onClose();
        };

        authFetch('/community/customize/add-emoji', res, res, "POST", {payload});
    }

    function checkIfHandleIsUnique() {
        setError('');
        FirebaseDB
            .collection('communities')
            .doc(community.uid)
            .collection('emojis')
            .doc(handle)
            .get()
            .then(doc => {
                setHandleIsUnique(!doc.exists);
                if(doc.exists) {
                    setError('handle-taken');
                }
            })
    }

    function fileSelected(e) {
        const file = e.target ? e.target.files[0] : e[0];
        if (!file) {
            return;
        }
        if (!hasRightFileType(file.type)) {
            alert('Wrong format');
            return;
        }
        if (isTooLarge(file.size)) {
            alert('Image is too large');
            return;
        }
        if(handle==='') {
            setHandle(`:${file.name.split('.')[0]}:`)
        }
        setImage(file);
        setPreview(URL.createObjectURL(file));
    }
    // handle must start and end with : <-> :

    function handleCreate() {
        setLoading('adding-emoji');
        uploadImage(image);
    }

    const body = <div className={`space-y-4 max-w-lg`}>
        <Field help_text={'Square images in png format under 128KB work best, animated gifs also work!'}
               label="1. Upload an Image">

            <div className="flex space-x-4">
                <div>
                    <Preview
                        className="flex items-center justify-center h-12 w-12 border border-gray-200 rounded-md bg-gray-100">
                        {preview && <img src={preview}/>}
                    </Preview>
                </div>
                <div className="flex-grow">
                    {image && <div className="text-xs text-gray-500 mb-1">{image.name}</div>}
                    <div>
                        <Button onClick={() => ref.current.click()} text="Upload Image"/>
                    </div>
                </div>
            </div>
        </Field>

        <Field corner_hint={error==='handle-taken'?"Name is already taken!":""}
               help_text="Emoji name must be less than 28 characters, all lowercase, start and end with a colon, and cannot include spaces."
               label="2. Name your Emoji">
            <Input intent={error==='handle-taken'?"warning":""} placeholder=":aliensguy:" value={handle} onChange={(v) => {
                setHandle(v);
                setHandleIsUnique(false);
            }}/>
        </Field>

        <input id='emoji' name='emoji' onChange={fileSelected.bind(this)} ref={ref}
               accept={accepted_types}
               type="file" style={{display: 'none'}}/>
    </div>

    return <Modal title={"Add Emoji"} size='small' body={body} footerRight={<div className="space-x-2">
        <Button onClick={()=>props.onClose()} text="Cancel"/>
        <Button onClick={()=>handleCreate()} loading={loading==='adding-emoji'} disabled={!is_valid} text="Add Emoji" intent="success"/>
    </div>} footerLeft={null}
                  onClose={()=>props.onClose()} open={true} lazy />
}