import React, {useMemo} from 'react';
import {sc_editor_style_map} from "../../../library/components/composer/editor/style-map";
import {scEditorBlockRenderer} from "../../../library/components/composer/editor/block-renderer";
import Editor from "@draft-js-plugins/editor";
import {scPluginEmoji} from "../../../library/components/composer/editor/plugins/emoji";
import {scPluginLinkify} from "../../../library/components/composer/editor/plugins/linkify";
import {scPluginLink} from "../../../library/components/composer/editor/plugins/anchor";
import {extendedBlockRenderMap} from "../../../library/components/composer/rich-text-editor";
import {EditorState, Entity, CompositeDecorator, convertToRaw} from "draft-js";
import {ScEditorContentSmall} from "../../../library/components/composer/styles/editor";

function findMergeTagEntities(contentBlock, callback) {
    contentBlock.findEntityRanges(
        (character) => {
            const entityKey = character.getEntity();
            return (
                entityKey !== null &&
                Entity.get(entityKey).getType() === '{{mention'
            )
        },
        callback
    )
}

function findMentionEntities(contentBlock, callback) {
    contentBlock.findEntityRanges(
        (character) => {
            const entityKey = character.getEntity();
            return (
                entityKey !== null &&
                Entity.get(entityKey).getType() === 'mention'
            )
        },
        callback
    )
}

function findLinkEntities(contentBlock, callback) {
    contentBlock.findEntityRanges(
        (character) => {
            const entityKey = character.getEntity();
            return (
                entityKey !== null &&
                Entity.get(entityKey).getType() === 'LINK'
            )
        },
        callback
    )
}

const LinkEntity = (props) => {
    const data = Entity.get(props.entityKey).getData();
    const href = data?.href||data?.url||data?.value;
    return (
        <a href={href} target="_blank" className="color-link-reverse" rel="nofollow noreferrer">{props.children}</a>
    )
}

const Mention = (props) => {
    const data = Entity.get(props.entityKey).getData();
    return (
        <span>{props.children}</span>
    )
}

const MergeTag = (props) => {
    const data = Entity.get(props.entityKey).getData();
    if(data.mention&&data.mention.example) {
        return <span>{data.mention.example}</span>
    }
    return (
        <span >{props.children}</span>
    )
}

export function SimpleDraftJsViewer({content_state=null,block_preview_count,block_count,classes='prose prose-sm',style={}}) {
    const decorator = new CompositeDecorator([
        { strategy: findMergeTagEntities, component: MergeTag },
        { strategy: findMentionEntities, component: Mention },
        { strategy: findLinkEntities, component: LinkEntity },
    ])
    const final_plugins = [scPluginEmoji, scPluginLinkify(true), scPluginLink];

    const editorState = useMemo(()=>{
        return content_state ? EditorState.createWithContent(content_state, decorator) : EditorState.createEmpty(decorator);
    }, [content_state,decorator])

    return <ScEditorContentSmall className={classes} style={style}>
        <Editor
            key="preview"
            customStyleMap={sc_editor_style_map}
            readOnly={true}
            plugins={final_plugins}
            blockRenderMap={extendedBlockRenderMap}
            blockRendererFn={scEditorBlockRenderer(()=>{}, true,editorState.getCurrentContent(),{block_count,block_preview_count})}
            editorState={editorState}
            onChange={(nes) => {}}
        />
    </ScEditorContentSmall>
}