import React, {useContext, useEffect, useRef, useState} from 'react';
import {CommunityContext} from "../../../../community-context";
import {GlobalContext} from "../../../../../global/global-context";
import styled from "styled-components";
import {ActionWithIcon, InfoState, SectionHeader} from "../../../../../../library/components/core";
import {FirebaseDB} from "../../../../../../config/setup-firestore";
import moment from "moment";
import {aa_formatDate} from "../../../../../../library/utilities/general/dates";
import {uniqBy} from 'lodash';

const statuses_map = {
    200: '200 OK',
    500: '500 Server Error'
};


function EventListItem({active, id, event_name, family, ts, onClick}) {
    return <div onClick={onClick} className="relative cursor-pointer group">
        <div className="grid border-b px-2.5 gap-4 border-b shared-bottom-border py-2"
             style={{gridTemplateColumns: '1fr 0.5fr auto'}}>
            <div className="text-sm font-medium text-gray-800">
                {event_name}
            </div>
            <div className="flex justify-end items-center text-xs text-gray-600">
                evt_{id}
            </div>
            <div className="text-xs flex justify-end items-center text-gray-500 text-right">
                {aa_formatDate(ts).date_formatted}
            </div>
        </div>
        <div
            className={`absolute top-1 w-1 left-0 bottom-1 rounded ${active ? "bg-selection" : "group-hover:bg-gray-400"}`}/>
    </div>
}

const DateHeader = styled.div`
  text-transform: uppercase;
  font-size: 11px;
  color: #1A1F35;
  letter-spacing: 0.2px;
  padding-bottom: 0.5rem;
  font-weight: 500;
`;

function EventsList({setSelected, has_more, loading, onLoadMore, selected, chunks}) {

    return <div>
        <div></div>
        <div>
            {chunks.map(ch => {
                const fi = ch[0]._date;
                const key = `${fi}-${ch[0].id}`;
                return <div key={key}>
                    <DateHeader>{fi}</DateHeader>
                    <div className="border-t shared-top-border">
                        {ch.map(log => <EventListItem active={selected===log.id} key={`log-${log.id}`} onClick={() => setSelected(log.id)} {...log} />)}
                    </div>
                </div>
            })}
            <div className="p-2.5">
                {has_more&&!loading&&<ActionWithIcon text="Load more" onClick={onLoadMore}/>}
            </div>
        </div>
    </div>
}

function Metadata({title, val}) {
    return <div className="grid py-1 grid-cols-5">
        <div className="text-gray-500 text-sm col-span-2">{title}</div>
        <div className="text-gray-800 text-sm col-span-3">{val}</div>
    </div>
}

function CodeBlock({json}) {
    const parsed = JSON.parse(json);
    return <div className="">
        <pre className="text-xs p-2 bg-gray-100 border-gray-300 rounded-md overflow-y-scroll text-left"
             style={{maxHeight: '400px'}}>{JSON.stringify(parsed, undefined, 2)}</pre>
    </div>
}

function SelectedEvent({
    id,
    ts,
                         event_name,
    source,
                           event_data
                     }) {

    
    return <div className="space-y-4">
        <div>
            <div className="text-xl font-black text-gray-800">{event_name}</div>
        </div>
        <div>
            <Metadata title="ID" val={`evt_${id}`}/>
            <Metadata title="Time" val={aa_formatDate(ts).date_formatted}/>
            <Metadata title="Source" val={source}/>
        </div>
        <div>
            <SectionHeader size="lg" padded={false} title={"Event Data"}/>
            <div className="pt-2">
                <CodeBlock json={event_data}/>
            </div>
        </div>
    </div>
}

function processDocs(docs) {
    return docs.map(doc => {
        let id = doc.id;
        const dt = doc.data();
        return {
            ...dt,
            id,
            _date: moment(dt.ts).format('MMMM D, YYYY')
        }
    })
}

const page_size = 20;

export function DevsEvents(props) {
    const global = useContext(GlobalContext);
    const community = useContext(CommunityContext);

    const [loading, setLoading] = useState(true);
    const [selected, setSelected] = useState(null);
    const [has_more, setHasMore] = useState(false);
    const [data, setData] = useState(null);
    const last_ref = useRef(null)

    useEffect(function () {
        getEventItems()
    }, []);

    function getEventItems(lr) {
        if(lr&&loading) {
            return
        }
        if(!lr) {
            setData(null);
        }

        getEvents(lr)
            .then((snap) => {
                if (snap.size > 0) {
                    last_ref.current = snap.docs[(snap.size - 1)];
                }
                let nd = data ? data : [];
                setData(uniqBy([...nd, ...processDocs(snap.docs)],'id'));
                setHasMore(snap.size === page_size);
                setLoading(false);
            })
    }

    async function getEvents(lr) {
        let base = FirebaseDB
            .collection('api')
            .doc(community.uid)
            .collection('events')
            .orderBy('ts', 'desc')

        if (lr) {
            base = base.startAfter(lr);
        }
        base = base.limit(page_size);
        return await base
            .get()
            .then(snap => {
                return snap;
            })
    }

    function getMoreEvents() {
        setLoading(true);
        getEventItems(last_ref.current)
    }

    const chunked = !data ? [] : Object.values(data.reduce((a, b) => {
        (a[b._date] = a[b._date] || []).push(b);
        return a;
    }, {}));

    const selected_data = selected && data ? (data&&data.filter(a => a.id === selected)[0]?data.filter(a => a.id === selected)[0]:{}) : {};

    return <div className="grid gap-4 grid-cols-2">
        <div className="p-4">
            {data&&data.length===0&&<div>
                <InfoState subtitle="No events found" />
            </div>}
            <EventsList selected={selected} setSelected={(sid) => setSelected(sid)} has_more={has_more} chunks={chunked}
                     onLoadMore={() => getMoreEvents()} loading={loading}/>
        </div>
        <div className="p-4">
            {selected && selected_data.event_name && <SelectedEvent {...selected_data} />}
        </div>
    </div>
}