import React from 'react';
import {Switch, Route, Redirect, withRouter, Link} from "react-router-dom";

export function RenderRoute({type, path, to, from, exact=false, component, ctx}) {
    if (type === 'route') {
        return <Route component={component} exact={exact} path={path} />
    } else if (type === 'redirect') {
        return <Redirect from={from} to={to} />
    } else {
        return null;
    }
}