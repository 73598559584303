import React, {useState} from 'react';
import styled from "styled-components";
import {AT_property_types} from "../property-types";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import {at_getFinalSort} from "../sort/utilities";
import {DNDList, useDraggableInPortal} from "../sort/sort";
import {IconGrabHandle} from "../../../icons/custom/GrabHandle";
import {MenuAlt2Icon} from '@heroicons/react/solid';

import {useIntl} from "react-intl";
import {ActionWithIcon, Field, Toggle} from "../../core";
import {ATPopup} from "../filters/pretty-filters";
import {PopupMenu} from "../../old/popup-menu";

const Option = styled.div`
    cursor: pointer;
display: grid;
grid-template-columns: 20px 1fr 32px;
grid-gap: 4px;
    padding: 3px 10px 2px 0px;
> div {
height: 24px;
display: flex;
align-items: center;
}
`;

const POIcon = styled.div`

> svg {
    height: 18px;
}
`;

const POSwitch = styled.div`

label {
     padding: 0;
    margin: 0;
}
span.bp4-control-indicator {
outline: none !important;
}
`;

function PropertyOption({label, custom, toggleProp, disabled, type, active}) {
    const icon = custom  ? <MenuAlt2Icon/> : AT_property_types[type] ? AT_property_types[type].icon : <MenuAlt2Icon />;
    return <Option>
        <POIcon className='text-gray-600'>
            {icon}
        </POIcon>
        <div className='text-gray-800 text-sm'>
            {label}
        </div>
        <POSwitch>
            <Toggle layout={'switch'} size="xs" onToggle={() => toggleProp()} disabled={disabled} checked={active}/>
        </POSwitch>
    </Option>
}

const PropsList = styled.div`
  margin-left: -1rem;
  margin-right: -1rem;
    max-height: 300px;
    user-select: none;
    overflow-y: auto;
`;

/*
render fields defined in view first
then other fields
 */

const DNDPropertyItem = styled.div`
        display: grid;
    grid-template-columns: 32px 1fr;
        background-color: #fff;
`;

const PropertyDragHandle = styled.div`
    height: 30px;
    margin-right: 6px;
      outline: none !important;
    display: flex;
    align-items: center;
    padding-left: 10px;
    width: 28px;
    > svg {
    opacity: ${props => props.disabled ? '0' : '1'};
        height: 18px;
    }
`;

export function getPropertiesArray(all, view = [{id: 'name', active: true}]) {
    let to_return = [];
    for (let i = 0; i < view.length; i++) {
        const it = view[i];
        const id = it.id;
        const item = all[id];
        if(item) {
            to_return.push({
                key: id,
                type: item.type,
                label: item.label,
                disabled: item.always_on === true,
                active: view[i].active
            })
        } else {
            to_return.push({
                key: id,
                type: it.type,
                label: it.label,
                custom: true,
                disabled: false,
                active: view[i].active
            })
        }
    }

    return to_return;
}

export function ATProperties({definitions, context, history, reorderProperties, handleToggleProperty, layout, view}) {
    const {formatMessage: f} = useIntl();
    const all_props = definitions.properties;
    const [open,setOpen] = useState(false);
    const view_properties = view.properties;
    const props = getPropertiesArray(all_props, view_properties);

    function handleOnDragEnd(result) {
        if (!result.destination) return;
        let current_props = view_properties;

        let final_properties = at_getFinalSort(current_props, result);
        reorderProperties(final_properties);
    }

    const renderDraggable = useDraggableInPortal();

    const active_props = props.filter(p=>p.active);
    const inactive_props = props.filter(p=>!p.active).map(p=>{
        return {
            type: 'item',
            title: p.label,
            onClick: ()=>handleToggleProperty(p.key, !p.active)
        }
    });

    return <div>
        <Field label={"Attributes"} corner_hint= {inactive_props.length>0&& <ATPopup trigger={<ActionWithIcon inverse onClick={()=>setOpen(true)} text='Add Attribute'/>} content={<div>
            <PopupMenu small items={inactive_props} />
        </div>} onClose={()=>{
            setOpen(false);
        }} onOpen={()=> {
            setOpen(true);
        }} open={open} placement='top-start' interactionKind='click' minimal popoverClassName='minimal-popover py-3' targetClassName='popover-target-span' />}>
            <DragDropContext onDragEnd={handleOnDragEnd}>
                <PropsList>
                    <Droppable droppableId="view-properties">
                        {(provided) => (
                            <DNDList {...provided.droppableProps} ref={provided.innerRef}>
                                {props.map((prop, i) => {
                                    if(!prop.active) {
                                        return null;
                                    }
                                    const disabled = prop.key===definitions.layout_config[layout]['name'];
                                    return <Draggable isDragDisabled={disabled} key={prop.key} draggableId={prop.key}
                                                      index={i}>
                                        {renderDraggable((provided) => {
                                            return <DNDPropertyItem className={`hover-bkg-1 ${!prop.active?"hidden-imp":""}`} ref={provided.innerRef}
                                                                    key={prop.key} {...provided.draggableProps}>
                                                <PropertyDragHandle disabled={disabled}
                                                                    className='text-gray-500 hover-opacity' {...provided.dragHandleProps}>
                                                    <IconGrabHandle/>
                                                </PropertyDragHandle>
                                                <PropertyOption
                                                    toggleProp={() => handleToggleProperty(prop.key, !prop.active)} {...prop}
                                                    key={prop.key}/>
                                            </DNDPropertyItem>
                                        })}
                                    </Draggable>
                                })}
                                {provided.placeholder}
                            </DNDList>
                        )}
                    </Droppable>
                </PropsList>
            </DragDropContext>

        </Field>


    </div>
}