import React, {useState} from 'react';
import {getMatchingCities, getMatchingCountries} from "../../components/suggestions/segment-suggestions";
import {getFlagByCountryCode} from "../../data/general/countries";
import {cities_data} from "../../components/suggestions/cities";

const special_emojis = {
    'munich': "🥨",
    'corpshaus': "🍻",
    'milky way': "🌌",
    'london': "🎡",
    'tokyo': "🗼",
    'church': "⛪",
    'island': "🏝️",
    'synagogue': "🕍",
    'usa': "🦅",
    'paris': "🥐",
    'new york': "🗽",
    'remote': "🧑‍💻",

    'moon': "🌝",
    'earth': "🌍"
};

const examples = [
    "New York, USA",
    "Earth",
    "Remote",
    "Munich",
    "Kirchdorf in Tirol, Austria",
    "London, United Kingdom",
    "München, Germany",
    "France",
    "San Diego, CA",
    "Amsterdam, Netherlands",
];

export function buildLocationMeta(location) {
    let emoji = "";
    let country = "";
    let label = location;
    let city = "";
    let p1 = "", p2 = "";

    if(!location) {
        return {
            city, country, emoji, label
        }
    }

    let countries = [], cities = [];

    const has_comma = location.trim().indexOf(',')!==-1;
    const words = location.trim().split(' ');

    if(has_comma) {
        const parts = location.split(',');
        p1 = parts[0].toLowerCase();
        p2 = parts[1].toLowerCase();
        countries = getMatchingCountries(p2, false);
        cities = getMatchingCities(p1, false);
    } else if(words.length===1) {
        p1 = words[0].toLowerCase();
        countries = getMatchingCountries(p1, false);
        cities = getMatchingCities(p1, false);
    } else if(words.length===2) {
        p1 = words[0].toLowerCase();
        p2 = words[1].toLowerCase();
        countries = getMatchingCountries(location.toLowerCase(), false);
        cities = getMatchingCities(location.toLowerCase(), false);
    } else {
        countries = getMatchingCountries(location.toLowerCase(), false);
        cities = getMatchingCities(location.toLowerCase(), false);
        p1 = location.trim().toLowerCase();
    }

    let c1 = "", c2 = "";
    if(special_emojis[p1]) {
        emoji = special_emojis[p1];
    } else if(countries.length>0) {
        c1 = getFlagByCountryCode(countries[0]);
        if(c1) {
            emoji = c1;
        }
    } else if(cities.length>0) {
        c1 = cities[0].toLowerCase();
        if(special_emojis[c1]) {
            emoji = special_emojis[c1];
        } else {
            c2 = cities_data.filter(it=>it.name.toLowerCase()===c1)[0];

            if(c2) {
                emoji = getFlagByCountryCode(c2.country);
            }
        }
    }

    return {
        city, country, emoji, label
    }
}

export function LocationTest() {
    const [l,setL] = useState('');

    const location_meta = buildLocationMeta(l);

    return <div className="space-y-4">
        <div>
            <input onChange={e=>setL(e.target.value)} />
        </div>
        <div>
            {JSON.stringify(location_meta)}
        </div>
        <div>
            {examples.map((ex,k)=>{
                const ex_it = buildLocationMeta(ex);
                return <div key={`${ex}-${k}`}>
                    {ex_it.label} - <span>{ex_it.emoji}</span>
                </div>
            })}
        </div>
    </div>
}