import React, {useContext, useEffect, useState} from 'react';
import {Button, Field, InlineLoader, Tabs, TextArea} from "../core";
import styled from "styled-components";
import {CheckIcon} from "@heroicons/react/outline";
import {
    voting_getIsWinner,
    voting_handleSelection,
    voting_rankedChoiceResolver,
    voting_shouldDisableButton
} from "./utilities";
import {NewTooltip} from "../reorganize/new-tooltip";
import moment from "moment";
import {api_votePoll} from "./api";
import {CommunityContext} from "../../../app/community/community-context";

const poll = {
    status: 'open',
    vote_visibility: 'counts', // counts = only how many voted in total and for each, general = who voted, detailed = who voted for what
    opens: null, // straight away
    closes: new Date(2022, 5, 3, 8, 0, 0),

    can_change_vote: false,

    show_results: 'immediately', // poll-close


    voter_count: 0,
    results: {},

    voting_method: 'ranked-choice-all', // first-past-the-post, ranked-choice-all, ranked-choice-any, majority, rating-all, rating-any, correct-answer
    title: 'What day is best?',
    choices_data: {
        '1234': {
            text: 'Thursday'
        },
        '7345': {
            text: 'Saturday'
        },
        '5236': {
            text: 'Monday'
        },
        '5235': {
            text: 'Tuesday'
        }
    },
    choices: ['1234', '7345', '5236', '5235'],
    who_can_vote: {
        all_members: true,
        entities: {},
        user_uids: {}
    },
    vote_history: {},
    votes: {
        '1234': {
            'XTMF': true
        }
    },
    voted: {
        'XTMF': true
    }
};

function Title({title}) {
    return <div className="text-xl font-semibold text-gray-800">{title}{!title &&
    <span className="text-gray-400 italic">Untitled Poll</span>}</div>
}

function renderTimeLeft(minutes) {
    const hours = minutes / 60;
    const days = hours / 24;

    if (minutes < 2) {
        return 'Closes shortly'
    } else if (minutes < 58) {
        return `Closes in ${minutes} minutes`
    } else if (days > 2) {
        return `Closes in ${hours} hours`
    } else {
        return `Closes in ${days} days`
    }
}

function TimeLeft({closes, opens, status, show_results}) {

    if (status === 'closed') {
        return <div>
            Poll closed {moment(closes).fromNow()}
        </div>
    }

    const minutes = moment.duration(moment(closes).diff(moment())).minutes();

    // {renderTimeLeft(minutes)}
    return <div>
        Closes {moment(closes).fromNow()}
    </div>
}

function Actions({vote, voted, status, poll, show_results, totals, disable_button}) {
    const {opens, closes} = poll;
    return <div className="flex space-x-4 items-center">
        {!voted && <div>
            <Button intent="primary" text="Vote" onClick={() => vote()} disabled={disable_button}/>
        </div>}
        <div className="flex space-x-2 text-sm text-gray-500">
            <div>{totals.total} vote{totals.total === 1 ? "" : "s"}</div>
            <div>·</div>
            <TimeLeft status={status} opens={opens} closes={closes}/>
        </div>
    </div>
}

const Square = styled.div`

`;

function ChoiceIcon({voting_method, is_winner, show_results, number}) {

    if (voting_method === 'ranked-choice-all' || voting_method === 'ranked-choice-any') {
        return <div
            className={`h-svg-4 text-xs font-medium ${show_results && is_winner ? "text-white" : show_results ? "text-gray-600" : "text-white"}`}>
            {number}
        </div>
    }

    return <div
        className={`h-svg-4 ${show_results && is_winner ? "text-white" : show_results ? "text-gray-600" : "text-white"}`}>
        <CheckIcon/></div>
}

function getPercent(integer) {
    return `${(integer * 100).toFixed(1)}%`
}

function getStyles(selected, show_results, is_winner) {
    let label = "";
    let container = "";

    if (show_results) {
        if (selected) {
            container = "";
        } else {
            container = "bg-white";
        }
    } else {
        if (selected) {
            container = "";
        } else {
            container = "bg-white hover:bg-gray-100";
        }
    }

    if (is_winner) {
        label = "text-white";
    } else {
        label = "text-gray-700";
    }

    return {
        label,
        container
    }
}

function getWidth(votes_count, totals, id) {
    const actual = (votes_count / totals.total) * 100 + 6;

    return actual > 100 ? "100%" : (actual < 7) ? "6%" : `${actual}%`;
}

function getRankedChoiceWidth(votes_count, totals, id) {
    const actual = (votes_count / totals.total) * 100 + 6;

    return actual > 100 ? "100%" : (actual < 7) ? "6%" : `${actual}%`;
}

function showAsEliminated(totals, id, selection, poll) {
    if (poll.voting_method === 'ranked-choice-all') {
        if (selection === 'Results') {
            const penultimate_item = totals.rounds_display[totals.rounds_display.length - 2];
            return !!penultimate_item[1][id];
        } else {
            const item = totals.rounds_display.filter(a => a[0] === selection)[0];
            return !!item[1][id];
        }
    }
    return false;
}

function getVoteCount(totals, id, selection, poll) {
    if (poll.voting_method === 'ranked-choice-all') {
        if (selection === 'Results') {
            const penultimate_item = totals.rounds_display[totals.rounds_display.length - 2];
            return penultimate_item[1][id] || 0
        } else {
            const item = totals.rounds_display.filter(a => a[0] === selection)[0];
            return item[1] && item[1][id] ? item[1][id] : 0
        }
    }
    return totals[id] || 0;
}

function SelectedBg({totals, eliminated, votes_count, voting_method, selection, id, is_winner}) {

    if (voting_method === 'ranked-choice-all') {
        const width = getRankedChoiceWidth(votes_count, totals, id, selection);
        return <div style={{width}}
                    className={`absolute top-0 left-0 bottom-0 rounded-md ${eliminated ? "bg-gray-200" : is_winner ? "bg-primary" : "bg-primary-50"} `}>

        </div>
    }

    const width = getWidth(votes_count, totals, id);
    return <div style={{width}}
                className={`absolute top-0 left-0 bottom-0 rounded-md ${eliminated ? "bg-gray-200" : is_winner ? "bg-primary" : "bg-primary-50"} `}>

    </div>
}

function Choice({
                    text,
                    onSelect,
                    totals,
                    voted,
                    selection,
                    show_your_results,
                    id,
                    is_winner,
                    show_results,
                    poll,
                    number,
                    can_select,
                    selected
                }) {

    const votes_count = show_results ? getVoteCount(totals, id, selection, poll, show_results) : 0;
    const show_as_eliminated = !show_results ? false : showAsEliminated(totals, id, selection, poll);
    const {container, label} = getStyles(selected, show_results, is_winner);

    const outer = selected ? `` : ``;
    const square = selected ? `border-selection bg-selection` : `border-gray-200 group-hover:border-gray-400`;
    return <div onClick={() => {
        onSelect();
    }} className={`rounded-xl relative group p-1.5 ${container} ${can_select ? "cursor-pointer" : ""} ${outer}`}
    >
        {show_results && <SelectedBg eliminated={!show_as_eliminated} votes_count={votes_count} selection={selection}
                                     voting_method={poll.voting_method} is_winner={is_winner} id={id} totals={totals}/>}
        <div className="grid z-10 relative gap-2.5" style={{gridTemplateColumns: '1.5rem 1fr auto'}}>
            {!show_results && !voted && <div className="w-6 flex items-center justify-center">
                <Square className={`h-5 w-5 flex items-center justify-center rounded-md border-2 ${square}`}>
                    {selected && <ChoiceIcon number={number} voting_method={poll.voting_method}/>}
                </Square>
            </div>}
            {show_your_results && <div className="w-6 flex items-center justify-center">
                <Square
                    className={`h-5 w-5 flex items-center justify-center rounded-md border-2 border-gray-400 bg-gray-400`}>
                    {selected && <ChoiceIcon number={number} voting_method={poll.voting_method}/>}
                </Square>
            </div>}
            {show_results && <div className="w-5 h-5 flex items-center justify-center">
                {selected &&
                <ChoiceIcon is_winner={is_winner} show_results number={number} voting_method={poll.voting_method}/>}
            </div>}
            <div className={`text-sm font-medium`}>
                <span className={` ${label}`}>{text}{!text &&
                <span className="text-gray-400 italic">Add Choice</span>}</span>
            </div>
            <div className="flex items-center pr-1">
                {show_results && <NewTooltip message={`${totals[id]} votes`}>
                    <div className="text-xs text-gray-600">
                        {getPercent(votes_count / totals.total)}
                    </div>
                </NewTooltip>}
            </div>
        </div>
    </div>
}

function getChoiceMeta(poll, id, vote, totals, status, has_voted, show_results) {
    /*
        if(poll.type==='ranked-choice-all') {

        } else if(poll.type==='ranked-choice-any') {

        }

     */

    const selected = vote && vote[id];

    return {
        is_winner: voting_getIsWinner(poll, id, vote, totals, status),
        number: vote[id],
        show_your_results: !show_results && has_voted && status === 'open',
        can_select: status === 'open' && !has_voted,
        selected
    }
}

function randomIntFromInterval(min, max) { // min and max included
    return Math.floor(Math.random() * (max - min + 1) + min);
}

function prepDataForResolver(poll) {
    return {
        choices: poll.choices,
        choices_data: poll.choices_data,
        votes: poll.votes,
        voted: poll.voted
    }
}

function buildTotals(ch, poll, show_results) {
    let a = {};

    if (poll.voting_method === 'ranked-choice-all') {
        if (!show_results) {
            return {
                total: poll.voter_count
            }
        }
        const results = voting_rankedChoiceResolver(prepDataForResolver(poll));

        return {
            ...results,
            total: poll.voter_count
        }
    }

    ch.forEach(id => {
        a[id] = poll.votes[id] ? Object.keys(poll.votes[id]).length : 0;
    });

    const total = Object.values(a).reduce((partialSum, a) => partialSum + a, 0);

    if (poll.voter_count) {
        a.total = poll.voter_count;
    } else {
        a.total = total;
    }

    return a;
}

function shouldShowResults(data, voted, status) {
    if (status === 'closed') {
        return true;
    } else if (data.show_results === 'immediately' && voted) {
        return true;
    } else {
        return false;
    }
}

function getPollStatus(poll) {

    let m2 = moment(poll.closes);
    let m = moment();

    if (m2.isAfter(m)) {
        return 'open'
    }

    return 'closed'
}

function processLiveData(livedata) {
    return {
        ...livedata
    }
}

function buildPoll(preview, livedata) {
    if (livedata) {
        return processLiveData(livedata);
    }
    if (preview) {
        return {
            ...poll,
            title: preview.title,
            voting_method: preview.voting_method,
            choices: preview.choices,
            closes: preview.closes,
            choices_data: preview.choices_data,
        }
    }
    return {...poll}
}

function RankedChoiceRoundsTabs({selected, totals, poll, setSelection}) {
    const tabs = totals.rounds_display.map(rd => {
        return rd[0]
    });

    return <Tabs tabs={tabs} onSelect={s => setSelection(s)} active={selected}/>;
}

export const rating_reactions = [
    {
        id: "bad",
        emoji: "😕",
        label: "Bad"
    },
    {
        id: "ok",
        emoji: "🙂",
        label: "Ok"
    },
    {
        id: "awesome",
        emoji: "😃",
        label: "Awesome"
    }
];

function buildRatingNumberArray() {
    let a = [];
    for (let i = 0; i < 11; i++) {
        a.push(i);
    }
    return a;
}

function buildRatingReactionsArray() {
    return [...rating_reactions];
}

function Reaction({meta,reaction,selectChoice}) {
    const bg = meta.selected ? `bg-selection text-white` : `bg-gray-100 text-gray-600 group-hover:bg-selection group-hover:text-white`;
    const text = meta.selected ? `bg-selection text-white` : `bg-gray-100 text-gray-600 transition-opacity opacity-0 group-hover:opacity-100 group-hover:bg-gray-100 group-hover:text-gray-600`;
    return <div onClick={()=>selectChoice(reaction.id)} className="group p-1 cursor-pointer">
        <div className="flex justify-center">
            <div className={`${bg} h-10 w-10 flex justify-center items-center rounded-full text-center`}>
            <span className="text-3xl">
            {reaction.emoji}
            </span>
            </div>
        </div>
        <div className="pt-1 h-6 flex justify-center">
            <div className={`${text} text-xs text-center p-0.5 rounded-md inline-block px-1.5 font-medium`}>
                {reaction.label}
            </div>
        </div>
    </div>
}

function CommentBox({comment,setComment}) {

    return <div className="pt-2 pb-1">
        <Field label="Add a Comment">
        <TextArea value={comment} onChange={v=>setComment(v)} placeholder="Do you have any feedback?" />
    </Field>
    </div>
}

function renderPollChoices(poll, vote, totals, status, voted, show_results, selection, selectChoice) {

    if (poll.voting_method === 'rating-number') {
        const numbers = buildRatingNumberArray();
        return <div className="grid gap-1 pt-2 pb-1" style={{gridTemplateColumns: `repeat(auto-fill, 2rem)`}}>
            {numbers.map(num => {
                const meta = getChoiceMeta(poll, num, vote, totals, status, voted, show_results);
                const bg = meta.selected ? `bg-selection text-white` : `bg-gray-100 text-gray-600 hover:bg-selection hover:text-white`;
                return <div onClick={() => selectChoice(num)}
                            className={`cursor-pointer flex ${bg} justify-center items-center h-8 transition-colors rounded-md`}
                            key={num}>
                    <div className="text-sm font-medium">
                        {num}
                    </div>
                </div>
            })}
        </div>
    }

    if (poll.voting_method === 'rating-reaction') {
        const reactions = buildRatingReactionsArray();
        return <div className="pt-2 pb-1 grid gap-2"
                    style={{gridTemplateColumns: `repeat(auto-fill, minmax(3rem,5rem))`}}>
            {reactions.map(reaction => {
                const meta = getChoiceMeta(poll, reaction.id, vote, totals, status, voted, show_results);
                return <Reaction selectChoice={selectChoice} key={reaction.id} meta={meta} reaction={reaction}/>
            })}
        </div>
    }

    return <div className="space-y-0.5 mt-1">
        {poll.choices.map(id => {
            const meta = getChoiceMeta(poll, id, vote, totals, status, voted, show_results);
            return <Choice show_results={show_results} id={id} voted={voted} totals={totals} poll={poll}
                           key={id} selection={selection}
                           onSelect={() => {
                               if (meta.can_select) {
                                   selectChoice(id)
                               }
                           }} {...meta} {...poll.choices_data[id]} />
        })}
    </div>
}

export function PollView({preview, id, debug, livedata, loading}) {
    const community = useContext(CommunityContext);
    const [vote, setVote] = useState({});
    const [selection, setSelection] = useState("Results");
    const [comment, setComment] = useState("");
    const [poll, setPoll] = useState(buildPoll(preview, livedata));
    const [voted, hasVoted] = useState(false);

    useEffect(function () {
        setPoll(buildPoll(preview, livedata))
        if (livedata) {
            const has_voted = !!livedata.voted[community.member_id];
            hasVoted(has_voted);
            if (has_voted) {
                setVote(livedata.vote_history[community.member_id]);
            }
        }
    }, [livedata, preview])

    function handleVote() {
        if (preview) {
            return;
        }

        // update poll

        api_votePoll(community, id, vote)
            .then(() => {

            })
        hasVoted(true);
        let np = {...poll};
        np.voted[community.member_id] = true;
        np.voter_count++;
        np.vote_history[community.member_id] = vote;
        const ventries = Object.entries(vote);
        ventries.forEach(([a, b]) => {
            if (np[`votes.${a}`]) {
                np[`votes.${a}`][`${community.member_id}`] = b;
            } else {
                np[`votes.${a}`] = {};
                np[`votes.${a}`][`${community.member_id}`] = b;
            }

        });
        setPoll(np);
    }

    function selectChoice(id) {
        let nv = voting_handleSelection(id, vote, poll);

        setVote({
            ...nv
        })
    }

    const status = getPollStatus(poll);

    const show_results = shouldShowResults(poll, voted, status);

    const disable_button = voting_shouldDisableButton(poll, vote);

    const totals = buildTotals(poll.choices, poll, show_results);

    let content;

    if (loading) {
        content = <div className="h-96">
            <InlineLoader padding={"p-10"}/>
        </div>
    } else {
        content = <div>
            <Title {...poll} />

            {renderPollChoices(poll, vote, totals, status, voted, show_results, selection, selectChoice)}

            {show_results && poll.voting_method === 'ranked-choice-all' && <div className="pt-2">
                <RankedChoiceRoundsTabs totals={totals} setSelection={(s) => setSelection(s)} poll={poll}
                                        selected={selection}/>
            </div>}
            {!voted&&preview&&preview.can_comment&&<CommentBox comment={comment} setComment={setComment} />}
            <div className="h-10 mt-1 flex items-center">
                <Actions voted={voted} status={status} show_results={show_results} totals={totals}
                         disable_button={disable_button}
                         vote={handleVote} poll={poll}/>
            </div>
            {!show_results && voted &&
            <div><span className="text-gray-600 text-xs">Results shown at poll close</span></div>}
            {show_results && poll.voting_method === 'ranked-choice-all' && totals.winner === 'none' && <div>
                <span className="text-gray-600 text-xs">Poll resulted in an even draw</span>
            </div>}
            {debug && <div>
            <pre className="text-xs p-2 bg-gray-100 border-gray-300 rounded-md overflow-y-scroll text-left"
                 style={{maxHeight: '200px'}}>{JSON.stringify({
                vote, totals, show_results, id, disable_button, status, poll
            }, undefined, 2)}</pre>

            </div>}
        </div>
    }

    return <div className="rounded-xl shadow-sm border border-gray-200 p-4">
        {content}
    </div>
}