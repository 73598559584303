import React, {Component} from "react";

import {getUrlVars} from "../../../library/utilities/general";
import {validateEmail} from "../../../library/utilities/validation";
import { externalAuthFetch} from "../../../config/network";
import Field from "../../../library/components/old/field";
import ButtonSection from "../../../library/components/old/button-section";
import Form from "../../../library/components/old/form";
import {Button} from "../../../library/components/core";
import styled from "styled-components";

const MetaText = styled.p`
      margin: 24px 0;
    line-height: 1.4;
`;

const ConfirmText = styled.div`
    font-size: 16px;
    color: green;
`;

class ForgotPasswordBlock extends Component {
    constructor(props) {
        super(props);

        this.state = {
            sending: false,
            email: '',
            sent: false
        }
    }

    hc(f, v) {
        this.setState({
            [f]: v
        })
    }

    componentDidMount() {
        let email = getUrlVars()['email'];
        let email1 = getUrlVars()['_email'];

        if (typeof email === 'string') {
            this.setState({
                email: email
            });
        }
        if (typeof email1 === 'string') {
            this.setState({
                email: email1
            });
        }
    }

    handleSend(disable,e) {
        if(e) {
            e.preventDefault();
        }
        if(disable) {
            return;
        }

        const {sending, email} = this.state;
        if (sending) {
            return;
        }

        setTimeout(
            function() {
                this.setState({
                    sending: false,
                    sent: true
                })
            }
            .bind(this), 2500);

        this.setState({
            sending: true
        });

        externalAuthFetch("/send-password-reset-email", ()=>{}, ()=>{}, "POST", {email});
    }

    renderAction(sending, sent) {
       if (sent) {
            return <div className="mt-2">
                <ConfirmText>
                    Email Sent
                </ConfirmText>

                <MetaText className='text-gray-800'>
                    If that email address is linked to an account, a password reset link has been sent to it, please check your inbox and spam folder if you do not see the email directly.
                </MetaText>
            </div>
        }

        const {email} = this.state;

        const disable = this.disableBtn();

        return <>
            <Form handleSubmit={ this.handleSend.bind(this,disable)}>

                <MetaText className='text-gray-800'>
                    Enter the email address associated with your account and we'll send you a link to reset your password.
                </MetaText>

                <Field autoFocus={true} type={"mainlogin"} label="Email" onChange={this.hc.bind(this, 'email')} value={email} />

            </Form>

            <ButtonSection className='auth-actions'>
                <Button intent='secondary' fill size='large' disabled={disable} onClick={this.handleSend.bind(this, disable)} text='Get Email Reset Link' loading={sending} />
            </ButtonSection>

        </>;
    }

    disableBtn() {
        let {email} = this.state;
        return (!validateEmail(email))
    }

    render() {
        const {sending, sent} = this.state;
        return (
            <>

                {this.renderAction(sending, sent)}
            </>
        );
    }
}

export default ForgotPasswordBlock;