import React, {useEffect, useState} from "react";
import {externalAuthFetch} from "../../../config/network";
import {Field, Input} from "../../../library/components/core";
import {ArrowCircleRightIcon} from "@heroicons/react/outline";
import {validateEmail} from "../../../library/utilities/validation";

export default function SignInInputUserName({
                                                username = "",
                                                handleResp = () => {}
                                            }) {
    const [u, setU] = useState("");
    const [loading, setLoading] = useState('');

    useEffect(function() {
        if(!u&&username) {
            setU(username);
        }
    }, [username]);

    function verifyUsernameExists() {
        setLoading('verify-username')

        const res = (resp) => {
            handleResp(resp,u);
            setLoading('');
        };

        const payload = {
            username: u.toLowerCase()
        };

        externalAuthFetch("/verify-username", res, res, "POST", payload);
    }

    function hc(f, v) {
        if(f==='username') {
            setU(v);
        }
    }

    const comp = <div>
        <div onClick={()=>{

            if(!loading&&u&&validateEmail(u)) {
                handleResp({data:'email_direct_entry'},u.toLowerCase());
            } else if(!loading&&u) {
                verifyUsernameExists()
            }
        }} className={`h-6 w-6 cursor-pointer ${!u?"text-gray-400":"text-gray-700"}`}>
            {loading==='verify-username'?<div className="simple-loader h-6 w-6" />:<ArrowCircleRightIcon />}
        </div>
    </div>;

    return <div className="space-y-4 pt-4">
        <Field label={"Unaty ID"}>
            <Input onEnter={()=>{
                if(u&&validateEmail(u)) {
                    handleResp({data:'email_direct_entry'},u.toLowerCase());
                } else if(u) {
                    // try
                    verifyUsernameExists();
                }
            }} placeholder="Email or @handle" autoFocus={true} type="text" trailing={{type:'comp',value:comp}} onChange={hc.bind(this, 'username')} value={u} />

            <input type="password" className="hidden" />
        </Field>

    </div>
}