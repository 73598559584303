import React, {useState, useEffect, useContext} from 'react';
import {getFileMap, handleSetFiles, handleSetFolders} from "./files-query";
import moment from "moment";
import {getItemFromLocalStorage} from '../../../library/utilities/localstorage';
import {copyTextToClipboard} from "../../../library/utilities/general/copy-to-clipboard";
import {APP_BASE_URL} from "../../../config/defaults";
import {CommunityContext} from "../community-context";
import {InlineLoader, SimpleTable} from "../../../library/components/core";
import {LinkIcon, DownloadIcon} from "@heroicons/react/solid";
import {formatFileSize, getFileTypeIcon} from "../../../library/components/old/file-uploader/helpers";
import {getDateFormats, getPrettyTime} from "../../../library/utilities/general/dates";
import {GlobalContext} from "../../global/global-context";
import {useHistory} from "react-router-dom";
import {FirebaseDB} from "../../../config/setup-firestore";
import {EntityCard} from "../../../library/components/entity-card";

function processAssets(assets, community_uid, formats) {
    return assets.map((asset, i) => {
        const {_type, id} = asset;

        if (_type === 'folder') {
            return {
                id: `folder-${id}`,
                icon: getFileTypeIcon(asset.type === 'group' ? 'group-folder' : 'folder'),
                type: 'folder',
                data: {
                    name: asset.name,
                    size: '—',
                    folder: asset.folder,
                    last_updated: {
                        date: getPrettyTime(moment.unix(asset.updated_at / 1000), '', formats.relative),
                        datetime: moment.unix(asset.updated_at / 1000).format(formats.date_time),
                        community_uid,
                        member_id: asset.updated_by ? asset.updated_by : asset.created_by
                    }
                },
                raw: {
                    last_updated: asset.updated_at,
                    size: null,
                    name: asset.name.toLowerCase(),
                    ...asset
                }
            };
        } else {
            // file
            return {
                id: `file-${id}`,
                icon: getFileTypeIcon(asset.type),
                type: 'file',
                data: {
                    name: asset.name,
                    folder: asset.folder,
                    link: asset.link,
                    size: formatFileSize(asset.size),
                    last_updated: {
                        date: getPrettyTime(moment.unix(asset.updated_at / 1000), '', formats.relative),
                        datetime: moment.unix(asset.updated_at / 1000).format(formats.date_time),
                        community_uid,
                        member_id: asset.updated_by ? asset.updated_by : asset.created_by
                    }
                },
                raw: {
                    last_updated: asset.updated_at,
                    size: asset.size,
                    name: asset.name.toLowerCase(),
                    ...asset
                }
            };
        }
    });
}

export function sortFilesFolders(files, folders) {
    let _folders = [];
    let _files = [];

    const keys = Object.keys(folders);

    for (let i = 0; i < keys.length; i++) {
        const key = keys[i];

        const item = folders[key];
        _folders.push({
            _type: 'folder',
            ...item
        });
    }
    const keys_files = Object.keys(files);

    for (let i = 0; i < keys_files.length; i++) {
        const key = keys_files[i];
        const item = files[key];
        _files.push({
            _type: 'file',
            ...item
        });
    }

    return _folders.sort((a, b) => (b.updated_at - a.updated_at)).concat(_files.sort((a, b) => (b.updated_at - a.updated_at)));
}

function getLastUpdated(ts,formats) {
    return `Last updated ${moment.unix(ts / 1000).format(formats.weekday)}`;
}

function buildFolderItem(fo,formats) {

    return {
        id: fo.id,
        title: fo.data.name,
        subtitle: `${getLastUpdated(fo.raw.updated_at,formats)}`,

        tags: [],

        icon: {
            emoji: fo.data.emoji,
            profile_picture: "",
            profile_picture_color: "",
            color: ""
        },

        show_title: false,

        cover: {
            url: "",
            color: ""
        },

        title_icon: null,
        title_icon_description: "",

        meta: null,

        action: null,

        stats: []
    };
}

export default function FilesScrollable({
                                            folder,
                                            in_group,
                                            base,
                                            display = 'table',
                                            is_mobile,
                                            can_edit,
                                            selected,
                                            group_top_level_folder,
                                            hide_go_up,
                                            setNames,
                                            setLength,
                                            setList,
                                            updateSelected,
                                            language,
                                            top_level,
                                            parent_folder,
                                        }) {
    const global = useContext(GlobalContext);
    const history = useHistory();
    const community = useContext(CommunityContext);
    const [loading, setLoading] = useState(true);
    const [files, setFiles] = useState(!folder?[]:null);
    const [folders, setFolders] = useState(null);

    useEffect(function () {
        setFiles(!folder?[]:null);
        setFolders(null);
        let folders_sub = FirebaseDB
            .collection('community_entities')
            .doc(community.uid)
            .collection('folders')
            .where('folder', '==', folder)
            .where(`user_uids.${community.member_id}`, '==', true)
            .limit(200)
            .onSnapshot((snap) => {
                const f = handleSetFolders('public', snap, community.uid, {setNames, setLength});
                if (setLength) {
                    setLength(f.length, 'folders');
                }
                if (setNames) {
                    const arr = Object.values(f).map(fol => fol.name);
                    setNames(arr, 'public');
                }
                setFolders(f);
            });



        let files_sub = !folder ? ()=>{

        } : FirebaseDB
            .collection('community_entities')
            .doc(community.uid)
            .collection('files')
            .where('folder', '==', folder)
            .limit(200)
            .onSnapshot((snap) => {
                const fi = handleSetFiles(snap, community.uid, {setNames, setLength});
                if (setList) {
                    setList(getFileMap(fi))
                }
                if (setLength) {
                    setLength(fi.length, 'files');
                }
                setFiles(fi);
            });

        return () => {
            folders_sub();
            files_sub();
        }
    }, [folder])

    function goTo(handle) {
        history.push(`/${community.data.handle}/d/${handle}`);
    }

    function copyToClipboard(path) {
        copyTextToClipboard(`${APP_BASE_URL}${path}`);
        global.onAddToast({text: "Link copied to clipboard!", intent: 'success'});
    }

    function goToItem(itid,entry) {
        console.log("entry",entry)
        if (entry.type === 'folder') {
            history.push(`${base}/folder/${itid.split('-')[1]}`)
        } else {
            history.push(`${base}/folder/${entry.data.folder}/file/${itid.split('-')[1]}`)
        }
    }

    let headers = [
        {
            width: '2fr',
            label: 'Name',
            field: 'name',
            type: 'text',
            title: true
        }
    ];


    if (!in_group) {
        headers.push({
            width: '1.2fr',
            label: 'Last modified',
            type: 'date-member',
            hidden: is_mobile,
            field: 'last_updated'
        });
        headers.push({
            width: '0.8fr',
            label: 'Size',
            type: 'number',
            hidden: is_mobile,
            field: 'size'
        });
    } else {
        headers.push({
            width: '0.6fr',
            label: 'Last modified',
            type: 'date',
            hidden: is_mobile,
            field: 'last_updated'
        });
    }

    if (!files || !folders) {
        return <InlineLoader/>;
    }

    let assets = sortFilesFolders(files, folders);

    const formats = getDateFormats(language);

    const final = processAssets(assets, community.uid, formats);

    function goUp() {
        if (parent_folder === '' || group_top_level_folder === parent_folder) {
            history.push(`${base}`)
        } else {
            history.push(`${base}/folder/${parent_folder}`)
        }
    }

    if (display === 'drive') {
        return <div className="grid gap-4 pt-1" style={{gridTemplateColumns: `repeat(auto-fill, minmax(300px, 1fr))`}}>
            {final.map(it => {
                const dt = buildFolderItem(it,formats);
                return <EntityCard onClick={(id) => {
                    goToItem(id,it);
                }} key={`${it.id}`} {...dt} />
            })}
        </div>
    }

    return <div className="-mx-3 relative">
        <SimpleTable localstorage_key={`folder-${folder}`} selected={selected}
                     updateSelected={(ids) => updateSelected(ids)} onGoUp={() => goUp()}
                     go_up={!top_level && !hide_go_up} actions={[{
            label: 'Copy Link',
            icon: <LinkIcon/>,
            types: ['folder', 'file'],
            onClick: (id, entry) => {
                // todo distinguish file and folder
                let path = '';
                if (entry.type === 'folder') {
                    path = `/${base}/folder/${id.split('-')[1]}`;
                } else {
                    path = `/${base}/folder/${entry.data.folder}/file/${id.split('-')[1]}`;
                }
                copyTextToClipboard(`${APP_BASE_URL}${path}`);
                global.onAddToast({text: "Link copied to clipboard!", intent: 'success'});
            }
        },
            {
                label: 'Download',
                icon: <DownloadIcon/>,
                types: ['file'],
                onClick: (id, entry) => {
                    window.open(entry.data.link, '_blank');
                }
            }]} onItemClick={(itid, entry) => goToItem(itid, entry)} items={final} headers={headers}
                     default_sort={getItemFromLocalStorage(`folder-${folder}`, {
                         field: 'last_updated',
                         dir: 'desc'
                     })} can_select={can_edit} stripe_color={"bg-white"}/>
    </div>;
}