import React from 'react';
import {ChevronRightIcon} from '@heroicons/react/solid';
import styled from "styled-components";
import {PopupMenu} from "./index";
import {Popover2} from "@blueprintjs/popover2";

function getColor(intent) {
    switch (intent) {
        case 'danger' : {
            return '#d84949';
        }
        case 'warning' : {
            return '#FFC107';
        }
        case 'primary' : {
            return '#2F80ED';
        }
        case 'success' : {
            return '#2d9665';
        }
        default : {
            return '';
        }
    }
}

function getBkg(intent) {
    switch (intent) {
        case 'danger' : {
            return '#d84949';
        }
        case 'warning' : {
            return '#FFC107';
        }
        case 'primary' : {
            return '#2F80ED';
        }
        case 'success' : {
            return '#2d9665';
        }
        default : {
            return '#2F80ED';
        }
    }
}

const Outer = styled.div`
    cursor: pointer;
        outline: none;
    opacity: ${props=>props.disabled?'0.5':'1'};
    cursor: ${props=>props.disabled?'auto':'pointer'};
    transition: 0.08s background-color, color ease;
    .title {
        color: ${props=>getColor(props.intent)};
    }
    :hover {
        background-color: ${props=>getBkg(props.intent)};
        .invert {
            color: #fff !important;
        }
    }
`;

const Inner = styled.div`
    display: grid;
    grid-template-columns: auto 1fr auto auto;
`;

const Title = styled.div`
    display: flex;
        font-weight: 500;
        flex-flow: row;
    align-items: baseline;
        line-height: 24px;
`;

const Subtitle = styled.div`
    margin-bottom: 4px;
`;

const Meta = styled.div`
        margin-left: 6px;
`;

const Shortcut = styled.div`
     line-height: 24px;
    padding: 0 6px;
`;

const Img = styled.div`
    height: 20px;
    width: 20px;
    border-radius: 3px;
    border: 1px solid;
`;

const Icon = styled.div`
height: 22px;
    display: flex;
    align-items: flex-start;
    margin-right: 4px;
    margin-left: 2px;
        margin-top: 2px;
    svg {
      height: 18px;
    }
`;

const SubmenuIcon = styled.div`
  height: 24px;
    width: 24px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    svg {
        height: 20px;
    }
`;

const ItemFrame = styled.div`
    > span, > span > span {
        display: block;
    }
`;

export const PM_Item = ({title,width="225px",use_portal=true,img,intent='',disabled=false,onClick=()=>{},items=[],subtitle,meta,icon,shortcut,has_submenu}) => {
    const item = <Outer tabIndex='-1' className={`${has_submenu?'':'bp4-popover2-dismiss'} px-2.5 py-1`} disabled={disabled} intent={intent} onClick={disabled?()=>{}:onClick}>
        <Inner className="gap-1">
        <div>
            {img&&<Img>img</Img>}
            {icon&&<Icon className='invert text-gray-500'>{icon}</Icon>}
        </div>

        <div>
            <Title className='invert text-xssm title text-gray-800'>
                {title}
                {meta&&<Meta className='invert text-xssm text-gray-600'>{meta}</Meta>}
            </Title>
            {subtitle&&<Subtitle className='invert text-xs text-gray-700'>{subtitle}</Subtitle>}
        </div>

        <div>
            {shortcut&&<Shortcut className='invert text-sm text-gray-600'>{shortcut}</Shortcut>}
            {has_submenu&&<SubmenuIcon className='invert text-gray-700'><ChevronRightIcon/></SubmenuIcon>}
        </div>
    </Inner>
    </Outer>;

    if(has_submenu) {
        return <ItemFrame>
            <Popover2 usePortal={use_portal} openOnTargetFocus={false} content={<PopupMenu width={width} items={items} />} interactionKind='hover' placement={'right'} minimal popoverClassName='minimal-popover no-margin-top margin-left-6'>
                {item}
            </Popover2>
        </ItemFrame>;
    }

    return <ItemFrame>{item}</ItemFrame>;
};