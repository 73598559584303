
import {CUSTOM_NANOID} from "../../config/defaults";

const defaultOptions = {
    color: "#6796e6"
};

export default function createToast(options) {
    const id = CUSTOM_NANOID();
    return {
        ...defaultOptions,
        ...options,
        id
    }
}