import React from 'react';
import classNames from 'classnames';
import {XIcon} from '@heroicons/react/solid';
import styled from "styled-components";

const props = {
actions: null,
    title: '',
    subtitle: null,
    right_action: null,
    accent_border: false,
    onDismiss: () => {}
};

const ActionsBox = styled.div`

> * {
    color: inherit !important;
}
`;

const IconBox = styled.div`

> svg {
    height: 20px;
}
`;

const Border = styled.div`

border-color: ${props=>getBorderColor(props.intent)} !important;
`;

const DismissIcon = styled.div`

> svg {
    height: 20px;
}
`;

function getBkgColor(intent) {
    switch (intent) {
        case 'primary': {
            return 'bg-primary-100 bg-opacity-20';
        }
        case 'success': {
            return 'bg-success-100 bg-opacity-20';
        }
        case 'info': {
            return 'bg-blue-50';
        }
        case 'secondary': {
            return 'bg-secondary-100 bg-opacity-20';
        }
        case 'warning': {
            return 'bg-yellow-100 bg-opacity-20';
        }
        case 'danger': {
            return 'bg-danger-100 bg-opacity-20';
        }
        default: {
            return 'bg-gray-50';
        }
    }
}

function getColor(intent) {
    switch (intent) {
        case 'primary': {
            return 'text-primary-700';
        }
        case 'success': {
            return 'text-success-700';
        }
        case 'info': {
            return 'text-blue-800';
        }
        case 'secondary': {
            return 'text-secondary-700';
        }
        case 'warning': {
            return 'text-yellow-700';
        }
        case 'danger': {
            return 'text-danger-700';
        }
        default: {
            return 'text-gray-700';
        }
    }
}

function getBorderColor(intent) {
    switch (intent) {
        case 'primary': {
            return '#d57f64';
        }
        case 'success': {
            return '#669d80';
        }
        case 'info': {
            return '#5e77c9';
        }
        case 'secondary': {
            return '#6bb8d4';
        }
        case 'warning': {
            return '#be9259';
        }
        case 'danger': {
            return '#c7716f';
        }
        default: {
            return 'transparent';
        }
    }
}

export function Alert({title,icon,subtitle,intent="info",actions,right_action,onDismiss,accent_border}) {

    const color = getColor(intent);
    return <Border intent={intent} className={classNames("w-full p-3 space-x-2.5 rounded flex","border-l-4 border-solid",getBkgColor(intent),getColor(intent))}>
<div className="pt-1">
    <IconBox className={classNames(color,"opacity-70")}>
        {icon}
    </IconBox>
</div>
<div className="flex-grow space-y-1">
    <div className={classNames(color,"font-medium text-base")}>{title}</div>
    {subtitle&&<div className={classNames(color)}>{subtitle}</div>}
    {actions&&<ActionsBox className={classNames(color,"space-x-6 pt-1 font-semibold text-sm")}>{actions}</ActionsBox>}
</div>
        {right_action&&<div className={classNames(color)}>{right_action}</div>}
        {!!onDismiss&&<DismissIcon onClick={()=>onDismiss()} className={classNames(color,"self-center hover:opacity-70 cursor-pointer justify-center pr-2")}>
            <XIcon/>
        </DismissIcon>}
    </Border>
}