import {authFetch} from "../../../config/network";

export async function api_createFile(community,pl) {
    return await new Promise((resolve, reject) => {
        const res = (r) => {
            resolve(r);
        };
        const payload = {
            community_uid: community.uid,
            member_id: community.member_id,

            name: pl.name,
            type: pl.type,
            folder: pl.folder,
            link: pl.url,
            size: pl.size,
        };
        authFetch("/files/create", res, res, "POST", {payload});
    })
}