import React from "react";

const examples = [
    "Sean Elliott McCall",
    "Sean E McCall",
    "Sean E. McCall",
    "John Smith",
    "Paul Steve Panakkal",
    "Blake Andrew Robertson",
    "Simon Baron-Cohen",
    "Dick Van Dyck",
    "Adriaen de Vries",
    "Sam de la Hoya",
    "Jamie del Bosque",
    "Dara Ó Briain",
    "Boyd van der Vuurst de Vries",
    "Alex Draconia van der Vuurst de Vries",
    "Fabio Alexander Di Nagamaka"
];
const last_name_prefixes = [
  "van der",
  "von der",
  "vom",
    "de la",
  "di",
  "de",
  "del",
  "van",
  "ó",
  "Ó",
    "von"
];
// account for middle initial, is uppercase?
/*
In the English-speaking world, a surname is commonly referred to as a last name
because it is usually placed at the end of a person's full name, after any given names.
In many parts of Asia, as well as some parts of Europe and Africa, the family name is
placed before a person's given name. In most Spanish-speaking and Portuguese-speaking countries,
two surnames are commonly used and in some families even three or more are used (often due to a family claim to nobility).
 */

/*
de la Hoya
del Bosque
 */

export function SplitNamesMocks() {
    return <div className="space-y-2">
        {examples.map((ex, index) => {
            const final = splitName(ex);
            return <div key={index}>
                [{ex}]: First: {final.first_name}, Middle: {final.middle_name}, Last: {final.last_name}
            </div>
        })}
    </div>
}

function isUppercase(l) {
    return l === l.toUpperCase()
}

function isMiddleInitial(str) {
    if(str.length===1&&isUppercase(str.charAt(0))) {
        return true;
    } else if(str.length===1&&isUppercase(str.charAt(0))&&str.charAt(1)==='.') {
        return true;
    }
    return false;
}

function checkLongLastName(str) {
    const test = str.toLowerCase();
    for(let i=0;i<last_name_prefixes.length;i++) {
        const prefix = last_name_prefixes[i];
        if(test.indexOf(prefix)!==-1) {
            return test.indexOf(prefix);
        }
    }
    return -1;
}

function isLastNamePrefix(str) {
    let test = str.toLowerCase();
    if(!last_name_prefixes.includes(test)&&!last_name_prefixes.includes(str)) {
        return '';
    }
    const index = last_name_prefixes.indexOf(test);
    return index;
}

export function splitName(full_name) {
    let first_name = "", last_name = "", middle_name = "", t1, t2, t3, t4, t5;

    if(full_name.indexOf("|")!==-1) {
        const splitter = full_name.split("|");
        const splitter_len = splitter.length;
        if(splitter.length===2) {
            first_name = splitter[0];
            last_name = splitter[1];
        } else if(splitter.length===3) {
            first_name = splitter[0];
            middle_name = splitter[1];
            last_name = splitter[2];
        } else {
            first_name = splitter[0];
            middle_name = [...splitter].slice(1,(splitter_len-1)).join(" ");
            last_name = splitter[(splitter_len-1)];
        }

        let d2 = `${first_name.trim()}${middle_name?` | ${middle_name.trim()}`:''}${last_name?` | ${last_name.trim()}`:''}`

        return {
            full_name: full_name,
            divided: d2,
            first_name: first_name.trim(),
            last_name: last_name.trim(),
            middle_name: middle_name.trim()
        }
    }

    const split = full_name.trim().split(" ");
    const name_len = full_name.length;

    const len = split.length;

    if(len===1) {
        first_name = split[0];
    } else if(len===2) {
        first_name = split[0];
        last_name = split[1];
    } else if(len===3) {
        t4 = isLastNamePrefix(split[1]);
       if(t4) {
           first_name = split[0];
           last_name = `${split[1]} ${split[2]}`;
       } else if(isMiddleInitial(split[1])) {
           first_name = split[0];
           middle_name = split[1];
           last_name = split[2];
       } else {
           // default case
           first_name = split[0];
           middle_name = split[1];
           last_name = split[2];
       }
    } else if(len===4) {
        t1 = split[0];
        t2 = split[(len-1)];
        t3 = [...split].slice(1,len-1).join(" ");
        t4 = isLastNamePrefix(t3);

        if(t4) {
            first_name = full_name.slice(0,(t4+1));
            last_name = full_name.slice((t4+1),name_len);
        } else {
            if(split[1].length>4) {
                // likely second first name
                first_name = `${split[0]} ${split[1]}`;
            } else {
                first_name = split[0];
            }

            if(split[2].length>4) {
                last_name = `${split[3]}`;
                middle_name = `${split[2]}`;
            } else {
                last_name = `${split[2]} ${split[3]}`;
            }
        }
    } else {
        // we know there's at least one first and one last name
        t1 = split[0];
        t2 = split[(len-1)];
        // rest
        t3 = [...split].slice(1,len-1).join(" ");
        t4 = checkLongLastName(t3);
        // t4 === index of the splitter
        if(t4>-1) {
            t5 = `${t3.slice(0,t4)}`;
            first_name = `${t1}${t5?` ${t5}`:''}`;
            last_name = `${t3.slice((t4),t3.length)} ${t2}`;
        } else {
            // go to 2 first names, probably not less than 2 chars
            if(split[1].length>4&&len>4) {
                // likely second first name
                first_name = `${split[0]} ${split[1]}`;
                t5 = 2;
            } else {
                first_name = split[0];
                t5 = 1;
            }

            if(split[len-2].length>4&&len>4) {
                // likely second last name
                last_name = `${split[(len-2)]} ${split[(len-1)]}`;
                middle_name = [...split].slice((t5+1),len-2).join(" ");
            } else {
                last_name = split[(len-1)];
                middle_name = [...split].slice((t5+1),len-1).join(" ");
            }
        }
    }

    let divided = `${first_name.trim()}${middle_name?` | ${middle_name.trim()}`:''}${last_name?` | ${last_name.trim()}`:''}`

    return {
        full_name: full_name,
        divided,
        first_name: first_name.trim(),
        last_name: last_name.trim(),
        middle_name: middle_name.trim()
    }
}