import React, {useContext, useEffect, useRef, useState} from "react";
import {SimpleEditList} from "../../../custom/simple-edit-list";
import {PlusIcon} from "@heroicons/react/solid";
import {SearchableSuggestions} from "../../../custom/searchable-suggestions";
import {CUSTOM_NANOID} from "../../../../../config/defaults";
import {teams_suggestions} from "../../suggestions/teams";
import {authFetch} from "../../../../../config/network";
import {CommunityContext} from "../../../../../app/community/community-context";

const tabs = [
    "All",
    "Social",
    "Non-profits",
    "Service",
    "Religion"
];

function convertArr(arr) {
    return arr.map(it => {
        return {
            emoji: it.emoji,
            title: it.name,
            tag_only: it.tag_only,
            subtitle: [
                {
                    text: it.purpose,
                    field: 'purpose'
                },
            ],
            search: it.name.toLowerCase(),
            tags: it.tags
        }
    })
}

function prepareData() {

}

export function ObScSetupTeamsAndRoles(props) {
    const community = useContext(CommunityContext);
    const [all,setAll] = useState([]);
    const [data,setData] = useState({});
    const val = useRef();

    useEffect(function () {
        return () => {
            val.current = {all,data};
        }
    }, [all,data]);

    useEffect(function () {
        return () => {
            handleSave(val.current);
        }
    }, []);

    function handleSave(dt) {
        if(dt&&community&&community.member_id) {
            // members/edit-interests
            const res = () => null;

            const prepped_data = prepareData(dt.data);

            const payload = {
                community_uid: community.uid,
                member_id: community.member_id,
                id: community.member_id,
                user_uid: community.member.user_uid,
                all: dt.all,
                data: prepped_data
            }

           // authFetch('/member-types/upsert-many', res, res, "POST", {payload});
        }
    }

    function handleAddItem(name,purpose,emoji) {
        if(all.length>9) {
            return;
        }
        const temp_id = CUSTOM_NANOID();
        setAll([...all,temp_id])
        setData({...data, [temp_id]: {
                title: name,
                emoji: emoji,
                can_delete: true,
                emoji_field: 'emoji',
                title_field: 'name',
                subtitle: [
                    {
                        field: 'purpose',
                        text: purpose
                    },
                ]
            }})
    }

    function handleRemoveItem(id) {
        let nall = [...all];
        const index = nall.findIndex(x=> x === id);
        nall.splice(index,1);
        setAll(nall);

        let ndata = {...data};
        delete ndata[id];
        setData(ndata);
    }

    function handleEditItem(id, {field,value}) {
        let ndata = {...data};
        if(field==='title') {
            ndata[id].title = value;
        } else if(field==='emoji') {
            ndata[id].emoji = value;
        } else {
            const index = ndata[id].subtitle.findIndex(x=>x.field===field);
            ndata[id].subtitle[index].text = value;
        }
        setData(ndata);
    }

    return <div className="grid grid-cols-2 gap-4">
        <SimpleEditList onEditItem={handleEditItem} onDelete={handleRemoveItem} items={all.map(id=>{
            return {
                id,
                ...data[id]
            }
        })} title="Teams" action={{icon:<PlusIcon />,text:'Custom',onClick:()=>handleAddItem('Custom Team','This teams purpose is to..','')}} />
        <SearchableSuggestions tabs={tabs} suggestions={convertArr(teams_suggestions)} onSelect={(it)=>{
            handleAddItem(it.title,it.subtitle[0].text,it.emoji)
        }} init_selected={Object.values(data).map(dt=>dt.title)} />
    </div>
}