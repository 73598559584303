import React, {useState} from 'react';
import {ActionWithIcon, Button, RichSearch, Toggle} from "../core";
import {QuillIcon} from "../../icons/custom/Quill";
import {PaperAirplaneIcon, PlusCircleIcon} from "@heroicons/react/solid";
import {EntityInfo} from "../../utilities/localstorage/entity-localstorage";
import {GroupDisplayIcon} from "../../utilities/groups";
import styled from "styled-components";
import {NewTooltip} from "../reorganize/new-tooltip";

function ToItem({data, editing, field, email}) {
    let label = ``;
    let meta = ``;
    let icon = null;
    if (field === 'email') {
        meta = `${email}`;
    } else {
        label = data.name;
        meta = data.count ? `(${data.count})` : null;
        icon = data.type === 'groups' ? <div>
            <GroupDisplayIcon emoji={data.emoji} profile_picture={data.profile_picture}
                              profile_picture_color={data.profile_picture_color} color={data.color}
                              name={data.name}/>
        </div> : null;
    }
    return <div className={`flex m-0.5 items-center space-x-1.5 py-0.5 pl-1.5 pr-2 rounded-md bg-gray-100`}>
        {label && <div className="text-sm font-semibold text-gray-700">{label}</div>}
        {meta && <div className="text-xs text-gray-500">{meta}</div>}
    </div>
}

const AddIcon = styled.div`
  > svg {
    height: 20px;
  }
`;

const FormatToField = styled.div`
  div[class$="-container"] {
    padding-bottom: 2px;
    margin-top: -6px;
    min-height: 30px !important;
  }

  div[class$="-control"] {
    border: none;
    cursor: pointer;
    background: transparent !important;
    padding: 0;
    box-shadow: none !important;
    min-height: 30px !important;
  }

  div[class$="-IndicatorsContainer"] > div {
    padding-top: 4px !important;
    padding-bottom: 4px !important;
  }

  div[class$="-ValueContainer"] {
    padding: 0 !important;
  }
`;

function MultiSelectItem({value,autoFocus,disabled=false,raw,community,updateParent}) {

    const search = <RichSearch autoFocus={autoFocus} can_change={!disabled} isClearable={false} style={"minimal"} isMulti allowed={{members: null, roles: null}}
                               persistent searchable={{members: true, roles: true}}
                               context={{community_uid: community.uid}} onSelect={(new_val) => {
        if (!new_val) {
            updateParent([]);
        } else {
            let new_to = [];
            new_val.forEach(v => {
                const is_existing = !v._type;
                if (is_existing) {
                    const vsplit = v.value.split('-');
                    const existing_item = raw.filter(a => a._type === vsplit[0] && a.value === vsplit[1])[0]
                    if (existing_item) {
                        new_to.push(existing_item);
                    }
                } else {
                    let nv = {...v};
                    nv.name = nv.label;
                    new_to.push(nv);
                }
            });
            updateParent(new_to);
        }
    }} value={value} placeholder=""/>;
if(disabled) {
    return <NewTooltip usePortal={false} message="You can't send group messages to anyone, but you can CC others">
        <div className="">
        {search}
    </div>
    </NewTooltip>
}
    return search;
}

export function ComposerHeader({
                                   can_change_to,
                                   can_change_cc,
                                   community,
                                   setCC,
                                   setTo,
    can_show_cc = true,
                                   cc = [],
                                   to = [],
                                   editing = ''
                               }) {
const [show_cc,setShowCC] = useState(cc.length>0);

    const to_value = !to ? [] : to.map(it => {
        const meta = it.count && it.count > 1 ? it.count : "";
        return {
            label: it.name,
            popup: !meta ? null : {
                type: it._type,
                id: it.value,
                cid: community.uid,
                data: {}
            },
            meta: meta,
            image: it.image ? `${it.image}_small?alt=media` : "",
            value: `${it._type}-${it.value}`
        }
    });

    const cc_value = !cc ? [] : cc.map(it => {
        const meta = it.count && it.count > 1 ? it.count : "";
        return {
            label: it.name,
            popup: !meta ? null : {
                type: it._type,
                id: it.value,
                cid: community.uid,
                data: {}
            },
            meta: meta,
            image: it.image ? `${it.image}_small?alt=media` : "",
            value: `${it._type}-${it.value}`
        }
    });

    return <div className={`pt-3 pb-1 px-4`}>

        <div className="flex">
            <div className="text-sm w-10  flex-none pt-1 text-gray-500">To:</div>
            <div className="flex-grow overflow-y-auto " style={{maxHeight: '3.8rem'}}>
                <MultiSelectItem autoFocus={to.length===0} disabled={!can_change_to} community={community} value={to_value} raw={to} updateParent={setTo} />
            </div>
            {!show_cc&&<div className="flex-none flex items-center pl-4" style={{height:'30px'}}>
                <ActionWithIcon text="Add CC" onClick={()=>setShowCC(true)}/>
            </div>}
        </div>

        {show_cc&&<div className="flex">
            <div className="text-sm w-10  flex-none pt-1 text-gray-500">CC:</div>
            <div className="flex-grow overflow-y-auto " style={{maxHeight: '3.8rem'}}>
                <MultiSelectItem disabled={!can_change_cc} community={community} value={cc_value} raw={cc} updateParent={setCC} />
            </div>
        </div>}

    </div>
}