import React, {useContext, useState} from 'react';
import {PinboardList} from "../../../../library/components/pinboard/pinboard";
import {CommunityContext} from "../../community-context";
import {authFetch} from "../../../../config/network";
import {GlobalContext} from "../../../global/global-context";
import {AddPinModal} from "../../group/add-pin-modal";

export function HomePinboard() {
const community = useContext(CommunityContext);
const global = useContext(GlobalContext);
const [modal,setModal] = useState(null);
const [modal_data,setModalData] = useState(null);

    function addPin(pin_id, pin_data, cb) {

        const payload = {
            member_id: community.member_id,
            community_uid: community.uid,
            pin_id,
            pin_data
        };

        const res = () => {
            setTimeout(() => {
                if (cb) {
                    cb();
                }
            }, 500);
            setModal(null)
            global.addToast({text: 'Pin added', intent: 'success'});
        };

        const err = () => {
            global.addToast({text: 'Pin could not be added', intent: 'danger'});
        };

        authFetch("/communities/add-pin", res, err, "POST", {payload});
    }

    function editPin(pin_id, pin_data, cb) {

        const payload = {
            member_id: community.member_id,
            community_uid: community.uid,
            pin_id,
            pin_data
        };

        const res = () => {
            setTimeout(() => {
                if (cb) {
                    cb();
                }
            }, 500);
            setModal(null);
            setModalData(null);
            global.addToast({text: 'Pin updated', intent: 'success'});
        };

        const err = () => {
            global.addToast({text: 'Pin could not be updated', intent: 'danger'});
        };

        authFetch("/communities/edit-pin", res, err, "POST", {payload});
    }

    function setPins(pin_ids, cb) {

        const payload = {
            community_uid: community.uid,
            member_id: community.member_id,
            pin_ids: pin_ids
        };

        const res = () => {
            setTimeout(() => {
                if (cb) {
                    cb();
                }
            }, 500);
            global.addToast({text: 'Pins updated', intent: 'success'});
        };

        const err = () => {
            global.addToast({text: 'Pins could not be updated', intent: 'danger'});
        };

        authFetch("/communities/set-pins", res, err, "POST", {payload});
    }

    function removePin(pin_id, cb) {

        const payload = {
            community_uid: community.uid,
            member_id: community.member_id,
            pin_id,
        };

        const res = () => {
            setTimeout(() => {
                if (cb) {
                    cb();
                }
            }, 500);
            global.addToast({text: 'Pin removed', intent: 'success'});
        };

        const err = () => {
            global.addToast({text: 'Pin could not be removed', intent: 'danger'});
        };

        authFetch("/communities/remove-pin", res, err, "POST", {payload});
    }

    const data = community.data;

    let rpins = data.pins ? data.pins : [];
    let rpins_data = data.pins_data ? data.pins_data : {};
    let pins = [];

    return <div>
        <PinboardList data_obj={{pins:rpins,pins_data:rpins_data}} pins={rpins} pin_data={rpins_data} removePin={removePin}
                      setPins={setPins} goToPanel={()=>setModal('add-pin')}
                      onEditPin={(id)=>{
                          setModalData(rpins_data[id]);
                          setModal('edit-pin');
                      }}
                      context={{
                          community_uid: community.uid,
                          member: community.member
                      }}/>

        {modal === 'add-pin' && <AddPinModal
            pins={rpins}
            title="Pin a link to the homepage"
            onClose={() => setModal(null)}
            onConfirm={addPin} pins_data={rpins_data}/>}

        {modal === 'edit-pin' && <AddPinModal
            pins={rpins}
            onClose={() => setModal(null)}
            onConfirm={editPin} title="Edit pin" confirm_text={"Save changes"} init_data={modal_data} pins_data={rpins_data}/>}
    </div>
}