import React from "react";
import styled from "styled-components";
import {EntityPreview} from "../entity-preview";
import {PCMeta} from "./meta";
import {member_profile} from "./examples/member";
import {PCCover} from "./cover";
import {PCBio} from "./bio";
import {Button, EntityIcon} from "../../core";
import RoleIcon from "../../old/role-icon";
import {Link} from "react-router-dom";
import {
    DotsHorizontalIcon, GlobeAltIcon,
    HeartIcon,
    InformationCircleIcon,
    LightningBoltIcon, OfficeBuildingIcon,
    UsersIcon
} from "@heroicons/react/solid";
import {EntityInfo} from "../../../utilities/localstorage/entity-localstorage";
import {ArchiveIcon} from "@heroicons/react/outline";

const Card = styled.div`
  width: 100%;
  background: #fff;
`;

const Message = styled.div`

`;

const MainActions = styled.div`
min-height: 2rem;
  display: flex;
  justify-content: ${props => props.left_align ? 'flex-start' : 'flex-end'};

  > div {
    margin-left: ${props => props.left_align ? '' : '10px'};
    margin-right: ${props => props.left_align ? '10px' : ''};
    max-width: 220px;
  }
`;

const Subtitle = styled.div`
  font-size: 14px;
  /*
  > div:not(:last-child):after {
      
  content: '·';
  font-size: 22px;
  padding: 0 6px;
  height: 16px;
  display: inline-block;
   line-height: 14px;
  color: #7f849c;
  vertical-align: text-bottom;
  }
  
   */
`;

function PCSubtitle(subtitle) {
    if (subtitle.length === 0) {
        return null;
    }
    return <Subtitle className={"flex -mt-px flex-wrap"}>
        {subtitle.map((sub, i) => <div className="text-gray-500 flex items-center mr-3 mb-1" key={i}>
            {sub}
        </div>)}
    </Subtitle>
}

function PCEmoji(emoji) {
    if (emoji === '') {
        return null;
    } else if (emoji.indexOf("?alt=media") !== -1) {
        return <div >
            <img alt="profile-picture" src={emoji} className="rounded-md border-3 bg-white border-white"
                    style={{height: '70px', width: '70px'}}/>
        </div>
    }
    return emoji;
}

const Item = styled.div`
  display: inline-flex;
  border-radius: 6px;
  max-width: 220px;
  cursor: pointer;
  padding: 4px 10px 4px 4px;
`;

const ModBox = styled.div`
  width: 24px;
  height: 24px;
  min-width: 24px;

  display: flex;
  align-items: center;
  justify-content: center;
`;

// member_profile, group_profile,  community_profile

const Content = styled.div`
  margin-top: ${props => props.mini_margin ? '6px' : '2.2rem'};
`;

function RolesTag({handle, roles}) {
    const len = roles.length;
    return <>
        {roles.map((role, i) => {
            return <React.Fragment key={i}><Link style={{textDecoration: 'none'}}
                                                 className="text-gray-900 text-sm font-medium pl-2 flex items-center"
                                                 key={i}
                                                 link={`/${handle}/role/${role.id}`}>{role.name}</Link>{(i === 0 && len === 1 ? "" : (i + 1) === len ? "" : <>,&nbsp;</>)}
            </React.Fragment>
        })}
    </>
}

function RichMetaItem(props) {
    const {items, type, component, onClick, context} = props;
    if (items) {
        if (type === 'roles') {
            return <Item className='hover-bkg-2'>
                <ModBox>
                    <RoleIcon fill="#ff6b00"/>
                </ModBox>
                <RolesTag handle={context.community_handle} roles={items}/>
            </Item>;
        } else if (type === 'moderators') {
            return <div>
                <EntityPreview community_uid={context.community_uid} onClick={onClick}
                               community_handle={context.community_handle}
                               type='members' sec={props}/>
            </div>;
        } else {
            return null;
        }

    } else if (component) {
        return <div>
            {component}
        </div>;
    } else {
        return null;
    }
}

function getPastRolesMatchText(arr) {
    const len = arr.length;
    if(len===1) {
        return `You've both been ${arr[0].name}`
    } else if(len===2) {
        return `You've both been ${arr[0].name} and ${arr[1].name}`
    } else {
        return `You've both held ${arr[0].name} and ${len-1} other roles`
    }
}

function InterestName({data}) {
    if(!data) {
        return "-"
    }
    return <span>
        {data.name}
    </span>
}

function InterestItem({community_uid,id}) {
    return <EntityInfo key={`interest-${id}`} community_uid={community_uid} type="interest" id={id}><InterestName /></EntityInfo>
}

function renderSharedInterests(arr,community_uid) {
    const len = arr.length;
    if(len===1) {
        return <span>You're both interested in <InterestItem key={`interest-${arr[0]}`} community_uid={community_uid} id={arr[0]} /></span>
    } else if(len===2) {
        return <span>You're both interested in <InterestItem key={`interest-${arr[0]}`} community_uid={community_uid} id={arr[0]} /> and <InterestItem  key={`interest-${arr[1]}`} community_uid={community_uid} id={arr[1]} /></span>
    } else {
        return <span>You have ${len} shared interests including <InterestItem key={`interest-${arr[0]}`} community_uid={community_uid} id={arr[1]} /></span>
    }
}

const highlight_types = {
  'groups-count': (value)=>{
   return {
       text: `You're in ${value} groups together`,
       icon: <UsersIcon />
   }
  },
    'past-roles-match': (value)=>{
        return {
            text: getPastRolesMatchText(value),
            icon: <LightningBoltIcon />
        }
    },
    'shared-interests': (value,community_uid) => {
        return {
            text: renderSharedInterests(value,community_uid),
            icon: <HeartIcon />
        }
    },
    'state-country': (value)=>{
        return {
            text: `You both live in ${value}`,
            icon: <GlobeAltIcon />
        }
    },
    'city-country': (value)=>{
        return {
            text: `You both live in ${value}`,
            icon: <OfficeBuildingIcon />
        }
    },
    'custom-field-match': (value) => {
        return {
            text: `Both of you have ${value.field_label}: ${value.value_label}`,
            icon: <InformationCircleIcon />
        }
    },
    'country': (value)=>{
        return {
            text: `You both live in ${value}`,
            icon: <GlobeAltIcon />
        }
    },
    'my-profile': ()=>{
      return null;
    }
};

function PCHighlight({highlight,context}) {
    if(!highlight) {
        return null;
    }
    const data = highlight_types[highlight.type](highlight.value,context.community_uid);
    if(!data) {
        return null;
    }
    return <div className="inline-flex items-center flex-wrap pt-2 space-x-1.5 pb-1.5 text-sm text-gray-600">
        <div className="h-svg-4 text-teal-700">
            {data.icon}
        </div>
        <div>{data.text}</div>
    </div>
}

function PCStats({stats=[]}) {
    if(!stats||stats.length===0) {
        return null;
    }
    return <div className="flex flex-wrap pt-2 pb-1 space-x-3">
        {stats.map((it,k)=>{
            return <div style={{fontSize:'14px'}} key={k}>
                <span className="text-gray-800 font-semibold">{it.number}</span>
                <span className="pl-1 text-gray-500">{it.label}</span>
            </div>
        })}
    </div>
}

function getMarginTop(type) {
    const width = window.outerWidth;
    if (type === 'community') {
        return ''
    } else if (type === 'member') {
        if (width < 640) {
            return 'mt-3'
        }
        return 'mt-5'
    } else if(type==='group'||type==='interest') {
        return "mt-2";
    } else {
        if (width < 640) {
            return 'mt-3'
        }
        return 'mt-5'
    }
}
/*
 <div className={`${data.type === 'group' ? "mt-6" : "mt-14"}`}>
                    <RichMetaItem context={context} {...data.rich_meta.primary} />
                    <RichMetaItem context={context} {...data.rich_meta.secondary} />
                    <RichMetaItem context={context} {...data.rich_meta.tertiary} />
                </div>
 */
function Note({message,intent,type}) {
    const bg = `bg-gray-600`;
    const text = `text-white`;
    return <div className={`${bg} py-1.5 px-4 flex space-x-2 text-white`}>
        <div className="h-svg-5">
            <ArchiveIcon />
        </div>
        <div className={`${text} text-sm`}>
            {message}
        </div>
    </div>
}
export function ProfileCard({data = {...member_profile}, editing = false, context = {}, actions, features = {}}) {

    //  <PCStats stats={data.main.stats}/>
    //    <Button icon={<DotsHorizontalIcon />} />
    const layout = data.type==='interest' || data.type==='group' ? 1 : 0;
    return <Card className="relative mx-auto">
        {data.note&&<Note {...data.note} />}
        <PCCover context={context} setProfilePicture={actions.setProfilePicture} data={data}
                 setCoverPhoto={actions.setCoverPhoto} features={features}/>
        <div className="relative px-4 pt-3">
            <MainActions className={`absolute top-2.5 right-4`}>
                {data.actions.map((act, i) => <div key={i}>
                    {act}
                </div>)}
            </MainActions>
            <div className="flex w-full pr-12">
                <Content className="w-full" mini_margin={layout===1}>
                    <div style={{marginRight:"6rem",marginTop: (layout===1 && data.appearance.profile_picture ? "14px" : "0")}}>
                        {(layout===1) && !data.appearance.emoji && !data.appearance.profile_picture &&
                        <div className="-mt-10"><EntityIcon shadow color={data.appearance.block_color} size="xl"
                                                                 initial={data.appearance.block_initial}/></div>}

                        {data.appearance.emoji && !data.appearance.profile_picture && <div
                            className={` text-6xl max-w-xs ${layout===1 ? "-mt-14 " : "mb-1"}`}>{PCEmoji(data.appearance.emoji)}</div>}

                        <div className={` items-center ${getMarginTop(data.type)} flex space-x-2`}>
                            {data.main.prefix && <div>{data.main.prefix}</div>}
                            <div className="font-black text-gray-800 "
                                 style={{fontSize: '20px'}}>{data.main.title}</div>
                            {data.main.suffix && <div>{data.main.suffix}</div>}
                        </div>
                    </div>
                    {PCSubtitle(data.main.subtitle)}
                    <PCBio bio={data.main.description} changeBio={actions.updateBio} editing={editing}/>
                </Content>

            </div>
            <div className="pr-8">
                <PCMeta meta={data.main.meta}/>
                <PCStats stats={data.stats} />
                <PCHighlight highlight={data.main.highlight} context={context} />
            </div>
            <Message/>
        </div>
        {features.top_right_tag && <div className="absolute z-10 top-0 right-0 pr-2.5 pt-2.5">
            {features.top_right_tag}
        </div>}
    </Card>
}