import React, {useContext, useEffect, useState} from 'react';
import {CADEditCard, CADGrid} from "../shared";
import {BasicSelect} from "../../../../../../library/components/core/basic-select";
import {Button, Field, Input} from "../../../../../../library/components/core";
import {FirebaseDB} from "../../../../../../config/setup-firestore";
import {DraftComposer} from "../../../../posts/compose-reply/draft-composer";
import {convertFromRaw, convertToRaw, EditorState} from "draft-js";
import {CommunityContext} from "../../../../community-context";
import {composer_merge_tags} from "../../../../../../library/components/composer/merge-tags";
import {copyTextToClipboard} from "../../../../../../library/utilities/general/copy-to-clipboard";
import {authFetch} from "../../../../../../config/network";

const email_templates = [
    {
        value: 'new-member-invite',
        label: 'Member Invite',
        desc: '',
        fallback: {
            internal_name: 'Member Invite',
            subject: "Join {{community.data.name}} on Orange",
            content: `{"blocks":[{"key":"5him4","text":"{{sender.name}} ({{sender.account_email}}) invited you to join {{community.name}} on Orange.","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[{"offset":0,"length":15,"key":0},{"offset":17,"length":24,"key":1},{"offset":63,"length":18,"key":2}],"data":{}}],"entityMap":{"0":{"type":"{{mention","mutability":"IMMUTABLE","data":{"mention":{"id":"{{sender.name}}","name":"{{sender.name}}","example":""}}},"1":{"type":"{{mention","mutability":"IMMUTABLE","data":{"mention":{"id":"{{sender.account_email}}","name":"{{sender.account_email}}","example":""}}},"2":{"type":"{{mention","mutability":"IMMUTABLE","data":{"mention":{"id":"{{community.name}}","name":"{{community.name}}","example":""}}}}}`
        }
    },
    {
        value: 'data-check',
        label: 'Member Data Check',
        desc: '',
        fallback: {
            internal_name: 'Member Data Check',
            subject: "Check your Member Profile",
            content: `{"blocks":[{"key":"5him4","text":"Hi {{member.first_name}},","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[{"offset":3,"length":21,"key":0}],"data":{}},{"key":"3bfv2","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"6tjg4","text":"We're missing some information in your Member Profile. Please take a moment to update your profile.","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{"0":{"type":"{{mention","mutability":"IMMUTABLE","data":{"mention":{"id":"{{member.first_name}}","name":"{{member.first_name}}","example":""}}}}}`
        }
    }
    /*
    {
        value: 'member-made-admin',
        label: 'Member made Admin',
        desc: ''
    },
    {
        value: 'member-no-longer-admin',
        label: 'Member removed as Admin',
        desc: ''
    },
    {
        value: 'application-approved',
        label: 'Join Application Approved',
        desc: ''
    },
    {
        value: 'application-denied',
        label: 'Join Application Denied',
        desc: ''
    },
    {
        value: 'application-received',
        label: 'Join Application Received',
        desc: ''
    },
     */
];

/*
How it works
these are system email templates under community_content > community_uid > email_templates
each template has a type, either one of the above or custom
each has an internal name
each has a subject
each has a body which is a raw Draft JS object that's stringified

in this component, the admin can select a template and then edit it
 */

function filterMergeTags(message_type, mt) {
    let a = [];

    mt.forEach(sec => {
        if (sec.scope === '*' || sec.scope === message_type) {
            a.push(sec);
        }
    });

    return a;
}

function RichTextField({updateParentContent,enable_copy,prefill=""}) {
    const [attachments, setAttachments] = useState({});
    const [editorState, setEditorState] = useState(null);
     useEffect(function () {
        if(editorState) {
            updateParentContent(JSON.stringify(convertToRaw(editorState.getCurrentContent())));
        }
    }, [editorState]);

    const available_merge_tags = filterMergeTags("direct", composer_merge_tags);

    return <div>
        <DraftComposer merge_tags={available_merge_tags} content_state={prefill} show_toolbar={false} type="edit-text" update={(es, att) => {
            setEditorState(es);
            setAttachments(att);
        }}/>
        {enable_copy&&<div>
        <Button onClick={()=>{
            copyTextToClipboard(JSON.stringify(convertToRaw(editorState.getCurrentContent())), ()=>{
                alert("done!")
            });
        }} text="Copy" />
        </div>}
    </div>
}

export async function getTemplateData(community_uid, template_id) {
    const data = await loadTemplateData(community_uid, template_id);

    if(data) {
        return data;
    } else {
        // return fallback of item based on id
        const item = email_templates.filter(it=>it.value===template_id)[0];

        if(item) {
            return item.fallback;
        } else {
            return {
                internal_name: '',
                subject: '',
                content: ''
            }
        }
    }
}

async function loadTemplateData(community_uid,template_id) {
    console.log("loadTemplateData",community_uid,template_id)
    if(!template_id) {
        return null;
    }
    if(!community_uid) {
        return null;
    }
    return await FirebaseDB
        .collection('community_content')
        .doc(community_uid)
        .collection('email_templates')
        .doc(template_id)
        .get()
        .then(doc=> {
            if(!doc.exists) {
                return null;
            }
            return {
                ...doc.data(),
                id: doc.id
            }
        })
}

export async function api_editEmailTemplate(community,id,data) {
    return await new Promise((resolve, reject) => {
        const res = (r) => {
            resolve(r);
        };
        const payload = {
            community_uid: community.uid,
            member_id: community.member_id,
            id,
            data
        };
        authFetch("/communities/edit-email-template", res, res, "POST", {payload});
    })
}

function parsePrefill(data) {
    if(!data) {
        return null;
    }
    if(!data.content) {
        return null;
    }
    try {
        return convertFromRaw(JSON.parse(data.content));
    } catch (e) {
        return null;
    }

}

export function CADEmailDesigner(props) {
    const community = useContext(CommunityContext);
const [selected,setSelected] = useState(email_templates[0].value);
const [changed,setChanged] = useState(false);
const [loading,setLoading] = useState(false);
const [data,setEmailData] = useState(null);
/*
<CADEditCard title="Header">
                Header cannot be edited yet.
            </CADEditCard>
             <CADEditCard title="Footer">
                logo
            </CADEditCard>
 */

    useEffect(function () {
        if(!selected) {
            return;
        }
        loadTemplate()
    }, [selected])

    function loadTemplate() {
        setEmailData(null);

// load template
        console.log("loadTemplate",community.uid,selected)
        loadTemplateData(community.uid,selected)
            .then(template => {
                console.log("LOAD TEMPLATE DATA RESULT",template)
                if(template) {
                    setEmailData({...template})
                } else {
                    // get template from selected ID
                    const fallback_data = email_templates.filter(it=>it.value===selected)[0];
                    setEmailData({...fallback_data.fallback});
                }
            })

    }

    function handleSave() {
        // save
        setLoading(true);

        api_editEmailTemplate(community,selected,data)
            .then(()=>{
                setLoading(false);
                setChanged(false);
            })
    }

    function handleChange(key,value) {
        let has_changed = false;

        if(data) {
            if(data[key]!==value) {
                has_changed = true;
            }
        }

        setEmailData({...(data||{}),[key]:value});

        if(has_changed) {
            setChanged(true);
        }
    }

    const select_value = email_templates.filter(it=>it.value===selected)[0] || null;

    return <CADGrid>
        <div className="space-y-2">
            <CADEditCard title="Content">
                <BasicSelect value={select_value} placeholder="Select Email Template"
                             onChange={(v) => {
                                 setSelected(v.value);
                             }} options={email_templates}/>

                {data&&<div className="grid gap-4">

              <Field label="Internal Name">
                    <Input value={data?.internal_name} onChange={v=>handleChange('internal_name',v)} />
                </Field>

                <Field label="Subject">
                    <Input value={data?.subject} onChange={v=>handleChange('subject',v)} />
                </Field>

                <Field label="Content">

                    <RichTextField enable_copy={false} prefill={parsePrefill(data)} updateParentContent={raw_content=>{
                        handleChange('content',raw_content);
                    }} />

                </Field>

                    <div className="flex">
                        <div className="flex-grow"></div>
                        <div>
                            <Button loading={loading} disabled={!changed} intent='success' onClick={()=>{
                                handleSave()
                            }} text='Save Changes' />
                        </div>
                    </div>
                </div>}
            </CADEditCard>
        </div>
        <div>

        </div>
    </CADGrid>
}