import React from 'react';
import {FirebaseDB} from "../../../config/setup-firestore";
import {getRandomColor} from "../get-random-color";
import {useEffect, useState} from "react";
import {DEFAULT_USER_PHOTO} from "../../../config/defaults";
import {reference_interests} from "../../../app/data-references";

export function getEntityColor(ctx, handle) {
    if (!handle) {
        return '#2a2a2a';
    }
    const item = localStorage.getItem(`${ctx}-${handle}-color`);
    if (item) {
        return JSON.parse(item);
    } else {
        let new_color = getRandomColor();
        localStorage.setItem(`${ctx}-${handle}-color`, JSON.stringify(new_color));
        return new_color;
    }
}

const entity_types = {
    'member': {
        type: 'members',
        collection: 'community_members',
        sub_collection: 'members'
    },
    'members': {
        type: 'members',
        collection: 'community_members',
        sub_collection: 'members'
    },
    'user': {
        type: 'users',
        collection: 'users'
    },
    'users': {
        type: 'users',
        collection: 'users'
    },
    'community': {
        type: 'communities',
        collection: 'communities'
    },
    'communities': {
        type: 'communities',
        collection: 'communities'
    },
    'role': {
        type: 'roles',
        collection: 'community_entities',
        sub_collection: 'roles'
    },
    'roles': {
        type: 'roles',
        collection: 'community_entities',
        sub_collection: 'roles'
    },
    'group': {
        type: 'groups',
        collection: 'community_entities',
        sub_collection: 'groups'
    },
    'groups': {
        type: 'groups',
        collection: 'community_entities',
        sub_collection: 'groups'
    },
    'list': {
        type: 'lists',
        collection: 'community_entities',
        sub_collection: 'lists'
    },
    'lists': {
        type: 'lists',
        collection: 'community_entities',
        sub_collection: 'lists'
    },
    'team': {
        type: 'teams',
        collection: 'community_entities',
        sub_collection: 'groups'
    },
    'teams': {
        type: 'teams',
        collection: 'community_entities',
        sub_collection: 'groups'
    },
    'event': {
        type: 'events',
        collection: 'community_entities',
        sub_collection: 'events'
    },
    'folder': {
        type: 'folders',
        collection: 'community_entities',
        sub_collection: 'folders'
    },
    'folders': {
        type: 'folders',
        collection: 'community_entities',
        sub_collection: 'folders'
    },
    'member_type': {
        type: 'member_types',
        collection: 'community_members',
        sub_collection: 'member_types'
    },
    'member_types': {
        type: 'member_types',
        collection: 'community_members',
        sub_collection: 'member_types'
    }
};

function getContext(type, community_uid) {
    if (community_uid) {
        return community_uid;
    } else {
        return 'global';
    }
}

export function getMiniEntity(type, id, cb, {community_uid}) {
    const data = entity_types[type];
    const ls_entity = getEntityFromLocalStorage(getContext(type, community_uid), type, id);

    // if not, get from db
    if (ls_entity !== null) {
        return ls_entity;
    }

    let ref;

    if (data.sub_collection) {
        ref = FirebaseDB
            .collection(data.collection)
            .doc(community_uid)
            .collection(data.sub_collection)
            .doc(id)
            .get();
    } else {
        ref = FirebaseDB
            .collection(data.collection)
            .doc(id)
            .get();
    }

    ref.then((doc) => {
        const data = doc.data();

        if (!doc.exists) {
            return;
        }

        const lse = convertEntityForLocalStorage(getContext(type, community_uid), type, data);

        saveEntityToLocalStorage(getContext(type, community_uid), type, id, lse);

        return lse;
    });

    return {
        name: type,
        bkg_color: '',
        image: '',
        handle: '',
        loading: true
    }
}

export function getSmallEntity(context = 'global', type = 'member', id = '', parent = 'community_entities') {
    // see if exists and valid in LS

    const ls_entity = getEntityFromLocalStorage(context, type, id);

    // if not, get from db
    if (ls_entity !== null) {
        return ls_entity;
    }
    // todo handle callbacks / rerender once we have member

    // member id is community_uid-user_uid

    FirebaseDB
        .collection(parent)
        .doc(context)
        .collection(`${type}s`)
        .doc(id)
        .get()
        .then((doc) => {
            const data = doc.data();

            if (!doc.exists) {
                return;
            }

            const lse = convertEntityForLocalStorage(context, type, data);

            saveEntityToLocalStorage(context, type, id, lse);

            return lse;
        });

    return {
        name: type,
        bkg_color: '',
        image: '',
        handle: '',
        loading: true
    }
}

export function saveEntityToLocalStorage(context = 'global', type = 'member', id = '', data = {}) {
    return localStorage.setItem(`${context}-${type}-${id}`, JSON.stringify(data));
}

export function getFullField(prop) {
    switch (prop) {
        case 'city' : {
            return `address.${prop}`
        }
        case 'country' : {
            return `address.${prop}`
        }
        case 'zip_code' : {
            return `address.${prop}`
        }
        default : {
            return prop;
        }
    }
}

export function MemberTypeCounter({type, community_uid, id}) {
    const [count, setCount] = useState(0);
    const [loading, setLoading] = useState(true);
    useEffect(function () {
        FirebaseDB
            .collection('community_members')
            .doc(community_uid)
            .collection('members')
            .where('archived', '==', false)
            .where('suspended', '==', false)
            .where('member_type', '==', id)
            .limit(100)
            .get()
            .then((snap) => {
                setCount(snap.size);
                setLoading(false);
            });
    }, [id]);
    return loading ? null : ` (${count}${count > 99 ? '+' : ''})`;
}


export function FilterCounter({type, filter, community_uid, id}) {
    const [count, setCount] = useState(0);
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        const full_field = getFullField(type);
        if (filter.operator === 'starts_with') {
            FirebaseDB
                .collection('community_members')
                .doc(community_uid)
                .collection('members')
                .where('archived', '==', false)
                .where('suspended', '==', false)
                .orderBy(full_field)
                .startAt(filter.value)
                .endAt(filter.value + "\uf8ff")
                .limit(100)
                .get()
                .then((snap) => {
                    setCount(snap.size);
                    setLoading(false);
                });
        } else if (filter.operator === '==') {
            FirebaseDB
                .collection('community_members')
                .doc(community_uid)
                .collection('members')
                .where('archived', '==', false)
                .where('suspended', '==', false)
                .where(full_field, '==', filter.value)
                .limit(100)
                .get()
                .then((snap) => {
                    setCount(snap.size);
                    setLoading(false);
                });
        }

    }, [id]);
    return loading ? null : `${count}${count > 99 ? '+' : ''} member${count !== 1 ? 's' : ''}`;
}

export function getDBInfoFromType(type) {
    switch (type) {
        case 'users':
            return {
                collection: 'users'
            };
        case 'user':
            return {
                collection: 'users'
            };
        case 'members':
            return {
                collection: 'community_members',
                sub_collection: 'members'
            };
        case 'member':
            return {
                collection: 'community_members',
                sub_collection: 'members'
            };
        case 'series':
            return {
                collection: 'community_content',
                sub_collection: 'series'
            };
        case 'interests':
            return {
                collection: 'communities',
                sub_collection: 'interests'
            };
        case 'interest':
            return {
                collection: 'community_members',
                sub_collection: 'interests'
            };
        case 'communities':
            return {
                collection: 'communities'
            };
        case 'filters':
            return {
                collection: 'community_entities',
                sub_collection: 'collections'
            };
        case 'segment':
            return {
                collection: 'community_entities',
                sub_collection: 'collections'
            };
        case 'roles':
            return {
                collection: 'community_entities',
                sub_collection: 'roles'
            };
        case 'role':
            return {
                collection: 'community_entities',
                sub_collection: 'roles'
            };
        case 'teams':
            return {
                collection: 'community_entities',
                sub_collection: 'groups'
            };
        case 'team':
            return {
                collection: 'community_entities',
                sub_collection: 'groups'
            };
        case 'lists':
            return {
                collection: 'community_entities',
                sub_collection: 'lists'
            };
        case 'folders':
            return {
                collection: 'community_entities',
                sub_collection: 'folders'
            };
        case 'groups':
            return {
                collection: 'community_entities',
                sub_collection: 'groups'
            };
        case 'group':
            return {
                collection: 'community_entities',
                sub_collection: 'groups'
            };
        case 'member_types':
            return {
                collection: 'community_members',
                sub_collection: 'member_types'
            };
        case 'member-type':
            return {
                collection: 'community_members',
                sub_collection: 'member_types'
            };
        case 'smart-view':
            return {
                collection: 'community_entities',
                sub_collection: 'smart_views'
            };
        default:
            return {}
    }
}


export function EntityInfo({type,children,extras,community_uid,id,refresh=false}) {

    // todo roles and teams just check local
    const db_info = getDBInfoFromType(type);
    const ls_entity = getEntityFromLocalStorage(getContext(type, community_uid), type, id, refresh);
    const [error,setError] = useState(null);
    const [data,setData] = useState(ls_entity);
    const [id_ref,setIDRef] = useState(id);

    useEffect(() => {
        if(id_ref!==id) {
            setData(ls_entity);
        }
        if(data===null&&(type==='interests'||type==='interest')&&id.startsWith('default_')) {
            const lse = convertEntityForLocalStorage(getContext(type, community_uid), type, reference_interests[id]);
            setData(lse)
            return;
        }
        if(!id||!db_info.sub_collection||!community_uid||typeof id !== 'string') {
            console.error('error!',id,community_uid,db_info)
        }else if((data===null||id_ref!==id)&&id&&db_info&&db_info.sub_collection&&community_uid&&id) {

            setIDRef(id);
            FirebaseDB
                .collection(db_info.collection)
                .doc(community_uid)
                .collection(db_info.sub_collection)
                .doc(id)
                .get()
                .then((doc) => {
                    if(!doc.exists) {
                        setError('not-found');
                    } else {
                        const lse = convertEntityForLocalStorage(getContext(type, community_uid), type, doc.data());
                        saveEntityToLocalStorage(getContext(type, community_uid), type, id, lse);
                        setData(lse);
                    }
                    return true;
                });
        } else if(data===null||id_ref!==id) {
            console.error('error!',id,id_ref,type,community_uid)
        }
        },[id,id_ref,community_uid,type,data]);

    if(error==='not-found') {
        return React.Children.map(children, child => {
            // checking isValidElement is the safe way and avoids a typescript error too
            if (React.isValidElement(child)) {
                return React.cloneElement(child, { data: data===null?{name:'Not found',id,extras}: {...data,id,extras} });
            }
        });
    }

    return React.Children.map(children, child => {
        // checking isValidElement is the safe way and avoids a typescript error too
        if (React.isValidElement(child)) {
            return React.cloneElement(child, { data: data===null?{name:'Loading..',id,extras}:{...data,id,extras} });
        }
    });
}

export function GlobalEntityInfo({type='communities',children,community_uid,id}) {

    const db_info = getDBInfoFromType(type);
    const ls_entity = getEntityFromLocalStorage(getContext(type, community_uid), type, id);
    const [data,setData] = useState(ls_entity);

    useEffect(() => {
        if(data===null) {
            FirebaseDB
                .collection(db_info.collection)
                .doc(id)
                .get()
                .then((doc) => {
                    const lse = convertEntityForLocalStorage(getContext(type, id), type, doc.data());
                    saveEntityToLocalStorage(getContext(type, id), type, id, lse);
                    setData(lse);
                    return true;
                });
        }
    },[id,community_uid,type]);

    return React.Children.map(children, child => {
        // checking isValidElement is the safe way and avoids a typescript error too
        if (React.isValidElement(child)) {
            return React.cloneElement(child, { data: data===null?{name:'Loading..'}:data });
        }
    });
}

export function convertEntityForLocalStorage(context, type = 'member', data, id = '') {
    if(data===undefined||data===null) {
        return {};
    }
    switch (type) {
        case "community":
            return {
                name: data.name,
                bkg_color: getEntityColor(context, data.handle),
                id,
                emoji: data.emoji,
                status: data.status,
                color: data.branding.color,
                handle: data.handle,
                image: data.profile_picture,
                updated_at: Date.now(),
                raw: data
            };
        case "series":
            return {
                name: data.name,
                id,
                updated_at: Date.now(),
                raw: data
            };
        case "communities":
            return {
                name: data.name,
                bkg_color: getEntityColor(context, data.handle),
                id,
                emoji: data.emoji,
                status: data.status,
                color: data.branding.color,
                handle: data.handle,
                image: data.profile_picture,
                updated_at: Date.now()
            };
        case "member":
            return {
                name: data.name,
                bkg_color: getEntityColor(context, data.handle),
                uid: data.uid,
                account_status: data.account_status ? data.account_status : "",
                account_email: data.account_email ? data.account_email : "",
                email: data.contact ? data.contact.email : data.email ? data.email : "",
                phone: data.contact ? data.contact.phone : data.phone ? data.phone : "",
                handle: data.handle,
                birthday: data.birthday,
                short_birthday: data.short_birthday,
                member_type: data.member_type,
                bio: data.about ? data.about.bio : data.bio,
                image: data.profile_picture,
                updated_at: Date.now()
            };
        case "filters":
            return {
                name: data.name,
                id: data.id,
                match: data.match,
                handle: data.handle,
                user_uids: data.user_uids,
                conditions: data.conditions,
                count: Object.keys(data.user_uids).length,
                image: '',
                updated_at: Date.now()
            };
        case "smart-view":
            return {
                name: data.name,
                id: data.id,
                emoji: data.emoji,
                type: data.type,
                layout: data.layout,
                updated_at: Date.now()
            };
        case "member_type":
            return {
                name: data.plural,
                bkg_color: data.color||"",
                count: data.count||0,
                handle: data.handle,
                emoji: data.emoji,
                updated_at: Date.now()
            };
        case "member_types":
            return {
                name: data.plural,
                bkg_color: data.color||"",
                count: data.count||0,
                handle: data.handle,
                emoji: data.emoji,
                updated_at: Date.now()
            };
        case "members":

            return {
                name: data.name,
                account_status: data.account_status ? data.account_status : "",
                account_email: data.account_email ? data.account_email : "",
                email: data.contact ? data.contact.email : data.email ? data.email : "",
                phone: data.contact ? data.contact.phone : data.phone ? data.phone : "",
                bkg_color: getEntityColor(context, data.handle),
                uid: data.uid,
                bio: data.about ? data.about.bio : data.bio,
                birthday: data.birthday,
                short_birthday: data.short_birthday,
                member_type: data.member_type,
                handle: data.handle,
                image: data.profile_picture,
                updated_at: Date.now()
            };
        case "role":
            return {
                name: data.name,
                email: data.assignee_data ? data.assignee_data.email : '',
                bkg_color: getEntityColor(),
                id: data.id,
                assignee_name: data.assignee_data.name,
                assignee_handle: data.assignee_data.handle,
                assignee_image: data.assignee_data.profile_picture ? data.assignee_data.profile_picture : DEFAULT_USER_PHOTO,
                image: data.assignee_data.profile_picture ? data.assignee_data.profile_picture : DEFAULT_USER_PHOTO,
                assignee_email: data.assignee_data.email,
                count: data.assignee===''?0:1,
                assignee: data.assignee,
                emoji: data.emoji,
                handle: data.handle,
                updated_at: Date.now()
            };
        case "roles":
            return {
                name: data.name,
                email: data.assignee_data ? data.assignee_data.email : '',
                bkg_color: getEntityColor(),
                assignee_name: data.assignee_data.name,
                assignee_handle: data.assignee_data.handle,
                assignee_image: data.assignee_data.profile_picture ? data.assignee_data.profile_picture : DEFAULT_USER_PHOTO,
                image: data.assignee_data.profile_picture ? data.assignee_data.profile_picture : DEFAULT_USER_PHOTO,
                assignee_email: data.assignee_data.email,
                id: data.id,
                count: data.assignee===''?0:1,
                emoji: data.emoji,
                assignee: data.assignee,
                handle: data.handle,
                updated_at: Date.now()
            };
        case "groups":
            return {
                name: data.name,
                email: data.power_ups.unaty__mailing_lists ? data.power_ups.unaty__mailing_lists.config.email_address : '',
                type: data.type,
                user_uids: data.user_uids,
                color: data.color ? data.color : "",
                profile_picture: data.profile_picture ? data.profile_picture : "",
                closed: data.closed,
                count: Object.keys(data.user_uids).length,
                handle: data.handle,
                updated_at: Date.now(),
                emoji: data.emoji
            };
        case "group":
            return {
                name: data.name,
                type: data.type,
                email: data.power_ups.unaty__mailing_lists ? data.power_ups.unaty__mailing_lists.config.email_address : '',
                closed: data.closed,
                user_uids: data.user_uids,
                color: data.color ? data.color : "",
                profile_picture: data.profile_picture ? data.profile_picture : "",
                count: Object.keys(data.user_uids).length,
                handle: data.handle,
                updated_at: Date.now(),
                emoji: data.emoji
            };
        case "interest":
            return {
                name: data.name,
                color: data.color ? data.color : "",
                profile_picture: data.profile_picture ? data.profile_picture : "",
                updated_at: Date.now(),
                emoji: data.emoji
            };
        case "interests":
            return {
                name: data.name,
                color: data.color ? data.color : "",
                profile_picture: data.profile_picture ? data.profile_picture : "",
                updated_at: Date.now(),
                emoji: data.emoji
            };
        case "teams":
            return {
                name: data.name,
                email: '',
                count: Object.keys(data.user_uids).length,
                handle: data.handle,
                user_uids: data.user_uids,
                updated_at: Date.now(),
                emoji: data.emoji
            };
        case "team":
            return {
                name: data.name,
                email: '',
                count: Object.keys(data.user_uids).length,
                handle: data.handle,
                user_uids: data.user_uids,
                updated_at: Date.now(),
                emoji: data.emoji
            };
        case "folder":
            return {
                name: data.name,
                emoji: data.emoji,
                id: data.id,
                updated_at: Date.now(),
                raw: data
            };
        case "folders":
            return {
                name: data.name,
                emoji: data.emoji,
                id: data.id,
                updated_at: Date.now(),
                raw: data
            };
        default : {
            return {
                bkg_color: getRandomColor(),
                updated_at: Date.now(),
                ...data
            };
        }
    }
}

function isInLastXMinutes(date, min) {
    const minutes = min * 60 * 1000;

    return ((new Date()) - date) < minutes;
}

// context = community_uid
export function getEntityFromLocalStorage(context = 'global', type = 'member', id = '', refresh=false) {
    const item = localStorage.getItem(`${context}-${type}-${id}`);

    if(refresh) {
        return null;
    }

    if (item) {
        const final = JSON.parse(item);
        if (isInLastXMinutes(final.updated_at, 30)) {
            return final;
        } else {
            return null;
        }
    } else {
        return null;
    }
}

export function removeEntityFromLocalStorage(context = 'global', type = 'member', id = '') {
    const item = localStorage.getItem(`${context}-${type}-${id}`);
    if (item) {
        return localStorage.removeItem(`${context}-${type}-${id}`);
    } else {
        return true;
    }
}

export function clearLocalStorage() {
    return localStorage.clear();
}