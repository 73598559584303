import {FirebaseDB} from "../../../../config/setup-firestore";
import {
    convertEntityForLocalStorage,
    saveEntityToLocalStorage
} from "../../../utilities/localstorage/entity-localstorage";

function buildCommunityEntity(id, obj, disabled = false) {
    return {
        value: id,
        handle: obj.handle,
        image: obj.profile_picture,
        isDisabled: disabled,
        email: '',
        count: 0,
        _type: 'communities',
        label: obj.name
    }
}

function buildMemberEntity(id, obj, disabled = false) {
    return {
        value: id,
        handle: obj.handle,
        image: obj.profile_picture,
        isDisabled: disabled,
        count: 1,
        _type: 'members',
        email: obj.contact ? obj.contact.email : obj.email ? obj.email : '',
        label: obj.name
    }
}

function buildFolderEntity(id, obj, disabled = false) {
    return {
        value: id,
        emoji: obj.emoji,
        isDisabled: disabled,
        count: 0,
        _type: 'folders',
        email: '',
        label: obj.name
    }
}

function buildMemberTypeEntity(id, obj, disabled = false) {
    return {
        value: id,
        handle: obj.handle,
        isDisabled: disabled,
        emoji: obj.emoji,
        count: 0,
        _type: 'member_types',
        email: '',
        label: obj.plural
    }
}

function buildGroupEntity(id, obj, disabled = false) {
    return {
        value: id,
        handle: obj.handle,
        isDisabled: disabled,
        count: Object.keys(obj.user_uids).length,
        emoji: obj.emoji,
        email: obj.power_ups.unaty__mailing_lists ? obj.power_ups.unaty__mailing_lists.config.email_address : '',
        image: '',
        _type: 'groups',
        label: obj.name
    }
}

function buildFilterEntity(id, obj, disabled = false) {
    return {
        value: id,
        handle: '',
        isDisabled: disabled,
        emoji: '',
        image: '',
        email: '',
        count: 0,
        _type: 'filters',
        label: obj.name
    }
}

function buildRoleEntity(id, obj, disabled = false) {
    return {
        value: id,
        count: obj.assignee ? 1 : 0,
        isDisabled: disabled,
        handle: obj.handle,
        emoji: obj.emoji,
        email: obj.assignee_data.email,
        image: obj.assignee_data.profile_picture,
        alt_name: obj.assignee_data.name,
        alt_handle: obj.assignee_data.handle,
        _type: 'roles',
        label: obj.name
    }
}

function handleResults() {

}

export function buildSearchPromise(query, {type, field}, context, meta, excluded, allowed = {}, disabled = {}, limit = 10) {
    switch (type) {
        case 'communities' :
            return FirebaseDB
                .collection('communities')
                .orderBy(field)
                .startAt(query)
                .endAt(query + "\uf8ff")
                .limit(limit)
                .get()
                .then((snap) => {
                    let obj = {};
                    for (let i = 0; i < snap.docs.length; i++) {
                        const doc = snap.docs[i];
                        const data = doc.data();
                        saveEntityToLocalStorage('global', 'communities', doc.id, convertEntityForLocalStorage(data, 'communities', data, doc.id));
                        if (!excluded[type][doc.id]) {
                            // if is not in excluded object, go on
                            if (!allowed || (!allowed[type] || allowed[type] === null)) {
                                // if no allowed is defined, go on and add
                                obj[doc.id] = buildCommunityEntity(doc.id, data);
                            } else {
                                if (allowed[type][doc.id]) {
                                    if (disabled[type] && disabled[type][doc.id]) {
                                        obj[doc.id] = buildCommunityEntity(doc.id, data, true);
                                    } else {
                                        obj[doc.id] = buildCommunityEntity(doc.id, data);
                                    }

                                } else {
                                    if (disabled[type] && disabled[type][doc.id]) {
                                        obj[doc.id] = buildCommunityEntity(doc.id, data, true);
                                    }
                                }
                            }

                        } else {
                            //  obj[doc.id] = buildCommunityEntity(doc.id, data, true);
                        }
                    }
                    return {communities: handleResults()};
                });
        case 'members' :
            return FirebaseDB
                .collection('search')
                .doc(context.community_uid)
                .collection('members')
                .orderBy(field)
                .startAt(query)
                .endAt(query + "\uf8ff")
                .limit(limit)
                .get()
                .then((snap) => {
                    let obj = {};
                    for (let i = 0; i < snap.docs.length; i++) {
                        const doc = snap.docs[i];
                        let d = Object.assign({}, doc.data());
                        d.id = doc.id;
                        saveEntityToLocalStorage(context.community_uid, 'members', convertEntityForLocalStorage(context.community_uid, 'members', d));

                        if (!excluded[type][doc.id]) {
                            if (!allowed || (!allowed[type] || allowed[type] === null)) {
                                // if no allowed is defined, go on and add
                                obj[doc.id] = buildMemberEntity(doc.id, doc.data());
                            } else {
                                if (allowed[type][doc.id]) {
                                    if (disabled[type] && disabled[type][doc.id]) {
                                        obj[doc.id] = buildMemberEntity(doc.id, doc.data(), true);
                                    } else {
                                        obj[doc.id] = buildMemberEntity(doc.id, doc.data());
                                    }
                                } else {
                                    if (disabled[type] && disabled[type][doc.id]) {
                                        obj[doc.id] = buildMemberEntity(doc.id, doc.data(), true);
                                    }
                                }
                            }

                        } else {
                            // obj[doc.id] = buildMemberEntity(doc.id, doc.data(), true);
                        }
                    }
                    return {members: obj};
                });
        case 'member_types' :
            return FirebaseDB
                .collection('community_members')
                .doc(context.community_uid)
                .collection('member_types')
                .orderBy(field)
                .startAt(query)
                .endAt(query + "\uf8ff")
                .limit(limit)
                .get()
                .then((snap) => {
                    let obj = {};
                    for (let i = 0; i < snap.docs.length; i++) {
                        const doc = snap.docs[i];
                        let d = Object.assign({}, doc.data());
                        d.id = doc.id;
                        saveEntityToLocalStorage(context.community_uid, 'member-types', convertEntityForLocalStorage(context.community_uid, 'member-types', d));

                        if (!excluded[type][doc.id]) {
                            if (!allowed || (!allowed[type] || allowed[type] === null)) {
                                // if no allowed is defined, go on and add
                                obj[doc.id] = buildMemberTypeEntity(doc.id, doc.data());
                            } else {
                                if (allowed[type][doc.id]) {
                                    if (disabled[type] && disabled[type][doc.id]) {
                                        obj[doc.id] = buildMemberTypeEntity(doc.id, doc.data(), true);
                                    } else {
                                        obj[doc.id] = buildMemberTypeEntity(doc.id, doc.data());
                                    }
                                } else {
                                    if (disabled[type] && disabled[type][doc.id]) {
                                        obj[doc.id] = buildMemberTypeEntity(doc.id, doc.data(), true);
                                    }
                                }
                            }
                        } else {
                            // obj[doc.id] = buildMemberTypeEntity(doc.id, doc.data(),true);
                        }
                    }
                    return {member_types: obj};
                });
        case 'roles' :
            return FirebaseDB
                .collection('community_entities')
                .doc(context.community_uid)
                .collection('roles')
                .orderBy(field)
                .startAt(query)
                .endAt(query + "\uf8ff")
                .limit(limit)
                .get()
                .then((snap) => {
                    let obj = {};
                    for (let i = 0; i < snap.docs.length; i++) {
                        const doc = snap.docs[i];
                        let d = Object.assign({}, doc.data());
                        d.id = doc.id;
                        saveEntityToLocalStorage(context.community_uid, 'roles', convertEntityForLocalStorage(context.community_uid, 'roles', d));

                        if (!excluded[type][doc.id]) {
                            if (!allowed || (!allowed[type] || allowed[type] === null)) {
                                // if no allowed is defined, go on and add
                                obj[doc.id] = buildRoleEntity(doc.id, d);
                            } else {
                                if (allowed[type][doc.id]) {
                                    if (disabled[type] && disabled[type][doc.id]) {
                                        obj[doc.id] = buildRoleEntity(doc.id, d, true);
                                    } else {
                                        obj[doc.id] = buildRoleEntity(doc.id, d);
                                    }
                                } else {
                                    if (disabled[type] && disabled[type][doc.id]) {
                                        obj[doc.id] = buildRoleEntity(doc.id, d, true);
                                    }
                                }
                            }

                        } else {
                            // obj[doc.id] = buildRoleEntity(doc.id, d, true);
                        }
                    }
                    return {roles: obj};
                });
        case 'filters' :
            return FirebaseDB
                .collection('community_entities')
                .doc(context.community_uid)
                .collection('collections')
                .orderBy(field)
                .startAt(query)
                .endAt(query + "\uf8ff")
                .limit(limit)
                .get()
                .then((snap) => {
                    let obj = {};
                    for (let i = 0; i < snap.docs.length; i++) {
                        const doc = snap.docs[i];
                        let d = Object.assign({}, doc.data());
                        d.id = doc.id;
                        saveEntityToLocalStorage(context.community_uid, 'filters', convertEntityForLocalStorage(context.community_uid, 'filters', d));

                        if (!excluded[type][doc.id]) {
                            if (!allowed || (!allowed[type] || allowed[type] === null)) {
                                // if no allowed is defined, go on and add
                                obj[doc.id] = buildFilterEntity(doc.id, d);
                            } else {
                                if (allowed[type][doc.id]) {
                                    if (disabled[type] && disabled[type][doc.id]) {
                                        obj[doc.id] = buildFilterEntity(doc.id, d, true);
                                    } else {
                                        obj[doc.id] = buildFilterEntity(doc.id, d);
                                    }
                                } else {
                                    if (disabled[type] && disabled[type][doc.id]) {
                                        obj[doc.id] = buildFilterEntity(doc.id, d, true);
                                    }
                                }
                            }

                        } else {
                            //   obj[doc.id] = buildFilterEntity(doc.id, d, true);
                        }
                    }
                    return {filters: obj};
                });
        case 'folders' :

            return FirebaseDB
                .collection('community_entities')
                .doc(context.community_uid)
                .collection('folders')
                .where('type', '==', 'standard')
                .where('security', '==', 'public')
                .orderBy(field)
                .startAt(query)
                .endAt(query + "\uf8ff")
                .limit(limit)
                .get()
                .then((snap) => {
                    let obj = {};
                    for (let i = 0; i < snap.docs.length; i++) {
                        const doc = snap.docs[i];
                        let d = Object.assign({}, doc.data());
                        d.id = doc.id;

                        saveEntityToLocalStorage(context.community_uid, 'folders', convertEntityForLocalStorage(context.community_uid, 'folders', d));

                        if (!excluded[type][doc.id]) {
                            if (!allowed || (!allowed[type] || allowed[type] === null)) {
                                // if no allowed is defined, go on and add
                                obj[doc.id] = buildFolderEntity(doc.id, d);
                            } else {
                                if (allowed[type][doc.id]) {
                                    if (disabled[type] && disabled[type][doc.id]) {
                                        obj[doc.id] = buildFolderEntity(doc.id, d, true);
                                    } else {
                                        obj[doc.id] = buildFolderEntity(doc.id, d);
                                    }
                                } else {
                                    if (disabled[type] && disabled[type][doc.id]) {
                                        obj[doc.id] = buildFolderEntity(doc.id, d, true);
                                    }
                                }
                            }

                        } else {
                            // obj[doc.id] = buildFolderEntity(doc.id, d, true);
                        }
                    }
                    return {folders: obj};
                });
        case 'teams' :
            return FirebaseDB
                .collection('community_entities')
                .doc(context.community_uid)
                .collection('groups')
                .where('type', '==', 'team')
                .where(`entities.groups.${meta.block_group_id}`, '!=', true)
                .orderBy(field)
                .startAt(query)
                .endAt(query + "\uf8ff")
                .limit(limit)
                .get()
                .then((snap) => {
                    let obj = {};
                    for (let i = 0; i < snap.docs.length; i++) {
                        const doc = snap.docs[i];
                        let d = Object.assign({}, doc.data());
                        d.id = doc.id;
                        saveEntityToLocalStorage(context.community_uid, 'teams', convertEntityForLocalStorage(context.community_uid, 'teams', d));

                        if (!excluded[type][doc.id]) {
                            if (!allowed || (!allowed[type] || allowed[type] === null)) {
                                // if no allowed is defined, go on and add
                                obj[doc.id] = buildGroupEntity(doc.id, d);
                            } else {
                                if (allowed[type][doc.id]) {
                                    if (disabled[type] && disabled[type][doc.id]) {
                                        obj[doc.id] = buildGroupEntity(doc.id, d, true);
                                    } else {
                                        obj[doc.id] = buildGroupEntity(doc.id, d);
                                    }
                                } else {
                                    if (disabled[type] && disabled[type][doc.id]) {
                                        obj[doc.id] = buildGroupEntity(doc.id, d, true);
                                    }
                                }
                            }
                        } else {
                            // obj[doc.id] = buildGroupEntity(doc.id, d, true);
                        }
                    }
                    return {teams: obj};
                });
        case 'groups' :
            if (meta.block_group_id) {
                return FirebaseDB
                    .collection('community_entities')
                    .doc(context.community_uid)
                    .collection('groups')
                    .where(`entities.groups.${meta.block_group_id}`, '!=', true)
                    .orderBy(field)
                    .startAt(query)
                    .endAt(query + "\uf8ff")
                    .limit(limit)
                    .get()
                    .then((snap) => {
                        let obj = {};
                        for (let i = 0; i < snap.docs.length; i++) {
                            const doc = snap.docs[i];
                            let d = Object.assign({}, doc.data());
                            d.id = doc.id;
                            saveEntityToLocalStorage(context.community_uid, 'groups', convertEntityForLocalStorage(context.community_uid, 'groups', d));

                            if (!excluded[type][doc.id]) {
                                if (!allowed || (!allowed[type] || allowed[type] === null)) {
                                    // if no allowed is defined, go on and add
                                    obj[doc.id] = buildGroupEntity(doc.id, doc.data());
                                } else {
                                    if (allowed[type][doc.id]) {
                                        if (disabled[type] && disabled[type][doc.id]) {
                                            obj[doc.id] = buildGroupEntity(doc.id, doc.data(),true);
                                        } else {
                                            obj[doc.id] = buildGroupEntity(doc.id, doc.data());
                                        }
                                    } else {
                                        if (disabled[type] && disabled[type][doc.id]) {
                                            obj[doc.id] = buildGroupEntity(doc.id, doc.data(), true);
                                        }
                                    }
                                }
                            } else {
                                //  obj[doc.id] = buildGroupEntity(doc.id, doc.data(), true);
                            }
                        }
                        return {groups: obj};
                    });
            } else {
                return FirebaseDB
                    .collection('community_entities')
                    .doc(context.community_uid)
                    .collection('groups')
                    .orderBy(field)
                    .startAt(query)
                    .endAt(query + "\uf8ff")
                    .limit(limit)
                    .get()
                    .then((snap) => {
                        let obj = {};
                        for (let i = 0; i < snap.docs.length; i++) {
                            const doc = snap.docs[i];
                            let d = Object.assign({}, doc.data());
                            d.id = doc.id;
                            saveEntityToLocalStorage(context.community_uid, 'groups', convertEntityForLocalStorage(context.community_uid, 'groups', d));

                            if (!excluded[type][doc.id]) {
                                if (!allowed || (!allowed[type] || allowed[type] === null)) {
                                    // if no allowed is defined, go on and add
                                    obj[doc.id] = buildGroupEntity(doc.id, doc.data());
                                } else {
                                    if (allowed[type][doc.id]) {
                                        if (disabled[type] && disabled[type][doc.id]) {
                                            obj[doc.id] = buildGroupEntity(doc.id, doc.data(), true);
                                        } else {
                                            obj[doc.id] = buildGroupEntity(doc.id, doc.data());
                                        }
                                    } else {
                                        if (disabled[type] && disabled[type][doc.id]) {
                                            obj[doc.id] = buildGroupEntity(doc.id, doc.data(), true);
                                        }
                                    }
                                }
                            } else {
                                //  obj[doc.id] = buildGroupEntity(doc.id, doc.data(), true);
                            }
                        }
                        return {groups: obj};
                    });
            }

        default :
            return {};
    }
}