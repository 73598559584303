import React, {useState, useEffect, useContext, useLayoutEffect} from 'react';
import styled from "styled-components";
import moment from 'moment';
import {ChevronLeftIcon,ChevronRightIcon,GiftIcon} from "@heroicons/react/solid";
import {FirebaseDB} from "../../../../config/setup-firestore";
import flatten from "lodash/flatten";
import {useIntl} from "react-intl";
import {CommunityContext} from "../../../../app/community/community-context";
import {Button, EntityHeader, PageHeader, SectionHeader} from "../../core";
import {useHistory} from "react-router-dom";

const PageFrame = styled.div`
user-select: none;
`;

const DateItem = styled.div`
   position: relative;
   padding-bottom: 70%;
       height: ${props=>props.height}px;
`;

const Wrapper = styled.div`
   display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    border: 1px solid #dadada;
    border-radius: 6px;
>div:nth-child(7n-1),>div:nth-child(7n-2),>div:nth-child(7n-3),>div:nth-child(7n-4),>div:nth-child(7n-5),>div:nth-child(7n-6)   {
  border-right: 1px solid #dadada;
}

>div:nth-child(n+8) {
  border-top: 1px solid #dadada;
}
`;

const DateText = styled.div`
   font-size: 14px;
   height: 24px;
   width: auto;
   display: flex;
   margin-left: auto;
   padding: 4px;
   align-items: center;
      justify-content: flex-end;
   background-color: ${props => props.is_today ? '#2F80ED' : ''};
    font-weight: 500;
`;

const DateInner = styled.div`
    position: absolute;
        left: 0;
    right: 0;
    bottom: 0;
    top: 0;
`;

const InnerWrapper = styled.div`
padding: 5px;
position: relative;
display: grid;
    grid-gap: 6px;
    height: 100%;
grid-template-rows: 24px 1fr;
`;

const Birthdays = styled.div`
    overflow-y: auto;
        margin-left: -5px;
    margin-right: -5px;
    margin-bottom: -5px;
    padding-bottom: 12px;
`;

const BirthdayItem = styled.div`
   display: grid;
    grid-template-columns: auto 1fr;
    grid-gap: 4px;
    height: 18px;
    padding: 2px 4px;
    margin: 0 2px 2px 2px;
    border-radius: 4px;
    
`;

const BdayName = styled.div`
    font-size: 12px;
        display: flex;
    align-items: center;
    color: ${props=>props.important?'#D12308':''};
    font-weight: ${props=>props.important?'600':'400'};
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 100%;
`;

const BdayIcon = styled.div`
    color: #D12308;
        display: flex;
    align-items: center;
    > svg {
        height: 13px;
    }
`;

const WeekDays = styled.div`
display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    padding-bottom: 4px;
`;

const WeekDay = styled.div`
    text-align: right;
    border-right: 1px solid #fff;
    padding-right: 8px;
    font-size: 14px;
    font-weight: 400;
`;

function getSunday(d,start_day) {
    d = new Date(d);
    let day = d.getDay() - (start_day==='monday'?1:0),
        diff = d.getDate() - day + (day == 0 ? -6 : 0); // adjust when day is sunday
    return new Date(d.setDate(diff));
}

const rows = 6;

const total = rows * 7;

function isCurrentMonth(id, today) {
    return id.slice(-7) === today;
}

function isWeekday(i) {
    return !(i % 7 === 0 || (i + 1) % 7 === 0);
}

function buildBoxes(sunday, today, date_events) {
    let arr = [];
    let temp = moment(sunday);

    const month_year_key = today.slice(-7);

    for (let i = 0; i < total; i++) {
        const id = temp.format('D-MM-YYYY');

        const sp = id.split('-');
        arr.push({
            id,
            month: sp[1],
            year: sp[2],
            is_today: today === id,
            date: sp[0],
            is_weekday: isWeekday(i),
            is_current_month: isCurrentMonth(id, month_year_key),
            birthdays: date_events[id] ? date_events[id] : []
        });
        temp.add(1, 'day');
    }

    return arr;
}

function Birthday({name, goToMember, current_year, handle, birth_year}) {
    const age = getAge(current_year, birth_year);
    let text = `${name}`;
    if(age<100&&age>0) {
        text += ` (${age})`;
    }
    const important = age>49&&(age%5===0);
    return <BirthdayItem alt={text} onClick={() => goToMember(handle)} className='hover-opacity'>
        <BdayIcon>
            <GiftIcon/>
        </BdayIcon>
        <BdayName important={important} className='text-gray-400'>
            {text}
        </BdayName>
    </BirthdayItem>
}

function getDateClassName(is_today, is_current_month, is_weekday) {
    if (is_today)
        return 'color-white';
    else if (is_weekday && is_current_month)
        return 'text-gray-900';
    else if (is_current_month)
        return 'text-gray-400';
    return 'text-gray-400';
}

function DateBox({month, year, height, goToMember, focus_month, is_current_month, is_weekday, birthdays, is_today, date}) {
    return <DateItem height={height}>
        <DateInner>
            <InnerWrapper>
                <DateText is_current_month={is_current_month} is_today={is_today}
                          className={`${getDateClassName(is_today, month === focus_month, is_weekday)} rounded-md`}>{date}</DateText>
                <Birthdays>
                    {birthdays.map((bday, i) => {
                        return <Birthday goToMember={goToMember} key={i} current_year={parseInt(year)} {...bday} />
                    })}
                </Birthdays>
            </InnerWrapper>
        </DateInner>
    </DateItem>
}

function getStartOfMonth(d) {
    return new Date(d.getFullYear(), d.getMonth(), 1);
}

function getBirthdayDate(m) {
    return m.format('D-MM-YYYY')
}

function getAge(c, b) {
    return c - b;
}

function getBirthdays(existing, arr) {
    let obj = Object.assign({}, existing);

    for (let i = 0; i < arr.length; i++) {
        const member = arr[i];

        const bday = moment().year(member.query_year).month(member.birthday.month).date(member.birthday.date);

        const bday_date = getBirthdayDate(bday);

        if (!obj[bday_date]) {
            obj[bday_date] = [];
        }

        obj[bday_date].push({
            name: member.name,
            handle: member.handle,
            birth_year: member.birthday.year,
            image: member.profile_picture,
            id: member.user_uid
        });
    }

    return obj;
}

function buildPromise([month, year], community_uid) {
    return FirebaseDB
        .collection('community_members')
        .doc(community_uid)
        .collection('members')
        .where('archived', '==', false)
        .where('suspended', '==', false)
        .where(`birthday.month`, '==', month)
        .get()
        .then((snap) => {
            return snap.docs.map((doc) => {
                const data = doc.data();
                return {
                    name: data.name,
                    handle: data.handle,
                    profile_picture: data.profile_picture,
                    user_uid: data.user_uid,
                    query_year: year,
                    short_birthday: data.short_birthday,
                    birthday: data.birthday
                };
            })
        })
}

function getQueryNeed(already_queried, start_month, start_year) {
    let need_to_query = [];
    let new_queried = already_queried;

    // current
    let s1, s2, s3;
    s1 = `${start_year}-${start_month.toString()}`;
    if (!new_queried[s1]) {
        need_to_query.push([start_month, start_year]);
        new_queried[s1] = true;
    }

    let m1 = (start_month - 1) % 12;

    let m1y = m1 === 0 ? start_year - 1 : start_year;
    s2 = `${m1y}-${m1.toString()}`;
    if (!new_queried[s2]) {
        need_to_query.push([m1, m1y]);
        new_queried[s2] = true;
    }
    // plus 1

    let m2 = (start_month + 1) % 12;
    let m2y = m2 === 0 ? start_year + 1 : start_year;
    s3 = `${m2y}-${m2.toString()}`;
    if (!new_queried[s3]) {
        need_to_query.push([m2, m2y]);
        new_queried[s3] = true;
    }
    // 1 behind

    return {
        new_queried,
        need_to_query
    }
}

function getFocusRange(boxes) {
    let obj = {};
    let years = {};
    for (let i = 0; i < boxes.length; i++) {
        if (!obj[boxes[i].month]) {
            obj[boxes[i].month] = 1
        } else {
            obj[boxes[i].month]++;
        }
        if (!years[boxes[i].year]) {
            years[boxes[i].year] = 1
        } else {
            years[boxes[i].year]++;
        }
    }
    return {
        month: Object.entries(obj).reduce((a, b) => a[1] > b[1] ? a : b)[0],
        year: Object.entries(years).reduce((a, b) => a[1] > b[1] ? a : b)[0]
    }
}

const now_moment = moment();

function getTitle(moment_item,focus_range) {
    const sm = `${moment_item.format('MMMM')} ${focus_range.year.toString()}`;
    const cm = `${now_moment.format('MMMM YYYY')}`;
    if(cm===sm) {
        return 'This Month'
    }
    return `${sm}`;
}

export function MainCalendar({community_uid = '', community_handle}) {
    const today = new Date();
    const history = useHistory();
    const community = useContext(CommunityContext);
    const {formatMessage: f} = useIntl();
    const [startDate, setStartDate] = useState(getStartOfMonth(today));
    const [can_scroll, setCanScroll] = useState(true);
    const [loading, setLoading] = useState(false);
    const pref_start_date = community.member.preferences.week_starts_on ? community.member.preferences.week_starts_on : 'sunday';
    const sunday = getSunday(startDate,pref_start_date);

    const [height,setHeight] = useState(window.innerHeight-280);

    function updateHeight() {
        const el = document.getElementById('main-calendar');

        const dims = el.getBoundingClientRect();

        const client_height = window.innerHeight;

        if(!el) {
            return;
        }

        const h = client_height-24-dims.top;

        setHeight(h);
    }

    useEffect(function () {
        updateHeight();
    }, []);

    useLayoutEffect(() => {
        window.addEventListener('resize', updateHeight);
        return () => window.removeEventListener('resize', updateHeight);
    }, []);

    // arr of all days we've gotten, need to do promises of up to 10 days each
    const [months_queried, setMQ] = useState({});
    const [date_events, updateDateEvents] = useState({});

    const today_moment = moment(today);
    const start_month_moment = moment(startDate);

    useEffect(() => {
        queryEvents(startDate);
        return () => {
        };
    }, [startDate]);

    function queryEvents() {
        const {new_queried, need_to_query} = getQueryNeed(months_queried, start_month_moment.month(), start_month_moment.year());
        setMQ(new_queried);
        if (need_to_query.length > 0) {
            setLoading(true);
            getEvents(need_to_query)
                .then((arrs) => {
                    updateDateEvents(getBirthdays(date_events, flatten(arrs)));
                    setLoading(false);
                })
        }
    }

    async function getEvents(months) {
        let promises = [];
        for (let i = 0; i < months.length; i++) {
            promises.push(buildPromise(months[i], community_uid));
        }
        return await Promise.all(promises);
    }

    function listener(e) {
        let delta = e.wheelDeltaY;
        if (can_scroll) {
            if (delta < -6) {
                addWeeks(1);
            } else if (delta > 6) {
                addWeeks(-1);
            }
            setCanScroll(false);
            setTimeout(() => {
                setCanScroll(true);
            }, 200);
        }
    }

    // scroll listener
    useEffect(() => {
        // window.addEventListener('mousewheel', listener, false);
        // Specify how to clean up after this effect:
        // document.getElementById('main-calendar').removeEventListener('scroll', listener, false)
       //  return () => window.removeEventListener('mousewheel', listener, false);
    }, [today]);

    function addWeeks(weeks) {
        if (can_scroll) {
            let now = new Date(startDate);
            now.setDate(now.getDate() + (weeks * 7));
            setStartDate(now);
        }
    }

    function firstDayInPreviousMonth(yourDate) {
        var d = new Date(yourDate);
        d.setDate(1);
        d.setMonth(d.getMonth() - 1);
        return d;
    }

    function firstDayInNextMonth(yourDate) {
        var d = new Date(yourDate);
        d.setDate(1);
        d.setMonth(d.getMonth() + 1);
        return d;
    }

    function goToToday() {
        setStartDate(getStartOfMonth(today));
    }

    const today_formatted = today_moment.format('D-MM-YYYY');

    const build_boxes = buildBoxes(sunday, today_formatted, date_events);

    const focus_range = getFocusRange(build_boxes);

    const moment_item = moment().month(parseInt(focus_range.month)-1).year(parseInt(focus_range.year));

    const actions = <div className="flex space-x-2">
        <Button minimal left_icon={<ChevronLeftIcon/>} onClick={() => {
            setStartDate(getStartOfMonth(firstDayInPreviousMonth(startDate)));
        }}/>
        <Button minimal left_icon={<ChevronRightIcon/>} onClick={() => {
            setStartDate(getStartOfMonth(firstDayInNextMonth(startDate)));
        }}/>
        <Button text={f({id:'community.calendar.today'})} onClick={() => goToToday()}/>
    </div>

    return <div>
        <EntityHeader title={getTitle(moment_item,focus_range)} actions={actions} />

        <div className="px-4 pt-3">
            <WeekDays>
                {pref_start_date==='sunday'&&<WeekDay className='text-gray-400'>{f({id:'community.calendar.short_days.sunday'})}</WeekDay>}
                <WeekDay className='text-gray-400'>{f({id:'community.calendar.short_days.monday'})}</WeekDay>
                <WeekDay className='text-gray-400'>{f({id:'community.calendar.short_days.tuesday'})}</WeekDay>
                <WeekDay className='text-gray-400'>{f({id:'community.calendar.short_days.wednesday'})}</WeekDay>
                <WeekDay className='text-gray-400'>{f({id:'community.calendar.short_days.thursday'})}</WeekDay>
                <WeekDay className='text-gray-400'>{f({id:'community.calendar.short_days.friday'})}</WeekDay>
                <WeekDay className='text-gray-400'>{f({id:'community.calendar.short_days.saturday'})}</WeekDay>
                {pref_start_date==='monday'&&<WeekDay className='text-gray-400'>{f({id:'community.calendar.short_days.sunday'})}</WeekDay>}
            </WeekDays>
            <Wrapper id='main-calendar'>
                {build_boxes.map((box) => (
                    <DateBox goToMember={(handle) => {
                        history.push(`/${community_handle}/member/${handle}`)
                    }} height={height/rows} focus_month={focus_range.month} key={box.id} {...box} />
                ))}
            </Wrapper>
        </div>
    </div>
}