import React from "react";
import {DetailModal} from "../../../../library/playground/detail-modal";

export function GroupMembersModal({
                                      onClose,
    tabs = [],
                                      openAddEntities = ()=>{},
                                      can_manage,
                                      start_tab=0,
                                      title = "Group Members"
                                  }) {
    return <DetailModal start_tab={start_tab} tabs={tabs} title={title} onClose={onClose} />
}