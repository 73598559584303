import React from "react";
import styled from "styled-components";

const ChildHeight = styled.div`
  > div {
    min-height: 540px;
  }
`;

export function OnboardingStep({children,progress=25}) {
    return <div className="bg-white sm:shadow-md flex flex-col sm:rounded-md" >
        <div className="sm:rounded-t-md overflow-hidden bg-gray-50 flex-none">
            <div className="h-1 bg-secondary" style={{width:`${progress}%`}} />
        </div>
        <div className="flex-grow w-full">
            <ChildHeight className="w-full h-full">
            {children}
            </ChildHeight>
        </div>
    </div>
}