import React, {Component} from 'react';

import styled from "styled-components";
import {BasicSelect} from "../../../../../../library/components/core/basic-select";
const display_name_options = {
    'members.created': {
        label: 'members.created',
        desc: 'Accounts for when a member is created',
        value:
            'members.created'
    }
    ,
    'members.updated': {
        label: 'members.updated',
        desc: 'Accounts for when a member is updated',
        value:
            'members.updated'
    }
    ,
    'members.deleted': {
        label: 'members.deleted',
        desc: 'Accounts for when a member is deleted',
        value:
            'members.deleted'
    },
    'members.deactivated': {
        label: 'members.deactivated',
        desc: 'Accounts for when a member is deactivated',
        value:
            'members.deactivated'
    },
    'members.reactivated': {
        label: 'members.reactivated',
        desc: 'Accounts for when a member is reactivated',
        value:
            'members.reactivated'
    },

    'roles.created': {
        label: 'roles.created',
        desc: 'Accounts for when a role is created',
        value:
            'roles.created'
    }
    ,
    'roles.updated': {
        label: 'roles.updated',
        desc: 'Accounts for when a role is updated',
        value:
            'roles.updated'
    }
    ,
    'roles.deleted': {
        label: 'roles.deleted',
        desc: 'Accounts for when a role is deleted',
        value:
            'roles.deleted'
    },

    'groups.created': {
        label: 'groups.created',
        desc: 'Accounts for when a group is created',
        value:
            'groups.created'
    },
    'groups.updated': {
        label: 'groups.updated',
        desc: 'Accounts for when a group is updated',
        value:
            'groups.updated'
    }
    ,
    'groups.deleted': {
        label: 'groups.deleted',
        desc: 'Accounts for when a group is deleted',
        value:
            'groups.deleted'
    },

    'groups.members.added': {
        label: 'groups.members.added',
        desc: 'Accounts for when a group member is added',
        value: 'groups.members.added'
    },

    'groups.members.removed': {
        label: 'groups.members.removed',
        desc: 'Accounts for when a group member is removed',
        value: 'groups.members.removed'
    },
};

const Val = styled.span`
font-weight: 500;
    margin-right: 8px;
`;

function convertStrToArr(value) {
    let split = value.split(' ');
    if(split.length===1&&split[0]==='') {
        return [];
    }
    let arr = [];
    for (let i = 0; i < split.length; i++) {
        let item = split[i];
        arr.push({
            value: item,
            label: display_name_options[item].label
        })
    }
    return arr;
}

function convertArrToStr(arr) {
    let str = '';

    if(!arr) {
        return '';
    }

    for(let i=0;i<arr.length;i++) {
        str += arr[i].value;
        str += ' ';
    }

    return str.trim();
}

export function WebhookManageEvents({value,onChange,edit=false}) {

        if (edit) {
            return <BasicSelect clearable={false} onChange={(val) => {
                onChange(convertArrToStr(val));
            }} multi value={convertStrToArr(value)} options={Object.values(display_name_options)}/>
        }
        return <span>
            <Val>{value}</Val>
        </span>
}