import React, {useContext, useEffect, useState} from "react";
import {HotkeyWrapper} from "../../../../library/components/old/hotkey-wrapper";
import {PageContainer} from "../../../../library/components/old/page-container";
import {Button} from "../../../../library/components/core";
import {FirebaseDB} from "../../../../config/setup-firestore";
import styled from "styled-components";
import EditFieldsSection from "../../../../library/components/old/edit-fields-section";
import {setDocumentTitle} from "../../../../library/utilities/set-document-title";
import {authFetch} from "../../../../config/network";
import {CommunityContext} from "../../community-context";
import {DoubleCheckModal} from "../../../../library/components/old/double-check-modal";
import {ChangeHandleModal} from "./change-handle";
import {DotsVerticalIcon} from "@heroicons/react/solid";
import {PopupMenu} from "../../../../library/components/old/popup-menu";
import {GlobalContext} from "../../../global/global-context";
import {AdminEntityInfo, PageHeader} from "../../../../library/components/core";
import {CIRole} from "../../../../library/icons/custom/Role";
import {Popover2} from "@blueprintjs/popover2";
import {useHistory} from "react-router-dom";

export default function AdminRole(props) {
    const community = useContext(CommunityContext);
    const global = useContext(GlobalContext);
    const history = useHistory();
    const [id, setId] = useState(props.id);
    const [modal, setModal] = useState(null);
    const [error, setError] = useState(null);
    const [modal_data, setModalData] = useState(null);
    const [loading, setLoading] = useState('role');
    const [role, setRole] = useState({});

    useEffect(function () {
        if (id !== props.id) {
            setId(props.id);
        }
    }, [props.id]);

    useEffect(function () {
        getRole(id);
    }, [id]);

    function getRole(id) {
        FirebaseDB
            .collection('community_entities')
            .doc(community.uid)
            .collection('roles')
            .doc(id)
            .get()
            .then(handleRole.bind(this));
    }

    function handleRole(doc) {
        if (!doc.exists) {
            setError('not-found');
            setLoading(null);
        } else {
            let document = doc.data();
            document.id = doc.id;
            setDocumentTitle(document.name, community.data.name);
            setLoading(null);
            setRole({...document});
        }
    }

    function handleDelete() {

        if (loading === 'deleting') {
            return;
        }
        setLoading('deleting');
        const res = () => {

            global.addToast({text: `Role was deleted.`, intent: 'success'});
            history.push(`/${community.data.handle}/admin/roles`)
        };

        const err = (err1) => {
            console.error('err', err1)
            global.addToast({text: `Role could not be deleted.`, intent: 'danger'});
        };

        const payload = {
            community_uid: community.uid,
            id: id, member_id: community.member_id
        };

        authFetch("/roles/delete", res, err, "POST", {payload})
    }

    function changeHandle(h) {
        setLoading('handle');
        const res = () => {
            setTimeout(() => {
                setLoading('');
                setModal(null);
                setModalData(null);
                getRole(id);
                global.addToast({text: `Role handle changed`, intent: 'success'});
            }, 1000);
        };

        const payload = {
            new_handle: h,
            community_uid: community.uid,
            id: id, member_id: community.member_id
        };

        authFetch("/roles/change-handle", res, res, "POST", {payload});
    }


    const {profile_sections} = props;

    const hotkeys = [
        {
            id: 'D',
            keys: ["d"],
            fn: () => {
                setModal('double-check-delete');
            }
        }
    ];

    if (!role.name) {
        return null
    }

    const obj = {
        data: role,

        single_line: true,

        type: 'role',
        id: id,
        endpoint: '/roles/update',
        update_fields: ['name', 'assignee', 'designation', 'purpose', 'emoji'],

        sections: [
            {
                title: 'Details',
                fields: [
                    {
                        field: 'name',
                        type: 'text',
                        defer: true,
                        name: 'Name'
                    },
                    {
                        name: 'Symbol',
                        type: 'symbol',
                        edit_only: true,
                        defer: true,
                        field: 'emoji'
                    },
                    {
                        field: 'assignee',
                        type: 'assignee',
                        defer: true,
                        name: 'Assignee'
                    },
                    {
                        field: 'purpose',
                        type: 'textarea',
                        defer: true,
                        name: 'Purpose'
                    },
                    {
                        field: 'designation',
                        type: 'text',
                        defer: true,
                        name: 'Designation'
                    },
                ]
            }
        ]
    };

    const options = [
        {
            type: 'item',
            title: 'Change Handle',
            onClick: () => {
                setModal('change-handle');
                setModalData({handle: role.handle})
            }
        }
    ];

    let actions = [
        <Button key={'action-button-delete'} onClick={() => {
            setModal('double-check-delete');
        }} shortcut={'D'} text='Delete'/>
    ];

    return (
        <div>
            <div>

                <AdminEntityInfo type_icon={<div className="h-5 w-5 text-gray-600"><CIRole/></div>} id={id}
                                 type_label={"Role"}/>
                <div>
                    <PageHeader padded subtitle={`@${role.handle}`} action={<div className="space-x-2 flex">
                        {actions.map((action, i) => {
                            return <div key={i}>
                                {action}
                            </div>;
                        })}
                        <Popover2 placement={'bottom-start'} content={<PopupMenu items={options}/>} minimal
                                  popoverClassName='minimal-popover'>
                            <Button className='admin-dots-button' icon={<DotsVerticalIcon/>}/>

                        </Popover2>
                    </div>} divider title={<div className="flex items-center">
                        <div>{role.name}</div>
                    </div>} size='2xl'/>


                    <div>
                        <div>
                            <EditFieldsSection member_types={community.member_types} id={id}
                                               member_id={community.member_id} onSaved={()=>getRole(id)}
                                               profile_sections={profile_sections} community_uid={community.uid}
                                               history={props.history} {...obj} />
                        </div>

                    </div>

                </div>
            </div>
            {modal === 'change-handle' &&
            <ChangeHandleModal loading={loading === 'handle'} community_uid={community.uid} data={modal_data}
                               onConfirm={(h) => changeHandle(h)}
                               onClose={() => {
                                   setModal(null);
                                   setModalData(null)
                               }} onCancel={() => {
                setModal(null);
                setModalData(null)
            }} type='role'/>}
            {modal === 'double-check-delete' && <DoubleCheckModal onConfirm={() => handleDelete()} onClose={() => {
                setModal(null);
                setModalData(null)
            }} onCancel={() => {
                setModal(null);
                setModalData(null)
            }} type='role_delete'/>}
        </div>
    );
}