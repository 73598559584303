import React, {useState} from 'react';
import {ATAddFilter} from "./add-filter";
import styled from "styled-components";
import {useIntl} from "react-intl";
import {Button} from "../../core";
import {Popover2} from "@blueprintjs/popover2";
import {ATPrettyFilters} from "./pretty-filters";
import {ATButtonBox} from "../views/select-view";

const FilterList = styled.div`
    padding-bottom: 4px;
`;

const DNDFilterItem = styled.div`
        display: grid;
    grid-template-columns: auto 1fr auto;
            padding: 6px 8px 6px 0px;
        background-color: #fff;
`;

const FilterDragHandle = styled.div`
    height: 30px;
    margin-right: 6px;
      outline: none !important;
    display: flex;
    align-items: center;
    padding-left: 10px;
    width: 28px;
    > svg {
    opacity: ${props=>props.disabled?'0':'1'};
        height: 18px;
    }
`;



/*
function getButtonContent(filters,defs) {
    let text = '', icon;
    if(filters.length===0) {
        text = "Add Filter";
        icon = null;
    } else if(filters.length===1) {
        icon = <FilterIcon />
        const item = Object.values(defs.properties).filter(a=>a.field===filters[0].field)[0];
        if(!item) {
            return <span>Filter</span>
        } else {
            text = <span>Filter by <strong>{item.label}</strong></span>;
        }

    } else {
        text = `${filters.length} Filters`;
        icon = <FilterIcon />
    }

    return {
        text, icon
    }
}

    function handleOnDragEnd(result) {
        if (!result.destination) return;
        let current_filters = filters;

        let final_filters = at_getFinalSort(current_filters,result);
        reorderFilters(final_filters);
    }

    const renderDraggable = useDraggableInPortal();

    const disable_drag = filters.length<2;

    const button_content = getButtonContent(filters,definitions);

    const content = <ATPopoverContents size='xxl'>
        <ATPopoverTitle meta='Filter' title={definitions.general.title}/>
        <DragDropContext onDragEnd={handleOnDragEnd}>
            <FilterList>
                <Droppable droppableId="view-filter">
                    {(provided) => (
                        <DNDList {...provided.droppableProps} ref={provided.innerRef}>
                            {filters.map((s, i) => {
                                return <Draggable isDragDisabled={disable_drag} key={s.field} draggableId={s.field}
                                                  index={i}>
                                    {renderDraggable((provided) => {
                                        return <DNDFilterItem ref={provided.innerRef}
                                                              key={s.field} {...provided.draggableProps}>
                                            <FilterDragHandle disabled={disable_drag}
                                                              className='text-gray-700 hover-opacity' {...provided.dragHandleProps}>
                                                <IconGrabHandle/>
                                            </FilterDragHandle>
                                            <ATEditFilter all_options={filter_options} avail_options={filter_avail_opts} editFilter={(nd)=>onEditFilter(i,nd)} context={context} data={s} />
                                            <div>
                                                <ATAction className='hover-opacity text-gray-700' onClick={()=>onRemoveFilter(i)}>
                                                    <TrashIcon/>
                                                </ATAction>
                                            </div>
                                        </DNDFilterItem>
                                    })}
                                </Draggable>
                            })}
                            {provided.placeholder}
                        </DNDList>
                    )}
                </Droppable>
            </FilterList>
        </DragDropContext>
        {add_filter}
    </ATPopoverContents>;

 return <Popover2 hoverOpenDelay={0} hoverCloseDelay={0} isOpen={open_select} enforceFocus={false} onInteraction={(nextOpenState,e)=>{
        if(nextOpenState) {
            setSelectOpen(true);
        } else {
            if(e&&e.target&&(e.target.id.indexOf('option')===-1&&e.target.className.indexOf('option')===-1)) {
                if(e.target.parentNode&&e.target.parentNode.className.indexOf('option')===-1) {
                    setCreateOpen(false);
                    setSelectOpen(false);
                }
            }
        }
    }} placement='bottom-start' interactionKind='click' minimal content={content}
                    popoverClassName='minimal-popover margin-top-8px py-1'>
        <Button icon={button_content.icon} minimal onClick={()=>setSelectOpen(true)} active text={button_content.text} />
    </Popover2>
 */

export function ATFilter({filters,context,definitions,filter_avail_opts,filter_options=[],onAddFilter,onRemoveFilter,onEditFilter,reorderFilters}) {
    const [open_create, setCreateOpen] = useState(false);
    const {formatMessage: f} = useIntl();

    const add_filter = filters.length < 3 &&
        <Popover2 onInteraction={(nextOpenState,e)=>{
            if(nextOpenState) {
                setCreateOpen(true);
            } else {
                if(e&&e.target&&e.target.className&&e.target.className.indexOf&&(e.target.id.indexOf('option')===-1&&e.target.className.indexOf('option')===-1)) {
                    if(e.target.parentNode&&e.target.parentNode.className.indexOf('option')===-1) {
                        setCreateOpen(false);
                    }
                }
            }
        }} hoverOpenDelay={0} hoverCloseDelay={0} enforceFocus={false} isOpen={open_create} content={<ATAddFilter definitions={definitions} filter_options={filter_avail_opts} context={context} handleAddFilter={(nf)=>{
            setCreateOpen(false);
            onAddFilter(nf);
        }} />} placement='bottom-start' interactionKind='click' minimal
                  popoverClassName='minimal-popover py-3' targetClassName='popover-target-span'>
            <ATButtonBox style="alt" onClick={() => setCreateOpen(true)}>
                Add Filter
            </ATButtonBox>
        </Popover2>;

    const f_props = {
        filter_options,
        onRemoveFilter,
        definitions,
        add_filter,
        can_add_filter: filters.length<3,
        filters,
        filter_avail_opts,
        onEditFilter,
        context
    };

    return <ATPrettyFilters {...f_props} />
}