import React, {Component, useState} from "react";

import {PageHeader} from "../../../../../library/components/core";
import {PageContainer} from "../../../../../library/components/old/page-container";
import EditFieldsSection from "../../../../../library/components/old/edit-fields-section";
import {CommunityContext} from "../../../community-context";

export default class SettingsPrivacy extends Component {
    static contextType = CommunityContext;
    constructor(props) {
        super(props);

        this.state = {
            exists: true,

        };
    }

    render() {
        const {history} = this.props;
        const {} = this.state;

        const base_link = `/${this.context.data.handle}/admin/settings`;

        const obj = {
            base_link: `/${this.context.data.handle}/admin/settings`,
            data: this.context.data.privacy,

            endpoint: '/communities/update-privacy',
            update_fields: ['privacy'],

            sections: [
                {
                    title: 'Privacy Policy',
                    fields: [
                        {
                            name: 'Website Link',
                            type: 'url',
                            field: 'full_policy_link'
                        }
                    ]
                }
            ]
        };

        const nav = {
            can_go_back: false,
            history: history,
            breadcrumbs: [

                {
                    label: "Community Settings",
                    path: `${base_link}`
                },
                {
                    label: "About",
                    path: `#`
                }
            ]
        };

        return (<PageContainer nav={nav}>

                <PageHeader title='Privacy' size="2xl"/>

                <EditFieldsSection history={this.props.history} {...obj} />
            </PageContainer>
        );
    }
}