import React from "react";
import {SelectorIcon} from "@heroicons/react/solid";

export function ListExpander({count=3,onClick=()=>{}}) {

    return <div onClick={onClick} className={`hover:opacity-80 transition-opacity flex space-x-2 cursor-pointer`}>
        <div className="w-5 h-5 rounded-full flex items-center justify-center shadow-md bg-white">
            <div className="w-4 h-4 text-gray-600">
            <SelectorIcon />
            </div>
        </div>
        <div className="text-gray-600 leading-5 text-xs font-medium">
            Show {count} more
        </div>
    </div>
}