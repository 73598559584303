import React, {useContext} from "react";
import {PageContainer} from "../../../../library/components/old/page-container";
import EditFieldsSection from "../../../../library/components/old/edit-fields-section";

import {UserDeleteAccount} from "./delete-account";
import {GlobalContext} from "../../global-context";
import {PageHeader} from "../../../../library/components/core";
import {UserEmailManager} from "../../../../library/components/user-email-manager";
import CommonCard from "../../../../library/components/old/card";

export default function UserPreferencesAccount(props) {

    const global = useContext(GlobalContext);

    const {user} = global;

    const {history} = props;

    if (!user.account_email) {
        // loading
        return null;
    }

    const obj = {
        base_link: `/preferences`,
        data: user,

        endpoint: '/users/update-account',
        update_fields: ['preferences'],

        sections: [

            {
                title: 'Time & Date',
                fields: [
                    {
                        name: 'Language',
                        type: 'language',
                        field: 'preferences.language'
                    },
                    {
                        name: 'Timezone',
                        type: 'timezone',
                        field: 'preferences.timezone'
                    },
                    {
                        name: '24 Hour Time',
                        type: 'switch',
                        field: 'preferences.time_format_24'
                    }
                ]
            }
        ]
    };

    return <PageContainer>
        <PageHeader title='Account' size='2xl'/>

        <CommonCard title="Email">
            <div className="space-y-2">
                <UserEmailManager/>
            </div>
        </CommonCard>
        <EditFieldsSection history={props.history} {...obj} />

        <UserDeleteAccount/>
    </PageContainer>
}