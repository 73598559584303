import React, {Component} from 'react';
import styled from "styled-components";
import InputField from "../input";
import {EmojiPopupSelector} from "../emoji-popup-selector";
import {Field} from "../../core";

const Container = styled.div`
  border-color: ${props=>props.focus?'#659adf':'#d4d4d8'};
  border-width: 1px;
  border-style: solid;

  border-radius: 0.375rem;
       display: grid;
       box-shadow: ${props=>props.focus?'rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(58, 151, 212, 0.36) 0px 0px 0px 4px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(60, 66, 87, 0.16) 0px 0px 0px 1px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px':'0 1px 2px 0 #0000000d'};
       grid-template-columns: 54px 1fr;
  :hover {
    border-color:   ${props=>props.focus?'#659adf':'#b3b3b3'};
  }
`;

const InputContainer = styled.div`
padding: 4px 16px 4px 14px;
    > input {
        border: none;
    line-height: 32px;
    padding: 0;
    font-size: 15px;
    width: 100%;
    outline: none;
    }
`;

const Label = styled.div`
    line-height: 20px;
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 6px;
`;

const Meta = styled.div`
      margin-top: 6px;
    font-size: 13px;
`;

export class TextAndEmoji extends Component {
    constructor(props) {
        super(props);
        this.ref = React.createRef();
        this.caught = false;
        this.state = {
            has_focus: false
        };
    }

    componentDidMount() {
        this.ref.current.focus();
    }

    handleChange(e) {
        const {changeText,text} = this.props;
        const val = e.target.value;
        if(text===''&&!this.caught&&(val==='n'||val==='N')) {
            this.caught = true;
            return;
        }

        changeText(val);
    }

    handleFocus() {
        if(this.props.highlight) {
            this.ref.current.setSelectionRange(0, this.props.text.length);
        }
        this.setState({
            has_focus: true
        });
    }

    handleBlur() {
        const {onBlur} = this.props;

        this.setState({
            has_focus: false
        });

        onBlur();
    }

    render() {
        const {emoji='',meta,highlight=false,autoFocus=false,onBlur=()=>{},label,placeholder='Set a status',text,changeEmoji,changeText} = this.props;
        const {has_focus} = this.state;

        return <>
            <Field label={label} help_text={meta}>
            <Container className="input-box-shadow" focus={has_focus}>
                <div>
                <EmojiPopupSelector usePortal={false} emoji={emoji} changeEmoji={(em)=>{
                    if(em&&em.native) {
                        changeEmoji(em.native)
                    } else if(em) {
                        changeEmoji(em)
                    }
                }} />
                </div>
                <InputContainer >
                    <input onFocus={this.handleFocus.bind(this)} ref={this.ref} onBlur={this.handleBlur.bind(this)} autoFocus={autoFocus} placeholder={placeholder} className='text-gray-900 ' value={text} onChange={this.handleChange.bind(this)} />
                </InputContainer>
            </Container>
            </Field>
            </>
    }
}