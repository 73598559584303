import React, {Component, useState} from "react";

import {APFieldGrid, getValue} from "../shared";
import Field from "../../../../../library/components/old/field";
import {Button, EntityHeader, PageHeader} from "../../../../../library/components/core";
import {PageContainer} from "../../../../../library/components/old/page-container";
import {StandardPageFramework} from "../../../../../library/playground/standard-page-framework";
import {EyeIcon} from "@heroicons/react/solid";


export default function SettingsAccount(props) {
    const [state,setState] = useState({
        ...props.community,
        _changes_made: false,
        _loading: false
    });

        const {history} = props;

        const { _changes_made,_loading} = state;

        const _fields = [
            {
                label: 'Handle',
                type: 'text',
                field: 'handle',
                disabled: true
            },
            {
                label: 'Email Domain',
                type: 'text',
                field: 'preferences.email.custom_domain',
                disabled: true
            }
        ];

        const base_link = `/${this.context.data.handle}/admin/settings`;

        const nav = {
            can_go_back: false,
            history: history,
            breadcrumbs: [

                {
                    label: "Community Settings",
                    path: `${base_link}`
                },
                {
                    label: "Account",
                    path: `#`
                }
            ]
        };
        return (<StandardPageFramework content full>
                <EntityHeader title="Account" goBackTo={()=>history.push(`/${props.community.data.handle}/admin/settings`)}  />
                <APFieldGrid>
                    {_fields.map((item)=> {
                        const {type,field,label,disabled=false,options=[]} = item;
                        return <Field disabled={disabled} options={options} key={field} type={type} label={label} onChange={this.hc.bind(this, field)}
                                        value={getValue(field, this.state)} />
                    })}
                </APFieldGrid>
            </StandardPageFramework>
        );

}