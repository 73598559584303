import {parseMemberLocation} from "../../../app/community/member/helpers";

function getValue(field,data) {
    const t = field.split('.');
    if(!data) {
        return "";
    }
    if(t.length===3) {
        return data[t[0]]&&data[t[0]][t[1]]&&data[t[0]][t[1]][t[2]];
    } else if(t.length===2) {
        return data[t[0]]&&data[t[0]][t[1]];
    } else {
        return data[t[0]];
    }
}

function hv(field,data,type,options,fallback='') {
    const v = getValue(field,data);

    if(!v) {
        return fallback;
    }

    if(type==='select') {
        const it = options.filter(a=>a.value===v)[0];
        if(!it) {
            return '';
        }
        return it.label;
    }

    return v;
}

export function preprocessData(data,ctx,defs,partials={}) {
    console.log("PREPROCESS",data)
    console.log("partials",partials)
    if(!data) {
        return {}
    }
    let no = {};
    const entries = Object.entries(data).filter(([a,b])=>!!b.id);
    entries.forEach(([id,entry])=>{
        const mt = hv('member_type',entry,'select',Object.entries(ctx.member_types).map(([id,mt])=>{
            return {
                value: id,
                label: mt.singular
            }
        }));
        const loc = parseMemberLocation(entry);
       no[id] = {
           ...entry,
           _gallery: {
               name: hv('name',entry),
               image: hv('profile_picture',entry,null,null,ctx.default_profile_picture),
               member_type: mt,
               occupation: hv('about.occupation',entry),
               location: loc
           }
       };
    });
    return no;
}