import React, {useState, useContext} from 'react';
import Modal from "../../../../library/components/old/modal";
import {Button, InfoState} from "../../../../library/components/core";
import {authFetch} from "../../../../config/network";
import {GlobalContext} from "../../global-context";
import {validateEmail} from "../../../../library/utilities/validation";
import Field from "../../../../library/components/old/field";
import Form from "../../../../library/components/old/form";
import {CheckIcon, PaperAirplaneIcon} from "@heroicons/react/solid";
import {SimpleCallout} from "../../../community/group";
import {FirebaseDB} from "../../../../config/setup-firestore";

export default function AddSecondaryEmail(props) {
    const global = useContext(GlobalContext);
    const [state, setState] = useState('');
    const [email, setEmail] = useState('');

    const {
        onClose = () => {
        },
        community_uid = ''
    } = props;

    let body, footer_right;

    if (state!=='sent') {
        const loading = state === 'sending'||state==='checking';
        footer_right = <div>
            <Button text='Send verification email' intent="secondary" disabled={!validateEmail(email)}
                    onClick={() => checkIfEmailAvailable()} loading={loading}/>
        </div>;
        body = <div>
            <Field disabled={loading} autoFocus={true} type={"email"} label="New Email" onChange={(v) => {
                setEmail(v);
                setState('');
            }} value={email}/>
            {state==='is-member'&&<div className="pt-4">
                <SimpleCallout intent="danger" message="This email is already taken by another member in this community - contact your community admins for help."/>
            </div>}
            {state==='in-use'&&<div className="pt-4">
                <SimpleCallout intent="danger" message="This email is already taken by another account."/>
            </div>}
        </div>;
    } else {
        footer_right = null;
        body = <div>
            <InfoState button={<Button text='Got it!' intent="secondary" onClick={() => onClose()}/>}
                       icon={<PaperAirplaneIcon/>} title="Verification Email Sent" intent="secondary"
                       subtitle={"Check your inbox and verify this email by clicking the link in the email to add it to your account."}/>
        </div>;
    }

    function checkIfEmailAvailable() {
        setState('checking');

        const em = email;
        const payload = {
            email: em,
            community_uid
        };

        const res = (r) => {
            if(!r) {
                return;
            }
            if (r.data.status === 'is-community-member') {
                setState('is-member');
                setEmail('');
            } else if (r.data.status === 'email-taken') {
                setState('in-use');
                setEmail('');
            } else {
                sendEmail(em)
            }
        };

        authFetch("/accounts/check-if-email-is-available", res, res, "POST", {payload});
    }

    function sendEmail() {
        setState('sending');
        const payload = {
            email,
            user_uid: props.user_uid?props.user_uid:global.user_uid
        };

        const res = (r) => {
            if(!r) {
                return;
            }
            if (r.data.ok === 'yes') {
                setState('sent');
                global.addToast({text: 'Verification Email Sent', intent: 'success'});
            } else {
                setState('in-use');
                global.addToast({text: 'Email already in use', intent: 'danger'});
            }

        };

        authFetch("/users/send-secondary-verification-email", res, res, "POST", {payload});
    }

    return <Modal size={'small'} open={true} onClose={onClose} title={'Add Secondary Email'}
                  body={body}
                  footerLeft={null}
                  footerRight={footer_right}
    />
}