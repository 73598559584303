import React, {useContext, useState} from "react";
import {Button, Field, RichSelect} from "./core";
import Modal from "./old/modal";
import {getInputByType} from "./old/field";
import {CommunityContext} from "../../app/community/community-context";

export function AddManyToGroup({
                                   onClose = () => {
                                   }, handleContinue = () => {
    }
                               }) {
    const community = useContext(CommunityContext);
    const [group, setGroup] = useState("");

    const body = <div className="space-y-4">
        <Field label="Group">
            {getInputByType("group", (val) => {
                    console.log('val', val)
                    setGroup(val);
                }, () => {

                }, group, {
                autoFocus: true,
                meta: {
                        community_uid: community.uid
                    }
                },
                "group")}
        </Field>

    </div>;

    function proceed() {
        handleContinue({group});
    }

    return <Modal size={'small'} open={true} onClose={onClose} title={'Add Members to Group'}
                  body={body}
                  footerLeft={null}
                  footerRight={<div>
                      <Button disabled={!group} text="Add Members to Group" intent="secondary" onClick={() => proceed()}/>
                  </div>}
    />
}