import React, {useContext, useEffect, useRef, useState} from "react";
import {SimpleEditList} from "../../../custom/simple-edit-list";
import {SearchableSuggestions} from "../../../custom/searchable-suggestions";
import {Badge} from "../../../core";
import {PlusIcon} from "@heroicons/react/solid";
import {CUSTOM_NANOID} from "../../../../../config/defaults";
import {member_types_suggestions} from "../../suggestions/member-types";
import {CommunityContext} from "../../../../../app/community/community-context";
import {authFetch} from "../../../../../config/network";

const tabs = [
    "All",
    "Social",
    "Service",
    "Sports",
    "Educational",
    "Religious",
    "Fraternal",
    "Political",
    "Corps"
];

function convertArr(arr) {
    return arr.map(it => {
        return {
            emoji: it.emoji,
            title: it.plural,
            tag_only: it.tag_only,
            subtitle: [
                {
                    text: it.singular,
                    field: 'singular'
                },
                {
                    text: `(${it.abbreviation})`,
                    field: 'abbreviation'
                }
            ],
            search: it.plural.toLowerCase(),
            tags: it.tags
        }
    })
}

function buildData(ex,community) {
    let obj = {};
    const entries = Object.entries(ex);
    for(let i=0;i<entries.length;i++) {
        const [id,data] = entries[i];
        obj[id] = {
            title: data.plural,
            emoji: data.emoji,
            can_delete: false,
            emoji_field: 'emoji',
            tag: community.data.preferences.members.default_member_type===id?<Badge text={"Default"} size="xs" rounded color="blue"/>:null,
            title_field: 'plural',
            subtitle: [
                {
                    field: 'singular',
                    text: data.singular
                },
                {
                    field: 'abbreviation',
                    text: data.abbreviation
                }
            ]
        };
    }
    return obj;
}

function handlePrepData(data) {
    let obj = {};

    const entries = Object.entries(data);

    for(let i=0;i<entries.length;i++) {
        const [id,data] = entries[i];
        obj[id] = {
          emoji: data.emoji,
            is_new: data.can_delete,
          plural: data.title,
          singular: data.subtitle[0].text,
          abbreviation: data.subtitle[1].text
        };
    }

    return obj;
}

export function ObScSetupMemberTypes(props) {
    const community = useContext(CommunityContext);
    const [all,setAll] = useState([
        ...Object.keys(community.member_types)
    ]);
    const [data,setData] = useState(buildData(community.member_types,community));

    // saving
    const val = useRef();

    useEffect(function () {
        return () => {
            val.current = {all,data};
        }
    }, [all,data]);

    useEffect(function () {
        return () => {
            handleSave(val.current);
        }
    }, []);

    function handleSave(dt) {
        if(dt&&community&&community.member_id) {
            // members/edit-interests
            const res = () => null;

            const prepped_data = handlePrepData(dt.data);

            const payload = {
                community_uid: community.uid,
                member_id: community.member_id,
                id: community.member_id,
                user_uid: community.member.user_uid,
                all: dt.all,
                data: prepped_data
            }

           authFetch('/member-types/upsert-many', res, res, "POST", {payload});
        }
    }

    function handleAddItem(plural,singular,abbr,emoji) {
        if(all.length>9) {
            return;
        }
        const temp_id = CUSTOM_NANOID();
        setAll([...all,temp_id])
        setData({...data, [temp_id]: {
            title: plural,
            emoji: emoji,
            can_delete: true,
            emoji_field: 'emoji',
            title_field: 'plural',
            subtitle: [
                {
                    field: 'singular',
                    text: singular
                },
                {
                    field: 'abbreviation',
                    text: abbr
                }
            ]
        }})
    }

    function handleRemoveItem(id) {
        let nall = [...all];
        const index = nall.findIndex(x=> x === id);
        nall.splice(index,1);
        setAll(nall);

        let ndata = {...data};
        delete ndata[id];
        setData(ndata);
    }

    function handleEditItem(id, {field,value}) {
        let ndata = {...data};
        if(field==='title') {
            ndata[id].title = value;
        } else if(field==='emoji') {
            ndata[id].emoji = value;
        } else {
            const index = ndata[id].subtitle.findIndex(x=>x.field===field);
            ndata[id].subtitle[index].text = value;
        }
        setData(ndata);
    }

    return <div className="grid grid-cols-2 gap-4">
        <SimpleEditList onEditItem={handleEditItem} onDelete={handleRemoveItem} items={all.map(id=>{
            return {
                id,
                ...data[id]
            }
        })} title="Member Types" note={{
            item: <div>
                <Badge text={"Default"} size="xs" rounded color="blue"/>
            </div>,
            text: 'This is the default type new members are added as.'
        }} action={{icon:<PlusIcon />,text:'Custom',onClick:()=>handleAddItem('Custom Members','Custom Member','CM','')}} />
        <SearchableSuggestions tabs={tabs} suggestions={convertArr(member_types_suggestions)} onSelect={(it)=>{
            handleAddItem(it.title,it.subtitle[0].text,it.subtitle[1].text.replace('(','').replace(')',''),it.emoji)
        }} init_selected={Object.values(data).map(dt=>dt.title)} />
    </div>
}