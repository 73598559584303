import React from 'react';
import styled from "styled-components";

const Divider = styled.div`
    margin-top: 6px;
    margin-bottom: 6px;
`;

export const PM_Divider = ({}) => {
    return <Divider className='border-bottom' />
};