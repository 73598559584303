import {validateEmail} from "./validation";
import {getPhoneNumberData} from "./phone";
import moment from "moment";
import {isLessThanAYear, isLessThanSixMonths, isLessThanTwoYears} from "./general/dates";
import {isAnonymousUnatyEmail} from "./anonymous-emails";

const field_cases = {
    'email': {
        field: 'account_email',
        weight: 0.3,
        cases: {
            1: 'empty',
            2: 'invalid',
            4: 'set-not-verified',
            5: 'verified'
        },
        getScore: ({value, email_verified = null}) => {
            let m = [];
            let s = 0;
            if (!value||isAnonymousUnatyEmail(value)) {
                s = 1;
                m.push({
                    label: "Missing account email",
                    type: 'con'
                });
            } else if (!validateEmail(value)) {
                s = 2;
                m.push({
                    label: "Invalid account email",
                    type: 'con'
                });
            } else if (email_verified === false && validateEmail(value)) {
                s = 4;
                m.push({
                    label: "Valid account email",
                    type: 'pro'
                });
            } else {
                s = 5;
                m.push({
                    label: "Verified account email",
                    type: 'pro'
                });
            }
            return {
                score: s,
                messages: m
            };
        }
    },
    'phone': {
        field: 'contact.phone',
        weight: 0.2,
        cases: {
            1: 'empty',
            2: 'invalid',
            // add verified in future
            5: 'valid'
        },
        getScore: ({value}) => {
            let m = [];
            let s = 0;
            if (!value) {
                s = 1;
                m.push({
                    label: "Missing phone number",
                    type: 'con'
                });
            } else {
                const phone_data = getPhoneNumberData(value);
                if (phone_data.valid) {
                    s = 5;
                    m.push({
                        label: "Valid phone number",
                        type: 'pro'
                    });
                } else {
                    s = 2;
                    m.push({
                        label: "Invalid phone number",
                        type: 'con'
                    });
                }
            }
            return {
                score: s,
                messages: m
            };
        }
    },
    'reachability': {
        field: 'contact.phone',
        weight: 0.05,
        cases: {
            1: 'empty',
            3: 'phone-valid',
            5: 'reachability-set'
        },
        getScore: ({value, reachability}) => {
            let m = [];
            let s = 0;
            if (!value) {
                s = 1;
            } else if (reachability&&reachability.length === 0) {
                s = 3;
                m.push({
                    type: 'con',
                    label: 'Reachability not set'
                });
            } else {
                // we need to check the reachability
                s = 5;
                m.push({
                    type: 'pro',
                    label: 'Reachability set'
                });
            }
            return {
                score: s,
                messages: m
            };
        }
    },
    'address': {
        field: 'address',
        weight: 0.1,
        cases: {
            1: 'empty',
            2: 'invalid',
            3: 'incomplete',
            4: 'valid',
            // country might be missing or state so = 4, both 4 + 5 have geo coords
            5: 'valid-complete'
        },
        getScore: ({value}) => {
            let m = [];
            let s = 0;

            const {line_one, line_two, city, state, country, zip_code} = value;

            if (!city && !line_one && !country && !zip_code) {
                s = 1;
                m.push({
                    label: 'Empty address',
                    type: 'con'
                });
            } else if (!city && !zip_code) {
                // todo use geo
                s = 2;
                m.push({
                    label: 'Invalid address',
                    type: 'con'
                });
            } else if (!city || !zip_code || !line_one) {
                s = 3;
                m.push({
                    label: 'Incomplete address',
                    type: 'con'
                });
            } else if (!country || !state) {
                s = 4;
                m.push({
                    label: 'Valid address',
                    type: 'pro'
                });
            } else {
                s = 5;
                m.push({
                    label: 'Complete, valid address',
                    type: 'pro'
                });
            }

            return {
                score: s,
                messages: m
            };
        }
    },
    'profile-updated': {
        field: 'profile_updated_at',
        weight: 0.15,
        cases: {
            1: 'never-updated',
            2: 'updated-over-2-years-ago',
            3: 'updated-over-a-year-ago',
            4: 'updated-over-6-months-ago',
            5: 'updated-in-last-6-months'
        },
        getScore: ({value}) => {
            let m = [];
            let s = 0;
            if (!value) {
                s = 1;
                m.push({
                    label: 'Profile never updated',
                    type: 'con'
                });
            } else {
                const mo = moment(value);
                if (isLessThanSixMonths(mo)) {
                    s = 5;
                    m.push({
                        label: 'Updated this year',
                        type: 'pro'
                    });
                } else if (isLessThanAYear(mo)) {
                    s = 4;
                    m.push({
                        label: 'Updated this year',
                        type: 'pro'
                    });
                } else if (isLessThanTwoYears(mo)) {
                    s = 3;
                    m.push({
                        label: 'Updated last year',
                        type: 'con'
                    });
                } else {
                    s = 2;
                    m.push({
                        label: 'Updated years ago',
                        type: 'con'
                    });
                }
            }
            return {
                score: s,
                messages: m
            };
        }
    },
    'first-name': {
        field: 'about.first_name',
        weight: 0.25,
        cases: {
            1: 'empty',
            5: 'valid'
        },
        getScore: ({value}) => {
            let m = [];
            let s = 0;
            if (!value) {
                s = 1;
                m.push({
                    label: 'Missing first name',
                    type: 'con'
                });
            } else {
                s = 5;
            }
            return {
                score: s,
                messages: m
            };
        }
    },
    'last-name': {
        field: 'about.last_name',
        weight: 0.25,
        cases: {
            1: 'empty',
            5: 'valid'
        },
        getScore: ({value}) => {
            let m = [];
            let s = 0;
            if (!value) {
                s = 1;
                m.push({
                    label: 'Missing last name',
                    type: 'con'
                });
            } else {
                s = 5;
            }
            return {
                score: s,
                messages: m
            };
        }
    },
    'birthday': {
        field: 'birthday',
        weight: 0.1,
        cases: {
            1: 'empty',
            5: 'valid'
        },
        getScore: ({value}) => {
            let m = [];
            let s = 0;
            if (!value) {
                s = 1;
                m.push({
                    label: 'Missing birthday',
                    type: 'con'
                });
            } else {
                s = 5;
            }
            return {
                score: s,
                messages: m
            };
        }
    },
    'bio': {
        field: 'about.bio',
        weight: 0.01,
        cases: {
            1: 'empty',
            5: 'valid'
        },
        getScore: ({value}) => {
            let m = [];
            let s = 0;
            if (!value) {
                s = 1;
                m.push({
                    label: 'Missing bio',
                    type: 'con'
                });
            } else {
                s = 5;
            }
            return {
                score: s,
                messages: m
            };
        }
    },
    'profile-picture': {
        field: 'profile_picture',
        weight: 0.05,
        cases: {
            1: 'empty',
            5: 'valid'
        },
        getScore: ({value}) => {
            let m = [];
            let s = 0;
            if (!value) {
                s = 1;
                m.push({
                    label: 'Missing profile picture',
                    type: 'con'
                });
            } else {
                s = 5;
            }
            return {
                score: s,
                messages: m
            };
        }
    },
    'join-date': {
        field: 'join_date',
        weight: 0.02,
        cases: {
            1: 'empty',
            5: 'valid'
        },
        getScore: ({value}) => {
            let m = [];
            let s = 0;
            if (!value) {
                s = 1;
                m.push({
                    label: 'Missing join date',
                    type: 'con'
                });
            } else {
                s = 5;
            }
            return {
                score: s,
                messages: m
            };
        }
    }
};

const mock = {
    email_verified: false,
    reachability: '',

    about: {
        first_name: 'Peter',
        last_name: 'Jones',
        bio: ''
    },
    account_email: 'hello@getunaty.com',
    contact: {
        phone: '262-825-2883'
    },
    birthday: {
        date: null,
        month: null,
        year: null
    },
    join_date: null,
    address: {
        line_one: '',
        line_two: '',
        city: '',
        zip_code: '',
        country: '',
        state: ''
    }
};

function getValue(member, field) {
const sp = field.split('.');
if(sp.length>1) {
    return member[sp[0]][sp[1]];
} else {
    return member[sp[0]];
}
}

/*
Thresholds
1 = below 50 (below 2.58)
2 = 50%+ (2.58)
3 = 70%+ (3.62)
4 = 85%+ (4.39)
5 = 98%+ (5.07)

 */

export function getQualityLevel(score) {
    if(score>98) {
        return 'perfect';
    } else if(score>85) {
        return 'good';
    } else if(score>70) {
        return 'ok';
    } else if(score>50) {
        return 'poor';
    } else {
        return 'very-bad';
    }
}

export function profileQualityCalculator(member = mock) {
    let score = 0, max_score = 0, final_score = 0, min_score, messages = [];

    // need to convert score to out of 1 -> 0.64
    // need to return the level of quality, need to return pros
    const checks = Object.values(field_cases);

    for (let i = 0; i < checks.length; i++) {
        const check = checks[i];
        let s;

        let value = getValue(member, check.field);

        s = check.getScore({value,email_verified:member.email_verified,reachability:member.reachability});

        messages = messages.concat(s.messages);
        // score
        // messages
        max_score += check.weight * 5;

        score += s.score * check.weight;
    }

    // 5.175
    // 4.2
    final_score = Math.ceil((score / max_score) * 100);

    return {
        final_score,
        level: getQualityLevel(final_score),
        pros: messages.filter(a => a.type === 'pro'),
        cons: messages.filter(a => a.type === 'con')
    }
}