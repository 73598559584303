import React, {useEffect, useState} from 'react';
import {ActionWithIcon, Field, InfoState, Input, SectionHeader} from "./core";
import {CUSTOM_NANOID} from "../../config/defaults";

function parseValue(v) {
    if (!v || typeof v !== 'object' || !v.length) {
        return [];
    }
    return [...v];
}

const GroupModeratorsRules = () => <div>
    <div>
        Arguments passed for trigger function are group
    </div>
    <br/>
    <br/>
    <div>
        Trigger types: these all must be true for suggestions to show
        <div>
            name-contains: lowercase matching anywhere in sentence
        </div>
        <div>
            member-count-greaterthan:
        </div>
        <div>
            member-count-lessthan:
        </div>
        <div>
            moderator-roles-count-greaterthan:
        </div>
        <div>
            moderator-roles-count-lessthan:
        </div>
    </div>
    <br/>
    <br/>
    <div>
        Suggestion types:
        <div>
            create-role: f1 is name, f2 is assignee
        </div>
        <div>
            add-role: f1 is id
        </div>
    </div>

    <div>
        <br/>
        <br/>
        special logic for making suggestions on ambassadors / leaders / leads / moderators / mods / managers + Co
        support
    </div>
</div>;

const GroupDataQualityRules = () => <div>
    <div>
        Arguments passed for trigger function are group and member
    </div>
    <br/>
    <br/>
    <div>
        Trigger types: these all must be true for suggestions to show
        <div>
            is_group_moderator: true
        </div>
        <div>
            member-count-greaterthan:
        </div>
        <div>
            member-count-lessthan:
        </div>
        <div>
            member_field_string_is: value = actual value (logged-in), modifier = member field (account_status)
        </div>
        <div>
            member_field_string_isnot: value = actual value (logged-in), modifier = member field (account_status)
        </div>
        <div>
            member_field_string_isoneof: value = actual value (logged-in,missing-email), modifier = member field (account_status)
        </div>
        <div>
            member_field_string_isnotoneof: value = actual value (logged-in,missing-email), modifier = member field (account_status)
        </div>
        <div>
            member_field_string_empty: value = boolean (true), modifier = member field (contact.phone,profile_picture)
        </div>
        <div>
            member_field_date_before: value = 6-months-ago (string), modifier = member field (profile_updated_at)
        </div>
    </div>
    <br/>
    <br/>
    <div>
        Suggestion types:
        <div>
            send-invite: f1 is
        </div>
        <div>
            resend-invite: f1 is
        </div>
        <div>
            send-data-check: f1 is
        </div>
        <div>
            crowdsource-email: f1 is
        </div>
    </div>

</div>;

const CommunityDataQualityRules = () => <div>
    <div>

    </div>
    <br/>
    <br/>
    <div>
        Trigger types: these all must be true for suggestions to show
        <div>
            name-contains: lowercase matching anywhere in sentence
        </div>
    </div>
    <br/>
    <br/>
    <div>
        Suggestion types:
        <div>
            tbd: f1 is x, f2 is y
        </div>
    </div>

</div>;

export function RulesEditor({field, value, scope = 'moderators', context = 'group', onChange, onBlur}) {
    const [rules, setRules] = useState(parseValue(value));
    const isFirstRun = React.useRef(true);
    const _id = React.useRef(`${context}-${scope}`);

    useEffect(function () {
        //setRules(parseValue(value))
        if (`${context}-${scope}` !== _id.current) {
            _id.current = `${context}-${scope}`;
            setRules(parseValue(value))
        }
    }, [value, scope, context])

    useEffect(function () {
        if (isFirstRun.current) {
            isFirstRun.current = false;
            return;
        }
        onChange(rules)
    }, [rules])

    function addRule() {
        const new_rule = {
            id: CUSTOM_NANOID(),
            name: '',
            context: context,
            scope: scope,
            triggers: [],
            suggestions: []
        };
        setRules([
            ...rules,
            new_rule
        ])
    }

    function handleChange(id, f, v, p, cid) {
        let index = rules.findIndex(x => x.id === id);
        let nr = [...rules];
        if (p) {
            const c_index = nr[index][p].findIndex(x => x.id === cid);
            nr[index][p][c_index][f] = v;
        } else {
            nr[index][f] = v;
        }
        setRules(nr);
    }

    function addTrigger(id) {
        let index = rules.findIndex(x => x.id === id);
        const new_trigger = {
            id: CUSTOM_NANOID(),
            type: 'name-contains',
            value: 'hub',
            modifier: ''
        };
        let nr = [...rules];
        nr[index].triggers.push(new_trigger);
        setRules(nr);
    }

    function deleteTrigger(id, tid) {
        let index = rules.findIndex(x => x.id === id);

        let nr = [...rules];

        let trigger_index = nr[index].triggers.findIndex(x => x.id === id);

        nr[index].triggers.splice(trigger_index, 1);

        setRules(nr);
    }

    function removeRule(id) {
        let index = rules.findIndex(x => x.id === id);

        let nr = [...rules];

        nr.splice(index, 1);

        setRules(nr);
    }

    function deleteSuggestion(id, sid) {
        let index = rules.findIndex(x => x.id === id);

        let nr = [...rules];

        let trigger_index = nr[index].suggestions.findIndex(x => x.id === id);

        nr[index].suggestions.splice(trigger_index, 1);

        setRules(nr);
    }

    function addSuggestion(id) {
        let index = rules.findIndex(x => x.id === id);
        const new_suggestion = {
            id: CUSTOM_NANOID(),
            type: 'create-role',
            f1: 'Ambassador',
            f2: ''
        };
        let nr = [...rules];
        nr[index].suggestions.push(new_suggestion);
        setRules(nr);
    }

    return <div>
        <div className="space-y-4">
            {rules.map(rule => {
                return <div className="p-3 rounded-md border border-gray-200 space-y-3" key={rule.id}>
                    <div>Rule ID: {rule.id}</div>
                    <div>
                        <ActionWithIcon onClick={() => removeRule(rule.id)} text="Remove Rule"/>
                    </div>
                    <Field label="Name">
                        <Input value={rule.name} onChange={v => handleChange(rule.id, 'name', v)}/>
                    </Field>
                    <Field label="Scope">
                        <Input value={rule.scope} onChange={v => handleChange(rule.id, 'scope', v)}/>
                    </Field>
                    <Field label="Triggers">
                        <div className="space-y-2">
                            {rule.triggers.map(t => {
                                return <Field key={t.id} label={t.id} corner_hint={<ActionWithIcon text="Delete"
                                                                                                   onClick={() => deleteTrigger(rule.id, t.id)}/>}>
                                    <div className="grid grid-cols-2 gap-3">
                                        <Input placeholder="type" value={t.type}
                                               onChange={v => handleChange(rule.id, 'type', v, 'triggers', t.id)}/>
                                        <Input placeholder="value" value={t.value}
                                               onChange={v => handleChange(rule.id, 'value', v, 'triggers', t.id)}/>
                                        <Input placeholder="modifier" value={t.modifier}
                                               onChange={v => handleChange(rule.id, 'modifier', v, 'triggers', t.id)}/>
                                    </div>
                                </Field>
                            })}
                        </div>
                        <div className="py-2">
                            <ActionWithIcon inverse text="Add Trigger" onClick={() => addTrigger(rule.id)}/>
                        </div>
                    </Field>
                    <Field label="Suggestions">
                        {rule.suggestions.map(s => {
                            return <Field key={s.id} label={s.id} corner_hint={<ActionWithIcon text="Delete"
                                                                                               onClick={() => deleteSuggestion(rule.id, s.id)}/>}>
                                <div className="grid grid-cols-2 gap-3">
                                    <Input placeholder="type" value={s.type}
                                           onChange={v => handleChange(rule.id, 'type', v, 'suggestions', s.id)}/>
                                    <Input placeholder="f1" value={s.f1}
                                           onChange={v => handleChange(rule.id, 'f1', v, 'suggestions', s.id)}/>
                                    <Input placeholder="f2" value={s.f2}
                                           onChange={v => handleChange(rule.id, 'f2', v, 'suggestions', s.id)}/>
                                </div>
                            </Field>
                        })}
                        <div className="py-2">
                            <ActionWithIcon inverse text="Add Suggestion" onClick={() => addSuggestion(rule.id)}/>
                        </div>
                    </Field>
                </div>
            })}
            {rules.length === 0 && <InfoState fill subtitle="No rules found"/>}
        </div>
        <div className='py-2'>
            <ActionWithIcon inverse text="Add Rule" onClick={() => addRule()}/>
        </div>

        <div className="space-y-4">
            <div>
                <SectionHeader padded={false} title="Documentation"/>
                {context === 'group' && scope === 'moderators' && <GroupModeratorsRules/>}
                {context === 'group' && scope === 'data-quality' && <GroupDataQualityRules/>}
                {context === 'community' && scope === 'data-quality' && <CommunityDataQualityRules/>}
            </div>

<div>
    Manual Change:<br/>
    <textarea onPaste={e=>{
        const v = e.clipboardData.getData('Text');
        if(typeof v==='string') {
            const a = JSON.parse(v);
            if(a&&typeof a === 'object' && a[0]) {
                setRules([...a])
            }
        }
    }} placeholder="Paste in JSON" className="w-full border border-gray-200 p-2" />
</div>
        </div>
    </div>
}