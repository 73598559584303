import React, {useState} from 'react';
import {Button} from "../../../../library/components/core";
import {RichTextEditor} from "../../../../library/components/composer/rich-text-editor";
import {prefillContentState} from "../../../../library/components/composer";

export function RichTextTestWrapper() {

    const [editorState, setEditorState] = useState(null);

    const initial_content = editorState ? editorState.getCurrentContent() : prefillContentState(null);

    return <div className="p-8">
        <RichTextEditor auto_focus={false} show_add_signature={true}
                        show_tools debug
                        upload_files_to_folder={() => {}}
                        handleClose={() => {
                        }} mode={"full-screen"}
                        updateAttachments={() => {
                        }} folder_id={""} fc={0}
                        content_state={initial_content} placeholder={"Start typing.."}
                        onChange={(editor_state) => {
                            setEditorState(editor_state)
                        }}
        />
    </div>
}