import React, {useContext, useState} from "react";
import styled from "styled-components";
import {
    EntityInfo
} from "../../../../../library/utilities/localstorage/entity-localstorage";

import EditModal from "../../../../../library/components/old/edit-modal/edit-modal";
import {Badge, Button, EntityHeader, PageHeader} from "../../../../../library/components/core";
import {PageContainer} from "../../../../../library/components/old/page-container";
import {HotkeyWrapper} from "../../../../../library/components/old/hotkey-wrapper";
import {authFetch} from "../../../../../config/network";
import {PlusIcon, MinusCircleIcon, EyeIcon} from '@heroicons/react/solid';
import {COMMUNITY_PLANS} from "../../../../../config/defaults";
import {CommunityContext} from "../../../community-context";
import {GlobalContext} from "../../../../global/global-context";
import {DoubleCheckModal} from "../../../../../library/components/old/double-check-modal";
import {StandardPageFramework, StandardPageHeight} from "../../../../../library/playground/standard-page-framework";

function buildAdminObj(admins, community_uid) {

    let obj = {};
    for (let i = 0; i < admins.members.length; i++) {
        const user_uid = admins.members[i];
        obj[`member-${user_uid}`] = {};
    }
    //
    for (let i = 0; i < admins.roles.length; i++) {
        const role_id = admins.roles[i];
        obj[`role-${role_id}`] = {};
    }
    return obj;
}

const Frame = styled.div`
  margin-top: 8px;
`;

const Outer = styled.div`
  padding: 8px 12px;
  line-height: 24px;
`;

const Inner = styled.div`
  display: flex;
`;

const Left = styled.div`
  flex-grow: 1;
  display: flex;
`;

const Right = styled.div`

`;

const Title = styled.div`
  font-size: 16px;
  font-weight: 500;
  line-height: 30px;
  margin-right: 12px;
`;

const Item = ({remove, data, is_current_user, is_owner}) => {
    return <Outer className='border-bottom'>
        <Inner>
            <Left>
                <Title className='text-gray-800'>{data.name}</Title>
            </Left>
            <Right>
                <div className="flex space-x-2">
                    {is_owner && <Badge size="md" text="Owner" color="orange"/>}
                    {!is_current_user && !is_owner &&
                    <Button icon={<MinusCircleIcon/>} onClick={remove} text='Remove'/>}
                </div>
            </Right>
        </Inner>
    </Outer>;
};

export default function SettingsAdmins(props) {
    const community = useContext(CommunityContext);
    const global = useContext(GlobalContext);

    const is_owner = community.data.plan.account_owner === community.member_id;

    const [loading, setLoading] = useState('');
    const [remove, setRemove] = useState(null);
    const [modal, setModal] = useState(null);

    if (!is_owner) {
        return <PageContainer>
            <PageHeader title='Only the owner can manage admins.' size="2xl">

            </PageHeader>
        </PageContainer>
    }

    function removeAdmin() {
        const {type, id} = remove;

        const res = () => {
            setModal(null);
            global.addToast({text: "Admin was removed", intent: 'success'});
        };

        const payload = {
            type,
            id,
            community_uid: community.uid,
            member_id: community.member_id,
            user_uid: global.user_uid
        };

        authFetch("/communities/remove-admin", res, res, "POST", {payload});
    }

    const {history} = props;
    const {admin_data, admins} = community;

    const base_link = `/${community.data.handle}/admin/settings`;

    const edit_entities = {
        _initial: {
            admins: {members: {}, roles: {}}
        },
        _existing: {members: admin_data.members, roles: admin_data.roles},
        _hide_existing: true,
        _enable_progress_bar: true,
        _fields: [
            {
                type: 'admins',
                field: 'admins',
                label: 'Add Members'
            }
        ],
        _endpoint: '/communities/add-admins',
        _list_id: '',
        _community_uid: community.uid,
        _title: 'Add Admins'
    };

    const hotkeys = [
        {
            id: 'A',
            keys: ["a"],
            fn: () => {
                setModal('_open_admins');
            }
        }
    ];

    const member_ids = Object.keys(admin_data.members);
    const role_ids = Object.keys(admin_data.roles);

    const final_data = buildAdminObj({members: member_ids, roles: role_ids}, community.uid);

    const should_disable = admins.length > (COMMUNITY_PLANS.STANDARD.admin_seats - 1);

    return (<StandardPageHeight content>
            <EntityHeader title="Admins" goBackTo={() => history.push(`/${community.data.handle}/admin/settings`)}
                          actions={<Button disabled={false}
                                           onClick={() => setModal('_open_admins')} intent='secondary'
                                           left_icon={<PlusIcon/>} shortcut={'N'}
                                           text='Add Admin'/>}/>

            <Frame className='border-top'>
                {member_ids.map((mid) => {
                    if (!final_data[`member-${mid}`]) {
                        return null;
                    }

                    return <EntityInfo community_uid={community.uid} id={mid} type={'members'}>
                        <Item key={mid} data={final_data[`member-${mid}`]}
                              is_owner={mid === community.data.plan.account_owner}
                              is_current_user={mid === community.member.user_uid} remove={() => {
                            setRemove({type: 'member', id: mid});
                            setModal('double-check-delete');
                        }}/>
                    </EntityInfo>
                })}
                {role_ids.map((rid) => {

                    return <EntityInfo community_uid={community.uid} id={rid} type={'roles'}>
                        <Item key={rid} data={final_data[`role-${rid}`]} is_owner={false}
                              is_current_user={false} remove={() => {
                            setRemove({type: 'role', id: rid});
                            setModal('double-check-delete');
                        }}/>
                    </EntityInfo>
                })}
            </Frame>

            {modal === 'double-check-delete' && <DoubleCheckModal onConfirm={() => removeAdmin()} onClose={() => {
                setRemove(null);
                setModal(null)
            }} onCancel={() => {
                setRemove(null);
                setModal(null)
            }} type='admin_remove'/>}

            {modal === '_open_admins' &&
            <EditModal {...edit_entities} member_id={community.member_id} member_uid={community.member_id} open={true}
                       onClose={() => {
                           setModal(null);
                       }}/>}
        </StandardPageHeight>
    );
}