import React from 'react';
import {Avatar} from "./avatar";
import {EntityInfo} from "../../utilities/localstorage/entity-localstorage";
import ModeratorIcon from "../old/moderator-icon";
import {DEFAULT_USER_PHOTO} from "../../../config/defaults";

function Item({title,suffix,layout,entity_type,subtitle,image,data}) {
    let ftitle, fimage, fsubtitle;

    if(data) {
        if(entity_type==='members') {
            ftitle = data.name;
            fsubtitle = null;
            fimage = data.image;
        } else if(entity_type==='roles') {
ftitle = data.name;
fsubtitle = data.assignee_name ? data.assignee_name : '';
        }

    } else {
        ftitle = title;
        fsubtitle = subtitle;
        fimage = image;
    }

    return <div className={`flex flex-none max-w-sm items-center ${layout==='grid'?'w-32 justify-center':'w-24'} flex-col`}>
        <Avatar url={fimage ? `${fimage}_small?alt=media` : ''} text={ftitle} size="lg" />
        <div className="pt-2 w-full items-center justify-center flex-wrap-none items-baseline flex text-sm font-semibold text-gray-800">
            <span style={{maxWidth:'85%'}} className="whitespace-nowrap max-w-xs truncate inline-block">{ftitle}</span>
            {suffix&&<span className="ml-0.5" style={{height:'12px'}}>{suffix}</span>}
        </div>
        {fsubtitle&&<div className="w-full items-center justify-center flex-wrap-none items-baseline flex text-xs font-medium text-gray-600">
            <span style={{maxWidth:'85%'}} className="whitespace-nowrap max-w-xs truncate inline-block">{fsubtitle}</span>
        </div>}
    </div>
}

function getCols(width) {

    if(width>1000) {
        return 'grid-cols-5';
    } else if(width>768) {
        return 'grid-cols-4';
    } else {
        return 'grid-cols-3';
    }
}

function getNumToRender(width,total) {

    if(width>1000) {
        return total > 10 ? 10 : total;
    } else if(width>768) {
        return total > 8 ? 8 : total;
    } else {
        return total > 6 ? 6 : total;
    }
}

const sf_map = {
  comp: <ModeratorIcon height={16} fill={"#F15F31"} />,
  user_uids: {
      '1234': true
  }
};

// moderators should be at the front of the list
const layouts = [
    'grid',
    'hscroll'
];

function getContainer(layout) {
    if(layout==='hscroll') {
        return 'flex overflow-x-scroll -ml-2  pb-4 max-w-2xl';
    } else {
        return 'grid gap-6 max-w-2xl';
    }
}
export function UsersGrid({total=21,layout='grid',entity_type='members',user_data={},user_uids=["1234", "5234", "5343", "3424", "6432", "6234", "9642", "9342", "2596", "2395", "2935", "2493"],suffix_map=sf_map,community_uid,use_entity_info=false}) {

    const width = window.innerWidth;

    const num_to_render = getNumToRender(width, total);

    const render_ids = user_uids.slice(0,num_to_render);

    return <div className={`${getContainer(layout)}  ${getCols(width)}`}>
        {render_ids.map((id,i)=>{

            if(use_entity_info) {
                if(!id||!community_uid) {
                    return <div>

                    </div>
                }
                return <EntityInfo type={entity_type} community_uid={community_uid} id={id}>
                    <Item entity_type={entity_type} layout={layout} key={id} use_entity_info={use_entity_info} />
                </EntityInfo>
            } else {
                const data = user_data[id] ? user_data[id] : {
                    title: 'President',subtitle:'Jon Tester',suffix:'',image:DEFAULT_USER_PHOTO,data:null
                };
                if(!data) {
                    return null;
                }
                return <Item entity_type={entity_type} layout={layout} key={id} {...data} use_entity_info={use_entity_info} />
            }
        })}
    </div>
}