import React, {useContext, useState} from "react";
import Modal from "../old/modal";
import {Button, Field, InfoState, InlineLoader, TextArea} from "../core";
import {ArrowLeftIcon, CheckIcon, HomeIcon, InformationCircleIcon, MailIcon, PhoneIcon} from "@heroicons/react/solid";
import {genders, genders_object} from "../../data/general/genders";
import {BasicSelect} from "../core/basic-select";
import {GlobalContext} from "../../../app/global/global-context";
import {CommunityContext} from "../../../app/community/community-context";
import {CUSTOM_NANOID} from "../../../config/defaults";
import {authFetch} from "../../../config/network";

/*
States
- overview with options to report different parts of the profile
- pages for email, phone, and address direct reporting
- perhaps a dropdown with the different profile fields
- suggestion field
 */

const mock_options = [
    {
        icon: <MailIcon />,
        id: 'contact.email',
        type: 'email',
        label: 'Incorrect Email',
        value: 'hello@getunaty.com'
    },
    {
        icon: <PhoneIcon />,
        id: 'contact.phone',
        type: 'phone',
        label: 'Incorrect Phone',
        value: '+49 160 103 1897'
    },
    {
        icon: <HomeIcon />,
        id: 'address',
        type: 'address',
        label: 'Incorrect Address',
        value: '3200 Paseo Village Way...'
    },
    {
        icon: <InformationCircleIcon />,
        id: '*',
        type: 'general',
        label: 'General Suggestion',
        value: 'About any profile field'
    }
];

export function MakeDataSuggestionModal({
                                            onClose = ()=>{},
                                            profile_member_id = '',
    initial_state = '',
    options = mock_options
                                        }) {
const global = useContext(GlobalContext);
const community = useContext(CommunityContext);
    const [loading,setLoading] = useState('');
    const [state,setState] = useState(initial_state);
    const [message,setMessage] = useState('');

    const dropdown_options = options.map(o=>{
        return {
            id: o.id,
            type: o.type,
            description: o.value,
            value: o.id,
            item_icon: o.icon,
            label: o.label
        }
    });

    let it = dropdown_options.filter(a=>a.id===state)[0];

    function submit() {
        setLoading('submitting');

        const res = () => {
            setLoading('submitted');
            global.addToast({text:'Suggestion submitted - thank you!'});
        };
        const err = () => {

        };
        const payload = {
            type: 'member',
            community_uid: community.uid,
            member_id: community.member_id,
            id: profile_member_id,
            field: state,
            value: message
        };

        authFetch("/data-suggestions/new", res, err, "POST", {payload});
    }

    const body = <div>
        {loading==='submitted'&&<div className="flex justify-center">
            <InfoState button={<Button text="Done" onClick={()=>onClose()} />} intent="success" icon={<CheckIcon />} title="Thank you for your help!" subtitle={"Your suggestion has been submitted."} />
        </div>}
        {loading==='submitting'&&<div>
            <InlineLoader padding="p-10" />
        </div>}
        {!state&&!loading&&<div>
            <div className="border-t border-gray-300">
            {dropdown_options.map(opt=>{
                return <div onClick={()=>setState(opt.id)} className="flex cursor-pointer space-x-2 px-2.5 py-1.5 hover:bg-gray-100 border-b border-gray-300" key={opt.id}>
                    <div className="flex-none p-1">
                        <div className="w-4 h-4 text-gray-600">
                            {opt.item_icon}
                        </div>
                    </div>
                    <div className="flex-grow">
                        <div className="text-base font-medium text-gray-800">{opt.label}</div>
                        <div className="truncate text-xs text-gray-500" style={{maxWidth:'90%'}}>{opt.description}</div>
                    </div>
                </div>
            })}
            </div>
        </div>}
        {state&&!loading&&<div>
            <div className="space-y-2">
            <Field label='Field'>
                <BasicSelect placeholder="" searchable={true} value={!state ? null : it}
                             onChange={(v) => {
                                 setState(v.id)
                             }} options={dropdown_options}/>
            </Field>
                {it&&it.value&&<div className="py-1 text-sm text-gray-700">
                    Currently: {it.description}
                </div>}
                <TextArea autoFocus placeholder="Do you have any more information?" value={message} onChange={v=>setMessage(v)} />
            </div>
        </div>}
        {}
    </div>;

    const buttons = state && !loading ? <div>
        <Button disabled={!message} onClick={()=>submit()} loading={loading==='submitting'} intent="secondary" text="Submit" />
    </div> : null;

    const left_button = !state || loading ? null : <Button onClick={()=>setState('')} minimal size="sm" left_icon={<ArrowLeftIcon />} text="Back to Options" />;

    const subtitle = state || loading ? "" : "What's wrong with this data?"

    return <Modal size="small" subtitle={subtitle} title={"Make Data Suggestion"} body={body} footerRight={buttons} footerLeft={left_button}
                  onClose={onClose} open={true} lazy />
}