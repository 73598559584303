import React from "react";
import {ProfileUpdatedTag} from "./profile-updated-tag";
import {Popover2} from "@blueprintjs/popover2";
import styled from "styled-components";
import {getProfileUpdatedScore, member_profile_data_quality_ref} from "./profile-updated-utilities";
import {getPrettyTime} from "../../utilities/general/dates";
import moment from "moment";

const Outer = styled.div`
  
  > div {
    display: flex;
    height: ${props=>props.indicator?"14px":"20px"};
  }
`;

function buildFinalMessage(message, name, last_updated, you) {
    if (you) {
        return message.replace(`{name}'s`, 'Your').replace('{date}', last_updated ? getPrettyTime(moment.unix(last_updated / 1000)) : "never");

    } else {
        return message.replace('{name}', name).replace('{date}', last_updated ? getPrettyTime(moment.unix(last_updated / 1000)) : "never");
    }
}

export function ProfileUpdatedIcon({
                                       last_updated,
                                       indicator=false,
                                       is_you,
                                       name = "",
                                       show_label,
                                       warnings = [],
                                       created_at = Date.now()
                                   }) {
    const score = getProfileUpdatedScore(last_updated, created_at, warnings);

    const {bg, color, message, admin_message, icon, label} = member_profile_data_quality_ref[score];

    const final_admin_message = buildFinalMessage(admin_message, name, last_updated, is_you);

    const content = <div className="rounded-md">

        <div className="px-3 py-3 rounded-md">
            <div className="text-sm text-gray-700">
                {final_admin_message}
            </div>

        </div>
    </div>;

    return <Outer indicator={indicator}>
        <Popover2 targetTagName='div' interactionKind='hover' placement={'bottom-start'}
                  popoverClassName='minimal-popover w-72 rounded-md shadow-lg border border-gray-200' minimal
                  content={content}>
            <div className="hover:opacity-70 inline-flex transition-opacity cursor-pointer">
                {!indicator&&<ProfileUpdatedTag message={message} label={label} icon={icon} show_label={true} date={last_updated}
                                   score={score} color={color}/>}
                {indicator&&<div className={`h-3.5 w-3.5 border-2 border-white ${color.replace('text-','bg-')} rounded-full`}></div>}
            </div>
        </Popover2>
    </Outer>
}