import React, {useContext} from "react";
import {PageContainer} from "../../../../../../library/components/old/page-container";
import EditFieldsSection from "../../../../../../library/components/old/edit-fields-section";
import {CommunityContext} from "../../../../community-context";
import {TeamsRanker} from "../../teams-rank/ranker";
import CommonCard from "../../../../../../library/components/old/card";

export default function SettingsPreferencesOld(props) {
    const community = useContext(CommunityContext);

    const {history} = props;

    const {data} = community;

    const {preferences} = data;

    const {language, timezone} = preferences.timedate;

    const obj = {
        base_link: `/${data.handle}/admin/settings`,
        data: {
            language, timezone, ...data
        },
        section_text: 'Time & Date',

        endpoint: '/communities/update-timedate',
        update_fields: ['language', 'timezone'],

        sections: [
            {
                title: 'Time & Date',
                desc: 'Desc',
                fields: [
                    {
                        name: 'Timezone',
                        type: 'timezone',
                        field: 'timezone'
                    },
                    {
                        name: 'Language',
                        type: 'language',
                        field: 'language'
                    }
                ]
            }
        ]
    };

    return (<div>
            <EditFieldsSection history={history} {...obj} />
        </div>
    );
}