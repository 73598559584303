export const PermissionsRef = {
    'login': {
        title: 'Login',
        text: ''
    },

    'create_lists': {
        title: 'Create Lists',
        text: ''
    },

    'edit_lists': {
        title: 'Edit Lists',
        text: ''
    },

    'delete_lists': {
        title: 'Delete Lists',
        text: ''
    },

    'create_members': {
        title: 'Create Members',
        text: ''
    },

    'edit_members': {
        title: 'Edit Members',
        text: ''
    },

    'delete_members': {
        title: 'Delete Members',
        text: ''
    },

    'export_members': {
        title: 'Export Members',
        text: ''
    },


    'add_admins': {
        title: 'Add Admins',
        text: ''
    },

    'remove_admins': {
        title: 'Remove Admins',
        text: ''
    },

    'edit_community': {
        title: 'Edit Community',
        text: ''
    },

    'edit_billing': {
        title: 'Edit Billing',
        text: ''
    },



    'create_member_types': {
        title: 'Create Member Types',
        text: ''
    },

    'edit_member_types': {
        title: 'Edit Member Types',
        text: ''
    },

    'delete_member_types': {
        title: 'Delete Member Types',
        text: ''
    },

    'view_members': {
        title: 'View Members',
        text: ''
    },

    'view_files': {
        title: 'View Files',
        text: ''
    },

    'view_lists': {
        title: 'View Lists',
        text: ''
    },
/*
    'create_custom_fields': {
        title: 'Create Custom Fields',
        text: ''
    },

    'edit_custom_fields': {
        title: 'Edit Custom Fields',
        text: ''
    },

    'delete_custom_fields': {
        title: 'Delete Custom Fields',
        text: ''
    },
*/
    'toggle_build_mode': {
        title: 'Toggle Build Mode',
        text: ''
    }
};

function convertToArr() {
    let arr = [];
    const keys = Object.keys(PermissionsRef);

    for(let i=0;i<keys.length;i++) {
        const key = keys[i];
        const perm = PermissionsRef[key];
        arr.push({
            label: perm.title,
            value: key
        })
    }

    return arr;
}

export const PermissionsSelectOptions = convertToArr();