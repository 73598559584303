import React from "react";
import {getFileTypeIcon} from "../../old/file-uploader/helpers";
import styled from "styled-components";
import {CheckIcon} from '@heroicons/react/solid';
import {InlineLoader} from "../../core";

const Wrapper = styled.div`
    display: grid;
    grid-template-columns: 28px 1fr 28px;
    padding: 10px 12px;
    grid-gap: 4px;
`;

const IconCol = styled.div`
        display: flex;
    align-items: center;
    > svg {
        height: 26px;
    }
`;

const TitleCol = styled.div`
    
`;

const Title = styled.div`
        font-weight: 500;
`;

const Subtitle = styled.div`
        display: flex;
    height: 15px;
    align-items: center;
`;

const UploadedText = styled.div`
    
`;

const ActionCol = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`;

const Action = styled.div`
    height: 20px;
    width: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
        height: 16px;
    }
`;



export function UploadProgressItem({progress=0.1,cancelUpload,error,paused,name='File Name',type='image/png'}) {
    const complete = progress === 1;
    return <Wrapper className='hover-bkg-2'>
        <IconCol>
            {getFileTypeIcon(type)}
        </IconCol>
        <TitleCol>
            <Title className='text-gray-700 text-sm line-clamp-1'>{name}</Title>
            <Subtitle className={"text-xs"}>
                {complete&&!error&&<UploadedText className='color-green'>Upload complete</UploadedText>}
                {error&&<UploadedText className='color-red'>Upload failed</UploadedText>}
                {paused&&<UploadedText className='text-gray-700'>Waiting..</UploadedText>}
                {!complete&&<UploadedText className='text-gray-700'>{Math.ceil((progress*100))}% complete</UploadedText>}
            </Subtitle>
        </TitleCol>
        <ActionCol>
            <Action className={!complete?'hover-bkg':''}>
                {!complete?<>
                        <InlineLoader mini />
                    </>
                    :
                    <CheckIcon className='color-green' />}
            </Action>
        </ActionCol>
    </Wrapper>
}