import React, {useEffect, useState} from "react";
import {getAdvancedImageBackgroundStyle} from "../../../../../../library/components/reorganize/profile-card/cover";

function shouldHideSection(section, filter) {
    if (filter === 'my-profile') {
        if (section === 'all-members') {
            return false;
        } else if (section === 'admins-only') {
            return true;
        } else if (section === 'private') {
            return false;
        } else if (section === 'read-only') {
            return false;
        } else if (section === 'hidden') {
            return true;
        } else {
            // system
            return false;
        }
    } else if (filter === 'other-profile') {
        if (section === 'all-members') {
            return false;
        } else if (section === 'admins-only') {
            return true;
        } else if (section === 'private') {
            return true;
        } else if (section === 'read-only') {
            return true;
        } else if (section === 'hidden') {
            return true;
        } else {
            // system
            return false;
        }
    } else if (filter === 'admin-view') {
        if (section === 'all-members') {
            return false;
        } else if (section === 'admins-only') {
            return false;
        } else if (section === 'private') {
            return false;
        } else if (section === 'read-only') {
            return false;
        } else if (section === 'hidden') {
            return true;
        } else {
            // system
            return false;
        }
    } else {
        return false;
    }
}

export function CADPreview({
                               visibility_filter = 'all-members',
                               sections,
                               cover_photo = "",
                               cover_photo_color = "",
                               profile_picture = "",
                               display_name = ""
                           }) {

    const [pt,setPt] = useState(visibility_filter);
    useEffect(function() {
        setPt(visibility_filter);
    }, [visibility_filter]);
    const cp_color = cover_photo_color ? cover_photo_color : '';
    const pp = profile_picture ? `${profile_picture}?alt=media` : '';
    return <div className="shadow-sm bg-white rounded-md p-4 space-y-4">
        <div className="pb-4">
            <div style={getAdvancedImageBackgroundStyle("community",cover_photo,cp_color,'auto','')}
                 className={`bg-gray-200 bg-center bg-cover shadow-md rounded-md w-full h-32 relative`}>
                <div style={{backgroundImage: `url('${pp}')`}}
                     className="border-3 bg-center bg-cover -bottom-6 left-3 border-white h-20 w-20 rounded-42 bg-gray-400 absolute"/>
            </div>
            <div className="pl-3 pt-6">
                <div className="font-semibold pt-1 text-gray-800 text-base">
                    <span className="capitalize">
                    {display_name.replace(/_/g, " ")}
                    </span>
                </div>
            </div>
        </div>
        <div className="space-y-4">
            {sections.map((sec, k) => {
                const should_hide = shouldHideSection(sec.visibility, pt);
                if (should_hide) {
                    return null;
                }
                return <div key={k}
                            className={`border border-gray-300 shadow-sm bg-white rounded-md px-3 py-2.5 w-full relative`}>
                    <div className={`text-gray-700 font-semibold text-sm pb-2`}>
                        {sec.name}
                    </div>
                    <div className={`grid grid-cols-2 gap-4`}>
                        {sec.fields.map((f, j) => <div key={j}>
                            <div className="text-xs text-gray-500">{f.name}{f.required?"*":""}</div>
                            <div className="mt-1 rounded bg-gray-200 h-4 mr-8"/>
                        </div>)}
                    </div>
                </div>
            })}
        </div>
    </div>
}