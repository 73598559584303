import {countries} from "../../data/general/countries";
import {prepCustomFields} from "./segment-suggestions";

export function renderArrayStrings(arr, join = 'and', transform = (v) => v) {
    if (arr.length === 1) {
        return transform(arr[0])
    } else if (arr.length === 2) {
        return `${transform(arr[0])} ${join} ${transform(arr[1])}`
    } else {
        const na = [...arr].slice(0, arr.length - 1).map(a => transform(a));
        return `${na.join(", ")} ${join} ${transform(arr[(arr.length - 1)])}`
    }
}

// zip code, city, country, archived, member_type, last_sign_in, sessions, custom select, number, archived, enriched age, gender, profile_completeness, profile_quality,
const mapping = {
    // zip_code, city
    'string': {
        operators: {
            'starts_with': (meta, value) => {
                return `${meta.label} starts with "${value}"`;
            },
            'is': (meta, value) => {
                return `${meta.label} is "${value}"`;
            },
            'is_not': (meta, value) => {
                return `${meta.label} is not "${value}"`;
            },
        }
    },
    // archived
    'boolean': {
        operators: {
            'true': (meta, id) => {
                return `test`;
            },
            'false': (meta, id) => {
                return `test`;
            },
        }
    },
    'date': {
        operators: {
            'is_before': (meta, id) => {
                return `test`;
            },
            'is_after': (meta, id) => {
                return `test`;
            },
        }
    },
    'country': {
        operators: {
            'is': (meta, id) => {
                if (!id) {
                    return '-';
                }
                return `${meta.label} is ${countries[id]}`
            },
            'is_one_of': (meta, ids) => {
                if (ids.length === 0) {
                    return '-';
                }
                return `${meta.label} is ${renderArrayStrings(ids.map(id => countries[id]), 'or')}`
            },
            'is_not': (meta, id) => {
                if (!id) {
                    return '-';
                }
                return `${meta.label} is not ${countries[id]}`
            },
            'is_not_one_of': (meta, ids) => {
                if (ids.length === 0) {
                    return '-';
                }
                return `${meta.label} is not ${renderArrayStrings(ids.map(id => countries[id]), 'or')}`
            },
        }

    },
    'member_type': {
        operators: {
            'is': (meta, id, member_types) => {
                if (!id||!member_types||!member_types[id]) {
                    return '-';
                }
                return `${meta.label} is ${member_types[id].plural}`
            },
            'is_one_of': (meta, ids, member_types) => {
                if (ids.length === 0||!member_types) {
                    return '-';
                }
                return `${meta.label} is ${renderArrayStrings(ids.map(id => member_types[id].plural), 'or')}`
            },
            'is_not': (meta, id, member_types) => {
                if (!id||!member_types||!member_types[id]) {
                    return '-';
                }
                return `${meta.label} is not ${member_types[id].plural}`
            },
            'is_not_one_of': (meta, ids, member_types) => {
                if (ids.length === 0||!member_types) {
                    return '-';
                }
                return `${meta.label} is not ${renderArrayStrings(ids.map(id => member_types[id].plural), 'or')}`
            },
        }
    },
    // country, member_type, gender, profile_quality
    // Lives in United States or Germany, Does not live in
    // Member type is Ambassadors
    'select': {
        operators: {
            'is': (meta, id, data) => {
                if(!meta) {
                    console.warn('msising meta',meta)
                    return '-'
                }
                if(!data||!data.options) {
                    console.warn('Data Missing', data)
                    return `${meta.label} is ..`
                }
                return `${meta.label} is ${data.options.filter(a => a.id === id)[0].label}`;
            },
            'is_not': (meta, id, data) => {
                if(!meta) {
                    console.warn('msising meta',meta)
                    return '-'
                }
                if(!data||!data.options) {
                    console.warn('Data Missing', data)
                    return `${meta.label} is ..`
                }
                return `${meta.label} is not ${data.options.filter(a => a.id === id)[0].label}`;
            },
            'is_one_of': (meta, ids, data) => {
                if(!meta) {
                    console.warn('msising meta',meta)
                    return '-'
                }
                if(!data||!data.options) {
                    console.warn('Data Missing', data)
                    return `${meta.label} is ..`
                }
                return `${meta.label} is ${renderArrayStrings(ids.map(id => data.options.filter(a => a.id === id)[0].label), 'or')}`;
            },
            'is_not_one_of': (meta, ids, data) => {
                if(!meta) {
                    console.warn('msising meta',meta)
                    return '-'
                }
                if(!data||!data.options) {
                    console.warn('Data Missing', data)
                    return `${meta.label} is ..`
                }
                return `${meta.label} is not ${renderArrayStrings(ids.map(id => data.options.filter(a => a.id === id)[0].label), 'or')}`;
            }
        }
    },
    // age, sessions, profile_completeness
    'number': {
        operators: {
            'equals': (meta, value) => {
                return `${meta.label} equals ${value}`
            },
            'greater_than': (meta, value) => {
                return `${meta.label} greater than ${value}`
            },
            'less_than': (meta, value) => {
                return `${meta.label} = ${value}`
            },
            'less_than_or_equal': (meta, value) => {
                return `${meta.label} <= ${value}`
            },
            'greater_than_or_equal': (meta, value) => {
                return `${meta.label} >= ${value}`
            }

        }
    },
    'multi-select': {
        operators: {
            'is': (meta, id, data) => {
                if(!meta) {
                    console.warn('msising meta',meta)
                    return '-'
                }
                if(!data||!data.options) {
                    console.warn('Data Missing', data)
                    return `${meta.label} is ..`
                }
                return `${meta.label} is ${data.options.filter(a => a.id === id)[0].label}`;
            },
            'is_not': (meta, id, data) => {
                if(!meta) {
                    console.warn('msising meta',meta)
                    return '-'
                }
                if(!data||!data.options) {
                    console.warn('Data Missing', data)
                    return `${meta.label} is ..`
                }
                return `${meta.label} is not ${data.options.filter(a => a.id === id)[0].label}`;
            },
            'is_one_of': (meta, ids, data) => {
                if(!meta) {
                    console.warn('msising meta',meta)
                    return '-'
                }
                if(!data||!data.options) {
                    console.warn('Data Missing', data)
                    return `${meta.label} is ..`
                }
                return `${meta.label} is ${renderArrayStrings(ids.map(id => data.options.filter(a => a.id === id)[0].label), 'or')}`;
            },
            'is_not_one_of': (meta, ids, data) => {
                if(!meta) {
                    console.warn('msising meta',meta)
                    return '-'
                }
                if(!data||!data.options) {
                    console.warn('Data Missing', data)
                    return `${meta.label} is ..`
                }
                return `${meta.label} is not ${renderArrayStrings(ids.map(id => data.options.filter(a => a.id === id)[0].label), 'or')}`;
            }
        },
    }
};

function getData(condition, meta) {
    if (condition.field === 'member_type' && condition.type === 'multi-select' && condition.value) {
        return {
            options: Object.entries(meta.member_types).map(a => {
                return {
                    id: a[0],
                    label: a[1].plural
                }
            })
        };
    } else if (condition.field === 'member_type' && condition.value) {
        return meta.member_types;
    } else if(condition.field==='address.country' && condition.value) {
        return {
            options: Object.entries(countries).map(a=>{
                return {
                    id: a[0],
                    label: a[1]
                }
            })
        };
    } else if (condition.field.startsWith('custom_fields.')) {
        return meta.custom_fields.filter(a => a.id === condition.field.replace('custom_fields.', ''))[0];
    } else {
        return {}
    }
}

const field_metadata = {
    'member_type': {
        label: "Member Type"
    },
    'gender': {
        label: "Sex"
    },
    'enriched_age': {
        label: "Age"
    },
    'address.country': {
        label: "Country"
    },
    'address.city': {
        label: "City"
    },
    'address.state': {
        label: "State"
    },
    'address.zip_code': {
        label: "ZIP Code"
    }
};

function getFieldMetadata(condition, custom_field) {
    if (condition.field.startsWith('custom_fields.')) {
        return {
            label: custom_field.name
        };
    } else {
        return field_metadata[condition.field];
    }
}

function getConditionDescription(condition, meta) {
    const metadata = getFieldMetadata(condition, meta);

    return mapping[condition.type].operators[condition.operator](metadata, condition.value, meta)
}

const mock_segment = {
    "name": "Algerian Members",
    "match": "any",
    "user_uids": {
        "QBMJE47IUK": true,
        "HWJYQ33TF6": true
    },
    "conditions": [
        {
            "valid": true,
            "operator": "is",
            "field": "address.country",
            "id": "CVF5FKM8Z4",
            "value": "dz",
            "type": "multi-select",
            "modifier": ""
        }
    ],
    "count": 2,
    "image": "",
    "updated_at": 1634090661496
};

export function getSegmentSemantics(segment = {}, community) {
    const {conditions = [], match = 'any'} = segment;

    if (!community) {
        return 'Unknown';
    }
    const meta = {
        member_types: community.member_types,
        custom_fields: prepCustomFields(community.custom_fields)
    }

    if (conditions.length === 0) {
        return 'No conditions added';
    } else if (conditions.length === 1) {
        return getConditionDescription(conditions[0], getData(conditions[0], meta));
    } else {
        const strs = conditions.map(c => getConditionDescription(c, getData(c, meta)));
        return strs.join(`${match === 'any' ? " or " : " and "}`);
    }
}

// outputs
// if member_type:ambassadors and hub:berlin -> Ambassadors in Berlin and London
// if member_Type:writers and city:paris -> Ambassadors in Paris
// if 1 condition and hub === Berlin -> Hub:Berlin

export function getSegmentTitle(segment,community) {
    const {conditions = [], match = 'any'} = segment;

    // depends on which conditions are valid as well
    const valid = conditions.filter(a=>typeof a.value === 'string');

    const cfs = prepCustomFields(community.custom_fields);

    const member_type_condition = valid.filter(a=>a.field==='member_type'&&typeof a.value === 'string')[0];
    const city_condition = valid.filter(a=>a.field==='address.city')[0];
    const country_condition = valid.filter(a=>a.field==='address.country'&&typeof a.value === 'string')[0];
    const select_condition = valid.filter(a=>a.field.startsWith('custom_fields-')&&typeof a.value === 'string')[0];

    const cf_data = select_condition ? cfs.filter(a=>a.id===select_condition.field.split('custom_fields-')[1]) : {};
    // also have to consider custom fields, though for now we can assume that all are select fields

    if(member_type_condition&&city_condition) {
        return `${community.member_types[member_type_condition.value].plural} in ${city_condition.value}`;
    } else if(member_type_condition&&country_condition) {
        return `${community.member_types[member_type_condition.value].plural} in ${countries[country_condition.value]}`;
    } else if(member_type_condition&&select_condition) {
        return `${community.member_types[member_type_condition.value].plural}`;
    } else if(member_type_condition) {
        return `All ${community.member_types[member_type_condition.value].plural}`;
    } else if(select_condition) {
        if(!cf_data||!cf_data[0]||!cf_data[0].options&&select_condition&&select_condition.value) {
            return '-';
        }
        const selected_opt = cf_data[0].options.filter(a=>a.id===select_condition.value)[0];
        if(!selected_opt) {
            return '-';
        }
return `${cf_data[0].name}: ${selected_opt.label}`
    } else if(city_condition) {
        return `Everyone in ${city_condition.value}`;
    } else if(country_condition) {
        return `Everyone in ${countries[country_condition.value]}`;
    }

    return 'Segment';
}