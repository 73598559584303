import React, {useState, useContext} from 'react';
import CommonCard from "../../../../library/components/old/card";
import {PlusIcon} from '@heroicons/react/solid';
import {Button} from "../../../../library/components/core";


import {authFetch} from "../../../../config/network";
import {GlobalContext} from "../../../global/global-context";
import {CommunityContext} from "../../community-context";
import {ExperienceHistory} from "../../../../library/components/reorganize/experience-history";
import {AddCareerModal} from "./add";
import {getSectionVisibilityNote} from "../../../../library/components/old/edit-fields-section";
import {useIsMobile} from "../../../global/global-router";
import {ProfileFieldsContentSection} from "../../../../library/components/edit-profile-mobile";

function validateCareer(obj) {
    let to_return = Object.assign({},obj);

    if(obj.org_data.title==='') {
        to_return.org_data.title = '-';
    }

    return to_return;
}

export function MemberCareer({can_add, career, my_profile=false,visibility='all-members',cb, user_uid}) {
    const global = useContext(GlobalContext);
    const community = useContext(CommunityContext);
    const [modal, setModal] = useState(null);
    const [loading, setLoading] = useState(null);
    const is_mobile = useIsMobile();
    function addCareer(ed,id) {
        setLoading('add');
        const payload = {
            position: validateCareer(ed),
            community_uid: community.uid,
            user_uid: user_uid,
            position_id: id
        };

        const res = async (r) => {
            cb();
            setTimeout(() => {
                global.addToast({text:'Position Added',intent:'success'});
                setLoading(null);
                setModal(null);
            }, 1500);
        };

        authFetch('/members/career-add', res, res, "POST", {payload});
    }

    function editCareer(ed,id) {
        setLoading('edit');
        const payload = {
            position: validateCareer(ed),
            community_uid: community.uid,
            user_uid: user_uid,
            position_id: id
        };

        const res = async (r) => {
            cb();
            setTimeout(() => {
            global.addToast({text:'Position Updated',intent:'success'});
            setLoading(null);
            setModal(null);
            }, 1500);
        };

        authFetch('/members/career-edit', res, res, "POST", {payload});
    }

    function deleteCareer(id) {
        setLoading('add');
        const payload = {
            community_uid: community.uid,
            user_uid: user_uid,
            position_id: id
        };

        const res = async () => {
            cb();
            setTimeout(() => {
            global.addToast({text:'Position Deleted',intent:'success'});
            setLoading(null);
            setModal(null);
        }, 1500);
        };

        authFetch('/members/career-delete', res, res, "POST", {payload});
    }

    let actions = can_add ? {onClick: () => setModal('add-career'),label:'Add'} : null;

    return <ProfileFieldsContentSection block={true} add={actions} layout={is_mobile?"":"desktop"} section={{autocomplete:[]}} visibility={visibility} title="Career">
        <ExperienceHistory type='career' can_add={can_add} loading={loading} editEducation={editCareer} deleteEducation={deleteCareer} modal={modal} updateModal={(a)=>{setModal(a)}} data={career} />
        {modal === 'add-career' &&
        <AddCareerModal loading={loading==='add'} can_add={can_add} onAdd={addCareer.bind(this)} onClose={() => setModal(null)}/>}

    </ProfileFieldsContentSection>
}