import React, {useEffect, useState} from "react";
import {RichSelect} from "../core";
import {custom_field_type_icons} from "../../../app/auth/profile-fields/constants";
import {custom_field_types, selectable_custom_fields_ids_array} from "../../utilities/community/custom-fields";

const options = selectable_custom_fields_ids_array.map(id=>{
    const item = custom_field_types[id];
    return {
        icon: custom_field_type_icons[id],
        label: item.label,
        id,
        value: id,
        default_value: item.default_value
    }
})

export function CustomFieldTypeSelector({type=options[0].id,disabled_types={},onSelect=()=>{}}) {
const [selected,setSelected] = useState(type);
const selected_option = options.filter(function (a) {
    return a.value === selected;
})[0];
useEffect(function() {
    setSelected(type);
}, [type]);
    const trigger = <div className={`flex pl-2 w-56 bg-white py-1.5 space-x-1.5 inline-flex pr-6 rounded-md shadow-sm border border-gray-300 hover:bg-gray-100 cursor-pointer hover:border-gray-400 transition-colors`}>
        <div className="w-5 h-5 flex items-center justify-center">
            <div className="w-5 h-5 text-gray-600">
                {selected_option.icon}
            </div>
        </div>
        <div className="text-sm text-gray-700 font-semibold truncate">{selected_option.label}</div>
    </div>;
    function handleSelect(v) {
        setSelected(v);
        onSelect(v);
    }
    const final_options = options.filter(opt=>{
        return !disabled_types[opt.id]
    });
    return <RichSelect custom_trigger={trigger} onSelect={handleSelect} options={final_options} selected={selected}/>;
}