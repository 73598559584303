import React, {useContext} from "react";
import {MemberDeleteAccount} from "./delete-account";
import {CommunityContext} from "../../community-context";
import {ActionWithIcon, Field} from "../../../../library/components/core";
import {HandleBadge} from "../../admin/member/components/handle-badge";
import {authFetch} from "../../../../config/network";
import {GlobalContext} from "../../../global/global-context";
import {UserEmailManager} from "../../../../library/components/user-email-manager";

export default function MemberPrefsAccount(props) {
    const community = useContext(CommunityContext);
    const global = useContext(GlobalContext);

    const {member, member_id} = community;

    if (!member.account_email) {
        // loading
        return null;
    }

    function handleChangeHandle(h) {
        const res = () => {
            global.addToast({
                text: "Handle changed",
                intent: "success"
            });
        };
        global.addToast({
            text: `Changing handle to @${h}`,
            intent: "info"
        });
        const payload = {
            community_uid: community.uid,
            member_id: community.member_id,
            id: member_id,
            new_handle: h
        };
        authFetch("/members/change-handle", res, res, "POST", {payload});
    }

    return <div>

        <div className="space-y-3">

            <UserEmailManager community={community}/>

                <HandleBadge layout="field" member={member} handleChange={handleChangeHandle} community={community}
                             member_id={member_id}/>
        </div>
        <MemberDeleteAccount/>
    </div>
}