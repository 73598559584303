import {getEntryUpdateID} from "./build-row-data";

function countOccurances(arr,field_id) {
    let obj = {};

    for(let i=0;i<arr.length;i++) {
        const item = arr[i];
        const ref = item[field_id];
        if(obj[ref]) {
            obj[ref]++;
        } else {
            obj[ref] = 1;
        }
    }

    return obj;
}

function getAdvancedMapping(col,data_field,field_details,raw_data,full_profile) {
    if(data_field!=='member_type') {
        return {};
    }
    let map = {};
    const data_items = Object.values(raw_data);

    const occurances = countOccurances(data_items,getEntryUpdateID(col));
    const existing = full_profile['member_type'].options;

    const file_options = Object.entries(occurances);

    for(let i=0;i<existing.length;i++) {
        const item = existing[i];
        const search_string = item.label.toLowerCase();
        const result = file_options.filter(a=> {
            const test_str = a[0].toLowerCase();
            // singular / plural test
            return test_str.indexOf(search_string) !== -1
        })[0];
        if(result) {
            map[result[0]] = item.id;
        }
    }
    return map;
}

export function lie_prematchColumns(columns,full_profile,raw_data) {
    let mapping = {};
    // todo full name
    let matcher = [
        {
            contains: 'email',
            match_fields: ['account_email'],
            done: false
        },
        {
            contains: 'member id',
            match_fields: ['docid'],
            done: false
        },
        {
            contains: 'city',
            match_fields: ['address.city'],
            done: false
        },
        {
            contains: 'line one',
            match_fields: ['address.line_one'],
            done: false
        },
        {
            contains: 'line two',
            match_fields: ['address.line_two'],
            done: false
        },
        {
            contains: 'line 1',
            match_fields: ['address.line_one'],
            done: false
        },
        {
            contains: 'line 2',
            match_fields: ['address.line_two'],
            done: false
        },
        {
            contains: 'birthday',
            match_fields: ['birthday'],
            done: false
        },
        {
            contains: 'country',
            match_fields: ['address.country'],
            done: false
        },
        {
            contains: 'member type',
            match_fields: ['member_type'],
            done: false
        },
        {
            contains: 'state',
            match_fields: ['address.state'],
            done: false
        },
        {
            contains: 'zip',
            match_fields: ['address.zip_code'],
            done: false
        },
        {
            contains: 'phone',
            match_fields: ['contact.phone'],
            done: false
        },
        {
            contains: 'city',
            match_fields: ['address.city'],
            done: false
        },
        {
            contains: 'first',
            match_fields: ['about.first_name'],
            done: false
        },
        {
            contains: 'middle',
            match_fields: ['about.middle_name'],
            done: false
        },
        {
            contains: 'last',
            match_fields: ['about.last_name'],
            done: false
        }
    ];

    for(let i=0;i<columns.length;i++) {
        const col = columns[i];
        const search_label = col.label.toLowerCase();
        for(let k=0;k<matcher.length;k++) {
            const match = matcher[k];
            if(!match.done&&search_label.indexOf(match.contains)!==-1) {
                // oh yes we're in
                if(match.match_fields.length===1) {
                    const data_field = match.match_fields[0];
                    const field_details = full_profile[data_field];
                    const advanced_mapping = getAdvancedMapping(col,data_field,field_details,raw_data,full_profile);
                    mapping[col.id] = {
                        data_field: data_field,
                        mapping: advanced_mapping,
                        field_details: field_details
                    }
                    match.done = true;
                } else {
                 // likely need to copy data?
                }
            }
        }
    }

    return mapping;
}