import React from "react";
import {EntityInfo} from "../../utilities/localstorage/entity-localstorage";
import {Avatar} from "../core";
import {XIcon} from "@heroicons/react/solid";

function Item({data,onRemove=()=>{}}) {
    return <div className="mr-2 transition-colors p-0.5 rounded-xl items-center mb-1.5 flex space-x-1.5 bg-gray-100 hover:bg-gray-200">
        <div className="h-6 w-6">
            <Avatar size="xs" url={data.image ? `${data.image}_small?alt=media` : ""} />
        </div>
        <div className="text-sm font-medium text-gray-700">{data.name}</div>
        <div className="ml-0.5 pr-1">
            <div onClick={onRemove} className="w-4 h-4 text-gray-500 cursor-pointer hover:opacity-70">
                <XIcon />
            </div>
        </div>
    </div>
}

export function EditMemberSelect({
    ids = [],
                                     onRemove = () => {},
                                     member_id = "",
                                     community_uid = ""
                                 }) {

    return <div className="flex flex-wrap">
        {ids.map(id=>{
            return <EntityInfo key={id} community_uid={community_uid} id={id} type={'members'}>
                <Item onRemove={()=>onRemove(id)} />
            </EntityInfo>
        })}
    </div>
}