import React, {useContext, useEffect} from "react";
import {Switch, Route, Redirect, useHistory} from 'react-router-dom';
import SettingsAdmins from "./admins";
import SettingsAbout from "./about";
import {CAdminTesting} from "./testing";
import SettingsAccount from "./account";
import SettingsMemberTypes from "./member-types";
import SettingsMemberType from "./member-type";
import SettingsPrivacy from "./privacy";
import SettingsFilters from "./filters";
import CreateFilter from "./filters/create-filter";
import UpdateFilter from "./filters/update-filter";
import SettingsPlan from "./plan";
import BuildModeAdminPage from "./build-mode";
import CommunitySettingsOverview from "./overview";
import {setDocumentTitle} from "../../../../library/utilities/set-document-title";
import {CommunityContext} from "../../community-context";
import {CommunityAdminDesigner} from "./designer";
import {CommunityAdminRule} from "./rule";
import SettingsMemberSegments from "./segments";
import SettingsMemberRules from "./rules/rules";
import {CommunityAdminSegment} from "./segment";
import SettingsPreferences from "./preferences";
import {SettingsApplications} from "./applications";
import {AdminMemberApplicationWrapper} from "./application/wrapper";
import SettingsDelegatedAccess from "./delegated-access";
import {CommunityAdminDeveloper} from "./developers";
import {CommunityAdminDuplicates} from "./duplicates";
import {CommunityAdminDataToolkit} from "./data-toolkit";
import CommunitySettingsHidden from "./hidden";
import {AdminMergeFlow} from "../merge-flow";

export default function AdminSettings() {
    const community = useContext(CommunityContext);
    setDocumentTitle('Settings', community.data.name);
    const history = useHistory();
    const {handle} = community.data;

    useEffect(function () {
        if (!community.is_admin) {
            history.push(`/${community.data.handle}/home`)
        }
    }, []);

    return (
        <Switch>
            <Route path='/:handle/admin/settings' exact component={CommunitySettingsOverview}/>
            <Route path='/:handle/admin/settings/preferences' component={SettingsPreferences}/>
            <Route path='/:handle/admin/settings/about' exact component={SettingsAbout}/>
            <Route path='/:handle/admin/settings/delegated-access' component={SettingsDelegatedAccess}/>
            <Route path='/:handle/admin/settings/designer' component={CommunityAdminDesigner}/>
            <Route path='/:handle/admin/settings/developers' component={CommunityAdminDeveloper}/>
            <Route path='/:handle/admin/settings/hidden' component={CommunitySettingsHidden}/>
            <Route path='/:handle/admin/settings/data-toolkit' component={CommunityAdminDataToolkit}/>
            <Route path='/:handle/admin/settings/applications' component={SettingsApplications}/>
            <Route path='/:handle/admin/settings/application/:id' exact component={AdminMemberApplicationWrapper}/>
            <Route path='/:handle/admin/settings/member-types' exact component={SettingsMemberTypes}/>
            <Route path='/:handle/admin/settings/filters' exact component={SettingsFilters}/>
            <Route path='/:handle/admin/settings/rule/:id' exact component={CommunityAdminRule}/>
            <Route path='/:handle/admin/settings/segment/:id' exact component={CommunityAdminSegment}/>
            <Route path='/:handle/admin/settings/member-rules' exact component={SettingsMemberRules}/>
            <Route path='/:handle/admin/settings/member-segments' exact component={SettingsMemberSegments}/>
            <Route path='/:handle/admin/settings/create-filter' exact component={CreateFilter}/>
            <Route path='/:handle/admin/settings/filter/:id' exact component={UpdateFilter}/>
            <Route path='/:handle/admin/settings/member-types/:id' exact component={SettingsMemberType}/>
            <Route path='/:handle/admin/settings/account' exact component={SettingsAccount}/>
            <Route path='/:handle/admin/settings/testing' exact component={CAdminTesting}/>
            <Route path='/:handle/admin/settings/merge-flow' exact component={AdminMergeFlow}/>
            <Route path='/:handle/admin/settings/privacy' exact component={SettingsPrivacy}/>
            <Route path='/:handle/admin/settings/plan' exact component={SettingsPlan}/>
            <Route path='/:handle/admin/settings/build-mode' exact component={BuildModeAdminPage}/>
            <Route path='/:handle/admin/settings/admins' exact component={SettingsAdmins}/>

            <Redirect from='/*' to={`/${handle}/admin/settings`}/>
        </Switch>
    );
}