import React from "react";
import {AuthContainer, AuthTitle, SplashContainer, SplashInner} from "../../landing/layout/splash-container";

import SignUpBlock from "../signup/SignUpBlock";
import {setDocumentTitle} from "../../../library/utilities/set-document-title";

function SignUp(props) {

    setDocumentTitle('Sign Up');
    
    return (
        <SplashContainer>
            <SplashInner>
                <AuthContainer>
                    <AuthTitle>Create an Account</AuthTitle>
                    <SignUpBlock {...props} />
                </AuthContainer>
            </SplashInner>
        </SplashContainer>
    );
}

export default SignUp;