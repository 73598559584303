import React, {useState} from 'react';
import {GroupUpdates} from "../group/updates";
import {getEntityNotificationPreferences} from "../../../library/utilities/notifications";
import {GroupJoin} from "../group/join";
import {Button} from "../../../library/components/core";
import RoleIcon from "../../../library/icons/custom/RoleIcon";

export function GroupJoinSubscribe({
                                       group,
    id = "",
                                       community_uid,
                                       member,
                                       member_id = ""
                                   }) {

    const [joined,setJoined] = useState(group.user_uids[member_id]);

    if (joined) {
        const notification_prefs = getEntityNotificationPreferences(member.notifications, 'group', id);

        return <GroupUpdates notification_prefs={notification_prefs} id={id} member_id={member_id}
                             community_uid={community_uid} cb={() => {
        }}/>
    }

    if (group.type === 'team') {
        return null;
    }

    if(group.closed) {
        return null;
    }

    return <GroupJoin cb={() => {
        setJoined(true);
    }} id={id} community_uid={community_uid} member_id={member_id}/>
}