import React, {useEffect, useState} from 'react';
import Select from 'react-select/async';
import {merge} from "lodash";
import {cleanQuery} from "../reorganize/entity-search/new-entity-search";
import {buildSearchPromise} from "../reorganize/entity-search/old-search-promise";
import {advanced_select_components, AdvancedSelectFormatGroupLabel} from "../advanced-selects/shared/components";
import {advanced_select_styles, advanced_select_styles_minimal} from "../advanced-selects/shared/styles";

export function buildSearchQueries(searchable) {
    let queries = [];
    if (searchable['members']) {
        queries.push({
            ctx: 'members_first_name',
            type: 'members',
            field: 'first_name'
        });
        queries.push({
            ctx: 'members_handle',
            type: 'members',
            field: 'handle'
        });
        queries.push({
            ctx: 'members_name',
            type: 'members',
            field: 'search_name'
        });
        queries.push({
            ctx: 'members_last_name',
            type: 'members',
            field: 'last_name'
        });
    }
    if (searchable['roles']) {
        queries.push({
            ctx: 'roles_handle',
            type: 'roles',
            field: 'handle'
        });
    }
    if (searchable['folders']) {
        queries.push({
            ctx: 'folders_name',
            type: 'folders',
            field: 'search_name'
        });
    }
    if (searchable['member_types']) {
        queries.push({
            ctx: 'membertypes_handle',
            type: 'member_types',
            field: 'handle'
        });
    }
    if (searchable['teams']) {
        queries.push({
            ctx: 'teams_handle',
            type: 'teams',
            field: 'handle'
        });
    }
    if (searchable['groups']) {
        queries.push({
            ctx: 'groups_handle',
            type: 'groups',
            field: 'handle'
        });
    }
    if (searchable['filters']) {
        queries.push({
            ctx: 'filters_name',
            type: 'filters',
            field: 'search_name'
        });
    }
    if (searchable['communities']) {
        queries.push({
            ctx: 'communities_handle',
            type: 'communities',
            field: 'handle'
        });
    }

    return queries;
}

export function convertSearchObjToArr(obj, disableFn = () => false) {
    // loop through members, communities keys
    const keys = Object.keys(obj);
    let arr = [];
    for (let i = 0; i < keys.length; i++) {
        const key = keys[i];

        const items = Object.values(obj[key]);

        for (let k = 0; k < items.length; k++) {
            const item = items[k];

            let is_disabled = item.isDisabled ? true : disableFn(item);

            const new_item = {
                ...item,
                isDisabled: is_disabled
            };
            arr.push(new_item);
        }
    }
    return arr;
}

export function buildSearchOptions(searchable) {
    let obj = {};

    const keys = Object.keys(searchable);

    for (let i = 0; i < keys.length; i++) {
        obj[keys[i]] = {};
    }

    return obj;
}

export function getSearchExcluded(s, e) {
    let obj = {};

    const keys = Object.keys(s);

    for (let i = 0; i < keys.length; i++) {
        if (e[keys[i]]) {
            obj[keys[i]] = e[keys[i]];
        } else {
            obj[keys[i]] = {};
        }

    }

    return obj;
}

export async function queryMembersAndRoles(q,queries,context,searchable={},excluded={},allowed={},disabled={}) {
    let promises = [];

    for (let i = 0; i < queries.length; i++) {
        if (queries[i].type === 'folders') {
            promises.push(buildSearchPromise(q, queries[i], context, {}, getSearchExcluded(searchable, excluded), allowed, disabled));
        } else {
            promises.push(buildSearchPromise(q.toLowerCase(), queries[i], context, {}, getSearchExcluded(searchable, excluded), allowed, disabled));
        }

    }

    return await Promise.all(promises).then((objs) => {
        let obj = buildSearchOptions(searchable);

        for (let i = 0; i < objs.length; i++) {
            const key = Object.keys(objs[i])[0];

            obj[key] = merge(obj[key], objs[i][key]);
        }

        return obj;
    });
}

export function RichSearch({
    style = "standard",
                               value = null,
                               disableFn = () => false,
                               isClearable = true,
                               can_change = true,
                               autoFocus = false,
                               backspaceRemovesValue = false,
                               isMulti = false,
                               persistent = false,
                               onSelect = () => {},
                               searchable = {members: true},
                               excluded = {members: {}, filters: {}},
                               disabled = {members: {}, filters: {}},
                               allowed = {
                                   members: {},
                                   roles: {}
                               },
                               context,
                               placeholder = "Type to search.."
                           }) {
    const [loading, setLoading] = useState(false);
    const [results, setResults] = useState({});
    const [q, setQuery] = useState("");
    const [last_q, setLastQ] = useState("");

    let styles = style==='standard'?advanced_select_styles:advanced_select_styles_minimal(can_change);

    const components = {...advanced_select_components};

    async function queryDB(q, queries) {
        return await queryMembersAndRoles(q,queries,context,searchable,excluded,allowed,disabled);
    }

    async function loadOptions(q, cb) {
        const fq = cleanQuery(q);
        if (fq.length > 0 && last_q !== fq) {
            setLoading(true);
            const queries = buildSearchQueries(searchable);
            await queryDB(fq, queries).then((res) => {
                let new_results = {...results, ...res};
                setResults(new_results);
                setLastQ(fq);
                setLoading(false);
                cb(convertSearchObjToArr(new_results, disableFn));
            });
        } else {
            cb(convertSearchObjToArr(results, disableFn));
        }
    }

    function handleInputChange(q) {
        setQuery(q);
    }

    const results_arr = convertSearchObjToArr(results, disableFn);

    function handleChange(v) {
        if (isMulti) {
            if (!v) {
                onSelect([]);
            } else {
                onSelect(v);
            }
        } else {
            if (!v) {
                setQuery("");
                onSelect("", "")
            } else {
                onSelect(v._type, v.value);
                setQuery("")
            }
        }
    }
    return <Select menuPortalTarget={document.body}
                   menuPlacement={"bottom"}
                   menuPosition={'fixed'}
                   isMulti={isMulti}
                   components={components}
                   styles={styles} onChange={(v) => handleChange(v)} placeholder={placeholder} value={!persistent ? null : value}
                   cacheOptions defaultOptions={true} isLoading={loading}
                   options={results_arr} isClearable={isClearable} loadOptions={(q, cb) => loadOptions(q, cb)}
                   onInputChange={(v) => handleInputChange(v)}
                   autoFocus={autoFocus} backspaceRemovesValue={backspaceRemovesValue}
                   formatGroupLabel={AdvancedSelectFormatGroupLabel}
    />
}