import React from 'react';
import {Avatar, Button} from "../../core";

export function CT_AccessDetails({community}) {

    const pp = community.data.profile_picture;

    return <div className="pt-3">
        <div>
            <Button size="lg" intent="success" text="Log in" />
        </div>
        <div className="text-base text-gray-600 pt-3">For reference, here are your account details for {community.data.name}:</div>
        <div className="border rounded-md mt-1.5 border-gray-200 p-4 flex flex-col items-center justify-center">
            <Avatar size="md" url={pp?`${pp}_small?alt=media`:""} text={community.data.name} />
            <div className="text-lg font-bold text-gray-800 mt-1">{community.data.name}</div>
            <div className="text-base text-gray-600 mt-1">
            <div className="">
                URL: <a className="color-link">{window.location.origin}/{community.data.handle}/home</a>
            </div>
            <div className="mt-1.5">
                Email: {`{{ACCOUNT_EMAIL}}`}
            </div>
            </div>
        </div>
    </div>
}