import React, {useContext, useEffect, useState} from 'react';
import {GlobalContext} from "../../global-context";
import {ActionWithIcon, Button, SectionHeader} from "../../../../library/components/core";
import {authFetch} from "../../../../config/network";
import styled from "styled-components";
import {PlusIcon} from "@heroicons/react/solid";
import {DoubleCheckModal} from "../../../../library/components/old/double-check-modal";
import AddSecondaryEmail from "./add-email";
import {FirebaseDB} from "../../../../config/setup-firestore";

const Title = styled.div`
    font-size: 16px;
    margin-bottom: 4px;
    font-weight: 600;
`;

const List = styled.div`
    width: 400px;
    padding: 6px 0;
`;

const Item = styled.div`
    display: grid;
        padding-bottom: 6px;
    grid-template-columns: 1fr auto;
`;

export default function SecondaryEmails({user,community_uid='',hide_title=false,user_uid='',updateMember}) {
    const global = useContext(GlobalContext);
    const [loading, setLoading] = useState('');
    const [email, setEmail] = useState('');
    const [modal, setModal] = useState(null);
    const [data, setData] = useState(user);

    const use_uid = user_uid==='' ? global.user_uid : user_uid;

    useEffect(function () {
        if(!user&&user_uid!=='') {
            // get user
            FirebaseDB
                .collection('users')
                .doc(user_uid)
                .get()
                .then(doc=>{
                    if(doc.exists) {
                        setData(doc.data());
                    }
                })
        }
    }, [user]);

    function removeSecondaryEmail() {
        setLoading('removing-email');
        const res = () => {
            setLoading('');
            setModal(null);
            setEmail('');
            global.addToast({text: 'Secondary Email Removed', intent: 'success'});
        };
        const payload = {
            email,
            user_uid: use_uid
        };
        authFetch("/users/remove-secondary-email", res, res, "POST", {payload});
    }

    function makePrimary() {
        setLoading('changing-account-email');
        const res = () => {
            if(user_uid!=='') {
                updateMember();
            }
            setLoading('');
            setModal(null);
            setEmail('');
            global.addToast({text: 'Primary Email Changed', intent: 'success'});
        };
        const payload = {
            new_email: email,
            user_uid: use_uid
        };
        authFetch("/users/change-account-email", res, res, "POST", {payload});
    }

    if(!data) {
        return null;
    }
    const secondary_emails = !data.secondary_emails || !data.secondary_emails.length ? [] : data.secondary_emails;

    // only users can remove their own
    const can_make_primary = user_uid=== '' || data.last_sign_in===null;
    const can_remove = user_uid === '';

    return <div>
        {!hide_title&&<SectionHeader padded={false} title="Secondary Emails" action={<ActionWithIcon icon={<PlusIcon />} inverse text="Add" onClick={()=>setModal('add-email')} />} />}
        <List>
            {secondary_emails.map(em => <Item key={em}>
                <div className='text-gray-700 text-sm'>
                {em}
                </div>
                <div>
                    <a className={'color-link text-xs font-medium '+(can_make_primary?"":"hidden")} style={{marginRight:'12px'}} onClick={()=>{
                        setEmail(em);
                        setModal('double-check-make-primary');
                    }}>Make Primary</a>
                    {can_remove&&<a className='color-link text-xs font-medium' onClick={()=>{
                        setEmail(em);
                        setModal('double-check-delete');
                    }}>Remove</a>}
                </div>
            </Item>)}
            {secondary_emails.length === 0 ? 'No secondary emails added yet' : ''}
        </List>


        {modal==='add-email'&&<AddSecondaryEmail community_uid={community_uid} user_uid={use_uid} onClose={()=>setModal(null)} />}

        {modal==='double-check-delete'&&<DoubleCheckModal onConfirm={()=>removeSecondaryEmail()} onClose={()=>{setModal(null);setEmail('')}} onCancel={()=>{setModal(null);setEmail('')}} type='user_remove_email' />}
        {modal==='double-check-make-primary'&&<DoubleCheckModal onConfirm={()=>makePrimary()} onClose={()=>{setModal(null);setEmail('')}} onCancel={()=>{setModal(null);setEmail('')}} type='user_change_primary_email' />}
    </div>
}