import React, {useContext} from 'react';
import {CommunityContext} from "./community-context";
import {CommunityCreateEvent} from "./events/create";

export function CommunityMockPage() {
    const community = useContext(CommunityContext);
    // overflow-y-auto 
    return <div>
        <CommunityCreateEvent />
    </div>
}