import React, {useState} from "react";
import styled from "styled-components";
import {playground_components} from "./components";

const selector_color = '#357ebf';

const Frame = styled.div`
background-color: #d9d9d9;
background-image: url("data:image/svg+xml,%3Csvg width='60' height='60' viewBox='0 0 60 60' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fillRule='evenodd'%3E%3Cg fill='%23ffffff' fillOpacity='0.32'%3E%3Cpath d='M36 34v-4h-2v4h-4v2h4v4h2v-4h4v-2h-4zm0-30V0h-2v4h-4v2h4v4h2V6h4V4h-4zM6 34v-4H4v4H0v2h4v4h2v-4h4v-2H6zM6 4V0H4v4H0v2h4v4h2V6h4V4H6z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
    height: 100vh;
    display: grid;
    grid-template-columns: 264px 1fr;
`;

const Sidebar = styled.div`
  background: #ffffff;
  border-right: 1px solid #dadada;
`;

const SidebarList = styled.div`
  height: calc(100vh - 48px);
  overflow-y: auto;
`;

const EmptyState = styled.div`
  font-size: 18px;
`;

const SidebarItem = styled.div`
      border-bottom: 1px solid #dadada;
  :hover {
  border-right: 4px solid ${selector_color};
  > a {
        color: ${selector_color};
         
         }
    }
    > a {
    font-weight: ${props=>props.active?`600`:'500'};
     color: ${props=>props.active?`${selector_color}`:''};
    }
    
    border-right: ${props=>props.active?`4px solid ${selector_color}`:''};
`;

const SidebarLabel = styled.a`
    display: block;
        padding: 12px 16px;
    font-size: 15px;
   
`;

const Main = styled.div`
      display: flex;
      position: relative;
    align-items: center;
    justify-content: center;
    padding-bottom: 20vh;
`;

const TitleBox = styled.div`
      padding: 14px 14px 10px 16px;
    border-bottom: 1px solid #dadada;
`;

const Title = styled.div`
      font-size: 18px;
    font-weight: 700;
`;

const ConfigBox = styled.div`
  
`;

const ComponentFrame = styled.div`
  background: #fff;
  border-radius: 8px;
  padding: 20px;
`;

const TrelloItem = styled.div`
  position: absolute;
  top: 12px;
  right: 16px;
  background: #fff;
  border-radius: 6px;
  font-size: 15px;
    padding: 8px 16px;
`;

const sections = playground_components;

function getFromHash() {

    const hash = window.location.hash;

    if(hash.includes('#')) {
        return hash.replace('#','');
    }

    return '';
}

function getFromSection(section) {
    for(let i=0;i<sections.length;i++) {
        if(sections[i].id===section) {
            return sections[i];
        }
    }
    return {};
}

const TagsItem = styled.div`
display: flex;
    margin: -6px 12px 8px 16px;
    > div {
        padding: 2px 4px;
    font-size: 11px;
    background: #d9d9d9;
    border-radius: 6px;
    letter-spacing: 0.5px;
    font-weight: 700;
    color: #7b7b7b;
        margin-right: 4px;
        margin-bottom: 2px;
    }
`;

function Tags({tags}) {
    return <TagsItem>
        {tags.map((tag,i)=>{
            return <div key={tag}>{tag}</div>;
        })}
    </TagsItem>
}

export function Playground() {
    const [section, setSection] = useState(getFromHash());

    const section_data = getFromSection(section);
//    runExportTest();
    return <Frame>
        <Sidebar>
            <TitleBox>
                <Title className='text-gray-900'>Playground</Title>
            </TitleBox>
            <SidebarList>
                {sections.map((sec) => {
                    return <SidebarItem className='hover-bkg-1' active={sec.id === section} onClick={() => {
                        setSection(sec.id);
                        window.location.hash = `#${sec.id}`;
                    }} key={sec.id}>
                            <SidebarLabel active={sec.id === section} href={`#${sec.id}`} key={sec.id} className='text-gray-800'>{sec.label}</SidebarLabel>
                        {sec.tags.length>0&&<Tags tags={sec.tags} />}
                    </SidebarItem>
                })}
            </SidebarList>
        </Sidebar>
        <Main>
            <ConfigBox/>
            <ComponentFrame>
            {section!==''&&section_data.component}
                {section===''&&<EmptyState>Select a component to get started</EmptyState>}
            </ComponentFrame>
            {section_data.trello!==''&&<TrelloItem><a className="color-link" href={section_data.trello} target="_blank" rel="noreferrer">Details / Leave a comment</a></TrelloItem>}
        </Main>
    </Frame>;
}