import React from 'react';
/*
About
- First name
- last name
-title and middle name
- email
- phone
- bio
- birthday
- gender

Address
- address

Social
- website
- fb
- twitter
- linkedin

Custom Fields

Career

Education

Interests
 */
import {getPhoneNumberData} from "../../utilities/phone";
import {buildEmailOptions, getSocialFieldsToShow} from "../../../app/community/admin/member/edit-obj";
import {getMemberProfileFlags} from "../old/person-profile";
import {ActionWithIcon} from "../core";

function transformCustomFields(ids, data) {
    let arr = [];

    for (let i = 0; i < ids.length; i++) {
        const field = data[ids[i]];

        arr.push({
            field: `custom_fields.${ids[i]}`,
            type: field.type,
            id: field.id,
            hint: field.hint ? field.hint : "",
            question: field.question ? field.question : "",
            placeholder: field.placeholder ? field.placeholder : "",
            edit_only: field.type === 'checkbox',
            options: field.options,
            name: field.name,
            defer: true
        })
    }
    return arr;
}

function getColSpanFromType(type) {
    return 2;
}

export function buildMemberProfileSections(member, community, custom_fields, display_sections = {display_custom: {}}, show = {}, important_fields = [], social_fields=[],actions={}) {
    let a = {}, ids = [];

    const profile_flags = getMemberProfileFlags({community:community.data});

    ids.push('about');

    a['about'] = {
        title: 'About',
        visibility: 'all-members',
        autocomplete: {},
        hidden_fields: {
            title: true,
            middle_name: true,
            show_title_middle_name: false,
        },
        fields: []
    };

    a['about'].fields.push({
        id: 'first_name',
        label: 'First Name',
        required: true,
        col_span: 1,
        suggestions: [],
        type: 'text',
        placeholder: 'George',
        field: 'about.first_name'
    });

    a['about'].fields.push({
        id: 'last_name',
        label: 'Last Name',
        required: true,
        col_span: 1,
        suggestions: [],
        type: 'text',
        placeholder: 'Washington',
        field: 'about.last_name'
    });

    if (member.about.title || member.about.middle_name || show.show_title_middle_name) {
        a['about'].fields.push({
            id: 'title',
            label: 'Title',
            col_span: 1,
            suggestions: [],
            type: 'text',
            placeholder: 'Mr.',
            field: 'about.title'
        });

        a['about'].fields.push({
            id: 'middle_name',
            label: 'Middle Name',
            col_span: 1,
            suggestions: [],
            type: 'text',
            placeholder: 'Carver',
            field: 'about.middle_name'
        });
    } else {
        a['about'].fields.push({
            id: 'show_title_middle_name',
            type: 'ACTION',
            col_span: 2,
            label: 'Add Title & Middle Name'
        });
    }

    a['about'].fields.push({
        id: 'birthday',
        label: 'Birthday',
        col_span: 2,
        type: 'birthday',
        placeholder: '',
        field: 'birthday'
    });

    a['about'].fields.push({
        id: 'location',
        label: 'Location',
        col_span: 2,
        type: 'location',
        placeholder: 'Where are you in the world?',
        field: 'about.location'
    });

    a['about'].fields.push({
        id: 'occupation',
        label: 'Occupation',
        col_span: 2,
        type: 'occupation',
        placeholder: 'What do you do?',
        field: 'about.occupation'
    });

    if(!community.data.preferences.members.hide_gender) {
        a['about'].fields.push({
            id: 'gender',
            label: 'Sex',
            col_span: 2,
            type: 'gender',
            placeholder: '',
            field: 'about.gender'
        });
    }

    /*
    a['about'].fields.push({
        id: 'bio',
        label: 'Bio',
        col_span: 2,
        type: 'textarea',
        placeholder: '',
        field: 'about.bio'
    });

     */

    ids.push('contact');

    a['contact'] = {
        title: 'Contact',
        visibility: 'all-members',
        autocomplete: {},
        hidden_fields: {},
        fields: []
    };

    a['contact'].fields.push({
        id: 'email',
        required: true,
        label: 'Email',
        cf_data: {
            options: {
                choices: buildEmailOptions(member)
            }
        },
        corner_hint: <ActionWithIcon text="Add Email" onClick={()=>{
            if(actions.addEmail) {
                actions.addEmail();
            }
        }} />,
        col_span: 2,
        type: 'select-email',
        placeholder: 'myname@work.com',
        field: 'contact.email'
    });

    a['contact'].fields.push({
        id: 'phone',
        required: true,
        label: 'Phone',
        col_span: 2,
        type: 'phone',
        placeholder: '',
        field: 'contact.phone'
    });

    const main_phone_data = getPhoneNumberData(member.contact.data);

    if (!show.add_secondary_phone && !member.contact.secondary_phone && main_phone_data.valid) {
        a['contact'].fields.push({
            id: 'add_secondary_phone',
            type: 'ACTION',
            col_span: 2,
            label: 'Add Phone'
        });
    } else if (member.contact.secondary_phone || show.add_secondary_phone) {
        a['contact'].fields.push({
            id: 'secondary-phone',
            label: '2. Phone',
            col_span: 2,
            type: 'phone',
            placeholder: '',
            field: 'contact.secondary_phone'
        });
    }

    ids.push('address');

    // todo only add address if necessary
    if(profile_flags.address_visibility !== 'hidden') {
        a['address'] = {
            title: 'Address',
            visibility: 'all-members',
            autocomplete: {},
            hidden_fields: {},
            fields: []
        };

        if(profile_flags.address_visibility==='full') {
            a['address'].fields.push({
                id: 'address',
                label: 'Home Address',
                col_span: 2,
                required: true,
                type: 'address',
                placeholder: '',
                field: 'address'
            });
        } else {
            a['address'].fields.push({
                id: 'city',
                label: 'City',
                col_span: 1,
                required: false,
                type: 'text',
                placeholder: '',
                field: 'address.city'
            });
            a['address'].fields.push({
                id: 'country',
                label: 'Country',
                col_span: 1,
                required: false,
                type: 'country',
                placeholder: '',
                field: 'address.country'
            });
        }
    }

    if(profile_flags.social_visibility==='full') {
        if(profile_flags&&social_fields.length>0) {
            ids.push('social');

            a['social'] = {
                title: 'Social',
                visibility: 'all-members',
                autocomplete: {},
                hidden_fields: {},
                fields: social_fields.map(sf=>{
                    return {
                        id: sf.field,
                        label: sf.name?sf.name:sf.label,
                        col_span: 1,
                        required: sf.important,
                        type: sf.type,
                        placeholder: sf.placeholder,
                        field: sf.field
                    }
                })
            };
        }
    }

    let ps_keys = Object.entries(custom_fields).sort((a, b) => a[1].order - b[1].order).map(a=>a[0]);

    for (let i = 0; i < ps_keys.length; i++) {
        const key = ps_keys[i];
        const sec = custom_fields[key];
        const full_key = `custom-${key}`;

        const fields_transformed = transformCustomFields(sec.fields, sec.field_data);

        if (display_sections.display_custom[ps_keys[i]]) {

            ids.push(full_key);

            a[full_key] = {
                title: sec.name,
                visibility: sec.visibility ? sec.visibility : 'all-members',
                autocomplete: {},
                hidden_fields: {},
                fields: []
            };

            fields_transformed.forEach(b => {
                let cf_data = {};
                if(b.options) {
                    cf_data.options = {...b.options};
                }
                a[full_key].fields.push({
                    id: b.field,
                    required: important_fields.indexOf(b.id)!==-1,
                    label: b.name,
                    title: b.question || "",
                    col_span: getColSpanFromType(b.type),
                    type: b.type,
                    placeholder: b.placeholder ? b.placeholder : "",
                    hint: b.hint ? b.hint : "",
                    cf_data,
                    field: b.field
                });
            })
        }
    }

    console.log("OBJE",a)

    return {obj: a, ids: ids};
}