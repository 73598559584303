import React, {useContext, useState} from 'react';
import {Button,TextArea,InfoState} from "../../../core";
import styled from "styled-components";
import {Popover2} from "@blueprintjs/popover2";
import {GlobalContext} from "../../../../../app/global/global-context";
import {CommunityContext} from "../../../../../app/community/community-context";
import {authFetch} from "../../../../../config/network";
import {useIntl} from "react-intl";
import {CheckIcon} from "@heroicons/react/outline";
import {GiveFeedbackIcon} from "./icon";


const NoRounding = styled.div`

textarea {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    box-shadow:none;
}
`;

export function FeedbackItem({theme}) {
    const global = useContext(GlobalContext);
    const community = useContext(CommunityContext);
    const {formatMessage: f} = useIntl();
const [feedback,setFeedback] = useState("");
const [status,setStatus] = useState("");
    // success state
    function sendFeedback() {

        const payload = {
          feedback,
          page: window.location.pathname,
            user_uid: global.user_uid,
            name: global.user.name,
            community_name: community.data ? community.data.name : ""
        };

        const res = () => {
            setStatus("sent");
            setFeedback("");
            setTimeout(() => {
                setStatus("");
            }, 3000);
        };

        setStatus("sending");

        authFetch("/feedback", res, res, "POST", {payload});
    }

    let content;

    if(status === 'sent') {
        content = <div className="w-80 px-2 bg-white py-2 border border-gray-300 rounded-md">
            <InfoState icon={<CheckIcon/>} subtitle="Thank you for your feedback!" intent="success" />
        </div>;
    } else {
        content = <div className="rounded-md w-80 bg-white">
            <NoRounding className="bg-white">
                <TextArea value={feedback} onChange={(f)=>setFeedback(f)} autoFocus placeholder={f({id:'topbar.improve_unaty'})}/>

            </NoRounding>
            <div className="bg-gray-50 px-2 py-2 border-b border-l border-r border-gray-300 rounded-b-md">
                <div className="flex">
                    <div className="flex-grow">
                        <Button className="bp4-popover2-dismiss" text={f({id:'shared.actions.cancel'})} />
                    </div>
                    <div>
                        <Button disabled={feedback.length===0} onClick={()=>sendFeedback()} loading={status==='sending'} text={f({id:'shared.actions.send'})} intent="secondary" />
                    </div>
                </div>
                <div className="text-xs text-gray-500 pt-1.5">
                    Have a specific issue? Contact <a className="color-link" href="mailto:hey@getunaty.com?subject=Support">Unaty Support</a>.
                </div>
            </div>
        </div>;
    }

    return <Popover2 shouldReturnFocusOnClose={false} canEscapeKeyClose enforceFocus={false} interactionKind="click" fill content={content} placement={'bottom-end'} minimal popoverClassName='minimal-popover margin-top-8'>
        <GiveFeedbackIcon theme={theme} />
    </Popover2>;
}