import React, {useEffect, useState} from 'react';
import {EntityContext} from "./entity-context";
import {FirebaseDB} from "../../config/setup-firestore";

export function EntityProvider({children, match}) {
    const [entity, setEntity] = useState({data: null, not_found: false, handle: ''});

    useEffect(function () {
        const nh = match.params.handle;
        setEntity({
            data: null,
            handle: nh
        });
        getEntity(nh);
    }, [match.params.handle]);

    function getEntity(h) {
        FirebaseDB.collection('handles')
            .doc('global')
            .collection('handles')
            .where('handle', '==', h)
            .limit(1)
            .get()
            .then(snap => {
                if(snap.empty) {
                    setEntity({
                        ...entity,
                        not_found: true
                    })
                } else {
                    setEntity({
                        ...entity,
                        data: snap.docs[0].data()
                    })
                }
            });
    }

    return <EntityContext.Provider value={entity}>
        {children}
    </EntityContext.Provider>
}