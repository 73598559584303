import firebase from "firebase/app";
import {authFetch, externalAuthFetch} from "../../../config/network";

export function verifyPasswordResetCode() {
    firebase.auth().verifyPasswordResetCode(this.code)
        .then((email) => {
            this.email = email;
            this._email = email;
            this.setState({
                load_init: false
            });
            return true;
        })
        .catch((error) => {
            console.error('ERROR', error);
            // auth/expired-action-code - expired
            // auth/invalid-action-code - already used
            // auth/user-disabled - user disabled
            // auth/user-not-found - user not found
            this.setState({
                error_code: error.code,
                load_init: false,
                invalid: true
            });
            this.code = null;
        })
}

export function resendResetPasswordLink() {
    this.setState({
        resending_link: true
    });

    const res = (result) => {
        this.setState({
            resending_link: false,
            reset_link_sent: true
        });
    };

    externalAuthFetch("/send-password-reset-email", res, res, "POST", {email: this._email});
}

export function handleUpdateBasicInfo(payload) {
    const res = () => {
    };
    authFetch("/update-basic-user-info", res, res, "POST", {payload});
}

export function handleSentId(sent_id, community_uid) {
    const res = () => {
    };
    authFetch("/followed-invite", res, res, "POST", {
        payload: {
            sent_id, community_uid
        }
    });
}

export function resetPassword() {
    const payload = {
        profile_picture: this.state.user_profile_picture,
        profile_picture_color: this.state.user_profile_picture_color,
        name: this.state.user_name,
        email: this.email
    };

    this.setState({
        loading: true
    });
    firebase.auth().confirmPasswordReset(this.code, this.state.password)
        .then((result) => {
            // undefined?
            // need to sign in after this

            externalAuthFetch('/confirm-password-reset', () => {
            }, () => {
            }, 'POST', {email: this.email, payload});
            this.context.addToast({text: "Your Password was updated", intent: 'success'});
            this.context.login(this.email, this.state.password, this._continueTo ? () => {
                if (this.sent_id && this._community) {
                    handleSentId(this.sent_id, this._community);
                }
                if (payload && payload.name) {
                    handleUpdateBasicInfo(payload)
                }
                this.props.history.push(`${this._continueTo}`)
            } : null);

        })
        .catch((error) => {
            console.log('error', error);
        })
}

export function isSignInWithEmailLink() {
    if (firebase.auth().isSignInWithEmailLink(window.location.href)) {
        // Additional state parameters can also be passed via URL.
        // This can be used to continue the user's intended action before triggering
        // the sign-in operation.
        // Get the email if available. This should be available if the user completes
        // the flow on the same device where they started it.

        // The client SDK will parse the code from the link for you.
        firebase.auth().signInWithEmailLink(this._email, window.location.href)
            .then((result) => {
                // Clear email from storage.
                window.localStorage.removeItem('emailForSignIn');
                // You can access the new user via result.user
                // Additional user info profile not available via:
                // result.additionalUserInfo.profile == null
                // You can check if the user is new or existing:
                // result.additionalUserInfo.isNewUser

                this.props.history.push(`/memberships`);
            })
            .catch((error) => {
                // Some error occurred, you can inspect the code: error.code
                // Common errors could be invalid email and invalid or expired OTPs.
            });
    }
}

export function verifyUserAccount(actionCode) {

    firebase.auth()
        .applyActionCode(actionCode)
        .then(() => {

            this.setState({
                verified: true,
                load_init: false
            });
            externalAuthFetch('/confirm-verify-email', () => {
            }, () => {
            }, 'POST', {email: this._email});

            this.context.addToast({text: 'Your Email was Verified', intent: 'success'});
            this.props.history.push(`/memberships`);
        })
        .catch((error) => {
            // error, ask to reverify
            this.setState({
                verify_error: true,
                load_init: false
            });
        })
}