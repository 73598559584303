import React from 'react';
import {getMemberMeta} from "../../community/member/helpers";
import moment from "moment";
import {DEFAULT_USER_PHOTO} from "../../../config/defaults";

function buildCardMeta(data) {
    let d = data;
    d.short_birthday = '';
    let meta = getMemberMeta(d);
    meta.push({
        type: 'joined',
        value: `${moment(data.created_at).format('MMM YYYY')}`
    });
    return meta;
}

export function buildUserProfileCard(data) {
    return {
        type: 'user',

        appearance: {
            show_color: true,

            cover_photo: data.cover_photo,
            cover_photo_color: data.cover_photo_color,

            emoji: '', // group only
            event_date: null,

            profile_picture: data.profile_picture?data.profile_picture:DEFAULT_USER_PHOTO,
            profile_picture_color: data.profile_picture_color
        },

        main: {
            title: data.name,
            status: {
                emoji: '',
                text: ''
            },
            subtitle: [],
            description: data.about.bio,
            meta: buildCardMeta(data),
        },

        actions: [],

        rich_meta: {
            primary: {
                type: 'parent', // networks (community), moderators (group), organizers (event), education (user/member)
                title: 'Parent Organization', // parent community
                items: [
                    {
                        image: 'https://firebasestorage.googleapis.com/v0/b/unaty-staging.appspot.com/o/profile_pictures%2Fzex6-vXgU_small?alt=media',
                        name: 'Accenture',
                        type: 'community',
                        handle: 'accenture'
                    }
                ]
            },
            secondary: {
                type: 'education', // networks (community), moderators (group), organizers (event), education (user/member)
                title: 'Education', // parent community
                items: [
                    {
                        image: 'https://firebasestorage.googleapis.com/v0/b/unaty-staging.appspot.com/o/profile_pictures%2Fq6bOcf2kH_small?alt=media',
                        name: 'Technische Universität München (TUM)',
                        type: 'community',
                        handle: 'tum'
                    }
                ]
            },
            tertiary: null
        },

        stats: [

        ]
    }
}