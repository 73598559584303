import {FirebaseDB} from "../config/setup-firestore";

export function api_getEntityFromHandle(handle) {
    return FirebaseDB
        .collection('handles')
        .doc('global')
        .collection('handles')
        .where('handle', '==', handle)
        .where('scope', '==', 'global')
        .limit(1)
        .get();
}
