import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  margin-bottom: 6px;
`;

export function ESHeader({children}) {

    return <Wrapper>
        {children}
    </Wrapper>;
}