import React from 'react';
import {fv_getFormattedVal} from "../../../../utilities/format-values";
import {UserEmailManager} from "../../../user-email-manager";
import {WCField} from "../index";

export function HWCSideContact({data,handleSave,editing,setEditing,community,meta}) {

    return <div className="w-full h-full flex flex-col">
        <div className="flex space-y-2 flex-col pt-4 px-3 pb-7">
            <div>
                <UserEmailManager community={community}/>
            </div>
            <div>
                <WCField handleSave={handleSave} type="phone" id="phone"
                         value_formatted={fv_getFormattedVal('phone', data.phone, meta, {})}
                         editing={editing} meta={meta} setEditing={setEditing} value={data.phone}
                         label={"Phone"}/>
            </div>

        </div>
    </div>
}