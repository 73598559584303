import React from "react";
import styled from "styled-components";
import {PrContainer} from "./profile/container";

export const ProfileEditFrame = styled.div`
    margin-top: 1.5rem;
`;

export const ProfileFrame = (props) => {
    return <PrContainer {...props} />
};