import React, {useState} from 'react';
import {ATActionsFooter, ATPopoverContents} from "../components/shared";
import {ATLayoutList} from "./layout-list";
import {ATAction} from "../components/shared";
import styled from "styled-components";
import {TrashIcon} from '@heroicons/react/solid';

import {Button, TextAndEmoji} from "../../core";

const NameWrapper = styled.div`
    padding: 12px 12px 8px 12px;
`;

export function ATEditView({definitions,deleteView,view,updateView,id}) {
    const [new_view, setNewView] = useState(view);

    function handleChange(f,v) {
        setNewView({
            ...new_view,
            [f]: v
        })
    }

    function updateViewName(e,n) {
        setNewView({
            ...new_view,
            'name': n,
            'emoji': e
        });
    }

    function handleSave() {
        updateView(id, new_view);
    }

    function handleDeleteView() {
        deleteView(id);
    }

    let secondary = view.type === 'custom' && view.id !== "Default" ? <ATAction className='hover-opacity text-gray-700' onClick={()=>handleDeleteView()}><TrashIcon/></ATAction> : null;

    return<ATPopoverContents>
        <NameWrapper>
        <TextAndEmoji usePortal autoFocus onChange={(n,e)=>{
            updateViewName(e,n)
        }} emoji={new_view.emoji} value={new_view.name} placeholder="View Name" />
        </NameWrapper>
        <ATLayoutList layouts={definitions.allowed_layouts} selected={new_view.layout} onChange={(f,l)=>handleChange('layout', l)} />
        <ATActionsFooter primary={<Button disabled={new_view.name.length<2} onClick={()=>handleSave()} intent='secondary' text='Update View' />}>
            {secondary}
        </ATActionsFooter>
    </ATPopoverContents>
}