import {FirebaseDB} from "../../../config/setup-firestore";
//  .where(`group.${group_id}`,'==', true)
export function singleMembersQuery(community_uid,page_size,group_id,ref=null) {
    return new Promise(resolve => {
        if(group_id) {
            FirebaseDB
                .collection('community_members')
                .doc(community_uid)
                .collection('members')
                .where(`groups.${group_id}`,'==', true)
                .limit(250)
                .get()
                .then(snap=>resolve(snap));
        } else if(ref) {
            FirebaseDB
                .collection('community_members')
                .doc(community_uid)
                .collection('members')
                .where('archived','==', false)
                .orderBy(`about.last_name`)
                .startAfter(ref)
                .limit(page_size)
                .get()
                .then(snap=>resolve(snap));
        } else {
            FirebaseDB
                .collection('community_members')
                .doc(community_uid)
                .collection('members')
                .where('archived','==', false)
                .orderBy(`about.last_name`)
                .limit(page_size)
                .get()
                .then(snap=>resolve(snap));
        }
    });
}