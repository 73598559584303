import React, {useContext, useState} from "react";

import CreateMemberType from "./create";
import {PlusIcon} from '@heroicons/react/solid';
import RichList from "../../../../../library/components/old/rich-list";
import {Button, EntityHeader} from "../../../../../library/components/core";
import {COMMUNITY_PLANS} from "../../../../../config/defaults";
import {setDocumentTitle} from "../../../../../library/utilities/set-document-title";
import {CommunityContext} from "../../../community-context";
import {StandardPageHeight} from "../../../../../library/playground/standard-page-framework";
import {useHistory} from "react-router-dom";

export default function SettingsMemberTypes(props) {
    const community = useContext(CommunityContext);
    const history = useHistory();
    const [open, setOpen] = useState(false);

    setDocumentTitle('Member Types', community.data.name);

    function goToCreate() {
        setOpen(true);
    }

    const member_types = community.member_types;
    const member_types_num = Object.keys(member_types).length;

    const base_link = `/${community.data.handle}/admin/settings`;

    const keys = Object.keys(member_types);

    const options = {
        type: 'member_types',
        need_to_load_data: false,
        ids: keys,
        data: member_types,
        allow_selection: false,
        scroll_style: 'table', // infinite
        per_page: 15,
        query: {
            parent_collection: 'community_members',
            parent_doc_id: community.uid,
            collection: 'member_types',
            where: []
        },

        link_base: `${base_link}/member-types`,
        link_id_field: 'docid',

        default_sort: {
            field: 'updated_at',
            dir: 'desc'
        },

        renderer: {
            columns: [
                {
                    text: 'Name',
                    type: 'title',
                    field: 'plural',
                    width: '.8fr',
                    show: true
                },
                {
                    text: 'Singular',
                    type: 'text',
                    field: 'singular',
                    width: '0.5fr',
                    show: true
                },
                {
                    text: 'Emoji',
                    type: 'meta',
                    field: 'emoji',
                    width: '0.3fr',
                    show: true
                },
                {
                    text: 'Abbr.',
                    type: 'text',
                    field: 'abbreviation',
                    width: '0.2fr',
                    show: true
                }
            ],
            row: {
                actions: []
            }
        },

        sort_options: [
            {
                name: 'Name',
                field: 'plural',
                type: 'text',
                modifiers: ['desc', 'asc']
            }
        ],

        filter_options: [],

        header: {
            show_properties: false,
            show_filter: false,
            show_sort: false,
            show_search: false,

            mass_actions: []
        }
    };

    const hotkeys = [
        {
            id: 'N',
            keys: ["n"],
            fn: () => {
                goToCreate();
            }
        }
    ];

    return (<StandardPageHeight content full>

            <EntityHeader title="Member Types" actions={<Button disabled={member_types_num > (COMMUNITY_PLANS.STANDARD.member_types - 1)}
                                                            onClick={goToCreate.bind(this)} intent='secondary' icon={<PlusIcon/>} shortcut={'N'}
                                                            text='Create'/>} goBackTo={()=>history.push(`/${community.data.handle}/admin/settings`)}  />

                <RichList {...props} options={options}/>

                {open && <CreateMemberType {...props} open={open} onClose={() => {
                    setOpen(false);
                }}/>}
            </StandardPageHeight>
    );
}
