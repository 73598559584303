import React, {useContext, useEffect, useState} from "react";
import styled from "styled-components";
import {EntityCommunityWrapper} from "../entity-community-wrapper";
import {CommunityJoinFlow} from "./join-flow";
import {GlobalContext} from "../../global/global-context";
import {useHistory} from "react-router-dom";

/*
How will this page work
- if you're not logged in, then you have to first sign up or sign in
- once you're logged in you can apply to join a community
- if you're logged in and you already are a member, we redirect you to the community
- this page should work well on mobile
- if you're not a member, you create an account, then you see a screen to apply to the community
 */

export function JoinCommunityPage(props) {
    const global = useContext(GlobalContext);
    const history = useHistory();
    useEffect(function () {
        if (!global.logged_in) {
            history.push(`/sign-in?_redirectTo=/${props.match.params.handle}/join`)
        }
    }, [props.match.params.handle]);

    return <EntityCommunityWrapper {...props}>
        <div />
    </EntityCommunityWrapper>
}