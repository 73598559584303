import React, {useContext, useEffect, useState} from 'react';
import {CommunityContext} from "../../community-context";
import {InfoState, InlineLoader} from "../../../../library/components/core";
import {getManyItems} from "../../../../library/utilities/get-many-items";
import {DoubleCheckModal} from "../../../../library/components/old/double-check-modal";
import {es_renderEntityType} from "../../../../library/components/reorganize/entity-select/item";
import {XIcon} from "@heroicons/react/outline";
import {TempTip} from "../../../../library/playground/temp-tip";
import {RulesSuggestions} from "../magic/rules-suggestions";
import {GlobalContext} from "../../../global/global-context";
import {buildGroupDefaultRolesOrder} from "./segments";
import {levenstein} from "../../../../library/components/suggestions/segment-suggestions";

function Subtitle({title = "Roles"}) {
    return <div>
        <div className="text-xs font-semibold text-gray-500">{title}</div>
    </div>
}

function Section({title, children, empty, type = 'segments'}) {

    return <div className="">
        <Subtitle title={title}/>
        <div className="pt-1 -mx-1">
            {children}
            {empty && <InfoState subtitle={`No ${type} added`} fill/>}
        </div>
    </div>
}

/*
  <MembersListContainer>
            {loading && <InlineLoader/>}
            {!loading&&members.length===0&&<InfoState subtitle="No moderators found" fill />}
            {!loading&&members.map(mm=>{
                return <MemberListEntry _fns={_fns} key={mm.id} {...buildMemberEntryProps(mm,data,community,'moderators')} />
            })}
        </MembersListContainer>
 */

function hasPartsExactMatch(n1,n2) {
    let p1 = n1.split(' ');
    let p2 = n2.split(' ');

    let match = false;

    p1.forEach(p=>{
        p2.forEach(ps=>{
            if(p===ps&&p.length>3) {
                match = true;
            }
        })
    })

    return match;
}

function getMemberRoleReplacement(mid,group,all_roles) {

    const roles = Object.entries(all_roles);

    const members_assigned_roles = roles.filter(a=>a[1].assignee===mid);

    let match = "";
    members_assigned_roles.forEach(([id,role])=>{
        console.log('exact match check:',role.name,group.name)
        if (hasPartsExactMatch(role.name,group.name)) {
            match = id;
        }
    })

    console.log('replace:',mid,match)

    return match;
}

let match = ["Ambassador","Leader","Manager","Organizer","Coach","Lead"];

function getRoleNamingSchema(group, roles) {
    const one_has_co = roles.filter(r=>r.name.toLowerCase().indexOf(' co')!==-1||r.name.toLowerCase().startsWith('co'));
    const lengths = roles.map(r=>r.name.length);
    const names = roles.map(r=>r.name);

    if(one_has_co.length===0) {
        return "";
    }

    let lengths_ok = true;
    lengths.forEach(l=>{
        if(Math.abs(l-roles[0].name)>3) {
            lengths_ok = false;
        }
    })
    if(!lengths_ok) {
        return "";
    }

    return one_has_co[0].name;
}

function canSuggestNewRoles(group,community) {
    const role_moderators = Object.keys(group.moderators.roles);

    if(role_moderators.length<2) {
        return false;
    }

    const roles = role_moderators.map(rid=>community.all_roles[rid]);

    const role_naming_schema = getRoleNamingSchema(group,roles);

    if(!role_naming_schema) {
        return false;
    }

    return role_naming_schema;
}

function shouldSuggestionReorderRoles(group,community) {
    const role_moderators = Object.keys(group.moderators.roles);

    if(group.roles_order&&group.roles_order.length>1) {
        return false;
    }

    if(role_moderators.length<2) {
        return false;
    }

    const roles = role_moderators.map(rid=>{
        return {
            ...community.all_roles[rid],
            id: rid
        }
    });

    const role_naming_schema = getRoleNamingSchema(group,roles);

    if(!role_naming_schema) {
        return false;
    }

    const not_has_co = roles.filter(r=>r.name.toLowerCase().indexOf(' co')===-1&&!r.name.toLowerCase().startsWith('co')).map(a=>a.id);
    const has_co = roles.filter(r=>r.name.toLowerCase().indexOf(' co')!==-1||r.name.toLowerCase().startsWith('co')).map(a=>a.id);

    if(has_co.length===(role_moderators.length-1)&&not_has_co.length===1) {
        let list = [...not_has_co];
        list = list.concat(has_co);

        const role_ids = Object.keys(group.entities.roles);
        role_ids.forEach(ritid=>{
            if(!list.includes(ritid)) {
                list.push(ritid);
            }
        })

        return list;
    }

    return false;
}

function buildDirectSuggestions(group,community) {
    let ds = [];
    const moderators = group.moderators;

    const {user_uids,roles,members} = moderators;

    // are there members added already through roles we can suggest removing
    const role_user_uids = Object.keys(roles).filter(id=>!!community.all_roles[id]).map(id=>community.all_roles[id].assignee).filter(a=>!!a);

    let suggest_removing_members = [];

    const member_ids = Object.keys(members);

    member_ids.forEach(mid=>{
       if(role_user_uids.includes(mid)) {
           suggest_removing_members.push(mid);
       }
    });

    if(suggest_removing_members.length>0) {
        ds.push({
            type: 'remove-member-moderator',
            items: suggest_removing_members
        })
    }

    // find similar moderator names that probably should be in the group
    let suggest_replacing_members_with_existing_roles = [];
    console.log('getMemberRoleReplacement 1111',member_ids,community.all_roles)
    member_ids.forEach(mid=>{
        const member_role_replacement = getMemberRoleReplacement(mid,group,community.all_roles);
        console.log('getMemberRoleReplacement',mid,member_role_replacement)
        if(member_role_replacement) {
            suggest_replacing_members_with_existing_roles.push({
                member_id: mid,
                role_id: member_role_replacement
            })
        }
    });

    console.log('suggest_replacing_members_with_existing_roles',suggest_replacing_members_with_existing_roles)
    if(suggest_replacing_members_with_existing_roles.length>0) {
        ds.push({
            type: 'replace-member-with-existing-role',
            items: suggest_replacing_members_with_existing_roles
        })
    }

    // suggest creating a new ambassador to replace a member with the right terminology
    let suggest_creating_new_roles_for_members = [];

    const can_suggest_new_roles = canSuggestNewRoles(group,community);

    if(can_suggest_new_roles&&member_ids.length>0) {
        member_ids.forEach(mid=>{
            suggest_creating_new_roles_for_members.push({
                member_id: mid,
                name: can_suggest_new_roles
            });
        });
    }

    if(suggest_creating_new_roles_for_members.length>0) {
        ds.push({
            type: 'create-new-roles-for-member-mods',
            items: suggest_creating_new_roles_for_members
        })
    }

    const should_suggest_reorder_roles = shouldSuggestionReorderRoles(group,community);

    if(should_suggest_reorder_roles) {
        ds.push({
            type: 'suggest-role-order',
            items: should_suggest_reorder_roles
        })
    }

    return ds.length>0 ? ds : [];
}

export function GMMModerators({
                                  removeMembersAsModerators = () => {},
                                  addRolesAsModerators = () => {},
                                  updateRolesOrder = () => {},
                                  removeModerator = () => {},
                                  removeEntity = () => {
                                  },
                                  openAddMembers = () => {
                                  },
                                  can_manage,
                                  data,
                                  id
                              }) {
    const global = useContext(GlobalContext);
    const community = useContext(CommunityContext);

    useEffect(function () {
        const call = getModerators()
            .then(res=>{
                setMembers(res.map(a => {
                    return {
                        id: `${community.uid}-${a.id}`,
                        last_name: a.about.last_name,
                        name: a.name,
                        member_type: a.member_type,
                        profile_picture: a.profile_picture
                    }
                }).sort((a, b) => a.last_name > b.last_name ? 1 : -1))
                setLoading(false);

            });
        return () => {

        };
    }, [data]);

    const [loading, setLoading] = useState(true);
    const [members, setMembers] = useState([]);

    const [modal, setModal] = useState(null);
    const [modal_data, setModalData] = useState(null);

    async function getModerators() {

        return await new Promise((resolve, reject) => {
            getManyItems('community_members.members', Object.keys(data.moderators.user_uids), (res) => {
               resolve(res);
            }, community.uid)
        });
    }

    function confirmRemove() {

    }

    let actions = can_manage ? [
        {
            key: 'remove-moderator',
            tooltip: "Remove",
            icon: <XIcon/>,
            show: (type, id) => {
                if (type === 'members' || (type === 'roles' && data.moderators.roles[id])) {
                    return true;
                }
                return false;
            },
            onClick: (type, id) => {
                removeModerator(type, id)
            }
        }
    ] : [];

    const entity_props = {
        can_remove: false,
        community: community,
        onRemove: (ent_type, ent_id) => {
        },
        can_select: false,
        onSelect: () => {
        },
        onToggleSegmentLive: () => {
        },
        selected: [],
        block_removal: {}
    };

    const mods_roles_count = Object.keys(data.moderators['roles']).length;
    const mods_members_count = Object.keys(data.moderators['members']).length;

    const no_mods = !mods_roles_count && !mods_members_count;
    const show_sections = mods_roles_count > 0 && mods_members_count > 0;

    const roles_order = buildGroupDefaultRolesOrder(data);

    let r_content = mods_roles_count > 0 ? es_renderEntityType('roles', data.moderators['roles'], community.uid, entity_props, actions, roles_order) : null;
    let m_content = mods_members_count > 0 ? es_renderEntityType('members', data.moderators['members'], community.uid, entity_props, actions) : null;

    let r_section, m_section;

    if (show_sections) {
        r_section = <Section empty={mods_roles_count === 0} title="Roles" type="roles">
            <div>
                {r_content}
            </div>
        </Section>
        m_section = <Section empty={mods_members_count === 0} title="Members" type="members">
            <div>
                {m_content}
            </div>
        </Section>
    } else {
        r_section = <div>
            {r_content}
        </div>;
        m_section = <div>
            {m_content}
        </div>
    }

    const _actions = {
        setRoleOrder: (items) => {
            updateRolesOrder(items)
        },
        createRolesForMembers: (items) => {
            global.handleSetRightMenu('create-role',{
                onConfirmed: (payload)=>{
                    if(payload&&payload.ids&&payload.ids.length) {
                       addRolesAsModerators(payload.ids,true);
                    }
                    global.handleCloseRightMenu();
                },
                initial: items.map(it=>{
                    return {
                        name: it.name,
                        assignee: it.member_id
                    }
                }),
                flow: 'leaders'
            })
        },
        createRoles: (items) => {
            global.handleSetRightMenu('create-role',{
                onConfirmed: (payload)=>{
                    if(payload&&payload.ids&&payload.ids.length) {
                        addRolesAsModerators(payload.ids);
                    }
                    global.handleCloseRightMenu();
                },
                initial: items.map(it=>{
                    return {
                        name: it.name
                    }
                }),
                flow: 'leaders'
            })
        },
        addRolesAsModerators: (items) => {
            addRolesAsModerators(items)
        },
        removeMembersAsModerators: (items)=>{
            // items are an array of member ids
            removeMembersAsModerators(items)
        }
    }

    const direct_suggestions = buildDirectSuggestions(data,community);

    return <div id="member-list"
                className="pt-3 adaptive-modal-height px-4 pb-3 space-y-3 flex flex-col overflow-y-auto">
        <TempTip tip="group-role-mods"/>
        {can_manage&&<RulesSuggestions direct={direct_suggestions} _actions={_actions} data={data} context="group" scope={"moderators"} />}
        {no_mods && <InfoState subtitle={`This Group doesn't have any Moderators`} fill/>}
        {mods_roles_count > 0 && r_section}
        {mods_members_count > 0 && m_section}
        {modal === 'double-check' && <DoubleCheckModal onConfirm={() => confirmRemove()} onClose={() => {
            setModal(null);
            setModalData(null)
        }} onCancel={() => {
            setModal(null);
            setModalData(null)
        }} type={modal_data.type}/>}
    </div>
}