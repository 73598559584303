import {FirebaseDB} from "../../config/setup-firestore";


export async function handleIsUnique(handle, type = 'user', scope = 'global', community_uid = '', check_id) {
    // users and domains are global scoped against the same space
    // channels, lists, teams, roles are community scoped
   return await handleExists(handle, scope, community_uid, check_id);
}

async function handleExists(handle, scope, community_uid, check_id='') {
    if(scope==='global') {
        return await FirebaseDB
            .collection('handles')
            .doc('global')
            .collection('handles')
            .where('handle', '==', handle)
            .get()
            .then((snap) => {
                return !snap.empty;
            })
            .catch((err) => {
                return false;
            });
    } else {
        return await FirebaseDB
            .collection('handles')
            .doc(community_uid)
            .collection('handles')
            .where('handle', '==', handle)
            .get()
            .then((snap) => {
                if(!snap.empty) {
                    const d = snap.docs[0];
                    const dt = d.data();
                    if(dt&&dt.member_id===check_id) {
                        return false;
                    }
                }

                return !snap.empty;
            })
            .catch((err) => {
                return false;
            });
    }

}