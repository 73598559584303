import React, {Component} from "react";
import {AuthTitle} from "../../landing/layout/splash-container";

import {ProfilePicture} from "../../../library/components/old/profile-picture";
import styled from "styled-components";
import firebase from "firebase/app";
import {externalAuthFetch} from "../../../config/network";

import {GlobalContext} from "../../global/global-context";
import {Avatar, Button, InfoState, InlineLoader} from "../../../library/components/core";
import {api_getEntityFromHandle} from "../../../api/entity";
import {api_getCommunity} from "../../../api/community";
import {CommunitySignInScreen} from "../../../library/components/community-sign-in-screen";
import {FirebaseDB} from "../../../config/setup-firestore";
import {SignInWrapper} from "./SignInWrapper";
import {JoinWrapper} from "../join-wrapper";
import SignUpBlock from "../signup/SignUpBlock";
import {Link} from "react-router-dom";
import {InformationCircleIcon} from "@heroicons/react/solid";
import {TempTip} from "../../../library/playground/temp-tip";

function tokenExpired(date_string) {
    const date = new Date(date_string);
    const now = new Date();
    return date < now;
}

const Container = styled.div`
  display: flex;
  flex-flow: column;
`;

const Loading = styled.div`
  padding: 48px;

  .bp4-spinner .bp4-spinner-head {
    stroke: rgb(27, 149, 224);
  }
`;

function getTitle2(community_data,group_data,sign_up_mode,color,can_apply_to_join) {
    if(!community_data) {
        return ""
    }

    if(community_data&&!can_apply_to_join&&sign_up_mode) {
        return <AuthTitle><span style={{color}}>{community_data.name}</span>&nbsp;<span>Member Applications</span></AuthTitle>
    }

    if(sign_up_mode) {
        if(group_data) {
            return <AuthTitle><span>Create an Account</span>&nbsp;to join&nbsp;<span style={{color}}>{group_data.name}</span></AuthTitle>
        } else {
            return <AuthTitle><span>Create an Account</span></AuthTitle>
        }
    } else {
        if(group_data) {
            return <AuthTitle><span>Welcome to</span>&nbsp;<span style={{color}}>{group_data.name}</span></AuthTitle>
        } else {
            return <AuthTitle><span>Welcome back to</span>&nbsp;<span style={{color}}>{community_data.name}</span></AuthTitle>
        }
    }
}

function getTitle(color,name,group_data,sign_up_mode) {

    if(group_data) {
        return <AuthTitle><span>Apply to join</span>&nbsp;<span style={{color}}>{group_data.name}</span></AuthTitle>
    }

    return <AuthTitle><span>Apply to join</span>&nbsp;<span style={{color}}>{name}</span></AuthTitle>
}

function CommunityTag({em1,profile_picture,name,color}) {

    return <div className="flex space-x-2 items-center">
        <Avatar url={`${profile_picture}_small?alt=media`} emoji={em1} size='xs' text={name} color={color}/>
        <div className="font-bold text-gray-800 text-sm">
            {name}
        </div>
    </div>
}

export function canApplyToJoin(community,group) {
    let can_apply = false;

    if(community) {
        const v1 = !!community.preferences.members.allow_new_member_applications;
        if(v1&&!group) {
            can_apply = true;
        } else if(v1&&group) {
            can_apply = !!group.enable_new_applications;
        }
    }

    return can_apply;
}

function buildMessage(name) {
    return <span>
        If you are a member of this community, please try <Link className="color-link" to={"/sandbox/sign-out"}>resetting your password</Link> with the e-mail address {name} uses to contact you, or reach out to an organizer to activate your account.
    </span>
}

export default class CommunitySignIn extends Component {
    static contextType = GlobalContext;

    constructor(props) {
        super(props);
        this.token = props.match.params.token;
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        this.redirectCommunity = urlParams.get('_redirectTo');
        this.group = urlParams.get('group');
        this.state = {
            token_data: null,
            community_data: null,
            token_expired: false,
            token_type: false,
            token_exists: true,
            token_redeemed: false,
            group_data: null,
            token_valid: false,
            community_uid: '',
            name: '',
            color: '',
            cover_photo: null,
            cover_photo_color: null,
            profile_picture: '',
            emoji: ''
        };
    }

    componentDidMount() {
        const handle = this.props.match.params.handle;
        this.getEntity(handle);

        if (this.token) {
            this.getToken();
        }
    }

    getGroup() {
        FirebaseDB
            .collection('community_public')
            .doc(this.state.community_uid)
            .collection('groups')
            .doc(this.group)
            .get()
            .then(this.handleGroup.bind(this));
    }

    handleGroup(doc) {
        if (doc.exists) {
            const data = doc.data();
            this.setState({
                group_data: {...data,id:doc.id}
            })
        } else {
            console.error('no doc')
        }
    }

    getToken() {
        FirebaseDB.collection('tokens')
            .doc(this.token)
            .get()
            .then(this.handleToken.bind(this));
    }

    handleToken(doc) {
        if (doc.exists) {
            const data = doc.data();
            if (tokenExpired(data.valid_until)) {
                this.setState({
                    token_data: data,
                    token_expired: true
                })
            } else if (data.redeemed) {
                this.setState({
                    token_data: data,
                    token_redeemed: true
                })
            } else if (data.type === 'set-password') {
                // set-password
                // verify-email
                this.setState({
                    token_valid: true,
                    token_data: data,
                    token_type: data.type
                })
            } else {
                this.setState({
                    token_data: data,
                    token_type: data.type
                })
            }
        } else {
            this.setState({
                token_data: null,
                token_exists: false
            })
        }
    }

    getEntity(handle) {
        const h = handle ? handle : this.props.match.path.split('/')[1];
        if (!h) {
            return;
        }
        api_getEntityFromHandle(h)
            .then(this.handleEntity.bind(this))
    }

    handleEntity(result) {
        if (result.empty) {
            this.setState({
                not_found: true
            })
        } else {
            const doc = result.docs[0].data();
            // check if community, if not ->
            if (doc.type === 'community') {
                this.getCommunity(doc.community_uid);
            } else {
                this.props.history.push('/');
            }
        }
    }

    getCommunity(community_uid) {
        api_getCommunity(community_uid)
            .then(doc => {
                const data = {...doc.data(),id:doc.id,uid:doc.id};

                this.setState({
                    name: data.name,
                    community_data: data,
                    community_uid,
                    cover_photo: data.branding.sign_in_cover_photo,
                    cover_photo_color: data.branding.sign_in_cover_photo_color,
                    color: data.branding.color,
                    profile_picture: data.profile_picture,
                    emoji: data.emoji
                }, () => {
                    if (this.group) {
                        this.getGroup();
                    }
                });
                return true;
            })
    }

    handleSubmit(email, password) {
        const cb = this.redirectCommunity ? () => {
            this.props.history.push(`${this.redirectCommunity}`)
        } : null;

        this.context.login(email, password, cb);
    }

    testPW() {
        externalAuthFetch("/password-reset", () => {}, () => {}, "POST", {});
    }

    render() {
        const {
            name,
            token_data,
            cover_photo,
            community_uid,
            cover_photo_color,
            group_data,
            community_data,
            profile_picture,
            color,
            emoji
        } = this.state;

        const {login_error} = this.context;

        const sign_up_mode = window.location.pathname.includes('/sign-up');

        const group_loading = this.group && !group_data;

        let content = null, note = null, block = null, meta = null;

        const can_apply_to_join = canApplyToJoin(community_data,group_data);

        const links_base = community_data ? `/${community_data.handle}` : ``;

        if (this.context.logged_in) {
            const em1 = profile_picture === '' && emoji === '' ? '👋' : emoji;

            const title = getTitle(color,name,group_data,sign_up_mode);

            if(this.group&&group_loading) {
                content = <div>
                    <InlineLoader/>
                </div>
            } else if(this.group&&group_data) {
                content = <div className="space-y-4">
                    <Container className="space-y-4">
                        <CommunityTag em1={em1} {...this.state} />
                    {title}
                    </Container>
                    <JoinWrapper can_apply_to_join={can_apply_to_join} community_data={community_data} group_id={this.group} group={group_data} community_uid={community_uid} name={name}/>
                </div>
            } else {
                content = <div className="space-y-4">
                    <Container className="space-y-4">
                        <ProfilePicture image={profile_picture} emoji={em1} size='medium' width='72px' height='72px'
                                        text={name} color={color}/>
                        {title}
                    </Container>
                    <JoinWrapper can_apply_to_join={can_apply_to_join} community_data={community_data} community_uid={community_uid} name={name}/>
                </div>;
            }

        } else if (name === '' || (this.token && token_data === null)) {
            content = <Loading>
                <InlineLoader/>
            </Loading>;
        } else if (this.token) {
            const {token_expired, token_valid, token_redeemed, token_exists, token_data} = this.state;

            if (token_expired) {
                content = <div>
                    token expired, resend..
                </div>;
            } else if (!token_exists) {
                content = <div>
                    token does not exist, resend..
                </div>;
            } else if (token_redeemed) {
                content = <div>
                    token is not valid, resend..
                </div>;
            } else if (token_valid) {
                content = <div>
                    <div>Token is valid, set your password:</div>
                    <div onClick={this.testPW.bind(this)}>
                        Test
                    </div>
                </div>;
            }
        } else {
            const em = profile_picture === '' && emoji === '' ? '👋' : emoji;
            const title2 = getTitle2(community_data,group_data,sign_up_mode,color,can_apply_to_join);
            const signup_props = {
              history: this.props.history,
                applying: true,
                is_community: !!community_data,
                is_group: !!this.group,
                group_data: group_data,
                community_data
            };

            if(!can_apply_to_join&&sign_up_mode) {
                content = <div>
                    {title2}
                    <InfoState icon={<InformationCircleIcon/>} intent="secondary"
                               subtitle={buildMessage(community_data.name)}/>
                    <div className="pt-4">
                        <Button intent="secondary" text="Back to Sign in" onClick={() => {
                            if(community_data&&community_data.handle) {
                                this.props.history.push(`/${community_data.handle}/sign-in`)
                            } else {
                                this.props.history.push('/sign-in')
                            }
                        }}/>
                    </div>
                </div>
            } else if(this.group&&group_loading) {
                content = <div>
                    <InlineLoader/>
                </div>
            } else if(this.group&&group_data) {
                note = sign_up_mode ? <TempTip tip="sign-up-apply"/> : <div></div>;
                block = sign_up_mode ? <SignUpBlock {...signup_props} /> : <SignInWrapper group_data={group_data} login_error={login_error} community_handle={community_data.handle} can_apply_to_join={can_apply_to_join} community_data={community_data} color={color} handleSubmit={this.handleSubmit.bind(this)} {...this.props} />
                meta = sign_up_mode ?  <div className="pt-4 text-xs text-gray-700">
                    Already have an account? <Link className='color-link' to={`${links_base}/sign-in`}>Sign in</Link>
                </div> : <div></div>;
                content = <div className="space-y-4">
                    <Container className="space-y-4">
                        <CommunityTag em1={em} {...this.state} />
                        {title2}
                    </Container>
                    {note}
                    {block}
                    {meta}
                </div>
            } else {
                note = sign_up_mode ? <TempTip tip="sign-up-apply"/> : <div></div>;
                block = sign_up_mode ? <SignUpBlock {...signup_props} /> : <SignInWrapper group_data={group_data} login_error={login_error} community_handle={community_data.handle} can_apply_to_join={can_apply_to_join} community_data={community_data} color={color} handleSubmit={this.handleSubmit.bind(this)} {...this.props} />
                meta = sign_up_mode ?  <div className="pt-4 text-xs text-gray-700">
                    Already have an account? <Link className='color-link' to={`${links_base}/sign-in`}>Sign in</Link>
                </div> : <div></div>;
                content = <div className="space-y-4">
                    <Container className="space-y-4">
                        {sign_up_mode?<CommunityTag em1={em} {...this.state} />:<ProfilePicture image={profile_picture} emoji={em} size='medium' width='72px' height='72px'
                                                                                                  text={name} color={color}/>}
                        {title2}
                    </Container>
                    {note}
                    {block}
                    {meta}
                </div>;
            }
        }

        return <CommunitySignInScreen url={cover_photo ? cover_photo : ''}
                                      color={cover_photo_color ? cover_photo_color : '#d7d7d7'}>
            {content}
        </CommunitySignInScreen>
    }
}