import React, {useState, useEffect} from 'react';
import {Button, InfoState} from "../../library/components/core";
import {CheckIcon} from "@heroicons/react/solid/esm";
import {externalAuthFetch} from "../../config/network";
import {useHistory} from 'react-router-dom';

export function QPUConfirm(props) {
    const history = useHistory();
    const [token, setToken] = useState(props.match.params.qpu_token);
    const [community, setCommunity] = useState(null);

    useEffect(function () {
        setToken(props.match.params.qpu_token);
        getData();
    }, [props.match.params.qpu_token]);

    function getData() {
        if (!token) {
            console.log('ERROR');
            return;
        }

        const res = (res1) => {
            if (res1) {
                if(res1.data.community) {
                    setCommunity(res1.data.community);
                } else {
                    setCommunity({});
                }

            }

        };

        const payload = {
            token
        };

        externalAuthFetch("/qpu-get-community", res, res, "POST", {payload});
    }

    if (!community) {
        return null;
    }


    return <div className="p-12">
        <InfoState fill button={<Button intent="secondary" onClick={() => history.push(`/${community.handle}/sign-in`)} text={`${community.name?`Log in to ${community.name}`:'Log in'}`}/>} icon={<CheckIcon/>} title="You're all set!" intent="success"
                   subtitle={`Thank you for making us make sure we have all your information!`}
        />
    </div>
}