export function getNestedValue(field,data) {
    if(field.indexOf(',')!==-1) {
        // some cases like url:cover_photo,color:cover_photo_color
        const sp = field.split(',');
        let obj = {};
        for(let i=0;i<sp.length;i++) {
            const it = sp[i];
            const sp2 = it.split(':');
            obj[sp2[0]] = data[sp2[1]];
        }
        return obj;
    }
    const t = field.split('.');
    if(t.length===3) {
        return data[t[0]][t[1]][t[2]];
    } else if(t.length===2) {
        return data[t[0]][t[1]];
    } else {
        return data[t[0]];
    }
}