import React, {useContext, useState} from 'react';
import {
    ClipboardIcon,
    DotsVerticalIcon,
    KeyIcon,
    MailIcon,
    MenuAlt2Icon,
    CogIcon,
    EyeIcon
} from "@heroicons/react/outline";

import {DetailModal} from "../../../../library/playground/detail-modal";
import {ActionWithIcon, Button, Field} from "../../../../library/components/core";
import {CommunityContext} from "../../community-context";
import {authFetch} from "../../../../config/network";
import {GroupModalAboutTab} from "./modal-about-tab";
import {GroupModalModeratorsTab} from "./modal-moderators-tab";
import {GroupModalEmailListTab} from "./modal-email-list-tab";
import {GroupModalAccessTab} from "./modal-access-tab";
import {GroupModalPinboardTab} from "./modal-pinboard-tab";
import {MemberStarred} from "../../../../library/components/starring";
import {GroupSubscribeButton} from "../../posts/group-subscribe-button";
import {PopupMenu} from "../../../../library/components/old/popup-menu";
import {Popover2} from "@blueprintjs/popover2";
import {getManyMembers} from "../../../../api/members/get-many";
import {GlobalContext} from "../../../global/global-context";
import {copyTextToClipboard} from "../../../../library/utilities/general/copy-to-clipboard";
import {GroupModalVisibilityTab} from "./modal-visibility-tab";
import {useHistory} from "react-router-dom";
import {DoubleCheckModal} from "../../../../library/components/old/double-check-modal";

export function DetailModalHoverField({
    label = "Name",
    can_edit = false,
    value = "Group Name",
    placeholder = "Add a Name",
    copy,
    onClick = ()=>{}
                                      }) {
    const content = <Field label={label} corner_hint={copy?<ActionWithIcon onClick={onClick} text="Copy" />:can_edit?<ActionWithIcon onClick={onClick} text="Edit" />:null}>
        {value&&<div className="text-smbase line-clamp-1 text-gray-800 -mt-0.5">
            {value}
        </div>}
        {!value&&<div className="text-gray-500 text-smbase">
            {placeholder}
        </div>}
    </Field>;

    if(!can_edit) {
        return content;
    }

    return <div onClick={onClick} className="-mx-1.5 py-1 -my-1 px-1.5 rounded-md hover:bg-gray-100 cursor-pointer transition-colors">
        {content}
    </div>
}

function RichTab({
    active = false,
    onClick = ()=>{},
    label,
    icon
                 }) {

    return <div onClick={onClick} className={`px-2 h-8 flex space-x-2 py-1.5 ${active?" bg-selection hover:bg-secondary-500":"bg-white  hover:bg-gray-200"} rounded-md transition-colors cursor-pointer`}>
        <div className={`h-svg-5 h-8 flex-none ${active?"text-gray-200":"text-gray-600"}`}>
            {icon}
        </div>
        <div className={`text-sm flex-grow font-semibold ${active?"text-white":"text-gray-800"}`}>
            {label}
        </div>
    </div>
}

export function GroupSettingsModal({
                                       is_moderator = false,
                                       is_admin = false,
                                       is_member = false,
                                       setData = ()=>{},
                                       getGroup = ()=>{},
                                       onClose = ()=>{},
    id = "",
                                       group_type = 'standard',
                                       initial_tab = 'About',
                                       group_data = {}
                                   }) {
const history = useHistory();
    const [modal, setModal] = useState('');
    const [tab, setTab] = useState(initial_tab);
const global = useContext(GlobalContext);
const community = useContext(CommunityContext);

    const email_list_power_up = group_data['power_ups']['unaty__mailing_lists'];

    const meta = {
        email_list_power_up,
        community,
        can_manage: is_admin||is_moderator,
        group_data,
        getGroup,
        setData,
        id
    };

    function renderTab() {
        switch (tab) {
            case 'About':
                return <GroupModalAboutTab {...meta} />
            case 'Moderators':
                return <GroupModalModeratorsTab {...meta} />
            case 'Email List':
                return <GroupModalEmailListTab {...meta} />
            case 'Access':
                return <GroupModalAccessTab {...meta} />
            case 'Visibility':
                return <GroupModalVisibilityTab {...meta} />
            case 'Pinboard':
                return <GroupModalPinboardTab {...meta} />
            default : {
                return <div>
                    Sorry, nothing found.
                </div>
            }
        }
    }

    function deleteGroup() {
        const handle = community.data.handle;
        const res = (result) => {
            global.addToast({text: 'Group Deleted', intent: 'success'});
            history.push(`/${handle}/explore/groups`);
        };

        const payload = {
            community_uid: community.uid,
            member_id: community.member_id,
            id: id,
        };

        authFetch("/groups/delete", res, res, "POST", {payload});
    }

    function copyMemberNames() {
        getManyMembers(community.uid, [{
            field: "group_ids",
            operator: "array-contains",
            value: id
        }], {field: 'about.last_name', dir: 'asc'})
            .then(members => {
                global.addToast({
                    text: "Copied to clipboard",
                    intent: "info"
                });
                copyTextToClipboard(members.map(m=>m.name).join(', '))
            });
    }

    function copyMemberEmails() {
        getManyMembers(community.uid, [{
            field: "group_ids",
            operator: "array-contains",
            value: id
        }], {field: 'about.last_name', dir: 'asc'})
            .then(members => {
                global.addToast({
                    text: "Copied to clipboard",
                    intent: "info"
                });
                copyTextToClipboard(members.map(m=>m.account_email).join(', '))
            });
    }

    function leaveGroup() {
        // setLoading(true);
        const res = () => {
            getGroup();
            //        setLoading(false);
            //    cb();
        };
        const payload = {
            id: group_data.id,
            member_id: community.member_id,
            community_uid:community.uid
        };

        authFetch("/groups/leave", res, res, "POST", {payload});
    }

    let tabs = [
        {
            icon: <MenuAlt2Icon/>,
            label: "About"
        }
    ];
    let tabs2 = [

    ];

    const can_manage = is_admin || is_moderator;

    if(can_manage) {
        tabs.push({
            icon: <EyeIcon/>,
            label: "Visibility"
        });
        tabs.push({
            icon: <ClipboardIcon />,
            label: "Pinboard"
        });
        tabs.push({
            icon: <KeyIcon />,
            label: "Access"
        });
        tabs2.push({
            icon: <MailIcon />,
            label: "Email List"
        })
    }

    const can_star = is_member;

    const star_meta = {
      type_id: id,
      type: "group"
    };

    const moderators = group_data.moderators.user_uids;
    const moderators_len = Object.keys(moderators).length;
    const can_leave = group_data.entities.members[community.member_id] && (!moderators[community.member_id] || (moderators[community.member_id] && moderators_len > 1));

    let more_options = [];

    if(is_member) {
        more_options.push({
            type: 'item',
            title: 'Copy Member Names',
            onClick: ()=>copyMemberNames()
        })
        more_options.push({
            type: 'item',
            title: 'Copy Member Emails',
            onClick: ()=>copyMemberEmails()
        })
    }

    if(can_leave) {
        more_options.push({
            type: 'item',
            intent: 'danger',
            title: 'Leave Group',
            onClick: ()=>leaveGroup()
        })
    }

    const not_everyone_group = id!==community.data.everyone_group_id;

    if(can_manage&&not_everyone_group) {
        more_options.push({
            type: 'item',
            intent: 'danger',
            title: 'Delete Group',
            onClick: ()=>setModal('group_delete')
        })
    }

    const actions = <div className="pl-4 flex space-x-2 pb-3">
        {can_star && <div>
            <MemberStarred layout="button" type={star_meta.type} type_id={star_meta.type_id}/>
        </div>}
        <div>
            <GroupSubscribeButton group_id={id} layout="button" />
        </div>
        <div>
            {more_options.length>0&&<Popover2 usePortal={false} placement={'bottom-start'} content={<PopupMenu items={more_options}/>} minimal popoverClassName='minimal-popover my-2'>
                <Button icon={<div className="h-svg-5 pl-px"><DotsVerticalIcon/></div>}/>
            </Popover2>}
        </div>
        {can_manage&&modal==='group_delete'&&<DoubleCheckModal type={'group_delete'} onConfirm={() => {
            if (modal === 'group_delete') {
                deleteGroup();
            } else {
                setModal(null);
            }
        }} onCancel={() => setModal(null)} onClose={() => setModal(null)}/>}
    </div>;

    const custom = <div className="grid grid-cols-3 gap-5 px-4 py-3">
        <div className="">
            <div className="space-y-1">
            {tabs.map((t,k)=>{
                return <RichTab onClick={()=>{
                    setTab(t.label);
                }} key={k} active={tab===t.label} {...t} />;
            })}
            </div>
            {tabs2.length>0&&<div className="mt-4">
                <div className="uppercase text-gray-700 mb-1 text-xs px-2 font-semibold">Power-Ups</div>
                <div className="space-y-1">
                    {tabs2.map((t,k)=>{
                        return <RichTab active={tab===t.label} onClick={()=>{
                            setTab(t.label);
                        }} key={k} {...t} />;
                    })}
                </div>
            </div>}
        </div>
        <div className="col-span-2">
            {renderTab()}
        </div>
    </div>;

    return <DetailModal min_height="460px" size="plus" title={group_data.name} actions={actions} onClose={onClose} tabs={[{label:"Group",type:"custom",component:custom}]} />
}