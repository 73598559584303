import React, {useEffect, useState} from "react";
import {ColorInput, Field} from "../core";
import {background_patterns} from "./data";
import {NewTooltip} from "../reorganize/new-tooltip";

export function buildBkgPattern(id='hideout',color) {
    return background_patterns[id].svg.replace('%239C92AC',`${color.replace('#','%23')}`);
}

const all_patterns = Object.entries(background_patterns).map(([id,data])=>{
    return {
        id,
        label:data.label
    };
})

function Pattern({id,label,onSelect,active,fore,back}) {
    const pt = buildBkgPattern(id,fore);
    return <div>
        <div onClick={onSelect} className={`h-14 w-full rounded-md shadow-sm border-2 hover:opacity-80 transition-opacity cursor-pointer ${active?"border-selection":"border-gray-100"}`} style={{backgroundColor:`${back}`,backgroundSize:'40%',backgroundPosition:'center',backgroundImage:`url("${pt}")`}}>

        </div>
    </div>
}

export function PatternBackgroundPreview({back,fore,id}) {
    const pattern = buildBkgPattern(id,fore);
    return  <div className="h-36 w-full rounded-md shadow-sm" style={{backgroundColor:`${back}`,backgroundPosition:'center',backgroundImage:`url("${pattern}")`}}>

    </div>
}

const default_fore = '#fba368';
const default_selected = 'wiggle';
const default_back = '#ffefe0';

// save format
// _color = background
// cover_photo = pattern_id_foreground color with hashtag
export function BackgroundPatternBuilder({
    preset_color = '',
                                             onSelect = ()=>{},
                                             onSelectPattern = ()=>{},
                                         }) {
    const [selected,setSelected] = useState(default_selected);
    const [back,setBack] = useState(default_back);
    const [fore,setFore] = useState(default_fore);

    useEffect(function () {
        onSelect({
            back,fore,id:selected
        })
    }, [back,fore,selected]);

    useEffect(function() {
        onSelectPattern();
    }, [selected]);

    return <div className="w-full space-y-4 max-w-md">

           <PatternBackgroundPreview back={back} fore={fore} id={selected} />

        <div className="grid grid-cols-2 gap-4">
            <Field label="Foreground">
                <ColorInput icon_only show_label={false} value={fore} onChange={c=>setFore(c)} />
            </Field>
            <Field label="Background">
                <ColorInput icon_only show_label={false} value={back} onChange={c=>setBack(c)} />
            </Field>
        </div>
        <Field label="Pattern">
        <div className="grid grid-cols-3 gap-3">

            {all_patterns.map(p=>{
                return <div key={p.id}>
                    <NewTooltip message={p.label}>
                    <Pattern onSelect={()=>setSelected(p.id)} active={selected===p.id} key={p.id} {...p} fore={fore} back={back} />
                    </NewTooltip>
                </div>
            })}
        </div>
        </Field>
        <div className="text-xs text-gray-500 pt-4 leading-5">
            A big thanks to Steve Schoger and <a className="color-link" href="https://www.heropatterns.com/" rel="noreferrer nofollow">Hero Patterns</a> for the terrific patterns!
        </div>
    </div>
}