import React from "react";
import {Button} from "../../../core";

export function OnboardingTitle({title = 'Title', subtitle = ''}) {

    return <div>
        <div className="text-gray-800 font-bold text-xl md:text-4xl">{title}</div>
        {subtitle && <div className="text-gray-500 text-sm sm:text-base sm:pt-2">{subtitle}</div>}
    </div>
}

export function OnboardingActions({
    onConfirm = () => {},
    onCancel = () => {},
    cancel_text = `I'll do this later`,
    confirm_text = 'Next'
                                  }) {

    return <div className="flex items-center space-x-2">
        <div>
            <Button onClick={onConfirm} text={confirm_text} intent="secondary"/>
        </div>
        {cancel_text&&<div>
            <Button onClick={onCancel} text={cancel_text} size="sm" minimal/>
        </div>}
    </div>
}