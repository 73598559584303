import React, {useState} from "react";
import InputField from "../../library/components/old/input";
import {Button} from "../../library/components/core";
import styled from "styled-components";
import {authFetch} from "../../config/network";

const Wrapper = styled.div`
 padding: 12px;
`;

export function AddTaxonomyCategory({taxonomy_id}) {
    const [label, setLabel] = useState('');
    const [label_id, setLabelId] = useState('');
    const [loading, setLoading] = useState(false);

    function create() {
        setLoading(true);
        setLabel('');
        setLabelId('');
        const res = () => {
            setLoading(false);
        };
        const payload = {
            taxonomy_id, category_id: label_id, category_label: label
        };

        authFetch("/taxonomies/create-category", res, res, "POST", payload);
    }

    return <Wrapper>
        <div style={{marginBottom: '6px'}}>
<div style={{marginBottom: '6px'}}>New Category</div>
        <InputField type="text" placeholder='Label: Sports' value={label} onChange={(val)=>{setLabel(val);}} />
        </div>
        <div style={{marginBottom: '6px'}}>
            <InputField type="text" placeholder='ID: e.g. sports' value={label_id} onChange={(val)=>{setLabelId(val);}} />
        </div>
        <div>
            <Button disabled={label===''} intent='primary' onClick={()=>{create()}} loading={loading} text='Add Category' />
        </div>
    </Wrapper>
}