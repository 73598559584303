/*
Letters, numbers, and underscores only. It’s case-blind, so you can enter hi_there, Hi_There, or HI_THERE and they’ll all work the same (and be treated as a single account).
There is a no char requirement; Maximum length is 30 characters.
There is also no requirement that the name contain letters at all;
 */
export const HandleRegex = /^[A-Za-z0-9_-]*$/;
export const HandleRegex1 = /[^A-Za-z0-9_-]/gi;

const umlautMap = {
    '\u00dc': 'ue',
    '\u00c4': 'Ae',
    '\u00d6': 'oe',
    '\u0153': 'oe',
    '\u00fc': 'ue',
    '\u00e4': 'ae',
    '\u00f6': 'oe',
    '\u04e6': 'Oe',
    '\u04e7': 'oe',
    '\u0450': 'e',
    '\u00df': 'ss',
};

export function replaceUmlaute(str) {
    return str.replace(new RegExp('['+Object.keys(umlautMap).join('|')+']',"g"),
            (a) => umlautMap[a]
        );
}

export function sanitizeHandle(str) {
    // remove spaces
    // underscores and dashes are OK
    let str1 = replaceUmlaute(str).replace(/ /g, "-").replace(HandleRegex1, "");
    str1 = str1.trim();
    str1 = str1.replace(/ /g, '-');
    str1 = str1.toLowerCase();
    // block 4 and under for now special users
    return str1;
}