import React, {useContext, useState} from 'react';
import {StandardPageFramework, StandardPageHeight} from "../../../library/playground/standard-page-framework";
import {SimplePageGridDivider} from "../group/simple-page-divider";
import {Button, EntityHeader, Tabs} from "../../../library/components/core";
import {ProfileCard} from "../../../library/components/reorganize/profile-card";
import InterestGroups from "./widgets/interest-groups";
import {CommunityContext} from "../community-context";
import {useHistory} from "react-router-dom";
import {buildInterestProfileCard} from "./build-profile-card";
import {InterestInterested} from "./interested";
import {interested} from "../interests/shared";
import ProfileLoadingCard from "../../../library/components/layout/loading/profile";
import {GMMMembers} from "../group/members-modal/members";
import {GroupMembersModal} from "../group/members-modal";
import {ListMembersEntity} from "../reorganize/entities";

export function InterestPage({
                                 data = {},
                                 id = "",
                                 updateStats = () => {
                                 },
                                 stats,
                             }) {
    const history = useHistory();
    const community = useContext(CommunityContext);
    const [tab, setTab] = useState("Groups");
    const [modal, setModal] = useState(null);
    const [loading, setLoading] = useState(false);

    if (!data) {
        return <ProfileLoadingCard type="interest"/>;
    }

    const sidebar = <div
        className="sticky top-0 pr-3 pt-2 block overflow-y-auto  h-screen">

    </div>;

    let action_items = null;

    let actions = [];

    const features = {
        edit_profile_picture: false,
        edit_cover_photo: false,
        can_manage: false,
        openMembers: () => {
        }
    };
    let members_len = 0;

    if (stats) {
        const message = stats.count === 0 ? "No members interested" : `${stats.count} Interested`;
        members_len = stats.count === 0 ? 0 : stats.count;
    }

    if(members_len>0) {
        action_items = <div>
            <ListMembersEntity title="Members" type_name="Members"
                               openEntities={() => setModal(true)}
                               close={() => {
                                   // setModal(null)
                               }} data={{user_uids:stats.user_uids}}
                               can_manage={false}
                               removeEntity={() => {}}
                               block_removal={{}}
                               _removing_entity={false}
                               is_open={false} id={id}
                               community_uid={community.uid}/>
        </div>;
    }

    let new_card = buildInterestProfileCard(data, features, stats);

    let member_interests = community.member.interests ? community.member.interests : {};

    const is_interested = member_interests[id];
    if (is_interested) {
        new_card.actions.push(<InterestInterested cb={() => {
            let ns = {...stats};
            ns.count--;
            if (ns.user_uids[community.member_id]) {
                delete ns.user_uids[community.member_id];
            }
            updateStats(ns);
        }} id={id} community={community} community_uid={community.uid} can_leave={false}/>);
    } else {
        new_card.actions.push(<Button loading={loading}
                                      onClick={() => interested(id, loading, setLoading, community, () => {
                                          let ns = {...stats};
                                          ns.count++;
                                          ns.user_uids[community.member_id] = true
                                          updateStats(ns);
                                      })} text="I'm Interested"/>);
    }

    let member_tabs = [
        {
            label: `Members`,
            label_count: members_len>0?members_len:null,
            type: "custom",
            component: <GMMMembers can_manage={false} type="interest" id={id} data={data} />
        }
    ];


    return <StandardPageFramework sidebar={sidebar} layout="sidebar" content>

            <EntityHeader single onClick={() => {
                const el = document.getElementById("main-content");
                el.scrollTo(0, 0);
            }} title={data.name} actions={action_items}/>

            <ProfileCard context={community} editing={false} actions={actions} data={new_card} features={features}/>

            <div className="sticky px-2.5 pt-1.5 top-0 bg-white z-10">
                <Tabs onSelect={t => setTab(t)} active={tab} tabs={["Groups"]} compact layout="entity"/>
            </div>

            <div className="p-3">
                <InterestGroups community={community} id={id} handle={community.data.handle} history={history}
                                data={data}/>
            </div>

        {modal&&<GroupMembersModal title={`Interested in ${data.name}`} can_manage={false} tabs={member_tabs} onClose={()=>setModal(null)} start_tab={0} />}
    </StandardPageFramework>
}