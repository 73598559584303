import React, {useContext,useEffect,useState} from "react";
import {CommunityContext} from "../community-context";
import {reference_interests} from "../../data-references";
import {FirebaseDB} from "../../../config/setup-firestore";
import {InterestPage} from "./page";

async function getInterestStats(community_uid,id) {
    return await FirebaseDB
        .collection('communities')
        .doc(community_uid)
        .collection('stats')
        .doc('interests')
        .get()
        .then(doc => {
            const data = doc.data();

            if(data[id]) {
                return {
                    count: data[`${id}_count`],
                    user_uids: data[id]
                };
            } else {
                return {
                    count: 0,
                    user_uids: {}
                };
            }
        }, err => {
            console.log("ERROR", err)
        });
}

async function getInterestData(community_uid,id) {
    if(id.startsWith("default_")) {
        return reference_interests[id];
    }
    return await FirebaseDB
        .collection('communities')
        .doc(community_uid)
        .collection('interests')
        .doc(id)
        .get()
        .then(doc => {
            return doc.data();
        }, err => {
            console.log("ERROR", err)
        });
}

export default function CommunityInterestPage(props) {
    const community = useContext(CommunityContext);
    const [data,setData] = useState(null);
    const [stats,setStats] = useState(null);
    const id = props.match.params.interest;

    useEffect(function() {
        getInterestData(community.uid,id)
            .then(dt=>{
                setData(dt);
            })
        getInterestStats(community.uid,id)
            .then(st=>{
                setStats(st);
            })
    }, [id,community.uid]);

    return <InterestPage data={data} id={id} stats={stats} updateStats={(st)=>setStats({...st})} />
}