import React, {useContext, useState} from "react";
import {Avatar} from "../../../../../../library/components/core";
import {PhotoSelector} from "../../../../../../library/components/old/photo-selector";
import {GlobalContext} from "../../../../../global/global-context";
import {authFetch} from "../../../../../../config/network";

export function AdminPhotoSelector({member={},member_id="",community={},hc=()=>{}}) {
    const global = useContext(GlobalContext);
    const [loading,setLoading] = useState(null);
    function changeProfilePicture(url,color) {
        setLoading('profile-picture');
        hc('profile_picture_color', color);
        hc('profile_picture', url);
        const res = (resp) => {
            setTimeout(() => {
                setLoading('');
                global.addToast({text: `Profile Picture saved`, intent: 'success'});
            }, 1000);
        };

        const payload = {
            community_uid: community.uid,
            member_id: community.member_id,
            url,
            color,
            id: member_id,
            user_uid: community.member.user_uid,
        };

        authFetch("/members/set-profile-picture", res, res, "POST", {payload})
    }

    return <PhotoSelector type='profile-picture' setPhoto={(url, color) => changeProfilePicture(url,color)}>
        <Avatar show_edit url={member.profile_picture ? `${member.profile_picture}_medium?alt=media` : ''}
                text={member.name}
                size="xl"/>
    </PhotoSelector>
}