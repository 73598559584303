import React, {useState} from 'react';
import {table_layouts} from "./layout-list";
import styled from "styled-components";
import {DotsHorizontalIcon} from '@heroicons/react/solid';
import {Popover2} from "@blueprintjs/popover2";
import {ATPopoverAddItem, ATPopoverContents, ATPopoverTitle} from "../components/shared";
import {ATCreateView} from "./create-view";
import {ATEditView} from "./edit-view";
import {useIntl} from "react-intl";
import {InlineLoader} from "../../core";
import {SelectorIcon} from "@heroicons/react/outline";


export function ATActiveView({layout, loading, total, onClick, emoji=null, has_unsaved_changes, name, icon, type, show_select = true}) {

   const pass_icon = emoji?<ViewEmoji small className="flex items-center justify-center mr-0.5">{emoji}</ViewEmoji>:null;
    return <ATButtonBox style="default" meta={total?total:""} onClick={onClick} icon={pass_icon} right_icon={loading?<InlineLoader tiny padding="p-1"/>:<SelectorIcon />}>
        {name}
    </ATButtonBox>
}

const Views = styled.div`
   
`;

const ViewItem = styled.div`
   cursor: pointer;
       display: grid;
    grid-template-columns: 20px 1fr auto;
    grid-gap: 6px;
    background-color: ${props=>props.active?'#f3f3f3':''};
        padding: 6px 12px;
    > div {
        display: flex;
        align-items: center;
        height: 24px;
    }
    :hover .show-on-hover {
        opacity: 1;
    }
`;

const ViewLayout = styled.div`
   
   > svg {
    height: 22px;
   }
`;

const ViewName = styled.div`
   font-weight: 500;
   font-size: 15px;
   
`;

const ViewEmoji = styled.div`
    font-size: ${props=>props.small?"18px":"20px"};
  height: 20px;
  width: ${props=>props.small?"18px":"20px"};
`;

const ViewProperties = styled.div`
   opacity: 0;
   cursor: pointer;
   height: 24px;
   transition: 0.1ms opacity ease;
       display: flex;
    align-items: center;
   :hover {
    opacity: 0.7 !important;
   }
   > svg {
    height: 16px;
   }
`;

export function ATButtonBox({icon,style='default',onClick,right_icon,meta,children}) {
    const container_styles = style==='alt'?"border border-gray-100 text-gray-700 bg-gray-100 hover:border-gray-300 hover:bg-gray-200":'border border-gray-200 text-gray-800 hover:border-gray-300 shadow-sm bg-white hover:bg-gray-100';
    return <div className={`rounded-lg h-8 items-center transition-colors cursor-pointer pl-1.5 pr-1 inline-flex text-sm ${container_styles}`} onClick={onClick}>
        {icon&&<div className="h-svg-45 text-gray-600">{icon}</div>}
        <div className=" font-medium pr-1 pl-1 truncate">{children}</div>
        {meta&&<div className="text-gray-600 text-xs px-0.5 ml-1 mr-0.5  rounded bg-gray-200">{meta}</div>}
        {right_icon&&<div className="h-svg-4 pr-0.5 text-gray-500 pl-0.5">{right_icon}</div>}
    </div>
}

export function ATSelectView({config, total, loading, is_custom_view, selected_view_data, updateSmartView, deleteSmartView, view_data, addView, selectView, definitions, view, views = []}) {
    const [open_create, setCreateOpen] = useState(false);
    const [open_edit, setEditOpen] = useState(null);
    const {formatMessage: f} = useIntl();
    const [open_select, setSelectOpen] = useState(false);

    // {data.has_unsaved_changes?' *':''}{data.not_yet_saved?' *':''}
    const content = <ATPopoverContents>
        <ATPopoverTitle meta='Switch view'/>
        <Views>
            {views.map((viewid) => {
                // can only edit custom views
                const data = view_data[viewid];
                if(data.layout==='map'||data.layout==='table') {
                    return null;
                }
                const is_active = viewid===view.id;
                return <ViewItem active={is_active}  key={viewid} className='hover-bkg-1'>
                    <ViewLayout className='text-gray-900'>
                        {!data.emoji&&(data.icon?data.icon:table_layouts[data.layout].icon)}
                        {data.emoji&&<ViewEmoji className="flex items-center justify-center">{data.emoji}</ViewEmoji>}
                    </ViewLayout>
                    <ViewName className='text-gray-900' onClick={()=>{setSelectOpen(false);selectView(viewid)}} >
                        <span className='link-underline'>
                        {data.name}
                        </span>
                    </ViewName>
                    {data.type==='custom'&&<Popover2 content={<ATEditView definitions={definitions} updateView={(id,update)=>{
                        updateSmartView(id,data,update);
                        setSelectOpen(false);
                        setEditOpen(null);
                    }} deleteView={(id)=>{
                        deleteSmartView(id);
                    }} view={data} id={viewid} />} placement='right' interactionKind='click' minimal isOpen={open_edit===viewid}
                                                     popoverClassName='minimal-popover margin-left-6' targetClassName='popover-target-span'>
                        <ViewProperties onClick={(e)=>{e.stopPropagation();setEditOpen(viewid)}} className='text-gray-700 show-on-hover'>
                            <DotsHorizontalIcon/>
                        </ViewProperties>

                    </Popover2>}
                </ViewItem>})}
        </Views>
        {config.features.create_view&&<Popover2 content={<ATCreateView addView={(nv)=>{
            setCreateOpen(false);
            setSelectOpen(false);
            addView(nv);
        }} definitions={definitions} view={view} />} isOpen={open_create} onOpen={()=>setCreateOpen(true)} onClose={()=>setCreateOpen(false)} placement='right' interactionKind='click' minimal usePortal
                                                popoverClassName='minimal-popover margin-left-6' targetClassName='popover-target-span'>
            <ATPopoverAddItem onClick={()=>{setCreateOpen(true);setEditOpen(null);}} text='Add View'/>

        </Popover2>}
    </ATPopoverContents>;

    return <Popover2 content={content} isOpen={open_select} onClose={()=>{setSelectOpen(false);setEditOpen(null);}} placement='bottom-start' interactionKind='click' minimal
                    popoverClassName='minimal-popover my-2'>
        <ATActiveView loading={loading} total={total} has_unsaved_changes={view.has_unsaved_changes} onClick={()=>setSelectOpen(true)} {...view} />
    </Popover2>
}