import createLinkifyPlugin from "@draft-js-plugins/linkify";
import {Tooltip2} from "@blueprintjs/popover2";
import React from "react";

function ToolbarItem({text,first,last}) {
    const border = `${first?"rounded-l":""} ${last?"rounded-r":""}`;
    return <div className={`${border} text-white text-sm font-medium cursor-pointer px-3 py-1.5 bg-gray-800 hover:bg-gray-600`}>{text}</div>
}

function InlineToolbar({items=[]}) {
    return <div className="bg-gray-800 rounded m-1 overflox-x-hidden flex divide-x divide-gray-500">
        {items.map((it,k)=><ToolbarItem first={k===0} last={(k+1)===items.length} key={k} {...it} />)}
    </div>
}

/*
Link behaviors
- autodetect + create from text (done)
- select text that isn't a link and press cmd + k to open modal to add link to existing
- popover above the text with a tooltip to remove or edit link
 */

export const scPluginLinkify = (read_only) => createLinkifyPlugin({
    component(props) {

        if(read_only) {
            return <a rel="noreferrer noopener" target="_blank" href={props.href}>
                {props.children}
            </a>
        }

        const items = [
            {
                text: 'Edit link'
            },
            {
                text: 'Remove link'
            }
        ];

        return <Tooltip2 minimal interactionKind='click' placement='top-center' content={<InlineToolbar items={items} />}>
            <a rel="noreferrer noopener" onClick={()=>{}}>
                {props.children}
            </a>
        </Tooltip2>
    }
});