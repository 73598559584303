import React, {useEffect, useState} from 'react';
import {ComposerSendTestEmail} from "../send-test-button";
import {ActionWithIcon, Avatar, InlineLoader} from "../../core";
import {Entity} from 'draft-js';
import {MailOpenIcon} from "@heroicons/react/outline";
import moment from "moment";
import {SimpleDraftJsViewer} from "../../../../app/community/posts/simple-draft-js-viewer";
import {CommunityPostPreview} from "../../../../app/community/posts";
import {useIsMobile} from "../../../../app/global/global-router";
import {message_getTypeContent} from "./write";
import {EntityInfo} from "../../../utilities/localstorage/entity-localstorage";

function MetaItem({label, value}) {

    return <div className="flex text-xssm space-x-2">
        <div className="w-16 flex-none text-gray-500">{label}</div>
        <div className="pl-0.5 flex-grow text-gray-600 font-medium">{value}</div>
    </div>
}

function getTo(message_type, to) {
    if (message_type !== 'group') {
        return to && to[0] ? to[0].email : "";
    }
    return to.map(t => t.email).join(", ")
}

function SeriesName({data}) {
    return <span>
        {data.name}
    </span>
}

function DefaultEmailHeader({community}) {
    return <div className="flex px-5 py-2.5">
        <div className="flex-none h-10">
        <Avatar type="community" text={community.data.name}
                url={`${community.data.profile_picture}_small?alt=media`}/>
    </div>
        <div className="flex-grow"></div>
        <div className="flex-none h-10 flex items-end flex-col justify-center">
            <div className="text-xs font-bold text-gray-800">{community.data.name}</div>
            <div className="text-xs text-gray-500">{moment().format("MMM D")}</div>
        </div>
    </div>;
}

function CustomEmailHeader({image}) {
    let style = {
        aspectRatio:'556 / 110',
        backgroundPosition: 'center',
        backgroundSize: 'cover'
    };
    if(image) {
        style.backgroundImage = `url('${image}_medium?alt=media')`;
    }
    return <div className="flex px-5 py-2.5">
     <div className="w-full bg-gray-100" style={style}>

     </div>
    </div>;
}

function EmailPreview({
                          community,
                          series,
                          community_uid,
                          message_type,
                          cc,
                          to,
                          extra,
                          settings,
                          subject,
                          content_state
                      }) {

    return <div>
        <div className="px-3 py-3 border-b border-gray-100 space-y-2">
            <MetaItem label="From:" value={`${settings.from_name} <${community.member.account_email}>`}/>
            <MetaItem label="To:" value={getTo(message_type, to)}/>
            {cc.length > 0 && <MetaItem label="Cc:" value={cc.map(t => t.email).join(", ")}/>}
            <MetaItem label="Subject:" value={subject}/>
            {settings.reply_to&&<MetaItem label="Reply-to:" value={settings.reply_to}/>}
        </div>

            {settings.header_style==='default'?<DefaultEmailHeader community={community} />:<CustomEmailHeader image={settings.header_custom} />}

        <div className="px-5 py-1">
            <div className="text-2xl font-bold text-gray-800">{subject ? subject :
                <span className="italic text-gray-500">Untitled</span>}</div>
            {series && <div className="text-xs text-gray-600">
                <EntityInfo community_uid={community_uid} type={"series"} id={series}><SeriesName/></EntityInfo>
            </div>}
            <div className="pt-2 pb-3">
                <SimpleDraftJsViewer content_state={content_state}/>
            </div>
            {extra && <div className="pb-3 pt-1">
                {extra}
            </div>}
        </div>
        <div className="bg-gray-100 rounded-b-lg px-5 py-3 text-xs text-gray-600 text-center space-y-1">
            <div>Sent by {community.member.name}</div>
            <div>{community.data.name}</div>
            <div>
                <a className="text-gray-600 underline">Sign in</a>
            </div>
            <div>
                <a className="text-gray-600 underline">Unsubscribe</a>
            </div>
        </div>
    </div>
}

function Section({
                     title, icon, action, children, subject
                 }) {

    return <div>
        <div className="flex px-3 py-1.5 rounded-t-lg bg-gray-200 border-gray-200">
            <div className="flex items-center flex-none">
                <div className="text-gray-600 text-xs uppercase font-semibold tracking-wide">{title}</div>
            </div>
            <div className="flex-grow"/>
            <div className="flex-none flex items-center">
                {action}
            </div>
        </div>
        <div className="border-l border-r border-b rounded-b-lg border-gray-200" style={{minHeight: '4rem'}}>
            {children}
        </div>
    </div>
}


export function ComposerPreviewTab({
                                       to,
                                       cc,
                                       type,
                                       series,
                                       message_type,
                                       is_mobile,
                                       merge_tags,
                                       settings,
                                       community,
                                       send_email,
                                       editorState,
                                       subject
                                   }) {
    const [reload, setReload] = useState(false);
    const [content_state, setContentState] = useState(editorState.getCurrentContent());

    useEffect(function () {
        setContentState(editorState.getCurrentContent());
        setReload(true);
        setTimeout(function () {
            setReload(false);
        }, 100)
    }, [editorState])

    const merge_tag_data = {
        community: community.data,
        member: community.member
    };


    return <div className="space-y-4">
        {send_email && <Section title="Email" icon={<MailOpenIcon/>}
                                action={<ComposerSendTestEmail subject={subject} editorState={editorState}/>}>
            <EmailPreview series={series} community_uid={community.uid} settings={settings} cc={cc} to={to}
                          extra={message_getTypeContent(type, {community})} subject={subject} community={community}
                          content_state={content_state}/>

        </Section>}
        {message_type === 'group' && <Section title="App">
            <div className="px-5 py-2" id="message-content">
                <CommunityPostPreview data={{
                    subject,
                    community_uid: community.uid,
                    series,
                    author_name: community.member.name,
                    author_image: `${community.member.profile_picture}`,
                    content_state: content_state,
                    created_at: Date.now()
                }} is_mobile={is_mobile}/>
            </div>
        </Section>}
    </div>
}