import React, {useState} from "react";
import LandingMenu from "./menu";
import LandingFooter from "./footer";
import styled from "styled-components";

const Frame = styled.div`
    height: 100vh;
    overflow-y: auto;
`;

export default function LandingFrame(props) {

    return (
        <Frame>
            <LandingMenu/>
            {props.children}
            <LandingFooter/>
        </Frame>
    );
}