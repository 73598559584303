import {activityGetMeta} from "../activity-get-meta";
import React from "react";
import {ActivityItemCreatedGroup} from "../activity/created-group";
import {ActivityItemMemberChanges} from "../activity/member-changes";
import {
    MATassignedToRole,
    MATbirthday,
    MATdeactivated,
    MATfirstLogin, MATjoinedGroup, MATsentInvite, MATunassignedFromRole,
    MATupdatedCity,
    MATupdatedCountry,
    MATupdatedEmail,
    MATupdatedMemberType,
    MATupdatedPhone,
    MATupdatedPicture
} from "../activity/member-activity-updates";

export function ActivityPostItem(props) {
const {activity_type,read,history,metadata,activity_meta} = props;
let content = null;
    if(activity_type==='created_group') {
        content = <ActivityItemCreatedGroup {...activity_meta} metadata={metadata} />
    } else if(activity_type==='member_changes') {
        content = <ActivityItemMemberChanges {...props} metadata={metadata} />
    } else if(activity_type==='updated_phone') {
        content = <MATupdatedPhone {...props} metadata={metadata} />
    } else if(activity_type==='updated_email') {
        content = <MATupdatedEmail {...props} metadata={metadata} />
    } else if(activity_type==='updated_picture') {
        content = <MATupdatedPicture {...props} metadata={metadata} />
    } else if(activity_type==='updated_member_type') {
        content = <MATupdatedMemberType {...props} metadata={metadata} />
    } else if(activity_type==='updated_country') {
        content = <MATupdatedCountry {...props} metadata={metadata} />
    } else if(activity_type==='updated_city') {
        content = <MATupdatedCity {...props} metadata={metadata} />
    } else if(activity_type==='first_login') {
        content = <MATfirstLogin {...props} metadata={metadata} />
    } else if(activity_type==='deactivated') {
        content = <MATdeactivated {...props} metadata={metadata} />
    } else if(activity_type==='reactivated') {
        content = <MATdeactivated {...props} metadata={metadata} />
    } else if(activity_type==='sent_invite') {
        content = <MATsentInvite {...props} metadata={metadata} />
    } else if(activity_type==='birthday') {
        content = <MATbirthday {...props} metadata={metadata} />
    } else if(activity_type==='joined_group') {
        content = <MATjoinedGroup {...props} metadata={metadata} />
    } else if(activity_type==='assigned_to_role') {
        content = <MATassignedToRole {...props} metadata={metadata} />
    } else if(activity_type==='unassigned_from_role') {
        content = <MATunassignedFromRole {...props} metadata={metadata} />
    }

    return <div className="">
        {content}
    </div>
}