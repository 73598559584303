import React, {useState} from 'react';
import styled from "styled-components";
import {PCProfilePicture} from "./profile-picture";
import {PhotoPortal} from "../../../../app/community/home/PhotoPortal";
import {getBkgColor, getLargeImage} from "../../old/profile-frame/profile/profile-picture";
import {PhotoSelector} from "../../old/photo-selector";
import {Button, InlineLoader} from "../../core";
import {buildBkgPattern} from "../../background-pattern-builder";

const Header = styled.div`
  height: ${props=>props.small?"140px":"200px"};
    position: relative;
    :hover .show-on-hover {
    opacity: 1;
    }
`;


const Background = styled.div`
    height: ${props=>props.small?"140px":"200px"};
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    position: relative;
`;

const Image = styled.div`
    height: 100%;
    position: relative;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
`;

const Loader = styled.div`
position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.3);
    display: flex;
    align-items: center;
    justify-content: center;
`;

export function getAdvancedImageBkg(url,size='') {
    if (url.startsWith('pattern_')) {
        const sp = url.split("_");
        return `url("${buildBkgPattern(sp[1],sp[2])}")`;
    } else if (url.startsWith('https://')) {
        return `url('${url}${size?`_${size}`:""}?alt=media')`;
    } else {
        return `${url}`;
    }
}

export function getAdvancedImageBackgroundStyle(type,url,color,bg_size='auto',img_size='') {
    let style = {};
    if(url.indexOf('https://')!==-1) {
        style.backgroundColor = color?color:"#efefef";
        style.backgroundImage = getAdvancedImageBkg(url,img_size);
            style.backgroundPosition = "center";
        style.backgroundSize = 'cover';
    } else if(url.startsWith('pattern_')) {
        style.backgroundColor = color?color:"#efefef";
        style.backgroundImage = getAdvancedImageBkg(url,img_size);
        style.backgroundPosition = "center";
        style.backgroundSize = bg_size;
    } else if(url.indexOf('-gradient')!==-1) {
        style.background = `${url}`;
    } else {
        style.backgroundColor = type==='community'?"":"#e3e8ed";
    }

    return style;
}

export function PCCover({data, context, setCoverPhoto, setProfilePicture, features}) {
    const [open, setOpen] = useState(false);
    const [change_open, setChangeOpen] = useState(false);
    const {cover_photo, cover_photo_color} = data.appearance;

    let loading_pp = context.loading_pp ? context.loading_pp : false;
    let loading_cp = context.loading_cp ? context.loading_cp : false;
    let style = getAdvancedImageBackgroundStyle(data.type,cover_photo,cover_photo_color);

    let onClick = () => {};

    // don't allow the cover photo to be expanded if gradient
    if (cover_photo !== '' && cover_photo.indexOf('https://')!==-1) {
        onClick = () => {
            setOpen(true);
        };
    }

    const width = window.outerWidth;

    let cp = <Background small={width<640} className="w-auto group ">
        <Image className={` mobile-cover-photo background-image-cover-responsive w-full ${cover_photo===''&&data.type==='community'?"community-cover-photo-bkg":""}`} style={style} onClick={onClick} />

        {features.edit_cover_photo&& <div className="absolute bottom-3 right-3">
            <PhotoSelector placement="bottom-end" onOpen={(new_open)=>setChangeOpen(new_open)} modes={[{id:'gallery',label:'Gallery'},{id:'pattern',label:'Pattern'},{label:'Custom',id:'custom'}]} type='cover-photo' setPhoto={setCoverPhoto}>
                <div className={`transition-opacity opacity-0 group-hover:opacity-100`}>
                    <Button active minimal size='sm' text={!cover_photo?`Add Cover`:`Change`} />
                </div>
            </PhotoSelector>
        </div>}
    </Background>;

    return <Header small={width<640}>
       {cp}
        {data.appearance.profile_picture &&
        <PCProfilePicture loading={loading_pp} data={data} setProfilePicture={setProfilePicture}
                          can_manage={features.edit_profile_picture}/>}
        {loading_cp && <Loader className="rounded-t-md -mx-2">
            <InlineLoader />
        </Loader>}
        {open &&
        <PhotoPortal aspect_ratio={(3.03 / 1)} raw_color={cover_photo_color} bkg_color={getBkgColor(cover_photo_color)} images={getLargeImage(cover_photo)} onClose={(e) => {
            setOpen(false)
        }}/>}
    </Header>
}