import {Button} from "../core";
import {PlayIcon} from "@heroicons/react/solid";
import React from "react";

function RichBlock({type,data}) {
    switch (type) {
        case 'link':
            return <div className="flex max-w-sm rounded-md border space-x-3 border-gray-200 bg-white overflow-hidden">
                <div className="h-24 w-24 bg-center flex-none bg-cover rounded-l-md" style={{backgroundImage:`url(https://pbs.twimg.com/card_img/1463527002040901635/v3FYYuBs?format=jpg&name=small)`}}></div>
                <div className="flex-grow flex flex-col justify-center space-y-0.5 pr-2.5">
                    <div className="text-xs text-gray-500">singularityhub.com</div>
                    <div className="text-sm text-gray-800 font-semibold truncate">Rolls-Royce Says Its Electric Plane Just Smashed the World Record for Speed</div>
                    <div className="text-xs text-gray-500">The plane reached a maximum speed of 387.4 mph (623 km/h), which Rolls-Royce says..</div>
                </div>
            </div>
        case 'event':
            return <div className="flex max-w-sm rounded-md border px-3 py-3 w-full border-gray-200 bg-white overflow-hidden">
                <div className="flex-grow flex flex-col justify-center">
                    <div className="text-xs text-primary-600">Wed, October 2nd at 7:15 pm</div>
                    <div className="text-base text-gray-800 font-semibold truncate">Community Dinner</div>
                    <div className="text-xs flex space-x-1 text-gray-500 mt-1.5">
                        <div className="flex">
                            <div className="rounded-full bg-center bg-cover h-4 z-3 relative w-4 border-2 border-white" style={{
                                backgroundImage: `url(https://firebasestorage.googleapis.com/v0/b/unaty-prod.appspot.com/o/member_card_backgrounds%2FUGG9YHJJT2?alt=media)`
                            }}></div>
                            <div className="rounded-full -ml-1.5 bg-center bg-cover h-4 z-2 relative w-4 border-2 border-white" style={{
                                backgroundImage: `url(https://firebasestorage.googleapis.com/v0/b/unaty-prod.appspot.com/o/member_card_backgrounds%2FUGG9YHJJT2?alt=media)`
                            }}></div>
                        </div>
                        <div>
                            5 joining, including <b>AH Ahrens</b>
                        </div>
                    </div>
                    <div className="mt-2.5">
                        <Button text="I'm Going" intent="secondary" />
                    </div>
                </div>
            </div>
        case 'youtube':
            return <div className="w-32 h-20 relative rounded-md bg-center bg-cover flex items-center justify-center" style={{backgroundImage:`url(https://firebasestorage.googleapis.com/v0/b/unaty-prod.appspot.com/o/member_card_backgrounds%2FUGG9YHJJT2?alt=media)`}}>
                <div className="absolute rounded-md z-3 top-0 right-0 bottom-0 left-0 bg-opacity-25 bg-black" />
                <div className="absolute z-3 top-0 right-0 bottom-0 left-0 flex items-center justify-center">
                    <div className="h-svg-8 z-5 color-white">
                        <PlayIcon />
                    </div>
                </div>
            </div>
        case 'data-check':
            return <div className="flex max-w-sm rounded-md border px-3 py-3 w-full border-gray-200 bg-white overflow-hidden">
                <div className="flex-grow flex flex-col justify-center">
                    <div className="text-xs text-secondary">Data Check</div>
                    <div className="text-base text-gray-800 font-semibold">Check your Profile Information</div>
                    <div className="mt-2">
                        <Button text="Get Started" intent="success" />
                    </div>
                </div>
            </div>
        case 'onboarding':
            return <div className="flex max-w-sm rounded-md border px-3 py-3 w-full border-gray-200 bg-white overflow-hidden">
                <div className="flex-grow flex flex-col justify-center">
                    <div className="text-xs text-secondary">Onboarding</div>
                    <div className="text-base text-gray-800 font-semibold">Welcome to the Community</div>
                    <div className="mt-2">
                        <Button text="Get Started" intent="success" />
                    </div>
                </div>
            </div>
        default :
            return <div className="rounded-md bg-gray-400 text-sm p-4 max-w-sm">
                {type}
            </div>

    }
    return <div className="rounded-md bg-gray-400 text-sm p-4 max-w-sm">
        {type}
    </div>
}

export function FeedItemRichBlocks({
                        rich_blocks = []
                    }) {
    if (rich_blocks.length === 0) {
        return null;
    }
    return <div className="mt-1.5 flex space-x-2">
        {rich_blocks.map((rb,k)=>{
            return <RichBlock key={k} {...rb} />
        })}
    </div>
}