import React from 'react';
import {EntityWrapper} from "../entity/entity-wrapper";
import Splash from "../landing/pages/splash";
import SignUp from "../auth/sign-up";
import LinkPage from "../auth/link";
import GodPanelPage from "../../superadmin";
import SignIn from "../auth/sign-in";
import Communities from "./memberships";
import SignOut from "../auth/signout/SignOut";
import {PolicyRouter} from "../landing/pages/policies/router";
import MagicLinkPage from "../auth/magic-link";
import ForgotPassword from "../auth/forgot-password";
import VerifyAccount from "../auth/verify/VerifyAccount";
import {Playground} from "../../library/playground";
import {StyleGuideHome} from "../../library/styleguide";
import CreateCommunity from "./create-community";
import {TaxonomiesPage} from "../../superadmin/taxonomies";
import Preferences from "./preferences";
import {ExternalEntity} from "../entity/external-entity";
import CommunitySignIn from "../auth/sign-in/community";
import ContextualRedirect from "../utilities/contextual-redirect";
import VerifySecondaryEmail from "../auth/verify-secondary-email";
import {QuickProfileUpdate} from "../quick-profile-update";
import {QPUConfirm} from "../quick-profile-update/confirm";
import {ExplorePage} from "./explore";
import {GlobalSearchPage} from "./search";
import {JoinCommunityPage} from "../entity/join";
import {SecretSignIn} from "../auth/secret-sign-in";
import {SignInWithGoogle} from "../auth/sign-in-with-google";

export const global_mobile_routes = {
  logged_in: [],
  logged_out: []
};
export const global_routes = {
    logged_in: [
        {
            type: 'route',
            key: 'qpu',
            layout: 'none',
            path: '/qpu/:qpu_token',
            exact: true,
            component: QuickProfileUpdate
        },
        {
            type: 'route',
            key: 'search',
            layout: 'app',
            path: '/search',
            exact: true,
            component: GlobalSearchPage
        },
        {
            type: 'route',
            key: 'explore-page',
            layout: 'app',
            path: '/explore',
            exact: false,
            component: ExplorePage
        },
        {
            type: 'route',
            key: 'qpu-confirm',
            layout: 'none',
            path: '/qpu-confirm/:qpu_token',
            exact: true,
            component: QPUConfirm
        },
        {
            type: 'route',
            key: 'memberships-route',
            layout: 'app',
            path: '/memberships',
            exact: true,
            component: Communities
        },
        {
            type: 'route',
            key: 'create-community-route',
            layout: 'app',
            path: '/create-community',
            exact: true,
            component: CreateCommunity
        },
        {
            type: 'route',
            key: 'policies-route',
            layout: 'landing',
            path: '/policies',
            component: PolicyRouter
        },
        {
            type: 'route',
            key: 'prefs-route',
            layout: 'app',
            path: '/preferences',
            component: Preferences
        },
        {
            type: 'route',
            key: 'sign-out-route',
            layout: 'none',
            path: '/sign-out',
            exact: true,
            component: SignOut
        },
        {
            type: 'route',
            key: 'sign-out-community-route',
            layout: 'none',
            path: '/:handle/sign-out',
            exact: true,
            component: SignOut
        },
        {
            type: 'route',
            key: 'vse-page',
            layout: 'landing',
            exact: true,
            path: '/verify-secondary-email',
            component: VerifySecondaryEmail
        },
        {
            type: 'redirect',
            key: 'sign-in-redirect',
            layout: 'landing',
            path: '/sign-in',
            from: '/sign-in',
            to: '/memberships'
        },
        {
            type: 'redirect',
            key: 'communities-redirect',
            layout: 'none',
            path: '/communities',
            from: '/communities',
            to: '/memberships'
        },
        {
            type: 'redirect',
            key: 'home-redirect',
            layout: 'app',
            path: '/home',
            from: '/home',
            to: '/memberships'
        },
        {
            type: 'redirect',
            key: 'sign-up-redirect',
            layout: 'landing',
            path: '/sign-up',
            from: '/sign-up',
            to: '/memberships'
        },
        {
            type: 'redirect',
            key: 'rp-redirect',
            from: '/reset-password',
            to: '/memberships'
        },
        {
            type: 'redirect',
            key: 'fp-redirect',
            from: '/forgot-password',
            to: '/memberships'
        },
        {
            type: 'route',
            key: 'taxonomies-page',
            layout: 'none',
            exact: true,
            path: '/taxonomies',
            component: TaxonomiesPage
        },
        {
            type: 'route',
            key: 'god-panel-page',
            layout: 'none',
            exact: true,
            path: '/superadmin',
            component: GodPanelPage
        },
        {
            type: 'route',
            key: 'styleguide-page',
            layout: 'none',
            exact: true,
            path: '/styleguide',
            component: StyleGuideHome
        },
        {
            type: 'route',
            key: 'playground-page',
            layout: 'none',
            exact: true,
            path: '/playground',
            component: Playground
        },
        {
            type: 'route',
            key: 'link-page',
            layout: 'landing',
            exact: true,
            path: '/link',
            component: LinkPage
        },
        {
            type: 'route',
            key: 'handle-route',
            layout: 'app',
            exact: true,
            path: '/:handle',
            component: ExternalEntity
        },
        {
            type: 'route',
            key: 'handle-route',
            layout: 'handle',
            path: '/:handle/*',
            component: EntityWrapper
        },
        {
            type: 'redirect',
            key: 'communities-redirect-memberships',
            path: '/communities',
            layout: 'landing',
            from: '/communities',
            to: '/memberships'
        },
        {
            type: 'route',
            key: 'commmunity-sign-in-page',
            layout: 'none',
            exact: true,
            path: '/:handle/sign-in',
            component: CommunitySignIn
        },
        {
            type: 'route',
            key: 'commmunity-sign-in-page',
            layout: 'none',
            exact: true,
            path: '/:handle/sign-up',
            component: CommunitySignIn
        },
        {
            type: 'redirect',
            key: 'msi-redirect',
            layout: 'none',
            exact: true,
            path: '/magic-sign-in',
            from: '/magic-sign-in',
            to: '/memberships'
        },
        {
            type: 'redirect',
            key: 'memberships-redirect',
            layout: 'landing',
            path: '*',
            from: '*',
            to: '/memberships'
        },
    ],
    logged_out: [
        {
            type: 'route',
            key: 'qpu',
            layout: 'none',
            path: '/qpu/:qpu_token',
            exact: true,
            component: QuickProfileUpdate
        },
        {
            type: 'route',
            key: 'search',
            layout: 'app',
            path: '/search',
            exact: true,
            component: GlobalSearchPage
        },
        {
            type: 'route',
            key: 'qpu-confirm',
            layout: 'none',
            path: '/qpu-confirm/:qpu_token',
            exact: true,
            component: QPUConfirm
        },
        {
            type: 'route',
            key: 'home-page',
            layout: 'landing',
            exact: true,
            path: '/',
            component: Splash
        },
        {
            type: 'redirect',
            key: 'create-community-route',
            path: '/create-community',
            layout: 'landing',
            from: '/create-community',
            to: '/',
            exact: true
        },
        {
            type: 'route',
            key: 'policies-route',
            layout: 'landing',
            path: '/policies',
            component: PolicyRouter
        },
        {
            type: 'route',
            key: 'sign-up-page',
            layout: 'landing',
            exact: true,
            path: '/sign-up',
            component: SignUp
        },
        {
            type: 'route',
            key: 'sign-in-page',
            layout: 'landing',
            exact: true,
            path: '/sign-in',
            component: SignIn
        },
        {
            type: 'route',
            key: 'sign-in-with-google',
            layout: 'landing',
            exact: true,
            path: '/sign-in-with-google',
            component: SignInWithGoogle
        },
        {
            type: 'route',
            key: 'token-sign-in-page',
            layout: 'landing',
            exact: true,
            path: '/_secret-sign-in',
            component: SecretSignIn
        },
        {
            type: 'route',
            key: 'commmunity-sign-in-page',
            layout: 'none',
            exact: true,
            path: '/:handle/sign-in',
            component: CommunitySignIn
        },
        {
            type: 'route',
            key: 'commmunity-sign-up-page',
            layout: 'none',
            exact: true,
            path: '/:handle/sign-up',
            component: CommunitySignIn
        },
        {
            type: 'route',
            key: 'commmunity-join-page',
            layout: 'landing',
            exact: true,
            path: '/:handle/join',
            component: JoinCommunityPage
        },
        {
            type: 'redirect',
            key: 'godpanel-redirect',
            path: '/superadmin',
            layout: 'landing',
            from: '/superadmin',
            to: '/sign-in'
        },
        {
            type: 'route',
            key: 'explore-page',
            layout: 'app',
            path: '/explore',
            exact: false,
            component: ExplorePage
        },
        {
            type: 'redirect',
            key: 'memberships-redirect',
            path: '/memberships',
            layout: 'landing',
            from: '/memberships',
            to: '/sign-in'
        },
        {
            type: 'redirect',
            key: 'communities-redirect',
            path: '/communities',
            layout: 'landing',
            from: '/communities',
            to: '/sign-in'
        },
        {
            type: 'redirect',
            key: 'styleguide-redirect',
            path: '/styleguide',
            layout: 'landing',
            from: '/styleguide',
            to: '/sign-in'
        },
        {
            type: 'redirect',
            key: 'playground-redirect',
            path: '/playground',
            layout: 'landing',
            from: '/playground',
            to: '/sign-in'
        },
        {
            type: 'redirect',
            key: 'taxonomies-redirect',
            path: '/taxonomies',
            layout: 'landing',
            from: '/taxonomies',
            to: '/sign-in'
        },
        {
            type: 'route',
            key: 'fp-page',
            layout: 'landing',
            exact: true,
            path: '/forgot-password',
            component: ForgotPassword
        },
        {
            type: 'route',
            key: 'va-page',
            layout: 'landing',
            exact: true,
            path: '/verify-account/:token',
            component: VerifyAccount
        },
        {
            type: 'route',
            key: 'vse-page',
            layout: 'landing',
            exact: true,
            path: '/verify-secondary-email',
            component: VerifySecondaryEmail
        },
        {
            type: 'route',
            key: 'link-page',
            layout: 'landing',
            exact: true,
            path: '/link',
            component: LinkPage
        },
        {
            type: 'route',
            key: 'msi-page',
            layout: 'landing',
            exact: true,
            path: '/magic-sign-in',
            component: MagicLinkPage
        },
        {
            type: 'route',
            key: 'community-login-redirect',
            layout: 'landing',
            path: '/:handle/*',
            component: ContextualRedirect
        },
        {
            type: 'route',
            key: 'handle-route',
            layout: 'app',
            exact: true,
            path: '/:handle',
            component: ExternalEntity
        },
        {
            type: 'route',
            key: 'sign-in-redirect',
            layout: 'landing',
            path: '*',
            component: ContextualRedirect
        }
    ]
};