import React from 'react';
import styled from "styled-components";
import {ChevronRightIcon,ChevronLeftIcon} from '@heroicons/react/solid';
const Footer = styled.div`
   display: grid;
   grid-template-columns: 1fr auto;
   padding-top: 6px;
`;

const Left = styled.div`
    
`;

const Right = styled.div`
    display: flex;
`;

const Paginate = styled.div`
    display: flex;
`;

const Pages = styled.div`
    display: flex;
    line-height: 28px;
    font-size: 12px;
    font-weight: 500;
    color: #acb3bc;
`;

const Action = styled.div`
height: 28px;
    width: 28px;
    margin-left: 8px;
    display: flex;
    align-items: center;
        border-radius: 6px;
    cursor: pointer;
    justify-content: center;
> svg {
    height: 24px;
    color: #87929e;
}
:hover {
background-color: #efefef;
}
`;

const disabled_item = {opacity: 0.7, pointerEvents: 'none'};

export function TableFooter({pagination}) {
    const {back,next,allow_next,root,total,root_to,allow_back} = pagination;

    if(total===0) {
        return null;
    }
    /*
     <Pages>
                {`${root} - ${root_to}`}
                {!total?'':` of ${total}`}
            </Pages>
     */
    return <Footer>
        <Left>

        </Left>
        <Right>

          <Paginate>

              <Action onClick={back} style={allow_back?{}:disabled_item}>
                  <ChevronLeftIcon/>
              </Action>
              <Action onClick={next} style={allow_next?{}:disabled_item}>
                  <ChevronRightIcon/>
              </Action>
          </Paginate>
        </Right>
    </Footer>
}