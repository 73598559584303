import React, {Component} from "react";
import {Link} from "react-router-dom";
import {cleanEmail, getCurrentLang, getCurrentTimezone, getUrlVars} from '../../../library/utilities/general'
import {validateEmail} from '../../../library/utilities/validation'
import firebase from 'firebase/app';
import 'firebase/auth';
import {authFetch} from "../../../config/network";
import {Button, TextArea} from "../../../library/components/core";
import Form from "../../../library/components/old/form";
import Field from "../../../library/components/old/field";
import ButtonSection from "../../../library/components/old/button-section";
import CustomCheckbox from "../../../library/components/old/checkbox";
import {PasswordField} from "../../../library/components/old/password-field";
import HandleSelector from "../../../library/components/old/handle-selector";
import {getNameMeta} from "../../../library/components/reorganize/advanced-modal/block-renderer";
import {FirebaseDB} from "../../../config/setup-firestore";
import {checkPasswordStrength} from "../sign-in/set-your-password";
import {applyToJoinCommunity} from "../../../library/components/join-community/apply-to-join";
import {TempTip} from "../../../library/playground/temp-tip";

function getCopy(is_applying, is_community, is_group, community, group) {
    return {
        button_text: is_applying ? "Create Account & Apply to Join" : "Create Account",
        message: !is_community || !community ? "" : is_group && group ? `Why do you want to join ${group.name}?` : `Why do you want to join ${community.name}?`
    }
}

export function buildAuthUrlSuffix(email,group_data) {
    let e = "";
    if(email&&validateEmail(email)) {
        e = `email=${email}`
    }
    let g = "";
    if(group_data&&group_data.id) {
        g = `group=${group_data.id}`;
    }
    if(!e&&!g) {
        return ""
    }
    return `?${e}${e&&g?"&":""}${g}`;
}

export default class SignUpBlock extends Component {
    constructor(props) {
        super(props);

        this.state = {
            email: '',
            password: '',
            handle: '',
            name: '',
            checked: false,

            message: "",

            _customizing: false,

            handle_name: '',

            checking_email: false,
            email_is_valid: true,
            checked_email: false,
            email_is_taken: false,

            loading: false,
            errorCode: '',
            errorMessage: null
        };

    }

    componentDidMount() {
        let email = getUrlVars()['email'];

        if (typeof email === 'string') {
            this.setState({
                email: email
            });
        }
    }

    hC(f, v) {
        this.setState({
            [f]: v
        })
    }

    disableBtn(score) {
        let {email, password, name, checked} = this.state;
        return (!validateEmail(email) || password.length < 8 || name.length < 4 || !checked || score < 2)
    }

    async userExistsWithEmail(email) {
        return await FirebaseDB
            .collection('users')
            .where('account_email', '==', email.toLowerCase())
            .limit(1)
            .get()
            .then(snap => {
                return !snap.empty;
            });
    }

    async userExistsWithSecondaryEmail(email) {
        return await FirebaseDB
            .collection('users')
            .where('secondary_emails', 'array-contains', email.toLowerCase())
            .limit(1)
            .get()
            .then(snap => {
                return !snap.empty;
            });
    }

    async emailIsTaken(email) {
        let p = [];

        p.push(this.userExistsWithEmail(email.toLowerCase()));
        p.push(this.userExistsWithSecondaryEmail(email.toLowerCase()));

        return await Promise.all(p).then((r) => {
            for (let i = 0; i < r.length; i++) {
                if (r[i]) {
                    return true;
                }
            }
            return false;
        })
    }

    submit(disabled, e) {
        if (e) {
            e.preventDefault();
        }
        const {email, password, name} = this.state;
        const email_clean = cleanEmail(email);
        // validate inputs
        const handle = this.state.handle;

        if (disabled) {
            return;
        }

        if (!validateEmail(email_clean) || password.length < 8) {
            return;
        }

        this.setState({
            loading: true
        });

        const {history} = this.props;

        // check to make sure email is not used
        this.emailIsTaken(email_clean).then(r => {

            if (!r) {
                this.signUpUser(email_clean, password, name, handle, history);
            } else {
                this.setState({
                    email_is_taken: true,
                    checked_email: true,
                    errorMessage: 'Email already taken',
                    errorCode: 'email-taken',
                    loading: false
                });
            }
        })
    }

    signUpUser(email_clean, password, name, handle, history) {
        firebase.auth().createUserWithEmailAndPassword(email_clean.toLowerCase(), password).then(
            result => {
                // todo call api to notify on new user creation
                return this.updateToken(result.user, handle, name);
            }).catch((error) => {
            // Handle Errors here.
            return this.setState({
                errorMessage: 'Something went wrong',
                errorCode: 'unknown',
                loading: false
            });
        });
    }

    updateToken(user, handle, name) {
        firebase
            .auth()
            .currentUser
            .getIdToken(true)
            .then((token) => {
                document.cookie = '__session=' + token + ';max-age=3600';
                return this.createUserObject(this.props.history, user, handle, name)
            });
    }

    createUserObject(history, user, handle, name) {
        const name_split = name.split(' ');

        const newUserData = {
            payload: {
                uid: user.uid,
                email: user.email,
                handle: handle,
                first_name: name_split[0],
                last_name: name_split[1] ? name_split[1] : ''
            },
            options: {
                language: getCurrentLang(),
                timezone: getCurrentTimezone()
            }
        };

        authFetch("/users/create-account", this.handleResult.bind(this, user), this.handleError.bind(this), "POST", newUserData)
    }

    handleResult(user,result) {
        if(this.props.is_community) {
            applyToJoinCommunity(this.props.community_data.uid, user.uid, this.props.is_group ? this.props.group_data.id : "", this.state.message || "")
                .then(() => {

                })
        } else {
            this.setState({
                loading: false
            });
        }

    }

    handleError(error) {
        console.error('error', error);
    }

    hc(f, v) {
        if (f === 'email') {
            this.setState({
                [f]: v,
                checking_email: false,
                checked_email: false,
                email_is_valid: true
            })
        } else {
            this.setState({
                [f]: v
            })
        }

    }

    hb(type) {
        let value = '';
        if (type === 'email') {
            value = this.state.email;
            if (!validateEmail(value)) {
                this.setState({
                    email_is_valid: false
                });
            } else {
                this.setState({
                    email_is_valid: true,
                    checked_email: false
                });
            }
        } else if (type === 'name') {
            const handle = this.state.handle;
            const name = this.state.name;
            if (handle === '' && name !== '') {

                this.setState({
                    handle_name: name
                })
            }
        }
    }

    render() {

        let {
            handle,
            name,
            email,
            checked_email,
            email_is_valid,
            email_is_taken,
            password,
            loading,
            errorMessage
        } = this.state;

        let result = checkPasswordStrength(password);

        const is_applying = !!this.props.applying;

        const base_link = `${this.props.is_community?`/${this.props.community_data.handle}`:""}`;

        const copy = getCopy(is_applying, this.props.is_community||false, this.props.is_group||false, this.props.community_data||{}, this.props.group_data||{});

        const suffix = buildAuthUrlSuffix(email,this.props.group_data||{});

        const disabled = this.disableBtn(result.score) || (this.props.is_community && !this.state.message);
        return <div className="mt-2">
            <Form handleSubmit={this.submit.bind(this)}>

                <div className="space-y-4">
                    <Field placeholder="Your name" onBlur={this.hb.bind(this, 'name')} autoFocus={true} type={"name"}
                           label="Full Name" onChange={this.hc.bind(this, 'name')}
                           value={name}/>

                    <Field placeholder="Your personal email"
                           error={!email_is_valid || (email_is_taken && checked_email)}
                           success={email_is_valid && checked_email && !email_is_taken}
                           onBlur={this.hb.bind(this, 'email')} type={"email"} label="Email"
                           onChange={this.hc.bind(this, 'email')} value={email}/>
                </div>
                {getNameMeta(handle, this.state._customizing, () => this.setState({_customizing: true}), "user")}

                {email_is_taken && checked_email && <div className="pt-2"><TempTip custom={<span>An account with this email
                    already exists. <Link className="color-link" to={`${base_link}/sign-in${suffix}`}>Sign in</Link></span>} /></div>}
                {!email_is_valid &&
                <div className="mt-2 px-3 py-2 rounded-md bg-danger-50 text-danger-800">Please enter a valid
                    email</div>}
                <div className={`${this.state._customizing ? "block mt-4" : "hidden"}`}>
                    <HandleSelector default_text={this.state.handle_name} setHandle={(handle) => {
                        this.setState({handle: handle});
                    }} type='user' scope='global'/>
                </div>

                <div className="pt-4">
                    <PasswordField onChange={this.hc.bind(this, 'password')} score={result.score} value={password}/>
                </div>
                {this.props.is_community && <div className="pt-4">
                    <div>
                        <div className="text-sm text-gray-700"><span>Message</span></div>
                        <TextArea rows={3} value={this.state.message} onChange={v=>this.setState({message:v})} placeholder={copy.message} />
                    </div>
                </div>}
                <CustomCheckbox checked={this.state.checked}
                                content={<span>I Agree to the <Link target="_blank" className="color-link"
                                                                    to="/policies/user-terms.html">
                                Terms
                            </Link>, <Link target="_blank" className="color-link"
                                           to="/policies/privacy.html">
                                    Privacy Policy
                            </Link> and <Link target="_blank" className="color-link"
                                              to="/policies/cookies.html">
                               Cookie Policy.
                            </Link></span>}
                                toggleCheck={() => {
                                    this.setState({checked: !this.state.checked})
                                }}
                />

                {errorMessage !== null ? <div>
                    {errorMessage}
                </div> : null}

                <ButtonSection className='auth-actions'>
                    <Button intent='secondary' fill size='large' disabled={disabled}
                            onClick={this.submit.bind(this, disabled)} text={copy.button_text}
                            loading={loading}/>
                </ButtonSection>

            </Form>

        </div>;
    }
}