import React from "react";
import styled from "styled-components";
import {XIcon} from "@heroicons/react/solid";

const Wrapper = styled.div`
        display: grid;
    grid-template-columns: 1fr auto;
       padding: 8px 20px 4px 20px;
`;

const Title = styled.div`
`;

const Subtitle = styled.div`
`;

const LeftSection = styled.div`
    
`;

const TypeIcon = styled.div`
        height: 26px;
    > svg {
        height: 26px;
    }
`;

const CloseIcon = styled.div`
    > svg {
        height: 20px;
    }
`;

export function AMHeader({title,closeModal,show_close_icon, subtitle, left_icon, type_icon}) {

    return <Wrapper>
        <LeftSection>
            {title && <div className='text-gray-800 text-xl font-bold'>{title}</div>}
            {subtitle && <div className='text-gray-600 text-sm font-medium'>{subtitle}</div>}
        </LeftSection>
        <div className="flex justify-items-center">
            {show_close_icon&&<CloseIcon onClick={()=>closeModal()} className='text-gray-600 h-8 w-8 flex items-center justify-center rounded-md hover:bg-gray-200 cursor-pointer transition-colors'>
                <XIcon/>
            </CloseIcon>}
            {type_icon&&<TypeIcon className='text-gray-600'>
                {type_icon}
            </TypeIcon>}
        </div>
    </Wrapper>
}