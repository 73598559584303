import React from "react";
import {ProfileBadge} from "../../../../../../library/components/profile-badge";
import {PopupMenu} from "../../../../../../library/components/old/popup-menu";
import {Popover2} from "@blueprintjs/popover2";

const mock = [

];

function getLabel(roles) {
    if(roles.length===1) {
        return roles[0];
    } else if(roles.length===2) {
        return `${roles[0]} and ${roles[1]}`;
    } else if(roles.length===3) {
        return `${roles[0]}, ${roles[1]}, and ${roles[2]}`;
    } else {
        return `${roles[0]}, ${roles[1]}, and ${roles.length-2} others`;
    }
}

export function RolesBadge({
    roles = [],
    community_handle = '',
    history = {push:()=>{}}
                           }) {

    if(roles.length===0) {
        return null;
    }

    const label = getLabel(roles.map(r=>r.name));

    if(roles.length===1) {
        return <ProfileBadge text={label} type="roles" onClick={()=>{
            history.push(`/${community_handle}/role/${roles[0].id}`)
        }} />;
    }

    let options = roles.map(r=>{
        return {
            id: r.id,
            type: 'item',
            title: r.name,
            onClick: () => {
                history.push(`/${community_handle}/role/${r.id}`)
            }
        }
    })

    return <Popover2 content={ <PopupMenu items={options}/>} placement={'bottom-start'} minimal popoverClassName='minimal-popover'>
        <ProfileBadge text={label} type="roles" />
    </Popover2>
}