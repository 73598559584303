import {EntityInfo} from "./localstorage/entity-localstorage";
import {buildMultiSelectString} from "../components/old/person-profile";
import {cf_getSelectOption, cf_getSelectOptionsObject, field_export_types} from "./community/custom-fields";
import React from "react";
import {getPhoneNumberData} from "./phone";
import {formatWebsiteForProfile} from "../../app/community/member/helpers";
import {buildAddress, cleanWebsiteForProfile} from "./shared";
import {countries} from "../data/general/countries";

function EntityName({data}) {
    return data.name;
}

export function fv_getFormattedVal(type, val, meta, cf_data, empty='-') {
    let keys = [], options_obj = [], text;
    if (type === 'member' || type === 'mentor') {
        return !val || val === empty ? empty : <EntityInfo key={`${val}`} community_uid={meta.community_uid} id={val} type={'members'}>
            <EntityName/>
        </EntityInfo>
    } else if (type === 'multi-member' || type === 'mentees') {
        if (!val || val === empty) {
            return empty;
        }
        keys = Object.keys(val);
        if (keys.length===0) {
            return empty;
        }
        return <div className="space-x-2 flex flex-wrap">{keys.map((v, k) => {
            return <EntityInfo key={`${v}`} community_uid={meta.community_uid} id={v} type={'members'}>
                <EntityName/>
            </EntityInfo>
        })}</div>;
    } else if (type === 'date') {
        return !val|| val === empty ? empty : val;
    } else if (type === 'text') {
        return !val|| val === empty ? empty : val;
    } else if (type === 'string') {
        return !val|| val === empty ? empty : val;
    } else if (type === 'country') {
        return !val|| val === empty ? empty : countries[val];
    } else if (type === 'url') {
        return !val|| val === empty ? empty : <span>{cleanWebsiteForProfile(val)}</span>;
    } else if (type === 'phone') {
        if(!val|| val === empty) {
         return empty;
        }
        const phone_data = getPhoneNumberData(val);

        if (phone_data.valid) {
            return phone_data.display;
        } else {
            return val;
        }
    } else if (type === 'group') {
        return !val || val === empty ? empty : <EntityInfo key={`${val}`} community_uid={meta.community_uid} id={val} type={'groups'}>
            <EntityName/>
        </EntityInfo>
    } else if (type === 'email') {
        return !val || val === empty ? empty : val;
    } else if (type === 'number') {
        return !val || val === empty ? empty : val;
    } else if (type === 'address') {
        text = buildAddress(val);
        return text ? text : empty;
    } else if (type === 'select') {
        if (!val || val === empty) {
            return empty;
        }
        text = cf_getSelectOption(val, cf_data.options);
        return text;
    } else if (type === 'multi-select') {
        if (!val || val === empty) {
            return empty;
        }
        options_obj = cf_getSelectOptionsObject(cf_data.options);
        if (val.length === 0) {
            return empty;
        } else {
            if(typeof val === 'object') {
                const keys = Object.keys(val);
                let final_keys = keys.filter(function (a) {
                    return options_obj[a];
                })
                if (final_keys.length === 0) {
                    return empty;
                }
            return buildMultiSelectString(final_keys,options_obj);
            } else {
                let final_val = val.filter(function (a) {
                    return options_obj[a];
                })
                if (final_val.length === 0) {
                    return empty;
                }
                return final_val.join(", ");
            }

        }
    } else if (type === 'select-taxonomy') {
        return !val ? empty : val.label;
    }  else if (type === 'checkbox') {
        return !val ? false : val;
    } else if (type === 'multi-select-taxonomy') {
        return !val ? empty : () => {
            keys = val;
            if (keys.length === 0) {
                return [];
            } else {
                return keys.map((val, i) => `${val.label}${(i + 1) !== keys.length ? ', ' : ''}`);
            }
        };
    } else if (type === 'textarea') {
        return !val ? empty : val;
    } else if (type&&type.startsWith('social-')) {
        if(!val) {
            return empty;
        }
        return field_export_types[type].export_fn(val,{});
    } else {
        return !val ? empty : JSON.stringify(val);
    }
}