import {FirebaseDB} from "../../../config/setup-firestore";

export async function api_getPostReply(community_uid,post_id,reply_id) {

    return await FirebaseDB
        .collection('community_content')
        .doc(community_uid)
        .collection('posts')
        .doc(post_id)
        .collection('replies')
        .doc(reply_id)
        .get()
        .then(doc=> {
           return {
               ...doc.data(),
               id: doc.id
           }
        })
}