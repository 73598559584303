import React from "react";
import {
    ArchiveIcon,
    BanIcon,
    CheckCircleIcon, ChevronDownIcon,
    ClockIcon,
    ExclamationCircleIcon, ExclamationIcon,
    InformationCircleIcon, PaperAirplaneIcon, QuestionMarkCircleIcon
} from "@heroicons/react/solid";
import {CIRole} from "../../icons/custom/Role";
import {CIAdmin} from "../../icons/custom/Admin";
import {CIOwner} from "../../icons/custom/Owner";
import {CIIndifferent} from "../../icons/custom/Indifferent";
import {CIHappy} from "../../icons/custom/Happy";
import {CIVeryHappy} from "../../icons/custom/VeryHappy";
import {CIUnhappy} from "../../icons/custom/Unhappy";
import {CIVeryUnhappy} from "../../icons/custom/VeryUnhappy";
import {CIMemberType} from "../../icons/custom/MemberType";
import {CakeIcon} from "@heroicons/react/outline";

const type_ref = {
  'owner': {
      label: 'Owner',
      larger: true,
      icon: <CIOwner />
  },
    'member-type': {
        label: 'Member Type',
        larger: true,
        icon: <CIMemberType />
    },
    'admin': {
        label: 'Admin',
        larger: true,
        icon: <CIAdmin />
    },
    'suspended': {
        label: 'Suspended',
        icon: <BanIcon />
    },
    'roles': {
        label: 'Senior, Kassenwart',
        larger: true,
        icon: <CIRole />
    },
    'archived': {
        label: 'Deactivated',
        icon: <ArchiveIcon />
    },

    'deactivated': {
        label: 'Deactivated',
        icon: <ArchiveIcon />
    },

    'perfect': {
        label: 'Perfect',
        larger: true,
        icon: <CIVeryHappy />
    },
    'good': {
        label: 'Good',
        larger: true,
        icon: <CIHappy />
    },
    'ok': {
        label: 'Ok',
        larger: true,
        icon: <CIIndifferent />
    },
    'poor': {
        label: 'Poor',
        larger: true,
        icon: <CIUnhappy />
    },
    'invite-sent': {
        label: 'Invite Sent',
        icon: <PaperAirplaneIcon fill="#448def" />
    },
    'never-invited': {
        label: 'Never Invited',
        icon: <InformationCircleIcon fill="#52525b" />
    },
    'last-login': {
      label: 'Yesterday',
        icon: null
    },
    'very-bad': {
        label: 'Very bad',
        larger: true,
        icon: <CIVeryUnhappy />
    },
    'up-to-date': {
        label: 'Up-to-date',
        icon: <CheckCircleIcon fill="#00B71C" />
    },
    'outdated': {
        label: 'Outdated',
        icon: <ClockIcon fill="#52525b" />
    },
    'never-accepted': {
        label: 'Never accepted',
        icon: <ExclamationCircleIcon fill="#FDCE4E" />
    },
    'na': {
        label: 'N/A',
        icon: <InformationCircleIcon fill="#7b7b80" />
    },
    'application-pending': {
      label: 'Pending',
        icon: <ClockIcon fill="#FDCE4E" />
    },
    'invite-just-sent': {
        label: 'Invite sent!',
        icon: <PaperAirplaneIcon fill="#29875b" />
    },
    'sending-invite': {
        label: 'Sending invite..',
        icon: <ClockIcon fill="#7b7b80" />
    },
    'join-date': {
        label: '',
        icon: null
    },
    'location': {
        label: '',
        icon: null
    },
    'text': {
        label: '',
        icon: null
    },
    'email-warning': {
        label: '',
        icon: <ExclamationIcon fill="#FDCE4E" />
    },
    'selector': {
      label: '',
        icon: null
    },
    'birthday': {
      icon: null,
        label: ''
    }
};

function getPadding(icon,compact) {
    if(compact) {
        return 'pl-1 pr-1 py-0.5'
    }
    if(icon) {
        return 'pl-1.5 py-1 pr-2';
    }
    return 'pl-2 py-1 pr-2';
}

export function ProfileBadge({type='archived',compact,emoji,text,onClick}) {
    const {label,larger,icon} = type_ref[type];
    const padding = getPadding(icon,compact);
    const text_color = compact ? "text-gray-500" : "text-gray-800";
    return <div onClick={onClick} className={`inline-flex space-x-2 rounded-md transition-colors ${padding} ${onClick?"cursor-pointer hover:bg-gray-200":""}`}>
        {icon&&<div className="w-5 h-5 flex items-center">
        <div className={`${larger?"w-6 h-6 -mx-0.5":"w-5 h-5"} text-gray-700`}>
            {icon}
        </div>
        </div>}
        {emoji&&<div className="w-4 h-5 flex items-center">
            <div className={`text-base`}>
                {emoji}
            </div>
        </div>}
        <div className={`text-sm ${text_color} font-medium leading-5 break-word`}>{text?text:label}</div>
        {type==='selector'&&<div>
            <div className="h-5 w-4 -ml-0.5 -mr-0.5 flex items-center justify-center">
            <div className="w-4 h-4">
            <ChevronDownIcon />
            </div>
        </div>
        </div>}
    </div>
}