import React from "react";
import {PLAYDarkModeSwitcher} from "../components/dark-mode-switcher";
import {PLAYScratch} from "./scratch";
import {NewMobileDirectory} from "../components/mobile-directory";
import {Notifications} from "../components/notifications";

export const playground_components = [
    {
        config: {}, trello: '', tags: [], component: <PLAYScratch />, id: 'scratch',
        label: 'Scratch'
    },
    {
        config: {}, trello: '', tags: [], component: <PLAYDarkModeSwitcher />, id: 'dark-mode-switcher',
        label: 'Dark Mode Switcher'
    }
];