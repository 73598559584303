import React, {useContext, useState} from "react";
import {ProfileBadge} from "../../../../../../library/components/profile-badge";
import {NewTooltip} from "../../../../../../library/components/reorganize/new-tooltip";
import {authFetch} from "../../../../../../config/network";
import {aa_formatDate, getPrettyTime} from "../../../../../../library/utilities/general/dates";
import moment from "moment";
import {isAnonymousUnatyEmail} from "../../../../../../library/utilities/anonymous-emails";
import {GlobalContext} from "../../../../../global/global-context";
import {membersBuildInviteTo} from "../../../../../../library/playground/advanced-table/wrapper";

export function LastLoginBadge({member={}, community={}, member_id="" }) {
    const [status, setStatus] = useState('');
    const global = useContext(GlobalContext);
    const invites_sent = member.invites_sent ? member.invites_sent : [];
    const last_sign_in = member.last_sign_in ? member.last_sign_in : null;
if(!member_id) {
    return null;
}
    function sendInvite() {
        const prefill = {
            to: membersBuildInviteTo([{...member,id:member_id}]),
            config: {},
            content: `${community.member.name} (${community.member.account_email}) invited you to join ${community.data.name} on Unaty.`,
            subject: `${community.member.name} invited you to join ${community.data.name}`,
            type: 'invite',
            template: 'system-invite'
        };

        community.openComposer(prefill)
    }

    if (status === 'invite-just-sent') {
        return <ProfileBadge onClick={() => {
        }} type="invite-just-sent"/>
    }
    if (status === 'sending-invite') {
        return <ProfileBadge onClick={() => {
        }} type="sending-invite"/>
    }
    if (!last_sign_in && invites_sent.length === 0) {
        if(isAnonymousUnatyEmail(member.account_email)) {
            return <ProfileBadge type="text" text={"N/A"}/>
        } else {
            return <NewTooltip message="Click to send invite">
                <ProfileBadge onClick={() => sendInvite()} type="never-invited"/>
            </NewTooltip>
        }
    }

    const last_login_time = aa_formatDate(moment.unix((last_sign_in/1000)),true).relative;
    const last_invite_sent = getPrettyTime(moment.unix((invites_sent[invites_sent.length-1]/1000)));
    if (!last_sign_in) {
        return <NewTooltip message={`Last invite sent ${last_invite_sent}`}>
            <ProfileBadge onClick={() => sendInvite()} type="invite-sent"/>
        </NewTooltip>
    } else {
        return <ProfileBadge text={last_login_time} type="last-login"/>
    }
}