import React, {Component, useState} from "react";

import styled from "styled-components";
import {authFetch} from "../../config/network";
import {Button} from "../../library/components/core";
const Container = styled.div`
   max-width: 1600px;
   padding: 32px 0;
`;

export default class GodClearDB extends Component {
    constructor(props) {
        super(props);

        this.state = {
            community_uid: '',
            type: '',
            loading: false
        };
    }

    handleChange(f,v) {
        this.setState({
            [f]: v
        })
    }

    clearDB() {
        this.setState({
            loading: true
        });

        const res = () => {
            this.setState({
                loading: false
            });
        };

        const payload = this.state;
        authFetch("/superadmin/clear-db", res, res, "POST", {payload});
    }

    memberIdMigration() {
        this.setState({
            loading: true
        });

        const res = () => {
            this.setState({
                loading: false,
            });
        };

        const payload = this.state;
        authFetch("/superadmin/member-id-migration", res, res, "POST", {payload});
    }

    singleMigration() {
        this.setState({
            loading: true
        });

        const res = () => {
            this.setState({
                loading: false
            });
        };

        const payload = this.state;
        authFetch("/superadmin/single-migration", res, res, "POST", {payload});
    }

    render() {

        let {loading} = this.state;

        return (<Container>

                <div>

                </div>

                <div>
                    <div>
                        <input placeholder="COMMUNITY ID" value={this.state.community_uid} onChange={(e)=>this.setState({community_uid:e.target.value})} />
                    </div>
                    <Button loading={loading} text={'Member ID Migration'} onClick={this.memberIdMigration.bind(this)}/>
                </div>

                <div>
                    <Button loading={loading} text={'Single Migration'} onClick={this.singleMigration.bind(this)}/>
                </div>

            </Container>
        );
    }
}