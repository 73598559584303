import {FirebaseDB} from "../../config/setup-firestore";
import {authFetch} from "../../config/network";

export async function saveEmailIPConfigChange(pl) {
    const res = () => {

    };
    const payload = {
        ...pl
    };

    authFetch("/superadmin/set-mailgun-warmup-config", res, res, "POST", {payload});
}

export async function getEmailIPConfig() {
    return await FirebaseDB
        .collection('remote_config')
        .doc('mailgun_ip_warmup')
        .get()
        .then(d=>d.data());
}