import React, {useState} from "react";
import {OnboardingActions, OnboardingTitle} from "../../components";
import {OnboardingHelpSection, OnboardingHelpToggle} from "../../components/help";

const mock = {
    title: 'Example large title',
    subtitle: 'This is an example subtitle'
};


export function OnboardingTemplateRightImage({
                                                 title = mock.title,
                                                 onConfirm = () => {},
                                                 confirm_text = '',
                                                 onCancel = () => {},
    fns = {},
    help = null,
    image = null,
                                                 component = <div></div>,
                                                 cancel_text = '',
                                                 subtitle = mock.subtitle
                                             }) {
    const [show_help,setShowHelp] = useState(false);
    return <div className="relative grid grid-cols-3 h-full">
        <div className="col-span-2 h-full pl-8 pt-8 pb-8 pr-4 flex flex-col space-y-4">
            <div className="flex-none">
                <OnboardingTitle title={title} subtitle={subtitle}/>
            </div>
            <div className="flex-grow">
                {component}
            </div>
            <div className="flex-none">
                <OnboardingActions onConfirm={()=>onConfirm({},fns)} confirm_text={confirm_text} cancel_text={cancel_text} onCancel={()=>onCancel({},fns)} />
            </div>
        </div>
        <div className="w-full h-full">
            {image}
        </div>
        {show_help&&<OnboardingHelpSection layout="right-image" comp={help} />}
        {help&&<OnboardingHelpToggle layout="right-image" on={show_help} onClick={()=>setShowHelp(!show_help)} />}
    </div>
}