import React, {useState, useContext, useEffect} from 'react';
import styled from "styled-components";
import {filter_match} from "../../../../app/community/reorganize/filtering-data";
import Select from "../select";
import {UserCircleIcon, CheckCircleIcon, FireIcon, ClipboardListIcon} from '@heroicons/react/solid';
import {segment_conditions} from '../../../data/community/segments'
import {CUSTOM_NANOID} from "../../../../config/defaults";
import {RulesAddItem} from "../../rules/rules-add-item";
import {RulesItem} from "../../rules/rules-item";
import {FilterCalculator, getValidConditions} from "./calculator";
import {CommunityContext} from "../../../../app/community/community-context";
import {custom_field_types} from "../../../utilities/community/custom-fields";
import {custom_field_type_icons} from "../../../../app/auth/profile-fields/constants";
import {countries} from "../../../data/general/countries";

const condition_options = [
    {
        label: 'Address',
        id: 'address',
        icon: <UserCircleIcon/>,
        items: [
            {
                label: 'ZIP Code',
                id: 'address.zip_code'
            },
            {
                label: 'City',
                id: 'address.city'
            },
            {
                label: 'Country',
                id: 'address.country'
            }
        ]
    },
    {
      label: 'Profile',
      id: 'profile',
      icon: <UserCircleIcon />,
      items: [
          {
              label: 'Age',
              id: 'age'
          },
          {
              label: 'Sex',
              id: 'about.gender'
          },
      ]
    },
    {
        label: 'Status',
        id: 'status',
        icon: <CheckCircleIcon/>,
        items: [
            {
                label: 'Member Type',
                id: 'member_type'
            },
            {
                label: 'Deactivated',
                id: 'archived'
            },
            {
                label: 'Last sign in',
                id: 'last_sign_in'
            }
        ]
    },
    {
        label: 'Activity',
        id: 'activity',
        icon: <FireIcon/>,
        items: [
            {
                label: '# of sign ins',
                id: 'stats.sessions'
            },
            {
                label: 'Profile last updated',
                id: 'profile_updated_at'
            }
        ]
    }
];

const InlineSelect = styled.div`

  > div {
    height: 25px !important;
    display: flex;
    align-items: center;
    min-width: 80px;
    line-height: normal !important;
  }

  span.bp4-icon {
    top: 6px !important;
    right: 2px !important;
  }

  .bp4-html-select.bp4-fill {
   
  }
`;

function factory(field = 'address.zip_code') {
    const default_operator = segment_conditions[field].default_operator;
    const type = segment_conditions[field].type;
    const default_value = segment_conditions[field].default_value;
    return {id: CUSTOM_NANOID(), type, field: field, operator: default_operator, value: default_value, modifier: ''};
}

function ANDOR({or = false}) {
    return <div className={"p-2 font-semibold text-sm uppercase tracking-wide text-secondary"}>
        {or ? "OR" : "AND"}
    </div>
}

const allowed_custom_fields = Object.entries(custom_field_types).map(([id, entry]) => {
    return {
        id,
        filterable: entry.filterable
    }
}).filter(a => a.filterable).map(it => it.id);

function buildFinalOptions(community) {
    let base = [...condition_options];
    const custom_fields_sections = Object.entries(community.custom_fields);
    // do this for each section, no icon

    if (custom_fields_sections.length > 0) {
        for (let i = 0; i < custom_fields_sections.length; i++) {
            const [id, data] = custom_fields_sections[i];
            let allowed_fields = data.fields.filter(a => {
                const a_data = data.field_data[a];
                if (allowed_custom_fields.includes(a_data.type)) {
                    return a;
                }
            });

            if (allowed_fields.length > 0) {
                base.push({
                    label: data.name,
                    id: id,
                    icon: <ClipboardListIcon/>,
                    items: allowed_fields.map(f => {
                        const f_data = data.field_data[f];
                        return {
                            id: `custom_fields.${f}`,
                            label: f_data.name
                        }
                    })
                });
            }
        }
    }

    return base;
}

function buildOptions(field_data) {
    const {type} = field_data;
    if (type === 'country') {
        return Object.entries(countries).map(([id, label]) => {
            return {id, label}
        })
    } else if (type === 'select' || type === 'multi-select') {
        return field_data.options.choices.map(ch => {
            return {
                id: ch.value,
                label: ch.text
            }
        })
    } else {
        return [];
    }
}

function getSegmentConditions(community) {
    let base = {...segment_conditions};

    base.member_type.options = Object.entries(community.member_types).map(([id, entry]) => {
        return {
            id,
            label: entry.singular
        }
    });

    // add in custom fields options
    const custom_fields_sections = Object.entries(community.custom_fields);
    // do this for each section, no icon

    if (custom_fields_sections.length > 0) {
        for (let i = 0; i < custom_fields_sections.length; i++) {
            const [id, data] = custom_fields_sections[i];
            let allowed_fields = data.fields.filter(a => {
                const a_data = data.field_data[a];
                if (allowed_custom_fields.includes(a_data.type)) {
                    return a;
                }
            });

            for (let k = 0; k < allowed_fields.length; k++) {
                const af = allowed_fields[k];
                const field_data = data.field_data[af];
                const custom_field_ref = custom_field_types[field_data.type];

                base[`custom_fields.${af}`] = {
                    type: custom_field_ref.filter_type,
                    prefix: field_data.name,
                    suffix: '',
                    default_operator: custom_field_ref.filter_default_operator,
                    default_value: custom_field_ref.filter_default_value,
                    icon: custom_field_type_icons[field_data.type],
                    options: buildOptions(field_data)
                };
            }
        }
    }

    return base;
}

function setValidity(arr,sc) {
    const vc = getValidConditions(arr, sc, true);

    let a = [...arr];

    for(let i=0;i<arr.length;i++) {
        if(vc.includes(i)) {
            a[i].valid = true;
        } else {
            a[i].valid = false;
        }
    }

    return a;
}

export function AdvancedFilter({m='any', c=[],updateParent=()=>{}, preview = false}) {
    const [match, setMatch] = useState(m);
    const community = useContext(CommunityContext);
    const [conditions, setConditions] = useState([...c]);
    const sc = getSegmentConditions(community);
    const final_options = buildFinalOptions(community);

    useEffect(function () {
        setConditions([...c]);
        setMatch(m);
    }, [c,m])

    useEffect(function () {
        updateParent(JSON.parse(JSON.stringify([...conditions])),match);
    }, [conditions,match]);

    function handleChange(num, p, val) {
        let nc = [...conditions];
        nc[num].operator = p.operator;
        nc[num].value = p.value;
        const final = setValidity(nc,sc);
        setConditions(final);
    }

    function addFilter(index, field) {
        if (index) {
            let nc = conditions;
            nc.splice(index, 0, factory(field));
            setConditions([...nc]);
        } else {
            setConditions([...conditions, factory(field)]);
        }
    }

    function removeFilter(index) {
        let nc = conditions;
        nc.splice(index, 1);
        setConditions([...nc]);
    }

    if (preview) {
        return <div>
            <FilterCalculator match={match} sc={sc} conditions={conditions}/>
        </div>
    }

    return <div>
        <div className="space-y-4">
            {conditions.map((cond, i) => <div key={cond.id}>
                    <RulesItem key={cond.id} sc={sc} updateParent={(p) => handleChange(i, p, cond)} {...cond}
                               onDelete={() => removeFilter(i)}/>
                </div>
            )}
            {conditions.length === 1 && <div className="space-y-4">
                <div>
                    <ANDOR/>
                    <RulesAddItem options={final_options} onSelect={(t) => {
                        setMatch('all');
                        addFilter(null, t)
                    }}/>
                </div>
                <div>
                    <ANDOR or/>
                    <RulesAddItem options={final_options} onSelect={(t) => {
                        setMatch('any');
                        addFilter(null, t)
                    }}/>
                </div>
            </div>}
            {conditions.length === 0 && <RulesAddItem options={final_options} onSelect={(t) => addFilter(null, t)}/>}
            {conditions.length > 1 && conditions.length < 4 &&
            <RulesAddItem options={final_options} onSelect={(t) => addFilter(null, t)}/>}
        </div>
        <div className="pt-4">
            {conditions.length > 1 && <div className="text-sm text-gray-600 flex items-center font-medium w-full px-3 py-2 rounded-lg border border-gray-200">
                <div>Match</div>
                <InlineSelect className="h-8 flex items-center px-2.5"><Select usePortal compact blank_option={false} value={match} field='match'
                                      onChange={(f, v) => setMatch(v)}
                                      options={filter_match}/></InlineSelect>
                <div>of the following criteria</div>
            </div>}
        </div>
    </div>
}