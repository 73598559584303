import React, {Component} from "react";
import {slugify} from "../../../library/utilities/slugify";
import {merge} from 'lodash';
import {
    LoadingBox, NoResultsBox,
    SearchInputBox,
    SearchResult,
    SearchResultsBox,
    SelectEntitiesFrame,
    TableOfEntities
} from "./shared";
import {InlineLoader} from "../../../library/components/core";
import {FirebaseDB} from "../../../config/setup-firestore";

function updateFinalResults(state) {
    let members = [];
    let groups = [];
    let roles = [];

    state.members_first_name.map((id)=>{
        members.push(id);
    });
    state.members_last_name.map((id)=>{
        members.push(id);
    });
    state.members_last_name.map((id)=>{
        members.push(id);
    });

    let final_results = [];

    members.map((id)=>{
       final_results.push({
           type: 'members',
           id
       })
    });

    return final_results;
}

function getIsSearching(search_state) {
    for (let property in search_state) {
        if (search_state.hasOwnProperty(property)) {
            // Do things here
            if(search_state[property]) {
                return true;
            }
        }
    }
    return false;
}

const initial_searching_state = {
    members_first_name: false,
    members_last_name: false,
    members_handle: false,

    roles_name: false,
    roles_handle: false,

    groups_name: false,
    groups_handle: false
};

function getMemberTypeMatches(q,member_types,existing) {
    const keys = Object.keys(member_types);
    let query = q.toLowerCase();
    let to_return = [];

    for(let i=0;i<keys.length;i++) {
        const key = keys[i];
        const val = member_types[key];

        if(val.plural.toLowerCase().startsWith(query)) {
            if(existing.member_types) {
                if(existing.member_types[key]) {

                } else {
                    to_return.push({
                        type: 'member_types',
                        id: key
                    })
                }
            } else {
                to_return.push({
                    type: 'member_types',
                    id: key
                })
            }

        }
    }
    return to_return;
}

function getFinalData(data,member_types) {
    let obj = Object.assign({},data);
    const mt_keys = Object.keys(member_types);
    for(let i=0;i<mt_keys.length;i++) {
        const key = mt_keys[i];
        const item = member_types[key];
       obj['member_types'][key] = {
           id: key,
           type: 'member_type',
           image: '',
           handle: item.handle,
           name: item.plural
       };
    }

    return obj;
}

export default class SelectEntities extends Component {
    constructor(props) {
        super(props);

        this.db = FirebaseDB;

        this.setRef = ref => this.inputRef = ref;

        this.focus = () => this.inputRef.focus();

        this.handleKeyDown = this.handleKeyDown.bind(this);

        // props
        // hideExisting
        // _existing values

        this.state = {
            query: '',
            focus: true,
            selectedIndex: 0,

            searching: {},

            data: {
                members: {},
                member_types: {},
                groups: {},
                roles: {},
            },

            members_first_name: [],
            members_last_name: [],
            members_handle: [],

            roles_name: [],
            roles_handle: [],

            groups_name: [],
            groups_handle: [],

            final_results: []

        };
    }

    handleKeyDown(e) {

        const { selectedIndex, final_results } = this.state;

        // arrow up/down button should select next/previous list element
        if(e.keyCode===38||e.keyCode===40||e.keyCode===13) {
            e.preventDefault();
        }

        if(e.keyCode===9&&final_results.length>0) {
            e.preventDefault();
        }

        if(e.keyCode === 13) {
            // enter key
            if(final_results.length>0) {
                // select
                this.handleSelect(final_results[selectedIndex])
            }
        } else if(e.keyCode === 9 && selectedIndex < final_results.length - 1) {
            // tab key
            this.setState( prevState => ({
                selectedIndex: prevState.selectedIndex + 1
            }))
        } else if (e.keyCode === 38 && selectedIndex > 0) {
            this.setState( prevState => ({
                selectedIndex: prevState.selectedIndex - 1
            }))
        } else if (e.keyCode === 40 && selectedIndex < final_results.length - 1) {
            this.setState( prevState => ({
                selectedIndex: prevState.selectedIndex + 1
            }))
        }
    }

    hc(e) {
        const query = e.target.value;

        const search_query = slugify(query);

        if(search_query.length>2) {
            this.search(search_query, 'members_first_name', 'members', 'first_name');
            this.search(search_query, 'members_last_name', 'members', 'last_name');
            this.search(search_query, 'members_handle', 'members', 'handle');
        }

        this.setState({
            query: e.target.value
        })
    }


    search(query, ctx, type, search_field) {
        const {searching} = this.state;

        if(searching[ctx]) {
            // already searching
            return;
        }

        const field = `${ctx}`;

        const new_searching = merge(searching, {[field]: true});

        this.setState({
            searching: new_searching
        });

        const handleRes = (snap) => {
            const updated_searching = merge(searching, {[field]: false});

            if (snap.empty) {
                let new_state = this.state;
                new_state[ctx] = [];
                this.setState({
                    searching: updated_searching,
                    [ctx]: [],
                    final_results: updateFinalResults(new_state, this.props.value),
                    selectedIndex: 0
                });
            } else {
                const {selectedIndex,data} = this.state;
                let ids = [];
                let new_data = data;
                snap.forEach((doc) => {
                    const data = doc.data();

                    if(this.props.value[type][data.id]) {
                        return;
                    }
                    if(this.props.hideExisting) {
                        if(this.props._existing.entities[type][data.id]) {
                            return;
                        }
                    }

                    // search results is just uid
                    new_data[type][data.id] = {
                        id: data.id,
                        type: 'member',
                        handle: data.handle,
                        image: data.image,
                        name: data.name
                    };
                    ids.push(data.id);
                });
                const updated_searching = merge(searching, {[field]: false});

                let new_state = this.state;
                new_state[ctx] = ids;
                    this.setState({
                        [ctx]: ids,
                        data: new_data,
                        final_results: updateFinalResults(new_state, this.props.value),
                        searching: updated_searching,
                        selectedIndex: 0
                    });
            }
        };

        this.db
            .collection('search')
            .doc(this.props.community_uid)
            .collection(type)
            .orderBy(search_field)
            .startAt(query)
            .endAt(query + "\uf8ff")
            .get()
            .then((snap) => handleRes(snap));
    }

    renderResults(results,isSearching,selectedIndex) {
        const {query} = this.state;
        if(query.length<2) {
            return null;
        }
        const {data} = this.state;

        const mt_matches = getMemberTypeMatches(query,this.props.member_types,this.props.value);

        const final_data = getFinalData(data,this.props.member_types);

        const final_results = mt_matches.concat(results);

        if(final_results.length>0) {
            return <SearchResultsBox>
                {final_results.map((item,i)=>{
                    const {id,type,image} = item;
                    const active = selectedIndex===i;
                    const item_data = final_data[type][id];

                    return <SearchResult image={image} selected={active} key={`${type}-${id}`} onClick={this.handleSelect.bind(this, item)} title={item_data.name} />;
                })}
            </SearchResultsBox>
        } else if(final_results.length===0&&isSearching) {
            return <SearchResultsBox>
                <LoadingBox>
                    <InlineLoader />
                </LoadingBox>
            </SearchResultsBox>
        } else if(!isSearching) {
            return <SearchResultsBox>
                <NoResultsBox>
                    No Results
                </NoResultsBox>
            </SearchResultsBox>
        }
    }

    handleSelect(item) {
        this.props.onSelect(item);

        this.setState({
            query: '',
            focus: true,
            final_results: [],
            selectedIndex: 0
        },()=>{this.focus()})
    }

    handleRemove(type, id) {
        this.props.onRemove({type,id});
    }

    render() {
        const {query,final_results,selectedIndex,data,searching} = this.state;
        const {value,member_types} = this.props;

        const isSearching = getIsSearching(searching);

        return (<SelectEntitiesFrame>
                <SearchInputBox>
                    <input placeholder='' ref={this.setRef} onKeyDown={this.handleKeyDown} value={query} autoFocus onChange={this.hc.bind(this)} onFocus={()=>{this.setState({focus: true})}} onBlur={()=>{if(this.state.focus){this.setState({focus:false})}}} />

                    {this.renderResults(final_results,isSearching,selectedIndex)}
                </SearchInputBox>

                <TableOfEntities extras={{member_types}} data={data} onRemove={this.handleRemove.bind(this)} entities={value} />
            </SelectEntitiesFrame>
        );
    }
}