import {authFetch} from "../../../config/network";

export function getEditFileAbout(data,id,community_uid,member_id,exclude,cb) {
    return {
        _initial: data,
        _fields: [
            {
                type: 'file-name',
                field: 'name',
                label: 'Name',
                autofocus: true,
                exclude: exclude,
                meta: 'We recommend keeping the file extension, like .png, in the name to make it easy to find and download files.'
            }
        ],
        _endpoint: '/files/update-about',
        _id: id,
        _community_uid: community_uid,
        _member_id: member_id,
        _title: 'Rename File',
        _left_items: [],
        _success_cb: ()=>{
            cb();
        },
        _error_cb: ()=>{

        }
    };
}

export function getEditAbout(id,community,data,cb) {
    return {
        _initial: data,
        _fields: [
            {
                type: 'emoji-hidden',
                field: 'emoji',
                label: 'Emoji',
                emoji: data.emoji
            }
        ],
        _endpoint: '/folders/edit-about',
        _id: id,
        _community_uid: community.uid,
        _title: 'Edit Folder',
        _left_items: [],
        _success_cb: ()=>cb(),
        _error_cb: ()=>{

        }
    };
}

export function getEditAccess(item,entities_filters) {
    return {
        _initial: {
            entities: {members: {}, member_types: {}, groups: {}, teams: {}, roles: {}, filters: {}},
            filters: {}
        },
        _existing: {entities: entities_filters},
        _hide_existing: true,
        _enable_progress_bar: true,
        _fields: [
            {
                type: 'entities-filters',
                field: 'entities',
                label: 'Add Members'
            }
        ],
        _endpoint: '/folders/add-new-entities',
        _id: item.id,
        _community_uid: item.context.uid,
        _title: 'Manage Access',
        _success_cb: ()=>{
            item.getFolder();
        },
        _error_cb: ()=>{

        }
    };
}

function getEntityID(type, id) {
    if (type === 'filters') {
        return `${id.field}.${id.num}`;
    }
    return id;
}

export function removeEntity(type, id) {
    if (this.state._removing_entity) {
        return;
    }
    const payload = {
        community_uid: this.props.community_uid,
        member_id: this.props.member_id,
        id: this.id,
        entity_type: type,
        entity_id: getEntityID(type, id)
    };

    const res = (result) => {
        this.getFolder();
        this.setState({
            _removing_entity: false
        });
    };

    this.setState({
        _removing_entity: true
    });

    authFetch("/folders/remove-entity", res, res, "POST", {payload});
}

export function deleteFolder() {
    const {deleting,data} = this.state;
    if (deleting) {
        return;
    }

    this.setState({
        deleting: true
    });

    const payload = {
        community_uid: this.context.uid, member_id: this.context.member_id, id: this.id
    };

    const redirect = () => {
        const ext = data.folder === '' ? `drive` : `drive/folder/${data.folder}`;
        this.setState({
            deleting: false,
            _open_general: false
        }, ()=>{
            this.props.actions.addToast({text: "Folder was deleted", intent: 'success'});
            this.props.history.push(`/${this.context.data.handle}/${ext}`);
        });
    };

    const res = (result) => {
        redirect();
    };

    authFetch("/folders/delete", res, res, "POST", {payload});
}

export function deleteFile(id,community_uid,community_handle,member_id,cb) {
    const payload = {
        community_uid: community_uid, id: id, member_id: member_id
    };

    const res = (result) => {
        cb();
    };

    authFetch("/files/delete", res, res, "POST", {payload});
}