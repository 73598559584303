import React from "react";
import styled from "styled-components";
import {blockRenderer} from "./block-renderer";

const Wrapper = styled.form`
    padding: 10px 20px 16px 20px;
`;

function hideBlock(block, ctx) {
    if (!block.hide) {
        return false;
    }
    for (let i = 0; i < block.hide.length; i++) {
        const {check, field, value} = block.hide[i];
        switch (check) {
            case 'ctx-value-not-equal' :
                return ctx[field] !== value;
            default:
                return false;
        }
    }
    return false;
}

export function AMBody({data, updateData, blocks, handleSubmit = () => {}, context}) {
    return <Wrapper className="space-y-4" onSubmit={(e) => {
        e.preventDefault();
        handleSubmit();
    }}>
        {blocks.map((block, i) => {
            if (hideBlock(block, context)) {
                return null;
            }
            return <div  key={i}>
                {blockRenderer(block, data, updateData, context)}
            </div>
        })}
    </Wrapper>
}