import React, {useContext, useEffect} from "react";
import {GlobalContext} from "../global-context";
import {useLocation} from "react-router-dom";
import {FilePageWrapper} from "../../community/files/file-page-wrapper";

export function FileViewerGlobal(props) {
    const global = useContext(GlobalContext);

    const location = useLocation();

    useEffect(function () {
            global.closeFileViewer();
    }, [location.pathname]);

    if(global.file_viewer) {

        return <FilePageWrapper {...global.file_viewer} />
    }

    return null;
}