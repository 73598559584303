import {FirebaseDB} from "../../../config/setup-firestore";
import {aa_formatDate} from "../../../library/utilities/general/dates";
import {convertFromRaw} from 'draft-js';
import {parseRawContent} from "../../../library/components/posts-feed/api";
export async function api_getPostReplies(community_uid,post_id,reply_to_id="",ref,page,per_page=25) {
    const replies_docs = await getPostReplies(community_uid,post_id,reply_to_id,ref,page,per_page,'group');

    const recent_replies = replies_docs.map(d=>{
        const dt = d.data();
        return {
            ...dt,
            id: d.id
        }
    });

    const last_ref = recent_replies.length>0 ? replies_docs[replies_docs.length-1] : null;

    return {replies: recent_replies.map(rp=>{
            return {
                ...rp,
                content_state: convertFromRaw(parseRawContent(rp.raw)),
                formatted_date: aa_formatDate(rp.created_at),
                data: rp
            }
        }), last_ref}
}

async function getPostReplies(community_uid,post_id,reply_to_id="",ref,page,per_page=25) {

    if(ref&&page>0) {
        return await FirebaseDB
            .collection('community_content')
            .doc(community_uid)
            .collection('posts')
            .doc(`${post_id}`)
            .collection('replies')
            .where('reply_to_id', '==', reply_to_id)
            .orderBy('created_at', 'desc')
            .startAfter(ref)
            .limit(per_page)
            .get()
            .then(snap=>{
                return snap.docs;
            })
    }
    return await FirebaseDB
        .collection('community_content')
        .doc(community_uid)
        .collection('posts')
        .doc(`${post_id}`)
        .collection('replies')
        .where('reply_to_id', '==', reply_to_id)
        .orderBy('created_at', 'desc')
        .limit(per_page)
        .get()
        .then(snap=>{
            return snap.docs;
        })
}