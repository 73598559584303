import React, {useEffect, useState} from "react";
import {Button, EntityIcon, Tabs} from "../core";
import {ChevronDownIcon, DotsVerticalIcon, LockClosedIcon} from "@heroicons/react/solid";
import {EntityIconSelector} from "../core/entity-icon-selector";
import {PhotoSelector} from "../old/photo-selector";
import {Popover2} from "@blueprintjs/popover2";
import {PopupMenu} from "../old/popup-menu";
import {MemberStarred} from "../starring";
import {GroupDisplayIcon} from "../../utilities/groups";
import styled from "styled-components";
import {useHistory} from "react-router-dom";
import {useIsMobile} from "../../../app/global/global-router";

function AEHTitle({t, editing, muted_title, doneEditing, handleChange, startEditing, can_edit}) {

    if (can_edit && !editing) {
        return <div style={{maxWidth: '400px'}} onClick={startEditing}
                    className={`px-1 py-px inline-block -my-0.5 -mx-1.5 hover:bg-gray-200 cursor-pointer rounded-md transition-colors text-lg text-gray-800 font-bold `}>{t}</div>
    } else if (editing) {
        return <div className={` rounded-md inline-block`}>
            <input onKeyUp={e => {
                if (e.keyCode === 13) {
                    doneEditing();
                }
            }} style={{maxWidth: '400px'}} autoFocus onFocus={e => e.target.select()} onBlur={doneEditing}
                   className={`text-lg px-1 py-px rounded-md -my-px -mx-1.5 focus-standard text-gray-800 text-gray-800 font-bold`}
                   value={t} onChange={handleChange}/>
        </div>
    }

    return <div className="text-lg inline-block text-gray-800 font-bold">{t}</div>
}

const Crumb = styled.div`
  height: 22px;
`;

const Icon = styled.div`
  height: 16px;

`;

const Emoji = styled.div`
  height: 16px;
  display: flex;
  font-size: 18px;
  align-items: center;
  margin-right: 6px;
`;

function Breadcrumb({label, goTo, is_active, emoji, icon}) {
    return <Crumb onClick={goTo} className='pt-1.5 flex items-center cursor-pointer' is_active={is_active}>
        {emoji && <Emoji>{emoji}</Emoji>}
        {icon && <Icon>{icon}</Icon>}
        <div style={{maxWidth: '200px'}}
             className={`text-gray-500  truncate text-sm hover:text-secondary font-medium pr-2`}>{label}</div>
        {!is_active && <span className={`text-gray-400`}>/</span>}
    </Crumb>
}

function getFinalBreadcrumbs(arr, len) {
    const inner_width = window.innerWidth;
    if (arr.length < 3) {
        return arr;
    } else if (inner_width > 1200 && len < 5) {
        return arr;
    }
    let a = [];
    // show up to 2 on tablet and below, 4 on desktop
    if (inner_width < 1200) {
        a.push(arr[0]);
        a.push({
            label: '...',
            goTo: () => {
            },
            is_active: false,
            emoji: null,
            icon: null
        });
        a.push(arr[len - 1]);
    } else {
        a.push(arr[0]);
        a.push(arr[1]);
        a.push({
            label: '...',
            goTo: () => {
            },
            is_active: false,
            emoji: null,
            icon: null
        });
        a.push(arr[len - 2]);
        a.push(arr[len - 1]);
    }

    return a;
}

function Breadcrumbs({breadcrumbs, history, len}) {

    const fb = getFinalBreadcrumbs(breadcrumbs, len);
    return <div className="flex flex-nowrap space-x-2 whitespace-pre overflow-x-hidden ">
        {fb.map((crumb, i) => {
            const is_active = (i + 1) === len;
            return <Breadcrumb key={`${crumb.path}-${i}`} goTo={() => {
                if (crumb.path !== '#') {
                    history.push(crumb.path);
                }
            }} is_active={is_active} {...crumb} />
        })}
    </div>
}

export function AdvancedEntityHeader({

                                         title = "",
                                         can_change_icon,
                                         layout = "",
                                         breadcrumbs = [],
                                         handleChangeIcon = () => {
                                         },
                                         onTitleClick = null,
                                         notifications,
                                         title_suffix = null,
                                         title_prefix = null,
                                         changeTab = () => {
                                         },
                                         show_icon,
                                         onRename = () => {
                                         },
                                         icon_meta,
                                         right_menu_options = [],
                                         can_edit_title = true,
                                         active_tab = "",
                                         tabs = null,
                                         can_star = false,
                                         show_title = true,
                                         hide_border = false,
                                         star_meta = {},
                                         right_elements = []
                                     }) {
    const history = useHistory();
    const breadcrumbs_len = breadcrumbs.length;
    const is_mobile = useIsMobile();
    const [t, setT] = useState(title);
    const [editing, setEditing] = useState('');

    useEffect(function () {
        setT(title)
    }, [title])

    function handleTitleChange(e) {
        setT(e.target.value);
    }

    const title_selectable = can_edit_title;

    return <div className={`flex items-center h-14 px-4`}>
        <div className={`flex-grow`}>
            {layout === 'breadcrumbs' && <div className="px-0.5 h-6 pb-px">
                <Breadcrumbs len={breadcrumbs_len} breadcrumbs={breadcrumbs} history={history}/>
            </div>}

            <div className={`flex space-x-3 h-8`}>
                {show_icon && <div className="flex-none flex items-center">
                    {can_change_icon && <PhotoSelector type={'emoji'} modes={[{id: 'color', label: 'Color'}, {
                        id: 'emoji',
                        label: 'Emoji'
                    }, {id: 'custom', label: 'Image'}]} setPhoto={(url, color) => {

                        const type = url === 'color' ? 'color' : url === 'emoji' ? 'emoji' : 'url';
                        handleChangeIcon(type, url, color)
                    }}>
                        <div style={{width: '2rem', height: '2rem'}}
                             className="-m-1 p-1 rounded-lg hover:bg-gray-200 cursor-pointer transition-colors">
                            <GroupDisplayIcon size="md" emoji={icon_meta.emoji} profile_picture={icon_meta.image}
                                              profile_picture_color={""} color={icon_meta.color} name={t ? t : ""}/>
                        </div>
                    </PhotoSelector>}
                    {!can_change_icon &&
                    <div style={{width: '2rem', height: '2rem'}}
                         className="-m-1 p-1 rounded-lg hover:bg-gray-200 cursor-pointer transition-colors">
                        <GroupDisplayIcon size="md" emoji={icon_meta.emoji} profile_picture={icon_meta.image}
                                          profile_picture_color={""} color={icon_meta.color} name={t ? t : ""}/>
                    </div>}

                </div>}
                <div className={`flex-grow flex pl-0.5 flex-col items-start justify-center`}>
                    {title && show_title && <div className="flex items-center pt-0.5 space-x-1.5">

                        {can_edit_title && <AEHTitle handleChange={handleTitleChange} t={t} can_edit={can_edit_title}
                                                     doneEditing={() => {
                                                         setEditing('');
                                                         if (t !== title) {
                                                             onRename(t);
                                                         }
                                                     }} editing={editing === 'title'}
                                                     startEditing={() => setEditing('title')}/>}
                        {!can_edit_title && <div style={{maxWidth: '400px'}} onClick={() => {
                            if (onTitleClick) {
                                onTitleClick()
                            }
                        }}
                                                 className={`px-1 inline-flex items-center h-6 pt-0.5 -mx-1 hover:bg-gray-200 cursor-pointer rounded-md transition-colors  `}>
                            {title_prefix && editing !== 'title' && <div className="pr-1">{title_prefix}</div>}
                            {title && <div className={"text-lg text-gray-800 font-black"}>{title}</div>}
                            {title_suffix && editing !== 'title' && <div className="pl-1">{title_suffix}</div>}
                        </div>}

                        <div className="space-x-1 flex items-baseline pb-0.5">
                            {can_star && <div>
                                <MemberStarred type={star_meta.type} type_id={star_meta.type_id}/>
                            </div>}
                            {notifications && <div>
                                {notifications}
                            </div>}
                        </div>
                    </div>}
                    <div className="">
                        {layout === 'tabs' && <div className="flex items-end">
                            <div className=" flex items-end -mb-px -mt-0.5">
                                <Tabs active={active_tab} onSelect={tab => changeTab(tab)} compact
                                      layout="standard-bottom"
                                      tabs={tabs}/>
                            </div>
                        </div>}
                    </div>
                </div>
            </div>
        </div>
        {!is_mobile && <div className="flex-none space-x-0.5 flex items-center h-12">
            {right_elements.length > 0 && <div className="space-x-1.5 flex">
                {right_elements.map((e, p) => {
                    return <div className="h-10 flex items-center" key={`${e.key}-${p}`}>
                        {e.comp}
                    </div>
                })}
            </div>}
            {right_menu_options.length > 0 && <div>
                <Popover2 content={<PopupMenu items={right_menu_options}/>} placement={'bottom-end'} minimal
                          popoverClassName='minimal-popover my-3'>
                    <Button icon_only size="sm" icon={<DotsVerticalIcon/>} minimal/>
                </Popover2>
            </div>}
        </div>}
    </div>
}