import React from 'react';
import {Card,Button,Badge} from "../../../library/components/core";

export function PolicyCard({data,history,community}) {
const {status,name,version,id} = data;
    return <div className="">
        <Card>
            <div className="text-lg font-semibold text-gray-800 mb-2">
            {name}
            </div>
            <div className="mb-4 flex flex-wrap space-x-2">
                {status==='draft'?<Badge text="Draft" />:<Badge color="green" text="Published" />}
                {status==='published'&&<Badge text={`v${version}`} />}
            </div>
            <div>
            <Button onClick={()=>history.push(`/${community.data.handle}/policies/${id}`)} text="View"  />
            </div>
        </Card>
    </div>
}