import React, {useContext, useEffect, useState} from "react";
import {authFetch} from "../../../../../config/network";
import DeleteModal from "../../../../../library/components/old/delete-modal";
import {Button, EntityHeader} from "../../../../../library/components/core";
import {AdminEntityInfo, PageHeader} from "../../../../../library/components/core";
import Field from "../../../../../library/components/old/field";
import EditFieldsSection from "../../../../../library/components/old/edit-fields-section";
import {CommunityContext} from "../../../community-context";
import {GlobalContext} from "../../../../global/global-context";
import {Popover2} from "@blueprintjs/popover2";
import {PopupMenu} from "../../../../../library/components/old/popup-menu";
import {ChangeHandleModal} from "../../role/change-handle";
import {DoubleCheckModal} from "../../../../../library/components/old/double-check-modal";
import {DotsVerticalIcon, PlusIcon} from '@heroicons/react/solid';
import {CIMemberType} from "../../../../../library/icons/custom/MemberType";
import {StandardPageHeight} from "../../../../../library/playground/standard-page-framework";

function buildMTArray(obj, remove_id) {
    let arr = [];

    const keys = Object.keys(obj);

    for (let i = 0; i < keys.length; i++) {
        const id = keys[i];
        if (id !== remove_id) {
            arr.push({
                value: id,
                text: obj[id].plural
            })
        }
    }

    return arr;
}

export default function SettingsMemberType(props) {
    const community = useContext(CommunityContext);
    const global = useContext(GlobalContext);
    const [id, setId] = useState(props.match.params.id);

    const [modal_data, setModalData] = useState(null);
    const [replacement_id, setReplacementId] = useState('');
    const [loading, setLoading] = useState('member-type');
    const [modal, setModal] = useState(null);
    useEffect(function () {
        if (id !== props.match.params.id) {
            setId(props.match.params.id);
        }
    }, [props.match.params.id]);

    function handleDelete() {

        if (loading==='deleting') {
            return;
        }
        setLoading('deleting');
        const res = () => {
            setLoading('');
            global.addToast({text: `Member Type was deleted.`, intent: 'success'});
            const {history} = props;
            history.push(`/${community.data.handle}/admin/settings/member-types`)
        };

        const err = () => {

            global.addToast({text: `Member Type could not be deleted.`, intent: 'danger'});
        };

        const payload = {
            replacement_id: replacement_id,
            community_uid: community.uid,
            id: id, member_id: community.member_id
        };

        authFetch("/member-types/delete", res, err, "POST", {payload})
    }

    function changeHandle(h) {
        setLoading('handle');
        const res = () => {
            setTimeout(() => {
                setLoading('');
                setModal(null);
                setModalData(null);
                global.addToast({text: `Member Type handle changed`, intent: 'success'});
            }, 1000);
        };

        const payload = {
            new_handle: h,
            community_uid: community.uid,
            id: id, member_id: community.member_id
        };

        authFetch("/member-types/change-handle", res, res, "POST", {payload})
    }

    const {history} = props;

    const {member_types} = community;

    const data = member_types[id];

    const number_of_member_types = Object.keys(member_types);

    if (!data) {
        // loading
        return null;
    }

    const {plural, emoji, handle} = data;

    // todo subcomponent to manage a single member type, set state on construct

    const base_link = `/${community.data.handle}/admin/settings`;

    const MTArray = buildMTArray(member_types, id);

    const obj = {
        data: data,

        type: 'member-type',
        id: id,
        endpoint: '/member-types/update',
        update_fields: ['plural', 'singular', 'permissions', 'emoji', 'abbreviation'],

        extra: {
          member_id: community.member_id
        },

        sections: [
            {
                title: 'Details',
                fields: [
                    {
                        field: 'plural',
                        type: 'text',
                        defer: true,
                        name: 'Name'
                    },
                    {
                        field: 'handle',
                        type: 'handle',
                        disabled: true,
                        name: 'Handle'
                    },
                    {
                        name: 'Symbol',
                        type: 'symbol',
                        edit_only: true,
                        defer: true,
                        field: 'emoji'
                    },
                    {
                        name: 'Color',
                        type: 'color',
                        edit_only: true,
                        defer: true,
                        field: 'color'
                    },
                    {
                        field: 'singular',
                        type: 'text',
                        defer: true,
                        name: 'Singular'
                    },
                    {
                        field: 'abbreviation',
                        type: 'text',
                        defer: true,
                        name: 'Abbreviation'
                    }
                ]
            }
        ]
    };

    const hotkeys = [
        {
            id: 'D',
            keys: ["d"],
            fn: () => {
                setModal('double-check-delete')
            }
        }
    ];

    const options = [
        {
            type: 'item',
            title: 'Change Handle',
            onClick: () => {setModal('change-handle');setModalData({handle: data.handle})}
        }
    ];

    return (<StandardPageHeight content full>
            <AdminEntityInfo type_icon={<div className="h-5 w-5 text-gray-600"><CIMemberType /></div>} id={id} type_label={"Member Type"} />

            <EntityHeader title={data.plural} actions={<div className="space-x-2 flex">
                <Button onClick={() => {
                    setModal('double-check-delete')
                }} disabled={number_of_member_types.length === 1} shortcut={'D'} text='Delete'/>

                <Popover2 content={<PopupMenu items={options}/>} placement={'bottom-start'} minimal popoverClassName='minimal-popover'>
                    <Button className='admin-dots-button' icon={<DotsVerticalIcon/>}/>
                </Popover2>
            </div>} goBackTo={()=>history.push(`/${community.data.handle}/admin/settings/member-types`)}  />


                <EditFieldsSection member_types={community.member_types} history={props.history} {...obj} />

                {modal === 'change-handle' &&
                <ChangeHandleModal loading={loading==='handle'} community_uid={community.uid} data={modal_data} onConfirm={(h) => changeHandle(h)}
                                   onClose={() => {
                                       setModal(null);
                                       setModalData(null)
                                   }} onCancel={() => {
                    setModal(null);
                    setModalData(null)
                }} type='member-type'/>}

                {modal==='double-check-delete'&&<DoubleCheckModal onConfirm={()=>setModal('open_delete')} onCancel={()=>setModal(null)} type='member_type_delete' />}

                {modal==='open_delete' && <DeleteModal
                    deleting={loading==='deleting'}
                    can_delete={replacement_id !== ''}
                    content={<>
                        <Field type={"select"} options={MTArray} label="Replacement Member Type" onChange={(v) => {
                            setReplacementId(v);
                        }}
                               value={replacement_id}/>
                    </>}
                    title={`Delete ${data.plural}`}
                    onDelete={handleDelete.bind(this)}
                    onClose={() => {
                        setModal(null)
                    }}/>}
            </StandardPageHeight>
    );
}