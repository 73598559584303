import React from "react";
import {BadgeCheckIcon, QuestionMarkCircleIcon} from "@heroicons/react/solid";
import {NewTooltip} from "../../reorganize/new-tooltip";

export function CommunityVerificationTag({
    verified = true,
    size = "4"
                                         }) {

    if(verified) {
        return <NewTooltip message="Verified Community">
            <div className={`text-primary h-${size} w-${size} hover:opacity-80 transition-opacity`}>
            <BadgeCheckIcon />
        </div>
        </NewTooltip>
    }
    return <NewTooltip message="Community not yet claimed">
        <div className={`text-gray-400 h-${size} w-${size} hover:opacity-80 transition-opacity`}>
            <QuestionMarkCircleIcon />
        </div>
    </NewTooltip>
}