import React from 'react';
import {Button, InfoState, InlineLoader, RichSearch} from "../../core";
import {PaperAirplaneIcon} from "@heroicons/react/solid";
import {ComposerSubjectBlock} from "../subject-block";
import {RichTextEditor} from "../rich-text-editor";
import {ComposerHeader} from "../header";
import {CT_Invite} from "../system-templates/invite";
import {CT_AccessDetails} from "../system-templates/access-details";
import {CT_DataCheck} from "../system-templates/data-check";
export function message_getTypeContent(type,meta) {
    switch (type) {
        case 'invite':
            return <CT_Invite {...meta} />
        case 'access-details':
            return <CT_AccessDetails {...meta} />
        case 'data-check':
            return <CT_DataCheck {...meta} />
        default:
            return null;
    }
}

export function ComposerWriteTab({loading,type,message_type,merge_tags,setEditorState,cc,setTo,setCC,initial_content,selected_template,can_send,handleUpdateAttachments,uploadFilesToFolder,handleSend,folder_id,actions,to,setSendEmail,send_email,setFocusCount,prefill,setSeries,focus_count,initial_action,editing,subject,setEditing,setSubject,mode,community,onClose,editorState}) {
let content, subject_block, series_block, editor_block;
    if (loading === 'sending') {
        content = <div>
            <InlineLoader padding="p-20"/>
        </div>;
    } else if (loading === 'sent') {
        content = <div className="flex items-center justify-center h-full">
            <InfoState button={<Button onClick={() => {
                onClose();
            }} text="Done"/>} title={"TITLE"}
                       intent="success"
                       icon={<PaperAirplaneIcon/>}/>
        </div>;
    } else {
        const content_state = editorState ? editorState.getCurrentContent() : null;
        const plain_text = content_state ? content_state.getPlainText() : "";
        const is_empty = !plain_text;

        subject_block = <div className="flex-none ">
            <ComposerSubjectBlock autoFocus={to.length>0&&!subject} mode={mode} updateSubject={(ss)=>setSubject(ss)} subject={subject} focusEditor={()=>{setFocusCount(focus_count+1)}} />
        </div>;

        editor_block = <div className={`flex-grow text-base `}>
            <RichTextEditor debug={false} auto_focus={is_empty&&subject} merge_tags={merge_tags} initial_action={initial_action} show_add_signature={true} show_tools upload_files_to_folder={uploadFilesToFolder("post")} handleClose={()=>{
                onClose()
            }} mode={mode} action_bar={mode==='compose'} actions={<div>
                {!loading &&
                <Button onClick={() => handleSend()} loading={loading === 'sending'} disabled={!can_send}
                        intent="secondary"
                        text={"ACTION"}/>}

            </div>} updateAttachments={handleUpdateAttachments} folder_id={folder_id} fc={focus_count} content_state={initial_content} placeholder={"Start typing.."} template={selected_template} onChange={(editor_state) => {
                setEditorState(editor_state)
            }}/>
            {message_getTypeContent(type,{community})}
        </div>;

        content = <div className="flex flex-col flex-grow">
            <div className="px-4 pt-2 flex flex-grow flex-col" id="message-content">
                {subject_block}
                {editor_block}
            </div>

        </div>
    }

    return <div className="relative">
        <ComposerHeader
            can_change_to={message_type==='direct'}
            can_change_cc={true}
            to={to} setTo={setTo}
            cc={cc} setCC={setCC}
            community={community}
            handleClose={onClose}
            actions={actions}
            send_email={send_email} setSendEmail={setSendEmail} editing={editing} setEditing={setEditing}
        />
        {content}
    </div>
}