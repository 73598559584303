import React, {useState} from "react";
import styled from "styled-components";
import Field from "../../old/field";
import {DateRange} from "../date-range";

const Wrapper = styled.div`
  width: 100%;
`;

const types = [
    'Full-time',
    'Part-time',
    'Self-employed',
    'Freelance',
    'Seasonal',
    'Contract',
    'Internship',
    'Apprenticeship'
];

const CaStep = {
    title: '',
    current_role: false,

    type: '',
    location: '',

    description: '',

    media: [],

    start_date: {
        month: null,
        year: null
    },

    end_date: {
        month: null,
        year: null
    },

    duration_months: 0,

    org_data: {
        community_uid: '',
        title: '',
        image: '',
        handle: '',
        industry: ''
    }
};

export function AddCareerStep({updatePosition, position}) {
    const {
        title, from_year,
        from_month,
        to_year, location, current,
        to_month, description, org_data
    } = position;

    return <Wrapper>
        <div>
            <Field placeholder='Select Title' taxonomy='position_titles' type='custom-add-select' label="Title"
                   onChange={(v) => {
                       updatePosition('title',v)
                   }} autoFocus
                   value={title}/>
        </div>
        <div>
            <Field placeholder='Select Company' taxonomy='companies' type='custom-add-select' label="Company"
                   onChange={(v) => {
                       updatePosition('org_data',v)
                   }}
                   value={org_data.title}/>
        </div>
        <div>
            <DateRange onChange={(v)=>updatePosition('range',v)} range={{
                current,
                from_year,
                from_month,
                to_year,
                to_month
            }} enable_current_check={true} labels={{start: 'Start date', end: 'End Date'}}
                       current_check_text={`I'm currently working in this role`}
                       type='month-year'/>
        </div>
        <div>
            <Field optional type={"text"} label="Location" onChange={(v) => {
                updatePosition('location',v)
            }}
                   value={location}/>
        </div>
        <div>
            <Field optional type={"textarea"} label="Description" onChange={(v) => {
                updatePosition('description',v)
            }}
                   value={description}/>
        </div>
    </Wrapper>
}