import React from 'react';
import {DetailModal, GeneralDetailItem} from "../playground/detail-modal";
import {ViewMembersListModal} from "./view-members-list-modal";
import {Avatar, Button} from "../components/core";
import {DEFAULT_USER_PHOTO} from "../../config/defaults";

function MemberItem(data) {
    let img, title, subtitle;
    if(data.loading) {
        img = ``;
        title = `-`;
    } else {
        img = `${data.image?data.image:DEFAULT_USER_PHOTO}_small?alt=media`;
        title = `${data.name}`;
        subtitle = `${data.bio?data.bio:"—"}`;
    }
    return <GeneralDetailItem title={title} image={img} subtitle={subtitle} action={null} />
}

export function ViewPostLikes({
    community_uid,
                                  onClose = ()=>{},
                                         member_ids = []
                                     }) {

    const tabs = [
        {
            label: `Likes`,
            type: "member-list",
            community_uid: community_uid,
            ids: member_ids,
            renderer: (data) => {
                return <MemberItem {...data} />
            }
        }
    ];

    const c = member_ids.length;

    return <ViewMembersListModal onClose={onClose} title={`${c} Like${c===1?"":"s"}`}
        tabs={tabs}
    />
}