import {authFetch} from "../../../config/network";

export async function api_createInterest(community,pl) {
    return await new Promise((resolve, reject) => {
        const res = (r) => {
            resolve(r);
        };
        const payload = {
            community_uid: community.uid,
            member_id: community.member_id,
            name: pl.name,
            emoji: pl.emoji,
            profile_picture: pl.profile_picture,
            color: pl.color,

            description: pl.description
        };
        authFetch("/interests/create-custom", res, res, "POST", {payload});
    })
}