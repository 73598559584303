import React from 'react';
import {PopupMenu} from "../../old/popup-menu";
import styled from "styled-components";
import {UnatySVG} from "../../../icons/unaty/logos";
import {useIntl} from "react-intl";
import {Avatar} from "../../core";


const Subtitle = styled.div`
  font-size: 13px;
`;

const UnatyLogo = styled.div`
  padding: 1px;

  > svg {
    height: 32px;
  }
`;

export function SidebarTopMenu({community, user, is_admin, global, goTo, communities, community_data, community_handle}) {
    const {formatMessage: f} = useIntl();

    const header = <div className="grid gap-3 px-3 py-1" style={{gridTemplateColumns:'auto 1fr'}}>
        {community.profile_picture !== '' && !global &&  <Avatar size="34px" type="community" text={community.name} border_color={"#ffffff"}
                                                                 url={community.profile_picture ? `${community.profile_picture}_medium?alt=media` : ""}/>}
        {global && <UnatyLogo className="flex h-full items-center"><UnatySVG/></UnatyLogo>}
        <div className="flex items-start justify-center flex-col ">
            <div className='text-smbase font-bold text-gray-800'>{global ? 'Unaverse' : community.name}</div>
            {community_handle && <div className='text-xs text-gray-700'>@{community_handle}</div>}
        </div>
    </div>;

    let items = [
        {
            type: 'custom',
            component: header,
            onClick: () => {
            }
        },
    ];


    if (!global) {

        items.push({
            type: 'divider'
        });

        if(is_admin) {
            items.push({
                type: 'item',
                title: f({id:`sidebar.settings`}),
                onClick: () => goTo(`/${community_handle}/admin/settings`)
            });
        }
        
        items.push({
            type: 'item',
            title: f({id:`sidebar.drive`}),
            onClick: () => goTo(`/${community_handle}/explore/drive`)
        });

        items.push({
            type: 'item',
            title: f({id:`sidebar.policies`}),
            onClick: () => goTo(`/${community_handle}/policies`)
        });

        items.push({
            type: 'item',
            title: f({id:`sidebar.interests`}),
            onClick: () => goTo(`/${community_handle}/explore/interests`)
        });

    }

    items.push({
        type: 'divider'
    });

    items.push({
        type: 'submenu',
        title: f({id:`sidebar.add_a_community`}),
        items: [
            {
                title: f({id:`sidebar.create_a_community`}),
                type: 'item',
                onClick: () => goTo('/create-community')
            }
        ]
    });
    let final_communities = [];
    for (let i in communities) {
        const id = communities[i];
        const d = community_data[id];
        if(d) {
            if (d.handle !== community_handle) {
                final_communities.push(d);
            }
        }
    }

    if (final_communities.length > 0) {
        items.push({
            type: 'submenu',
            title: f({id:`sidebar.switch_community`}),
            items: final_communities.map(item => {
                return {
                    title: item.name,
                    onClick: () => goTo(`/${item.handle}/home`)
                };
            })
        });
    }

    if (!global) {
        items.push({
            type: 'divider'
        });

        items.push({
            type: 'item',
            title: f({id:`sidebar.back_to_the_unaverse`}),
            onClick: () => goTo(`/memberships`)
        });
    }

    return <PopupMenu items={items}/>;
}