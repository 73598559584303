import React, {useContext, useEffect, useRef, useState} from 'react';
import {FirebaseDB} from "../../../config/setup-firestore";
import {ActionWithIcon, Button} from "../core";
import {ChevronLeftIcon, ChevronRightIcon} from "@heroicons/react/solid";
import {getMemberActivityInboxPosts, getMemberInboxPosts} from "./api";
import {FeedItem} from "./feed-item";
import {CommunityContext} from "../../../app/community/community-context";
import {useIsMobile} from "../../../app/global/global-router";
import {GlobalContext} from "../../../app/global/global-context";
import {api_recordPostImpressions} from "../../../api/community/posts/record-impressions";
import useInfiniteScroll from "../../playground/advanced-table/use-infinite-scroll";
import {InboxPagination} from "./home-wrapper";

export function InboxMemberActivityWrapper({
                                     per_page = 12,
    refresh = 0,
                                               member_id = "",
                                     composer = null
                                 }) {
    const global = useContext(GlobalContext);
    const community = useContext(CommunityContext);
    const community_uid = community.uid;
    const is_mobile = useIsMobile();
    const [inbox, setInbox] = useState(null);
    const [loading, setLoading] = useState(null);
    const [items, setItems] = useState([]);
    const [page, setPage] = useState(0);
    const has_more = useRef(false);
    const total_posts = useRef(0);
    const [last_page_ref, setLastPageRef] = useState(null);

    const scroll_id = "main-content";

    const [isFetching, setIsFetching] = useInfiniteScroll("infinite", scroll_id, {
        padding: 400,
        container: true
    }, () => {
        if (!loading && has_more.current) {
            handleLoadMore();
        }
    });

    function handleNextPage() {
setPage(page+1);
        getPosts(inbox,page+1);
    }

    function handleLoadMore() {
        setPage(page+1);
        setLoading(true);
        getPosts(inbox,page+1, true);
    }

    function handlePrevPage() {
        setPage(page-1);
        getPosts(inbox,page-1);
    }

    function getPosts(inb,pg,concat) {
        getMemberInboxPosts(community_uid,member_id,last_page_ref,pg,inb,per_page,"member-activity")
            .then(result=>{

                has_more.current = (items.length+result.posts.length) < total_posts.current;

                if(concat) {
                    setItems([...items].concat(result.posts));
                } else {
                    setItems(result.posts);
                }
                setLoading(false);
                setLastPageRef(result.last_ref);
                setIsFetching(false);
           //     api_recordPostImpressions(community,result.posts.map(it=>it.id)).then(()=>{});
            })
    }

    useEffect(function () {
        let listener = FirebaseDB
            .collection('community_content')
            .doc(community_uid)
            .collection('inboxes')
            .doc(`member-activity-${member_id}`)
            .onSnapshot((doc) => {
                if (doc.exists) {
                    setInbox(doc.data());
                    total_posts.current = doc.data().total_count;
                    if(page===0) {
                        getPosts(doc.data(),0);
                    }
                } else {
                    setInbox({
                        unread: 0,
                        total_count: 0,
                        last_activity: null
                    });
                }
            })

        return () => listener();
    }, []);

    const final_items = !items ? [] : items.map(it=>{
        let obj = {...it};
        if(!obj.author_image) {
            obj.author_image = community.default_member_profile_picture;
        }
        return obj;
    })

    const item_actions = {
        openFileViewer: global.openFileViewer,
        openImageViewer: global.openImageViewer,
    }
    return <>
        {composer&&<div className="flex items-end">
            <div className="flex-grow">{composer}</div>
            <div>

            </div>
        </div>}
        <div className="">
            {inbox&&final_items.map(it=>{
                return <FeedItem item_actions={item_actions} activity_meta={{community:community}} is_mobile={is_mobile} page_ref="home" community_handle={community.data.handle} community_uid={community.uid} id={it.id} formatted_date={it.formatted_date} read={it.seen} key={it.id} {...it.data} />
            })}
            {(!inbox||(inbox.total_count>0&&final_items.length===0))&&<>
                <FeedItem key="1" loading />
                <FeedItem key="2" loading />
                <FeedItem key="3" loading />
            </>}
            {loading&&<>
                <FeedItem key="5" loading />
                <FeedItem key="4" loading />
            </>}
            {inbox&&final_items.length>0&&<InboxPagination loadMore={handleLoadMore} onNextPage={handleNextPage} onPrevPage={handlePrevPage} total={inbox.total_count} start={page*per_page} />}
            {inbox&&final_items.length===0&&inbox.total_count===0&&<div className="p-6 text-gray-500 text-sm text-center">
                No messages found
            </div>}
        </div>
    </>
}