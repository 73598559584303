import React, {Component} from "react"
import styled from "styled-components";
import Modal from "../modal";
import {Button} from "../../core";


const Body = styled.div`
min-height: 32px;
`;

export default class DeleteModal extends Component {
    state = {};

    render() {
        const { onClose, text='Delete', intent='danger', title, usePortal=true, deleting=false, content=null, can_delete=true, confirm_text='Delete', onDelete } = this.props;

        const modal_footer = <>
            <div className='left'>

            </div>
            <div style={{display: 'flex'}}>
                <Button text={'Cancel'} disabled={deleting} onClick={onClose}/>
                <div style={{marginLeft: '12px'}}>
                    <Button disabled={!can_delete} text={text}
                              intent={intent} loading={deleting}
                              onClick={onDelete}/>
                </div>
            </div>
        </>;
        const modal_hidden = null;

        const body = <Body>
            {content}
        </Body>;

        return <Modal usePortal={usePortal} title={title} size='small' body={body} footerRight={modal_footer} footerLeft={modal_hidden}
                        onClose={onClose} open={true} />
    }
}