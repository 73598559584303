import React, {useState} from 'react';
import styled from "styled-components";
import {PhotoPortal} from "../../../../app/community/home/PhotoPortal";
import {getBkgColor, getLargeImage} from "../../old/profile-frame/profile/profile-picture";
import {PhotoSelector} from "../../old/photo-selector";
import {Avatar, InlineLoader} from "../../core";
import {DEFAULT_COMMUNITY_LOGO, DEFAULT_USER_PHOTO} from "../../../../config/defaults";

function getSize(type) {
    const width = window.outerWidth;

    switch (type) {
        case 'community':
            if(width<640) {
                return '96px'
            }
            return '110px'
        default :
            if(width<640) {
                return '96px'
            }
            return '138px';
    }
}

const ProfilePicture = styled.div`
   
    z-index: 2;
       
    height: ${props=>getSize(props.type)};
    width: ${props=>getSize(props.type)};
        
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
`;

const Loader = styled.div`
position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 42%;
    background-color: rgba(0,0,0,0.5);
    display: flex;
    align-items: center;
    justify-content: center;
`;

function getBottom(type) {
    const width = window.outerWidth;

    switch (type) {
        case 'community':
            return '-40px'
        default :
            if(width<640) {
                return '-48px'
            }
            return '-56px';
    }
}

const Wrapper = styled.div`
    position: absolute;
    z-index: 15;
    bottom: ${props=>getBottom(props.type)};
    left: ${props=>props.left};
    height: ${props=>getSize(props.type)};
    width: ${props=>getSize(props.type)};
    > div {
     position: relative;
    }
`;

const GroupWrapper = styled.div`
    position: absolute;
    z-index: 15;
    bottom: -20px;
    left: 0.75rem;
    height: 70px;
    width: 70px;
    > div {
     position: relative;
    }
`;


const Image = styled.div`
        position: absolute;
    top: 0;
    left: 0;
     background-position: center;
    background-size: cover;
    right: 0;
    bottom: 0;
`;

function getProfilePicture(url,type) {
    let base = url ? url : (type==='community'?DEFAULT_COMMUNITY_LOGO:DEFAULT_USER_PHOTO);
   return `${base}_medium?alt=media`
}

export function PCProfilePicture({data, loading, editing, setProfilePicture, can_manage}) {
    const [open, setOpen] = useState(false);
    let pp;

    const width = window.outerWidth;
const use_type = data.type==='community'?'community':'member';
const rounding = use_type==='community'?"rounded-md":"rounded-42";
// todo these should be settings
    if(data.type!=='group'&&data.type!=='interest') {
        pp = <Wrapper left={width<640?"0.75rem":"0.75rem"} type={data.type}>
            <div>
                <ProfilePicture type={data.type}>
                     <Image type={data.type} className={`${rounding}`} onClick={(e)=> {
                         if(e&&e.target&&e.target.className&&e.target.className.indexOf&&e.target.className.indexOf(rounding)>-1) {
                             setOpen(true)
                         }
                     }} image={getProfilePicture(data.appearance.profile_picture,data.type)}
                           color={data.appearance.profile_picture_color}>
                         {can_manage&&<PhotoSelector type='profile-picture' setPhoto={setProfilePicture}>
                         <Avatar onClick={()=>{}} type={use_type} image_styles={{border:'4px solid #ffffff'}} size={getSize(use_type)} show_edit={can_manage} url={getProfilePicture(data.appearance.profile_picture,data.type)} />
                         </PhotoSelector>}
                         {!can_manage&& <Avatar onClick={()=>{}} type={use_type} image_styles={{border:'4px solid #ffffff'}} size={getSize(use_type)} show_edit={can_manage} url={getProfilePicture(data.appearance.profile_picture,data.type)} />}
                     </Image>

                    {loading && <Loader><InlineLoader /></Loader>}
                </ProfilePicture>

            </div>
        </Wrapper>;
    } else {
        pp = <GroupWrapper className="border-3 rounded-xl border-white">
                <Avatar type="group" size="2xl" url={`${data.appearance.profile_picture}_medium?alt=media`} />

        </GroupWrapper>;
    }

    return <>
        {pp}
        {open &&
        <PhotoPortal raw_color={data.appearance.profile_picture_color?data.appearance.profile_picture_color:"#7f828e"} bkg_color={getBkgColor(data.appearance.profile_picture_color)} images={getLargeImage(data.appearance.profile_picture)}
                     onClose={() => {
                         setOpen(false)
                     }}/>}
    </>
}