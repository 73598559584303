import React, {Component} from 'react';
import styled from "styled-components";
import {TableCheckbox} from "../table-checkbox";
const CheckboxesFrame = styled.div`
display: flex;
    align-items: baseline;
    padding-left: 6px;
    padding-top: 10px;
`;

export const CheckboxCell = (props) => {
    return <CheckboxesFrame>
        <TableCheckbox {...props} />
    </CheckboxesFrame>
};