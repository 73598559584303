import React, {useContext, useState} from 'react';
import {ATPopoverContents} from "../components/shared";
import styled from "styled-components";
import {date_in_last_options, fields, operators} from "../../../../app/community/reorganize/filtering-data";
import {at_getDefaultValueFromOperator} from "./edit-filter";
import {Button} from "../../core";
import {RulesItem} from "../../rules/rules-item";
import Select from "../../old/select";
import {PlusCircleIcon, TrashIcon} from "@heroicons/react/solid";
import {CommunityContext} from "../../../../app/community/community-context";
import {BasicSelect} from "../../core/basic-select";
import InputField from "../../old/input";

function getInit(item) {
    return {field: item.field, operator: operators[item.type][0].value, value: '', modifier: ''};
}

const AddGrid = styled.div`
  padding: 10px;
  display: grid;
  grid-template-columns: 1fr auto;

  > div {
    display: flex;
    align-items: center;

    > div {
      width: 100%;
    }
  }
`;

const ANDOR = styled.div`
  line-height: 32px;
  font-weight: 600;
  padding: 0 8px;
  font-size: 13px;
  letter-spacing: 0.5px;
  color: #357ebf;
`;


const Item = styled.div`
  display: grid;
  grid-template-columns: ${props => props.match ? 'auto' : ''} 1fr 0.8fr 1.3fr auto !important;
  grid-gap: 12px;
`;

const Operator = styled.div`

`;

const Value = styled.div`

  > div > input {
    padding: 6px 8px 6px 8px !important;
    height: 32px !important;
  }

  div[class$="-control"], div > input {
    min-height: 40px;
  }

  div > input[id^="react-select-"] {
    min-height: 30px !important;
  }
`;

const Action = styled.div`
  margin-left: 6px;
  height: 28px;
  width: 28px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;

  > svg {
    height: 20px;
  }
`;


const IsBetween = styled.div`
  display: grid;
  grid-gap: 8px;
  grid-template-columns: 1fr 1fr;
`;

function getLabel(value, opts) {
    const it = opts.filter(obj => {
        return obj.value === value
    })[0];
    return it ? it.label : '';
}

function getValue(value, is_multi, opts, flag) {
    if (!value && (value !== '' && !flag)) {
        return is_multi ? [] : null;
    }
    if (is_multi) {
        let keys = Object.keys(value);
        return keys.map(id => {
            return {
                value: id,
                label: getLabel(id, opts)
            }
        })
    } else {
        return {
            value,
            label: getLabel(value, opts)
        }
    }
}

function convertValuesToObj(arr = []) {
    let obj = {};
    for (let i = 0; i < arr.length; i++) {
        obj[arr[i].value] = true;
    }
    return obj;
}

function buildOptions(mts) {
    return Object.entries(mts).map(([id, mt]) => {
        return {
            label: mt.plural,
            value: id
        }
    });
}

function getOpts(base, custom_field) {
    return [{
        value: '',
        label: 'Blank'
    }, ...base]
}

const account_status_levels = {
    0: "missing-email", // is anonymous account
    1: "archived", // archived
    2: "not-invited", // never sent an invite and never logged in
    3: "invite-sent", // sent an invitation but not logged in
    4: "logged-in", // has logged in once,
    5: "suspended" // member has been suspended
}

export const account_status_filtering_options = [
    {
        label: "Logged in",
        value: "logged-in"
    },
    {
        label: "Invite Sent",
        value: "invite-sent"
    },
    {
        label: "Not Invited",
        value: "not-invited"
    },
    {
        label: "Anonymous",
        value: "missing-email"
    },
    {
        label: "Deactivated",
        value: "archived"
    },
];

export const data_quality_filtering_options = [
    {
        label: "Never updated",
        value: "never-updated"
    },
    {
        label: "Outdated",
        value: "outdated"
    },
    {
        label: "Incomplete",
        value: "incomplete"
    },
    {
        label: "Perfect",
        value: "perfect"
    },
    {
        label: "Unknown",
        value: "unknown"
    },
];

function ConditionValue({type, autoFocus, value, operator, custom_field = false, field_data, onChange, cid}) {
    const community = useContext(CommunityContext);
    let is_multi;
    if (operator === 'empty' || operator === 'not_empty') {
        return null;
    }
    switch (true) {
        case type === 'member_type':
            is_multi = operator === 'in' || operator === 'not-in';
            const options = buildOptions(community.member_types);
            return <BasicSelect menuPortalTarget={document.body} multi={is_multi} filter autofocus={autoFocus}
                                clearable={false} searchable
                                value={getValue(value, is_multi, options, true)} field='value'
                                onChange={(val) => {
                                    if (is_multi) {
                                        onChange('value', convertValuesToObj(val));
                                    } else {
                                        onChange('value', val.value);
                                    }

                                }}
                                options={options}/>;
        case type === 'account_status':
            is_multi = operator === 'in' || operator === 'not-in';
            const options2 = [...account_status_filtering_options];
            return <BasicSelect menuPortalTarget={document.body} multi={is_multi} filter autofocus={autoFocus}
                                clearable={false} searchable
                                value={getValue(value, is_multi, options2, true)} field='value'
                                onChange={(val) => {
                                    if (is_multi) {
                                        onChange('value', convertValuesToObj(val));
                                    } else {
                                        onChange('value', val.value);
                                    }

                                }}
                                options={options2}/>;
        case type === 'data_integrity':
            is_multi = operator === 'in' || operator === 'not-in';
            const options3 = [...data_quality_filtering_options];
            return <BasicSelect menuPortalTarget={document.body} multi={is_multi} filter autofocus={autoFocus}
                                clearable={false} searchable
                                value={getValue(value, is_multi, options3, true)} field='value'
                                onChange={(val) => {
                                    if (is_multi) {
                                        onChange('value', convertValuesToObj(val));
                                    } else {
                                        onChange('value', val.value);
                                    }

                                }}
                                options={options3}/>;
        case type === 'select':
            is_multi = operator === 'in' || operator === 'not-in';
            const opts = getOpts(field_data.options, custom_field);
            const val = getValue(value ? value : '', is_multi, opts);
            return <BasicSelect multi={is_multi} filter autofocus={autoFocus} clearable={false} searchable
                                placeholder="Select"
                                value={val} field='value'
                                onChange={(val) => {
                                    if (is_multi) {
                                        onChange('value', convertValuesToObj(val ? val : []));
                                    } else {
                                        onChange('value', val.value);
                                    }

                                }}
                                options={opts}/>;
        case type === 'boolean':
            return null;
        case type === 'number' && operator === 'is_between':
            return <IsBetween>
                <Value>
                    <InputField autoFocus={autoFocus} type="number" placeholder="Number" value={value[0]}
                                onChange={(val) => {
                                    let nv = value;
                                    nv[0] = val;
                                    onChange('value', nv);
                                }}/>
                </Value>
                <Value>
                    <InputField autoFocus={autoFocus} type="number" placeholder="Number" value={value[1]}
                                onChange={(val) => {
                                    let nv = value;
                                    nv[1] = val;
                                    onChange('value', nv);
                                }}/>
                </Value>
            </IsBetween>;
        case type === 'is_between' && type === 'date':
            return <IsBetween>
                <Value>
                    <InputField autoFocus={autoFocus} type="date" value={value[0]} onChange={(val) => {
                        let nv = value;
                        nv[0] = val;
                        onChange('value', nv);
                    }}/>
                </Value>
                <Value>
                    <InputField autoFocus={autoFocus} type="date" value={value[1]} onChange={(val) => {
                        let nv = value;
                        nv[1] = val;
                        onChange('value', nv);
                    }}/>
                </Value>
            </IsBetween>;
        case type === 'date':
            if (operator === 'in_last') {
                const sp = value.split('-');
                const num = parseInt(sp[0]);
                const opts = [...date_in_last_options];
                const val = getValue(sp[1] ? sp[1] : 'days', false, opts);
                return <div className="grid gap-2 grid-cols-3">
                    <div className="">
                        <InputField onChange={(v2) => {
                            onChange('value', `${v2}-${val.value}`);
                        }} min={0} max={96} value={num} type="number"/>
                    </div>
                    <div className="col-span-2">
                        <BasicSelect multi={false} filter autofocus={autoFocus} clearable={false} searchable
                                     placeholder="Select"
                                     value={val} field='value'
                                     onChange={(v3) => {
                                         onChange('value', `${num}-${v3.value}`);
                                     }}
                                     options={opts}/>
                    </div>
                </div>
            }
            return <Value>
                <InputField autoFocus={autoFocus} type="date" value={value} onChange={(val) => onChange('value', val)}/>
            </Value>;
        case type === 'number':
            return <Value>
                <InputField autoFocus={autoFocus} type="number" placeholder="Number" value={value}
                            onChange={(val) => onChange('value', val)}/>
            </Value>;
        default:
            return <Value>
                <InputField autoFocus={autoFocus} type="text" value={value} onChange={(val) => onChange('value', val)}/>
            </Value>;
    }
}

function getOperators(field, field_data) {
    return operators[field_data.type]
}

function Condition({
                       field,
                       autoFocus,
                       can_change_type = true,
                       avail_fields,
                       addFilter,
                       field_data,
                       cid,
                       can_add,
                       removeFilter,
                       options,
                       num,
                       match,
                       operator,
                       value,
                       onChange
                   }) {

    if (!field_data) {
        return null;
    }

    let use_fields = avail_fields ? avail_fields : fields;

    const opts = getOperators(field, field_data);

    return <Item match={num !== 0}>
        <ANDOR style={{display: num !== 0 ? '' : 'none'}}>{match === 'any' ? 'OR' : 'AND'}</ANDOR>
        <div>
            <Select disabled={!can_change_type} usePortal blank_option={false} value={field} field='field'
                    onChange={onChange} options={use_fields}/>
        </div>
        <Operator>
            <Select usePortal blank_option={false} value={operator} field='operator' onChange={onChange}
                    options={opts}/>
        </Operator>

        <Value>
            <ConditionValue autoFocus={autoFocus} cid={cid} custom_field={field.indexOf('custom_fields.') !== -1}
                            operator={operator} field_data={field_data} type={field_data.type} value={value}
                            onChange={onChange}/>
        </Value>
        <div className="flex space-x-2">
            {can_add && <Action className='hover-bkg text-gray-700' onClick={() => addFilter((num + 1))}>
                <PlusCircleIcon/>
            </Action>}
            {removeFilter && <Action className='hover-bkg text-gray-700' onClick={() => removeFilter((num))}>
                <TrashIcon/>
            </Action>}
        </div>
    </Item>
}

const AddSimpleFilter = styled.div``;

export function AFAddNewFilter({context, autoFocus, can_change_type, filter_options, handleChange, data}) {

    const field_data = filter_options.filter(obj => {
        return obj.field === data.field
    })[0];

    return <AddSimpleFilter>
        <Condition autoFocus={autoFocus} avail_fields={filter_options} cid={context.community_uid} can_add={false}
                   match={'all'}
                   addFilter={() => {
                   }} num={0} can_change_type={can_change_type}
                   onChange={(f, val) => handleChange(f, val)} {...data} field_data={field_data}/>
    </AddSimpleFilter>
}

const is_select_archetype = (type) => type === 'member_type' || type === 'data_integrity' || type === 'account_status' || type === 'multi-select' || type === 'country' || type === 'select' || type === 'multi-select-taxonomy' || type === 'select-taxonomy';

function isEmpty(filter, def) {
    if (!def) {
        return true;
    }
    const type = def.type;
    if (filter.operator === 'empty' || filter.operator === 'not_empty') {
        return false;
    }
    if (is_select_archetype(type)) {
        if (filter.operator === 'in' || filter.operator === 'not-in') {
            const k = Object.keys(filter.value);
            return k.length === 0;
        } else {
            return filter.value === ''
        }
    } else if (type === 'text') {

    }
    return filter.value === ''
}

export function ATAddFilter({handleAddFilter, definitions, filter_options, context}) {
    const [new_filter, setNewFilter] = useState(getInit(filter_options[0]));
    const filter_defs = filter_options.filter(a => a.field === new_filter.field)[0];

    function handleChange(f, val) {
        let filter = new_filter;
        filter[f] = val;
        const type = filter_options.filter(obj => {
            return obj.field === filter.field
        })[0].type;
        if (f === 'field') {
            filter['operator'] = operators[type][0].value;
        }
        if (f === 'field' || f === 'operator') {
            filter['value'] = '';
            filter['value'] = at_getDefaultValueFromOperator(filter['operator'], type);
        }
        setNewFilter({...filter});
    }

    return <ATPopoverContents size='xxl'>
        <AddGrid>
            <AFAddNewFilter filter_options={filter_options} context={context} handleChange={handleChange.bind(this)}
                            data={new_filter}/>
            <div>
                <Button onClick={() => handleAddFilter(new_filter)} disabled={isEmpty(new_filter, filter_defs)}
                        intent='secondary' text='Add'/>
            </div>
        </AddGrid>
    </ATPopoverContents>
}