import React, {Component, useState} from "react";

import {NewEntitySelect} from "../../../library/components/reorganize/entity-select";

export function getPassEntities(entities, filters) {
    let to_return = entities;

    to_return.filters = [];

    for (let property in filters) {
        if (filters.hasOwnProperty(property)) {
            // Do things here
            // property === member_type
            // for each item in array
            const item = filters[property];
            for (let i = 0; i < item.length; i++) {
                const it = item[i];
                to_return.filters.push({
                    field: property,
                    value: it.value,
                    modifier: it.modifier,
                    operator: it.operator
                })
            }
        }
    }

    return to_return;
}

// todo cleanup
export default class EntitiesFilters extends Component {
    constructor(props) {
        super(props);

        this.state = {
            entities: {
                members: {},
                roles: {},
                member_types: {},
                teams: {},
                groups: {},
                filters: {}
            },
        };
    }

    notifyParent() {
        this.props.updateEF(this.state);
    }

    render() {
        const {
            entities
        } = this.state;
        const {_existing} = this.props;

        return <NewEntitySelect hide_filter meta={{}} searchable={{members: true, roles: true, member_types: true}}
                                 context={{community_uid:this.props.community_uid,is_admin:this.props.is_admin}} onChange={(f,val)=>{
                                     this.setState({
                                         [f]: val
                                     },()=>{this.notifyParent()});
                }} value={this.state} _existing={_existing} />
    }
}