import React, {useState} from "react";
import InputField from "../../library/components/old/input";
import {Button} from "../../library/components/core";
import styled from "styled-components";
import {authFetch} from "../../config/network";

const Wrapper = styled.div`
 padding: 12px;
`;

function CustomField({type,value,id,onChange}) {
    switch (type) {
        case 'community_uid' : {
            return <div>
CHOOSE COMMUNITY
            </div>
        }
        case 'logo' : {
            return <div>
ADD LOGO
            </div>
        }
        case 'string' : {
            return <div>
                <InputField type="text" placeholder='Label: Javascript' value={value} onChange={(val)=>{onChange(val);}} />
            </div>
        }
        default : {
            return null;
        }
    }
}

function getCustomFields(fields) {
    let obj = {};
    const keys = Object.keys(fields);

    for(let i=0;i<keys.length;i++) {
        obj[keys[i]] = '';
    }

    return obj;
}

export function AddTaxonomyOption({taxonomy_id,fields}) {
    const [label, setLabel] = useState('');
    const [loading, setLoading] = useState(false);
    const [field_data, setFieldData] = useState({});

    function create() {
        setLoading(true);
        setLabel('');
        const res = () => {
            setLoading(false);
        };
        const payload = {
            taxonomy_id,
            option_label: label,
            option_categories: {},
            field_data: getCustomFields(fields)
        };

        authFetch("/taxonomies/create-option", res, res, "POST", payload);
    }

    return <Wrapper>
        <div style={{marginBottom: '6px'}}>
<div style={{marginBottom: '6px'}}>New Option</div>
        <InputField type="text" placeholder='Label: Javascript' value={label} onChange={(val)=>{setLabel(val);}} />
        </div>
        <div>
            <div>Custom Fields</div>
            <div>
                {Object.entries(fields).map((f,i)=>{
                    if(f[0]==='search_name'||f[0]==='label'||f[0]==='count') {
                        return null;
                    }
                    return <div key={i}>
                        <div>{f[0]}</div>
                        <div>
                            <CustomField onChange={(val)=>{
                                let data = field_data;
                                data[f[0]] = val;
                                setFieldData(val);
                            }} type={f[1]} id={f[0]} value={field_data[f[0]]} />
                        </div>
                    </div>
                })}
            </div>
        </div>
        <div>
            <Button disabled={label===''} intent='primary' onClick={()=>{create()}} loading={loading} text='Add Option' />
        </div>
    </Wrapper>
}