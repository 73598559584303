import React, {useContext} from "react";
import {CommunityContext} from "../../community-context";
import {useIntl} from "react-intl";
import {Field} from "../../../../library/components/core";
import {TimezoneSwitcher} from "../../../../library/components/timezone-switcher";
import {getInputByType} from "../../../../library/components/old/field";
import {authFetch} from "../../../../config/network";

function getValues(member) {
    let timezone, set_tz_automatically, language, week_starts_on, region, time_format_24;

    set_tz_automatically = !!member.preferences.set_tz_automatically;
    timezone = member.preferences.timezone ? member.preferences.timezone : "America/Chicago";
    language = member.preferences.language ? member.preferences.language : "en";
    region = member.preferences.region ? member.preferences.region : "us";
    week_starts_on = member.preferences.week_starts_on ? member.preferences.week_starts_on : "monday";
    time_format_24 = member.preferences.time_format_24 ? member.preferences.time_format_24 : false;

    return {
        set_tz_automatically, timezone, language, week_starts_on, region, time_format_24
    };
}

export default function MemberPrefsTimeDate(props) {
    const community = useContext(CommunityContext);
    const {formatMessage: f} = useIntl();
    const {history} = props;

    const all_values = getValues(community.member);

    const {time_format_24,set_tz_automatically,language,week_starts_on,region,timezone} = all_values;

    if (!community.member.account_email) {
        // loading
        return null;
    }

    function saveChanges(av) {
        const res = async () => {

        };
        const payload = {
            community_uid: community.uid,
            member_id: community.member_id,
            id: community.member_id,
            ...av
        };
        authFetch("/members/update-timedate", res, res, "POST", {payload});
    }

    function change(f,v) {
        console.log('change',f,v)
        const nv = {
            preferences: {
                ...all_values,
                [f.split('preferences.')[1]]: v
            }
        }
        community.updateMember(nv);
        saveChanges(nv);
    }

    return <div className="space-y-3">

        <Field label="Time zone" help_text={"Unaty uses your time zone to display times in the feed and for sending summary emails."}>
            <div className="pb-1">
            <TimezoneSwitcher onChange={(ntz,sa)=>{
                const nv = {
                    preferences: {
                        ...all_values,
                        timezone: ntz,
                        set_tz_automatically: sa
                    }
                };
                community.updateMember(nv)
                saveChanges(nv);
            }} value={timezone} can_set_automatically set_tz_automatically={set_tz_automatically} />
            </div>
        </Field>
        <Field label="Language">
            {getInputByType("language", (val) => {
                change("language", val)
            }, () => {

            }, language, {},
                "language")}
        </Field>

        <Field label="Region">
            {getInputByType("country", (val) => {
                change("preferences.region", val)
            }, () => {

            }, region, {},
                "preferences.region")}

        </Field>

        <Field label="24 Hour Time">
            {getInputByType("switch", (val) => {
                    change("preferences.time_format_24", val)
                }, () => {

                }, time_format_24, {},
                "preferences.time_format_24")}
        </Field>

        <Field label="Week starts on">
            {getInputByType("week-starts-on", (val) => {
                    change("preferences.week_starts_on", val)
                }, () => {

                }, week_starts_on, {},
                "preferences.week_starts_on")}
        </Field>

    </div>
}