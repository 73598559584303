import {FirebaseDB} from "../../../config/setup-firestore";

export async function api_getSeries(community_uid,series_id) {
    return await FirebaseDB
        .collection('community_content')
        .doc(community_uid)
        .collection('series')
        .doc(series_id)
        .get()
        .then(doc=> {
           return {
               ...doc.data(),
               id: doc.id
           }
        })
}