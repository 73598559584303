import React, {useState} from "react";
import styled from "styled-components";
import {SplashContainer, SplashInner} from "../splash-container";

const Footer = styled.div`
    padding: 96px 0 16px 0;
    font-size: 14px;
    color: grey;
`;

export default function LandingFooter(props) {

    return (
        <SplashContainer>
            <SplashInner>
        <Footer>
            &copy; {new Date().getFullYear()} Unaty Technologies, Inc
        </Footer>
            </SplashInner>
        </SplashContainer>
    );
}