import React from "react";
import {XIcon} from "@heroicons/react/solid";

function getPosition(layout) {
    if(layout==='right-image') {
        return 'bottom-9 right-60';
    }
    return 'top-0 right-4';
}

function getPositionOn(layout) {
    if(layout==='right-image') {
        return 'top-4 right-4';
    }
    return 'top-0 right-4';
}

export function OnboardingHelpToggle({on=false,layout='standard',onClick=()=>{}}) {
const pos = getPosition(layout);
    if(on) {
        const pos_on = getPositionOn(layout);
        return <div onClick={onClick} className={`absolute z-99 ${pos_on} hover:opacity-70 cursor-pointer transition-opacity  w-6 h-6 text-sm flex items-center justify-center rounded-full bg-gray-100`}>
            <div className="w-4 h-4 text-gray-500">
                <XIcon />
            </div>
        </div>
    }

    return <div onClick={onClick} className={`absolute z-99 ${pos} hover:opacity-70 cursor-pointer transition-opacity w-6 h-6 text-sm flex items-center justify-center rounded-full bg-blue-100 text-blue-500 font-bold`}>
        ?
    </div>
}

function getPositionOnSec(layout) {
    if(layout==='right-image') {
        return 'top-0 right-0';
    }
    return '-top-4';
}

export function OnboardingHelpSection({comp,layout='standard'}) {
    return <div className={`absolute pt-10 px-8 pb-8 ${getPositionOnSec(layout)} right-0 bg-white left-0 bottom-0`}>
        <div className="prose leading-relaxed text-gray-800">
        {comp}
        </div>
    </div>
}