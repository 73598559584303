import styled from "styled-components";
import {SearchIcon, XIcon} from "@heroicons/react/solid";
import {components} from "react-select";
import classNames from "classnames";
import {Avatar} from "../../core";
import React from "react";
import {Popover2} from "@blueprintjs/popover2";
import {ESMembersList} from "../../reorganize/entity-select/item";

export const AdvancedSelectFormatGroupLabel = data => (
    <div className="flex pb-0.5 pt-0.5">
        <span className="uppercase font-sm font-semibold tracking-wide text-gray-600">{data.label}</span>
        <span/>
    </div>
);

const IconBox = styled.div`

  > svg {
    height: 20px;
  }
`;

const IconBoxMini = styled.div`

  > svg {
    height: 20px;
  }
`;


const DropdownBox = styled.span`
  padding-right: 12px;

  > svg {
    height: 18px;
  }
`;

const ClearBox = styled.div`
  padding: 0;

  > svg {
    height: 20px;
  }
`;

const ClearIndicator = props => {
    const {
        children = <XIcon/>,
        getStyles,
        innerProps: {ref, ...restInnerProps},
    } = props;
    return (
        <div
            {...restInnerProps}
            ref={ref}
            style={getStyles('clearIndicator', props)}
        >
            <ClearBox className="text-gray-500 hover:text-gray-800"><XIcon/></ClearBox>
        </div>
    );
};

const DropdownIndicator = ({innerProps}) => {
    return <DropdownBox className='text-gray-500' {...innerProps}>
        <SearchIcon/>
    </DropdownBox>
};

const IndicatorSeparator = ({innerProps}) => {
    return <span className="border-r border-solid border-gray-200 h-6 mr-2 inline-block" {...innerProps} />;
};

const ControlComponent = props => (
    <components.Control {...props} />
);

const SelectContainer = ({children, ...props}) => {
    return (
        <components.SelectContainer {...props}>
            {children}
        </components.SelectContainer>
    );
};

const SingleValue = props => {
    const val = props.getValue()[0];
    return <div className="flex">
        <div className="flex-none w-6 -ml-1">
            {val.icon && <IconBox className={classNames("h-6 w-6 flex items-center justify-center text-gray-500")}
                                  color={val.color}>{val.icon}</IconBox>}
            {!val.icon && <Avatar url={val.image ? `${val.image}_small?alt=media` : ''} text={val.label} size="xs"/>}
        </div>
        <div className="flex-1 pl-1.5">
            <components.SingleValue {...props}/>
        </div>
    </div>
};

const SingleValueSimple = props => {
    const val = props.getValue()[0];
    return <div className="flex">
        <div className="flex-1">
            <components.SingleValue {...props}/>
        </div>
    </div>
};

const Option = props => {
    const data = props.data;
    // props.isFocused
    return <div onClick={() => props.selectOption(data)}
                className={classNames("relative flex px-3 py-1.5 transition-colors", props.isDisabled ? "cursor-not-allowed opacity-50" : "cursor-pointer", props.isFocused ? "bg-selection" : "bg-white hover:bg-gray-100")}>
        <div className="flex-none w-6" style={{height: '24px'}}>
            {!data.icon && !data.simple &&
            <Avatar url={data.image ? `${data.image}_small?alt=media` : ""} text={data.label} size="xs"/>}

            {!data.image && data.icon && !data.simple && <IconBoxMini
                className={classNames("h-6 w-6 flex items-center justify-center ", props.isFocused ? "text-gray-200" : "text-gray-700")}>
                {data.icon}
            </IconBoxMini>}
        </div>
        <div className="flex-1 items-center flex px-2.5">
            <components.Option {...props} getStyles={() => {
                return {}
            }} children={<span className="">
                               <span
                                   className={classNames("text-sm font-semibold", props.isFocused ? "text-white" : "text-gray-800")}>{data.label}</span>
                {data.desc &&
                <span
                    className={classNames("text-sm pl-3", props.isFocused ? "text-gray-200 font-medium" : "text-gray-400")}>{data.desc}</span>}</span>}/>
        </div>
        <div className="flex-none">

        </div>
    </div>
};

const OptionSimple = props => {
    const data = props.data;
    // props.isFocused
    return <div onClick={() => props.selectOption(data)}
                className={classNames("relative flex px-3 py-1.5 transition-colors", props.isDisabled ? "cursor-not-allowed opacity-50" : "cursor-pointer", props.isFocused ? "bg-selection" : "bg-white hover:bg-gray-100")}>
        <div className="flex-1 items-center flex pl-1.5">
            <components.Option {...props} getStyles={() => {
                return {}
            }} children={<span className="">
                               <span
                                   className={classNames("text-sm font-semibold", props.isFocused ? "text-white" : "text-gray-800")}>{data.label}</span>
                {data.desc &&
                <span
                    className={classNames("text-sm pl-3", props.isFocused ? "text-gray-200 font-medium" : "text-gray-400")}>{data.desc}</span>}</span>}/>
        </div>
        <div className="flex-none">

        </div>
    </div>
};


const MultiValueContainer = props => {
    const data = props.data;
    if(data.popup) {
        const content = <div className="w-72">
            <ESMembersList padded type={data.popup.type} id={data.popup.id} cid={data.popup.cid} data={data.popup.data} />
        </div>
        return <div className="inline-block">
            <Popover2 portalClassName="z-9999" usePortal={true} placement='bottom-start' content={content} interactionKind='hover' minimal
                      popoverClassName='z-9999 minimal-popover mt-1 popup-box-shadow'>
            <components.MultiValueContainer {...props} />
            </Popover2>
        </div>
    }
    return (
        <components.MultiValueContainer {...props} />
    );
};

const MultiValueLabel = props => {
    const data = props.data;
    return (
        <span onClick={(e)=>e.stopPropagation()} className={props.innerProps.className}>
            <span>{props.children}</span>{data.meta&&<span className="pl-1 text-xs text-gray-500">{data.meta}</span>}
            </span>
    );
};

const RemoveIcon = styled.span`
  height: 100%;
  display: flex;
  align-items: center;

  > svg {
    margin-top: 1px;
    height: 14px;
  }
`;

const MultiValueRemove = props => {
    return (
        <components.MultiValueRemove {...props}>
            <RemoveIcon>
                <XIcon/>
            </RemoveIcon>
        </components.MultiValueRemove>
    );
};

const MCont = styled.div`

  > div {
    overflow: hidden;
  }
`;


const Menu = props => {

    return (
        <MCont>
            <components.Menu {...props}>{props.children}</components.Menu>
        </MCont>
    );
};

export const advanced_select_components_simple = {
    ControlComponent,
    Menu,
    Option: OptionSimple,
    MultiValueContainer,
    MultiValueLabel,
    MultiValueRemove,
    ClearIndicator,
    IndicatorSeparator,
    DropdownIndicator,
    SelectContainer,
    SingleValue: SingleValueSimple
};

export const advanced_select_components = {
    ControlComponent,
    Menu,
    Option,
    MultiValueContainer,
    MultiValueLabel,
    MultiValueRemove,
    ClearIndicator,
    IndicatorSeparator,
    DropdownIndicator,
    SelectContainer,
    SingleValue
};