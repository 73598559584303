import React, {useState} from 'react';
import styled from "styled-components";
import {PhotoPortal} from "../../../../../app/community/home/PhotoPortal";
import {getBkgColor, getLargeImage} from "./profile-picture";
import ImageUploader from "../../image-uploader";
import {PhotoSelector} from "../../photo-selector";
import {InlineLoader} from "../../../core";

const Frame = styled.div`
    background: #f7f8fa;
    height: 184px;
    position: relative;
    border-bottom: 1px solid #e3e8ee;
`;

const Background = styled.div`

    height: 184px;
`;

const AdminTag = styled.span`
       position: absolute;
    bottom: 9px;
    right: 16px;
    font-size: 13px;
    font-weight: 600;
    padding: 3px 8px;
    background: #112d42;
    border-radius: 5px;
    color: #fff;
`;

const Loader = styled.div`
position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.5);
    display: flex;
    align-items: center;
    justify-content: center;
`;

const EditFrame = styled.div`
 z-index: 999;
 position: absolute;
    bottom: 0;
    display: flex;
       
        justify-content: center;
      width: auto;
    right: 14px;
`;

const EditButton = styled.div`

        
    > div {
    margin-bottom: 10px;
       display: inline-block;
           padding: 5px 12px;
    border-radius: 8px;
    background: #000000a6;
    color: #fff;
    cursor: pointer;
    font-size: 13px;
    font-weight: 500;
    letter-spacing: 0.25px;
    :hover { 
        opacity: 0.7;
    }
    }
`;

function getBkg(url) {
    if (url.startsWith('https://')) {
        return `url('${url}?alt=media')`;
    } else {
        return `${url}`;
    }
}

export function PrCoverPhoto({cover_photo='',loading=false,cover_photo_color='rgb(0,0,0)', is_admin=false, setCoverPhoto=()=>{}, editing=false}) {
    const [open, setOpen] = useState(false);

    let style = {};

    let onClick = () => {};

    //
    if (cover_photo !== '') {
        style = {
            backgroundImage: getBkg(cover_photo),
            backgroundPosition: "center",
            backgroundSize: "cover"
        };
    } else {
        style = {
            backgroundSize: "cover",
            backgroundColor: "#ffceb0",
            background: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='28' height='49' viewBox='0 0 28 49'%3E%3Cg fillRule='evenodd'%3E%3Cg id='hexagons' fill='%23ffffff' fillOpacity='0.37' fillRule='nonzero'%3E%3Cpath d='M13.99 9.25l13 7.5v15l-13 7.5L1 31.75v-15l12.99-7.5zM3 17.9v12.7l10.99 6.34 11-6.35V17.9l-11-6.34L3 17.9zM0 15l12.98-7.5V0h-2v6.35L0 12.69v2.3zm0 18.5L12.98 41v8h-2v-6.85L0 35.81v-2.3zM15 0v7.5L27.99 15H28v-2.31h-.01L17 6.35V0h-2zm0 49v-8l12.99-7.5H28v2.31h-.01L17 42.15V49h-2z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E")`
        }
    }

    if (!editing && cover_photo !== '') {
        onClick = () => {
            setOpen(true);
        };
    }

    let cp = <Background onClick={onClick} style={style}/>;

    return <Frame>
        {editing &&
        <ImageUploader style={{width: '100%'}} title='Cover Photo' type='cover-photo' handleUrl={setCoverPhoto}>
            {cp}
            <EditFrame>
                <PhotoSelector type='cover-photo' setPhoto={setCoverPhoto}>
                    <EditButton>
                        <div>Change Cover</div>
                    </EditButton>
                </PhotoSelector>
            </EditFrame>
        </ImageUploader>}
        {!editing && cp}
        {loading&&<Loader>
            <InlineLoader />
        </Loader>}
        {open && <PhotoPortal raw_color={cover_photo_color?cover_photo_color:"#7f828e"} bkg_color={getBkgColor(cover_photo_color)} images={getLargeImage(cover_photo)} onClose={(e) => {
            setOpen(false)
        }}/>}
        {is_admin&&!editing&&<AdminTag>Admin</AdminTag>}
    </Frame>
}