import React from 'react';
import {Avatar, EntityIcon} from "../components/core";
import styled from "styled-components";

const EmojiIcon = styled.div`
         font-size: ${props=>props.font_size};
    text-align: center;
    padding-left: ${props=>!props.is_url?"":""};
        height: ${props=>props.dimension};
  width: ${props=>props.dimension};
    display: flex;
  line-height: ${props=>props.font_size};
    align-items: center;
    justify-content: center;
`;

function getFontSize(size) {
    if(size==='mini') {
        return '18px'
    } else if(size==='xs') {
        return '20px'
    } else if(size==='md') {
        return '26px'
    } else if(size==='lg') {
        return '34px'
    } else if(size==='xl') {
        return '34px'
    }
}

function getAvatarSize(size) {
    if(size==='mini') {
        return '20px'
    } else if(size==='xs') {
        return '20px'
    } else if(size==='sm') {
        return '24px'
    } else if(size==='md') {
        return '24px'
    } else if(size==='lg') {
        return '34px'
    } else if(size==='xl') {
        return '42px'
    }
}

function getImageQuality(size) {
    if(size==='xs') {
        return '_small'
    } else if(size==='md') {
        return '_medium'
    } else if(size==='lg') {
        return '_medium'
    } else if(size==='xl') {
        return ''
    }
}

function getCustomEmojiDims(size) {
    if(size==='xs') {
        return 'h-5 w-5'
    } else if(size==='md') {
        return 'w-6 h-6'
    } else if(size==='lg') {
        return 'h-8 w-8'
    } else if(size==='xl') {
        return 'w-9 h-9'
    }
}

export function GroupDisplayIcon(data) {
    const {
        emoji = "",
        profile_picture = "",
        type='group',
        profile_picture_color = "",
        name = "Default",
        color = "#a1a1aa", size="xs"
    } = data;
    const initial = name.charAt(0).toUpperCase();

    const avatar_size = getAvatarSize(size);
    if (emoji) {
        return <EmojiIcon is_url={emoji.indexOf("?alt=media")!==-1} dimension={avatar_size} font_size={getFontSize(size)} className="emoji-font">
            {emoji.indexOf("?alt=media")===-1&&emoji}
            {emoji.indexOf("?alt=media")!==-1&&<img src={`${emoji}`} className={`${getCustomEmojiDims(size)} flex-none rounded`} />}
        </EmojiIcon>
    } else if (profile_picture) {
        return <Avatar size={avatar_size} type={type} url={`${profile_picture}${getImageQuality(size)}?alt=media`} />
    } else if (color) {
        return <EntityIcon initial={initial} size={size} color={color} />;
    } else {
        return <EntityIcon color={color} size={size} initial={initial} />;
    }
}