const sort_criteria = {
    dir: 'asc',
    type: 'text',
    field: 'about.last_name'
};

let default_filters = [
    {
        field: 'archived',
        operator: 'false',
        value: false,
        modifier: ''
    }
];

const default_active_props = [
    {
        id: 'name',
        active: true,
        col_width: 180
    },
    {
        id: 'about.last_name',
        active: true,
        col_width: 150
    },
    {
        id: 'birthday',
        active: true,
        col_width: 150
    },
    {
        id: 'address.country',
        active: true,
        col_width: 150
    },
    {
        id: 'contact.email',
        active: true,
        col_width: 150
    },
    /*
    {
        id: 'location',
        active: true,
        col_width: 150
    },

     */
    {
        id: 'member_type',
        active: true,
        col_width: 150
    }
];

const default_sort = [
    sort_criteria
];

const default_group_by = null;

const group_by_mock = {

};

const default_footer_calc = {

};

export function at_buildView(id, name, layout, filters = default_filters, sort = default_sort, active_props = default_active_props, type = 'default', group_by=default_group_by, footer_calc = default_footer_calc, emoji = null, top_type='system') {
    return {
        layout,
        type: top_type, // custom
        id,
        emoji,
        features: {
            filter: true,
            can_select: layout === 'table',
            can_group: (layout === 'list' || layout === 'gallery'),
            can_sort: layout !== 'map'
        },
        name,
        sort,
        filters,
        show_deactivated_members: false,
        properties: active_props,
        footer_calc: type==='member'?{
            name: {
                calculation: 'row-count',
                label: {
                    single: 'member',
                    multi: 'members'
                }
            },
            sessions: {
                calculation: 'average',
                label: {
                    single: 'session',
                    multi: 'sessions'
                }
            },
            dt_age: {
                calculation: 'average',
                label: {
                    single: 'year',
                    multi: 'years'
                }
            }
        }:{},
        group_by
    };
}