export function co_buildMultiSelectArray(val=[],options_obj={}) {
    let arr = [];
    for (let i = 0; i < val.length; i++) {
        const item = val[i];
        if(!options_obj[item]) {
            // this culls the hidden values
            continue;
        }
        arr.push({
            label: options_obj[item],
            value: item
        });
    }
    return arr;
}