import React from 'react';
import {SGSection} from "./items";
import styled from "styled-components";
import {useIntl} from "react-intl";

function objToArr(obj) {
    return Object.keys(obj).map(function (key) {
        return obj[key];
    });
}

const SGFrame = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    margin-top: 12px;
    > div:nth-child(1), > div:nth-child(2) {
        border-right: 1px solid #e4e4e7;
    }
`;

export const SettingsGrid = ({items = [],base_link='/preferences'}) => {
    const {formatMessage: f} = useIntl();
    return <SGFrame>
            {items.map((sec) => {
                return <SGSection base_link={base_link} key={sec.title} desc={sec.desc}
                                          title={f({id:`preferences.${sec.title}`})} icon={sec.icon} links={objToArr(sec.links)}/>
            })}
    </SGFrame>;
};