import React from 'react';
import {PostAuthorSeries} from "../series";
import {InfoState, Input, SectionHeader, SimpleListbox, Toggle} from "../../core";
import {AdminImageEditorField} from "../../custom/admin-image-editor-field";

function Section({children, action, title = "Hey"}) {
    return <div className="border border-gray-200 rounded-lg px-3 pt-2 pb-3 relative">
        <SectionHeader fill padded={false} title={title}/>
        <div className="pt-1">
            {children}
        </div>
        {action}
    </div>
}

function Item({title, subtitle, value}) {

    return <div className={`flex`}>
        <div className="flex-grow">
            <div className="text-sm text-gray-800 font-semibold">{title}</div>
            <div className="text-xs pt-0.5 text-gray-600">{subtitle}</div>
        </div>
        <div className="flex-none  flex items-center">
            {value}
        </div>
    </div>
}

export function ComposerSettingsTab({community, can_customize_email_design, series, can_enable_send_email, message_type, send_email, setSendEmail, changeSettings, settings, setSeries, prefill}) {
console.log("SERIES",series)
    const sp = {
    ...prefill,
        series
    }
    return <div className="space-y-4 pt-4">
        {!can_enable_send_email&&<div>
            <Section title="Send as Email">
                <InfoState fill subtitle="Add a mailing list to the Group to email everyone" />
            </Section>
        </div>}
        {can_enable_send_email&&<Section action={<div className="absolute top-2 right-2">
            <Toggle simple checked={send_email} layout="switch" onToggle={() => {
                setSendEmail(!send_email)
            }}/>
        </div>} title="Send as Email">
            {send_email&&<div className="space-y-2 pt-0.5">
                <Item title={"From name"} subtitle={"Who the email appears to be from in an inbox."}
                value={<Input value={settings.from_name} onChange={(v)=>{
                    changeSettings("from_name",v)
                }} compact />}
                />
                <Item title={"Reply-to email"} subtitle={"When members click reply, who should that be sent to?"}
                value={<Input value={settings.reply_to} onChange={(v)=>{
                    changeSettings("reply_to",v)
                }} compact />}
                />
                {message_type==='group'&&<Item title={"Track delivery"} subtitle={"See who messages are delivered to, does not track opens or add a pixel."}
                      value={<div className="">
                          <Toggle checked={settings.track_delivery} simple layout="switch" onToggle={() => {
                              changeSettings("track_delivery",!settings.track_delivery)
                          }}/>
                      </div>}
                />}
            </div>}
        </Section>}
        {can_customize_email_design&&send_email&&<Section title="Email Design">
            <div className="space-y-2 pt-0.5">
                <Item title={"Custom Header Image"} subtitle={"Select your own custom header image"}
                      value={<Toggle simple checked={settings.header_style!=='default'} layout="switch" onToggle={() => {
                          changeSettings("header_style",settings.header_style==='default'?"custom":"default")
                      }}/>}
                />
                {settings.header_style==='custom'&&<Item title={"Header Image"} subtitle={"556px x 48px"} value={
                    <div className="w-36">
                        <AdminImageEditorField title="Email Header" dims="w-12 h-3" type="email-header"
                                               handleUrl={(url, color) => {
                                                   changeSettings("header_custom",url)
                                               }} url={settings.header_custom}/>
                    </div>
                } />}
            </div>
        </Section>}
        {message_type==='group'&&<Section title="Discussion">
            <div className="space-y-2 pt-0.5">
                <Item title={"Enable replies"} subtitle={"Allow members to reply to this message on Unaty."} value={<div className="">
                    <Toggle checked={settings.enable_replies} simple layout="switch" onToggle={() => {
                        changeSettings("enable_replies",!settings.enable_replies)
                    }}/>
                </div>}/>
                {message_type==='group'&&<Item title={"Message Series"} subtitle={"Organize similar messages on Unaty with a series."}
                      value={<PostAuthorSeries can_create_series={true} community={community}
                                               onChangeSeries={(se) => {
                                                   setSeries(se)
                                               }} prefill={sp}/>}/>}
            </div>
        </Section>}
    </div>
}