import React, {useContext, useEffect, useState} from 'react';
import {ActionWithIcon, SectionHeader} from "../../../../library/components/core";
import {FirebaseDB} from "../../../../config/setup-firestore";
import {GlobalContext} from "../../../global/global-context";

function getCopy(count) {
    if(count===1) {
        return "1 New Member Application"
    }
    return `${count} New Member Applications`
}

export async function getGroupMemberApplications(community_uid,group_id) {
    return await FirebaseDB
        .collection('community_members')
        .doc(community_uid)
        .collection('applications')
        .where('status','==','pending')
        .where('group_id','==',group_id)
        .get()
        .then(snap=>{
            return snap.docs.map(doc=>{
                return {
                    ...doc.data(),
                    id: doc.id
                }
            })
        })
}

export function MemberApplicationsWidget({community_uid,group_id}) {
const global = useContext(GlobalContext);
    const [applications,setApplications] = useState([]);

    useEffect(function () {
        getApplications()
    }, [community_uid,group_id])

    function getApplications() {
        getGroupMemberApplications(community_uid,group_id)
            .then(apps=>{
                setApplications(apps)
            })
    }

    const count = applications.length;

    if(!count) {
        return null;
    }

    return <div>
        <SectionHeader title={getCopy(count)} />
        <div className="pt-1 space-y-1 px-2.5">
            {applications.map(app=>{
                return <div className="flex items-center" key={app.id}>
                    <div className="flex-grow">
                        <div className="text-sm font-semibold text-gray-800">{app.name}</div>
                        <div className="text-sm text-gray-600">{app.account_email}</div>
                    </div>
                    <div>
                        <ActionWithIcon inverse text="View" onClick={()=>{
                            global.handleSetRightMenu(`member-application__${app.id}`,{onClosing:()=>{
                                    getApplications()
                                }});
                        }} />
                    </div>
                </div>
            })}
        </div>
    </div>
}