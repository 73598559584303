import React, {useState} from 'react';
import {UnatySVG} from "../../../icons/unaty/logos";
import {Avatar, Button, ColorInput, Field, Tabs} from "../../core";
import ImageUploader from "../../old/image-uploader";
import {authFetch} from "../../../../config/network";
import {CADEditCard, CADGrid, CADPreviewBox} from "../../../../app/community/admin/settings/designer/shared";
import {DEFAULT_USER_PHOTO} from "../../../../config/defaults";
import {PhotoSelector} from "../../old/photo-selector";
import styled from "styled-components";
import {colorContrast} from "../../../utilities/general/colors";
import {getAdvancedImageBackgroundStyle, getAdvancedImageBkg} from "../profile-card/cover";
import {AdminImageEditorField} from "../../custom/admin-image-editor-field";

const mock = {
    background: {
        type: 'color',
        color: '#ff6b00',
        image: ''
    }
};

const preview_mock = {
    community: {
        logo: 'https://firebasestorage.googleapis.com/v0/b/unaty-prod.appspot.com/o/profile_pictures%2F8hvqXXAzF_small?alt=media',
        name: 'Corps Isaria'
    },

    member: {
        name: 'Fabian Ahrens',
        member_type: 'Alter Herr',
        member_id: 'FH59D4F0',
        joined_year: '2012'
    }
};

function getBackground(bkg) {
    if (bkg.type === 'color') {
        return {backgroundColor: `${bkg.color}`,paddingBottom:'60%'};
    } else if(!bkg.image) {
        return {backgroundColor: `lightgrey`,paddingBottom:'60%'};
    }
    return {backgroundImage: `url('${bkg.image}?alt=media')`,backgroundColor:'#b5b5b5',paddingBottom:'60%'};
}

const data_mock = {
    member: {
        member_type: 'Leader',
        name: 'George Washington',
        member_id: '12345678'
    },
    community: {
        logo: '',
        emoji: '',
        name: 'United States'
    }
}

const style_mock = {
    background: {
        type: 'color',
        color: 'sky'
    }
};


export function MembershipCardPreview({background, onClick=()=>{}, community, member}) {
    const contrast_color = background.type === 'image' ? "#ffffff" : colorContrast(background.color);
const shadow = background.type==='image'? {textShadow:'0 1px 3px #000000'} : {};
    return <div onClick={onClick}
        className="relative transform transition-transform bg-center bg-cover hover:-translate-y-0.5 w-full shadow-lg rounded-lg cursor-pointer"
        style={getBackground(background)}>
        <div className="flex flex-col absolute top-3 right-3 bottom-3 left-3">
            <div className="flex-none flex">
                <div className="flex flex-grow space-x-2">
                    {!community.logo && community.emoji && <div className={`h-7 w-7 flex items-center justify-center rounded-md bg-white ${background.type==='image'?"shadow":""}`}>
                        <span className="emoji-font text-xl mt-0.5">{community.emoji}</span>
                    </div>}
                    {community.logo &&
                    <div className={`h-7 w-7 flex items-center justify-center border-white rounded-md bg-white ${background.type==='image'?"shadow":""}`}><img
                        src={community.logo} className="h-6 w-6 rounded"/></div>}
                    <div className="flex items-center h-7 text-sm font-semibold opacity-90"
                         style={{color: `${contrast_color}`,...shadow}}>{community.name}</div>
                </div>
                <div>

                </div>
            </div>
            <div className="flex-grow"></div>
            <div className="flex-none flex flex-col">
                <div className="text-base pb-px font-semibold" style={{color: `${contrast_color}`,...shadow}}>{member.name}</div>
                {member.member_type&&<div className="text-sm pb-1 font-medium opacity-90"
                     style={{color: `${contrast_color}`,...shadow}}>{member.member_type}</div>}
                <div className="hidden space-x-2">
                    <div className="text-xs font-medium opacity-70"
                         style={{color: `${contrast_color}`,...shadow}}>ID {member.member_id}</div>
                    <div className="text-xs font-medium hidden opacity-70" style={{color: `${contrast_color}`,...shadow}}>Joined
                        in {member.joined_year}</div>
                </div>

            </div>
        </div>
        <div className="absolute w-5 h-5 opacity-70 right-4 bottom-4">
            <UnatySVG fill={contrast_color}/>
        </div>
    </div>
}

export function MembershipCardDesigner({current = mock, updateCommunityData, community={}, preview = preview_mock}) {
    const [config, setConfig] = useState(current);
    const [changes_made, setChangesMade] = useState(false);
    const [saving, setSaving] = useState(false);

    function saveChanges() {
        setSaving(true);
        if(!community) {
            return;
        }
        const payload = {
            community_uid: community.uid,
            member_card_design: config, member_id: community.member_id
        };

        const res = (result) => {
            updateCommunityData(community.uid,result.data.community);
           setSaving(false);
            setChangesMade(false);
        };

        const err = () => {
            setSaving(false);
            setChangesMade(false);
        };

        authFetch("/communities/update-member-card-design", res, err, "POST", {payload});
    }

    return <CADGrid>
        <CADEditCard title="Customize" actions={changes_made&&<div className="">
            <Button onClick={()=>saveChanges()} loading={saving} text="Save" intent="success" />
        </div>}>
            <Field label="Background">
                <Tabs intent="secondary" layout="pills-color" tabs={["Color","Image"]} onSelect={(t)=>{
                    if(t==='Image') {
                        let nc = {...config};
                        nc.background.type = 'image';
                        setConfig(nc);
                        setChangesMade(true);
                    } else {
                        let nc = {...config};
                        nc.background.type = 'color';
                        nc.background.color = "#F15F31";
                        nc.background.image = "";
                        setConfig(nc);
                        setChangesMade(true);
                    }
                }} active={config.background.type==='image'?"Image":"Color"} />
            </Field>

            {config.background.type==='color'&&<div>
                <ColorInput value={config.background.color} onChange={(color) => {
                    let nc = {...config};
                    nc.background.type = 'color';
                    nc.background.color = color;
                    nc.background.image = "";
                    setConfig(nc);
                    setChangesMade(true);
                }} />
            </div>}
            {config.background.type==='image'&&<div>
                <Field label="Image">
                    <AdminImageEditorField type="member-card" url={config.background.image} handleUrl={(url,color)=>{
                        let nc = {...config};
                        nc.background.type = 'image';
                        nc.background.image = url;
                        nc.background.color = color;
                        setConfig(nc);
                        setChangesMade(true);
                    }} />

                </Field>
            </div>}
        </CADEditCard>
        <CADPreviewBox>
            <div className="w-96 mx-auto py-4">
                <MembershipCardPreview {...config} {...preview} />
            </div>
        </CADPreviewBox>
    </CADGrid>
}