import React, {useContext, useEffect, useState} from 'react';
import {ActionWithIcon, Avatar, Button} from "../core";
import {
    CheckIcon, DotsHorizontalIcon, PlusIcon,
    SparklesIcon,
    XIcon
} from "@heroicons/react/solid";
import {DEFAULT_USER_PHOTO} from "../../../config/defaults";
import {NewTooltip} from "../reorganize/new-tooltip";
import {Popover2} from "@blueprintjs/popover2";
import {PFFieldRenderer} from "../../../app/quick-profile-update/field-renderer";
import {PhotoSelector} from "../old/photo-selector";
import {buildMemberProfileSections} from "./build-profile-sections";
import {getProfileCompleteness} from "./get-profile-completeness";
import {useIsMobile} from "../../../app/global/global-router";
import {useHistory, useLocation} from "react-router-dom";
import {CommunityContext} from "../../../app/community/community-context";
import {PhoneServicesFinder} from "../reorganize/phone-services-finder";
import {EmailValidationStatusTag} from "../custom/email-validation-status-tag";
import ReactDOM from "react-dom";
import {buildUserProfileSections} from "./build-user-profile-sections";
import {getSectionVisibilityNote} from "../old/edit-fields-section";
import {PopupMenu} from "../old/popup-menu";
import styled from "styled-components";

function TextAction({text = "Save", onClick, bold, intent}) {

    return <div onClick={onClick}
                className={`text-sm py-1 px-2.5 rounded-md hover:opacity-70 transition-opacity ${bold ? "font-bold" : "font-medium"} ${intent === 'primary' ? "text-secondary" : "text-gray-600"}`}>
        {text}
    </div>
}

function getProgressIntent(p) {
    if (p === 1) {
        return 'bg-secondary-400'
    } else if (p > 0.7) {
        return 'bg-success'
    } else if (p > 0.4) {
        return 'bg-yellow-500'
    } else {
        return 'bg-danger'
    }
}

function getStyles(layout) {
    if (layout === 'desktop') {
        return ``;
    } else {
        return 'border-b relative border-gray-200 px-4 py-2 bg-white';
    }
}

function ProfileCompleteness({percent, layout}) {
    const p = Math.round(percent * 100);

    const bg_intent = getProgressIntent(percent);
    return <div className={`${getStyles(layout)}`}>
        <div className="text-sm flex">
            <div className="font-medium flex-grow text-gray-700">
                {p === 100 ? "Great Job!" : "Profile Completeness"}
            </div>
            <div className="font-medium text-gray-500">{p}%</div>
        </div>
        <div className="mt-1 mb-1">
            <div className="rounded-full h-2 w-full bg-gray-200 relative overflow-hidden">
                <div style={{width: `${p}%`, transition: '0.2s width ease'}}
                     className={`absolute top-0 left-0 bottom-0 ${bg_intent}`}/>
            </div>
        </div>
    </div>
}

function ProfilePhotoChange({
                                handleNewPhoto = () => {
                                },
                                profile_picture = "",
                                profile_picture_color = ""
                            }) {
    return <PhotoSelector type='profile-picture' setPhoto={handleNewPhoto}>
        <div className="flex items-center justify-center p-4">
            <Avatar empty={!profile_picture} show_edit
                    url={profile_picture ? `${profile_picture}_medium?alt=media` : `${DEFAULT_USER_PHOTO}_medium?alt=media`}
                    size="4xl"/>
        </div>
    </PhotoSelector>
}

function Topbar({
                    onCancel = () => {
                    },
                    onSaveChanges = () => {
                    },
                    saving
                }) {
    return <div className="h-12 bg-white border-b border-gray-200 px-2.5 grid grid-cols-3">
        <div className="flex items-center">
            <TextAction onClick={onCancel} text="Cancel"/>
        </div>
        <div className="flex justify-center items-center">
            <div className="text-base font-bold text-gray-800">
                Edit Profile
            </div>
        </div>
        <div className="flex items-center justify-end">
            <TextAction onClick={() => {
                if (!saving) {
                    onSaveChanges();
                }
            }} bold intent="primary" text={saving ? "Saving.." : "Save"}/>
        </div>
    </div>
}

function StatusSymbol({visibility}) {
    const note = getSectionVisibilityNote(visibility, true);
    if (!note) {
        return null;
    }
    return <NewTooltip message={note.text}>
        <div tabIndex={"-1"}
             className="h-5 flex cursor-pointer items-center text-selection p-1 bg-white hover:bg-gray-100 rounded-md">
            <div className="h-3.5 w-3.5">
                {note.icon}
            </div>
        </div>
    </NewTooltip>
}

function TextButton() {

    return <div
        className="flex space-x-0.5 cursor-pointer h-5 items-center px-1 bg-primary-50 hover:opacity-70 transition-opacity rounded-md">

        <div className="h-3.5 w-3.5 text-primary-600">
            <SparklesIcon/>
        </div>
        <div className="text-xs font-medium pr-0.5 text-primary-800">Autocomplete</div>
    </div>
}

function SectionAutocomplete({section}) {
    const autocomplete_fields = Object.keys(section.autocomplete).length;
    if (autocomplete_fields === 0) {
        return null;
    }
    return <TextButton/>
}

const MiniBtn = styled.div`

`;

function CustomMiniButton({
                              onClick = () => {
                              },
    show_tooltip = true,
                              icon = null,
                              label = ''
                          }) {

    const content = <MiniBtn onClick={onClick} tabIndex={"-1"}
                              className="h-5 w-5 cursor-pointer flex items-center justify-center text-gray-500 hover:text-gray-800 hover:bg-gray-100 rounded-md transition-colors">
        <div className="h-4 w-4">
            {icon}
        </div>
    </MiniBtn>;

    if(!show_tooltip) {
        return content;
    }
    return <NewTooltip message={label}>
        {content}
    </NewTooltip>;
}

function SectionOptions({options,is_open}) {
    const content = <CustomMiniButton show_tooltip={false} icon={<DotsHorizontalIcon/>}/>;
    if(is_open) {
        return content;
    }
    return <Popover2 enforceFocus={true} autoFocus={true} placement={'bottom-end'} content={<PopupMenu items={options}/>} minimal
                     popoverClassName='minimal-popover'>
        {content}
    </Popover2>;
}

export function ProfileFieldsContentSection({
                                                title = "About",
                                                options,
                                                body_className = "",
                                                visibility,
                                                add,
                                                single_line = false,
                                                id,
                                                layout,
    actions,
    block,
                                                body,
                                                is_open,
                                                children,
                                                section
                                            }) {
    return <div id={`profile-section-${id}`}
                className={`${layout === 'desktop' ? "px-4 pt-3 pb-4 border-t shared-top-border" : ""}`}>
        <div className="flex h-8 mb-1 items-baseline">
            <div className="flex-grow">
                <div className="text-base font-bold text-gray-800">
                    {title}
                </div>
            </div>
            <div className="flex space-x-1">
                <SectionAutocomplete section={section}/>
                {add && <CustomMiniButton {...add} icon={<PlusIcon/>}/>}
                {options && options.length > 0 && <div className="">
                    <SectionOptions is_open={is_open} options={options}/>
                </div>}
                {actions&&<div className="flex space-x-2 items-center">{actions}</div>}
                <StatusSymbol visibility={visibility}/>
            </div>
        </div>
        <div className={`${block?"block":"grid"} ${single_line?"grid-cols-1 max-w-lg":"grid-cols-2"} gap-3 ${body_className}`}>
            {children}
        </div>
    </div>
}

function DataSuggestion() {
    return <div className="py-1">
        <div className="text-xs text-gray-500 pb-0.5">
            <div><strong className="text-gray-700">iaCB Ahrens</strong> made a suggestion</div>
        </div>
        <div className="flex space-x-2">
            <div
                className="flex-grow flex items-center rounded-md py-0.5 px-1.5 border border-secondary-100 bg-secondary-50">
                <div className="truncate text-gray-700 font-medium">
                    seanm@gmail.com
                </div>
            </div>
            <div className="flex space-x-1">
                <Button size="xs" icon={<XIcon/>}/>
                <Button size="xs" icon={<CheckIcon/>} intent="success"/>
            </div>
        </div>
    </div>
}

function FieldSuggestions() {

    return <Popover2 placement={'bottom-end'} minimal
                     popoverClassName='minimal-popover my-0.5' interactionKind='hover'
                     content={<div className="w-96 px-2.5 py-1.5 popup-box-shadow user-select-none rounded-md">
                         <DataSuggestion/>
                     </div>}>
        <ActionWithIcon onClick={() => {
        }} text={"1 suggestion"}/>
    </Popover2>;
}

/*
How it works

 */


function RenderAction({
                          col_span = 1, onClick = () => {
    }, label
                      }) {
    return <div onClick={onClick} className={`${col_span === 2 ? "col-span-2" : ""}`}>
        <div className={`text-secondary cursor-pointer font-medium -mt-2 text-sm hover:opacity-70 transition-opacity`}>
            {label}
        </div>
    </div>
}

export function RenderProfileField({
                                       corner_hint, title,
                                       col_span = 1, metadata, cf_data = {}, meta, onChange = () => {
    }, field, value, type, label, required, hint="", placeholder=""
                                   }) {

    return <div className={`${col_span === 2 ? "col-span-2" : ""}`}>
        <PFFieldRenderer title={title} corner_hint={corner_hint} hint={hint} placeholder={placeholder} cf_data={cf_data} meta={meta} onChange={(v) => onChange(field, v)} value={value} label={label}
                         type={type} required={required}/>

        {field === "contact.email" && <div className="pt-1 pb-1 text-xs text-gray-500">
            This email is displayed in your profile and the directory.
        </div>}

        {type === 'phone' && value && metadata && metadata.phone_services && metadata.phone_services &&
        <div className="pt-0.5 pl-1 pb-1">
            <PhoneServicesFinder field={field.replace('contact.', '')} updateMember={metadata.updateMember}
                                 id={metadata.id} can_edit
                                 active={metadata.phone_services[`${field.replace('contact.', '')}`]}/>
        </div>}

        {type === 'email' && field === 'account_email' && value && metadata && metadata.email_validation &&
        <div className="pt-0.5 pl-1 pb-1">
            <EmailValidationStatusTag {...metadata.ev_data} />
        </div>}
    </div>
}

function getValue(member, field) {
    const sp = field.split('.');
    if (sp.length > 1) {
        return member[sp[0]][sp[1]];
    } else {
        return member[sp[0]];
    }
}

const member_mock = {
    profile_picture: "",
    profile_picture_color: "",
    address: {
        line_one: "",
        line_two: "",
        city: "",
        zip_code: "",
        state: "",
        country: "",
    },
    about: {
        first_name: "",
        last_name: "",
        location: "",
        middle_name: "",
        bio: "",
        title: "",
    },
    birthday: {
        date: null,
        month: null,
        year: null
    },
    social: {
        website: "",
        linkedin: "",
        twitter: "",
        facebook: "",
    },
    contact: {
        phone: "",
        email: "",
        secondary_phone: ""
    }
};

export function EditProfileMobile(props) {
    const {
        layout = 'desktop',
        init_member = member_mock,
        social_fields = [],
        metadata = {},
        type = "member",
        hidden = {},
        _actions = {},
        show_completeness = true,
        meta = {},
        display_sections = {},
        important_fields = [],
        actions = null,
        saving = '',
        handleUpdate = () => {
        },
        onSaveChanges = () => {
        },
        onCancel = () => {
        },
    } = props;

    const community = useContext(CommunityContext);

    const history = useHistory();
    const location = useLocation();
    const [data, setData] = useState(init_member);
    const [updated_fields, setUpdatedFields] = useState([]);

    const [show, setShow] = useState({});

    const is_mobile = useIsMobile();

    const completeness = getProfileCompleteness(type, data, important_fields);

    const profile_sections = type === 'user' ? buildUserProfileSections(data, show) : buildMemberProfileSections(data, community, community.custom_fields, display_sections, show, important_fields, social_fields, _actions);

    function handleChange(field, value) {
        const d = {...data};
        const sp = field.split('.');
        if (sp.length === 2) {
            d[sp[0]][sp[1]] = value;
        } else {
            d[sp[0]] = value;
        }
        setData(d);

        let uf = [...updated_fields];
        if (uf.indexOf(field) === -1) {
            // not yet in fields changed
            uf.push(field);
            setUpdatedFields(uf);
        }

        handleUpdate(d, uf);
    }

    const {obj, ids} = profile_sections;

    const content = <div
        id={"profile-top"}
        className={layout === 'desktop' ? "bg-white max-w-xl" : "fixed overflow-y-auto z-99 top-0 right-0 left-0 bottom-0 bg-white"}
        style={{maxWidth: '600px'}}>
        <div
            className={`${layout === 'desktop' ? "" : "pb-40 "} bg-white`}
            style={{maxWidth: '600px'}}>
            {layout === 'mobile' && show_completeness && <div className="top-0 sticky z-99">
                <Topbar saving={saving} onSaveChanges={onSaveChanges} onCancel={onCancel}/>
                <ProfileCompleteness layout={layout} {...completeness} />
            </div>}
            {layout === 'desktop' && show_completeness && <div style={{maxWidth: '600px'}}
                                          className="fixed flex space-x-4 border border-gray-200 bottom-4 z-99 w-full bg-white px-4 py-2 rounded-md shadow-md">
                <div className="flex-grow">
                    <ProfileCompleteness layout={layout} {...completeness} />
                </div>
                {actions}
            </div>}
            <div className="space-y-4 pb-4 relative">
                {layout === 'mobile' && <div>
                    <ProfilePhotoChange profile_picture={data.profile_picture}
                                        profile_picture_color={data.profile_picture_color}
                                        handleNewPhoto={(url, color) => {
                                            let d = {...data};
                                            d['profile_picture'] = url;
                                            d['profile_picture_color'] = color;
                                            setData(d);
                                            let uf = [...updated_fields];
                                            if (uf.indexOf('profile_picture') === -1) {
                                                // not yet in fields changed
                                                uf.push('profile_picture');
                                                uf.push('profile_picture_color');
                                                setUpdatedFields(uf);
                                            }
                                            handleUpdate(d, uf);
                                        }}/>
                </div>}

                <div className={`${layout === 'desktop' ? "space-y-8" : "px-5 space-y-6"}`}>
                    {ids.map((id, k) => {
                        const sec = obj[id];
                        if(hidden[id]) return null;
                        return <ProfileFieldsContentSection body_className="max-w-lg" key={id} id={id} title={sec.title} is_open={props.is_open}
                                                            visibility={sec.visibility} layout={layout} section={sec}>
                            {sec.fields.map((f, l) => {
                                if (f.type === 'ACTION') {
                                    return <RenderAction onClick={() => {
                                        let ns = {...show};
                                        ns[f.id] = true;
                                        setShow(ns);
                                    }} {...f} key={f.id}/>
                                }
                                return <RenderProfileField {...f} meta={meta} metadata={metadata} key={f.id}
                                                           onChange={handleChange} value={getValue(data, f.field)}/>
                            })}
                        </ProfileFieldsContentSection>
                    })}
                </div>

            </div>
        </div>
    </div>;

    if (is_mobile) {
        return ReactDOM.createPortal(
            content,
            document.body
        );
    }

    return content;
}