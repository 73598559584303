import React, {useContext} from "react";
import {PageContainer} from "../../../../../library/components/old/page-container";
import {EntityHeader, PageHeader} from "../../../../../library/components/core";
import CommonCard from "../../../../../library/components/old/card";
import {DelegatedAccessBlock} from "../../../../../library/components/delegated-access-block";
import {StandardPageHeight} from "../../../../../library/playground/standard-page-framework";
import {CommunityContext} from "../../../community-context";
import {useHistory} from "react-router-dom";

export default function SettingsDelegatedAccess(props) {
    const community = useContext(CommunityContext);
    const history = useHistory();
    return (<StandardPageHeight content>
            <EntityHeader title="Delegated Access"
                          goBackTo={() => history.push(`/${community.data.handle}/admin/settings`)}/>
            <div className="p-4">
            <DelegatedAccessBlock/>
            </div>
        </StandardPageHeight>
    );
}