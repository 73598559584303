import React, {useContext, useEffect, useState} from "react";
import styled from "styled-components";
import {Popover2} from "@blueprintjs/popover2";
import {Avatar} from "../../../core";
import {SidebarBottomMenu} from "../../../reorganize/sidebar-bottom-menu";
import {TopbarUserIcon} from "../../topbar/user-nav-icon";
import {CommunityContext} from "../../../../../app/community/community-context";
import {DEFAULT_USER_PHOTO} from "../../../../../config/defaults";

const emoji_regex = /^(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|[\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|[\ud83c[\ude32-\ude3a]|[\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])+$/;


function hasEmoji(status) {
    const first_character = status.charAt(0);

    return emoji_regex.test(first_character);
}

function getStatus(status) {
    let to_return = {
        emoji: '',
        text: ''
    };

    if (status !== '') {
        let str = status.trim();
        const copy = status.trim();
        if (hasEmoji(str)) {
            to_return.emoji = str.charAt(0);
        }
        to_return.text = str.slice(1, copy.length).trim();
    }

    return to_return;
}

export function SidebarUserFooter({
                                      theme,
                                      data,
                                      global,
                                      is_member,
                                      border,
                                      history,
                                      member_type = '',
                                      goTo,
                                      community_handle
                                  }) {

    if (!global && !is_member) {
        return null;
    }

    const community = useContext(CommunityContext);

    const user_image = !data ? DEFAULT_USER_PHOTO : data.profile_picture ? `${data.profile_picture}` : !community.data ? DEFAULT_USER_PHOTO : community.default_profile_picture ? community.default_profile_picture : DEFAULT_USER_PHOTO;

    const popup_content = <SidebarBottomMenu community={community} notifications={[]}
                                             user_image={user_image} member_type={member_type}
                                             is_global={global} goTo={goTo}
                                             community_handle={community_handle} data={data}
                                             status={""}/>;

    return <div className="flex-none pl-2 flex items-center">
        <Popover2 shouldReturnFocusOnClose={false} interactionKind='click' content={popup_content} placement={'bottom-end'} minimal
                  popoverClassName='minimal-popover mt-0.5'>
            <TopbarUserIcon theme={theme} has_update={false} name={data.name} url={`${user_image}_small?alt=media`}/>

        </Popover2>
    </div>

}