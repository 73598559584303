import React from 'react';
import classNames from 'classnames';
import {Avatar} from './avatar';
import {Button} from './button';
import {Badge} from './badge';
import {DEFAULT_USER_PHOTO} from "../../../config/defaults";
import ModeratorIcon from "../old/moderator-icon";
import styled from "styled-components";
import {EntityInfo} from "../../utilities/localstorage/entity-localstorage";
import {IconGrabHandle} from "../../icons/custom/GrabHandle";
import {Draggable} from "react-beautiful-dnd";
import {useHistory} from 'react-router-dom';
import RoleIcon from "../../icons/custom/RoleIcon";
import {es_singleEntityRenderer, ESMembersList} from "../reorganize/entity-select/item";

const mock = [
    {
        image: DEFAULT_USER_PHOTO,
        icon: null,
        title_suffix: <ModeratorIcon height={12} fill={"#F15F31"}/>,
        id: 'member-2143',
        title: 'John Smith',
        subtitle: '@jsmith',
        actions: [<Button size="xs" circular text="Remove"/>]
    },
    {
        image: "",
        icon: <RoleIcon/>,
        id: 'role-5232',
        title_suffix: null,
        title: 'President (James Madison)',
        subtitle: '@prez',
        actions: [<Badge size={"sm"} text="You"/>]
    }
];

const IconItem = styled.div`
  > svg {
    height: 24px;
  }
`;

function getSubtitle(type, data, item) {
    if (type === 'filters') {
        return `${data.count} member${data.count === 1 ? "" : "s"}`;
    } else if (type === 'roles') {
        // data.assignee
        return `@${data.handle}`;
    } else {
        return data ? `@${data.handle}` : item.subtitle;
    }
}

function getImage(type, data, item) {
    if (type === 'filters') {
        return data ? data.image : item.image;
    } else if (type === 'roles') {
        // data.assignee
        return data ? data.assignee_image : DEFAULT_USER_PHOTO;
    } else {
        return data ? data.image : item.image;
    }
}

function MemberTypeMember({data, context, minimal}) {
    return <ItemRenderer data={null} context={context} drag_handle={null} minimal={minimal} id={data.user_uid}
                         type={'members'} key={`user-${data.user_uid}`} item={{
        value: `members-${data.user_uid}`,
        title: `${data.name}`,
        image: `${data.image}`,
        subtitle: `@${data.handle}`,
        actions: []
    }}/>
}

function ItemRenderer(props) {
    const {item, data, history, type, id, minimal, drag_handle, value, context} = props;

    const {expanded} = item;
    // data.count, data.user_uids
    const image = getImage(type, data, item);
    const title = data ? data.name : item.title;
    const icon = item.icon ? item.icon : null;
    const subtitle = getSubtitle(type, data, item);
    const icon_class = minimal ? "h-5 w-5 text-gray-500" : "bg-gray-200 text-gray-500 h-10 w-10";
    const left_class = minimal ? "w-6 pr-1" : "w-12";
    const text_class = minimal ? "text-xs" : "text-xs pt-0.5";
    const padding = "py-1 px-1";

    function goToItem() {
        if (type !== 'roles' && type !== 'members') {
            return;
        }
        const ext = type === 'roles' ? `role/${id}` : `member/${data.handle}`;
        history.push(`/${context.community.handle}/${ext}`);
    }

    return <>
        <div className={`flex ${padding} items-center`}>
            <div className={`${left_class} flex items-center justify-center flex-none`}>
                {!drag_handle && image &&
                <Avatar url={image ? `${image}_small?alt=media` : ''} text={title} size="md"/>}
                {!drag_handle && !image && icon &&
                <IconItem className={`rounded-42 ${icon_class} flex items-center justify-center`}>
                    {icon}
                </IconItem>}
                {!drag_handle && !icon && !image &&
                <Avatar url={`${DEFAULT_USER_PHOTO}_small?alt=media`} text={title} size="md"/>}
                {drag_handle && <div>
                    {drag_handle}
                </div>}
            </div>
            <div className="flex-1 pl-1.5 pr-2">
                <div onClick={() => goToItem()}
                     className="text-gray-800 cursor-pointer hover:opacity-70 max-w-xs truncate font-semibold text-sm"
                     style={{height: '18px'}}>
                    {title}{item.title_suffix && <div className="pl-1 inline-block" style={{
                    height: '18px',
                    verticalAlign: 'text-top'
                }}>{item.title_suffix}</div>}
                </div>
                {subtitle && <div className={`text-gray-500 max-w-xs truncate ${text_class}`}>
                    {subtitle}
                </div>}
            </div>
            <div className="flex-none flex items-center space-x-2 pr-1">
                {item.actions.map((act, i) => {
                    return <div key={i}>
                        {act}
                    </div>
                })}
            </div>
        </div>
        {(type === 'filters') && expanded && data.user_uids && <div
            className="border-l-4 border-gray-200 pl-2 divide-y divide-gray-200">{Object.keys(data.user_uids).map((uid) => {
            return <EntityInfo key={`user-` + uid} community_uid={context.community_uid} id={uid}
                               type={'members'}>
                <ItemRenderer history={history} context={context} drag_handle={drag_handle} minimal={minimal} id={id}
                              type={'members'} key={`user-${uid}`} item={{
                    value: `members-${id}`,
                    actions: []
                }}/>;
            </EntityInfo>
        })}</div>}
        {type === 'member_types' && expanded &&
        <div className="border-l-4 border-gray-200 pl-2 divide-y divide-gray-200">

            <ESMembersList renderItem={<MemberTypeMember context={context} minimal={minimal}/>} data={data}
                           type={'member_types'} id={id} cid={context.community_uid}/>
        </div>}
    </>;
}

function buildFinalProps(item_props = {}, it = {}) {
    let obj = {...item_props, ...it};
    if (!it.can_remove) {
        obj.onRemove = null;
    }
    return obj;
}

export function List({
                         items = mock,
                         space_between = 'space-y-1',
                         old_loader,
                         size = 'sm',
                         item_props = {},
                         renderDraggable,
                         can_reorder,
                         minimal,
                         use_entity_info,
                         context
                     }) {
    const dir = "grid-cols-1";
    const divide = "";
    const history = useHistory();

    const renderItem = (it, i, drag_handle) => {
        if (it.blank) {
            return null;
        }
        const sp = it.value.split('-');

        let result = [];
        if (old_loader) {
            result = <ItemRenderer history={history} context={context} drag_handle={drag_handle} minimal={minimal}
                                   key={it.value} id={sp[1]} type={sp[0]} item={it}/>;
        } else if (use_entity_info) {
            result = es_singleEntityRenderer(it.type, it.item_id, context.community_uid, buildFinalProps(item_props, it), context.community, {}, it.actions);
            /*
            result = <EntityInfo refresh={sp[0]==='filters'} key={it.value} community_uid={context.community_uid} id={sp[1]}
                               type={sp[0]}>
                <ItemRenderer history={history} context={context} drag_handle={drag_handle} minimal={minimal} id={sp[1]} type={sp[0]} key={it.value} item={it}/>;
            </EntityInfo>;

             */
        } else {
            result = es_singleEntityRenderer(it.type, it.item_id, context.community_uid, buildFinalProps(item_props, it), context.community, {}, it.actions);
            //result = <ItemRenderer history={history} context={context} drag_handle={drag_handle} minimal={minimal} key={it.value} id={sp[1]} type={sp[0]} item={it}/>;
        }

        return result;
    };

    if (can_reorder) {
        return <div className={classNames("grid max-w-screen-md", dir, divide)}>
            {items.map((it, i) => {
                const disabled = false;
                if (it.blank) {
                    return null;
                }
                return <Draggable isDragDisabled={disabled} key={it.value} draggableId={it.value}
                                  index={i}>
                    {renderDraggable((provided) => <div ref={provided.innerRef}
                                                        key={it.value} {...provided.draggableProps}>
                        {renderItem(it, i, <div
                            className='w-6 text-gray-500 hover:text-gray-800 h-6' {...provided.dragHandleProps}>
                            <IconGrabHandle/>
                        </div>)}
                    </div>)}
                </Draggable>
            })}
        </div>
    }
    return <div className={classNames("grid max-w-screen-md", dir, divide, space_between)}>
        {items.map((it, k) => renderItem(it, k))}
    </div>;
}