import React, {useContext, useState} from "react";
import {LocalSearchBar} from "../../../../../library/components/custom/local-search-bar";
import {TipItem} from "../tip";
import {all_tips} from "../../../../../library/components/education/tips";
import {useHistory} from "react-router-dom";
import {CommunityContext} from "../../../../community/community-context";
import {GlobalContext} from "../../../global-context";

function Recommended({tips=[],ctx,onTipClick}) {
    const slice_len = tips.length > 3 ? 3 : tips.length;
    const sample = [...tips].slice(0,slice_len);
    return <div className="space-y-1.5">
        <div className="pt-1 font-semibold text-gray-600 text-xs">Recommended</div>
        {sample.map(tip=><TipItem onTipClick={onTipClick} context={ctx} key={tip.id} {...tip} />)}
    </div>
}

function filterResults(q,arr) {
    if(!q) {
        return arr;
    }
    return arr.filter(a=>{
       return a.title.toLowerCase().indexOf(q)!==-1||a.subtitle.toLowerCase().indexOf(q)!==-1
    });
}

export function getUserPersonas(community) {
    let p = [];

    if(!community.data) {
        p.push('user');
        return p;
    }

    if(community.data.plan.account_owner === community.member_id) {
        p.push('owner');
    }

   if(community.is_admin) {
       p.push('admin');
   }

    p.push('member');

    return p;
}

export function HTSearchTips() {
    const global = useContext(GlobalContext);
    const community = useContext(CommunityContext);
    const [query,setQuery] = useState('');
    const user_personas = getUserPersonas(community);
    const all_relevant_tips = all_tips.filter(a=>{
        for(let i=0;i<a.for.length;i++) {
            const it = a.for[i];
            if(user_personas.includes(it)) {
                return a;
            }
        }
    });
    const results = filterResults(query,all_relevant_tips);
    const ctx = {
        c: community.data?community.data.handle:'',
        m: community.member?community.member.handle: ''
    }
    return <div className="space-y-2 px-4 py-3 overflow-y-auto" style={{maxHeight:'60vh'}}>
        <div className="pb-1">
            <LocalSearchBar onChange={(q)=>setQuery(q.toLowerCase())} />
        </div>
        <div className="flex flex-col space-y-1.5">
            {!query&&<Recommended onTipClick={()=>global.handleCloseRightMenu()} ctx={ctx} tips={all_relevant_tips} />}
            {query && results.length === 0 &&
            <div className="text-gray-500 text-sm p-4 text-center">
                No tips found
            </div>
            }
            {query&&results.map(tip=><TipItem onTipClick={()=>global.handleCloseRightMenu()} key={tip.id} {...tip} context={ctx} />)}
        </div>
    </div>
}