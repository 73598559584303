import React, {useState} from 'react';
import {authFetch} from "../../config/network";
import {Button, Input} from "../../library/components/core";

export function DeleteUser({}) {
    const [input,setInput] = useState("");
    const [loading,setLoading] = useState("");
    function callAPI() {
        setLoading('loading')
        const res = () => {
            setLoading('')
            setInput("");
        };

        const payload = {
            _user_uid: input
        };

        authFetch("/superadmin/delete-user", res, res, "POST", {payload});
    }

    return <div>
        <div>
            <Input placeholder="user_uid" value={input} onChange={v=>setInput(v)} />
        </div>
        <div>
            <Button onClick={()=>callAPI()} text="Call API" loading={loading==='loading'} />
        </div>
    </div>
}