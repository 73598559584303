import React from 'react';

function Stat({onClick,number=0,label="Emails sent"}) {

    return <div className={`${onClick?"cursor-pointer hover:opacity-70":""} transition-opacity`} onClick={()=>{
    if(onClick) {
        onClick();
    }
    }}>
        <div className="text-xl font-black text-gray-800">{number}</div>
        <div className="text-xs font-medium text-gray-500 -mt-0.5">{label}</div>
    </div>
}

export function MiniStatsRow({stats=[]}) {

    return <div className="grid grid-cols-3">
        {stats.map((stat,k)=>{
            return <Stat key={k} {...stat} />
        })}
    </div>
}