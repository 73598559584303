import React, {Component} from "react";
import styled from "styled-components";
const Container = styled.div`
    margin-top: 24px;
    > div {
        background-color: ${props=>props.color?props.color:''} !important;
        border-color: ${props=>props.color?props.color:''} !important;
        transition: opacity 0.15s ease;
        :hover {
            opacity: 0.8;
        }
        box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0.12) 0px 1px 1px 0px, ${props=>props.color?props.color:'#f96721'} 0px 0px 0px 1px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(60, 66, 87, 0.08) 0px 2px 5px 0px !important;
    }
`;

export default function ButtonSection({children,color=''}) {

    return (
        <Container color={color}>
            {children}
        </Container>
    );
}