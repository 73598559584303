import React, {useContext, useEffect, useRef, useState} from "react";
import {AddManyMembers} from "../../../add-many-members";
import {CommunityContext} from "../../../../../app/community/community-context";
import {authFetch} from "../../../../../config/network";

export function ObScAddAdmins() {
    const community = useContext(CommunityContext);
    const [members,setMembers] = useState([]);

    const val = useRef();

    useEffect(function () {
        return () => {
            val.current = members;
        }
    }, [members]);

    useEffect(function () {
        return () => {
            handleSave(val.current);
        }
    }, []);

    function handleSave(dt) {
        if(dt&&community&&community.member_id) {
            // members/edit-interests
            const res = () => null;

            const payload = {
                community_uid: community.uid,
                member_id: community.member_id,
                id: community.member_id,
                user_uid: community.member.user_uid,
                members: dt
            }

           authFetch('/communities/invite-admins', res, res, "POST", {payload});
        }
    }

    return <div>
        <AddManyMembers max_rows={6} setNewMembers={(nm)=>setMembers(nm)} start_rows={2} default_member_type={community.data.preferences.members.default_member_type} show_add_member />
    </div>
}