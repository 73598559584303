import React, {Component} from "react";
import styled from "styled-components";
import {authFetch} from "../../../../config/network";
import {Button} from "../../core";
import Modal from "../modal";
import Form from "../form";
import Field from "../field";
import {TextAndEmoji} from "../text-and-status";
import {NewEntitySelect} from "../../reorganize/entity-select";
import {CommunityContext} from "../../../../app/community/community-context";
import EntitiesFilters from "../../../../app/community/reorganize/entities-filters";


const Item = styled.div`
    display: inline-block;
`;

const Message = styled.div`
    border: 1px solid #829fbf;
    border-radius: 6px;
    padding: 8px 12px;
    background: #e8f3ff;
    display: grid;
    grid-template-columns: 24px 1fr;
    grid-gap: 8px;
`;

const IconItem = styled.div`
    color: #3080d8;
    > svg {
        height: 22px;
    }
`;

const Text = styled.div`
    font-size: 14px;
    font-weight: 500;
    line-height: 1.3;
`;

function InfoMessage({icon,content}) {
    return <Message>
        <IconItem>{icon}</IconItem>
        <Text className='text-gray-900'>{content}</Text>
    </Message>
}

// todo generalize for all, define fields, validation, etc

function getAdminsEntities(selected) {
    let to_return = {
        members: {},
        roles: {}
    };

    for(let i=0;i<selected.length;i++) {
        const item = selected[i];
        const val = item.value.split('-')[1];

        if(item._type==='member') {
            to_return.members[val] = true;
        } else {
            to_return.roles[val] = true;
        }
    }

    return to_return;
}

export default class EditModal extends Component {
    static contextType = CommunityContext;

    constructor(props) {
        super(props);

        let is = props._initial;

        is._changes_made = false;
        is._saving_changes = false;

        this.state = is;
    }
    
    update(e) {
        if(e) {
            e.preventDefault();
        }

        const {_saving_changes, _changes_made} = this.state;

        if (_saving_changes) {
            return;
        }

        if (!_changes_made) {
            // no changes made
            this.props.onClose();
            return;
        }

        this.setState({
            _saving_changes: true
        });

        // build payload
        let payload = {};
        const _fields = this.props._fields;
        for (let i = 0; i < _fields.length; i++) {
            const field_item = _fields[i];

            if(field_item.field==='entities') {
                payload[`filters`] = this.state[`filters`];
                payload[`entities`] = this.state[`entities`];
            } else if(field_item.field==='admins') {
                // todo from selected
                payload['entities'] = this.state.admins;
            } else {
                payload[field_item.field] = this.state[field_item.field];
            }
        }

        payload.community_uid = this.props._community_uid;
        payload.member_uid = this.props._member_uid;
        payload.member_id = this.props.member_id;

        payload.list_id = this.props._list_id;
        payload.id = this.props._id;

       authFetch(this.props._endpoint, this.handleResult.bind(this), this.handleError.bind(this), "POST", {payload})
    }

    handleResult() {
        this.setState({
            _changes_made: false,
            _saving_changes: false
        });
        // const {addToast} = this.props.actions;
      //  addToast({text: `Changes were saved.`, intent: 'success'});
        if(this.props._success_cb) {
            this.props._success_cb();
        }
        if(this.props._error_cb) {
            this.props._error_cb();
        }
        this.props.onClose();
    }

    handleError() {

        // addToast({text: `Changes could not be saved.`, intent: 'error'});
    }

    hc(f, v) {
        this.setState({
            [f]: v,
            _changes_made: true
        })
    }

    handleSelectChange(val) {
        console.log('selected', val)
        this.setState({
            selected: val,
            _changes_made: true
        })
    }

    render() {
        const {onClose, open, _existing, hideExisting=false, _allowed, _exclusions, _left_items = [], _title, _fields} = this.props;

        const {_saving_changes,_changes_made} = this.state;

        if (!open) {
            return null;
        }

        let body, footerRight, footerLeft;
        footerRight = <div className="flex space-x-2">
            <Button loading={_saving_changes} disabled={!_changes_made} text="Save Changes" intent='success' onClick={this.update.bind(this)}/>
        </div>;
        footerLeft = <div>
            {_left_items.map((item, i) => {
                return <Item key={i}>
                    {item}
                </Item>
            })}
        </div>;
        body = <Form handleSubmit={this.update.bind(this)}>

            {_fields.map((item) => {
                const {type, highlight, field, meta, autofocus=false, label, hide_filters=false, options = []} = item;

                if(type==='entities-filters') {
                    return <EntitiesFilters key={'ef'} hide_filters={hide_filters} hideExisting={true} _existing={_existing} updateEF={({entities})=>{this.setState({_changes_made:true,entities})}} />;
                } else if(type==='text-and-emoji') {
                    return <TextAndEmoji highlight={highlight} key={'tem'} label={'Name'} onBlur={()=>{}} placeholder='' changeEmoji={(emoji)=>{this.hc('emoji', emoji.native);}} emoji={this.state.emoji} text={this.state[field]} changeText={this.hc.bind(this, field)}/>;
                } else if(type==='admins') {
                    // need to convert obj to arr
                    return <NewEntitySelect hide_filter searchable={{members: true, roles: true}}
                                            context={{community_uid:this.context.uid}} onChange={(f,val)=>{
                        this.hc(f,val)
                    }} value={{entities:this.state.admins,filters:{}}} _existing={_existing} />;
                } else if(type==='info-message') {
                    return <div>
                        <InfoMessage {...item} />

                    </div>;
                } else if(type==='emoji-hidden') {
                    return null;
                }

                return <Field autoFocus={autofocus} meta={meta} _allowed={_allowed} _exclusions={_exclusions} _existing={_existing} hideExisting={hideExisting} data={item.data} options={options} key={field}
                                type={type} label={label} onChange={this.hc.bind(this, field)}
                                value={this.state[field]}/>
            })}

        </Form>;

        return (<Modal open={open} onClose={onClose} title={_title}
                         body={body}
                         footerLeft={footerLeft}
                         footerRight={footerRight}
            />
        );
    }
}