import {PB_type_names} from "../../../library/utilities/pinboard";
import {copyTextToClipboard} from "../../../library/utilities/general/copy-to-clipboard";
import {APP_BASE_URL} from "../../../config/defaults";

function buildIndexes(arr) {
    let obj = {};

    for (let i = 0; i < arr.length; i++) {
        const it = arr[i];

        if (it.label === 'Email') {
            obj['email'] = Object.keys(obj).length;
        } else if (it.label === 'Leader') {
            obj['leader'] = Object.keys(obj).length;
        } else {
            obj[it.label.toLowerCase()] = Object.keys(obj).length;
        }
    }

    return obj;
}

function handleOnClick(dt, history, addToast) {
    if (dt.type === 'email') {
        copyTextToClipboard(dt.value);
        addToast({text: "Copied to clipboard", intent: 'info'});
    } else if (dt.value.includes(APP_BASE_URL)) {
        history.push(dt.value.split(APP_BASE_URL)[1]);
    } else {
        window.open(dt.value, '_blank');
    }
}


export function group_buildPinsExtras(all, pins, pins_data, history, addToast) {
    let arr = [...all];
    let indexes = buildIndexes(arr);
    for (let i = 0; i < pins.length; i++) {
        const id = pins[i];
        const dt = pins_data[id];

        if (!dt) {
            continue;
        }

        const ind = dt.type === 'custom' ? indexes[dt.custom_category.toLowerCase()] : indexes[dt.type];


        if (ind !== null && ind !== undefined) {
            arr[ind].items.push({
                type: dt.type === 'email' ? 'email' : 'website',
                value: `[${dt.label}](${dt.value})`,
                onClick: (e) => handleOnClick(dt, history, addToast)
            });
        } else {

            arr.push({
                label: dt.type === 'custom' ? (dt.custom_category ? dt.custom_category : "") : PB_type_names[dt.type],
                items: [{
                    type: dt.type === 'email' ? 'email' : 'website',
                    value: `[${dt.label}](${dt.value})`,
                    onClick: (e) => handleOnClick(dt, history, addToast)
                }]
            });

            let index = Object.keys(indexes).length;

            if (index < 0) {
                index = 0
            }

            if (dt.type === 'custom') {
                indexes[dt.custom_category.toLowerCase()] = index;
            } else {
                indexes[dt.type] = index;
            }

        }
    }

    return arr;
}
