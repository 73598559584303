import React from 'react';
import {Card} from './card';
import styled from "styled-components";
import {HeartIcon} from '@heroicons/react/solid';
import {EntitiesPreview} from "../custom/entities-preview";

const Icon = styled.div`
      
    > svg {
        height: 16px;
    }
`;

export function InterestItem({emoji="🍣",user_uids,community_uid,onClick,is_interested=false,name="Sushi",number=829,id="sushi",active}) {

    const num = is_interested?(number-1):number;
    return <Card onClick={()=>onClick?onClick():null} fill interactive={!!onClick}>
        <div className="flex">
        <div className="flex-1">
            <div className="text-xs flex">
                {is_interested&&<Icon className="pr-1.5">
                    <div className="text-primary -mt-px w-4">
                    <HeartIcon />
                    </div>
                </Icon>}
                {is_interested&&<span className="text-gray-700 font-semibold">You&nbsp;</span>}
                {is_interested&&num!==0&&<span>and&nbsp;</span>}
                {num!==0&&<span className="text-gray-700 font-semibold">{num}</span>}{num!==0&&<span className="text-gray-600">&nbsp;other user{num===1?"":"s"}</span>}
            </div>
            <div className="text-lg font-semibold text-gray-700">Interested in <span className="relative"><span className="relative z-10 text-gray-800">{name}</span><span className="absolute h-1 rounded-md bottom-0.5 -left-0.5 -right-0.5 bg-gradient-to-br from-yellow-400 to-orange-500" /></span></div>
            <div className="mt-0.5">
                {user_uids&&<EntitiesPreview simple community_uid={community_uid} openEntities={()=>{}} toggleOpen={() => null} user_uids={user_uids} can_manage={false} />}
            </div>
        </div>
        <div className="flex-none flex items-center pr-3">
            {emoji&&<div className="text-6xl">{emoji}</div>}
        </div>
        </div>
    </Card>
}