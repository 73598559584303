import React from 'react';
import styled from "styled-components";
import {TrashIcon} from "@heroicons/react/solid";
import {NewTooltip} from "../reorganize/new-tooltip";


export function ContextualActions({actions = [], count = 0, layout = "footer"}) {
    if (actions.length === 0) {
        return null;
    }
    return <div className="fixed bottom-4 z-50 left-0 right-0">
        <div className="mx-auto max-w-md bg-gray-800 rounded-full py-1.5 px-6 flex items-center">
            <div className="text-sm text-gray-200 flex-grow">
                {count}&nbsp;selected
            </div>
            <div className=" flex space-x-4">
                {actions.map((act, k) => <div onClick={act.onClick} key={k}
                                              className=" rounded-md flex items-center flex-col text-white">
                    <div
                        className="h-8 flex px-2 items-center justify-center rounded-md hover:bg-gray-600 transition-colors cursor-pointer">
                        <div className="h-5 w-5">
                            {act.icon}
                        </div>
                        <div className="h-5 ml-1.5 text-sm">
                            {act.label}
                        </div>
                    </div>
                </div>)}
            </div>
        </div>
    </div>
}