import React, {Component, useState} from "react";


import {PageContainer} from "../../../../../library/components/old/page-container";

import {setDocumentTitle} from "../../../../../library/utilities/set-document-title";
import {AdvancedFilter} from "../../../../../library/components/old/advanced-filter";
import {AdminEntityInfo, Button, PageHeader} from "../../../../../library/components/core";
import {FirebaseDB} from "../../../../../config/setup-firestore";
import {CommunityContext} from "../../../community-context";
import {TagIcon} from '@heroicons/react/solid';
import {FilterIcon, TrashIcon} from "@heroicons/react/solid";

export default class UpdateFilter extends Component {
    static contextType = CommunityContext;
    constructor(props) {
        super(props);

        this.id = props.match.params.id;
        this.state = {
            loading: true,
            init_data: {}
        };
    }

    componentDidMount() {
        this.getFilter();
        setDocumentTitle('Filters', this.context.data.name);
    }

    getFilter() {
        this.id = this.props.match.params.id;
        FirebaseDB
            .collection('community_entities')
            .doc(this.context.uid)
            .collection('filters')
            .doc(this.id)
            .get()
            .then(this.handleFilter.bind(this));
    }

    handleFilter(doc) {
        if (!doc.exists) {
            this.setState({
                loading: false
            })
        } else {
            let document = doc.data();
            document.id = doc.id;
            setDocumentTitle(document.name,this.context.data.name);
            this.setState({
                loading: false,
                init_data: document
            });
        }
    }

    render() {
        const {init_data, loading} = this.state;
        const {history, handle} = this.props;

        if(loading) {
            return null;
        }

        const nav = {
            can_go_back: false,
            history: this.props.history,
            breadcrumbs: [
                {
                    label: "Community Settings",
                    path: `/${this.props.match.params.handle}/admin/settings`
                },
                {
                    label: "Filters",
                    path: `/${this.props.match.params.handle}/admin/settings/filters`
                },
                {
                    label: init_data.name,
                    path: `#`
                }
            ]
        };

        const action = {
            button_text: 'Update Filter',
            api: '/filters/update',
            cb: ()=>{
                this.props.actions.addToast({text: "Changes were saved", intent: 'success'});
            },
            delete_text: 'Delete Filter',
            delete_api: '/filters/delete',
            delete_cb: ()=>{
                history.push(`/${this.props.handle}/admin/settings/filters`);
                this.props.actions.addToast({text: "Filter was deleted", intent: 'success'});
            }
        };

        return <PageContainer nav={nav}>
            <AdminEntityInfo type_icon={<FilterIcon/>} id={this.id} type_label={"Filter"} />

            <PageHeader title={init_data.name} size="2xl" action={<div className="space-x-2 flex">
                <Button text="Delete" left_icon={<TrashIcon/>} />
            </div>} />

            <AdvancedFilter actions={this.props.actions} history={history} action={action} data={init_data} id={this.props.match.params.id}
                                handle={handle} community_uid={this.context.uid}/>

        </PageContainer>
    }
}