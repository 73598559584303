import React from 'react';
import {NewFeedItem} from "./index";
import {buildNFIMocks} from "./mock-items";
import {useHistory} from "react-router-dom";

function processItems(items) {
    return items;
}

export function NFIMockWrapper({messages,show_context=true,activity_meta,actions,context={},debug=false}) {
    const items = buildNFIMocks(messages,context,{show_context});
    const history = useHistory();

    const final = processItems(items);
    console.log('FINAL',final,show_context)
    return <div className="divide-y divide-gray-200">
        {final.map(it=>{
            return <NewFeedItem activity_meta={activity_meta} history={history} actions={actions} context={context} debug={debug} key={it.id} {...it} />
        })}
    </div>
}