import {advt2_getDefinitions} from "../../../../playground/advanced-table/build-definitions";
import {account_status_tags, data_integrity_tags} from "../../../../playground/advanced-table/wrapper";

export function buildListColumns(properties, definitions, context) {
    const new_defs = advt2_getDefinitions(context, {
        data_integrity_tags: data_integrity_tags,
        default_profile_picture: context.default_profile_picture,
        account_status_tags: account_status_tags
    },properties,definitions,context);
    let a = [
        {
            Header: 'Name',
            accessor: 'name',
            minWidth: 50,
            maxWidth: 400,
            width: 240,
            sortable: 'about.last_name',
            field_type: "name",
            options: {
                image: 'profile_picture',
                default_profile_picture: context.default_profile_picture
            }
        }
    ];

    const active = properties.filter(p=>p.active).map(a=>a.id);

    active.forEach(vc_id=>{
        const dt = new_defs.fields[vc_id];
        if(vc_id==='name') {
            return;
        }

        if(!dt) {
            console.error('ERROR',vc_id)
        } else {
            a.push(dt);
        }

    });

    // add last blank column

    a.push({
        Header: '',
        accessor: '_add_field',
        minWidth: 150,
        maxWidth: 200,
        width: 150,
        field_type: "_add_field",
        options: {

        }
    });

    return a;
}