import React from 'react';
import {FeedItemPostContainer} from "../feed-item";
import {EntityInfo} from "../../../utilities/localstorage/entity-localstorage";

function getType(md,first) {
    if(md.auto_added[first]) {
        return 'added';
    } else if(md.left[first]) {
        return 'left';
    } else if(md.removed[first]) {
        return 'left';
    } else if(md.added[first]) {
        return 'added';
    } else {
        return 'joined';
    }
}

function getMemberChangesData(metadata) {
    let post_author_id = metadata.first;
    let init = "";

    const obj = {
        joined: Object.entries(metadata.joined),
        left: Object.entries(metadata.left).concat(Object.entries(metadata.removed)),
        added: Object.entries(metadata.added).concat(Object.entries(metadata.auto_added))
    };

    const first_type = getType(metadata,metadata.first);

    let types = [
        first_type
    ];

    // AUTHOR NAME
    if(first_type==='joined') {
        // joined GROUP
        types.push('added');
        types.push('left');
    } else if(first_type==='left') {
        types.push('joined');
        types.push('added');
    } else if(first_type==='added') {
        // was added to '
        // we're added to...
        types.push('joined');
        types.push('left');
    }

    let items = [];

    types.forEach((typ,k)=>{

        const type_items = obj[typ];

        if(!obj[typ]) {
            console.error('ERROR',typ)
            return {
                items,
                first_type,
                author_id: post_author_id
            };
        }

        if(type_items.length>0) {
            items[k] = {
                flow: typ,
                items: []
            }

            if(k===0) {
                items[k].items.push("");
            }

            type_items.forEach(ti=>{
                if(ti[0]!==post_author_id) {
                    items[k].items.push(ti[0])
                }
            });
        }
    })

    return {
        items: items,
        first_type,
        author_id: post_author_id
    }
}

// 1 added
function MemberName({data}) {
    return <span className="">
        {data.name}
    </span>
}

function MemberItem({cid,id}) {
    return <EntityInfo community_uid={cid} id={id} type="members"><MemberName /></EntityInfo>
}

function GN({n}) {
    return <span className="font-medium">
        {n}
    </span>
}

function ActionDescription({community_uid,also=false,members=["1","2"],group,flow='added',period=false}) {
    const len = members.length;
    let p1, p2, p3, p4;
    const cid = community_uid;
    p2 = <GN n={group.name} />;
    if(flow==='added') {
        if(also) {
            if(len===1) {
                p1 = <span><MemberItem cid={cid} id={members[0]} /> was added</span>;
            } else if(len===2) {
                p1 = <span><MemberItem cid={cid} id={members[0]} /> and <MemberItem cid={cid} id={members[1]} /> were added</span>;
            } else if(len===3) {
                p1 = <span><MemberItem cid={cid} id={members[0]} />, <MemberItem cid={cid} id={members[1]} />, and 1 other was added</span>;
            } else {
                // more
                p1 = <span><MemberItem cid={cid} id={members[0]} />, <MemberItem cid={cid} id={members[1]} />, and {len-2} others were added</span>;
            }
        } else {
            if (len === 1) {
                p1 = <span>was added to {p2}</span>;
            } else if (len === 2) {
                p1 = <span>and <MemberItem cid={cid} id={members[1]} /> were added to {p2}</span>;
            } else if (len === 3) {
                p1 = <span>was added to {p2} with <MemberItem cid={cid} id={members[1]} /> and 1 other</span>;
            } else {
                // more
                p1 = <span>was added to {p2} along with <MemberItem cid={cid} id={members[1]} /> and {len-2} others</span>;
            }
        }
    } else if(flow==='auto_added') {
        if(also) {
            if(len===1) {
                p1 = <span><MemberItem cid={cid} id={members[0]} /> was added</span>;
            } else if(len===2) {
                p1 = <span><MemberItem cid={cid} id={members[0]} /> and <MemberItem cid={cid} id={members[1]} /> were added</span>;
            } else if(len===3) {
                p1 = <span><MemberItem cid={cid} id={members[0]} />, <MemberItem cid={cid} id={members[1]} />, and 1 other was added</span>;
            } else {
                // more
                p1 = <span><MemberItem cid={cid} id={members[0]} />, <MemberItem cid={cid} id={members[1]} />, and {len-2} others were added</span>;
            }
        } else {
            if (len === 1) {
                p1 = <span>was added to {p2}</span>;
            } else if (len === 2) {
                p1 = <span>and <MemberItem cid={cid} id={members[1]} /> were added to {p2}</span>;
            } else if (len === 3) {
                p1 = <span>was added to {p2} with <MemberItem cid={cid} id={members[1]} /> and 1 other</span>;
            } else {
                // more
                p1 = <span>was added to {p2} along with <MemberItem cid={cid} id={members[1]} /> and {len-2} others</span>;
            }
        }
    } else if(flow==='joined') {
        if(also) {
            if(len===1) {
                p1 = <span><MemberItem cid={cid} id={members[0]} /> joined</span>;
            } else if(len===2) {
                p1 = <span><MemberItem cid={cid} id={members[0]} /> and <MemberItem cid={cid} id={members[1]} /> joined</span>;
            } else if(len===3) {
                p1 = <span><MemberItem cid={cid} id={members[0]} />, <MemberItem cid={cid} id={members[1]} />, and 1 other joined</span>;
            } else {
                // more
                p1 = <span><MemberItem cid={cid} id={members[0]} />, <MemberItem cid={cid} id={members[1]} />, and {len-2} others joined</span>;
            }
        } else {
            if(len===1) {
                p1 = <span>joined {p2}</span>;
            } else if(len===2) {
                p1 = <span>and <MemberItem cid={cid} id={members[1]} /> joined {p2}</span>;
            } else if(len===3) {
                p1 = <span>joined {p2} with <MemberItem cid={cid} id={members[1]} /> and 1 other</span>;
            } else {
                // more
                p1 = <span>joined {p2} along with <MemberItem cid={cid} id={members[1]} /> and {len-2} others</span>;
            }
        }
    } else if(flow==='left') {
        if(also) {
            if(len===1) {
                p1 = <span><MemberItem cid={cid} id={members[0]} /> left</span>;
            } else if(len===2) {
                p1 = <span><MemberItem cid={cid} id={members[0]} /> and <MemberItem cid={cid} id={members[1]} /> left</span>;
            } else if(len===3) {
                p1 = <span><MemberItem cid={cid} id={members[0]} />, <MemberItem cid={cid} id={members[1]} />, and 1 other left</span>;
            } else {
                // more
                p1 = <span><MemberItem cid={cid} id={members[0]} />, <MemberItem cid={cid} id={members[1]} />, and {len-2} others left</span>;
            }
        } else {
            if (len === 1) {
                p1 = <span>left {p2}</span>;
            } else if (len === 2) {
                p1 = <span>and <MemberItem cid={cid} id={members[1]} /> left {p2}</span>;
            } else if (len === 3) {
                p1 = <span>left {p2} with <MemberItem cid={cid} id={members[1]} /> and 1 other</span>;
            } else {
                // more
                p1 = <span>left {p2} along with <MemberItem cid={cid} id={members[1]} /> and {len-2} others</span>;
            }
        }
    } else if(flow==='removed') {
        if(also) {
            if(len===1) {
                p1 = <span><MemberItem cid={cid} id={members[0]} /> left</span>;
            } else if(len===2) {
                p1 = <span><MemberItem cid={cid} id={members[0]} /> and <MemberItem cid={cid} id={members[1]} /> left</span>;
            } else if(len===3) {
                p1 = <span><MemberItem cid={cid} id={members[0]} />, <MemberItem cid={cid} id={members[1]} />, and 1 other left</span>;
            } else {
                // more
                p1 = <span><MemberItem cid={cid} id={members[0]} />, <MemberItem cid={cid} id={members[1]} />, and {len-2} others left</span>;
            }
        } else {
            if (len === 1) {
                p1 = <span>left {p2}</span>;
            } else if (len === 2) {
                p1 = <span>and <MemberItem cid={cid} id={members[1]} /> left {p2}</span>;
            } else if (len === 3) {
                p1 = <span>left {p2} with <MemberItem cid={cid} id={members[1]} /> and 1 other</span>;
            } else {
                // more
                p1 = <span>left {p2} along with <MemberItem cid={cid} id={members[1]} /> and {len-2} others</span>;
            }
        }
    }

    return <span>
        {also&&<span>&nbsp;Also,&nbsp;</span>}
        {p1}
        {period&&"."}
    </span>
}

export function ActivityItemMemberChanges(props) {

    const {metadata,activity_meta} = props;

const feed_obj = getMemberChangesData(metadata);

    return <FeedItemPostContainer {...props} author_id={feed_obj.author_id}>
        <div style={{fontSize:'14px',lineHeight:1.4}} className="text-gray-700 pt-px line-clamp-3">
            {feed_obj.items.map((it,k)=>{
                return <ActionDescription also={k>0} members={it.items} flow={it.flow} period={feed_obj.items.length>1} key={k} community_uid={activity_meta.community.uid} group={activity_meta.group}  />
            })}
        </div>
    </FeedItemPostContainer>;
}