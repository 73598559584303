import React from "react";
import {UserTermsText} from "./user-terms-text";
import {
    AuthTitle,
    SplashContainer,
    SplashInner,
    SplashTextContainer
} from "../../layout/splash-container";

export function PoliciesUserTerms() {

    return <SplashContainer>
        <SplashInner>
            <SplashTextContainer>
                <AuthTitle>User Terms</AuthTitle>
                <UserTermsText/>
            </SplashTextContainer>
        </SplashInner>
    </SplashContainer>
}