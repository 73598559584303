import React from 'react';
import Modal from "../../old/modal";
import {isValidURL} from "../../../utilities/pinboard";
import {Button, Field, Input} from "../../core";

function getFinalLinkUrl(sl) {
    if(!sl) {
        return sl;
    }
    if(sl.includes('.')&&!sl.includes('http')&&!sl.includes('//')) {
        sl = `https://${sl}`;
    }
    return sl;
}

export function AddLinkModal({meta,handleMetaChange,onAdd=()=>{},onClose=()=>{}}) {
const final = getFinalLinkUrl(meta.selection_link);
    const disabled = !isValidURL(final)||!final;
    const body = <div className="space-y-4">
        <Field label="Link URL">
            <Input onEnter={(e)=>{
                if(e) {
                    e.preventDefault();
                    e.stopPropagation();
                }
                if(!disabled) {
                    onAdd();
                }
            }} placeholder="Website URL" autoFocus onChange={(v)=> {
                handleMetaChange({
                    selection_link: v
                });
            }} value={meta.selection_link} />
        </Field>
        <Field label="Link Text">
             <Input placeholder="" onChange={(v)=> {
                handleMetaChange({
                    selection_text: v
                });
            }} value={meta.selection_text} />
        </Field>
    </div>;

    const buttons = <div>
        <Button onClick={()=>{
            onAdd();
        }} text="Add Link" intent="success" disabled={disabled} />
    </div>;

    const left_button = <div>

    </div>;

    return <Modal size="small" title={"Add Link"} body={body} footerRight={buttons} footerLeft={left_button}
                  onClose={onClose} open={true} lazy />
}