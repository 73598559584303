import React from 'react';
import {DetailModal} from "../playground/detail-modal";

export function ViewMembersListModal({
                                         tabs=[],
                                         onClose,
    start_tab=0,
                                         title = "Member Likes"
                                     }) {

    return <DetailModal start_tab={start_tab} tabs={tabs} title={title} onClose={onClose} />
}