import React, {useState} from "react";
import {Button, Field, RichSelect} from "./core";
import Modal from "./old/modal";

export function EditAttributeMany({onClose=()=>{},handleContinue=()=>{}}) {

    const [attribute,setAttribute] = useState("");
    const [value,setValue] = useState("");

    const body = <div className="space-y-4">
        <Field label="Attribute">

        </Field>
        <Field label="Value">

        </Field>
    </div>;

    function proceed() {
        handleContinue({attribute,value});
    }

    return <Modal size={'small'} open={true} onClose={onClose} title={'Edit Attribute'}
                  body={body}
                  footerLeft={null}
                  footerRight={<div>
                     <Button text="Continue" intent="secondary" onClick={()=>proceed()} />
                  </div>}
    />
}