import React, {useContext, useState} from 'react';
import {Button, Field, InfoState, Input, TextArea} from "../../core";
import {authFetch} from "../../../../config/network";
import {GlobalContext} from "../../../../app/global/global-context";
import {Link} from "react-router-dom";

function getMessage(name,group) {
    if(group) {
        return `Why do you want to join ${group.name}?`
    }
    return `Why do you want to join ${name}?`
}

export async function applyToJoinCommunity(community_uid="",user_uid="",group_id="",message="") {
    return await new Promise((resolve, reject) => {
        const r = () => {
            resolve(true);
        };

        const payload = {
            community_uid: community_uid,
            user_uid: user_uid,
            group_id: group_id||"",
            message
        };

        authFetch('/apply-to-join-community', r, r, "POST", {payload});
    });
}
export default function ApplyToJoin({community_uid='',group,group_id,name='',cb=()=>{},user_uid}) {
    const global = useContext(GlobalContext);
    const [loading,setLoading] = useState("");
    const [message,setMessage] = useState("");

    function handleApply() {
        setLoading('applying');
        applyToJoinCommunity(community_uid,global.user_uid,group_id,message)
            .then(()=>{
                cb();
            })
    }

    return <div className="max-w-md space-y-2">
        <Field label="Message">
            <TextArea rows={3} value={message} onChange={v=>setMessage(v)} placeholder={getMessage(name,group)} />
        </Field>
        <Button intent="secondary" fill text="Apply to Join" loading={loading==='applying'} onClick={()=>handleApply()} disabled={!message} />

        <div className="pt-4 text-xs text-gray-700">
            Already have an account? Contact <a href="mailto:hey@getunaty.com" className="color-link">Unaty Support</a>
        </div>

        <div className="pt-4 text-xs text-gray-700">
            Back to your <Link to="/memberships" className="color-link">Memberships</Link>
        </div>
    </div>
}