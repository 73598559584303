import React, {useState} from "react";
import {ChevronDownIcon, ChevronLeftIcon} from "@heroicons/react/solid";

export function AdminEntitySummary({items=[]}) {
const [expanded,setExpanded] = useState({});
function toggleSection(k) {
    let ne = {...expanded};
    if(expanded[k]) {
        delete ne[k];
    } else {
        ne[k] = true;
    }
    setExpanded(ne);
}
    return <div className="space-y-1.5">
            {items.map((it, k) => <div key={`${k}-${it.title}`}>
                <div className={`grid gap-1 `}
                                       style={{minHeight: '1.75rem', gridTemplateColumns: "8% 38% 54%"}} key={k}>
                <div className="w-5 flex h-6 pt-1 items-start justify-center text-gray-500">
                    <div className="flex items-center justify-center h-6 h-svg-5" style={{height: '1.1rem', width: '1.1rem'}}>
                        {it.icon}
                    </div>
                </div>
                <div className="leading-5 flex items-start pt-1 text-sm text-gray-600">
                    <span>{it.title}</span>
                    {it.expandable&&<span onClick={()=>{
                    toggleSection(k)
                    }} className="w-4 h-5 cursor-pointer hover:opacity-80 ml-1 flex items-center">{expanded[k]?<ChevronDownIcon />:<ChevronLeftIcon />}</span>}
                </div>
                <div onClick={()=>{
                    if(it.expandable) {
                        toggleSection(k)
                    }
                }} style={{maxWidth:'246px'}} className={`flex overflow-x-hidden items-center text-sm font-medium text-gray-600 ${it.badge ? "-mx-1.5" : ""}`}>
                    {it.comp}
                </div>
            </div>
                {expanded[k]&&<div className={``} style={{paddingLeft:'1.70rem',paddingRight:'1.7rem'}}>
                    {it.expanded}
                </div>}
            </div>)}
        </div>
}