import React, {useContext, useState} from "react";
import {Button, EntityHeader, Tabs} from "../../../../library/components/core";
import {ListMembersEntity} from "../../reorganize/entities";
import {CommunityContext} from "../../community-context";
import {GroupMembersModal} from "../members-modal";
import {GMMMembers} from "../members-modal/members";
import {GMMModerators} from "../members-modal/moderators";
import {GMMSegments} from "../members-modal/segments";


export function GroupHeader({
                                is_member = false,
                                can_manage = false,
                                is_admin = false,
                                is_moderator = false,
                                is_mobile = false,
                                addEntities = () => {},
                                addRolesAsModerators = () => {},
                                handleMakeMemberModerator = () => {},
                                updateRolesOrder = () => {},
                                removeMembersAsModerators = () => {},
                                handleMakeRoleModerator = () => {},
                                removeModerator = () => {},
                                removeEntity = () => {},
                                onToggleMenu = () => {},
                                onOpenAddEntities = () => {},
                                onLeaveGroup = () => {
                                },
                                data,
                                id
                            }) {
    const [modal,setModal] = useState(null);
    const community = useContext(CommunityContext);

    let right_elements = [];

    let right_menu_options = [];

    if (can_manage) {
        right_elements.push({
            key: 'members',
            comp: <ListMembersEntity title="Members" type_name="Members"
                                     openEntities={() => setModal(true)}
                                     data={data}
                                     can_manage={can_manage}
                                     is_open={false}
                                     community_uid={community.uid}/>
        });
    }

    if ((is_admin || is_moderator) && !is_mobile) {
        right_elements.push({
            key: 'manage',
            comp: <div className="h-8 flex items-center justify-center">
                <Button onClick={() => onToggleMenu()} size="sm" text="Settings" intent="secondary" minimal/>
            </div>
        })
    }

    if (is_moderator || (data.type === 'team' && community.is_admin) || is_admin) {
// note
    } else if (is_member && data.type !== 'team') {
        right_menu_options.push({
            type: 'item',
            intent: 'danger',
            title: 'Leave Group',
            onClick: () => onLeaveGroup()
        });
    }

    const members_count = Object.keys(data.user_uids).length;
    const mods_count = Object.keys(data.moderators.user_uids).length;

    let member_tabs = [
        {
            label: `Moderators`,
            label_count: mods_count>0?mods_count:null,
            type: "custom",
            component: <GMMModerators updateRolesOrder={updateRolesOrder} addRolesAsModerators={addRolesAsModerators} removeMembersAsModerators={removeMembersAsModerators} removeModerator={removeModerator} openAddMembers={()=>{}} id={id} data={data} can_manage={can_manage} removeEntity={removeEntity} />
        },
        {
            label: `Members`,
            label_count: members_count>0?members_count:null,
            type: "custom",
            component: <GMMMembers handleMakeMemberModerator={handleMakeMemberModerator} addEntities={addEntities} openAddMembers={onOpenAddEntities} id={id} data={data} can_manage={can_manage} removeEntity={removeEntity} />
        }
    ];

    if(is_moderator||is_admin) {
        member_tabs.push({
            label: `Rules`,
            type: "custom",
            component: <GMMSegments updateRolesOrder={updateRolesOrder} handleMakeRoleModerator={handleMakeRoleModerator} openAddMembers={()=>onOpenAddEntities()} id={id} data={data} can_manage={can_manage} removeEntity={removeEntity} />
        });
    }

    return <div className={`h-12`}>
        <EntityHeader single onTitleClick={()=>{
            onToggleMenu();
        }} title={data.name} actions={
            <div>
                {right_elements.length > 0 && <div className="space-x-1.5 flex">
                    {right_elements.map((e,p) => {
                        return <div className="h-10 flex items-center" key={`${e.key}-${p}`}>
                            {e.comp}
                        </div>
                    })}
                </div>}
            </div>
        } />

        {modal&&<GroupMembersModal title={`${data.name} Members`} can_manage={can_manage} tabs={member_tabs} onClose={()=>setModal(null)} start_tab={1} />}
    </div>
}