import React, {useContext} from 'react';
import {ActionWithIcon, SectionHeader, SimpleTable} from "../../../library/components/core";
import {DownloadIcon, LinkIcon} from "@heroicons/react/solid";
import {copyTextToClipboard} from "../../../library/utilities/general/copy-to-clipboard";
import {APP_BASE_URL} from "../../../config/defaults";
import {GlobalContext} from "../../global/global-context";
import {CommunityContext} from "../community-context";
import {getFileTypeIcon} from "../../../library/components/old/file-uploader/helpers";

export function PostAttachments(props) {
    const community = useContext(CommunityContext);
    const global = useContext(GlobalContext);

    const files = props.attachments.map(attach => {
        return {
            id: attach.id,
            icon: getFileTypeIcon(attach.type),
            type: 'file',
            data: {
                link: attach.url,
                folder: attach.folder,
                name: attach.name
            }
        }
    })
    return <div>
        <SectionHeader title="Attachments"/>

        <SimpleTable actions={[{
            label: 'Copy Link',
            icon: <LinkIcon/>,
            types: ['folder', 'file'],
            onClick: (id, entry) => {
                const path = `/${community.handle}/group/${props.group_id}/drive/folder/${entry.data.folder}/file/${id}`;
                copyTextToClipboard(`${APP_BASE_URL}${path}`);
                global.addToast({text: "Link copied to clipboard!", intent: 'success'});
            }
        },
            {
                label: 'Download',
                icon: <DownloadIcon/>,
                types: ['file'],
                onClick: (id, entry) => {
                    window.open(entry.data.link, '_blank');
                }
            }]} onItemClick={(id, entry) => {
            global.openFileViewer(id, props.attachments);
        }} items={files} headers={[{
            width: '2fr',
            label: 'Name',
            field: 'name',
            title: true
        }]} hide_headers={true} can_select={false}/>
    </div>
}