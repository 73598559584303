import React, {useContext, useEffect, useState} from "react";
import {useHistory} from 'react-router-dom';
import {PageContainer} from "../../../library/components/old/page-container";

import {setDocumentTitle} from "../../../library/utilities/set-document-title";

import {CommunityContext} from "../community-context";
import {EntityHeader, Field, InfoState, PageHeader, Tabs} from "../../../library/components/core";
import {PolicyCard} from "./item";
import {FirebaseDB} from "../../../config/setup-firestore";
import {StandardPageFramework, StandardPageHeight} from "../../../library/playground/standard-page-framework";

export default function CommunityPolicies(props) {
    const community = useContext(CommunityContext);
    const [tab, setTab] = useState("All");
    const [loading, setLoading] = useState("policies");
    const [policies, setPolicies] = useState([]);
    const history = useHistory();
    setDocumentTitle('Policies', community.data.name);

    const title = "Policies";

    useEffect(function () {
        getPolicies();
    }, [community.uid]);

    function getPolicies() {
        // add id
        const admin = community.is_admin;

        const op = admin?'in':'==';
        const val = admin?['published','draft']:'published';

        FirebaseDB
            .collection('communities')
            .doc(community.uid)
            .collection('policies')
            .where('status',op,val)
            .get()
            .then(snap=>{
                setLoading('');
                setPolicies(snap.docs.map(doc=>{
                    return {
                        id: doc.id,
                        ...doc.data()
                    }
                }))
            })
    }

    return (<StandardPageHeight content full>
            <EntityHeader title="Policies" />
            <div className="pt-2 pb-12 px-4 grid gap-4 grid-cols-3">
                {policies.map((pol,i)=><PolicyCard community={community} history={history} data={pol} key={pol.id} />)}
                {policies.length===0&&loading===''&&<InfoState intent="secondary" subtitle="No policies found"/>}
            </div>
        </StandardPageHeight>
    );
}