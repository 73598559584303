import {authFetch} from "../../../config/network";

export async function api_setDefaultView(community,view) {
    return await new Promise((resolve, reject) => {
        const res = (r) => {
            resolve(r);
        };
        const payload = {
            community_uid: community.uid,
            member_id: community.member_id,
            meta_id: 'directory-default',
            meta_update: view
        };
        authFetch("/communities/update-meta-doc", res, res, "POST", {payload});
    })
}