import React, {Component} from "react";

export default class Form extends Component {
    constructor(props) {
        super(props);

        this.state = {}
    }

    render() {
        const {children,handleSubmit} = this.props;

        return (
            <form action="/" autoComplete='on' onSubmit={(e)=>{e.preventDefault();handleSubmit();}} className='main-form'>
                {children}
                <input type="submit" style={{display:'none'}} />
            </form>
        );
    }
}