import React, {Component} from "react";
import styled from "styled-components";
import {components} from 'react-select';
import Select from "react-select/async";
import {FirebaseDB} from "../../../config/setup-firestore";
import {getSmallEntity} from "../../../library/utilities/localstorage/entity-localstorage";
import {XIcon} from "@heroicons/react/solid";

const Image = styled.div`
height: 30px;
    width: 30px;
    border-radius: 6px 0px 0px 6px;
        margin-right: 8px;
    background-position: center;
    background-size: cover;
`;

const Inner = styled.div`
`;

const RemoveItem = styled.div`

> div {
height: 30px;
    padding-right: 6px;
    padding-left: 2px;
    cursor: pointer;
        border: none;
    border-radius: 0px 6px 6px 0px;
    > svg {
    height: 16px;
    }
}
`;

const InputWrapper = styled.div`
    > div {
        box-shadow: rgba(0,0,0,0) 0px 0px 0px 0px, rgba(58,151,212,0.36) 0px 0px 0px 4px, rgba(0,0,0,0) 0px 0px 0px 0px, rgba(60,66,87,0.16) 0px 0px 0px 1px, rgba(0,0,0,0) 0px 0px 0px 0px, rgba(0,0,0,0) 0px 0px 0px 0px, rgba(0,0,0,0) 0px 0px 0px 0px;
    }
`;

const ControlComponent = props => (
    <InputWrapper>
        <components.Control {...props} />
    </InputWrapper>
);

const MultiValueContainer = props => {
    return (
        <components.MultiValueContainer {...props} />
    );
};

function getComponentImage({image}) {
    if(image!=='') {
        return <Image style={{backgroundImage: `url('${image}_small?alt=media')`}}/>
    }
    return <Image style={{background: 'lightgray'}}/>
}

const MultiValueLabel = props => {
    return (
        <Inner className="mr-0.5 leading-5 flex items-center text-sm font-medium text-gray-700">
            {getComponentImage(props.data)}
            {props.data.label}
        </Inner>
    );
};

const MultiValueRemove = props => {
    return (
        <RemoveItem className="text-gray-600">
        <components.MultiValueRemove {...props}>
            <XIcon/>
        </components.MultiValueRemove>
        </RemoveItem>
    );
};


const SelectContainer = ({children, ...props}) => {
    return (
        <components.SelectContainer {...props}>
            {children}
        </components.SelectContainer>
    );
};


export default class CommunitySearch extends Component {
    constructor(props) {
        super(props);

        this.state = {
            inputValue: '',
            searching: false,
            results: []
        };
    }

    handleInputChange = (newValue) => {
        this.setState({inputValue: newValue});
    };

    onChange = (v, a) => {
        this.props.onChange(v);
    };

    queryDB(query_text, queries, community_uid, callback) {
        const {
            exclusions = {
                'members': {
                    // 'tJkv2pjuvqZthYWJSIBiLzhc5im2': true
                },
                'roles': {},
                'teams': {},
            },
            _allowed
        } = this.props;


        let refs = queries.map(query => {
            return FirebaseDB
                .collection('search')
                .doc(community_uid)
                .collection(query.type)
                .orderBy(query.field)
                .startAt(query_text)
                .endAt(query_text + "\uf8ff")
                .get();
        });

        Promise.all(refs)
            .then(snaps => {
                let items = {};
                snaps.map(snap => {

                    snap.docs.map(doc => {

                        let obj = doc.data();
                        obj.id = doc.id;
                        let real_id = `${obj.type}-${obj.id}`;
                        let exclude_obj = exclusions[`${obj.type}s`] ? exclusions[`${obj.type}s`] : {};
                        if (!exclude_obj[obj.id]) {

                            if(_allowed) {

                                if(_allowed[`${obj.type}s`]) {
                                    if(_allowed[`${obj.type}s`][obj.id]) {

                                        items[real_id] = {
                                            value: real_id,
                                            handle: obj.handle,
                                            image: obj.profile_picture,
                                            _type: obj.type,
                                            label: obj.name
                                        };
                                    }
                                } else {
                                    items[real_id] = {
                                        value: real_id,
                                        handle: obj.handle,
                                        image: obj.profile_picture,
                                        _type: obj.type,
                                        label: obj.name
                                    };
                                }
                            } else {
                                items[real_id] = {
                                    value: real_id,
                                    handle: obj.handle,
                                    image: obj.profile_picture,
                                    _type: obj.type,
                                    label: obj.name
                                };
                            }
                        }
                    })
                });
                let results = Object.values(items);
                this.setState({
                    searching: false,
                    results
                });
                callback(results);
            })
            .catch(error => console.log(error))
    }

    loadOptions = (inputValue, callback) => {
        if (inputValue.length < 3) {
            callback(this.state.results);
            return;
        }
        let {
            searchable = ['members']
        } = this.props;
        if (this.state.searching) {
            callback(this.state.results);
            return;
        }
        this.setState({
            searching: true
        });


        let queries = [];
        if (searchable.includes('members')) {
            queries.push({
                ctx: 'members_first_name',
                type: 'members',
                field: 'first_name'
            });
            queries.push({
                ctx: 'members_handle',
                type: 'members',
                field: 'handle'
            });
            queries.push({
                ctx: 'members_last_name',
                type: 'members',
                field: 'last_name'
            });
        }
        if (searchable.includes('roles')) {

        }

        this.queryDB(inputValue.toLowerCase(), queries, this.props.community_uid, callback);
    };

    getSelectValue(sel,isMulti) {
        // todo adjust for admins
        if(!isMulti) {
            if(sel!=='') {
                const entity = getSmallEntity(this.props.community_uid, 'member', sel, 'community_members');
                return {
                    handle: entity.handle,
                    _type: 'member',
                    image: entity.image,
                    label: entity.name,
                    value: `member-${sel}`
                };
            }
        } else if(this.props.isMultiMember) {
            let arr = [];
            const keys = Object.keys(sel);
            for(let i=0;i<keys.length;i++) {
                const entity = getSmallEntity(this.props.community_uid, 'member', keys[i], 'community_members');
                arr.push({
                    handle: entity.handle,
                    _type: 'member',
                    image: entity.image,
                    label: entity.name,
                    value: `member-${keys[i]}`
                })
            }
            return arr;
        } else {
            return sel;
        }
    }

    render() {
        const {selected, autoFocus = false, clearable=false, isMulti=true} = this.props;
        const {results} = this.state;

        return (
            <Select onChange={this.onChange} autoFocus={autoFocus} options={results} value={this.getSelectValue(selected,isMulti)} isMulti={isMulti}
                    cacheOptions defaultOptions={results} isClearable={clearable}
                    components={{MultiValueLabel, SelectContainer, ControlComponent, MultiValueRemove, MultiValueContainer}}
                    styles={{
                        multiValue: (base, state) => ({
                            ...base,
                            border: `none`,
                            height: '30px',
                            color: 'black',
                            borderRadius: '6px',
                            background: "rgba(134,181,238,0.24)",
                        }),
                        container: base => ({
                            ...base,
                        }),
                        control: (base, state) => ({
                            ...base,
                            boxShadow: state.isFocused ? 'rgba(0,0,0,0) 0px 0px 0px 0px, rgba(58,151,212,0.36) 0px 0px 0px 4px, rgba(0,0,0,0) 0px 0px 0px 0px, rgba(60,66,87,0.16) 0px 0px 0px 1px, rgba(0,0,0,0) 0px 0px 0px 0px, rgba(0,0,0,0) 0px 0px 0px 0px, rgba(0,0,0,0) 0px 0px 0px 0px' : 'rgba(0,0,0,0) 0px 0px 0px 0px, rgba(0,0,0,0) 0px 0px 0px 0px, rgba(0,0,0,0) 0px 0px 0px 0px, rgba(60,66,87,0.16) 0px 0px 0px 1px, rgba(0,0,0,0) 0px 0px 0px 0px, rgba(0,0,0,0) 0px 0px 0px 0px, rgba(0,0,0,0) 0px 0px 0px 0px;',
                            border: 'none',
                            minHeight: '46px'
                        })
                    }}
                    loadOptions={this.loadOptions} onInputChange={this.handleInputChange}/>
        );
    }
}