import React, {useEffect, useState} from 'react';

function sanitize(v) {
    return v ? v.replace(/\/n/g,'') : '';
}

export function TextArea({value, minimal, fill, max=null, onEnter=()=>{}, onEmptyBackspace=()=>{}, autoFocus=false, onChange, rows=3, placeholder = "", name = "", id = ""}) {
    const [val, setVal] = useState(sanitize(value));
    const oc = onChange ? (e) => {setVal(e.target.value);onChange(e.target.value)} : (e) => setVal(e.target.value);

    useEffect(function () {
        setVal(`${sanitize(value)}`);
    }, [value]);

    function handleKeyDown(e) {
        if (e.key === 'Enter') {
            onEnter();
        } else if(e.keyCode===8&&!val) {
            onEmptyBackspace();
        }
    }

    return <div className={`mt-1 relative`}>
        <textarea onKeyDown={handleKeyDown} name={name} id={id} rows={rows} autoFocus={autoFocus} maxLength={max?max:null}
                  className={`block ${fill?"":"max-w-lg"} resize-none ${minimal?"":"border focus-standard border-gray-300 shadow-sm py-2 pl-3"}  w-full  ${max?"pr-8":"pr-3"} outline-none rounded-md placeholder-gray-500 sm:flex-1 `}
                  placeholder={placeholder} value={val} onChange={oc} style={{fontSize:'16px'}}/>
        {max&&val&&<div className={`${minimal?"top-0 right-0":"top-2 right-2"} absolute text-xs text-gray-400`}>
            {max-val.length}
        </div>}
    </div>
}