import React from 'react';
import {Button} from "../../components/core";
import {authFetch} from "../../../config/network";
const client_id = '8611rdkhf4x2r9';
const redirect_url = `http://localhost:5001/unaty-prod/us-central1/server/social-callback`;

const oauth_url = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${client_id}&scope=r_liteprofile&state=123456&redirect_uri=${redirect_url}`;
export function LinkedInTests() {

    function socialTest() {
        const res = () => {};
        const payload = {

        };
        authFetch("/social-test", res, res, "POST", {payload});
    }

    function requestProfile() {
        let oauthUrl = oauth_url
        let width = 450,
            height = 730,
            left = window.screen.width / 2 - width / 2,
            top = window.screen.height / 2 - height / 2;

        window.open(
            oauthUrl,
            "Linkedin",
            "menubar=no,location=no,resizable=no,scrollbars=no,status=no, width=" +
            width +
            ", height=" +
            height +
            ", top=" +
            top +
            ", left=" +
            left
        );
    }

    return <div>
        LinkedInTests {redirect_url}
        <Button text="Login with LinkedIn" onClick={()=>requestProfile()} />
        <Button text="LinkedIn Test" onClick={()=>socialTest()} />
    </div>
}