import React, {useContext} from 'react';
import {DEFAULT_USER_PHOTO} from "../../../config/defaults";
import {Avatar} from "./avatar";
import {useHistory} from 'react-router-dom';
import styled from "styled-components";
import {ActionWithIcon, SectionHeader} from "./index";
import {PlusIcon, UsersIcon} from "@heroicons/react/solid";
import {EntityInfo} from "../../utilities/localstorage/entity-localstorage";
import {CommunityContext} from "../../../app/community/community-context";
import RoleIcon from "../../icons/custom/RoleIcon";
import {useIntl} from "react-intl";

function getDisplay(type, val) {
    let split, str;
    switch (type) {
        case 'email' :
            split = val.split('](');
            str = split[0];
            return str.slice(1, str.length);
        case 'interest' :
            split = val.split('](');
            str = split[0];
            return str.slice(1, str.length);
        case 'website' :
            split = val.split('](');
            str = split[0];
            return str.slice(1, str.length);
        default :
            return val;
    }
}


const AvatarCont = styled.div`
      height: 26px;
  > div {

    margin-top: 3px;
    img {

    }
  }
 
`;

function ItemEntity({type,data,id,chandle,history}) {
    let img;
if(type==='members') {
    img = data.image ? data.image : DEFAULT_USER_PHOTO;
    return <div onClick={()=>history.push(`/${chandle}/member/${data.handle}`)} className="flex mr-1.5 text-sm mb-1.5 bg-secondary-50 hover:bg-secondary-600 hover:text-white text-gray-700 transition-colors cursor-pointer pr-2 rounded-md">
        <AvatarCont className="pl-1 pt-px">
            <Avatar url={`${img}_small?alt=media`} size="18px" />
        </AvatarCont>
        <div className="py-0.5 pl-1.5 pr-0.5 mt-px">
            {data.name}
        </div>
    </div>;
} else if(type==='roles') {
    return <div onClick={()=>history.push(`/${chandle}/role/${id}`)} className="flex mr-1.5 text-sm mb-1.5 bg-secondary-50 hover:bg-secondary-600 hover:text-white text-gray-700 transition-colors cursor-pointer pr-2 rounded-md">
        <AvatarCont className="pl-1 pt-px">
            <RoleIcon/>
        </AvatarCont>
        <div className="py-0.5 pl-1.5 pr-0.5 mt-px">
            {data.name}
        </div>
    </div>;
} else if(type==='groups') {

    return <div onClick={()=>history.push(`/${chandle}/group/${id}`)} className="flex mr-1.5 text-sm mb-1.5 bg-secondary-50 hover:bg-secondary-600 hover:text-white text-gray-700 transition-colors cursor-pointer pr-2 rounded-md">
        <AvatarCont className="pl-1 pt-px">
           <UsersIcon/>
        </AvatarCont>
        <div className="py-0.5 pl-1.5 pr-0.5 mt-px">
            {data.name}
        </div>
    </div>;
}
return null;
}

function Item({type, value, onClick, active, history, chandle, community_uid}) {
    if(type==='member') {
        if(!value) {
            return null;
        }
        return <EntityInfo community_uid={community_uid} id={value} type={'members'}>
            <ItemEntity chandle={chandle} id={value} history={history} type="members" />
        </EntityInfo>;
    } else if(type==='role') {
        if(!value) {
            return null;
        }
        return <EntityInfo community_uid={community_uid} id={value} type={'roles'}>
            <ItemEntity chandle={chandle}  id={value} history={history} type="roles" />
        </EntityInfo>
    } else if(type==='group') {
        if(!value) {
            return null;
        }
        return <EntityInfo community_uid={community_uid} id={value} type={'groups'}>
            <ItemEntity chandle={chandle} id={value} history={history} type="groups" />
        </EntityInfo>
    } else if(type==='file') {
        return <div>
            FILE
        </div>
    } else if(type==='date') {
        return <div>
            date
        </div>
    } else if(type==='text') {
        return <div className={`mr-1.5 mb-1.5 text-sm transition-colors px-2 py-0.5 rounded-md`}>
            {value}
        </div>
    } else if(type==='number') {
        return <div className={`mr-1.5 mb-1.5 text-sm transition-colors px-2 py-0.5 rounded-md`}>
            {value}
        </div>
    } else if(type==='email') {
        return <div onClick={onClick} className="mr-1.5 mb-1.5 text-sm bg-secondary-50 hover:bg-secondary-600 hover:text-white text-gray-700 transition-colors cursor-pointer px-2 py-0.5 rounded-md">
            {getDisplay('email', value)}
        </div>
    } else if(type==='tag') {
        const color = "bg-gray-100 text-gray-700";
        return <div className={`mr-1.5 ${color} mb-1.5 text-sm transition-colors px-2 py-0.5 rounded-md`}>
            {value}
        </div>
    }
    let display = getDisplay(type, value);
    if(type==='interest') {
        const color = active ? "bg-gradient-to-br from-yellow-400 to-orange-500 text-white hover:opacity-70" : "bg-gray-50 hover:bg-gray-600 hover:text-white text-gray-700";
        return <div onClick={onClick} className={`mr-1.5 mb-1.5 text-sm ${color} transition-colors cursor-pointer px-2 py-0.5 rounded-md`}>
            {display}
        </div>
    }
    return <div onClick={onClick} className="mr-1.5 mb-1.5 text-sm bg-secondary-50 hover:bg-secondary-600 hover:text-white text-gray-700 transition-colors cursor-pointer px-2 py-0.5 rounded-md">
        {display}
    </div>
}

function Pin({label, community_uid,show_empty, history, chandle, items}) {
    if (items.length === 0&&!show_empty) {
        return null;
    }
    return <div className="flex space-x-4 items-start justify-start">
        <div className="flex-none w-28 text-left pl-2.5 text-sm text-gray-500 pt-px">{label}</div>
        <div className="flex-grow flex flex-wrap -mt-px">
            {show_empty&&items.length===0&&<div className="mb-1.5" style={{height:'24px'}}>

            </div>}
            {items.map((item, k) => <Item history={history} chandle={chandle} community_uid={community_uid} key={k} {...item} />)}
        </div>
    </div>
}

export function Pinboard({pins = [], show_empty=false, title, header=true, can_add_pin, onAddPin = () => {}}) {
    const community = useContext(CommunityContext);
    const history = useHistory();
    const {formatMessage: f} = useIntl();

    if(pins.length===0&&!show_empty) {
        return null;
    }

    return <div className="max-w-lg">
        {header&&title&&<SectionHeader title={title} action={can_add_pin?<ActionWithIcon size="xs" icon={<PlusIcon/>}
                                                                    onClick={() => onAddPin()}
                                                                    text={f({id:'widgets.pinboard.add_action'})} inverse/>:null}/>}
                                                                    <div className="space-y-2 py-2 ">
        {pins.map((pin, i) => <Pin show_empty={show_empty} community_uid={community.uid} chandle={community.data.handle} history={history} key={i} {...pin} />)}
                                                                    </div>
    </div>;
}