import React, {useContext, useEffect, useState} from 'react';
import {FirebaseDB} from "../../../../../../config/setup-firestore";
import {Button, SectionHeader} from "../../../../../../library/components/core";
import {EntityInfo} from "../../../../../../library/utilities/localstorage/entity-localstorage";
import {getPrettyTime} from "../../../../../../library/utilities/general/dates";
import {Link} from "react-router-dom";
import {CheckIcon} from "@heroicons/react/solid";
import {NewTooltip} from "../../../../../../library/components/reorganize/new-tooltip";
import {authFetch} from "../../../../../../config/network";
import {GlobalContext} from "../../../../../global/global-context";

function MemberWrapper({data,chandle=""}) {
    return <Link className="color-link-reverse" to={`/${chandle}/member/${data.handle}`}>
        {data.name}
    </Link>
}

function Item({id,field,onClose,community,closing,created_by,ts,value}) {
return <div className="flex space-x-2 pr-1.5">
    <div className="flex-grow">
    <div className="text-base text-gray-600">
        <div>
            {value}
        </div>
    </div>
    <div className="text-xs space-x-3 text-gray-500 pt-1">
        <EntityInfo community_uid={community.uid} id={created_by} type={'members'}>
            <MemberWrapper chandle={community.handle} />
        </EntityInfo>
        <span>{getPrettyTime(ts)}</span>
    </div>
    </div>
    <div className="flex-none flex items-center">
        <NewTooltip message="Close Suggestion">
        <Button onClick={onClose} loading={closing} size="xs" icon={<CheckIcon />} />
        </NewTooltip>
    </div>
</div>
}

export function ManageDataSuggestions({community,member_id}) {
    const [ids,setIds] = useState([]);
    const global = useContext(GlobalContext);
    const [deleting,setDeleting] = useState('');
    const [loading,setLoading] = useState(true);
    const [data,setData] = useState({});
    const [last_visible,setLastVisibile] = useState(null);

    function handleSnap(snap) {
        if(snap.empty) {

        } else {
            const lastVisible = snap.docs[snap.docs.length-1];
            console.log("last", lastVisible);
        }
        let new_ids = [];
        let new_data = {...data};
        snap.docs.forEach(a=>{
            new_ids.push(a.id);
            new_data[a.id] = a.data();
        });
        setData(new_data);
        setLoading(false);
        setIds(new_ids);
    }

    useEffect(function () {
        let sub = () => {};

        // start without
        sub = FirebaseDB
            .collection('community_members')
            .doc(community.uid)
            .collection('data_quality')
            .where('context', '==', 'data-suggestion')
            .where('id', '==', member_id)
            .orderBy('ts', 'desc')
            .limit(50)
            .onSnapshot(s=> {
                handleSnap(s, true)
            })

        return () => {
            sub();
        };
    }, [member_id,community.uid]);

    function handleClose(id) {
        setDeleting(id);
        const payload = {
          type: 'member',
            suggestion_id: id,
            community_uid: community.uid,
            member_id: community.member_id
        };
        global.addToast({text:'Marking suggestion as closed',intent:'info'})
        const res = () => {
            setDeleting('');
            global.addToast({text:'Suggestion closed',intent:'success'})
        };
        const err = () => {};
        authFetch("/data-suggestions/approve", res, err, "POST", {payload});
    }

    const n = ids.length;

    if(n===0) {
        return null;
    }

    return <div>
        <SectionHeader divider fill padded={false} size="base" title={`${n} Data Suggestion${n===1?"":"s"}`}>
            <div className="space-y-2">
                {ids.map(id=>{
                    return <Item closing={deleting===id} onClose={()=>handleClose(id)} community={community} key={id} {...data[id]} />
                })}
            </div>
        </SectionHeader>
    </div>
}