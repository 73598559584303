import React from 'react';
import {Avatar} from "../core";
/*

<div style={{height:'3.25rem'}} className="flex px-3 items-center mx-auto app-max-width">
                <div className="flex items-center">
                    <div className="h-9">
                        <Avatar type="community" text="Corps Isaria" size="36px" />
                    </div>
                    <div></div>
                </div>
                <div className="flex-grow"/>
                <div>R</div>
            </div>


           <div style={{backgroundColor: `${styles.body.content}`}}>
                    Content
                    <div className="h-screen bg-gray-300"/>
                    <div className="h-screen bg-red-700"/>
                    <div className="h-screen bg-success"/>
                </div>
                <div className="relative">
                    <div className="static detail-bar">

                        <div className="h-screen bg-red-700"/>
                        <div className="h-screen bg-moderator"/>
                        <div className="h-screen bg-danger"/>
                    </div>
                </div>

 */
export function LatestFrame(props) {
    const {theme} = props;

    // style={{backgroundColor: `${theme.body_bg}`}}
    return <div className="grid h-screen app-frame-grid">
        <div className={` app-topbar`}
             style={{backgroundColor: `${theme.bg}`, height: '3.25rem', borderBottom: `1px solid ${theme.bg_border}`}}>
            {props.topbar}
        </div>
        <div className="grid app-body-grid overflow-y-auto" id="main-content" >
            <div/>
            <div className="app-sidebar">
                <div style={{top:'3.25rem'}} className="fixed app-sidebar-content pl-3 max-h-body overflow-y-auto no-scrollbars">
                    {props.sidebar}
                </div>
            </div>
            <div className={"grid app-content-grid gap-6 sm:pr-3"}>
                {props.children}

            </div>
            <div/>
        </div>
        {props.extras}
    </div>
}