import React, {useEffect, useState} from "react";
import styled from "styled-components";
import {es_renderEntityType} from "./item";

const Wrapper = styled.div`
  max-height: 44vh;
  overflow-y: auto;
`;

export function ESTable({
                            entities,
                            can_select = false,
                            selected = [],
                            onSelect,
                            community,
                            block_removal = {},
                            non_live_ids = {},
                            onToggleSegmentLive = null,
                            no_top_margin = false,
                            community_uid,
                            can_remove = true,
                            onRemove
                        }) {
    const [entities_data,setEntitiesData] = useState(Object.entries(entities));

    useEffect(function () {
        setEntitiesData(Object.entries(entities))
    }, [entities])

    if (entities_data.length === 0) {
        return null;
    }

    const entity_props = {
        can_remove,
        community,
        onRemove,
        can_select,
        onSelect,
        onToggleSegmentLive,
        selected,
        block_removal
    };

    return <Wrapper no_top_margin={no_top_margin} className={`${no_top_margin ? "-m-px" : "-m-px mt-2"}`}>
        {entities['members'] && es_renderEntityType('members', entities['members'], community_uid, entity_props)}
        {entities['roles'] && es_renderEntityType('roles', entities['roles'], community_uid, entity_props)}
        {entities['member_types'] && es_renderEntityType('member_types', entities['member_types'], community_uid, entity_props)}
        {entities['filters'] && es_renderEntityType('filters', entities['filters'], community_uid, entity_props)}
    </Wrapper>
}