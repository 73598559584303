import React, {useState} from 'react';
import {getFileTypeIcon} from "../old/file-uploader/helpers";
import styled from "styled-components";
import {LinkIcon,DownloadIcon,CheckIcon,MinusIcon} from "@heroicons/react/solid";
import {useIntl} from 'react-intl';
import {ArrowNarrowDownIcon, ArrowNarrowUpIcon} from "@heroicons/react/solid";
import {saveItemToLocalStorage} from "../../utilities/localstorage";
import {EntityInfo} from "../../utilities/localstorage/entity-localstorage";
import {Tooltip2} from "@blueprintjs/popover2";
import {useIsMobile} from "../../../app/global/global-router";

function getGrid(headers, has_actions) {
    let str = '';

    for (let i = 0; i < headers.length; i++) {
        if(headers[i].hidden) {
            continue;
        }
        str += `${headers[i].width} `;
    }

    if (has_actions) {
        str += `0.5fr `;
    }

    return str;
}

const Icon = styled.div`
      
    > svg {
        height: 24px;
    }
`;

const ActionIcon = styled.div`
      
    > svg {
        height: 18px;
    }
`;

const CheckItem = styled.div`
      
    > svg {
        height: 18px;
    }
`;

const MinusItem = styled.div`
      
    > svg {
        height: 14px;
    }
`;

const SortArrow = styled.div`
      height: 16px;
  margin-left: 0.25rem;
  width: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
    > svg {
        height: 12px;
    }
`;

/*
  let clicks = 0;
    function handleClick(ev) {
        clicks++;
        if (clicks == 1) {
            setTimeout(function(){
                if(clicks == 1) {
                    onSelect(ev);
                } else {
                    onDoubleClick(ev)
                }
                clicks = 0;
            },  200);
        }
    }
 */

function MemberName({data}) {
    return <span className="font-medium pl-0.5">{data.name}</span>
}

function renderValue(header,v) {
    if(header.type==='date-member' && v && v.datetime) {
        return <Tooltip2 minimal  placement='bottom-center' content={<div className=" ">
            <div className="bg-gray-800 rounded-md m-1 px-3 py-1.5 text-white text-sm font-medium shadow">
                {v.datetime}
            </div>
        </div>}>
            <div className="transition-opacity hover:opacity-70">
                {v.date} <EntityInfo community_uid={v.community_uid} id={v.member_id} type={'members'}>
                <MemberName />
            </EntityInfo>
            </div>
        </Tooltip2>
    } else if(header.type==='date'&&v&&v.datetime) {
        return  <div className="transition-opacity hover:opacity-70">
            {v.date}
        </div>
    } else {
        return v ? v : '—'
    }
}

function Row({data, actions, hidden, selected_len, type, can_select, item, grid_cols, next_selected, previous_selected, stripe_color, onSelect, even, selected, headers, onDoubleClick=()=>{}, last, first, id, icon}) {
    const rounded = next_selected && previous_selected ? "" : !next_selected && !previous_selected ? "rounded-md" : previous_selected ? "rounded-b-md" : "rounded-t-md";
    function handleClick(ev) {
        if(type==='system') {
            onSelect(ev);
        } else if(selected_len>0) {
            onSelect(ev);
        } else {
            onDoubleClick(ev);
        }
    }
    return <div style={{gridTemplateColumns: grid_cols}} onClick={(ev) => handleClick(ev)}
                className={`grid min-h-0 min-w-0 group px-2.5 py-1.5 ${rounded} transition-colors cursor-pointer ${selected ? "bg-selection" : `${even ? `${stripe_color}` : ``} hover:bg-secondary-50`}`}>

        {headers.map((header, k) => {
            if(header.hidden) {
                return null;
            }
            const field = header.field;
            const color = header.title ? (selected ? "text-white" : "text-gray-800") : (selected ? "text-gray-100" : "text-gray-500");
            const v = data[header.field];
            const value = renderValue(header,v);
            return <div className="flex items-center min-w-0 overflow-hidden" key={k}>
                {field === 'name' && <div className="flex items-center justify-center w-5 h-5 mr-2 rounded">
                    {!selected && <Icon style={{minWidth:'1.25rem'}} className={`transform flex justify-center scale-125 ${type!=='system'&&can_select?"group-hover:hidden":""}`}>
                        {icon}
                    </Icon>}
                    {!selected&&can_select&&<div className={`hidden ${type!=='system'?"group-hover:block":""}`}>
                    <ItemToggle onClick={(ev)=>onSelect(ev)} />
                    </div>}
                    {selected && <div onClick={(ev)=>{ev.stopPropagation();onSelect(ev)}} className="w-8 h-7 flex items-center -mb-1.5 -mr-1 -mt-1.5 -ml-3 pt-1 pr-1 pb-1 pl-3">
                        <CheckItem className="text-white">
                        <CheckIcon/>
                    </CheckItem>
                    </div>}
                </div>}
                <div className={` max-w-sm flex-shrink truncate ${color} ` + (header.title ? "font-medium text-sm" : "text-xs")}>
                    {value}
                </div>
            </div>
        })}
        {actions.length > 0 && <div className="flex space-x-2.5 justify-end min-w-0 overflow-hidden">
            {actions.map((act, k) => {
                if(!act.types.includes(type)) {
                    return null;
                }
                return <div key={k}>
                    <ActionIcon onClick={(e) => {
                        e.stopPropagation();
                        act.onClick(id, item)
                    }}
                                className={`cursor-pointer ${selected ? "text-white hover:text-gray-300" : "text-gray-500 hover:text-selection"}`}>
                        {act.icon}
                    </ActionIcon>
                </div>;
            })}
        </div>}
    </div>;
}

const items_mock = [
    {
        id: '2354',
        icon: getFileTypeIcon('application/pdf'),
        type: 'file',
        data: {
            name: 'CC Protokoll',
            size: '12mb',
            last_updated: Date.now().toString()
        }
    },
    {
        id: '6123',
        icon: getFileTypeIcon('folder'),
        type: 'folder',
        data: {
            name: 'CC Protokoll',
            size: '12mb',
            last_updated: Date.now().toString()
        }
    },
    {
        id: '23423',
        icon: getFileTypeIcon('folder'),
        type: 'folder',
        data: {
            name: 'CC Protokoll',
            size: '12mb',
            last_updated: Date.now().toString()
        }
    },
    {
        id: '5234',
        icon: getFileTypeIcon('folder'),
        type: 'folder',
        data: {
            name: 'CC Protokoll',
            size: '12mb',
            last_updated: Date.now().toString()
        }
    },

    {
        id: '22345',
        icon: getFileTypeIcon('video/mpeg'),
        type: 'file',
        data: {
            name: 'CC Protokoll',
            size: '12mb',
            last_updated: Date.now().toString()
        }
    },
    {
        id: '7243',
        icon: getFileTypeIcon('link'),
        type: 'file',
        data: {
            name: 'CC Protokoll',
            size: '12mb',
            last_updated: Date.now().toString()
        }
    },
    {
        id: '6245',
        icon: null,
        type: 'file',
        data: {
            name: 'CC Protokoll',
            size: '12mb',
            last_updated: Date.now().toString()
        }
    }
];

const headers_mock = [
    {
        width: '2fr',
        label: 'Name',
        field: 'name',
        title: true
    },
    {
        width: '0.8fr',
        label: 'Size',
        field: 'size'
    },
    {
        width: '1.2fr',
        label: 'Last updated',
        field: 'last_updated'
    }
];

function getExtras(selected, ids_arr, last_selected_id, current_id) {
    const current_index = ids_arr.indexOf(current_id);
    const previous_index = ids_arr.indexOf(last_selected_id);

    // need to handle 2 cases here
    // need to get ids between
    let slice = [];

    if (current_index > previous_index) {
        slice = ids_arr.slice(previous_index, current_index);
    } else {
        slice = ids_arr.slice(current_index, previous_index);
    }

    return selected.concat(slice).concat([current_id]).unique()
}

const mock_actions = [
    {
        label: 'Copy Link',
        icon: <LinkIcon/>,
        types: ['folder', 'file'],
        onClick: (id, entry) => {
        }
    },
    {
        label: 'Download',
        icon: <DownloadIcon/>,
        types: ['file'],
        onClick: (id, entry) => {
        }
    }
];

function ItemToggle({onClick,children,active}) {
    return <div onClick={(ev) => {ev.stopPropagation();onClick(ev)}}
                className={`cursor-pointer w-8 h-7 -mb-1.5 -mr-1 -mt-1.5 -ml-3 pt-1.5 pr-1 pb-1.5 pl-3 `}>
        <div className={`w-4 h-4 rounded ${active ? "border-2 border-selection bg-selection" : "border-2 border-gray-300"}`}>
        {children}
        </div>
    </div>
}

function sortItems(items,headers,sort) {
    if(!sort) {
        return items;
    }
    let files = [...items.filter(it => {
        return it.type === 'file'
    }).map((it)=>{
        return {
            sort_value: it.raw[sort.field],
            ...it
        }
    })];

    let folders = [...items.filter(it => {
        return it.type === 'folder'
    }).map((it)=>{
        return {
            sort_value: it.raw[sort.field],
            ...it
        }
    })];

    const header = headers.filter(head => {
        return head.field === sort.field
    })[0];

    // null for folders should keep them at the top for size
    // folders and files should be sorted separately
    if((header.type!=='text'&&sort.dir==='asc')||(header.type==='text'&&sort.dir==='desc')) {
        return folders.sort((a, b) => (a.sort_value > b.sort_value) ? 1 : -1).concat(files.sort((a, b) => (a.sort_value > b.sort_value) ? 1 : -1));
    } else {
        return folders.sort((a, b) => (a.sort_value < b.sort_value) ? 1 : -1).concat(files.sort((a, b) => (a.sort_value < b.sort_value) ? 1 : -1));
    }
}

export function SimpleTable({items = items_mock, localstorage_key='', selected=[], default_sort=null, updateSelected=()=>{}, onItemClick=()=>{}, can_select=true, hide_headers, go_up,onGoUp=()=>{}, actions = mock_actions, stripe_color = "bg-gray-50", headers = headers_mock}) {
    const [last_selected, setLastSelected] = useState('');
    const is_mobile = useIsMobile();
    const [sort, setSort] = useState(default_sort);
    const actions_len = actions.length;
    const grid_cols = getGrid(headers, actions_len > 0);
    const {formatMessage: f} = useIntl();
    function handleSelect(ev, id, arr_index) {
        const index = selected.indexOf(id);

        // handle shift key multi selection
        if (index === -1) {
            // not selected
            let is_shift;
            if (window.event) {
                is_shift = !!window.event.shiftKey;
            } else {
                is_shift = !!ev.shiftKey;
            }
            let extras = [];
            setLastSelected(id);
            if (is_shift) {
                const new_selected = getExtras(selected, items.map(it => it.id), last_selected, id);

                updateSelected(new_selected);
            } else {
                updateSelected([...selected, ...extras, id]);
            }
        } else {
            let new_selected = selected.slice();
            new_selected.splice(index, 1);
            setLastSelected('');
            updateSelected(new_selected);
        }
    }

    function toggleAll() {
        if (selected.length > 0) {
            updateSelected([]);
        } else {
            updateSelected(items.map(it => it.id));
        }
    }

    function handleDoubleClick(ev, id, item, i) {
        ev.stopPropagation();
        onItemClick(id,item);
    }

    function handleHeaderClick(head) {
        if(!sort) {
            return;
        } else if(sort.field===head.field) {
            // flip sort
            const ns = {
                field: head.field,
                dir: sort.dir==='desc'?'asc':'desc'
            };
            setSort(ns);
            if(localstorage_key) {
                saveItemToLocalStorage(localstorage_key,ns);
            }
        } else {
            // start sort on this field
            const ns = {
                field: head.field,
                dir: head.type==='text'?'asc':'desc'
            };
            setSort(ns);
            if(localstorage_key) {
                saveItemToLocalStorage(localstorage_key,ns);
            }
        }
    }

    const len = items.length;
    const selected_len = selected.length;

    const results = sortItems(items,headers,sort);

    return <div className="w-full user-select-none">
        {!hide_headers&&<div className={`grid py-0.5 h-6 px-2.5`} style={{gridTemplateColumns: grid_cols}}>
            {headers.map((head, i) => {
                if(head.hidden) {
                    return null;
                }
                return <div className={"text-xs text-gray-600 font-medium flex items-center"} key={i}>
                    {head.title && <div style={{minWidth: '1.25rem'}}
                                        className="flex items-center justify-center w-5 h-5 mr-2 rounded ">
                        {can_select && <ItemToggle onClick={() => toggleAll()} active={selected.length > 0}>
                            {selected_len > 0 &&
                            <MinusItem className="text-white w-3 h-3 -mt-px -ml-px"><MinusIcon/></MinusItem>}
                        </ItemToggle>}
                    </div>}
                    {selected_len === 0 ? <div onClick={()=>handleHeaderClick(head)} className="flex cursor-pointer hover:opacity-70 transition-opacity">
                        <div>
                            {head.label}
                        </div>
                        {sort&&sort.field===head.field&&<SortArrow className="text-gray-400 hover:text-gray-600 hover:bg-gray-100 transition-colors cursor-pointer">
                            {sort.dir==='desc'?<ArrowNarrowDownIcon />:<ArrowNarrowUpIcon />}
                        </SortArrow>}
                    </div> : head.title ? <div>
                        <div>
                            {selected_len} selected
                        </div>
                    </div> : <div className={"flex cursor-pointer hover:opacity-70 transition-opacity"} onClick={()=>handleHeaderClick(head)}>
                        <div>
                            {head.label}
                        </div>
                        {sort&&sort.field===head.field&&<SortArrow className="text-gray-400 hover:text-gray-600 hover:bg-gray-100 transition-colors cursor-pointer">
                            {sort.dir==='desc'?<ArrowNarrowDownIcon />:<ArrowNarrowUpIcon />}
                        </SortArrow>}
                    </div>}

                </div>
            })}
            {actions_len > 0 && <div/>}
        </div>}
        <div className="">
            {go_up&&<Row actions={[]} headers={headers} grid_cols={grid_cols} stripe_color={stripe_color} first={true} last={len===0} previous_selected={false} next_selected={false} even={false} data={{name:'Go up'}} icon={getFileTypeIcon('go-up')} type="system" onSelect={()=>onGoUp()} />}
            {results.map((item, i) => {
                const first = i === 0;
                const last = (i + 1) === len;
                const is_selected = selected.indexOf(item.id) !== -1;
                const next_item_selected = last ? false : selected.indexOf(items[(i + 1)].id) !== -1;
                const previous_item_selected = first ? false : selected.indexOf(items[(i - 1)].id) !== -1;
                return <Row can_select={can_select} selected_len={selected_len} actions={actions} even={go_up?(i%2===0):(Math.abs(i % 2) == 1)} stripe_color={stripe_color}
                            onSelect={(ev) => handleSelect(ev, item.id, i)} previous_selected={previous_item_selected}
                            next_selected={next_item_selected} selected={is_selected} first={first} last={last}
                            onDoubleClick={(ev)=>handleDoubleClick(ev, item.id, item)} is_mobile={is_mobile}
                            grid_cols={grid_cols} headers={headers} key={item.id} item={item} {...item} />
            })}
        </div>
    </div>;
}