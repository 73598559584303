import React, {useState} from 'react';
import {XIcon} from "@heroicons/react/solid";
import {SegmentPreviewWrapper} from "./segment-preview-wrapper";
import {getSegmentSemantics} from "../semantic-segments";
import {UNATY_COLOR_GREEN} from "../../../../config/defaults";

export function AddEntitiesSuggestions(props) {
const [show,setShow] = useState(false);

    return <div style={{backgroundColor: `${UNATY_COLOR_GREEN}`}} className={`${show?"":"hidden"} mt-4 -mx-5 pt-2.5 pb-4 px-5`}>
        <div className="flex space-x-1.5 items-center px-1 pb-1">
            <div className="flex-grow">
                <div className="text-sm font-medium text-white">
                    Suggestion
                </div>
            </div>
            <div className="flex-none">
                <div onClick={()=>setShow(false)} className={`text-gray-200 w-4 h-4 hover:text-white cursor-pointer`}>
                    <XIcon />
                </div>
            </div>
        </div>
        <div className="mt-0.5">
            <SegmentPreviewWrapper loading={props.loading} onAdd={props.onAdd} match={props.match} label={props.name} sub_label={getSegmentSemantics(props,props.community)} conditions={props.conditions} notifyParent={()=>{
                setShow(true);
            }} />
        </div>
    </div>
}