import React, {useState, useEffect, useContext} from "react";
import {deleteFile, getEditFileAbout} from "../helpers";
import EditModal from "../../../../library/components/old/edit-modal/edit-modal";
import {setDocumentTitle} from "../../../../library/utilities/set-document-title";
import {FilePreview} from "../../../../library/components/reorganize/file-preview";
import {CommunityContext} from "../../community-context";
import {GlobalContext} from "../../../global/global-context";
import {DoubleCheckModal} from "../../../../library/components/old/double-check-modal";
import firebase from "firebase/app";
import {authFetch} from "../../../../config/network";
import {UploadProgress} from "../../../../library/components/reorganize/upload-progress";
import DeleteModal from "../../../../library/components/old/delete-modal";
import FolderPicker from "../../../../library/components/reorganize/folder-picker";
import {Field} from "../../../../library/components/core";
import {CUSTOM_NANOID} from "../../../../config/defaults";
import {useHistory} from "react-router-dom";
import {FirebaseDB} from "../../../../config/setup-firestore";

function findWithAttr(array, attr, value) {
    for(let i = 0; i < array.length; i += 1) {
        if(array[i][attr] === value) {
            return i;
        }
    }
    return -1;
}

function getFileNav(id, file_list=[]) {
    const total = file_list.length;
    let prev = null, next = null;
    if(total===0) {
        return {
            next,
            prev
        }
    }
    const index = findWithAttr(file_list,'id',id);

    if ((index+1) !== total) {
        next = file_list[(index+1)].id;
    }
    if (index !== 0) {
        prev = file_list[(index-1)].id;
    }
    return {
        next,
        prev
    }
}

function ensureExtension(str) {
    return str;
}

function cleanDownloadURL(str) {
    return str.split('&token')[0];
}

function getStrParts(name) {
    if (name.includes('.')) {
        const split = name.split('.');
        return {
            base: `${split[0]}`,
            ext: `.${split[1]}`
        }
    }
    return {
        base: name,
        ext: ''
    }
}

function getFinalName(name, block) {
    let final_name = name;
    let valid = !block.includes(final_name);
    const str_parts = getStrParts(name);
    let i = 1;
    while (!valid) {
        final_name = `${str_parts.base} (${i})${str_parts.ext}`;
        i++;
        valid = !block.includes(final_name);
    }
    return final_name;
}

const storage = firebase.storage().ref();

export default function FilePage(props) {
    const [id, setId] = useState(props.match.params.fileId);
    const [error, setError] = useState(null);
    const [data, setData] = useState(null);
    const [uploading, updateUploading] = useState({});
    const [modal, setModal] = useState(null);
    const [loading, setLoading] = useState('');
    const [move_to, setMoveTo] = useState('');
    const history = useHistory();
    const community = useContext(CommunityContext);
    const global = useContext(GlobalContext);
    const community_uid = community.uid;
    const is_admin = community.is_admin;
    const community_handle = community.data.handle;

    function closeModal() {
        if(props.handleClose) {
            props.handleClose()
        } else {
            const final = `${props.base}/folder/${data.folder}`;
            history.push(final)
        }

    }

    useEffect(function () {
        setId(props.match.params.fileId);
    }, [props.match.params.fileId]);

    useEffect(function () {
        getFile(id);
    }, [id]);

    function getFile(new_id) {
        if(!new_id) {
            return;
        }
        FirebaseDB
            .collection('community_entities')
            .doc(community_uid)
            .collection('files')
            .doc(new_id)
            .get()
            .then(res => gotFile(res))
    }

    function handleDeleteFile() {
        global.addToast({text:'File is being deleted..',intent:'info'});
        closeModal();
        const cb = () => {
            global.addToast({text:'File was deleted',intent:'success'});
        };
        deleteFile(id, community_uid, community_handle, community.member_id, cb)
    }

    function gotFile(res) {
        if (res.exists) {
            const data = {...res.data(),id: res.id};
            setDocumentTitle(data.name, community.data.name);
            setData(data);
        } else {
            setError('access-denied');
        }
    }

    if (!data) {
        return null;
    }

    let file_edit_obj = {};

    let can_replace = false, can_edit = false, can_edit_file_info = false;

    if (error !== 'access_denied' && data !== null) {
        const {permissions_type} = data;
        can_edit = permissions_type === 'editor';
        can_replace = is_admin || can_edit;
        can_edit_file_info = can_edit || is_admin;

        file_edit_obj = getEditFileAbout(data,id,community_uid,community.member_id, Object.keys(props.file_list.map_names), ()=>{
            getFile(id);
        });
    }

    const preview_data = {
        name: data.name,
        url: data.link,
        meta: {
            ext: '.png',
            type: data.type,
            size: data.size
        },
        updated_by: data.updated_by,
        updated_at: data.updated_at
    };

    // get next and prev
    const file_nav = getFileNav(id, props.file_list.ordered);

    function getNavAction(b, id) {
        if(props.global_viewer) {
            setId(id);
        } else {
            props.history.push(`${b}${id}`);
        }

    }

    function buildNav(fn) {
        const base = `${props.base}/folder/${data.folder}/file/`;
        return {
            prev: fn.prev,
            next: fn.next,
            onPrev: fn.prev ? () => getNavAction(base, fn.prev) : null,
            onNext: fn.next ? () => getNavAction(base, fn.next) : null,
        }
    }

    const nav_obj = buildNav(file_nav);

    function handleFileChange(files) {

        var filesize = ((files[0].size / 1024) / 1024).toFixed(4); // MB

        if (filesize > 20) {
            alert(`${files[0].name} is too large, it must be under 20 MB`);

        } else {
            uploadFiles([files[0]]);
        }
    }

    function uploadFiles(files) {
        for (let i = 0; i < files.length; i++) {
            const file = files[i];
            uploadFile(file);
        }
    }

    function updateProgress(file_name, progress, meta) {
        let all = uploading;
        if (progress === -1) {
            delete all[file_name];
        } else {
            all[file_name] = {
                progress,
                ...meta
            };
        }

        updateUploading({...all});
    }

    function uploadFile(file) {
        const uid = CUSTOM_NANOID();
        let final_name = '';
        if (props.replace) {
            final_name = getFinalName(props.current_name, []);
        } else {
            final_name = getFinalName(file.name, []);
        }
        const file_name = `${uid}-${ensureExtension(final_name)}`;
        const path = `files/${community.uid}/${props.match.params.folderId}/${file_name}`;
        const metadata = {
            contentType: file.type
        };
        const task = storage.child(path).put(file, metadata);

        updateProgress(file_name, 0, {name: file.name, type: file.type, size: file.size});

        task.on(
            firebase.storage.TaskEvent.STATE_CHANGED,
            snap => {
                updateProgress(file_name, (snap.bytesTransferred / snap.totalBytes), {
                    name: final_name,
                    type: file.type,
                    size: file.size
                });
            },
            err => {
            },
            () => fileUploaded(path, file.type, final_name, file.size, file_name)
        );
    }

    function fileUploaded(path, type, name, size, file_name) {
        storage
            .child(path)
            .getDownloadURL()
            .then((url) => {
                // url
                createFileInDB(name, cleanDownloadURL(url), {type, size});

                updateProgress(file_name, 1, {name, type, size});

                setTimeout(() => {
                    updateProgress(file_name, -1, {name, type, size});
                }, 2000);
            })
    }

    function createFileInDB(name, downloadURL, metadata) {
        const res = () => {
            global.addToast({text:'New version is loading..'});
            getFile(id);
        };

        const payload = {
            name,
            type: metadata.type,
            id,
            link: downloadURL,
            size: metadata.size,
            community_uid: community.uid,
            member_id: community.member_id,
        };

        authFetch("/files/replace-link", res, res, "POST", {payload});
    }

    function moveFile() {
        setLoading('moving');
        const res = () => {
            setLoading('');
            setModal(null);
            props.history.push(`${props.base}/folder/${move_to}`);
            global.addToast({text: "Folder was moved", intent: 'success'});
        };
        const payload = {
            file_id: id,
            target_folder: move_to,
            community_uid: community.uid,
            member_id: community.member_id,
        };

        authFetch("/files/move", res, res, "POST", {payload});
    }

    return <FilePreview handleFileChange={handleFileChange.bind(this)} can_replace={can_replace} onRename={()=>setModal('rename')} onMove={!can_edit_file_info?null:()=>setModal('move')} onDelete={!can_edit_file_info?null:()=>setModal('double-check-delete')} nav={nav_obj} context={{community_uid, id, community_handle}} data={preview_data}
                        onClose={closeModal}>
        {modal==='double-check-delete'&&<DoubleCheckModal onConfirm={()=>handleDeleteFile()} onCancel={()=>setModal(null)} type='file_delete' />}
        {can_edit_file_info && modal === 'rename' &&
        <EditModal {...file_edit_obj} open={modal === 'rename'} onClose={() => {
            setModal(null);
        }}/>}
        {can_edit_file_info && modal === 'move' && <DeleteModal
            deleting={loading === 'moving'}
            can_delete={move_to !== ''}
            content={<>
            <Field label="Where do you want to move this?" help_text="This file will assume the permissions of the folder you're moving it to, so it may become private or public.">
                <FolderPicker current_folder={data.folder} value={move_to} handleSelect={(folder) => {
                    setMoveTo(folder);
                }}/>
            </Field>
            </>}
            title={`Move File`}
            intent='secondary'
            text='Move'
            onDelete={() => moveFile()}
            onClose={() => {
                setModal(null);
            }}/>}
        <UploadProgress uploading={uploading}/>
    </FilePreview>;
}