import React, {useState, useContext} from 'react';
import {authFetch} from "../../config/network";
import {PolicyViewer} from "../community/policy";
import {Button, Toggle} from "../../library/components/core";
import {RemoteMdViewer} from "./remote-md-viewer";
import {useIsMobile} from "../global/global-router";
import {demo_policy, inDemoMode} from "./user";

export function OwnerPolicyGate({community, member_id = '', global, community_uid, member}) {
    const [loading, setLoading] = useState('');
    const [consents, setConsent] = useState(false);
    const is_mobile = useIsMobile();
    if (!member || !community) {
        return null;
    }

    const is_owner = community.plan.account_owner === global.full_memberships[community_uid];

    if (!is_owner) {
        return null;
    }

    // check if new policies are available that require their attention

    let needs_to_accept = [];

    const global_owner_policies = Object.entries(global.policies_data).map(([id, pd]) => {
        return {
            id,
            ...pd
        }
    }).filter((a) => {
        return a.gate === 'owner';
    });

    for (let i = 0; i < global_owner_policies.length; i++) {
        const {version, name, link, link_german = '', id} = global_owner_policies[i];
        if (version === '' || !link || !link_german) {
            // if no version is set, none to accept..
            continue;
        }
        const uid = `${id}__${version}`;
        if (!community.owner_policies || !community.owner_policies[uid]) {
            needs_to_accept.push({
                slug: id,
                version,
                link,
                link_german,
                name
            });
        }
    }

    function snapToTop() {
        let content_cont = document.getElementById('policy-viewer-container');
        if (content_cont) {
            content_cont.scrollTop = 0;
        }
    }

    const demo_mode = inDemoMode("owner_policy_demo");

    if (needs_to_accept.length === 0 && !demo_mode) {
        return null;
    }

    function acceptPolicy(slug, version) {
        setLoading('accepting');
        const res = (result) => {
            setConsent(false);
            setLoading('');
            snapToTop();
        };

        const payload = {
            member_id,
            community_uid,
            slug, version
        };

        authFetch("/owners/accept-policy", res, res, "POST", {payload});
    }

    const policy_to_accept = demo_mode ? demo_policy : needs_to_accept[0] ? needs_to_accept[0] : null;

    if (!policy_to_accept && !demo_mode) {
        return null;
    }

    return <div className="z-99999 fixed top-0 left-0 h-screen hide-scrollbar right-0 bottom-0 bg-black bg-opacity-70">
        <div className="max-w-xl mx-auto relative h-screen md:pt-8 md:pb-16">
            <div className={`${is_mobile ? "h-screen flex flex-col" : "rounded-md"} bg-white`}>
                <div id="policy-viewer-container" style={{paddingBottom: '40vh'}}
                     className={`${is_mobile ? "flex-grow max-h-100vh" : "max-h-70vh"} overflow-y-auto relative`}>
                    <RemoteMdViewer demo={demo_mode}
                                    url={global.language === 'de' ? policy_to_accept.link_german : policy_to_accept.link}/>
                </div>
                <div
                    className={`${is_mobile?"fixed bottom-0 left-0 right-0 ":"rounded-b-md"} bg-white flex space-x-6 items-center p-4 border-t border-gray-200`}>
                <div className={`space-y-2 flex-grow sm:flex sm:space-x-6 items-center `}>
                        <div className="flex-grow flex sm:justify-end">
                            <Toggle checked={consents} onToggle={(v) => setConsent(v)} label={<>
                                I agree to the terms of the policy above.
                            </>}/>
                        </div>
                        <Button size="lg" disabled={!consents} loading={loading === 'accepting'}
                                onClick={() => acceptPolicy(policy_to_accept.slug, policy_to_accept.version)}
                                text="Accept & Continue" intent="secondary"/>
                    </div>
                </div>
            </div>
        </div>
    </div>
}