import React, {useContext, useState} from 'react';
import {Avatar, Badge, Button} from "../../core";
import {DEFAULT_COMMUNITY_LOGO} from "../../../../config/defaults";
import {getPrettyTime} from "../../../utilities/general/dates";
import moment from "moment";
import {authFetch} from "../../../../config/network";
import {GlobalContext} from "../../../../app/global/global-context";

function getSubtitle(status,ts,action_ts) {
    if(status==='approved') {
        return `You application was approved ${getPrettyTime(moment.unix((action_ts/1000)))}`
    } else if(status==='denied') {
        return `You application was denied ${getPrettyTime(moment.unix((action_ts/1000)))}`
    } else {
        // pending
        return `Your application is being reviewed by community leaders`;
    }
}

function Item({
    status = "pending",
    ts = Date.now(),
                  data = {},
                  community_uid = '',
    action_ts = Date.now(),
              }) {
    const [loading,setLoading] = useState("");
    const global = useContext(GlobalContext);

    function handleRevokeApplication() {
        setLoading("retracting");
        global.addToast({text:"Retracting application",intent:'info'});
        const res = () => {
            global.addToast({text:"Application retracted",intent:'success'});
        };
        const payload = {
            community_uid,
            user_uid: global.user_uid
        };
        authFetch("/revoke-application", res, res, "POST", {payload});
    }

    return <div className="flex group items-center p-1 rounded-md hover:bg-gray-100 cursor-pointer transition-colors space-x-2">
        <div className="flex-none h-10 w-10">
        <Avatar type="community" url={data.profile_picture?`${data.profile_picture}_small?alt=media`:`${DEFAULT_COMMUNITY_LOGO}_small?alt=media`} size="md" text={data.name} />
        </div>
        <div className="flex-grow">
            <div className="flex space-x-1.5">
                <div className="text-base font-medium text-gray-800"><strong>{data.name}</strong></div>
            </div>
            <div className="text-xs text-gray-600">
                <div>
                    {getSubtitle(status,ts,action_ts)}
                </div>
            </div>
        </div>
        <div className="flex-none pr-1.5">
            <div className={status==='pending'?`block group-hover:hidden`:''}>
                {status==="pending"&&<Badge text="Pending" color="yellow" />}
                {status==="approved"&&<Badge text="Approved" color="green" />}
                {status==="denied"&&<Badge text="Denied" color="gray" />}
            </div>
            {status==='pending'&&<div className="hidden group-hover:block">
<Button loading={loading==="retracting"} onClick={handleRevokeApplication} text="Cancel" />
            </div>}
        </div>
    </div>
}

const mock_item = {
  community_data: {},
  community_uid: '1234',
  status: 'pending'
};

export default function MyApplications({items = []}) {

    return items.map(a=>{
        return <Item key={a.community_uid} {...a} />
    })
}