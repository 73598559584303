import React, {useState,useContext} from 'react';
import Modal from "../../../../library/components/old/modal";
import {Button} from "../../../../library/components/core";

import {AddCareerStep} from "../../../../library/components/reorganize/add-career-step";
import {CUSTOM_NANOID} from "../../../../config/defaults";

const mock = {
    org_data: {
        community_uid: '',
        title: '',
        image: '',
        handle: ''
    },

    title: '', // Bachelor of Science (B.Sc.)

    current: true, // BWL
    location: '',

    from_year: null,
    from_month: null,
    to_month: null,
    to_year: null,

    grade: '', // 1.0

    description: ''
};

function shouldDisable(d) {
    if(d.title.length<2) {
        return true;
    }
    return false;
}

export function AddCareerModal({onAdd,onClose,loading}) {
    const [position,setPosition] = useState(mock);

    function handleUpdatePosition(f,v) {
        let ed = Object.assign({},position);
        if(f==='range') {
            const final = {...ed,...v};
            setPosition({...final})
        } else if(f==='org_data') {
            ed[f]['title'] = v;
            setPosition({...ed});
        } else {
            ed[f] = v;
            setPosition(ed);
        }
    }

    const disabled = shouldDisable(position);

    return <Modal title={"Add Position"} size='base' body={<AddCareerStep position={position} updatePosition={handleUpdatePosition.bind(this)} />} footerRight={<div>
        <Button intent='secondary' loading={loading} disabled={disabled} onClick={()=>{
            const id = CUSTOM_NANOID();
            onAdd(position,id);
        }} text='Add' />
    </div>} footerLeft={null}
                  onClose={onClose} open={true} lazy />
}