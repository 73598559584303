import React from 'react';
import {ACCENT_COLORS, ACCENT_GRADIENTS} from "../../../../config/defaults";

function Item({initial,onSelect,color}) {
    return <div onClick={onSelect} style={{background:color}} className={`h-8 w-8 transform hover:scale-110 transition-transform cursor-pointer hover:opacity-60 text-lg font-semibold inline-flex justify-center leading-8 text-white rounded-md bg-gray-400`}>
        {initial}
    </div>
}

const colors = [
    ...ACCENT_COLORS,
    ...ACCENT_GRADIENTS
];

export function EntityColorSelector({onSelect,initial="U"}) {

    return <div className="grid grid-cols-8 gap-4 px-4 py-1">
        {colors.map((co,k)=><Item onSelect={()=>onSelect(co)} key={k} color={co} initial={initial} />)}
    </div>
}