import React from 'react';
import {us_getResultsLength} from "../universal-search";
import {us_getQueryMeta} from "../universal-search/async-search";
import {us_buildDataTypeResults} from "../universal-search/build-results";
import {InlineLoader} from "../core";
import {USResultsSection} from "../universal-search/components";

function buildFinalResults(query,items={}) {
    // only one section here
    let sections = [
        {
            id: `all-results`,
            title: null,
            items: []
        }
    ];
    let count = 0;
    let qa=[],qs=[],rc=[],nv=[];

    const meta = us_getQueryMeta(query);

    const result_types = Object.keys(items);

    let type_data, type_results, type_excluded, type_already_added;

    if(result_types.includes('members')&&meta.final_query) {
        type_data = items['members'];

        type_results = us_buildDataTypeResults('members',meta.final_query,count,type_data,true, 10);
      //  console.log("type_resultstype_results",JSON.stringify(type_results))
        if(type_results.length>0) {
            count += type_results.length;

            sections[0].items =  [...sections[0].items].concat(type_results);
        }
    }

  //  console.log("RESTSSFSd",JSON.stringify(sections))

    return sections;
}

function TextItem({text="",show_loader=false}) {
    return <div className="py-1.5 space-x-3 h-10 items-center px-4 flex text-sm text-gray-600">
        {show_loader&&<div>
            <InlineLoader mini padding={""} />
        </div>}
        <div>{text}</div>
    </div>
}

export function MobileDirectorySearch({community_uid,query,list_item,completed_queries,items}) {

    const results = buildFinalResults(query,items,community_uid);

    const results_length = us_getResultsLength(results);

    const is_searching = Object.values(completed_queries).filter(a=>a==='loading').length>0;

    return <div>
        {query&&is_searching&&results_length===0&&<TextItem text="Searching.." show_loader />}
        {query&&!is_searching&&results_length===0&&<TextItem text="No results found" />}
        {results.filter(a=>a.items.length>0).map(sec=> {
            return <USResultsSection no_padding title={null} key={sec.id}>
                {sec.items.map(it => {
                    if (React.isValidElement(list_item)) {
                        return <div key={it.id}>{React.cloneElement(list_item, { ...it })}</div>;
                    }
                    return <div key={it.id}>
                        hey
                    </div>
                })}
            </USResultsSection>
        })}
        {!query&&!is_searching&&results_length===0&&<TextItem text="Search for members" />}
    </div>;
}