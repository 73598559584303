import React from 'react';
import {NFIInner, NFIOuter} from "./shared";
import {FeedItemAttachments} from "../posts-feed/feed-item";

export function NFIAttachments({attachments=[],actions}) {
    return <NFIOuter>
        <NFIInner>
            <FeedItemAttachments item_actions={actions} attachments={attachments} />
        </NFIInner>
    </NFIOuter>
}