import React, {useContext, useEffect, useState} from 'react';
import {post_type_info_spec} from "../../../../library/components/composer";
import {CheckCircleIcon, HeartIcon, MailIcon, MailOpenIcon} from "@heroicons/react/outline";
import styled from "styled-components";
import {FirebaseDB} from "../../../../config/setup-firestore";
import {CommunityContext} from "../../community-context";
import {aa_formatDate} from "../../../../library/utilities/general/dates";
import {EntityInfo} from "../../../../library/utilities/localstorage/entity-localstorage";

const IconItem = styled.div`
  svg path {
    color: #536471;
    stroke-width: 1.5px;
  }
`;

function ActionItem({count=0,icon=<HeartIcon/>}) {
    return <div style={{fontSize: '13px'}} className={`text-gray-500 space-x-1.5 flex items-center`} onClick={(e) => {
        e.stopPropagation();

    }}>
        <IconItem className={`h-svg-5 cursor-pointer p-1 -m-1 rounded-full`}>
            {icon}
        </IconItem>
        {count > 0 && <div className="pt-px ">{`${count}`}</div>}

    </div>
}

function getActionData(type,to_ids,action_ids,delivered_member_ids) {
    let a = [];
    const t_keys = Object.keys(to_ids);
    const a_keys = Object.keys(action_ids);
    a.push({
        icon: <MailIcon />,
        count: t_keys.length
    });
    if(type!=='post') {
        a.push({
            icon: <CheckCircleIcon />,
            count: a_keys.length
        });
    } else {
        a.push({
           icon: <MailOpenIcon />,
           count: Object.keys(delivered_member_ids).length
        });
    }
    return a;
}

function MemberContainer({data}) {
    return <span>{data.name}</span>
}

function GeneralItem({type,subject,action_member_ids,community_uid,delivered_member_ids,to_member_ids,created_at}) {
    const type_info = post_type_info_spec[type];
    const action_data = getActionData(type,to_member_ids,action_member_ids,delivered_member_ids);
    const to_keys = Object.keys(to_member_ids);
    const to_id = to_keys[0];
    return <div style={{gridTemplateColumns:"1.25rem 1fr auto"}} className="grid gap-2 shared-bottom-border border-b px-4 py-2.5">
        <div className="">
            {type_info.icon}
        </div>
        <div>
            <div style={{fontSize:'13px'}} className="flex space-x-1">
                <div className="text-gray-500">To:</div>
                <div className="text-gray-600 font-medium">
                    <EntityInfo community_uid={community_uid} id={to_id} type="member">
                        <MemberContainer />
                    </EntityInfo>
                    {to_keys.length>1?` and ${to_keys.length} other${to_keys.length===1?"":"s"}`:""}
                </div>
            </div>
            <div className="text-gray-900 pt-0.5 cursor-pointer -mt-0.5 font-bold line-clamp-1"
                 style={{fontSize: "15px"}}>
                {subject}
            </div>
            <div className="grid gap-4 grid-cols-4 pt-2.5 pb-0.5">
                {action_data.map((ad,k)=>{
                    return <ActionItem key={k} {...ad} />
                })}
            </div>
        </div>
        <div>
            <div className="text-gray-500" style={{fontSize:'13px'}}>{aa_formatDate(created_at).date_formatted}</div>
        </div>
    </div>
}

export function HomeSent() {
    const [sent, setSent] = useState(null);
    const community = useContext(CommunityContext);
    function handleResult(ns) {
        setSent([...ns]);
    }

    useEffect(function () {
        FirebaseDB
            .collection('community_content')
            .doc(community.uid)
            .collection('sent')
            .where('created_by','==',`${community.member_id}`)
            .orderBy('created_at', 'desc')
            .limit(25)
            .get()
            .then((snap) => {
                if(snap.empty) {
                    handleResult([]);
                } else {
                    handleResult(snap.docs.map(d=>{
                        let o = {...d.data()};
                        o.id = d.id;
                        return o;
                    }));
                }
            })
    }, [])

    if(!sent) {
        return null;
    }

    return <div>
        {sent.map(it=><GeneralItem community_uid={community.uid} key={it.id} {...it} />)}
    </div>
}