import React, {useContext, useState} from 'react';
import {authFetch} from "../../../../config/network";
import {ChangeHandleModal} from "../../admin/role/change-handle";
import {SimpleCallout} from "../index";
import {GroupEditPurposeModal} from "./edit-purpose-modal";
import {GroupRenameModal} from "./rename-modal";
import {DetailModalHoverField} from "./modal";
import {GlobalContext} from "../../../global/global-context";
import {DoubleCheckModal} from "../../../../library/components/old/double-check-modal";
import {InterestField} from "../../../../library/components/old/interest-field";
import {Button} from "../../../../library/components/core";
import {useHistory} from "react-router-dom";
import {APP_BASE_URL} from "../../../../config/defaults";
import {copyTextToClipboard} from "../../../../library/utilities/general/copy-to-clipboard";

const modal_double_checks = [
    'make_group_closed',
    'make_group_open',
    'group_delete',
    'convert_group_to_team',
    'convert_team_to_group'
];

export function GroupModalAboutTab({community,can_manage=false,setData=()=>{},getGroup=()=>{},cb=()=>{},id,email_list_power_up,group_data}) {
    const [modal,setModal] = useState("");
    const [loading,setLoading] = useState("");
    const global = useContext(GlobalContext);
    const [new_interests,setNewInterests] = useState(group_data.interests);
const history = useHistory();
    const group_join_link = `${APP_BASE_URL}/${community.data.handle}/sign-in?group=${id}`;
    const community_uid = community.uid;

    const moderators = group_data.moderators.user_uids;

    const moderators_len = Object.keys(moderators).length;


    function changeHandle(h) {
        const payload = {
            new_handle: h,
            community_uid: community.uid,
            id: id, member_id: community.member_id
        };

        setData({
            handle: h
        });
        setModal(null);

        const res = (resp) => {
            if (resp && resp.data && resp.data.group) {
                cb(...resp.data.group)
            }
            setModal(null);
            setLoading('');
            global.addToast({text: 'Change saved', intent: 'success'});
        };

        authFetch("/groups/change-handle", res, res, "POST", {payload});
    }

    function updateInterests(p, e, cb) {

        const ex = group_data.interests;

        if(JSON.stringify(ex) === JSON.stringify(p)) {
            return;
        }

        const payload = {
            community_uid: community_uid,
            member_id: community.member_id,
            interests: p,
            id: id,
        };

        setData({interests:p})

        setModal(null);

        const res = () => {
            getGroup(cb);
            global.addToast({text: 'Interests updated', intent: 'success'});
        };

        const err = () => {
            global.addToast({text: 'Interests could not be updated', intent: 'danger'});
        };

        authFetch("/groups/edit-interests", res, err, "POST", {payload});
    }

    function renameGroup(n, e, pp, ppc, co, cb) {
        const payload = {
            community_uid: community_uid,
            name: n,
            emoji: e,
            profile_picture: pp,
            profile_picture_color: ppc,
            member_id: community.member_id,
            color: co,
            id: id,
        };

        setData({
            ...group_data,
            profile_picture: pp,
            profile_picture_color: ppc,
            color: co,
            name: n,
            emoji: e,
        })

        setModal(null);

        const res = () => {
            getGroup(cb);
            global.addToast({text: 'Name updated', intent: 'success'});
        };

        const err = () => {
            global.addToast({text: 'Name could not be updated', intent: 'danger'});
        };

        authFetch("/groups/rename", res, err, "POST", {payload});
    }


    function editPurpose(v, cb) {
        const payload = {
            community_uid: community_uid,
            purpose: v,
            member_id: community.member_id,
            id: id,
        };

        setData({purpose:v});
        setModal(null);
        const res = () => {
            getGroup(cb);
            global.addToast({text: 'Purpose updated', intent: 'success'});
        };

        const err = () => {
            global.addToast({text: 'Purpose could not be updated', intent: 'danger'});
        };

        authFetch("/groups/update-purpose", res, err, "POST", {payload});
    }

    return <div className="pb-2 pr-1.5">
        <div className="space-y-2">
            <DetailModalHoverField can_edit={can_manage} label="Name" value={group_data.name} placeholder="Add a Name" onClick={()=>{
                setModal('rename');
            }} />
            <DetailModalHoverField can_edit={can_manage} label="Purpose" value={group_data.purpose} placeholder="Add a Purpose" onClick={()=>{
                setModal('edit_purpose');
            }} />
            <DetailModalHoverField can_edit={can_manage} label="Handle" value={`@${group_data.handle}`} onClick={()=>{
                setModal('change_handle');
            }} />

            {can_manage&&<DetailModalHoverField copy can_edit={false} label="Group Join Link" value={group_join_link} onClick={()=>{
                copyTextToClipboard(group_join_link);
                global.addToast({intent:'info',text:'Link copied to clipboard'})
            }} />}

            <InterestField cmi={community.member.interests} can_edit={can_manage} onSave={(p)=> {
                setNewInterests({...p});
                updateInterests({...p},"",()=>{});
            }} max={3} label={"Group Interests"} interests={new_interests} />
        </div>

        {modal === 'change_handle' &&<ChangeHandleModal message={email_list_power_up ? <SimpleCallout
            message={`Changing the handle will also change your group email address (${email_list_power_up.config.email_address}).`}/> : null}
                                                        loading={loading === 'handle'} community_uid={community.uid} data={{handle: group_data.handle}}
                                                        onConfirm={changeHandle}
                                                        onClose={() => {
                                                            setModal(null);
                                                        }} onCancel={() => {
            setModal(null);
            //    setModalData(null)
        }} type='group'/>}

        {modal === 'edit_purpose' &&<GroupEditPurposeModal loading={loading === 'purpose'} community_uid={community.uid}
                                                           data={{purpose: group_data.purpose}}
                                                           onConfirm={editPurpose}
                                                           onClose={() => {
                                                               setModal(null);
                                                           }} onCancel={() => {
            setModal(null);
        }} />}

        {modal === 'rename' &&<GroupRenameModal loading={loading === 'rename'} community_uid={community.uid}
                                                data={{name: group_data.name}}
                                                value={group_data.name} emoji={group_data.emoji} color={group_data.color}
                                                profile_picture_color={group_data.profile_picture_color}
                                                profile_picture={group_data.profile_picture}
                                                onConfirm={renameGroup}
                                                onClose={() => {
                                                    setModal(null);
                                                }} onCancel={() => {
            setModal(null);
        }} />}
    </div>
}