import React, {useContext, useEffect, useState} from "react";
import {useHistory} from 'react-router-dom';

import {setDocumentTitle} from "../../../library/utilities/set-document-title";
import {CommunityContext} from "../community-context";
import {InfoState, InterestItem, EntityHeader, Button} from "../../../library/components/core";
import {FirebaseDB} from "../../../config/setup-firestore";
import {reference_interests} from "../../data-references";
import {HeartIcon, PlusIcon} from '@heroicons/react/solid';
import {CreateCustomInterest} from "./create";
import {GlobalContext} from "../../global/global-context";
import {SelectInterests} from "./select-interests";

function getPopular(data) {
    let arr = [];
    const all = Object.entries(data);
    for (let i = 0; i < all.length; i++) {
        const key = all[i][0];
        const value = all[i][1];

        if (key.indexOf('_count') !== -1) {
            continue;
        }

        const count = data[`${key}_count`];
        if (count === 0) {
            continue;
        }
        const user_uids = Object.keys(value);

        arr.push({
            user_uids,
            id: key,
            data: reference_interests[key],
            count
        })
    }

    return arr.sort((a, b) => (a.count > b.count) ? -1 : 1);
}

export default function CommunityInterests(props) {
    const global = useContext(GlobalContext);
    const community = useContext(CommunityContext);
    const history = useHistory();
    const [tab, setTab] = useState("Popular");
    const [stats, setStats] = useState(null);
    const [modal, setModal] = useState(null);
    const [popular, setPopular] = useState(null);

    useEffect(function () {
        let sub;

        sub = FirebaseDB
            .collection('communities')
            .doc(community.uid)
            .collection('stats')
            .doc('interests')
            .onSnapshot(doc => {
                const data = doc.data();
                setStats(data);
                setPopular(getPopular(data));
            }, err => {
                console.log("ERROR", err)
            });

        return () => sub();
    }, []);

    setDocumentTitle('Interests', community.data.name);

    const title = <span>Discover members with <span className="relative"><span
        className="relative z-10 text-gray-800">mutual</span><span
        className="absolute h-2 rounded-md bottom-0.5 -left-0.5 -right-0.5 bg-gradient-to-br from-yellow-400 to-orange-500"/></span> interests.</span>;

    let body;

    let member_interests = community.interests ? community.interests : {};

    if (tab === 'Popular') {
        body = <div className="space-y-2">
            {popular && popular.length === 0 &&
            <InfoState fill icon={<HeartIcon/>} subtitle="No Interests yet" intent="secondary"

            />}
            {popular && popular.map((int, i) => {
                if (!int.data) {
                    return null;
                }
                return <InterestItem community_uid={community.uid}
                                     onClick={() => history.push(`/${community.data.handle}/interests/${int.id}`)}
                                     user_uids={int.user_uids} emoji={int.data && int.data.emoji ? int.data.emoji : ""}
                                     name={int.data.name} number={int.count} id={int.id} key={int.id}
                                     is_interested={member_interests[`${int.id}`]}/>
            })}
        </div>
    } else if (tab === 'My Interests') {
        body = <div>
            <SelectInterests onSelect={(s, sid) => {
                history.push(`/${community.data.handle}/interests/${sid}`)
            }} initial={member_interests} max={15}/>
        </div>
    }

    const actions = <div className="flex space-x-1">
        <Button text="New" intent="secondary" minimal icon={<PlusIcon/>} onClick={() => setModal('add-interest')}/>
        {tab === 'Popular' &&
        <Button text="My Interests" intent="secondary" minimal onClick={() => setTab("My Interests")}/>}
        {tab === 'My Interests' &&
        <Button text="See Popular" intent="secondary" minimal onClick={() => setTab("Popular")}/>}
    </div>;

    return (<div>
            <div className="sticky top-0 bg-white z-20">
                <EntityHeader onClick={() => {
                    const el = document.getElementById("main-content");
                    if (el) {
                        el.scrollTo(0, 0);
                    }
                }} actions={actions} title={tab}/>
            </div>
            <div className="pt-1 pl-3 pb-12">
                {body}
            </div>
            {modal === 'add-interest' && <CreateCustomInterest onConfirm={(new_id) => {
                setModal('')
                global.addToast({text: "Interest created", intent: "success"})
            }} onCancel={() => setModal('')}/>}
        </div>
    );
}