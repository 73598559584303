import React, {useState} from "react";
import {authFetch} from "../../config/network";
import {Button, Input} from "../../library/components/core";

export function GPACResetLink({}) {
    const [email, setEmail] = useState('');
    const [status, setStatus] = useState('');

    function action() {
        const payload = {
            email
        };
        setStatus('sending');
        const cb = (it) => {
            setEmail('');
            setStatus('sent');
            alert('sent!')
        };
        authFetch("/superadmin/generate-password-set-code", cb, cb, "POST", {payload});
    }

    return <div>
        <div className="text-lg font-semibold text-gray-800">
            Generate a link to set a password on the server
        </div>
        <div className="flex max-w-lg space-x-2">
            <div className="flex-grow">
                <Input value={email} placeholder={"email"} onChange={(uid) => setEmail(uid)}/>
            </div>
            <div className="flex-none">
                <Button text="Generate Link" loading={status === 'sending'} intent="secondary" disabled={!email}
                        onClick={() => action()}/>
            </div>
        </div>
    </div>
}
