import React from "react";
import {import_initial_state, importButtonRef} from "./index";

export function handleOpenDialog(e) {
    // Note that the ref is set async, so it might be null at some point
    if (importButtonRef.current) {
        importButtonRef.current.open(e);
    }
}

export function handleOnFileLoad(data,file) {
    if(data[0].data) {
        this.setState({uploading:true})
        this.setupHeaders(data[0].data);
        this.setupPreview(data);
        this.setState({file_name:file.name,file,uploading:false})
    }
}

export function handleOnError(err, file, inputElem, reason) {
    alert(`Error: ${err}`);
}

export function handleOnRemoveFile(data) {
    console.log('---------------------------')
    console.log(data)
    console.log('---------------------------')

}

export function handleRemoveFile(e) {
    // Note that the ref is set async, so it might be null at some point
    if (importButtonRef.current) {
        importButtonRef.current.removeFile(e)
    }
    this.setState({...import_initial_state})
}