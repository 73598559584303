import React, {useContext,useState,useRef,useEffect} from 'react';
import {GlobalContext} from "./global-context";

import {Switch, Redirect, useLocation, withRouter} from "react-router-dom";
import {global_routes} from "./routes";
import {RenderRoute} from "../utilities/utilities";
import AppLayout from "../../library/components/layout";
import {FullScreenLoader} from "../../library/components/old/full-screen-loader";
import {IntlProvider} from "react-intl";
import {intl_messages_de} from "../../config/intl/de";
import {intl_messages_en} from "../../config/intl/en";
import moment from "moment";
import {moment_config_de, moment_config_en} from "../../config/moment-configs";
import {ErrorBoundary} from "../../library/components/layout/error-boundary";

// const messages = await loadLocaleData(locale)
/*
function loadLocaleData(locale: string) {
  switch (locale) {
    case 'fr':
      return import('./compiled-lang/fr.json')
    default:
      return import('./compiled-lang/en.json')
  }
}
 */

const getIsMobile = () => window.innerWidth <= 550;

export function useIsMobile() {
    const [isMobile, setIsMobile] = useState(getIsMobile());

    useEffect(() => {
        const onResize = () => {
            setIsMobile(getIsMobile());
        }

        window.addEventListener("resize", onResize);

        return () => {
            window.removeEventListener("resize", onResize);
        }
    }, []);

    return isMobile;
}

function getLocaleMessages(loc) {
    if (loc === 'de') {
        return intl_messages_de;
    } else {
        return intl_messages_en;
    }
}

function buildGlobalConfig(global,user,community_data,logged_in) {
    return {
        memberships: global.memberships,
        user: user,
        member: {},
        community_uid: '',
        community_data: community_data,
        community: {},
        logged_in: logged_in,
        member_types: {},
        context: 'global',
        groups: [],
        qs_results: {
            communities: Object.entries(global.community_data).map(([id,entry])=>{
                return {
                    id: entry.handle,
                    data_type: 'community',
                    data: {
                        profile_picture: entry.profile_picture,
                        id,
                        color: entry.color,
                        emoji: entry.emoji,
                    },
                    label: entry.name
                }
            })
        },
        teams: [],
        roles: [],
        is_member: false,
        is_admin: false
    };
}

function GlobalRouter(props) {
    const global = useContext(GlobalContext);
    const {user, language, auth_updated, community_data, logged_in, logging_in, logging_out} = global;
    const loading_auth = auth_updated === null;
    const is_mobile = useIsMobile();
    const {pathname} = useLocation();

    if (loading_auth) {
        return <FullScreenLoader/>;
    } else {
        if (document.getElementById("preloader")) {
            document.getElementById("preloader").remove();
        }
    }

    if (logging_out) {
        return <FullScreenLoader/>;
    }

    if (logging_in && !window.location.pathname.includes('/sign-in')) {
        return <FullScreenLoader/>;
    }

    if (logged_in && !user) {
        // todo this seems like an error state, or maybe we should have a separate loading flag?
        console.warn('logged in, no user??')
        if(window.location.pathname==='/sign-out') {
            console.log('sign out')
        } else {
            return <FullScreenLoader/>;
        }

    }

    const ctx = {handle: props.match.params.handle};

    let routes = [];

    routes = logged_in ? global_routes.logged_in : global_routes.logged_out;

    const app_frame_config = buildGlobalConfig(global,user,community_data,logged_in);

    // language === 'en' ? moment_config_en : moment_config_de
    moment.locale(language);

    const messages = getLocaleMessages(language);

    return <ErrorBoundary>
        <IntlProvider locale={language} key={language} messages={messages}>
        <AppLayout {...props} context='global' routes={routes} app_frame_config={app_frame_config}>
            <Switch>
                <Redirect from="/:url*(/+)" to={pathname.slice(0, -1)}/>
                {routes.map(rt => <RenderRoute key={rt.key} {...rt} ctx={ctx}/>)}
            </Switch>
        </AppLayout>
    </IntlProvider>
    </ErrorBoundary>;
}

export default withRouter(GlobalRouter);