import {FirebaseDB} from "../../../config/setup-firestore";
import {
    convertEntityForLocalStorage,
    saveEntityToLocalStorage
} from "../../../library/utilities/localstorage/entity-localstorage";
import {sortFilesFolders} from "./files-scrollable";

export function filesQuery(id) {

    const {loading} = this.state;
    if (loading) {
        return;
    }
    const community_uid = this.context.uid;
    const {folder, is_admin} = this.props;
    this.setState({
        loading: true
    });

    this.sub_folders_public = FirebaseDB
        .collection('community_entities')
        .doc(community_uid)
        .collection('folders')
        .where('folder', '==', folder)
        .limit(200)
        .onSnapshot((snap) => {
            console.log("sub_folders_public ",snap)
            this.setFolders('public', snap);
        });

    this.sub_files = FirebaseDB
        .collection('community_entities')
        .doc(community_uid)
        .collection('files')
        .where('folder', '==', folder)
        .limit(200)
        .onSnapshot((snap) => {
            this.setFiles(snap);
        });
}

export function handleSetFolders(type, snap, community_uid,{setLength,setNames}) {
    let folders = {};
    const docs = snap.docs;
    const len = docs.length;
    for (let i = 0; i < len; i++) {
        let doc = docs[i];
        let item = doc.data();
        item.id = doc.id;
        folders[doc.id] = item;

        const lse = convertEntityForLocalStorage(community_uid, 'folders', item);

        saveEntityToLocalStorage(community_uid, 'folders', doc.id, lse);
    }
    const key = type === 'public' ? 'public_folders' : 'private_folders';
    return folders;
}

export function getFileMap(files) {
    let items = Object.entries(files);
    let map = {};
    let ordered_hold = sortFilesFolders(files, []);
    let map_names = {};
    for (let i = 0; i < items.length; i++) {
        map[items[i][0]] = i;
        map_names[items[i][1].name] = true;
    }
    const ordered = ordered_hold.map(fil => {
        return {
            id: fil.id,
            name: fil.name
        }
    });
    return {
        map,
        ordered,
        map_names
    };
}

export function handleSetFiles(snap,community_uid) {
    let files = {};
    const docs = snap.docs;
    const len = docs.length;

    for (let i = 0; i < len; i++) {
        let doc = docs[i];
        let item = doc.data();
        item.id = doc.id;
        files[doc.id] = item;
    }

    return files;
}