import React, {useContext, useState} from 'react';
import {DetailModal} from "../../../library/playground/detail-modal";
import {CommunityContext} from "../community-context";
import {ClipboardIcon} from "@heroicons/react/solid";
import {HomePinboard} from "./home-pinboard";

function RichTab({
                     active = false,
                     onClick = ()=>{},
                     label,
                     icon
                 }) {

    return <div onClick={onClick} className={`px-2 h-8 flex space-x-2 py-1.5 ${active?" bg-selection hover:bg-secondary-500":"bg-white  hover:bg-gray-200"} rounded-md transition-colors cursor-pointer`}>
        <div className={`h-svg-5 h-8 flex-none ${active?"text-gray-200":"text-gray-600"}`}>
            {icon}
        </div>
        <div className={`text-sm flex-grow font-semibold ${active?"text-white":"text-gray-800"}`}>
            {label}
        </div>
    </div>
}

export function CommunityPreferencesModal({
                                              initial_tab = "Home Pinboard",
                                              onClose = ()=>{}
                                          }) {
    const [tab, setTab] = useState(initial_tab);
    const community = useContext(CommunityContext);

    const meta = {
        community
    };

    let tabs = [
        {
            icon: <ClipboardIcon />,
            label: "Home Pinboard"
        }
    ];

    let actions = null;

    function renderTab() {
        switch (tab) {
            case 'Home Pinboard':
                return <HomePinboard {...meta} />
            default : {
                return <div>
                    Sorry, nothing found.
                </div>
            }
        }
    }

    const custom = <div className="grid grid-cols-3 gap-5 px-4 py-3">
        <div className="">
            <div className="space-y-1">
                {tabs.map((t,k)=>{
                    return <RichTab onClick={()=>{
                        setTab(t.label);
                    }} key={k} active={tab===t.label} {...t} />;
                })}
            </div>
        </div>
        <div className="col-span-2">
            {renderTab()}
        </div>
    </div>;

    return <DetailModal padded_header min_height="460px" size="plus" title={`${community.data.name} Preferences`} actions={actions} onClose={onClose} tabs={[{label:"Group",type:"custom",component:custom}]} />
}