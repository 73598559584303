import React from "react";
import {Link} from "react-router-dom";
import styled from "styled-components";

const SML = styled.span`
    font-size: 14px;
`;

export function SubtitleMetaLink({text, onClick, lowercase=false, link = '#'}) {
    return <SML className="inline-flex items-center" onClick={()=>onClick?onClick():null}>
        <Link className={`text-gray-500 whitespace-nowrap `} to={link}>
            {text}
        </Link>
    </SML>
}