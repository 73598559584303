import styled from "styled-components";
import React from "react";
import {months} from "../../../../data/general/months";
import {Input,SimpleListbox} from "../../../core";
import {countries_array} from "../../../../data/general/countries";
import {seasons, seasonsObj} from "../../../../data/general/seasons";

function getLayoutFromTimeFormat(tf) {
    if(tf==='MM/DD/YYYY') {
        return '2fr 1fr 1.5fr'
    } else if(tf==='UU/YYYY') {
        return '3fr 2fr'
    } else if(tf==='MM/YYYY') {
        return '3fr 2fr'
    } else if(tf==='YYYY') {
        return '1fr'
    } else {
        return '1fr 2fr 1.5fr'
    }
}

const BDaySelectorOuter = styled.div`
    display: grid;
    grid-template-columns: ${props=>getLayoutFromTimeFormat(props.time_format)};
    grid-gap: 8px;
`;

function dateIsInvalid(date) {
    // check if is number
    if(!date||isNaN(date)) {
        return false;
    }
    return date>31||date<1;
}

function yearIsInvalid(year) {
    if(!year||isNaN(year)) {
        return false;
    }
    return !(year>1500&&year<2100);
}

function getListboxValue(v,t) {
    // can't do !v because then v==0 is taken out
    if(v===null||v==='') {
        return null;
    }
    if(t==='months') {
        // becasue month 0 === null selector

        if(isNaN(v)) {
            return null;
        }
        const item = months[(v+1)];
        return {
            value: v,
            label: item.label
        }
    } else {
        return {
            value: v?v:null,
            label: seasonsObj[v]
        }
    }
}

export const BirthdaySelector = ({value, autoFocus = false, time_format='MM/DD/YYYY', onChange}) => {
    let new_birthday = value;
    const split_char = time_format.includes('/') ? '/' : '.';
    const order = time_format.split(split_char);
    return <BDaySelectorOuter time_format={time_format} className={`birthday-selector ${time_format}`}>
        {order.map((item,k)=>{
            switch (item) {
                case 'MM':
                    return <div key={'month'} className='month'>
                        <SimpleListbox usePortal openMenuOnFocus placeholder="Month" onChange={(v,t,e)=>{
                            if(!v||v.value===null) {
                                new_birthday.month = null;
                                onChange(new_birthday);
                            } else {
                                new_birthday.month = parseInt(v.value);
                                onChange(new_birthday);
                            }
                            // ideally focus here on the next item
                        }} value={getListboxValue(new_birthday?new_birthday.month:null,'months')} searchable options={months} />
                    </div>;
                case 'UU':
                    return <div key={'season'} className="season">
                        <SimpleListbox openMenuOnFocus placeholder="Season" onChange={(v)=>{
                            if(!v) {
                                new_birthday.season = '';
                                onChange(new_birthday);
                            } else {
                                new_birthday.season = v.value;
                                onChange(new_birthday);
                            }
                        }} value={getListboxValue(new_birthday?new_birthday.season:null, 'season')} searchable options={seasons} />
                    </div>
                case 'DD':
                    return <div key={'date'} className='date'>
                        <Input autoFocus={k===0&&autoFocus} type="number" min={0} max={31} onChange={(v)=>{
                            if(v) {
                                new_birthday.date = parseInt(v);
                                onChange(new_birthday);
                            } else {
                                new_birthday.date = null;
                                onChange(new_birthday);
                            }

                        }} onBlur={()=>{
                            if(dateIsInvalid(new_birthday.date)) {
                                alert('Please enter a valid date');
                                new_birthday.date = null;
                                onChange(new_birthday);
                            }
                        }} placeholder={"Day"} value={new_birthday&&new_birthday.date?new_birthday.date:''}/>
                    </div>;
                case 'YYYY':
                    return <div key={'year'} className='year'>
                        <Input autoFocus={k===0&&autoFocus} type="number" min={1500} max={2100} onChange={(v)=>{
                            new_birthday.year = parseInt(v);
                            onChange(new_birthday);
                        }} onBlur={()=>{
                            if(yearIsInvalid(new_birthday.year)) {
                                alert('Please enter a valid year');
                                new_birthday.year = null;
                                onChange(new_birthday);
                            }
                        }} placeholder={"Year"} value={new_birthday&&new_birthday.year?new_birthday.year:''}/>
                    </div>;
                default:
                    return null;
            }
        })}
    </BDaySelectorOuter>
};