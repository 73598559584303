import React from 'react';
import {AuthorPoll} from "../../../../library/components/poll/author";
import {PollLiveWrapper} from "../../../../library/components/poll/live-wrapper";
import {RichTextTestWrapper} from "./rich-text-test-wrapper";

export function CAdminTesting() {

    return <div className="space-y-4">
        <div>
            <RichTextTestWrapper />
        </div>
        <div>
            <PollLiveWrapper id="s8zR7xfBeVXryCxmbCDy" />
        </div>
        <div>
            DIVIDER
        </div>
        <AuthorPoll />
    </div>
}