import React, {useState} from "react";
import styled from "styled-components";
import {UnatySVG} from "../../../../icons/unaty/logos";
import {Avatar} from "../../../core";
import {SidebarTopMenu} from "../../../reorganize/sidebar-top-menu";
import {Popover2} from "@blueprintjs/popover2";
import {ChevronDownIcon} from "@heroicons/react/solid";

const UnatyLogo = styled.div`
  padding: 1px;

  > svg {
    height: 32px;
  }
`;

const ChevIcon = styled.div`
  > svg {
    height: 16px;
  }
`;

/*
 <div>
                {!global && <div className="cursor-pointer rounded-42 shadow" style={{height: '28px'}}>
                    <Avatar size="30px" type="community" bg={bg} text={community.name} border_color={border}
                            url={community.profile_picture ? `${community.profile_picture}_medium?alt=media` : ""}/>
                </div>}
                {global && <UnatyLogo className="flex pl-0.5 h-full items-center"><UnatySVG/></UnatyLogo>}
                </div>
 */

/*
   isOpen={open} onInteraction={(nextOpenState, e)=>{
          if(nextOpenState) {
              setOpen(true);
          } else if(e) {
              const content = document.getElementsByClassName('community-menu-popover')[0];

              if (e&&e.target&&content !== e.target && (!content.contains(e.target) || (e.target.className.indexOf('bp4-popover2-dismiss')===-1))) {
                  // clicks outside of div

                  setOpen(false);
              } else {
              }

          }
      }}
       {!global && <div className="cursor-pointer rounded-42 shadow" style={{height: '28px'}}>
                      <Avatar size="28px" type="community" bg={bg} text={community.name} border_color={border}
                              url={community.profile_picture ? `${community.profile_picture}_medium?alt=media` : ""}/>
                  </div>}
                  {global && <UnatyLogo className="flex pl-0.5 h-full items-center"><UnatySVG/></UnatyLogo>}
   */

export function SidebarSpaceInfo({
                                     global = false,
                                     bg,
                                     border,
                                     user,
                                     theme,
                                     is_admin = false,
                                     community_handle = '',
                                     communities = [],
                                     goTo,
                                     community_data = {},
                                     loading = false,
                                     community = {
                                         name: '',
                                         image: ''
                                     }
                                 }) {

    const content = <SidebarTopMenu user={user} is_admin={is_admin} global={global} goTo={goTo}
                                    community={community} communities={communities}
                                    community_data={community_data} community_handle={community_handle}/>;

    return <div className={`flex h-9 w-9 justify-center`}>
        <Popover2 interactionKind="click"
                  content={content}
                  placement={'bottom-start'} minimal popoverClassName='minimal-popover mt-1 community-menu-popover'>
            <div className={`h-9 flex items-center cursor-pointer duration-100 transition-colors`}>
                <div className={"cursor-pointer hover:opacity-70 transition-opacity "}>
                    {!global && <div className="h-9 items-center flex">
                        <Avatar border border_color={theme.bg_border} inner_size="32px" size="34px" type="community" bg={bg} text={community.name}
                                url={community.profile_picture ? `${community.profile_picture}_medium?alt=media` : ""}/>
                    </div>}
                    {global && <UnatyLogo className="flex h-full items-center"><UnatySVG fill="#ffffff"/></UnatyLogo>}
                </div>
            </div>
        </Popover2>
    </div>;
}