import React, {useEffect, useState} from 'react';
import {FirebaseDB} from "../../../../config/setup-firestore";
import {ActionWithIcon, InfoState} from "../../../../library/components/core";
import {ArrowRightIcon} from "@heroicons/react/outline";
import {useHistory, useLocation} from "react-router-dom";

function Group({data, id, title, onClick}) {
    return <div onClick={() => onClick()} className="px-3 hover:opacity-80 transition-opacity cursor-pointer items-center flex py-1.5">
        <div className="flex-grow">
            <div className="font-bold text-smbase text-gray-800 line-clamp-2">{title}</div>
            <div className="text-xs pt-px text-gray-500 line-clamp-2">{Object.keys(data.user_uids).length} members</div>
        </div>
        <div className="flex-none">
            <ActionWithIcon icon={<ArrowRightIcon/>} onClick={() => onClick()}/>
        </div>
    </div>
}

export function PublicCommunityGroupsPage({community_uid = ""}) {
    const [groups, setGroups] = useState(null);
    const history = useHistory();
    const location = useLocation();
    useEffect(function () {
        getGroups();
    }, [])

    function getGroups() {
        FirebaseDB
            .collection('community_entities')
            .doc(community_uid)
            .collection('groups')
            .where('enable_public_page', '==', true)
            .where('display_leaders','==',false)
            .get()
            .then(snap => {
                setGroups(snap.docs.map(doc => {
                    const data = doc.data();
                    return {
                        id: doc.id,
                        title: data.name,
                        data: data,
                        subtitle: data.purpose
                    }
                }).sort((a, b) => (a.name < b.name) ? 1 : -1))
            })
    }

    if (!groups) {
        return <div>
            <InfoState subtitle="-"/>
        </div>
    }

    return <div className="px-1 pt-1.5 pb-20">
        <div className="">
            {groups.map(group => {
                return <Group key={group.id} onClick={() => {
                    history.push(`${location.pathname}?group=${group.id}`);
                }} community_uid={community_uid} {...group}  />
            })}
            {groups.length===0&&<InfoState subtitle="-" />}
        </div>
    </div>
}