import React, {useState, useEffect} from "react";
import {FirebaseDB} from "../../config/setup-firestore";
import styled from "styled-components";

const ListItem = styled.div`
 padding: 8px 12px;
 font-size: 15px;
 font-weight: ${props=>props.active?'600':'400'};
`;

const List = styled.div`
 margin-bottom: 20px;
`;

export function DataList({collection,selected,onSelect,doc,subcollection}) {
    const [data, setData] = useState(null);

    useEffect(() => {
        function handleData(snap) {
            setData(snap.docs.map(doc => {return {...doc.data(), id:doc.id}}));
        }

        let unsubscribe;

        if(subcollection) {

            unsubscribe = FirebaseDB
                .collection(collection)
                .doc(doc)
                .collection(subcollection)
                .onSnapshot(handleData);
        } else {

            unsubscribe = FirebaseDB
                .collection(collection)
                .onSnapshot(handleData);
        }

        // Specify how to clean up after this effect:
        return () => unsubscribe();
    }, [collection,subcollection,doc]);

    if (data === null) {
        return null;
    }

    return <List>
        {data.map((item) => {
        return <ListItem active={selected===item.id} onClick={()=>{onSelect(item.id,item,'option')}} key={item.label}>
            {item.label}
        </ListItem>
    })}
    </List>
}