import React, {useContext, useState} from "react";
import {NewTooltip} from "../../../../../../library/components/reorganize/new-tooltip";
import {ProfileBadge} from "../../../../../../library/components/profile-badge";
import {AdMeActionChangeAccountEmail} from "../../actions/change-account-email";
import {isAnonymousUnatyEmail} from "../../../../../../library/utilities/anonymous-emails";
import {authFetch} from "../../../../../../config/network";
import {useHistory} from "react-router-dom";
import {GlobalContext} from "../../../../../global/global-context";

const code_messages = {
    'known-member': 'Member has already logged in - they should change the email themselves',
    '': 'Not sure!',
    'email-belongs-to-existing-member': 'Email already taken by another member',
};

export function AccountEmailBadge({member,member_id,email='',community,layout='admin-profile',changeEmail}) {
    const history = useHistory();
    const global = useContext(GlobalContext);
    const [loading,setLoading] = useState(null);
    const [open,setOpen] = useState(null);
    const [default_email,setDefaultEmail] = useState(email);
    function changeAccountEmail(new_email) {
        setLoading('changing-account-email');
        global.addToast({text: `Changing account email`, intent: 'info'});

        const res = (resp) => {
            setLoading('');
            setOpen(null);

            if (resp.data.ok === 'yes') {
               // history.push(`/${community.data.handle}/admin/member/${resp.data.id}`);
                global.addToast({text: `Account Email changed`, intent: 'success'});
                changeEmail(resp.data.member.account_email);
            } else {
                // no error is shown
                global.addToast({text: resp.data.code&&code_messages[resp.data.code]?code_messages[resp.data.code]:"Something went wrong..", intent: 'danger'});
            }
        };

        const payload = {
            member_id: community.member_id,
            community_uid: community.uid,
            id: member_id,
            new_email,
            member_user_uid: member.user_uid,
            user_uid: community.member.user_uid,
        };

        authFetch("/members/change-account-email", res, res, "POST", {payload});
    }

    let content;

    if(layout==='field-meta') {
        content = <div>
            Change account email to <span className="underline cursor-pointer" onClick={()=> {
                setOpen(true)
        }}>{default_email}</span>? <span className="underline cursor-pointer">Ignore</span>
        </div>
    } else {
        content = <NewTooltip message="Click to change account email">
            {isAnonymousUnatyEmail(member.account_email)?<ProfileBadge onClick={()=>setOpen(true)} type="email-warning" text={`Missing Email`} />:<ProfileBadge onClick={()=>setOpen(true)} type="text" text={`${member.account_email}`} />}
        </NewTooltip>
    }

    return <>
        {content}
        {open&&<AdMeActionChangeAccountEmail
            default_email={default_email}
            loading={loading === 'changing-account-email'} community_uid={community.uid}
            onConfirm={(h) => changeAccountEmail(h)}
            onClose={() => {
                setOpen(null);
            }} onCancel={() => {
            setOpen(null);
        }}
        />}
    </>
}