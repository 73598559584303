import React, {useContext, useState} from "react";
import {ChevronDownIcon} from "@heroicons/react/solid";
import {Button} from "../../../../library/components/core";
import {Popover2} from "@blueprintjs/popover2";
import {PopupMenu} from "../../../../library/components/old/popup-menu";
import {authFetch} from "../../../../config/network";
import {GlobalContext} from "../../../global/global-context";
import {isAnonymousUnatyEmail} from "../../../../library/utilities/anonymous-emails";
import {useHistory} from "react-router-dom";
import {DoubleCheckModal} from "../../../../library/components/old/double-check-modal";
import {membersBuildInviteTo} from "../../../../library/playground/advanced-table/wrapper";

export function NewAdminMemberActions({member_id,hc=()=>{},community,member}) {
    const [loading,setLoading] = useState('');
    const [modal,setModal] = useState('');
    const global = useContext(GlobalContext);
    const history = useHistory();

    function sendQPUEmail() {
        setLoading('qpu');
        const res = () => {
            setTimeout(() => {
                setLoading('');
                global.addToast({text: `Quick Profile Update Email sent`, intent: 'success'});
            }, 1000);
        };

        const payload = {
            requesting_member_id: community.member.user_uid,
            community_uid: community.uid,
            member_id: community.member_id,
            id: member_id,
            user_uid: community.member.user_uid,
        };

        authFetch("/members/send-qpu-email", res, res, "POST", {payload})
    }

    function sendInvite() {
        const email = member.account_email;

        setLoading('_sending_invite');

        const prefill = {
            to: membersBuildInviteTo([
                {...member,id: member_id}
            ]),
            config: {},
            content: `${community.member.name} (${community.member.account_email}) invited you to join our ${community.data.name} membership platform.`,
            subject: `Join ${community.data.name} on Unaty`,
            type: 'invite',
            template: 'system-invite'
        };

        community.openComposer(prefill)
    }

    function deleteMember() {
        global.addToast({text: "Deleting member", intent: 'info'});
        history.push(`/${community.data.handle}/admin/members`);
        global.handleCloseRightMenu();

        const res = async () => {
            setLoading('');
            global.addToast({text: "Member was deleted", intent: 'success'});
        };

        setLoading('_loading_delete');

        const payload = {
            community_uid: community.uid,
            member_id: community.member_id,
            id: member_id,
            user_uid: community.member.user_uid
        };

        authFetch("/members/delete", res, res, "POST", {payload})
    }

    function resetPassword() {
        setLoading('_sending_invite');

        const prefill = {
            to: membersBuildInviteTo([
                {...member,id: member_id}
            ]),
            config: {},
            content: `Hi ${member.name}, we wanted to make sure you had everything you need to get logged into our membership platform.`,
            subject: `Your Access Details for ${community.data.name}`,
            type: 'access-details',
            template: 'standard'
        };

        community.openComposer(prefill)
    }

    function unArchive() {
        global.addToast({text: "Reactivating member", intent: 'info'});
        const res = () => {
            global.addToast({text: "Member was reactivated", intent: 'success'});
        };
        hc('archived',false);
        setLoading('_loading_archive');

        const payload = {
            member_id: community.member_id,
            id: member_id,
            user_uid: community.member.user_uid,
            community_uid: community.uid,
        };

        authFetch("/members/unarchive", res, res, "POST", {payload})
    }

    function archive() {
        global.addToast({text: "Deactivating member", intent: 'info'});
        const res = () => {

            setLoading('');
            global.addToast({text: "Member account was deactivated", intent: 'success'});
        };
        hc('archived',true);
        setLoading('_loading_archive');

        const payload = {
            community_uid: community.uid,
            member_id: community.member_id,
            id: member_id,
            user_uid: community.member.user_uid,
            archived_reason: member.archived_reason
        };

        authFetch("/members/archive", res, res, "POST", {payload});
    }

    let options = [];

    // archive / unarchive
    if(member.archived) {
        options.push({
            type: 'item',
            title: 'Reactivate member',
            onClick: ()=>setModal('member_unarchive')
        });
        options.push({
            type: 'item',
            intent: 'danger',
            title: 'Delete member',
            onClick: ()=>setModal('member_delete')
        });
    } else {
        options.push({
            type: 'item',
            title: 'Deactivate member',
            onClick: ()=>setModal('member_archive')
        });

        if(isAnonymousUnatyEmail(member.account_email)) {
            // nothing
        } else if(member.last_sign_in) {
            options.push({
                type: 'item',
                title: 'Send Data Check Email',
                onClick: ()=>sendQPUEmail()
            });
            options.push({
                type: 'item',
                title: 'Send Account Details',
                onClick: ()=>resetPassword()
            });
        } else {
            options.push({
                type: 'item',
                title: 'Send Invite',
                onClick: ()=>sendInvite()
            });
        }
    }

    // Send Quick Profile Update Email
    return <>
        <Popover2 content={<PopupMenu items={options}/>} placement={'bottom-end'} minimal
                      popoverClassName='minimal-popover my-3'>
        <Button intent="manage" size="sm" text="Actions" right_icon={<ChevronDownIcon />} />
    </Popover2>
        {modal&&<DoubleCheckModal onConfirm={()=> {
            setModal(null);
            if(modal==='member_archive') {
                archive();
            } else if(modal==='member_delete') {
                deleteMember();
            } else if(modal==='member_unarchive') {
                unArchive();
            }

        }} onClose={()=>{
            setModal(null);
        }} onCancel={()=>{
            setModal(null);
        }} type={modal} />}
        </>
}