import React, {useContext, useState} from "react";
import EditFieldsSection from "../../../../../../library/components/old/edit-fields-section";
import {setDocumentTitle} from "../../../../../../library/utilities/set-document-title";
import {CommunityContext} from "../../../../community-context";
import {Button, Field, InlineLoader, Input, SectionHeader} from "../../../../../../library/components/core";
import {BasicSelect} from "../../../../../../library/components/core/basic-select";
import {authFetch} from "../../../../../../config/network";
import {GlobalContext} from "../../../../../global/global-context";

const _membership_period_mock = {
    frequency: 12,
    start_month: 0, // jan,
    labels: {
        // 0: 'SS {{start_date.YY}}'
        // 1: 'WS {{start_date.YY}}/{{end_date.YY}}'
    }
};

const default_membership_period = {
    type: "annual",
    frequency: 12,
    start_month: 0,
    labels: JSON.stringify({})
};

const membership_selector = [
    {label: "Annually", value: "annual", data: 12},
    {label: "Bi-Annually", value: "bi-annual", data: 6},
    {label: "Quarterly", value: "quarter", data: 3},
    {label: "Monthly", value: "month", data: 1},
    {label: "Custom", value: "custom", data: null}
];

function deriveMembershipPeriods(data) {
    // here we need to add one period to an array for each relevant unit
    // so if the unit is year, we need to add one period for each year
    // if the unit is month, we need to add one period for each month

    let periods = [];

    // so for basic example of frequency = 12 (months) which is annual, we should return 1 period
    // how would this work for bi-annual -> return 2 periods at 0 and 1 // each should have start month
    // for quarterly -> return 4 periods at 0, 3, 6, 9 // each should have start month
    // for monthly -> return 12 periods at 0, 1, 2, 3, 4, 5, 6, 7, 8, 9 // each should have start month
    // how does this work where period = 24 (months) which is 2 years

    const periods_to_create = Math.ceil(12 / data?.frequency);

    for (let i = 0; i < periods_to_create; i++) {
        const period_start_month = (data?.start_month + (data?.frequency * i)) % 12;
        const period_end_month = (period_start_month + data?.frequency - 1) % 12;
        periods.push({
            start_month: period_start_month,
            end_month: period_end_month
        });
    }

    return periods;
}

const months = [
    {label: "January", value: 0},
    {label: "February", value: 1},
    {label: "March", value: 2},
    {label: "April", value: 3},
    {label: "May", value: 4},
    {label: "June", value: 5},
    {label: "July", value: 6},
    {label: "August", value: 7},
    {label: "September", value: 8},
    {label: "October", value: 9},
    {label: "November", value: 10},
    {label: "December", value: 11},
];

function parseConfig(cf) {
    return {
        ...cf,
        labels: JSON.parse(cf?.labels || "{}")
    }
}

// the custom labels at least should be stringified..
function EditMembershipPeriod() {
    const global = useContext(GlobalContext);
    const community = useContext(CommunityContext);
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState(parseConfig(community.data.preferences.members.period || {...default_membership_period}));

    function handleChange(key, value) {
        if (key === 'type') {
            if (value === 'custom') {
                setData({
                    ...data,
                    type: "custom"
                });
            } else {
                const frequency = membership_selector.find(v => v.value === value).data;

                setData({
                    ...data,
                    type: value,
                    frequency
                });
            }
        } else {
            console.log("setting", key, value)
            setData({...(data || {}), [key]: value});
        }
    }

    function handleSave() {
        const payload = {
            period: {
                ...data,
                labels: JSON.stringify(data?.labels || {})
            },
            community_uid: community.uid,
            member_id: community.member_id
        };

        setLoading(true);

        const res = (resp) => {
            global.addToast({text: 'Settings updated', intent: 'success'});
            setLoading(false);
        };

        authFetch("/communities/update-membership-period", res, res, "POST", {payload});
    }

    const derived_membership_periods = deriveMembershipPeriods(data);

    const type_value = membership_selector.find(v => v.value === data?.type);

    const start_month = months.find(v => v.value === data?.start_month);

    return <div className="rounded-md border border-gray-200 bg-white p-4">
        <SectionHeader padded={false} title="Membership Period"/>

        <div className="flex flex-col gap-4 pb-8 pt-2">

            <Field label="Membership within our Community is measured:">
                <BasicSelect value={type_value} clearable={false} onChange={(val) => {
                    handleChange('type', val.value);
                }} options={membership_selector}/>

                {data?.type === 'custom' && <div className="mt-2">
                    <div className="flex flex-col gap-2">
                        <div className="flex flex-col gap-2">
                            <label>How many months in a membership period?</label>
                            <Input type="number" custom={{max: 144, min: 1}} value={data?.frequency}
                                   onChange={v => handleChange('frequency', v)}/>
                        </div>
                    </div>
                </div>}
            </Field>

            <Field label="Start Month">
                <BasicSelect value={start_month} clearable={false} onChange={(val) => {
                    handleChange('start_month', val.value);
                }} options={months}/>
            </Field>

        </div>

        <SectionHeader padded={false} title="Membership Period Custom Labels"/>
        <div className="flex flex-col gap-2 pt-2">
            {derived_membership_periods.map((period, i) => {
                let note = `${months[period.start_month].label} - ${months[period.end_month].label}`;
                return <Field key={i} label={`Label for Period ${i + 1}`} corner_hint={`${note}`}>
                    <Input value={data?.labels[i]} placeholder={`{{start_date.YYYY}} Membership`}
                           onChange={v => handleChange('labels', {...data?.labels || {}, [i]: v})}/>
                </Field>
            })}
        </div>

        <div className="flex pt-2">
            <div className="flex-none">
                <Button loading={loading} intent='success' onClick={() => {
                    handleSave()
                }} text='Save Changes'/>
            </div>
            <div className="flex-grow"/>

        </div>
    </div>
}

export default function SettingsMembers(props) {
    const community = useContext(CommunityContext);
    setDocumentTitle('Members', community.data.name);

    const member_types = community.member_types;

    const obj = {
        base_link: `/${community.data.handle}/admin/settings`,
        data: community.data.preferences.members,
        section_text: 'Member Defaults',

        endpoint: '/communities/update-members',
        update_fields: ['default_member_type', 'enable_member_opt_in', 'permissions', 'display_name'],

        sections: [
            {
                title: 'General',
                desc: '',
                fields: [
                    {
                        name: 'Display Name',
                        type: 'display-name',
                        field: 'display_name'
                    },
                    {
                        name: 'Default Member Type',
                        type: 'member-type',
                        field: 'default_member_type'
                    },
                    {
                        name: 'Allow new Member Applications',
                        type: 'switch',
                        field: 'allow_new_member_applications'
                    },
                    {
                        name: "Enable Member Opt-in",
                        type: 'switch',
                        field: 'enable_member_opt_in'
                    }
                ]
            }
        ]
    };

    if (Object.keys(member_types).length === 0) {
        return <div>
            <InlineLoader padding/>
        </div>;
    }

    return <div className="space-y-4">
        <EditFieldsSection member_types={member_types} history={props.history} {...obj} />
        <div className="p-4">
            <EditMembershipPeriod/>
        </div>
    </div>
}