import React, {useContext, useEffect, useState} from 'react';
import {CADEditCard, CADGrid} from "../shared";
import {FirebaseDB} from "../../../../../../config/setup-firestore";
import {Button, Field, Input} from "../../../../../../library/components/core";
import {BasicSelect} from "../../../../../../library/components/core/basic-select";
import {CreateGateModal, deleteGate} from "./create";
import {CommunityContext} from "../../../../community-context";
import {DoubleCheckModal} from "../../../../../../library/components/old/double-check-modal";

async function loadAllGates(community_uid) {
    console.log("loadGates", community_uid)
    if (!community_uid) {
        return null;
    }
    return await FirebaseDB
        .collection('communities')
        .doc(community_uid)
        .collection('gates')
        .get()
        .then(snap => {
            if (snap.empty) {
                return [];
            }
            return snap.docs.map(doc => {
                return {...doc.data(), id: doc.id}
            })
        })
}

function GatesList({gates, onEditGate, onDeleteGate}) {

    return <div className="grid gap-4">
        {gates.length === 0 && <div className="text-gray-500">No gates yet</div>}
        {gates.map(gate => {
            // show internal name as title
            // show type as subtitle along with status
            // show edit button which opens
            return <div key={gate.id} className="bg-white border-gray-200 border rounded-md p-4">
                <div className="text-base font-bold">{gate.internal_name}</div>
                <div className="text-sm text-gray-500">Type: {gate.type} - Status: {gate.status}</div>
                <div className="pt-2 flex gap-2">
                    <Button onClick={() => {
                        onEditGate(gate.id, gate)
                    }} text='Edit'/>
                    <Button onClick={() => {
                        onDeleteGate(gate.id, gate)
                    }} text='Delete'/>
                </div>
            </div>
        })}
    </div>
}

const __MOCK__gate = {
    internal_name: "string",
    type: "string", // policy, opt-in, paywall, questionnaire, approval
    scope: "string", // community, group, event
    scope_id: "string", // id of scope
    scope_apply_to: "string", // applicants, members, leaders, admins, followers
    scope_apply_to_advanced: "map",
    status: "string", // active, inactive, draft, archived
    data: 'map',
    options: 'map',
    created_at: 'timestamp',
    updated_at: 'timestamp',
    created_by: 'string',
    updated_by: 'string',
}

const __MOCK__gate_types = {
    policy: {
        data: {
            policy_ids: '[string]'
        },
        options: {}
    },
    onboarding: {
        data: {
            flow: 'string'
        },
        options: {}
    }
}

const gate_types = {
    policy: {
        name: 'Policy',
        description: 'Require members to accept a policy'
    },
    opt_in: {
        name: 'Opt-In',
        description: 'Require members to opt-in to something'
    },
    onboarding: {
        name: 'Onboarding',
        description: 'Require members to complete onboarding'
    },
    paywall: {
        disabled: true,
        name: 'Paywall',
        description: 'Require members to pay to access something'
    },
    questionnaire: {
        disabled: true,
        name: 'Questionnaire',
        description: 'Require members to answer questions'
    },
    approval: {
        disabled: true,
        name: 'Approval',
        description: 'Require members to be approved'
    },
};

export function PoliciesSelect({
                                   value = [], onChange = () => {
    }
                               }) {
    const community = useContext(CommunityContext);
    const [options, setOptions] = useState([]);

    useEffect(function () {
        if (!community.uid) {
            return;
        }
        FirebaseDB
            .collection('communities')
            .doc(community.uid)
            .collection('policies')
            .where('status', '==', 'published')
            .get()
            .then(snap => {
                if (snap.empty) {
                    return [];
                }
                setOptions(snap.docs.map(doc => {
                    return {
                        label: doc.data().name,
                        value: doc.id
                    }
                }))
            })
    }, [community.uid]);

    const processed_value = value.map(function (a) {
        return {
            label: options.filter(function (b) {
                return b.value === a;
            })[0]?.label || "Loading",
            value: a
        }
    });
    return <div>
        <BasicSelect clearable={false} onChange={(val) => {
            onChange(val ? val.map(a => a.value) : []);
        }} multi value={processed_value} options={options}/>
    </div>
}

function GateEditor({id, handleChange, community_uid, handleSave, loading, changed, data}) {

    // internal name and data and options can be edited
    // type, scope, scope_id, scope_apply_to, scope_apply_to_advanced, status, created_at, updated_at, created_by, updated_by cannot be edited
    // if deactivated, can be reactivated or archived
    return <div className="grid gap-4">
        <Field label="Internal Name">
            <Input value={data?.internal_name} onChange={v => handleChange('internal_name', v)}/>
        </Field>

        <Field label="Type">
            <div className="text-sm text-gray-500">{gate_types[data?.type]?.name}</div>
        </Field>

        <Field label="Status">
            <div className="text-sm text-gray-500">{data?.status}</div>
            <div className="pt-2 grid gap-2">
                {data?.status === 'inactive' && <Button intent='primary' onClick={() => {
                }} text='Activate'/>}
                {data?.status === 'inactive' && <Button onClick={() => {
                }} text='Archive'/>}
                {data?.status === 'active' && <Button onClick={() => {
                }} text='Deactivate'/>}
            </div>
        </Field>

        <Field label="Data">
            {data?.type === 'policy' && <div>
                <PoliciesSelect/>
            </div>}
        </Field>

        <Field label="Options">

        </Field>

        <div className="flex">
            <div className="flex-grow"></div>
            <div>
                <Button loading={loading} disabled={!changed} intent='success' onClick={() => {
                    handleSave(id)
                }} text='Save Changes'/>
            </div>
        </div>
    </div>
}

function CreateGateActions({gates, startCreateGateFlow, scope_id, scope_apply_to, scope}) {

    // can create one of each type of gate that is not disabled
    // for each gate type to create, we should have a button that says "Add Policy Gate" or "Add Opt-In Gate" etc
// when clicked, it should open a modal with the form to create that gate
// when the gate is created, it should be added to the list of gates

    const gates_can_create = Object.keys(gate_types).filter(function (a) {
        return !gate_types[a].disabled;
    }).map(function (a) {
        return {
            ...gate_types[a],
            type: a
        }
    }).filter(function (a) {
        // check if already exists
        return !gates.filter(function (b) {
            return b.type === a.type;
        }).length;
    });


    return <div className="flex gap-2">
        {gates_can_create.map(gate => {
            return <div key={gate.type}>
                <Button text={`Add ${gate.name} Gate`} onClick={() => {
                    startCreateGateFlow({
                        internal_name: "",
                        type: gate.type,
                        status: "inactive",
                        scope,
                        scope_id,
                        scope_apply_to,
                        scope_apply_to_advanced: {},
                        data: {
                            flow: "",
                            policy_ids: []
                        },
                        options: {},
                    })
                }}/>
            </div>
        })}
    </div>
}

export function CADGateDesigner(props) {
    const community = useContext(CommunityContext);
    const [selected, setSelected] = useState(null);
    const [changed, setChanged] = useState(false);
    const [loading, setLoading] = useState(false);
    const [modal, setModal] = useState(null);
    const [modal_data, setModalData] = useState(null); // {type,scope,scope_id
    const [data, setData] = useState(null);

    useEffect(function () {
        loadGates()
    }, [community.uid])

    function loadGates() {

        loadAllGates(community.uid)
            .then(gates => {
                console.log("RESULT", gates)
                setData(gates);
            })

    }

    function handleSave() {
        // save
        setLoading(true);
        alert('handle save');

        /*
        api_editEmailTemplate(community,selected,data)
            .then(()=>{
                setLoading(false);
                setChanged(false);
            })

         */
    }

    function handleStartCreateGateFlow(dt) {
        setModalData(dt);
        setModal('create-gate');
    }

    function handleChange(key, value) {
        let has_changed = false;

        if (data) {
            if (data[key] !== value) {
                has_changed = true;
            }
        }

        // setEmailData({...(data || {}), [key]: value});

        if (has_changed) {
            setChanged(true);
        }
    }

    function handleDeleteGate() {
        const gid = modal_data.id;

        if (!gid) {
            return;
        }

        deleteGate(gid, community)
            .then(() => {
                setModal(null);
                setModalData(null);

                loadGates();
            });
    }

    // need to get selected item data by matching id of selected to id of data
    const selected_data = selected && data && data.filter(d => d.id === selected)[0];

    return <CADGrid>
        <div className="space-y-2">
            <CADEditCard title="Member Gates">
                {data && !selected && <div>
                    <GatesList onEditGate={(gid, gdata) => {
                        setModalData(gdata);
                        setModal('edit-gate');
                    }} onDeleteGate={(gid, gdata) => {
                        setModalData({id: gid})
                        setModal('double-check-delete');
                    }} onSelect={gid => {
                        setSelected(gid);
                    }} gates={data}/>

                    <div className="pt-4">
                        <CreateGateActions startCreateGateFlow={handleStartCreateGateFlow} scope_apply_to="members"
                                           scope_id={community.uid} scope="community" gates={data}/>
                    </div>
                </div>}
                {data && selected && selected_data && <div>
                    <GateEditor community_uid={community.uid} handleChange={handleChange} loading={loading}
                                changed={changed} handleSave={handleSave}
                                id={selected} data={selected_data}/>
                </div>}
            </CADEditCard>
        </div>
        <div>

        </div>

        {(modal === 'edit-gate' || modal === 'create-gate') && modal_data &&
            <CreateGateModal id={modal_data?.id || ""} type={modal === 'create-gate' ? "create" : "edit"}
                             init_data={modal_data} onConfirm={() => {
                setModal(null);
                setModalData(null);
                loadGates()
            }} onClose={() => {
                setModal(null)
                setModalData(null)
            }}/>}

        {modal === 'double-check-delete' && <DoubleCheckModal onConfirm={() => handleDeleteGate()} onClose={() => {
            setModal(null);
            setModalData(null)
        }} onCancel={() => {
            setModal(null);
            setModalData(null)
        }} type='gate_delete'/>}
    </CADGrid>
}