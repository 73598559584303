import React, {useState} from "react";
import styled from "styled-components";
import {FlagIcon,LocationMarkerIcon,ClipboardCheckIcon,TagIcon} from '@heroicons/react/solid';
import {BasicSelect} from "../../core/basic-select";

export const group_types = {
    interest: {
        value: 'interest',
        label: 'Interest',
        desc: 'For members that share a similar similar passion or topic.',
        color: '#E86900',
        icon: <TagIcon/>
    },
    location: {
        value: 'location',
        label: 'Location',
        desc: 'For members who live in the same area to stay connected.',
        color: '#65BC0C',
        icon: <LocationMarkerIcon/>
    },
    /*
    team: {
        value: 'team',
        label: 'Team',
        desc: 'For members that work together on a regular basis.',
        color: '#0090D7',
        icon: <FlagIcon/>
    },

     */
    project: {
        value: 'project',
        label: 'Project',
        desc: 'For members that are working together on a single project.',
        color: '#4E30C4',
        icon: <ClipboardCheckIcon/>
    },
    /*
    class: {
        value: 'class',
        label: 'Class',
        desc: 'This group is for people you really like to know and get used to.',
        color: '#E6AC01',
        icon: <LibraryIcon/>
    }

     */
};

const Wrapper = styled.div`
 width: 320px;
`;

function buildOptions() {
    const items = Object.values(group_types);
    return items.map((gt) => {
        return {
            ...gt,
            display: 'icon'
        };
    });
}



export const GroupTypeOptions = buildOptions();

function getItem(id) {
    return group_types[id];
}

export function GroupTypeSelect({onChange, is_admin, value, placeholder = "Type"}) {
    let options = [{
        value: 'standard',
        label: 'Group',
        desc: 'For members that share a similar similar passion or topic.',
        color: '#E86900',
        icon: <TagIcon/>
    }];
    if(is_admin) {
        options.splice(1,0,{
            value: 'team',
            label: 'Team',
            desc: 'For members that work together on a regular basis.',
            color: '#0090D7',
            icon: <FlagIcon/>
        });
    }
    const item = value === 'standard' ? options[0] : options[1];
    return <BasicSelect placeholder={placeholder} value={item} onChange={(val) => {
        onChange(val.value, val)
    }} options={options}/>
}

export function PLAYGroupTypeSelector() {
    const [type, setType] = useState('interest');
    return <Wrapper>

        <GroupTypeSelect placeholder='Type' value={type} onChange={(val) => {
            setType(val)
        }}/>

    </Wrapper>
}