import React, {useState} from "react";
import {authFetch} from "../../../config/network";
import {Button} from "../../../library/components/core";

export function GroupJoin({cb,id,community_uid,member_id}) {
    const [loading, setLoading] = useState(false);
    function joinGroup() {
        setLoading(true);
        const res = () => {
            setLoading(false);
            cb();
        };
        const payload = {
            id,
            member_id,
            community_uid
        };

        authFetch("/groups/join", res, res, "POST", {payload});
    }

    return <Button minimal inverted size="sm" height="32px" loading={loading} text='Join' intent='success' onClick={()=>joinGroup()} />
}