import {colors} from "../data/unaty/colors";

export function getRandomColor() {
    const num = Math.floor(Math.random() * 6) + 1;
    switch (num) {
        case 6 : {
            return colors[1];
        }
        case 5 : {
            return colors[2];
        }
        case 4 : {
            return colors[3];
        }
        case 3 : {
            return colors[4];
        }
        case 2 : {
            return colors[5];
        }
        default : {
            return colors[0];
        }
    }
}