import React from 'react';
import {
    MenuAlt2Icon,
    CheckCircleIcon,
    FlagIcon,
    BanIcon,
    ArchiveIcon,
    CakeIcon,
    LocationMarkerIcon,
    GlobeIcon,
    PhoneIcon,
    ClockIcon,
    CalendarIcon,
    TagIcon,
    UserCircleIcon,
    MailIcon,
    AtSymbolIcon,
    ChevronDownIcon, UsersIcon, StarIcon, HashtagIcon
} from '@heroicons/react/solid';
export const AT_property_types = {
    text: {
        label: 'Text',
        icon: <MenuAlt2Icon/>
    },
    textarea : {
        label: 'Textarea',
        icon: <MenuAlt2Icon/>
    },
    select : {
        label: 'Select',
        icon: <CheckCircleIcon/>
    },
    'account-email': {
        label: 'Account Email',
        icon: <AtSymbolIcon/>
    },
    teams: {
        label: 'Teams',
        icon: <FlagIcon/>
    },
    archived: {
        label: 'Deactivated',
        icon: <ArchiveIcon />
    },
    suspended: {
        label: 'Suspended',
        icon: <BanIcon />
    },
    boolean: {
        label: 'Boolean',
        icon: <CheckCircleIcon />
    },
    handle: {
        label: 'Handle',
        icon: <AtSymbolIcon/>
    },
    email: {
        label: 'Email',
        icon: <MailIcon/>
    },
    name: {
        label: 'Name',
        icon: <MenuAlt2Icon/>
    },
    member: {
        label: 'Member',
        icon: <UserCircleIcon/>
    },
    assignee: {
        label: 'Assignee',
        icon: <UserCircleIcon/>
    },
    members: {
        label: 'Members',
        icon: <UserCircleIcon/>
    },
    number: {
        label: 'Number',
        icon: <MenuAlt2Icon/>
    },
    member_type: {
        label: 'Member Type',
        icon: <TagIcon/>
    },
    tags: {
      label: 'Tags',
      icon: <TagIcon />
    },
    roles: {
        label: 'Roles',
        icon: <StarIcon />
    },
    groups: {
        label: 'Groups',
        icon: <UsersIcon />
    },
    account_status: {
        label: 'Account Status',
        icon: <UserCircleIcon/>
    },
    id: {
        label: 'ID',
        icon: <HashtagIcon/>
    },
    data_integrity: {
        label: 'Data Integrity',
        icon: <CheckCircleIcon/>
    },
    date: {
        label: 'Date',
        icon: <CalendarIcon/>
    },
    timestamp: {
        label: 'Timestamp',
        icon: <ClockIcon/>
    },
    select_email: {
      label: 'Email',
      icon: <MailIcon />
    },
    phone: {
        label: 'Phone',
        icon: <PhoneIcon/>
    },
    country: {
        label: 'Country',
        icon: <GlobeIcon/>
    },
    location: {
        label: 'Location',
        icon: <LocationMarkerIcon/>
    },
    address: {
        label: 'address',
        icon: <LocationMarkerIcon/>
    },
    birthday: {
        label: 'Birthday',
        icon: <CakeIcon/>
    }
};
