import React from 'react';
import styled from "styled-components";
import {SUPPORT_EMAIL} from "../../../../config/defaults";

const Frame = styled.div`
        margin-top: 16px;
    font-size: 14px;
    max-width: 400px;
`;

export const UserDeleteAccount = () => {

    return <Frame className='text-gray-700'>
        If you would like to deactivate and delete your account, please contact <a href={`mailto:${SUPPORT_EMAIL}`}>{SUPPORT_EMAIL}</a>.
    </Frame>
};