import React, {useContext, useEffect, useState} from "react";
import {GiftIcon} from "@heroicons/react/solid";
import {HelpTipsHome} from "./help-tips-home";
import {getSeenProductUpdates, HTWhatsNew} from "./whats-new";
import {MenuPanels} from "../../../../library/components/core";
import {tp_pu_getProductUpdates} from "../../../../library/utilities/product-updates";
import {GlobalContext} from "../../global-context";

function UpdateIcon() {
    const global = useContext(GlobalContext);
    const [updates, setUpdates] = useState([]);
    useEffect(function () {
        tp_pu_getProductUpdates()
            .then(arr => setUpdates([...arr]))
    }, []);

    let seen_updates, has_update = false;

    if(updates.length>0) {
        seen_updates = getSeenProductUpdates();

        has_update = updates[0] ? !seen_updates.includes(updates[0].version) : false;
    }

    return <div className="relative">
        <GiftIcon />
        {has_update&&<div className="bg-red-700 -bottom-1 -right-1 rounded-full h-3 w-3 absolute border-2 border-white" />}
    </div>
}

export function HelpAndTipsMenu({onClose=()=>{}}) {
    const panels = [
        {
            id: 'home',
            title: `Help & tips`,
            compact: true,
            nav: [
                {
                    title: `What's new?`,
                    target_panel: 'whats-new',
                    icon: <UpdateIcon />
                }
            ],
            component: <HelpTipsHome />
        },
        {
            id: 'whats-new',
            title: `What's new?`,
            compact: true,
            component: <HTWhatsNew />
        }
    ];

    return <MenuPanels new_layout enforce_child_height initial={''} onClose={onClose} show={true} panels={panels}/>
}