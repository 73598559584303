import React, {useContext, useState} from 'react';
import {DraftComposer} from "./draft-composer";
import {convertToRaw} from "draft-js";
import {CommunityContext} from "../../community-context";
import {getImagesFromContentState} from "../../../../library/components/composer";
import {composer_handleSendReply} from "./handle-send-reply";
import {Avatar, Button, InlineLoader} from "../../../../library/components/core";
import {GlobalContext} from "../../../global/global-context";

function Frame({onClick, reply_modal, url, children}) {
    return <div onClick={() => {
        if (onClick) {
            onClick();
        }
    }}
                className={`${onClick ? "feed-item-bg" : ""} ${reply_modal ? "" : "shared-top-border"} relative cursor-pointer px-4 pt-2 pb-1  grid gap-1.5`}
                style={{gridTemplateColumns: 'auto 1fr'}}>
        <div className="">
            <Avatar size="md" url={`${url}?alt=media`}/>
        </div>
        <div className="pl-1.5 overflow-auto">
            {children}
        </div>
        {reply_modal && <div className="absolute" style={{
            left: '2.5rem',
            height: '1rem',
            top: '-0.3rem',
            width: '2px',
            bottom: 'auto',
            backgroundColor: '#cbcbcb'
        }}>

        </div>}
    </div>
}

export function ComposeReply({
                                 reply_to_id = "",
                                 init_state = "prompt",
                                 onSendReply = () => {
                                 },
                                 post_id = "",
                                 type = "reply"
                             }) {
    const global = useContext(GlobalContext);
    const community = useContext(CommunityContext);
    const [state, setState] = useState(init_state);
    const [loading, setLoading] = useState(null);
    const [attachments, setAttachments] = useState({});
    const [editorState, setEditorState] = useState(null);

    function handleSend() {

        const contentState = editorState.getCurrentContent();

        const payload = {
            community_uid: community.uid,
            member_id: community.member_id,
            reply_id: reply_to_id,
            reply_to_id,
            post_id,

            images: getImagesFromContentState(contentState),
            attachments: Object.entries(attachments).filter(a => a[1].status === 'done').map(ent => {
                return {
                    id: ent[1].id,
                    folder: ent[1].folder,
                    url: ent[1].url,
                    name: ent[1].name,
                    type: ent[1].type,
                    size: ent[1].size
                }
            }),
            raw: convertToRaw(contentState),
            plain_text: contentState.getPlainText()
        };

        setLoading('sending');

        const res = (result) => {
            global.addToast({text: "Reply sent", intent: "success"});
            setState('prompt');
            setLoading('');
            onSendReply();
        };
        composer_handleSendReply(payload, {community, global}, res);
    }

    const cs = editorState ? editorState.getCurrentContent() : {getPlainText: () => ""};

    const can_send = cs.getPlainText();

    if (state === 'prompt') {
        return <Frame url={community.member.profile_picture} onClick={() => {
            setState("compose");
        }}>
            <div className="text-base py-1 text-gray-500">
                Reply to the post
            </div>
        </Frame>
    } else if (state === 'reply-modal') {
        return <Frame reply_modal url={community.member.profile_picture}>
            <DraftComposer auto_focus show_toolbar={false} type="reply" update={(es, att) => {
                setEditorState(es);
                setAttachments(att);
            }}/>
            <div className="flex">
                <div className="flex-grow"></div>
                <div>
                    <Button loading={loading === 'sending'} onClick={() => handleSend()} size="sm" disabled={!can_send}
                            intent="secondary"
                            text={"Reply"}/>
                </div>
            </div>
        </Frame>
    } else if (state === 'compose') {
        return <Frame url={community.member.profile_picture}>
            <DraftComposer auto_focus show_toolbar={false} type="reply" update={(es, att) => {
                setEditorState(es);
                setAttachments(att);
            }}/>
            <div className="flex pb-1">
                <div className="flex-grow"></div>
                <div>
                    <Button loading={loading === 'sending'} onClick={() => handleSend()} size="sm" disabled={!can_send}
                            intent="secondary"
                            text={"Reply"}/>
                </div>
            </div>
        </Frame>
    }
}