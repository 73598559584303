import React, {useState,useEffect} from 'react';
import {FirebaseDB} from "../../../../config/setup-firestore";

export function ManagePolicyStats({community,policy,slug}) {
const [stats,setStats] = useState(null);
const [community_members,setCM] = useState(null);
    // need to get all members count
    // need to get stats item
    useEffect(function() {
        getStats(slug);
        getCommunityStats(slug);
    }, [slug]);

    function getStats(sl) {
        FirebaseDB
            .collection('communities')
            .doc(community.uid)
            .collection('stats')
            .doc('policies')
            .get()
            .then(doc=>setStats(doc.data()))
    }

    function getCommunityStats() {
        FirebaseDB
            .collection('communities')
            .doc(community.uid)
            .collection('stats')
            .doc('members')
            .get()
            .then(doc=>setCM(doc.data()))
    }

    if(!stats) {
        return null;
    }

    // stats - privacy-policy__2

    const uid = `${slug}__${policy.version}`;

    const adopted = stats[uid] ? stats[uid] : 0;

    const total_members = community_members ? community_members.total : 0;

    const adopted_rate = total_members>0?((adopted/total_members)*100).toFixed(0):null;

    if(!adopted_rate) {
        return null;
    }
    return <div>
            Adoption rate: {adopted_rate}%
    </div>
}