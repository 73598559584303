import React from 'react';
import styled from "styled-components";
import {useHistory} from 'react-router-dom';
import {Link} from "react-router-dom";
import {DEFAULT_USER_PHOTO} from "../../../../config/defaults";
import {ActionWithIcon, SectionHeader} from "../../core";
import {useIntl} from "react-intl";

/*
Today w/ images
- show up to 3 images
- then say and more

Tomorrow

This week

Hover to see member caard
 */

const ImageBox = styled.div`
  border-radius: 42%;
  height: 64px;
  width: 64px;
  border: 3px solid #FFF;
  background-position: center;
  background-size: cover;
  background-color: #dcdcdc;
  background-image: url('${props => props.image}_medium?alt=media');
`;

function Image({url}) {
    return <ImageBox image={url}/>
}

const NumberItem = styled.div`
  border-radius: 42%;
  height: 44px;
  width: 44px;
  border: 3px solid #FFF;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #9ea3ae;
  font-size: 14px;
  font-weight: 600;
  margin-top: 10px;
  color: #fff;
`;

function Number({num}) {
    return <NumberItem>
        +{num}
    </NumberItem>
}

const ImagesBox = styled.div`
  margin-top: 12px;
  margin-bottom: 6px;
  display: flex;
  justify-content: center;
`;

function getImages(members, len) {
    // less than length
    if (len < 6) {
        return <ImagesBox>
            {members.map((mem, k) => <Image url={mem.profile_picture === '' ? DEFAULT_USER_PHOTO : mem.profile_picture}
                                            key={k}/>)}
        </ImagesBox>
    } else {
        return <ImagesBox>
            {[0, 1, 2, 3].map((n, k) => <Image
                url={members[n].profile_picture === '' ? DEFAULT_USER_PHOTO : members[n].profile_picture} key={k}/>)}
            <Number num={len - 4}/>
        </ImagesBox>
    }
}

const TodayText = styled.div`
  margin: 0 32px;
  text-align: center;
  line-height: 1.4;
  font-size: 15px;
  font-weight: 500;
`;

function TodayMember({handle, community_handle, name, age, ultimate, penultimate}) {
    return <>
        <Link to={`/${community_handle}/member/${handle}`}
              className='color-link'>{name.trim()}</Link>{ultimate ? `'s` : ''} ({age}){penultimate ? ' and ' : ''}{!penultimate && !ultimate ? ', ' : ''}
    </>
}

function getTodayText(members, len, community_handle) {
    if (len === 1) {
        return <TodayText className='text-gray-800'>
            It's&nbsp;<TodayMember {...members[0]} ultimate={true} community_handle={community_handle}/>&nbsp;birthday!
        </TodayText>
    } else if (len === 2) {
        return <TodayText className='text-gray-800'>
            It's&nbsp;
            <TodayMember {...members[0]} penultimate={true}
                         community_handle={community_handle}/> and <TodayMember {...members[1]} ultimate={true}
                                                                                community_handle={community_handle}/>
            &nbsp;birthday!
        </TodayText>
    } else {
        // do this on repeat, we should feature everyone..
        return <TodayText className='text-gray-800'>
            It's&nbsp;
            {members.map((mem, k) => {
                return <TodayMember {...mem} penultimate={len === (k + 2)} ultimate={len === (k + 1)}
                                    community_handle={community_handle} key={k}/>;
            })}
            &nbsp;birthday!
        </TodayText>
    }
}

const DateTitle = styled.div`
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 2px;
  margin-top: 6px;
`;

const DateText = styled.div`
  font-size: 14px;
  line-height: 1.3;
  padding-bottom: 4px;
`;

const TodayFrame = styled.div`
  padding-bottom: 12px;
`;

function ListMember({name, handle, community_handle, age, ultimate, penultimate}) {
    if (!name) {
        return null;
    }
    return <>
        <Link to={`/${community_handle}/member/${handle}`} style={{fontWeight: '500'}}
              className='color-link-reverse-2'>{name}</Link> ({age}){ultimate ? '' : penultimate ? ' and ' : ', '}
    </>
}


export function NewBirthdayWidget({data = {today: [], week: []}, handle = 'isaria'}) {
    const {today, week} = data;
    const today_len = today.length;
    const {formatMessage: f} = useIntl();
    const history = useHistory();
    const week_len = week.length;
    if (today_len === 0 && week_len === 0) {
        return null;
    }
    return <div>
        <SectionHeader title={f({id: 'widgets.birthdays.title'})} action={<ActionWithIcon size="xs" onClick={() => {
            history.push(`/${handle}/calendar?view=upcoming_birthdays`);
        }}
                                                                                          text={f({id: 'widgets.birthdays.calendar'})}
                                                                                          inverse/>}/>
        <div className="px-2.5">
            {today_len > 0 && <TodayFrame>
                <div>{getImages(today, today_len)}</div>
                <div>{getTodayText(today, today_len, handle)}</div>
            </TodayFrame>}
            {week_len > 0 && <div>
                {week.map((day, i) => {
                    const day_len = day.members.length;
                    return <div key={i}>
                        <DateTitle className='text-gray-700'>
                            {day.formatted_date}
                        </DateTitle>
                        <DateText className='text-gray-800'>
                            {day.members.map((mem, k) => <ListMember community_handle={handle} key={k} {...mem}
                                                                     penultimate={day_len === (k + 2)}
                                                                     ultimate={day_len === (k + 1)}/>)}
                        </DateText>
                    </div>
                })}
            </div>}
        </div>
    </div>;
}