import React, {useState, useContext, useEffect} from 'react';
import {FirebaseDB} from "../../../../config/setup-firestore";
import {CommunityContext} from "../../../../app/community/community-context";
import styled from "styled-components";
import {getFolderIcon} from "../../../../app/community/files/mini-folder";
import {Tree} from "./tree";
import {getDriveContentAccess} from "../../../../app/community/files/folder";

const Wrapper = styled.div`
    padding: 4px 4px;
    border-radius: 4px;
    border: 1px solid #e0e0e0;
    height: 200px;
    overflow-x: auto;
`;

function getFolderAccess(folder,community) {
    const group_folder_id = folder.top_level_folder||folder.id;
    const group = Object.entries(community.my_groups).filter(([id,obj])=>{
       return obj.folder_id===group_folder_id;
    })[0];

    if(!group) {
        return "hidden";
    }
    const group_data = group[1];
    let is_moderator = group_data.moderators.user_uids[community.member_id];
    let is_member = group_data.user_uids[community.member_id];
    const access = getDriveContentAccess('folder',folder,community, {is_moderator,is_member});

    return access;
}

// todo get effective permission on a per folder basis based on folders loading + comparing with groups of the top level folder
export default function FolderPicker({handleSelect,type,current_folder,default_open=''}) {
    const [count, setCount] = useState(0);
    const [nodes, setNodes] = useState([]);
    const community = useContext(CommunityContext);
    const [selected, setSelected] = useState(null);
    const [fcontents, setFContents] = useState({});

    useEffect(function () {
        if(count===1) {
     //       getFolderContents(current_folder)
        }
    }, [count,nodes])

    useEffect(function () {
        if(type==="group-drive-upload") {
            loadInitialFolder(current_folder)
                .then((init)=>{
                    setNodes([...init]);
                    setCount(1);
                    setSelected(current_folder);
                })

        } else {
            loadFolders('')
                .then((res) => {
                    setNodes([...res]);
                });
        }

    }, [community.uid]);

    function getFolderContents(id) {

        if(!fcontents[id]) {
            loadFolders(id)
                .then((res) => {
                    forEachNode(nodes, n => {
                        if (n.id === id) {
                            n.childNodes = res;
                        }
                    });
                    setCount(2);
                    setFContents({...fcontents, [id]: res});
                    setNodes([...nodes]);
                });
        } else {
            forEachNode(nodes, n => {
                if (n.id === id) {
                    n.childNodes = fcontents[id];
                }
            });
            setNodes([...nodes]);
        }
    }

    async function loadInitialFolder(id) {
        return await FirebaseDB
            .collection('community_entities')
            .doc(community.uid)
            .collection('folders')
            .doc(id)
            .get()
            .then(doc=>{
                const data = doc.data();
                if(!data) {
                    return [];
                }
                return [{
                    isExpanded: true,
                    isSelected: true,
                    disabled: false,
                    label: data.name,
                    updated_at: data.updated_at,
                    path: data.system_path,
                    icon: <div className="">{getFolderIcon(data.type,data.security)}</div>,
                    hasCaret: true,
                    id: doc.id
                }]
            })
    }

    async function loadFolders(id) {
        let promises = [];

        promises.push(FirebaseDB
            .collection('community_entities')
            .doc(community.uid)
            .collection('folders')
            .where('folder','==',id)
            .get()
            .then(snap=>{
                return snap.docs.map(doc=>{
                    let data = doc.data();
                    data.id = doc.id;
                    const access = getFolderAccess(data,community);
                    return {
                        access: access,
                        disabled: doc.id===current_folder||access!=='editor',
                        label: data.name,
                        updated_at: data.updated_at,
                        path: data.system_path,
                        icon: <div className="">{getFolderIcon(data.type,data.security)}</div>,
                        hasCaret: true,
                        id: doc.id
                    }
                }).filter(a=>a.access!=='hidden').sort((a, b) => (a.updated_at < b.updated_at) ? 1 : -1)
            }));

        return await Promise.all(promises).then((results)=>[].concat.apply([], results));
    }

    function handleNodeClick(nodeData, _nodePath, e) {
        const originallySelected = nodeData.isSelected;
        if (!e.shiftKey) {
            forEachNode(nodes, n => (n.isSelected = false));
        }
        nodeData.isSelected = originallySelected == null ? true : !originallySelected;
        setSelected(nodeData.isSelected ? nodeData.id : null);
        handleSelect(nodeData.isSelected ? nodeData.id : '');
        setNodes([...nodes]);
    }

    function handleNodeCollapse(nodeData, _nodePath) {
        forEachNode(nodes, n => {
            if (n.id === nodeData.id) {
                n.isExpanded = false;
            }
        });
        setNodes([...nodes]);
    }

    function handleNodeExpand(nodeData, _nodePath) {
        forEachNode(nodes, n => {
            if (n.id === nodeData.id) {
                getFolderContents(nodeData.id);
                n.isExpanded = true;
            }
        });
        setNodes([...nodes]);
    }

    function forEachNode(nodes, callback) {
        if (nodes == null) {
            return;
        }

        for (const node of nodes) {
            callback(node);
            forEachNode(node.childNodes, callback);
        }
    }

    /*
           <Tree
            contents={nodes}
            onNodeClick={handleNodeClick.bind(this)}
            onNodeCollapse={handleNodeCollapse.bind(this)}
            onNodeExpand={handleNodeExpand.bind(this)}
        />
     */
    return <Wrapper className=''>
        <Tree contents={nodes}
              onNodeClick={handleNodeClick.bind(this)}
              onNodeCollapse={handleNodeCollapse.bind(this)}
              onNodeExpand={handleNodeExpand.bind(this)} />
    </Wrapper>
}