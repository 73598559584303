import React, {useState, useEffect, useContext} from 'react';
import * as API from './api';
import {NewProductUpdate} from './components';
import {GlobalContext} from "../../app/global/global-context";

export function GPProductUpdates() {
    const global = useContext(GlobalContext);
    const [config,setConfig] = useState(null);
    useEffect(function () {
       API.gp_pu_getConfig()
           .then(obj=>setConfig(obj));
    }, []);

    if(!config) {
        return <div>
            Loading..
        </div>
    }

    return <div className="p-4 rounded-md">

        <div className="pt-4 pb-2 text-lg font-bold text-gray-800">
            New Product Update
        </div>
        <NewProductUpdate onCreate={()=>{
            global.addToast({intent:'success',text:'Update created and email sent!'});
        }} />
    </div>
}