import React, {useState} from "react";
import {Switch, Route, Redirect, Link, NavLink} from 'react-router-dom';
import UserPreferencesAccount from "./account";
import UserPreferencesSecurity from "./security";
import UserPreferencesNotifications from "./notifications";
import styled from "styled-components";
import UserPrefsOverview from "./overview";
import {setDocumentTitle} from "../../../library/utilities/set-document-title";


export default function Preferences() {

    setDocumentTitle('Preferences');

    return (
        <Switch>
            <Route path='/preferences' exact component={UserPrefsOverview}/>
            <Route path='/preferences/account' component={UserPreferencesAccount}/>
            <Route path='/preferences/security' component={UserPreferencesSecurity}/>
            <Route path='/preferences/notifications' component={UserPreferencesNotifications}/>

            <Redirect from='/*' to='/preferences'/>
        </Switch>
    );
}