export const PsCommunityLogos = [
    {
        title: 'Middle-earth',
        desc: '',
        url: '',
        images: [
            {
                "url": "https://firebasestorage.googleapis.com/v0/b/unaty-staging.appspot.com/o/profile_pictures%2FATD1WQ0MON",
                "color": "rgb(36,15,104)",
                title: "Gondor"
            },
            {
                "url": "https://firebasestorage.googleapis.com/v0/b/unaty-staging.appspot.com/o/profile_pictures%2F1YWGWRN3Q2",
                "color": "rgb(5,90,20)",
                title: "Rohan"
            },

            {
                title: "Rivendell",
                "url": "https://firebasestorage.googleapis.com/v0/b/unaty-staging.appspot.com/o/profile_pictures%2F1D743MQK9O",
                "color": "rgb(39,175,210)"
            },
            {
                "url": "https://firebasestorage.googleapis.com/v0/b/unaty-staging.appspot.com/o/profile_pictures%2F33B47G3PAX",
                "color": "rgb(0,104,49)",
                title: "Erebor",
            },
            /*
 {
                "url": "https://firebasestorage.googleapis.com/v0/b/unaty-staging.appspot.com/o/profile_pictures%2FATD1WQ0MON",
                "color": "rgb(36,15,104)",
                title: "Arnor"
            },

           {
               title: "Lindon",
               "url": "https://firebasestorage.googleapis.com/v0/b/unaty-staging.appspot.com/o/profile_pictures%2F4_b5Gxny9",
               "color": "rgb(200,200,200)"
           },
           {
               title: "Imladris",
               "url": "https://firebasestorage.googleapis.com/v0/b/unaty-staging.appspot.com/o/profile_pictures%2F4_b5Gxny9",
               "color": "rgb(200,200,200)"
           },
           {
               title: "Lothlorien",
               "url": "https://firebasestorage.googleapis.com/v0/b/unaty-staging.appspot.com/o/profile_pictures%2F4_b5Gxny9",
               "color": "rgb(200,200,200)"
           },
           {
               title: "Shire",
               "url": "https://firebasestorage.googleapis.com/v0/b/unaty-staging.appspot.com/o/profile_pictures%2F4_b5Gxny9",
               "color": "rgb(200,200,200)"
           }

            */
        ]
    }
];