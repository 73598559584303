import {at_buildView} from "../../../library/components/advanced-table/factory/view";

export function getMemberViews(can_manage_members,smart_views=[],default_view) {

    const default_filters = [

    ];

    const default_sort = [
        {
            dir: 'asc',
            type: 'text',
            field: 'about.last_name'
        }
    ];

    const default_member_props = [
        {
            id: 'account_email',
            active: true,
            col_width: 200
        },
        {
            id: 'member_type',
            active: true,
            col_width: 120
        },
        {
            id: 'birthday',
            active: true,
            col_width: 120
        },
        {
            id: 'contact.phone',
            active: true,
            col_width: 150
        },
        {
            id: 'address.city',
            active: true,
            col_width: 120
        },
        {
            id: 'address.country',
            active: true,
            col_width: 120
        },
    ];

    const default_group_by = null;

    const default_footer_calc = {};

    let obj = {
        starting: '',
        // for rendering order
        views: [
            'Default'
        ],
        data: {
            ['Default']: at_buildView('Default', 'All Members', 'gallery', default_filters, default_sort, default_member_props, 'member')
        }
    };

    if(default_view&&default_view.id!=='Default') {
        obj.starting = default_view.id;
        obj.views.push(default_view.id);
        obj.data[default_view.id] = default_view;
    } else {
        obj.starting = 'Default';
    }
    for(let i=0;i<smart_views.length;i++) {
        const sm = smart_views[i];
        if(obj.data[sm.id]) {
            continue;
        } else if(sm.id === 'Default') {
            continue;
        }
        if(sm.layout === "table") {
            continue;
        }
        obj.views.push(sm.id);
        obj.data[sm.id] = at_buildView(sm.id, sm.name, sm.layout, sm.config.filters, sm.config.sort, sm.config.properties, 'member', sm.config.group_by, sm.config.footer_calc, sm.emoji, 'custom');
    }

    return obj;
}