import React, {useContext, useState} from 'react';
import Modal from "../../../../../../library/components/old/modal";
import {Button, Field, Input, SectionHeader, TextArea} from "../../../../../../library/components/core";
import {WebhookManageEvents} from "./manage-events";
import {isValidHttpUrl} from "../../../../../../library/components/pinboard/add-pin";
import {createWebhook, updateWebhook} from "../api";
import {CommunityContext} from "../../../../community-context";
import {GlobalContext} from "../../../../../global/global-context";

const mock = {
    url: "",
    name: "",
    events: "",
    description: "",
};

export function DevsWebhookModal({init_data = {...mock}, id="", type="create", onClose, onConfirm}) {
    const community = useContext(CommunityContext);
    const global = useContext(GlobalContext);

    const [loading, setLoading] = useState('');
    const [url, setUrl] = useState(init_data.url);
    const [events, setEvents] = useState(init_data.events);
    const [name, setName] = useState(init_data.name);
    const [description, setDescription] = useState(init_data.description);

    function handleCreate() {
        setLoading('adding-webhook');
        if(type==='create') {
            createWebhook({url,events,description,name:"-"},community)
                .then(()=>{

                    global.addToast({text:"Webhook added",intent:"success"});
                    onConfirm();
                    setLoading('');
                })
        } else {
            updateWebhook(id,{url,events,description,name:"-"},community)
                .then(()=>{
                    global.addToast({text:"Webhook updated",intent:"success"});
                    onConfirm();
                    setLoading('');
                })
        }

    }

    const body = <div className="space-y-4">
        <Field label="Endpoint URL">
            <Input placeholder={'https://'} value={url} onChange={v => setUrl(v)}/>
        </Field>

        <Field label="Description">
            <TextArea value={description} placeholder="An optional description of what this endpoint is for.."
                      onChange={v => setDescription(v)}/>
        </Field>

        <Field label="Events">
            <WebhookManageEvents edit onChange={v => setEvents(v)} value={events}/>
        </Field>
    </div>;

    const is_valid = isValidHttpUrl(url) && events.length>0;

    return <Modal title={type==='create'?"Create Webhook":"Edit Webhook"} size='small' body={body} footerRight={<div className="space-x-2">
        <Button onClick={() => onClose()} text="Cancel"/>
        <Button onClick={() => handleCreate()} loading={loading === 'adding-webhook'} disabled={!is_valid}
                text={type==='create'?"Create":"Update"} intent="success"/>
    </div>} footerLeft={null}
                  onClose={() => onClose()} open={true} lazy/>
}