import React from 'react';
import {BookOpenIcon, CalendarIcon, FolderIcon, HomeIcon, StarIcon} from "@heroicons/react/solid";
import {Avatar} from "../../../core";

const steps = [
    {
        title: 'Review ownership policies'
    },
    {
        title: 'Complete your member profile'
    },
    {
        title: 'Create your member types'
    },
    /*
    {
        title: 'Add teams and roles'
    },
     */
    {
        title: 'Set defaults for all members'
    },
    {
        title: 'Add your other admins'
    },
    {
        title: 'Design the member profile'
    }
];

function Step({index,title=""}) {
    return  <div className="flex space-x-3">
        <div className='flex-none w-5'>
            <div style={{fontSize: '0.7rem'}} className="flex items-center justify-center font-semibold text-secondary w-5 h-5 rounded-full border-2 border-secondary">
                {index}
            </div>
        </div>
        <div className="text-sm leading-5 text-gray-700">
            {title}
        </div>
    </div>
}

function MenuItem({icon=<HomeIcon />,active,color,label="Home"}) {
    return <div className="flex items-center space-x-2">
        <div>
            <div style={{color:active?color:""}} className={`w-4 h-4 ${active?"":"text-gray-500"}`}>
                {icon}
            </div>
        </div>
        <div style={{color:active?color:""}} className={`text-xs font-semibold ${active?"":"text-gray-500"}`}>
            {label}
        </div>
    </div>
}

export function CommunityCreateSplashImage(props) {
    return <div className="h-full w-full flex flex-col justify-center">
        <div className="bg-gray-100 border-t-2 border-l-2 border-b-2 border-gray-400 rounded-l-md shadow-lg h-4/5 ml-12">
            <div className="flex space-x-2 pl-3 overflow-x-hidden py-2.5">
                {props.logo&&<Avatar size="20px" url={`${props.logo}_small?alt=media`} />}
                {props.emoji&&!props.logo&&<div className="h-5 flex items-center">
                    <div className="text-base w-4 h-4 flex items-center">
                        {props.emoji}
                    </div>
                </div>}
                <div className="h-5 flex items-center">
                <div className="text-gray-700 text-sm font-bold">{props.name}</div>
                </div>
            </div>
            <div className="space-y-2 pl-3 pt-2">
               <MenuItem label="Home" icon={<HomeIcon />} active color={props.color} />
               <MenuItem label="Members" icon={<BookOpenIcon />} color={props.color} />
               <MenuItem label="Calendar" icon={<CalendarIcon />} color={props.color} />
               <MenuItem label="Roles" icon={<StarIcon />} color={props.color} />
               <MenuItem label="Files" icon={<FolderIcon />} color={props.color} />
            </div>
        </div>
    </div>
}

export function ObScCommunityCreateSplash(props) {

    return <div>
        <div className="text-gray-800 font-semibold text-sm pb-4">
            Next steps
        </div>
        <div className="space-y-6 ">
            {steps.map((step,k)=><Step key={step.title} index={(k+1)} {...step} />)}
        </div>
    </div>
}