import React, {useEffect, useState} from 'react';
import {RichSearch, List, Badge, Field} from '../../../../library/components/core';
import ModeratorIcon from "../../../../library/components/old/moderator-icon";
import {Button} from "../../../../library/components/core/button";
import {TrashIcon} from "@heroicons/react/solid";
import {NewEntitySelect} from "../../../../library/components/reorganize/entity-select";

export function MenuPanelGroupModerators({
                                             removeModerator,
                                             disabled,
                                             searchable,
                                             excluded,
                                             allowed = {members: {}, roles: {}},
                                             context,
                                             can_manage,
                                             moderators,
    group_data,
                                             addModerator
                                         }) {
    const [member_mods, setMemberMods] = useState(Object.keys(moderators.members));
    const [role_mods, setRoleMods] = useState(Object.keys(moderators.roles));

    useEffect(function () {
        setMemberMods(Object.keys(moderators.members));
        setRoleMods(Object.keys(moderators.roles));
    }, [moderators.roles, moderators.members]);

    const current_id = context.member.id;

    const nes_props = {};
    return <div>
        <div>
            <Field label="Add Group Members & Roles" help_text={"Only members and roles in the group can be selected"}>
                <NewEntitySelect auto_focus={false} show_table={false} {...nes_props} placeholder={"Type to search.."} searchable={{members: true, roles: true}}
                                 search_ctx={['group-mods']} meta={{allowed:{
                                     members: group_data.user_uids,
                        roles: group_data.entities.roles
                    }}} context={context} onChange={(f, v, vo) => {
                    if(vo&&vo.type) {
                        addModerator(vo.type, vo.id, (group) => {
                            setMemberMods(Object.keys(group.moderators.members));
                            setRoleMods(Object.keys(group.moderators.roles));
                        });
                    }
                }} value={{
                    entities: {
                        roles: moderators.roles,
                        members: moderators.members
                    }
                }}/>

            </Field>
        </div>
        <div className="pt-1"/>
        <div>
            <List use_entity_info context={context} items={[...member_mods.map(id => {
                return {
                    value: `members-${id}`,
                    onRemove: () => {
                        removeModerator('members', id, (group) => {
                            setMemberMods(Object.keys(group.moderators.members));
                        })
                    },
                    can_remove: can_manage && id !== current_id,
                    item_id: id,
                    type: 'members',
                    title_suffix: <ModeratorIcon height={16} fill={"#F15F31"}/>
                }
            }), ...role_mods.map(id => {
                return {
                    value: `roles-${id}`, can_remove: can_manage, onRemove: () => {
                        removeModerator('roles', id, (group) => {
                            setRoleMods(Object.keys(group.moderators.roles));
                        })
                    }, item_id: id, type: 'roles', title_suffix: <ModeratorIcon height={16} fill={"#F15F31"}/>
                }
            })]}/>
        </div>
    </div>
}