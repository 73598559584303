import React, {useContext, useEffect, useState} from 'react';
import {authFetch} from "../../../../config/network";
import {RulesSuggestions} from "../magic/rules-suggestions";
import {membersBuildInviteTo} from "../components/directory-wrapper";
import {CommunityContext} from "../../community-context";
import {getAllMemberData} from "../../../../library/components/advanced-table";

function getAllNames(unknown, groupmembers) {
    let arr = unknown.map(id => {
        const it = groupmembers.filter(a => a.id === id)[0];
        return it;
    }).filter(a => !!a).map(b=>b.name);

    return arr.join('\n');
}

export function GroupDataQualitySuggestions({group_id, group, community_uid}) {
    const [suggestions, setSuggestions] = useState(null);
    const community = useContext(CommunityContext);
    useEffect(function () {
        getSuggestions();
    }, [])

    function getSuggestions() {
        const payload = {
            group_id,
            community_uid
        };

        const res = (resp) => {
            if (resp && resp.data && resp.data.suggestions) {
                setSuggestions([...resp.data.suggestions])
            }
        };

        authFetch("/groups/get-data-quality-suggestions", res, res, "POST", {payload});
    }

    if (!suggestions || !suggestions.length) {
        return null;
    }

    const _actions = {
        sendDataCheck: (items) => {
            getAllMemberData(community_uid, items.map(it => it.id))
                .then(members => {
                    const prefill = {
                        to: membersBuildInviteTo(members),
                        config: {},
                        content: `Hi {{member.first_name}}, can you please make sure your information is up-to-date?`,
                        subject: `Check your Member Profile`,
                        type: 'data-check',
                        template: 'system-data-check'
                    };

                    community.openComposer(prefill)
                })
        },
        crowdsourceEmail: (items) => {
            // this case is different
            const all_user_uids = Object.keys(group.user_uids);
            getAllMemberData(community_uid, all_user_uids)
                .then(groupmembers => {
                    const unknown = items.map(it => it.id);
                    const all_names = getAllNames(unknown, groupmembers);
                    const prefill = {
                        to: membersBuildInviteTo(groupmembers.filter(a=>!unknown.includes(a.id))),
                        config: {},
                        content: `Hi everyone, we're unable to reach a few of our group members.\n\nDoes anyone have an email or phone number for these members:\n\n${all_names}`,
                        subject: `Reach Group Members`,
                        type: 'email',
                        template: ''
                    };

                    community.openComposer(prefill)
                })
        },
        resendInvite: (items) => {
            getAllMemberData(community_uid, items.map(it => it.id))
                .then(members => {
                    const prefill = {
                        to: membersBuildInviteTo(members),
                        config: {},
                        content: `${community.member.name} (${community.member.account_email}) invited you to join ${community.data.name} on Unaty.`,
                        subject: `${community.member.name} invited you to join ${community.data.name}`,
                        type: 'invite',
                        template: 'system-invite'
                    };

                    community.openComposer(prefill)
                })
        },
        sendInvite: (items) => {
            getAllMemberData(community_uid, items.map(it => it.id))
                .then(members => {
                    const prefill = {
                        to: membersBuildInviteTo(members),
                        config: {},
                        content: `${community.member.name} (${community.member.account_email}) invited you to join ${community.data.name} on Unaty.`,
                        subject: `${community.member.name} invited you to join ${community.data.name}`,
                        type: 'invite',
                        template: 'system-invite'
                    };

                    community.openComposer(prefill)
                })
        },
    };

    const data = {...group};

    return <RulesSuggestions direct={suggestions} _actions={_actions} data={data} context="group"
                             scope={"data-quality"}/>
}