import React from 'react';
import {NFIInner, NFIOuter, NFIReadMore} from "./shared";

export function NFIBody({children,can_show_more,setShowMore,show_more}) {

    return <NFIOuter>
        <NFIInner>
            {children}
            {can_show_more&&!show_more&&<NFIReadMore setShowMore={setShowMore} />}
        </NFIInner>
    </NFIOuter>
}