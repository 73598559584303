import React, {useState,useContext} from "react";
// split out into steps

import {CommunityContext} from "../community-context";
import {folder_modal_about} from "./create-folder";
import {authFetch} from "../../../config/network";
import {AdvancedModal} from "../../../library/components/reorganize/advanced-modal";

function buildData(community,type) {
    let obj = Object.assign({},JSON.parse(JSON.stringify(folder_modal_about)));
    obj.context.community_uid = community.uid;
    obj.state.type = type;

    return obj;
}

export function CreateFolderController({create_filter, folder, folder_names, base, open, type, history, handle, closeModal, context={}}) {
    const community = useContext(CommunityContext);
    const [data,setData] = useState({});
    const [_loading,setLoading] = useState(false);
    const [phase,setPhase] = useState('about');

    function createFolder(p,filter_id='') {
        setLoading(true);

        const payload = {
            ...p,
            security: p['security'] ? 'private' : 'public',
            community_uid: community.uid,
            permissions_type: 'viewer',
            entities: {
              members: {},
              teams: {},
              groups: {},
              roles: {},
              member_types: {},
              filters: {},
            },
            folder: folder,

            member_id: community.member_id
        };

        authFetch("/folders/create", handleResponse, handleError, "POST", {payload});
    }

    function handleResponse(resp) {
        actionHandler('created',{id:resp.data.id});
        closeModal();
        setLoading(false);
    }

    function handleError(error) {
        setLoading(false);
        closeModal();
    }

    function actionHandler(action,payload) {
        let d;
        switch (action) {
            case 'proceed':

                createFolder(payload);

                return;
            case 'add-entities':
                d = data;
                d[phase] = payload;
                setData({...d});
                setPhase('add-entities');
                return;
            case 'cancel':
                closeModal();
                return;
            case 'close-modal':
                closeModal();
                return;
            case 'created':
                setData({});
                setPhase('about');
                // only if is private ?action=add-members
                history.push(`${base}/folder/${payload.id}`);
                closeModal();
                return;
            default:
                return;
        }
    }

    const phase_data = buildData(community,type);

    return open ? <AdvancedModal _loading={_loading} context={context} actionHandler={(a,p)=>actionHandler(a,p)} phase_data={phase_data}  /> : null;
}