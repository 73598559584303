import React, {useContext, useEffect, useState} from 'react';
import {CommunityContext} from "../../../../community-context";
import {GlobalContext} from "../../../../../global/global-context";
import {FirebaseDB} from "../../../../../../config/setup-firestore";
import {Button, SectionHeader} from "../../../../../../library/components/core";
import styled from "styled-components";
import {createPublishableKey, createSecretKey, getFullSecretKey, rollKey} from "../api";
import {aa_formatDate} from "../../../../../../library/utilities/general/dates";

const HeaderCell = styled.div`
  padding: 6px 10px 6px 10px;
  line-height: 20px;
  text-transform: uppercase;
  font-size: 11px;
  color: #1A1F35;
  letter-spacing: 0.2px;
  font-weight: 500;
`;

const Cell = styled.div`
  padding: 8px 10px 8px 10px;
  line-height: 20px;
  display: flex;
  align-items: center;
`;

const Grid = styled.div`
  display: grid;
  grid-gap: 2rem;
  border-bottom: 1px solid #e3e8ee;
  grid-template-columns: 1fr 1.6fr 0.5fr 0.5fr 0.5fr;
`;

function TokenField({token,onShowFullKey, show_key}) {

    return <Cell className="font-mono relative text-xs text-gray-600">
        {token}
        {onShowFullKey&&show_key!=='shown'&&<div className="absolute right-0 top-0 bottom-0 flex items-center"><Button loading={show_key==='loading'} text="Show Key" onClick={onShowFullKey} size="sm" /></div>}
    </Cell>
}

function Key({name, token, onShowFullKey, show_key, created, last_used, actions = []}) {
    return <Grid>
        <Cell className="text-sm text-gray-600">{name}</Cell>
        <TokenField token={token} onShowFullKey={onShowFullKey} show_key={show_key} />
        <Cell className="text-sm text-gray-600">{last_used}</Cell>
        <Cell className="text-sm text-gray-600">{created}</Cell>
        <Cell className="flex">
            {actions.map((act, k) => {
                return <div key={k}>
                    {act}
                </div>
            })}
        </Cell>
    </Grid>
}

export function DevsApiKeys(props) {
    const global = useContext(GlobalContext);
    const community = useContext(CommunityContext);
    const [loading, setLoading] = useState("");
    const [show_key, setShowKey] = useState("");
    const [keys, setKeys] = useState([]);

    function getSecretKey() {
        const sk_keys = keys.filter(k => k.type === 'secret');
setShowKey('loading');
        const sk_item = sk_keys[0] ? sk_keys[0] : null;
        getFullSecretKey(sk_item.last4,community)
            .then(key=>{
                let nk = [...keys];
                const index = nk.findIndex(a=>a.type==='secret');
                nk[index].token = key;
                setKeys(nk);
                setShowKey('shown')
            })
    }

    useEffect(function () {
        let listener = () => {
        };
        if (community.uid) {
            listener = FirebaseDB
                .collection('communities')
                .doc(community.uid)
                .collection('api_keys')
                .where('status', '==', `active`)
                .limit(3)
                .onSnapshot((snap) => {
                    if (snap.empty) {
                        setKeys([]);
                    } else {
                        setKeys(snap.docs.map(d => {
                            let o = {...d.data()};
                            o.id = d.id;
                            return o;
                        }));
                    }
                })
        } else {
            setKeys([]);
        }

        return () => listener();
    }, []);

    function handleCreatePk() {
        setLoading('pk');
        createPublishableKey(community)
            .then(() => {
setLoading('');
            })
    }

    function handleCreateSk() {
        setLoading('sk');
        createSecretKey(community)
            .then(() => {
                setLoading('');
            })
    }

    function handleRollPk(id) {
        setLoading('pk');
        rollKey(id,community)
            .then(() => {
                setLoading('');
            })
    }

    function handleRollSk(id) {
        setLoading('sk');
        rollKey(id,community)
            .then(() => {
                setLoading('');
            })
    }

    const pk_keys = keys.filter(k => k.type === 'publishable');
    const sk_keys = keys.filter(k => k.type === 'secret');

    const pk_item = pk_keys[0] ? pk_keys[0] : null;
    const sk_item = sk_keys[0] ? sk_keys[0] : null;

    const pk = {
        name: "Publishable Key",
        token: pk_item ? pk_item.token : "—",
        last_used: pk_item && pk_item.last_used ? aa_formatDate(pk_item.last_used).relative : "—",
        created: pk_item ? aa_formatDate(pk_item.created_at).full_date : "—",
        actions: [
            (!pk_item && <div>
                <Button onClick={handleCreatePk} size="sm" text="Create Key" loading={loading === 'pk'}/>
            </div>),
            (pk_item && <div>
                <Button onClick={()=>handleRollPk(pk_item.id)} size="sm" text="Roll Key" loading={loading === 'pk'}/>
            </div>)
        ]
    };

    const sk = {
        name: "Secret Key",
        token: sk_item ? sk_item.token : "—",
        last_used: sk_item && sk_item.last_used ? aa_formatDate(sk_item.last_used).relative : "—",
        created: sk_item ? aa_formatDate(sk_item.created_at).full_date : "—",
        onShowFullKey: sk_item&&show_key!=='shown'?()=>getSecretKey():null,
        show_key,
        actions: [
            (!sk_item && <div>
                <Button onClick={handleCreateSk} size="sm" text="Create Key" loading={loading === 'sk'}/>
            </div>),
            (sk_item && <div>
                <Button onClick={()=>handleRollSk(sk_item.id)} size="sm" text="Roll Key" loading={loading === 'sk'}/>
            </div>)
        ]
    };

    return <div className="mt-4">
        <SectionHeader padded={false} title={"Standard Keys"}/>
        <div className="-mt-0.5 text-sm text-gray-500">Authenticate any API requests.</div>
        <div className="mt-1.5">
            <div>
                <Grid>
                    <HeaderCell>Name</HeaderCell>
                    <HeaderCell>Token</HeaderCell>
                    <HeaderCell>Last used</HeaderCell>
                    <HeaderCell>Created</HeaderCell>
                    <HeaderCell></HeaderCell>
                </Grid>
            </div>
            <div>
                <Key {...pk} />
                <Key {...sk} />
            </div>
        </div>
    </div>
}