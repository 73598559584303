import {externalAuthFetch} from "../../config/network";

export function logError(logged,ctx={}) {
    const payload = {
        error: logged,
        info: JSON.stringify({
            href: window.location.href,
            innerHeight: window.innerHeight,
            innerWidth: window.innerWidth
        }),
        ...ctx
    };

    console.log('log error',payload)

    externalAuthFetch('/log-error', ()=>{}, () => {}, 'POST', payload);
}