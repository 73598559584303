import {cloneObject} from "./index";

function meetCondition(c,data,ctx={}) {
    if(!data[c.field]) {
        return false;
    }
    switch (c.check) {
        case 'length is less than':
            return !(data[c.field].length < parseInt(c.value));
        case 'equals':
            return !(data[c.field] === c.value);
        case 'values-length':
            const clone = cloneObject(data);
            const arr = Object.values(clone);
            let sum = 0;
            for(let i in arr) {
                sum += Object.keys(arr[i]).length;
            }
          return sum>0;
        case 'in':
            const arr1 = ctx[c.value];
            if(!arr1) {
                return true;
            }
            return arr1.indexOf(data[c.field].toLowerCase()) === -1;
        default:
            return true;
    }
}

export function disableField(props,data,ctx) {

    if(!props.disabled) {
        return false;
    }

    for(let i=0;i<props.disabled.length;i++) {
        const condition = props.disabled[i];
        if(!meetCondition(condition,data,ctx)) {
            return true;
        }
    }
    return false;
}

export function getModalWidth(size) {
    switch (size) {
        case 'small':
            return '320px';
        case 'mini':
            return '200px';
        case 'large':
            return '700px';
        case 'medium':
            return '540px';
        default:
            return '450px';
    }
}