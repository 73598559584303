import React from "react";

// ⌘ = command
// ⇧ = shift
// ⌥ = option / alt
// ⌃ = ctrl
export function HotkeyBadge({
    hotkey = "⌘K"
                            }) {

    return <div className="px-1 py-0.5 inline-flex text-xs font-medium text-gray-600 bg-gray-200 tracking-wide rounded-md">
        {hotkey}
    </div>
}