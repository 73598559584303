import React, {useEffect, useState} from "react";
import {ActionWithIcon} from "../../../../../library/components/core";
import {ArrowNarrowRightIcon} from "@heroicons/react/solid";
import {getPrettyTime} from "../../../../../library/utilities/general/dates";
import moment from "moment";
import {tp_pu_getProductUpdates} from "../../../../../library/utilities/product-updates";

function Item({video, read, desc, subject = 'Test Update', created_at, version, changelog_link}) {
if(!subject) {
    return null;
}
    return <div className={`py-3 px-4 space-y-1`}>
        <div className={`font-medium text-xs text-gray-500`}>{getPrettyTime(moment.unix((created_at/1000)))}</div>
        <div className="pr-8">
            <a href={changelog_link} target={"_blank"} rel="noreferrer" className="block leading-tight text-gray-800 hover:text-secondary -mt-0.5 text-lg font-bold">
                {subject}
            </a>
        </div>
        {desc && <div className={`text-xs pr-12 -mt-1 text-gray-600`}>
            {desc}
        </div>}
        <div className="pt-2 space-x-4 flex">
            <ActionWithIcon inverse onClick={() => window.open(changelog_link, '_blank')} text="Learn more on our blog"
                            icon_right icon={<ArrowNarrowRightIcon/>}/>
        </div>
    </div>
}

export function getSeenProductUpdates() {
    const ls = localStorage.getItem('product_updates_seen');

    return ls ? JSON.parse(ls) : [];
}

function markAllAsSeen(arr) {
    return localStorage.setItem('product_updates_seen', JSON.stringify(arr));
}

export function HTWhatsNew({goToPanel}) {
    const [updates, setUpdates] = useState([]);

    useEffect(function () {
      if(updates.length>0) {
          markAllAsSeen(updates.map(up => up.version));
      }
    }, [updates])

    useEffect(function () {
        tp_pu_getProductUpdates()
            .then(arr => setUpdates([...arr]))
    }, []);

    if (updates.length === 0) {
        return null;
    }

    const seen_updates = getSeenProductUpdates();

    return <div className="divide-y divide-gray-200 overflow-y-auto" style={{maxHeight:'50vh'}}>
        {updates.map((update, k) => {
            const read = seen_updates.indexOf(update.version) !== -1;
            return <Item key={k} {...update} read={read}/>
        })}
    </div>
}