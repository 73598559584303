import React, {useContext, useState} from 'react';
import {StarIcon} from "@heroicons/react/outline";
import styled from "styled-components";
import {NewTooltip} from "./reorganize/new-tooltip";
import {authFetch} from "../../config/network";
import {CommunityContext} from "../../app/community/community-context";
import {Button} from "./core";

const StarIconFilling = styled.div`
  svg {
    height: 1.1rem;
  }
 path {
   color: ${props=>props.starred?"#FEBE00":""};
   fill: ${props=>props.starred?"#FEBE00":""};
 }
`;

const StarIconFillingButton = styled.div`
  svg {
   
  }
 path {
   color: ${props=>props.starred?"#FEBE00":""};
   fill: ${props=>props.starred?"#FEBE00":""};
 }
`;

const SpanChild = styled.div`
 > span {
   height: 1rem;
   line-height: 0;
 }
`;

function isStarred(member,type,type_id) {

    if(!member.starred) {
        return false;
    } else {
        return !!member.starred[`${type}__${type_id}`]
    }
}

export function MemberStarred({type,layout="button",type_id}) {
    const community = useContext(CommunityContext);

    const [st,setSt] = useState(isStarred(community.member,type,type_id));

    function handleAddStar() {

        const payload = {
            community_uid: community.uid,
            id: community.member_id,
            user_uid: community.member.user_uid,
         type,
            type_id
        };

        setSt(true);
        authFetch('/members/add-star', ()=>{}, ()=>{}, "POST", {payload});
    }

    function handleRemoveStar() {
        setSt(false);
        const payload = {
            community_uid: community.uid,
            id: community.member_id,
            user_uid: community.member.user_uid,
            type,
            type_id
        };

        authFetch('/members/remove-star', ()=>{}, ()=>{}, "POST", {payload});
    }

    function handleToggleStar() {
        if(st) {
            handleRemoveStar();
        } else {
            handleAddStar();
        }
    }

    if(layout==='button') {
        return <Button onClick={handleToggleStar} icon={<StarIconFillingButton starred={st} className="h-svg-45 p-0.5"><StarIcon /></StarIconFillingButton>} />
    }

    const content = <StarIconFilling onClick={handleToggleStar} starred={st} className={`inline-flex cursor-pointer transition-opacity transition-colors ${st? "hover:opacity-70" : "hover:text-gray-700 text-gray-400"}`}>
        <StarIcon/>
    </StarIconFilling>;

    return <SpanChild className={`w-5 h-5 flex items-center justify-center`}>
        <NewTooltip message={!st?"Star":"Remove Star"}>
            {content}
        </NewTooltip>
    </SpanChild>
}