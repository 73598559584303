import React from "react";
import {Switch, Route, Redirect} from 'react-router-dom';

import {PoliciesHome} from "./home";
import {PoliciesCookies} from "./cookies";
import {PoliciesPrivacy} from "./privacy";
import {PoliciesUserTerms} from "./user-terms";

export function PolicyRouter() {

    return <Switch>
        <Route path="/policies" exact component={PoliciesHome}/>
        <Route path="/policies/privacy" exact component={PoliciesPrivacy}/>
        <Route path="/policies/cookies" exact component={PoliciesCookies}/>
        <Route path="/policies/user-terms" exact component={PoliciesUserTerms}/>
        <Redirect from='/*' to='/policies'/>
    </Switch>
}