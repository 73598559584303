import React, {useContext, useState} from 'react';
import {Button, Field, Input} from "../../../library/components/core";
import {TimezoneSwitcher} from "../../../library/components/timezone-switcher";
import {timezones_obj} from "../../../library/components/timezone-switcher/data";
import {getCurrentTimezone} from "../../../library/utilities/general";
import {CommunityContext} from "../community-context";
import {api_createEvent} from "../../../api/community/events/create";

export function CommunityCreateEvent() {
    const community = useContext(CommunityContext);
    const [name,setName] = useState("");
    const [description,setDescription] = useState("");
    const [start_date,setStartDate] = useState(new Date());
    const [end_date,setEndDate] = useState(new Date());
    const [start_time,setStartTime] = useState('12:15');
    const [end_time,setEndTime] = useState('12:50');
    const [group_id,setGroupId] = useState("zq1b0ftzqlI9SxLXe8eu");
    const [timezone,setTimezone] = useState(getCurrentTimezone());
    const [state,setState] = useState("");

    function createEvent() {
        setState('creating')
        const payload = {
            name,
            description,
            group_id,
            start_date,
            end_date,
            start_time,
            end_time,
            timezone
        };
        api_createEvent(community,payload)
            .then(id=>{
                setState('')
                console.log("ID",id)
            })
    }

    return <div className="space-y-2 p-4">
        <Field label="Name">
            <Input onChange={v=>setName(v)} value={name} />
        </Field>
        <Field label="Description">
            <Input onChange={v=>setDescription(v)} value={description} />
        </Field>

        <Field label="Start Date">
            <Input onChange={v=>setStartDate(v)} type="date" value={start_date}  />
        </Field>
        <Field label="Start Time">
            <Input onChange={v=>setStartTime(v)} value={start_time} />
        </Field>

        <Field label="End Date">
            <Input onChange={v=>setEndDate(v)} type="date" value={end_date}  />
        </Field>
        <Field label="End Time">
            <Input onChange={v=>setEndTime(v)} value={end_time} />
        </Field>

        <Field label="Timezone">
            <TimezoneSwitcher value={timezone} onChange={(tz)=>setTimezone(tz)} />
        </Field>
        <Field label="Group ID">
            <Input onChange={v=>setGroupId(v)} value={group_id} />
        </Field>
        <div>
            <Button loading={state==='creating'} intent="secondary" onClick={createEvent} text="Create" />
        </div>
    </div>
}