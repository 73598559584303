import React, {useContext} from "react";
import {Breadcrumbs} from "../../../../library/components/old/breadcrumb";
import {PageHeader} from "../../../../library/components/old/page-header";
import {PageContainer} from "../../../../library/components/old/page-container";
import PrivitizeProfile from "./privatize-profile";
import {GlobalContext} from "../../global-context";

export default function UserPreferencesSecurity(props) {
    const global = useContext(GlobalContext);

    const {user} = global;

    if (!user.account_email) {
        // loading
        return null;
    }

    const nav = {
        can_go_back: false,
        history: props.history,
        breadcrumbs: [
            {
                label: 'Preferences',
                path: '/preferences'
            },
            {
                label: 'Security',
                path: '#'
            }
        ]
    };

    return <PageContainer nav={nav}>
        <PageHeader title='Security' size='h2'/>
        <div>
            <PrivitizeProfile user={user}/>
        </div>
    </PageContainer>;

}