import React from 'react';
import {Avatar} from "../../../core";
import {PhotoSelector} from "../../../old/photo-selector";
import {fv_getFormattedVal} from "../../../../utilities/format-values";
import {cf_getCFValue} from "../../../../utilities/community/custom-fields";
import {WCField} from "../index";

export function HWCSideFields({data,id,side,custom_fields,editing,setEditing,handleSave,member_type,meta}) {

    return <div className="w-full h-full flex flex-col">
        <div className="flex flex-col space-y-2 pt-4 px-3 pb-7">
            {side.fields.length > 0 && <div className="grid grid-cols-1 gap-2">
                {side.fields.map((cf) => {
                    const v = cf_getCFValue(cf.id, cf.type, data.custom_field_data);
                    const fv = fv_getFormattedVal(cf.type, v, meta, cf);
                    return <div key={cf.id}>
                        <WCField cf_data={cf} handleSave={handleSave} type={cf.type} id={`custom_fields.${cf.id}`}
                                 meta={meta} placeholder={cf.placeholder ? cf.placeholder : ""}
                                 value_formatted={fv} editing={editing} setEditing={setEditing} value={v}
                                 label={cf.name}/>
                    </div>
                })}
            </div>}
        </div>
    </div>
}