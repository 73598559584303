import React from 'react';
import {Alert} from "../../core";
import {CheckIcon,ExclamationIcon,InformationCircleIcon} from '@heroicons/react/solid';

export default function SGAlertsPage() {

    return <div className="space-y-4">
        <div>
            <div>
With Description
            </div>
        <Alert intent="warning" icon={<ExclamationIcon/>} title="Attention needed" subtitle="Lorem ipsum dolar is set niete." />
        </div>
        <div>
            <div>
                Info
            </div>
            <Alert intent="info" icon={<InformationCircleIcon/>} title="Information" subtitle="Lorem ipsum dolar is set niete." />
        </div>
        <div>
            <div>
                Primary
            </div>
            <Alert intent="secondary" icon={<InformationCircleIcon/>} title="Information" subtitle="Lorem ipsum dolar is set niete." />
        </div>
        <div>
            <div>
                Secondary
            </div>
            <Alert intent="secondary" icon={<InformationCircleIcon/>} title="Information" subtitle="Lorem ipsum dolar is set niete." />
        </div>
        <div>
            <div>
                Danger
            </div>
            <Alert intent="danger" icon={<InformationCircleIcon/>} title="Information" subtitle="Lorem ipsum dolar is set niete." />
        </div>
        <div>
            <div>
                Accent Border
            </div>
            <Alert accent_border intent="info" icon={<InformationCircleIcon/>} title="Information" subtitle="Lorem ipsum dolar is set niete." />
        </div>
        <div>
            <div>With Actions</div>
            <Alert intent="success" icon={<CheckIcon/>} title="Attention needed" subtitle="Lorem ipsum dolar is set niete." actions={<>
                <a>View Status</a>
                <a>Dismiss</a>
            </>} />
        </div>
        <div>
            <div>
                Dismiss
            </div>
            <Alert intent="success" icon={<CheckIcon/>} onDismiss={()=>{}} title="Attention needed" subtitle="Lorem ipsum dolar is set niete." />
        </div>
    </div>
}