import React from 'react';
import classNames from 'classnames';
import styled from "styled-components";

const props = {};

/*
title
subtitle
action
divider
 */
const TitleIcon = styled.div`
    display: inline-flex;
    margin-left: 8px;
    vertical-align: baseline;
    > svg {
        height: 20px;
    }
`;

const Emoji = styled.div`
       display: inline-flex;
       margin-right: 10px;
    font-size: 28px;
    align-items: center;
`;

function getSubtitleSize(size) {

    if (size === '2xl' || size === 'xl') {
        return 'text-sm';
    }

    return 'text-base';
}

export function PageHeader({title = "Page Header",padded, onSubtitleClick=()=>{}, suffix, children, titleAction, emoji, size = "3xl", subtitle = "", action, divider = false}) {
    //   {emoji&&<Emoji>{emoji}</Emoji>}
    return <div
        className={classNames("relative z-10 flex items-center", padded?"px-4":"", !subtitle && !action ? 'pt-2 pb-1' : 'py-2', divider ? 'border-b pb-2 mb-2 border-gray-200 border-solid' : 'pb-3')}>
        <div className="flex-grow">

            <div onClick={() => {
                if (titleAction) {
                    titleAction()
                }
            }}
                 className={`${titleAction ? "hover:opacity-60 cursor-pointer" : ""} flex text-gray-900 text-${size} font-bold`}>
                {emoji&&emoji.indexOf("?alt=media")===-1&&<Emoji>{emoji}</Emoji>}
                {emoji&&emoji.indexOf("?alt=media")!==-1&&<Emoji><img alt="Emoji" src={emoji} className={"h-6 w-6 rounded"} /></Emoji>}
                <div>{title}</div>
                {suffix&&<div className="inline-flex items-center pl-1.5">
                    {suffix}
                </div>}
            </div>
            {subtitle && <div onClick={onSubtitleClick} className={`text-gray-500 font-medium ${getSubtitleSize(size)}`}>{subtitle}</div>}
        </div>
        <div className="space-x-2 hidden sm:flex items-baseline">
            {children}
            {action}
        </div>
    </div>;
}