import React, {useState} from 'react';
import {ATPopoverTitle} from "../components/shared";
import styled from "styled-components";
import Select from "../../old/select";
import {useIntl} from "react-intl";
import {Field} from "../../core";


const Fields = styled.div`
padding: 4px 12px 12px 12px;
    display: grid;
    grid-template-columns: 1.5fr 1fr;
    grid-gap: 12px;
`;


export function ATGroup({definitions, setGroup, options, group_by}) {
    const [open_select, setSelectOpen] = useState(false);
    const {formatMessage: f} = useIntl();
    function handleChange(f, v) {
        let ng = group_by ? group_by : {};
        if(f==='field') {
            // need to reset whole thing to item basically
            if(v==='') {
                ng = null;
            } else {
                ng = options.filter(obj => {
                    return obj.field === v;
                })[0];
            }
        } else {
            // changed sort only
            ng.dir = v;
        }
        setSelectOpen(false);
        setGroup(ng);
    }

    const values = group_by ? group_by : {field: '', dir: 'asc'};
    return <div className="px-3.5 pb-2 pt-0.5">
        <Field label={"Grouping"}>
            <div className="">

                <Select blank_option={true} placeholder='No Grouping' value={values.field} onChange={(f, val) => {
                    handleChange('field', val);
                }} options={options}/>

                {values.field !== '' &&
                <Select blank_option={false} placeholder='Sort Field' value={values.dir} onChange={(f, val) => {
                    handleChange('dir', val);
                }} options={[{value: 'asc', text: 'Ascending'}, {value: 'desc', text: 'Descending'}]}/>}

            </div>
        </Field>
    </div>
}