import React, {useEffect, useState} from 'react';
import {CheckCircleIcon, ClockIcon, ExclamationCircleIcon, QuestionMarkCircleIcon} from "@heroicons/react/solid";
import {Badge} from "../../core";
import {NewTooltip} from "../../reorganize/new-tooltip";
import moment from "moment";
import {authFetch} from "../../../../config/network";
import {FirebaseDB} from "../../../../config/setup-firestore";

const internal_status = {
    'ok': {
        label: 'Valid',
        message: 'Email was verified and found to be deliverable {ts}',
        color: 'emerald',
        icon: <CheckCircleIcon />
    },
    'error': {
        label: 'Invalid email',
        message: 'This email does not exist and should be updated',
        color: 'red',
        icon: <ExclamationCircleIcon />
    },
    'warning': {
        label: 'Careful',
        message: 'This email exists, but you might encounter delivery issues',
        color: 'amber',
        icon: <ExclamationCircleIcon />
    },
    'undetermined': {
        label: 'Undetermined',
        message: 'We were not able to confirm if this email exists',
        color: 'gray',
        icon: <ExclamationCircleIcon />
    },
    'unknown': {
        label: 'Unknown',
        color: 'gray',
        message: 'Click to verify this email exists - note that members will not be notified in any way',
        icon: <QuestionMarkCircleIcon />
    },
    'loading': {
        label: 'Loading',
        color: 'gray',
        message: 'Currently determining if this email is valid..',
        icon: <ClockIcon />
    }
};

export function EmailValidationStatusTag({
    email = 'seanmccall14@gmail.com',
    target_user_uid = 'm9m9OjeqVSRYnoNYYO8QhMNvE8p1'
                                         }) {

    const [status,setStatus] = useState('');
    const [loading,setLoading] = useState('init');
    const [data,setData] = useState(null);

    useEffect(function () {
        // run once at the beginning
        FirebaseDB
            .collection('email_validations')
            .doc(email)
            .get()
            .then(d=>{
                if(d.exists) {
                    const dt = d.data();
                    setData(dt);
                    setStatus(dt.status);
                    setLoading('');
                } else {
                    setStatus('unknown');
                    setLoading('');
                }
            })
    }, []);

    function checkIfEmailIsValid() {
        setLoading('loading');
        setStatus('loading');

        const res = (response) => {
            setLoading('');
            setStatus(response.data.status);
            setData(response.data);
        };

        const payload = {
            email,
            target_user_uid
        };

        authFetch("/users/validate-account-email", res, res, "POST", {payload});
    }

    if(!data&&loading==='init') {
        return null;
    }

    const final_status = loading ? 'loading' : status ? status : 'unknown';
    const final_ts = data ? data.ts : Date.now();

    const {
        label,
        message,
        color,
        icon
    } = internal_status[final_status];

    const time = moment.unix(final_ts / 1000).fromNow();

    return <NewTooltip message={message.replace('{ts}',`(${time==='now'?time:`${time} ago`})`)}>
        <Badge onClick={()=>{
            if(final_status==='unknown') {
                checkIfEmailIsValid();
            }
        }} icon={icon} color={color} text={label} />
    </NewTooltip>
}