import React from 'react';
import {ColorInput, Field, Input, SimpleListbox, TextAndEmoji, TextArea, Toggle} from "../../library/components/core";
import {countries_array} from "../../library/data/general/countries";
import {BirthdaySelector} from "../../library/components/old/selectors/birthday";
import {getInputByType} from "../../library/components/old/field";
import {cf_getSelectOptionsArray, cf_getSelectOptionsObject} from "../../library/utilities/community/custom-fields";
import {BasicSelect} from "../../library/components/core/basic-select";
import {co_buildMultiSelectArray} from "../../library/utilities/components";
import {NewEntitySearch} from "../../library/components/reorganize/entity-search/new-entity-search";
import {RichPhoneInput} from "../../library/components/inputs/rich-phone-input";
import {EditMemberSelect} from "../../library/components/edit-member-select";
import {genders, genders_object} from "../../library/data/general/genders";
import {languages, languages_object} from "../../library/data/general/languages";
import {timezones_obj} from "../../library/components/timezone-switcher/data";
import {MemberJoinDate} from "../../library/components/reorganize/member-join-date";
import {SymbolEditor} from "../../library/components/old/symbol-editor";

function handleAddressChange(v, current, field, onChange) {
    let nv = {...current};
    nv[field] = v;
    onChange(nv);
}

function valueFallback(v,f) {
    return v && v[f] ? v[f] : "";
}

function buildObject(arr) {
    let obj = {};
    arr.forEach(a=>{
        obj[a.id] = a.label;
    });
    return obj;
}

function ShortSelectOption({label,onClick,selected,value}) {
    const classes = selected ? "bg-blue-50 border-blue-300" : "border-gray-200 hover:border-gray-400 hover:bg-gray-50";
    return <div onClick={onClick} className={`${classes} cursor-pointer pl-2 pr-3 flex space-x-2 items-center rounded-xl border `}>
        <div className="-mt-1">
            <input checked={selected} className="transform scale-125" type="radio" id={label} name={"label"+label} />
        </div>
        <div className={`text-base leading-8 font-medium ${selected?"text-blue-600":"text-gray-700 "} `}>
            {label}
        </div>
    </div>
}

const isSelected = (selected, value) => {
    return !selected ? false : selected.value === value;
};

function ShortSelect({options,multi,value,onChange,onBlur}) {
    return <div className="flex flex-wrap gap-2">
        {options.map((opt, key) => {
            return <ShortSelectOption onClick={()=>{
                onChange(opt);
            }} key={key} selected={isSelected(value,opt.value)} {...opt}/>
        })}
    </div>
}

function newInputByType(value, type, onChange, props, meta, cf_data,placeholder="") {
    let text, options_obj, options, cval;

    switch (type) {
        case 'text':
            return <Input placeholder={placeholder} type="text" value={value} onChange={onChange} {...props} />;
        case 'location':
            return <Input custom={{maxLength:32}} placeholder={placeholder} type="text" value={value} onChange={onChange} {...props} />;
        case 'occupation':
            return <Input custom={{maxLength:32}} placeholder={placeholder} type="text" value={value} onChange={onChange} {...props} />;
        case 'string':
            return <Input placeholder={placeholder} type="text" value={value} onChange={onChange} {...props} />;
        case 'id':
            return <Input placeholder={placeholder} type="text" value={value} onChange={onChange} {...props} />;
        case 'multi-line-text':
            return <TextArea placeholder={placeholder} value={value} onChange={onChange} {...props} />;
        case 'textarea':
            return <TextArea placeholder={placeholder} value={value} onChange={onChange} {...props} />;

        case 'color':
            return <ColorInput show_label={false} value={value} onChange={onChange} {...props} />

        case 'date':
            return <Input placeholder={placeholder} type="date" value={value} onChange={onChange} {...props} />;
        case 'timestamp':
            return <Input placeholder={placeholder} type="date" value={value} onChange={onChange} {...props} />;

        case 'email':
            return <Input placeholder={placeholder} type="email" value={value} onChange={onChange} {...props} />;
        case 'group':
            return getInputByType('group', onChange, () => {
            }, value, {meta});
        case 'mentor':
            return getInputByType('mentor', onChange, () => {
            }, value, {meta});
        case 'member':
            return getInputByType('member', onChange, () => {
            }, value, {meta});
        case 'multi-member':
            return getInputByType('multi-member', onChange, () => {
            }, value, {meta});
        case 'mentees':
            // todo into component
            if(!meta.community_uid) {
                return <div>
                    missing community uid
                </div>
            }
            let excluded = meta.member_id ? {[meta.member_id]: true} : {};
            let temp_ids = value?{...value}:{};
            let all_ids = Object.keys(temp_ids);
            return <div>
                {all_ids.length>0&&<div className="mb-1.5"><EditMemberSelect community_uid={meta.community_uid} ids={all_ids} member_id={meta.member_id} onRemove={(id)=>{
                    let new_ids = {...temp_ids};
                    if(new_ids[id]) {
                        delete new_ids[id];
                    }
                    onChange(new_ids);
                }} /></div>}
                {all_ids.length<7&&<NewEntitySearch placeholder={placeholder?placeholder:"Search Members"} context={meta} is_multi={true}
                                    searchable={{members: true}} is_clearable={false}
                                    excluded={{members: excluded}} onChange={(val) => {
                                        console.log("VAL",val,value)
                let new_ids = val && val.value ? [val.value] : val && val.members ? Object.keys(val.members) : [];
                let new_obj = {...temp_ids};
                for(let i=0;i<new_ids.length;i++) {
                    new_obj[new_ids[i]] = true;
                }
                onChange(new_obj)
            }}
                                    value={null}/>}

            </div>;
        case 'select-email':
            if(!cf_data&&!props.options) {
                return null;
            }
            options_obj = props.options ? buildObject(props.options) : cf_getSelectOptionsObject(cf_data.options,false);
            options = props.options ? props.options : cf_getSelectOptionsArray(cf_data.options,false);
            return <BasicSelect usePortal searchable={options.length>4} placeholder={options.length>4?"Start typing..":"Select"}
                                value={value ? {value: value, label: options_obj[value]} : null}
                                onChange={(v) => {
                                    onChange(v ? v.value : "");
                                }} options={options}/>;
        case 'select':
            if(!cf_data&&!props.options) {
                return null;
            }
            options_obj = props.options ? buildObject(props.options) : cf_getSelectOptionsObject(cf_data.options,true);
            options = props.options ? props.options : cf_getSelectOptionsArray(cf_data.options,true);
            if(options.length<5) {
                return <ShortSelect options={options.filter(a=>!!a.value)} value={value === '' ? null : {value: value, label: options_obj[value]}}
                                    onChange={(v) => {
                                        onChange(v ? v.value : "");
                                    }} />
            }
            return <BasicSelect usePortal searchable={options.length>4} placeholder={placeholder?placeholder:(options.length>4?"Start typing..":"Select")}
                                value={value ? {value: value, label: options_obj[value]} : null}
                                onChange={(v) => {
                                    onChange(v ? v.value : "");
                                }} options={options}/>;
        case 'multi-select':
            options_obj = props.options ? buildObject(props.options) : cf_getSelectOptionsObject(cf_data.options);
            options = props.options ? props.options : cf_getSelectOptionsArray(cf_data.options);
            cval = Array.isArray(value) ? value.map(id=>{
                return {
                    id,
                    label: options_obj[id]
                }
            }) : co_buildMultiSelectArray(Object.keys(value?value:{}), options_obj);

            return <BasicSelect searchable usePortal placeholder={placeholder?placeholder:"Start typing.."} clearable={false} onChange={(vt) => {
                // convert to obj
                if (!vt) {
                    onChange({});
                } else {
                    let nv = {};
                    // todo should be value or id?
                    for (let i = 0; i < vt.length; i++) {
                        nv[vt[i].value] = true;
                    }
                    onChange(nv);
                }
            }} multi value={cval} options={options}/>;
        case 'select-taxonomy':
            if(!cf_data||!cf_data.options||!cf_data.options.taxonomy) {
                return null;
            }
            return <div>
                {getInputByType('select-taxonomy', onChange, () => {
            }, value, {
                meta,
                taxonomy: cf_data.options.taxonomy
            })}
            </div>;
        case 'multi-select-taxonomy':
            if(!cf_data||!cf_data.options||!cf_data.options.taxonomy) {
                return null;
            }
            return <div>
                {getInputByType('multi-select-taxonomy', onChange, () => {
            }, value, {
                meta,
                taxonomy: cf_data.options.taxonomy
            })}
            </div>;
        case 'emoji' :
            return <div className="inline-block">
                <SymbolEditor value={value} onChange={(emoji) => {
                    onChange(emoji.native)
                }}/>
            </div>
        case 'checkbox' : {
            // value?props.options.checked_label:props.options.unchecked_label
            return <div className="p-px">
                <Toggle checked={value}
                        label={!cf_data.options.checked_label?"":(value?cf_data.options.checked_label:cf_data.options.unchecked_label)}
                        onToggle={() => {
                            onChange(!value)
                        }}
                />
            </div>
        }
        case 'symbol' :
            return <div className="inline-block">
                <SymbolEditor value={value} onChange={(emoji) => {
                    onChange(emoji.native)
                }}/>
            </div>
        case 'phone':
            return <RichPhoneInput value={value} onChange={onChange} {...props} />
        case 'url':
            return <Input placeholder={placeholder} type="url" value={value} onChange={onChange} {...props} />;
        case 'number':
            return <Input placeholder={placeholder} type="number" value={value} onChange={onChange} {...props} />;

        case 'address':
            return <>
                <Input name={"address-line1"} value={valueFallback(value,'line_one')} onChange={(v) => handleAddressChange(v, value, 'line_one', onChange)}
                       input_classes="border-b-0 focus-within-z-trick rounded-b-none" type="text"
                       placeholder="Street Address"/>
                <Input name={"address-line2"} value={valueFallback(value,'line_two')} onChange={(v) => handleAddressChange(v, value, 'line_two', onChange)}
                       input_classes="border-b-0 focus-within-z-trick rounded-none" type="text" placeholder="Apt"/>
                <div className="flex">
                    <div className="flex-auto">
                        <Input value={valueFallback(value,'city')} onChange={(v) => handleAddressChange(v, value, 'city', onChange)}
                               input_classes="border-b-0 border-r-0 focus-within-z-trick rounded-none" type="text"
                               placeholder="City"/>
                    </div>
                    <div className="flex-auto">
                        <Input value={valueFallback(value,'zip_code')}
                               onChange={(v) => handleAddressChange(v, value, 'zip_code', onChange)}
                               input_classes="border-b-0 focus-within-z-trick rounded-none" type="text"
                               placeholder="ZIP Code"/>
                    </div>
                </div>
                <Input value={valueFallback(value,'state')} onChange={(v) => handleAddressChange(v, value, 'state', onChange)}
                       input_classes="border-b-0  focus-within-z-trick rounded-none" type="text" placeholder="State"/>
                <SimpleListbox placeholder="Country" usePortal name={"address-country"} layout="address" value={valueFallback(value,'country')} onChange={(v) => {
                    if(!v) {
                        handleAddressChange("", value, 'country', onChange)
                    } else {
                        handleAddressChange(v.value, value, 'country', onChange)
                    }

                }} clearable searchable options={countries_array}/>
            </>;
        case 'gender' : {
            return <BasicSelect placeholder="Sex" searchable={true} value={value === '' ? null : {value: value, label: genders_object[value]}}
                                onChange={(v) => {
                                    onChange(v.value)
                                }} options={genders}/>
        }
        case 'language' : {
            return <BasicSelect placeholder="Language" searchable={true} value={value === '' ? null : {value: value, label: languages_object[value]}}
                                onChange={(v) => {
                                    onChange(v.value)
                                }} options={languages}/>
        }
        case 'join-date' : {
            return <MemberJoinDate simple onChange={(njd)=>onChange(njd)} existing={value}/>
        }
        case 'timezone' : {
            return <BasicSelect placeholder="Timezone" searchable={true} value={value === '' ? null : {value: value, label: timezones_obj[value]}}
                                onChange={(v) => {
                                    onChange(v.value)
                                }} options={Object.entries(timezones_obj).map(a=>{
                                    return {
                                        id: a[0],
                                        label: a[1]
                                    }
            })}/>
        }
        case 'country' : {
            return <SimpleListbox placeholder="Country" usePortal name={"country"} value={value} onChange={(v) => {
                if(!v) {
                    onChange("");
                } else {
                    onChange(v.value);
                }
            }} clearable searchable options={countries_array}/>
        }
        case 'birthday':
            return <BirthdaySelector time_format={'MM/DD/YYYY'} value={value} onChange={(v) => {
                onChange({...v});
            }}/>;
        case 'text-and-emoji':
            return <TextAndEmoji onChange={(v) => {
            }} emoji={value.emoji} value={value.text}/>;
        default:
            return <Input placeholder={placeholder} type="text" value={value} onChange={onChange} {...props} />
    }
}

export function PFFieldRenderer({
                                    title,
                                    label = "",
                                    field,
                                    cf_data,
    placeholder="",
    hint="",
    required,
                                    value,
                                    corner_hint,
                                    onChange=()=>{},
                                    type = "text",
                                    input_props = {},
                                    meta = {}
                                }) {

    function handleOnChange(val) {
        onChange(val, {type});
    }

    const input = newInputByType(value, type, handleOnChange, input_props, meta, cf_data, placeholder);

    return <Field title={title} corner_hint={corner_hint} help_text={hint} required={required} label={title ? "" : label ? label : ""}>
        {input}
    </Field>
}