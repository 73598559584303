import React, {useState} from "react";
import {Button} from "../../../library/components/core";
import {authFetch} from "../../../config/network";
import {BellIcon, XIcon} from "@heroicons/react/outline";
import {PopupMenu} from "../../../library/components/old/popup-menu";
import {Popover2} from "@blueprintjs/popover2";
import {NewTooltip} from "../../../library/components/reorganize/new-tooltip";
import {CheckIcon, PlusIcon} from "@heroicons/react/solid";

export function GroupUpdates({cb, layout = 'standard', id, notification_prefs, member_id, community_uid}) {
    const [saving, setSaving] = useState(false);

    function muteGroup(id) {
        setSaving(true);
        const res = () => {
            setSaving(false);
        };
        const payload = {
            entity_type: 'group',
            member_id,
            community_uid,
            entity_id: id
        };
        authFetch("/members/mute", res, res, "POST", {payload});
    }

    function unMuteGroup(id) {
        setSaving(true);
        const res = () => {
            setSaving(false);
        };
        const payload = {
            entity_type: 'group',
            community_uid,
            member_id,
            entity_id: id
        };
        authFetch("/members/unmute", res, res, "POST", {payload});
    }

    const muted = notification_prefs.muted;

    let eo = [];

    if (!muted) {
        if (layout === 'button') {
            eo.push({
                type: 'item',
                title: 'Mute Group',
                onClick: () => muteGroup(id)
            });
            return <Popover2 usePortal={false} placement={'bottom-start'} content={<PopupMenu items={eo}/>} minimal
                             popoverClassName='minimal-popover my-2'>
                <Button icon={<BellIcon/>} size="sm" text="Subscribed"/>
            </Popover2>

        }
        return <NewTooltip message="Click to Unsubscribe">
            <div onClick={() => muteGroup(id)}
                 className={`p-0.5 -m-0.5 rounded-md cursor-pointer bg-white text-success hover:text-danger-800 text-success hover:text-danger-800`}>
                <Button icon={<div className="text-success h-svg-4"><CheckIcon /></div>} size="sm" text="Following"/>
            </div>
        </NewTooltip>
    } else {
        if (layout === 'button') {
            eo.push({
                type: 'item',
                title: 'Unmute Group',
                onClick: () => unMuteGroup(id)
            });
            return <Popover2 usePortal={false} placement={'bottom-start'} content={<PopupMenu items={eo}/>} minimal
                             popoverClassName='minimal-popover my-2'>
                <Button icon={<XIcon/>} size="sm" text="Muted"/>
            </Popover2>
        }
        return <div onClick={() => unMuteGroup(id)}
                    className={`p-0.5 -m-0.5 rounded-md cursor-pointer bg-white text-gray-500 hover:text-success-800 hover:text-success-300`}>
            <Button icon={<PlusIcon />} size="sm" text="Follow"/>
        </div>
    }
}