import React from 'react';

export const CIDirectory = () => {
    return <svg className='ci' version="1.1"  x="0" y="0" width="auto" viewBox="0, 0, 24, 24">
        <g id="directory">
            <path className="ci-fill" d="M7.238,2 L16.762,2 C18.866,2 20.571,3.628 20.571,5.636 L20.571,18.364 C20.571,20.372 18.866,22 16.762,22 L7.238,22 C5.134,22 3.429,20.372 3.429,18.364 L3.429,5.636 C3.429,3.628 5.134,2 7.238,2 z" fill="#FF0000"/>
            <path className="ci-strokefill" d="M16.744,1 C19.39,1 21.554,3.065 21.554,5.636 L21.554,18.364 C21.554,20.935 19.39,23 16.744,23 L7.22,23 C4.847,22.961 2.559,21.199 2.429,18.714 L2.26,18.714 C0.58,18.714 0.58,15.762 2.26,15.762 L2.411,15.762 L2.411,13.476 L2.26,13.476 C0.58,13.476 0.58,10.524 2.26,10.524 L2.411,10.524 L2.411,8.238 C2.352,8.238 2.403,8.238 2.26,8.238 L2.26,8.238 C0.58,8.238 0.58,5.286 2.26,5.286 L2.429,5.286 C2.579,2.716 4.809,1.117 7.22,1 L16.744,1 z M16.744,3 L7.22,3 C5.658,3 4.411,4.191 4.411,5.636 L4.411,18.364 C4.411,19.809 5.658,21 7.22,21 L16.744,21 C18.306,21 19.554,19.809 19.554,18.364 L19.554,5.636 C19.554,4.191 18.306,3 16.744,3 z M11.982,6.083 C12.855,6.083 13.693,6.43 14.31,7.047 C14.928,7.664 15.274,8.502 15.274,9.375 C15.256,10.298 15.023,10.721 14.529,11.461 L14.31,11.703 C13.942,12.036 14.159,11.861 13.642,12.206 C14.373,12.421 14.971,12.871 15.526,13.374 C16.466,14.313 16.994,15.588 16.994,16.917 C16.994,17.47 16.546,17.917 15.994,17.917 L7.971,17.917 C7.418,17.917 6.971,17.47 6.971,16.917 C6.971,15.588 7.499,14.313 8.439,13.374 L8.439,13.374 C8.968,12.802 9.62,12.497 10.322,12.206 C9.805,11.861 10.022,12.036 9.654,11.703 C9.037,11.086 8.69,10.248 8.69,9.375 C8.69,8.502 9.037,7.664 9.654,7.047 C10.272,6.43 11.109,6.083 11.982,6.083 z M11.982,13.906 C11.183,13.906 10.418,14.223 9.853,14.788 L9.853,14.788 C9.514,15.098 9.324,15.502 9.152,15.917 L14.812,15.917 C14.683,15.479 14.413,15.12 14.111,14.788 C13.547,14.223 12.781,13.906 11.982,13.906 z M11.982,8.083 C11.639,8.083 11.311,8.219 11.068,8.461 C10.826,8.704 10.69,9.032 10.69,9.375 C10.69,9.718 10.826,10.046 11.068,10.289 C11.311,10.531 11.639,10.667 11.982,10.667 C12.325,10.667 12.654,10.531 12.896,10.289 L12.896,10.289 C13.138,10.046 13.274,9.718 13.274,9.375 C13.274,9.032 13.138,8.704 12.896,8.461 C12.654,8.219 12.325,8.083 11.982,8.083 z" fill="#000000"/>
        </g>
    </svg>

};