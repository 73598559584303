import React, {useContext, useEffect, useState} from 'react';
import {useIntl} from "react-intl";
import {GlobalContext} from "../../global/global-context";
import {getUrlVars} from "../../../library/utilities/general";
import SignInBlock from "./SignInBlock";
import {VerifyAccountPin} from "./verify-account-pin";
import {SetYourPassword} from "./set-your-password";
import SignInMeta from "./sign-in-meta";
import styled from "styled-components";
import SignInInputUserName from "./input-username";
import {Link} from "react-router-dom";
import {SocialSignIn} from "./social-sign-in";
import {SignInWithGoogle} from "../sign-in-with-google";

const ChildSpanColor = styled.div`

  a[href^="/"] {
    color: ${props => props.color ? props.color : ''} !important;
  }
`;

/*
wrapper for all sign in related components
first we have user enter handle or email
if valid and account_confirmed, they enter pw
if no password set -> pin verification + pw set flow
if no account found -> sign up + verification flow
 */

export function SignInWrapper({
                                  handleSubmit = () => {
                                  },
                                  login_error,
                                  group_data,
                                  community_handle = "",
                                  can_apply_to_join = false,
                                  color = ""
                              }) {
    const global = useContext(GlobalContext);
    const [phase, setPhase] = useState("enter-username");
    const [username, setUsername] = useState("");
    const [temp_code, setTempCode] = useState('');
    const [meta, setMeta] = useState('');
    const [strategy, setStrategy] = useState('');

    useEffect(function () {
        let email = getUrlVars()['email'];
        let email1 = getUrlVars()['_email'];

        if (typeof email === 'string') {
            setUsername(email);
        }
        if (typeof email1 === 'string') {
            setUsername(email1);
        }

    }, []);

    return <ChildSpanColor color={color}>

        {phase === "enter-username" && <SignInInputUserName handleResp={(resp, u) => {
            const data = resp.data;

            if (!data) {
                console.error('something went wrong');
                return;
            }
            if (data === 'email_direct_entry') {
                setUsername(u);
                setMeta('');
                setStrategy('email');
                setPhase("enter-password");
            } else if (data.status === "account_confirmed") {
                setUsername(u);
                setMeta(data.meta);
                setStrategy(data.strategy);
                setPhase("enter-password");
            } else if (data.status === "anonymous_account") {
                // todo
            } else if (data.status === "force_reset_password") {
                setUsername(u);
                setMeta(data.meta);
                setPhase("verify-account");
            } else if (data.status === "not_found") {
                setPhase("sign-up");
            }
        }} username={username}/>}

        {phase === 'enter-username'&&<div>
            <div>
                <div className="text-center text-base text-gray-600 py-4">or</div>
            </div>
            <div>
                <SignInWithGoogle />
            </div>
        </div>}

        {phase === "enter-password" &&
        <SignInBlock group_data={group_data} community_handle={community_handle} strategy={strategy} username={username}
                     handleSubmit={(u, p, cb) => {
                         handleSubmit(u, p, cb);
                     }}/>}

        {phase === "verify-account" && <VerifyAccountPin username={username} onSuccess={(reset_pw_code) => {
            setTempCode(reset_pw_code);
            setPhase('set-password');
        }}/>}

        {phase === "sign-up" && <div className="mt-2 px-3 py-2 rounded-md bg-secondary-50 text-secondary-800">
            No account found for this Unaty ID. <Link to="/sign-up">Sign up</Link>
        </div>}

        {phase === "set-password" && <SetYourPassword color={color} temp_code={temp_code} username={username}/>}

        <SignInMeta community_handle={community_handle} phase={phase}/>

    </ChildSpanColor>
}