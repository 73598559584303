import React, {useContext} from "react";
import {CommunityContext} from "../../../../community-context";
import {TeamsRanker} from "../../teams-rank/ranker";
import CommonCard from "../../../../../../library/components/old/card";

export default function SettingsPreferencesTeams(props) {
    const community = useContext(CommunityContext);

    return (<div className="px-4 pt-1">
            <CommonCard title="Leaders Groups Display Order">
            <TeamsRanker/>
            </CommonCard>
        </div>
    );
}