export const genders = [
    {
        value: 'female',
        label: 'Female'
    },
    {
        value: 'male',
        label: 'Male'
    },
    {
        value: 'non-binary',
        label: 'Non-binary'
    },
    {
        value: 'prefer-not-to-say',
        label: "I'd prefer not to say"
    }
];
export const genders_object = {
    'female': 'Female',
    'male': 'Male',
    'other': 'Other',
    'non-binary': 'Non-binary',
    'prefer-not-to-say': "I'd prefer not to say"
};