import React, {useContext} from 'react';
import {GlobalContext} from "../../../../../global/global-context";
import {CommunityContext} from "../../../../community-context";
import {SignInPageDesigner} from "../../../../../../library/components/reorganize/sign-in-page-designer";

function getDefaults(community) {
    if(community.data.branding.sign_in_cover_photo) {
        return {
            url: community.data.branding.sign_in_cover_photo,
            color: community.data.branding.sign_in_cover_photo_color
        };
    }
    return {
        url: '',
        color: '#ffffff'
    }
}

export function CADSignInPage() {
    const global = useContext(GlobalContext);
    const community = useContext(CommunityContext);

    const defaults = getDefaults(community);

    return <SignInPageDesigner updateCommunityData={global.updateCommunityData} community={community} current={defaults} />
}