import React, {useState} from "react";
import {OnboardingStep} from "../step";
import {ActionWithIcon} from "../../../core";
import {ChevronLeftIcon} from "@heroicons/react/solid";
import {UnatySVG} from "../../../../icons/unaty/logos";
import {OnboardingTemplateStandard} from "../templates/standard";
import {OnboardingTemplateFeatured} from "../templates/featured";
import {OnboardingTemplateRightImage} from "../templates/right-image";
import {useIsMobile} from "../../../../../app/global/global-router";

export function OnboardingFrame({
    data = {}
                                }) {
    const {onSkip, onComplete, steps, step_data} = data;
    const [step, setStep] = useState(0);
const is_mobile = useIsMobile();
    const step_id = steps[step];

    const steps_count = steps.length;

    function next() {
        if (step + 1 === steps_count) {
            // last
            onComplete();
        } else {
            setStep(step + 1);
        }
    }

    function back() {
        setStep(step - 1);
    }

    function renderStep(id, data, step_functions) {
        switch (data.layout) {
            case 'right-image':
                return <OnboardingTemplateRightImage {...data} fns={step_functions}/>
            case 'featured':
                return <OnboardingTemplateFeatured {...data} fns={step_functions}/>
            default:
                return <OnboardingTemplateStandard {...data} fns={step_functions}/>
        }
    }

    const progress = Math.ceil(((step + 1) / steps_count) * 100);

    const step_functions = {
        nextStep: () => {
            next();
        },
    };

    return <div className={`bg-white sm:bg-gray-200 fixed top-0 left-0  right-0 bottom-0 overflow-y-auto ${is_mobile?"z-99999":"z-9999"}`}>
        <div>
            <div className="w-full sm:pt-8 sm:pb-20 mx-auto sm:px-8" style={{maxWidth: '720px'}}>
                <div className="hidden sm:flex mb-2.5 items-center px-3">
                    <div className="flex-none w-20">
                        {step > 0 && <ActionWithIcon size="sm" onClick={() => back()} inverse text="Back"
                                                     icon={<ChevronLeftIcon/>}/>}
                    </div>
                    <div className="flex-grow flex items-center justify-center">
                        <div className="h-5 w-5">
                            <UnatySVG fill="#B3B3B3"/>
                        </div>
                    </div>
                    <div className="flex-none w-20 flex justify-end">
                        {(step + 1) !== steps_count &&
                        <ActionWithIcon size="sm" onClick={() => onSkip()} inverse text="Skip"/>}
                    </div>
                </div>

                <OnboardingStep progress={progress}>
                    {renderStep(step_id, step_data[step_id], step_functions)}
                </OnboardingStep>
            </div>
        </div>
    </div>
}