import React, {useState} from 'react';
import {Button} from "../../../../../library/components/core";
import Modal from "../../../../../library/components/old/modal";
import {validateEmail} from "../../../../../library/utilities/validation";
import {Field, Input} from "../../../../../library/components/core";
import {FirebaseDB} from "../../../../../config/setup-firestore";
import {SimpleCallout} from "../../../group";
import {authFetch} from "../../../../../config/network";
import {ArrowRightIcon} from "@heroicons/react/solid";

export function AdMeActionChangeAccountEmail({
                                                 loading = false,
                                                 onClose=()=>{},
                                                 onConfirm=()=>{},
                                                 default_email = '',
                                                 community_uid = '',
}) {
    const [email, setEmail] = useState(default_email);
    const [state, setState] = useState('');

    function checkIfEmailAvailable() {
        setState('checking');

        const em = email;
        const payload = {
            email: em,
            community_uid
        };

        const res = (r) => {
            if(!r) {
                return;
            }
            if (r.data.code === 'is-community-member') {
                setState('is-member');
                setEmail('');
            } else if (r.data.code === 'email-taken') {
                setState('in-use');
            } else {
                onConfirm(em)
            }
        };

        authFetch("/accounts/check-if-email-is-available", res, res, "POST", {payload});
    }

    const body = <div>
        <Field label="New Email">
            <Input autoFocus placeholder="name@email.com" value={email} onChange={(v)=> {
                setEmail(v);
                setState('');
            }} />
        </Field>
        {state==='in-use'&&<div className="pt-4">
            <SimpleCallout intent="danger" action={<Button onClick={()=>onConfirm(email)} text={"Switch Anyway"} />} message="This email is already taken by another account. If you switch this members account email, this member will be ressigned to the user account with the new email."/>
        </div>}
        {state==='is-member'&&<div className="pt-4">
            <SimpleCallout intent="danger" message="This email belongs to another community member, please contact an admin to merge your profiles."/>
        </div>}
    </div>;

    return <Modal title={"Change Account Email"} size='small' body={body} footerRight={<div>
        <Button intent='secondary' loading={loading} disabled={!validateEmail(email)||state==='checking'} onClick={()=>checkIfEmailAvailable()} text='Next' right_icon={<ArrowRightIcon />}/>
    </div>} footerLeft={null}
                  onClose={onClose} open={true} lazy/>
}