import React, {useEffect, useState} from "react";
import {Input} from "../../core";
import {RulesInlineSelect} from "../../rules/rules-inline-select";
import {isSupportedCountry, getCountryCallingCode} from "libphonenumber-js/min";
import {countries, getFlagByCountryCode} from "../../../data/general/countries";
import {getPhoneNumberData} from "../../../utilities/phone";
import styled from "styled-components";

const phone_placeholders = {
    'de': '170 523 9230',
    'gb': '20 7946 0389',
    'fr': '93 205 0237',
    'us': '(216) 867-5309',
    'nl': '71 884 6843',
    '*': '000 0000'
};


function buildCountries() {
    // prioritize a few countries
    const priorities = ['de','fr','gb','us','nl'];
    let arr = [...priorities.map(id=>{
        const label = countries[id];
        return {
            id,
            label,
            placeholder: phone_placeholders[id] ? phone_placeholders[id] : phone_placeholders['*'],
            emoji: getFlagByCountryCode(id),
            meta: `+${getCountryCallingCode(id.toUpperCase())}`
        }
    }),{
        id: 'divider-main',
        divider: true
    }];

    const world = Object.entries(countries).filter(([id, name]) => {
        return !priorities.includes(id)&&isSupportedCountry(id.toUpperCase());
    }).sort((a, b) => {
        return (a.name < b.name) ? -1 : 1;
    }).map(([id, name]) => {
        return {
            label: name,
            id,
            placeholder: phone_placeholders[id] ? phone_placeholders[id] : phone_placeholders['*'],
            emoji: getFlagByCountryCode(id),
            meta: `+${getCountryCallingCode(id.toUpperCase())}`
        };
    });

    return arr.concat(world);
}

const all_countries = buildCountries();

/*
parent only knows full number
if it contains a plus, it has a country, if not we try to get it

user should be able to select country
user should be able to paste in value and have country autodetected
 */

const Wrapper = styled.div`

    .inline-select {
      min-width: 32px;
      margin: 0;
      margin-left: 2px;
      height: 38px;
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
`;

function getCountry(number) {
    if(!number) {
        return '';
    }
    const phone_data = getPhoneNumberData(number);
    if(phone_data.valid) {
        return phone_data.country.toLowerCase();
    } else {
        return '';
    }
}

function getInitialData(val) {

    const pn = getPhoneNumberData(val);
    if(pn) {
        return {
            value: pn.edit,
            country: pn.country.toLowerCase()
        }
    }
    return {
        value: val,
        country: pn.country.toLowerCase()
    }
}

export function RichPhoneInput({
                                   value, onChange = () => {
    }, onBlur = () => {}, autoFocus = false,
    onEnter=()=>{}
                               }) {

    const [val,setVal] = useState(getInitialData(value).value);
    const [co,setCo] = useState(getInitialData(value).country);
    const inputRef = React.useRef();
    const isFirstRun = React.useRef(true);

    const inputFocus = () => {
        if(inputRef&&inputRef.current) {
            inputRef.current.focus();
        }
    };

    useEffect(function() {
        // get country from phone
        if (isFirstRun.current) {
            isFirstRun.current = false;
            return;
        }

        if(!val) {
            onChange("");
            return;
        }

        const full = getPhoneNumberData(val,co);

        onChange(full.number)
    }, [val,co]);

    function handlePaste(e) {
        e.stopPropagation();
        e.preventDefault();
        const ve = e.clipboardData.getData('Text');

        let nv = `${ve}`;
        nv = nv.replace(/\)/g,'').replace(/\(/g,'').replace(/ /g, '');

        if(nv.indexOf('+')===-1&&co) {
            nv = `+${getCountryCallingCode(co.toUpperCase())}${nv}`;
        }

        const phone_data = getPhoneNumberData(nv);

        if(phone_data.valid&&co!==phone_data.country.toLowerCase()) {
            setCo(phone_data.country.toLowerCase());
        }

        if(phone_data.valid) {
            setVal(phone_data.number);
        } else {
            setVal(nv);
        }
    }

    function handleCountryChange(nc) {
        const phone_data = getPhoneNumberData(val);
        if(phone_data.valid&&nc!==phone_data.country.toLowerCase()) {
            // valid phone, switch number
            const new_country = all_countries.filter(a=>a.id===nc)[0];

            const new_number = val.replace(`${phone_data.calling_code}`,`${new_country.meta}`);

            const phone_data_2 = getPhoneNumberData(new_number);

            if(phone_data_2.valid) {
                setVal(phone_data_2.edit);
            } else {
                setVal(phone_data_2.edit);
            }
        }
        setCo(nc);
        if(val.length<5) {
            inputFocus();
        }
    }

    function handleBlur() {
        onBlur();
    }

    function handleChange(v) {
        if(!v) {
            setCo('')
            setVal('');
        }

        let nv = `${v}`;

        // 015172888877
        // 015127 538238
        // +49 30 901820
        // add national format
        // or select the country, add teh rest of the international format
        nv = nv.replace(/\)/g,'').replace(/\(/g,'').replace(/ /g, '');

        if(nv&&co&&nv.indexOf("+")===-1) {
            nv = `+${getCountryCallingCode(co.toUpperCase())}${nv}`
        }

        const phone_data = getPhoneNumberData(nv);

        if(nv&&!co&&phone_data.valid&&phone_data.country) {
            setCo(phone_data.country.toLowerCase());
        }

        if(nv&&phone_data.valid) {
            setVal(phone_data.number);
        } else {
            setVal(nv);
        }
    }

    function onEmptyBackspace() {
        setCo('');
    }

    const country_data = all_countries.filter(a=>a.id===co)[0];

    const has_country = !!country_data;
    return <div className="relative">
        <Input meta={{country: has_country?co:"",country_code: !has_country?"":country_data.meta}} onPaste={handlePaste} onEnter={onEnter} inputRef={inputRef} onEmptyBackspace={onEmptyBackspace} autoFocus={autoFocus} onBlur={handleBlur} placeholder={has_country?country_data.placeholder:'+1 123 456 7890'} leading={{type:has_country?'phone-country-code':'phone-country-code-select'}} formatting='phone' type="phone" onChange={handleChange} value={val}/>
        <Wrapper className="absolute items-center top-0 bottom-0 -left-px flex space-x-px">
            <RulesInlineSelect usePortal emoji searchable inline selected={co} options={all_countries} placeholder={"🌍"}
                               onSelect={(no) => handleCountryChange(no)}/>
            {has_country&&<div style={{fontSize:'16px',paddingTop:'1px',paddingLeft:'3px'}} className="text-gray-500 w-8 text-right">
                {country_data.meta}
            </div>}
        </Wrapper>
    </div>
}