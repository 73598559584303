import React from 'react';
import {Field} from "./field";
import {Input} from "./text-input";
import {EmojiPopupSelector} from "../old/emoji-popup-selector";
import {PhotoSelector} from "../old/photo-selector";
import {GroupDisplayIcon} from "../../utilities/groups";
import styled from "styled-components";
import {ChevronDownIcon} from "@heroicons/react/solid";

const DownIcon = styled.div`
> svg {
    height: 14px;
}
`;

function capitalizeFirstLetter(string) {
    if(!string) {
        return "";
    }
    return string.charAt(0).toUpperCase() + string.slice(1);
}

function buildModes(types) {
    return types.map((type)=>{
        if(typeof type === 'string') {
            const t = type === 'image' ? 'custom' : type;
            return {
                id: t,
                label: capitalizeFirstLetter(t)
            }
        } else {
            return {
                id: type.id==='image'?'custom':type.id,
                label: type.label
            }
        }
    })
}

export function EntityIconSelector({value,selector_type='emoji',onBlur,allowed_types=[{id:'emoji',label:'Emoji'}, {id:'color',label:'Color'}, {id:'image',label:'Image'}],placeholder="Set your Status",label,emoji,profile_picture,color="#a1a1aa",onChange}) {
    const modes = buildModes(allowed_types);
    const comp = <PhotoSelector type={selector_type} modes={modes} setPhoto={(url, color)=>{
        if(url==='color') {
            onChange(value,'color',color);
        } else if(url==='emoji') {
            onChange(value,'emoji',color);
        } else {
            onChange(value,'profile_picture',url,color);
        }
    }}>
        <div className="flex space-x-1 items-center cursor-pointer hover:opacity-70" style={{width:'2.3rem'}}>
        <GroupDisplayIcon emoji={emoji} profile_picture={profile_picture}
                          profile_picture_color={""} color={color} name={value?value:""} />
                        <DownIcon className="text-gray-600">
                            <ChevronDownIcon/>
                        </DownIcon>
        </div>
    </PhotoSelector>;

    return <Field label={label} corner_hint={null}>
        <Input onBlur={onBlur} autoFocus leading={{type:'group-icon',value:comp}} placeholder={placeholder} type="text" onChange={(v)=>{
            const type = emoji ? 'emoji' : profile_picture ? 'profile_picture' : 'color';
            const val = emoji ? emoji : profile_picture ? profile_picture : color;
            onChange(v,'name',val);
        }} value={value} />
    </Field>
}