import React from 'react';
import styled from "styled-components";

const Wrapper = styled.div`
    > div {
         padding-bottom: 64px;
    }
`;

const WrapperFull = styled.div`
    display: grid;
    > div {
         padding-bottom: 64px;
    }
`;

export function CommonLayoutProfile({layout="sidebar",no_body_padding,scrollable=true,children,sidebar=null,topbar=null}) {

    let body = null;

    if(layout==='sidebar') {
        body = <Wrapper className='standard-entity-profile-grid'>
            <div >
                {children}
            </div>
            <div >
                {sidebar}
            </div>
        </Wrapper>
    } else if(layout==='admin-entity') {
        body = <WrapperFull className="gap-6 grid-cols-5">
            <div className="col-span-2">
                {sidebar}
            </div>
            <div className="col-span-3">
                {children}
            </div>
        </WrapperFull>
    } else {
        body = <WrapperFull>
            <div>
                {children}
            </div>
        </WrapperFull>
    }

    return <div id={"content-top"} style={{maxWidth:'100vw'}} className={`relative ${scrollable?"scrollable-content":""} z-20 overflow-x-hidden md:overflow-x-auto`}>
        {topbar&&<div className="w-full sticky top-0 h-12 border-bottom flex items-center z-40 bg-white relative">
            {topbar}
        </div>}
        <div className={no_body_padding?"":"md:px-8 md:pt-4 md:pb-12"}>
        {body}
        </div>
    </div>
}