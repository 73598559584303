import React, {useState} from 'react';
import {cities_data} from "./cities";
import {countries} from "../../data/general/countries";
import {country_demonyms} from "./demonyms";
import {LocalSearchBar} from "../custom/local-search-bar";
import {reference_interests} from "../../../app/data-references";
import {AddEntitiesSuggestions} from "./add-entities-suggestions";
import {CUSTOM_NANOID} from "../../../config/defaults";

const specificity = {
    1: 'GENERAL', // this is a rough match on country name
    2: 'BROAD', // match on city, or on demonym
    3: 'NARROW', // match on select
    4: 'PRECISE' // match on member type
};

/*
- take select custom fields and countries to make suggestions on segments to easily add to groups
- based on group names, need to make this fuzzy
 */

export function levenstein(a, b) {
    var m = [], i, j, min = Math.min;

    if (!(a && b)) return (b || a).length;

    for (i = 0; i <= b.length; m[i] = [i++]) ;
    for (j = 0; j <= a.length; m[0][j] = j++) ;

    for (i = 1; i <= b.length; i++) {
        for (j = 1; j <= a.length; j++) {
            m[i][j] = b.charAt(i - 1) == a.charAt(j - 1)
                ? m[i - 1][j - 1]
                : m[i][j] = min(
                    m[i - 1][j - 1] + 1,
                    min(m[i][j - 1] + 1, m[i - 1][j] + 1))
        }
    }

    return m[b.length][a.length];
}

export function fuzzyContains(a, b, error, score) {
    let matchLength = a.length - b.length;
    let distanceToMatch = levenstein(a, b) - matchLength;
    const final = distanceToMatch - error > 0;
    if (final) {
        return score ? distanceToMatch : false;
    } else {
        return score ? distanceToMatch : true;
    }
}

// match based on member type, country, select fields, city??

// add a few demonyms like French, British, English... https://github.com/mledoze/countries/blob/master/dist/countries.json

// add top 500 cities?

const group_names = [
    "Coffee Corner",
    "Young Writers",
    "German Editors",
    "Sydney writers",
    "Amsterdam Hub",
    "I Love Porto",
    "London Hub",
    "Ambassadors of New York",
    "Ambassador Chat",
    "Paris",
    "Portugal is the best",
    "White Russian",
    "French members"
];

const meta = {
    member_types: [
        {
            name: 'Ambassadors || Ambassador'
        },
        {
            name: 'Editors || Editor'
        },
        {
            name: 'Alte Herren || Alter Herr'
        }
    ],
    custom_fields: [
        {
            name: 'Hub',
            options: [
                "Amsterdam",
                "Paris",
                "London",
                "New York"
            ]
        }
    ]
}

function isMatch(full, word, bool = false, error = 1) {
    const result = fuzzyContains(full, word, error);
    if (bool) {
        return result;
    }
    if (result) {
        return <strong className="text-success">true</strong>
    }
    return <span className="text-gray-400">false</span>;
}

function selectFieldMatches(name, option, bool = false) {
    const name_substrings = name.split(' ').filter(a => a.length > 3);
    const opt_substrings = option.split(' ').filter(a => a.length > 3).map(a => a.toLowerCase());
    let match = false;
    for (let i = 0; i < name_substrings.length; i++) {
        const a = name_substrings[i];
        for (let k = 0; k < opt_substrings.length; k++) {
            const os = opt_substrings[k];
            if (isMatch(a, os, true, 1) && (Math.abs(a.length - os.length) < 2)) {
                match = true;
                break;
            }
        }
        if (match) {
            break;
        }
    }
    if (bool) {
        return match;
    }
    if (match) {
        return <strong className="text-success">true</strong>;
    }
    return <span className="text-gray-400">false</span>;
}

export function getMatchingCountries(name, display = true) {
    // names of countries and demonyms
    let matches_obj = {};

    const name_substrings = name.split(' ');

    const countries_entries = Object.entries(countries);
    const countries_names = countries_entries.map(a => a[1].toLowerCase());

    // loop through countries first
    countries_names.forEach((cn, k) => {
        const substrings = cn.split(' ').filter(a => a.length > 4);
        substrings.forEach(cs => {
            name_substrings.forEach(ns => {
                if (isMatch(ns, cs, true, 0) && (Math.abs(ns.length - cs.length) < 2)) {
                    matches_obj[countries_entries[k][0]] = 1;
                }
            })
        })
    });

    // then loop through demonyms
    const demonyms = [...country_demonyms];

    demonyms.forEach((d, k) => {
        name_substrings.forEach(ns => {
            if (isMatch(ns, d.demonym, true, 1) && (Math.abs(ns.length - d.demonym.length) < 2)) {
                if (demonyms[k].country) {
                    matches_obj[demonyms[k].country] = 2;
                }

            }
        })
    });

    let matches = Object.entries(matches_obj).sort((a, b) => (a[1] < b[1]) ? 1 : -1).map(b => b[0]);

    if (!display) {
        return matches;
    }

    if (matches.length === 0) {
        return <span>-</span>
    }
    return <span>
        {matches.join(', ')}
    </span>

}

export function getMatchingCities(name, display = true) {
    let matches = [];

    const name_substrings = name.split(' ').map(n=>n.toLowerCase());

    const city_names = cities_data.map(a => a.name.toLowerCase());

    city_names.filter(a => a.length > 3).forEach(n => {
        let matched = false;
        if(isMatch(name.toLowerCase(), n, true, 0)&&n.length>6) {
            matches.push({name: n, score: 3})
            matched = true;
        } else if (n === name) {
            matches.push({name: n, score: 3})
            matched = true;
        } else {
            name_substrings.forEach(ns => {
                if (isMatch(ns, n, true, 0) && (Math.abs(ns.length - n.length) < 2)) {
                    matches.push({name: n, score: 2});
                    matched = true;
                }
            })
        }

        if (!matched) {
            const city_name_substrings = n.split(' ').filter(a => a.length > 4);
            city_name_substrings.forEach(cn2 => {
                name_substrings.forEach(ns => {
                    if (isMatch(ns, cn2, true, 0) && (Math.abs(ns.length - cn2.length) < 2)) {
                        matches.push({name: n, score: 1});
                        matched = true;
                    }
                })
            });
        }
    })

    const sorted = matches.sort((a, b) => a.score < b.score ? 1 : -1);

    if (!display) {
        let obj = {};
        sorted.forEach(m => {
            obj[m.name] = m.score;
        })
        return sorted.map(a => a.name);
    }

    if (sorted.length === 0) {
        return <span>-</span>
    }
    return <span>
        {sorted.map(a => a.name).join(', ')}
    </span>
}

function checkFullString(full, option) {
    const parts = full.split(' ').filter(a => a.length > 2);
    const mt_name_parts = option.split(' ').filter(a => a.length > 2);
    let matches = [];
    parts.forEach(a => {
        mt_name_parts.forEach(b => {
            const match = isMatch(a, b, true, 1);
            if (match && (Math.abs(a.length - b.length) < 2)) {
                matches.push(a);
            }
        })
    });
    return matches.length > 0;
}

function getMatchingMemberTypes(mts, name, display = true) {
    let matches = mts.filter(mt => checkFullString(name, mt.name.toLowerCase()));
    if (!display) {
        let obj = {};
        matches.forEach(m => {
            obj[m.id] = 4;
        })
        return matches[0] ? [matches[0].id] : [];
    }

    if (matches.length === 0) {
        return <span>-</span>
    }
    return <span>
        {matches.map(a => a.name.split(' || ')[0]).join(', ')}
    </span>
}

function getAllCustomFieldMatches(custom_fields, name, display = true) {
    let matches_obj = {};
    custom_fields.forEach(sf => {
        sf.options.forEach((opt, k) => {
            if (selectFieldMatches(name, opt.label, true)) {
                matches_obj[sf.id] = opt.id;
            }
        })
    });

    let matches = Object.entries(matches_obj);
    if (!display) {
        return matches.map(m => {
            return {
                [m[0]]: m[1]
            }
        });
    }
    if (matches.length === 0) {
        return <span>-</span>
    }
    return <span>
        {matches.map(a => `${a[0]}: ${a[1]}`).join(', ')}
    </span>
}

function getOperator(mt, co, ci, cf) {
    if (mt && (ci || cf || co)) {
        return 'all'
    } else if (co && ci) {
        return 'any'
    } else if (co && cf) {
        return 'any'
    } else if (ci && cf) {
        return 'any'
    } else {
        return 'all'
    }
}

function getRecommendations(name, obj) {
    const has_member_types_match = obj.member_types.length > 0;
    const has_countries_match = obj.countries.length > 0;
    const has_cities_match = obj.cities.length > 0;
    const has_custom_fields_match = obj.custom_fields.length > 0;
    const has_interests_match = obj.interests.length > 0;

    let conditions = [];

    let operator = getOperator(has_member_types_match, has_countries_match, has_cities_match, has_custom_fields_match);

    // has member type and country or city match = probably AND
    if (has_member_types_match && has_custom_fields_match) {
        // like paris ambassadors
        conditions.push({
            type: 'member_type',
            id: CUSTOM_NANOID(),
            field: 'member_type',
            operator: 'is',
            value: obj.member_types[0]
        });
        conditions.push({
            type: 'select',
            id: CUSTOM_NANOID(),
            field: `custom_fields-${Object.keys(obj.custom_fields[0])[0]}`,
            operator: 'is',
            value: Object.values(obj.custom_fields[0])[0]
        });
    } else if (has_member_types_match && has_cities_match) {
        // like german members
        conditions.push({
            type: 'member_type',
            field: 'member_type',
            id: CUSTOM_NANOID(),
            operator: 'is',
            value: obj.member_types[0]
        });
        conditions.push({
            type: 'string',
            id: CUSTOM_NANOID(),
            field: 'address.city',
            operator: 'starts_with',
            value: obj.cities[0]
        });
    } else if (has_member_types_match && has_countries_match) {
        // like german members
        conditions.push({
            id: CUSTOM_NANOID(),
            type: 'member_type',
            field: 'member_type',
            operator: 'is',
            value: obj.member_types[0]
        });
        conditions.push({
            type: 'country',
            id: CUSTOM_NANOID(),
            field: 'address.country',
            operator: 'is',
            value: obj.countries[0]
        });
    } else {
        if (has_member_types_match) {
            conditions.push({
                id: CUSTOM_NANOID(),
                type: 'member_type',
                field: 'member_type',
                operator: 'is',
                value: obj.member_types[0]
            });
        }

        if (has_custom_fields_match) {
            conditions.push({
                id: CUSTOM_NANOID(),
                type: 'select',
                field: `custom_fields-${Object.keys(obj.custom_fields[0])[0]}`,
                operator: 'is',
                value: Object.values(obj.custom_fields[0])[0]
            });
        }

        if (has_cities_match && !has_countries_match) {
            conditions.push({
                type: 'string',
                id: CUSTOM_NANOID(),
                field: 'address.city',
                operator: 'starts_with',
                value: obj.cities[0]
            });
        }

        if (has_countries_match) {
            conditions.push({
                id: CUSTOM_NANOID(),
                type: 'country',
                field: 'address.country',
                operator: 'is',
                value: obj.countries[0]
            });
        }

        if (has_interests_match) {
            /*
            conditions.push({
                type: 'boolean',
            id: CUSTOM_NANOID(),
                field: `interests.default_${obj.interests[0]}`,
                operator: 'is',
                value: true
            });

             */
        }
    }
    // has no member type, but country and city probably OR

    // has no member type, but country and select

    if (conditions.length === 0) {
        return [];
    }

    return [
        {
            match: operator,
            conditions
        }
    ];
}

function getMatchingIntersts(name, display = true) {
    const interests_arr = Object.values(reference_interests).filter(a => a.name.length > 4);

    const parts = name.split(' ').filter(a => a.length > 2);

    let matches_obj = {};

    interests_arr.forEach(ii => {
        const name_parts = ii.name.toLowerCase().split(' ').filter(a => a.length > 4);
        // just broad search is probably ok here

        parts.forEach(pt => {
            name_parts.forEach(np => {

                if ((Math.abs(pt.length - np.length) < 1) && isMatch(pt, np, true, 1)) {
                    matches_obj[ii.id] = 1;
                } else if ((Math.abs(pt.length - np.length) < 2) && isMatch(pt, np, true, 2) && pt.charAt(0) === np.charAt(0)) {
                    matches_obj[ii.id] = 2;
                }
            })
        })

    });
    let matches = Object.entries(matches_obj).sort((a, b) => a[1] > b[1] ? 1 : -1).map(b => b[0]);
    if (!display) {
        return matches;
    }

    return [];
}

function prepMemberTypes(raw) {
    if(!raw) {
        return [];
    }
    return Object.entries(raw).map(([id, entry]) => {
        return {
            id,
            name: `${entry.plural} || ${entry.singular}`
        }
    })
}

export function prepCustomFields(raw) {
    /*
    {
            name: 'Hub',
            options: [
                "Amsterdam",
                "Paris",
                "London",
                "New York"
            ]
        }
     */
    if(!raw) {
        return [];
    }
    let fields = [];
    Object.entries(raw).forEach((([id, entry]) => {
        fields = fields.concat(entry.fields.filter(fid => entry.field_data[fid].type === 'select').map(fid => {
            const dt = entry.field_data[fid];
            return {
                id: fid,
                options: dt.options.choices.map(c => {
                    return {
                        id: c.value,
                        label: c.text
                    }
                }),
                name: dt.name
            }
        }));
    }))
    return fields;
}

export function getSegmentMatchesForName(name, community = {}) {

    let result = {
        countries: [],
        cities: [],
        member_types: [],
        custom_fields: [],
        birth_year: [],
        interests: [],
        recommendation: []
    };
    if (!name) {
        return result;
    }
    result.countries = getMatchingCountries(name, false);

    result.cities = getMatchingCities(name, false);

    result.member_types = getMatchingMemberTypes(prepMemberTypes(community.member_types), name, false)

    result.custom_fields = getAllCustomFieldMatches(prepCustomFields(community.custom_fields), name, false)

    result.interests = getMatchingIntersts(name, false)

    result.recommendation = getRecommendations(name, {...result});

    return result;
}

export function SegmentSuggestions() {
    const [q, setQ] = useState("");
    const live_matches = getSegmentMatchesForName(q);
    return <div>
        <div>
            How it works: finds matches based on name for top 1k cities in the world, all countries, community member
            types, and custom field select options.
            <br/>
            <br/>
            Makes recommendations on a segment filter based on specificity of results, e.g. matching select value is
            more specific than general country match.
            <br/>
            <br/>
            Fuzzy matching across strings and parts, matching for country names (France) and demonyms (French).
            <br/>
            <br/>
            Specificity: 1 = General, 2 = Broad, 3 = Narrow, 4 = Precise
        </div>
        <br/>
        <br/>
        <br/>
        <div>
            Try it:
            <div>
                <LocalSearchBar onChange={v => setQ(v.toLowerCase())}/>
            </div>
            <div>
                {JSON.stringify(live_matches)}
            </div>
            </div>
        <br/>
        <br/>
        <br/>
        {!q && <div>
            <div>
                Examples:
            </div>
            {group_names.map(gn => {
                const fn = gn.toLowerCase();
                const info = getSegmentMatchesForName(fn);
                return <div className="text-xs p-4" key={fn}>
                    <div className="text-base">
                        Group Name: <strong>{gn}</strong>
                    </div>
                    <br/>
                    <div>
                        {JSON.stringify(info)}
                    </div>
                </div>
            })}
        </div>}
        <br/>
        <br/>
        <br/>
        <div>
            Community Data: {JSON.stringify(meta)}
        </div>
    </div>
}