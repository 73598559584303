import React, {useContext, useEffect, useRef, useState} from 'react';
import {CommunityContext} from "../../../../../app/community/community-context";
import {authFetch} from "../../../../../config/network";
import {Field, Toggle} from "../../../core";
import {LanguageSelect} from "../../../old/selectors/language";
import MemberTypeSelect from "../../../old/selectors/member-type-select";

export function ObScCommunityDefaults(props) {
    const community = useContext(CommunityContext);
    const [ language, setLanguage ] = useState(community.data.preferences.timedate.language);
    const [ default_member_type, setDefaultMemberType ] = useState(community.data.preferences.members.default_member_type);
    const [ build_mode, setBuildMode ] = useState(community.data.preferences.build_mode);
    const val = useRef();

    useEffect(function () {
        return () => {
            val.current = {language,build_mode,default_member_type};
        }
    }, [language,build_mode,default_member_type]);

    useEffect(function () {
        return () => {
            handleSave(val.current);
        }
    }, []);

    function handleSave(dt) {
        if(dt&&community&&community.member_id) {
            // members/edit-interests
            const res = () => null;

            const payload = {
                community_uid: community.uid,
                member_id: community.member_id,
                id: community.member_id,
                user_uid: community.member.user_uid,
                ...dt
            }

            authFetch('/communities/set-defaults', res, res, "POST", {payload});
        }
    }

    return <div className="space-y-6">
        <div>
            <Field label="What should the default language be?">
                <LanguageSelect value={language} onChange={(val) => {
                    setLanguage(val);
                }}/>
            </Field>
        </div>
        <div>
            <Field label="What member type should new members have?">
                <MemberTypeSelect value={default_member_type} onChange={(dmt)=>setDefaultMemberType(dmt)}/>
            </Field>
        </div>
        <div>
            <Field label="Turn on Community build mode?">
               <Toggle checked={build_mode} onToggle={(c)=>setBuildMode(c)} layout="switch" label={!build_mode?"Off - Members will receive emails normally":"On - No emails will be sent out to members while activated."} />
            </Field>
        </div>
    </div>
}