import React, {useState, useContext} from "react";
import styled from "styled-components";
import {Button} from "../../../../library/components/core";
import {authFetch} from "../../../../config/network";
import {CommunityContext} from "../../community-context";

const Container = styled.div`
    
`;

const Wrapper = styled.div`
    padding-bottom: 16px;
    padding-top: 12px;
    border-bottom: 1px solid #e3e8ee;
`;

const Title = styled.div`
    font-size: 16px;
    font-weight: 500;
    line-height: 1.4;
    opacity: ${props => props.muted ? '0.5' : ''};
    font-style: ${props => props.muted ? 'italic' : ''};
`;

const Subtitle = styled.div`
    
`;

const Text = styled.p`
    padding: 8px 0;
`;

const Actions = styled.div`
        display: flex;
    margin-top: 8px;
`;

// TODO generalize and make list component

const Item = ({member_prefs, name, unMuteList, loading, muteList, email}) => {
    return <Wrapper>
        <Title muted={member_prefs.muted} className='text-gray-900'>{name}</Title>
        {email !== '' && <Subtitle className='text-gray-700'>{email}</Subtitle>}
        <Text className='text-gray-800'>
            {member_prefs.muted ? 'You are not receiving emails for this list.' : 'You are receiving emails for this list.'}
        </Text>
        <div className="flex space-x-2">
            {member_prefs.muted && <Button loading={loading} onClick={unMuteList} text='Unmute Notifications'/>}
            {!member_prefs.muted && <Button loading={loading} onClick={muteList} text='Mute Notifications'/>}
        </div>
    </Wrapper>
};

export default function AllEntitiesNotifications() {
    const [loading, setLoading] = useState(false);
    const [saving, setSaving] = useState(false);
    const [groups, setGroups] = useState([]);

    const community = useContext(CommunityContext);

    function muteGroup(id) {
        setSaving(true);
        const res = () => {

        };
        const payload = {
            entity_type: 'group',
            community_uid: community.uid,
            entity_id: id
        };
        authFetch("/members/mute", res, res, "POST", {payload});
    }

    function unMuteGroup(id) {
        setSaving(true);
        const res = () => {

        };
        const payload = {
            entity_type: 'group',
            community_uid: community.uid,
            entity_id: id
        };
        authFetch("/members/unmute", res, res, "POST", {payload});
    }

    function handleRes(data) {
        setSaving(false);
        setLoading(false);
        setGroups(data);
    }

    return <div>
        <Container>
            {loading && <div>loading..</div>}

        </Container>
    </div>
}