import React, {useContext, useState} from 'react';
import {authFetch} from "../../../../config/network";
import {GlobalContext} from "../../../global/global-context";
import {logEngagement} from "../../../../api/analytics";
import {analytics} from "firebase";
import {MenuPanelGroupEmail} from "./email";

function getPowerUpData(pups) {
    let data = pups['unaty__mailing_lists'];
    if (!data) {
        return {
            config: {},
            installed: false
        };
    }
    return {
        config: data.config,
        installed: true
    }
}

export function GroupModalEmailListTab({community,getGroup=()=>{},cb=()=>{},id,email_list_power_up,group_data}) {
    const [modal,setModal] = useState("");
    const [loading,setLoading] = useState("");
    const global = useContext(GlobalContext);

    const community_uid = community.uid;

    function updateSettings(config, cb) {

        const payload = {
            power_up_uid: 'unaty__mailing_lists',
            community_uid: community_uid,
            member_id: community.member_id,
            group_id: id,
            ...config
        };

        const res = () => {
            getGroup(cb);

         //   global.addToast({text: 'Group email settings updated', intent: 'success'});
        };

        authFetch("/groups/update-power-up", res, res, "POST", {payload});
    }

    function createEmail(cb) {
        const payload = {
            power_up_uid: 'unaty__mailing_lists',
            community_uid: community_uid,
            member_id: community.member_id,
            group_id: id,
        };

        const res = () => {
            getGroup(cb);
            analytics().logEvent('create_group_mailing_list', {
                event_id: 'create_group_mailing_list',
                content_type: 'group',
                community_uid: community_uid,
                member_id: community.member_id,
                user_uid: community.member.user_uid
            });
            logEngagement("create_group_mailing_list",{
                community_uid: community_uid,
                member_id: community.member_id,
                user_uid: community.member.user_uid
            });
            global.addToast({text: 'Group email created', intent: 'success'});
        };

        authFetch("/groups/add-power-up", res, res, "POST", {payload});
    }

    function removeEmail(cb) {
        const payload = {
            power_up_uid: 'unaty__mailing_lists',
            community_uid: community_uid,
            member_id: community.member_id,
            group_id: id,
        };

        const res = () => {
            getGroup(cb);
            global.addToast({text: 'Group email removed', intent: 'success'});
        };

        authFetch("/groups/remove-power-up", res, res, "POST", {payload});
    }

    return <div >
        <MenuPanelGroupEmail onCopy={() => {
            global.addToast({text: 'Email copied to clipboard', intent: 'success'});
        }} data={getPowerUpData(group_data.power_ups)} createEmail={(cb) => createEmail(cb)}
                             openChangeHandle={() => setModal('change_handle')}
                             removeEmail={(cb) => removeEmail(cb)}
                             updateSettings={(co, cb) => updateSettings(co, cb)}/>
    </div>
}