import React, {useEffect, useRef, useState} from 'react';
import styled from "styled-components";
import {SearchIcon, XCircleIcon, XIcon} from "@heroicons/react/outline";
import {HotkeyBadge} from "../custom/hotkey-badge";
import {USSimpleItemLabel} from "./components";

const XWrapper = styled.div`

  > svg {
    height: 18px;
    width: 18px;
  }
`;

const SearchWrapper = styled.div`

  > svg {
    height: 16px;
    width: 16px;
  }
`;

const CustomInput = styled.input`
  border: ${props=>props.border_color?`1px solid ${props.border_color}`:""};
  :focus-within, :hover {
  }
`;

function clickedOnOption(elements) {
    if(!elements||!elements[1]) {
        return false;
    }
    for(let el of elements.values()) {
        if(el&&el.className&&typeof el.className==='string'&&el.className.indexOf('us-option')!==-1) {
            return true;
        }
    }
    return false;
}

export function USSearchBar({
                                space_name,
                                border_color,
                                open_search = null,
                                onChange = () => {
                                }, open, onEscape = () => {
    }, selected_item, query = '', onBlur = () => {
    }, onFocus = () => {
    }, onEnter = () => {
    }, onArrowKey = () => {
    }, autoFocus = true
                            }) {
    const [q, setQ] = useState(query);
    const [focus, setFocus] = useState(false);
    const input_ref = useRef(null);
    const isFirstRun = useRef(true);

    useEffect(function () {
        if(open_search&&open) {
            input_ref.current.focus();
        } else if(!open) {
            setFocus(false);
            input_ref.current.blur();
        }
    }, [open_search,open])

    useEffect(function () {
        if(!focus&&open&&q) {
            input_ref.current.focus();
        }
    }, [open,focus])

    useEffect(function () {
        // as long as it's invalid, keep trying to update
        if (isFirstRun.current) {
            isFirstRun.current = false;
            return;
        }
        if (!query||(query&&query!==q)) {
            setQ(query);
        }
    }, [query]);

    useEffect(function () {
        onChange(q);
    }, [q]);

    function handleKeyDown(e) {
        // 27 === escape, alt = 18

        if (e.keyCode === 38 || e.keyCode === 37) {
            e.preventDefault();
            onArrowKey(-1);
        } else if (e.keyCode === 39 || e.keyCode === 40) {
            e.preventDefault();
            onArrowKey(1);
        } else if (e.keyCode === 9) {

        } else if (e.keyCode === 13) {
            e.preventDefault();
            onEnter();
        } else if (e.keyCode === 27) {
            e.preventDefault();
            onEscape();
        }
    }

    return <div className="relative h-9 w-full group" style={{maxWidth:'610px'}}>
        <div className="relative text-sm">
            <CustomInput border_color={border_color} autoFocus={open||focus} ref={input_ref} onBlur={(e) => {
                const elements = document.querySelectorAll( ":hover" );
                e.preventDefault();
                if(clickedOnOption(elements)) {
                    // clicked on an option
                } else {
                    setFocus(false);
                    onBlur();
                }
            }} onFocus={(e) => {
                e.stopPropagation();
                e.preventDefault();
                setFocus(true);
                onFocus();
            }} onKeyDown={handleKeyDown}
                   className={`pr-1.5 h-9 pl-8 py-1.5 w-full rounded-xl placeholder-gray-500 bg-gray-100 hover:bg-gray-200 cursor-pointer duration-100 transition-colors focus-standard`}
                   placeholder={`Search${space_name?` ${space_name}`:""}..`} value={q} onChange={(e) => setQ(e.target.value)}/>
            {selected_item && q && focus && false && <div
                className='absolute text-base top-0 bottom-0 flex items-center left-10 pointer-events-none pl-2 font-normal text-gray-500'>
                <span className="opacity-0">{q}</span>—&nbsp;&nbsp;<USSimpleItemLabel
                label={selected_item.label}/>
            </div>}
        </div>
        <div className="absolute left-0 top-0 bottom-0 w-8 flex items-center justify-center pl-0.5">
            <SearchWrapper className="text-gray-500 group-hover:text-gray-600 ">
                <SearchIcon/>
            </SearchWrapper>
        </div>
        <div className="absolute right-0 top-0 bottom-0 w-8 flex items-center justify-center pr-2">
            {q && <XWrapper onClick={() => setQ('')} className="text-gray-500 hover:text-gray-600 cursor-pointer">
                <XIcon/>
            </XWrapper>}
            {!q && <div className="pr-1 opacity-80 transition-opacity">
                <HotkeyBadge/>
            </div>}
        </div>
    </div>
}