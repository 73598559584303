import React, {useState, useContext, useEffect} from 'react';
import {authFetch} from "../../../config/network";
import {AdvancedModal} from "../../../library/components/reorganize/advanced-modal";
import {getAllowedEntitiesFromType} from "../create-entities/create-group";
import {GlobalContext} from "../../global/global-context";
import {getSegmentMatchesForName} from "../../../library/components/suggestions/segment-suggestions";
import {getSegmentTitle} from "../../../library/components/suggestions/semantic-segments";

function getSuggestions(data,community) {

    // TODO TEMPORARY
    return null;

    if(!community.member_access.add_segments) {
        return null;
    }

    if(data.name.length<4) {
        return null;
    }

    let segments = Object.keys(data.entities.filters);

    if(segments.length>0) {
        return null;
    }

    const matches = getSegmentMatchesForName(data.name, community);

    if(matches.recommendation.length===0) {
        return null;
    } else {
        const name = getSegmentTitle(matches.recommendation[0],community);

        return {
            ...matches.recommendation[0],
            name
        };
    }
}

const group_add_entities = (data, type, existing, context, group_id, suggested={}) => {
    return {
        flow: 'update-group-entities',
        step: 'add-entities',

        appearance: {
            size: 'medium',
        },

        state: {
            roles: {},
            teams: {},
            filters: {},
            groups: {},
            members: {},
            member_types: {}
        },

        header: {
            title: `Add people to ${data.name}`,
            show_close_icon: true
        },

        blocks: [
            {
                type: "entities-filters",
                placeholder: `Search for ${context.is_admin?'members, roles, and more':'members and roles'}`,
                value: ["entities"],
                meta: {
                    block_group_id: group_id,
                    existing: existing
                },
                defaults: suggested,
                suggestions: getSuggestions(data,context.community),
                search_ctx: 'group',
                allowed_entities: getAllowedEntitiesFromType(type,context.is_admin)
            },
        ],

        footer: {
            primary: {
                type: 'button',
                intent: 'success',
                text: 'Add',
                action: 'proceed',
                disabled: [
                    {
                        field: 'members',
                        check: 'values-length',
                        value: '0'
                    }
                ]
            },
            secondary: null,
            tertiary: null
        }
    };
};

function noNewEntities(en) {
    let roles = Object.keys(en.roles).length === 0;
    let member_types = Object.keys(en.member_types).length === 0;
    let members = Object.keys(en.members).length === 0;
    let groups = Object.keys(en.groups).length === 0;
    let teams = Object.keys(en.teams).length === 0;
    let filters = Object.keys(en.filters).length === 0;

    return roles && members && member_types && teams && groups && filters;
}

export function GroupAddEntities({closeModal, data, handleSuccess, suggested, group_id, context, open, type, existing}) {
    const [loading, setLoading] = useState('');
    const global = useContext(GlobalContext);
    const isFirstRun = React.useRef(true);
    const [initial_data,setInitialData] = useState(group_add_entities(data, type, existing, context, group_id,suggested));

    useEffect(function () {
        if (isFirstRun.current) {
            isFirstRun.current = false;
            return;
        }
        setInitialData(group_add_entities(data, type, existing, context, group_id,suggested))
    }, [existing])

    function addEntities(p) {
        if (noNewEntities(p)) {
            actionHandler('cancel');
            return;
        }

        setLoading('add-entities');

        const payload = {
            entities: p,
            id: group_id,
            member_id: context.member_id,
            community_uid: context.community_uid
        };

        const res = () => {
            actionHandler('success');
            global.addToast({text: 'Group updated', intent: 'success'});
            setLoading('');
        };

        authFetch("/groups/add-new-entities", res, res, "POST", {payload});
    }

    function actionHandler(action, payload) {
        switch (action) {
            case 'proceed':
                addEntities(payload);
                return;
            case 'cancel':
                closeModal();
                return;
            case 'success':
                handleSuccess();
                return;
            case 'close-modal':
                closeModal();
                return;
            case 'created':
                closeModal();
                return;
            default:
                return;
        }
    }
    return open ? <AdvancedModal _loading={loading === 'add-entities'} context={context}
                                 actionHandler={(a, p) => actionHandler(a, p)} phase_data={initial_data}/> : null;
}