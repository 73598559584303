import React, {useState} from "react";
import {CogIcon, LightBulbIcon} from "@heroicons/react/outline";
import {NewTooltip} from "../../../../reorganize/new-tooltip";
import {useIntl} from "react-intl";
import styled from "styled-components";
import {useHistory} from "react-router-dom";

const HoverDiv = styled.div`
:hover {
  background: ${props=>props.theme.bg_contrast_alpha};
}
`;


export function CommunitySettingsIcon({theme,community_handle}) {
    const history = useHistory();
    return <NewTooltip margin message="Community Settings">
        <HoverDiv onClick={()=>{
            history.push(`/${community_handle}/admin/settings`);
        }} theme={theme} style={{color:theme.bg_contrast_text}} className={`h-8 inline-flex px-1.5 space-x-1.5 items-center justify-center rounded-full hover:bg-gray-200 transition-colors cursor-pointer`}>
        <div className={`h-5 w-5`}>
        <CogIcon />
        </div>
    </HoverDiv>
    </NewTooltip>
}