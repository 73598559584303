// green: #00b234
// yellow: #f7ff72
// red: #9a281e
export const CC_community_color = '#ff6b00';


function rgbToYIQ({r, g, b}) {
    return ((r * 299) + (g * 587) + (b * 114)) / 1000;
}

function hexToRgb(hex) {
    if (!hex || hex === undefined || hex === '') {
        return undefined;
    }

    const result =
        /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

    return result ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
    } : undefined;
}

export function colorContrast(colorHex, threshold = 180) {
    if (colorHex === undefined) {
        return '#000';
    }

    const rgb = hexToRgb(colorHex);

    if (rgb === undefined) {
        return '#000';
    }

    return rgbToYIQ(rgb) >= threshold ? '#000' : '#fff';
}

function componentToHex(c) {
    var hex = c.toString(16);
    return hex.length == 1 ? "0" + hex : hex;
}

function rgbToHex(r, g, b) {
    return "#" + componentToHex(r) + componentToHex(g) + componentToHex(b);
}

export function hexToRgbStr(hex) {
    // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
    var shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
    hex = hex.replace(shorthandRegex, function (m, r, g, b) {
        return r + r + g + g + b + b;
    });

    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? `rgb(${parseInt(result[1], 16)},${parseInt(result[2], 16)},${parseInt(result[3], 16)})` : '';
}

export function setAlpha(color,a) {
    const cs = color.replace('rgb(', '');
    const cs2 = cs.replace(')', '');

    const split = cs2.split(',');
    return `rgba(${split[0]},${split[1]},${split[2]},${a})`;
}

export function buildSidebarTheme(primary,topbar_bg,body_bg='#f5f8fa') {

    const ctrst = colorContrast(primary);
    const ctrst_bg = colorContrast(topbar_bg);
    const ctrst_bg_body = colorContrast(body_bg);
    const is_dark = colorContrast === '#fff';
    return {
        color: primary,
        hover: setAlpha(hexToRgbStr(primary),0.1),
        hover_bold: setAlpha(hexToRgbStr(primary),0.3),

        bg: topbar_bg,
        bg_border: setAlpha(hexToRgbStr(ctrst_bg),is_dark?0.2:0.05),

        bg_contrast: ctrst_bg,
        bg_contrast_text: setAlpha(hexToRgbStr(ctrst_bg),is_dark?0.8:0.7),
        bg_contrast_alpha: setAlpha(hexToRgbStr(ctrst_bg),is_dark?0.2:0.075),
        bg_contrast_alpha_hover: setAlpha(hexToRgbStr(ctrst_bg),is_dark?0.4:0.2),

        body_bg: body_bg,
        body_border: setAlpha(hexToRgbStr(body_bg),0.1),

        body_text: setAlpha(hexToRgbStr(ctrst_bg_body),0.7),
        body_text_mid: setAlpha(hexToRgbStr(ctrst_bg_body),0.55),
        body_text_low: setAlpha(hexToRgbStr(ctrst_bg_body),0.4),

        contrast: ctrst,
        contrast_alpha: setAlpha(hexToRgbStr(ctrst),0.6)
    }
}

export const CC_hover_color = setAlpha(hexToRgbStr(CC_community_color),0.1);
export const CC_contrast_color = colorContrast(CC_community_color);
export const CC_contrast_color_alpha = setAlpha(hexToRgbStr(CC_contrast_color),0.6);