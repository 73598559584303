import React from 'react';
import styled from "styled-components";

const Wrapper = styled.div`
    max-width: 600px;
    width: 100%;
    background: #fff;
    margin-top: 10px;
    margin-bottom: 16px;
  margin-right: auto;
`;

const Header = styled.div`
        padding: 12px 16px 8px 16px;
        display: grid;
        grid-template-columns: 1fr auto;
`;

const Body = styled.div`
        padding: 0 16px 12px 16px;
`;

export default function CommonCard({children, subtitle, title, topper, actions}) {
    return <Wrapper className={`md:border ml-0 2xl:ml-auto border-gray-200 rounded-md ${(!title&&!actions)?"pt-3":""}`}>
        {(title||actions)&&<Header>
            <div className="flex-grow">
                {topper&&<div className='text-xs text-gray-500'>
                    {topper}
                </div>}
            {title&&<div className='text-base font-bold text-gray-900'>
                {title}
            </div>}
                {subtitle&&<div className={`text-sm text-gray-500`}>
                    {subtitle}
                </div>}

            </div>
            {actions&&<div className="flex space-x-2">
                {actions.map((act,k)=><div key={k}>
                    {act}
                </div>)}
            </div>}
        </Header>}
        <Body>
            {children}
        </Body>
    </Wrapper>
}