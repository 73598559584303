import React from "react";
import {
    AtSymbolIcon, CloudUploadIcon,
    PlusCircleIcon,
    TableIcon
} from "@heroicons/react/solid";

// contexts = user, member, admin, owner
export const us_all_quick_actions = [
    {
        label: 'Add Member',
        context: 'admin',
        icon: {
            type: 'icon',
            icon: <PlusCircleIcon />
        },
        onClick: (h,ctx) => {
            h.push(`/${ctx.community_handle}/directory?action=create`)
        }
    },

    {
        label: 'New Group',
        context: 'member',
        icon: {
            type: 'icon',
            icon: <PlusCircleIcon />
        },
        onClick: (h,ctx) => {
            h.push(`/${ctx.community_handle}/groups?action=create`)
        }
    },
    {
        label: 'New Segment',
        context: 'admin',
        icon: {
            type: 'icon',
            icon: <PlusCircleIcon />
        },
        onClick: (h,ctx) => {
            h.push(`/${ctx.community_handle}/admin/settings/member-segments`)
        }
    },
    {
        label: 'New Member Type',
        context: 'admin',
        icon: {
            type: 'icon',
            icon: <PlusCircleIcon />
        },
        onClick: (h,ctx) => {
            h.push(`/${ctx.community_handle}/admin/settings/member-types`)
        }
    },
    {
        label: 'Add Secondary Email',
        context: 'member',
        icon: {
            type: 'icon',
            icon: <AtSymbolIcon />
        },
        onClick: (h,ctx) => {
            h.push(`/${ctx.community_handle}/preferences`)
        }
    },
    {
        label: 'Export Members',
        context: 'admin',
        icon: {
            type: 'icon',
            icon: <TableIcon />
        },
        onClick: (h,ctx) => {
            h.push(`/${ctx.community_handle}/directory?action=export`)
        }
    },
    {
        label: 'Import Members',
        context: 'admin',
        icon: {
            type: 'icon',
            icon: <CloudUploadIcon />
        },
        onClick: (h,ctx) => {
            h.push(`/${ctx.community_handle}/admin/members/import`)
        }
    },
    /*
    {
        label: 'Send Message',
        context: 'member',
        icon: {
            type: 'icon',
            icon: <PaperAirplaneIcon />
        },
        onClick: (h,ctx) => {
            h.push(`/${ctx.community_handle}/home?action=send-message`)
        }
    },

     */
    {
        label: 'Create Community',
        context: 'user',
        icon: {
            type: 'icon',
            icon: <PlusCircleIcon />
        },
        onClick: (h,ctx) => {
            h.push(`/create-community`)
        }
    },
    {
        label: 'Add Admins',
        context: 'owner',
        icon: {
            type: 'icon',
            icon: <PlusCircleIcon />
        },
        onClick: (h,ctx) => {
            h.push(`/${ctx.community_handle}/admin/settings/admins`)
        }
    },
    {
        label: 'Add Custom Emoji',
        context: 'admin',
        icon: {
            type: 'icon',
            icon: <PlusCircleIcon />
        },
        onClick: (h,ctx) => {
            h.push(`/${ctx.community_handle}/admin/settings/customize/emojis`)
        }
    },
    {
        label: 'New Role',
        context: 'admin',
        icon: {
            type: 'icon',
            icon: <PlusCircleIcon />
        },
        onClick: (h,ctx) => {
            h.push(`/${ctx.community_handle}/leaders?action=create-role`)
        }
    }
];