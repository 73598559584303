import React, {useContext, useEffect, useState} from 'react';
import CommonCard from "../../../../library/components/old/card";
import {InterestField} from "../../../../library/components/old/interest-field";
import {authFetch} from "../../../../config/network";
import {GlobalContext} from "../../../global/global-context";
import {getSectionVisibilityNote} from "../../../../library/components/old/edit-fields-section";
import {ProfileFieldsContentSection} from "../../../../library/components/edit-profile-mobile";
import {useIsMobile} from "../../../global/global-router";

export function MemberViewInterests({interests,my_profile=false,visibility='all-members',member_interests={},updateParent,can_edit,community,id,cb}) {
    const global = useContext(GlobalContext);
    const is_mobile = useIsMobile();
    return <InterestField cmi={member_interests} my_profile={my_profile} can_edit={can_edit} layout='member' onSave={(p)=>updateParent({...p})} max={15} label={can_edit?"My Interests":""} interests={interests} />
}