import React, {useContext, useEffect, useState} from "react";
import {union, uniq} from "lodash";
import {Button} from "../../core";
import {getNestedValue} from "../../../utilities/get-nested-value";
import {GlobalContext} from "../../../../app/global/global-context";
import {CommunityContext} from "../../../../app/community/community-context";
import {authFetch} from "../../../../config/network";
import {Field} from "./fields";
import {
    AdjustmentsIcon,
    EyeIcon,
    EyeOffIcon,
    GlobeIcon,
    LockClosedIcon,
    SparklesIcon,
    UsersIcon
} from "@heroicons/react/solid";
import {canTryAutocompleteAddress} from "../../../../app/community/admin/member";
import {AutocompleteTest} from "../../../../app/community/admin/member/autocomplete-test";
import {ProfileFieldsContentSection} from "../../edit-profile-mobile";
import {useIsMobile} from "../../../../app/global/global-router";
import {getMemberProfileFlags} from "../person-profile";

function buildObj(arr) {
    let obj = {};

    for (let i = 0; i < arr.length; i++) {
        const item = arr[i];
        if (item.hidden) {
            continue;
        }
        obj[item.value] = item.text;
    }

    return obj;
}

export function getSectionVisibilityNote(vis, obj = false) {
    if (!vis) {
        return null;
    }
    if (vis === 'all-members') {
        if (obj) {
            return {
                icon: <UsersIcon/>,
                text: 'All members can see this section'
            }
        }
        return <div className="text-xs items-center pb-0.5 -mt-0.5 flex space-x-1.5 text-gray-600 opacity-80">
            <div className="w-3">
                <div className="w-3 h-3 text-secondary-700">
                    <UsersIcon/>
                </div>
            </div>
            <div>
                All members can see this section
            </div>
        </div>
    } else if (vis === 'admins-only') {
        if (obj) {
            return {
                icon: <AdjustmentsIcon/>,
                text: 'Only admins can see this section'
            }
        }
        return <div className="text-xs items-center pb-0.5 -mt-0.5 flex space-x-1.5 text-gray-600 opacity-80">
            <div className="w-3">
                <div className="w-3 h-3 text-purple-700">
                    <AdjustmentsIcon/>
                </div>
            </div>
            <div>
                Only admins can see this section
            </div>
        </div>
    } else if (vis === 'read-only') {
        if (obj) {
            return {
                icon: <EyeIcon/>,
                text: 'Members can view, only admins can edit'
            }
        }
        return <div className="text-xs items-center pb-0.5 -mt-0.5 flex space-x-1.5 text-gray-600 opacity-80">
            <div className="w-3">
                <div className="w-3 h-3 text-purple-700">
                    <EyeIcon/>
                </div>
            </div>
            <div>
                Only admins can edit this section
            </div>
        </div>
    } else if (vis === 'private') {
        if (obj) {
            return {
                icon: <LockClosedIcon/>,
                text: 'Only you and admins can see this section'
            }
        }
        return <div className="text-xs items-center pb-0.5 -mt-0.5 flex space-x-1.5 text-gray-600 opacity-80">
            <div className="w-3">
                <div className="w-3 h-3 text-success">
                    <LockClosedIcon/>
                </div>
            </div>
            <div>
                Only you and admins can see this section
            </div>
        </div>
    } else if (vis === 'system') {
        if (obj) {
            return {
                icon: null,
                text: ''
            }
        }
        return null;
    } else if (vis === 'user-public') {
        if (obj) {
            return {
                icon: <GlobeIcon/>,
                text: 'Visible for everyone'
            }
        }
        return null;
    } else if (vis === 'user-private') {
        if (obj) {
            return {
                icon: <LockClosedIcon/>,
                text: 'Only you can see this'
            }
        }
        return null;
    } else if (vis === 'hidden') {
        if (obj) {
            return {
                icon: <EyeOffIcon/>,
                text: 'No one can see this section'
            }
        }
        return <div className="text-xs items-center pb-0.5 -mt-0.5 flex space-x-1.5 text-gray-600 opacity-80">
            <div className="w-3">
                <div className="w-3 h-3 text-red-700">
                    <EyeOffIcon/>
                </div>
            </div>
            <div>
                No one can see this section
            </div>
        </div>
    }
}

function sectionHasUpdatedField(sec, updated) {
    const section_fields = sec.fields.map(d => d.field);

    return section_fields.filter(value => updated.includes(value)).length > 0;
}

export default function EditFieldsSection(props) {
    const global = useContext(GlobalContext);
    const community = useContext(CommunityContext);
    const is_mobile = useIsMobile();
    const {
        show_save = true, show_vis = true, metadata = {}, onSaved = () => {
        }
    } = props;

    const {custom_fields} = community;

    const {addToast} = global;

    const [data, setData] = useState(props.data);
    const [_editing_field, setEditingField] = useState('');
    const [_changes_made, setChangesMade] = useState(false);
    const [_loading, setLoading] = useState(false);
    const [_updated_fields, updateFieldsChanged] = useState([]);

    useEffect(function () {
        setData({...props.data});
    }, [props.data]);

    function shouldDisable(disabled, dc) {
        if (disabled) {
            return true;
        }
        if (dc === null) {
            return false;
        }
        let value = community[dc['f']];
        if (dc['o'] === '!==') {
            return value !== dc['v'];
        } else if (dc['o'] === '===') {
            return value === dc['v'];
        }
        return false;
    }

    function save(e) {
        if (e) {
            e.preventDefault();
        }
        const {endpoint, update_fields} = props;

        if (endpoint === '') {
            props.handleSave(data);
            setChangesMade(false);
            updateFieldsChanged([]);
            return;
        }


        if (_loading || !_changes_made) {
            return;
        }

        setLoading(true);

        let payload = {};

        if (community.uid) {
            payload.community_uid = community.uid;
        }

        if (community.member_id) {
            payload.member_id = community.member_id;
        }

        for (let i = 0; i < update_fields.length; i++) {
            const f = update_fields[i];

            payload[f] = data[f];
        }

        if (props.id) {
            payload.id = props.id;
        }

        payload._updated_fields = _updated_fields;

        if (payload._updated_fields.length === 0) {
            return;
        }

        authFetch(endpoint, handleResult.bind(this), handleError.bind(this), "POST", {payload});
    }

    function handleResult() {
        addToast({text: `Changes were saved.`, intent: 'success'});
        onSaved();
        setLoading(false);
        setChangesMade(false);
    }

    function handleError() {
        addToast({text: `Your changes could not be saved.`, intent: 'error'});
        setLoading(false);
        setChangesMade(false);
    }

    useEffect(function () {
        if (props.fieldsChanged) {
            props.fieldsChanged(_updated_fields);
        }
    }, [_updated_fields]);

    function hc(item, fid, val) {
        const sp = fid.split('.');
        let state = data;
        if (item.type === 'cover-photo') {
            state.cover_photo = val.url;
            state.cover_photo_color = val.color;
        } else if (item.type === 'profile-picture') {
            state.profile_picture = val.url;
            state.profile_picture_color = val.color;
        } else if (item.type === 'community-logo') {
            state.profile_picture = val.url;
            state.profile_picture_color = val.color;
        } else if (item.type === 'organization-category') {
            state[sp[0]].type = val.type;
            state[sp[0]].category = val.category;
        } else if (sp.length === 3) {
            state[sp[0]][sp[1]][sp[2]] = val;
        } else if (sp.length === 2) {
            state[sp[0]][sp[1]] = val;
        } else {
            state[sp[0]] = val;
        }
        setChangesMade(true);
        let uf = uniq(union(_updated_fields, [fid]));

        updateFieldsChanged([...uf]);

        if (item.hooks) {
            for (let i = 0; i < item.hooks.length; i++) {
                const hook = item.hooks[i];
                // this.transformHooks(hook, val);
            }
        }

        setData(state);

        if (props.endpoint === '') {
            props.handleSave(data);
        }
    }

    function onBlur(defer, fid) {
        setEditingField('');

        if (!defer) {
            save();
        } else {

            if (props.endpoint === '') {
                props.handleSave(data);
            }
        }
    }

    const profile_flags = getMemberProfileFlags({community: community.data});

    const {sections = [], time_format} = props;
    return (<div className="space-y-3 mt-3 max-w-2xl">
            {sections.map((sec) => {
                let cps = sec.custom_id ? custom_fields[sec.custom_id] : {};
                let c_actions = [];
                if (sec.title === 'Address' && data.address && canTryAutocompleteAddress(data.address)) {
                    c_actions.push(<div className="-m-0.5" key={'addressautoc'}><AutocompleteTest id={props.id}
                                                                                                  member={props.data}
                                                                                                  autocompleteAddress={(dt) => {
                                                                                                      props.autocompleteAddress(dt);
                                                                                                      const new_updated_fields = _updated_fields.filter(a => !a.startsWith('address.'));
                                                                                                      updateFieldsChanged(new_updated_fields);
                                                                                                  }}
                                                                                                  loading={props.parent_loading === 'autocomplete-address'}/>
                    </div>);
                }
                if (_changes_made && show_save && sectionHasUpdatedField(sec, _updated_fields)) {
                    c_actions.push(<div className="-m-1" key={'savebtn'}><Button onClick={(e) => {
                        save(e)
                    }} loading={_loading} text='Save' size='sm' intent='success'/></div>);
                }

                if (c_actions.length === 0) {
                    c_actions = null;
                }
                return <ProfileFieldsContentSection single_line={props.single_line} section={{autocomplete: []}}
                                                    layout={is_mobile ? '' : 'desktop'} visibility={sec.visibility}
                                                    actions={c_actions} title={sec.title} key={sec.title}>
                    {sec.fields.map((item, num) => {
                        const {
                            type,
                            placeholder = '',
                            edit_only = false,
                            field,
                            name,
                            defer = false,
                            disabled = false,
                            disable_condition = null,
                            options = []
                        } = item;
                        let final_options = options;
                        let cfd = field.includes('custom_fields') ? cps.field_data[field.split('.')[1]] : {};
                        if (type === 'select-email') {
                            final_options = item.options.choices.filter(function (a) {
                                return !a.hidden
                            });
                        } else if (type === 'multi-select') {
                            final_options = cfd.options.choices.filter(function (a) {
                                return !a.hidden
                            });
                        } else if (type === 'select') {
                            final_options = cfd.options.choices.filter(function (a) {
                                return !a.hidden
                            });
                        } else if (type === 'select-taxonomy') {
                            final_options = cfd.options;
                        } else if (type === 'multi-select-taxonomy') {
                            final_options = cfd.options;
                        }
                        const disable_field = shouldDisable(disabled, disable_condition);

                        const obj = buildObj(final_options);

                        return <div key={field} className={`${item.col_span === 2 ? "col-span-2" : ""}`}>
                            <Field placeholder={placeholder} num={num} member_types={community.member_types}
                                   user_uid={global.user_uid} member_id={community.member_id}
                                   community_uid={community.uid} options={final_options} key={field}
                                   edit={(fid) => {
                                       setEditingField(fid);
                                   }} obj={obj} blur={onBlur.bind(this, defer)}
                                   editing={_editing_field === field}
                                   type={type} edit_only={edit_only} name={name} field={field} disabled={disable_field}
                                   change={hc.bind(this, item)} meta={{time_format}}
                                   metadata={metadata}
                                   value={getNestedValue(field, data)}/>
                        </div>
                    })}
                </ProfileFieldsContentSection>;
            })}
        </div>
    );
}