import React, {useState} from 'react';
import styled from "styled-components";
import {Markdown} from "../components/core";

import SGTogglesPage from "../components/reorganize/pages/toggles";
import SGCardHeadingsPage from "../components/reorganize/pages/card-headings";
import SGSectionHeadingsPage from "../components/reorganize/pages/section-headings";
import SGPageHeadingsPage from "../components/reorganize/pages/page-headings";
import SGAlertsPage from "../components/reorganize/pages/alerts";
import SGModalsPage from "../components/reorganize/pages/modals";
import SGNotificationsPage from "../components/reorganize/pages/notifications";
import SGRadioPage from "../components/reorganize/pages/radio";
import SGListboxesPage from "../components/reorganize/pages/listboxes";
import SGMarkdownPage from "../components/reorganize/pages/markdown";
import SGEmailComposerPage from "../components/reorganize/pages/email-composer-page";
import SGInputsPage from "../components/reorganize/pages/inputs";
import SGAvatarsPage from "../components/reorganize/pages/avatars";
import SGBadgesPage from "../components/reorganize/pages/badges";
import SGBreadcrumbsPage from "../components/reorganize/pages/breadcrumbs";
import SGTabsPage from "../components/reorganize/pages/tabs";
import SGCardsPage from "../components/reorganize/pages/cards";
import SGCalloutsPage from "../components/reorganize/pages/callout";
import SGButtonsPage from "../components/reorganize/pages/buttons";
import SGCustomInputsPage from "../components/reorganize/pages/custom-inputs";
import SGListsPage from "../components/reorganize/pages/lists";
import SGColorsPage from "../components/reorganize/pages/colors";
import SGTypographyPage from "../components/reorganize/pages/typography";
import SGContextualActionsPage from "../components/reorganize/pages/contextual-actions";
import SGMetaPage from "../components/reorganize/pages/meta";
import SGInfoStates from "../components/reorganize/pages/info-states";
import SGInterestsPage from "../components/reorganize/pages/interests";
import SGMenuPanelsPage from "../components/reorganize/pages/menu-panels";
import SGCustomIconsPage from "../components/reorganize/pages/custom-icons";
import SGSimpleTablePage from "../components/reorganize/pages/simple-table";
import SGPinboardPage from "../components/reorganize/pages/pinboard";
import SGCustomPage from "../components/reorganize/pages/custom";
import SGIconography from "../components/reorganize/iconography";

export function SGSectionHeader({children}) {
    return <div className="pt-6 pb-2 font-semibold text-xl">
        {children}
    </div>
}

const menu = [
    {
        label: 'Styles',
        items: [
            {
                label: 'Colors',
                component: <SGColorsPage/>
            },
            {
                label: 'Typography',
                component: <SGTypographyPage/>
            },
            {
                label: 'Iconography',
                component: <SGIconography/>
            },
            {
                label: 'Custom Icons',
                component: <SGCustomIconsPage/>
            }
        ]
    },
    {
        label: 'Components',
        items: [
            {
                label: 'Pinboard',
                component: <SGPinboardPage/>
            },
            {
              label: "Custom",
              component: <SGCustomPage/>
            },
            {
                label: 'Toggles',
                component: <SGTogglesPage/>
            },
            {
                label: 'Info States',
                component: <SGInfoStates/>
            },
            {
                label: 'Listboxes',
                component: <SGListboxesPage/>
            },
            {
                label: 'Interests',
                component: <SGInterestsPage/>
            },
            {
                label: 'Menu Panels',
                component: <SGMenuPanelsPage/>
            },
            {
                label: 'Buttons',
                component: <SGButtonsPage/>
            },
            {
                label: 'Callout',
                component: <SGCalloutsPage/>
            },
            {
                label: 'Card',
                component: <SGCardsPage/>
            },
            {
                label: 'Tabs',
                component: <SGTabsPage/>
            },
            {
                label: 'Lists',
                component: <SGListsPage/>
            },
            {
                label: 'Breadcrumbs',
                component: <SGBreadcrumbsPage/>
            },
            {
                label: 'Badges',
                component: <SGBadgesPage/>
            },
            {
                label: 'Avatars',
                component: <SGAvatarsPage/>
            },
            {
                label: 'Contextual Actions',
                component: <SGContextualActionsPage/>
            },
            {
                label: 'Meta',
                component: <SGMetaPage/>
            }
        ]
    },
    {
        label: 'Form Elements',
        items: [
            {
                label: 'Inputs',
                component: <SGInputsPage/>
            },
            {
                label: 'Markdown',
                component: <SGMarkdownPage/>
            },
            {
                label: 'Listbox',
                component: <SGListboxesPage/>
            },
            {
                label: 'Radio',
                component: <SGRadioPage/>
            },
            {
                label: 'Custom',
                component: <SGCustomInputsPage/>
            },
            {
                label: 'Simple Table',
                component: <SGSimpleTablePage/>
            }
        ]
    },
    {
        label: 'Overlays',
        items: [
            {
                label: 'Notifications',
                component: <SGNotificationsPage/>
            },
            {
                label: 'Modals',
                component: <SGModalsPage/>
            },
            {
                label: 'Alerts',
                component: <SGAlertsPage/>
            },
            {
                label: 'Email Composer',
                component: <SGEmailComposerPage/>
            },
        ]
    },
    {
        label: 'Headings',
        items: [
            {
                label: 'Page Headings',
                component: <SGPageHeadingsPage/>
            },
            {
                label: 'Section Headings',
                component: <SGSectionHeadingsPage/>
            },
            {
                label: 'Card Headings',
                component: <SGCardHeadingsPage/>
            },
        ]
    }
];

export function StyleGuideHome() {
    const [tab, setTab] = useState("Form Elements-Inputs");
    const [tabData, setTabData] = useState({
        label: 'Inputs',
        component: <SGInputsPage/>
    });

    return <div className="flex flex-row">
        <div className="border-r h-screen overflow-y-auto w-80 p-8 border-gray-200 bg-gray-50 border-solid">
            <div className="mb-12">
                <div className="text-2xl font-bold mb-6">Styleguide</div>

                {menu.map(sec => <div key={sec.label}>
                    <div className="text-xs text-gray-500 font-semibold uppercase mb-1">{sec.label}</div>
                    <ul className="list-none space-y-1 text-md mb-8 leading-relaxed">
                        {sec.items.map(it => {
                            const id = `${sec.label}-${it.label}`;
                            const active = id === tab;
                            return <li key={it.label}><a href={`#${id}`} onClick={()=>{setTab(id);setTabData(it);}}
                                                         className={active?"text-emerald-800 font-semibold hover:text-emerald-800":"text-gray-500 hover:text-emerald-800"}>{it.label}</a>
                            </li>
                        })}
                    </ul>
                </div>)}


            </div>
        </div>
        <div className="relative py-16 px-24 flex-grow h-screen overflow-y-auto">
            {tabData&&<div className="text-3xl font-bold pb-4">{tabData.label}</div>}
            {tabData&&tabData.component}
        </div>
    </div>
}