import React, {useContext, useEffect, useRef, useState} from "react";
import {CommunityContext} from "../../../../../app/community/community-context";
import {EditProfileMobile} from "../../../edit-profile-mobile";
import {useIsMobile} from "../../../../../app/global/global-router";
import {communityGetSectionsToDisplay} from "../../../../../app/community/admin/member/edit-obj";
import {authFetch} from "../../../../../config/network";

export function ObScCustomFields({}) {
    const community = useContext(CommunityContext);
    const val = useRef();
    const is_mobile = useIsMobile();
    const [saving, setSaving] = useState(false);
    const [update, setUpdate] = useState({...community.member});
    const uf_ref = useRef([]);

    useEffect(function () {
        return () => {
            val.current = {
                ...update
            };
        }
    }, [update]);

    function handleSave(dt) {
        if (dt && community && community.member_id) {
            const res = () => null;

            const payload = {
                community_uid: community.uid,
                id: community.member_id,
                member_id: community.member_id,
                user_uid: community.member.user_uid,

                ...update,
                _updated_fields: [...uf_ref.current]
            }

            console.log("SAVE",payload)

           authFetch('/members/update-profile', res, res, "POST", {payload});
        }
    }

    useEffect(function () {
        return () => {
            handleSave(val.current);
        }
    }, []);

    const {
        display_social,
        display_custom,
        social_vis,
        career_vis,
        education_vis,
        interests_vis,
        display_career,
        display_interests,
        display_education
    } = communityGetSectionsToDisplay(community, false);


    const hidden = {
        about: true,
        contact: true,
        address: true
    };
    return <div className="grid">
        <EditProfileMobile
            hidden={hidden}
            important_fields={community.important_custom_fields}
            show_completeness={false}
            saving={saving}
            meta={{
                community_uid: community.uid,
                member_id: community.member_id
            }}
            onSaveChanges={() => handleSave(val.current)}
            onCancel={() => {

            }}
            display_sections={{display_custom}}
            layout={is_mobile ? "mobile" : "desktop"}
            init_member={update}
            type={"member"}
            metadata={{
                phone_services: {}, id: community.member.user_uid, update: (b, f, v) => {

                }
            }}
            phone_services={{phone_services: {}}}
            handleUpdate={(ne, fc) => {
                console.log("UPDATE", ne, fc);
                setUpdate({...ne});
                uf_ref.current = [...fc];
            }}
        />
    </div>
}