export function getInitials(name) {
    let str = '';
    const split = name.split(' ');
    for(let i=0;i<split.length;i++) {
        if(i===1) {
            break;
        }
        str += `${split[i].charAt(0).toUpperCase()}`;
    }
    return str;
}