import React, {useContext} from "react";
import {GlobalContext} from "../../global/global-context";
import {CIMembership} from "../../../library/icons/custom/Membership";
import styled from "styled-components";
import {CIUser} from "../../../library/icons/custom/User";
import {CILeaders} from "../../../library/icons/custom/Leaders";
import {CIHome} from "../../../library/icons/custom/Home";
import {CIDirectory} from "../../../library/icons/custom/Directory";

function MenuItem({active,theme,icon,label,onClick}) {
    return <div style={{minWidth:'3.6rem'}} onClick={onClick} className="px-2 py-0.5 rounded-md flex hover:bg-gray-100 flex-col items-center">
        <div className="pb-px">
            <MMIIcon active={active} theme={theme} default_color={`#72727b`} className="text-gray-500">
                {icon}
            </MMIIcon>
        </div>
        <div className={`font-medium text-gray-500 pt-px`} style={{color:active?theme.color:"",fontSize:'.7rem'}}>
            {label}
        </div>
    </div>
}

const MMIIcon = styled.div`
    display: flex;
    transition: 0.1s color ease;
    align-items: center;
    color: ${props => props.active ? `${props.theme.color}` : ''} !important;
   height: 24px;
   width: 24px;
     svg {
        height: 24px;
        .ci-fill {
    fill: ${props => props.active ? `${props.theme.hover}` : ''} !important;
    }
        .ci-strokefill {
    fill: ${props => props.active ? `${props.theme.color}` : '#72727b'} !important;
    }
    .ci-stroke {
    stroke: ${props => props.active ? `${props.theme.color}` : '#72727b'} !important;
    }
     }
`;

const items = {
  global: [
      {
          label: 'Memberships',
          icon: <CIMembership />,
          path: '/memberships'
      },
      {
          label: 'Profile',
          icon: <CIUser />,
          path: '/:handle'
      }
  ],
    community: [
        {
            label: 'Home',
            icon: <CIHome />,
            path: '/:community_handle/home'
        },
        {
            label: 'Directory',
            icon: <CIDirectory />,
            path: '/:community_handle/directory'
        },
        {
            label: 'Leaders',
            icon: <CILeaders />,
            path: '/:community_handle/leaders'
        },
        {
            label: 'Profile',
            icon: <CIUser />,
            path: '/:community_handle/member/:handle'
        },
        {
            label: 'Unaverse',
            icon: <CIMembership />,
            path: '/memberships'
        }
    ]
};

export function MobileNavbar({
                                 theme,
                                 user,
                                 handle,
                                 communities,
    member,
    location,
                                 community_handle,
    history,
                                 context="global"

                             }) {
    const global = useContext(GlobalContext);

    const menu_items = items[context];

    return <div className="fixed z-9999 bottom-0 bg-white left-0 right-0 border-t h-12">
        <div className="px-2 pb-px pt-0.5 flex space-x-1 items-center" style={{justifyContent:'space-evenly'}}>
            {menu_items.map((mi,k)=> {
                const hydrated_path = mi.path.replace(':handle', handle).replace(':community_handle',community_handle);
                return <MenuItem onClick={() => {
                    history.push(`${hydrated_path}`)
                }} active={location.pathname===hydrated_path} key={mi.path} theme={theme} icon={mi.icon} label={mi.label}/>
            })}
        </div>
    </div>
}