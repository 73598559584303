import React, {useState, useContext, useEffect} from 'react';
import {FirebaseDB} from "../../../config/setup-firestore";
import {Avatar, Button, EntityHeader, Pinboard, Tabs} from '../../../library/components/core';
import {
    convertEntityForLocalStorage,
    saveEntityToLocalStorage
} from "../../../library/utilities/localstorage/entity-localstorage";
import {authFetch} from "../../../config/network";
import {GroupAddEntities} from "./add-entities";
import {Link, useLocation} from "react-router-dom";
import {CommunityContext} from "../community-context";
import {GlobalContext} from "../../global/global-context";
import ProfileLoadingCard from "../../../library/components/layout/loading/profile";
import EntityNotFound from "../../../library/components/layout/non-ideal-states/not-found";
import GroupRecentFiles from "./widgets/recent-files";
import {reference_interests} from "../../data-references";
import {setDocumentTitle, setFavicon} from "../../../library/utilities/set-document-title";
import {copyTextToClipboard} from "../../../library/utilities/general/copy-to-clipboard";
import {GroupHeader} from "./components/header";
import {GroupFilesWrapper} from "./components/files-wrapper";
import {group_buildPinsExtras} from "./pins";
import {useIsMobile} from "../../global/global-router";
import {GroupInbox} from "./inbox";
import GroupRecentImages from "./widgets/recent-images";
import {GroupPostsSeriesPage} from "./series";
import {SimplePageGridDivider} from "./simple-page-divider";
import {GroupSettingsModal} from "./settings/modal";
import {getGroupAccess} from "./settings/modal-access-tab";
import {AddPinModal} from "./add-pin-modal";
import {ProfileCard} from "../../../library/components/reorganize/profile-card";
import {buildGroupCard} from "./utilities";
import {GroupMediaTab} from "./media";
import {ManageGroupQuickActions} from "./quick-actions";
import {AdvancedTableWrapper} from "../../../library/playground/advanced-table/wrapper";
import {GroupJoinSubscribe} from "../my-groups/group-join-subscribe";
import {PlusIcon} from "@heroicons/react/solid";
import {StandardPageFramework, StandardPageHeight} from "../../../library/playground/standard-page-framework";
import {DirectoryWrapper} from "./components/directory-wrapper";
import {buildNewPathWithURLParam} from "../directory";
import {InboxGroupActivityWrapper} from "../../../library/components/posts-feed/group-activity-wrapper";
import {APP_BASE_URL, CUSTOM_DOMAIN_DEFAULT} from "../../../config/defaults";
import {MemberApplicationsWidget} from "./widgets/applications";
import GroupStatsWidget from "./widgets/stats";
import {GroupDataQualitySuggestions} from "./actions/suggestions";

export function getMutualInterests(i1 = {}, i2 = {}) {
    const i1_arr = Object.keys(i1);
    const i2_arr = Object.keys(i2);
    const arr = i1_arr.filter(value => i2_arr.includes(value));

    return arr.map((id, i) => {
        return {
            id,
            data: reference_interests[id]
        }
    })
}

function arrayToSentence(arr) {
    if (arr.length === 1) {
        return <div className="text-gray-500">{arr[0]}</div>;
    }

    let last = arr.pop();

    const new_len = arr.length;

    return <div className="text-gray-500">
        {arr.map((it, k) => {
            return <>{it}{(k + 1) !== new_len ? "," : ""}&nbsp;</>
        })}
        and&nbsp;{last}
    </div>
}

export function displayInterests(arr, handle) {
    const len = arr.length;
    const items = arr.map((dt, k) => {
        const it = dt.data.name;
        const id = dt.id;
        const first = k === 0;
        const last = (k + 1) === len;
        const penultimate = len > 2 && k === len;

        let link = <Link className="text-gray-600" to={`/${handle}/interests/${id}`}>{it}</Link>;

        return link;
    });
    return arrayToSentence(items);
}

const paths = {
    "About": 'about',
    "Activity": 'activity',
    "Media": 'media',
    "Feed": 'feed',
    "Drive": 'drive',
    "Series": 'series',
    "Debug": 'debug',
    "Members": 'members'
};

const paths_reverse = {
    "about": 'About',
    "group_id": 'Activity',
    "media": 'Media',
    "feed": 'Feed',
    "series": "Series",
    "drive": 'Drive',
    "debug": 'Debug',
    "members": 'Members'
};

const callout_styles = {
    'danger': {
        pill: 'bg-red-500',
        text: 'text-gray-800'
    },
    'warning': {
        pill: 'bg-amber-500',
        text: 'text-gray-800'
    },
    'primary': {
        pill: 'bg-primary',
        text: 'text-gray-800'
    },
    'secondary': {
        pill: 'bg-blue-500',
        text: 'text-gray-800'
    },
};

export function SimpleCallout({message, action, intent}) {

    const {
        pill, text
    } = intent ? callout_styles[intent] : callout_styles['secondary'];

    return <div className="flex space-x-2 max-w-xl">
        <div className={`w-1.5 flex-none rounded-xl ${pill}`}/>
        <div className={`py-px text-sm ${text} font-medium`}>
            {message}
            {action && <div className="pt-2">
                {action}
            </div>}
        </div>
    </div>
}

function canMemberSendToList(email_list_power_up, is_member, is_moderator, is_admin) {
    if (is_admin) {
        return true;
    }

    if (!email_list_power_up) {
        if (is_member) {
            return true;
        } else {
            return false;
        }
    }

    const access_level = email_list_power_up.config.access_level;

    if (access_level === 'readonly' && is_moderator) {
        return true;
    } else if (access_level === 'members' && is_member) {
        return true;
    } else if (access_level === 'community' || access_level === 'public' || access_level === 'everyone') {
        return true;
    } else {
        return false;
    }
}

function getInitialTab(group_id, groups = {}) {
    const is_member = groups[group_id];
    return "Feed";
}


function memberIsInTeam(team, member_id) {
    return team.user_uids[member_id];
}

function getAccessItem(field, access, group, community, {is_member, is_moderator, is_admin}) {
    const member = community.member;
    const member_id = community.member_id;
    const all_roles = community.all_roles;
    const all_teams = community.all_teams;

    if (is_admin) {
        return true;
    }

    if (access.group_members && is_member) {
        return true;
    }

    if (access.moderators && is_moderator) {
        return true;
    }

    const member_teams = Object.entries(all_teams).filter(a => memberIsInTeam(a[1], member_id, all_roles))

    member_teams.forEach(a => {
        if (access?.teams?.[a[0]]) {
            return true;
        }
    });

    const member_roles = Object.entries(all_roles).filter(a => {
        return a[1].assignee === member_id
    });

    if (member_roles.length > 0) {
        if (access?.all_roles) {
            return true;
        }
    }

    if (access.member_types?.[member.member_type]) {
        return true;
    }

    return false;
}

export function calculateGroupAccess(group_access, data, community, ex) {
    let access_obj = {
        manage_drive: false,
        view_drive: false,
        reply_to_posts: false,
        post_to_feed: false
    };

    const keys = Object.keys(access_obj);

    keys.forEach(key => {
        access_obj[key] = getAccessItem(key, group_access[key], data, community, ex)
    })

    return access_obj
}

export function GroupPage(props) {
    const {history, match} = props;
    const [id, setId] = useState("");
    const location = useLocation();
    const global = useContext(GlobalContext);
    const community = useContext(CommunityContext);
    const member_interests = community.member.interests ? community.member.interests : {};

    const is_mobile = useIsMobile();
    const community_uid = community.uid;
    const is_admin = community.is_admin;
    const [loading, setLoading] = useState("group");
    const [initial_panel, setInitialPanel] = useState("");
    const [show_members, setShowMembers] = useState(false);
    const [suggested, setSuggested] = useState(null);
    const [init_members, setInitMembers] = useState('Members');
    const [removing_entity, setRemovingEntity] = useState(false);
    const [group_pins, setGroupPins] = useState([]);
    const [show_settings, showSettings] = useState(false);
    const [error, setError] = useState(null);
    const [checked_url, setCheckedUrl] = useState(false);
    // todo set based o nwho it is
    const [tab, setTab] = useState(getInitialTab(match.params.id, community.member.groups));
    const [data, setData] = useState(null);
    const [modal, setModal] = useState(null);
    const [modal_data, setModalData] = useState(null);


    useEffect(function () {
        if (!data || suggested) {
            return;
        }

        if (!suggested && data.type === 'standard') {

            FirebaseDB
                .collection('community_members')
                .doc(community.uid)
                .collection('members')
                .orderBy('created_at', 'desc')
                .limit(25)
                .get()
                .then(snap => {
                    let arr = [];
                    snap.docs.map(d => {
                        if (!data.user_uids[d.id]) {
                            arr.push({
                                ...d.data(),
                                id: d.id
                            })
                        }
                    })

                    setSuggested({
                        members: arr
                    })
                })
        } else if (!suggested && data.type === 'team') {
            FirebaseDB
                .collection('community_entities')
                .doc(community.uid)
                .collection('roles')
                .orderBy('created_at', 'desc')
                .limit(25)
                .get()
                .then(snap => {
                    let arr = [];
                    snap.docs.map(d => {
                        if (!data.entities.roles[d.id]) {
                            arr.push({
                                ...d.data(),
                                id: d.id
                            })
                        }
                    })

                    setSuggested({
                        roles: arr
                    })
                })
        }
    }, [suggested, data, id]);

    useEffect(function () {
        const sp = location.pathname.split(`/group/${match.params.id}`)[1].split('/');

        if (id !== match.params.id && match.params.id) {
            setId(match.params.id);
            setLoading("group");
            setSuggested(null);
            getGroup(() => {

            }, match.params.id);

            setInitialPanel('');
            setShowMembers(false);
            showSettings(false);
        }

        if (tab !== paths_reverse[sp[1]] && paths_reverse[sp[1]]) {
            setTab(paths_reverse[sp[1]]);
        }
    }, [location.pathname, id, tab, match.params.id]);

    function addPin(pin_id, pin_data, cb) {

        const payload = {
            community_uid: community_uid,
            pin_id,
            member_id: community.member_id,
            pin_data,
            id: id,
        };

        setModal(null);

        const res = () => {
            setTimeout(() => {
                getGroup(cb);
            }, 1000);

            global.addToast({text: 'Pin added', intent: 'success'});
        };

        const err = () => {
            global.addToast({text: 'Pin could not be added', intent: 'danger'});
        };

        authFetch("/groups/add-pin", res, err, "POST", {payload});
    }

    useEffect(function () {
        if (data && data.emoji) {
            setFavicon(data.emoji, true)
        }
        return () => {
            // set back
            setFavicon((community.data.profile_picture ? `${community.data.profile_picture}_small?alt=media` : '/favicon-32x32.png'));
        }
    }, [data]);

    useEffect(function () {
        if (!checked_url) {
            const urlParams = new URLSearchParams(window.location.search);
            const action = urlParams.get('action');
            if (action === 'add-members') {
// check if is group admin
                setModal('add-entities');
                history.push(window.location.pathname);
            }
            setCheckedUrl(true);
        }

        // todo set right tab

    }, []);

    function removeEntity(type, entity_id, cb, keep_members = false) {
        if (removing_entity) {
            return;
        }
        const payload = {
            community_uid: community_uid,
            id: id,
            member_id: community.member_id,
            entity_type: type,
            keep_members,
            entity_id: entity_id
        };

        const res = (result) => {
            getGroup(cb);
            setRemovingEntity(false);
        };

        setRemovingEntity(true);

        authFetch("/groups/remove-entity", res, res, "POST", {payload});
    }

    function getGroup(cb, nid) {

        if (!id && !nid) {
            return;
        }
        FirebaseDB
            .collection('community_entities')
            .doc(community.uid)
            .collection('groups')
            .doc(nid ? nid : id)
            .get()
            .then(doc => handleGroupData(doc.data(), cb));
    }

    function reorderRoles(new_order) {
        const payload = {
            id: id,
            community_uid: community.uid,
            member_id: community.member_id,
            roles: new_order
        };

        setData({
            ...data,
            roles_order: new_order
        })

        const res = () => {

        };

        const err = () => {

        };

        authFetch("/groups/arrange-roles", res, err, "POST", {payload});
    }

    function handleGroupData(data, cb) {
        if (!data) {
            setError('not-found');
            return;
        }
        const lse = convertEntityForLocalStorage(community_uid, 'groups', data);
        saveEntityToLocalStorage(community_uid, 'groups', match.params.id, lse);

        //setMutualInterests(getMutualInterests(data.interests, member_interests));
        setGroupPins(data.pins ? data.pins : []);
        setData(data);
        setLoading("");
        if (cb) {
            cb(data);
        }
    }

    if (error === 'not-found') {
        return <EntityNotFound type='group'/>;
    }

    if (loading === "group") {
        return <ProfileLoadingCard type="group"/>;
    }

    const moderators = data.moderators.user_uids;
    const moderators_len = data.moderators.user_uids.length;
    const is_member = data.user_uids[community.member_id];
    const can_manage = data.moderators.user_uids[community.member_id] || is_admin;
    const can_leave = data.entities.members[community.member_id] && (!moderators[community.member_id] || (moderators[community.member_id] && moderators_len > 1));

    function setCoverPhoto(url, color) {

        let payload = {
            url: url,
            community_uid: community_uid,
            member_id: community.member_id,
            id: id,
            color
        };

        const res = () => {
            setTimeout(function () {
                setData({...data, cover_photo: url, cover_photo_color: color});
                global.addToast({text: 'Cover Photo updated', intent: 'success'});
            }, 350)

        };
        authFetch('/groups/set-cover-photo', res, res, "POST", {payload});
    }

    const email_list_power_up = data['power_ups']['unaty__mailing_lists'];

    let gpins = group_pins ? group_pins : [];
    let gpins_data = data.pins_data ? data.pins_data : {};
    let pins = [];

    if (email_list_power_up) {
        pins.push({
            label: 'Email',
            items: [
                {
                    type: 'email',
                    value: `[${email_list_power_up.config.email_address.split('@')[0]}@](${email_list_power_up.config.email_address})`,
                    onClick: (e) => {
                        copyTextToClipboard(email_list_power_up.config.email_address);
                        global.addToast({text: "Copied to clipboard", intent: 'info'});
                    }
                }
            ]
        });
    }

    function addToGroup(member_ids, lcb) {
        if (!member_ids || member_ids.length === 0) {
            return;
        }

        const obj = {};

        member_ids.forEach(id => {
            obj[id] = true;
        })

        addEntities({members: obj}, () => {
            getGroup();
        })
    }

    function addEntities(p, cb = () => {
    }) {

        const payload = {
            entities: p,
            id: id,
            member_id: community.member_id,
            community_uid: community.uid
        };

        const res = () => {
            getGroup(cb);
        };

        authFetch("/groups/add-new-entities", res, res, "POST", {payload});
    }

    function addModerator(type, itid, cb, replace_member_mods) {
        const f_type = (type === 'member' || type === 'members') ? 'members' : 'roles';

        const payload = {
            community_uid: community_uid,
            member_id: community.member_id,
            group_id: id,
            type: f_type,
            id: itid,
            replace_member_mods: !!replace_member_mods
        };

        global.addToast({text: 'Adding moderator', intent: 'info'});
        let new_entities = {...data.entities};
        let new_mods = {...data.moderators};
        if (typeof itid === 'string') {
            new_mods[f_type][id] = true;
        } else {
            itid.forEach(_id => {
                new_mods[f_type][_id] = true;
                new_entities[f_type][_id] = true;
            })
        }
        const new_group = {
            ...data,
            entities: new_entities,
            moderators: new_mods,
        };
        setData(new_group);
        if (cb) {
            cb(new_group);
        }

        const res = (resp) => {
            if (resp && resp.data && resp.data.data) {
                setData({...resp.data.data});
            }
            global.addToast({text: 'Moderator added', intent: 'success'});
        };

        authFetch("/groups/add-moderator", res, res, "POST", {payload});
    }

    function removeModerator(type, itid, cb) {
        const payload = {
            community_uid: community_uid,
            member_id: community.member_id,
            group_id: id,
            type,
            id: itid
        };

        const f_type = (type === 'member' || type === 'members') ? 'members' : 'roles';

        global.addToast({text: 'Removing moderator', intent: 'info'});

        let new_mods = {...data.moderators};

        if (typeof itid === 'string') {
            delete new_mods[f_type][itid];
        } else {
            itid.forEach(_id => {
                delete new_mods[f_type][_id];
            })
        }

        const new_group = {
            ...data,
            moderators: new_mods
        };
        setData(new_group);

        const res = (resp) => {
            if (resp.data && resp.data.data) {
                setData({...resp.data.data});
            }
            global.addToast({text: 'Moderator removed', intent: 'success'});
        };

        authFetch("/groups/remove-moderator", res, res, "POST", {payload});
    }

    function handleRemoveModerator(type, itid) {
        removeModerator(type, itid);
    }

    function handleMakeRoleModerator(itid) {
        addModerator('roles', itid);
    }

    function handleMakeMemberModerator(itid) {
        addModerator('members', itid);
    }

    function addRolesAsModerators(items,replace_member_mods) {
        addModerator('roles', items, ()=>{}, replace_member_mods);
    }

    function removeMembersAsModerators(ids) {
        removeModerator('members', ids);
    }

    function handleOpenCreateMembers() {
        global.handleSetRightMenu('create-members', {
            onConfirmed: (ids) => {
                if (ids.length > 0) {
                    addToGroup(ids, () => {

                    });
                }
                global.handleCloseRightMenu();
            },
            flow: 'add-to-group',
            initial: []
        })
    }

    let group_interests = !data.interests ? [] : Object.keys(data.interests);

    if (group_interests.length > 0) {
        pins.push({
            label: 'Interests',
            items: group_interests.map(gi => {
                const it = reference_interests[gi];
                return {
                    type: 'interest',
                    active: !!member_interests[gi],
                    value: `[${it.name}](${gi})`,
                    onClick: (e) => {
                        history.push(`/${community.data.handle}/interests/${gi}`);
                    }
                };
            })
        });
    }

    const is_moderator = data.moderators.user_uids[community.member_id];

    const group_access = getGroupAccess(data);
    const group_my_access = calculateGroupAccess(group_access, data, community, {is_admin, is_member, is_moderator});
    // -224px
    const {view_drive, manage_drive, post_to_feed, reply_to_posts} = group_my_access;

    const new_pins = group_buildPinsExtras(pins, gpins, gpins_data, history, global.addToast);

    setDocumentTitle(`${data.name}`, `${community.data.name}`);

    let tabs = ["Feed"];

    if (community.member_access.manage_members) {
        tabs.push("Activity");
    }
    tabs.push("Media");
    if (is_member) {
        tabs.push("Drive");
    }

    const can_send = canMemberSendToList(email_list_power_up, is_member, is_moderator, is_admin);

    let content = null;

    const _actions = {
        launchAddMembers: () => setModal('add-entities')
    };

    const group_join_link = `${APP_BASE_URL}/${community.data.handle}/sign-in?group=${id}`;

    if (location.pathname.includes(`group/${id}/directory`)) {
        return <StandardPageHeight content full id="directory">
            <div className="relative">
                <div className="">
                    <EntityHeader actions={<Button intent="secondary" minimal left_icon={<PlusIcon/>}
                                                   onClick={() => handleOpenCreateMembers()}
                                                   text="Create Member"
                                                   size="sm"/>} single goBackTo={() => {
                        history.push(`/${community.data.handle}/group/${id}`)
                    }} title={"Back to Group"}/>
                </div>
                <DirectoryWrapper data={data} group_id={id} ls_key={'group-directory'}
                                  can_manage={community.member_access.manage_members}
                                  view_type="group"/>

            </div>
        </StandardPageHeight>
    }

    if (tab === 'Series') {
        content = <GroupPostsSeriesPage is_admin={is_admin} is_moderator={is_moderator} is_mobile={is_mobile}
                                        is_member={is_member} series_id={location.pathname.split("/")[5]} group_id={id}
                                        community_uid={community.uid}
                                        community={community}
                                        group={data}
        />
    } else if (tab === 'Drive') {
        content = <div className="pt-1">
            <GroupFilesWrapper view_drive={view_drive} manage_drive={manage_drive} {...props}
                               is_moderator={is_moderator} is_member={is_member} group={data} id={id}/>
        </div>;
    } else if (tab === 'Media') {
        content = <div>
            <GroupMediaTab community_uid={community_uid} history={history}
                           community={community}
                           group_id={id}/>
        </div>;
    } else if (tab === 'Feed') {
        content = <GroupInbox _actions={_actions} folder_id={data.folder_id} email_list_power_up={email_list_power_up}
                              data={data} id={id} can_send={can_send}
                              is_member={is_member}/>;
    } else if (tab === 'Activity') {
        content = <InboxGroupActivityWrapper _actions={_actions}
                                             data={data} id={id} can_send={can_send}
                                             is_member={is_member}/>;
    }

    const right_sidebar = <>
        {can_manage&&<MemberApplicationsWidget community_uid={community_uid} group_id={id} />}

        {can_manage&&<GroupDataQualitySuggestions group={data} community_uid={community_uid} group_id={id} />}

        {can_manage && <div key="quick-actions">
            <ManageGroupQuickActions createMember={handleOpenCreateMembers}
                                     copyJoinLink={()=>{
                                         copyTextToClipboard(group_join_link);
                                         global.addToast({intent:'info',text:'Link copied to clipboard'})
                                     }}
                                     inviteMembers={() => {
                                         history.push(`/${community.data.handle}/group/${id}/directory?action=invite`)
                                     }}
                                     manageAccess={() => {
                                         setModalData("Access");
                                         showSettings(true);
                                     }}
                                     exportMemberList={() => {
                                         history.push(`/${community.data.handle}/group/${id}/directory?action=export`)
                                     }}
                                     openGroupDirectory={() => {
                                         history.push(`/${community.data.handle}/group/${id}/directory`)
                                     }}/>
        </div>}

        {can_manage&&<GroupStatsWidget group={data} community_uid={community.uid} group_id={id} />}

        <div key="pinboard">
            <Pinboard show_empty title="Pinboard" community_uid={community.uid} can_add_pin={can_manage}
                      onAddPin={() => {
                          setModal('add-pin');
                      }} pins={new_pins}/>
        </div>

        {is_member &&
        <GroupRecentFiles key={"recent-files"} community_uid={community_uid} history={history} community={community}
                          group_id={id}
                          folder_id={data.folder_id}/>}

        {is_member &&
        <GroupRecentImages key={"recent-images"} community_uid={community_uid} history={history}
                           community={community}
                           group_id={id}/>}

    </>;

    let actions = {
        setCoverPhoto: setCoverPhoto.bind(this),
    };

    let features = {
        edit_profile_picture: false,
        edit_cover_photo: true,
        can_manage: community.is_admin,
        openMembers: () => {
            // todo
        }
    };

    let new_card = buildGroupCard(data, features);

    if (can_send && email_list_power_up) {
        //
    } else if (!email_list_power_up && can_manage && !is_mobile) {
        new_card.actions.push(<Button text="Mailing List" onClick={() => {
            setModalData("Email List");
            showSettings(true);
        }} icon={<PlusIcon/>} intent="secondary" minimal/>);
    }

    if (is_member || (!data.closed && data.type !== 'team')) {
        new_card.actions.push(<GroupJoinSubscribe id={id} member={community.member} community_uid={community.uid}
                                                  member_id={community.member_id} group={data}/>);
    }
    // backgroundColor:"rgba(255,255,255,0.9)",backdropFilter:'blur(10px)'
    return <StandardPageFramework content sidebar={right_sidebar}>
        <GroupHeader is_mobile={is_mobile} can_manage={can_manage} addEntities={addEntities}
                     has_group_email={!!email_list_power_up}
                     removeModerator={handleRemoveModerator}
                     onOpenAddEntities={() => {
                         setModal('add-entities')
                     }}
                     addRolesAsModerators={addRolesAsModerators}
                     handleMakeMemberModerator={handleMakeMemberModerator}
                     updateRolesOrder={reorderRoles}
                     removeMembersAsModerators={removeMembersAsModerators}
                     handleMakeRoleModerator={handleMakeRoleModerator}
                     onToggleMenu={(init_panel) => {
                         showSettings(!show_settings);
                         setInitialPanel(init_panel);
                     }} is_member={is_member} removeEntity={removeEntity} is_moderator={is_moderator}
                     is_admin={is_admin}
                     data={data} id={id} tab={tab}
        />
        <ProfileCard context={community} editing={false} actions={actions} data={new_card}
                     features={features}/>
        <div className="sticky px-4 border-b border-gray-200 pt-1.5 top-0 bg-white z-10">
            <div className="-mb-px">
                <Tabs onSelect={t => {
                    const p = paths[t];
                    setTab(t);
                    history.push(`/${community.data.handle}/group/${id}/${p}`)
                }} active={tab} tabs={tabs} compact layout="entity"/>
            </div>
        </div>
        <div id="message-content">
            {content}
        </div>

        {modal === 'add-pin' && <AddPinModal
            pins={group_pins}
            onClose={() => setModal(null)}
            onConfirm={addPin} pins_data={gpins_data}/>}

        {modal === 'add-entities' &&
        <GroupAddEntities group_id={id} handleSuccess={() => {
            getGroup();
            setModal(null);
        }} existing={data.entities} suggested={suggested ? suggested : {}} open type={data.type}
                          closeModal={() => setModal(null)}
                          data={data} context={{community_uid, community, member_id: community.member_id, is_admin}}
                          id={id}/>}

        {show_settings && !is_mobile && <GroupSettingsModal id={id} onClose={() => showSettings(false)} setData={nd => {

            setData({...data, ...nd});
        }} getGroup={() => getGroup(() => {
        }, id)} is_moderator={is_moderator} is_admin={is_admin} is_member={is_member} group_data={data}
                                                            group_type={data.type}
                                                            initial_tab={modal_data ? modal_data : "About"}/>}
    </StandardPageFramework>

}
