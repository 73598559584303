import React, {useContext} from 'react';
import {CreateRoleController} from "../create-entities/roles/create";
import {CommunityContext} from "../community-context";
import {useHistory} from "react-router-dom";

export function CreateRoleWrapper({onClose,onConfirmed=()=>{},initial=[]}) {
    const community = useContext(CommunityContext);
    const history = useHistory();

    return <CreateRoleController onConfirmed={onConfirmed} initial={initial} handle={community.handle} context={{
        is_admin: community.is_admin,
        member_id: community.member_id,
        type: 'role',
        user_uid: community.member.user_uid,
        community_uid: community.uid,
        handle: community.handle
    }} closeModal={() => onClose()} history={history} open={open}/>
}