export const members_headers = [
    {label: "Member ID", key: "id", field: 'id', type: 'string'},
    {label: "Name", key: "name", field: 'name', type: 'string'},
    {label: "Account Email", key: "account_email", field: 'account_email', type: 'email'},
    {label: "Handle", key: "handle", field: 'handle', type: 'string'},
    {label: "Member Type", key: "member_type", field: 'member_type', type: 'member-type'},
    {label: "Bio", key: "bio", field: 'about.bio', type: 'string'},
    {label: "Title", key: "title", field: 'about.title', type: 'string'},
    {label: "First Name", key: "first_name", field: 'about.first_name', type: 'string'},
    {label: "Middle Name", key: "middle_name", field: 'about.middle_name', type: 'string'},
    {label: "Last Name", key: "last_name", field: 'about.last_name', type: 'string'},
    {label: "Birthday", key: "birthday", field: 'birthday', type: 'birthday'},
    {label: "Email", key: "email", field: 'contact.email', type: 'email'},
    {label: "Phone", key: "phone", field: 'contact.phone', type: 'phone'},
    {label: "Secondary Phone", key: "secondary_phone", field: 'contact.secondary_phone', type: 'phone'},
    {label: "Address Line One", key: "line_one", field: 'address.line_one', type: 'string'},
    {label: "Line Two", key: "line_two", field: 'address.line_two', type: 'string'},
    {label: "City", key: "city", field: 'address.city', type: 'string'},
    {label: "ZIP Code", key: "zip_code", field: 'address.zip_code', type: 'string'},
    {label: "State", key: "state", field: 'address.state', type: 'string'},
    {label: "Country", key: "country", field: 'address.country', type: 'country'},
    {label: "Join Date", key: "join_date", field: 'join_date', type: 'semantic-date'},
    {label: "Timezone", key: "timezone", field: 'preferences.timezone', type: 'timezone'},
    {label: "Deactivated", key: "archived", field: 'archived', type: 'boolean'},
   // {label: "Suspended", key: "suspended", field: 'suspended', type: 'boolean'},
    {label: "Sessions", key: "sessions", field: 'stats.sessions', type: 'number'},

    {label: "Website", key: "website", field: 'social.website', type: 'string'},
    {label: "Facebook", key: "facebook", field: 'social.facebook', type: 'string'},
    {label: "Twitter", key: "twitter", field: 'social.twitter', type: 'string'},

    // todo should be optional
    {label: "Eth Address", key: "eth_address", field: 'social.eth_address', type: 'string'},
    {label: "Reddit", key: "reddit", field: 'social.reddit', type: 'string'},
    {label: "Github", key: "github", field: 'social.github', type: 'string'},
    {label: "Skype", key: "skype", field: 'social.skype', type: 'string'},
    {label: "Instagram", key: "instagram", field: 'social.instagram', type: 'string'},
    {label: "Discord", key: "discord", field: 'social.discord', type: 'string'},
];