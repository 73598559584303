import React, {useContext} from "react";
import {NewTooltip} from "../../../../../../library/components/reorganize/new-tooltip";
import {ProfileBadge} from "../../../../../../library/components/profile-badge";
import {copyTextToClipboard} from "../../../../../../library/utilities/general/copy-to-clipboard";
import {GlobalContext} from "../../../../../global/global-context";

export function IdBadge({member_id}) {
const global = useContext(GlobalContext);
    return <NewTooltip message="Click to copy">
        <ProfileBadge onClick={()=>copyTextToClipboard(member_id,()=>{
            global.addToast({text:'Copied to clipboard!',intent:'info'});
        })} type="text" text={<span className="font-mono opacity-80">{member_id}</span>} />
    </NewTooltip>
}