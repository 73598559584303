import React, {useEffect, useLayoutEffect, useRef, useState} from 'react';
import {LocalSearchBar} from "../custom/local-search-bar";
import {GeneralItemIcon} from "../universal-search/components";
import {DEFAULT_USER_PHOTO} from "../../../config/defaults";
import InfiniteScrollWrapper from "./infinite-scroll-wrapper";
import {cleanQuery} from "../reorganize/entity-search/new-entity-search";
import {us_asyncSearch, us_getAsyncSearches} from "../universal-search/async-search";
import {us_handleAsyncItemsUpdate} from "../universal-search";
import {MobileDirectorySearch} from "./search";
import {merge} from 'lodash';
import {useHistory} from "react-router-dom";
import {singleMembersQuery} from "./load-members";
import useInfiniteScroll from "../../playground/advanced-table/use-infinite-scroll";

function Item({label = "", index, icon, handle, base, history, style, onClick, profile_picture}) {

    return <div onClick={() => {
        history.push(`${base}${handle}`)
    }} className="py-1.5 space-x-2.5 px-3 rounded-md flex" style={style}>
        <div className="flex-none">
            <GeneralItemIcon size="md" type={icon.type} url={icon.url}/>
        </div>
        <div className="flex-grow flex flex-col justify-center">
            <div className="text-base truncate font-bold text-gray-800">
                {label}
            </div>
            <div className="text-xssm line-clamp-2 text-gray-500">
                @{handle}
            </div>
        </div>
        <div>

        </div>
    </div>
}

function getFinalMembers(docs, default_member_profile_picture, gid) {
    const items = docs.map((d) => {
        const data = d.data();
        return ({
            ...data,
            icon: {
                type: 'image',
                url: data.profile_picture ? `${data.profile_picture}_small?alt=media` : `${default_member_profile_picture}_small?alt=media`
            },
            handle: data.handle,
            label: data.name
        })
    });
    if (!gid) {
        return items;
    } else {
        return items.sort((a, b) => a.about.last_name < b.about.last_name ? -1 : 1);
    }
}

const page_size = 25;

// todo with test community
export function NewMobileDirectory({
                                       display = 'infinite',
                                       autoFocus = false,
                                       group_id = "",
                                       overflow = true,
                                       is_group = true,
                                       community,
                                       community_uid = ""
                                   }) {
    const default_member_profile_picture = community.default_member_profile_picture ? community.default_member_profile_picture : DEFAULT_USER_PHOTO;
    const [focus, setFocus] = useState(false);
    const [query, setQuery] = useState('');
    const [completed_queries, setCompletedQueries] = useState({});
    const [items, setItems] = useState({});
    const history = useHistory();

    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(0);
    const has_more = useRef(false);
    const [last_page_ref, setLastPageRef] = useState(null);

    const scroll_id = "main-content";
    const [isFetching, setIsFetching] = useInfiniteScroll("infinite", scroll_id, {
        padding: 400,
        container: true
    }, () => {
        console.log("IS",isFetching,loading,has_more.current)
        if (!loading && has_more.current) {
            handleNextPage();
        }
    });

    function handleNextPage() {
        setPage(page + 1);
        _loadNextPage()
    }

    const vl = useRef();
    const cq_vl = useRef();

    const [data, setData] = useState([]);

    const [fn_items, setFnItems] = useState({});
    const [ln_items, setLnItems] = useState({});
    const [hn_items, setHnItems] = useState({});
    const [em_items, setEmItems] = useState({});


    useEffect(function () {
        cq_vl.current = completed_queries;
    }, [completed_queries]);

    useEffect(function () {
        vl.current = items;
    }, [items]);

    useEffect(function () {
        _loadNextPage();
    }, [])

    useEffect(function () {
        const cleaned = cleanQuery(query);
        if (cleaned) {
            const searches = us_getAsyncSearches(query, ['member-directory'], {members: true});
            if (!searches.async) {
                return;
            }

            for (let i = 0; i < searches.queries.length; i++) {
                const sq = searches.queries[i];
                const query_id = [`${sq.type}-${searches.final_query}`];

                if (!cq_vl.current[query_id] && searches.final_query) {
                    setCompletedQueries({
                        ...cq_vl.current,
                        [`${sq.type}-${sq.field}-${searches.final_query}`]: 'loading'
                    });

                    us_asyncSearch(searches.final_query, community_uid, sq, cleaned, {group_id})
                        .then(resp => {

                            let new_items = us_handleAsyncItemsUpdate({}, resp, resp.field);

                            setCompletedQueries({
                                ...cq_vl.current,
                                [`${resp.type}-${resp.field}-${searches.final_query}`]: 'complete'
                            })

                            if (resp.field === 'first_name') {
                                setFnItems(new_items)
                            } else if (resp.field === 'email') {
                                setEmItems(new_items)
                            } else if (resp.field === 'last_name') {
                                setLnItems(new_items)
                            } else {
                                setHnItems(new_items)
                            }
                        });
                }
            }
        }
    }, [query])

    function _loadNextPage(...args) {
        setLoading(true);
        singleMembersQuery(community_uid, page_size, group_id, last_page_ref)
            .then(snap => handleSnap(snap))
    }

    function handleSnap(snap) {
        const len = snap.docs.length;
        let hnp = len === page_size;
        let lir = snap.docs[(len - 1)];

        setData([...data].concat(
            getFinalMembers(snap.docs, default_member_profile_picture, group_id)
        ))

        has_more.current = hnp;
        setLastPageRef(lir);
        setLoading(false);
        setIsFetching(false);
    }

    const merged = merge(hn_items, ln_items, fn_items, em_items);

    return <div className={`${!group_id ? "flex-grow" : ""}`}>
        {!group_id && <div className="px-3 py-3 flex-none">
            <LocalSearchBar autoFocus={autoFocus} onFocus={() => setFocus(true)} onBlur={() => setFocus(false)}
                            onChange={q => {
                                setQuery(q);
                            }}/>
        </div>}
        <div style={{minHeight: '1px'}}>
            {!query && display === 'infinite' && data.map(it => {
                return <Item key={it.id} base={`/${community.data.handle}/member/`} history={history} {...it} />
            })}
            {!query && display === 'static' && <div className="" style={{minHeight: '1px'}}>
                {data.map(it => {
                    return <Item key={it.id} base={`/${community.data.handle}/member/`} history={history} {...it} />
                })}
            </div>}
            {(query) && <MobileDirectorySearch
                list_item={<Item base={`/${community.data.handle}/member/`} history={history}/>} items={merged}
                query={query} community_uid={community_uid} completed_queries={completed_queries}/>}
        </div>
    </div>
}