import React, {useContext, useEffect, useState} from 'react';
import {SimpleMetricsItem} from "./simple";
import {ProfileBadge} from "../profile-badge";
import {CommunityContext} from "../../../app/community/community-context";
import {FirebaseDB} from "../../../config/setup-firestore";
import {getQualityLevel} from "../../utilities/profile-quality-calculator";

function getDates() {
    let d = new Date();
    d.setDate(d.getDate() - 1);
    const mm = d.getUTCMonth();
    const dd = d.getUTCDate();
    const yy = d.getUTCFullYear();
    return {
        mm, dd, yy
    }
}

const mock = {
    version: 1,

    month: '07',
    date: '21',
    year: '2020',

    total_members: 267,
    total_members_delta: -57,

    avg_profile_completeness_delta: 0,
    avg_profile_completeness: 0,

    active_last_24_hours: 0,
    active_last_24_hours_delta: 0,

    updated_profile_this_week: 0,

    active_last_30_days: 0,
    active_last_30_days_delta: 0,

    active_last_7_days: 0,
    active_last_7_days_delta: 1,

    avg_profile_quality: 94,
    avg_profile_quality_delta: 2,

    created_at: Date.now(),

    avg_age: 0,
    member_types: {},
    groups: {
        // id: avg_profile_completeness, avg_profile_quality
    },
    cities: {},
    countries: {}
};

export function CommunityDashboard(props) {
    const {
        type = 'community',
        id
    } = props;
    const community = useContext(CommunityContext);
    const [loading,setLoading] = useState('');
    const [error,setError] = useState('');
    const [data,setData] = useState(null);

    useEffect(function() {
        getStats();
    }, [community.uid]);

    function getStats() {
        const {mm, dd, yy} = getDates();

        FirebaseDB
            .collection('communities')
            .doc(community.uid)
            .collection('insights')
            .orderBy('created_at','desc')
            .limit(1)
            .get()
            .then(snap => {
                if(snap.empty) {
                    setError('not-found');
                    setLoading('');
                    return;
                }
                const doc = snap.docs[0];
                const data = doc.data();
                setData(data);
                setLoading('');
            })
    }

    if(!data) {
        return null;
    }

    const group_data = !data.groups[id] ? null : data.groups[id];

    if(type==='group') {

        if(!group_data) {
            return null;
        }
        const {
            avg_profile_quality,
            avg_profile_quality_delta=0
        } = group_data;
        return <div className="border-t border-gray-200">
            <SimpleMetricsItem
                number={avg_profile_quality}
                delta={avg_profile_quality_delta/avg_profile_quality}
                tooltip_text="Average data quality across all members"
                title="Data Quality"
                custom={<div className="-mx-1.5"><ProfileBadge type={getQualityLevel(avg_profile_quality)}/></div>}
                // action={<a className="color-link text-xs">Improve</a>}
            />
        </div>
    }

    const { total_members, total_members_delta, avg_profile_quality, avg_profile_quality_delta, active_last_30_days, active_last_30_days_delta } = data;

    return <div>
        <div className="flex-grow pb-1.5 flex items-end text-base text-gray-800 font-semibold tracking-tight">
            This month
        </div>
        <div className="grid grid-cols-2 md:grid-cols-3 divide-x divide-gray-200 border-t border-gray-200">
            <SimpleMetricsItem
                number={avg_profile_quality}
                delta={avg_profile_quality_delta/avg_profile_quality}
                tooltip_text="Average data quality across all members"
                title="Data Quality"
                custom={<div className="-mx-1.5"><ProfileBadge type={getQualityLevel(avg_profile_quality)}/></div>}
               // action={<a className="color-link text-xs">Improve</a>}
            />
            <SimpleMetricsItem
                number={active_last_30_days}
                tooltip_text="Logins over the last 30 days"
                delta={active_last_30_days_delta/active_last_30_days}
                title="Active Members"
                description="Members that logged in"
            />
            <SimpleMetricsItem
                tooltip_text="Not including archived members"
                number={total_members}
                delta={total_members_delta/total_members}
                description={total_members_delta?`${Math.abs(total_members_delta)} ${total_members_delta>0?"more":"less"} member${Math.abs(total_members_delta)===1?"":"s"} than last month`:""}
                title="Total Members"
            />
        </div>
    </div>
}