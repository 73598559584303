import React from 'react';
import styled from "styled-components";

const Wrapper = styled.div`
display: grid;
grid-template-columns: 1fr auto;
    user-select: none;
    > div {
        
    }
`;

const Right = styled.div`
display: flex;
> div, > span {
margin-left: 6px;
}
`;

export function ATOptionsBar({children,left}) {

    return <Wrapper className=''>
        <div className="">
            {left}
        </div>
        <Right className="">
            {children}
        </Right>
    </Wrapper>
}