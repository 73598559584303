import React, {useContext, useEffect, useRef, useState} from 'react';
import {replaceUmlaute} from "../../../utilities/sanitize-handle";
import {us_getResultsLength, us_getSelectedItem, us_handleAsyncItemsUpdate} from "../../universal-search";
import {us_asyncSearch, us_getAsyncSearches, us_getQueryMeta} from "../../universal-search/async-search";
import {EntitySearchInput} from "./search-input";
import {us_buildDataTypeResults, us_buildQuickSearches} from "../../universal-search/build-results";
import {USItem, USPopupContainer, USResultsSection} from "../../universal-search/components";
import {Popover2} from "@blueprintjs/popover2";
import {InlineLoader} from "../../core";
import {CommunityContext} from "../../../../app/community/community-context";

export function cleanQuery(str) {
    return replaceUmlaute(str).trim().replace(/ /g, '-').toLowerCase();
}

function sanitizeValue(value, multi, context) {
    if (multi) {
        return value ? value : [];
    }
    return !value || value.value === '' ? null : {...value, community_uid: context.community_uid}
}

function handleItemAddNoteAndDisabled(it, excluded, excluded_message, already_added) {
    if (excluded.includes(it.data_id)) {
        return {
            ...it,
            disabled: true,
            note: excluded_message
        }
    } else if (already_added.includes(it.data_id)) {
        return {
            ...it,
            disabled: true,
            note: "Already added"
        }
    } else {
        return {
            disabled: false,
            ...it
        }
    }
}

function handleAddNoteAndDisabled(items, excluded_message, excluded = [], already_added = []) {
    return items.map(a => handleItemAddNoteAndDisabled(a, excluded, excluded_message, already_added)).map((a, i) => {
        return {
            ...a
        }
    }).sort(function(x,y) {
        return (x.disabled === y.disabled) ? 0 : x.disabled ? -1 : 1;
    });
}

function handleAddQSNoteDisabled(qs, all_excluded, excluded_message, all_already_added = {}) {

    return qs.map(a => {
        const t = `${a.data_type}s`;
        const ex = all_excluded[t] ? Object.keys(all_excluded[t]) : [];
        const aa = all_already_added[t] ? Object.keys(all_already_added[t]) : [];
        return handleItemAddNoteAndDisabled(a, ex, excluded_message, aa)
    }).sort(function(x,y) {
        return (x.disabled === y.disabled) ? 0 : x.disabled ? 1 : -1;
    });
}

function TextItem({text = "", show_loader = false}) {
    return <div className="py-1.5 space-x-3 h-10 items-center px-4 flex text-sm text-gray-600">
        {show_loader && <div>
            <InlineLoader mini padding={""}/>
        </div>}
        <div>{text}</div>
    </div>
}

function getAllowedData(dt, allowed, type) {
    if (!allowed) {
        return dt;
    }
    let final = {...dt};
    const keys = Object.keys(dt);
    keys.forEach(key => {
        if (!allowed[key]) {
            delete final[key];
        }
    });
    return final;
}

function miniTransformMember(id, data) {
    return {
        ...data,
        match_str: `${data.first_name} ${data.last_name} ${data.handle} ${data.email}`,
        id: id,
        data_type: 'member'
    }
}

function getTransformedMembers(arr) {
    let new_obj = {};
    arr.forEach((it) => {
        new_obj[it.id] = miniTransformMember(it.id, it);
    })
    return new_obj;
}

function buildFinalResults(query, search_ctx, excluded_message, qs_results = {}, items = {}, ctx_id, excluded, already_added, allowed = {}, defaults = {}) {
    // only one section here
    let sections = [
        {
            id: `all-results`,
            title: null,
            items: []
        }
    ];
    let count = 0;
    let qa = [], qs = [], rc = [], nv = [];

    const meta = us_getQueryMeta(query);

    const result_types = Object.keys(items);

    let type_data, type_results, type_allowed, type_excluded, type_already_added;

    if (meta.final_query) {
        // add roles in group search
        qs = handleAddQSNoteDisabled(us_buildQuickSearches(meta.cleaned_query, search_ctx, qs_results, count, 6, true, allowed), excluded, excluded_message, already_added);

        if (qs.length > 0) {
            count += qs.length;
            sections[0].items = [...sections[0].items].concat(qs);
        }
    }

    // todo move disabled and already added to bottom

    if (result_types.includes('members') && meta.final_query) {
        type_data = items['members'];
        type_excluded = excluded && excluded['members'] ? Object.keys(excluded['members']) : [];
        type_allowed = allowed && allowed['members'] ? allowed['members'] : null;
        type_already_added = already_added && already_added['members'] ? Object.keys(already_added['members']) : [];
        type_results = handleAddNoteAndDisabled(us_buildDataTypeResults('members', meta.final_query, count, getAllowedData(type_data, type_allowed, 'members'), true, 6), excluded_message, type_excluded, type_already_added);
        if (type_results.length > 0) {
            count += type_results.length;
            sections[0].items = [...sections[0].items].concat(type_results);
        }
    } else if (defaults && defaults.members) {
        type_data = getTransformedMembers(defaults.members);
        type_excluded = excluded && excluded['members'] ? Object.keys(excluded['members']) : [];
        type_allowed = allowed && allowed['members'] ? allowed['members'] : null;
        type_already_added = already_added && already_added['members'] ? Object.keys(already_added['members']) : [];
        type_results = handleAddNoteAndDisabled(us_buildDataTypeResults('members', meta.final_query, count, getAllowedData(type_data, type_allowed, 'members'), true, 6), excluded_message, type_excluded, type_already_added);
        if (type_results.length > 0) {
            count += type_results.length;
            sections[0].items = [...sections[0].items].concat(type_results);
        }
    }

    if (result_types.includes('roles') && meta.final_query) {
        type_data = items['roles'];
        type_excluded = excluded && excluded['roles'] ? Object.keys(excluded['roles']) : [];
        type_allowed = allowed && allowed['roles'] ? allowed['roles'] : null;
        type_already_added = already_added && already_added['roles'] ? Object.keys(already_added['roles']) : [];
        type_results = handleAddNoteAndDisabled(us_buildDataTypeResults('roles', meta.final_query, 8, getAllowedData(type_data, type_allowed, 'roles'), true, 6), excluded_message, type_excluded, type_already_added);
        if (type_results.length > 0) {
            count += type_results.length;
            sections[0].items = [...sections[0].items].concat(type_results);
        }
    }

    if (result_types.includes('segments') && meta.final_query) {
        type_data = items['segments'];
        type_excluded = excluded['segments'] ? Object.keys(excluded['segments']) : [];
        type_already_added = already_added['segments'] ? Object.keys(already_added['segments']) : [];
        type_results = handleAddNoteAndDisabled(us_buildDataTypeResults('segments', meta.final_query, count, type_data, true), excluded_message, type_excluded, type_already_added);
        if (type_results.length > 0) {
            count += type_results.length;
            sections[0].items = [...sections[0].items].concat(type_results);
        }
    }

    if (result_types.includes('groups') && meta.final_query) {
        type_data = items['groups'];
        type_excluded = excluded['groups'] ? Object.keys(excluded['groups']) : [];
        type_already_added = already_added['groups'] ? Object.keys(already_added['groups']) : [];
        type_results = handleAddNoteAndDisabled(us_buildDataTypeResults('groups', meta.final_query, count, type_data, true), excluded_message, type_excluded, type_already_added);
        if (type_results.length > 0) {
            count += type_results.length;
            sections[0].items = [...sections[0].items].concat(type_results);
        }
    }

    return sections;
}

/*
excluded is used for existing group members for example
 */

function getScore(aa,type,id) {
    if(!aa||!aa[type]) {
        return 1;
    }
    return aa[type][id] ? 0.1 : 1;
}

function getQSResults(searchable, community, already_added) {
    let qs = {};

    if (searchable.roles) {
        qs.roles = Object.entries(community.all_roles).map(([id, entry]) => {
            return {
                id,
                data_type: 'role',
                data: {
                    assignee_data: entry.assignee_data,
                },
                label: entry.name,
                score: getScore(already_added,'roles',id)
            }
        }).sort((a,b) => (a.score < b.score) ? 1 : -1);
    }

    if (searchable.member_types) {
        qs.member_types = Object.entries(community.member_types).map(([id, entry]) => {
            return {
                id,
                data_type: 'member_type',
                data: {},
                label: entry.plural,
                score: getScore(already_added,'member_type',id)
            }
        }).sort((a,b) => (a.score < b.score) ? 1 : -1);
    }

    return qs;
}

export function NewEntitySearch({

                                    auto_focus = false,
                                    excluded_message = '',
                                    placeholder = 'Search Members, Roles, Groups, Filters, and more',
                                    searchable = {members: true},
                                    already_added = {members: {}, filters: {}},
                                    excluded = {members: {}, filters: {}},
                                    context = {},
                                    defaults = {},
                                    allowed = null,
                                    onChange,
                                    search_ctx = ["group"], // team
                                    value
                                }) {
    const community = useContext(CommunityContext);
    const [query, setQuery] = useState('');
    const [completed_queries, setCompletedQueries] = useState({});
    const [items, setItems] = useState({});
    const [index, setIndex] = useState(null);
    const isFirstRun = useRef(true);

    const all_qs_results = getQSResults(searchable, community, already_added);

    const results = buildFinalResults(query, search_ctx, excluded_message, all_qs_results, items, context.community_uid, excluded, already_added, allowed, defaults);

    const results_length = us_getResultsLength(results);

    const [open, setOpen] = useState(query || (!query && results_length > 0));

    const is_searching = Object.values(completed_queries).filter(a => a === 'loading').length > 0;

    const selected_item = index !== null ? us_getSelectedItem(index, results) : null;

    const vl = useRef();
    const cq_vl = useRef();

    function handleOnChange(it) {
        if (!it) {
            onChange({type: '', value: '', id: ''});
        } else {
            onChange({type: it.data_type, id: it.data_id, value: it.data_id});
        }
        resetQuery();
    }


    useEffect(function () {
        if (isFirstRun.current) {
            isFirstRun.current = false;
            return;
        }
        if (query) {
            setOpen(true);
        } else {
            setOpen(false);
        }
    }, [query])

    useEffect(function () {
        // autoselect the most relevant result
        if (query.length > 2 && index === null) {
            setIndex(0);
        }
    }, [query, index]);

    useEffect(function () {
        cq_vl.current = completed_queries;
    }, [completed_queries]);

    useEffect(function () {
        vl.current = items;
    }, [items]);

    useEffect(function () {
        const len = results_length;
        let new_index = index;
        if (index > (len - 1)) {
            new_index = 0;
            setIndex(new_index);
        } else if (index < 0) {
            new_index = len - 1;
            setIndex(new_index);
        }
    }, [query, index])

    function handleIndexMove(int) {
        let new_index;

        const len = results_length;
        // len
        if (index === null) {
            new_index = int > 0 ? 0 : len - 1;
        } else {
            new_index = index + int;
        }
        if (new_index > (len - 1)) {
            new_index = 0;
        } else if (new_index < 0) {
            new_index = len - 1;
        }
        setIndex(new_index);
    }

    function handleEnter() {
        if (selected_item && !selected_item.disabled) {
            handleOnChange(selected_item);
        }
    }

    function resetQuery() {
        setQuery('');
        setIndex(null);
    }

    useEffect(function () {
        const cleaned = cleanQuery(query);
        if (cleaned) {
            const searches = us_getAsyncSearches(query, search_ctx, searchable);
            if (!searches.async) {
                return;
            }
            for (let i = 0; i < searches.queries.length; i++) {
                const sq = searches.queries[i];
                const query_id = [`${sq.type}-${searches.final_query}`];

                if (!cq_vl.current[query_id] && searches.final_query) {
                    setCompletedQueries({...cq_vl.current, [`${sq.type}-${searches.final_query}`]: 'loading'});
                    // todo context ID here change
                    us_asyncSearch(searches.final_query, context.community_uid, sq)
                        .then(resp => {
                            let new_items = us_handleAsyncItemsUpdate(vl.current, resp);
                            setCompletedQueries({
                                ...cq_vl.current,
                                [`${resp.type}-${searches.final_query}`]: 'complete'
                            })
                            setItems(new_items)
                        });
                }
            }
        }
    }, [query]);

    const trigger = <EntitySearchInput key='esi-trigger' clearValue={() => handleOnChange(null)} context={context}
                                       value={value} auto_focus={auto_focus} onFocus={() => {
    }} placeholder={placeholder} onBlur={() => {
    }} selected_item={selected_item} query={query} onChange={q => {
        setQuery(q.toLowerCase());
    }} onEnter={handleEnter} onArrowKey={handleIndexMove}/>;

    const content = <USPopupContainer width="60vw" maxWidth={"500px"}>
        {results.filter(a => a.items.length > 0).map(sec => {
            return <USResultsSection title={null} key={sec.id}>
                {sec.items.map(it => {
                    return <USItem selected={it.id === index} data={it} key={`${sec.id}-${it.id}-${it.data_id}`}
                                   query={query} {...it} onClick={() => {
                        if (!it.disabled) {
                            handleOnChange(it);
                        }
                    }}/>
                })}
            </USResultsSection>
        })}
        {!query && results_length === 0 && <TextItem text="Type to start searching"/>}
        {query && is_searching && results_length === 0 && <TextItem text="Searching.." show_loader/>}
        {query && !is_searching && results_length === 0 && <TextItem text="No results found"/>}
    </USPopupContainer>;

    return <Popover2 key="nes-section" autoFocus={false} isOpen={open} portalClassName="z-999"
                     onInteraction={((nextOpenState, e) => {
                         if (!e) {
                             return;
                         }
                         setOpen(nextOpenState);
                     })}
                     onClose={() => {
                     }} enforceFocus={false} minimal content={content} fill usePortal={false} placement="bottom-start"
                     style={{maxHeight: '500px'}}>
        {trigger}
    </Popover2>
}