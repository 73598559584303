import React from 'react';
import {Avatar, Button} from "../../core";

function Item({label="",value=""}) {

    return <div className="px-2 font-medium text-sm grid grid-cols-2 gap-2 border-b border-gray-200 py-3">
        <div className="text-gray-600">
            {label}
        </div>
        <div className="text-gray-800">
            {value}
        </div>
    </div>
}

function getFields(community,custom_fields,important_custom_fields) {
    let all_fields = [];

    all_fields.push({
        label: "First Name",
        value: "{{FIRST_NAME}}"
    })

    all_fields.push({
        label: "Last Name",
        value: "{{LAST_NAME}}"
    })

    all_fields.push({
        label: "Account Email",
        value: "{{ACCOUNT_EMAIL}}"
    })

    all_fields.push({
        label: "Phone",
        value: "{{CONTACT.PHONE}}"
    })

    all_fields.push({
        label: "Address",
        value: "{{ADDRESS}}"
    })

    let ps_keys = Object.keys(custom_fields);

    for(let i=0;i<ps_keys.length;i++) {
        const sec = custom_fields[ps_keys[i]];

        sec.fields.forEach(field=>{
            if(important_custom_fields.includes(field)) {
                all_fields.push({
                    label: sec.field_data[field].name,
                    value: `{{CUSTOM_FIELDS.${field}}}`
                })
            }
        })

    }
    return all_fields;
}

export function CT_DataCheck({community, member}) {

    const items = getFields(community.data,community.custom_fields,community.important_custom_fields);

    return <div className="mt-4 border-t border-gray-200">
        {items.map((it,k)=>{
            return <Item key={k} {...it}/>
        })}
        <div className="mt-4 flex space-x-3">
            <Button text="Looks great!" intent="success" />
            <Button text="Make changes" />
        </div>
    </div>
}