import React, {useContext, useState} from "react";
import styled from "styled-components";
import GodChangeDomain from "./change-domain";
import GodMigrations from "./migrations";
import {GlobalContext} from "../app/global/global-context";
import GodClearDB from "./clear db";
import {GPProductUpdates} from "./product-updates";
import {UnatySVG} from "../library/icons/unaty/logos";
import {GPPolicies} from "./policies";
import {GPCommunityInvites} from "./community-invites";
import {GPTips} from "./tips";
import {SAGeneralSection} from "./general";
import {Link} from "react-router-dom";
import {SuperadminMetricsPage} from "./metrics";
import {SuperadminEmailIP} from "./mailgun-ip";

const LogoCont = styled.div`
   > svg {
     height: 32px;
   }
`;

const sections = [
    {
        title: 'Dashboard',
    },
    {
        title: 'Product Updates',
    },
    {
        title: 'Metrics',
    },
    {
        title: 'Policies',
    },
    {
        title: 'Email IP',
    },
    {
        title: 'Tips',
    },
    {
        title: 'Community Invites'
    },
    {
        title: 'Migrations',
    },
    {
        title: 'Email Utilities',
    }
];

const allowed_emails = ['seanmccall14@gmail.com','sean.mccall@toptal.com','sean.mccall@getunaty.com','ahrens@getunaty.com','ramin.rente@getunaty.com'];

export default function GodPanelPage() {
const [tab,setTab] = useState(sections[0].title);
    const global = useContext(GlobalContext);

    const email = global.user.account_email;

    let content;

    if(!allowed_emails.includes(email)) {
        return <div className="p-8 text-center text-lg">
            Access denied
        </div>
    }

    switch (tab) {
        case 'Dashboard': {
            content = <div>
                Welcome, {global.user.about.first_name}

                <div className="pt-4 prose prose-sm">
                    <ul>
                        <li>
                            <Link to="/styleguide">Styleguide</Link>
                        </li>
                        <li>
                            <Link to="/playground">Playground</Link>
                        </li>
                    </ul>
                </div>
            </div>;
            break;
        }
        case 'Product Updates': {
            content = <div>
                <GPProductUpdates />
            </div>;
            break;
        }
        case 'Metrics': {
            content = <div>
                <SuperadminMetricsPage />
            </div>;
            break;
        }
        case 'Email IP': {
            content = <div>
                <SuperadminEmailIP />
            </div>;
            break;
        }
        case 'Tips': {
            content = <div>
                <GPTips />
            </div>;
            break;
        }
        case 'Policies': {
            content = <div>
<GPPolicies />
            </div>;
            break;
        }
        case 'Community Invites': {
            content = <div>
                <GPCommunityInvites />
            </div>;
            break;
        }
        case 'Migrations': {
            content = <div>
                <GodMigrations {...global} />
                <GodClearDB {...global} />
            </div>;
            break;
        }
        case 'Email Utilities': {
            content = <div>
                <GodChangeDomain {...global} />
                <SAGeneralSection />
            </div>;
            break;
        }
        default : {
            content = <div>
                Blank
            </div>;
            break;
        }
    }

    return <div className="h-screen overflow-y-auto">
        <div className="bg-gray-800">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                <div className="flex items-center justify-between h-16">
                    <div className="flex items-center">
                        <LogoCont className="flex-shrink-0 h-8">
                            <UnatySVG />
                        </LogoCont>
                        <div className="hidden sm:block">
                            <div className="ml-10 flex items-baseline space-x-4">
                                {sections.map((item, itemIdx) =>
                                        <a
                                            key={item.title}
                                            onClick={()=>setTab(item.title)}
                                            href="#"
                                            className={item.title===tab?`bg-gray-900 text-white px-3 py-2 rounded-md text-sm font-medium`:`text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium`}
                                        >
                                            {item.title}
                                        </a>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <header className="bg-white border-b border-gray-200">
            <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
                <h1 className="text-3xl font-bold text-gray-900">{tab}</h1>
            </div>
        </header>
        <main>
            <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
                {content}
            </div>
        </main>
    </div>;
}