import React, {useContext, useEffect, useState} from "react";
import {CommunityContext} from "../../../community-context";
import {GlobalContext} from "../../../../global/global-context";
import {FirebaseDB} from "../../../../../config/setup-firestore";
import {setDocumentTitle} from "../../../../../library/utilities/set-document-title";
import {authFetch} from "../../../../../config/network";
import {AdminEntityInfo, Button, PageHeader, SectionHeader} from "../../../../../library/components/core";
import {HotkeyWrapper} from "../../../../../library/components/old/hotkey-wrapper";
import {PageContainer} from "../../../../../library/components/old/page-container";
import EditFieldsSection from "../../../../../library/components/old/edit-fields-section";
import {DoubleCheckModal} from "../../../../../library/components/old/double-check-modal";
import styled from "styled-components";
import {AdvancedFilter} from "../../../../../library/components/old/advanced-filter";
import CommonCard from "../../../../../library/components/old/card";
import {CISmallSegment} from "../../../../../library/icons/custom/SmallSegment";

const ProfileContainer = styled.div`
  display: grid;
  grid-template-columns: 600px 1fr;
  grid-gap: 20px;
`;

function valuesChanged(arr,orig) {
    let flag = false;
    arr.forEach((it,index)=>{
        if(it.value!==orig[index].value) {
            flag = true;
        }
    })
    return flag;
}

export function CommunityAdminSegment(props) {
    const community = useContext(CommunityContext);
    const global = useContext(GlobalContext);
    const [loading, setLoading] = useState('segment');
    const [error, setError] = useState(null);
    const [modal_data, setModalData] = useState(null);
    const [catch_cm, setCatchCM] = useState(true);
    const [modal, setModal] = useState(null);
    const [id, setId] = useState(props.match.params.id);
    const [match, setMatch] = useState('any');
    const [conditions, setConditions] = useState([]);
    const [changes_made, setChangesMade] = useState(false);
    const [segment, setSegment] = useState({});

    useEffect(function () {
        getSegment(id);
    }, [id]);

    function getSegment(id) {
        FirebaseDB
            .collection('community_entities')
            .doc(community.uid)
            .collection('collections')
            .doc(id)
            .get()
            .then(handleSegment.bind(this));
    }

    function handleSegment(doc) {
        if (!doc.exists) {
            setError('not-found');
            setLoading(null);
        } else {
            let document = doc.data();
            document.id = doc.id;
            setDocumentTitle(document.name, community.data.name);
            setLoading(null);
            setMatch(document.match);
            setConditions([...document.conditions]);
            setSegment({...document});
        }
    }

    function handleDelete() {

        if (loading === 'deleting') {
            return;
        }
        setLoading('deleting');
        const res = () => {

            global.addToast({text: `Segment was deleted.`, intent: 'success'});
            const {history} = props;
            history.push(`/${community.data.handle}/admin/settings/member-segments`)
        };

        const err = () => {

            global.addToast({text: `Segment could not be deleted.`, intent: 'danger'});
        };

        const payload = {
            community_uid: community.uid,
            id: id,
            member_id: community.member_id
        };

        authFetch("/filters/delete", res, err, "POST", {payload});
    }

    function handleUpdate() {
        setLoading('saving');
        const payload = {
            conditions,
            match,
            name: segment.name,
            community_uid: community.uid,
            id: id,
            member_id: community.member_id
        };
        const res = () => {
            setLoading('');
            setChangesMade(false);
            global.addToast({text: 'Changes saved', intent: 'success'});
        };
        authFetch("/filters/update", res, res, "POST", {payload});
    }


    if (!segment.name) {
        return null;
    }

    const obj = {
        data: segment,

        type: 'segment',
        id: id,
        endpoint: '/filters/update',
        update_fields: ['name'],

        sections: [
            {
                title: 'Details',
                fields: [
                    {
                        field: 'name',
                        type: 'text',
                        defer: true,
                        name: 'Name'
                    }
                ]
            }
        ]
    };

    const hotkeys = [
        {
            id: 'D',
            keys: ["d"],
            fn: () => {
                setModal('double-check-delete');
            }
        }
    ];

    let actions = [
        <Button key={'action-button-delete'} onClick={() => {
            setModal('double-check-delete');
        }} shortcut={'D'} text='Delete'/>
    ];

    const current_conditions = JSON.stringify(conditions);

    return <HotkeyWrapper keys={hotkeys}>
        <PageContainer>

            <AdminEntityInfo type_icon={<div className="h-5 w-5 text-gray-600"><CISmallSegment/></div>} id={id}
                             type_label={"Segment"}/>

            <PageHeader action={<div className="space-x-2 flex">
                {changes_made ?
                    <Button onClick={() => handleUpdate()} loading={loading === 'saving'} text="Save changes"
                            intent="success"/> : null}
                {actions.map((action, i) => {
                    return <div key={i}>
                        {action}
                    </div>;
                })}
            </div>} divider title={<div className="flex items-center">
                <div>{segment.name}</div>
            </div>} size='2xl'/>


            <ProfileContainer>
                <div className="space-y-3">
                    <EditFieldsSection onSaved={() => {
                        getSegment(id)
                    }} member_types={community.member_types} id={id} member_id={community.member_id}
                                       community_uid={community.uid} history={props.history} {...obj} />
                    <SectionHeader divider fill padded={false} size="base" title={"Conditions"}>
                        <div className="pt-2">
                            {conditions&&<AdvancedFilter m={segment.match} c={[...conditions]} updateParent={(nc, nm) => {
                                if(!nc) {
                                    return;
                                }
                                const parsed = JSON.parse(current_conditions);
                                if (valuesChanged(parsed,nc) || nm !== match) {
                                    setChangesMade(true);
                                    setMatch(nm);
                                    setConditions([...nc]);
                                }
                            }}/>}
                        </div>
                    </SectionHeader>
                </div>

                <div>

                    <div>
                        <CommonCard title="Members" actions={[]}>
                            {conditions&&<AdvancedFilter preview m={match} c={[...conditions]} updateParent={(nc, nm) => {

                            }}/>}
                        </CommonCard>
                    </div>
                </div>
            </ProfileContainer>

        </PageContainer>


        {modal === 'double-check-delete' && <DoubleCheckModal onConfirm={() => handleDelete()} onClose={() => {
            setModal(null);
            setModalData(null)
        }} onCancel={() => {
            setModal(null);
            setModalData(null)
        }} type='segment_delete'/>}
    </HotkeyWrapper>
}