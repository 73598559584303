import React, {useContext, useEffect, useRef, useState} from "react";
import {buildGroupDirectoryViews, group_buildDirectoryConfig} from "./directory-utilities";
import {CommunityContext} from "../../community-context";
import {
    directory_buildConfig,
    directory_buildContext,
    directory_getInitialView,
    useSmartViews
} from "../../directory/utilities";
import {GlobalContext} from "../../../global/global-context";
import {useHistory, useLocation} from "react-router-dom";
import {bulk_sendDataQualityEmails} from "../../directory/api";
import {Button} from "../../../../library/components/core";
import {ExternalLinkIcon, PaperAirplaneIcon, PencilAltIcon, PlusIcon} from "@heroicons/react/solid";
import {AdvancedTable} from "../../../../library/components/advanced-table";
import {useIntl} from "react-intl";
import {getMemberViews} from "../../directory/views";
import {getItemFromLocalStorage} from "../../../../library/utilities/localstorage";
import {ChevronDownIcon, PencilIcon, UserAddIcon} from "@heroicons/react/outline";
import {isAnonymousUnatyEmail} from "../../../../library/utilities/anonymous-emails";
import {ATButtonBox} from "../../../../library/components/advanced-table/views/select-view";

function buildInviteTitle(community, group) {
    if (group) {
        return {
            subject: `Join ${group.name} at ${community.data.name}`,
            content: `${community.member.name} (${community.member.account_email}) invited you to join ${group.name} on the ${community.data.name} community on Unaty.`
        };
    }
    return {
        subject: `Join ${community.data.name} on Unaty`,
        content: `${community.member.name} (${community.member.account_email}) invited you to join ${community.data.name} on Unaty.`
    };
}

export function membersBuildInviteTo(members) {
    let a = [];
    members.filter(b => !isAnonymousUnatyEmail(b.account_email)).forEach(m => {
        a.push({
            email: m.account_email,
            handle: m.handle,
            image: m.profile_picture,
            isDisabled: false,
            name: m.name,
            value: m.id,
            _type: "members"
        });
    });
    return a;
}

export function DirectoryWrapper(props) {
    // CONTEXT
    const community = useContext(CommunityContext);
    const global = useContext(GlobalContext);
    const location = useLocation();
    const history = useHistory();
    const {addToast} = global;
    const {formatMessage: f} = useIntl();

    // ids holder
    const ids_hold = useRef();

    // STATE
    const [modal, setModal] = useState(null);
    const [modal_data, setModalData] = useState(null);
    const [initial_view, setInitialView] = useState(directory_getInitialView(props.ls_key, props.default_view));
    const [checked_url, setCheckedUrl] = useState(false);
    const smart_views = useSmartViews(props.get_smart_views, community);

    const all_views = props.view_type === 'group' ? buildGroupDirectoryViews(props.can_manage, []) : getMemberViews(props.can_manage, smart_views.data, props.default_view);

    useEffect(function () {
        if (!checked_url) {
            const urlParams = new URLSearchParams(window.location.search);
            const action = urlParams.get('action');
            if (action === 'create') {
                setModal('add-members');
            } else if (action === 'export') {
                setModal('pre-export');
            } else if (action === 'invite') {
                setModal('invite');
            }
            setCheckedUrl(true);
        }

        return () => {
            // save current view
            // how to best do this because we only know the view in the parent
        }
    }, []);

    function sendEmail(members) {
        const to = membersBuildInviteTo(members);
        if (to.length === 0) {
            alert("No members selected");
            return;
        }
        const prefill = {
            to,
            config: {},
            content: ``,
            subject: ``,
            type: 'email',
            template: ''
        };

        community.openComposer(prefill)
    }

    function sendDataCheck(members) {
        const to = membersBuildInviteTo(members);
        if (to.length === 0) {
            alert("No members selected");
            return;
        }
        const prefill = {
            to,
            config: {},
            content: `Hi {{member.first_name}}, can you please make sure your information is up-to-date?`,
            subject: `Check your Member Profile`,
            type: 'data-check',
            template: 'system-data-check'
        };

        community.openComposer(prefill)
    }

    function sendCommunityInvite(members) {
        const to = membersBuildInviteTo(members);
        if (to.length === 0) {
            alert("No members selected");
            return;
        }
        const ct = buildInviteTitle(community, props.data);
        const prefill = {
            to,
            config: {},
            content: ct.content,
            subject: ct.subject,
            type: 'invite',
            template: 'system-invite'
        };

        community.openComposer(prefill)
    }

    const config = props.view_type === 'group' ? group_buildDirectoryConfig(community, props.data.type, props.id) : directory_buildConfig(community);

    config.onAddEntry = () => setModal('add-members');
    config.onTableClick = (id) => global.handleSetRightMenu(`admin-member__${id}`, {});

    const context = directory_buildContext(community, props, history, global.addToast, global);

    if (props.can_manage) {
        config.actions = [];
    }

    config.selected_actions = [
        /*
        {
            onClick: (ids, data) => {
                setModal('edit-attribute');
            },
            component: <ATButtonBox onClick={()=>{
                setModal('edit-attribute')
            }} icon={<PencilIcon />} style="alt">
                Edit Attribute
            </ATButtonBox>,
        },

         */
        {
            type: 'item',
            id: 'add-to-group',
            title: 'Add to Group',
            icon: <UserAddIcon/>,
            onClick: (ids, data) => {
                setModalData(ids);
                setModal('add-to-group');
            }
        }
    ];

    config.selected_more_options = [];

    if (props.can_manage) {
        const opts = [
            {
                type: 'item',
                id: 'email',
                title: 'Send Email',
                onClick: (ids, data) => {
                    if (!ids) {
                        return;
                    }
                    sendEmail(ids.map(id => data[id]));
                }
            },
            {
                type: 'item',
                id: 'invite',
                title: 'Send Invite to Community',
                onClick: (ids, data) => {
                    if (!ids) {
                        return;
                    }
                    sendCommunityInvite(ids.map(id => data[id]));
                }
            },
            {
                type: 'item',
                id: 'data-check',
                title: 'Send Data Check',
                onClick: (ids, data) => {
                    if (!ids) {
                        return;
                    }
                    sendDataCheck(ids.map(id => data[id]));
                }
            },
            {
                type: 'item',
                id: 'import',
                title: 'Import Members from file',
                onClick: () => {
                    global.handleSetRightMenu('member-import', {});
                }
            },
            {
                type: 'item',
                id: 'export',
                title: 'Export Members to csv',
                onClick: () => {
                    setModal('pre-export');
                }
            }
        ];

        config.manage_actions = opts;

        config.actions = [
            {
                type: 'popup-menu',
                options: opts,
                button: <ATButtonBox style="alt" onClick={e => e.preventDefault()} right_icon={<ChevronDownIcon/>}>
                    Actions
                </ATButtonBox>
            }
        ];

        config.cell_actions = {};

        config.cell_actions['send-member-invite'] = () => {

        };
    }

    const row_actions = [];

    const initial_view_loaded = !initial_view;
    const iv = all_views.data[initial_view] ? initial_view : all_views.starting;

    if(props.parent_loading) {
        return null;
    }
    if (!iv) {
        return null;
    }

    return <AdvancedTable title={props.title} row_actions={row_actions} default_view={props.default_view}
                          scroll_id={"main-content"}
                          group_id={props.group_id} modal={modal} handleCloseModal={() => setModal(null)}
                          onSetIds={(new_ids) => {
                              ids_hold.current = new_ids;
                          }} ls_key={props.ls_key}
                          header={props.view_type === 'community' ? {
                              default_title: f({id: 'shared.entities.directory'}),
                              actions: <div className="flex items-center space-x-1.5">
                                  {community.member_access.add_members &&
                                  <Button intent="secondary" minimal left_icon={<PlusIcon/>}
                                          onClick={() => setModal('add-members')} text="Member" size="sm"/>}

                              </div>
                          } : null} initial_view={iv} initial_view_loaded={initial_view_loaded} config={config}
                          addToast={addToast} all_views={all_views}
                          history={history} context={context}/>
}