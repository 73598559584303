import React from 'react';
import {PinboardAddPin} from "../../../library/components/pinboard/add-pin";
import Modal from "../../../library/components/old/modal";

export function AddPinModal({
                                init_data,
                                confirm_text = "Create Pin",
                                    title="Pin a link to the group",
                                pins_data, pins, onClose=()=>{},onConfirm
                                 }) {

    const body = <div>
        <PinboardAddPin confirm_text={confirm_text} init_data={init_data} addPin={onConfirm} pin_data={pins_data} pins={pins} />
    </div>;

    return <Modal title={title} size='small' body={body} footerRight={null} footerLeft={null}
                  onClose={onClose} open={true} lazy />
}