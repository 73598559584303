import React from 'react';
import {PlusIcon} from '@heroicons/react/solid';
import styled from "styled-components";

function getSize(size) {
    if(size==='lg') {
        return '18px'
    } else if(size==='sm') {
        return '16px'
    } else if(size==='base') {
        return '17px'
    } else {
        return '14px'
    }
}

const ActionIcon = styled.div`
      
    > svg {
        height: ${props=>getSize(props.size)};
    }
`;

export function ActionWithIcon({text,
                                   dims = "",
                                   disabled=false,
                                   stopPropogation=true,
                                   icon_right=false,
    bg,
                                   size="xs",shade="500",onClick,icon,inverse=false}) {

    const color = inverse ? ` text-secondary ${onClick?`hover:text-gray-${shade}`:""}` : `${onClick?"hover:text-secondary":""} text-gray-${shade} `;
    return <div onClick={(e)=> {
        if(stopPropogation) {
            e.stopPropagation();
        }
        if(disabled||!onClick) {
            return;
        }
        onClick(e);
    }} className={`flex ${dims} items-center rounded-full  ${disabled?`opacity-50 text-gray-500 `:`${color} ${bg?"hover:bg-gray-100":""} cursor-pointer`}`}>
        {!icon_right&&icon&&<ActionIcon size={size} className={`text-${size} ${text?"pr-1":""}`}>
            {icon}
        </ActionIcon>}
        <div className={`font-medium text-${size}`}>{text}</div>
        {icon_right&&icon&&<ActionIcon size={size} className={`text-${size}  ${text?"pl-1":""}`}>
            {icon}
        </ActionIcon>}
    </div>
}