import React, {useContext, useState} from 'react';
import {ActionWithIcon, Avatar, Button, SectionHeader} from "../../../../library/components/core";
import {PlusIcon} from "@heroicons/react/solid";
import {DEFAULT_USER_PHOTO} from "../../../../config/defaults";
import {formatEndDate, formatStartDate} from "../../../../library/components/reorganize/experience-history/utilities";
import {PastAssigneesModal} from "./modal";
import {CommunityContext} from "../../community-context";
import {EntityInfo} from "../../../../library/utilities/localstorage/entity-localstorage";
import {Link} from "react-router-dom";

export function getSortableMonthYear(m, y) {
    const sm = m > 9 ? `${m}` : `0${m}`;
    return parseInt(`${y}${sm}`);
}

function Item({
                  data,
                  name = "",
                  onEdit,
                  base_link,
                  can_edit = false,
                  show_line = true,
                  formatted_start_date,
                  formatted_end_date,
                  profile_picture = DEFAULT_USER_PHOTO
              }) {

    return <div className="flex p-1 relative items-center space-x-2">
        <div className="flex-none">
            <div className="rounded-42 border-2 border-transparent h-11 w-11">
                <Avatar url={`${data && data.image ? data.image : profile_picture}_small?alt=media`}/>
            </div>
        </div>
        <div className="flex-grow">
            <div className="-mt-1 pb-0.5">
                {data ? <Link to={`${base_link}${data.handle}`}
                              className="text-gray-800 text-sm font-bold hover-opacity">{data ? data.name : name}</Link> :
                    <div className="text-gray-800 text-sm font-bold">{data ? data.name : name}</div>}
            </div>
            <div className="text-xs space-x-1 text-gray-600">
                {formatted_start_date && <span>{formatted_start_date}</span>}
                {formatted_start_date && <span>-</span>}
                <span>{formatted_end_date}</span>
            </div>
        </div>
        {can_edit && <div className="flex-none">
            <Button onClick={onEdit} minimal size="sm" text="Edit"/>
        </div>}
        {show_line && <div className="absolute w-0.5 -top-3 left-4 bg-gray-200 h-4"></div>}
    </div>
}

function List({current, items, startEditing, is_admin, community_uid}) {
    return <div className="space-y-0.5">
        {current.member_id &&
        <EntityInfo key={`current-it`} community_uid={community_uid} id={current.member_id} type={'members'}>
            <Item can_edit={false} show_line={false} {...current} />
        </EntityInfo>}
        {!current.member_id && <Item can_edit={false} show_line={false} {...current} name={"Unassigned"}/>}
        {items.map((it, k) => {
            return <EntityInfo key={it.id} community_uid={community_uid} id={it.member_id} type={'members'}>
                <Item onEdit={() => startEditing(it.id, it)} can_edit={is_admin}
                      {...it} />
            </EntityInfo>
        })}
    </div>
}

export function PastAssignees(props) {
    const [modal, setModal] = useState(null);
    const [modal_data, setModalData] = useState(null);
    const community = useContext(CommunityContext);

    function onAddPastAssignee() {
        setModal('new');
    }

    function handleStartEditing(id, data) {
        setModalData({...data});
        setModal('edit');
    }

    const past_assignees = props.data.past_assignee_data ? Object.entries(props.data.past_assignee_data) : [];

    const items = past_assignees.map(([id, it]) => {
        return {
            id,
            ...it,
            sort_date: getSortableMonthYear(it.from_month, it.from_year),
            formatted_start_date: formatStartDate(it.from_month, it.from_year),
            profile_picture: community.default_member_profile_picture,
            base_link: `/${community.data.handle}/member/`,
            formatted_end_date: formatEndDate(it.to_month, it.to_year, false)
        }
    }).sort((a, b) => a.sort_date < b.sort_date ? 1 : -1);


    const current = {
        member_id: props.data.assignee,
        formatted_end_date: 'Now',
        base_link: `/${community.data.handle}/member/`,
        formatted_start_date: items.length > 0 && items[0].formatted_end_date
    };

    return <div>
        <SectionHeader title={"Past Assignees"} action={<ActionWithIcon size="xs" icon={<PlusIcon/>}
                                                                        onClick={() => onAddPastAssignee()}
                                                                        text={"Add"} inverse/>}/>
        <div>
            <List community_uid={community.uid} startEditing={handleStartEditing} is_admin={community.is_admin} items={items}
                  current={current}/>
        </div>

        {modal === 'new' && <PastAssigneesModal action='create' onClose={() => {
            setModal(null);
            setModalData(null);
        }} onSuccess={() => {
// update member
            props.getRole();
            setModal(null);
            setModalData(null);
        }} endpoint={"/roles/past-assignees/add"} api_props={{id: props.id, community_uid: community.uid}}/>}

        {modal === 'edit' && <PastAssigneesModal action='edit' onClose={() => {
            setModal(null);
            setModalData(null);
        }} onSuccess={() => {
            // update member
            props.getRole();
            setModal(null);
            setModalData(null);
        }} onDelete={()=>{
            props.getRole();
            setModal(null);
            setModalData(null);
        }} init_data={modal_data} endpoint={"/roles/past-assignees/edit"}
                                                 delete_endpoint={"/roles/past-assignees/delete"} api_props={{
            id: props.id,
            item_id: modal_data.id,
            community_uid: community.uid
        }}/>}
    </div>
}