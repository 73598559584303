import React, {useContext, useEffect, useState} from 'react';
import {authFetch} from "../../../../config/network";
import {CommunityContext} from "../../community-context";

export function GetRelatedMembers(props) {
    const community = useContext(CommunityContext);
    const [members,setMembers] = useState([]);

    useEffect(function () {
        const payload = {
            community_uid: community.uid,
            user_uid: community.member.user_uid,
            id: props.id,
            member_id: community.member_id
        };
        authFetch("/get-similar-members", (resp)=>{
            if(resp&&resp.data.members) {
                console.log("GOGO",resp.data.members)
               setMembers(resp.data.members);
            }
        }, ()=>{}, "POST", {payload});
    }, [])

    return <div>
        Related members:
        {members.map(member=>{
            return <div key={member.id}>
                {member.data.name} ({member.type})
            </div>
        })}
    </div>
}