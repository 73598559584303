import React from 'react';
import {Avatar} from "../../../core";
import {PhotoSelector} from "../../../old/photo-selector";
import {fv_getFormattedVal} from "../../../../utilities/format-values";
import {UserEmailManager} from "../../../user-email-manager";
import {WCField} from "../index";

export function HWCSideAddress({data,id,handleSave,editing,setEditing,address_vis,community,member_type,meta}) {

    return <div className="w-full h-full flex flex-col">
        <div className="flex flex-col pt-4 px-3 pb-7">
            <div>
                {address_vis === 'full' && <div>
                    <div>
                        <WCField truncate={false} handleSave={handleSave} type="address" id="address"
                               value_formatted={fv_getFormattedVal('address', data.address, meta, {})} meta={meta}
                               editing={editing}
                               setEditing={setEditing} value={data.address}
                               label={"Address"}/>
                    </div>
                </div>}
                {address_vis === 'simple' && <>
                    <div>
                        <WCField handleSave={handleSave} type="string" id="city"
                               value_formatted={fv_getFormattedVal('string', data.address.city, meta, {})} meta={meta}
                               editing={editing}
                               setEditing={setEditing} value={data.address.city} label={"City"}/>
                    </div>
                    <div>
                        <WCField handleSave={handleSave} type="country" id="country"
                               value_formatted={fv_getFormattedVal('country', data.address.country, meta, {})}
                               meta={meta}
                               editing={editing}
                               setEditing={setEditing} value={data.address.country} label={"Country"}/>
                    </div>
                </>}
            </div>
        </div>
    </div>
}