import React, {useEffect, useState} from 'react';
import {DraftComposer} from "./compose-reply/draft-composer";

export function PostEditWrapper({data,updateParentContent}) {
    const [attachments, setAttachments] = useState({});
    const [editorState, setEditorState] = useState(null);

    useEffect(function () {
        if(editorState) {
            updateParentContent(editorState.getCurrentContent())
        }
    }, [editorState])

    return <div>
        <DraftComposer content_state={data.content_state} show_toolbar={true} type="edit-post" update={(es, att) => {
            setEditorState(es);
            setAttachments(att);
        }}/>
    </div>
}