import {at_buildView} from "../../../../../library/components/advanced-table/factory/view";

const filters = [
    {
        "field": "account_status",
        "operator": "in",
        "value": {
            "invite-sent": true,
            "not-invited": true
        },
        "modifier": "",
        "join": "and"
    }
];

const sort = [
    {
        dir: 'asc',
        type: 'text',
        field: 'about.last_name'
    }
];

const props = [
    {
        id: 'account_email',
        active: true,
        col_width: 200
    },
    {
        id: 'account_status',
        active: true,
        col_width: 260
    },
    {
        id: 'member_type',
        active: true,
        col_width: 120
    },
    {
        id: 'contact.phone',
        active: true,
        col_width: 150
    },
    {
        id: 'address.city',
        active: true,
        col_width: 120
    },
    {
        id: 'address.country',
        active: true,
        col_width: 120
    },
    {
        id: 'birthday',
        active: true,
        col_width: 120
    },
];

const layout = "list";

export const groupview_never_logged_in = at_buildView('NeverLoggedIn', 'Never Logged In', layout, filters, sort, props, 'member')