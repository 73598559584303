import React, {useContext, useEffect, useState} from 'react';
import {CommunityContext} from "../community-context";
import {useHistory, withRouter} from 'react-router-dom';
import {setDocumentTitle} from "../../../library/utilities/set-document-title";
import {DirectoryWrapper} from "../group/components/directory-wrapper";
import {useIsMobile} from "../../global/global-router";
import {NewMobileDirectory} from "../../../library/components/mobile-directory";
import {Button, EntityHeader} from "../../../library/components/core";
import {StandardPageHeight} from "../../../library/playground/standard-page-framework";
import {GlobalContext} from "../../global/global-context";
import {PlusIcon} from "@heroicons/react/solid";
import {FirebaseDB} from "../../../config/setup-firestore";

export function buildNewPathWithURLParam(history, ext = '') {
    if (history.location.search === '') {
        return `${history.location.pathname}${ext ? `?${ext}` : ''}`;
    } else {
        return `${history.location.pathname}${history.location.search}&${ext}`;
    }
}

function DirectoryMembers(props) {
    const history = useHistory();
    const global = useContext(GlobalContext);
    const community = useContext(CommunityContext);
    const [checked_url, setCheckedUrl] = useState(false);
    setDocumentTitle(`Directory`, community.data.name);

    const [default_view,setDefaultView] = useState(null);

    useEffect(function () {
        checkForDefault();
    }, [])

    function checkForDefault() {
        FirebaseDB
            .collection('communities')
            .doc(community.uid)
            .collection('meta')
            .doc('directory-default')
            .get()
            .then(d=>{
                setDefaultView(d.exists ? d.data() : false)
            })
    }

    useEffect(function () {
        if (!checked_url) {
            const urlParams = new URLSearchParams(window.location.search);
            const action = urlParams.get('action');

            if (action === 'create') {
                openAddMembers()
            }
            setCheckedUrl(true);
        }
    }, []);

    function openAddMembers() {
        global.handleSetRightMenu('create-members',{
            onConfirmed: (ids)=>{
                global.handleCloseRightMenu();

                const new_path = buildNewPathWithURLParam(history, 'reload=true');
                history.push(new_path);
            },
            initial: [],
            flow: 'directory'
        })
    }

    const is_mobile = useIsMobile();

    if (is_mobile) {
        return <StandardPageHeight display="flex flex-col" className="">
            <EntityHeader title="Directory"/>
            <NewMobileDirectory overflow={false} community_uid={community.uid} community={community}/>
        </StandardPageHeight>
    }

    const action = <Button intent="secondary" minimal left_icon={<PlusIcon/>} onClick={() => openAddMembers()}
                           text="Create Member"
                           size="sm"/>;

    return <StandardPageHeight content full>
            <DirectoryWrapper parent_loading={default_view===null} default_view={default_view} title={{action,title:"Directory"}}
                              ls_key={'community-directory'}
                              can_manage={community.member_access.manage_members}
                              view_type="community" get_smart_views/>
    </StandardPageHeight>
}

export default withRouter(DirectoryMembers);