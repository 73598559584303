import {DEFAULT_USER_PHOTO} from "../../../config/defaults";
import {EditorState,convertToRaw,convertFromRaw} from 'draft-js';
import {parseRawContent} from "../posts-feed/api";
let ref = [
    {
        header: {
            author: {

            },
            message: {
                type: "sent-a-message"
            },
            context: {
                type: "group"
            }
        },
        subject: "Protokoll des 1. oCC im SS 2022",
        type: 'group-message',
        blocks: [
            {
                type: 'text'
            }
        ]
    },
    {
        subject: "Aufnahmeantrag - Herr Lucca Kern",
        type: 'group-message',
        blocks: [
            {
                type: 'text'
            }
        ]
    },
    {
        subject: "Ein frohes & gesegnetes Osterfest!",
        type: 'group-message',
        blocks: [
            {
                caption: "Das Corpshaus",
                image: {
                    url: "https://firebasestorage.googleapis.com/v0/b/unaty-prod.appspot.com/o/qKE5EUiLwxrAcOE4OusA%2Fposts%2FZHYUG8ER5T-tempImagexr6B5K.png?alt=media",
                    height: 450,
                    width: 250,
                    color: "rgb(125,235,83)"
                },
                type: 'image'
            }
        ]
    },
    {
        subject: "TOP des An-CC im SS 22",
        type: 'group-message',
        blocks: [
            {
                type: 'text'
            }
        ]
    }
];

function buildMessage() {

}

function buildActivity() {

}

export function buildNFIMocks(messages,context,options={show_context:true}) {

    if(messages) {
        console.log("MESSAGES",messages)
        // build messages
       return messages.map((message,index)=>{

           if(message.type==='activity') {
               return {
                   id: message.id,
                   type: 'activity',
                   activity_type: message.activity_type,
                   created_at: message.data.created_at,
                   metadata: message.data.metadata,
                   header: {
                       author: {
                           type: message.data.author_type,
                           id: message.data.author_id,
                           link: `/${context.community.data.handle}/member-id/${message.data.author_id}`,
                       },

                       entity: options.show_context ? {
                           type: message.data.context_type,
                           id: message.data.context_id,
                           link: `/${context.community.data.handle}/group/${message.data.context_id}`,
                       } : {},

                       message: {
                           type: options.show_context?'sent-group-message':'sent-message'
                       },
                   },

                   attachments: [],

                   topbar: {
                       type: ""
                   },
               }
           }

           let craw = parseRawContent(message.data.raw);

            return {
                id: message.id||index,
                type: message.type==='activity'?'activity':'message',

                created_at: message.data.created_at,

                subject: message.data.subject,

                block_preview_count: 3,
                block_count: craw.blocks.length,

                content_state: convertFromRaw(craw),

                attachments: message.data.attachments || [],

                header: {
                    author: {
                        type: message.data.author_type,
                        id: message.data.author_id,
                        link: `/${context.community.data.handle}/member-id/${message.data.author_id}`,
                    },

                    entity: options.show_context ? {
                        type: message.data.context_type,
                        id: message.data.context_id,
                        link: `/${context.community.data.handle}/group/${message.data.context_id}`,
                    } : {},

                    message: {
                        type: options.show_context ? 'sent-group-message' : 'sent-message'
                    },

                    series: {
                        id: message.data.series || "",
                        name: message.data.series_name || "",
                        link: `/${context.community.data.handle}/group/${message.data.context_id}/series/${message.data.series}`
                    },
                },

                topbar: {
                    type: ""
                },
            }
        })
    }
    const sample = convertToRaw(EditorState.createWithText("Hello").getCurrentContent());
    return ref.map((it,index)=>{
        return itemFactory(it,index,sample)
    })
}

function itemFactory(item,index,sample) {
 return {
     id: index,
     type: item.type||'message',

     content_state: convertFromRaw(parseRawContent(sample)),

     subject: item.subject||"",

     blocks: item.blocks.map(bl=>blockFactory(bl)),

     attachments: [
         {
             id: "1234",
             size: 51234,
             type: "application/pdf",
             name: "Protokoll des 1. oCC im SS 2022.pdf",
         }
     ],

     header: {
         author: {
             name: "CB Schwabe",
             id: "",
             link: "",
             image: ""
         },

         context: {
             type: 'group',
             id: '1sPdnvqeDt18jca6oUU0',
             name: 'CC-Mitglieder'
         },

         action: {
             type: 'sent-group-message'
         },
     },

     topbar: {
         icon: "",
         text: ""
     },
 }
}

function blockFactory(props) {

    if(props.type==='image') {
        return {
            type: "image",
            ...props
        }
    }

    return {
        type: props.type||"text",
        text: `
        Meinen herzlichen Gruß zuvor!
Liebe Corpsbrüder,
Anbei erhaltet Ihr zur Kenntnisnahme das Protokoll des 1. oCC im SS 2022. Bei Fragen und Anmerkungen könnt ihr euch jederzeit gerne an mich wenden.
Mit besten corpsbrüderlichen Grüßen
Schwabe x, FM
        `
    }
}