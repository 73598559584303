import React, {useContext, useState} from "react";
import {ProfileBadge} from "../../../../../../library/components/profile-badge";
import {RulesInlineSelect} from "../../../../../../library/components/rules/rules-inline-select";
import {authFetch} from "../../../../../../config/network";
import {GlobalContext} from "../../../../../global/global-context";

export function ChangeMemberTypeBadge({
                                          member, changeMemberType = () => {
    }, community, member_id
                                      }) {
    const [member_type, setMemberType] = useState(member.member_type);
    const global = useContext(GlobalContext);

    function handleMemberTypeChange(mt) {
        if (mt === member_type) {
            return;
        }
        global.addToast({text: "Updating member type", intent: 'info'});
        setMemberType(mt);
        const res = (resp) => {
            global.addToast({text: "Changes saved", intent: 'success'});
            changeMemberType(mt);
        };
        const payload = {
            community_uid: community.uid,
            member_id: community.member_id,
            id: member_id,
            _updated_fields: ["member_type"],
            member_type: mt
        };
        authFetch("/members/update-profile", res, res, "POST", {payload});
    }

    if (!member_id) {
        return null;
    }

    const member_type_data = community.member_types[member.member_type];

    const options = Object.entries(community.member_types).map(mt => {
        return {
            id: mt[0],
            label: mt[1].singular
        }
    });
    return <RulesInlineSelect onSelect={handleMemberTypeChange} layout="meta" size="sm" inline searchable={false}
                              options={options} selected={member.member_type}/>
}