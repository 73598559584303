import {authFetch} from "../../../../config/network";

export function makeProfilePrivate(res) {

    const payload = {};

    authFetch("/users/make-profile-private", res, res, "POST", {payload})
}

export function makeProfilePublic(res) {

    const payload = {};

    authFetch("/users/make-profile-public", res, res, "POST", {payload})
}