import {countries} from "../../../library/data/general/countries";

function intersection(o1, o2) {
    return Object.keys(o1).concat(Object.keys(o2)).sort().reduce(function (r, a, i, aa) {
        if (i && aa[i - 1] === a) {
            r.push(a);
        }
        return r;
    }, []);
}

function getCommonInterests(a, b) {
    return intersection(a, b);
}

function getSharedGroups(a,b) {
    return intersection(a||{}, b||{});
}

function hasSameCfValue(cf, m1, m2) {
    const v1 = m1.custom_fields[cf.id] || "";
    const v2 = m2.custom_fields[cf.id] || "";

    return v1 && v2 && v1 === v2;
}

function getValueLabel(value, cf) {
    if (cf.type === 'select') {
        const opt = cf.options.choices.filter(opt => opt.value === value)[0];
        if(!opt) {
            return "";
        } else if(opt.text) {
            return opt.text;
        } else {
            return "";
        }
    } else if (cf.type === 'number') {
        return `${value}`
    }
    return "";
}

const relevant_types = ["number", "select"];

function getRelevantCustomFields(profile_sections) {

    const sections = Object.entries(profile_sections);

    let a = [];

    sections.forEach(([sid, sec]) => {
        const fields = sec.fields;
        fields.forEach(field => {
            const dt = sec.field_data[field];
            if (relevant_types.includes(dt.type)) {
                a.push({
                    id: field,
                    label: dt.name,
                    type: dt.type,
                    options: dt.options
                })
            }
        })
    })

    return a;
}

function hasCommonPastRole(mid1, mid2, roles = {}) {

    const roles_arr = Object.entries(roles).map(([id, data]) => {
        const pai = data.past_assignee_ids || {};
        let obj = {
            id,
            name: data.name,
            ids: {
                ...pai
            }
        }
        if (data.assignee) {
            obj.ids[data.assignee] = true;
        }
        return obj;
    })

    let result = [];

    roles_arr.forEach(ra => {
        const h1 = ra.ids[mid1];
        const h2 = ra.ids[mid2];
        if (h1 && h2) {
            result.push({
                name: ra.name,
                id: ra.id
            });
        }
    })

    return result;
}

function hasSimilarLocation(m1, m2) {
    if(!m1||!m2||!m1.address||!m2.address) {
        return null;
    }
   const city_match = m1.address.city&&m2.address.city &&m1.address.city.toLowerCase() === m2.address.city.toLowerCase();
   const state_match = m1.address.state&&m2.address.state &&m1.address.state.toLowerCase() === m2.address.state.toLowerCase();
   const country_match = m1.address.country&&m2.address.country &&m1.address.country === m2.address.country;


    if (city_match && country_match) {
        return {
            type: 'city-country',
            value: `${m1.address.city}`
        }
    } else if(state_match&&country_match) {
        return {
            type: 'state-country',
            value: `${m1.address.state}`
        }
    } else if (country_match) {
        return {
            type: 'country',
            value: `${countries[m1.address.country]}`
        }
    }
    return null;
}

export function getProfileHighlight(member, community) {
    if (member.id === community.member_id) {
        return {
            type: "my-profile",
            value: ""
        }
    }

    // common interests
    const common_interests = getCommonInterests(member.interests ? member.interests : {}, community.member.interests ? community.member.interests : {});

    if (common_interests.length > 0) {
        return {
            type: 'shared-interests',
            value: common_interests
        }
    }
    // common receptionsjahr or leibbursch
    const rcf = getRelevantCustomFields(community.custom_fields);

    let rcfm = null;
    rcf.forEach(cf => {
        if (hasSameCfValue(cf, member, community.member)) {
            const value_label = getValueLabel(member.custom_fields[cf.id], cf);
            if(value_label) {
                rcfm = {
                    id: cf.id,
                    field_label: cf.label,
                    value: member.custom_fields[cf.id],
                    value_label
                };
            }
        }
    })
    if (rcfm) {
        return {
            type: 'custom-field-match',
            value: rcfm
        };
    }
    // common role
    const cr = hasCommonPastRole(member.id, community.member.id, community.all_roles);

    if (cr.length > 0) {
        return {
            type: 'past-roles-match',
            value: cr
        };
    }
    // join date

    // both live in same place
    const loc = hasSimilarLocation(member, community.member);
    if (loc) {
        return loc
    }

    // both in groups together
    const similar_groups = getSharedGroups(member.groups,community.member.groups);

    if(similar_groups.length>3) {
        return {
            type: 'groups-count',
            value: similar_groups.length
        }
    }

    return null;
}