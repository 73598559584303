import React, {useContext} from "react";
import {setDocumentTitle} from "../../../library/utilities/set-document-title";
import {MainCalendar} from "../../../library/components/reorganize/birthdays-list";
import {CommunityContext} from "../community-context";
import {useIntl} from "react-intl";

export default function CommunityCalendar(props) {
    const community = useContext(CommunityContext);
    const {formatMessage: f} = useIntl();
    setDocumentTitle(f({id:'community.calendar.title'}), community.data.name);
    let action = null;
    /*
       <div className="-mt-1.5">
                    <Tabs onSelect={nt=>setTab(nt)} layout="pills-solid" active={tab} tabs={tabs.map(t=>t.label)} />
                </div>
     */
    return <MainCalendar community_handle={community.data.handle} history={props.history}
                          community_uid={community.uid}/>
}