import React, {useContext, useState} from "react";
import {ExclamationIcon} from "@heroicons/react/solid";
import {Badge, Button, InfoState, Input} from "./core";
import {NewTooltip} from "./reorganize/new-tooltip";
import {Popover2} from "@blueprintjs/popover2";
import styled from "styled-components";
import {authFetch} from "../../config/network";
import {CommunityContext} from "../../app/community/community-context";

const NoRounding = styled.div`

  > div {
    margin: -1px !important;
  }

  input {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    box-shadow: none;

  }
`;

export function EmptyFieldWarning({
                                      padded = true, type = "Email", field = 'account_email', can_suggest = true, id = ""
                                  }) {
    const [suggestion, setSuggestion] = useState('');
    const [loading, setLoading] = useState('');
    const [open, setOpen] = useState(false);
    const community = useContext(CommunityContext);

    function handleMakeSuggestion() {
        setLoading('sent');
        const payload = {
            community_uid: community.uid,
            member_id: community.member_id,
            id: id,
            type,
            field: field,
            suggestion
        };
        const res = () => {
            setSuggestion('');
        };
        authFetch("/members/make-profile-suggestion", res, res, "POST", {payload});
    }

    function handleClose() {
        setOpen(false);
    }

    let trigger = <div className={`${padded?"p-1":""}`}>
        <Badge onClick={can_suggest ? () => {
    } : null} text={`${type} missing`} icon={<ExclamationIcon/>} color="amber"/>
    </div>;

    if (!can_suggest) {
        return trigger;
    }

    const content = <div className="rounded-md">
        {loading === 'sent' && <InfoState button={<Button onClick={() => {
            setOpen(false);
            setLoading('');
            setSuggestion('');
        }} size="sm" text="Done"/>} subtitle="Thank you for your feedback!" intent="success"/>}
        {loading === 'updated' && <InfoState button={<Button onClick={() => {
            setOpen(false);
            setLoading('');
            setSuggestion('');
        }} size="sm" text="Done"/>} subtitle="Thanks for letting us know!" intent="success"/>}
        {loading !== 'sent' && <div className="rounded-md">

            <NoRounding className="bg-white">
                <Input value={suggestion} onChange={(f) => setSuggestion(f)} autoFocus
                       placeholder={`Suggest a new ${type}`}/>
            </NoRounding>

            <div className="px-3 flex py-3 rounded-b-md">
                <div className="flex-grow">
                    <Button onClick={() => handleClose()} text="Cancel" size="sm"/>
                </div>
                <div className="space-x-2">
                    <Button onClick={() => handleMakeSuggestion()} text="Send" intent="secondary" size="sm"
                            disabled={!suggestion}/>
                </div>

            </div>
        </div>}
    </div>;

    return <Popover2 interactionKind='click' onClose={() => {
        setOpen(false);
    }} placement={'bottom-start'} popoverClassName='minimal-popover bg-white w-72 rounded-md shadow-lg border border-gray-200'
                     isOpen={open} minimal content={content}>
        <div onClick={() => setOpen(!open)}>
            <NewTooltip message="Click to make a suggestion">
                {trigger}
            </NewTooltip>
        </div>
    </Popover2>
}