import React from 'react';
import {EntityInfo} from "../../../utilities/localstorage/entity-localstorage";
import {getPrettyTime} from "../../../utilities/general/dates";
import moment from "moment";
import {UserAddIcon} from "@heroicons/react/outline";

function MemberName({data}) {
    return <span className="font-medium text-gray-800">
        {data.name}
    </span>
}

export function ActivityItemCreatedGroup({group,community,is_moderator,_actions={},is_member,is_admin}) {

    return <div className={"px-4 mt-1 pb-2.5 pt-2 w-full"}>
        <div className="text-lg font-bold text-gray-900">{group.name}</div>
        <div className="text-sm text-gray-700">
            <EntityInfo community_uid={community.uid} id={group.created_by} type="member"><MemberName /></EntityInfo> created this group on {moment(group.created_at).format('MMM Do')}. Purpose: {group.purpose?group.purpose:"—"}
        </div>
        <div className="pt-2">
        {is_member&&_actions.launchAddMembers&&<div>
            <div onClick={_actions.launchAddMembers} className="inline-flex p-0.5 text-link hover:opacity-80 transition-opacity cursor-pointer">
                <div className="h-svg-45">
                    <UserAddIcon />
                </div>
                <div className="pl-1.5 font-medium text-sm">Add people</div>
            </div>
        </div>}
        </div>
    </div>
}