import React, {Component, useState} from "react";
import {PageContainer} from "../../../../../library/components/old/page-container";
import {setDocumentTitle} from "../../../../../library/utilities/set-document-title";
import {AdvancedFilter} from "../../../../../library/components/old/advanced-filter";
import {PageHeader} from "../../../../../library/components/core";
import {CommunityContext} from "../../../community-context";

export default class CreateFilter extends Component {
    static contextType = CommunityContext;
    constructor(props) {
        super(props);

        this.state = {
            open: false,
            deleting: false,
            open_delete: false
        };
    }

    componentDidMount() {
        setDocumentTitle('Filters', this.context.data.name);
    }

    render() {
        const {history} = this.props;
        const nav = {
            can_go_back: false,
            history: this.props.history,
            breadcrumbs: [
                {
                    label: "Community Settings",
                    path: `/${this.props.match.params.handle}/admin/settings`
                },
                {
                    label: "Filters",
                    path: `/${this.props.match.params.handle}/admin/settings/filters`
                },
                {
                    label: "Create",
                    path: `#`
                }
            ]
        };

        const action = {
            button_text: 'Create Filter',
            api: '/filters/create',
            cb: ()=>{
                history.push(`/${this.props.handle}/admin/settings/filters`);
                // this.props.actions.addToast({text: "Changes were saved", intent: 'success'});
            }
        };

        return <PageContainer nav={nav}>
            <PageHeader title='Create Filter' size="2xl"/>

            <div>
                <AdvancedFilter actions={this.props.actions} handle={this.props.handle} history={this.props.history} action={action} community_uid={this.context.uid} />
            </div>

        </PageContainer>
    }
}