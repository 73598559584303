import React, {useState} from "react";
import styled from "styled-components";
import {DataList} from "./data-list";
import {AddTaxonomy} from "./add-taxonomy";
import {AddTaxonomyOption} from "./add-option";
import {AddTaxonomyCategory} from "./add-category";


const Taxonomies = {
    interests: {
        count: 0,
        options: [
            {
                label: 'Golf',
                description: '',
                icon: '',
                emoji: '',
                categories: {
                    sports: true
                },
                tags: {
                    'countries-DE': true
                }
            }
        ]
    }
};

const TaxonomyCategories = {
    interests: {
        count: 0,
        categories: {
            sports: {
                label: 'Sports',
                icon: '',
                emoji: '',
                description: 'About Sports'
            }
        }
    }
};

const Frame = styled.div`
  display: grid;
    grid-template-columns: 280px 280px 1fr;
    > div {
        height: 100vh;
    }
`;

const Sidebar = styled.div`
border-right: 1px solid #ededed;
`;

const SecondSidebar = styled.div`
  border-right: 1px solid #ededed;
`;

const Main = styled.div`
 
`;


const Title = styled.div`
    font-size: 18px;
    font-weight: 600;
    padding: 12px;
    height: 48px;
    display: flex;
    align-items: center;
`;

const SmallTitle = styled.div`
 font-size: 16px;
 padding: 12px;
 line-height: 18px;
    font-weight: 500;
    height: 48px;
    display: flex;
    align-items: center;
`;

export function TaxonomiesPage() {
    const [taxonomy, setTaxonomy] = useState('');
    const [taxonomyData, setTaxonomyData] = useState({});

    const [option, setOption] = useState('');
    const [optionType, setOptionType] = useState('');
    const [optionData, setOptionData] = useState({});
    return <Frame>
        <Sidebar>
            <div>
                <Title className='text-gray-900'>
                    Taxonomies
                </Title>
                <DataList selected={taxonomy} onSelect={(id,data)=>{setTaxonomy(id);setOption('');setOptionData({});setOptionType('');setTaxonomyData(data)}} collection='taxonomies'/>
               <AddTaxonomy/>
            </div>
        </Sidebar>
        {taxonomy!==''&&<SecondSidebar>
            <SmallTitle className='text-gray-800'>
                Options
            </SmallTitle>

            <DataList selected={option} onSelect={(id,data,type)=>{setOption(id);setOptionType(type);setOptionData(data)}} collection='taxonomies' doc={taxonomy} subcollection="options"/>

            <AddTaxonomyOption fields={taxonomyData.fields} taxonomy_id={taxonomy} />

            <SmallTitle className='text-gray-800'>
                Categories
            </SmallTitle>

            {JSON.stringify(taxonomyData.categories)}

            <AddTaxonomyCategory taxonomy_id={taxonomy} />

        </SecondSidebar>}
        {taxonomy!==''&&option!==''&&<Main>
            <SmallTitle className='text-gray-800'>
                Edit {optionData.label}
            </SmallTitle>
            <div>
                {JSON.stringify(optionData)}
            </div>
        </Main>}
    </Frame>
}