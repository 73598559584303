export const PSProfilePhotos = [
    {
        title: 'Historical Leaders',
        desc: '',
        url: '',
        images: [
            {
                "url": "https://firebasestorage.googleapis.com/v0/b/unaty-staging.appspot.com/o/profile_pictures%2FJsD9_iULd",
                "color": "rgb(241,241,241)",
                title: "John Muir"
            },
            {
                title: "Abraham Lincoln",
                "url": "https://firebasestorage.googleapis.com/v0/b/unaty-staging.appspot.com/o/profile_pictures%2FrPXwfDCzJ",
                "color": "rgb(195,195,195)"
            },
            {
                "url": "https://firebasestorage.googleapis.com/v0/b/unaty-staging.appspot.com/o/profile_pictures%2FdYhpbxWvV",
                "color": "rgb(240,240,240)",
                title: "Mahatma Gandhi",
            },
            {
                title: "George Washington",
                "url": "https://firebasestorage.googleapis.com/v0/b/unaty-staging.appspot.com/o/profile_pictures%2F4_b5Gxny9",
                "color": "rgb(200,200,200)"
            },
            {
                title: "Martin Luther King Jr",
                "url": "https://firebasestorage.googleapis.com/v0/b/unaty-staging.appspot.com/o/profile_pictures%2FNkQ0ng08H",
                "color": "rgb(10,10,10)"
            },
            {
                title: "Susan B. Anthony",
                "url": "https://firebasestorage.googleapis.com/v0/b/unaty-staging.appspot.com/o/profile_pictures%2FbJ3CWDFfg",
                "color": "rgb(7,7,7)"
            },
            {
                title: "Theodore Roosevelt",
                "url": "https://firebasestorage.googleapis.com/v0/b/unaty-staging.appspot.com/o/profile_pictures%2FlkhZd98sp",
                "color": "rgb(182,182,182)"
            }
        ]
    },
    {
        title: 'Lego',
        desc: '',
        url: '',
        images: [
            {
                "color": "#F9E9DF",
                "url": "https://firebasestorage.googleapis.com/v0/b/unaty-staging.appspot.com/o/profile_pictures%2F-KZ44Ame3",
                "title": "Artist"
            }, {
                "color": "#F9E9DF",
                "url": "https://firebasestorage.googleapis.com/v0/b/unaty-staging.appspot.com/o/profile_pictures%2Fd7QfBFD_k",
                "title": "Basketball Player"
            }, {
                "color": "#F9E9DF",
                "url": "https://firebasestorage.googleapis.com/v0/b/unaty-staging.appspot.com/o/profile_pictures%2F8SzFpYS4i",
                "title": "Batman"
            }, {
                "color": "#F9E9DF",
                "url": "https://firebasestorage.googleapis.com/v0/b/unaty-staging.appspot.com/o/profile_pictures%2Fj_UidD6Ga",
                "title": "Caveman"
            }, {
                "color": "#F9E9DF",
                "url": "https://firebasestorage.googleapis.com/v0/b/unaty-staging.appspot.com/o/profile_pictures%2F9h6_c9Jog",
                "title": "Cheerleader"
            }, {
                "color": "#F9E9DF",
                "url": "https://firebasestorage.googleapis.com/v0/b/unaty-staging.appspot.com/o/profile_pictures%2Fote7LP8LI",
                "title": "Chef"
            }, {
                "color": "#F9E9DF",
                "url": "https://firebasestorage.googleapis.com/v0/b/unaty-staging.appspot.com/o/profile_pictures%2FC_1bF2Hug",
                "title": "Cowgirl"
            }, {
                "color": "#F9E9DF",
                "url": "https://firebasestorage.googleapis.com/v0/b/unaty-staging.appspot.com/o/profile_pictures%2FFfglze1uY",
                "title": "Deadpool"
            }, {
                "color": "#F9E9DF",
                "url": "https://firebasestorage.googleapis.com/v0/b/unaty-staging.appspot.com/o/profile_pictures%2FTVgzk9BZ4",
                "title": "Devil"
            }, {
                "color": "#F9E9DF",
                "url": "https://firebasestorage.googleapis.com/v0/b/unaty-staging.appspot.com/o/profile_pictures%2FEi802bzR7",
                "title": "Diver"
            }, {
                "color": "#F9E9DF",
                "url": "https://firebasestorage.googleapis.com/v0/b/unaty-staging.appspot.com/o/profile_pictures%2FeTyPnNHn5",
                "title": "DJ"
            }, {
                "color": "#F9E9DF",
                "url": "https://firebasestorage.googleapis.com/v0/b/unaty-staging.appspot.com/o/profile_pictures%2F9l8T8jdUC",
                "title": "Doctor"
            }, {
                "color": "#F9E9DF",
                "url": "https://firebasestorage.googleapis.com/v0/b/unaty-staging.appspot.com/o/profile_pictures%2FKF9Jx3SSc",
                "title": "Explorer"
            }, {
                "color": "#F9E9DF",
                "url": "https://firebasestorage.googleapis.com/v0/b/unaty-staging.appspot.com/o/profile_pictures%2FudtJyPymm",
                "title": "Fisherman"
            }, {
                "color": "#F9E9DF",
                "url": "https://firebasestorage.googleapis.com/v0/b/unaty-staging.appspot.com/o/profile_pictures%2FpxHfbYfCO",
                "title": "Football Player"
            }, {
                "color": "#F9E9DF",
                "url": "https://firebasestorage.googleapis.com/v0/b/unaty-staging.appspot.com/o/profile_pictures%2FiZkaFuqqE",
                "title": "Knight"
            }, {
                "color": "#F9E9DF",
                "url": "https://firebasestorage.googleapis.com/v0/b/unaty-staging.appspot.com/o/profile_pictures%2FbF4eIqjEj",
                "title": "Man"
            }, {
                "color": "#F9E9DF",
                "url": "https://firebasestorage.googleapis.com/v0/b/unaty-staging.appspot.com/o/profile_pictures%2FZD89H9M9s",
                "title": "Model"
            }, {
                "color": "#F9E9DF",
                "url": "https://firebasestorage.googleapis.com/v0/b/unaty-staging.appspot.com/o/profile_pictures%2FddSPeopPH",
                "title": "Native American"
            }, {
                "color": "#F9E9DF",
                "url": "https://firebasestorage.googleapis.com/v0/b/unaty-staging.appspot.com/o/profile_pictures%2FGzP1FLv2b",
                "title": "Pirate"
            }, {
                "color": "#F9E9DF",
                "url": "https://firebasestorage.googleapis.com/v0/b/unaty-staging.appspot.com/o/profile_pictures%2F60IPMXLx6",
                "title": "Policeman"
            }, {
                "color": "#F9E9DF",
                "url": "https://firebasestorage.googleapis.com/v0/b/unaty-staging.appspot.com/o/profile_pictures%2FAXXkAiL59",
                "title": "Queen"
            }, {
                "color": "#F9E9DF",
                "url": "https://firebasestorage.googleapis.com/v0/b/unaty-staging.appspot.com/o/profile_pictures%2FB9AtZiL67",
                "title": "Rockstar"
            }, {
                "color": "#F9E9DF",
                "url": "https://firebasestorage.googleapis.com/v0/b/unaty-staging.appspot.com/o/profile_pictures%2FwvTDVHfOO",
                "title": "Santa"
            }, {
                "color": "#F9E9DF",
                "url": "https://firebasestorage.googleapis.com/v0/b/unaty-staging.appspot.com/o/profile_pictures%2FwwBZGQZ3j",
                "title": "Sensei"
            }, {
                "color": "#F9E9DF",
                "url": "https://firebasestorage.googleapis.com/v0/b/unaty-staging.appspot.com/o/profile_pictures%2FAdgL1g6HQ",
                "title": "Spaceman"
            }, {
                "color": "#F9E9DF",
                "url": "https://firebasestorage.googleapis.com/v0/b/unaty-staging.appspot.com/o/profile_pictures%2F6o-AKr2oE",
                "title": "Spiderman"
            }, {
                "color": "#F9E9DF",
                "url": "https://firebasestorage.googleapis.com/v0/b/unaty-staging.appspot.com/o/profile_pictures%2Fqnrog0qtt",
                "title": "Superman"
            }, {
                "color": "#F9E9DF",
                "url": "https://firebasestorage.googleapis.com/v0/b/unaty-staging.appspot.com/o/profile_pictures%2FIQOUK01In",
                "title": "Surgeon"
            }, {
                "color": "#F9E9DF",
                "url": "https://firebasestorage.googleapis.com/v0/b/unaty-staging.appspot.com/o/profile_pictures%2FVO8G8-8yi",
                "title": "Worker"
            }
        ]
    },
];