import {HandleRegex} from "./sanitize-handle";

export function handleIsValid(str,scope) {
    // > 0 characters
    // < 30 characters
    let lens = scope === 'community' ? {min:2,max:80} : {min:5,max:80};
    if(str.length<lens.min||str.length>lens.max) {
        return false;
    }

    const regex = RegExp(HandleRegex);

    return regex.test(str);
}