import React from "react";
import {GPCISendInvite} from "./send-invite";
import {GPCIUsersWithInvites} from "./users-with-invites";
import {GPACResetLink} from "../account-issues/send-invite";

export function GPCommunityInvites() {

    return <div className="space-y-4">
        <div>
        <GPCISendInvite />
        </div>
        <div>
<GPCIUsersWithInvites />
        </div>
        <div>
            <GPACResetLink />
        </div>
    </div>
}