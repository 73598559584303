import React, {useState} from "react";
import {ATPopoverContents} from "../components/shared";
import {Popover2} from "@blueprintjs/popover2";
import {ActionWithIcon, Button, Field, Tabs} from "../../core";
import {ATProperties} from "../properties/properties";
import {ATGroup} from "../group/group";
import {ATSort} from "../sort/sort";
import {AdjustmentsIcon, CheckIcon, SelectorIcon} from "@heroicons/react/outline";
import {TableCheckbox} from "../../old/table-checkbox";
import {ATButtonBox} from "../views/select-view";
import {table_layouts} from "../views/layout-list";

export function ATCustomizeView({
                                    config,
                                    view,
                                    definitions,
                                    context,
                                    toggleShowDeactivatedMembers,
                                    handleEditSort,
                                    all_sort_options,
                                    sorts_in_use,
                                    handleChangeLayout,
                                    build_options,
                                    handleReorderSort,
                                    handleRemoveSort,
                                    setDefaultView,
                                    handleAddSort,
                                    history,
                                    setGroup,
                                    group_options,
                                    total,
                                    handleToggleProperty,
                                    handleReorderProperties
                                }) {
    const [open_select, setSelectOpen] = useState(false);

    const layout_options = definitions.allowed_layouts.map(al=>{
        const al_data = table_layouts[al];
       return {
           ...al_data,
           id: al
       };
    });
    const content = <ATPopoverContents size='large'>
        <div className="pt-3">
            {config.features.can_sort && <div className="px-3.5 pb-2 pt-0.5">
                <ATSort definitions={definitions} sorts_in_use={sorts_in_use} build_options={build_options}
                        all_sort_options={all_sort_options}
                        sort_options={definitions.sort_options} sort={view.sort} reorderSort={handleReorderSort}
                        onEditSort={handleEditSort} onRemoveSort={handleRemoveSort} onAddSort={handleAddSort}/>
            </div>}
            {view.features.can_sort && <div className="border-t border-gray-200 mt-2 pb-2"/>}
            <div className="px-3.5 pb-2 pt-0.5">
                <Field label="Layout">
                    <Tabs tabs={layout_options.map(a=>a.label)} onSelect={nl=>handleChangeLayout(nl.toLowerCase())} active={table_layouts[view.layout].label} intent="secondary" layout="pills-color" />
                </Field>
            </div>
            <div className="border-t border-gray-200 mt-2 pb-2"/>

            {view.features.can_group && false &&
            <ATGroup setGroup={setGroup} options={group_options} group_by={view.group_by}
                     definitions={definitions}/>}
            {view.features.can_group && false && <div className="border-t border-gray-200 mt-2 pb-2"/>}
            {config.features.edit_fields && view.layout === 'list' && <div className="px-3.5 pb-2 pt-0.5" >
                <ATProperties context={context} history={history} reorderProperties={handleReorderProperties}
                              handleToggleProperty={handleToggleProperty}
                              view={view} definitions={definitions} layout={view.layout}/>
            </div>}
            {config.features.edit_fields && view.layout === 'list' &&
            <div className="border-t border-gray-200 mt-2 pb-2"/>}
            <div className="px-3.5 pb-2 pt-0.5">
                <Field label="Customize">
                    <TableCheckbox onClick={toggleShowDeactivatedMembers} label="Show deactivated members"
                                   checked={view.show_deactivated_members}/>
                </Field>
            </div>
            {config.features.can_set_default_view&&<>
                <div className="border-t border-gray-200 mt-2 pb-2"/>
                <div className="px-3.5 pb-2 pt-0.5">
                    <ActionWithIcon onClick={()=>setDefaultView()} text="Set current view as Community default" />
                </div>
            </>}
        </div>
    </ATPopoverContents>;

    return <Popover2 isOpen={open_select}
                     placement='bottom-end' interactionKind='click' minimal content={content}
                     popoverClassName='minimal-popover margin-top-8px py-1'>
        <ATButtonBox style="alt" onClick={() => {
            if(open_select) {
                setSelectOpen(false)
            } else {
                setSelectOpen(true)
            }
        }} icon={open_select?<CheckIcon />:<AdjustmentsIcon/>}>
            {open_select?"Save Changes":"Adjust View"}
        </ATButtonBox>

    </Popover2>
}