import {customAlphabet} from 'nanoid';

export const APP_ENV = process.env.REACT_APP_ENV;
// export const APP_ENV = 'local-prod';

function getServerURL(env) {
  switch (env) {
    case 'prod' : {
      // https://us-central1-unaty-prod.cloudfunctions.net/imageResizerApi
      return 'https://us-central1-unaty-prod.cloudfunctions.net'
    }
    case 'local-prod' : {
      return 'http://localhost:5001/unaty-prod/us-central1'
    }
    case 'staging' : {
      return 'https://us-central1-unaty-staging.cloudfunctions.net'
    }
    default : {
      return 'http://localhost:5001/unaty-staging/us-central1'
    }
  }
}

function getAppURL(env) {
  switch (env) {
    case 'prod' : {
      return 'https://getunaty.com'
    }
    case 'staging' : {
      return 'https://unaty-staging.web.app'
    }
    case 'local-prod' : {
      return 'http://localhost:3000'
    }
    default : {
      return 'http://localhost:3000'
    }
  }
}

export const PROJECT_SLUG = (APP_ENV === 'prod' || APP_ENV === 'local-prod') ? 'unaty-prod' : 'unaty-staging';
export const SERVER_BASE_URL = getServerURL(APP_ENV);

export const ACCENT_COLORS = [
  '#027581',
  '#2A3643',
  '#B91C1C',
  '#FBBF24',
  '#2ca7ec',
  '#4C1D95',
  '#F472B6',
  '#0F766E',
  '#374151',
  '#65A30D',
  '#F15F31',
  '#FB7185',
];
export const ACCENT_GRADIENTS = [
  'linear-gradient(to right, #e65c00, #f9d423)',
  'linear-gradient(to right, #2193b0, #6dd5ed)',
  'linear-gradient(to right, #cc2b5e, #753a88)',
  'linear-gradient(to right, #ec008c, #fc6767)',
  'linear-gradient(to right, #1488cc, #2b32b2)',
  'linear-gradient(to right, #0f2027, #203a43, #2c5364)',
  'linear-gradient(to right, #12c2e9, #c471ed, #f64f59)',
  'linear-gradient(to right, #f12711, #f5af19)',
  'linear-gradient(to right, #659999, #f4791f)',
  'linear-gradient(to right, #8360c3, #2ebf91)',
  'linear-gradient(to right, #654ea3, #eaafc8)',
  'linear-gradient(to right, #8a2387, #e94057, #f27121)',
];
export const FULL_DATE_FORMAT = 'MMM Do YYYY, h:mm:ss a';
export const STORAGE_PROFILE_PICS_FOLDER = 'profile_pictures';
export const STORAGE_ACHIEVEMENTS_PICS_FOLDER = 'achievements';
export const MEMBER_CARD_PICS_FOLDER = 'member_card_backgrounds';
export const EMAIl_HEADER_PICS_FOLDER = 'email_headers';
export const COMMUNITY_LOGOS_FOLDER = 'community_logos';
export const STORAGE_COVER_PICS_FOLDER = 'cover_photos';
export const GROUP_COVER_PHOTO_FOLDER = 'group_cover_photos';
export const STORAGE_SIGN_IN_PAGE_FOLDER = 'sign_in_page_photos';
export const IMAGE_RESIZER_URL = `${SERVER_BASE_URL}/resize`;
export const EXTERNAL_SERVER_URL = `${SERVER_BASE_URL}/external`;
export const EMAIL_SERVER_URL = `${SERVER_BASE_URL}/email`;
export const APP_SERVER_URL = `${SERVER_BASE_URL}/server`;
export const STORAGE_BASE_URL = `https://firebasestorage.googleapis.com/v0/b/${PROJECT_SLUG}.appspot.com/o/`;
export const MAX_FILE_SIZE = 20*1024*1024;
export const MAX_IMAGE_SIZE = 5*1024*1024;
export const SUPPORT_EMAIL = 'hey@getunaty.com';
export const CUSTOM_DOMAIN_DEFAULT = 'm.getunaty.com';
export const APP_BASE_URL = getAppURL(APP_ENV);
export const DEFAULT_COMMUNITY_LOGO = 'https://firebasestorage.googleapis.com/v0/b/unaty-prod.appspot.com/o/profile_pictures%2F6ER1AKWO1N';
export const DEFAULT_USER_PHOTO = 'https://firebasestorage.googleapis.com/v0/b/unaty-prod.appspot.com/o/profile_pictures%2F1868I1QUR5';
export const DEFAULT_UNASSIGNED_ROLE = 'https://firebasestorage.googleapis.com/v0/b/unaty-prod.appspot.com/o/profile_pictures%2F605UVB21IZ';
export const DEFAULT_COVER_PHOTO = '';

export const POST_ACCEPTED_ATTACHMENT_TYPES = "*";
export const POST_ACCEPTED_IMAGE_TYPES = "image/jpg,image/png,image/gif,image/jpeg";

export const UNATY_COLOR = '#F15F31';
export const UNATY_COLOR_BACKGROUND = '#f5f2ec';
export const UNATY_COLOR_YELLOW = '#F0C165';
export const UNATY_COLOR_GREEN = '#1A7866';
export const UNATY_COLOR_BLUE = '#1D3B62';

export const LEADER_COLOR = '#04BAA3';
export const MEMBER_TYPE_COLOR = '#FF6270';
export const ADMIN_COLOR = '#9336E3';
export const SEGMENTS_COLOR = '#FDCF5F';
export const MODERATOR_COLOR = '#5A76AB';
export const REQUIRED_COLOR = '#6a162b';

export const NANOID_ALPHABET = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
export const NANOID_LENGTH = 10;

export const CUSTOM_NANOID = customAlphabet(NANOID_ALPHABET, NANOID_LENGTH);

// plans
export const COMMUNITY_PLANS = {
  STANDARD: {
    admin_seats: 6,
    roles: 12,
    teams: 3,
    profile_sections: 3,
    member_types: 6,
    lists: 18,
    storage: 25
  },
  PARTNERSHIP: {
    admin_seats: 6,
    roles: 12,
    teams: 3,
    profile_sections: 3,
    member_types: 6,
    lists: 18,
    storage: 25
  }
};

export const POST_REACTIONS = [
  {
    id: '1',
    emoji: '🙌'
  },
  {
    id: '2',
    emoji: '✔'
  },
  {
    id: '3',
    emoji: '👍'
  },
  {
    id: '4',
    emoji: '🔥'
  },
  {
    id: '5',
    emoji: '❤️'
  }
];