import React, {useState} from 'react';
import {Button} from '../../core/button';
import {slugify} from "../../../utilities/slugify";
import {TextAndEmoji} from "../../core";

export function AddInterest(props) {
    const [emoji,setEmoji] = useState("");
    const [name,setName] = useState("");
    const [parents,setParents] = useState({});
    const [related,setRelated] = useState({});
    return <div>
        <div>
            <TextAndEmoji onChange={(n,e)=>{setEmoji(e);setName(n)}} label="Name" emoji={emoji} value={name} placeholder="Swimming" />
        </div>
        <div className="mt-2">
            <Button disabled={name===""} intent="success" text="Add Interest" onClick={()=>{
                props.addInterest({id:slugify(name),emoji,name,parents,related});
                setEmoji("");
                setName("");
                setParents({});
                setParents({});
            }} />
        </div>
    </div>
}