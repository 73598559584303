import React, {useState} from "react";
import styled from "styled-components";
import {XIcon} from "@heroicons/react/solid";
import {SimpleOverlay} from "../../../library/components/simple-overlay";
import {colorContrast} from "../../../library/utilities/general/colors";
import {rgbToHex} from "../../global/create-community";

const alpha_value = 0.8;

function setAlpha(color) {
    const cs = color.replace('rgb(','');
    const cs2 = cs.replace(')','');

  const split = cs2.split(',');
    return `rgba(${split[0]},${split[1]},${split[2]},${alpha_value})`;
}

const Container = styled.div`
       height: 100vh;
    width: 100%;
    padding: 0px 48px;
    background: ${props=>setAlpha(props.bkg_color)};
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
`;

const Image = styled.img`
   max-height:100%;
    max-width:100%;
    height:auto;
    border-radius: 12px;
    width:auto;
    aspect-ratio: ${props=>props.aspect_ratio?props.aspect_ratio:""};
        background: #585858;
    min-width: ${props=>props.aspect_ratio?"":"200px"};
    min-height: ${props=>props.aspect_ratio?"":"200px"};
`;

const CloseBox = styled.div`
    height: 40px;
    cursor: pointer;
    width: 40px;
    border-radius: 50%;
    background: ${props=>props.bkg_color};
    color: ${props=>props.color};
    display: flex;
    align-items: center;
    justify-content: center;
    > svg {
        color: inherit;
        height: 28px;
    }
    :hover {
    opacity: 0.85;
    }
`;


export function PhotoPortal({onClose,aspect_ratio=null,raw_color="",images,bkg_color='rgb(0,0,0)'}) {
    const [currentImage, setCurrentImage] = useState(0);
    const img = images[currentImage];
if(!img) {
    return null;
}

const contrast = !raw_color ? "#ffffff" : colorContrast(rgbToHex(raw_color))


    return <SimpleOverlay canEscapeKeyClose onClose={onClose} enforceFocus className='photo-portal' isOpen>
        <Container className="bp4-overlay-content bp4-overlay-appear-done bp4-overlay-enter-done" bkg_color={bkg_color} onClick={onClose}>
        <Image aspect_ratio={aspect_ratio} onClick={(e)=>{e.stopPropagation();}} src={images[currentImage]} />

        <CloseBox className="absolute top-4 left-4" bkg_color={bkg_color} color={contrast} onClick={onClose}>
            <XIcon/>
        </CloseBox>
        </Container>
    </SimpleOverlay>;
}