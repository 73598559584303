// unsupported: 'community.Community', 'multi-community.Multi-Communities'

import {
    AtSymbolIcon, CakeIcon,
    CalendarIcon,
    CheckCircleIcon, CheckIcon,
    ClipboardCheckIcon,
    DatabaseIcon, GlobeAltIcon, GlobeIcon,
    HashtagIcon,
    IdentificationIcon, KeyIcon,
    LinkIcon,
    LocationMarkerIcon,
    MenuAlt2Icon,
    MenuAlt4Icon,
    PhoneIcon,
    PhotographIcon, StarIcon, TagIcon,
    UserIcon,
    UsersIcon
} from "@heroicons/react/solid";
import React from "react";
import {UserCircleIcon,UserGroupIcon} from '@heroicons/react/solid';
const field_types = ['text.Text', 'textarea.Multiline Text', 'url.Website Link', 'email.Email', 'phone.Phone Number', 'checkbox.Checkbox', 'date.Date', 'number.Number', 'select.Select', 'multi-select.Multi-select', 'member.Member', 'mentor.Mentor', 'address.Address', 'group.Group', 'country.Country', 'select-taxonomy.Taxonomy Select', 'multi-select-taxonomy.Taxonomy Multi-select'];

export const custom_field_type_icons = {
    email: <AtSymbolIcon />,
    'multi-email': <AtSymbolIcon />,
    'full-name': <UserIcon />,
    language: <GlobeAltIcon />,
    'profile-picture': <IdentificationIcon />,
    'cover-photo': <PhotographIcon />,
    image: <PhotographIcon />,
    name: <StarIcon />,
    id: <KeyIcon />,
    docid: <KeyIcon />,
    gender: <CheckCircleIcon />,
    checkbox: <CheckIcon />,
    'boolean-label': <CheckIcon />,
    boolean: <CheckIcon />,
    birthday: <CakeIcon />,
    'display-name': <StarIcon />,
    phone: <PhoneIcon />,
    'multi-select': <ClipboardCheckIcon />,
    select: <CheckCircleIcon />,
    member: <UserCircleIcon />,
    'member-type': <TagIcon />,
    country: <GlobeIcon />,
    mentor: <UserIcon />,
    mentees: <UsersIcon />,
    group: <UserGroupIcon />,
    url: <LinkIcon />,
    'social-github': <LinkIcon />,
    'social-reddit': <LinkIcon />,
    'social-facebook': <LinkIcon />,
    'social-linkedin': <LinkIcon />,
    'social-eth': <LinkIcon />,
    'social-instagram': <LinkIcon />,
    'social-skype': <LinkIcon />,
    'social-twitter': <LinkIcon />,
    'social-discord': <LinkIcon />,
text: <MenuAlt4Icon />,
string: <MenuAlt4Icon />,
    textarea: <MenuAlt2Icon />,
    date: <CalendarIcon />,
    number: <HashtagIcon />,
    address: <LocationMarkerIcon />,
    'select-taxonomy': <DatabaseIcon />,
    'multi-select-taxonomy': <DatabaseIcon />
};

/*
Member - string of UID
Multi-member - object of UIDs as keys = true
Mentor - can be set on every member level, is auto set to mentees on Member parent, is just uid string on member, gets set as [uid]: true on the member parent on the object
-> likely needs to create 2 custom fields based on this then
Community - UID of the community that makes a call to the system, we add and maintain basically filters
Multi-communities - object of uids for the community
 */
function generateArr(types) {
    let to_return = [];

    for (let i = 0; i < types.length; i++) {
        const type = types[i];

        const split = type.split('.');

        to_return.push({
            value: split[0],
            text: split[1]
        });
    }

    return to_return;
}

function generateObj(types) {
    let to_return = {};

    for (let i = 0; i < types.length; i++) {
        const type = types[i];

        const split = type.split('.');

        to_return[split[0]] = split[1];
    }

    return to_return;
}

export const field_types_arr = generateArr(field_types);

export const field_types_obj = generateObj(field_types);