import React from 'react';
import classNames from 'classnames';

function getTabLayout(layout,first,last,compact) {
    if(layout==='pills') {
        return 'rounded-md px-3 py-1.5 text-sm';
    } else if(layout==='pills-solid') {
        return 'rounded-full px-2.5 py-1 text-sm whitespace-nowrap';
    } else if(layout==='rich-bottom') {
        return 'text-center px-3 pt-2 pb-2 text-sm -mb-px';
    } else if(layout==='left-navbar') {
        return 'text-center px-3 pb-2 pt-2 flex items-center justify-center text-sm';
    } else if(layout==='standard-bottom') {
        return compact?'text-center pt-1 pb-0.5 text-xs':'text-center px-3 pt-2 pb-2 text-sm -mb-0.5';
    } else if(layout==='medium-bottom') {
        return compact?'text-center pt-1 pb-1 text-xs':'text-center px-3 pt-1 pb-1 text-base';
    } else if(layout==='large-bottom') {
        return 'text-center px-3 pt-2 pb-3 text-sm -mb-0.5';
    } else if(layout==='entity') {
        return 'text-center pt-1 pb-1 -mb-px';
    } else if(layout==='navbar') {
        return 'text-center pt-1 pb-1 text-sm -mb-px';
    } else if(layout==='pills-color') {
        return 'rounded-md px-3 py-1.5 text-sm';
    } else if(layout==='pills-connected') {
        const base = "px-3 py-1.5 text-sm text-center";
        const bg = first ? "" : last ? "" : "";
        const border = first ? "border-l border-r rounded-l-md" : last ? "border-r rounded-r-md" : "border-r";
        return `${base} border-t border-b border-solid ${border} ${bg}`;
    } else {
        // simple
        return '';
    }
}

function getActiveLayout(layout,active,intent,compact) {
    if(active) {
        if(layout==='pills') {
            return 'bg-gray-100 text-gray-800';
        } else if(layout==='pills-solid') {
            return `bg-${intent} text-white`;
        } else if(layout==='rich-bottom') {
            return `hover:bg-${intent}-50 text-${intent} border-b-2 border-${intent}`;
        } else if(layout==='standard-bottom') {
            return compact?`text-${intent} border-b-2  -mb-px special-standard-bottom-compact-item border-${intent}`:`text-${intent} border-b-2 border-${intent}`;
        } else if(layout==='medium-bottom') {
            return `text-${intent} border-b-2  -mb-px special-standard-bottom-compact-item border-${intent}`;
        } else if(layout==='left-navbar') {
            return `text-${intent} border-b border-${intent}`;
        } else if(layout==='large-bottom') {
            return `text-${intent} border-b-2 border-${intent}`;
        } else if(layout==='entity') {
            return `text-${intent} border-b-2  -mb-px special-standard-bottom-compact-item border-${intent}`;
        } else if(layout==='navbar') {
            return `text-${intent} border-b-2 mb-px border-${intent}`;
        } else if(layout==='pills-color') {
            return `bg-${intent}-50 text-${intent}`;
        } else if(layout==='pills-connected') {
            return `bg-${intent} border-${intent} text-white`;
        } else {
            // simple
            return `text-${intent}`;
        }
    } else {
        if(layout==='pills') {
            return 'text-gray-500 hover:bg-gray-100';
        } else if(layout==='pills-solid') {
            return `text-gray-500 hover:text-gray-600 hover:bg-${intent}-50`;
        } else if(layout==='pills-color') {
            return `text-gray-500 hover:text-gray-600 hover:bg-${intent}-50`;
        } else if(layout==='rich-bottom') {
            return `text-gray-500 hover:bg-${intent}-50 border-b-2 hover:border-${intent} hover:text-${intent}`;
        } else if(layout==='standard-bottom') {
            return compact?`text-gray-500 hover:border-b-2 -mb-px border-bottom-2-hover border-b hover:text-${intent} shared-bottom-border hover:border-${intent} hover:text-${intent}`:`text-gray-500 hover:text-${intent} border-b-2 hover:border-${intent} hover:text-${intent}`;
        } else if(layout==='medium-bottom') {
            return `text-gray-500 hover:border-b-2 -mb-px border-bottom-2-hover border-b hover:text-${intent} shared-bottom-border hover:border-${intent} hover:text-${intent}`;
        } else if(layout==='left-navbar') {
            return `text-gray-500 hover:border-b border-bottom-hover border-b hover:text-${intent} shared-bottom-border hover:border-${intent} hover:text-${intent}`;
        } else if(layout==='large-bottom') {
            return `text-gray-500 hover:text-${intent} border-b-2 hover:border-${intent} hover:text-${intent}`;
        } else if(layout==='entity') {
            return `text-gray-500 hover:text-${intent} hover:border-b-2 border-bottom-2-hover hover:border-${intent} hover:text-${intent}`;
        } else if(layout==='navbar') {
            return `text-gray-500 hover:text-${intent} hover:border-b-2 shared-bottom-border mb-px border-bottom-2-hover border-b hover:border-${intent} hover:text-${intent}`;
        } else if(layout==='pills-connected') {
            return `text-gray-500 bg-gray-100 border-gray-200 hover:bg-${intent}-50 hover:text-${intent}`;
        } else {
            // simple
            return `text-gray-500 hover:text-${intent}`;
        }
    }
}

function getFontSize(compact,layout) {
    if(layout==='large-bottom') {
        return 'text-smbase';
    } else if(layout==='entity') {
        return 'text-smbase';
    }
    return compact?"text-xssm":"text-sm";
}

function Tab({active,text,sub_text,compact,layout,intent,onSelect,first,last}) {
    return <div className={classNames(getTabLayout(layout,first,last,compact),getActiveLayout(layout,active,intent,compact),getFontSize(compact,layout)," font-medium cursor-pointer transition-colors")} onClick={()=>onSelect(text)}>
        {text}{sub_text&&<span className="font-normal">{sub_text}</span>}
    </div>
}

function getGridCols(count) {
    if(count===1) {
        return `grid-cols-1`;
    } else if(count===2) {
        return `grid-cols-2`;
    } else if(count===3) {
        return `grid-cols-3`;
    } else if(count===4) {
        return `grid-cols-4`;
    } else if(count===5) {
        return `grid-cols-5`;
    } else if(count===6) {
        return `grid-cols-6`;
    } else {
        return `grid-cols-7`;
    }
}

function getContainerLayout(layout,count,compact) {
    if(layout==='pills') {
        return 'flex space-x-3';
    } else if(layout==='pills-solid') {
        return 'flex space-x-1.5';
    } else if(layout==='pills-color') {
        return 'flex space-x-3';
    } else if(layout==='rich-bottom') {
        return `grid ${getGridCols(count)} border-b border-solid border-gray-200`;
    } else if(layout==='standard-bottom') {
        return `flex border-solid ${compact?"space-x-3 border-b hover:border-b-2 shared-bottom-border":"space-x-1 border-b-2 border-gray-200"} `;
    } else if(layout==='medium-bottom') {
        return `flex border-solid ${compact?"space-x-4":"space-x-1"}  border-b hover:border-b-2 shared-bottom-border `;
    } else if(layout==='large-bottom') {
        return `flex border-b-2 border-solid space-x-1.5 border-gray-200`;
    } else if(layout==='entity') {
        return `flex px-0.5 border-b border-solid space-x-6 border-gray-200`;
    } else if(layout==='left-navbar') {
        return `grid ${getGridCols(count)}`;
    } else if(layout==='navbar') {
        return `flex space-x-3`;
    } else if(layout==='pills-connected') {
        return `grid max-w-2xl ${getGridCols(count)}`;
    } else {
        // simple
        return 'flex space-x-4';
    }
}

export function Tabs({tabs=["All", "Groups", "Teams", "Events", "Members"], getSublabel, compact, action,intent="secondary",layout="text",active="Groups",onSelect=()=>{}}) {
    const count = tabs.length;
    return <div className={classNames(getContainerLayout(layout,count,compact),"relative","user-select-none")}>
        {tabs.map((t,i)=> {
            const sl = getSublabel && getSublabel(t) ? ` ${getSublabel(t)}` : "";
            return <Tab compact={compact} intent={intent} first={i === 0} last={(i + 1) === count} layout={layout}
                 text={`${t}`} sub_text={`${sl}`} onSelect={() => onSelect(t, i)} active={active === t} key={t}/>
        })}
        {action&&<div className="bg-white absolute pl-1 top-0 right-0">
            {action}
        </div>}
    </div>
}