import React from 'react';
import {getMemberMeta} from "./helpers";
import {DEFAULT_USER_PHOTO} from "../../../config/defaults";
import {getProfileHighlight} from "./get-profile-highlight";

export function buildMemberProfileCard(data,community) {
    return {
        type: 'member',

        appearance: {
            show_color: true,

            cover_photo: !data.cover_photo?community.default_member_cover_photo:data.cover_photo,
            cover_photo_color: data.cover_photo_color,

            emoji: '', // group only
            event_date: null,

            profile_picture: !data.profile_picture?community.default_member_profile_picture:data.profile_picture,
            profile_picture_color: data.profile_picture_color===''?'#ffffff':data.profile_picture_color
        },

        main: {
            title: data.name,
            status: {
                emoji: '',
                text: ''
            },
            subtitle: [
                `@${data.handle}`
            ],
            description: data.about.bio,
            meta: getMemberMeta(data),

            highlight: getProfileHighlight(data,community)
        },

        actions: [

        ],

        rich_meta: {
            primary: null,
            secondary: null,
            tertiary: null
        },

        stats: [

        ]
    }
}