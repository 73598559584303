import React, {useEffect, useState} from 'react';
import {DEFAULT_UNASSIGNED_ROLE} from "../../../../config/defaults";
import {Avatar, InfoState} from "../../../../library/components/core";
import {FirebaseDB} from "../../../../config/setup-firestore";
import {EntityInfo} from "../../../../library/utilities/localstorage/entity-localstorage";
import {useHistory,useLocation} from "react-router-dom";
function Role({data}) {
const { name, assignee, assignee_image, assignee_name } = data;

const image = assignee_image ? `${assignee_image}_medium?alt=media` : `${DEFAULT_UNASSIGNED_ROLE}_medium?alt=media`;
    return <div>
        <div className="">
            <Avatar size="xl" url={image} type="member" />
        </div>
        <div className="font-semibold text-sm text-gray-800 line-clamp-2">{name}</div>
        <div className="text-xs text-gray-500 line-clamp-2">{assignee_name?assignee_name:"Unassigned"}</div>
    </div>
}

function Group({roles, onClick, roles_order, title, community_uid, subtitle}) {

    const fr = roles_order ? roles_order : roles;
    return <div>
        <div className="pb-2">
            <div onClick={onClick} className="font-bold cursor-pointer hover:text-gray-600 text-smbase text-gray-800">{title}</div>
            {subtitle&&<div className="text-gray-500 pt-0.5 text-xssm">{subtitle}</div>}
        </div>
        <div className="grid grid-cols-2 gap-3 sm:grid-cols-3 lg:grid-cols-4">
            {roles.length===0&&<InfoState subtitle="—" />}
            {fr.map(role => {
                return <EntityInfo key={role} community_uid={community_uid} type="roles" id={role}>
                    <Role />
                </EntityInfo>
            })}
        </div>
    </div>
}

const mock = [
    {
        id: "5234",
        title: "Board of Directors",
        subtitle: "To coordinate all activities.",
        roles: [
            {
                id: "1234",
                title: "President",
                subtitle: "James MacArthur",
                image: `${DEFAULT_UNASSIGNED_ROLE}_medium?alt=media`
            }
        ]
    }
]

export function PublicCommunityLeaders({community_uid=''}) {

    const [groups, setGroups] = useState(null);
const history = useHistory();
const location = useLocation();
    useEffect(function () {
        getGroups()
    }, [])

    function getGroups() {
        FirebaseDB
            .collection('community_entities')
            .doc(community_uid)
            .collection('groups')
            .where('display_leaders','==',true)
            .where('enable_public_page','==',true)
            .get()
            .then(snap=>{
                setGroups(snap.docs.map(doc=>{
                    const data = doc.data();
                    return {
                        id: doc.id,
                        title: data.name,
                        subtitle: data.purpose,
                        roles_order: data.roles_order,
                        roles: Object.keys(data.entities.roles)
                    }
                }).sort((a, b) => (a.rank < b.rank) ? 1 : -1))
            })
    }

    if(!groups) {
        return <div>
            <InfoState subtitle="-" />
        </div>
    }

    return <div className="px-4 pt-3 pb-20">
        <div className="space-y-6">
        {groups.map(group=>{
            return <Group key={group.id} onClick={()=>{
                history.push(`${location.pathname}?group=${group.id}`);
            }} community_uid={community_uid} {...group}  />
        })}
            {groups.length===0&&<InfoState subtitle="-" />}
        </div>
    </div>
}