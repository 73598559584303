import prod from './firebase-prod';
import staging from './firebase-staging';

/*
If you want to debug prod locally, just change the below config to always target prod
 */

const env = process.env.REACT_APP_ENV;

const config = env === 'local-prod' || env === 'prod' ? prod : staging;
export default config;