import React, {useContext, useState} from 'react';
import {Button, Field, Input, RichSelect} from "../core";
import {
    ChevronDownIcon,
    ChevronLeftIcon,
    ChevronUpIcon, DotsHorizontalIcon,
    EyeIcon,
    EyeOffIcon,
    LinkIcon,
    TrashIcon
} from "@heroicons/react/solid";
import {AddCustomField} from "./add-custom-field";
import {ProfileSectionVisibilitySelector} from "./profile-section-visibility-selector";
import RequiredIndicator from "./required-indicator";
import {custom_field_type_icons} from "../../../app/auth/profile-fields/constants";
import {custom_field_types} from "../../utilities/community/custom-fields";
import {Prompt} from 'react-router-dom';
import {CUSTOM_NANOID, DEFAULT_COVER_PHOTO, DEFAULT_USER_PHOTO} from "../../../config/defaults";
import {authFetch} from "../../../config/network";
import ManageDisplayName from "../old/edit-fields-section/manage-display-name";
import MemberTypeSelect from "../old/selectors/member-type-select";
import ChoiceEditor from "./choice-editor";
import {DoubleCheckModal} from "../old/double-check-modal";
import TaxonomySelector from "./taxonomy-selector";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import {useDraggableInPortal} from "../advanced-table/sort/sort";
import {IconGrabHandle} from "../../icons/custom/GrabHandle";
import {at_array_move} from "../advanced-table/utilities";
import styled from "styled-components";
import {NewTooltip} from "./new-tooltip";
import {LanguageSelect} from "../old/selectors/language";
import {AdminImageEditorField} from "../custom/admin-image-editor-field";
import {CIRequired} from "../../icons/custom/Required";
import {CommunityContext} from "../../../app/community/community-context";
import {GlobalContext} from "../../../app/global/global-context";
import {PopupMenu} from "../old/popup-menu";
import {Popover2} from "@blueprintjs/popover2";
function Expander({
                      expanded = false, onExpand = () => {
    }
                  }) {

    return <div onClick={() => onExpand()}
                className="w-6 cursor-pointer h-6 flex items-center justify-center hover:text-gray-800 rounded hover:bg-gray-200 transition-colors">
        <div className="w-5 h-5 text-gray-500 ">
            {expanded ? <ChevronDownIcon/> : <ChevronLeftIcon/>}
        </div>
    </div>
}

function ImportantToggle({
                             important = false,
                             onToggle = () => {
                             }
                         }) {

    return <NewTooltip message={important ? "Required Field" : "Mark as Required"}>
        <div className={`${important ? "" : "hidden group-hover:block"}`}>
            <Button onClick={() => onToggle()}
                    left_icon={<RequiredIndicator icon={<CIRequired/>} important={important}/>} minimal/>
        </div>
    </NewTooltip>
}

const FieldsCont = styled.div`

  :hover .show-me {
    opacity: 100;
  }
`;

function getProfileFieldEditing(id, type, context, onChange, payload) {
    let v1, v2, v3;

    switch (type) {
        case 'profile-picture' :
            v1 = payload.url ? payload.url : DEFAULT_USER_PHOTO;
            return <div>
                <Field label="Default Profile Picture">
                    <AdminImageEditorField title="Default Profile Picture" dims="w-12 h-12" type="profile-picture"
                                           handleUrl={(url, color) => {
                                               onChange('profile-picture', 'profile-picture', {url, color})
                                           }} url={v1}/>
                </Field>
            </div>
        case 'cover-photo' :
            v1 = payload.url ? payload.url : DEFAULT_COVER_PHOTO;
            v2 = payload.color ? payload.color : "";
            return <div>
                <Field label="Default Cover Photo">
                    <div className="inline-block w-48">
                        <AdminImageEditorField show_selector title="Default Cover Photo" type="cover-photo"
                                               handleUrl={(url, color) => onChange('cover-photo', 'cover-photo', {
                                                   url,
                                                   color
                                               })}
                                               url={v1} color={v2}/>
                    </div>
                </Field>
            </div>;
        case 'member-type' :
            return <div>
                <Field label="Default Member Type">
                    <MemberTypeSelect value={payload.id} onChange={(val) => {
                        onChange('member-type', 'member-type', {id: val})
                    }}/>
                </Field>
            </div>;
        case 'checkbox' :
            return <div className="grid grid-cols-2 gap-4 pr-8">
                <Field label="Checked Label">
                    <Input placeholder="On" value={payload.options.checked_label} onChange={(v) => {
                        onChange(id, type, {
                            options: {
                                checked_label: v,
                                unchecked_label: payload.options.unchecked_label
                            }
                        })
                    }}/>
                </Field>
                <Field label="Unchecked Label">
                    <Input placeholder="Off" value={payload.options.unchecked_label} onChange={(v) => {
                        onChange(id, type, {
                            options: {
                                unchecked_label: v,
                                checked_label: payload.options.checked_label
                            }
                        })
                    }}/>
                </Field>
            </div>
        case 'display-name' :
            return <div>
                <Field label="Name Format">
                    <ManageDisplayName onChange={(val) => {
                        onChange('display-name', 'display-name', {format: val})
                    }} edit value={payload.format}/>
                </Field>
            </div>
        case 'language' :
            return <div>
                <Field label="Language">
                    <LanguageSelect onChange={(val) => {
                        onChange('language', 'language', {value: val})
                    }} edit value={payload.value}/>
                </Field>
            </div>
        case 'select' :
            v1 = payload.options.choices.filter(function (a) {
                return !a.hidden;
            }).map(it => it.text);
            return <div>
                <Field label="Options">
                    <ChoiceEditor initial={v1} onAddChoice={(arr) => {
                        let new_choices = [...payload.options.choices];

                        arr.forEach(a => {
                            const index = new_choices.map(a => a.text.toLowerCase()).indexOf(a.toLowerCase());

                            if (index === -1) {
                                new_choices.push({
                                    hidden: false,
                                    value: CUSTOM_NANOID(),
                                    text: a
                                });
                            } else {
                                new_choices[index].hidden = false;
                            }
                        });
                        let new_options = {...payload.options, choices: new_choices};

                        onChange(id, type, {options: new_options});
                    }} onRemoveChoice={(n) => {
                        const index = payload.options.choices.findIndex(a => a.text === n);
                        let new_choices = [...payload.options.choices];
                        new_choices.splice(index, 1);
                        let new_options = {...payload.options, choices: new_choices};
                        onChange(id, type, {options: new_options});
                    }} onRenameChoice={(old_text, new_text) => {
                        const index = payload.options.choices.findIndex(a => a.text === old_text);
                        let new_choices = [...payload.options.choices];
                        new_choices[index].text = new_text;
                        let new_options = {...payload.options, choices: new_choices};
                        onChange(id, type, {options: new_options});
                    }}/>
                </Field>
            </div>
        case 'multi-select' :
            v1 = payload.options.choices.filter(function (a) {
                return !a.hidden;
            }).map(it => it.text);
            return <div>
                <Field label="Options">
                    <ChoiceEditor initial={v1} onAddChoice={(arr) => {
                        let new_choices = [...payload.options.choices];

                        arr.forEach(a => {
                            const index = new_choices.map(a => a.text.toLowerCase()).indexOf(a.toLowerCase());

                            if (index === -1) {
                                new_choices.push({
                                    hidden: false,
                                    value: CUSTOM_NANOID(),
                                    text: a
                                });
                            } else {
                                new_choices[index].hidden = false;
                            }
                        });
                        let new_options = {...payload.options, choices: new_choices};

                        onChange(id, type, {options: new_options});
                    }} onRemoveChoice={(n) => {
                        const index = payload.options.choices.findIndex(a => a.text === n);
                        let new_choices = [...payload.options.choices];
                        new_choices.splice(index, 1);
                        let new_options = {...payload.options, choices: new_choices};
                        onChange(id, type, {options: new_options});
                    }} onRenameChoice={(old_text, new_text) => {
                        const index = payload.options.choices.findIndex(a => a.text === old_text);
                        let new_choices = [...payload.options.choices];
                        new_choices[index].text = new_text;
                        let new_options = {...payload.options, choices: new_choices};
                        onChange(id, type, {options: new_options});
                    }}/>
                </Field>
            </div>
        case 'select-taxonomy' :
            return <div>
                <Field label="Taxonomy">
                    <TaxonomySelector onChange={(v) => {
                        let new_options = {
                            ...payload.options,
                            taxonomy: v
                        };
                        onChange(id, type, {options: new_options});
                    }} value={payload.options.taxonomy}/>
                </Field>
            </div>
        case 'multi-select-taxonomy' :
            return <div>
                <Field label="Taxonomy">
                    <TaxonomySelector onChange={(v) => {
                        let new_options = {
                            ...payload.options,
                            taxonomy: v
                        };
                        onChange(id, type, {options: new_options});
                    }} value={payload.options.taxonomy}/>
                </Field>
            </div>
        default :
            return null;
    }
}

function AdvancedVisibilitySelector({handleSelect, selected, options}) {
    const selected_option = options.filter(function (a) {
        return a.value === selected;
    })[0];
    const trigger = <Button left_icon={<EyeIcon/>} text={selected_option.label}/>;
    return <RichSelect custom_trigger={trigger} onSelect={handleSelect} options={options} selected={selected}/>
}

function getHiddenComponent(hidden_key, hd, opts, toggleHidden) {
    const bool = opts.length === 0;

    return <div className="flex items-center">
        {hidden_key && !bool && <div>
            <AdvancedVisibilitySelector options={opts} selected={hd} handleSelect={v => toggleHidden(v)}/>
        </div>}
        {hidden_key && bool && <NewTooltip message={hd ? "Show Field" : "Hide Field"}>
            <div className="block">
                <Button size="sm" onClick={() => toggleHidden()}
                        left_icon={<div className={`h-svg-4 pl-0.5 ${hd ? "text-danger-600" : "text-gray-500"}`}>{hd ?
                            <EyeOffIcon/> : <EyeIcon/>}</div>} minimal/>
            </div>
        </NewTooltip>}
    </div>
}

function ProfileFieldItem({
                              name = '',
                              placeholder = "",
                              question = "",
                              hint = "",
                              set = "",
                              hidden = false,
                              hidden_key = "",
                              expanded = false,
                              can_toggle_visibility = false,
                              can_toggle_important = false,
                              important = false,
                              can_expand = false,
                              important_key,
                              can_edit_name = false,
                              value = {},
                              onDelete = () => {
                              },
                              visibility_options = [],
                              context = {},
                              onSetHidden = () => {
                              },
                              onExpand = () => {
                              },
                              onChangeName = () => {
                              },
                              onChangeHint = () => {},
                              onChangeQuestion = () => {},
                              onChangePlaceholder = () => {
                              },
                              id = '',
                              onChange = () => {
                              },
                              toggleImportant = () => {
                              },
                              can_delete = true,
                              type = 'email'
                          }) {
    const [editing, setEditing] = useState(false);
    const [field_name, setFieldName] = useState(name);
    const [qs, setQs] = useState(question);
    const [hd, setHd] = useState(hidden);
    const [pl, setPl] = useState(placeholder);
    const [ht, setHt] = useState(hint);
    let icon = custom_field_type_icons[type];
    let info = custom_field_types[type];

    if (!icon) {
        icon = <LinkIcon/>
    }
    if (!info) {
        info = {
            default_value: '',
            filterable: true,
            filter_type: 'string',
            filter_default_operator: 'starts_with',
            filter_default_value: '',
            example: '',
            edit_in_designer: false,
            label: 'Text',
            can_be_imported: true,
            import_format: 'string',
            export_format: 'string'
        };
    }

    function handleKeyDown(e) {
        if (e.key === 'Enter') {
            doneEditingName();
        }
    }

    function doneEditingName() {
        if (field_name.length > 0) {
            setEditing(false);
            onChangeName(id, field_name);
        }
    }

    function doneEditingQuestion() {
        if (qs.length > 0 && qs.length < 140) {
            setEditing(false);
            onChangeQuestion(id, qs);
        }
    }

    function doneEditingHint() {
        if (ht.length > 0 && ht.length < 140) {
            setEditing(false);
            onChangeHint(id, ht);
        }
    }

    function toggleHidden(v2) {
        if (v2) {
            setHd(v2);
            onSetHidden(id, v2);
        } else {
            const new_val = !hd;
            setHd(new_val)
            onSetHidden(id, new_val);
        }
    }

    function doneEditingPlaceholder() {
        if (pl.length > 0 && pl.length < 48) {
            setEditing(false);
            onChangePlaceholder(id, pl);
        }
    }

    return <div className={`space-y-1 group ${expanded ? "" : "hover:bg-gray-50"}`}>
        <div className={`flex px-2.5 py-1.5  space-x-0.5`}>
            <div className="flex-none pt-0.5">
                <div className="w-5 h-5">
                    <div className="w-4 h-4 text-gray-600">
                        {icon}
                    </div>
                </div>
            </div>
            <div className="flex-grow">
                <div className="flex space-x-2">
                    {!can_edit_name && <div className="text-sm text-gray-800 font-semibold">{field_name}</div>}
                    {can_edit_name && !editing && <div style={{minWidth: '220px'}} onClick={() => setEditing(true)}
                                                       className={`text-sm text-gray-800 font-semibold transition-colors rounded-md border cursor-pointer  ${expanded ? "border-white" : "border-white group-hover:border-gray-50"} hover:bg-gray-200 -my-px -mx-1 px-1 py-px `}>
                        {field_name}
                    </div>}
                    {can_edit_name && editing && <div className={''}>
                        <input onBlur={() => doneEditingName()} autoFocus onKeyDown={handleKeyDown}
                               className={`text-sm text-gray-800 font-semibold rounded-md border border-gray-300 shadow-sm -my-px -mx-1 px-1 py-px`}
                               value={field_name} onChange={(e) => setFieldName(e.target.value)}/>
                    </div>}

                </div>
                <div className="text-xs text-gray-500 flex space-x-2">
                    <div>{info.label}</div>
                </div>
            </div>
            <div className="flex space-x-1.5 items-center">
                {can_delete && <NewTooltip message={"Delete Field"}>
                    <div className="hidden group-hover:block">
                        <Button size="sm" onClick={() => onDelete()}
                                left_icon={<div className="h-svg-4"><TrashIcon/></div>} minimal/>
                    </div>
                </NewTooltip>}
                {can_toggle_important && !hd && <ImportantToggle important_key={important_key} onToggle={() => {
                    if (can_toggle_important) {
                        toggleImportant(important_key);
                    }
                }} important={important}/>}
                {getHiddenComponent(hidden_key, hd, visibility_options, toggleHidden)}
                {can_expand && <div className="flex-none pr-0.5 pt-1">
                    <div className="pt-px">
                        <Expander onExpand={() => onExpand()} expanded={expanded}/>
                    </div>
                </div>}
            </div>
        </div>
        {expanded && can_expand && <div className="pr-2.5 pl-8 pb-2 grid grid-cols-1 gap-2">
            {getProfileFieldEditing(id, type, context, onChange, value)}
            {set !== 'system' && <div className="space-y-2">
                <Field label="Placeholder" help_text="">
                    <Input custom={{minLength: 0, maxLength: 48}} value={pl} onBlur={() => doneEditingPlaceholder()}
                           onChange={v => {
                               const fv = v.substr(0, 48);
                               setPl(fv);
                           }} placeholder="Add a placeholder"/>
                </Field>
                <Field label="Hint" help_text="How should members fill out this field?">
                    <Input custom={{minLength: 0, maxLength: 140}} value={ht} onBlur={() => doneEditingHint()}
                           onChange={v => {
                               setHt(v.substr(0, 140));
                           }} placeholder="Add a hint"/>
                </Field>
                <Field label="Question" help_text="How would you phrase this as a question?">
                    <Input custom={{minLength: 0, maxLength: 140}} value={qs} onBlur={() => doneEditingQuestion()}
                           onChange={v => {
                               setQs(v.substr(0, 140));
                           }} placeholder="Add a Question"/>
                </Field>
            </div>}
        </div>}
    </div>
}

export const social_fields_array = [
    'website', 'twitter', 'eth_address', 'linkedin', 'facebook', 'reddit', 'discord', 'instagram', 'skype', 'github'
];

export function ProfileFieldsSection({
                                         title = "General",
                                         onDelete = () => {
                                         },
                                         onMoveUp,
    onMoveDown,
                                         init_visibility = 'system',
                                         id = "",
                                         expanded = false,
                                         can_edit_name = false,
                                         can_delete_fields = false,
                                         save_endpoint = '/',
                                         visibility_key = '',
                                         can_toggle_important = false,
                                         can_delete_section = false,
                                         can_add_field = true,
                                         init_field_ids = [],
                                         init_field_data = {},
                                         all_fields_data = {},
                                         context = {},
                                         onExpand = () => {
                                         },
                                         updateSection = () => {
                                         },
                                         onChangeName = () => {
                                         }
                                     }) {

    const [expanded_fields, setExpandedFields] = useState([]);
    const community = useContext(CommunityContext);
    const global = useContext(GlobalContext);
    const [name, setName] = useState(title);
    const [modal, setModal] = useState('');
    const [selected_field, setSelectedField] = useState('');
    const [visibility, setVisibility] = useState(init_visibility);
    const [fields, setFields] = useState(init_field_ids);
    const [field_data, setFieldsData] = useState(init_field_data);
    const [editing, setEditing] = useState(false);
    const [loading, setLoading] = useState('');
    const [changes, setChanges] = useState({});

    function saveChanges() {
        setLoading('saving');

        let updates = [], data = {
            id, fields, field_data, name, visibility, desc: ''
        };

        const changes_entries = Object.entries(changes);

        for (let i = 0; i < changes_entries.length; i++) {
            const [ceid, cvalue] = changes_entries[i];
            if (ceid === 'profile-picture') {
                updates.push({
                    field: 'design_defaults.member_profile_picture',
                    value: cvalue.url
                });
                updates.push({
                    field: 'design_defaults.member_profile_picture_color',
                    value: cvalue.color
                });
            } else if (ceid === 'language') {
                updates.push({
                    field: 'preferences.language',
                    value: cvalue.value
                });
                updates.push({
                    field: 'design_defaults.member_profile_picture_color',
                    value: cvalue.color
                });
            } else if (ceid === 'address') {
                updates.push({
                    field: 'preferences.members.address_visibility',
                    value: cvalue.hidden
                });
            } else if (ceid === 'birthday') {
                updates.push({
                    field: 'preferences.members.birthday_visibility',
                    value: cvalue.hidden
                });
            } else if (ceid === 'gender') {
                updates.push({
                    field: 'preferences.members.hide_gender',
                    value: cvalue.hidden
                });
            } else if (social_fields_array.includes(ceid)) {
                updates.push({
                    field: `preferences.members.hide_${ceid}`,
                    value: cvalue.hidden
                });
            } else if (ceid.startsWith("imp_")) {
                updates.push({
                    field: `preferences.members.${ceid}`,
                    value: cvalue
                });
            } else if (ceid === 'cover-photo') {
                updates.push({
                    field: 'design_defaults.member_cover_photo',
                    value: cvalue.url
                });
                updates.push({
                    field: 'design_defaults.member_cover_photo_color',
                    value: cvalue.color
                });
            } else if (ceid === 'member-type') {
                updates.push({
                    field: 'preferences.members.default_member_type',
                    value: cvalue.id
                });
            } else if (ceid === 'display-name') {
                updates.push({
                    field: 'preferences.members.display_name',
                    value: cvalue.format
                });
            } else if (ceid === 'visibility') {
                updates.push({
                    field: visibility_key,
                    value: cvalue.value
                });
            } else if (ceid === 'name') {
                updates.push({
                    field: 'name',
                    value: cvalue.value
                });
            } else {
                data = {
                    visibility,
                    name,
                    desc: '',
                    id,
                    fields,
                    field_data
                };
            }
        }

        let payload = {
            community_uid: context.community.uid,
            member_id: context.community.member_id,
            user_uid: context.community.member.user_uid,
            updates,
            data
        };

        const res = () => {
            setChanges({});
            setLoading('');
            context.global.addToast({text: 'Changes Saved', intent: 'success'});
        }
        console.log("payload", payload);
        authFetch(save_endpoint, res, res, "POST", {payload});
    }

    function handleKeyDown(e) {
        if (e.key === 'Enter') {
            doneEditingName();
        }
    }

    function doneEditingName() {
        if (name.length > 0) {
            setEditing(false);
            onChangeName(name);
            let nc = {...changes};
            nc['name'] = {value: name};
            setChanges(nc);
        }
    }

    function handleChange(id, field, payload) {
        // this is for the changes to save
        let nc = {...changes};
        nc[id] = payload;
        setChanges(nc);

        // now update the data for us now as well
        let nfd = {...field_data};
        nfd[id].value = payload;
        setFieldsData(nfd);
        updateSection({
            title: name,
            can_edit_name,
            can_toggle_important,
            can_delete_fields,
            can_delete_section,
            can_add_field,
            visibility_key,
            init_visibility,
            save_endpoint,
            init_field_ids: fields,
            init_field_data: field_data
        });
    }

    function handleChangeQuestion(id, question) {
        let nc = {...changes};
        nc[id] = {question: question};
        setChanges(nc);
        let nfd = {...field_data};
        nfd[id].question = question;
        setFieldsData(nfd);
    }

    function handleChangeHint(id, hint) {
        let nc = {...changes};
        nc[id] = {hint: hint};
        setChanges(nc);
        let nfd = {...field_data};
        nfd[id].hint = hint;
        setFieldsData(nfd);
    }

    function handleSetHidden(id, nhd) {
        let nc = {...changes};
        nc[id] = {hidden: nhd};
        setChanges(nc);
        let nfd = {...field_data};
        nfd[id].hidden = nhd;
        setFieldsData(nfd);
    }

    function handleChangePlaceholder(id, placeholder) {
        let nc = {...changes};
        nc[id] = {placeholder};
        setChanges(nc);
        let nfd = {...field_data};
        nfd[id].placeholder = placeholder;
        setFieldsData(nfd);
    }

    function handleChangeName(id, name) {
        let nc = {...changes};
        nc[id] = {name};
        setChanges(nc);
        let nfd = {...field_data};
        nfd[id].name = name;
        setFieldsData(nfd);
    }

    function handleAddField(p) {
        const {name, type} = p;

        const new_id = CUSTOM_NANOID();

        // update data
        let nfd = {...field_data};
        nfd[new_id] = {
            name,
            type,
            new_field: true,
            placeholder: '',
            hint: '',
            question: '',
            important: false,
            can_edit_name: true,
            can_expand: true,
            original: {
                options: {},
                id: new_id,
                parent_id: '',
                name,
                type,
                desc: '',
                required: false,
            },

            value: {
                options: {},
            },

        };

        if (type === 'number') {
            const number_field_type_count = Object.values(all_fields_data).filter(f => f.type === 'number').length;
            if (community.data.cf_number_primary) {
                nfd[new_id].is_secondary = true;
            } else {
                nfd[new_id].is_primary = true;
            }
        }

        if (type === 'select-taxonomy' || type === 'multi-select-taxonomy') {
            nfd[new_id].value.options.taxonomy = '';
        } else if (type === 'select' || type === 'multi-select') {
            nfd[new_id].value.options.choices = [];
        }
        // update ids
        let nfids = [...fields];
        nfids.push(new_id);

        // mentees field
        if (type === 'mentor') {
            const mentee_id = CUSTOM_NANOID();
            nfids.push(mentee_id);
            nfd[mentee_id] = {
                name: 'Mentees',
                type: 'mentees',
                new_field: true,
                placeholder: 'Mentee',
                question: '',
                hint: '',
                important: false,
                can_edit_name: true,
                original: {
                    name: 'Mentees',
                    desc: '',
                    type: 'mentees',
                    parent_id: new_id,
                    required: false,
                    id: mentee_id,
                    options: {},
                },
                value: {
                    options: {},
                },
            };
        }

        setFields(nfids);
        setFieldsData(nfd);

        // auto-expand field
        let naef = [...expanded_fields];
        naef.push(new_id);
        setExpandedFields(naef);

        // update changes
        let nc = {...changes};
        nc['field_data'] = nfd;
        nc['fields'] = nfids;
        setChanges(nc);
    }

    function handleDragEnd({source, destination}) {
        if (!destination) return;
        let nfids = at_array_move([...fields], source.index, destination.index);

        setFields(nfids);

        let nc = {...changes};
        nc['fields'] = nfids;
        setChanges(nc);
    }

    const container = ``;
    const body = ` ${can_add_field ? "border-l border-r" : "border-l border-r border-b rounded-b-md"}`;

    const has_unsaved_changes = Object.keys(changes).length > 0;

    // reordering.. can_add_field
    const renderDraggable = useDraggableInPortal();

    function renderFields() {
        if (can_add_field && fields.length > 1) {
            return <FieldsCont>
                <DragDropContext onDragEnd={handleDragEnd}>
                    <Droppable droppableId={`dnd-${id}`}>
                        {(provided) => (
                            <div {...provided.droppableProps} ref={provided.innerRef}>
                                {fields.map((f, k) => {
                                    const dt = field_data[f];
                                    return <Draggable isDragDisabled={false} key={`${f}`} draggableId={f}
                                                      index={k}>
                                        {renderDraggable((provided) => {
                                            return <div className="relative" ref={provided.innerRef}
                                                        key={f} {...provided.draggableProps}>
                                                <ProfileFieldItem
                                                    onChangeName={handleChangeName}
                                                    onChangeHint={handleChangeHint}
                                                    onChangeQuestion={handleChangeQuestion}
                                                    onSetHidden={handleSetHidden}
                                                    onChangePlaceholder={handleChangePlaceholder}
                                                    onChange={handleChange}
                                                    context={context}
                                                    onDelete={() => {
                                                        setSelectedField(f);
                                                        setModal('delete-field');
                                                    }}
                                                    toggleImportant={() => {

                                                        let nfdata = {...field_data};
                                                        nfdata[f].important = !nfdata[f].important;
                                                        setFieldsData(nfdata);

                                                        if (dt.important_key) {
                                                            let nc = {...changes};
                                                            nc[dt.important_key] = !nc[dt.important_key];
                                                            setChanges(nc)
                                                            return;
                                                        }

                                                        let nc = {...changes};
                                                        nc['field_data'] = nfdata;
                                                        setChanges(nc);
                                                    }}
                                                    expanded={expanded_fields.indexOf(f) !== -1}
                                                    onExpand={() => {
                                                        let ne = [...expanded_fields];
                                                        if (expanded_fields.indexOf(f) !== -1) {
                                                            ne.splice(expanded_fields.indexOf(f), 1);
                                                        } else {
                                                            ne.push(f);
                                                        }
                                                        setExpandedFields(ne);
                                                    }} key={f}
                                                    can_delete={can_delete_fields && dt.type !== 'mentees'}
                                                    can_toggle_important={can_toggle_important}
                                                    id={f} {...dt} />
                                                <div
                                                    className='w-5 z-50 show-me top-1.5 pt-px opacity-0 transition-opacity left-2 bg-white text-gray-500 hover:text-gray-800 h-5 absolute' {...provided.dragHandleProps}>
                                                    <IconGrabHandle/>
                                                </div>
                                            </div>
                                        })}
                                    </Draggable>
                                })}

                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
            </FieldsCont>;

        } else {
            return fields.map((f, k) => {
                const dt = field_data[f];
                return <ProfileFieldItem onChangeName={handleChangeName}
                                         onChangeHint={handleChangeHint}
                                         onChangeQuestion={handleChangeQuestion}
                                         onSetHidden={handleSetHidden}
                                         onChangePlaceholder={handleChangePlaceholder}
                                         onChange={handleChange} context={context}
                                         onDelete={() => {
                                             setSelectedField(f);
                                             setModal('delete-field');
                                         }}
                                         toggleImportant={() => {
                                             let nfdata = {...field_data};
                                             nfdata[f].important = !nfdata[f].important;
                                             setFieldsData(nfdata);

                                             if (dt.important_key) {
                                                 let nc = {...changes};
                                                 nc[dt.important_key] = !nc[dt.important_key];
                                                 setChanges(nc)
                                                 return;
                                             }

                                             let nc = {...changes};
                                             nc['field_data'] = nfdata;
                                             setChanges(nc);
                                         }}
                                         expanded={expanded_fields.indexOf(f) !== -1} onExpand={() => {
                    let ne = [...expanded_fields];
                    if (expanded_fields.indexOf(f) !== -1) {
                        ne.splice(expanded_fields.indexOf(f), 1);
                    } else {
                        ne.push(f);
                    }
                    setExpandedFields(ne);
                }} key={f} can_delete={can_delete_fields && dt.type !== 'mentees'}
                                         can_toggle_important={can_toggle_important} id={f} {...dt} />
            })
        }
    }

    let opts = [];

    if(onMoveUp) {
        opts.push({
            title: 'Move up',
            onClick: onMoveUp
        });
    }

    if(onMoveDown) {
        opts.push({
            title: 'Move down',
            onClick: onMoveDown
        });
    }
    if(can_delete_section) {
        opts.push({
            title: 'Delete Section',
            onClick: () => {
                setModal('delete-section')
            }
        });
    }

    return <div className={` ${container}`}>
        <div
            className={`rounded-t-md space-x-2 px-3 py-1.5 flex border group border-gray-200 ${!expanded ? "rounded-b-md bg-gray-50 hover:bg-white" : "bg-white"}`}>
            <div className="flex-grow flex items-center">
                {!can_edit_name && <div
                    className={`text-lg rounded-md border ${expanded ? "border-white" : "border-gray-50 group-hover:border-white"} text-gray-800 font-semibold`}>
                    {name}
                </div>}
                {can_edit_name && !editing && <div style={{minWidth: '220px'}} onClick={() => setEditing(true)}
                                                   className={`text-lg transition-colors rounded-md border cursor-pointer ${expanded ? "border-white" : "border-gray-50 group-hover:border-white"} hover:bg-gray-200 -my-0.5 -mx-1.5 px-1.5 py-0.5 text-gray-800 font-semibold`}>
                    {name}
                </div>}
                {can_edit_name && editing && <div className={` `}>
                    <input onBlur={() => doneEditingName()} autoFocus onKeyDown={handleKeyDown}
                           className={`text-lg rounded-md border border-gray-300 shadow-sm -my-0.5 -mx-1.5 px-1.5 py-0.5 text-gray-800 font-semibold`}
                           value={name} onChange={(e) => setName(e.target.value)}/>
                </div>}
            </div>
            <div className="flex space-x-1 items-center">
                {has_unsaved_changes && <div>
                    <Button size="sm" loading={loading === 'saving'} intent="success" text="Save"
                            onClick={() => saveChanges()}/></div>}
                {opts.length>0 &&<div>
                    <Popover2 usePortal={false} placement={'bottom-start'} content={<PopupMenu items={opts}/>} minimal
                              popoverClassName='minimal-popover my-2'>
                    <Button size="sm" minimal
                                 left_icon={<div className="h-svg-5"><DotsHorizontalIcon/></div>}/>

                    </Popover2></div>}

                <ProfileSectionVisibilitySelector layout={'icon-label'} selection={visibility} onSelect={(v) => {
                    let nc = {...changes};
                    nc['visibility'] = {value: v};
                    setChanges(nc);
                    setVisibility(v);
                }}/>
                {(fields.length > 0 || can_add_field) &&
                    <div className="flex items-center justify-center" style={{height: '34px', width: '24px'}}>
                        <Expander onExpand={() => onExpand()} expanded={expanded}/>
                    </div>}
            </div>
        </div>
        {expanded && <div className={`bg-white divide-y divide-gray-200 ${body}`}>
            {fields.length === 0 && <div className="text-sm text-gray-500 p-4 text-center">
                No fields found
            </div>}
            {renderFields()}
        </div>}
        {expanded && can_add_field &&
            <AddCustomField all_fields_data={all_fields_data}
                            existing_names={Object.values(field_data).map(fd => fd.name)} handleAdd={handleAddField}/>}
        {modal !== '' && <DoubleCheckModal onConfirm={() => {

            if (modal === 'delete-field') {
                let nfids = [...fields];
                nfids.splice(fields.indexOf(selected_field), 1);

                let nfdata = {...field_data};
                delete nfdata[selected_field];
                // also remove mentees field if needed
                if (field_data[selected_field].type === 'mentor') {
                    const query = Object.values(field_data).filter(function (a) {
                        return a.original.parent_id === selected_field;
                    })[0];
                    nfids.splice(nfids.indexOf(query.id), 1);
                    delete nfdata[query.id];
                }

                setFields(nfids);
                setFieldsData(nfdata);
                setSelectedField('');
                setModal('');

                // update changes
                let nc = {...changes};
                nc['field_data'] = nfdata;
                nc['fields'] = nfids;
                setChanges(nc);
            } else {
                onDelete({id});
                setModal('');
            }
        }} onCancel={() => {
            setSelectedField('');
            setModal('');
        }} type={modal === 'delete-field' ? 'delete_profile_field' : 'delete_profile_section'}/>}
        <Prompt when={has_unsaved_changes} message={'Leaving the page will cause any unsaved changes to be lost.'}/>
    </div>
}