import {CUSTOM_NANOID, STORAGE_BASE_URL} from "../../../../config/defaults";
import Pica from "pica";
import firebase from "firebase";
import analyze from "rgbaster";

export function zoomOut() {
    const scale = this.state.scale;
    let newScale = scale - 0.1;

    if (scale < 0.2) {

    } else {
        this.setState({
            scale: newScale
        })
    }
}

export function zoomIn() {
    const scale = this.state.scale;
    let newScale = scale + 0.1;

    if (scale > 3) {

    } else {
        this.setState({
            scale: newScale
        })
    }
}

export function rotateLeft() {
    const rotation = this.state.rotate;

    if (rotation === 0) {
        this.setState({
            rotate: 270
        })
    } else {
        this.setState({
            rotate: rotation - 90
        })
    }
}

export function rotateRight() {
    const rotation = this.state.rotate;

    if (rotation === 270) {
        this.setState({
            rotate: 0
        })
    } else {
        this.setState({
            rotate: rotation + 90
        })
    }
}

export function handlePositionChange(position) {
    const { dragging, shift_key_pressed, move_start_x, move_start_y } = this.state;
    if(dragging&&shift_key_pressed) {
        // need to get deltas
        const dy = Math.abs(position.y-move_start_y);
        const dx = Math.abs(position.x-move_start_x);
        let new_position = position;
        if(dy>dx) {
            new_position.x = move_start_x;
            this.setState({
                position: new_position
            });
        } else {
            new_position.y = move_start_y;
            this.setState({
               position: new_position
            });
        }
    } else if(dragging) {
        this.setState({position})
    } else {
        this.setState({
            position,
            move_start_x: position.x,
            move_start_y: position.y,
            dragging: true
        })
    }
}

export async function imageReady() {
    if (this.editor) {
        const canvasScaled = this.editor.getImageScaledToCanvas();
        const canvasScaled1 = this.editor.getImageScaledToCanvas();

        const context = canvasScaled.getContext('2d');
        context.save();
        context.globalCompositeOperation = "destination-over";
        context.fillStyle = 'rgba(255, 255, 255, 1)';
        context.fillRect(0, 0, canvasScaled.width, canvasScaled.height);
        context.restore();


        const context2 = canvasScaled1.getContext('2d');
        context2.save();
        context2.globalCompositeOperation = "destination-over";
        context2.fillStyle = 'rgba(255, 255, 255, 1)';
        context2.fillRect(0, 0, canvasScaled1.width, canvasScaled1.height);
        context2.restore();


        let canvasMedium = this.mediumCanvas.current;
        let canvasSmall = this.smallCanvas.current;

        await new Promise((resolve, reject) => {
            this.pica.resize(canvasScaled, canvasMedium, this.sharpness.medium).then(() => {
                this.pica.resize(canvasScaled1, canvasSmall, this.sharpness.small).then(() => {

                    resolve();
                })
            });
        }).catch((error) => {
            console.error("ERROR", error);
        });

        return canvasScaled;

    } else {
        return null;
    }
}

function cloneCanvas(oldCanvas) {

    //create a new canvas
    var newCanvas = document.createElement('canvas');
    var context = newCanvas.getContext('2d');

    //set dimensions
    newCanvas.width = oldCanvas.width;
    newCanvas.height = oldCanvas.height;

    //apply the old canvas to the new one
    context.drawImage(oldCanvas, 0, 0);

    //return the new canvas
    return newCanvas;
}


export async function startUpload() {
    if (this.state.uploading) {
        return;
    }
    this.setState({
        uploading: true
    });

    if (this.pica === null) {
        this.pica = Pica();
    }



    // need to generate image sizes first
    const originalCanvas = await this.imageReady();

    const color_results = await analyze(originalCanvas.toDataURL(), {
        scale: 0.4,
        ignore: ['rgb(255,255,255)', 'rgb(0,0,0)']
    });

    const dominant_color = color_results[0].color;

    const uid = CUSTOM_NANOID();
    // save uid
    let p = [];
    p.push(this.uploadFile(originalCanvas, uid, true));
    p.push(this.uploadFile(this.smallCanvas.current, uid + '_small'));
    p.push(this.uploadFile(this.mediumCanvas.current, uid + '_medium'));
    /*
    await this.uploadFile(originalCanvas, uid, true);

    await this.uploadFile(this.smallCanvas.current, uid + '_small');

    await this.uploadFile(this.mediumCanvas.current, uid + '_medium');

     */

    await Promise.all(p);
    // close modal, reset

    let new_url = STORAGE_BASE_URL + this.folder + "%2F" + uid;

    setTimeout(() => {
        this.setState({
            progress: 0,
            uploading: false,
            files: [],
            open: false
        }, () => {
            this.notifyParent(new_url, dominant_color);
        })
    }, 1500);

}

export async function uploadFile(canvas, name, trackProgress = false) {

    await this.pica.toBlob(canvas, 'image/png', 1.00)
        .then(blob => {
            const task = this.storage.child(this.folder + '/' + name).put(blob, {
                contentType: 'image/png',
            });

            task.on(
                firebase.storage.TaskEvent.STATE_CHANGED,
                snap => {
                    // provide progress updates
                    if (trackProgress) {

                        const progress = (snap.bytesTransferred / snap.totalBytes);

                        if (progress > 0.2) {
                            this.setState({
                                progress
                            })
                        }
                    }
                    // progress(true, snap.bytesTransferred, snap.totalBytes)
                },
                err => {
                    // provide errors
                    console.error(err.message)
                    return false;
                },
                () => {
                    // the file has been uploaded

                    this.storage
                        .child(this.folder + '/' + name)
                        .getDownloadURL()
                        .then((url) => {
                            return true;
                        })
                        .catch((error) => {
                            return false;
                        })
                }
            );
        });
}