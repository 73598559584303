import React, {useState} from 'react';
import {InterestTag,Field,InterestItem} from '../../core';
import styled from "styled-components";
import {HeartIcon} from '@heroicons/react/solid';
import {AddInterest} from "./add-interest";
import {copyTextToClipboard} from "../../../utilities/general/copy-to-clipboard";
import {interests_data} from "../../core/interests-data";
const Icon = styled.div`
      
    > svg {
        height: 20px;
    }
`;


export default function SGInterestsPage() {
    const [wobble,setWobble] = useState(false);
    const [interests,setInterests] = useState(interests_data);
    return <div className="space-y-4">
        <div>
            <div className="text-3xl font-bold text-gray-800">
                Discover members with <span className="relative"><span className="relative z-10 text-gray-800">mutual</span><span className="absolute h-2 rounded-md bottom-0.5 -left-0.5 -right-0.5 bg-gradient-to-br from-yellow-400 to-orange-500" /></span> interests.
            </div>
        </div>
        <div>
            <div className="flex items-center h-5">
                <Icon>
                    <HeartIcon fill="url(#orange-gradient)" />
                </Icon>
                <div className="pl-1.5 text-gray-500">
                    <span className="text-gray-500">Golf</span>,&nbsp;
                    <span className="text-gray-700 font-semibold">Tennis</span>,&nbsp;
                    <span className="text-gray-500">Brunch</span>
                </div>
            </div>
        </div>
        <div>
            <div className="flex items-center h-5">
                <Icon className="text-gray-500">
                    <HeartIcon />
                </Icon>
                <div className="pl-1.5 text-gray-500">
                    <span className="text-gray-500">Golf</span>,&nbsp;
                    <span className="text-gray-500">Tennis</span>,&nbsp;
                    <span className="text-gray-500">and 3 other interests</span>
                </div>
            </div>
        </div>
        <div>
            <a onClick={()=>copyTextToClipboard(JSON.stringify(interests))}>Copy to Clipboard</a>
        </div>
        <div>
            <AddInterest addInterest={(interest)=>setInterests([...interests,interest])} />
        </div>
        <div>
            <InterestItem />
        </div>
        <div>
            <a onClick={()=>setWobble(!wobble)}>Edit Interests</a>
        </div>
        <div className={`flex flex-wrap interest-tag-spacing max-w-3xl ${wobble?"wobble-items":""}`}>
            {interests.map((int,i)=><InterestTag onRemove={()=>{}} can_remove={wobble} active={i%3===0} key={int.id} {...int} />)}
        </div>
        <div>
            <Field help_text={<a>Explore other interests</a>} label="Interests" corner_hint={<a>Edit Interests</a>}>
                <div className={`flex flex-wrap interest-tag-spacing max-w-3xl -mb-2`}>
                <InterestTag active />
                <InterestTag active {...interests[3]} />
                <InterestTag active {...interests[9]} />
                </div>
            </Field>
        </div>
        <div>
            <Field help_text={<a>Explore other interests</a>} label="Interests" corner_hint={<a>Edit Interests</a>}>
                <div className="py-2 text-gray-600">No interests selected yet</div>
            </Field>
        </div>
    </div>
}