import React, {useContext} from "react";
import AppSidebar from "./left-sidebar";
import {FullScreenLoader} from "../old/full-screen-loader";
import {CommunityPolicyGate} from "../../../app/policy-gates/community";
import {AppTopbar} from "./topbar";
import {RightContextMenu} from "../../../app/global/right-context-menu";
import {UserPolicyGate} from "../../../app/policy-gates/user";
import {GlobalContext} from "../../../app/global/global-context";
import {OwnerPolicyGate} from "../../../app/policy-gates/owner";
import {GlobalHotKeys} from "react-hotkeys";
import {MobileNavbar} from "../../../app/mobile/navbar";
import {buildSidebarTheme} from "../../utilities/general/colors";
import {useIsMobile} from "../../../app/global/global-router";
import {FileViewerGlobal} from "../../../app/global/file-viewer";
import {ImageViewerGlobal} from "../../../app/global/image-viewer";
import {AppFrameLoading} from "../../playground/app-frame-loading";
import {LatestFrame} from "../latest-frame";
import {MemberArchived} from "../../../app/community/states/archived";

function getTheme(ctx, community) {
    if (ctx !== 'community') {
        return buildSidebarTheme('#ff6b00','#f15f31');
    }
    return buildSidebarTheme(community.branding.color,community.branding.topbar_bg||"#ffffff");
}

export default function AppFrame(props) {
    const global = useContext(GlobalContext);
    const {
        is_admin,
        styles,
        context,
        user,
        member,
        community,
        community_uid,
        starred_smart_views = {},
        all_teams = {},
        all_roles = {},
        groups,
        teams,
        my_groups,
        community_data,
        is_member,
        member_id = '',
        qs_results,
        is_owner,
        roles,
        member_types,
        history,
        logged_in,
        memberships
    } = props;

    const is_mobile = useIsMobile();

    if (context === 'community' && !member && is_member) {
        console.error('In a community without a member object!');
    }

    if (context === 'community' && member === null && is_member) {
        // this seems like it's wrong
        return <AppFrameLoading/>;
    }

    // don't show on onboarding screens
    const show_community_gates = context === 'community' && props.history.location.pathname.indexOf('/onboarding') === -1;

    if (context === 'global' && !user) {
        const t = getTheme('global')
        return <LatestFrame theme={t} topbar={<AppTopbar {...props} {...{theme:t}}/>} extras={null}
                            sidebar={<AppSidebar bg={"#ffffff"} is_admin={is_admin} community_uid={community_uid} teams={teams} roles={roles}
                                                 groups={groups} community={{}} my_groups={my_groups}
                                                 user_permissions={{}} community_handle={''}
                                                 logged_in={logged_in} member_id={member_id}
                                                 communities={memberships}
                                                 member_type={''} all_teams={all_teams} all_roles={all_roles}
                                                 handle={""} member={member} theme={t}
                                                 user={user} {...props} />}>
            {props.children}
        </LatestFrame>
        return <div className="relative h-screen">
            <div className="grid unaty-frame top-padded">
                <div className="sidebar-bkg-left"/>
                <AppSidebar bg={"#ffffff"} is_admin={is_admin} community_uid={community_uid} teams={teams} roles={roles}
                            groups={groups} community={{}} my_groups={my_groups}
                            user_permissions={{}} community_handle={''}
                            logged_in={logged_in} member_id={member_id}
                            communities={memberships}
                            member_type={''} all_teams={all_teams} all_roles={all_roles}
                            handle={""} member={member} theme={getTheme('global')}
                            user={user} {...props} />

                <div
                    className={`content-bkg overflow-y-hidden overflow-x-hidden ${is_mobile ? "mobile-page-height" : "h-screen"}  flex flex-col relative`}>
                    {props.children}
                </div>
                <div className='content-bkg'/>
            </div>
        </div>

    }

    const keyMap = {
        SEARCH: "cmd+k"
    };

    const handlers = {
        SEARCH: () => {
            // ensure no modals are open
            let modals = document.querySelectorAll("div.bp4-overlay.bp4-overlay-open");
            if (modals.length === 0) {
                global.openSearch()
            } else {
                global.closeSearch()
            }
        }
    };

    let search_community_ctx = ['member'];

    if (is_admin) {
        search_community_ctx.push('admin');
    }

    if (is_owner) {
        search_community_ctx.push('owner');
    }

    const search_props = context === 'community' ? {
        click_ctx: {
            user_handle: global.handle,
            community_handle: community.handle,
            member_handle: member ? member.handle : ''
        },
        qs_results,
        search_ctx: search_community_ctx,
        ctx_id: community_uid
    } : {
        click_ctx: {
            user_handle: global.handle
        },
        qs_results,
        search_ctx: ['user'],
        ctx_id: 'global'
    };

    if(context==='community'&&member&&member.account_status&&member.account_status==='archived') {
        return <MemberArchived />
    }

    const theme = getTheme(context, community);

    const extras = <>
        <RightContextMenu/>
        <UserPolicyGate user={global.user} global={global}/>
        {show_community_gates &&
        <CommunityPolicyGate global={global} member_id={member_id} community_uid={community_uid} member={member}
                             community={community}/>}
        {show_community_gates &&
        <OwnerPolicyGate member_id={member_id} global={global} community_uid={community_uid} member={member}
                         community={community}/>}
        <GlobalHotKeys keyMap={keyMap} handlers={handlers}/>
        {is_mobile &&
        <MobileNavbar location={props.location} communities={memberships} context={context} theme={theme}
                      history={history}
                      handle={context === 'community' && is_member ? (member ? member.handle : "") : user.handle}
                      member={member}
                      community_handle={context === 'community' ? community.handle : ''}
                      user={user} {...props} />}
        <FileViewerGlobal/>
        <ImageViewerGlobal/>
    </>;

    const comp_props = {
        is_member,
        member,
        user,
        community_handle: context === 'community' ? community.handle : '',
        space_name: context === 'community' ? community.name : 'the Unaverse',
        search_props,
        teams,
        groups,
        roles,
        community,
        theme,
        logged_in,
        starred_smart_views: starred_smart_views,
        communities: memberships,
        my_groups: my_groups,
        all_teams: all_teams,
        all_roles,
        user_permissions: {},
        community_data: community_data,
        is_admin,
        styles,
        is_mobile,
        is_owner,
        bg: "#fff",
        community_uid: community_uid,
        member_id: member_id,
        member_type: context === 'community' && is_member ? (member.member_type[member.member_type] ? member_types[member.member_type].singular : '') : '',
        handle: context === 'community' && is_member ? member.handle : user.handle
    };

    return (
        <LatestFrame theme={theme} topbar={<AppTopbar {...props} {...comp_props}/>} extras={extras}
                    sidebar={<AppSidebar {...props} {...comp_props} />}>
            {props.children}
        </LatestFrame>
    );
}