import React from 'react';
import {FirebaseDB} from "../../../config/setup-firestore";
import {getFieldExportValue} from "../../../library/utilities/community/custom-fields";
import {
    buildElasticsearchQuery,
    directoryMembersQuery
} from "../../../library/components/advanced-table/build-elasticsearch-query";
import {getAllMemberData} from "../../../library/components/advanced-table";

export function buildMemberNameReference(docs) {
    let obj = {};
    for (let i = 0; i < docs.length; i++) {
        const doc = docs[i];
        obj[doc.id] = doc.data().name;
    }
    return obj;
}

function getOptions(type, field, ex) {
    let obj = {}, entries = [], temp;
    switch (type) {
        case 'member': {
            return ex.name_ref;
        }
        case 'mentor': {
            return ex.name_ref;
        }
        case 'mentees': {
            return ex.name_ref;
        }
        case 'multi-member': {
            return ex.name_ref;
        }
        case 'checkbox': {
            temp = ex.cp_fields[field.replace('custom_fields.', '')];
            return !temp ? {unchecked_label: 'false', checked_label: 'true'} : temp.options;
        }
        case 'multi-select': {
            temp = ex.cp_fields[field.replace('custom_fields.', '')];

            return !temp ? {} : temp.choices_map;
        }
        case 'select': {
            temp = ex.cp_fields[field.replace('custom_fields.', '')];

            return !temp ? {} : temp.choices_map;
        }
        case 'member-type': {
            entries = Object.entries(ex.member_types);
            for (let i = 0; i < entries.length; i++) {
                const [id, item] = entries[i];
                obj[id] = item.singular;
            }
            return obj;
        }
        default : {
            return {};
        }
    }
}

function cleanValues(v,regexp) {
    v = v.replace(regexp,'');
    v = v.replace(/↵/g,' ');
    v = v.replace(/(?:\r\n|\r|\n)/g, ' ');
    return v;
}

export function EXPORTtransformMember(member, id, headers, ex = {}, format, name_map, cf_label_map) {
    let obj = {};

    for (let i = 0; i < headers.length; i++) {
        const item = headers[i];

        const {label, key, field, type} = item;

        const ctx = {
            date_format: format.date_format,
            options: getOptions(type, field, ex)
        };
        let m2 = {...member, id};
        const format_type = format_type_ref[type];
        const raw_value = parseMemberValue(field, m2);

        let value = "";

        // anonymous accounts should still be able to be updated
        //if((field==='account_email'||field==='contact.email')&&isAnonymousUnatyEmail(raw_value)) {

        const regexp = format.delimiter === ',' ? /,/g : /;/g;
        const v2 = getFieldExportValue(raw_value, format_type, ctx, {name_map,cf_label_map,field});
        if(typeof v2 !== 'string') {
            console.warn('NOT STRING',v2, field);
        } else {
            value = cleanValues(v2,regexp);
        }

        obj[key] = value;
    }

    return obj;
}

export function buildCustomProfileFields(ps) {
    let obj = {};
    const values = Object.values(ps);

    for (let i = 0; i < values.length; i++) {
        const pf = values[i];
        for (let k = 0; k < pf.fields.length; k++) {
            const field_key = pf.fields[k];
            const field_data = pf.field_data[field_key];
            obj[field_key] = field_data;
        }
    }
    return obj;
}

function parseMemberValue(field, member) {
    let split = field.split('.');
    const len = split.length;

    if (len === 3) {
        return member[split[0]][split[1]][split[2]];
    } else if (len === 2) {
        return member[split[0]][split[1]];
    } else {
        return member[field];
    }
}

const format_type_ref = {
    'email': 'email',
    'country': 'country',
    'phone': 'phone',
    'timezone': 'timezone',
    'number': 'number',
    'semantic-date': 'semantic-date',
    'boolean': 'boolean',
    'text': 'text',
    'birthday': 'birthday',
    'id': 'id',
    'string': 'text',
    'textarea': 'textarea',
    'url': 'url',
    'checkbox': 'checkbox',
    'select': 'select',
    'select-taxonomy': 'select-taxonomy',
    'multi-select-taxonomy': 'multi-select-taxonomy',
    'mentor': 'mentor',
    'member': 'member',
    'group': 'group',
    'multi-member': 'multi-member',
    'address': 'address',
    'member-type': 'member-type',
    'mentees': 'mentees',
    'date': 'date',
    'timestamp': 'timestamp',
    'multi-select': 'multi-select',
    'account_email': 'email',
};

export function buildMemberExportHeaders(arr, ps) {
    let base = [];

    base = base.concat(arr);

    const values = Object.values(ps);

    for (let i = 0; i < values.length; i++) {
        const pf = values[i];
        for (let k = 0; k < pf.fields.length; k++) {
            const field_key = pf.fields[k];
            const field_data = pf.field_data[field_key];

            base.push({
                label: field_data.name,
                key: `custom_fields.${field_key}`,
                field: `custom_fields.${field_key}`,
                type: field_data.type,
                format_type: format_type_ref[field_data.type]
            });
        }
    }

    return base;
}

function getAllIds(first_resp,second_query,third_query) {
    let base = first_resp.members.map(m=>m.id);

    if(second_query) {
        base = base.concat(second_query.members.map(m=>m.id))
    }

    if(third_query) {
        base = base.concat(third_query.members.map(m=>m.id))
    }

    return base;
}

export async function es_getAllMemberData(view,defs,context,group_id) {
    const query = buildElasticsearchQuery(view,"",defs,0,{
        size: 1000,
        group_id
    });
    const first_resp = await new Promise(resolve => {
        directoryMembersQuery(query, context.community.uid)
            .then((response) => {
                resolve(response);
            })
    });

    let second_query = null, third_query = null;

    if(first_resp.page.total_results>1000) {
        second_query = await new Promise(resolve => {
            directoryMembersQuery({...query,current:1,size:1000}, context.community.uid)
                .then((response) => {
                    resolve(response);
                })
        });
    }

    if(first_resp.page.total_results>2000) {
        third_query = await new Promise(resolve => {
            directoryMembersQuery({...query,current:2,size:1000}, context.community.uid)
                .then((response) => {
                    resolve(response);
                })
        });
    }

    const ids = getAllIds(first_resp,second_query,third_query);

    if(ids.length>0) {
        const members = await getAllMemberData(context.community_uid, ids);
        let ids2 = [];
        let final = [];
        let obj = {};
        members.forEach(member=>{
            final.push(member);
            ids2.push(member.id);
            obj[member.id] = member;
        })

        return {
            members: final,
            ids: ids2,
            obj
        };
    }
    return [];
}

export async function es_exportMemberSelection(headers,view,defs,context,name_ref,format) {
    const query = buildElasticsearchQuery(view,"",defs,0,{
        size: 1000
    });
    // todo this really should be backend
    const first_resp = await new Promise(resolve => {
        directoryMembersQuery(query, context.community.uid)
            .then((response) => {
                resolve(response);
            })
    });

    let second_query = null, third_query = null;

    if(first_resp.page.total_results>1000) {
        second_query = await new Promise(resolve => {
            directoryMembersQuery({...query,current:1,size:1000}, context.community.uid)
                .then((response) => {
                    resolve(response);
                })
        });
    }

    if(first_resp.page.total_results>2000) {
        third_query = await new Promise(resolve => {
            directoryMembersQuery({...query,current:2,size:1000}, context.community.uid)
                .then((response) => {
                    resolve(response);
                })
        });
    }

    const ids = getAllIds(first_resp,second_query,third_query);

    if(ids.length>0) {
        const members = await getAllMemberData(context.community_uid, ids);
        let final = [];
        members.forEach(member=>{
            final.push(EXPORTtransformMember(member, member.id, headers, {
                member_types: context.member_types,
                cp_fields: context.custom_fields,
                name_ref
            }, format, name_ref));
        })

        return final;
    }
    return [];
}

export async function exportMemberSelection(headers, all, ids, context, format) {

    // todo how to handle mentor and mentee, don't want to get all members
    return await FirebaseDB
        .collection('community_members')
        .doc(context.community_uid)
        .collection('members')
        .get()
        .then(snap => {
            const docs = snap.docs;
            const name_ref = buildMemberNameReference(snap.docs);
            let data = [];
            for (let i = 0; i < docs.length; i++) {
                const doc = docs[i];

                if (all || ids.includes(doc.id)) {
                    data.push(EXPORTtransformMember(doc.data(), doc.id, headers, {
                        member_types: context.member_types,
                        cp_fields: context.custom_fields,
                        name_ref
                    }, format, name_ref));
                }
            }
            return data;
        });
}