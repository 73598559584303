import React from 'react';
import styled from "styled-components";


const TitleBox = styled.div`
        display: grid;
    grid-template-columns: 1fr auto;
`;

const TBLeft = styled.div`
    display: inline-flex;
`;

const TBRight = styled.div`
    display: flex;
    height: 100;
    align-items: center;
    > div, a {
    text-decoration: none;
        margin-left: 10px;
    }
`;


const H2 = styled.h2`
    margin: 0;
    font-size: 24px;
`;

const H1 = styled.h1`
    margin: 0;
    font-size: 28px;
    display: inline-flex;
`;

const Header = styled.div`
        padding: 4px 0 8px 0;
        position: relative;
        z-index: 1;
`;

const Emoji = styled.div`
       display: inline-flex;
       margin-right: 10px;
    font-size: 24px;
    align-items: center;
`;

const Desc = styled.div`
          font-size: 15px;
    margin-top: 4px;
    line-height: 1.4;
`;

const MetaItem = styled.div`
      font-weight: 500;
    padding-left: 8px;
`;

const TitleIcon = styled.div`
    display: inline-flex;
    margin-left: 8px;
    vertical-align: baseline;
    > svg {
        height: 20px;
    }
`;

export const PageHeader = ({title,titleAction,titleIcon,emoji,size='h1',meta,children,desc}) => {
    // {desc&&<Desc className='text-gray-700'>{desc}</Desc>}
    const icon_el = titleIcon ? <TitleIcon className='text-gray-700'>{titleIcon}</TitleIcon> : null;
    return <Header>
        <TitleBox>
            <TBLeft onClick={()=>{if(titleAction) {titleAction()}}} className={titleAction?'hover-opacity text-gray-900':'text-gray-900'}>
                {emoji&&<Emoji>{emoji}</Emoji>}
                {size==='h1'?<H1 className={'text-gray-900'}>{title}{meta&&<MetaItem>{meta}</MetaItem>}{icon_el}</H1>:<H2 className='text-gray-900'>{title}{icon_el}</H2>}

            </TBLeft>
            <TBRight>
                {children}
            </TBRight>
        </TitleBox>

    </Header>
};