import React, {useState} from 'react';
import {Field,Input} from "./index";
export function ColorInput({label="Accent Color",icon_only=false,show_label=true,onChange=()=>{},value=""}) {
    const [open,setOpen] = useState(false);
    function handleChange(v) {
        onChange(v);
    }
    const input =  <Input leading={{type:'color'}} onColorClick={()=>setOpen(true)} type="color" onChange={(v)=>handleChange(v)} value={value} />;
    if(!show_label) {
        return input
    }
    return <Field label={label}>
        {input}
    </Field>
}