import React, {useContext, useEffect, useState} from "react";

import {FirebaseDB} from "../../../../config/setup-firestore";
import moment from "moment";
import {CommunityContext} from "../../../../app/community/community-context";
import {NewBirthdayWidget} from "../../reorganize/birthday-widget";
import {GlobalContext} from "../../../../app/global/global-context";
import {getDateFormats} from "../../../utilities/general/dates";

function buildDays(num) {
    let db_days = [];
    let days_map = {};
    let sort_map = {};

    let date = moment();

    for (let i = 0; i < num; i++) {
        let date_str, split;
        if (i !== 0) {
            date.add(1, 'days');
        }
        // 101-2021
        // 1231-2020
        date_str = date.format('MDD-YYYY');
        split = date_str.split('-');
        db_days.push(split[0]);
        days_map[split[0]] = split[1];
        sort_map[split[0]] = {
            year: split[1],
            date: split[0]
        };
    }

    return {
        db: db_days,
        sort: sort_map,
        map: days_map
    }
}

function getAge(c, b) {
    return c - b;
}

const days = buildDays(7);

const today = moment();

function buildFinalData(arr) {
    let obj = {
        today: [],
        week: [],
        days_index: {}
    };

    const t_check = today.format('DD-MM-YYYY');

    for(let i in arr) {
        const item = arr[i];

        // check if this memebr has their birthday today
        if(item.use_date===t_check) {
            // today
            obj.today.push(item);
        } else {
            // if not, let's see if there is already a day tracked in days_index
          let index = obj.week.length;
            if(!item.use_date) {
                continue;
            }
            // 0 === false
            if(obj.days_index[item.use_date]===null||obj.days_index[item.use_date]===undefined) {
                obj.week.push({
                    date: item.use_date,
                    formatted_date: item.formatted_date,
                    members: []
                });
                index = obj.week.length-1;
                obj.days_index[item.use_date] = index;
                obj.week[index].members.push(item);
            } else {
                // probably need to get index of this here?

                obj.days_index[item.use_date] = index;
                obj.week[(index-1)].members.push(item);
            }

        }
    }

    return obj;
}

export default function BirthdaysWidget(props) {
    const global = useContext(GlobalContext);
    const community = useContext(CommunityContext);
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);

    const date_format = getDateFormats(global.language).weekday;

    useEffect(function () {
        getMembers();
    }, []);

    function getMembers() {

        FirebaseDB
            .collection('community_members')
            .doc(community.uid)
            .collection('members')
            .where(`archived`, '==', false)
            .where(`short_birthday`, 'in', days.db)
            .get()
            .then((snap) => {
                let data = [];
                for (let i = 0; i < snap.docs.length; i++) {
                    let doc = snap.docs[i];
                    let item = doc.data();
                    item.id = doc.id;
                    const sort_data = days.sort[item.short_birthday];
                    const age = getAge(days.map[item.short_birthday], item.birthday.year);
                    const full_date = moment().year(days.map[item.short_birthday]).month(item.birthday.month).date(item.birthday.date);
                    data.push({
                        birthday: item.birthday,
                        handle: item.handle,
                        sort_one_field: parseFloat(sort_data.year),
                        sort_two_field: parseFloat(sort_data.date),
                        user_uid: item.user_uid,
                        formatted_date: full_date.format(date_format),
                        use_date: full_date.format('DD-MM-YYYY'),
                        name: item.name,
                        birth_year: item.birthday.year,
                        age: age,
                        profile_picture: item.profile_picture,
                        important: age > 49 && (age % 5 === 0)
                    });
                }
                setLoading(false);
                setData(data.sort(function (a, b) {
                    let aSize = a.sort_one_field;
                    let bSize = b.sort_one_field;
                    let aLow = a.sort_two_field;
                    let bLow = b.sort_two_field;

                    if(aSize == bSize)
                    {
                        return (aLow < bLow) ? -1 : (aLow > bLow) ? 1 : 0;
                    }
                    else
                    {
                        return (aSize < bSize) ? -1 : 1;
                    }
                }));
            })
    }

    if (data.length === 0) {
        return null;
    }

    const final = buildFinalData(data);

    return <NewBirthdayWidget data={final} handle={community.handle} />
}