import React, {useState, useEffect, useContext} from 'react';
import {ActionWithIcon, Card, InfoState, SectionHeader} from "../../../../library/components/core";
import {useHistory} from 'react-router-dom';
import {FirebaseDB} from "../../../../config/setup-firestore";
import styled from "styled-components";
import {PlusIcon,UsersIcon} from "@heroicons/react/solid";
const IconBox = styled.div`
    
    > svg {
        height: 20px;
    }
`;

function GroupItem({data,history,handle}) {
    const {name,emoji,user_uids,purpose} = data.data;
    const len = Object.keys(user_uids).length;
    return <Card fill interactive onClick={()=>history.push(`/${handle}/group/${data.id}`)}>
        <div className="flex">
        <div className="w-8 flex-none flex items-center">
            {emoji&&<div className="text-3xl">
                {emoji}
            </div>}
            {!emoji&&<IconBox>
                <UsersIcon/>
            </IconBox>}
        </div>
        <div className="flex-grow pl-2">
            <div className="text-gray-700 font-semibold text-base">{name}</div>
            <div className="text-gray-500 text-xs">
                {len} member{len===1?"":"s"}{purpose&&` - ${purpose}`}
            </div>
        </div>
        </div>
    </Card>
}

export default function InterestGroups({data,community,handle,id}) {
    const [groups, setGroups] = useState(null);
    const [loading, setLoading] = useState(true);
    const history = useHistory();

    useEffect(function () {
        getGroups(id, community.uid);
        setLoading(true);
    }, [id]);

    function getGroups(id, cid) {
        FirebaseDB
            .collection('community_entities')
            .doc(cid)
            .collection('groups')
            .where(`interests.${id}`, '==', true)
            .limit(6)
            .get()
            .then((snap) => {
                setGroups(snap.docs.map((doc) => {
                    const data = doc.data();
                    return {
                        id: doc.id,
                        data
                    }
                }));
                setLoading(false);
            })
    }

    if (!groups) {
        return null;
    }

    return <div>
       <div className="space-y-2">
            {groups.length===0&&<InfoState fill subtitle="No groups found here, yet!" />}
            {groups.map((gr,k)=><GroupItem handle={community.data.handle} history={history} key={k} data={gr} />)}
        </div>
    </div>
}