import React, {Component, useEffect, useRef} from 'react';
import {HotKeys} from "react-hotkeys";

/*
const keys = [
        {
            id: 'N',
            keys: ["n"],
            fn: React.useCallback(() => {
                console.log("N")
            }, [])
        }
    ];

 */

function buildHotkeys(keys) {
    let obj = {
        keyMap: {},
        handlers: {}
    };
    for (let i = 0; i < keys.length; i++) {
        const item = keys[i];
        obj.keyMap[item.id] = item.keys;
        obj.handlers[item.id] = item.fn;
    }
    return obj;
}

export function HotkeyWrapper(props) {
    const container_ref = useRef(null);

    useEffect(function () {
        if (container_ref.current) {
            container_ref.current.focus();
        }
    }, [container_ref]);
    
    const {keys, children} = props;
    const info = buildHotkeys(keys);
    return <HotKeys innerRef={container_ref} handlers={info.handlers} keyMap={info.keyMap}>
        {children}
    </HotKeys>
}