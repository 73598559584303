import React, {useState,useEffect} from "react";

export function MergeFlowProfile({
                                     fields = [],
    mapping = [],
                                     merged_view = false
                                 }) {

    return   <div className="border-r border-gray-200" style={{width:'320px'}}>
        {merged_view?"MERGED":"NORMAL"}
    </div>
}