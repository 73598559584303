import React, {useEffect, useRef, useState} from "react";
import {Field, SimpleListbox} from "../../core";

/*
service
religious
charity
PTO
booster club
political
non-profit
trade
business league
sorority
homeowner
sports club

types
- club
- association
- organization
- league
- network
- institution

Sororal

// The primary reason that nonprofits are different than associations is that nonprofits are focused on their mission,
// whereas associations exist for the sole purpose of providing products and services for the needs of their members.

What best describes your Organization?
 */
const types = [
    {
        id: 'organization',
        label: 'Organization'
    },
    {
        id: 'association',
        label: 'Association'
    },
    {
        id: 'club',
        label: 'Club'
    },
    {
        id: 'network',
        label: 'Network'
    },
    {
        id: 'league',
        label: 'League'
    },
    {
        id: 'congregation',
        label: 'Congregation'
    },
    {
        id: 'union',
        label: 'Union'
    },
];

const categories = [
    {
        id: 'social',
        label: 'Social',
        default_type: 'club'
    },
    {
        id: 'service',
        label: 'Service',
        default_type: 'club'
    },
    {
        id: 'environmental',
        label: 'Environmental',
        default_type: 'association'
    },
    {
        id: 'professional',
        label: 'Professional',
        default_type: 'network'
    },
    {
        id: 'civic',
        label: 'Civic',
        default_type: 'association'
    },
    {
        id: 'fraternal',
        label: 'Fraternal',
        default_type: 'association'
    },
    {
        id: 'sororal',
        label: 'Sororal',
        default_type: 'association'
    },
    {
        id: 'alumni',
        label: 'Alumni',
        default_type: 'association'
    },

    {
        id: 'booster',
        label: 'Booster',
        default_type: 'club'
    },
    {
        id: 'parent-teacher',
        label: 'Parent-teacher',
        default_type: 'association'
    },
    {
        id: 'veterans',
        label: 'Veterans',
        default_type: 'association'
    },
    {
        id: 'sports',
        label: 'Sports',
        default_type: 'club'
    },
    {
        id: 'religious',
        label: 'Religious',
        default_type: 'congregation'
    },
    {
        id: 'political',
        label: 'Political',
        default_type: 'organization'
    },
    {
        id: 'homeowner',
        label: 'Homeowner',
        default_type: 'association'
    },
    {
        id: 'labor',
        label: 'Labor',
        default_type: 'union'
    },
    {
        id: 'scientific',
        label: 'Scientific',
        default_type: 'association'
    },
    {
        id: 'philanthropic',
        label: 'Philanthropic',
        default_type: 'organization'
    },
    {
        id: 'educational',
        label: 'Educational',
        default_type: 'institution'
    }
];

export function displayOrganizationType(cat,typ,empty='') {
    let str = '', it1, it2;

    if(cat) {
        it1 = categories.filter(a=>a.id===cat)[0];
        if(it1) {
            str += `${it1.label} `;
        }
    }

    if(typ) {
        it2 = types.filter(a=>a.id===typ)[0];
        if(it2) {
            str += `${it2.label} `;
        }
    }

    if(!it1&&!it2) {
        return empty;
    }

    return str;
}

export function OrganizationTypeSelectors({
                                              category = null,
                                              type = null,
                                              onChange = () => {},
                                              show_label = true
                                          }) {
    const [cat, setCategory] = useState(category);
    const [ty, setType] = useState(type);
    const isFirstRun = useRef(true);

    useEffect(function () {
        if (isFirstRun.current) {
            isFirstRun.current = false;
            return;
        }
        onChange({
            category: cat,
            type: ty
        })
    }, [ty,cat]);

    function handleCatChange(it) {
        // it.value
        setCategory(it.value);
        setType(it.default_type);
    }

    const content =  <div className="grid grid-cols-2 gap-2">
        <SimpleListbox placeholder="Category" value={cat} onChange={handleCatChange} searchable
                       options={categories.map(it => {
                           return {label: it.label, value: it.id, default_type: it.default_type}
                       })}/>
        <SimpleListbox placeholder="Type" value={ty} onChange={(v) => setType(v.value)} searchable
                       options={types.map(it => {
                           return {label: it.label, value: it.id}
                       })}/>
    </div>;

    if(show_label) {
        return <Field label="What best describes your Community?">
            {content}
        </Field>;
    }

    return content
}