import React, {useState} from 'react';
import {MergeFlowHeader} from "./header";
import {MergeFlowProfile} from "./profile";

export function AdminMergeFlow({
    ids = [],
    init_master = ""
                               }) {
    const [profiles, setProfiles] = useState([
        {
            id: '1234'
        },
        {
            id: '5678'
        }
    ]);
    const [mapping, setMapping] = useState({});
    const [master, setMaster] = useState(init_master);
    const fields = [];
    return <div>
        <MergeFlowHeader/>
        <div className="grid" style={{gridTemplateColumns: '1fr 320px'}}>
            <div className="flex">
                {profiles.map(profile => {
                    return <MergeFlowProfile fields={fields} mapping={mapping} is_master={master === profile.id} selectField={f => {
                        setMapping({
                            ...mapping,
                            [f]: profile.id
                        })
                    }} {...profile} key={profile.id}/>
                })}
            </div>
            <div>
                <MergeFlowProfile merged_view/>
            </div>
        </div>
    </div>
}