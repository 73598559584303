import React from 'react';
import styled from "styled-components";
const Frame = styled.div`
      border-bottom: 1px solid #e3e8ee;
`;

export function TableHeader({children}) {
    return <Frame>
        {children}
    </Frame>
}