export function getAllowedEntitiesFromType(type,is_admin=false) {
    switch (type) {
        case 'standard':
            if(is_admin) {
                return {
                    roles: true,
                    teams: false,
                    groups: false,
                    filters: true,
                    members: true,
                    member_types: true
                };
            }
            return {
                roles: true,
                teams: false,
                groups: false,
                filters: false,
                members: true,
                member_types: false
            };
        case 'team':
            return {
                roles: true,
                teams: false,
                groups: false,
                filters: false,
                members: false,
                member_types: false
            };
        default:
            return {};
    }
}

export const group_modal_about = {
    flow: 'create-group',
    step: 'about',

    appearance: {
        size: 'medium',
    },

    state: {
        type: 'standard',
        name: '',
        interests: {},
        purpose: '',
        handle: '',
        closed: false,
        entities: {
          members: {},
          teams: {},
          groups: {},
          roles: {},
          filters: {},
          member_types: {},
        },
        emoji: '',
        profile_picture: '',
        profile_picture_color: '',
        color: '#a1a1a1',
        _checking_handle: false,
        _customizing: false,
        _helper_name: ''
    },

    header: {
        title: 'Create Group',
        subtitle: 'Groups help members stay connected and organized.',
        show_close_icon: true
    },

    context: {
        type: 'group',
        community_uid: ''
    },

    blocks: [
        {
            type: "name-icon-handle",
            placeholder: 'Golfers',
            value: ["emoji", "profile_picture", "profile_picture_color", "color", "handle", "name"],
            label: "Name",
            meta: "",
            handle: {
                scope: 'community',
                scope_id: '',
                type: 'group',
                type_name: 'Group'
            }
        },
        {
            label: "Purpose",
            placeholder: "",
            optional: true,
            value: "purpose",
            type: "text",
            meta: "What is this group about?"
        },
        {
            texts: {
                on: {
                    title: 'Closed Group',
                    desc: `<b>This cannot be undone.</b> Only members you invite can see this group.`
                },
                off: {
                    title: 'Make Closed Group',
                    desc: `If your group is closed, only members you invite can see the group.`
                }
            },
            type: "info-switch",
            value: "closed",
            disabled: [
                {
                    field: 'type',
                    check: 'equals',
                    value: 'team'
                }
            ]
        }
    ],

    footer: {
        primary: {
            type: 'button',
            intent: 'secondary',
            text: 'Create Group',
            action: 'proceed',
            disabled: [
                {
                    field: 'name',
                    check: 'length is less than',
                    value: '2'
                }
            ]
        },
        secondary: null,
        tertiary: null
    }
};

export const group_modal_add_entities = {
    flow: 'create-group',
    step: 'add-entities',

    appearance: {
        size: 'medium',
    },

    state: {
        entities: {
            roles: {},
            teams: {},
            filters: {},
            groups: {},
            members: {},
            member_types: {}
        }
    },

    context: {
        community_uid: 'MjlVMc7mwK7hhMTQRo8Y'
    },

    header: {
        title: 'Add Members',
        show_close_icon: true
    },

    blocks: [
        {
            type: "entities-filters",
            placeholder: 'Add Members',
            value: ["entities"],
            allowed_entities: getAllowedEntitiesFromType('interest')
        },
    ],

    footer: {
        primary: {
            type: 'button',
            intent: 'secondary',
            text: 'Create Group',
            action: 'proceed',
            disabled: []
        },
        secondary: null,
        tertiary: null
    }
};