export const cities_data = [
        {
            "name": "New York",
            "country": "us"
        },
        {
            "name": "Los Angeles",
            "country": "us"
        },
        {
            "name": "Chicago",
            "country": "us"
        },
        {
            "name": "Houston",
            "country": "us"
        },
        {
            "name": "Philadelphia",
            "country": "us"
        },
        {
            "name": "Phoenix",
            "country": "us"
        },
        {
            "name": "San Antonio",
            "country": "us"
        },
        {
            "name": "San Diego",
            "country": "us"
        },
        {
            "name": "Dallas",
            "country": "us"
        },
        {
            "name": "San Jose",
            "country": "us"
        },
        {
            "name": "Austin",
            "country": "us"
        },
        {
            "name": "Indianapolis",
            "country": "us"
        },
        {
            "name": "Jacksonville",
            "country": "us"
        },
        {
            "name": "San Francisco",
            "country": "us"
        },
        {
            "name": "Columbus",
            "country": "us"
        },
        {
            "name": "Charlotte",
            "country": "us"
        },
        {
            "name": "Fort Worth",
            "country": "us"
        },
        {
            "name": "Detroit",
            "country": "us"
        },
        {
            "name": "El Paso",
            "country": "us"
        },
        {
            "name": "Memphis",
            "country": "us"
        },
        {
            "name": "Seattle",
            "country": "us"
        },
        {
            "name": "Denver",
            "country": "us"
        },
        {
            "name": "Washington",
            "country": "us"
        },
        {
            "name": "Boston",
            "country": "us"
        },
        {
            "name": "Nashville-Davidson",
            "country": "us"
        },
        {
            "name": "Baltimore",
            "country": "us"
        },
        {
            "name": "Oklahoma City",
            "country": "us"
        },
        {
            "name": "Louisville/Jefferson County",
            "country": "us"
        },
        {
            "name": "Portland",
            "country": "us"
        },
        {
            "name": "Las Vegas",
            "country": "us"
        },
        {
            "name": "Milwaukee",
            "country": "us"
        },
        {
            "name": "Albuquerque",
            "country": "us"
        },
        {
            "name": "Tucson",
            "country": "us"
        },
        {
            "name": "Fresno",
            "country": "us"
        },
        {
            "name": "Sacramento",
            "country": "us"
        },
        {
            "name": "Long Beach",
            "country": "us"
        },
        {
            "name": "Kansas City",
            "country": "us"
        },
        {
            "name": "Mesa",
            "country": "us"
        },
        {
            "name": "Virginia Beach",
            "country": "us"
        },
        {
            "name": "Atlanta",
            "country": "us"
        },
        {
            "name": "Colorado Springs",
            "country": "us"
        },
        {
            "name": "Omaha",
            "country": "us"
        },
        {
            "name": "Raleigh",
            "country": "us"
        },
        {
            "name": "Miami",
            "country": "us"
        },
        {
            "name": "Oakland",
            "country": "us"
        },
        {
            "name": "Minneapolis",
            "country": "us"
        },
        {
            "name": "Tulsa",
            "country": "us"
        },
        {
            "name": "Cleveland",
            "country": "us"
        },
        {
            "name": "Wichita",
            "country": "us"
        },
        {
            "name": "Arlington",
            "country": "us"
        },
        {
            "name": "New Orleans",
            "country": "us"
        },
        {
            "name": "Bakersfield",
            "country": "us"
        },
        {
            "name": "Tampa",
            "country": "us"
        },
        {
            "name": "Honolulu",
            "country": "us"
        },
        {
            "name": "Aurora",
            "country": "us"
        },
        {
            "name": "Anaheim",
            "country": "us"
        },
        {
            "name": "Santa Ana",
            "country": "us"
        },
        {
            "name": "St. Louis",
            "country": "us"
        },
        {
            "name": "Riverside",
            "country": "us"
        },
        {
            "name": "Corpus Christi",
            "country": "us"
        },
        {
            "name": "Lexington-Fayette",
            "country": "us"
        },
        {
            "name": "Pittsburgh",
            "country": "us"
        },
        {
            "name": "Anchorage",
            "country": "us"
        },
        {
            "name": "Stockton",
            "country": "us"
        },
        {
            "name": "Cincinnati",
            "country": "us"
        },
        {
            "name": "St. Paul",
            "country": "us"
        },
        {
            "name": "Toledo",
            "country": "us"
        },
        {
            "name": "Greensboro",
            "country": "us"
        },
        {
            "name": "Newark",
            "country": "us"
        },
        {
            "name": "Plano",
            "country": "us"
        },
        {
            "name": "Henderson",
            "country": "us"
        },
        {
            "name": "Lincoln",
            "country": "us"
        },
        {
            "name": "Buffalo",
            "country": "us"
        },
        {
            "name": "Jersey City",
            "country": "us"
        },
        {
            "name": "Chula Vista",
            "country": "us"
        },
        {
            "name": "Fort Wayne",
            "country": "us"
        },
        {
            "name": "Orlando",
            "country": "us"
        },
        {
            "name": "St. Petersburg",
            "country": "us"
        },
        {
            "name": "Chandler",
            "country": "us"
        },
        {
            "name": "Laredo",
            "country": "us"
        },
        {
            "name": "Norfolk",
            "country": "us"
        },
        {
            "name": "Durham",
            "country": "us"
        },
        {
            "name": "Madison",
            "country": "us"
        },
        {
            "name": "Lubbock",
            "country": "us"
        },
        {
            "name": "Irvine",
            "country": "us"
        },
        {
            "name": "Winston-Salem",
            "country": "us"
        },
        {
            "name": "Glendale",
            "country": "us"
        },
        {
            "name": "Garland",
            "country": "us"
        },
        {
            "name": "Hialeah",
            "country": "us"
        },
        {
            "name": "Reno",
            "country": "us"
        },
        {
            "name": "Chesapeake",
            "country": "us"
        },
        {
            "name": "Gilbert",
            "country": "us"
        },
        {
            "name": "Baton Rouge",
            "country": "us"
        },
        {
            "name": "Irving",
            "country": "us"
        },
        {
            "name": "Scottsdale",
            "country": "us"
        },
        {
            "name": "North Las Vegas",
            "country": "us"
        },
        {
            "name": "Fremont",
            "country": "us"
        },
        {
            "name": "Boise City",
            "country": "us"
        },
        {
            "name": "Richmond",
            "country": "us"
        },
        {
            "name": "San Bernardino",
            "country": "us"
        },
        {
            "name": "Birmingham",
            "country": "us"
        },
        {
            "name": "Spokane",
            "country": "us"
        },
        {
            "name": "Rochester",
            "country": "us"
        },
        {
            "name": "Des Moines",
            "country": "us"
        },
        {
            "name": "Modesto",
            "country": "us"
        },
        {
            "name": "Fayetteville",
            "country": "us"
        },
        {
            "name": "Tacoma",
            "country": "us"
        },
        {
            "name": "Oxnard",
            "country": "us"
        },
        {
            "name": "Fontana",
            "country": "us"
        },
        {
            "name": "Columbus",
            "country": "us"
        },
        {
            "name": "Montgomery",
            "country": "us"
        },
        {
            "name": "Moreno Valley",
            "country": "us"
        },
        {
            "name": "Shreveport",
            "country": "us"
        },
        {
            "name": "Aurora",
            "country": "us"
        },
        {
            "name": "Yonkers",
            "country": "us"
        },
        {
            "name": "Akron",
            "country": "us"
        },
        {
            "name": "Huntington Beach",
            "country": "us"
        },
        {
            "name": "Little Rock",
            "country": "us"
        },
        {
            "name": "Augusta-Richmond County",
            "country": "us"
        },
        {
            "name": "Amarillo",
            "country": "us"
        },
        {
            "name": "Glendale",
            "country": "us"
        },
        {
            "name": "Mobile",
            "country": "us"
        },
        {
            "name": "Grand Rapids",
            "country": "us"
        },
        {
            "name": "Salt Lake City",
            "country": "us"
        },
        {
            "name": "Tallahassee",
            "country": "us"
        },
        {
            "name": "Huntsville",
            "country": "us"
        },
        {
            "name": "Grand Prairie",
            "country": "us"
        },
        {
            "name": "Knoxville",
            "country": "us"
        },
        {
            "name": "Worcester",
            "country": "us"
        },
        {
            "name": "Newport News",
            "country": "us"
        },
        {
            "name": "Brownsville",
            "country": "us"
        },
        {
            "name": "Overland Park",
            "country": "us"
        },
        {
            "name": "Santa Clarita",
            "country": "us"
        },
        {
            "name": "Providence",
            "country": "us"
        },
        {
            "name": "Garden Grove",
            "country": "us"
        },
        {
            "name": "Chattanooga",
            "country": "us"
        },
        {
            "name": "Oceanside",
            "country": "us"
        },
        {
            "name": "Jackson",
            "country": "us"
        },
        {
            "name": "Fort Lauderdale",
            "country": "us"
        },
        {
            "name": "Santa Rosa",
            "country": "us"
        },
        {
            "name": "Rancho Cucamonga",
            "country": "us"
        },
        {
            "name": "Port St. Lucie",
            "country": "us"
        },
        {
            "name": "Tempe",
            "country": "us"
        },
        {
            "name": "Ontario",
            "country": "us"
        },
        {
            "name": "Vancouver",
            "country": "us"
        },
        {
            "name": "Cape Coral",
            "country": "us"
        },
        {
            "name": "Sioux Falls",
            "country": "us"
        },
        {
            "name": "Springfield",
            "country": "us"
        },
        {
            "name": "Peoria",
            "country": "us"
        },
        {
            "name": "Pembroke Pines",
            "country": "us"
        },
        {
            "name": "Elk Grove",
            "country": "us"
        },
        {
            "name": "Salem",
            "country": "us"
        },
        {
            "name": "Lancaster",
            "country": "us"
        },
        {
            "name": "Corona",
            "country": "us"
        },
        {
            "name": "Eugene",
            "country": "us"
        },
        {
            "name": "Palmdale",
            "country": "us"
        },
        {
            "name": "Salinas",
            "country": "us"
        },
        {
            "name": "Springfield",
            "country": "us"
        },
        {
            "name": "Pasadena",
            "country": "us"
        },
        {
            "name": "Fort Collins",
            "country": "us"
        },
        {
            "name": "Hayward",
            "country": "us"
        },
        {
            "name": "Pomona",
            "country": "us"
        },
        {
            "name": "Cary",
            "country": "us"
        },
        {
            "name": "Rockford",
            "country": "us"
        },
        {
            "name": "Alexandria",
            "country": "us"
        },
        {
            "name": "Escondido",
            "country": "us"
        },
        {
            "name": "McKinney",
            "country": "us"
        },
        {
            "name": "Kansas City",
            "country": "us"
        },
        {
            "name": "Joliet",
            "country": "us"
        },
        {
            "name": "Sunnyvale",
            "country": "us"
        },
        {
            "name": "Torrance",
            "country": "us"
        },
        {
            "name": "Bridgeport",
            "country": "us"
        },
        {
            "name": "Lakewood",
            "country": "us"
        },
        {
            "name": "Hollywood",
            "country": "us"
        },
        {
            "name": "Paterson",
            "country": "us"
        },
        {
            "name": "Naperville",
            "country": "us"
        },
        {
            "name": "Syracuse",
            "country": "us"
        },
        {
            "name": "Mesquite",
            "country": "us"
        },
        {
            "name": "Dayton",
            "country": "us"
        },
        {
            "name": "Savannah",
            "country": "us"
        },
        {
            "name": "Clarksville",
            "country": "us"
        },
        {
            "name": "Orange",
            "country": "us"
        },
        {
            "name": "Pasadena",
            "country": "us"
        },
        {
            "name": "Fullerton",
            "country": "us"
        },
        {
            "name": "Killeen",
            "country": "us"
        },
        {
            "name": "Frisco",
            "country": "us"
        },
        {
            "name": "Hampton",
            "country": "us"
        },
        {
            "name": "McAllen",
            "country": "us"
        },
        {
            "name": "Warren",
            "country": "us"
        },
        {
            "name": "Bellevue",
            "country": "us"
        },
        {
            "name": "West Valley City",
            "country": "us"
        },
        {
            "name": "Columbia",
            "country": "us"
        },
        {
            "name": "Olathe",
            "country": "us"
        },
        {
            "name": "Sterling Heights",
            "country": "us"
        },
        {
            "name": "New Haven",
            "country": "us"
        },
        {
            "name": "Miramar",
            "country": "us"
        },
        {
            "name": "Waco",
            "country": "us"
        },
        {
            "name": "Thousand Oaks",
            "country": "us"
        },
        {
            "name": "Cedar Rapids",
            "country": "us"
        },
        {
            "name": "Charleston",
            "country": "us"
        },
        {
            "name": "Visalia",
            "country": "us"
        },
        {
            "name": "Topeka",
            "country": "us"
        },
        {
            "name": "Elizabeth",
            "country": "us"
        },
        {
            "name": "Gainesville",
            "country": "us"
        },
        {
            "name": "Thornton",
            "country": "us"
        },
        {
            "name": "Roseville",
            "country": "us"
        },
        {
            "name": "Carrollton",
            "country": "us"
        },
        {
            "name": "Coral Springs",
            "country": "us"
        },
        {
            "name": "Stamford",
            "country": "us"
        },
        {
            "name": "Simi Valley",
            "country": "us"
        },
        {
            "name": "Concord",
            "country": "us"
        },
        {
            "name": "Hartford",
            "country": "us"
        },
        {
            "name": "Kent",
            "country": "us"
        },
        {
            "name": "Lafayette",
            "country": "us"
        },
        {
            "name": "Midland",
            "country": "us"
        },
        {
            "name": "Surprise",
            "country": "us"
        },
        {
            "name": "Denton",
            "country": "us"
        },
        {
            "name": "Victorville",
            "country": "us"
        },
        {
            "name": "Evansville",
            "country": "us"
        },
        {
            "name": "Santa Clara",
            "country": "us"
        },
        {
            "name": "Abilene",
            "country": "us"
        },
        {
            "name": "Athens-Clarke County",
            "country": "us"
        },
        {
            "name": "Vallejo",
            "country": "us"
        },
        {
            "name": "Allentown",
            "country": "us"
        },
        {
            "name": "Norman",
            "country": "us"
        },
        {
            "name": "Beaumont",
            "country": "us"
        },
        {
            "name": "Independence",
            "country": "us"
        },
        {
            "name": "Murfreesboro",
            "country": "us"
        },
        {
            "name": "Ann Arbor",
            "country": "us"
        },
        {
            "name": "Springfield",
            "country": "us"
        },
        {
            "name": "Berkeley",
            "country": "us"
        },
        {
            "name": "Peoria",
            "country": "us"
        },
        {
            "name": "Provo",
            "country": "us"
        },
        {
            "name": "El Monte",
            "country": "us"
        },
        {
            "name": "Columbia",
            "country": "us"
        },
        {
            "name": "Lansing",
            "country": "us"
        },
        {
            "name": "Fargo",
            "country": "us"
        },
        {
            "name": "Downey",
            "country": "us"
        },
        {
            "name": "Costa Mesa",
            "country": "us"
        },
        {
            "name": "Wilmington",
            "country": "us"
        },
        {
            "name": "Arvada",
            "country": "us"
        },
        {
            "name": "Inglewood",
            "country": "us"
        },
        {
            "name": "Miami Gardens",
            "country": "us"
        },
        {
            "name": "Carlsbad",
            "country": "us"
        },
        {
            "name": "Westminster",
            "country": "us"
        },
        {
            "name": "Rochester",
            "country": "us"
        },
        {
            "name": "Odessa",
            "country": "us"
        },
        {
            "name": "Manchester",
            "country": "us"
        },
        {
            "name": "Elgin",
            "country": "us"
        },
        {
            "name": "West Jordan",
            "country": "us"
        },
        {
            "name": "Round Rock",
            "country": "us"
        },
        {
            "name": "Clearwater",
            "country": "us"
        },
        {
            "name": "Waterbury",
            "country": "us"
        },
        {
            "name": "Gresham",
            "country": "us"
        },
        {
            "name": "Fairfield",
            "country": "us"
        },
        {
            "name": "Billings",
            "country": "us"
        },
        {
            "name": "Lowell",
            "country": "us"
        },
        {
            "name": "San Buenaventura (Ventura)",
            "country": "us"
        },
        {
            "name": "Pueblo",
            "country": "us"
        },
        {
            "name": "High Point",
            "country": "us"
        },
        {
            "name": "West Covina",
            "country": "us"
        },
        {
            "name": "Richmond",
            "country": "us"
        },
        {
            "name": "Murrieta",
            "country": "us"
        },
        {
            "name": "Cambridge",
            "country": "us"
        },
        {
            "name": "Antioch",
            "country": "us"
        },
        {
            "name": "Temecula",
            "country": "us"
        },
        {
            "name": "Norwalk",
            "country": "us"
        },
        {
            "name": "Centennial",
            "country": "us"
        },
        {
            "name": "Everett",
            "country": "us"
        },
        {
            "name": "Palm Bay",
            "country": "us"
        },
        {
            "name": "Wichita Falls",
            "country": "us"
        },
        {
            "name": "Green Bay",
            "country": "us"
        },
        {
            "name": "Daly City",
            "country": "us"
        },
        {
            "name": "Burbank",
            "country": "us"
        },
        {
            "name": "Richardson",
            "country": "us"
        },
        {
            "name": "Pompano Beach",
            "country": "us"
        },
        {
            "name": "North Charleston",
            "country": "us"
        },
        {
            "name": "Broken Arrow",
            "country": "us"
        },
        {
            "name": "Boulder",
            "country": "us"
        },
        {
            "name": "West Palm Beach",
            "country": "us"
        },
        {
            "name": "Santa Maria",
            "country": "us"
        },
        {
            "name": "El Cajon",
            "country": "us"
        },
        {
            "name": "Davenport",
            "country": "us"
        },
        {
            "name": "Rialto",
            "country": "us"
        },
        {
            "name": "Las Cruces",
            "country": "us"
        },
        {
            "name": "San Mateo",
            "country": "us"
        },
        {
            "name": "Lewisville",
            "country": "us"
        },
        {
            "name": "South Bend",
            "country": "us"
        },
        {
            "name": "Lakeland",
            "country": "us"
        },
        {
            "name": "Erie",
            "country": "us"
        },
        {
            "name": "Tyler",
            "country": "us"
        },
        {
            "name": "Pearland",
            "country": "us"
        },
        {
            "name": "College Station",
            "country": "us"
        },
        {
            "name": "Kenosha",
            "country": "us"
        },
        {
            "name": "Sandy Springs",
            "country": "us"
        },
        {
            "name": "Clovis",
            "country": "us"
        },
        {
            "name": "Flint",
            "country": "us"
        },
        {
            "name": "Roanoke",
            "country": "us"
        },
        {
            "name": "Albany",
            "country": "us"
        },
        {
            "name": "Jurupa Valley",
            "country": "us"
        },
        {
            "name": "Compton",
            "country": "us"
        },
        {
            "name": "San Angelo",
            "country": "us"
        },
        {
            "name": "Hillsboro",
            "country": "us"
        },
        {
            "name": "Lawton",
            "country": "us"
        },
        {
            "name": "Renton",
            "country": "us"
        },
        {
            "name": "Vista",
            "country": "us"
        },
        {
            "name": "Davie",
            "country": "us"
        },
        {
            "name": "Greeley",
            "country": "us"
        },
        {
            "name": "Mission Viejo",
            "country": "us"
        },
        {
            "name": "Portsmouth",
            "country": "us"
        },
        {
            "name": "Dearborn",
            "country": "us"
        },
        {
            "name": "South Gate",
            "country": "us"
        },
        {
            "name": "Tuscaloosa",
            "country": "us"
        },
        {
            "name": "Livonia",
            "country": "us"
        },
        {
            "name": "New Bedford",
            "country": "us"
        },
        {
            "name": "Vacaville",
            "country": "us"
        },
        {
            "name": "Brockton",
            "country": "us"
        },
        {
            "name": "Roswell",
            "country": "us"
        },
        {
            "name": "Beaverton",
            "country": "us"
        },
        {
            "name": "Quincy",
            "country": "us"
        },
        {
            "name": "Sparks",
            "country": "us"
        },
        {
            "name": "Yakima",
            "country": "us"
        },
        {
            "name": "Lee's Summit",
            "country": "us"
        },
        {
            "name": "Federal Way",
            "country": "us"
        },
        {
            "name": "Carson",
            "country": "us"
        },
        {
            "name": "Santa Monica",
            "country": "us"
        },
        {
            "name": "Hesperia",
            "country": "us"
        },
        {
            "name": "Allen",
            "country": "us"
        },
        {
            "name": "Rio Rancho",
            "country": "us"
        },
        {
            "name": "Yuma",
            "country": "us"
        },
        {
            "name": "Westminster",
            "country": "us"
        },
        {
            "name": "Orem",
            "country": "us"
        },
        {
            "name": "Lynn",
            "country": "us"
        },
        {
            "name": "Redding",
            "country": "us"
        },
        {
            "name": "Spokane Valley",
            "country": "us"
        },
        {
            "name": "Miami Beach",
            "country": "us"
        },
        {
            "name": "League City",
            "country": "us"
        },
        {
            "name": "Lawrence",
            "country": "us"
        },
        {
            "name": "Santa Barbara",
            "country": "us"
        },
        {
            "name": "Plantation",
            "country": "us"
        },
        {
            "name": "Sandy",
            "country": "us"
        },
        {
            "name": "Sunrise",
            "country": "us"
        },
        {
            "name": "Macon",
            "country": "us"
        },
        {
            "name": "Longmont",
            "country": "us"
        },
        {
            "name": "Boca Raton",
            "country": "us"
        },
        {
            "name": "San Marcos",
            "country": "us"
        },
        {
            "name": "Greenville",
            "country": "us"
        },
        {
            "name": "Waukegan",
            "country": "us"
        },
        {
            "name": "Fall River",
            "country": "us"
        },
        {
            "name": "Chico",
            "country": "us"
        },
        {
            "name": "Newton",
            "country": "us"
        },
        {
            "name": "San Leandro",
            "country": "us"
        },
        {
            "name": "Reading",
            "country": "us"
        },
        {
            "name": "Norwalk",
            "country": "us"
        },
        {
            "name": "Fort Smith",
            "country": "us"
        },
        {
            "name": "Newport Beach",
            "country": "us"
        },
        {
            "name": "Asheville",
            "country": "us"
        },
        {
            "name": "Nashua",
            "country": "us"
        },
        {
            "name": "Edmond",
            "country": "us"
        },
        {
            "name": "Whittier",
            "country": "us"
        },
        {
            "name": "Nampa",
            "country": "us"
        },
        {
            "name": "Bloomington",
            "country": "us"
        },
        {
            "name": "Deltona",
            "country": "us"
        },
        {
            "name": "Hawthorne",
            "country": "us"
        },
        {
            "name": "Duluth",
            "country": "us"
        },
        {
            "name": "Carmel",
            "country": "us"
        },
        {
            "name": "Suffolk",
            "country": "us"
        },
        {
            "name": "Clifton",
            "country": "us"
        },
        {
            "name": "Citrus Heights",
            "country": "us"
        },
        {
            "name": "Livermore",
            "country": "us"
        },
        {
            "name": "Tracy",
            "country": "us"
        },
        {
            "name": "Alhambra",
            "country": "us"
        },
        {
            "name": "Kirkland",
            "country": "us"
        },
        {
            "name": "Trenton",
            "country": "us"
        },
        {
            "name": "Ogden",
            "country": "us"
        },
        {
            "name": "Hoover",
            "country": "us"
        },
        {
            "name": "Cicero",
            "country": "us"
        },
        {
            "name": "Fishers",
            "country": "us"
        },
        {
            "name": "Sugar Land",
            "country": "us"
        },
        {
            "name": "Danbury",
            "country": "us"
        },
        {
            "name": "Meridian",
            "country": "us"
        },
        {
            "name": "Indio",
            "country": "us"
        },
        {
            "name": "Concord",
            "country": "us"
        },
        {
            "name": "Menifee",
            "country": "us"
        },
        {
            "name": "Champaign",
            "country": "us"
        },
        {
            "name": "Buena Park",
            "country": "us"
        },
        {
            "name": "Troy",
            "country": "us"
        },
        {
            "name": "O'Fallon",
            "country": "us"
        },
        {
            "name": "Johns Creek",
            "country": "us"
        },
        {
            "name": "Bellingham",
            "country": "us"
        },
        {
            "name": "Westland",
            "country": "us"
        },
        {
            "name": "Bloomington",
            "country": "us"
        },
        {
            "name": "Sioux City",
            "country": "us"
        },
        {
            "name": "Warwick",
            "country": "us"
        },
        {
            "name": "Hemet",
            "country": "us"
        },
        {
            "name": "Longview",
            "country": "us"
        },
        {
            "name": "Farmington Hills",
            "country": "us"
        },
        {
            "name": "Bend",
            "country": "us"
        },
        {
            "name": "Lakewood",
            "country": "us"
        },
        {
            "name": "Merced",
            "country": "us"
        },
        {
            "name": "Mission",
            "country": "us"
        },
        {
            "name": "Chino",
            "country": "us"
        },
        {
            "name": "Redwood City",
            "country": "us"
        },
        {
            "name": "Edinburg",
            "country": "us"
        },
        {
            "name": "Cranston",
            "country": "us"
        },
        {
            "name": "Parma",
            "country": "us"
        },
        {
            "name": "New Rochelle",
            "country": "us"
        },
        {
            "name": "Lake Forest",
            "country": "us"
        },
        {
            "name": "Napa",
            "country": "us"
        },
        {
            "name": "Hammond",
            "country": "us"
        },
        {
            "name": "Fayetteville",
            "country": "us"
        },
        {
            "name": "Bloomington",
            "country": "us"
        },
        {
            "name": "Avondale",
            "country": "us"
        },
        {
            "name": "Somerville",
            "country": "us"
        },
        {
            "name": "Palm Coast",
            "country": "us"
        },
        {
            "name": "Bryan",
            "country": "us"
        },
        {
            "name": "Gary",
            "country": "us"
        },
        {
            "name": "Largo",
            "country": "us"
        },
        {
            "name": "Brooklyn Park",
            "country": "us"
        },
        {
            "name": "Tustin",
            "country": "us"
        },
        {
            "name": "Racine",
            "country": "us"
        },
        {
            "name": "Deerfield Beach",
            "country": "us"
        },
        {
            "name": "Lynchburg",
            "country": "us"
        },
        {
            "name": "Mountain View",
            "country": "us"
        },
        {
            "name": "Medford",
            "country": "us"
        },
        {
            "name": "Lawrence",
            "country": "us"
        },
        {
            "name": "Bellflower",
            "country": "us"
        },
        {
            "name": "Melbourne",
            "country": "us"
        },
        {
            "name": "St. Joseph",
            "country": "us"
        },
        {
            "name": "Camden",
            "country": "us"
        },
        {
            "name": "St. George",
            "country": "us"
        },
        {
            "name": "Kennewick",
            "country": "us"
        },
        {
            "name": "Baldwin Park",
            "country": "us"
        },
        {
            "name": "Chino Hills",
            "country": "us"
        },
        {
            "name": "Alameda",
            "country": "us"
        },
        {
            "name": "Albany",
            "country": "us"
        },
        {
            "name": "Arlington Heights",
            "country": "us"
        },
        {
            "name": "Scranton",
            "country": "us"
        },
        {
            "name": "Evanston",
            "country": "us"
        },
        {
            "name": "Kalamazoo",
            "country": "us"
        },
        {
            "name": "Baytown",
            "country": "us"
        },
        {
            "name": "Upland",
            "country": "us"
        },
        {
            "name": "Springdale",
            "country": "us"
        },
        {
            "name": "Bethlehem",
            "country": "us"
        },
        {
            "name": "Schaumburg",
            "country": "us"
        },
        {
            "name": "Mount Pleasant",
            "country": "us"
        },
        {
            "name": "Auburn",
            "country": "us"
        },
        {
            "name": "Decatur",
            "country": "us"
        },
        {
            "name": "San Ramon",
            "country": "us"
        },
        {
            "name": "Pleasanton",
            "country": "us"
        },
        {
            "name": "Wyoming",
            "country": "us"
        },
        {
            "name": "Lake Charles",
            "country": "us"
        },
        {
            "name": "Plymouth",
            "country": "us"
        },
        {
            "name": "Bolingbrook",
            "country": "us"
        },
        {
            "name": "Pharr",
            "country": "us"
        },
        {
            "name": "Appleton",
            "country": "us"
        },
        {
            "name": "Gastonia",
            "country": "us"
        },
        {
            "name": "Folsom",
            "country": "us"
        },
        {
            "name": "Southfield",
            "country": "us"
        },
        {
            "name": "Rochester Hills",
            "country": "us"
        },
        {
            "name": "New Britain",
            "country": "us"
        },
        {
            "name": "Goodyear",
            "country": "us"
        },
        {
            "name": "Canton",
            "country": "us"
        },
        {
            "name": "Warner Robins",
            "country": "us"
        },
        {
            "name": "Union City",
            "country": "us"
        },
        {
            "name": "Perris",
            "country": "us"
        },
        {
            "name": "Manteca",
            "country": "us"
        },
        {
            "name": "Iowa City",
            "country": "us"
        },
        {
            "name": "Jonesboro",
            "country": "us"
        },
        {
            "name": "Wilmington",
            "country": "us"
        },
        {
            "name": "Lynwood",
            "country": "us"
        },
        {
            "name": "Loveland",
            "country": "us"
        },
        {
            "name": "Pawtucket",
            "country": "us"
        },
        {
            "name": "Boynton Beach",
            "country": "us"
        },
        {
            "name": "Waukesha",
            "country": "us"
        },
        {
            "name": "Gulfport",
            "country": "us"
        },
        {
            "name": "Apple Valley",
            "country": "us"
        },
        {
            "name": "Passaic",
            "country": "us"
        },
        {
            "name": "Rapid City",
            "country": "us"
        },
        {
            "name": "Layton",
            "country": "us"
        },
        {
            "name": "Lafayette",
            "country": "us"
        },
        {
            "name": "Turlock",
            "country": "us"
        },
        {
            "name": "Muncie",
            "country": "us"
        },
        {
            "name": "Temple",
            "country": "us"
        },
        {
            "name": "Missouri City",
            "country": "us"
        },
        {
            "name": "Redlands",
            "country": "us"
        },
        {
            "name": "Santa Fe",
            "country": "us"
        },
        {
            "name": "Lauderhill",
            "country": "us"
        },
        {
            "name": "Milpitas",
            "country": "us"
        },
        {
            "name": "Palatine",
            "country": "us"
        },
        {
            "name": "Missoula",
            "country": "us"
        },
        {
            "name": "Rock Hill",
            "country": "us"
        },
        {
            "name": "Jacksonville",
            "country": "us"
        },
        {
            "name": "Franklin",
            "country": "us"
        },
        {
            "name": "Flagstaff",
            "country": "us"
        },
        {
            "name": "Flower Mound",
            "country": "us"
        },
        {
            "name": "Weston",
            "country": "us"
        },
        {
            "name": "Waterloo",
            "country": "us"
        },
        {
            "name": "Union City",
            "country": "us"
        },
        {
            "name": "Mount Vernon",
            "country": "us"
        },
        {
            "name": "Fort Myers",
            "country": "us"
        },
        {
            "name": "Dothan",
            "country": "us"
        },
        {
            "name": "Rancho Cordova",
            "country": "us"
        },
        {
            "name": "Redondo Beach",
            "country": "us"
        },
        {
            "name": "Jackson",
            "country": "us"
        },
        {
            "name": "Pasco",
            "country": "us"
        },
        {
            "name": "St. Charles",
            "country": "us"
        },
        {
            "name": "Eau Claire",
            "country": "us"
        },
        {
            "name": "North Richland Hills",
            "country": "us"
        },
        {
            "name": "Bismarck",
            "country": "us"
        },
        {
            "name": "Yorba Linda",
            "country": "us"
        },
        {
            "name": "Kenner",
            "country": "us"
        },
        {
            "name": "Walnut Creek",
            "country": "us"
        },
        {
            "name": "Frederick",
            "country": "us"
        },
        {
            "name": "Oshkosh",
            "country": "us"
        },
        {
            "name": "Pittsburg",
            "country": "us"
        },
        {
            "name": "Palo Alto",
            "country": "us"
        },
        {
            "name": "Bossier City",
            "country": "us"
        },
        {
            "name": "Portland",
            "country": "us"
        },
        {
            "name": "St. Cloud",
            "country": "us"
        },
        {
            "name": "Davis",
            "country": "us"
        },
        {
            "name": "South San Francisco",
            "country": "us"
        },
        {
            "name": "Camarillo",
            "country": "us"
        },
        {
            "name": "North Little Rock",
            "country": "us"
        },
        {
            "name": "Schenectady",
            "country": "us"
        },
        {
            "name": "Gaithersburg",
            "country": "us"
        },
        {
            "name": "Harlingen",
            "country": "us"
        },
        {
            "name": "Woodbury",
            "country": "us"
        },
        {
            "name": "Eagan",
            "country": "us"
        },
        {
            "name": "Yuba City",
            "country": "us"
        },
        {
            "name": "Maple Grove",
            "country": "us"
        },
        {
            "name": "Youngstown",
            "country": "us"
        },
        {
            "name": "Skokie",
            "country": "us"
        },
        {
            "name": "Kissimmee",
            "country": "us"
        },
        {
            "name": "Johnson City",
            "country": "us"
        },
        {
            "name": "Victoria",
            "country": "us"
        },
        {
            "name": "San Clemente",
            "country": "us"
        },
        {
            "name": "Bayonne",
            "country": "us"
        },
        {
            "name": "Laguna Niguel",
            "country": "us"
        },
        {
            "name": "East Orange",
            "country": "us"
        },
        {
            "name": "Shawnee",
            "country": "us"
        },
        {
            "name": "Homestead",
            "country": "us"
        },
        {
            "name": "Rockville",
            "country": "us"
        },
        {
            "name": "Delray Beach",
            "country": "us"
        },
        {
            "name": "Janesville",
            "country": "us"
        },
        {
            "name": "Conway",
            "country": "us"
        },
        {
            "name": "Pico Rivera",
            "country": "us"
        },
        {
            "name": "Lorain",
            "country": "us"
        },
        {
            "name": "Montebello",
            "country": "us"
        },
        {
            "name": "Lodi",
            "country": "us"
        },
        {
            "name": "New Braunfels",
            "country": "us"
        },
        {
            "name": "Marysville",
            "country": "us"
        },
        {
            "name": "Tamarac",
            "country": "us"
        },
        {
            "name": "Madera",
            "country": "us"
        },
        {
            "name": "Conroe",
            "country": "us"
        },
        {
            "name": "Santa Cruz",
            "country": "us"
        },
        {
            "name": "Eden Prairie",
            "country": "us"
        },
        {
            "name": "Cheyenne",
            "country": "us"
        },
        {
            "name": "Daytona Beach",
            "country": "us"
        },
        {
            "name": "Alpharetta",
            "country": "us"
        },
        {
            "name": "Hamilton",
            "country": "us"
        },
        {
            "name": "Waltham",
            "country": "us"
        },
        {
            "name": "Coon Rapids",
            "country": "us"
        },
        {
            "name": "Haverhill",
            "country": "us"
        },
        {
            "name": "Council Bluffs",
            "country": "us"
        },
        {
            "name": "Taylor",
            "country": "us"
        },
        {
            "name": "Utica",
            "country": "us"
        },
        {
            "name": "Ames",
            "country": "us"
        },
        {
            "name": "La Habra",
            "country": "us"
        },
        {
            "name": "Encinitas",
            "country": "us"
        },
        {
            "name": "Bowling Green",
            "country": "us"
        },
        {
            "name": "Burnsville",
            "country": "us"
        },
        {
            "name": "Greenville",
            "country": "us"
        },
        {
            "name": "West Des Moines",
            "country": "us"
        },
        {
            "name": "Cedar Park",
            "country": "us"
        },
        {
            "name": "Tulare",
            "country": "us"
        },
        {
            "name": "Monterey Park",
            "country": "us"
        },
        {
            "name": "Vineland",
            "country": "us"
        },
        {
            "name": "Terre Haute",
            "country": "us"
        },
        {
            "name": "North Miami",
            "country": "us"
        },
        {
            "name": "Mansfield",
            "country": "us"
        },
        {
            "name": "West Allis",
            "country": "us"
        },
        {
            "name": "Bristol",
            "country": "us"
        },
        {
            "name": "Taylorsville",
            "country": "us"
        },
        {
            "name": "Malden",
            "country": "us"
        },
        {
            "name": "Meriden",
            "country": "us"
        },
        {
            "name": "Blaine",
            "country": "us"
        },
        {
            "name": "Wellington",
            "country": "us"
        },
        {
            "name": "Cupertino",
            "country": "us"
        },
        {
            "name": "Springfield",
            "country": "us"
        },
        {
            "name": "Rogers",
            "country": "us"
        },
        {
            "name": "St. Clair Shores",
            "country": "us"
        },
        {
            "name": "Gardena",
            "country": "us"
        },
        {
            "name": "Pontiac",
            "country": "us"
        },
        {
            "name": "National City",
            "country": "us"
        },
        {
            "name": "Grand Junction",
            "country": "us"
        },
        {
            "name": "Rocklin",
            "country": "us"
        },
        {
            "name": "Chapel Hill",
            "country": "us"
        },
        {
            "name": "Casper",
            "country": "us"
        },
        {
            "name": "Broomfield",
            "country": "us"
        },
        {
            "name": "Petaluma",
            "country": "us"
        },
        {
            "name": "South Jordan",
            "country": "us"
        },
        {
            "name": "Springfield",
            "country": "us"
        },
        {
            "name": "Great Falls",
            "country": "us"
        },
        {
            "name": "Lancaster",
            "country": "us"
        },
        {
            "name": "North Port",
            "country": "us"
        },
        {
            "name": "Lakewood",
            "country": "us"
        },
        {
            "name": "Marietta",
            "country": "us"
        },
        {
            "name": "San Rafael",
            "country": "us"
        },
        {
            "name": "Royal Oak",
            "country": "us"
        },
        {
            "name": "Des Plaines",
            "country": "us"
        },
        {
            "name": "Huntington Park",
            "country": "us"
        },
        {
            "name": "La Mesa",
            "country": "us"
        },
        {
            "name": "Orland Park",
            "country": "us"
        },
        {
            "name": "Auburn",
            "country": "us"
        },
        {
            "name": "Lakeville",
            "country": "us"
        },
        {
            "name": "Owensboro",
            "country": "us"
        },
        {
            "name": "Moore",
            "country": "us"
        },
        {
            "name": "Jupiter",
            "country": "us"
        },
        {
            "name": "Idaho Falls",
            "country": "us"
        },
        {
            "name": "Dubuque",
            "country": "us"
        },
        {
            "name": "Bartlett",
            "country": "us"
        },
        {
            "name": "Rowlett",
            "country": "us"
        },
        {
            "name": "Novi",
            "country": "us"
        },
        {
            "name": "White Plains",
            "country": "us"
        },
        {
            "name": "Arcadia",
            "country": "us"
        },
        {
            "name": "Redmond",
            "country": "us"
        },
        {
            "name": "Lake Elsinore",
            "country": "us"
        },
        {
            "name": "Ocala",
            "country": "us"
        },
        {
            "name": "Tinley Park",
            "country": "us"
        },
        {
            "name": "Port Orange",
            "country": "us"
        },
        {
            "name": "Medford",
            "country": "us"
        },
        {
            "name": "Oak Lawn",
            "country": "us"
        },
        {
            "name": "Rocky Mount",
            "country": "us"
        },
        {
            "name": "Kokomo",
            "country": "us"
        },
        {
            "name": "Coconut Creek",
            "country": "us"
        },
        {
            "name": "Bowie",
            "country": "us"
        },
        {
            "name": "Berwyn",
            "country": "us"
        },
        {
            "name": "Midwest City",
            "country": "us"
        },
        {
            "name": "Fountain Valley",
            "country": "us"
        },
        {
            "name": "Buckeye",
            "country": "us"
        },
        {
            "name": "Dearborn Heights",
            "country": "us"
        },
        {
            "name": "Woodland",
            "country": "us"
        },
        {
            "name": "Noblesville",
            "country": "us"
        },
        {
            "name": "Valdosta",
            "country": "us"
        },
        {
            "name": "Diamond Bar",
            "country": "us"
        },
        {
            "name": "Manhattan",
            "country": "us"
        },
        {
            "name": "Santee",
            "country": "us"
        },
        {
            "name": "Taunton",
            "country": "us"
        },
        {
            "name": "Sanford",
            "country": "us"
        },
        {
            "name": "Kettering",
            "country": "us"
        },
        {
            "name": "New Brunswick",
            "country": "us"
        },
        {
            "name": "Decatur",
            "country": "us"
        },
        {
            "name": "Chicopee",
            "country": "us"
        },
        {
            "name": "Anderson",
            "country": "us"
        },
        {
            "name": "Margate",
            "country": "us"
        },
        {
            "name": "Weymouth Town",
            "country": "us"
        },
        {
            "name": "Hempstead",
            "country": "us"
        },
        {
            "name": "Corvallis",
            "country": "us"
        },
        {
            "name": "Eastvale",
            "country": "us"
        },
        {
            "name": "Porterville",
            "country": "us"
        },
        {
            "name": "West Haven",
            "country": "us"
        },
        {
            "name": "Brentwood",
            "country": "us"
        },
        {
            "name": "Paramount",
            "country": "us"
        },
        {
            "name": "Grand Forks",
            "country": "us"
        },
        {
            "name": "Georgetown",
            "country": "us"
        },
        {
            "name": "St. Peters",
            "country": "us"
        },
        {
            "name": "Shoreline",
            "country": "us"
        },
        {
            "name": "Mount Prospect",
            "country": "us"
        },
        {
            "name": "Hanford",
            "country": "us"
        },
        {
            "name": "Normal",
            "country": "us"
        },
        {
            "name": "Rosemead",
            "country": "us"
        },
        {
            "name": "Lehi",
            "country": "us"
        },
        {
            "name": "Pocatello",
            "country": "us"
        },
        {
            "name": "Highland",
            "country": "us"
        },
        {
            "name": "Novato",
            "country": "us"
        },
        {
            "name": "Port Arthur",
            "country": "us"
        },
        {
            "name": "Carson City",
            "country": "us"
        },
        {
            "name": "San Marcos",
            "country": "us"
        },
        {
            "name": "Hendersonville",
            "country": "us"
        },
        {
            "name": "Elyria",
            "country": "us"
        },
        {
            "name": "Revere",
            "country": "us"
        },
        {
            "name": "Pflugerville",
            "country": "us"
        },
        {
            "name": "Greenwood",
            "country": "us"
        },
        {
            "name": "Bellevue",
            "country": "us"
        },
        {
            "name": "Wheaton",
            "country": "us"
        },
        {
            "name": "Smyrna",
            "country": "us"
        },
        {
            "name": "Sarasota",
            "country": "us"
        },
        {
            "name": "Blue Springs",
            "country": "us"
        },
        {
            "name": "Colton",
            "country": "us"
        },
        {
            "name": "Euless",
            "country": "us"
        },
        {
            "name": "Castle Rock",
            "country": "us"
        },
        {
            "name": "Cathedral City",
            "country": "us"
        },
        {
            "name": "Kingsport",
            "country": "us"
        },
        {
            "name": "Lake Havasu City",
            "country": "us"
        },
        {
            "name": "Pensacola",
            "country": "us"
        },
        {
            "name": "Hoboken",
            "country": "us"
        },
        {
            "name": "Yucaipa",
            "country": "us"
        },
        {
            "name": "Watsonville",
            "country": "us"
        },
        {
            "name": "Richland",
            "country": "us"
        },
        {
            "name": "Delano",
            "country": "us"
        },
        {
            "name": "Hoffman Estates",
            "country": "us"
        },
        {
            "name": "Florissant",
            "country": "us"
        },
        {
            "name": "Placentia",
            "country": "us"
        },
        {
            "name": "Dublin",
            "country": "us"
        },
        {
            "name": "Oak Park",
            "country": "us"
        },
        {
            "name": "Peabody",
            "country": "us"
        },
        {
            "name": "Perth Amboy",
            "country": "us"
        },
        {
            "name": "Battle Creek",
            "country": "us"
        },
        {
            "name": "Bradenton",
            "country": "us"
        },
        {
            "name": "Gilroy",
            "country": "us"
        },
        {
            "name": "Milford",
            "country": "us"
        },
        {
            "name": "Albany",
            "country": "us"
        },
        {
            "name": "Ankeny",
            "country": "us"
        },
        {
            "name": "La Crosse",
            "country": "us"
        },
        {
            "name": "Burlington",
            "country": "us"
        },
        {
            "name": "DeSoto",
            "country": "us"
        },
        {
            "name": "Harrisonburg",
            "country": "us"
        },
        {
            "name": "Minnetonka",
            "country": "us"
        },
        {
            "name": "Elkhart",
            "country": "us"
        },
        {
            "name": "Lakewood",
            "country": "us"
        },
        {
            "name": "Glendora",
            "country": "us"
        },
        {
            "name": "Southaven",
            "country": "us"
        },
        {
            "name": "Charleston",
            "country": "us"
        },
        {
            "name": "Joplin",
            "country": "us"
        },
        {
            "name": "Enid",
            "country": "us"
        },
        {
            "name": "Palm Beach Gardens",
            "country": "us"
        },
        {
            "name": "Brookhaven",
            "country": "us"
        },
        {
            "name": "Plainfield",
            "country": "us"
        },
        {
            "name": "Grand Island",
            "country": "us"
        },
        {
            "name": "Palm Desert",
            "country": "us"
        },
        {
            "name": "Huntersville",
            "country": "us"
        },
        {
            "name": "Tigard",
            "country": "us"
        },
        {
            "name": "Lenexa",
            "country": "us"
        },
        {
            "name": "Saginaw",
            "country": "us"
        },
        {
            "name": "Kentwood",
            "country": "us"
        },
        {
            "name": "Doral",
            "country": "us"
        },
        {
            "name": "Apple Valley",
            "country": "us"
        },
        {
            "name": "Grapevine",
            "country": "us"
        },
        {
            "name": "Aliso Viejo",
            "country": "us"
        },
        {
            "name": "Sammamish",
            "country": "us"
        },
        {
            "name": "Casa Grande",
            "country": "us"
        },
        {
            "name": "Pinellas Park",
            "country": "us"
        },
        {
            "name": "Troy",
            "country": "us"
        },
        {
            "name": "West Sacramento",
            "country": "us"
        },
        {
            "name": "Burien",
            "country": "us"
        },
        {
            "name": "Commerce City",
            "country": "us"
        },
        {
            "name": "Monroe",
            "country": "us"
        },
        {
            "name": "Cerritos",
            "country": "us"
        },
        {
            "name": "Downers Grove",
            "country": "us"
        },
        {
            "name": "Coral Gables",
            "country": "us"
        },
        {
            "name": "Wilson",
            "country": "us"
        },
        {
            "name": "Niagara Falls",
            "country": "us"
        },
        {
            "name": "Poway",
            "country": "us"
        },
        {
            "name": "Edina",
            "country": "us"
        },
        {
            "name": "Cuyahoga Falls",
            "country": "us"
        },
        {
            "name": "Rancho Santa Margarita",
            "country": "us"
        },
        {
            "name": "Harrisburg",
            "country": "us"
        },
        {
            "name": "Huntington",
            "country": "us"
        },
        {
            "name": "La Mirada",
            "country": "us"
        },
        {
            "name": "Cypress",
            "country": "us"
        },
        {
            "name": "Caldwell",
            "country": "us"
        },
        {
            "name": "Logan",
            "country": "us"
        },
        {
            "name": "Galveston",
            "country": "us"
        },
        {
            "name": "Sheboygan",
            "country": "us"
        },
        {
            "name": "Middletown",
            "country": "us"
        },
        {
            "name": "Murray",
            "country": "us"
        },
        {
            "name": "Roswell",
            "country": "us"
        },
        {
            "name": "Parker",
            "country": "us"
        },
        {
            "name": "Bedford",
            "country": "us"
        },
        {
            "name": "East Lansing",
            "country": "us"
        },
        {
            "name": "Methuen",
            "country": "us"
        },
        {
            "name": "Covina",
            "country": "us"
        },
        {
            "name": "Alexandria",
            "country": "us"
        },
        {
            "name": "Olympia",
            "country": "us"
        },
        {
            "name": "Euclid",
            "country": "us"
        },
        {
            "name": "Mishawaka",
            "country": "us"
        },
        {
            "name": "Salina",
            "country": "us"
        },
        {
            "name": "Azusa",
            "country": "us"
        },
        {
            "name": "Chesterfield",
            "country": "us"
        },
        {
            "name": "Leesburg",
            "country": "us"
        },
        {
            "name": "Dunwoody",
            "country": "us"
        },
        {
            "name": "Hattiesburg",
            "country": "us"
        },
        {
            "name": "Roseville",
            "country": "us"
        },
        {
            "name": "Bonita Springs",
            "country": "us"
        },
        {
            "name": "Portage",
            "country": "us"
        },
        {
            "name": "St. Louis Park",
            "country": "us"
        },
        {
            "name": "Collierville",
            "country": "us"
        },
        {
            "name": "Middletown",
            "country": "us"
        },
        {
            "name": "Stillwater",
            "country": "us"
        },
        {
            "name": "East Providence",
            "country": "us"
        },
        {
            "name": "Lawrence",
            "country": "us"
        },
        {
            "name": "Wauwatosa",
            "country": "us"
        },
        {
            "name": "Mentor",
            "country": "us"
        },
        {
            "name": "Ceres",
            "country": "us"
        },
        {
            "name": "Cedar Hill",
            "country": "us"
        },
        {
            "name": "Mansfield",
            "country": "us"
        },
        {
            "name": "Binghamton",
            "country": "us"
        },
        {
            "name": "Coeur d'Alene",
            "country": "us"
        },
        {
            "name": "San Luis Obispo",
            "country": "us"
        },
        {
            "name": "Minot",
            "country": "us"
        },
        {
            "name": "Palm Springs",
            "country": "us"
        },
        {
            "name": "Pine Bluff",
            "country": "us"
        },
        {
            "name": "Texas City",
            "country": "us"
        },
        {
            "name": "Summerville",
            "country": "us"
        },
        {
            "name": "Twin Falls",
            "country": "us"
        },
        {
            "name": "Jeffersonville",
            "country": "us"
        },
        {
            "name": "San Jacinto",
            "country": "us"
        },
        {
            "name": "Madison",
            "country": "us"
        },
        {
            "name": "Altoona",
            "country": "us"
        },
        {
            "name": "Columbus",
            "country": "us"
        },
        {
            "name": "Beavercreek",
            "country": "us"
        },
        {
            "name": "Apopka",
            "country": "us"
        },
        {
            "name": "Elmhurst",
            "country": "us"
        },
        {
            "name": "Maricopa",
            "country": "us"
        },
        {
            "name": "Farmington",
            "country": "us"
        },
        {
            "name": "Glenview",
            "country": "us"
        },
        {
            "name": "Cleveland Heights",
            "country": "us"
        },
        {
            "name": "Draper",
            "country": "us"
        },
        {
            "name": "Lincoln",
            "country": "us"
        },
        {
            "name": "Sierra Vista",
            "country": "us"
        },
        {
            "name": "Lacey",
            "country": "us"
        },
        {
            "name": "Biloxi",
            "country": "us"
        },
        {
            "name": "Strongsville",
            "country": "us"
        },
        {
            "name": "Barnstable Town",
            "country": "us"
        },
        {
            "name": "Wylie",
            "country": "us"
        },
        {
            "name": "Sayreville",
            "country": "us"
        },
        {
            "name": "Kannapolis",
            "country": "us"
        },
        {
            "name": "Charlottesville",
            "country": "us"
        },
        {
            "name": "Littleton",
            "country": "us"
        },
        {
            "name": "Titusville",
            "country": "us"
        },
        {
            "name": "Hackensack",
            "country": "us"
        },
        {
            "name": "Pittsfield",
            "country": "us"
        },
        {
            "name": "Lombard",
            "country": "us"
        },
        {
            "name": "Attleboro",
            "country": "us"
        },
        {
            "name": "DeKalb",
            "country": "us"
        },
        {
            "name": "Blacksburg",
            "country": "us"
        },
        {
            "name": "Dublin",
            "country": "us"
        },
        {
            "name": "Haltom City",
            "country": "us"
        },
        {
            "name": "Lompoc",
            "country": "us"
        },
        {
            "name": "El Centro",
            "country": "us"
        },
        {
            "name": "Danville",
            "country": "us"
        },
        {
            "name": "Jefferson City",
            "country": "us"
        },
        {
            "name": "Cutler Bay",
            "country": "us"
        },
        {
            "name": "Oakland Park",
            "country": "us"
        },
        {
            "name": "North Miami Beach",
            "country": "us"
        },
        {
            "name": "Freeport",
            "country": "us"
        },
        {
            "name": "Moline",
            "country": "us"
        },
        {
            "name": "Coachella",
            "country": "us"
        },
        {
            "name": "Fort Pierce",
            "country": "us"
        },
        {
            "name": "Smyrna",
            "country": "us"
        },
        {
            "name": "Bountiful",
            "country": "us"
        },
        {
            "name": "Fond du Lac",
            "country": "us"
        },
        {
            "name": "Everett",
            "country": "us"
        },
        {
            "name": "Danville",
            "country": "us"
        },
        {
            "name": "Keller",
            "country": "us"
        },
        {
            "name": "Belleville",
            "country": "us"
        },
        {
            "name": "Bell Gardens",
            "country": "us"
        },
        {
            "name": "Cleveland",
            "country": "us"
        },
        {
            "name": "North Lauderdale",
            "country": "us"
        },
        {
            "name": "Fairfield",
            "country": "us"
        },
        {
            "name": "Salem",
            "country": "us"
        },
        {
            "name": "Rancho Palos Verdes",
            "country": "us"
        },
        {
            "name": "San Bruno",
            "country": "us"
        },
        {
            "name": "Concord",
            "country": "us"
        },
        {
            "name": "Burlington",
            "country": "us"
        },
        {
            "name": "Apex",
            "country": "us"
        },
        {
            "name": "Midland",
            "country": "us"
        },
        {
            "name": "Altamonte Springs",
            "country": "us"
        },
        {
            "name": "Hutchinson",
            "country": "us"
        },
        {
            "name": "Buffalo Grove",
            "country": "us"
        },
        {
            "name": "Urbandale",
            "country": "us"
        },
        {
            "name": "State College",
            "country": "us"
        },
        {
            "name": "Urbana",
            "country": "us"
        },
        {
            "name": "Plainfield",
            "country": "us"
        },
        {
            "name": "Manassas",
            "country": "us"
        },
        {
            "name": "Bartlett",
            "country": "us"
        },
        {
            "name": "Kearny",
            "country": "us"
        },
        {
            "name": "Oro Valley",
            "country": "us"
        },
        {
            "name": "Findlay",
            "country": "us"
        },
        {
            "name": "Rohnert Park",
            "country": "us"
        },
        {
            "name": "Westfield",
            "country": "us"
        },
        {
            "name": "Linden",
            "country": "us"
        },
        {
            "name": "Sumter",
            "country": "us"
        },
        {
            "name": "Wilkes-Barre",
            "country": "us"
        },
        {
            "name": "Woonsocket",
            "country": "us"
        },
        {
            "name": "Leominster",
            "country": "us"
        },
        {
            "name": "Shelton",
            "country": "us"
        },
        {
            "name": "Brea",
            "country": "us"
        },
        {
            "name": "Covington",
            "country": "us"
        },
        {
            "name": "Rockwall",
            "country": "us"
        },
        {
            "name": "Meridian",
            "country": "us"
        },
        {
            "name": "Riverton",
            "country": "us"
        },
        {
            "name": "St. Cloud",
            "country": "us"
        },
        {
            "name": "Quincy",
            "country": "us"
        },
        {
            "name": "Morgan Hill",
            "country": "us"
        },
        {
            "name": "Warren",
            "country": "us"
        },
        {
            "name": "Edmonds",
            "country": "us"
        },
        {
            "name": "Burleson",
            "country": "us"
        },
        {
            "name": "Beverly",
            "country": "us"
        },
        {
            "name": "Mankato",
            "country": "us"
        },
        {
            "name": "Hagerstown",
            "country": "us"
        },
        {
            "name": "Prescott",
            "country": "us"
        },
        {
            "name": "Campbell",
            "country": "us"
        },
        {
            "name": "Cedar Falls",
            "country": "us"
        },
        {
            "name": "Beaumont",
            "country": "us"
        },
        {
            "name": "La Puente",
            "country": "us"
        },
        {
            "name": "Crystal Lake",
            "country": "us"
        },
        {
            "name": "Fitchburg",
            "country": "us"
        },
        {
            "name": "Carol Stream",
            "country": "us"
        },
        {
            "name": "Hickory",
            "country": "us"
        },
        {
            "name": "Streamwood",
            "country": "us"
        },
        {
            "name": "Norwich",
            "country": "us"
        },
        {
            "name": "Coppell",
            "country": "us"
        },
        {
            "name": "San Gabriel",
            "country": "us"
        },
        {
            "name": "Holyoke",
            "country": "us"
        },
        {
            "name": "Bentonville",
            "country": "us"
        },
        {
            "name": "Florence",
            "country": "us"
        },
        {
            "name": "Peachtree Corners",
            "country": "us"
        },
        {
            "name": "Brentwood",
            "country": "us"
        },
        {
            "name": "Bozeman",
            "country": "us"
        },
        {
            "name": "New Berlin",
            "country": "us"
        },
        {
            "name": "Goose Creek",
            "country": "us"
        },
        {
            "name": "Huntsville",
            "country": "us"
        },
        {
            "name": "Prescott Valley",
            "country": "us"
        },
        {
            "name": "Maplewood",
            "country": "us"
        },
        {
            "name": "Romeoville",
            "country": "us"
        },
        {
            "name": "Duncanville",
            "country": "us"
        },
        {
            "name": "Atlantic City",
            "country": "us"
        },
        {
            "name": "Clovis",
            "country": "us"
        },
        {
            "name": "The Colony",
            "country": "us"
        },
        {
            "name": "Culver City",
            "country": "us"
        },
        {
            "name": "Marlborough",
            "country": "us"
        },
        {
            "name": "Hilton Head Island",
            "country": "us"
        },
        {
            "name": "Moorhead",
            "country": "us"
        },
        {
            "name": "Calexico",
            "country": "us"
        },
        {
            "name": "Bullhead City",
            "country": "us"
        },
        {
            "name": "Germantown",
            "country": "us"
        },
        {
            "name": "La Quinta",
            "country": "us"
        },
        {
            "name": "Lancaster",
            "country": "us"
        },
        {
            "name": "Wausau",
            "country": "us"
        },
        {
            "name": "Sherman",
            "country": "us"
        },
        {
            "name": "Ocoee",
            "country": "us"
        },
        {
            "name": "Shakopee",
            "country": "us"
        },
        {
            "name": "Woburn",
            "country": "us"
        },
        {
            "name": "Bremerton",
            "country": "us"
        },
        {
            "name": "Rock Island",
            "country": "us"
        },
        {
            "name": "Muskogee",
            "country": "us"
        },
        {
            "name": "Cape Girardeau",
            "country": "us"
        },
        {
            "name": "Annapolis",
            "country": "us"
        },
        {
            "name": "Greenacres",
            "country": "us"
        },
        {
            "name": "Ormond Beach",
            "country": "us"
        },
        {
            "name": "Hallandale Beach",
            "country": "us"
        },
        {
            "name": "Stanton",
            "country": "us"
        },
        {
            "name": "Puyallup",
            "country": "us"
        },
        {
            "name": "Pacifica",
            "country": "us"
        },
        {
            "name": "Hanover Park",
            "country": "us"
        },
        {
            "name": "Hurst",
            "country": "us"
        },
        {
            "name": "Lima",
            "country": "us"
        },
        {
            "name": "Marana",
            "country": "us"
        },
        {
            "name": "Carpentersville",
            "country": "us"
        },
        {
            "name": "Oakley",
            "country": "us"
        },
        {
            "name": "Huber Heights",
            "country": "us"
        },
        {
            "name": "Lancaster",
            "country": "us"
        },
        {
            "name": "Montclair",
            "country": "us"
        },
        {
            "name": "Wheeling",
            "country": "us"
        },
        {
            "name": "Brookfield",
            "country": "us"
        },
        {
            "name": "Park Ridge",
            "country": "us"
        },
        {
            "name": "Florence",
            "country": "us"
        },
        {
            "name": "Roy",
            "country": "us"
        },
        {
            "name": "Winter Garden",
            "country": "us"
        },
        {
            "name": "Chelsea",
            "country": "us"
        },
        {
            "name": "Valley Stream",
            "country": "us"
        },
        {
            "name": "Spartanburg",
            "country": "us"
        },
        {
            "name": "Lake Oswego",
            "country": "us"
        },
        {
            "name": "Friendswood",
            "country": "us"
        },
        {
            "name": "Westerville",
            "country": "us"
        },
        {
            "name": "Northglenn",
            "country": "us"
        },
        {
            "name": "Phenix City",
            "country": "us"
        },
        {
            "name": "Grove City",
            "country": "us"
        },
        {
            "name": "Texarkana",
            "country": "us"
        },
        {
            "name": "Addison",
            "country": "us"
        },
        {
            "name": "Dover",
            "country": "us"
        },
        {
            "name": "Lincoln Park",
            "country": "us"
        },
        {
            "name": "Calumet City",
            "country": "us"
        },
        {
            "name": "Muskegon",
            "country": "us"
        },
        {
            "name": "Aventura",
            "country": "us"
        },
        {
            "name": "Martinez",
            "country": "us"
        },
        {
            "name": "Greenfield",
            "country": "us"
        },
        {
            "name": "Apache Junction",
            "country": "us"
        },
        {
            "name": "Monrovia",
            "country": "us"
        },
        {
            "name": "Weslaco",
            "country": "us"
        },
        {
            "name": "Keizer",
            "country": "us"
        },
        {
            "name": "Spanish Fork",
            "country": "us"
        },
        {
            "name": "Beloit",
            "country": "us"
        },
        {
            "name": "Panama City",
            "country": "us"
        },
    {
        "name": "Tokyo",
        "country": "jp"
    },
    {
        "name": "Delhi",
        "country": "in"
    },
    {
        "name": "Sao Paulo",
        "country": "br"
    },
    {
        "name": "Mexico City",
        "country": "mx"
    },
    {
        "name": "Dhaka",
        "country": "bd"
    },
    {
        "name": "Cairo",
        "country": "eg"
    },
    {
        "name": "Mumbai",
        "country": "in"
    },
    {
        "name": "Osaka",
        "country": "jp"
    },
    {
        "name": "Karachi",
        "country": "pk"
    },
    {
        "name": "Istanbul",
        "country": "tr"
    },
    {
        "name": "Buenos Aires",
        "country": "ar"
    },
    {
        "name": "Kolkata",
        "country": "in"
    },
    {
        "name": "Lagos",
        "country": "ng"
    },
    {
        "name": "Manila",
        "country": "ph"
    },
    {
        "name": "Rio de Janeiro",
        "country": "br"
    },
    {
        "name": "Lahore",
        "country": "pk"
    },
    {
        "name": "Bangalore",
        "country": "in"
    },
    {
        "name": "Chennai",
        "country": "in"
    },
    {
        "name": "Bogota",
        "country": "co"
    },
    {
        "name": "Paris",
        "country": "fr"
    },
    {
        "name": "Jakarta",
        "country": "id"
    },
    {
        "name": "Lima",
        "country": "pe"
    },
    {
        "name": "Bangkok",
        "country": "th"
    },
    {
        "name": "Hyderabad",
        "country": "in"
    },
    {
        "name": "Seoul",
        "country": "kr"
    },
    {
        "name": "Nagoya",
        "country": "jp"
    },
    {
        "name": "London",
        "country": "gb"
    },
    {
        "name": "Luanda",
        "country": "ao"
    },
    {
        "name": "Ahmedabad",
        "country": "in"
    },
    {
        "name": "Kuala Lumpur",
        "country": "my"
    },
    {
        "name": "Hong Kong",
        "country": "hk"
    },
    {
        "name": "Surat",
        "country": "in"
    },
    {
        "name": "Riyadh",
        "country": "sa"
    },
    {
        "name": "Baghdad",
        "country": "iq"
    },
    {
        "name": "Santiago",
        "country": "cl"
    },
    {
        "name": "Pune",
        "country": "in"
    },
    {
        "name": "Madrid",
        "country": "es"
    },
    {
        "name": "Toronto",
        "country": "ca"
    },
    {
        "name": "Belo Horizonte",
        "country": "br"
    },
    {
        "name": "Singapore",
        "country": "sg"
    },
    {
        "name": "Khartoum",
        "country": "sd"
    },
    {
        "name": "Johannesburg",
        "country": "za"
    },
    {
        "name": "Barcelona",
        "country": "es"
    },
    {
        "name": "Fukuoka",
        "country": "jp"
    },
    {
        "name": "Yangon",
        "country": "mm"
    },
    {
        "name": "Alexandria",
        "country": "eg"
    },
    {
        "name": "Guadalajara",
        "country": "mx"
    },
    {
        "name": "Ankara",
        "country": "tr"
    },
    {
        "name": "Chittagong",
        "country": "bd"
    },
    {
        "name": "Melbourne",
        "country": "au"
    },
    {
        "name": "Addis Ababa",
        "country": "et"
    },
    {
        "name": "Sydney",
        "country": "au"
    },
    {
        "name": "Monterrey",
        "country": "mx"
    },
    {
        "name": "Nairobi",
        "country": "ke"
    },
    {
        "name": "Brasilia",
        "country": "br"
    },
    {
        "name": "Cape Town",
        "country": "za"
    },
    {
        "name": "Jiddah",
        "country": "sa"
    },
    {
        "name": "Rome",
        "country": "it"
    },
    {
        "name": "Tel Aviv",
        "country": "il"
    },
    {
        "name": "Montreal",
        "country": "ca"
    },
    {
        "name": "Recife",
        "country": "br"
    },
    {
        "name": "Yaounde",
        "country": "cm"
    },
    {
        "name": "Porto Alegre",
        "country": "br"
    },
    {
        "name": "Fortaleza",
        "country": "br"
    },
    {
        "name": "Kano",
        "country": "ng"
    },
    {
        "name": "Medellin",
        "country": "co"
    },
    {
        "name": "Jaipur",
        "country": "in"
    },
    {
        "name": "Ekurhuleni",
        "country": "za"
    },
    {
        "name": "Salvador",
        "country": "br"
    },
    {
        "name": "Casablanca",
        "country": "ma"
    },
    {
        "name": "Douala",
        "country": "cm"
    },
    {
        "name": "Lucknow",
        "country": "in"
    },
    {
        "name": "Kozhikode",
        "country": "in"
    },
    {
        "name": "Curitiba",
        "country": "br"
    },
    {
        "name": "Ibadan",
        "country": "ng"
    },
    {
        "name": "Malappuram",
        "country": "in"
    },
    {
        "name": "Berlin",
        "country": "de"
    },
    {
        "name": "Faisalabad",
        "country": "pk"
    },
    {
        "name": "Antananarivo",
        "country": "mg"
    },
    {
        "name": "Bekasi",
        "country": "id"
    },
    {
        "name": "Kumasi",
        "country": "gh"
    },
    {
        "name": "Kampala",
        "country": "ug"
    },
    {
        "name": "Busan",
        "country": "kr"
    },
    {
        "name": "Abuja",
        "country": "ng"
    },
    {
        "name": "Asuncion",
        "country": "py"
    },
    {
        "name": "Santo Domingo",
        "country": "do"
    },
    {
        "name": "Campinas",
        "country": "br"
    },
    {
        "name": "Puebla",
        "country": "mx"
    },
    {
        "name": "Dakar",
        "country": "sn"
    },
    {
        "name": "Thrissur",
        "country": "in"
    },
    {
        "name": "Kochi",
        "country": "in"
    },
    {
        "name": "Kuwait City",
        "country": "kw"
    },
    {
        "name": "Durban",
        "country": "za"
    },
    {
        "name": "Port Harcourt",
        "country": "ng"
    },
    {
        "name": "Kanpur",
        "country": "in"
    },
    {
        "name": "Athens",
        "country": "gr"
    },
    {
        "name": "Milan",
        "country": "it"
    },
    {
        "name": "Indore",
        "country": "in"
    },
    {
        "name": "Pyongyang",
        "country": "kp"
    },
    {
        "name": "Sanaa",
        "country": "ye"
    },
    {
        "name": "Guayaquil",
        "country": "ec"
    },
    {
        "name": "Izmir",
        "country": "tr"
    },
    {
        "name": "Kiev",
        "country": "ua"
    },
    {
        "name": "Guatemala City",
        "country": "gt"
    },
    {
        "name": "Surabaya",
        "country": "id"
    },
    {
        "name": "Lisbon",
        "country": "pt"
    },
    {
        "name": "Nagpur",
        "country": "in"
    },
    {
        "name": "Shizuoka",
        "country": "jp"
    },
    {
        "name": "Dubai",
        "country": "ae"
    },
    {
        "name": "Ouagadougou",
        "country": "bf"
    },
    {
        "name": "Lusaka",
        "country": "zm"
    },
    {
        "name": "Coimbatore",
        "country": "in"
    },
    {
        "name": "Port-au-Prince",
        "country": "HT"
    },
    {
        "name": "Depok",
        "country": "id"
    },
    {
        "name": "Incheon",
        "country": "kr"
    },
    {
        "name": "Cali",
        "country": "co"
    },
    {
        "name": "Algiers",
        "country": "dz"
    },
    {
        "name": "Manchester",
        "country": "gb"
    },
    {
        "name": "Goiania",
        "country": "br"
    },
    {
        "name": "Bamako",
        "country": "ml"
    },
    {
        "name": "Thiruvananthapuram",
        "country": "in"
    },
    {
        "name": "Sapporo",
        "country": "jp"
    },
    {
        "name": "Pretoria",
        "country": "za"
    },
    {
        "name": "Birmingham",
        "country": "gb"
    },
    {
        "name": "Bandung",
        "country": "id"
    },
    {
        "name": "Vancouver",
        "country": "ca"
    },
    {
        "name": "Accra",
        "country": "gh"
    },
    {
        "name": "Tashkent",
        "country": "uz"
    },
    {
        "name": "Toluca de Lerdo",
        "country": "mx"
    },
    {
        "name": "Patna",
        "country": "in"
    },
    {
        "name": "Bhopal",
        "country": "in"
    },
    {
        "name": "San Juan",
        "country": "pr"
    },
    {
        "name": "Brisbane",
        "country": "au"
    },
    {
        "name": "Beirut",
        "country": "lb"
    },
    {
        "name": "Tunis",
        "country": "tn"
    },
    {
        "name": "Tangerang",
        "country": "id"
    },
    {
        "name": "Baku",
        "country": "az"
    },
    {
        "name": "Medan",
        "country": "id"
    },
    {
        "name": "Belem",
        "country": "br"
    },
    {
        "name": "Sendai",
        "country": "jp"
    },
    {
        "name": "Manaus",
        "country": "br"
    },
    {
        "name": "Barranquilla",
        "country": "co"
    },
    {
        "name": "Gujranwala",
        "country": "pk"
    },
    {
        "name": "Rawalpindi",
        "country": "pk"
    },
    {
        "name": "Peshawar",
        "country": "pk"
    },
    {
        "name": "Agra",
        "country": "in"
    },
    {
        "name": "Vadodara",
        "country": "in"
    },
    {
        "name": "Kannur",
        "country": "in"
    },
    {
        "name": "Visakhapatnam",
        "country": "in"
    },
    {
        "name": "Daegu",
        "country": "kr"
    },
    {
        "name": "Naples",
        "country": "it"
    },
    {
        "name": "Amman",
        "country": "jo"
    },
    {
        "name": "Tijuana",
        "country": "mx"
    },
    {
        "name": "Phnom Penh",
        "country": "kh"
    },
    {
        "name": "Havana",
        "country": "cu"
    },
    {
        "name": "Nashik",
        "country": "in"
    },
    {
        "name": "Grande Vitoria",
        "country": "br"
    },
    {
        "name": "Vijayawada",
        "country": "in"
    },
    {
        "name": "Brussels",
        "country": "be"
    },
    {
        "name": "Mecca",
        "country": "sa"
    },
    {
        "name": "Hiroshima",
        "country": "jp"
    },
    {
        "name": "Perth",
        "country": "au"
    },
    {
        "name": "Multan",
        "country": "pk"
    },
    {
        "name": "Minsk",
        "country": "by"
    },
    {
        "name": "Bursa",
        "country": "tr"
    },
    {
        "name": "Conakry",
        "country": "gn"
    },
    {
        "name": "Vienna",
        "country": "at"
    },
    {
        "name": "Kollam",
        "country": "in"
    },
    {
        "name": "Rajkot",
        "country": "in"
    },
    {
        "name": "Almaty",
        "country": "kz"
    },
    {
        "name": "Baixada Santista",
        "country": "br"
    },
    {
        "name": "Rabat",
        "country": "ma"
    },
    {
        "name": "Semarang",
        "country": "id"
    },
    {
        "name": "Quito",
        "country": "ec"
    },
    {
        "name": "Panama City",
        "country": "pa"
    },
    {
        "name": "Hyderabad",
        "country": "pk"
    },
    {
        "name": "Ludhiana",
        "country": "in"
    },
    {
        "name": "Lome",
        "country": "tg"
    },
    {
        "name": "Davao City",
        "country": "ph"
    },
    {
        "name": "Leon de los Aldamas",
        "country": "mx"
    },
    {
        "name": "Turin",
        "country": "it"
    },
    {
        "name": "Bucharest",
        "country": "ro"
    },
    {
        "name": "Adana",
        "country": "tr"
    },
    {
        "name": "Warsaw",
        "country": "pl"
    },
    {
        "name": "Hamburg",
        "country": "de"
    },
    {
        "name": "Benin City",
        "country": "ng"
    },
    {
        "name": "Budapest",
        "country": "hu"
    },
    {
        "name": "Madurai",
        "country": "in"
    },
    {
        "name": "Montevideo",
        "country": "uy"
    },
    {
        "name": "Palembang",
        "country": "id"
    },
    {
        "name": "Matola",
        "country": "mz"
    },
    {
        "name": "Gaziantep",
        "country": "tr"
    },
    {
        "name": "Sharjah",
        "country": "ae"
    },
    {
        "name": "Lyon",
        "country": "fr"
    },
    {
        "name": "Meerut",
        "country": "in"
    },
    {
        "name": "Raipur",
        "country": "in"
    },
    {
        "name": "Varanasi",
        "country": "in"
    },
    {
        "name": "Mosul",
        "country": "iq"
    },
    {
        "name": "Moscow",
        "country": "ru"
    },
    {
        "name": "Glasgow",
        "country": "gb"
    },
    {
        "name": "La Laguna",
        "country": "mx"
    },
    {
        "name": "Stockholm",
        "country": "se"
    },
    {
        "name": "Auckland",
        "country": "nz"
    },
    {
        "name": "Jamshedpur",
        "country": "in"
    },
    {
        "name": "Srinagar",
        "country": "in"
    },
    {
        "name": "Batam",
        "country": "id"
    },
    {
        "name": "Ulaanbaatar",
        "country": "mn"
    },
    {
        "name": "Marseille",
        "country": "fr"
    },
    {
        "name": "Makassar",
        "country": "id"
    },
    {
        "name": "Aurangabad",
        "country": "in"
    },
    {
        "name": "Muscat",
        "country": "Om"
    },
    {
        "name": "Cordoba",
        "country": "ar"
    },
    {
        "name": "Calgary",
        "country": "ca"
    },
    {
        "name": "Daejon",
        "country": "kr"
    },
    {
        "name": "Monrovia",
        "country": "lr"
    },
    {
        "name": "Tiruppur",
        "country": "in"
    },
    {
        "name": "Rosario",
        "country": "ar"
    },
    {
        "name": "Munich",
        "country": "de"
    },
    {
        "name": "Harare",
        "country": "zw"
    },
    {
        "name": "Ciudad Juarez",
        "country": "mx"
    },
    {
        "name": "Gwangju",
        "country": "kr"
    },
    {
        "name": "Medina",
        "country": "sa"
    },
    {
        "name": "Abu Dhabi",
        "country": "ae"
    },
    {
        "name": "Jodhpur",
        "country": "in"
    },
    {
        "name": "Grande Sao Luis",
        "country": "br"
    },
    {
        "name": "Edmonton",
        "country": "ca"
    },
    {
        "name": "Natal",
        "country": "br"
    },
    {
        "name": "Tegucigalpa",
        "country": "hn"
    },
    {
        "name": "Onitsha",
        "country": "ng"
    },
    {
        "name": "N-Djamena",
        "country": "td"
    },
    {
        "name": "Ranchi",
        "country": "in"
    },
    {
        "name": "Kathmandu",
        "country": "np"
    },
    {
        "name": "Jabalpur",
        "country": "in"
    },
    {
        "name": "Mandalay",
        "country": "mm"
    },
    {
        "name": "Asansol",
        "country": "in"
    },
    {
        "name": "Kota",
        "country": "in"
    },
    {
        "name": "Kharkiv",
        "country": "ua"
    },
    {
        "name": "San Jose",
        "country": "cr"
    },
    {
        "name": "Chon Buri",
        "country": "th"
    },
    {
        "name": "Allahabad",
        "country": "in"
    },
    {
        "name": "Gwalior",
        "country": "in"
    },
    {
        "name": "Ottawa",
        "country": "ca"
    },
    {
        "name": "Zurich",
        "country": "ch"
    },
    {
        "name": "Belgrade",
        "country": "rs"
    },
    {
        "name": "Amritsar",
        "country": "in"
    },
    {
        "name": "Joao Pessoa",
        "country": "br"
    },
    {
        "name": "Basra",
        "country": "iq"
    },
    {
        "name": "Nouakchott",
        "country": "mr"
    },
    {
        "name": "Queretaro",
        "country": "mx"
    },
    {
        "name": "Copenhagen",
        "country": "dk"
    },
    {
        "name": "Konya",
        "country": "tr"
    },
    {
        "name": "Bucaramanga",
        "country": "co"
    },
    {
        "name": "Dhanbad",
        "country": "in"
    },
    {
        "name": "Adelaide",
        "country": "au"
    },
    {
        "name": "Mombasa",
        "country": "ke"
    },
    {
        "name": "Maceio",
        "country": "br"
    },
    {
        "name": "Niamey",
        "country": "ne"
    },
    {
        "name": "Suweon",
        "country": "kr"
    },
    {
        "name": "Samut Prakan",
        "country": "th"
    },
    {
        "name": "Joinville",
        "country": "br"
    },
    {
        "name": "Helsinki",
        "country": "fi"
    },
    {
        "name": "Porto",
        "country": "pt"
    },
    {
        "name": "Antalya",
        "country": "tr"
    },
    {
        "name": "Bareilly",
        "country": "in"
    },
    {
        "name": "Sofia",
        "country": "bg"
    },
    {
        "name": "Ad-Dammam",
        "country": "sa"
    },
    {
        "name": "Port Elizabeth",
        "country": "za"
    },
    {
        "name": "Florianopolis",
        "country": "br"
    },
    {
        "name": "Fes",
        "country": "ma"
    },
    {
        "name": "Aligarh",
        "country": "in"
    },
    {
        "name": "Dublin",
        "country": "ie"
    },
    {
        "name": "Tanger",
        "country": "ma"
    },
    {
        "name": "Pekan Baru",
        "country": "id"
    },
    {
        "name": "Freetown",
        "country": "sl"
    },
    {
        "name": "San Luis Potosi",
        "country": "mx"
    },
    {
        "name": "Mysore",
        "country": "in"
    },
    {
        "name": "Moradabad",
        "country": "in"
    },
    {
        "name": "Durg-Bhilainagar",
        "country": "in"
    },
    {
        "name": "Astana",
        "country": "kz"
    },
    {
        "name": "Uyo",
        "country": "ng"
    },
    {
        "name": "Bhubaneswar",
        "country": "in"
    },
    {
        "name": "Mendoza",
        "country": "ar"
    },
    {
        "name": "Bogor",
        "country": "id"
    },
    {
        "name": "Chiang Mai",
        "country": "th"
    },
    {
        "name": "Tiruchirappalli",
        "country": "in"
    },
    {
        "name": "Merida",
        "country": "mx"
    },
    {
        "name": "Lilongwe",
        "country": "mw"
    },
    {
        "name": "Kigali",
        "country": "rw"
    },
    {
        "name": "Tripoli",
        "country": "ly"
    },
    {
        "name": "Chandigarh",
        "country": "in"
    },
    {
        "name": "Islamabad",
        "country": "pk"
    },
    {
        "name": "Amsterdam",
        "country": "nl"
    },
    {
        "name": "Haifa",
        "country": "il"
    },
    {
        "name": "Mexicali",
        "country": "mx"
    },
    {
        "name": "Hubli-Dharwad",
        "country": "in"
    },
    {
        "name": "Guwahati",
        "country": "in"
    },
    {
        "name": "Kaduna",
        "country": "ng"
    },
    {
        "name": "Quetta",
        "country": "pk"
    },
    {
        "name": "Cologne",
        "country": "de"
    },
    {
        "name": "Aguascalientes",
        "country": "mx"
    },
    {
        "name": "Salem",
        "country": "in"
    },
    {
        "name": "Abomey-Calavi",
        "country": "bj"
    },
    {
        "name": "Maputo",
        "country": "mz"
    },
    {
        "name": "Bandar Lampung",
        "country": "id"
    },
    {
        "name": "Aba",
        "country": "ng"
    },
    {
        "name": "Nnewi",
        "country": "ng"
    },
    {
        "name": "Tasikmalaya",
        "country": "id"
    },
    {
        "name": "San Salvador",
        "country": "sv"
    },
    {
        "name": "Yongin",
        "country": "kr"
    },
    {
        "name": "Saharanpur",
        "country": "in"
    },
    {
        "name": "Shimkent",
        "country": "kz"
    },
    {
        "name": "Cuernavaca",
        "country": "mx"
    },
    {
        "name": "Yerevan",
        "country": "am"
    },
    {
        "name": "Goyang",
        "country": "kr"
    },
    {
        "name": "Tbilisi",
        "country": "ge"
    },
    {
        "name": "Chihuahua",
        "country": "mx"
    },
    {
        "name": "Bujumbura",
        "country": "bi"
    },
    {
        "name": "Jalandhar",
        "country": "in"
    },
    {
        "name": "Managua",
        "country": "ni"
    },
    {
        "name": "Cartagena",
        "country": "co"
    },
    {
        "name": "Lille",
        "country": "fr"
    },
    {
        "name": "Bishkek",
        "country": "kg"
    },
    {
        "name": "Diyarbakir",
        "country": "tr"
    },
    {
        "name": "Siliguri",
        "country": "in"
    },
    {
        "name": "Oslo",
        "country": "no"
    },
    {
        "name": "Changwon",
        "country": "kr"
    },
    {
        "name": "Antwerp",
        "country": "be"
    },
    {
        "name": "Johor Bahru",
        "country": "my"
    },
    {
        "name": "Solapur",
        "country": "in"
    },
    {
        "name": "Samarinda",
        "country": "id"
    },
    {
        "name": "Toulouse",
        "country": "fr"
    },
    {
        "name": "Mersin",
        "country": "tr"
    },
    {
        "name": "Teresina",
        "country": "br"
    },
    {
        "name": "Kirkuk",
        "country": "iq"
    },
    {
        "name": "Aracaju",
        "country": "br"
    },
    {
        "name": "Bobo-Dioulasso",
        "country": "bf"
    },
    {
        "name": "Marrakech",
        "country": "ma"
    },
    {
        "name": "Tampico",
        "country": "mx"
    },
    {
        "name": "Rotterdam",
        "country": "nl"
    },
    {
        "name": "Aden",
        "country": "ye"
    },
    {
        "name": "Denpasar",
        "country": "id"
    },
    {
        "name": "Odesa",
        "country": "ua"
    },
    {
        "name": "Saltillo",
        "country": "mx"
    },
    {
        "name": "San Miguel de Tucuman",
        "country": "ar"
    },
    {
        "name": "Asmara",
        "country": "er"
    },
    {
        "name": "Padang",
        "country": "id"
    },
    {
        "name": "Cebu City",
        "country": "ph"
    },
    {
        "name": "Acapulco de Juarez",
        "country": "mx"
    },
    {
        "name": "Valparaiso",
        "country": "cl"
    },
    {
        "name": "Sekondi Takoradi",
        "country": "gh"
    },
    {
        "name": "Ikorodu",
        "country": "ng"
    },
    {
        "name": "Kayseri",
        "country": "tr"
    },
    {
        "name": "Warangal",
        "country": "in"
    },
    {
        "name": "Bordeaux",
        "country": "fr"
    },
    {
        "name": "Songkhla",
        "country": "th"
    },
    {
        "name": "Nonthaburi",
        "country": "th"
    },
    {
        "name": "Ilorin",
        "country": "ng"
    },
    {
        "name": "Cancun",
        "country": "mx"
    },
    {
        "name": "Nyala",
        "country": "sd"
    },
    {
        "name": "Blantyre-Limbe",
        "country": "mw"
    },
    {
        "name": "Morelia",
        "country": "mx"
    },
    {
        "name": "Dnipro",
        "country": "ua"
    },
    {
        "name": "Khulna",
        "country": "bd"
    },
    {
        "name": "Jerusalem",
        "country": "il"
    },
    {
        "name": "Cucuta",
        "country": "co"
    },
    {
        "name": "Nice",
        "country": "fr"
    },
    {
        "name": "Dehradun",
        "country": "in"
    },
    {
        "name": "Seongnam",
        "country": "kr"
    },
    {
        "name": "Agadir",
        "country": "ma"
    },
    {
        "name": "Dushanbe",
        "country": "tj"
    },
    {
        "name": "Southampton",
        "country": "gb"
    },
    {
        "name": "Arequipa",
        "country": "pe"
    },
    {
        "name": "Zamboanga City",
        "country": "ph"
    },
    {
        "name": "Veracruz",
        "country": "mx"
    },
    {
        "name": "San Pedro Sula",
        "country": "hn"
    },
    {
        "name": "Pathum Thani",
        "country": "th"
    },
    {
        "name": "Rajshahi",
        "country": "bd"
    },
    {
        "name": "Misratah",
        "country": "ly"
    },
    {
        "name": "Jos",
        "country": "ng"
    },
    {
        "name": "Reynosa",
        "country": "mx"
    },
    {
        "name": "Villahermosa",
        "country": "mx"
    },
    {
        "name": "Ulsan",
        "country": "kr"
    },
    {
        "name": "Bangui",
        "country": "cf"
    },
    {
        "name": "Oran",
        "country": "dz"
    },
    {
        "name": "West Rand",
        "country": "za"
    },
    {
        "name": "Owerri",
        "country": "ng"
    },
    {
        "name": "Campo Grande",
        "country": "br"
    },
    {
        "name": "Liverpool",
        "country": "gb"
    },
    {
        "name": "Antipolo",
        "country": "ph"
    },
    {
        "name": "Najaf",
        "country": "iq"
    },
    {
        "name": "Bergamo",
        "country": "it"
    },
    {
        "name": "Donetsk",
        "country": "ua"
    },
    {
        "name": "Warri",
        "country": "ng"
    },
    {
        "name": "Londrina",
        "country": "br"
    },
    {
        "name": "Malang",
        "country": "id"
    },
    {
        "name": "La Plata",
        "country": "ar"
    },
    {
        "name": "Concepcion",
        "country": "cl"
    },
    {
        "name": "Sylhet",
        "country": "bd"
    },
    {
        "name": "Nampula",
        "country": "mz"
    },
    {
        "name": "Hermosillo",
        "country": "mx"
    },
    {
        "name": "Trujillo",
        "country": "pe"
    },
    {
        "name": "Taiz",
        "country": "ye"
    },
    {
        "name": "Guntur",
        "country": "in"
    },
    {
        "name": "Bhiwandi",
        "country": "in"
    },
    {
        "name": "Lubango",
        "country": "ao"
    },
    {
        "name": "Bahawalpur",
        "country": "pk"
    },
    {
        "name": "Soshanguve",
        "country": "za"
    },
    {
        "name": "Tuxtla Gutierrez",
        "country": "mx"
    },
    {
        "name": "Ashgabat",
        "country": "tm"
    },
    {
        "name": "Erbil",
        "country": "iq"
    },
    {
        "name": "Puducherry",
        "country": "in"
    },
    {
        "name": "Culiacan",
        "country": "mx"
    },
    {
        "name": "Palermo",
        "country": "it"
    },
    {
        "name": "Hufuf-Mubarraz",
        "country": "sa"
    },
    {
        "name": "Firozabad",
        "country": "in"
    },
    {
        "name": "Libreville",
        "country": "ga"
    },
    {
        "name": "Banghazi",
        "country": "ly"
    },
    {
        "name": "Valencia",
        "country": "es"
    },
    {
        "name": "Quebec City",
        "country": "ca"
    },
    {
        "name": "Bucheon",
        "country": "kr"
    },
    {
        "name": "Ipoh",
        "country": "my"
    },
    {
        "name": "Winnipeg",
        "country": "ca"
    },
    {
        "name": "Bogra",
        "country": "bd"
    },
    {
        "name": "Cabinda",
        "country": "ao"
    },
    {
        "name": "Umuahia",
        "country": "ng"
    },
    {
        "name": "Newcastle upon Tyne",
        "country": "gb"
    },
    {
        "name": "Thessaloniki",
        "country": "gr"
    },
    {
        "name": "Bologna",
        "country": "it"
    },
    {
        "name": "Maiduguri",
        "country": "ng"
    },
    {
        "name": "Niigata",
        "country": "jp"
    },
    {
        "name": "Eskisehir",
        "country": "tr"
    },
    {
        "name": "Cherthala",
        "country": "in"
    },
    {
        "name": "Sorocaba",
        "country": "br"
    },
    {
        "name": "Enugu",
        "country": "ng"
    },
    {
        "name": "Nottingham",
        "country": "gb"
    },
    {
        "name": "Xalapa",
        "country": "mx"
    },
    {
        "name": "Bikaner",
        "country": "in"
    },
    {
        "name": "Frankfurt",
        "country": "de"
    },
    {
        "name": "Nakhon Ratchasima",
        "country": "th"
    },
    {
        "name": "Vereeniging",
        "country": "za"
    },
    {
        "name": "Nellore",
        "country": "in"
    },
    {
        "name": "Hamilton",
        "country": "ca"
    },
    {
        "name": "Gebze",
        "country": "tr"
    },
    {
        "name": "Cagayan de Oro City",
        "country": "ph"
    },
    {
        "name": "Krakow",
        "country": "pl"
    },
    {
        "name": "Gorakhpur",
        "country": "in"
    },
    {
        "name": "Dasmarinas",
        "country": "ph"
    },
    {
        "name": "Amravati",
        "country": "in"
    },
    {
        "name": "Ansan",
        "country": "kr"
    },
    {
        "name": "Ar-Rayyan",
        "country": "qa"
    },
    {
        "name": "Sulaimaniya",
        "country": "iq"
    },
    {
        "name": "Cuttack",
        "country": "in"
    },
    {
        "name": "Bur Sa'id",
        "country": "eg"
    },
    {
        "name": "Benguela",
        "country": "ao"
    },
    {
        "name": "Banjarmasin",
        "country": "id"
    },
    {
        "name": "Muzaffarnagar",
        "country": "in"
    },
    {
        "name": "Lokoja",
        "country": "ng"
    },
    {
        "name": "Kumamoto",
        "country": "jp"
    },
    {
        "name": "Kottayam",
        "country": "in"
    },
    {
        "name": "Belgaum",
        "country": "in"
    },
    {
        "name": "Zarqa",
        "country": "jo"
    },
    {
        "name": "Zaria",
        "country": "ng"
    },
    {
        "name": "Sheffield",
        "country": "gb"
    },
    {
        "name": "Zaragoza",
        "country": "es"
    },
    {
        "name": "Zaporizhzhya",
        "country": "ua"
    },
    {
        "name": "Malegaon",
        "country": "in"
    },
    {
        "name": "Sao Jose dos Campos",
        "country": "br"
    },
    {
        "name": "Oshogbo",
        "country": "ng"
    },
    {
        "name": "Kayamkulam",
        "country": "in"
    },
    {
        "name": "Mangalore",
        "country": "in"
    },
    {
        "name": "Okayama",
        "country": "jp"
    },
    {
        "name": "Ribeirao Preto",
        "country": "br"
    },
    {
        "name": "Lviv",
        "country": "ua"
    },
    {
        "name": "Cheongju",
        "country": "kr"
    },
    {
        "name": "Sialkot",
        "country": "pk"
    },
    {
        "name": "Buffalo City",
        "country": "za"
    },
    {
        "name": "Oaxaca de Juarez",
        "country": "mx"
    },
    {
        "name": "Sargodha",
        "country": "pk"
    },
    {
        "name": "Bhavnagar",
        "country": "in"
    },
    {
        "name": "Gold Coast",
        "country": "au"
    },
    {
        "name": "Kitwe",
        "country": "zm"
    },
    {
        "name": "Florence",
        "country": "it"
    },
    {
        "name": "Jammu",
        "country": "in"
    },
    {
        "name": "Nanded Waghala",
        "country": "in"
    },
    {
        "name": "Tirupati",
        "country": "in"
    },
    {
        "name": "Bacoor",
        "country": "ph"
    },
    {
        "name": "Malanje",
        "country": "ao"
    },
    {
        "name": "The Hague",
        "country": "nl"
    },
    {
        "name": "Seville",
        "country": "es"
    },
    {
        "name": "Cotonou",
        "country": "bj"
    },
    {
        "name": "Salta",
        "country": "ar"
    },
    {
        "name": "Bali",
        "country": "id"
    },
    {
        "name": "Uberlandia",
        "country": "br"
    },
    {
        "name": "Taif",
        "country": "sa"
    },
    {
        "name": "Bristol",
        "country": "gb"
    },
    {
        "name": "Akure",
        "country": "ng"
    },
    {
        "name": "Ibb",
        "country": "ye"
    },
    {
        "name": "Celaya",
        "country": "mx"
    },
    {
        "name": "Al-Hudaydah",
        "country": "ye"
    },
    {
        "name": "Cheonan",
        "country": "kr"
    },
    {
        "name": "Jundiai",
        "country": "br"
    },
    {
        "name": "Gulbarga",
        "country": "in"
    },
    {
        "name": "Huambo",
        "country": "ao"
    },
    {
        "name": "Padova",
        "country": "it"
    },
    {
        "name": "Durgapur",
        "country": "in"
    },
    {
        "name": "Kurnool",
        "country": "in"
    },
    {
        "name": "Nantes",
        "country": "fr"
    },
    {
        "name": "Buraydah",
        "country": "sa"
    },
    {
        "name": "Zagreb",
        "country": "hr"
    },
    {
        "name": "Pontianak",
        "country": "id"
    },
    {
        "name": "Amara",
        "country": "iq"
    },
    {
        "name": "Liege",
        "country": "be"
    },
    {
        "name": "Genoa",
        "country": "it"
    },
    {
        "name": "Santiago",
        "country": "do"
    },
    {
        "name": "Mar Del Plata",
        "country": "ar"
    },
    {
        "name": "Tamale",
        "country": "gh"
    },
    {
        "name": "Lodz",
        "country": "pl"
    },
    {
        "name": "Tabuk",
        "country": "sa"
    },
    {
        "name": "General Santos City",
        "country": "ph"
    },
    {
        "name": "Manama",
        "country": "bh"
    },
    {
        "name": "Jeonju",
        "country": "kr"
    },
    {
        "name": "Sokoto",
        "country": "ng"
    },
    {
        "name": "Samsun",
        "country": "tr"
    },
    {
        "name": "Denizli",
        "country": "tr"
    },
    {
        "name": "Busto Arsizio",
        "country": "it"
    },
    {
        "name": "Jhansi",
        "country": "in"
    },
    {
        "name": "Jamnagar",
        "country": "in"
    },
    {
        "name": "As-Suways",
        "country": "eg"
    },
    {
        "name": "Erode",
        "country": "in"
    },
    {
        "name": "Doha",
        "country": "qa"
    },
    {
        "name": "Pachuca de Soto",
        "country": "mx"
    },
    {
        "name": "Chongjin",
        "country": "kp"
    },
    {
        "name": "Chiclayo",
        "country": "pe"
    },
    {
        "name": "Be'er Sheva",
        "country": "il"
    },
    {
        "name": "Durango",
        "country": "mx"
    },
    {
        "name": "Bokaro Steel City",
        "country": "in"
    },
    {
        "name": "Wroclaw",
        "country": "pl"
    },
    {
        "name": "Nay Pyi Taw",
        "country": "mm"
    },
    {
        "name": "Bulawayo",
        "country": "zw"
    },
    {
        "name": "Venezia",
        "country": "it"
    },
    {
        "name": "Jambi",
        "country": "id"
    },
    {
        "name": "Safaqis",
        "country": "tn"
    },
    {
        "name": "Duesseldorf",
        "country": "de"
    },
    {
        "name": "Belfast",
        "country": "gb"
    },
    {
        "name": "Nasiriyah",
        "country": "iq"
    },
    {
        "name": "Stuttgart",
        "country": "de"
    },
    {
        "name": "Verona",
        "country": "it"
    },
    {
        "name": "Raurkela",
        "country": "in"
    },
    {
        "name": "Jubayl",
        "country": "sa"
    },
    {
        "name": "Riga",
        "country": "lv"
    },
    {
        "name": "Kolhapur",
        "country": "in"
    },
    {
        "name": "Bacolod",
        "country": "ph"
    },
    {
        "name": "Seregno",
        "country": "it"
    },
    {
        "name": "Al-Ain",
        "country": "ae"
    },
    {
        "name": "Bari",
        "country": "it"
    },
    {
        "name": "Ajmer",
        "country": "in"
    },
    {
        "name": "Kuching",
        "country": "my"
    },
    {
        "name": "Bissau",
        "country": "gw"
    },
    {
        "name": "Bauchi",
        "country": "ng"
    },
    {
        "name": "Geneva",
        "country": "ch"
    },
    {
        "name": "Colombo",
        "country": "lk"
    },
    {
        "name": "Ujjain",
        "country": "in"
    },
    {
        "name": "Gothenburg",
        "country": "se"
    },
    {
        "name": "Patiala",
        "country": "in"
    },
    {
        "name": "Bilaspur",
        "country": "in"
    },
    {
        "name": "San Jose del Monte",
        "country": "ph"
    },
    {
        "name": "Hillah",
        "country": "iq"
    },
    {
        "name": "Kryvyi Rih",
        "country": "ua"
    },
    {
        "name": "Brighton",
        "country": "gb"
    },
    {
        "name": "Feira De Santana",
        "country": "br"
    },
    {
        "name": "Comilla",
        "country": "bd"
    },
    {
        "name": "Tlaxcala",
        "country": "mx"
    },
    {
        "name": "Calabar",
        "country": "ng"
    },
    {
        "name": "Mathura",
        "country": "in"
    },
    {
        "name": "Abakaliki",
        "country": "ng"
    },
    {
        "name": "Sanliurfa",
        "country": "tr"
    },
    {
        "name": "Leipzig",
        "country": "de"
    },
    {
        "name": "Agartala",
        "country": "in"
    },
    {
        "name": "Imphal",
        "country": "in"
    },
    {
        "name": "Kingston",
        "country": "jm"
    },
    {
        "name": "Sangli",
        "country": "in"
    },
    {
        "name": "Cuiaba",
        "country": "br"
    },
    {
        "name": "Samarkand",
        "country": "uz"
    },
    {
        "name": "Malaga",
        "country": "es"
    },
    {
        "name": "Ibague",
        "country": "co"
    },
    {
        "name": "Dortmund",
        "country": "de"
    },
    {
        "name": "Kagoshima",
        "country": "jp"
    },
    {
        "name": "Catania",
        "country": "it"
    },
    {
        "name": "Samut Sakhon",
        "country": "th"
    },
    {
        "name": "Beira",
        "country": "mz"
    },
    {
        "name": "Djibouti",
        "country": "cj"
    },
    {
        "name": "Essen",
        "country": "de"
    },
    {
        "name": "Toulon",
        "country": "fr"
    },
    {
        "name": "Dresden",
        "country": "de"
    },
    {
        "name": "Juiz De Fora",
        "country": "br"
    },
    {
        "name": "Pereira",
        "country": "co"
    },
    {
        "name": "Udaipur",
        "country": "in"
    },
    {
        "name": "Vellore",
        "country": "in"
    },
    {
        "name": "Thoothukkudi",
        "country": "in"
    },
    {
        "name": "Bloemfontein",
        "country": "za"
    },
    {
        "name": "Udon Thani",
        "country": "th"
    },
    {
        "name": "Ogbomosho",
        "country": "ng"
    },
    {
        "name": "Jalgaon",
        "country": "in"
    },
    {
        "name": "Gaya",
        "country": "in"
    },
    {
        "name": "Tirunelveli",
        "country": "in"
    },
    {
        "name": "Matamoros",
        "country": "mx"
    },
    {
        "name": "Kitchener",
        "country": "ca"
    },
    {
        "name": "Poza Rica de Hidalgo",
        "country": "mx"
    },
    {
        "name": "Oujda",
        "country": "ma"
    },
    {
        "name": "Santa Fe",
        "country": "ar"
    },
    {
        "name": "Cuito",
        "country": "ao"
    },
    {
        "name": "Bremen",
        "country": "de"
    },
    {
        "name": "Karbala",
        "country": "iq"
    },
    {
        "name": "Al-Mansurah",
        "country": "eg"
    },
    {
        "name": "Anyang",
        "country": "kr"
    },
    {
        "name": "Kota Kinabalu",
        "country": "my"
    },
    {
        "name": "Basel",
        "country": "ch"
    },
    {
        "name": "Gomel",
        "country": "by"
    },
    {
        "name": "Meknes",
        "country": "ma"
    },
    {
        "name": "Panipat",
        "country": "in"
    },
    {
        "name": "Kahramanmaras",
        "country": "tr"
    },
    {
        "name": "Irbid",
        "country": "jo"
    },
    {
        "name": "Tepic",
        "country": "mx"
    },
    {
        "name": "Chiang Rai",
        "country": "th"
    },
    {
        "name": "Leicester",
        "country": "gb"
    },
    {
        "name": "Khamis Mushayt",
        "country": "sa"
    },
    {
        "name": "Ndola",
        "country": "zm"
    },
    {
        "name": "Imus",
        "country": "ph"
    },
    {
        "name": "Villavicencio",
        "country": "co"
    },
    {
        "name": "Sakarya",
        "country": "tr"
    },
    {
        "name": "Bamenda",
        "country": "cm"
    },
    {
        "name": "Utrecht",
        "country": "nl"
    },
    {
        "name": "Rajahmundry",
        "country": "in"
    },
    {
        "name": "Larkana",
        "country": "pk"
    },
    {
        "name": "Basilan City",
        "country": "ph"
    },
    {
        "name": "Valledupar",
        "country": "co"
    },
    {
        "name": "Uige",
        "country": "ao"
    },
    {
        "name": "Hamhung",
        "country": "kp"
    },
    {
        "name": "Sukkur",
        "country": "pk"
    },
    {
        "name": "Rayong",
        "country": "th"
    },
    {
        "name": "Port of Spain",
        "country": "tt"
    },
    {
        "name": "Abeokuta",
        "country": "ng"
    },
    {
        "name": "Mekele",
        "country": "et"
    },
    {
        "name": "Edinburgh",
        "country": "gb"
    },
    {
        "name": "San Juan",
        "country": "ar"
    },
    {
        "name": "Vilnius",
        "country": "lt"
    },
    {
        "name": "Calamba",
        "country": "ph"
    },
    {
        "name": "Hannover",
        "country": "de"
    },
    {
        "name": "Namangan",
        "country": "uz"
    },
    {
        "name": "Al-Mukalla",
        "country": "ye"
    },
    {
        "name": "Santa Marta",
        "country": "co"
    },
    {
        "name": "Ambon",
        "country": "id"
    },
    {
        "name": "Bellary",
        "country": "in"
    },
    {
        "name": "Diwaniyah",
        "country": "iq"
    },
    {
        "name": "Pietermaritzburg",
        "country": "za"
    },
    {
        "name": "Rustenburg",
        "country": "za"
    },
    {
        "name": "Surakarta",
        "country": "id"
    },
    {
        "name": "Sheikhupura",
        "country": "pk"
    },
    {
        "name": "Grenoble",
        "country": "fr"
    },
    {
        "name": "Puerto Vallarta",
        "country": "mx"
    },
    {
        "name": "Angeles City",
        "country": "ph"
    },
    {
        "name": "Gombe",
        "country": "ng"
    },
    {
        "name": "Hosur",
        "country": "in"
    },
    {
        "name": "Poznan",
        "country": "pl"
    },
    {
        "name": "Van",
        "country": "tr"
    },
    {
        "name": "El Djelfa",
        "country": "dz"
    },
    {
        "name": "Ar-Rusayfah",
        "country": "jo"
    },
    {
        "name": "Vale do Aco",
        "country": "br"
    },
    {
        "name": "Cuautla Morelos",
        "country": "mx"
    },
    {
        "name": "Kalasin",
        "country": "th"
    },
    {
        "name": "Al-Mahallah al-Kubra",
        "country": "eg"
    },
    {
        "name": "Loum",
        "country": "cm"
    },
    {
        "name": "Kakinada",
        "country": "in"
    },
    {
        "name": "Rupganj",
        "country": "bd"
    },
    {
        "name": "Utsunomiya",
        "country": "jp"
    },
    {
        "name": "Porto Velho",
        "country": "br"
    },
    {
        "name": "Davanagere",
        "country": "in"
    },
    {
        "name": "Duisburg",
        "country": "de"
    },
    {
        "name": "Nakhon Pathom",
        "country": "th"
    },
    {
        "name": "Muzaffarpur",
        "country": "in"
    },
    {
        "name": "Mataram",
        "country": "id"
    },
    {
        "name": "Mbouda",
        "country": "cm"
    },
    {
        "name": "Zinder",
        "country": "ne"
    },
    {
        "name": "Kuantan",
        "country": "my"
    },
    {
        "name": "Nurenberg",
        "country": "de"
    },
    {
        "name": "London CA",
        "country": "ca"
    },
    {
        "name": "Bournemouth",
        "country": "gb"
    },
    {
        "name": "Khon-Kaen",
        "country": "th"
    },
    {
        "name": "Matsuyama",
        "country": "jp"
    },
    {
        "name": "Tanta",
        "country": "eg"
    },
    {
        "name": "Karaganda",
        "country": "kz"
    },
    {
        "name": "Murcia",
        "country": "es"
    },
    {
        "name": "Malatya",
        "country": "tr"
    },
    {
        "name": "Tirana",
        "country": "al"
    },
    {
        "name": "Surat Thani",
        "country": "th"
    },
    {
        "name": "Ubon Ratchathani",
        "country": "th"
    },
    {
        "name": "Douai-Lens",
        "country": "fr"
    }
];