
// TODO
// ACTIVE LAST 30, 7, 1
// AGE
// custom fields..
// member type
// profile_updated_at
// joined_date
// created_at
// stats.sessions

export function advt2_getDefinitions(community, obj, properties, definitions, context) {
    let base = {
        general: {
            title: "Members"
        },
        allowed_layouts: ['list', 'gallery'],
        fields: {}
    };

    properties.forEach(prop => {
        let to_add;
        if (prop.id.startsWith('social.') || prop.id.startsWith('custom_fields.')) {
            to_add = {
                Header: prop.label,
                accessor: prop.id,
                minWidth: 50,
                maxWidth: 400,
                filterable: true,
                sortable: false,
                width: 150,
                field_type: prop.type,
                options: prop.options
            };
        } else {
            const prop_data = definitions.properties[prop.id];

            if (!prop_data) {
                console.warn("No propdata for ", prop.id)
                return;
            }

            to_add = {
                Header: prop_data.label,
                accessor: prop_data.field,
                minWidth: 50,
                maxWidth: 400,
                filterable: prop_data.filterable,
                sortable: prop_data.sortable,
                width: prop_data.col_width || 150,
                field_type: prop_data.type,
                options: {
                    options: prop_data.options
                }
            };
        }

        base.fields[prop.id] = {...to_add};
    })

    return base;
}