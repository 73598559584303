import React, {useState, useCallback, useEffect, useRef} from 'react';
import {ATContentWrapper} from "./components/content-wrapper";
import {ATHeader} from "./components/header";
import {ATOptionsBar} from "./general/options-bar";
import {ATButtonBox, ATSelectView} from "./views/select-view";
import styled from "styled-components";
import {ATFilter} from "./filters/filter";
import {AT_data_type_definitions} from "./definitions";
import {
    at_buildAddOptions, at_buildFilterOptions, at_buildGroupOptions,
    at_buildSortOptions,
    at_getFiltersInUse,
    at_getSortsInUse, at_getSortsWithDirection,
    at_propIsInArray
} from "./sort/utilities";
import {at_array_move, at_buildFullDefinition, at_combineViewProperties, at_updateSort} from "./utilities";
import {authFetch} from "../../../config/network";
import {buildNewPathWithURLParam} from "../../../app/community/directory";
import {Button, EntityHeader, InlineLoader, PageHeader} from "../core";
import {ATCustomizeView} from "./customize-view";
import {saveItemToLocalStorage} from "../../utilities/localstorage";
import {useIsMobile} from "../../../app/global/global-router";
import {buildElasticsearchQuery, directoryMembersQuery} from "./build-elasticsearch-query";
import {FirebaseDB, Firestore} from "../../../config/setup-firestore";
import {NewDirectoryRenderer} from "./renderer/new-directory-renderer";
import {preprocessData} from "./preprocess-data";
import {ATMassActions} from "./at-mass-actions";
import {Popover2} from "@blueprintjs/popover2";
import {PopupMenu} from "../old/popup-menu";
import {ATSearch} from "./search/search";
import {UserAddIcon} from "@heroicons/react/outline";
import {api_setDefaultView} from "../../../api/community/directory/set-default-view";
import {directory_getInitialView} from "../../../app/community/directory/utilities";
import {useLocation} from "react-router-dom";
import {getManyItems, getManyItemsAsync} from "../../utilities/get-many-items";

const table = {
    type: 'member',
    general: {
        title: 'Members',
        emoji: '',
        icon: null
    },
    sort_options: {
        last_name: {
            field: 'about.last_name',
            name: 'Last Name',
            options: ['asc', 'desc']
        }
    },
    filter_options: {},
    selected_actions: [
        // export
    ],
    actions: [
        // invite, export all, import
    ],
    selected_more_options: [],
    more_options: [],
    view_options: {
        list: {
            label: 'List'
        },
        /*
        map: {
            label: 'Map'
        },

         */
        table: {
            label: 'Table'
        },
        gallery: {
            label: 'Gallery'
        }
    },
    group_options: {
        team: {
            label: 'Team'
        }
    },
    properties: {
        name: {
            label: 'Name',
            type: 'text',
            field: 'about.name'
        }
    },
    default_properties: [
        'name',
        'birthday'
    ],
    features: {
        filter: true,
        sort: true,
        create_view: true,
        can_edit: false,
        can_select: false,
        search: false
    }
};

const ActionButton = styled.div`
  padding-left: ${props => props.left ? '' : '4px'};
  padding-right: ${props => props.left ? '8px' : ''};
`;

const FullLoader = styled.div`
  padding: 48px;
`;


const ATContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  min-height: 1px;
  flex-flow: column;
`;

const SelectedText = styled.div`
  height: 28px;
  display: flex;
  align-items: center;
  font-size: 13px;
  margin-right: 12px;
`;

function processMoreOptions(opts, view) {
    return opts.map(opt => {
        return {
            ...opt,
            onClick: () => {
                opt.onClick(view)
            }
        }
    })
}

function getDQInfo(data) {
    const count = data.length;
    let counts = {
        total: count,
        1: 0,
        2: 0,
        3: 0,
        4: 0,
        5: 0
    }
    let score = 0;
    for (let i = 0; i < count; i++) {
        const it = data[i].data;

        if (it) {
            score += it.dt_dataqualityscore;
            counts[it.dt_dataqualityscore]++;
        }

    }
    return {
        score: Math.round((score / count) * 20),
        counts
    };
}

function getPageTitle(header, view) {
    if (!header) {
        return {
            label: null,
            emoji: null
        };
    }
    if (view.type === 'system') {
        return {
            label: header.default_title,
            emoji: null
        };
    }
    return {
        label: view.name,
        emoji: view.emoji
    }
}

async function getMemberData(community_uid, arr = []) {
    return await FirebaseDB
        .collection('community_members')
        .doc(community_uid)
        .collection('members')
        .where(Firestore.FieldPath.documentId(), 'in', arr)
        .get()
        .then(snap => {
            return snap.docs.map(d => {
                return {
                    ...d.data(),
                    id: d.id
                }
            })
        })
}

function chunk(array, size) {
    const chunked_arr = [];
    for (let i = 0; i < array.length; i++) {
        const last = chunked_arr[chunked_arr.length - 1];
        if (!last || last.length === size) {
            chunked_arr.push([array[i]]);
        } else {
            last.push(array[i]);
        }
    }
    return chunked_arr;
}

export async function getAllMemberData(community_uid, all_ids = []) {
    const chunks = chunk(all_ids.filter(a => !!a), 10);
    let promises = [];
    chunks.forEach(ch => {
        promises.push(getMemberData(community_uid, ch));
    })
    return await Promise.all(promises).then(resp => {
        let a = [];
        resp.forEach(re => {
            a = a.concat(re);
        })
        return a;
    })
}

function updateDataStore(obj) {
    return {...obj};
}

function getInits(config, context, initial_view, all_views) {
    const definitions = at_buildFullDefinition(AT_data_type_definitions[config.type], context.custom_fields, context.member_types, context.data);
    const fv = initial_view && all_views.data[initial_view] ? all_views.data[initial_view] : all_views.data[all_views.starting];
    const nv = at_combineViewProperties(fv, definitions.properties, context.custom_fields, definitions, context.data);

    return {
        defs: definitions,
        view: nv
    }
}

let query_count = 0;

function transformDocs(a) {
    return a;
}

export function AdvancedTable({
                                  scroll_id = "main-content",
                                  title,
    default_view,
                                  initial_view_loaded,
                                  row_actions = [],
                                  group_id = "",
                                  modal, config = table, handleCloseModal = () => {
    }, onSetIds = () => {
    }, ls_key = '', initial_view, header = null, addToast, all_views, context, history
                              }) {
    const inits = getInits(config, context, initial_view, all_views);

    const [definitions, setDefinitions] = useState(inits.defs);
    const [selected, setSelected] = useState({});
    const location = useLocation();

    const [search_query, setSearchQuery] = useState("");
    const [debouncedTerm, setDebouncedTerm] = useState(search_query);
    const [all_data, setAllData] = useState(null);
    const [ids, setIds] = useState(null);
    const [view, setView] = useState(inits.view);
    const [view_change_made, setViewChangeMade] = useState(false);
    const [is_loading, setIsLoading] = useState(false);
    const [changes, updateChanges] = useState([]);
    const [view_changes_made, setViewCM] = useState(false);
    const [views, setViews] = useState(all_views.views);
    const [view_data, setViewData] = useState(all_views.data);
    const current_view_id = useRef(view.id);
    const page_ref = useRef(0);
    const total_ref = useRef(0);
    const has_more_ref = useRef(false);

    const [partials, setPartials] = useState({});

    const data_store = useRef({});

    useEffect(function () {
        setViewData(all_views.data);
        setViews(all_views.views);
    }, [all_views,default_view])

    async function getPartialsData(fids) {
        let p = [];
        const chunks = chunk(fids, 10);
        chunks.forEach(a => {
           p.push(getManyItemsAsync('community_members.members', a, context.community_uid));
        })
        return await Promise.all(p).then(arrs => {
            return arrs;
        });
    }

    function checkForPartials(docs) {
        let fetch_ids = [];
        let to_set = [];
        docs.forEach(doc => {
            if (doc.partial && !partials[doc.id] && doc.id) {
                fetch_ids.push(doc.id);
            } else if (!doc.partial && !partials[doc.id] && doc.id) {
                to_set.push(doc);
            }
        });

        if (fetch_ids.length > 0) {
            getPartialsData(fetch_ids)
                .then(snaps => {
                    updatePartials(snaps)
                })
        } else if (to_set.length > 0) {
            setPartialDocs(to_set)
        }
    }

    function setPartialDocs(docs) {
        if (docs.length) {
            return;
        }
        let p = {...partials};
        if (docs.length) {
            // got new data, how to pass back
            const transformed = transformDocs(docs);
            transformed.forEach(it => {
                p[it.id] = it;
            })
        }
        setPartials(p);
    }

    function updatePartials(snaps) {
        if (!snaps || !snaps[0] || !snaps[0].docs.length) {
            return;
        }
        let p = {...partials};

        snaps.forEach(snap => {
            if (snap.docs.length) {
                // got new data, how to pass back
                const transformed = transformDocs(snap.docs);
                transformed.forEach(it => {
                    p[it.id] = it;
                })
            }
        })

        setPartials(p);
    }

    /*
    useEffect(function () {
        console.log('view_data,default_view,view')
        console.log('default_view',default_view)
        console.log('view_data',view_data)
        if(default_view&&default_view.id===current_view_id.current) {
            console.log('serview')
            setView({...view_data[default_view.id]})
            setViewChangeMade(true)
        }
    }, [view_data,default_view])
*/
    const is_mobile = useIsMobile();

    useEffect(function () {
        const nv2 = directory_getInitialView(ls_key);

        if(nv2&&nv2!==current_view_id.current) {
            selectView(nv2);
        }
    }, [location.search])


    useEffect(function () {
        current_view_id.current = view.id;
        if (view && view.filters && !ids && page_ref.current === 0 && !is_loading) {
            setIds(null);
            startQuerying();
        }
    }, [view, is_loading, ids]);


    useEffect(function () {
        if (view_change_made && !is_loading && page_ref.current > 0) {
            setViewChangeMade(false);
            startQuerying();
        }
    }, [view_change_made, is_loading])

    function startQuerying() {
        page_ref.current = 0;
        setIsLoading(true);

        getData(view, true);
    }

    useEffect(function () {
        if (history.location.search.indexOf('reload=true') !== -1 && view) {
            const new_path = buildNewPathWithURLParam(history, '').replace('reload=true&', '').replace('reload=true', '');
            history.push(new_path);
        }
    }, [history.location.search, view]);

    const selected_ids = Object.keys(selected);
    const selected_len = selected_ids.length;

    function hasUnsavedChanges() {
        if (view) {
            if (view.type === 'custom' && !view.has_unsaved_changes) {
                setView({...view, has_unsaved_changes: true});
                let nvd = {...view_data};
                nvd[view.id].has_unsaved_changes = true;
                setViewData({...nvd})
            }
        }
    }

    function updateDataEntries(arr, preview) {
        let current = ids && page_ref.current > 1 ? [...ids] : [];

        current = current.concat(arr);

        setIds(current);
    }

    function updateAllData(res_arr, preview = true) {
        let nad = {...data_store.current};
        res_arr.forEach(entry => {
            nad[entry.id] = {
                ...entry,
                _preview: preview
            };
        })
        data_store.current = updateDataStore(nad);
        setAllData(nad);
    }

    function nextPage() {
        page_ref.current++;
        getData(view);
    }

    function getData(v, override) {
        if (!v) {
            return;
        }
        if (is_loading && !override) {
            return;
        }

        if (!is_loading) {
            setIsLoading(true);
        }
        const es_query = buildElasticsearchQuery(v, search_query, definitions, page_ref.current, {group_id}, context.community.data);

        query_count++;
        directoryMembersQuery(es_query, context.community_uid)
            .then((response) => {
                if (!response) {
                    console.error('something went wrong..')
                    return;
                }
                const {members, page} = response;

                const ids2 = members.map(mem => {
                    return mem.id
                }).filter(a => !!a);
                has_more_ref.current = page.current < page.total_pages;
                total_ref.current = page.total_results;
                page_ref.current = page.current;
                updateAllData(members, true);
                updateDataEntries(ids2, true);

                const members_to_get = ids2.filter(id => {
                    return !data_store.current[id] || !data_store.current[id].account_email;
                })
                setIsLoading(false);
                if (members_to_get.length > 0) {
                    getAllMemberData(context.community_uid, members_to_get)
                        .then(fd => {
                            updateAllData(fd, false);
                        })
                } else {
                    updateAllData([], false);
                }
            })
    }

    function handleToggleDeMembers() {
        let new_view = {...view};
        new_view.show_deactivated_members = !new_view.show_deactivated_members;
        setView({...new_view});
        setViewChangeMade(true);
    }

    function handleChangeLayout(nl) {
        let new_view = {...view};
        new_view.layout = nl;
        setView({...new_view});
    }

    function setDefaultView() {
        addToast({text: 'Updating default view', intent: 'info'});
        api_setDefaultView({
            uid: context.community_uid,
            member_id: context.member_id
        },view)
            .then(()=>{
                addToast({text: 'Community default view updated', intent: 'success'});
            });
    }

    function handleToggleProperty(id, new_state) {
        let new_view = view;
        const index = at_propIsInArray(new_view.properties, 'id', id);
        if (index) {
            new_view.properties[index].active = new_state;
        } else {
            new_view.properties.push({
                id,
                active: new_state
            });
        }
        setViewCM(true);
        setView({...new_view});
        hasUnsavedChanges();
    }

    function handleAddSort(new_sort) {
        let new_view = view;
        new_view.sort.push(new_sort);
        setView({...new_view});
        hasUnsavedChanges();
        setViewChangeMade(true);
    }

    function handleRemoveSort(i) {
        let new_view = view;
        new_view.sort.splice(i, 1);
        setView({...new_view});
        hasUnsavedChanges();
        setViewChangeMade(true);
    }

    function handleEditSort(i, u) {
        let new_view = view;
        new_view.sort[i] = u;
        setView({...new_view});
        hasUnsavedChanges();
        setViewChangeMade(true);
    }

    function handleRemoveFilter(i) {
        let new_view = view;
        new_view.filters.splice(i, 1);
        setView({...new_view});
        hasUnsavedChanges();
        setViewChangeMade(true);
    }

    function handleReorderFilters(fs) {
        let new_view = view;
        new_view.filters = fs;
        setView({...new_view});
        hasUnsavedChanges();
        setViewChangeMade(true);
    }

    function handleReorderProperties(new_props) {
        let new_view = {...view};
        new_view.properties = [...new_props];
        setView({...new_view});
        hasUnsavedChanges();
    }

    function handleMoveProperty(current_index, new_index) {
        let new_view = view;
        new_view.properties = at_array_move(view.properties, current_index, new_index);
        setView({...new_view});
        hasUnsavedChanges();
    }

    function handleEditFilter(i, f) {
        let new_view = view;
        new_view.filters[i] = f;
        setView({...new_view});
        hasUnsavedChanges();
        setViewChangeMade(true);
    }

    function handleAddFilter(nf) {
        let new_view = view;
        new_view.filters.push({...nf, join: 'and'});
        setView({...new_view});
        hasUnsavedChanges();
        setViewChangeMade(true);
    }

    function saveChanges(ac) {
        const payload = {
            changes: ac,
            member_id: context.member_id,
            community_uid: context.community_uid
        };
        updateChanges([]);
        authFetch(definitions.general.bulk_endpoint, async () => {
            addToast({text: 'Your changes were saved', intent: 'success'});
        }, async () => {
        }, 'POST', {payload});
    }

    function updateSmartView(id, current, update) {
        let payload = {
            id,
            config: current,
            ...update,
            community_uid: context.community_uid
        };

        payload.config.name = update.name;

        addToast({text: 'Updating view..', intent: 'info'});

        const res = (resp) => {
            //   const {} = resp;
            let nvd = {...view_data};
            nvd[id].has_unsaved_changes = false;
            setViewData(nvd);
            setView({...view, has_unsaved_changes: false})
            addToast({text: 'View updated', intent: 'success'});
        };

        authFetch("/smart-views/update", res, res, "POST", {payload});
    }

    function deleteSmartView(id) {
        const payload = {
            id,
            community_uid: context.community_uid
        };

        // need to avoid the case of having a view selected and then having it deleted, that would lead to errors

        // selectView(id) of other view, first in arr
        if (id === view.id) {
            selectView(views[0]);
        }

        addToast({text: 'Deleting view..', intent: 'info'});

        const res = (resp) => {
            const {id} = resp;
            addToast({text: 'View deleted', intent: 'success'});
        };

        authFetch("/smart-views/delete", res, res, "POST", {payload});
    }

    function updateView(id, updated_view) {
        // updates views
        let all_views = view_data;
        all_views[id] = updated_view;
        all_views[id].has_unsaved_changes = true;
        setViewData({...all_views});

        // update view
        setIds(null);
        setView(at_combineViewProperties(all_views[view.id], definitions.properties, context.custom_fields, definitions, context.data));
    }

    function selectView(id) {
        setIds(null);
        if(!id||!view_data[id]) {
            return;
        }
        setView(at_combineViewProperties({...view_data[id]}, definitions.properties, context.custom_fields, definitions, context.data));
        if (ls_key) {
            saveItemToLocalStorage(ls_key, id)
        }
        setViewChangeMade(true);
    }

    function handleSetSearchQuery(sq, flag) {
        setDebouncedTerm(sq);
        if (flag) {
            setTimeout(() => {
                setViewChangeMade(true);
            }, 500)
        }
    }

    useEffect(function () {
        if (search_query) {
            setViewChangeMade(true);
        } else {
            // how to handle clear of the search
        }
    }, [search_query])

    useEffect(() => {
        const timer = setTimeout(() => setSearchQuery(debouncedTerm), 450);
        return () => clearTimeout(timer);
    }, [debouncedTerm])

    function handleReorderSort(s) {
        let new_view = view;
        new_view.sort = s;
        setView({...new_view});
        hasUnsavedChanges();
    }


    function addView(new_view) {
        let create_view = {...new_view};

        const payload = {
            type: config.type, // member
            id: create_view.config.id,
            layout: create_view.config.layout,
            ...new_view,
            community_uid: context.community_uid
        };

        addToast({text: 'Creating new view..', intent: 'info'});

        const res = (resp) => {
            const {id} = resp;
            addToast({text: 'View created', intent: 'success'});
        };

        authFetch("/smart-views/create", res, res, "POST", {payload});
    }

    function setGroup(ng) {
        let new_view = view;
        new_view.group_by = ng;
        setViewCM(true);
        setView({...new_view});
        setIds(null);
        hasUnsavedChanges();
    }

    if (!view || !view.filters || !view.sort || !views || !view_data) {
        return <ATContainer>
            <ATHeader>
            </ATHeader>
            <ATContentWrapper>
                <FullLoader>
                    <InlineLoader/>
                </FullLoader>

            </ATContentWrapper>
        </ATContainer>;
    }


    const sorts_in_use = at_getSortsInUse(view.sort);
    const sort_with_direction = at_getSortsWithDirection(view.sort);
    const all_sort_options = at_buildSortOptions(definitions.sort_options, sorts_in_use);
    const build_options = at_buildAddOptions(all_sort_options);
    const can_add_sort = Object.keys(sorts_in_use).length < 2;

    const filters_in_use = at_getFiltersInUse(view.filters);
    const filter_options = at_buildFilterOptions(definitions.filter_options, filters_in_use);
    const filter_avail_opts = at_buildAddOptions(filter_options);

    const group_options = at_buildGroupOptions(definitions.group_options, view.group_by);

    const table_fns = {
        addSort: (field) => {
            let new_view = view;
            new_view.sort = at_updateSort(view.sort, 'add-sort', field, definitions);
            setView({...new_view});
        },
        removeSort: (field) => {
            let new_view = view;
            new_view.sort = at_updateSort(view.sort, 'remove-sort', field, definitions);
            setView({...new_view});
        },
        reverseSort: (field) => {
            let new_view = view;
            new_view.sort = at_updateSort(view.sort, 'reverse-dir', field, definitions);
            setView({...new_view});
        },
        addFilter: () => {
        },
        removeFilter: () => {
        },
        hideField: (pid) => {
            handleToggleProperty(pid, false);
        },
        moveColLeft: (ci, ni) => handleMoveProperty(ci, ni),
        moveColRight: (ci, ni) => handleMoveProperty(ci, ni)
    };

    function renderAction(item) {
        if (item.type === 'popup-menu') {
            const content = <PopupMenu items={item.options.map(opt => {
                return {
                    ...opt,
                    onClick: () => opt.onClick(selected_ids.length>0?selected_ids:ids, data_store.current)
                }
            })}/>;
            return <Popover2 popoverClassName="" key={'actions'} placement="bottom-end" content={content}>
                {item.button}
            </Popover2>
        } else if(item.type==='item') {
            return <ATButtonBox onClick={()=>{
                item.onClick(selected_ids.length>0?selected_ids:ids)
            }} icon={item.icon} style="alt">
                {item.title}
            </ATButtonBox>
        } else {
            return null;
        }
    }

    /*
      const dq_info = config.type==='member'&&data&&data.length>0?getDQInfo(data):null;
    {dq_info&&view.layout==='table'&&<div className="transform scale-95">
                        <DataQualityIcon score={dq_info.score} counts={dq_info.counts} />
                    </div>}
     */
    function renderOptionsBar(len, svd, icv) {
        if (is_mobile) {
            return null;
        }
        //
        if (len === 0) {
            return <ATOptionsBar
                left={<div className="flex space-x-1.5 relative">
                    <ATSelectView is_custom_view={icv} total={is_loading ? 0 : total_ref.current}
                                  selected_view_data={svd} config={config} context={context}
                                  selectView={selectView} addView={addView} loading={is_loading}
                                  updateView={updateView} updateSmartView={updateSmartView}
                                  deleteSmartView={deleteSmartView} views={views}
                                  view_data={view_data} definitions={definitions} view={view}/>

                    {config.features.filter && !is_mobile &&
                    <ATFilter definitions={definitions} filter_avail_opts={filter_avail_opts}
                              filter_options={filter_options} context={context}
                              filters={view.filters} onEditFilter={handleEditFilter}
                              reorderFilters={handleReorderFilters}
                              onRemoveFilter={handleRemoveFilter}
                              onAddFilter={handleAddFilter}/>}

                    <div className="absolute right-0 top-0 bottom-0 w-2" style={{background: `linear-gradient(to right, rgba(255,255,255,0), rgba(255,255,255,1))`}} />
                </div>}>

                <div className="pr-1">
                    <ATSearch loading={is_loading} query={debouncedTerm} setQuery={handleSetSearchQuery}/>
                </div>
                {config.features.edit_fields && !is_mobile &&
                <ATCustomizeView config={config} view={view} definitions={definitions} context={context}
                                 history={history} total={total_ref.current} handleChangeLayout={handleChangeLayout}
                                 toggleShowDeactivatedMembers={handleToggleDeMembers}
                                 setGroup={setGroup} group_options={group_options}
                                 setDefaultView={setDefaultView}
                                 handleToggleProperty={handleToggleProperty}
                                 handleEditSort={handleEditSort} all_sort_options={all_sort_options}
                                 sorts_in_use={sorts_in_use}
                                 build_options={build_options} handleReorderSort={handleReorderSort}
                                 handleRemoveSort={handleRemoveSort} handleAddSort={handleAddSort}
                                 handleReorderProperties={handleReorderProperties}/>}

                {config.actions.map((item, i) => <ActionButton key={i}>{renderAction(item)}</ActionButton>)}
            </ATOptionsBar>
        } else {
            return <ATOptionsBar left={<div className="flex items-center space-x-1">
                <SelectedText className='text-gray-600'>
                    {len} selected
                </SelectedText>
                {config.selected_actions.map((item, i) => <ActionButton key={i}>{renderAction(item)}</ActionButton>)}
            </div>}>

                <div className="pr-1">
                    <ATSearch loading={is_loading} query={debouncedTerm} setQuery={handleSetSearchQuery}/>
                </div>
                {config.actions.map((item, i) => <ActionButton key={i}>{renderAction(item)}</ActionButton>)}
            </ATOptionsBar>
        }
    }

    let loading = ids === null;

    const renderer_props = {
        data: preprocessData(data_store.current, context, definitions, partials),
        ids: ids,
        definitions,
        table_fns,
        suggestions_handler: {},
        suggestions: false,
        actions: {},
        handleToggleProperty: handleToggleProperty,
        setNewSort: (obj) => {
            handleEditSort(0, obj)
        },
        goToItem: (id, dt) => {
            context.global.handleSetRightMenu(`preview-member__${dt.handle}`);
        },
        editItem: (id, dt) => {
            context.global.handleSetRightMenu(`admin-member__${id}`);
        },
        onNextPage: () => {
            nextPage()
        },
        loading: loading || is_loading,
        scroll_id: scroll_id,
        scroll: "infinite",
        scroll_options: {
            padding: 500
        },
        context,
        view,
        selected_ids,
        updateSelected: (ids) => {
            if(ids.length===0) {
                setSelected({});
                return;
            }

            let ns = {};
            ids.forEach(id => {
                    ns[id] = true;
            })
            setSelected(ns);
        },
        row_actions,
        meta: {
            can_change_image: true,
            changeImageFn: (id, url, color) => {

            }
        },
        has_more: has_more_ref.current,
        total_results: total_ref.current,
        page: page_ref.current
    };

    const selected_view_data = view_data[view.id];

    const is_custom_view = selected_view_data.type === 'custom';

    return <>
        {title && <div>
            <EntityHeader title={title.title} actions={title.action}/>
        </div>}
        <div className="relative">
            <ATContainer>

                <ATHeader>
                    {renderOptionsBar(selected_len, selected_view_data, is_custom_view)}
                </ATHeader>
                <ATContentWrapper is_mobile={is_mobile} layout={view.layout}>
                    <NewDirectoryRenderer key="a" {...renderer_props} />
                </ATContentWrapper>

                <ATMassActions deselectAll={()=> {
                    setSelected({})
                }} selected_ids={selected_ids} manage_actions={config.manage_actions} handleCloseModal={handleCloseModal} context={context}
                               community={context.community} modal={modal}
                               view={view} group_id={group_id} definitions={definitions}/>

                <div className="_block_scroller"/>
            </ATContainer>
        </div>
    </>
}