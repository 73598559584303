
import {countries, getFlagByCountryCode} from "../../../data/general/countries";
import {at_array_move, at_getFieldValue} from "../utilities";

export const at_sort_label_map = {
    text: {
        desc: 'Z to A',
        asc: 'A to Z'
    },
    date: {
      asc: 'Oldest',
      desc: 'Latest'
    },
    number: {
        asc: 'Low to High',
        desc: 'High to Low'
    },
    rank: {
        asc: 'Ascending',
        desc: 'Descending'
    },
    position: {
        asc: 'Ascending',
        desc: 'Descending'
    }
};


export function at_propIsInArray(arr,f,v) {
    for(let i = 0; i < arr.length; i++) {
        if (arr[i][f] === v) {
            return i;
        }
    }
    return null;
}

export function at_getFiltersInUse(s) {
    let obj = {};
    for (let i = 0; i < s.length; i++) {
        obj[s[i].field] = true;
    }
    return obj;
}


export function at_getSortsInUse(s) {
    let obj = {};
    for (let i = 0; i < s.length; i++) {
        obj[s[i].field] = true;
    }
    return obj;
}

export function at_getSortsWithDirection(s) {
    let obj = {};
    for (let i = 0; i < s.length; i++) {
        const it = s[i];
        obj[it.field] = it.dir;
    }
    return obj;
}

export function at_buildAddOptions(a) {
    let arr = [];

    for (let i = 0; i < a.length; i++) {
        if (!a[i].disabled) {
            arr.push(a[i]);
        }
    }

    return arr;
}

export function at_buildGroupOptions(all,active) {
    let arr = [];

    const entries = Object.entries(all);
    for (let i = 0; i < entries.length; i++) {
        const [id, entry] = entries[i];
        arr.push({
            active: active && active.field===entry.field,
            field: entry.field,
            options: entry.options,
            value: entry.field,
            sort_type: entry.sort_type,
            sort: entry.sort,
            dir: entry.dir,
            text: entry.label,
            type: entry.type
        })
    }

    return arr;
}


function getGroupOptions(type,context) {
    let a, obj = {};
    if(type==='country') {
        // map of vals
        a = Object.entries(countries);
        for(let i=0;i<a.length;i++) {
            const [b,c] = a[i];
            obj[b] = {
                label: c,
                emoji: getFlagByCountryCode(b)
            };
        }
        return obj;
    } else if(type==='member_type') {
        a = Object.entries(context.member_types);
        for(let i=0;i<a.length;i++) {
            const [b,c] = a[i];
            obj[b] = {
                label: c.singular,
                emoji: c.emoji
            };
        }
        return obj;
    } else if(type==='account_status') {
        a = [];
        for(let i=0;i<a.length;i++) {
            const [b,c] = a[i];
            obj[b] = {
                label: c.singular,
                emoji: c.emoji
            };
        }
        return obj;
    } else if(type==='data_integrity') {
        a = [];
        for(let i=0;i<a.length;i++) {
            const [b,c] = a[i];
            obj[b] = {
                label: c.singular,
                emoji: c.emoji
            };
        }
        return obj;
    } else if(type==='number') {
        return obj;
    }
}
// number: homes.sort((a, b) => parseFloat(a.price) - parseFloat(b.price));
// alpha: list.sort((a, b) => (a.color > b.color) ? 1 : -1)
function sortGroups(group_by,group_map) {
    let arr = Object.values(group_map);
    if(group_by.type==='country') {
        if(group_by.dir==='asc') {
            return arr.sort((a, b) => (a.label > b.label) ? 1 : -1);
        } else {
            return arr.sort((a, b) => (a.label < b.label) ? 1 : -1);
        }
    } else if(group_by.type==='member_type') {
        if(group_by.dir==='asc') {
            return arr.sort((a, b) => (a.label > b.label) ? 1 : -1);
        } else {
            return arr.sort((a, b) => (a.label < b.label) ? 1 : -1);
        }
    }
    return [];
}

export function at_buildDataGroups(group_by,data={},context) {
    if(!group_by) {
        return null;
    }

    let group_map = {};
    let options = getGroupOptions(group_by.type,context);

    let entries = Object.entries(data);

    for(let i=0;i<entries.length;i++) {
        const [id,entry] = entries[i];
        // need to get field
        const item_value = at_getFieldValue(group_by.field,entry);

        let group_key = item_value==='' ? '_blank' : item_value;

        if(!group_map[group_key]) {
            group_map[group_key] = {
                items: {},
                ids: {},
                ...options[group_key]
            }
        }
        group_map[group_key].ids[id] = true;

        group_map[group_key].items[id] = entry;
    }

    // todo now we would need to sort items in each by sort fields

    return sortGroups(group_by,group_map);
}

export function at_buildFilterOptions(all,in_use) {
    let arr = [];

    const entries = Object.entries(all);
    for (let i = 0; i < entries.length; i++) {
        const [id, entry] = entries[i];
        arr.push({
            disabled: !!in_use[entry.field],
            field: entry.field,
            value: entry.field,
            text: entry.label,
            options: entry.options ? entry.options: [],
            type: entry.type
        })
    }

    return arr;
}

export function at_buildSortOptions(opts, siu) {
    let arr = [];

    const entries = Object.entries(opts);

    for (let i = 0; i < entries.length; i++) {
        const [id, entry] = entries[i];
        arr.push({
            disabled: !!siu[entry.field],
            field: entry.field,
            label: entry.label,
            default_value: entry.default_value,
            options: entry.options.map(opt => {
                return {
                    text: at_sort_label_map[entry.type][opt],
                    key: opt,
                    value: opt
                }
            })
        });
    }

    return arr;
}

export function at_getFinalSort(arr,{source,destination}) {
    return at_array_move(arr,source.index,destination.index);
}