import React, {useState,useContext} from 'react';
import {AddEducationStep} from "../../../../library/components/reorganize/add-education-step";
import Modal from "../../../../library/components/old/modal";
import {Button} from "../../../../library/components/core";

import {CUSTOM_NANOID} from "../../../../config/defaults";

const mock = {
    org_data: {
        community_uid: '',
        title: '',
        image: '',
        handle: ''
    },

    degree: '', // Bachelor of Science (B.Sc.)

    field_of_study: '', // BWL

    from_year: null,
    to_year: null,

    grade: '', // 1.0

    description: ''
};

function shouldDisable(d) {
    if(d.degree.length<2) {
        return true;
    }
    if(d.field_of_study.length<2) {
        return true;
    }
    return false;
}

export function AddEducationModal({onAdd,onClose,loading}) {
    const [education,setEducation] = useState(mock);

    function handleUpdateEducation(f,v) {
        let ed = Object.assign({},education);
        if(f==='range') {
            const final = {...ed,...v};
            setEducation({...final})
        } else if(f==='org_data') {
            ed[f]['title'] = v;
            setEducation({...ed});
        } else {
            ed[f] = v;
            setEducation(ed);
        }
    }

    const disabled = shouldDisable(education);

    return <Modal title={"Edit Education"} size='base' body={<AddEducationStep education={education} updateEducation={handleUpdateEducation.bind(this)} />} footerRight={<div>
        <Button intent='secondary' loading={loading} disabled={disabled} onClick={()=>{
            const id = CUSTOM_NANOID();
            onAdd(education,id);
        }} text='Add' />
    </div>} footerLeft={null}
                  onClose={onClose} open={true} lazy />
}