import React from "react"; 

export function PrivacyText() {
    return <div>
            <div className="normal-secondary-header">Last updated: May 2nd, 2018</div>

            <div>
                This Privacy Policy describes how Unaty collects, uses and discloses information, and what
                choices you have with respect to the information.
                Updates in this version of the Privacy Policy reflect changes in data protection law. In
                addition, we have worked to make the Privacy Policy clearer and more understandable by:
                <ul>
                    <li>organizing it into the sections listed in the Table of Contents below,</li>
                    <li>providing a series of examples that help illustrate how the policies may be
                        implemented by
                        Unaty and
                    </li>
                    <li>defining and capitalizing a few terms that are used more than once for simplicity
                        and brevity.
                        When we refer to "Unaty", we mean the Unaty Technologies GmbH, Unertlstr. 27,
                        D-80803 Munich,
                        that acts as the controller or processor of your information, as explained in more
                        detail in the
                        "Identifying the Data Controller and Processor" section below.
                    </li>
                </ul>
            </div>
            <div className="ui header">Table of Contents</div>
            <ol>
                <li><a href="#1">Applicability of this Privacy Policy</a></li>
                <li><a href="#2">Information We Collect and Receive</a></li>
                <li><a href="#3">How We Use Information</a></li>
                <li><a href="#4">Data Retention</a></li>
                <li><a href="#5">How We Share and Disclose Information</a></li>
                <li><a href="#14">Unaty Subprocessors</a></li>
                <li><a href="#6">Security</a></li>
                <li><a href="#7">Age Limitations</a></li>
                <li><a href="#8">Changes to this Privacy Policy</a></li>
                <li><a href="#9">Data Protection Officer</a></li>
                <li><a href="#10">Identifying the Data Controller and Processor</a></li>
                <li><a href="#11">Your Rights</a></li>
                <li><a href="#12">Data Protection Authority</a></li>
                <li><a href="#13">Contacting Unaty</a></li>
            </ol>
            <div className="ui divider section"></div>
            <div className="ui header" id="1">1. Applicability of This Privacy Policy</div>
            <div>
                This Privacy Policy applies to Unaty's online platform, including the Unaty's products
                "Build", "Engage" and "Grow" (collectively, the "Services"), Unaty.de and other Unaty
                websites
                (collectively, the "Websites") and other interactions (e.g., customer service inquiries,
                user conferences, etc.) you may have with Unaty.
            </div>
            <div>
                Users and Customers, who use our Services, consent with our practices of collecting and
                processing data with a click on a distinct checkbox when signing up for a Unaty Account.
                Furthermore, if you do not agree with the privacy policy, please do also not access or use
                the Websites or any other aspect of Unaty's business.
            </div>
            <div>
                This Privacy Policy does not apply to any third-party applications or software that
                integrate
                with the Services through the Unaty platform ("Third Party Services"), or any other
                third-party
                products, services or businesses. In addition, a separate agreement governs delivery, access
                and
                use of the Services (the "Terms of Services for Customers"), including the processing of any
                messages, files or other content submitted through Services accounts (collectively,
                "Customer
                Data"). The organization (e.g., your employer or another entity or person) that entered into
                the
                Customer Agreement ("Customer") controls their instance of the Services (their "Community")
                and
                any associated Customer Data. If you have any questions about specific Community settings
                and
                privacy practices, please contact the Customer whose Community you use. If you have an
                account,
                you can check Settings>Community Administration for contact information of your Community
                owner(s) and administrator(s). If you have received an invitation to join a Community but
                have
                not yet created an account, you should request assistance from the Customer that sent the
                invitation.
            </div>
            <div className="ui header" id="2">
                Information We Collect and Receive
            </div>
            <div>
                Unaty may collect and receive Customer Data, Content Data and data about data ("Metadata")
                in a
                variety of ways:
            </div>
            <div>
                <strong>Customer Data</strong><br/>
                Customers or individuals granted access to a Community by a Customer
                ("Administrators") routinely submit Customer Data to Unaty when using the Services: To
                create or
                update a Community account, you or your Customer (e.g., your Club/Organization) supply Unaty
                with an email address, phone number, password, domain and/or similar account details. In
                addition, Customers that purchase a Products provide Unaty (or its payment processor Stripe,
                Inc.) with billing details such as credit card information, banking information and/or a
                billing
                address.
            </div>
            <div>
                <strong>Content Data</strong><br/>
                Unaty contains and therefore receives data that is created in the
                Communities
                like Events, Roles, Personal Messages and Conversations. Most of these data is directly
                connectable to Customers and Users and is therefore personal data. Unaty emphasizes
                explicitly
                that Content data is not used for any purposes but generating anonymous metadata (see
                below).
                Content Data is neither sold nor used for usable services for third parties or monetized in
                any
                other way. These Data is not accessible to Unaty employees or third parties.
            </div>
            <div>
                <strong>Metadata</strong><br/>
                Unaty collects, generates and/or receives general Metadata:
            </div>
            <div>
                <strong>Services Metadata</strong><br/>
                When an Administrator interacts with the Services, metadata is
                generated
                that provides additional context about the way Administrators work. For example, Unaty logs
                the
                Communitys, channels, people, features, content and links you interact with, the types of
                files
                shared and what Third Party Services are used (if any).
            </div>
            <div>
                <strong>Log data</strong><br/>
                As with most websites and technology services delivered over the Internet, our
                servers automatically collect information when you access or use our Websites or Services
                and
                record it in log files. This log data may include the Internet Protocol (IP) address, the
                address of the web page visited before using the Website or Services, browser type and
                settings,
                the date and time the Services were used, information about browser configuration and
                plugins,
                language preferences and cookie data.
            </div>
            <div>
                <strong>Device information</strong><br/>
                Unaty collects information about devices accessing the Services,
                including
                type of device, what operating system is used, device settings, application IDs, unique
                device
                identifiers and crash data. Whether we collect some or all of this Metadata often depends on
                the
                type of device used and its settings.
            </div>
            <div>
                <strong>Location information</strong><br/>
                We receive information from you, your Customer and other
                third-parties
                that helps us approximate your location. We may, for example, use a business address
                submitted
                by your employer, or an IP address received from your browser or device to determine
                approximate
                location. Unaty may also collect location information from devices in accordance with the
                consent process provided by your device.
            </div>
            <div>
                <strong>Cookie Information</strong><br/>
                Unaty uses cookies and similar technologies in our Websites and
                Services
                that help us collect Metadata. The Websites and Services may also include cookies and
                similar
                tracking technologies of third parties, which may collect Metadata about you via the
                Websites
                and Services and across other websites and online services. For more details about how we
                use
                these technologies, please see our Cookie Policy.
            </div>
            <div>
                <strong>Third Party Services</strong><br/>
                Customer can choose to permit or restrict Third Party Services for their
                Community. Typically, Third Party Services are software that integrate with our Services,
                and
                Customer can permit its Administrators to enable and disable these integrations for their
                Community. Once enabled, the provider of a Third Party Service may share certain information
                with Unaty. For example, if an E-Mail-Marketing application is enabled to permit email lists
                to
                be imported to a Community, we may receive user name and email address of Administrators,
                along
                with additional information that the application has elected to make available to Unaty to
                facilitate the integration. Administrators should check the privacy settings and notices in
                these Third Party Services to understand what data may be disclosed to Unaty. When a Third
                Party
                Service is enabled, Unaty is authorized to connect and access Metadata made available to
                Unaty
                in accordance with our agreement with the Third Party Provider. We do not, however, receive
                or
                store passwords for any of these Third Party Services when connecting them to the Services.
                Generally, no one is under a statutory or contractual obligation to provide any Customer
                Data,
                Content Data or Metadata (collectively, "Information"). However, certain Information is
                collected automatically and, if some Information, such as Community setup details, is not
                provided, we may be unable to provide the Services.
            </div>
            <div className="ui header" id="3">
                How We Use Information
            </div>
            <div>
                Customer Data will be used by Unaty in accordance with Customer's instructions, including
                any
                applicable Terms of Service for Customer, and as required by applicable law. Unaty is a
                processor of Customer Data and Customer is the controller. Customer may, for example, use
                the
                Services to grant and remove access to a Community, assign roles and configure settings,
                access,
                modify, export, share and remove Customer Data and otherwise apply its policies to the
                Services.
                Content Data is never used by Unaty for Business. Anonymous generated Data around Content
                Creation and Usage is collected as part of our Metadata, which is never connected to single
                Users or Customers. Unaty will make Content Data accessible only to public authorities when
                requested, for example when illegal use of Unaty is indicated.
            </div>
            <div>
                Unaty uses Metadata in furtherance of our legitimate interests in operating our Services,
                Websites and business, always as required by applicable law, legal process or regulation..
                More
                specifically, Unaty uses Metadata:
                <ul>
                    <li>To provide, update, maintain and protect our Services, Websites and business. This
                        includes
                        use of Metadata to support delivery of the Services under a Customer Agreement,
                        prevent or
                        address service errors, security or technical issues, analyze and monitor usage,
                        trends and
                        other activities or at an Administrator's request.
                    </li>
                    <li>To develop and provide search, learning and engagement tools and additional
                        features. Unaty
                        tries to make the Services as useful as possible for specific Communities and
                        Administrators.
                        For example, we may improve search functionality by using Metadata to help determine
                        and rank
                        the relevance of content, channels or expertise to an Administrator, make Services
                        suggestions
                        based on historical use and predictive models, identify organizational trends and
                        insights, to
                        customize a Services experience or create new engagement features and products.
                    </li>
                </ul>
            </div>
            <div>
                If Information is aggregated or de-identified so it is no longer reasonably associated with
                an
                identified or identifiable natural person, Unaty may use it for any business purpose. To the
                extent Information is associated with an identified or identifiable natural person and is
                protected as personal data under applicable data protection law, it is referred to in this
                Privacy Policy as "Personal Data."
            </div>
            <div>
                In the following cases, Unaty is allowed to connect Metadata to single Users or
                Organizations
                (Customers, Communities) which are then classified as Personal Data:
                <ul>
                    <li>For billing, account management and other administrative matters. Unaty may need to
                        contact
                        you for invoicing, account management and similar reasons and we use account data to
                        administer
                        accounts and keep track of billing and payments.
                    </li>
                    <li>To communicate with you by responding to your requests, comments and questions. If
                        you contact
                        us, we may use your Metadata to respond.
                    </li>
                    <li>To send emails and other communications. We may send you service, technical and
                        other
                        administrative emails, messages and other types of communications. We may also
                        contact you to
                        inform you about changes in our Services, our Services offerings, and important
                        Services-related
                        notices, such as security and fraud notices. These communications are considered
                        part of the
                        Services and you may not opt out of them. In addition, we sometimes send emails
                        about new
                        product features, promotional communications or other news about Unaty. These are
                        marketing
                        messages so you can control whether you receive them.
                    </li>
                    <li>To investigate and help prevent security issues and abuse.</li>
                </ul>
            </div>
            <div className="ui header" id="4">
                Data Retention
            </div>
            <div>
                Unaty will retain Customer Data in accordance with a Customer's instructions, including any
                applicable terms in the Customer Agreement and Customer's use of Services functionality, and
                as
                required by applicable law. Depending on the Services plan, Customer may be able to
                customize
                its retention settings and apply those customized settings at the Community level, channel
                level
                or other level. Customer may also apply different settings to messages, files or other types
                of
                Customer Data. The deletion of Customer Data and other use of the Services by Customer may
                result in the deletion and/or de-identification of certain associated Metadata. For more
                detail,
                please review the Help Center or contact Customer. Unaty may retain Metadata pertaining to
                you
                for as long as necessary for the purposes described in this Privacy Policy. This may include
                keeping your Metadata after you have deactivated your account for the period of time needed
                for
                Unaty to pursue legitimate business interests, conduct audits, comply with (and demonstrate
                compliance with) legal obligations, resolve disputes and enforce our agreements. Personal
                Data
                is deleted automatically 14 days after the deletion of a community. If you want to get your
                personal data deleted as an user, please contact your Community Administrators, who are the
                controllers of these data. A deletion of your personal Unaty Access is not equal to the
                deletion
                of a community and therefore not enough to delete all of your Personal Data.
            </div>
            <div className="ui header" id="5">
                How We Share and Disclose Information
            </div>
            <div>
                This section describes how Unaty may share and disclose Information. Customers determine
                their
                own policies and practices for the sharing and disclosure of Information, and Unaty does not
                control how they or any other third parties choose to share or disclose Information.

                <ul>
                    <li>Customer's Instructions. Unaty will solely share and disclose Customer Data in
                        accordance with
                        a Customer's instructions, including any applicable terms in the Customer Agreement
                        and
                        Customer's use of Services functionality, and in compliance with applicable law and
                        legal
                        process.
                    </li>
                    <li>Customer Access. Owners, administrators, Administrators and other Customer
                        representatives and
                        personnel may be able to access, modify or restrict access to Metadata. This may
                        include, for
                        example, your employer using Service features to export logs of Community activity,
                        or accessing
                        or modifying your profile details.
                    </li>
                    <li>Third Party Service Providers and Partners. We may engage third party companies or
                        individuals
                        as service providers or business partners to process Metadata and support our
                        business. These
                        third parties may, for example, provide virtual computing and storage services.
                        Additional
                        information about the subprocessors we use to support delivery of our Services is
                        set forth at
                        Unaty Subprocessors.
                    </li>
                    <li>Third Party Services. Customer may enable or permit Administrators to enable Third
                        Party
                        Services. When enabled, Unaty may share Metadata with Third Party Services. Third
                        Party Services
                        are not owned or controlled by Unaty and third parties that have been granted access
                        to Metadata
                        may have their own policies and practices for its collection and use. Please check
                        the privacy
                        settings and notices in these Third Party Services or contact the provider for any
                        questions.
                    </li>
                    <li>Corporate Affiliates. Unaty may share Metadata, like its User Growth, with its
                        corporate
                        affiliates, parents and/or subsidiaries.
                    </li>
                    <li>During a Change to Unaty's Business. If Unaty engages in a merger, acquisition,
                        bankruptcy,
                        dissolution, reorganization, sale of some or all of Unaty's assets or stock,
                        financing, public
                        offering of securities, acquisition of all or a portion of our business, a similar
                        transaction
                        or proceeding, or steps in contemplation of such activities (e.g. due diligence),
                        some or all
                        Metadata may be shared or transferred, subject to standard confidentiality
                        arrangements.
                    </li>
                    <li>Aggregated or De-identified Data. We may disclose or use aggregated or de-identified
                        Metadata
                        for any purpose. For example, we may share aggregated or de-identified Metadata with
                        prospects
                        or partners for business or research purposes, such as telling a prospective Unaty
                        customer the
                        average amount of time spent within a typical Community.
                    </li>
                    <li>To Comply with Laws. If we receive a request for information, we may disclose
                        Metadata if we
                        reasonably believe disclosure is in accordance with or required by any applicable
                        law,
                        regulation or legal process. Please see the Data Request Policy to understand how
                        Unaty responds
                        to requests to disclose data from government agencies and other sources.
                    </li>
                    <li>To enforce our rights, prevent fraud, and for safety. To protect and defend the
                        rights,
                        property or safety of Unaty or third parties, including enforcing contracts or
                        policies, or in
                        connection with investigating and preventing fraud or security issues.
                    </li>
                    <li>With Consent. Unaty may share Metadata with third parties when we have consent to do
                        so.
                    </li>
                </ul>

            </div>
            <div className="ui header" id="14">
                Unaty Subprocessors
            </div>
            <div>
                To support delivery of our Services, Unaty Technologies GmbH
                may engage and use data processors with access to certain Customer Data (each,
                a "<strong>Subprocessor</strong>").
            </div>
            <div>
                Unaty currently uses third party Subprocessors to provide infrastructure services, and to
                help us provide customer support, website analytics and email/sms notifications. Prior to engaging any third party
                Subprocessor, Unaty performs diligence to evaluate their privacy, security and
                confidentiality practices, and executes an agreement implementing its applicable
                obligations.
            </div>
            <div>
                <strong>Infrastructure Subprocessors</strong><br/>
                <table>
                    <tbody>
                    <tr>
                        <th>Entity Name</th>
                        <th>Subprocessing Activities</th>
                        <th>Entity Country</th>
                        <th>Country of Subprocessing Activities</th>
                    </tr>
                    <tr>
                        <td>Meteor Development Group Inc.</td>
                        <td>Cloud-Service Provider of the Unaty Platform</td>
                        <td>USA</td>
                        <td>USA</td>
                    </tr>
                    <tr>
                        <td>ObjectLabs Corporation</td>
                        <td>Cloud-Service Provider of the Unaty Platform</td>
                        <td>USA</td>
                        <td>USA</td>
                    </tr>
                    <tr>
                        <td>Amazon Web Services, Inc.</td>
                        <td>Hosting of unaty.de</td>
                        <td>USA</td>
                        <td>Germany</td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <div>
                <strong>Other Subprocessors</strong><br/>
                <table>
                    <tbody>
                    <tr>
                        <th>Entity Name</th>
                        <th>Subprocessing Activities</th>
                        <th>Entity Country</th>
                        <th>Country of Subprocessing Activities</th>
                    </tr>
                    <tr>
                        <td>Stripe, Inc.</td>
                        <td>Cloud-based payment services</td>
                        <td>USA</td>
                        <td>USA</td>
                    </tr>
                    <tr>
                        <td>Mailgun Technologies, Inc.</td>
                        <td>Cloud-based email notification services</td>
                        <td>USA</td>
                        <td>USA</td>
                    </tr>
                    <tr>
                        <td>Twilio, Inc.</td>
                        <td>Cloud-based sms notification services</td>
                        <td>USA</td>
                        <td>USA</td>
                    </tr>
                    <tr>
                        <td>Google, Inc.</td>
                        <td>Cloud-based website analytics services</td>
                        <td>USA</td>
                        <td>USA</td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <div className="ui header" id="6">
                Security
            </div>
            <div>
                Unaty takes security of data very seriously. Unaty works hard to protect Metadata you
                provide
                from loss, misuse, and unauthorized access or disclosure. These steps take into account the
                sensitivity of the Data we collect, process and store, and the current state of technology.
                Unaty undergoes the process of acquiring internationally recognized security certifications
                for
                ISO 27001 (information security management system) and ISO 27018 (for protecting personal
                data
                in the cloud). To learn more about current practices and policies regarding security and
                confidentiality of the Services, please see our Data Security page. Given the nature of
                communications and information processing technology, Unaty cannot guarantee that
                Information,
                during transmission through the Internet or while stored on our systems or otherwise in our
                care, will be absolutely safe from intrusion by others.
            </div>
            <div className="ui header" id="7">
                Age Limitations
            </div>
            <div>
                To the extent prohibited by applicable law, Unaty does not allow use of our Services and
                Websites by anyone younger than 16 years old. If you learn that anyone younger than 16 has
                unlawfully provided us with personal data, please contact us and we will takes steps to
                delete
                such information.
            </div>
            <div className="ui header" id="8">
                Changes to This Privacy Policy
            </div>
            <div>
                Unaty may change this Privacy Policy from time to time. Laws, regulations and industry
                standards
                evolve, which may make those changes necessary, or we may make changes to our business. We
                will
                post the changes to this page and encourage you to review our Privacy Policy to stay
                informed.
                If we make changes that materially alter your privacy rights, Unaty will provide additional
                notice, such as via email or through the Services. If you disagree with the changes to this
                Privacy Policy, you should deactivate your Services account. Contact the Customer if you
                wish to
                request the removal of Personal Data under their control.
            </div>
            <div className="ui header" id="9">
                Data Protection Officer
            </div>
            <div>
                At Unaty, there is only an extremely narrow circle of employees that gets in touch with your
                Personal Data. Therefore, Unaty is not required to register an official data Protection
                Officer
                to authorities. However, we designated one employee to explicitly take care of data
                protection.
                To communicate with him, please email <a href="mailto:datenschutz@unaty.de">datenschutz@unaty.de</a>.
            </div>
            <div className="ui header" id="10">
                Identifying the Data Controller And Processor
            </div>
            <div>
                Data protection law in certain jurisdictions differentiates between the "controller" and
                "processor" of information. In general, Customer is the controller of Customer Data. The
                Unaty
                Technologies GmbH is the controller of Metadata and a processor of Customer Data.
            </div>
            <div className="ui header" id="11">
                Your Rights
            </div>
            <div>
                Individuals located in certain countries, including the European Economic Area, have certain
                statutory rights in relation to their personal data. Subject to any exemptions provided by
                law,
                you may have the right to request access to Information, as well as to seek to update,
                delete
                (Right to be Forgotten) or correct this Information. You can usually do this using the
                settings
                and tools provided in your Services account. If you cannot use the settings and tools,
                contact
                Customer for additional access and assistance.
            </div>
            <div>
                If you are a Customer and want to make use of these rights, contact datenschutz@unaty.de
                Data Protection Authority
            </div>
            <div>
                Subject to applicable law, you also have the right to
                <ul>
                    <li>restrict Unaty's use of Metadata that constitutes your Personal Data and</li>
                    <li>lodge a complaint with your local data protection authority or the Bavarian Data
                        Protection
                        Officer (https://www.datenschutz-bayern.de/), which is Unaty's lead supervisory
                        authority in the
                        European Union.
                    </li>
                </ul>
            </div>
            <div className="ui header" id="12">
                Contacting Unaty
            </div>
            <div>
                Please also feel free to contact Unaty if you have any questions about this Privacy Policy
                or
                Unaty's practices, or if you are seeking to exercise any of your statutory rights. You may
                contact us at <a href="mailto:datenschutz@unaty.de">datenschutz@unaty.de</a> or at our
                mailing address below:
            </div>
            <div>
                For Customers and Administrators who use Communitys established for Customers in the US and
                Canada:
            </div>
            <div>
                <strong>Unaty Technologies GmbH</strong><br/>
                Unertlstraße 27<br/>
                D-80803 München<br/>
                Deutschland
            </div>
        </div>;
}