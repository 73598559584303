import React from 'react';
import {ActionWithIcon, Avatar} from "../../core";
import {NewTooltip} from "../../reorganize/new-tooltip";
import {PaperClipIcon, PhotographIcon} from "@heroicons/react/outline";

export function ComposerShareTab({setMode,community,to,setInitialAction}) {

    return <div onClick={() => setMode('full-screen')}
                className="px-4 gap-2.5 border-b border-gray-200 transition-colors hover:bg-gray-100 cursor-pointer py-2 flex items-center">
        <div className="h-10 w-10">
            <Avatar text={community.member.about.first_name} url={`${community.member.profile_picture}_small?alt=media`} type="member" size="md" />
        </div>
        <div className="flex-grow text-gray-600" style={{fontSize:'15px'}}>
            Send a message to {to[0]&&to[0].name}
        </div>
        <div className="space-x-2 flex flex-none pr-2">
            <div>
                <NewTooltip message="Choose Image">
                    <div className="w-6 h-6 flex items-center justify-center">
                        <ActionWithIcon size="lg" icon={<PhotographIcon />} inverse onClick={(e)=>{
                            e.stopPropagation();
                            setInitialAction('upload-image')
                            setMode('full-screen')
                        }} />
                    </div>
                </NewTooltip>
            </div>
            <div>
                <NewTooltip message="Choose Attachment">
                    <div className="w-6 h-6 flex items-center justify-center">
                        <ActionWithIcon size="lg" icon={<PaperClipIcon />} inverse onClick={(e)=>{
                            e.stopPropagation();
                            setInitialAction('upload-attachment')
                            setMode('full-screen')
                        }} />
                    </div>
                </NewTooltip>
            </div>
        </div>
    </div>
}