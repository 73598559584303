import React from "react";

export function CADPreviewBox({children}) {
    return <div className="rounded-md relative border border-gray-200 p-4 h-full bg-gray-100">
        {children}
    </div>
}

export function CADGrid({children}) {
    return <div className="grid grid-cols-2 gap-4 pt-3 px-4 pb-8">
        {children}
    </div>
}

export function CADEditCard({children,title,subtitle,actions}) {

    return  <div>
        <div style={{minHeight:'48px'}}
                 className={`rounded-t-md space-x-2 px-3 py-1.5 flex border group border-gray-200 bg-white`}>
        <div className="flex-grow flex-col flex">
            <div
                className={`text-lg rounded-md border border-white text-gray-800 font-semibold`}>
                {title}
            </div>
            {subtitle&&<div className="text-sm text-gray-600">
                {subtitle}
            </div>}
        </div>
        <div className="flex space-x-2">
            {actions}
        </div>
    </div>
    <div className={`bg-white space-y-2 px-3 py-3 border-l border-r border-b rounded-b-md`}>
        {children}
    </div>
    </div>
}