import React, {useContext, useState} from 'react';
import ApplyToJoin from "../../../library/components/join-community/apply-to-join";
import {GlobalContext} from "../../global/global-context";
import {Button, InfoState} from "../../../library/components/core";
import {CheckIcon, ClockIcon, InformationCircleIcon, XIcon} from "@heroicons/react/solid";
import {Link, useHistory} from "react-router-dom";

function buildMessage(name) {
    return <span>
        If you are a member of this community, please try <Link className="color-link" to={"/sandbox/sign-out"}>resetting your password</Link> with the e-mail address {name} uses to contact you, or reach out to an organizer to activate your account.
    </span>
 }


function ApplicationDenied({admin_message = ''}) {

    return <InfoState icon={<XIcon/>} title="Application Denied"
                      subtitle={admin_message ? admin_message : 'No reason was given'}/>
}

function ApplicationSent() {
    return <InfoState icon={<CheckIcon/>} title="Application Sent" intent="secondary"
                      subtitle={"We'll send you an email when the Leaders review your application"}/>
}

function ApplicationPending() {
    return <InfoState icon={<ClockIcon/>} title="Application Pending" intent="secondary"
                      subtitle={"We'll send you an email when the Leaders review your application"}/>
}

function CantApplyToJoin({name}) {
    return <InfoState icon={<InformationCircleIcon/>} intent="secondary"
                      subtitle={buildMessage(name)}/>
}


export function JoinWrapper({community_uid, group, can_apply_to_join, group_id, name}) {
    const global = useContext(GlobalContext);
    const [loading, setLoading] = useState('');
    const history = useHistory();

    const m = <div className="pt-4 flex justify-center">
        <Button intent="secondary" text="Back to Memberships" onClick={() => history.push('/memberships')}/>
    </div>;
    if (!can_apply_to_join) {
        return <div>
            <CantApplyToJoin name={name}/>
            {m}
        </div>
    } else if (global.applications[community_uid] === 'pending') {
        return <div>
            <ApplicationPending/>
            {m}
        </div>
    } else if (global.applications[community_uid] === 'denied') {
        return <div>
            <ApplicationDenied/>
            {m}
        </div>
    } else if (loading === 'application-sent') {
        return <div>
            <ApplicationSent/>
            {m}
        </div>
    }
    // first check if user has applied already, if so, show state
    // otherwise show state of being able to apply to join
    return <div>
        <ApplyToJoin group_id={group_id} group={group} name={name} community_uid={community_uid}
                     cb={() => setLoading('application-sent')}/>
    </div>
}