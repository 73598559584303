import React, {useState} from "react";
import {AuthContainer, AuthTitle, SplashContainer, SplashInner} from "../../landing/layout/splash-container";

import MagicLinkBlock from "./MagicLinkBlock";
import {setDocumentTitle} from "../../../library/utilities/set-document-title";


export default function MagicLinkPage(props) {

    setDocumentTitle('Magic Link');
    
    return (
        <SplashContainer>
            <SplashInner>
                <AuthContainer>
                    <AuthTitle>Magic Sign in Link</AuthTitle>
                    <MagicLinkBlock {...props} />
                </AuthContainer>
            </SplashInner>
        </SplashContainer>
    );
}