import React, {useEffect, useMemo} from 'react';
import useInfiniteScroll from "../../../../playground/advanced-table/use-infinite-scroll";
import {BriefcaseIcon, LocationMarkerIcon} from "@heroicons/react/outline";

function LoadingCard() {
    return <div className="rounded-lg border border-gray-100">
        <div className="bg-gray-200 rounded-t-md" style={{paddingBottom: '100%'}}>

        </div>
        <div className="pt-2 px-2 pb-2 flex flex-col space-y-2">
            <div>
                <div className="h-4 bg-gray-300 rounded-md w-32"/>
            </div>
            <div>
                <div className="h-3 bg-gray-200 rounded-md w-20"/>
            </div>
            <div>
                <div className="h-3 bg-gray-200 rounded-md w-20"/>
            </div>
            <div>
                <div className="h-3 bg-gray-200 rounded-md w-20"/>
            </div>
        </div>
    </div>
}


function Title({children}) {
    return <div style={{textDecorationColor: 'lightgray', textUnderlineOffset: '0.1rem'}} className="text-base truncate max-w-full inline-block cursor-pointer underline hover:color-link font-semibold text-gray-800">
        {children}
    </div>
}

function Subtitle({children}) {
    return <div className="text-sm -mt-0.5 truncate max-w-full text-gray-600">
        {children}
    </div>
}

function Meta({children}) {
    return <div className="text-xssm text-gray-600">
        {children}
    </div>
}

function Item({data, loading, onClick}) {

    if (loading) {
        return <LoadingCard/>
    }

    const {image,member_type,location,occupation} = data._gallery;

    return <div className="rounded-lg border border-gray-100">
        <div className="bg-gray-200 rounded-t-md bg-center" style={{backgroundSize:'auto 101%', paddingBottom: '100%',backgroundImage:`url("${image}_medium?alt=media")`}}>

        </div>
        <div className="pt-2 px-2.5 pb-3">
            <Title><span onClick={onClick}>{data.name}</span></Title>
            <Subtitle>{member_type}</Subtitle>
            <div className="pt-1.5 space-y-1.5">
                {location.text&&<Meta>
                    <div className="flex">
                        <div className="h-svg-4 text-gray-500">
                            {location.emoji&&<span>{location.emoji}</span>}
                            {!location.emoji&&<LocationMarkerIcon />}

                        </div>
                        <div className="pl-1  truncate max-w-full">{location.text}</div>
                    </div>
                </Meta>}
                {occupation&&<Meta>
                    <div className="flex">
                        <div className="h-svg-4 text-gray-500">
                            <BriefcaseIcon />
                        </div>
                        <div className="pl-1  truncate max-w-full">{occupation}</div>
                    </div>
                </Meta>}
            </div>

        </div>
    </div>
}

export function DRGallery({
                              data,
                              loading,
                              scroll_id = "",
                              ids,
                              goToItem = () => {},
                              onNextPage = () => {
                              },
                              has_more
                          }) {

    const all_items = useMemo(
        () => [
            ...(ids && data ? ids.filter(id=>!!data[id]).map(id => {
                return data[id];
            }) : [])
        ],
        [ids, data]
    );

    useEffect(function () {
        if (!loading) {
            setIsFetching(false)
        }
    }, [loading])

    const [isFetching, setIsFetching] = useInfiniteScroll("infinite", scroll_id, {
        padding: 400,
        container: true
    }, () => {
        console.log("use infinite scroll",scroll_id,loading,has_more)
        if (!loading && has_more) {
            onNextPage();
        }
    });
    return <div className="grid px-4 gap-3 pt-1 pb-3" style={{gridTemplateColumns: 'repeat(auto-fill, minmax(150px, 1fr))'}}>
        {all_items.map(it => {
            if(!it||!it.id) {
                console.error('no item',it)
                return;
            }

            return <Item onClick={()=>goToItem(it.id,it)} key={it.id} data={it}/>
        })}
        {loading && <>
            <Item loading/>
            <Item loading/>
            <Item loading/>
            <Item loading/>
            <Item loading/>
        </>}
    </div>
}