import React, {Component} from "react";
import styled from "styled-components";
import {AuthContainer, AuthTitle, SplashContainer, SplashInner} from "../../landing/layout/splash-container";
import firebase from "firebase/app";
import {Link} from "react-router-dom";
import {PasswordField} from "../../../library/components/old/password-field";
import {Avatar, Button, InlineLoader} from "../../../library/components/core";
import ButtonSection from "../../../library/components/old/button-section";
import {ProfilePicture} from "../../../library/components/old/profile-picture";
import {
    isSignInWithEmailLink,
    resendResetPasswordLink,
    resetPassword,
    verifyPasswordResetCode,
    verifyUserAccount
} from "./helpers";
import Field from "../../../library/components/old/field";
import {GlobalContext} from "../../global/global-context";
import {checkPasswordStrength} from "../sign-in/set-your-password";
import {DEFAULT_USER_PHOTO} from "../../../config/defaults";
import {FirebaseDB} from "../../../config/setup-firestore";
import {authFetch, externalAuthFetch} from "../../../config/network";
import {UserProfilePictureSelector} from "../../community/admin/member/components/user-photo-selector";

const Frame = styled.div`
  padding: 40px;
`;

const Container = styled.div`
  display: flex;
  flex-flow: column;
  align-items: center;
`;

const LoaderItem = () => {
    return <Frame>
        <InlineLoader/>
    </Frame>;
};

function FirstSignInHeader({
                               name, user_name, user_profile_picture,
                               changeProfilePicture = () => {
                               },
                               changeName = () => {
                               },
                           }) {

    const im = user_profile_picture ? user_profile_picture : DEFAULT_USER_PHOTO;
    return <div className="pb-4 w-full flex items-center flex-col">
        <div className="text-2xl font-semibold text-gray-800">Welcome to {name}</div>
        <div className="text-base text-gray-600">First, let's get your account ready.</div>

        <div className="w-full flex justify-center mt-5">
            <UserProfilePictureSelector updateParent={(a,b)=>changeProfilePicture(a,b)} profile_picture={im} text={user_name} />

        </div>
        <div className="w-full mt-4">
            <Field type={"text"} label="What should we call you?" onChange={(v) => {
                changeName(v)
            }} value={user_name}/>
        </div>
    </div>
}

export default class LinkPage extends Component {
    static contextType = GlobalContext;

    constructor(props) {
        super(props);
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);

        this.code = urlParams.get('oobCode');
        this.mode = urlParams.get('mode');
        this._email = urlParams.get('_email');
        // modes = resetPassword
        this._continueTo = urlParams.get('_continueTo');
        this._template = urlParams.get('_template');
        this._community = urlParams.get('_community');
        this.sent_id = urlParams.get('sent_id');
        this.email = '';

        this.state = {
            load_community: true,
            load_init: true,
            invalid: false,
            loading: false,
            community: {profile_picture: '', emoji: '', branding: {color: ''}},
            verify_error: false,

            resending_link: false,
            reset_link_sent: false,

            verified: false,

            error_code: '',

            user_name: '',

            user_profile_picture: '',
            user_profile_picture_color: '',

            password: ''
        };

        this.verifyPasswordResetCode = verifyPasswordResetCode.bind(this);
        this.resendResetPasswordLink = resendResetPasswordLink.bind(this);
        this.isSignInWithEmailLink = isSignInWithEmailLink.bind(this);
        this.verifyUserAccount = verifyUserAccount.bind(this);
        this.resetPassword = resetPassword.bind(this);
    }

    componentDidMount() {
        // Confirm the link is a sign-in with email link.

        if (this._community) {
            FirebaseDB
                .collection('communities')
                .doc(this._community)
                .get()
                .then((doc) => {
                    if (!doc.exists) {

                    } else {
                        this.setState({
                            community: doc.data(),
                            load_community: false
                        })
                    }
                })
                .catch(() => {

                });

            if (this._email) {
                const res = (resp) => {
                    if(resp&&resp.data&&resp.data.user) {
                        this.setState({
                            user_name: resp.data.user.name,
                            user_profile_picture: resp.data.user.profile_picture,
                            user_profile_picture_color: resp.data.user.profile_picture_color
                        })
                    }
                };

                const payload = {
                    email: this._email
                };

                externalAuthFetch("/get-user-info", res, res, "POST", {payload});
            }
        }
        if (this.code && this.mode === 'resetPassword') {
            this.verifyPasswordResetCode(this.code);
        }
        if (this.code && this.mode === 'signIn') {
            this.isSignInWithEmailLink();
        }
        if (this.code && this.mode === 'verifyEmail') {
            this.verifyUserAccount(this.code);
        }
    }

    hC(f, v) {
        this.setState({
            [f]: v
        })
    }

    render() {

        const mode = this.mode;
        const {
            password,
            user_name,
            user_profile_picture,
            loading,
            verify_error,
            reset_link_sent,
            resending_link,
            error_code,
            verified,
            community,
            load_community,
            load_init,
            invalid
        } = this.state;

        let contents = null;

        if (load_init || (this._community && load_community)) {
            contents = <LoaderItem/>;
        } else if (!this.code || invalid) {

            if (reset_link_sent) {
                contents = <AuthContainer>
                    <AuthTitle>Reset Password Email sent</AuthTitle>
                    <div className="prose prose-sm text-gray-700 pt-4">
                        If you don’t see an email from us within a few minutes, a few things might have happened:
                        <br/><br/>
                        - The email landing in your spam folder.<br/>
                        - The email address you entered had a mistake or typo. (Happens to everyone!)<br/>
                        - You accidentally gave us another email address. (Maybe a work or personal email?)<br/>
                        - We can’t deliver the email to this address. (Likely corporate firewalls or filtering.)
                    </div>
                </AuthContainer>;
            } else {
                switch (error_code) {
                    case 'auth/invalid-action-code':
                        contents = <AuthContainer>
                            <AuthTitle>Link has already been used</AuthTitle>
                            <div className="prose prose-sm text-gray-700 py-4">
                                To make sure your account is safe, we sent you a new link to {this._email} to reset your password.
                            </div>
                            <Button intent='secondary' loading={resending_link} fill size='large'
                                    onClick={this.resendResetPasswordLink} text='Get new Link'/>
                        </AuthContainer>;
                        break;
                    case 'auth/expired-action-code':
                        contents = <AuthContainer>
                            <AuthTitle>Link has expired</AuthTitle>
                            <div className="prose prose-sm text-gray-700 py-4">
                                To make sure your account is safe, we sent you a new link to {this._email} to reset your password.
                            </div>
                            <Button intent='secondary' loading={resending_link} fill size='large'
                                    onClick={this.resendResetPasswordLink} text='Get new Link'/>
                        </AuthContainer>;
                        break;
                    case 'auth/user-disabled':
                        contents = <AuthContainer>
                            <AuthTitle>Something went wrong</AuthTitle>
                            <div className="prose prose-sm text-gray-700 pt-4">
                                Try to <Link className='color-link' to='/sign-in'>sign in</Link> again or contact us
                                at <a className='color-link' href='mailto:hey@getunaty.com'>hey@getunaty.com</a> if you
                                need any help!
                            </div>
                        </AuthContainer>;
                        break;
                    case 'auth/user-not-found':
                        contents = <AuthContainer>
                            <AuthTitle>Something went wrong</AuthTitle>
                            <div className="prose prose-sm text-gray-700 pt-4">
                                Try to <Link className='color-link' to='/sign-in'>sign in</Link> again or contact us
                                at <a className='color-link' href='mailto:hey@getunaty.com'>hey@getunaty.com</a> if you
                                need any help!
                            </div>
                        </AuthContainer>;
                        break;
                    default:
                        contents = <AuthContainer>
                            <AuthTitle>Invalid Link</AuthTitle>
                            <Button intent='secondary' fill size='large' onClick={() => {
                                this.props.history.push('/sign-in')
                            }} text='Back to Sign In'/>
                        </AuthContainer>;
                        break;
                }
            }
        } else {

            let show_choose = (this._template && this._template === 'community-set-password');

            const show_email = (this._template && this._template === 'community-set-password') || mode === 'resetPassword';
            // contents depend on link
            if (mode === 'resetPassword') {
                let result = checkPasswordStrength(password);
                const em = !community ? '' : community.profile_picture === '' && community.emoji === '' ? '👋' : community.emoji;
                const img = community ? community.profile_picture : '';
                const color = community ? community.branding.color : '';
                const name = community ? community.name : '';
                contents = <AuthContainer>
                    <Container>
                        {community && show_choose && <FirstSignInHeader
                            user_name={user_name}
                            user_profile_picture={user_profile_picture}
                            name={name}
                            changeName={(n) => this.setState({user_name: n})}
                            changeProfilePicture={(pp, ppc) => {
                                console.log('changeProfilePicture',pp,ppc)
                                this.setState({
                                    user_profile_picture: pp,
                                    user_profile_picture_color: ppc
                                })
                            }}
                        />}
                        {community && !show_choose &&
                        <ProfilePicture hide_initials image={img} emoji={em} size='custom' width='96px' height='96px'
                                        text={name} color={color}/>}
                        {!this._template && <div className="mb-3 mt-2"><AuthTitle>Reset your Password</AuthTitle></div>}
                    </Container>

                    <div className="space-y-4">
                        {show_email && !show_choose && <Field type={"text"} disabled label="Email" onChange={() => {
                        }} value={this._email}/>}

                        <PasswordField autoFocus onChange={this.hC.bind(this, 'password')} score={result.score}
                                       value={password}/>

                        <ButtonSection className='auth-actions'>
                            <Button intent='secondary' fill size='large' disabled={result.score < 2}
                                    onClick={this.resetPassword}
                                    text={show_choose ? 'Set Password & Login' : 'Reset Password'}
                                    loading={loading}/>
                        </ButtonSection>
                    </div>

                    {show_choose&&<div className="mt-4 text-center text-xssm text-gray-600 space-y-0.5">
                        <div>You're creating an account for <span className="font-medium">{this._email}</span>.</div>
                        <div className="hidden">If you want to sign up with a different email, you can change it after signing up.
                        </div>
                    </div>}
                </AuthContainer>
            } else if (mode === 'signIn') {
                contents = <LoaderItem/>;
            } else {
                // verify
                if (verified) {
                    contents = <div>
                        your account has been verified
                    </div>
                } else if (verify_error) {
                    contents = <div>
                        Something went wrong, this link was no longer valid
                    </div>
                } else {
                    contents = <LoaderItem/>;
                }

            }
        }


        return (
            <SplashContainer>
                <SplashInner>
                    {contents}
                </SplashInner>
            </SplashContainer>
        );
    }
}