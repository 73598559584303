import {FirebaseDB} from "../../../config/setup-firestore";
import {getManyItemsAsync} from "../../utilities/get-many-items";
import {convertFromRaw} from 'draft-js';
import {aa_formatDate} from "../../utilities/general/dates";
import {authFetch} from "../../../config/network";
// messages/get-status

export async function getSendStatus(community_uid,post_id,mailgun_message_id,list_id) {
    return await new Promise((resolve, reject) => {
        const res = (data) => {
            resolve(data.data);
        };
        const payload = {
            community_uid: community_uid,
            list_id: list_id,
            message_id: mailgun_message_id
        };
        authFetch("/messages/get-status", res, res, "POST", {payload});
    })
}

export async function getPostDeliveryData(community_uid,post_id,mailgun_message_id) {
    let a = await FirebaseDB
        .collection('community_content')
        .doc(community_uid)
        .collection('emails')
        .doc(`${mailgun_message_id}`)
        .get()
        .then(doc=>{
            return doc.exists ? doc.data() : null;
        });

    return a;
}

async function getPostIds(community_uid,id,ref,page,per_page=25,type='member') {

    if(ref&&page>0) {
        return await FirebaseDB
            .collection('community_content')
            .doc(community_uid)
            .collection('inboxes')
            .doc(`${type}-${id}`)
            .collection('posts')
            .orderBy('ts', 'desc')
            .startAfter(ref)
            .limit(per_page)
            .get()
            .then(snap=>{
                return snap.docs;
            })
    }
    return await FirebaseDB
        .collection('community_content')
        .doc(community_uid)
        .collection('inboxes')
        .doc(`${type}-${id}`)
        .collection('posts')
        .orderBy('ts', 'desc')
        .limit(per_page)
        .get()
        .then(snap=>{
            return snap.docs;
        })
}

export async function getGroupInboxPosts(community_uid,group_id,series_id,member_id,ref,page,inbox,per_page=25) {
    const recent_posts_docs = await getPostIds(community_uid,group_id,ref,page,per_page,'group');

    const recent_posts = recent_posts_docs.map(d=>{
        const dt = d.data();
        return {
            ...dt,
            seen: !!dt.seen[member_id],
            id: d.id
        }
    });

    const last_ref = recent_posts.length>0 ? recent_posts_docs[recent_posts_docs.length-1] : null;

    const ids = recent_posts.map(rp=>rp.id);

    const {posts_data} = await getPostsData(ids,community_uid);

    return {posts: recent_posts.map(rp=>{
            return {
                ...rp,
                formatted_date: aa_formatDate(rp.ts),
                data: processFinalData(posts_data[rp.id])
            }
        }), last_ref}
}

export function parseRawContent(raw) {
    if(!raw) {
        return {};
    }

    if(typeof raw === 'string') {
        return JSON.parse(raw);
    }

    return raw;
}

function processFinalData(data) {
    // add content state
    let obj = {...data};
    if(data.raw) {
        obj.content_state = convertFromRaw(parseRawContent(data.raw));
    }
    return obj;
}

export async function getMemberInboxPosts(community_uid,member_id,ref,page,inbox,per_page=25,type="member") {
    const recent_posts_docs = await getPostIds(community_uid,member_id,ref,page,per_page,type);

    const recent_posts = recent_posts_docs.map(d=>{
        return {
            ...d.data(),
            id: d.id
        }
    });

    const last_ref = recent_posts.length > 0 ? recent_posts_docs[recent_posts_docs.length-1] : null;

    const ids = recent_posts.map(rp=>rp.id);

    const {posts_data} = await getPostsData(ids,community_uid);

    return {posts: recent_posts.map(rp=>{
        return {
            ...rp,
            formatted_date: aa_formatDate(rp.ts),
            data: processFinalData(posts_data[rp.id])
        }
    }), last_ref}
}

export async function getPostsData(ids,community_uid) {
    return await getManyItemsAsync("community_content.posts",ids,community_uid,true).then(result=>{
         let obj = {};
        result.forEach(d=>{
           obj[d.id] = d.data();
        });
        return {posts_data:obj};
    })
}