import React, {useState,useContext} from 'react';
import {AddEducationStep} from "../../../../library/components/reorganize/add-education-step";
import Modal from "../../../../library/components/old/modal";
import {Button} from "../../../../library/components/core";

import {DoubleCheckModal} from "../../../../library/components/old/double-check-modal";
import {HotkeyWrapper} from "../../../../library/components/old/hotkey-wrapper";
import {AddCareerStep} from "../../../../library/components/reorganize/add-career-step";

function shouldDisable(d) {
    if(d.title.length<2) {
        return true;
    }
    return false;
}

export function EditCareerModal({onEdit,onDelete,onClose,loading,data,id}) {
    const [modal,setModal] = useState(null);
    const [position,setPosition] = useState(data);

    function handleUpdateCareer(f,v) {
        let ed = Object.assign({},position);
        if(f==='range') {
            const final = {...ed,...v};
            setPosition({...final})
        } else if(f==='org_data') {
            ed[f]['title'] = v;
            setPosition({...ed});
        } else {
            ed[f] = v;
            setPosition(ed);
        }
    }

    function deleteEducation() {
        onDelete(id);
    }

    const disabled = shouldDisable(position);

    return <Modal title={"Edit Position"} size='base' body={<AddCareerStep position={position} updatePosition={handleUpdateCareer.bind(this)} />} footerRight={<div>
        <Button intent='success' loading={loading} disabled={disabled} onClick={()=>{
            onEdit(position,id);
        }} text='Save Changes' />
    </div>} footerLeft={<div>
        <Button intent='danger' onClick={()=>{
            setModal('double-check-delete');
        }} text='Delete Position' />
        {modal==='double-check-delete'&&<DoubleCheckModal onConfirm={()=>deleteEducation()} onClose={()=>setModal(null)} onCancel={()=>setModal(null)} type='career_delete' />}
    </div>}
                  onClose={onClose} open={true} lazy />
}