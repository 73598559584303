import React from 'react';
import ReactDOM from 'react-dom'
// import firebase
import './config/firebase-setup.js';

// import styled
// import components
import {NewApp} from "./app";

String.prototype.replaceAt = function(index, replacement) {
    return this.substr(0, index) + replacement + this.substr(index + replacement.length);
};

Array.prototype.unique = function() {
    let a = this.concat();
    for(let i=0; i<a.length; ++i) {
        for(let j=i+1; j<a.length; ++j) {
            if(a[i] === a[j])
                a.splice(j--, 1);
        }
    }

    return a;
};

ReactDOM.render(<NewApp />, document.getElementById("root"));