import React from "react";
import {Avatar} from "../../../core";
import {DEFAULT_USER_PHOTO} from "../../../../../config/defaults";
import {LanguageSwitcher} from "../language-switcher";

/*
 <div className="flex space-x-3 p-1.5 transition-colors rounded-md hover:bg-gray-100 cursor-pointer">
            <div className="flex-none h-14" >
                <Avatar size="xl" url={`${url}?alt=media`} />
            </div>
            <div className="flex items-center h-14">
                <div>
                <div className="text-gray-700 text-base font-bold">{title}</div>
                <div className="text-gray-500 text-sm font-medium">{subtitle}</div>
                </div>
            </div>
        </div>
 */
export function TopbarUserMenuHeader({url=DEFAULT_USER_PHOTO,title="Max Mustermann",subtitle="Member"}) {

    return <div className="pb-2 px-1.5 pt-0.5 w-full">

        <div className="flex pt-2 px-1.5">
            <div className="flex-none">
                <LanguageSwitcher />
            </div>
            <div className="flex-grow" />
            <div></div>
        </div>
    </div>
}