import React from 'react';
import classNames from 'classnames';
import {Card} from './index'
import styled from "styled-components";
import {PhoneIncomingIcon,DesktopComputerIcon} from '@heroicons/react/solid';
const props = {

};

const MT1 = styled.div`

margin-top: ${props=>props.last?'-1px':''};
`;

const IconBox = styled.div`

> svg {
    height: 80px;
}
`;

const CardIcon = styled.div`

> svg {
    height: 32px;
}
`;


const layouts = [
    "simple",
    "simple-table",
    "boxes",
    "stacked-cards",
    "large-icons"
];

function ItemStackedCards({value,selected,icon,label,desc,meta}) {
    const icon_color = selected ? "text-gray-500" : "text-gray-400";
    return <Card interactive selected={selected} fill>
        <div className="flex">
            {icon&&<CardIcon className={`flex ${icon_color} -ml-1 items-center justify-center flex-none w-12 pr-2`}>
                {icon}
            </CardIcon>}
        <div className="flex-1">
            <div className="font-medium text-base text-gray-800">{label}</div>
            <div className="text-sm text-gray-500">{desc}</div>
        </div>
        {meta&&<div className="flex-none justify-center flex flex-col text-sm text-gray-600">{meta}</div>}
        </div>
    </Card>
}

function ItemLargeIcon({label,icon,value,desc,selected}) {
    const text_class = selected ? "text-blue-400" : "text-gray-300";
    const border_class = selected ? "border-blue-400" : "border-gray-200";
    const bg_class = selected ? "" : "hover:border-blue-400 cursor-pointer";
    return <div className={classNames("flex-1 max-w-xs mt-none hover:opacity-90 cursor-pointer transition-opacity")}>
        <div className={classNames("border-2 p-8 shadow-sm rounded-md border-solid ",border_class,bg_class)}>
<IconBox className={classNames("flex items-center justify-center",text_class)}>
    {icon}
</IconBox>
        </div>
        <div className="font-medium text-sm text-center mt-2 text-gray-800">{label}</div>
    </div>
}

function ItemSimpleTable({label,value,desc,meta,first,last,selected}) {
    const text_class = selected ? "text-blue-700" : "text-gray-500";
    const border_class = selected ? "" : "";
    const bg_class = selected ? "bg-blue-50" : "hover:bg-gray-50 cursor-pointer";
    const border = selected ? "border border-solid border-blue-400" : "border border-solid border-gray-300";
    const border_radius = last ? "rounded-b-md" : first ? "rounded-t-md" : "";
    return <MT1 last={last} className={classNames("grid relative py-3 px-4 gap-2 grid-cols-12 overflow-overlay",selected?'z-10':'',border_radius,border,bg_class)}>
        <div  className="col-span-4 flex">
            <div className={`${selected?'text-blue-700':'text-blue-500'} col-span-1 flex items-center custom_radio`}>
                <input checked={selected} id={`radio_item_${value}`} value={value} type="radio"
                       className="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300"/>
                       <span className="inline-flex" />
            </div>
            <div className="font-medium flex items-center pl-2 text-base text-gray-800">{label}</div>
        </div>
        {desc&&<div className={`col-span-${meta?'5':'8'} justify-start flex items-center text-right  ${text_class}`}>{desc}</div>}
        {meta&&<div className={`col-span-${desc?'3':'8'} justify-end flex items-center text-right ${text_class}`}>{meta}</div>}
    </MT1>
}

function ItemSimple({value,label,selected}) {
    return <div className="flex items-center cursor-pointer">
        <input id={`radio_item_${value}`} value={value} type="radio"
               className="focus:ring-blue-500 h-4 w-4 text-blue-400 border-gray-300"/>
        <label htmlFor={`radio_item_${value}`} className="ml-3">
            <span className="block text-sm font-medium text-gray-700">{label}</span>
        </label>
    </div>
}

function ItemBoxes({label,value,desc,selected}) {
    return <div className="flex items-start cursor-pointer">
        <div className="flex items-center h-5">
            <input checked={selected} id={`radio_item_${value}`} type="checkbox"
                   className="focus:ring-blue-500 h-4 w-4 text-blue-400 border-gray-300 rounded"/>
        </div>
        <div className="ml-3 text-sm">
            <label htmlFor={`radio_item_${value}`} className="font-medium text-gray-700">{label}</label>
            {desc&&<p className="text-gray-500">{desc}</p>}
        </div>
    </div>
}

const opt = {
  value: "1234",
  label: "Offers",
    icon: <PhoneIncomingIcon/>,
    desc: "This is a real option for desc.",
    meta: <>
        <strong>$20</strong>
        <span>/mo</span>
    </>,
};

const opt2 = {
    value: "5234",
    label: "Values",
    icon: <DesktopComputerIcon/>,
    desc: "This is a real option for desc.",
    meta: null,
};

function ItemRenderer({layout,first,selected,last,data}) {
    switch(layout) {
        case 'simple': {
            return <ItemSimple {...data} last={last} first={first} selected={selected} />
        }
        case 'simple-table': {
            return <ItemSimpleTable {...data} last={last} first={first} selected={selected} />
        }
        case 'boxes': {
            return <ItemBoxes {...data} last={last} first={first} selected={selected} />
        }
        case 'stacked-cards': {
            return <ItemStackedCards {...data} last={last} first={first} selected={selected} />
        }
        case 'large-icons': {
            return <ItemLargeIcon {...data} last={last} first={first} selected={selected} />
        }
        default: {
            return <ItemSimple {...data} last={last} first={first} selected={selected} />
        }
    }
}

function getSpacing(layout) {
    switch(layout) {
        case 'simple': {
            return "space-y-2";
        }
        case 'simple-table': {
            return "";
        }
        case 'boxes': {
            return "space-y-2";
        }
        case 'stacked-cards': {
            return "space-y-4";
        }
        case 'large-icons': {
            return "";
        }
        default: {
            return "space-y-2";
        }
    }
}

// value is a single string or array depending on multi
export function Radio({options=[opt,opt2],onChange,value,multi=false,layout="simple"}) {
    let padding = layout === 'simple-table' ? "" : layout === 'large-icons' ? "space-x-4 flex" : "py-2";
    let spacing = getSpacing(layout);
    let look = layout === 'simple-table' ? 'overflow-hidden shadow-sm bg-white rounded-md': '';
    return <div className={classNames(padding,spacing,look,"")}>
        {options.map((opt,i)=><ItemRenderer first={i===0} last={(i+1)===options.length} selected={multi?value.indexOf(opt.value)!==-1:value===opt.value} layout={layout} data={opt} key={i} />)}
    </div>
}