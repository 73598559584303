import React, {useContext, useState} from "react";
import {GlobalContext} from "../../../../../global/global-context";
import {authFetch} from "../../../../../../config/network";
import {AdminMemberJoinedDate} from "../../meta/joined-date";

export function JoinDateBadge({member,community,hc=()=>{},member_id}) {
    const global = useContext(GlobalContext);
    const [tjd,setTjd] = useState(!member.join_date?{type:'',value:{season:null,date:null,month:null,year:null}}:JSON.parse(JSON.stringify(member.join_date)));

    function updateJoinDate(jd) {
        if(jd.type===tjd.type&&jd.value.season===tjd.value.season&&jd.value.month===tjd.value.month&&jd.value.year===tjd.value.year&&jd.value.date===tjd.value.date) {
            return;
        }

        hc('join_date', {...jd})
        setTjd({...jd});

        const payload = {
            join_date: jd,
            id: member_id,
            member_id: community.member_id,
            user_uid: community.member.user_uid,
            community_uid: community.uid
        };

        const res = () => {
            global.addToast({text: 'Changes saved', intent: 'success'});
        };

        authFetch("/members/set-join-date", res, res, "POST", {payload});
    }

    return <AdminMemberJoinedDate handleChange={(hjd) => {
    }} handleBlur={(hjd) => {
        updateJoinDate(hjd)
    }} member={member} join_date_init={tjd}/>
}