import React, {useContext, useState} from 'react';
import {CommunityContext} from "../../../app/community/community-context";
import {ActionWithIcon, Button, Field, TextArea} from "../core";
import {Popover2} from "@blueprintjs/popover2";
import {validateEmail} from "../../utilities/validation";
import {authFetch} from "../../../config/network";
import {convertToRaw} from "draft-js";
import {GlobalContext} from "../../../app/global/global-context";

export function ComposerSendTestEmail({
    subject, editorState
                                      }) {
    const community = useContext(CommunityContext);
    const global = useContext(GlobalContext);
    const [state,setState] = useState('');
    const [open,setOpen] = useState(false);

    const [recipients,setRecipients] = useState(`${community.member.account_email},`);

    const valid = recipients.split(',').filter(a=>validateEmail(a.trim())).map(a=>a.trim());

    const count = valid.length;

    function sendTestEmail() {
        setState('sending');

        const res = () => {
            setState('');
            setOpen(false);
            global.addToast({intent:'success',text:"Test Email sent"});
        };

        const payload = {
          subject,
            raw: convertToRaw(editorState.getCurrentContent()),
            member_id: community.member_id,
            community_uid: community.uid,
            recipients: {
              to: valid.join(',')
            }
        };

        authFetch("/posts/send-test-email", res, res, "POST", {payload});
    }

    const content = <div className="shadow-md space-y-2 bg-white py-2 px-3 rounded-md border-gray-200 border">
        <Field label="Recipients" help_text="Add up to 20 recipients emails separated by a comma">
            <TextArea onChange={v=>setRecipients(v)} value={recipients} />
        </Field>
        <div className="flex justify-end">
            <Button onClick={sendTestEmail} loading={state==='sending'} size="sm" disabled={!subject||valid.length===0} text={`Send Test Email`} intent="secondary" />
        </div>
    </div>

    return <Popover2 onOpen={()=>setOpen(true)} onClose={()=>setOpen(false)} isOpen={open} usePortal={false} placement='bottom-right' content={content} interactionKind='click' minimal
                     popoverClassName='minimal-popover mt-2'>
        <ActionWithIcon stopPropogation={false} text="Send Test Email" inverse onClick={()=>setOpen(true)} disabled={!subject||recipients.length===0}  />
    </Popover2>
}