import React from "react";
import {Breadcrumbs} from "../../../../library/components/old/breadcrumb";
import {PageHeader} from "../../../../library/components/old/page-header";
import {PageContainer} from "../../../../library/components/old/page-container";
import SecondaryEmails from "./secondary-emails";

export default function UserPreferencesNotifications(props) {

    const {history} = props;
    const nav = {
        can_go_back: false,
        history: props.history,
        breadcrumbs: [
            {
                label: 'Preferences',
                path: '/preferences'
            },
            {
                label: 'Notifications',
                path: '#'
            }
        ]
    };
    // keywords, muted words, push, email, sms
    return <PageContainer nav={nav}>
        <PageHeader title='Notifications' size='h2'/>
        <SecondaryEmails/>
    </PageContainer>
}