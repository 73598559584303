import React from "react";
import {} from "@heroicons/react/solid";

export const notification_types_ref = {
  'system': {
      color: 'bg-gray-600',
      size: '4'
  },
};

export const notification_types = {
    'member-assigned-to-role': {
        added: true,
        message: '**{member_name}** assigned you to the role [{name}](role={id})',
        type: 'role'
    },
    'member-assigned-to-role-self': {
        added: true,
        message: 'You assigned yourself to the role [{name}](role={id})',
        type: 'role'
    },

    'member-followed-invite': {
        added: true,
        message: '[{name}](member={id}) followed your invite and logged in',
        type: 'member'
    },
    'member-followed-data-check': {
        added: true,
        message: '[{name}](member={id}) completed the data check you sent',
        type: 'member'
    },

    'changed-member-type': {
        added: true,
        message: '{admin_name} changed your member type to **{label}**',
        type: 'member_type'
    },

    'made-moderator': {
        added: true,
        message: 'You became a Moderator of [{name}](group={id})',
        type: 'group'
    },

    'joined-group': {
        added: true,
        message: 'You joined [{name}](group={id})',
        type: 'group'
    },

    'invited-to-group': {
        added: true,
        message: 'You were invited to [{name}](group={id})',
        type: 'group'
    },

    'auto-added-to-group': {
        added: true,
        message: 'You were auto-added to [{name}](group={id})',
        type: 'group'
    },

    'invited-to-team': {
        added: true,
        message: 'You were invited to [{name}](group={id})',
        type: 'team'
    },

    'auto-added-to-team': {
        added: true,
        message: 'You were auto-added to [{name}](group={id})',
        type: 'team'
    }
};