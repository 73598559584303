import React, {useContext, useEffect, useState} from 'react';
import {FirebaseDB} from "../../../config/setup-firestore";
import {setDocumentTitle} from "../../../library/utilities/set-document-title";
import {CommunityContext} from "../community-context";
import {CommonLayoutProfile} from "../../../library/components/layout/page/profile";
import {ViewDraftJsContent} from "./view-content";
import {convertFromRaw,convertToRaw} from 'draft-js';
import {ActionWithIcon, Avatar, Button, EntityHeader, InlineLoader} from "../../../library/components/core";
import {aa_formatDate} from "../../../library/utilities/general/dates";
import {EntityInfo} from "../../../library/utilities/localstorage/entity-localstorage";
import {ArrowLeftIcon, ChevronDownIcon, ChevronUpIcon, DotsVerticalIcon} from "@heroicons/react/solid";
import {useHistory} from "react-router-dom";
import {authFetch} from "../../../config/network";
import {useIsMobile} from "../../global/global-router";
import {PostStatsDelivered} from "./stats/delivered";
import {PostAttachments} from "./post-attachments";
import {ComposeReply} from "./compose-reply"
import {RepliesContainer} from "./replies-container";
import {PostItemMeta} from "../../../library/components/posts-feed/feed-item";
import styled from "styled-components";
import {ViewPostLikes} from "../../../library/posts/view-post-likes";
import {StandardPageFramework, StandardPageHeight} from "../../../library/playground/standard-page-framework";
import {api_recordPostView} from "../../../api/community/posts/record-view";
import {SimplePageGridDivider} from "../group/simple-page-divider";
import {Popover2} from "@blueprintjs/popover2";
import {PopupMenu} from "../../../library/components/old/popup-menu";
import {ProfileBadge} from "../../../library/components/profile-badge";
import {PostEditWrapper} from "./edit-wrapper";
import {api_editPost} from "../../../api/community/posts/edit-post";
import {GlobalContext} from "../../global/global-context";
import {parseRawContent} from "../../../library/components/posts-feed/api";

const Grid = styled.div`
  grid-template-columns: 1fr 440px;
`;

function Stat({number = 0, onClick, label, label_plural}) {
    return <div onClick={() => {
        if (onClick) {
            onClick();
        }
    }} className={` text-sm`}>
        <span className="text-gray-800 font-medium pr-1">{number}</span>
        <span
            className={`text-gray-600 ${onClick ? "hover:underline cursor-pointer" : ""}`}>{number === 1 ? label : label_plural}</span>
    </div>
}

function PostStats({data, community_uid}) {
    const [view, setView] = useState(null);
    /*

      <div className="text-sm text-gray-600">
            <span>Last reply 3 days ago</span>
        </div>
     */
    const contributors = data.replies_members ? Object.keys(data.replies_members) : [];
    const liked_members = data.likes_members ? Object.keys(data.likes_members) : [];
    const cont_count = contributors.length;
    return <div className="shared-top-border flex space-x-5 py-2 px-1.5">
        <Stat onClick={() => setView('likes')} number={data.likes_count} label="Like" label_plural="Likes"/>
        <Stat number={data.replies_count} label="Reply" label_plural="Replies"/>
        {cont_count > 1 && <Stat number={cont_count} label="Contributor" label_plural="Contributors"/>}
        {view === 'likes' &&
        <ViewPostLikes community_uid={community_uid} member_ids={liked_members} onClose={() => setView(null)}/>}
    </div>
}

function Header({data, goToSeries, goToAuthor, is_mobile}) {

    return <div>
        <div className="mt-2">
            <Title is_mobile={is_mobile} title={data.subject}/>
        </div>
        {data.author_name && <div className="mt-0.5">
            <Author goToAuthor={goToAuthor} goToSeries={goToSeries} is_mobile={is_mobile} data={data}/>
        </div>}
    </div>
}

function Title({title, is_mobile}) {

    return <div className={`${is_mobile ? "text-lg" : "text-xl"} font-bold`}>
        {title}
    </div>
}

function SeriesName({data}) {

    return <span>{data.name}</span>
}

function Author({
                    is_mobile = false,
                    data,
                    goToSeries = () => {},
                    goToAuthor = () => {}
                }) {

    const {
        author_id,
        created_at = Date.now(),
        author_name,
        author_image,
        community_uid,

        series,
        series_name
    } = data;

    const has_series = !!series;
    // add series..
    return <div className="flex space-x-1.5 items-center">
        <div className="flex items-center space-x-1.5">
            <Avatar url={`${author_image}_small?alt=media`} size={"mini"}/>
            <div onClick={()=>goToAuthor()}
                className={`text-sm font-semibold text-gray-800 hover:underline cursor-pointer`}>{author_name}</div>
        </div>

        <div className="pt-px">
            <Date is_mobile={is_mobile} date={aa_formatDate(created_at)}/>
        </div>
        {has_series && <div>
            &nbsp;·&nbsp;
        </div>}
        {has_series && <div onClick={goToSeries}
                            className="text-sm pt-px hover:underline hover:text-gray-700 cursor-pointer text-gray-600">
            {series_name ? series_name :
                <EntityInfo community_uid={community_uid} type={"series"} id={series}><SeriesName/></EntityInfo>}
        </div>}
    </div>
}

function Date({date, is_mobile}) {
    return <div className={`text-sm   text-gray-600`}>
        on {date.full_date}
    </div>
}

function Content({data, can_edit, updateParentContent, editing, is_mobile}) {

    return <div className="mt-2">
        {editing && <PostEditWrapper updateParentContent={updateParentContent} data={data}/>}
        {!editing && <ViewDraftJsContent style={{}} classes='prose prose-base text-gray-800' content_state={data.content_state}/>}
    </div>
}

async function getRefs() {
    let refs = {};
}

function PostArrows({post_id}) {
    const [items, setItems] = useState(null);

    useEffect(function () {

    }, []);

    if (!items) {
        return null
    }

    return <div className="flex space-x-2">
        <div>
            <Button size="sm" minimal icon={<ChevronUpIcon/>}/>
        </div>
        <div>
            <Button size="sm" minimal icon={<ChevronDownIcon/>}/>
        </div>
    </div>
}


function Actions({post}) {
    let options = [];

    options.push({
        type: 'item',
        title: 'Copy as Markdown',
        onClick: () => {
        }
    });

    return <Popover2 content={<PopupMenu items={options}/>} placement={'bottom-start'} minimal
                     popoverClassName='minimal-popover'>
        <Button minimal icon={<div className="h-svg-5 pl-px"><DotsVerticalIcon/></div>}/>
    </Popover2>
}

function PostTopNavBar({data, actions, post_id, history, post, is_mobile, community}) {
    // data = group data
    const goToGroup = () => {
        const urlParams = new URLSearchParams(window.location.search);
        const ref = urlParams.get('ref');
        history.push(`/${community.data.handle}/group/${post.group_id}`)

    };

    const goBack = () => {
        const urlParams = new URLSearchParams(window.location.search);
        const ref = urlParams.get('ref');
        if (ref && ref === "home") {
            history.push(`/${community.data.handle}/home`)
        } else {
            history.push(`/${community.data.handle}/group/${post.group_id}`)
        }
    }
// <Actions post={post} />
    return <EntityHeader actions={actions} title="Message" single onClickSubtitle={goToGroup} goBackTo={goBack}/>
}

function Replies({
                     data,
                     post_id = ""
                 }) {

    const can_reply = true;

    return <div>
        {can_reply && <ComposeReply post_id={post_id} reply_to_id={""}/>}
        <RepliesContainer post_id={post_id} replies_count={data.replies_count ? data.replies_count : 0}/>

    </div>
}

export function CommunityPostPreview({
                                         editing, updateParentContent, can_edit, handleGoToSeries = () => {
    }, is_mobile, data, handleGoToAuthor=()=>{}
                                     }) {
    return <div>
        <Header goToAuthor={handleGoToAuthor} goToSeries={handleGoToSeries} is_mobile={is_mobile} data={data}/>
        <Content updateParentContent={updateParentContent} editing={editing} can_edit={can_edit} is_mobile={is_mobile}
                 data={data}/>
    </div>
}

function EditingButton({editing, onToggle}) {
    if (editing) {
        return <Button onClick={onToggle} text="Save Changes" intent="success"/>
    }
    return <Button onClick={onToggle} text="Edit"/>
}

export function CommunityPost(props) {
    const global = useContext(GlobalContext);
    const community = useContext(CommunityContext);
    const history = useHistory();
    const is_mobile = useIsMobile();
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(null);
    const [editing, setEditing] = useState(false);
    const [error, setError] = useState(null);

    useEffect(function () {
        api_recordPostView(community, props.match.params.postId)
            .then(() => {
                // view recorded..
            });
    }, []);

    useEffect(function () {
        let listener = () => {
        };
        if (props.match.params.postId) {
            listener = FirebaseDB
                .collection('community_content')
                .doc(community.uid)
                .collection('posts')
                .doc(props.match.params.postId)
                .onSnapshot(doc => handlePost(doc))
        }
        return () => listener();
    }, [props.match.params.postId]);

    function handlePost(doc) {
        if (!doc.exists) {
            setError('not-found');
            setLoading(null);
        } else {
            let document = doc.data();
            document.id = doc.id;
            setDocumentTitle(`${document.author_name} posted "${document.subject}"`, community.data.name);
            setLoading(null);
            setData({
                ...document,
                author_image: document.author_image ? document.author_image : community.default_member_profile_picture,
                content_state: convertFromRaw(parseRawContent(document.raw))
            });
            checkIfUnread(document.id);
        }
    }

    function checkIfUnread(id) {
        FirebaseDB
            .collection('community_content')
            .doc(community.uid)
            .collection('inboxes')
            .doc(`member-${community.member_id}`)
            .collection('posts')
            .doc(id)
            .get()
            .then(doc => {
                if (doc.exists) {
                    if (!doc.data().seen) {
                        markPostAsSeen(id);
                    }
                }
            })
    }

    function markPostAsSeen(id) {
        const res = () => {
            console.log('mark as seen..')
        };
        const payload = {
            community_uid: community.uid,
            member_id: community.member_id,
            post_id: id
        };
        authFetch("/posts/mark-as-seen", res, res, "POST", {payload});
    }

    function handleGoToAuthor() {
        if (data && data.author_id) {
            history.push(`/${community.handle}/member-id/${data.author_id}`);
        }
    }

    function handleGoToSeries() {
        if (data && data.series) {
            history.push(`/${community.handle}/group/${data.group_id}/series/${data.series}`);
        }
    }

    function updateParentContent(cs) {
        setData({
            ...data,
            raw: convertToRaw(cs),
            plain_text: cs.getPlainText(),
            content_state: cs
        });
    }

    function handleToggle() {
        if (editing) {
            setEditing(false);
            global.addToast({text: "Saving changes"});
            api_editPost(community, {
                raw: data.raw,
                id: props.match.params.postId
            })
                .then(() => {
                    global.addToast({text: "Changes saved"});
                })
        } else {
            setEditing(true);
        }
    }

    if (!data) {
        return <CommonLayoutProfile>
            <div>
                <InlineLoader/>
            </div>
        </CommonLayoutProfile>
    }

    const can_edit = community.is_admin || data.author_id === community.member_id;

    const topbar = <div>
        {data.context_id && data.context_type === 'group' && community.uid && typeof data.context_id === 'string' &&
        <EntityInfo community_uid={community.uid} id={data.context_id} type={'groups'}>
            <PostTopNavBar actions={can_edit ? <EditingButton editing={editing} onToggle={handleToggle}/> : null}
                           post_id={props.match.params.postId} history={history} post={data} is_mobile={is_mobile}
                           community={community}/>
        </EntityInfo>}
    </div>;

    const sidebar = <div className="space-y-4 sticky top-0">
        {data.attachments.length > 0 && <PostAttachments group_id={data.group_id} attachments={data.attachments}/>}

        {data && <PostStatsDelivered list_id={""} community_uid={community.uid} post={data}/>}
    </div>

    let sb = <div
        className="block space-y-4">
        {sidebar}
    </div>;
    return <StandardPageFramework sidebar={sb} content>
        {topbar}
        <div className="pt-1 pb-12">
            <div className="">
                <div className="px-4">
                <CommunityPostPreview updateParentContent={updateParentContent} editing={editing} can_edit={can_edit}
                                      handleGoToAuthor={handleGoToAuthor}
                                      handleGoToSeries={handleGoToSeries} is_mobile={is_mobile} data={data}/>
                </div>
                <div className="pt-1 px-4">
                    <PostStats community_uid={community.uid} data={data}/>
                </div>
                <div className="shared-top-border pt-1 pb-1 px-4">
                    <PostItemMeta community={community} post_id={props.match.params.postId}
                                  likes_members={data.likes_members}
                                  likes_count={data.likes_count} layout="post"
                                  replies_count={data.replies_count} can_reply={false}/>
                </div>

            </div>
            <div>
                <Replies data={data} post_id={props.match.params.postId} group_id={data.group_id}/>
            </div>
        </div>
    </StandardPageFramework>
}