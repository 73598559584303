import React, {useContext, useState} from 'react';
import {Button, Field, Input, TextAndEmoji, TextArea} from "../../../library/components/core";
import {TimezoneSwitcher} from "../../../library/components/timezone-switcher";
import {timezones_obj} from "../../../library/components/timezone-switcher/data";
import {getCurrentTimezone} from "../../../library/utilities/general";
import {CommunityContext} from "../community-context";
import {api_createEvent} from "../../../api/community/events/create";
import {api_createInterest} from "../../../api/community/interests/create";
import Modal from "../../../library/components/old/modal";
import {validateEmail} from "../../../library/utilities/validation";
import {EntityIconSelector} from "../../../library/components/core/entity-icon-selector";

export function CreateCustomInterest({
                                         onCancel = () => {
                                         },
                                         onConfirm = () => {
                                         },
                                     }) {
    const community = useContext(CommunityContext);
    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const [loading, setLoading] = useState("");
    const [emoji, setEmoji] = useState("");
    const [color, setColor] = useState("");
    const [profile_picture, setProfilePicture] = useState("");
    const [state, setState] = useState("");

    function createInterest() {
        setLoading('interest')
        const payload = {
            name,
            color,
            profile_picture,
            description,
            emoji
        };
        api_createInterest(community, payload)
            .then(id => {
                setLoading('')
                onConfirm(id);
            })
    }

    const content = <div className="space-y-4">

        <EntityIconSelector profile_picture={profile_picture} color={color} onChange={(n, t, e, m) => {
            if (t === 'emoji') {
                setEmoji(e);
                setProfilePicture("");
                setColor("");
                setName(n);
            } else if (t === 'profile_picture') {
                setEmoji("");
                setProfilePicture(e);
                setColor("");
                setName(n);
            } else if (t === 'name') {
                setName(n);
            } else {
                setEmoji("");
                setProfilePicture("");
                setColor(e);
                setName(n);
            }
        }} label="Name" emoji={emoji} value={name} placeholder="Name"/>

        <Field label="Description">
            <TextArea placeholder="Explain.." onChange={v => setDescription(v)} value={description}/>
        </Field>
    </div>;

    return <Modal title={"Create Custom Interest"} size='small' body={content} footerRight={<div>
        <Button intent='secondary' loading={loading} disabled={!name} onClick={() => createInterest()}
                text='Create'/>
    </div>} footerLeft={null}
                  onClose={onCancel} open={true} lazy/>
}