import React, {useEffect, useContext, useState} from 'react';
import {CommunityContext} from "./community-context";
import {FirebaseDB} from "../../config/setup-firestore";
import {GlobalContext} from "../global/global-context";
import {FullScreenLoader} from "../../library/components/old/full-screen-loader";
import {authFetch} from "../../config/network";
import {DEFAULT_COVER_PHOTO, DEFAULT_USER_PHOTO} from "../../config/defaults";
import {setFavicon} from "../../library/utilities/set-document-title";
import {getManyItems} from "../../library/utilities/get-many-items";
import {AppFrameLoading} from "../../library/playground/app-frame-loading";
import {useHistory} from "react-router-dom";

const default_value = {
    data: null,
    handle: '',
    default_member_profile_picture: DEFAULT_USER_PHOTO,
    default_member_cover_photo: DEFAULT_COVER_PHOTO,
    not_found: false,
    loading: false,
    uid: ''
};

const mock_access = {
    community: false,
    all_roles: false,
    admins: true,
    teams: {},
    members: {},
    roles: {},
    member_types: {}
};

function getAccess(community,arr) {
    let final =  {};

    arr.forEach(a=>{
        if(community && community.access && community.access[a]) {
            final[a] = community.access[a]
        } else {
            final[a] = {...mock_access}
        }
    })

    return final;
}

function memberIsInTeam(team,member_id) {
   return team.user_uids[member_id];
}

function getMemberAccess(community_access, is_admin, all_roles, all_teams, member_types, member_id, member) {
    let permissions = {
        add_members: false,
        add_segments: false,
        create_spaces: true,
        manage_roles: false,
        manage_members: false
    };

    if(!member) {
        return permissions;
    }

    if (is_admin) {
        permissions.add_members = true;
        permissions.manage_roles = true;
        permissions.create_spaces = true;
        permissions.add_segments = true;
        permissions.manage_members = true;
    }

    if (community_access?.add_members?.community) {
        permissions.add_members = true;
    }

    if (community_access?.manage_roles?.community) {
        permissions.manage_roles = true;
    }

    if (!community_access?.create_spaces?.community) {
        permissions.create_spaces = false;
    }

    if (community_access?.manage_members?.community) {
        permissions.manage_members = true;
    }

    if (community_access?.add_segments?.community) {
        permissions.add_segments = true;
    }

    // teams
    const member_teams = Object.entries(all_teams).filter(a=>memberIsInTeam(a[1],member_id,all_roles))

    member_teams.forEach(a=>{
        if (community_access?.add_members?.teams?.[a[0]]) {
            permissions.add_members = true;
        }
        if (community_access?.manage_roles?.teams?.[a[0]]) {
            permissions.manage_roles = true;
        }
        if (community_access?.create_spaces?.teams?.[a[0]]) {
            permissions.create_spaces = true;
        }
        if (community_access?.add_segments?.teams?.[a[0]]) {
            permissions.add_segments = true;
        }
        if (community_access?.manage_members?.teams?.[a[0]]) {
            permissions.manage_members = true;
        }
    });

    // roles
    const member_roles = Object.entries(all_roles).filter(a=>{
        return a[1].assignee === member_id
    });

    if(member_roles.length>0) {
        if (community_access?.add_members?.all_roles) {
            permissions.add_members = true;
        }
        if (community_access?.add_segments?.all_roles) {
            permissions.add_segments = true;
        }
        if (community_access?.manage_roles?.all_roles) {
            permissions.manage_roles = true;
        }
        if (community_access?.create_spaces?.all_roles) {
            permissions.create_spaces = true;
        }
        if (community_access?.manage_members?.all_roles) {
            permissions.manage_members = true;
        }
    }

    member_roles.forEach(a=>{
        if (community_access?.add_members?.roles?.[a[0]]) {
            permissions.add_members = true;
        }
        if (community_access?.add_segments?.roles?.[a[0]]) {
            permissions.add_segments = true;
        }
        if (community_access?.manage_roles?.roles?.[a[0]]) {
            permissions.manage_roles = true;
        }
        if (community_access?.create_spaces?.roles?.[a[0]]) {
            permissions.create_spaces = true;
        }
        if (community_access?.manage_members?.roles?.[a[0]]) {
            permissions.manage_members = true;
        }
    });

    // member types
    if (community_access?.add_members?.member_types?.[member.member_type]) {
        permissions.add_members = true;
    }
    if (community_access?.add_segments?.member_types?.[member.member_type]) {
        permissions.add_segments = true;
    }
    if (community_access?.manage_roles?.member_types?.[member.member_type]) {
        permissions.manage_roles = true;
    }
    if (community_access?.create_spaces?.member_types?.[member.member_type]) {
        permissions.create_spaces = true;
    }
    if (community_access?.manage_members?.member_types?.[member.member_type]) {
        permissions.manage_members = true;
    }

    return permissions;
}

export function CommunityProvider({children, community_uid}) {
    const history = useHistory();
    const [community, setCommunity] = useState(default_value);
    const [admins, setAdmins] = useState([]);
    const [community_emojis, setCommunityEmojis] = useState(null);
    const [admin_data, setAdminData] = useState(null);
    const [starred_smart_views, setSmartViews] = useState({});
    const [interests, setInterests] = useState({});
    const [member_id, setMemberId] = useState('');
    const [is_member, setIsMember] = useState(false);
    const [member, setMember] = useState(null);
    const [important_custom_fields, setICF] = useState([]);
    const [member_types, setMemberTypes] = useState(null);
    const [policies, setPolicies] = useState({});
    const [all_teams, setAllTeams] = useState({});
    const [my_groups, setMyGroups] = useState({});
    const [all_roles, setAllRoles] = useState({});
    const [custom_fields, setCustomFields] = useState(null);
    const [is_owner, setIsOwner] = useState(false);
    const [is_admin, setIsAdmin] = useState(false);
    const [open_composer, setOpenComposer] = useState(null);
    const [entities, setEntities] = useState({teams: [], roles: [], events: [], groups: []});
    const global = useContext(GlobalContext);

    // this is the complex one, need to sub the community first and see if I'm a member/admin and others

    useEffect(function () {
        if (member_id) {
            const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
            const payload = {
                community_uid,
                member_id,
                timezone: tz
            };
            authFetch('/members/record-sign-in', () => {
            }, () => {
            }, 'POST', {payload});
        }
    }, [member_id]);

    function handlePolicies(snap) {
        let obj = {};
        snap.forEach(a => {
            obj[a.id] = a.data()
        });
        setPolicies(obj)
    }

    function handleCommunityDoc(doc) {
        if (!doc.exists) {
            setCommunity({
                ...default_value,
                loading: false,
                not_found: true
            });
            setICF([]);
        } else {
            const data = doc.data();
            setCommunity({
                ...community,
                loading: false,
                not_found: false,
                default_member_profile_picture: data.design_defaults && data.design_defaults.member_profile_picture ? data.design_defaults.member_profile_picture : DEFAULT_USER_PHOTO,
                default_member_cover_photo: data.design_defaults && data.design_defaults.member_cover_photo ? data.design_defaults.member_cover_photo : DEFAULT_COVER_PHOTO,
                data: data,
                uid: community_uid,
                handle: data.handle
            });
            setICF(data.important_custom_fields ? data.important_custom_fields : []);
            setIsOwner(data.plan.account_owner === global.member_ids[community_uid]);
            setFavicon((data.profile_picture ? `${data.profile_picture}_small?alt=media` : ''));
        }
    }

    function checkOnboardingHook(dt) {
        const loc = window.location.pathname;
        const qp = new URLSearchParams(window.location.search);
        if(!community||!community.data) {
            console.log("NO COMMUNITY");
            return;
        }
        if(qp.get('onboardRedirect') === '1') {
            history.push(`/${community.data.handle}/onboarding?flow=member`);
            return;
        }
        if(!dt||!dt.about || !dt.stats) {
            console.log("NO DATA");
            return;
        }
        if((!dt.last_sign_in||dt.stats.sessions<3)&&(!dt.profile_picture&&!dt.about.location&&!dt.about.occupation)) {
            if(!loc.includes('/onboarding?flow=member')) {
                history.push(`/${community.data.handle}/onboarding?flow=member`);
            }
        }
    }

    function gotMemberHook(dt) {
        const ms = dt.starred ? Object.keys(dt.starred) : [];

        // .filter(b=>!starred_smart_views[b.split('__')[1]]) -> take all smart views in case a na
        const get_smart_view_ids = ms.filter(a=>a.startsWith("smart-view__")).map(c=>c.split('__')[1]);

        if(get_smart_view_ids.length>0) {
            getManyItems('community_entities.smart_views',get_smart_view_ids,(res)=>{
                let new_smart_view = {...starred_smart_views};
                res.forEach(a=>{
                    new_smart_view[a.id] = a;
                })
                setSmartViews(new_smart_view);
            }, community_uid);
        }
    }

    useEffect(function () {
        const is_member_tag = !!global.member_ids[community_uid];

        setIsMember(is_member_tag);
        const community_member_id = global.member_ids[community_uid] ? global.member_ids[community_uid] : '';
        setMemberId(community_member_id);

        setCommunity({
            ...community,
            loading: true
        });

        const mm_id = community_member_id;

        let admins_sub = () => {};
        let entities_sub = () => {};
        let custom_fields = () => {};
        let policies_sub = () => {};
        let member_types_sub = () => {};
        let member_sub = () => {};
        let my_groups_sub = () => {};
        let all_teams_sub = () => {};
        let all_roles_sub = () => {};
        let community_sub = () => {};

        community_sub = FirebaseDB
            .collection('communities')
            .doc(community_uid)
            .onSnapshot(handleCommunityDoc);
        if (is_member_tag) {
            // get emojis
            FirebaseDB
                .collection('communities')
                .doc(community_uid)
                .collection('emojis')
                .limit(100)
                .get()
                .then(snap => {
                    const docs = snap.docs;
                    let obj = {};
                    for (let i = 0; i < docs.length; i++) {
                        const data = docs[i].data();
                        obj[data.handle] = data;
                    }
                    setCommunityEmojis(obj);
                });

            policies_sub = FirebaseDB
                .collection('communities')
                .doc(community_uid)
                .collection('policies')
                .where('status', '==', 'published')
                .onSnapshot(handlePolicies);

            admins_sub = FirebaseDB
                .collection('community_admins')
                .doc(community_uid)
                .onSnapshot((doc) => {
                    const d = doc.data();
                    const {all_user_uids} = d;
                    setAdminData(d);
                    setAdmins(Object.keys(all_user_uids));
                    setIsAdmin(all_user_uids[global.member_ids[community_uid]]);
                });

            entities_sub = FirebaseDB
                .collection('community_members')
                .doc(community_uid)
                .collection('member_relationships')
                .doc(global.member_ids[community_uid])
                .onSnapshot((doc) => {
                    const data = doc.data();
                    const {teams = {}, groups = {}, roles = {}} = data;
                    setEntities({teams: Object.keys(teams), groups: Object.keys(groups), roles: Object.keys(roles)});
                });

            member_sub = FirebaseDB
                .collection('community_members')
                .doc(community_uid)
                .collection('members')
                .doc(global.member_ids[community_uid])
                .onSnapshot((doc) => {
                    const data = doc.data();
                    setMember(data);
                    setInterests(data.interests ? data.interests : {});
                    gotMemberHook(data);
                    checkOnboardingHook(data);
                });

            custom_fields = FirebaseDB
                .collection('community_members')
                .doc(community_uid)
                .collection('profile_sections')
                .onSnapshot((snap) => {
                    let data = {};
                    for (let i = 0; i < snap.docs.length; i++) {
                        const doc = snap.docs[i];
                        data[doc.id] = doc.data();
                    }
                    setCustomFields(data);
                });

            all_teams_sub = FirebaseDB
                .collection('community_entities')
                .doc(community_uid)
                .collection('groups')
                .where('display_leaders', '==', true)
                .onSnapshot((snap) => {
                    let data = {};
                    for (let i = 0; i < snap.docs.length; i++) {
                        const doc = snap.docs[i];
                        data[doc.id] = doc.data();
                    }
                    setAllTeams(data);
                });

            my_groups_sub = FirebaseDB
                .collection('community_entities')
                .doc(community_uid)
                .collection('groups')
                .where(`user_uids.${mm_id}`, '==', true)
                .onSnapshot((snap) => {
                    let data = {};
                    for (let i = 0; i < snap.docs.length; i++) {
                        const doc = snap.docs[i];
                        data[doc.id] = doc.data();
                    }
                    setMyGroups(data);
                });

            all_roles_sub = FirebaseDB
                .collection('community_entities')
                .doc(community_uid)
                .collection('roles')
                .onSnapshot((snap) => {
                    let data = {};
                    for (let i = 0; i < snap.docs.length; i++) {
                        const doc = snap.docs[i];
                        data[doc.id] = doc.data();
                    }
                    setAllRoles(data);
                });


            member_types_sub = FirebaseDB
                .collection('community_members')
                .doc(community_uid)
                .collection('member_types')
                .onSnapshot((snap) => {
                    let data = {};
                    for (let i = 0; i < snap.docs.length; i++) {
                        const doc = snap.docs[i];
                        data[doc.id] = doc.data();
                    }
                    setMemberTypes(data);
                });
        }
        return () => {
            // update favicon
            setFavicon("/favicon-32x32.png");
            admins_sub();
            custom_fields();
            policies_sub();
            all_teams_sub();
            my_groups_sub();
            all_roles_sub();
            member_types_sub();
            member_sub();
            entities_sub();
            community_sub();
        };
    }, [community.uid]);

    function handleUpdateCommunity() {
        FirebaseDB
            .collection('communities')
            .doc(community_uid)
            .get()
            .then(handleCommunityDoc);
    }

    function handleOpenComposer(p) {
        setOpenComposer(p);
    }

    function handleCloseComposer() {
        setOpenComposer(null);
    }

    if (community.loading) {
        return <AppFrameLoading community={community&&community.data?community.data:null} />
    }

    if (community.not_found) {
        return <AppFrameLoading community={community&&community.data?community.data:null}/>
    }

    const is_ready = (!is_member && community.data !== null) || (is_member && community.data !== null && member_types !== null && custom_fields !== null && member !== null && admins.length !== 0);

    if (!is_ready) {
        return <AppFrameLoading community={community&&community.data?community.data:null} />
    }

    const community_access = getAccess(community.data,['add_members','manage_members', 'create_spaces', 'add_segments','manage_roles']);
    const member_access = getMemberAccess(community_access, is_admin, all_roles, all_teams, member_types, member_id, member);

    return <CommunityContext.Provider value={{
        ...community,
        updateCommunity: handleUpdateCommunity,
        important_custom_fields,
        open_composer,
        closeComposer: handleCloseComposer,
        openComposer: handleOpenComposer,
        updateMember: (m2) => {
            setMember({...member,...m2});
        },
        updateCommunityEmojis: (em) => {
            let obj = {};
            for (let i = 0; i < em.length; i++) {
                const data = em[i];
                obj[data.handle] = data;
            }
            setCommunityEmojis(obj);
        },
        interests,
        entities,
        admin_data,
        starred_smart_views,
        all_teams,
        my_groups,
        access: community_access,
        member_access,
        member_id,
        is_owner,
        all_roles,
        community_emojis,
        policies,
        admins,
        is_member,
        is_admin,
        member_types,
        custom_fields,
        member
    }}>
        {children}
    </CommunityContext.Provider>
}