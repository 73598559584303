import React, {useState, useEffect, useContext} from 'react';
import {SectionHeader} from "../../../../library/components/core";

import {GlobalContext} from "../../../global/global-context";
import {CommunityContext} from "../../community-context";
import {FirebaseDB} from "../../../../config/setup-firestore";
import {useHistory} from "react-router-dom";

function Item({label, stat, onClick}) {

    return <div onClick={()=>{
    if(onClick) {
        onClick();
    }
    }} className={`${onClick ? "cursor-pointer hover:opacity-70" : ""} transition-opacity`}>
        <div className="text-xl text-gray-800 font-bold">{stat}</div>
        <div className="text-xs text-gray-500">{label}</div>
    </div>
}

function buildStats(group, group_id, insights, community, history) {

    const gi = insights.groups[group_id];
    let a = [];

    if (gi) {
        a.push({
            label: "Member Changes (30 days)",
            stat: `${gi.members_count_delta > 0 ? `+${gi.members_count_delta}` : gi.members_count_delta}`
        });
    }

    a.push({
        onClick: () => {
            history.push(`/${community.data.handle}/group/${group_id}/directory`);
        },
        label: "Total Members",
        stat: Object.keys(group.user_uids).length
    });

    if (gi) {
        a.push({
            onClick: () => {
                history.push(`/${community.data.handle}/group/${group_id}/directory`);
            },
            label: "Logged in",
            stat: gi.logged_in_count
        });

        a.push({
            onClick: () => {
                history.push(`/${community.data.handle}/group/${group_id}/directory`);
            },
            label: "Profile Complete",
            stat: gi.profile_complete_count
        });
    }

    return a;
}

export default function GroupStatsWidget({group_id, group, community_uid}) {
    const [data, setData] = useState(null);
    const history = useHistory();
    const community = useContext(CommunityContext);
    const global = useContext(GlobalContext);

    useEffect(function () {
        getData(group_id, community.uid);
    }, [community.uid, group_id]);

    function getData(id, cid) {
        FirebaseDB
            .collection('communities')
            .doc(community.uid)
            .collection('insights')
            .orderBy('created_at', 'desc')
            .limit(1)
            .get()
            .then((snap) => {
                if (snap.empty) {
                    setData(null)
                }
                setData({...snap.docs[0].data()});
            })
    }

    if (!data) {
        return null;
    }

    const stats = buildStats(group, group_id, data, community, history);

    return <div>
        <SectionHeader title="Stats"/>
        <div className="gap-2 grid grid-cols-2 px-2.5 pb-1">
            {stats.map((stat, i) => {
                return <Item key={i} {...stat} />
            })}
        </div>

    </div>
}