export const country_demonyms = [
    {
        "demonym": "Aruban",
        "country": "aw"
    },
    {
        "demonym": "Afghan",
        "country": "af"
    },
    {
        "demonym": "Angolan",
        "country": "ao"
    },
    {
        "demonym": "Anguillian",
        "country": "ai"
    },
    {
        "demonym": "Ålandish",
        "country": "ax"
    },
    {
        "demonym": "Albanian",
        "country": "al"
    },
    {
        "demonym": "Andorran",
        "country": "ad"
    },
    {
        "demonym": "Emirati",
        "country": "ae"
    },
    {
        "demonym": "Argentine",
        "country": "ar"
    },
    {
        "demonym": "Armenian",
        "country": "am"
    },
    {
        "demonym": "American Samoan",
        "country": "as"
    },
    {
        "demonym": "Antarctican",
        "country": "aq"
    },
    {
        "demonym": "French",
        "country": "fr"
    },
    {
        "demonym": "Antiguan, Barbudan",
        "country": "ag"
    },
    {
        "demonym": "Australian",
        "country": "au"
    },
    {
        "demonym": "Austrian",
        "country": "at"
    },
    {
        "demonym": "Azerbaijani",
        "country": "az"
    },
    {
        "demonym": "Burundian",
        "country": "bi"
    },
    {
        "demonym": "Belgian",
        "country": "be"
    },
    {
        "demonym": "Beninese",
        "country": "bj"
    },
    {
        "demonym": "Burkinabe",
        "country": "bf"
    },
    {
        "demonym": "Bangladeshi",
        "country": "bd"
    },
    {
        "demonym": "Bulgarian",
        "country": "bg"
    },
    {
        "demonym": "Bahraini",
        "country": "bh"
    },
    {
        "demonym": "Bahamian",
        "country": "bs"
    },
    {
        "demonym": "Bosnian, Herzegovinian",
        "country": "ba"
    },
    {
        "demonym": "Saint Barthélemy Islander",
        "country": "bl"
    },
    {
        "demonym": "Saint Helenian",
        "country": "sh"
    },
    {
        "demonym": "Belarusian",
        "country": "by"
    },
    {
        "demonym": "Belizean",
        "country": "bz"
    },
    {
        "demonym": "Bermudian",
        "country": "bm"
    },
    {
        "demonym": "Bolivian",
        "country": "bo"
    },
    {
        "demonym": "Dutch",
        "country": "nl"
    },
    {
        "demonym": "Brazilian",
        "country": "br"
    },
    {
        "demonym": "Barbadian",
        "country": "bb"
    },
    {
        "demonym": "Bhutanese",
        "country": "bt"
    },
    {
        "demonym": "Motswana",
        "country": "bw"
    },
    {
        "demonym": "Central African",
        "country": "cf"
    },
    {
        "demonym": "Canadian",
        "country": "ca"
    },
    {
        "demonym": "Cocos Islander",
        "country": "cc"
    },
    {
        "demonym": "Swiss",
        "country": "ch"
    },
    {
        "demonym": "Chilean",
        "country": "cl"
    },
    {
        "demonym": "Chinese",
        "country": "cn"
    },
    {
        "demonym": "Cameroonian",
        "country": "cm"
    },
    {
        "demonym": "Cook Islander",
        "country": "ck"
    },
    {
        "demonym": "Colombian",
        "country": "co"
    },
    {
        "demonym": "Comoran",
        "country": "km"
    },
    {
        "demonym": "Cape Verdian",
        "country": "cv"
    },
    {
        "demonym": "Costa Rican",
        "country": "cr"
    },
    {
        "demonym": "Cuban",
        "country": "cu"
    },
    {
        "demonym": "Curaçaoan",
        "country": "cw"
    },
    {
        "demonym": "Christmas Islander",
        "country": "cx"
    },
    {
        "demonym": "Caymanian",
        "country": "ky"
    },
    {
        "demonym": "Cypriot",
        "country": "cy"
    },
    {
        "demonym": "Czech",
        "country": "cz"
    },
    {
        "demonym": "German",
        "country": "de"
    },
    {
        "demonym": "Djibouti",
        "country": "cj"
    },
    {
        "demonym": "Dominican",
        "country": "dm"
    },
    {
        "demonym": "Danish",
        "country": "dk"
    },
    {
        "demonym": "Dominican",
        "country": "do"
    },
    {
        "demonym": "Algerian",
        "country": "dz"
    },
    {
        "demonym": "Ecuadorean",
        "country": "ec"
    },
    {
        "demonym": "Egyptian",
        "country": "eg"
    },
    {
        "demonym": "Eritrean",
        "country": "er"
    },
    {
        "demonym": "Sahrawi",
        "country": "eh"
    },
    {
        "demonym": "Spanish",
        "country": "es"
    },
    {
        "demonym": "Estonian",
        "country": "ee"
    },
    {
        "demonym": "Ethiopian",
        "country": "et"
    },
    {
        "demonym": "Finnish",
        "country": "fi"
    },
    {
        "demonym": "Fijian",
        "country": "fj"
    },
    {
        "demonym": "Falkland Islander",
        "country": "fi"
    },
    {
        "demonym": "French",
        "country": "fr"
    },
    {
        "demonym": "Faroese",
        "country": "fo"
    },
    {
        "demonym": "Micronesian",
        "country": "fsm"
    },
    {
        "demonym": "Gabonese",
        "country": "ga"
    },
    {
        "demonym": "British",
        "country": "gb"
    },
    {
        "demonym": "Georgian",
        "country": "ge"
    },
    {
        "demonym": "Channel Islander",
        "country": "gg"
    },
    {
        "demonym": "Ghanaian",
        "country": "gh"
    },
    {
        "demonym": "Gibraltar",
        "country": "gi"
    },
    {
        "demonym": "Guinean",
        "country": "gn"
    },
    {
        "demonym": "Guadeloupian",
        "country": "gp"
    },
    {
        "demonym": "Gambian",
        "country": "gm"
    },
    {
        "demonym": "Guinea-Bissauan",
        "country": "gw"
    },
    {
        "demonym": "Equatorial Guinean",
        "country": "gq"
    },
    {
        "demonym": "Greek",
        "country": "gr"
    },
    {
        "demonym": "Grenadian",
        "country": "gd"
    },
    {
        "demonym": "Greenlandic",
        "country": "gl"
    },
    {
        "demonym": "Guatemalan",
        "country": "gt"
    },
    {
        "demonym": "Guianan",
        "country": "gf"
    },
    {
        "demonym": "Guamanian",
        "country": "gu"
    },
    {
        "demonym": "Guyanese",
        "country": "gy"
    },
    {
        "demonym": "Hong Konger",
        "country": "hk"
    },
    {
        "demonym": "Heard and McDonald Islander",
        "country": "hm"
    },
    {
        "demonym": "Honduran",
        "country": "hn"
    },
    {
        "demonym": "Croatian",
        "country": "hr"
    },
    {
        "demonym": "Haitian",
        "country": "HT"
    },
    {
        "demonym": "Hungarian",
        "country": "hu"
    },
    {
        "demonym": "Indonesian",
        "country": "id"
    },
    {
        "demonym": "Manx",
        "country": "im"
    },
    {
        "demonym": "Indian",
        "country": "in"
    },
    {
        "demonym": "Indian",
        "country": "io"
    },
    {
        "demonym": "Irish",
        "country": "ie"
    },
    {
        "demonym": "Iraqi",
        "country": "iq"
    },
    {
        "demonym": "Icelander",
        "country": "is"
    },
    {
        "demonym": "Israeli",
        "country": "il"
    },
    {
        "demonym": "Italian",
        "country": "it"
    },
    {
        "demonym": "Jamaican",
        "country": "jm"
    },
    {
        "demonym": "Channel Islander",
        "country": "je"
    },
    {
        "demonym": "Jordanian",
        "country": "jo"
    },
    {
        "demonym": "Japanese",
        "country": "jp"
    },
    {
        "demonym": "Kazakhstani",
        "country": "kz"
    },
    {
        "demonym": "Kenyan",
        "country": "ke"
    },
    {
        "demonym": "Kirghiz",
        "country": "kg"
    },
    {
        "demonym": "Cambodian",
        "country": "kh"
    },
    {
        "demonym": "I-Kiribati",
        "country": "ki"
    },
    {
        "demonym": "Kittitian or Nevisian",
        "country": "kn"
    },
    {
        "demonym": "South Korean",
        "country": "kr"
    },
    {
        "demonym": "Kuwaiti",
        "country": "kw"
    },
    {
        "demonym": "Lebanese",
        "country": "lb"
    },
    {
        "demonym": "Liberian",
        "country": "lr"
    },
    {
        "demonym": "Libyan",
        "country": "ly"
    },
    {
        "demonym": "Saint Lucian",
        "country": "lc"
    },
    {
        "demonym": "Liechtensteiner",
        "country": "li"
    },
    {
        "demonym": "Sri Lankan",
        "country": "lk"
    },
    {
        "demonym": "Mosotho",
        "country": "ls"
    },
    {
        "demonym": "Lithuanian",
        "country": "lt"
    },
    {
        "demonym": "Luxembourger",
        "country": "lu"
    },
    {
        "demonym": "Latvian",
        "country": "lv"
    },
    {
        "demonym": "Moroccan",
        "country": "ma"
    },
    {
        "demonym": "Monegasque",
        "country": "mc"
    },
    {
        "demonym": "Malagasy",
        "country": "mg"
    },
    {
        "demonym": "Maldivan",
        "country": "mv"
    },
    {
        "demonym": "Mexican",
        "country": "mx"
    },
    {
        "demonym": "Marshallese",
        "country": "mh"
    },
    {
        "demonym": "Malian",
        "country": "ml"
    },
    {
        "demonym": "Maltese",
        "country": "mt"
    },
    {
        "demonym": "Burmese",
        "country": "mm"
    },
    {
        "demonym": "Montenegrin",
        "country": "me"
    },
    {
        "demonym": "Mongolian",
        "country": "mn"
    },
    {
        "demonym": "American",
        "country": "mp"
    },
    {
        "demonym": "Mozambican",
        "country": "mz"
    },
    {
        "demonym": "Mauritanian",
        "country": "mr"
    },
    {
        "demonym": "Montserratian",
        "country": "ms"
    },
    {
        "demonym": "Martinican",
        "country": "mq"
    },
    {
        "demonym": "Mauritian",
        "country": "mu"
    },
    {
        "demonym": "Malawian",
        "country": "mw"
    },
    {
        "demonym": "Malaysian",
        "country": "my"
    },
    {
        "demonym": "Mahoran",
        "country": "yt"
    },
    {
        "demonym": "Namibian",
        "country": "na"
    },
    {
        "demonym": "New Caledonian",
        "country": "nc"
    },
    {
        "demonym": "Nigerien",
        "country": "ne"
    },
    {
        "demonym": "Norfolk Islander",
        "country": "nf"
    },
    {
        "demonym": "Nigerian",
        "country": "ng"
    },
    {
        "demonym": "Nicaraguan",
        "country": "ni"
    },
    {
        "demonym": "Niuean",
        "country": "nu"
    },
    {
        "demonym": "Dutch",
        "country": "nl"
    },
    {
        "demonym": "Norwegian",
        "country": "no"
    },
    {
        "demonym": "Nepalese",
        "country": "np"
    },
    {
        "demonym": "Nauruan",
        "country": "nr"
    },
    {
        "demonym": "New Zealander",
        "country": "nz"
    },
    {
        "demonym": "Omani",
        "country": "Om"
    },
    {
        "demonym": "Pakistani",
        "country": "pk"
    },
    {
        "demonym": "Panamanian",
        "country": "pa"
    },
    {
        "demonym": "Pitcairn Islander",
        "country": "pi"
    },
    {
        "demonym": "Peruvian",
        "country": "pe"
    },
    {
        "demonym": "Filipino",
        "country": "ph"
    },
    {
        "demonym": "Palauan",
        "country": "pw"
    },
    {
        "demonym": "Papua New Guinean",
        "country": "pg"
    },
    {
        "demonym": "Polish",
        "country": "pl"
    },
    {
        "demonym": "Puerto Rican",
        "country": "pr"
    },
    {
        "demonym": "North Korean",
        "country": "kp"
    },
    {
        "demonym": "Portuguese",
        "country": "pt"
    },
    {
        "demonym": "Paraguayan",
        "country": "py"
    },
    {
        "demonym": "Palestinian",
        "country": "ps"
    },
    {
        "demonym": "French Polynesian",
        "country": "pf"
    },
    {
        "demonym": "Qatari",
        "country": "qa"
    },
    {
        "demonym": "Réunionese",
        "country": "re"
    },
    {
        "demonym": "Romanian",
        "country": "ro"
    },
    {
        "demonym": "Russian",
        "country": "ru"
    },
    {
        "demonym": "Rwandan",
        "country": "rw"
    },
    {
        "demonym": "Saudi Arabian",
        "country": "sa"
    },
    {
        "demonym": "Sudanese",
        "country": "sd"
    },
    {
        "demonym": "Senegalese",
        "country": "sn"
    },
    {
        "demonym": "Singaporean",
        "country": "sg"
    },
    {
        "demonym": "South Georgian South Sandwich Islander",
        "country": "sgs"
    },
    {
        "demonym": "Norwegian",
        "country": "sj"
    },
    {
        "demonym": "Solomon Islander",
        "country": "sb"
    },
    {
        "demonym": "Sierra Leonean",
        "country": "sl"
    },
    {
        "demonym": "Salvadoran",
        "country": "sv"
    },
    {
        "demonym": "Sammarinese",
        "country": "sm"
    },
    {
        "demonym": "Somali",
        "country": "so"
    },
    {
        "demonym": "Saint-Pierrais, Miquelonnais",
        "country": "pm"
    },
    {
        "demonym": "Serbian",
        "country": "rs"
    },
    {
        "demonym": "South Sudanese",
        "country": "ss"
    },
    {
        "demonym": "Surinamer",
        "country": "sr"
    },
    {
        "demonym": "Slovak",
        "country": "sk"
    },
    {
        "demonym": "Slovene",
        "country": "si"
    },
    {
        "demonym": "Swedish",
        "country": "se"
    },
    {
        "demonym": "Swazi",
        "country": "sz"
    },
    {
        "demonym": "St. Maartener",
        "country": "sxm"
    },
    {
        "demonym": "Seychellois",
        "country": "sc"
    },
    {
        "demonym": "Syrian",
        "country": "sy"
    },
    {
        "demonym": "Turks and Caicos Islander",
        "country": "tc"
    },
    {
        "demonym": "Chadian",
        "country": "td"
    },
    {
        "demonym": "Togolese",
        "country": "tg"
    },
    {
        "demonym": "Thai",
        "country": "th"
    },
    {
        "demonym": "Tadzhik",
        "country": "tj"
    },
    {
        "demonym": "Tokelauan",
        "country": "tk"
    },
    {
        "demonym": "Turkmen",
        "country": "tm"
    },
    {
        "demonym": "East Timorese",
        "country": "tl"
    },
    {
        "demonym": "Tongan",
        "country": "to"
    },
    {
        "demonym": "Trinidadian",
        "country": "tt"
    },
    {
        "demonym": "Tunisian",
        "country": "tn"
    },
    {
        "demonym": "Turkish",
        "country": "tr"
    },
    {
        "demonym": "Tuvaluan",
        "country": "tv"
    },
    {
        "demonym": "Taiwanese",
        "country": "tw"
    },
    {
        "demonym": "Tanzanian",
        "country": "tz"
    },
    {
        "demonym": "Ugandan",
        "country": "ug"
    },
    {
        "demonym": "Ukrainian",
        "country": "ua"
    },
    {
        "demonym": "American Islander",
        "country": "um"
    },
    {
        "demonym": "Uruguayan",
        "country": "uy"
    },
    {
        "demonym": "American",
        "country": "us"
    },
    {
        "demonym": "Uzbekistani",
        "country": "uz"
    },
    {
        "demonym": "Vatican",
        "country": "vt"
    },
    {
        "demonym": "Saint Vincentian",
        "country": "vc"
    },
    {
        "demonym": "Venezuelan",
        "country": "ve"
    },
    {
        "demonym": "Virgin Islander",
        "country": "vi"
    },
    {
        "demonym": "Vietnamese",
        "country": "vt"
    },
    {
        "demonym": "Ni-Vanuatu",
        "country": "vu"
    },
    {
        "demonym": "Wallis and Futuna Islander",
        "country": "wf"
    },
    {
        "demonym": "Samoan",
        "country": "ws"
    },
    {
        "demonym": "Yemeni",
        "country": "ye"
    },
    {
        "demonym": "South African",
        "country": "za"
    },
    {
        "demonym": "Zambian",
        "country": "zm"
    },
    {
        "demonym": "Zimbabwean",
        "country": "zw"
    }
];