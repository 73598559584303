import React, {useState} from "react";
import styled from "styled-components";
import {XIcon,ChevronLeftIcon} from '@heroicons/react/solid';
import {Button} from "./button";
import {NewTooltip} from "../reorganize/new-tooltip";

const ActionIcon = styled.div`
> svg {
    height: 20px;
}
`;
const ChevronIcon = styled.div`
> svg {
    height: 24px;
}
`;

const HeightDiv = styled.div`
  
  > div {
    padding: ${props=>props.padded?`0.75rem 1rem`:``};
  }
  
`;

const box_class = `flex items-center text-gray-500 hover:text-gray-800 transition-colors cursor-pointer hover:bg-gray-200 justify-center h-8 w-8 rounded-md`;

export function PanelHeader(props) {
    const {hide_divider,compact,nav=[],goToPanel=()=>{}} = props;
    const has_divider = hide_divider ? "pt-3 pb-1" : "border-b border-solid border-gray-200 py-3";

    const back = props.show_back && <ChevronIcon onClick={() => props.goBack()}
                                                 className={`${box_class}`}>
        <ChevronLeftIcon/>
    </ChevronIcon>;

    const close = props.onClose ? <ActionIcon onClick={() => props.onClose()}
                              className={`${box_class}`}>
        <XIcon/>
    </ActionIcon> : null;

    const title = <div className={`${compact?"text-base":"text-2xl"} flex items-center flex-1 font-bold text-gray-800`}>
        {props.title}
    </div>;
    if(compact) {
        return <div className={`flex space-x-2 items-center flex-none px-4 ${has_divider}`}>
            {back&&<div className="flex-none">{back}</div>}
            <div className="flex-grow">
                {title}
            </div>
            {nav.length>0&&<div className="space-x-1 ">
                {nav.map((it,k)=><NewTooltip message={it.title} key={k}>
                    <div onClick={()=>{
                    if(it.target_panel) {
                        goToPanel(it.target_panel);
                    }
                    }} className={`${box_class} relative`}>
                    <div className="h-5 w-5">
                    {it.icon}
                    </div>
                </div>
                </NewTooltip>)}
            </div>}
            <div>
                {close}
            </div>
        </div>
    }

    return <div className={`flex flex-none flex-col px-4 ${has_divider}`}>
        <div className="flex mb-2 -mx-1">
            <div className="w-12 flex-1">
                {back}
            </div>
            <div className="w-12 flex-none flex justify-end">
                {close}
            </div>
        </div>
        <div className="flex">
            {title}
        </div>
    </div>
}

export function PanelDivider(props) {
    return <div className="my-2 mx-3 border-b border-solid border-gray-200"/>
}

const MenuIcon = styled.div`
> svg {
    height: 18px;
}
`;

function MenuPanelMenuItem(props) {
    const oc = props.onClick ? () => props.onClick() : props.goToPanel ? () => props.goToPanel(props.target_panel) : () => {
    };
    return <div onClick={oc}
                className="flex px-2 transition-colors rounded-md hover:bg-gray-100 cursor-pointer py-1.5 items-start">
        <MenuIcon style={{marginTop: '1px'}}
                  className="flex-none pr-1.5 h-4.5 w-8 justify-center flex items-center text-gray-500">
            {props.icon}
        </MenuIcon>
        <div className="flex-1 items-center">
            <div className="text-sm  text-gray-700 font-semibold">
                {props.label}
            </div>
            {props.desc && <div className="text-sm text-gray-500 -mt-0.5">
                {props.desc}
            </div>}
        </div>
    </div>
}

export function PanelMenu(props) {
    const {items, goToPanel} = props;
    return <div className="px-3 py-2 space-y-1">
        {items.map(it => <MenuPanelMenuItem {...it} key={it.key} goToPanel={(ti) => goToPanel(ti)}/>)}
    </div>
}

function renderComponent(children, props) {

    return React.Children.map(children, child => {
        // checking isValidElement is the safe way and avoids a typescript error too
        if (React.isValidElement(child)) {
            return React.cloneElement(child, props);
        }
    });
}

function getComponentByID(arr, id) {
    return arr.filter(obj => {
        return obj.id === id
    })[0];
}

function getInitialPanels(panels,initial) {

    let arr = [];

    arr.push(panels[0]);

    if((typeof initial === 'string' && initial!=='')) {
        arr.push(getComponentByID(panels,initial));
    } else if(Array.isArray(initial)) {
        for(let i=0;i<initial.length;i++) {
            arr.push(getComponentByID(panels,initial[i]));
        }
    }

    return arr;
}

export function MenuPanels(props) {
    const {panels, new_layout, enforce_child_height = false, show = true, data_obj = {}, initial='', onClose} = props;
    const [stack, setStack] = useState(getInitialPanels(panels,initial));

    const len = stack.length;

    const last_index = len - 1;

    const item = stack[last_index];

    function close() {
        onClose();
        setTimeout(() => {
            setStack([stack[0]]);
        }, 1000);

    }

    function back() {
        stack.splice(-1, 1);

        setStack([...stack]);
    }

    function handleGoToPanel(id) {
        const new_component = getComponentByID(panels, id);
        if (!new_component) {
            alert('No access');
        } else {
            const new_stack = [...stack, new_component];

            setStack(new_stack);
        }
    }

    if(new_layout) {
        return <div className="bg-white rounded-md w-96">
            <PanelHeader goToPanel={handleGoToPanel} compact={item.compact} show_back={len > 1} goBack={() => back()} onClose={() => close()} title={item.title} {...item} />
            <HeightDiv child={enforce_child_height} padded={item.padded}>
                {renderComponent(item.component, {
                    data_obj,
                    goToPanel: handleGoToPanel,
                    close: () => close(),
                    goBack: () => back(),
                    key: item.id,
                    stack_length: stack.length
                })}
            </HeightDiv>
        </div>
    }

    return <div className="fixed z-99 w-full right-0 pointer-events-none top-0 bottom-0">
        <div className="mx-auto app-width pointer-events-none flex justify-end">
        <div className=" flex flex-col z-40 mt-14 pointer-events-auto">
        <div className={"transition-opacity " + (show ? "opacity-100" : "opacity-0")}>
            <div className="bg-white mobile-page-height border-l border-solid border-gray-200 w-96">
                <PanelHeader goToPanel={handleGoToPanel} compact={item.compact} show_back={len > 1} goBack={() => back()} onClose={() => close()} title={item.title} {...item} />
                <HeightDiv child={enforce_child_height} padded={item.padded}>
                    {renderComponent(item.component, {
                        data_obj,
                        goToPanel: handleGoToPanel,
                        close: () => close(),
                        goBack: () => back(),
                        key: item.id,
                        stack_length: stack.length
                    })}
                </HeightDiv>
            </div>
        </div>
    </div>
        </div>
    </div>
}