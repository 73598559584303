import React from 'react';
import {HomeIcon, InformationCircleIcon, MailIcon, PhoneIcon} from "@heroicons/react/solid";
import {buildAddress} from "../../../library/utilities/shared";

export function buildDataSuggestionsOptions(member,my_profile=false) {
    let arr = [];

    if(my_profile) {
        return arr;
    }
    const {name} = member;

    if(member.address.line_one) {
        arr.push({
            id: 'address',
            icon: <HomeIcon />,
            type: 'address',
            value: buildAddress(member.address),
            label: `${name} doesn't live there`
        })
    } else {
        arr.push({
            id: 'address',
            icon: <HomeIcon />,
            type: 'address',
            value: '',
            label: `I know ${name}'s address`
        })
    }

    if(member.contact.email) {
        arr.push({
            id: 'contact.email',
            icon: <MailIcon />,
            type: 'email',
            value: member.contact.email,
            label: `Email doesn't work`
        })
    } else {
        arr.push({
            id: 'contact.email',
            icon: <MailIcon />,
            type: 'email',
            value: '',
            label: `I know ${name}'s email`
        })
    }

    if(member.contact.phone) {
        arr.push({
            id: 'contact.phone',
            icon: <PhoneIcon />,
            type: 'phone',
            value: member.contact.phone,
            label: `${name} doesn't live there`
        })
    } else {
        arr.push({
            id: 'contact.phone',
            icon: <PhoneIcon />,
            type: 'phone',
            value: '',
            label: `I know ${name}'s number`
        })
    }

    arr.push({
        id: '*',
        icon: <InformationCircleIcon />,
        type: 'general',
        value: '',
        label: 'General Data Suggestion'
    });

    return arr;
}