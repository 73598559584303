import React from 'react';
import {CheckCircleIcon, ClockIcon, ExclamationCircleIcon, QuestionMarkCircleIcon} from "@heroicons/react/solid";

/*
4 differnt statuses
unknown - gray questionmark - the member has never updated their profile
uptodate - lightblue check - updated in the last 3 months, shows date
outdated - gray outdated - data fields were marked as wrong
warning - yellow warning - the profile hasn't been updated in the last year
 */

const ref = {
    'uptodate': {
        icon: <CheckCircleIcon/>,
        color: 'text-success',
        message: `Profile up-to-date` // last week, today, last month, a couple months ago
    },
    'unknown': {
        icon: <QuestionMarkCircleIcon/>,
        color: 'text-red-600',
        message: `Never updated`
    },
    'outdatedlong': {
        icon: <ClockIcon/>,
        color: 'text-red-600',
        message: `Outdated`
    },
    'outdated': {
        icon: <ClockIcon/>,
        color: 'text-yellow-600',
        message: `Profile outdated`
    },
    'warning': {
        icon: <ExclamationCircleIcon/>,
        color: 'text-yellow-600',
        message: `Caution`
    }
};

export function ProfileUpdatedTag({message,color,icon,show_label=true}) {

    return <div className={`inline-flex text-sm items-center font-medium space-x-1.5 ${color}`}>
        <div className="h-5 w-5">
            {icon}
        </div>
        {show_label&&<div className={""}>
            {message}
        </div>}
    </div>
}