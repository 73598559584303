import React from "react";
import {CheckCircleIcon, ClockIcon, XCircleIcon} from "@heroicons/react/solid";
import {getPrettyTime} from "../../../../../../library/utilities/general/dates";
import moment from "moment";

// Version 3 not yet accepted, version 1 accepted on 12.6.2021
// Never accepted
function Policy({
    label = "Privacy Policy",
    status = 'up-to-date',
    data,
    details
                }) {

    return <div className="flex space-x-2">
        <div className="py-0.5">
            {status==='up-to-date'&&<div className="text-green-700 w-4 h-4">
                <CheckCircleIcon/>
            </div>}
            {status==='never-accepted'&&<div className="text-gray-500 w-4 h-4">
                <XCircleIcon/>
            </div>}
            {status==='outdated'&&<div className="text-gray-500 w-4 h-4">
                <ClockIcon/>
            </div>}
        </div>
        <div>
            <div className="text-gray-800 text-sm font-medium">{label}</div>
            {status==='up-to-date'&&<div className="text-xs text-gray-600">Accepted - {getPrettyTime(moment.unix((details.at/1000)))}</div>}
            {status==='never-accepted'&&<div className="text-xs text-gray-600">Never accepted</div>}
            {status==='outdated'&&<div className="text-xs text-gray-600">Previously accepted version {details.last_version_accepted}</div>}
        </div>
    </div>
}

export function MemberPoliciesTable({outdated,status,up_to_date,never_accepted,policy_data}) {

    return <div className="rounded-md mt-0.5 shadow-sm border border-gray-200 bg-gray-50 space-y-2 p-2">
        {never_accepted.map(na=><Policy details={na} data={policy_data[na.id]} key={na.id} label={policy_data[na.id].name} status={'never-accepted'} />)}
        {outdated.map(od=><Policy key={od.id} details={od} data={policy_data[od.id]} label={policy_data[od.id].name} status={'outdated'} />)}
        {up_to_date.map(ud=><Policy key={ud.id} details={ud} data={policy_data[ud.id]} label={policy_data[ud.id].name} status={'up-to-date'} />)}
    </div>
}