import React, {Component, useState} from "react";
import styled from "styled-components";

export const APFieldGrid = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    > div {
        margin-right: 16px;
    }
`;

export const APHeader = styled.div`
color: #112D42;
    font-weight: 600;
    font-size: 16px;
`;

export const APButtons = styled.div`
        display: inline-block;
    margin-top: 20px;
`;

export const APContainer = styled.div`
margin-bottom: 10vh;
`;

export function getValue(field,data) {
    const t = field.split('.');
    if(t.length===3) {
        return data[t[0]][t[1]][t[2]];
    } else if(t.length===2) {
        return data[t[0]][t[1]];
    } else {
        return data[t[0]];
    }
}