import React, {useContext} from 'react';
import {GlobalContext} from "../../global/global-context";
import {CommonLayoutProfile} from "../../../library/components/layout/page/profile";
import {ProfileCard} from "../../../library/components/reorganize/profile-card";
import {buildUserProfileCard} from "./build-profile-card";
import ProfileLoadingCard from "../../../library/components/layout/loading/profile";
import {StandardPageFramework} from "../../../library/playground/standard-page-framework";
import {EntityHeader} from "../../../library/components/core";
import {useIsMobile} from "../../global/global-router";

export function OtherUserPage(props) {
    const global = useContext(GlobalContext);
const is_mobile = useIsMobile();
    const {data} = props;

    const sidebar = null;

    let card = buildUserProfileCard(data);

    let actions = {

    };
    let context = {

    };
    let features = {
        can_manage: false
    };

    // return <ProfileLoadingCard/>;

    return <StandardPageFramework header={is_mobile?null:<EntityHeader onClick={() => {
        const el = document.getElementById("main-content");
        el.scrollTo(0, 0);
    }} title={data.name} actions={null} />} layout="sidebar" sidebar={sidebar}>
        <ProfileCard context={context} actions={actions} data={card} features={features} />

    </StandardPageFramework>
}