import React from 'react';
import styled from "styled-components";
import {QuestionMarkCircleIcon,TagIcon,UserIcon,LocationMarkerIcon,ClockIcon} from "@heroicons/react/solid";
import {ProfileUpdatedIcon} from "../reorganize/profile-updated-icon";

const Icon = styled.div`
      
    > svg {
        height: 18px;
    }
`;


const LargeIcon = styled.div`
      
    > svg {
        height: 22px;
    }
`;

function MetaItem({icon = <LocationMarkerIcon/>, comp, num, value="", first, last, color, layout, label = "Item"}) {
    const is_str = typeof label === "string";

    if(layout==='admin-subtitle') {
        return <div className={`pt-1 pr-6 mb-1.5 ${num!==0?"pl-3":"pl-1"}`}>
            <div>
            <div className="text-gray-600 font-medium text-xs mb-1">{label}</div>
            <div style={{height:'28px'}} className="flex items-center">
                {comp}
                {!comp&&<div className="text-sm font-medium text-gray-700">
                    {value}
                </div>}
            </div>
            </div>
        </div>
    }

    if(layout==='rich') {
        const padding = first ? "pt-2 pb-4" : last ? "pb-2 pt-4" : " py-4";
        return <div className={` px-4 ${padding}`}>
            <div className={`flex items-center justify-center w-10 h-10 rounded-42 bg-${color}`}>
                <LargeIcon className="color-white">
                    {icon}
                </LargeIcon>
            </div>
            <div className="mt-2 pb-1 text-sm text-gray-700">
                {label}
            </div>
        </div>
    }

    return <div className="mt-2 flex items-center text-sm text-gray-500">
        <Icon className="pr-2 text-gray-400">
            {icon}
        </Icon>
        {is_str &&
        <span className="text-gray-600">
        {label}
        </span>}
        {!is_str && label}
    </div>
}

const layouts = ["simple", "rich", "rich-horizontal"];

const mock = [
    {
        label: 'Last login',
        color: 'teal-800',
        icon: <ClockIcon/>
    },
    {
        label: 'Item title',
        color: 'purple-800',
        icon: <UserIcon/>,
        comp: <ProfileUpdatedIcon />
    },
    {
        label: 'Profile check',
        color: 'pink-800',
        icon: <TagIcon/>
    },
    {
        label: "Profile quality",
        color: 'red-700',
        icon: <QuestionMarkCircleIcon/>
    }
];

function getContainerClassesFromLayout(layout) {
    if(layout==='simple') {
        return '';
    } else if(layout==='admin-subtitle') {
        return 'flex flex-wrap divide-x space-x-2';
    }
    return '';
}

export function Meta({layout = "admin-subtitle", items = mock}) {

    return <div className={`${getContainerClassesFromLayout(layout)}`}>
        {items.map((it,i) => <MetaItem num={i} first={i===0} last={(i+1)===items.length} layout={layout} key={i} {...it} />)}
    </div>
}