import React, {useEffect, useState} from 'react';
import {Button, Field, InlineLoader, Input} from "../../library/components/core";
import {getEmailIPConfig,saveEmailIPConfigChange} from "./get-config";
import {GlobalEntityInfo} from "../../library/utilities/localstorage/entity-localstorage";
import {PlusIcon} from "@heroicons/react/solid";
import {validateEmail} from "../../library/utilities/validation";


export function SuperadminEmailIP() {

    const [changes_made,setChangesMade] = useState(false);
    const [data,setData] = useState(null);

    useEffect(function () {
        getEmailIPConfig()
            .then(config=>{
                setData(config);
            })
    }, [])


    function saveChanges() {
        saveEmailIPConfigChange(data)
            .then(()=>{
                setChangesMade(false);
            })
    }

    if(!data) {
        return <div>
            <InlineLoader padding />
        </div>
    }

    return <div className="space-y-2">
        <Field label="Emails per day" help_text={`= ${Math.round(data.emails_per_day/24)} per hour`}>
            <Input value={data.emails_per_day} onChange={(v)=>{
                setData({
                    ...data,
                    emails_per_day: v
                })
                setChangesMade(true);
            }} />
        </Field>
        <Field label="Emails to send to" help_text={`Up to 16 of these emails will be sent to at random`}>
            <div className="space-y-2">
            {data.emails.map((em,k)=>{
                return <div key={k}>
                    <Input id={`email-${k}`} placeholder="email@getunaty.com" onChange={(v)=>{
                        let ne = [...data.emails];
                        ne[k] = v;
                        setData({
                            ...data,
                            emails: ne
                        })
                        setChangesMade(true);
                    }} value={em} />
                    {!validateEmail(em)&&<div>
                      Invalid Email!
                    </div>}
                </div>
            })}
            </div>
            <div className="pt-2">
                <Button text="Add Email" icon={<PlusIcon />} onClick={()=>{
                    let ne = [...data.emails];
                    ne.push("");
                    setData({
                        ...data,
                        emails: ne
                    })
                    setChangesMade(true);
                }} />
            </div>
        </Field>
        {changes_made&&<div className="pt-4">
            <Button text="Save Changes" onClick={()=>saveChanges()} intent="success" />
        </div>}
        <div className="mt-4">
            <p>
                About our sending:<br/>
                Domain: m.getunaty.com <br/>
                IP: 104.130.122.94<br/>
                <a className="color-link" target="_blank" rel="nofollow noreferrer" href="https://mxtoolbox.com/SuperTool.aspx?action=spf%3am.getunaty.com%3a104.130.122.94&run=toolpage">SPF Check</a><br/>
                <a className="color-link" target="_blank" rel="nofollow noreferrer" href="https://mxtoolbox.com/SuperTool.aspx?action=blacklist%3am.getunaty.com&run=toolpage">Blacklist Check</a><br/>
                <a className="color-link" target="_blank" rel="nofollow noreferrer" href="https://mxtoolbox.com/SuperTool.aspx?action=dmarc%3am.getunaty.com&run=toolpage">DMARC Check</a><br/>
            </p>
        </div>
    </div>
}