import React, {useEffect, useState} from "react";
import {api_getEntityFromHandle} from "../../api/entity";
import {api_getCommunity} from "../../api/community";
import {InfoState, InlineLoader} from "../../library/components/core";

function renderComponent(children, props) {

    return React.Children.map(children, child => {
        // checking isValidElement is the safe way and avoids a typescript error too
        if (React.isValidElement(child)) {
            return React.cloneElement(child, props);
        }
    });
}

export function EntityCommunityWrapper(props) {
    const [entity,setEntity] = useState(null);
    const [community,setCommunity] = useState(null);
    const [error,setError] = useState('');
    const [loading,setLoading] = useState('entity');

    useEffect(function () {
        console.log('EntityCommunityWrapper',props)
        if(!props.match.params.handle) {
            return;
        }
        api_getEntityFromHandle(props.match.params.handle)
            .then(snap=>{
                if(snap.empty) {
                    setError('entity-not-found')
                        setLoading('');
                } else {
                    const entity_data = snap.docs[0].data();
                    setEntity(entity_data);
                    if(entity_data.type==='community') {
                        setLoading('community');
                        api_getCommunity(entity_data.community_uid)
                            .then(doc=>{
                                console.log('doc',doc.data(),doc.exists)
                                if(!doc.exists) {
setError('community-not-found');
                                    setLoading('');
                                } else {
                                    setLoading('');
                                    setError('');
                                    setCommunity(doc.data());
                                }
                            })
                    } else {
                        setLoading('');
                    }
                }

            })
    }, [props.match.params.handle]);

    if(loading==='entity') {
        return <div>
            <InlineLoader />
        </div>
    }

    if(loading==='community') {
        return <div>
            <InlineLoader />
        </div>
    }

    if(error) {
        return <div>
            <InfoState subtitle="Something went wrong" />
        </div>
    }

    return <div>
        {renderComponent(props.children, {community,entity})}
    </div>
}