import React, {useContext, useState} from 'react';
import {SectionHeader, Toggle} from "../../../../library/components/core";
import {authFetch} from "../../../../config/network";
import {GlobalContext} from "../../../global/global-context";

function Item({title, child, hide, value, onChange, subtitle}) {

    if(hide) {
        return null;
    }

    return <div className={`flex ${child?"border-l-2 border-gray-200 pl-4 pt-1 pb-2":""}`}>
        <div className="flex-grow">
            <div className="text-sm text-gray-800 font-semibold">{title}</div>
            <div className="text-xs pt-0.5 text-gray-600">{subtitle}</div>
        </div>
        <div className="flex-none  flex items-center">
            <Toggle layout='switch' checked={value}
                    onBlur={() => {
                    }} onToggle={() => {
                onChange(!value)
            }}/>
        </div>
    </div>
}

export function GroupModalVisibilityTab({
                                            getGroup,
                                            setData,
                                            community, cb = () => {
    }, id, email_list_power_up, group_data
                                        }) {

    const global = useContext(GlobalContext);
    async function apiCall(endpoint, payload) {
        return await new Promise((resolve, reject) => {
            const r = (resp) => {
                resolve(resp);
            }
            authFetch(endpoint, r, r, "POST", {payload});
        });
    }

    function toggleGroupOpen() {
        const endpoint = group_data.closed?"/groups/visibility/make-open":"/groups/visibility/make-closed";
        const payload = {
            id,
            member_id: community.member_id,
            community_uid: community.uid,
            closed: !group_data.closed,
            hide_from_non_members: false
        };
        setData({
            closed: !group_data.closed,
            hide_from_non_members: false
        });
        apiCall(endpoint, payload)
            .then(() => {
                getGroup();
                global.addToast({text:"Settings updated",intent:"success"});
            });
    }


    function toggleHideGroupFromNonMembers() {
        const endpoint = "/groups/visibility/hide-non-members";
        const payload = {
            id,
            member_id: community.member_id,
            community_uid: community.uid,
            hide_from_non_members: !group_data.hide_from_non_members
        };
        setData({
            hide_from_non_members: !group_data.hide_from_non_members
        });
        apiCall(endpoint, payload)
            .then(() => {
                getGroup();
                global.addToast({text:"Settings updated",intent:"success"});
            });
    }

    function toggleLeadersPageDisplay() {
        const endpoint = "/groups/visibility/leaders-display-toggle";
        const payload = {
            id,
            member_id: community.member_id,
            community_uid: community.uid,
            display_leaders: !group_data.display_leaders
        };

        setData({
            display_leaders: !group_data.display_leaders
        });
        apiCall(endpoint, payload)
            .then((resp) => {
                getGroup();
                global.addToast({text:"Settings updated",intent:"success"});
            });
    }

    function togglePublicPageDisplay() {
        const endpoint = "/groups/visibility/public-page-toggle";
        const payload = {
            id,
            member_id: community.member_id,
            community_uid: community.uid,
            enable_public_page: !group_data.enable_public_page
        };
        setData({
            enable_public_page: !group_data.enable_public_page
        });
        apiCall(endpoint, payload)
            .then(() => {
                getGroup();
                global.addToast({text:"Settings updated",intent:"success"});
            });
    }

    function toggleAllowNewApplications() {
        const endpoint = "/groups/visibility/allow-new-applications";
        const payload = {
            id,
            member_id: community.member_id,
            community_uid: community.uid,
            enable_new_applications: !group_data.enable_new_applications
        };
        setData({
            enable_new_applications: !group_data.enable_new_applications
        });
        apiCall(endpoint, payload)
            .then(() => {
                getGroup();
                global.addToast({text:"Settings updated",intent:"success"});
            });
    }

    const items = [
        {
            title: "Make Group Closed",
            subtitle: "Only group members will be able to see group content",
            value: group_data.closed,
            field: 'closed',
            onChange: (ct) => toggleGroupOpen()
        },
        {
            title: "Hide Group from Non-Members",
            subtitle: "Only group members can see this group exists",
            value: group_data.hide_from_non_members,
            disabled: !group_data.closed,
            child: true,
            hide: !group_data.closed,
            field: 'hide_from_non_members',
            onChange: (ct) => toggleHideGroupFromNonMembers()
        },
        {
            is_divider: true,
            title: "Group Discovery"
        },
        {
            title: "Display on Leaders Page",
            subtitle: "This group and its roles will be displayed on the Leaders page",
            value: group_data.display_leaders,
            field: 'enable_new_applications',
            onChange: (ct) => toggleLeadersPageDisplay()
        },
        {
            title: "Public Group Page",
            subtitle: "A public link for your Group visible to the world",
            value: group_data.enable_public_page,
            disabled: group_data.closed,
            field: 'enable_public_page',
            onChange: (ct) => togglePublicPageDisplay()
        }
    ];

    if(community.data.preferences.members.allow_new_member_applications) {
        items.push({
            is_divider: true,
            title: "Member Applications"
        });
        items.push({
            title: "Allow New Group Member Applications",
            subtitle: "Get a unique link people can use to join your group, and the community itself. Group moderators will be notified to approve new member applications",
            value: group_data.enable_new_applications,
            field: 'enable_new_applications',
            onChange: (ct) => toggleAllowNewApplications()
        });
    }

    return <div className={`space-y-3`}>
        {items.map(it => {
            if (it.is_divider) {
                return <div className="pt-4" key={it.title}>
                    <SectionHeader padded={false} divider title={it.title}/>
                </div>
            }
            return <Item key={it.title} {...it}/>
        })}
    </div>
}