import React, {useContext, useState} from "react";
import {CommunityContext} from "../community-context";
export default function CommunityUploadFile(props) {
    const {scope_id, folder_id} = props;
    const [fileRef, setRef] = useState(React.createRef());
    const community = useContext(CommunityContext);

    function selectFile() {
        fileRef.current.click();
    }

    function handleFilesEvent(e) {
        const fs = e.target.files;
        props.handleFileChange(fs)
    }

    const {replace} = props;

    return (<>
            <div onClick={() => selectFile()}>
                {props.children}
            </div>

            <input onChange={handleFilesEvent.bind(this)} value={[]} ref={fileRef} multiple={!replace} type="file"
                   accept="*" style={{display: 'none'}}/>
        </>
    );
}