import {authFetch} from "../../../config/network";
import {analytics} from "firebase";

export function arrayCompare(_arr1, _arr2) {
    if (
        !Array.isArray(_arr1)
        || !Array.isArray(_arr2)
        || _arr1.length !== _arr2.length
    ) {
        return false;
    }

    // .concat() to not mutate arguments
    const arr1 = _arr1.concat().sort();
    const arr2 = _arr2.concat().sort();

    for (let i = 0; i < arr1.length; i++) {
        if (arr1[i] !== arr2[i]) {
            return false;
        }
    }

    return true;
}

export function saveChanges() {
    const member = this.state.member;

    const ni = this.state.new_interests;

    const same = arrayCompare(Object.keys(ni?ni:{}),Object.keys(member.interests?member.interests:{}));

    const no_other_update = this._updated_fields.length === 0 && !this._updated_bio;
    if(!same) {
        this.updateInterests(this.state.new_interests,{},no_other_update?()=>{
            this.getMember(this.state.user_uid);
        }:()=>{});
    }

    if (no_other_update) {
        this.setState({
            editing: false
        });

        return;
    }

    this.setState({
        saving: true
    });

    let payload = {
        about: member.about,
        social: member.social,
        contact: member.contact,
        join_date: member.join_date,
        address: member.address,
        custom_fields: member.custom_fields,
        birthday: member.birthday,
        community_uid: this.context.uid,
        user_uid: this.state.user_uid,
        member_id: this.context.member_id,
        id: this.state.member_id
    };

    payload.about.bio = this.state.member.about.bio;

    const res = async () => {
        this.setState({
            loading: true,
        });

        analytics().logEvent('update_own_member_profile', {
            event_id: 'update_own_member_profile',
            content_type: 'member',
            community_uid: this.context.uid,
            member_id: this.state.member_id,
            user_uid: this.state.user_uid
        });

        this.getMember(this.state.member_id);
        this._updated_fields = [];
        this._updated_bio = false;

       // addToast({text: "Your changes were saved", intent: 'success'});
    };

    payload._updated_fields = this._updated_fields;

    if(this._updated_bio) {
        payload._updated_fields.push('about.bio');
    }

    authFetch('/members/update-own-profile', res, res, "POST", {payload});
}

export function setProfilePicture(url,color) {
    const img = url;
    let payload = {
        url: img,
        community_uid: this.context.uid,
        id: this.state.member_id,
        member_id: this.context.member_id,
        color
    };
    this.setState({
        pp_loading: true
    });
    const res = () => {
        let member = this.state.member;
        member.profile_picture = img;
        member.profile_picture_color = color;
        this.setState({
            pp_loading: false,
            member
        })
    };
    authFetch('/members/set-profile-picture', res, res, "POST", {payload});
}

export function setCoverPhoto(url,color) {
    const img = url;
    let payload = {
        url: img,
        community_uid: this.context.uid,
        member_id: this.context.member_id,
        id: this.state.member_id,
        color
    };
    this.setState({
       cp_loading: true
    });
    const res = () => {
        let member = this.state.member;
        member.cover_photo = img;
        member.cover_photo_color = color;
        this.setState({
            cp_loading: false,
            member
        })
    };
    authFetch('/members/set-cover-photo', res, res, "POST", {payload});
}