import React from 'react';
import {Field} from "./field";
import {Input} from "./text-input";
import {EmojiPopupSelector} from "../old/emoji-popup-selector";

export function TextAndEmoji({value,usePortal=false,autoFocus=false,placeholder="Set your Status",label,emoji,onChange}) {

    const comp = <EmojiPopupSelector usePortal={usePortal} emoji={emoji} changeEmoji={(em)=>onChange(value,em.native)} />;

    return <Field label={label} corner_hint={null}>
        <Input autoFocus={autoFocus} leading={{type:'emoji',value:comp}} placeholder={placeholder} type="text" onChange={(v)=>onChange(v,emoji)} value={value} />
    </Field>
}