import {FirebaseDB} from "../../../config/setup-firestore";

export async function api_getAllSeriesForGroup(community_uid,group_id) {
    if(!community_uid||!group_id) {
        return {};
    }
    return await FirebaseDB
        .collection('community_content')
        .doc(community_uid)
        .collection('series')
        .where('group_id', '==', group_id)
        .limit(50)
        .get()
        .then(snap=> {
            let obj = {};
            snap.docs.map(d => {
                obj[d.id] = {...d.data()};
                obj[d.id].id = d.id;
            })
            return obj;
        })
}