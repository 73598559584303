import React from 'react';
import classNames from 'classnames';
import {MinusIcon} from "@heroicons/react/solid";
import styled from "styled-components";

const Icon = styled.span`
    > svg {
        height: 14px;
    }
`;

export function InterestTag({emoji="🍣",onClick,can_remove=false,onRemove=()=>{},name="Sushi",id="sushi",active}) {
const bg = active ? "bg-gradient-to-br from-yellow-400 to-orange-500 text-white border-orange-300" : "hover:bg-gray-100 text-gray-600 border-gray-200";
    return <div onClick={()=>onClick?onClick():null} className={classNames("relative inline-flex items-center text-sm rounded-full border border-solid h-7 px-1.5  cursor-pointer",bg)}>
        {emoji&&<span className="pr-0.5 text-base ">{emoji}</span>}
        <span className=" px-1.5 font-medium">{name}</span>
        {can_remove&&<Icon onClick={(e)=>{e.stopPropagation();onRemove()}} className="flex items-center justify-center absolute text-gray-600 hover:bg-gray-200 rounded-full h-5  w-5 -top-2 -left-2 bg-gray-100">
            <MinusIcon/>
        </Icon>}
    </div>
}