import React, {useContext,useEffect} from "react";
import {GlobalContext} from "../../global/global-context";
import {FullScreenLoader} from "../../../library/components/old/full-screen-loader";

export default function SignOut(props) {
    const {user_uid,logout,logging_out,logged_in} = useContext(GlobalContext);

    useEffect(function() {

        if(!logged_in) {
            const handle = props.match.params.handle;
            let redirect = handle ? `/${handle}/sign-in` : `/sign-in`;
            props.history.push(redirect);
        }

        if(logged_in&&!logging_out) {
            const handle = props.match.params.handle;
            let redirect = handle ? `/${handle}/sign-in` : `/sign-in`;
            let cb = () => {props.history.push(redirect)};
            logout(cb);
        }
    }, [user_uid,logging_out]);

    return <FullScreenLoader/>;
}