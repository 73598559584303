import {getMiniEntity, getSmallEntity} from "../../../library/utilities/localstorage/entity-localstorage";

export function getFolderCrumbs(crumbs,community_uid,handle,current_name,in_group=false,base='') {

    let split = crumbs.split('/');
    split.splice(-1,1);

    let items = [];

    for(let i=0;i<split.length;i++) {
        const id = split[i];
        if(id!=='') {
            const entity = getMiniEntity('folders', id, () => {}, {community_uid});
            items.push({
                label: entity.name,
                //emoji: entity.emoji,
                path:in_group?`${base}/folder/${id}`:`/${handle}/explore/drive/folder/${id}`
            })
        }
    }

    if(current_name) {
        items.push({
            label: current_name,
            //emoji: current_emoji,
            path: '#'
        })
    }

    return items;
}