import {authFetch} from "../../../config/network";

export async function api_createEvent(community,pl) {
    return await new Promise((resolve, reject) => {
        const res = (r) => {
            resolve(r);
        };
        const payload = {
            community_uid: community.uid,
            member_id: community.member_id,
            group_id: pl.group_id,
            name: pl.name,

            timezone: pl.timezone,

            start_date: pl.start_date,
            start_time: pl.start_time,

            end_date: pl.end_date,
            end_time: pl.end_time,

            description: pl.description
        };
        authFetch("/events/create", res, res, "POST", {payload});
    })
}