import React from "react";
import {CheckIcon, XIcon} from "@heroicons/react/solid";

/*
What are the things that impact profile quality?

- email address: exists, is valid
- phone number: exists, valid
- phone reachability set
- address: exists, valid

- when the profile was last viewed by the member

- how complete the profile is
- birthday set
- join date
- bio
- profile picture
- interests
- 

 */

const items = [
    {
        id: 'has-account-email',
        label: '',
        weight: 1
    },
    {
        // this is if the account mail was validated
        id: 'valid-account-email',
        label: '',
        weight: 1
    },
    {
        id: 'missing-account-email',
        label: '',
        weight: 1
    },
    {
        id: 'missing-account-email',
        label: '',
        weight: 1
    },
    {
        id: 'missing-account-email',
        label: '',
        weight: 1
    },
    {
        id: 'missing-account-email',
        label: '',
        weight: 1
    },
    {
        id: 'missing-account-email',
        label: '',
        weight: 1
    }
]

function Item({type='pro',label=""}) {

    return <div className="flex items-center space-x-1">
        <div className="w-4 flex items-center justify-center">
            <div className="w-3.5 h-3.5 text-gray-500">
                {type==='pro'?<CheckIcon />:<XIcon />}
            </div>
        </div>
        <div className="text-xs text-gray-600">
            {label}
        </div>
    </div>
}

export function ProsConsList({cons=[],pros=[]}) {

    return <div className="rounded-md shadow-sm border border-gray-200 bg-gray-50 grid grid-cols-2">
        <div className="p-2 space-y-1">
            {pros.map((it,k)=><Item key={k} type="pro" {...it} />)}
        </div>
        <div className="p-2 border-l border-gray-100 space-y-1">
            {cons.map((it,k)=><Item key={k} type="con" {...it} />)}
        </div>
    </div>
}