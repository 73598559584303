import React, {useContext} from 'react';
import {PopupMenu} from "../../../library/components/old/popup-menu";
import {Popover2} from "@blueprintjs/popover2";
import {EntityInfo} from "../../../library/utilities/localstorage/entity-localstorage";
import {CommunityContext} from "../community-context";
import {Avatar} from "../../../library/components/core";
import {MailIcon, PhoneIcon} from "@heroicons/react/outline";
import {getPhoneNumberData} from "../../../library/utilities/phone";
import {copyTextToClipboard} from "../../../library/utilities/general/copy-to-clipboard";
import {GlobalContext} from "../../global/global-context";
import {useHistory} from "react-router-dom";

function ContactItem({icon,label,addToast,value}) {
    return <div onClick={()=>{
        copyTextToClipboard(value,()=>{
            addToast({text:"Copied to clipboard"});
        })
    }} className="flex items-center space-x-2 hover:opacity-80 transition-opacity cursor-pointer">
        <div className="text-gray-500 h-svg-4">
            {icon}
        </div>
        <div className="text-gray-600 text-sm">
            {label}
        </div>
    </div>
}

function MemberCard({data,ch,history,addToast}) {
    const link = `/${ch}/member/${data.handle}`;
    const pn = data.phone ? getPhoneNumberData(data.phone) : "";
    return <div className="shadow-md w-96 rounded-xl border border-gray-100 bg-white max-w-xs">
        <div className="px-2.5 pt-2.5 pb-0.5">
            <div>
                <Avatar size="2xl" url={`${data.image}_medium?alt=media`} />
            </div>
            <div></div>
        </div>
        <div className="px-2.5 pb-2">
            <div className="text-base font-bold text-gray-800">
                <a onClick={()=>history.push(link)} className="hover:underline cursor-pointer">{data.name}</a>
            </div>
            <div className="text-sm -mt-0.5 text-gray-600">
                <a onClick={()=>history.push(link)} className="hover:underline cursor-pointer">@{data.handle}</a>
            </div>
            {data.bio&&<div className="text-sm leading-5 pt-1 text-gray-800">{data.bio}</div>}
        </div>
        <div className="px-2.5 pb-2 bg-gray-100 pt-2 space-y-1.5 rounded-b-xl">
            {data.email&&<ContactItem addToast={addToast} icon={<MailIcon />} label={data.email} value={data.email} />}
            {pn.display&&<ContactItem addToast={addToast} icon={<PhoneIcon />} label={pn.display} value={pn.edit} />}
        </div>

    </div>
}

function Container({community,history,addToast,id}) {
    return <EntityInfo community_uid={community.uid} type="member" id={id}>
<MemberCard history={history} ch={community.data.handle} addToast={addToast} />
    </EntityInfo>
}

export function MemberHoverCard({children, id}) {
    const community = useContext(CommunityContext);
    const global = useContext(GlobalContext);
    const history = useHistory();

    if (!id) {
        return <div>{children}</div>;
    }

    const content = <Container history={history} addToast={global.addToast} id={id} community={community} />;

    return <Popover2 interactionKind="hover" content={content} placement={'bottom-start'} minimal
                     popoverClassName='minimal-popover my-3'>
        {children}
    </Popover2>
}