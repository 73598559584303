import React from 'react';
import styled from "styled-components";

const mock = [
    {
        title: 'One',
        desc: 'Desc',
        value: '1'
    },
    {
        title: 'Two',
        desc: 'Desc',
        value: '2'
    },
    {
        title: 'Three',
        desc: 'Desc',
        value: '3'
    },
];

const Frame = styled.div`
`;

const Item = styled.div`
      border: 2px solid ${props=>props.active?'#feac89':'#e3e8ee'};
      background: ${props=>props.active?'#fff3ee':''};
    border-radius: 6px;
    padding: 6px 10px;
        display: flex;
    margin-bottom: 8px;
`;

const Title = styled.div`
color: ${props=>props.active?'#f96721':''};
      font-weight: 600;
    font-size: 15px;
`;

const Subtitle = styled.div`
color: ${props=>props.active?'#c7561d':''};
opacity: 0.85;
   font-size: 13px;
`;

const CircleCont = styled.div`
  width: 24px;
  min-width: 24px;
  display: flex;
  align-items: center;
`;

const RightBox = styled.div`
  display: flex;
 align-items: flex-start;
  flex-flow: column;
`;

const Circle = styled.div`
      height: 14px;
    width: 14px;
   border: 4px solid ${props=>props.active?'#f96721':'#e3e8ee'};
      background: ${props=>props.active?'#fff':''};
    border-radius: 50%;
`;

export const RadioGroup = ({options = [], onSelect = () => {}, value}) => {
    return <Frame>
        {options.map((opt,i) => {
            const active = opt.value === value;
            return <Item key={opt.value} className='hover-opacity' onClick={()=>{onSelect(opt.value);}} active={active}>
                <CircleCont>
                    <Circle active={active}/>
                </CircleCont>
                <RightBox>
                    <Title active={active} className='title text-gray-900'>
                        {opt.title}
                    </Title>
                    {opt.desc && <Subtitle active={active} className='subtitle text-gray-700'>
                        {opt.desc}
                    </Subtitle>}
                </RightBox>
            </Item>
        })}
    </Frame>
};