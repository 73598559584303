import React from "react";
import {
    AuthTitle,
    SplashContainer,
    SplashInner,
    SplashTextContainer
} from "../../layout/splash-container";
import {Link} from "react-router-dom";
export function PoliciesHome() {

    return <SplashContainer>
        <SplashInner>
            <SplashTextContainer>
                <AuthTitle>Policies</AuthTitle>
                <div>
                    <Link className='color-link' to='/policies/cookies'>Cookie Policy</Link>,&nbsp;
                    <Link className='color-link' to='/policies/privacy'>Privacy Policy</Link>,&nbsp;
                    <Link className='color-link' to='/policies/user-terms'>User Terms</Link>
                </div>
            </SplashTextContainer>
        </SplashInner>
    </SplashContainer>
}