import React, {useContext, useState} from 'react';
import {authFetch} from "../../../../config/network";
import {GlobalContext} from "../../../global/global-context";
import {PinboardList} from "../../../../library/components/pinboard/pinboard";
import {AddPinModal} from "../add-pin-modal";

export function GroupModalPinboardTab({community,setData=()=>{},getGroup=()=>{},cb=()=>{},id,email_list_power_up,group_data}) {
    const [modal,setModal] = useState("");
    const [modal_data,setModalData] = useState(null);
    const [loading,setLoading] = useState("");
    const global = useContext(GlobalContext);

    const community_uid = community.uid;

    function addPin(pin_id, pin_data, cb) {

        const payload = {
            community_uid: community_uid,
            pin_id,
            member_id: community.member_id,
            pin_data,
            id: id,
        };

        setModal(null);

        const res = () => {
            setTimeout(() => {
                getGroup(cb);
            }, 1000);

            global.addToast({text: 'Pin added', intent: 'success'});
        };

        const err = () => {
            global.addToast({text: 'Pin could not be added', intent: 'danger'});
        };

        authFetch("/groups/add-pin", res, err, "POST", {payload});
    }

    function editPin(pin_id, pin_data, cb) {

        const payload = {
            community_uid: community_uid,
            pin_id,
            member_id: community.member_id,
            pin_data,
            id: id,
        };

        setModal(null);

        const res = () => {
            setTimeout(() => {
                getGroup(cb);
            }, 1000);

            global.addToast({text: 'Pin updated', intent: 'success'});
        };

        const err = () => {
            global.addToast({text: 'Pin could not be updated', intent: 'danger'});
        };

        authFetch("/groups/edit-pin", res, err, "POST", {payload});
    }

    function setPins(pin_ids, cb) {
        const payload = {
            community_uid: community_uid,
            member_id: community.member_id,
            pin_ids: pin_ids,
            id: id,
        };

        setModal(null);
        const res = () => {
            setTimeout(() => {
                getGroup(cb);
            }, 1000);
            global.addToast({text: 'Pins updated', intent: 'success'});
        };

        const err = () => {
            global.addToast({text: 'Pins could not be updated', intent: 'danger'});
        };

        authFetch("/groups/set-pins", res, err, "POST", {payload});
    }

    function removePin(pin_id, cb) {

        const payload = {
            community_uid: community_uid,
            member_id: community.member_id,
            pin_id,
            id: id,
        };

        setModal(null);

        const res = () => {
            setTimeout(() => {
                getGroup(cb);
            }, 1000);
            global.addToast({text: 'Pin removed', intent: 'success'});
        };

        const err = () => {
            global.addToast({text: 'Pin could not be removed', intent: 'danger'});
        };

        authFetch("/groups/remove-pin", res, err, "POST", {payload});
    }

    let gpins = group_data.pins ? group_data.pins : [];
    let gpins_data = group_data.pins_data ? group_data.pins_data : {};

    return <div >
        <PinboardList pins={gpins} data_obj={{pins:gpins,pins_data:gpins_data}} pin_data={gpins_data} removePin={removePin}
                      setPins={setPins} goToPanel={()=>setModal('add-pin')} onEditPin={(id)=>{
                        setModalData(gpins_data[id]);
                        setModal('edit-pin');
                      }}
                      context={{community_uid: community_uid, member: community.member}}/>
        {modal==='add-pin'&&<AddPinModal onClose={()=>setModal(null)} onConfirm={addPin} pins_data={gpins_data} pins={gpins} addPin={addPin} />}
        {modal==='edit-pin'&&<AddPinModal confirm_text="Save changes" title="Edit pin" init_data={modal_data} onClose={()=>setModal(null)} onConfirm={editPin} pins_data={gpins_data} pins={gpins} addPin={editPin} />}
    </div>
}