import React, {useContext, useState} from "react";
import {Button, InfoState, Toggle} from "../../../core";
import {CheckIcon} from "@heroicons/react/solid";
import {GlobalContext} from "../../../../../app/global/global-context";
import {authFetch} from "../../../../../config/network";
import {CommunityContext} from "../../../../../app/community/community-context";
import {RemoteMdViewer} from "../../../../../app/policy-gates/remote-md-viewer";

function PolicyViewer({children}) {
    return <div className="absolute z-9999 -left-8 bg-white -right-8 -bottom-16 top-0">
        <div className="px-8">
        {children}
        </div>
    </div>
}
function Success() {
    return <div className='flex justify-center w-full pt-8'>
        <InfoState icon={<CheckIcon/>} title="All policies accepted!" intent="success"/>
    </div>
}

export function ObScReviewOwnerPolicies(props) {
    const global = useContext(GlobalContext);
    const community = useContext(CommunityContext);
    const [loading,setLoading] = useState('');
    const [consents,setConsent] = useState(false);
    let needs_to_accept = [];

    const global_owner_policies = Object.entries(global.policies_data).map(([id,pd])=>{
        return {
            id,
            ...pd
        }
    }).filter((a)=>{
        return a.gate === 'owner';
    });
    for(let i=0;i<global_owner_policies.length;i++) {
        const {version, name, link, link_german='', id} = global_owner_policies[i];
        if (version === ''||!link||!link_german) {
            // if no version is set, none to accept..
            continue;
        }
        const uid = `${id}__${version}`;
        if(!community.data.owner_policies||!community.data.owner_policies[uid]) {
            needs_to_accept.push({
                slug: id,
                version,
                link,
                link_german,
                name
            });
        }
    }

    if(needs_to_accept.length===0) {
        return <Success />;
    }

    function snapToTop() {
        let content_cont = document.getElementById('policy-viewer-container');
        if(content_cont) {
            content_cont.scrollTop = 0;
        }
    }

    function acceptPolicy(slug,version) {
        setLoading('accepting');
        const res = (result) => {
            setLoading('');
            setConsent(false);
            snapToTop();
        };

        const payload = {
            community_uid: community.uid,
            slug,version
        };

        authFetch("/owners/accept-policy", res, res, "POST", {payload});
    }

    const policy_to_accept = needs_to_accept[0] ? needs_to_accept[0] : null;

    if(!policy_to_accept) {
        return null;
    }

    return <PolicyViewer>
        <div id="policy-viewer-container" className="overflow-y-auto relative" style={{maxHeight:'340px',height: '340px'}}>
            <RemoteMdViewer border url={global.language==='de'?policy_to_accept.link_german:policy_to_accept.link} />
        </div>
        <div className="flex space-x-6 items-center p-4 border-t border-gray-200">
            <div className="flex-grow flex justify-end">
                <Toggle checked={consents} onToggle={(v)=>setConsent(v)} label={<>
                    I agree to the terms of the policy above.
                </>} />
            </div>
            <Button size="lg" disabled={!consents} loading={loading==='accepting'} onClick={()=>acceptPolicy(policy_to_accept.slug,policy_to_accept.version)} text="Accept & Continue" intent="secondary" />
        </div>
    </PolicyViewer>
}