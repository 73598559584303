import React from 'react';
import {Avatar} from "../../core";

export default function SGAvatarsPage() {

    return <div>
        <div className="text-lg font-semibold text-gray-700">
            Sizes
        </div>
        <div className="space-x-4">
         <Avatar size="xs" />
         <Avatar size="sm" />
         <Avatar size="md" />
         <Avatar size="lg" />
         <Avatar size="xl" />
        </div>
        <div className="text-lg font-semibold text-gray-700">
            Custom Symbols
        </div>
        <div className="space-x-4">
            <Avatar />
        </div>
    </div>
}