import React, {useContext,useEffect,useState} from "react";
import {useHistory} from 'react-router-dom';
import {setDocumentTitle} from "../../../library/utilities/set-document-title";
import {CommunityContext} from "../community-context";
import {
    MarkdownViewer,
    Button,
    EntityHeader,
    Meta, InfoState, Avatar
} from "../../../library/components/core";
import {FirebaseDB} from "../../../config/setup-firestore";
import {EntityInfo} from "../../../library/utilities/localstorage/entity-localstorage";
import {QuestionMarkCircleIcon,PencilAltIcon} from '@heroicons/react/solid';
import {StandardPageFramework} from "../../../library/playground/standard-page-framework";


function MetaItem({contact,history,handle,data}) {
    return <Meta items={[ {
        label: <span>Questions? <span onClick={()=>{
            if(contact.type==='roles') {
                history.push(`/${handle}/role/${contact.id}`);
            } else {
                history.push(`/${handle}/member/${data.handle}`);
            }
        }} className="font-medium text-gray-700 underline hover:text-gray-500 transition-colors cursor-pointer">{data.name}</span> can help.</span>,
            color: 'red-700',
            icon: <QuestionMarkCircleIcon/>
        }]} />
}

function SimpleMeta({contact,history,handle,community_uid}) {
    if(!contact||contact.id==="") {
        return null;
    }
    return <EntityInfo community_uid={community_uid} id={contact.id} type={contact.type}>
        <MetaItem handle={handle} contact={contact} history={history} />
    </EntityInfo>
}

export function PolicyViewer({slug,viewer_only=false,is_update=false,show_logo=false,can_edit=false}) {
    const community = useContext(CommunityContext);
    const [loading, setLoading] = useState("data");
    const history = useHistory();
    const [data,setData] = useState({});

    useEffect(function () {
        getPolicy();
    }, [community.uid,slug]);

    function getPolicy() {
        // add id

        FirebaseDB
            .collection('communities')
            .doc(community.uid)
            .collection('policies')
            .doc(slug)
            .get()
            .then(doc=>{
                setLoading('');
                setData({...doc.data()})
            })
    }

    const is_admin = community.is_admin;

    if(loading==='data'||(loading===''&&!data)) {
        return null;
    }

    if(!is_admin&&loading===''&&data.status==='draft') {
        return <InfoState subtitle="This policy is not yet published" />
    }

    if(viewer_only) {
        return <div className="px-4 pt-6 pb-6">
            {data.status==='published'&&<MarkdownViewer content={data.content} />}
        </div>
    }

    setDocumentTitle(data.name, community.data.name);

    let topbar_actions = null;

    if(is_admin&&can_edit) {
        topbar_actions = <div>
            <Button onClick={()=>history.push(`/${community.data.handle}/policies/${slug}/manage`)} text="Edit" left_icon={<PencilAltIcon/>} />
        </div>
    } else if(!can_edit&&show_logo) {
        topbar_actions = <div>
            <Avatar size="28px" type="community" text={community.data.name} url={community.data.profile_picture?`${community.data.profile_picture}_small?alt=media`:""} />
        </div>
    }

    // <Meta />
    const sidebar = <div className="px-2 py-4">
        <SimpleMeta handle={community.data.handle} history={history} community_uid={community.uid} contact={data.contact} />
    </div>;

    return (<StandardPageFramework content layout="sidebar" sidebar={sidebar}>
            <EntityHeader onClick={() => {
                const el = document.getElementById("main-content");
                el.scrollTo(0, 0);
            }} title={`${is_update?`Updated `:""}${data.name}`} actions={topbar_actions} />
            <div className="pt-4 px-4">
                {data.status==='published'&&<MarkdownViewer fill size="prose-sm" content={data.content} />}
            </div>
        </StandardPageFramework>
    );
}

export function CommunityPolicyPage(props) {

    const slug = props.match.params.slug;

    return <PolicyViewer viewer_only={false} can_edit={true} slug={slug} />
}