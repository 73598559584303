import React, {useEffect, useState} from 'react';
import {FirebaseDB} from "../../../../config/setup-firestore";

function SegmentItem({name, member}) {

    return <div className="flex space-x-2 items-center px-1 py-1">
        <div className="flex-grow">
            <div className="text-sm line-clamp-1 font-bold text-gray-800">{name}</div>
        </div>
        <div className="text-sm text-gray-700">

        </div>
    </div>
}

// this could be generalized table
export function AMSegmentsList({community_uid, id, member}) {
    const [loading, setLoading] = useState(true);
    const [items, setItems] = useState(null);

    useEffect(function () {
        getSegmentsForMember()
    }, []);

    function getSegmentsForMember() {
        FirebaseDB
            .collection('community_entities')
            .doc(community_uid)
            .collection('collections')
            .where(`member_ids.${id}`, '==', true)
            .limit(100)
            .get()
            .then(snap => {
                let items = [];
                snap.docs.forEach(doc => {
                    items.push({...doc.data(), id: doc.id});
                })
                setItems(items);
                setLoading(false);
            })
    }

    return <div className="space-y-1 pt-1.5 pb-1.5">
        {loading && !items && <div>Loading..</div>}
        {!loading && items && items.length === 0 && <div>
            No results found
        </div>}
        {items&&items.map(it => {
            return <SegmentItem key={it.id} {...it} />
        })}
    </div>
}