import React, {useState} from "react";
import {Button} from "../../../library/components/core";
import {PopupMenu} from "../../../library/components/old/popup-menu";
import {ChevronDownIcon} from "@heroicons/react/solid";
import {notInterested} from "../interests/shared";
import {Popover2} from "@blueprintjs/popover2";

export function InterestInterested({cb, id, can_leave, community, community_uid}) {
    const [loading, setLoading] = useState(false);

    let options = [];

    options.push({
        type: 'item',
        intent: 'danger',
        title: 'No longer interested',
        onClick: () => notInterested(id,loading,setLoading,community,cb)
    });

    return <Popover2 content={<PopupMenu items={options}/>} placement={'bottom-start'} minimal popoverClassName='minimal-popover'>
        <Button size="sm" intent="secondary" loading={loading} text='Interested' right_icon={<ChevronDownIcon/>}/>

    </Popover2>;
}