import React from 'react';
import {Field, Radio} from '../../core';

export default function SGRadioPage() {

    return <div className="space-y-4">

        <Field label="Label">
            <Radio/>
        </Field>
        <Field label="Label">
            <Radio multi value={[]} layout="boxes"/>
        </Field>
        <Field label="Mailing Lists">
            <Radio value={'5234'} layout="stacked-cards"/>
        </Field>
        <Field label="Community Storage">
            <Radio value={'1234'} layout="simple-table"/>
        </Field>
        <Field label="Mailing Lists">
            <Radio value={'1234'} layout="large-icons"/>
        </Field>
    </div>
}