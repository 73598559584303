import React, {useContext} from "react";
import styled from "styled-components";
import {AdminMemberPage} from "../../../app/community/admin/member";
import {XIcon} from "@heroicons/react/solid";
import AdminImport from "../../../app/community/admin/import";
import {SimpleOverlay} from "../simple-overlay";
import {useHistory} from "react-router-dom";
import {GlobalContext} from "../../../app/global/global-context";
import AdminRole from "../../../app/community/admin/role";
import MemberProfile from "../../../app/community/member";
import {NewMemberApplication} from "../../../app/community/admin/settings/application";

const Frame = styled.div`
  max-width: 1159px;
  width: 94%;
`;

const Btn = styled.div`
`;

// todo focus
export function FullScreenContentOverlay({
                                             onClose = () => {
                                             },
                                             eprops={},
                                             id = "",
                                             type = "member"
                                         }) {
    let content;
    const history = useHistory();
    const global = useContext(GlobalContext);
    function handleClose() {
        if(eprops.onClosing) {
            eprops.onClosing();
        }
        onClose();
    }
    if (type === 'member-import') {
        content = <AdminImport {...eprops} global={global} history={history}/>;
    } else if (type === 'member') {
        if (!id) {
            return null;
        }
        content = <AdminMemberPage {...eprops} scrollable={false} id={id}/>;
    } else if (type === 'role') {
        if (!id) {
            return null;
        }
        content = <AdminRole {...eprops} scrollable={false} id={id}/>;
    } else if(type==='member-application') {
        if (!id) {
            return null;
        }
        content = <NewMemberApplication {...eprops} scrollable={false} id={id}/>;
    }
    return <SimpleOverlay isOpen canEscapeKeyClose hasBackdrop canOutsideClickClose usePortal onClose={() => handleClose()}>

        <div className="w-full h-screen overflow-y-auto" onClick={() => handleClose()}>
            <Frame className="mx-auto py-8" onClick={e => e.stopPropagation()}>
                <div className="bg-white rounded-xl shadow-lg pt-2 pb-10" style={{minHeight: '200px'}}>
                    {content}
                </div>
            </Frame>
            <Btn onClick={() => handleClose()} style={{backgroundColor: '#575b5e'}}
                 className="z-9999 fixed top-4 right-4 h-10 flex items-center justify-center transition-opacity hover:bg-opacity-70 cursor-pointer w-10 rounded-full">
                <div className="color-white h-6 w-6">
                    <XIcon/>
                </div>
            </Btn>
        </div>
    </SimpleOverlay>;
}