import React, {createContext} from 'react';

const default_value = {
    data: null,
    handle: '',
    not_found: false,
    loading: false,
    all_teams: {},
    all_roles: {},
    uid: '',
    updateMember: ()=>{},
  entities: {teams:[],groups:[],roles:[]},
    admins: [], is_member: false, is_admin: false, member_types: {}, custom_fields: {}, member: null
};

export const CommunityContext = createContext(default_value);