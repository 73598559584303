import {
    ArchiveIcon,
    CheckCircleIcon,
    CheckIcon,
    ChevronDoubleUpIcon,
    CogIcon,
    FilterIcon,
    LightningBoltIcon
} from "@heroicons/react/solid";
import {IconLowerRightAddon} from "./index";
import React from "react";

export const RoleIcon = () => {
    return <IconLowerRightAddon secondary={<LightningBoltIcon />} />
}

export const AdminIcon = () => {
    return <IconLowerRightAddon secondary={<ChevronDoubleUpIcon />} />
}

export const ArchiveMemberIcon = () => {
    return <IconLowerRightAddon secondary={<ArchiveIcon />} />
}

export const MemberTypeIcon = () => {
    return <IconLowerRightAddon secondary={<FilterIcon />} />
}