import React, {useContext, useEffect, useState} from 'react';
import {InterestTag} from "../../../library/components/core";
import styled from "styled-components";
import {interests_data} from "../../../library/components/core/interests-data";
import {FirebaseDB} from "../../../config/setup-firestore";
import {CommunityContext} from "../community-context";
import {LocalSearchBar} from "../../../library/components/custom/local-search-bar";

async function getCustomCommunityInterests(community_uid) {
    return FirebaseDB
        .collection('communities')
        .doc(community_uid)
        .collection('interests')
        .limit(200)
        .get()
        .then(snap=>{
            return snap.docs.map(d=>{
                return {
                    id: d.id,
                    ...d.data()
                }
            })
        })
}

function buildAllInterests(dt,custom) {
    return [...dt.map(it=>{
        return {
            ...it,
            id: `default_${it.id}`
        }
    })].concat(custom.map(it=>{
        return {
            id: it.id,
            name: it.name,
            emoji: it.emoji,
            custom: true
        }
    }))
}

function buildShowInterests(all_interests,query) {
    if(!query) {
        return all_interests.sort((a,b)=>a.name>b.name?1:-1);
    }
    return all_interests.filter(interest=>{
        return interest.name.toLowerCase().indexOf(query.toLowerCase()) !== -1
    })
}

export function SelectInterests({title = "Interest Selector", onSelect=()=>{}, initial={}, max = 15}) {
    const [selected,setSelected] = useState({...initial});
    const community = useContext(CommunityContext);
    const [query,setQuery] = useState("");
    const [custom,setCustom] = useState([]);
    const len = Object.keys(selected).length;

    useEffect(function () {
        getCustomInterests()
    }, [])

    function getCustomInterests() {
        getCustomCommunityInterests(community.uid)
            .then(data=>{
                setCustom([...data]);
            })
    }

    function handleSelect(id) {
        let ns = selected;
        if(selected[id]) {
            delete ns[id];
        } else {
            if(len>(max-1)) {
                return;
            }
            ns[id] = true;
        }
        setSelected({...ns});
        onSelect(ns,id);
    }

    const all_interests = buildAllInterests(interests_data,custom);

    const show_interests = buildShowInterests(all_interests,query);

    return <div className="space-y-2">
        <div className="pb-1.5 max-w-md">
            <LocalSearchBar simple autoFocus onChange={q=>setQuery(q)} />
        </div>
        <div>
            <div className={`flex flex-wrap interest-tag-spacing pr-10`}>
                {show_interests.map((int,i)=><InterestTag onClick={()=>handleSelect(`${int.id}`)} onRemove={()=>{}} can_remove={false} active={selected[`${int.id}`]} key={int.id} {...int} />)}
            </div>
        </div>
    </div>
}