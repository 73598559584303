import React from "react";
import styled from "styled-components";
import {getSmallEntity} from "../../../library/utilities/localstorage/entity-localstorage";
import moment from "moment";
import {LinkIcon,ChevronUpIcon,FolderIcon} from "@heroicons/react/solid";
import {PrivateFolderIcon} from "../../../library/icons/custom/PrivateFolder";
import {SharedFolderIcon} from "../../../library/icons/custom/SharedFolder";
import {NewTooltip} from "../../../library/components/reorganize/new-tooltip";

const BodyRow = styled.div`
    border-bottom: 1px solid #f1f4f7;
    display: grid;
    grid-gap: 8px;
    grid-template-columns: 36px 50% 25% 10% 60px;
    cursor: pointer;
    :hover {
        background: #f8f9fb;
    }
`;

const Column = styled.div`
    display: flex;
    align-items: center;
`;

const IconCol = styled.div`
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    > svg {
        height: ${props=>props.small?"20px":"24px"};
    }
`;


const Title = styled.div`
    
`;

const Meta = styled.div`
    font-size: 13px;
        white-space: nowrap;
    overflow-x: hidden;
    text-overflow: ellipsis;
    max-width: 90%;
    overflow-y: hidden;
`;

export const FolderLoadingRow = () => {
    return <BodyRow>
        <IconCol>

        </IconCol>
        <Column className='text-gray-900'>
            <Title>
                Loading..
            </Title>
        </Column>
        <Column className='text-gray-700'>
            <Meta>
                —
            </Meta>
        </Column>
        <Column className='text-gray-700'>
            <Meta>
                —
            </Meta>
        </Column>
        <IconCol>

        </IconCol>
    </BodyRow>;
};

export const FolderEmptyRow = () => {
    return <BodyRow>
        <IconCol>

        </IconCol>
        <Column className='text-gray-900'>
            <Title>
                No files or folders found
            </Title>
        </Column>
        <Column className='text-gray-700'>
            <Meta>
                —
            </Meta>
        </Column>
        <Column className='text-gray-700'>
            <Meta>
                —
            </Meta>
        </Column>
        <IconCol>

        </IconCol>
    </BodyRow>;
};


export const FolderGoUp = ({community_handle,goUp,history,id}) => {

    return <BodyRow onClick={()=>{goUp();}}>
        <IconCol small>
            <ChevronUpIcon className='text-gray-800' />
        </IconCol>
        <Column className='text-gray-900'>
            <Title>
                Go up
            </Title>
        </Column>
        <Column className='text-gray-700'>
            <Meta>
                —
            </Meta>
        </Column>
        <Column className='text-gray-700'>
            <Meta>
                —
            </Meta>
        </Column>
        <IconCol>

        </IconCol>
    </BodyRow>;
};

const Action = styled.div`
        display: flex;
        justify-content: end;
            margin-left: 8px;
    svg {
        height: 18px;
    }
`;

export function getFolderIcon(type,security) {
    if(type==='group') {
        return <SharedFolderIcon />
    } else if(security==='private') {
        return <FolderIcon  />
        return <PrivateFolderIcon />
    } else if(security==='public') {
        return <FolderIcon  />
    } else {
        return null;
    }
}

export const MiniFolder = ({community_handle,security,clipboardCopy,user_uids,type,emoji,access_level,community_uid,name,updated_at,updated_by,history,id}) => {
    const entity = getSmallEntity(community_uid, 'member', updated_by, 'community_members');
    const path = `/${community_handle}/explore/drive/folder/${id}`;
    const date_str = `${moment(updated_at).format('MMM Do, YYYY')} by ${entity.name}`;
    return <BodyRow onClick={()=>{history.push(path);}}>
        <IconCol className="text-gray-600">
            {getFolderIcon(type,security)}
        </IconCol>
        <Column className='text-gray-900'>
            <div className="text-gray-700 flex items-center font-medium text-base truncate" title={name}>
                {name}
            </div>
        </Column>
        <Column className='text-gray-500'>
            <Meta title={date_str}>
                {date_str}
            </Meta>
        </Column>
        <Column className='text-gray-500'>
            <Meta>
                —
            </Meta>
        </Column>
        <IconCol style={{justifyContent:'right'}}>
            <NewTooltip message="Copy Link">
                <Action className='text-gray-600' onClick={(e)=>{e.stopPropagation();clipboardCopy(path);}}>
                    <LinkIcon/>
                </Action>
            </NewTooltip>
        </IconCol>
    </BodyRow>;
};