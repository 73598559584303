import {countries} from "../data/general/countries";
import {isValidHttpUrl} from "../components/pinboard/add-pin";

export function parseSocialUrl(v,base='twitter.com') {
    if(!v) {
        return '';
    }
    let val = v.toLowerCase();
    if(base==='eth_address') {
        return val;
    }
    if(isValidHttpUrl(val)) {
        return `__CUSTOM__${val}`;
    }
    if(val.indexOf(`https://${base}/`)!==-1) {
        return val;
    } else if(val.indexOf(`http://${base}/`)!==-1) {
        return val;
    } else if(val.indexOf(`${base}/`)!==-1) {
        return `https://${val}`;
    } else {
        return `https://${base}/${val.replace(/@/g,'')}`;
    }
}

export function cleanWebsiteForProfile(url) {
    let str = url;
    str = str.replace('https://','');
    str = str.replace('http://','');
    str = str.replace('www.','');
    str = str.replace(/\/$/,'');
    return str.split("/")[0].trim();
}

const display_social_pipeline = {
    'facebook': (v,bool)=>{
        let a = v.split("facebook.com/")[1];
        if(a) {
            return `${a.replace(/\//g,'')}`;
        }
        return bool?v:"";
    },
    'linkedin': (v)=>{
        let a = v.split("linkedin.com/in/")[1];
        if(a) {
            return `${a.replace(/\//g,'')}`;
        }
        return v;
    },
    'twitter': (v,bool)=>{
        if(v.includes('@')&&!v.includes('http')) {
            return `${v.replace(/\//g,'')}`
        }
        let a = v.split("twitter.com/")[1];
        if(a) {
            return `@${a.replace(/\//g,'')}`;
        }
        return bool?v:"";
    },
    'discord': (v,bool)=>{
        let a = v.split("discordapp.com/users/")[1];
        if(a) {
            return `${a.replace(/\//g,'')}`;
        }
        return bool?v:"";
    },
    'website': (v)=>{
        return formatWebsiteForProfile(v);
    },
    'skype': (v)=>{
       return v;
    },
    'instagram': (v,bool)=>{
        let a = v.split("instagram.com/")[1];
        if(a) {
            return `@${a.replace(/\//g,'')}`;
        }
        return bool?v:"";
    },
    'github': (v,bool)=>{
        let a = v.split("github.com/")[1];
        if(a) {
            return `@${a.replace(/\//g,'')}`;
        }
        return bool?v:"";
    },
    'reddit': (v,bool)=>{
        let a = v.split("reddit.com/user/")[1];
        if(a) {
            return `@${a.replace(/\//g,'')}`;
        }
        return bool?v:"";
    }
};

export function cleanSocialForProfile(url,social_type,bool) {

    if(!display_social_pipeline[social_type]) {
        return url;
    }
    return display_social_pipeline[social_type](url,bool);
}

export function formatWebsiteForProfile(str,bool) {
    if(!str) {
        return '';
    }
    if(bool) {
        return str.replace("https://","").replace("https://","").replace("www","");
    }
    if (str.startsWith('http')) {
        return str;
    } else {
        return `https://${str}`;
    }
}

export function formatTimestampAsString(ts){
    let a = new Date(ts);
    let year = a.getFullYear();
    let month = a.getMonth();
    let date = a.getDate();
    return formatDateParts(date,month,year);
}


export function buildAddress(a) {
    let str = '';

    if(!a) {
        return '';
    }

    if(a.line_one) {
        str += `${a.line_one}\n`;
    }
    if(a.line_two) {
        str += `${a.line_two}\n`;
    }
    if(a.city) {
        str += `${a.city}`;
    }
    if(a.city&&a.zip_code) {
        str += `, `;
    }
    if(a.zip_code) {
        str += `${a.zip_code}`;
    }
    if(a.city||a.zip_code) {
        str += `\n`;
    }
    if(a.state) {
        str += `${a.state}, `;
    }
    if(a.country) {
        str += `${countries[a.country]}`;
    }

    return str;
}

export function getAddressUrl(value) {
    return `http://maps.google.com/?q=${value.replace(' ', '+')}`;
}

function formatDateParts(date,month,year,format) {
    return ((month > 8) ? (month + 1) : ('0' + (month + 1))) + '/' + ((date > 9) ? date : ('0' + date)) + '/' + year
}

export function isValidDate(d) {
    return d instanceof Date && !isNaN(d);
}

export function isValidTimestamp(ts) {
return (new Date(ts)).getTime() > 0
}

export function formatDateAsString(date) {
    return formatDateParts(date.getDate(),date.getMonth(),date.getFullYear());
}