import React, {useState,useContext} from "react";
import {AdvancedModal} from "../../../library/components/reorganize/advanced-modal";
import {authFetch} from "../../../config/network";
import {group_modal_about} from "./create-group";
import {CommunityContext} from "../community-context";
import {analytics} from "firebase";

function buildData(community,type) {
    let obj = Object.assign({},JSON.parse(JSON.stringify(group_modal_about)));
    obj.context.community_uid = community.uid;
    obj.state.type = type;

    return obj;
}

export function CreateGroupController({create_filter, open, type, history, handle, closeModal, context={}}) {
    const community = useContext(CommunityContext);
    const [data,setData] = useState({});
    const [_loading,setLoading] = useState(false);
    const [phase,setPhase] = useState('about');

    function createFilter(p) {
        setLoading(true);

        const payload = {
            ...create_filter,
            name: `${p.name} Filter`,
            member_id: community.member_id,
            community_uid: community.uid
        };

        authFetch("/filters/create", (res)=>createGroup(p,res.data.id), ()=>{}, "POST", {payload});
    }

    function createGroup(p,filter_id='') {
        setLoading(true);

        // need to get the default interests
        const urlParams = new URLSearchParams(window.location.search);
        const interests = urlParams.get('interests');
        let new_interests = {};
        if(interests) {
            const arr = interests.split(',');
            for(let i=0;i<arr.length;i++) {
                new_interests[arr[i]] = true;
            }
        }

        const payload = {
            ...p,
            ...data['about'],
            filter_id,
            member_id: community.member_id,
            community_uid: community.uid,
            interests: new_interests
        };

        authFetch("/groups/create", handleResponse, handleError, "POST", {payload});
    }

    function handleResponse(resp) {
        actionHandler('created',{id:resp.data.id});

        analytics().logEvent('create_group', {
            event_id: 'create_group',
            content_type: 'group',
            community_uid: community.uid,
            content_id: resp.data.id
        });

        setLoading(false);
    }

    function handleError(error) {
        setLoading(false);
    }

    function actionHandler(action,payload) {
        let d;
        switch (action) {
            case 'proceed':
                if(create_filter&&create_filter.conditions.length>0) {
                    createFilter(payload)
                } else {
                    createGroup(payload);
                }
                return;
            case 'add-entities':
                d = data;
                d[phase] = payload;
                setData({...d});
                setPhase('add-entities');
                return;
            case 'cancel':
                closeModal();
                return;
            case 'close-modal':
                closeModal();
                return;
            case 'created':
                setData({});
                setPhase('about');
                history.push(`/${handle}/group/${payload.id}?action=add-members`);
                closeModal();
                return;
            default:
                return;
        }
    }

    const phase_data = buildData(community,type);

    return open ? <AdvancedModal _loading={_loading} context={context} actionHandler={(a,p)=>actionHandler(a,p)} phase_data={phase_data}  /> : null;
}