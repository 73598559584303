import React from 'react';
import {List} from '../../core';
export default function SGListsPage() {

    return <div>
        <div>
            <div>Simple Member List</div>
            <List />
        </div>
        <ul className="mt-8 space-y-5 lg:space-y-0 lg:grid lg:grid-cols-2 lg:gap-x-8 lg:gap-y-5">
            <li className="flex items-start lg:col-span-1">
                <div className="flex-shrink-0">

                    <svg className="h-5 w-5 text-green-400" xmlns="http://www.w3.org/2000/svg"
                         viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                        <path fillRule="evenodd"
                              d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                              clipRule="evenodd"/>
                    </svg>
                </div>
                <p className="ml-3 text-sm text-gray-700">
                    Private forum access
                </p>
            </li>

        </ul>
    </div>
}