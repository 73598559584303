import React from "react";
import styled from "styled-components";
import {getFileTypeIcon} from "../../old/file-uploader/helpers";
import {UploadProgressItem} from "./item";

// position: fixed;
const Wrapper = styled.div`
   
`;

const Frame = styled.div`
    position: fixed;
    bottom: 24px;
    right: 16px;
    z-index: 999;
`;

const Header = styled.div`
    padding: 12px 16px;
`;

const Title = styled.div`
    font-size: 15px;
    font-weight: 500;
`;

const Body = styled.div`
    max-height: 200px;
    min-height: 80px;
    overflow-y: auto;
`;

const mitems = {
  "key": {
      name: "Test file.png",
      type: "image/png",
      progress: 0.5
  }
};

export function UploadProgress({uploading,mock}) {

    let items = Object.entries(mock?mitems:uploading);

    if(items.length===0) {
        return null;
    }

    return <div className={`z-999 fixed right-8 bottom-6`}>
        <div className="rounded-xl bg-white shadow-lg w-96 max-w-lg">
        <div className='px-4 py-2'>
            <div className='text-gray-800 font-semibold'>Uploads</div>
        </div>
        <Body>
            {items.map(([id,val])=>{
                return <UploadProgressItem key={id} name={val.name} type={val.type} progress={val.progress} />
            })}
        </Body>
    </div>
    </div>;
}