import React from 'react';
import {Attachment} from '../../core';

export default function SGEmailComposerPage() {

    return <div className="space-y-4">
        <div>
        </div>
        <div>
            <Attachment can_remove />
        </div>
    </div>
}