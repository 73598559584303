import React from "react";
import {AdminMemberPage} from "./index";
import {StandardPageHeight} from "../../../../library/playground/standard-page-framework";

export function AdminMemberWrapper(props) {
    let id = props.match.params.id;

    return <StandardPageHeight full content>
        <AdminMemberPage scrollable={true} id={id} />
    </StandardPageHeight>
}