import React, {useState} from "react";
import styled from "styled-components";
import {UnatySVG} from "../../../../library/icons/unaty/logos";
import {XIcon,MenuAlt4Icon} from "@heroicons/react/solid";
import {SplashContainer, SplashInner} from "../splash-container";
import {Link} from "react-router-dom";

const Menu = styled.div`
    display: flex;
    padding: 20px 0;
`;

const Left = styled.div`
    flex-grow: 1;
`;

const Right = styled.div`
    
`;

const PopupMenu = styled.div`
    position: absolute;
    top: 26px;
    right: 48px;
    background: #fff;
    width: 260px;
    border-radius: 12px;
    z-index: 8;
    padding: 8px;
    display: grid;
    grid-gap: 4px;
       box-shadow: 0 10px 15px -3px rgba(0,0,0,.1),0 4px 6px -2px rgba(0,0,0,.05)!important;
`;

const Item = styled.div`
        padding: 8px 10px;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    border-radius: 4px;
    :hover {
        background-color: #efefef;
    }
    a {
    color: #112d41;
        display: block;
        text-decoration: none;
    }
`;

const MenuBox = styled.div`
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 12px;
    margin-top: 6px;
    cursor: pointer;
    :hover {
    background-color: #efefef;
    }
    svg {
        height: 32px;
    }
`;

const Logo = styled.div`
    a {
    display: flex;
    align-items: center;
    justify-content: center;
     height: 56px;
    width: 56px;
      cursor: pointer;
        color: #112D42;
    border-radius: 12px;
      :hover {
    background-color: #efefef;
    }
    }
    
    svg {
        height: 44px;
        
    }
`;

export default function LandingMenu(props) {
    const [open, toggleMenu] = useState(false);

    return (
        <SplashContainer>
            <SplashInner>
                <Menu>
                    <Left>
                        <Logo>
                            <Link to='/'>
                            <UnatySVG/>
                            </Link>
                        </Logo>
                    </Left>
                    <Right>
                        <MenuBox onClick={()=>toggleMenu(!open)}>
                            {open?<XIcon/>:<MenuAlt4Icon/>}
                        </MenuBox>
                    </Right>
                </Menu>
                {open && <PopupMenu>
                    <Item>
                        <Link onClick={()=>toggleMenu(false)} to='/sign-in'>
                            Sign In
                        </Link>
                    </Item>
                </PopupMenu>}
            </SplashInner>
        </SplashContainer>
    );
}