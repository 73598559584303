import React, {useContext} from "react";
import {CommunityContext} from "../../community-context";
import FilesFolder from "../../files/folder";
import {useLocation} from "react-router-dom";
// <FilesFolder base={`/${community.data.handle}/group/${props.id}/drive`} {...props} id={props.match.params.folderId} />
function getFolderId(location,community,group) {
    const pathname = location.pathname.split(`/group`)[1];

    if(pathname.indexOf('/folder/')!==-1) {
        return pathname.split("/folder/")[1].split('/')[0];
    }
    return group.folder_id;
}
export function GroupFilesWrapper(props) {
    const location = useLocation();
    const community = useContext(CommunityContext);
    const folder_id = getFolderId(location,community,props.group);

    return <div className="">
        <FilesFolder {...props} show_title={false} hide_go_up={props.group.folder_id===folder_id} group_top_level_folder={props.group.folder_id} base={`/${community.data.handle}/group/${props.id}/drive`} group_top_level={folder_id===props.group.folder_id} title_override={null} in_group id={folder_id} />
    </div>
}