import React, {useState} from 'react';
import styled from "styled-components";
import {LockClosedIcon} from '@heroicons/react/solid';
const Frame = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  grid-gap: 20px;
  margin-top: 20px;
`;

const Title = styled.div`
      font-size: 16px;
      margin-bottom: 2px;
    font-weight: 600;
`;

const Description = styled.div`
  font-size: 13px;
  > span > b {
    font-weight: 600;
  }
`;

const CheckIconBox = styled.div`
    color: #fff;
    height: 32px;
    display: inline-flex;
    align-items: center;
    width: 22px;
    margin-left: 6px;
    > svg {
        height: 17px;
    }
`;

const Shell = styled.div`
  position: relative;
  height: 34px;
  outline: none;
  
  opacity: ${props=>props.disabled?'0.4':''};
  :focus {
  box-shadow: rgba(0,0,0,0) 0px 0px 0px 0px, rgba(58,151,212,0.36) 0px 0px 0px 3px, rgba(0,0,0,0) 0px 0px 0px 0px, rgba(60,66,87,0.16) 0px 0px 0px 1px, rgba(0,0,0,0) 0px 0px 0px 0px, rgba(0,0,0,0) 0px 0px 0px 0px, rgba(0,0,0,0) 0px 0px 0px 0px;
  
  }
  transition: background-color 0.25s ease, border-color 0.25s ease, opacity 0.1s ease;
  cursor: ${props=>props.disabled?'no-drop':'pointer'};
    width: 60px;
    background-color: ${props=>props.active?'#109a60':'#efefef'};
    border-radius: 16px;
    border: 1px solid;
    border-color: ${props=>props.active?'#109a60':'#a6adbd'};
    :hover {
        opacity: 0.85;
    }
`;

const SwitchBox = styled.div`
display: flex;
    align-items: center;
`;

const Circle = styled.div`
position: absolute;
    background: ${props=>props.active?'#ffffff':'#828c9c'};
    height: 24px;
     transition: left 2s ease;
    width: 24px;
        top: 4px;
    left: ${props=>props.active?'auto':'5px'};
    right: ${props=>!props.active?'auto':'5px'};
    border-radius: 50%;
`;

function SwitchItem({on,toggle,disabled=false}) {
    return <Shell id={`switch-1`} disabled={disabled} active={on} onClick={()=>{if(!disabled){toggle('switch-1')}}}>
        {on&&<CheckIconBox><LockClosedIcon/></CheckIconBox>}
        <Circle active={on} />
    </Shell>
}

const defaults = {
    on: {
        title: 'Make Private',
        desc: `<span><b>This cannot be undone.</b> Your group...</span>`
    },
    off: {
        title: 'Make Private',
        desc: `<span>Blah blah</span>`
    }
};

export function InfoSwitch({texts=defaults,on=false,disabled=false,toggleOn=()=>{}}) {

    const text = on ? texts.on : texts.off;

    function toggleSwitch(id) {
        if(id) {
            document.getElementById(id).tabIndex = 1;
            document.getElementById(id).focus();
        }
        toggleOn(!on);
    }

    return <Frame>
            <div>
                <Title className='text-gray-900'>{text.title}</Title>
                <Description dangerouslySetInnerHTML={{__html: text.desc}} className='text-gray-700' />
            </div>
            <SwitchBox>
                <SwitchItem disabled={disabled} toggle={(id)=>toggleSwitch(id)} on={on} />
            </SwitchBox>
        </Frame>
}