import React, {useState, useEffect} from 'react';
import {Avatar} from "../../library/components/core/avatar";
import {Button, Input, Field, InfoState, InlineLoader} from "../../library/components/core";
import {PFFieldRenderer} from "./field-renderer";
import {useHistory} from 'react-router-dom';
import {CheckIcon, ClockIcon} from "@heroicons/react/solid";
import {authFetch, externalAuthFetch} from "../../config/network";

function getMemberValue(field,member) {
    const split = field.split('.');
    if(split[1]) {
       return member[split[0]][split[1]];
    } else {
        return member[split[0]];
    }
}

function buildSections(member,custom_fields) {
    let arr = [];
    arr.push({
        id: 'basic-info',
        title: 'Basic Info',
        fields: [
            {
                field: 'about.first_name',
                label: 'First Name',
                type: 'text',
                props: {
                    placeholder: "John"
                }
            },
            {
                field: 'about.last_name',
                label: 'Last Name',
                type: 'text',
                props: {
                    placeholder: "Muir"
                }
            },
            {
                field: 'about.middle_name',
                label: 'Middle Name',
                type: 'text',
                props: {
                    placeholder: ""
                }
            },
            {
                field: 'about.title',
                label: 'Title',
                type: 'text',
                props: {
                    placeholder: "Mr."
                }
            }
        ]
    });
    arr.push({
        id: 'contact-info',
        title: 'Contact Info',
        fields: [
            {
                field: 'contact.email',
                label: 'Email',
                type: 'email',
                props: {
                    placeholder: ""
                }
            },
            {
                field: 'contact.phone',
                label: 'Phone',
                type: 'phone',
                props: {
                    placeholder: ""
                }
            },
        ]
    });
    arr.push({
        id: 'address',
        title: 'Address',
        fields: [
            {
                field: 'address',
                label: 'Address',
                type: 'address',
                full_width: true
            }
        ]
    });
    return arr;
}

export function QuickProfileUpdate(props) {
    const [token, setToken] = useState(props.match.params.qpu_token);
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState('');
    const history = useHistory();
    const [error, setError] = useState('');
    const [changes, setChanges] = useState({});
    const [section, setSection] = useState(0);

    useEffect(function () {
        setToken(props.match.params.qpu_token);
        getData();
    }, [props.match.params.qpu_token]);

    function getData() {
        if(!token) {
            console.log('ERROR');
            return;
        }
        setLoading('data');
        const res = (res1) => {
            setLoading('');
            if(res1) {
                if(res1.data) {
                    if(res1.data.token_expired) {
                        setError('expired');
                    } else {
                        setData(res1.data);
                    }
                }
            }

        };

        const err = () => {
          setLoading('');
          alert('error');
        };

        const payload = {
            token
        };

        externalAuthFetch("/qpu-get-info", res, err, "POST", {payload});
    }

    if(error==='expired') {
        return <div className="p-12">
            <InfoState fill button={<Button intent="secondary" onClick={()=>history.push(`/`)} text={`Homepage`} />} icon={<ClockIcon/>} title="Token Expired" intent="warning"
            />
        </div>
    }

    if (!data) {
        return <div className="flex justify-center p-32">
            <InlineLoader />
        </div>
    }

    const {member, community, custom_fields} = data;

    const sections = buildSections(member,custom_fields);

    const len = sections.length;

    function handleProceed() {
if(section+1===len) {
    saveChanges();
    setSection(-1);
} else {
    setSection(section+1);
}
    }

    function saveChanges() {
        setLoading('changes');
        const res = (result) => {
            setLoading('');
        };

        const payload = {
            member_id: data.member_id,
            changes,
            token,
            community_uid: data.community_uid
        };

        externalAuthFetch("/qpu-update", res, res, "POST", {payload});
    }

    // ex.type
    function handleChange(f,v,ex) {
        let nd = data;
        let c = changes;

        let f1, f2;
        const split = f.split('.');

        f1 = split[0];

        if(split[1]) {
            f2 = split[1];
        }

        if(f2) {
            nd['member'][f1][f2] = v;
            c[f] = v;
        } else {
            nd['member'][f1] = v;
            c[f] = v;
        }

        setChanges({...c});
        setData({...nd});
    }

    const render_section = section === -1 ? [] : [sections[section]];

    return <div className="overflow-y-auto h-screen">
        <div className="container h-screen overflow-x-visible px-6 relative prose mx-auto flex flex-col">
            <div className="pb-8">
                {section!==-1&&<div className="pb-8 pt-6">
                    <div>
                        <Avatar url={community.profile_picture?`${community.profile_picture}_medium?alt=media`:''} text={community.name} size="xl"/>
                    </div>
                    <div className="mt-6 border-b border-gray-200 pb-4">
                        <div className="text-2xl mb-2 font-bold text-gray-800">Hey {member.about.first_name},</div>
                        <div className="text-lg text-gray-600">
                            To make sure all your information is up-to-date take a look at your profile below, thank
                            you!
                        </div>
                        <div className="text-sm pt-2 text-gray-600">
                            - Your {community.name} Admins
                        </div>
                    </div>
                </div>}
                <div className="pb-48">
                    {section===-1&&<div className="p-12">
                        <InfoState fill button={<Button intent="secondary" onClick={()=>history.push(`/${data.community.handle}/sign-in`)} text={`Log in to ${community.name}`} />} icon={<CheckIcon/>} title="You're all set!" intent="success"
                                   subtitle={`Thank you for helping us make sure we have all your information!`}
                        />
                    </div>}
                    {render_section.map((sec, k) => <div key={sec.id}>
                        <div className="text-lg mb-2 font-semibold text-gray-800">
                            {sec.title}
                        </div>
                        <div className="space-y-4 pb-8">
                            <div className="grid  grid-cols-1 sm:grid-cols-2 gap-4">
                                {sec.fields.map((field, f) => <div className={field.full_width?"col-span-2":""} key={field.field}>
                                    <PFFieldRenderer onChange={(v,ex)=>handleChange(field.field,v,ex)} type={field.type} label={field.label} input_props={field.props}
                                                     value={getMemberValue(field.field,member)}/>
                                </div>)}
                            </div>
                        </div>
                    </div>)}
                </div>
            </div>
            {section!==-1&&<div
                className="fixed flex items-end justify-end bottom-0 left-0 right-0 bg-white z-50 border-t border-gray-200">
                <div className="container prose mx-auto flex px-6 py-4">
                    <div className="sm:flex-grow flex-shrink text-gray-500 text-sm sm:text-base items-center flex pr-8">
                        Does your {render_section[0].title} look right to you?
                    </div>
                    <div className="flex-none">
                        <Button onClick={()=>handleProceed()} text="Yes, looks good" size="lg" intent="secondary"/>
                    </div>
                </div>
            </div>}
        </div>
    </div>
}