import React, {useEffect, useState} from 'react';
import {getPostDeliveryData, getSendStatus} from "../../../../library/components/posts-feed/api";
import {Avatar, SectionHeader, Tabs} from "../../../../library/components/core";
import {ESMembersList} from "../../../../library/components/reorganize/entity-select/item";
import {InboxPagination} from "../../../../library/components/posts-feed/home-wrapper";
import {aa_formatDate} from "../../../../library/utilities/general/dates";
import {DEFAULT_USER_PHOTO} from "../../../../config/defaults";
import {GeneralEntitiesList} from "../../../../library/components/general-entities-list";
import {MiniStatsRow} from "../../../../library/posts/mini-stats-row";
import {ViewMembersListModal} from "../../../../library/posts/view-members-list-modal";
import {GeneralDetailItem} from "../../../../library/playground/detail-modal";

function transformData(data) {
    let arr = [];

    const items = Object.entries(data.recipients);

    items.forEach(item => {
        const [email, {ts, member_id, status}] = item;

        if (member_id) {
            arr.push({
                email,
                member_id,
                ts,
                status
            });
        }
    });

    return arr.sort((a, b) => (a.ts < b.ts) ? 1 : -1);
}

function parseStatus(st) {
    if (st === 'delivered') {
        return 'Delivered'
    } else {
        return 'Could not be delivered'
    }
}

function MemberItem({data,metadata}) {
    const {loading,image,name} = data;
    let img, title, subtitle,action=null;
    if(loading) {
        img = ``;
        title = `-`;
    } else {
        img = `${image?image:DEFAULT_USER_PHOTO}_small?alt=media`;
        title = `${name}`;
        subtitle = `${metadata.email}`;
        action = <div className="text-xs text-gray-500 pt-2.5">{aa_formatDate(metadata.ts).full_date}</div>

    }
   return <GeneralDetailItem title={title} image={img} subtitle={subtitle} action={action} />
}

function getStatusOfSending(post,has_results) {
    if(!post.mailgun_sent_to_count) {
        return 'hidden'
    }
    if(!has_results) {
        return 'sending'
    }
    return 'delivered'
}

const per_page = 8

export function PostStatsDelivered({post, community_uid, list_id}) {
    const [data, setData] = useState(null);
    const [status, setStatus] = useState(null);
    const [modal, setModal] = useState(null);
    const [tab, setTab] = useState('');
    const [page, setPage] = useState(0);

    useEffect(function () {
        if (post && post.mailgun_message_id) {
            getPostDeliveryData(community_uid, post.id, post.mailgun_message_id)
                .then(dt => {
                    if (!dt) {
                        return;
                    }
                    setData(dt);
                });

            getSendStatus(community_uid,post.id,post.mailgun_message_id,list_id)
                .then(it=>{
                    if(!it) {
                        return;
                    }
                    setStatus(it);
                })
        }
    }, [post]);

    if (!data) {
        return null;
    }

    const t_data = transformData(data);

    if (t_data.length === 0) {
        return null;
    }

    const delivered = t_data.filter(a => a.status === 'delivered');
    const delivered_count = delivered.length;
    const failed = t_data.filter(a => a.status === 'permanent_fail');
    const failed_count = failed.length;

    const total_sent_to = post.mailgun_sent_to_count || 0;

    let sending_status = getStatusOfSending(post,delivered_count>0||failed_count>0);

    if(sending_status === 'hidden') {
        return null;
    } else if(sending_status === 'sending') {
        if(!total_sent_to) {
            return null;
        }
        return <div className="bg-gray-100 py-2 px-3 rounded-lg w-full">
            <SectionHeader title={`Notified ${total_sent_to} people`} padded={false} />
        </div>;
    }

    const total_count = total_sent_to;

    let stats = [
        {
            number: total_count,
            label: "Notified",
            onClick: () => setModal(1)
        },
        /*
        {
            number: delivered_count,
            label: "Delivered",
            onClick: () => setModal(2)
        },

         */
        {
            number: failed_count,
            label: "Errors",
            onClick: () => setModal(2)
        }
    ];

    let all_metadata = {};

    t_data.forEach(it=>{
        all_metadata[it.member_id] = it;
    })

    const tabs = [
        {
            label: `Notified ${total_count}`,
            type: "member-list",
            community_uid: community_uid,
            ids: t_data.map(it=>it.member_id),
            renderer: (data,id) => {
                return <MemberItem data={data} metadata={all_metadata[id]} />
            }
        },
        /*
        {
            label: `Delivered ${delivered_count}`,
            type: "member-list",
            community_uid: community_uid,
            ids: delivered.map(it=>it.member_id),
            renderer: (data,id) => {
                return <MemberItem data={data} metadata={all_metadata[id]} />
            }
        },

         */
        {
            label: `Errors ${failed_count}`,
            type: "member-list",
            community_uid: community_uid,
            ids: failed.map(it=>it.member_id),
            renderer: (data,id) => {
                return <MemberItem data={data} metadata={all_metadata[id]} />
            }
        }
    ];



    return <div className="bg-gray-100 py-2 px-3 rounded-lg w-full">
        <SectionHeader title="Email stats" padded={false} />
        <div className="">
            <MiniStatsRow stats={stats} />
        </div>
        {!!modal&&<ViewMembersListModal onClose={()=>{
            setModal(null);
        }} title={`Email stats`}
                                      start_tab={(modal-1)}
                              tabs={tabs}
        />}
    </div>
}