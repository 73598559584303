import React from "react";
import styled from "styled-components";
import TwitterIcon from "../../../icons/social/Twitter";
import FacebookIcon from "../../../icons/social/Facebook";
import LinkedInIcon from "../../../icons/social/LinkedIn";
import {
    BriefcaseIcon,
    CakeIcon, CalendarIcon,
    FlagIcon, GlobeAltIcon,
    HeartIcon,
    LinkIcon,
    LocationMarkerIcon, LockClosedIcon,
    MailIcon,
    PhoneIcon, UsersIcon
} from "@heroicons/react/outline";
import {CIInterests} from "../../../icons/custom/Interests";
import {cleanSocialForProfile, cleanWebsiteForProfile} from "../../../utilities/shared";
import GithubIcon from "../../../icons/social/Github";
import InstagramIcon from "../../../icons/social/Instagram";
import EthIcon from "../../../icons/social/Eth";
import SkypeIcon from "../../../icons/social/Skype";
import RedditIcon from "../../../icons/social/Reddit";
import DiscordIcon from "../../../icons/social/Discord";
import {copyTextToClipboard} from "../../../utilities/general/copy-to-clipboard";
import {APP_BASE_URL} from "../../../../config/defaults";

const SI = styled.div`
       padding-top: 2px;
    svg {
        height: 16px;
    }
`;

const AnchorLink = styled.a`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 180px;
    display: block;
`;

const Emoji = styled.div`

`;

const IconFrame = styled.div`
      
    > svg {
        height: 18px;
    }
`;

const Icon = styled.div`
      
    > svg {
        height: 20px;
      fill: #f15f31;
    }
`;

function cleanLink(l) {
    let a = l.replace("__CUSTOM__", "");
    return a;
}

function getIcon(type,link) {
    switch (type) {
        case 'new-location' : {
            if(link.emoji&&link.emoji!==null) {
                return <Emoji className="leading-6 emoji-location">
                    {link.emoji}
                </Emoji>
            } else {
                return <LocationMarkerIcon/>
            }
        }
        case 'occupation' : {
                return <BriefcaseIcon/>
        }
        case 'email' : {
            return <MailIcon/>
        }
        case 'phone' : {
            return <PhoneIcon/>
        }
        case 'birthday' : {
            return <CakeIcon/>
        }
        case 'open-group' : {
            return <GlobeAltIcon/>
        }
        case 'closed-group' : {
            return <LockClosedIcon/>
        }
        case 'website' : {
            return <LinkIcon/>
        }
        case 'members-count' : {
            return <UsersIcon/>
        }
        case 'APP-uml-mailing-list' : {
            return <MailIcon/>
        }
        case 'founded' : {
            return <FlagIcon/>
        }
        case 'interests' : {
            return <Icon className={`w-4`}>
                <CIInterests />
            </Icon>
        }
        case 'url-twitter' : {
            return <SI className='hover-opacity' onClick={()=>{window.open(cleanLink(link),'_blank')}}><TwitterIcon/></SI>
        }
        case 'url-facebook' : {
            return <SI className='hover-opacity' onClick={()=>{window.open(cleanLink(link),'_blank')}}><FacebookIcon/></SI>
        }
        case 'url-linkedin' : {
            return <SI className='hover-opacity' onClick={()=>{window.open(cleanLink(link),'_blank')}}><LinkedInIcon/></SI>
        }
        case 'url-reddit' : {
            return <SI className='hover-opacity' onClick={()=>{window.open(cleanLink(link),'_blank')}}><RedditIcon/></SI>
        }
        case 'url-github' : {
            return <SI className='hover-opacity' onClick={()=>{window.open(cleanLink(link),'_blank')}}><GithubIcon/></SI>
        }
        case 'url-discord' : {
            return <SI className='hover-opacity' onClick={()=>{window.open(cleanLink(link),'_blank')}}><DiscordIcon/></SI>
        }
        case 'url-instagram' : {
            return <SI className='hover-opacity' onClick={()=>{window.open(cleanLink(link),'_blank')}}><InstagramIcon/></SI>
        }
        case 'url-eth_address' : {
            return <SI className='hover-opacity' onClick={()=>{
                copyTextToClipboard(link)
            }}><EthIcon/></SI>
        }
        case 'url-skype' : {
            return <SI className='hover-opacity' onClick={()=>{window.open(cleanLink(link),'_blank')}}><SkypeIcon/></SI>
        }
        case 'joined' : {
            return <CalendarIcon/>
        }
        case 'created' : {
            return <CalendarIcon/>
        }
        default : {
            return null;
        }
    }
}

function getValue({type,value,meta}) {

    if(type.startsWith('url-')) {
        if(value.startsWith("__CUSTOM__")) {
            return null;
        }
        if(type==='url-skype'||type==='url-eth_address') {
            return <span onClick={()=>{
                copyTextToClipboard(`${value}`);
            }} className="text-secondary-700 cursor-pointer">{cleanSocialForProfile(value,type.split('url-')[1])}</span>
        }
        return <AnchorLink className='text-secondary-700' href={value} target={'_blank'}>{cleanSocialForProfile(value,type.split('url-')[1])}</AnchorLink>
    }

    switch (type) {
        case 'new-location' : {
            return value.emoji ? (value.text?value.text:'') : value.text ? value.text : value ? value : "";
        }
        case 'occupation' : {
            return value.text ? value.text : value ? value : "";
        }
        case 'birthday' : {
            return `Born ${value}`
        }
        case 'interests' : {
            return value;
        }
        case 'founded' : {
            return `Founded ${value}`
        }
        case 'website' : {
            return <AnchorLink className='text-secondary-700' href={value} target={'_blank'}>{cleanWebsiteForProfile(value)}</AnchorLink>
        }
        case 'url' : {
            return <AnchorLink className='text-secondary-700' href={value} target={'_blank'}>{cleanWebsiteForProfile(value)}</AnchorLink>
        }
        case 'joined' : {
            return `Joined ${value}`
        }
        case 'created' : {
            return `Created ${value}`
        }
        case 'email': {
            return <a className='text-gray-7005' href={`mailto:${value}`}>{value}</a>;
        }
        case 'phone': {
            return <a className='text-gray-7005' href={`${meta?meta:`tel:${value}`}`}>{value}</a>;
        }
        default : {
            return value?`${value}`:null;
        }
    }
}

export function PCMeta({meta=[]}) {
    if(meta.length===0) {
        return null;
    }
    return <div className="flex flex-wrap pt-2 -mb-1">
        {meta.map((item,i)=>{
            const text = getValue(item);
            return <div className="flex items-center h-6 mr-3.5 mb-1" key={i}>
                <div className='text-gray-500 h-5 w-4 flex items-baseline'>
                    <IconFrame className="" style={{height:'1.1rem',width:`1.2rem`}}>
                    {getIcon(item.type, item.value)}
                    </IconFrame>
                </div>
                {text&&<div className={`text-gray-500 ml-2 truncate`} style={{fontSize:'14px',maxWidth:'200px'}}>
                    {text}
                </div>}
            </div>
        })}
    </div>
}