import {analytics} from "firebase";
import {logEngagement} from "../../../api/analytics";
import {authFetch} from "../../../config/network";
import {convertToRaw} from "draft-js";

export function composer_handleSendDataCheck(payload, {community},res) {
    analytics().logEvent('send_data_check', {
        event_id: 'send_data_check',
        content_type: 'email',
        community_uid: community.uid,
        content_id: community.member.user_uid
    });

    logEngagement("send_data_check", {
        community_uid: community.uid,
        member_id: community.member_id,
        user_uid: community.member.user_uid
    });

    const final_payload = {
        ...payload,
        community_uid: community.uid,
        member_id: community.member_id,
        user_uid: community.member.user_uid,
        settings: payload.settings,
        raw: payload.raw,
        subject: payload.subject,
        ids: payload.to_data.map(a => {
            let o = {};
            o.id = a.id ? a.id : a.value;
            return o;
        })
    }

    authFetch("/composer/send-data-check", res, res, "POST", {payload:final_payload});
}