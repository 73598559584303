import React from 'react';

function getColor(intent) {
    switch (intent) {
        case 'success':
            return '#2d9665';
        case 'secondary':
            return '#2F80ED';
        case 'primary':
            return '#F15F31';
        case 'danger':
            return '#d84949';
        default:
            return 'gray';
    }
}

export function SimpleProgressBar({
    value = 0,
    intent = 'secondary',
                                      stripes = false
                                  }) {

    const containerStyles = {
        height: 16,
        width: 'auto',
        backgroundColor: "#e0e0de",
        borderRadius: 32
    }

    const fillerStyles = {
        width: `${value*100}%`,
        backgroundColor: getColor(intent)
    }

    return <div style={containerStyles} className={`${stripes?"simple-progress-bar-animate":""} simple-progress-bar`}>
        <span style={fillerStyles}><span></span></span>
    </div>
}