import React from "react"; 

export function UserTermsText() {
    return <div>
            <div className="normal-secondary-header" >Last updated: May 2nd, 2018</div>

            <div>
                These User Terms of Service (the "User Terms") govern your access and use of our online
                engagement tools and platform (the "Services"). Please read them carefully. Even though you are
                signing onto an existing Community, these User Terms apply to you as a user of the Services. We
                are happy you're here.
            </div>
            <div className="ui header">
                First things First
            </div>
            <div>
                <strong>These User Terms are Legally Binding</strong>
            </div>
            <div>
                These User Terms are a legally binding contract between you and us. As part of these User Terms,
                you agree to comply with the most recent version of our Acceptable Use Policy, which is
                incorporated by reference into these User Terms. If you access or use the Services, or continue
                accessing or using the Services after being notified of a change to the User Terms or the
                Acceptable Use Policy, you confirm that you have read, understand and agree to be bound by the
                User Terms and the Acceptable Use Policy. "We", "our" and "us" currently refers to the
                applicable Unaty entity in the Contract (defined below).
            </div>
            <div className="ui header">
                Customer's Choices and Instructions
            </div>
            <div>
                <strong>You are an Authorized User on a Community Controlled by a "Customer"</strong>
            </div>
            <div>
                An organization or other third party that we refer to in these User Terms as "Customer" has
                invited you to a Community (i.e., a unique domain where a group of users may access the
                Services, as further described in our Help Center pages). If you are joining one of your
                association's Communities, for example, Customer is your association. If you are joining a
                Community created by your friend using her personal email address to work on her new charity
                idea, she is our Customer and she is authorizing you to join her Community.
            </div>
            <div className="ui header">
                What This Means for You - and for Us
            </div>
            <div>
                Customer has separately agreed to our Customer Terms of Service or entered into a written
                agreement with us (in either case, the "Contract") that permitted Customer to create and
                configure a Community so that you and others could join (each invitee granted access to the
                Services, including you, is an "Authorized User"). The Contract contains our commitment to
                deliver the Services to Customer, who may then invite Authorized Users to join its
                Community/ies). When an Authorized User (including, you) submits content or information to the
                Services, such as messages or files ("Customer Data"), you acknowledge and agree that the
                Customer Data is owned by Customer and the Contract provides Customer with many choices and
                control over that Customer Data. For example, Customer may provision or deprovision access to
                the Services, enable or disable third party integrations, manage permissions, retention and
                export settings, transfer or assign Communities, share channels, or consolidate your Community
                or channels with other Communities or channels, and these choices and instructions may result in
                the access, use, disclosure, modification or deletion of certain or all Customer Data. Please
                check out our Help Center pages for more detail on our different Service plans and the options
                available to Customer.
            </div>
            <div className="ui header">
                The Relationship Between You, Customer and Us
            </div>
            <div>
                AS BETWEEN US AND CUSTOMER, YOU AGREE THAT IT IS SOLELY CUSTOMER'S RESPONSIBILITY TO (A) INFORM
                YOU AND ANY AUTHORIZED USERS OF ANY RELEVANT CUSTOMER POLICIES AND PRACTICES AND ANY SETTINGS
                THAT MAY IMPACT THE PROCESSING OF CUSTOMER DATA; (B) OBTAIN ANY RIGHTS, PERMISSIONS OR CONSENTS
                FROM YOU AND ANY AUTHORIZED USERS THAT ARE NECESSARY FOR THE LAWFUL USE OF CUSTOMER DATA AND THE
                OPERATION OF THE SERVICES; (C) ENSURE THAT THE TRANSFER AND PROCESSING OF CUSTOMER DATA UNDER
                THE CONTRACT IS LAWFUL; AND (D) RESPOND TO AND RESOLVE ANY DISPUTE WITH YOU AND ANY AUTHORIZED
                USER RELATING TO OR BASED ON CUSTOMER DATA, THE SERVICES OR CUSTOMER'S FAILURE TO FULFILL THESE
                OBLIGATIONS. UNATY MAKES NO REPRESENTATIONS OR WARRANTIES OF ANY KIND, WHETHER EXPRESS OR
                IMPLIED, TO YOU RELATING TO THE SERVICES, WHICH ARE PROVIDED TO YOU ON AN "AS IS" AND "AS
                AVAILABLE" BASIS.
            </div>
            <div className="ui header">
                A Few Ground Rules
            </div>
            <div>
                <strong>You Must be Over the Legal Age</strong>
            </div>
            <div>
                To the extent prohibited by applicable law, the Services are not intended for and should not be
                used by anyone under the age of sixteen. You represent that you are over the legal age and are
                the intended recipient of Customer's invitation to the Services. You may not access or use the
                Services for any purpose if either of the representations in the preceding sentence is not true.
                Without limiting the foregoing, you must be of legal working age.
            </div>
            <div className="ui header">
                While You Are Here, You Must Follow the Rules
            </div>
            <div>
                To help ensure a safe and productive work environment, all Authorized Users must comply with our
                Acceptable Use Policy and remain vigilant in reporting inappropriate behavior or content to
                Customer and us.
            </div>
            <div className="ui header">
                You are here at the will of the Customer (and Us)
            </div>
            <div>
                These User Terms remain effective until Customer's subscription for you expires or terminates,
                or your access to the Services has been terminated by Customer or us. Please contact Customer if
                you at any time or for any reason wish to terminate your account, including due to a
                disagreement with any updates to these User Terms or the Acceptable Use Policy.
            </div>
            <div className="ui header">
                Limitation of Liability
            </div>
            <div>
                If we believe that there is a violation of the Contract, User Terms, the Acceptable Use Policy,
                or any of our other policies that can simply be remedied by Customer's removal of certain
                Customer Data or taking other action, we will, in most cases, ask Customer to take action rather
                than intervene. We may directly step in and take what we determine to be appropriate action
                (including disabling your account) if Customer does not take appropriate action or we believe
                there is a credible risk of harm to us, the Services, Authorized Users, or any third parties. IN
                NO EVENT WILL YOU OR WE HAVE ANY LIABILITY TO THE OTHER FOR ANY LOST PROFITS OR REVENUES OR FOR
                ANY INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL, COVER OR PUNITIVE DAMAGES HOWEVER CAUSED,
                WHETHER IN CONTRACT, TORT OR UNDER ANY OTHER THEORY OF LIABILITY, AND WHETHER OR NOT THE PARTY
                HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. UNLESS YOU ARE ALSO A CUSTOMER (AND WITHOUT
                LIMITATION TO OUR RIGHTS AND REMEDIES UNDER THE CONTRACT), YOU WILL HAVE NO FINANCIAL LIABILITY
                TO US FOR A BREACH OF THESE USER TERMS. OUR MAXIMUM AGGREGATE LIABILITY TO YOU FOR ANY BREACH OF
                THE USER TERMS IS ONE HUNDRED DOLLARS ($100) IN THE AGGREGATE. THE FOREGOING DISCLAIMERS WILL
                NOT APPLY TO THE EXTENT PROHIBITED BY APPLICABLE LAW AND DO NOT LIMIT EITHER PARTY'S RIGHT TO
                SEEK AND OBTAIN EQUITABLE RELIEF.
            </div>
            <div className="ui header">
                Application of Consumer Law
            </div>
            <div>
                Unaty is a professional tool intended for use by organizations and businesses and not for
                consumer purposes. To the maximum extent permitted by law, you hereby acknowledge and agree that
                consumer laws do not apply. If however any consumer laws (e.g., in Australia, the Competition
                and Consumer Act 2010 (Cth)) do apply and cannot otherwise be lawfully excluded, nothing in
                these User Terms will restrict, exclude or modify any statutory warranties, guarantees, rights
                or remedies you have, and our liability is limited (at our option) to the replacement, repair or
                resupply of the Services or the pro-rata refund to Customer of pre-paid fees for your
                subscription covering the remainder of the term.
            </div>
            <div className="ui header">
                Survival
            </div>
            <div>
                The sections titled "The Relationship Between You, Customer, and Us," "Limitation of Liability,"
                and "Survival," and all of the provisions under the general heading "General Provisions" will
                survive any termination or expiration of the User Terms.
            </div>
            <div className="ui header">
                General Provisions
            </div>
            <div>
                <strong>Email and Unaty Messages</strong><br/>
                Except as otherwise set forth herein, all notices under the User Terms will be by email,
                although we may instead choose to provide notice to Authorized Users through the Services (e.g.,
                a Unatybot notification). Notices to Unaty should be sent to <a href="mailto:hey@getunaty.com">hey@getunaty.com</a>, except for legal
                notices, which must be sent to admin@unaty.de. A notice will be deemed to have been duly given
                (a) the day after it is sent, in the case of a notice sent through email; and (b) the same day,
                in the case of a notice sent through the Services. Notices under the Contract will be delivered
                solely to Customer in accordance with the terms of that agreement.
            </div>
            <div className="ui header">
                Privacy Policy
            </div>
            <div>
                Please review our Privacy Policy for more information on how we collect and use data relating to
                the use and performance of our products.
            </div>
            <div className="ui header">
                Modifications
            </div>
            <div>
                As our business evolves, we may change these User Terms or the Acceptable Use Policy. If we make
                a material change to the User Terms or the Acceptable Use Policy, we will provide you with
                reasonable notice prior to the change taking effect either by emailing the email address
                associated with your account or by messaging you through the Services. You can review the most
                current version of the User Terms at any time by visiting this page, and by visiting the
                following for the most current versions of the other pages that are referenced in these User
                Terms: Acceptable Use Policy and Privacy Policy. Any material revisions to these User Terms will
                become effective on the date set forth in our notice, and all other changes will become
                effective on the date we publish the change. If you use the Services after the effective date of
                any changes, that use will constitute your acceptance of the revised terms and conditions.
            </div>
            <div className="ui header">
                Waiver
            </div>
            <div>
                No failure or delay by either party in exercising any right under the User Terms, including the
                Acceptable Use Policy, will constitute a waiver of that right. No waiver under the User Terms
                will be effective unless made in writing and signed by an authorized representative of the party
                being deemed to have granted the waiver.
            </div>
            <div className="ui header">
                Severability
            </div>
            <div>
                The User Terms, including the Acceptable Use Policy, will be enforced to the fullest extent
                permitted under applicable law. If any provision of the User Terms is held by a court of
                competent jurisdiction to be contrary to law, the provision will be modified by the court and
                interpreted so as best to accomplish the objectives of the original provision to the fullest
                extent permitted by law, and the remaining provisions of the User Terms will remain in effect.
            </div>
            <div className="ui header">
                Assignment
            </div>
            <div>
                You may not assign any of your rights or delegate your obligations under these User Terms,
                including the Acceptable Use Policy, whether by operation of law or otherwise, without the prior
                written consent of us (not to be unreasonably withheld). We may assign these User Terms in their
                entirety (including all terms and conditions incorporated herein by reference), without your
                consent, to a corporate affiliate or in connection with a merger, acquisition, corporate
                reorganization, or sale of all or substantially all of our assets.
            </div>
            <div className="ui header">
                Governing Law; Venue; Waiver of Jury Trial; Fees
            </div>
            <div>
                The User Terms, including the Acceptable Use Policy, and any disputes arising out of or related
                hereto, will be governed exclusively by the same applicable governing law of the Contract,
                without regard to conflicts of laws rules or the United Nations Convention on the International
                Sale of Goods. The courts located in the applicable venue of the Contract will have exclusive
                jurisdiction to adjudicate any dispute arising out of or relating to the User Terms, including
                the Acceptable Use Policy, or its formation, interpretation or enforcement. Each party hereby
                consents and submits to the exclusive jurisdiction of such courts. Each party also hereby waives
                any right to jury trial in connection with any action or litigation in any way arising out of or
                related to the Contract. In any action or proceeding to enforce rights under the User Terms, the
                prevailing party will be entitled to recover its reasonable costs and attorney's fees.
            </div>
            <div>
                Each party hereby consents and submits to the exclusive jurisdiction of such courts. Each party
                also hereby waives any right to jury trial in connection with any action or litigation in any
                way arising out of or related to the User Terms. In any action or proceeding to enforce rights
                under the User Terms, the prevailing party will be entitled to recover its reasonable costs and
                attorney's fees.
            </div>
            <div className="ui header">
                Entire Agreement
            </div>
            <div>
                The User Terms, including any terms incorporated by reference into the User Terms, constitute
                the entire agreement between you and us and supersede all prior and contemporaneous agreements,
                proposals or representations, written or oral, concerning its subject matter. To the extent of
                any conflict or inconsistency between the provisions in these User Terms and any pages
                referenced in these User Terms, the terms of these User Terms will first prevail; provided,
                however, that if there is a conflict or inconsistency between the Contract and the User Terms,
                the terms of the Contract will first prevail, followed by the provisions in these User Terms,
                and then followed by the pages referenced in these User Terms (e.g., the Privacy Policy).
            </div>
            <div>
                Customer will be responsible for notifying Authorized Users of those conflicts or
                inconsistencies and until such time the terms set forth herein will be binding.
            </div>
            <div className="ui header">
                Contacting Unaty
            </div>
            <div>
                Please also feel free to contact us if you have any questions about Unaty's User Terms of
                Service. You may contact us at <a href="mailto:hey@getunaty.com">hey@getunaty.com</a> or at our mailing address below:
            </div>
            <div>
                <strong>Unaty Technologies GmbH</strong><br/>
                Unertlstrasse 27<br/>
                D-80803 Munich<br/>
                Germany
            </div>

        </div>;
}