import React from 'react';
import {FeedItem} from "../../core";
import {UsersGrid} from "../../core/users-grid";

export default function SGCustomPage() {

    return <div className="space-y-4">
       <div>
           <UsersGrid layout={'hscroll'} />

       </div>
    </div>
}