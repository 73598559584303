import React, {useContext} from 'react';
import {Redirect, Route, Switch, useHistory} from "react-router-dom";
import {CommunityContext} from "../../../community-context";
import {EntityHeader, PageHeader, Tabs} from "../../../../../library/components/core";
import {PageContainer} from "../../../../../library/components/old/page-container";
import {CustomizeEmojis} from "./emojis";
import SettingsMembers from "./members";
import SettingsPreferencesOld from "./general";
import SettingsPreferencesTeams from "./teams";
import {StandardPageHeight} from "../../../../../library/playground/standard-page-framework";

const tabs = [
    {
        path: '/:handle/admin/settings/preferences/members',
        comp: SettingsMembers,
        label: 'Members'
    },
    {
        path: '/:handle/admin/settings/preferences/leaders',
        comp: SettingsPreferencesTeams,
        label: 'Leaders'
    },
    {
        path: '/:handle/admin/settings/preferences/emojis',
        comp: CustomizeEmojis,
        label: 'Emojis'
    },
    {
        path: '/:handle/admin/settings/preferences/general',
        comp: SettingsPreferencesOld,
        label: 'General'
    },
];

export default function SettingsPreferences(props) {
    const community = useContext(CommunityContext);
    const {handle} = community.data;
    const history = useHistory();
    const current_path = history.location.pathname;
    const selected = tabs.map((t)=>{
        return {
            ...t,
            path: t.path.replace(':handle',handle)
        }
    }).filter(function (a) {
        return a.path === current_path;
    })[0];
    return <StandardPageHeight full content>
        <EntityHeader goBackTo={()=>history.push(`/${community.data.handle}/admin/settings`)} title="Preferences" />

        <div>
            <div className="">
                <Tabs active={selected?selected.label:""} onSelect={(nl)=>{
                    const new_selection = tabs.filter(function (a) {
                        return a.label === nl;
                    })[0];
                    history.push(new_selection.path.replace(':handle',handle));
                }} intent="secondary" tabs={tabs.map(t=>{
                    return t.label
                })} layout="standard-bottom"/>
            </div>
        </div>

        <div>
            <Switch>
                {tabs.map(t=><Route path={t.path} key={t.path} exact component={t.comp}/>)}
                <Redirect from='/*' to={`/${handle}/admin/settings/preferences/members`}/>
            </Switch>
        </div>
    </StandardPageHeight>
}