import React, {useState} from "react";
import {Button, Input} from "../library/components/core";
import {authFetch} from "../config/network";
import {LanguageSelect} from "../library/components/old/selectors/language";

export function SAGeneralSection() {
    const [loading, setLoading] = useState('');
    const [lang, setLang] = useState('en');
    const [community_uid, setCommunityUid] = useState('');

    function updateMembers() {
       setLoading('updating')

        const res = () => {
           setLoading('');
            setCommunityUid('');
        };

        const payload = {
            community_uid,
            value: lang,
            action: 'set-default-language'
        };

        authFetch("/superadmin/member-update", res, res, "POST", {payload});
    }


    return <div className="space-y-2 border border-gray-200 p-4">
        <div>
            <Input value={community_uid} onChange={(v)=>setCommunityUid(v)} placeholder="Community UID" />
        </div>
        <div>
            <LanguageSelect hide_blank value={lang} onChange={(l)=>setLang(l)} />
        </div>
        <div>
            <Button intent="secondary" disabled={community_uid.length<8} text="Update" onClick={()=>updateMembers()} loading={loading==='updating'} />
        </div>

    </div>
}