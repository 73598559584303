import React, {useCallback, forwardRef, useRef, useEffect, useMemo} from 'react';
import {AT_BodyCell, AT_HeaderCell} from "../../../../playground/advanced-table/table-components";
import {useFlexLayout, useRowSelect, usePagination, useResizeColumns, useTable} from "react-table";
import useInfiniteScroll from "../../../../playground/advanced-table/use-infinite-scroll";
import {NewTooltip} from "../../../reorganize/new-tooltip";
import {buildListColumns} from "./build-list-columns";
import {getPropertiesArray} from "../../properties/properties";
import {ArrowLeftIcon, ArrowRightIcon, EyeOffIcon} from "@heroicons/react/outline";

const Cell = ({value}) => {
    const formatted = !value ? "" : typeof value === 'string' ? String(value) : JSON.stringify(value);
    return formatted;
};

const defaultColumn = {
    Cell: Cell
}


function LRow({header_groups}) {

    return <div>
        {header_groups.map((headerGroup, p) => (
            <div key={`${p}`} {...headerGroup.getHeaderGroupProps()} className={`flex`}>
                {headerGroup.headers.map((column, k) => {
                    if(k===0) {
                        return <div style={{height: '36px',width: `${column.totalWidth}px`, minWidth: `${column.totalWidth}px`}} key={k} className="w-8 border-gray-100 border-b">

                        </div>
                    }
                    return <div key={k} style={{height: '36px',width: `${column.totalWidth}px`, minWidth: `${column.totalWidth}px`}}
                         className={`bg-white py-1 border-b border-gray-100 border-r`}>
                        {k === 1 && <div className="flex space-x-2 pl-1 pr-2">
                            <div className="bg-gray-300 rounded-42"
                                 style={{height: '28px', minWidth: '28px', width: '28px'}}/>
                            <div className="flex items-center w-full">
                                <div className="bg-gray-400 rounded h-2.5 w-5/6"/>
                            </div>
                        </div>}
                        {k > 1 && <div className="px-2 py-2.5 flex items-center">
                            <div className="bg-gray-300 rounded h-2 w-2/3"/>
                        </div>}
                    </div>
                })}
            </div>
        ))}
    </div>
}

function RowAction({icon, _data, onClick, tooltip = ""}) {
    return <div className="w-8 flex items-center">
        <NewTooltip message={tooltip}>
            <div onClick={(e) => {
                e.stopPropagation();
                onClick(_data);
            }}
                 className="h-svg-5 h-7 w-7 flex items-center justify-center rounded-full text-gray-600 hover:text-gray-800 hover:bg-gray-200">
                {icon}
            </div>
        </NewTooltip>
    </div>
}

function LoadingRows({header_groups, layout = 'list'}) {

    return <div className="flex flex-col">
        <LRow key="1" header_groups={header_groups}/>
        <LRow key="2" header_groups={header_groups}/>
        <LRow key="3" header_groups={header_groups}/>
        <LRow key="4" header_groups={header_groups}/>
        <LRow key="5" header_groups={header_groups}/>
        <LRow key="6" header_groups={header_groups}/>
        <LRow key="7" header_groups={header_groups}/>
        <LRow key="8" header_groups={header_groups}/>
    </div>
}

function buildData(ids, data) {
    let a = [];

    ids.forEach(id => {
        if (data[id]) {
            a.push(data[id]);
        }
    })

    return a;
}

function getSortable(cc, so) {
    if (cc.id === 'name') {
        return {...cc, sortable: "about.last_name"};
    }
    return {...cc, sortable: so[cc.id] ? so[cc.id] : ""};
}

function buildColumnOptions(column, first, last, table_fns, attributes, {prev_prop_id, next_prop_id}, len) {
    let a = [];

    if (first || last) {
        return a;
    }
    const active_atts = attributes.filter(a => a.active);

    const prop_index = active_atts.findIndex(a => a.key === column.id);

    const prev_prop_index = prev_prop_id ? active_atts.findIndex(a => a.key === prev_prop_id) : "";
    const next_prop_index = next_prop_id ? active_atts.findIndex(a => a.key === next_prop_id) : "";

    let f = [];

    if (next_prop_id && (next_prop_index + 1 !== len) && next_prop_id !== '_add_field') {
        f.push({
            type: 'item',
            icon: <ArrowRightIcon/>,
            title: 'Move Right',
            onClick: () => table_fns.moveColRight(prop_index, next_prop_index)
        });
    }

    if (prev_prop_id && prev_prop_id !== 'name') {
        f.push({
            type: 'item',
            icon: <ArrowLeftIcon/>,
            title: 'Move Left',
            onClick: () => table_fns.moveColLeft(prop_index, prev_prop_index)
        });
    }

    f.push({
        type: 'divider'
    });

    f.push({
        type: 'item',
        icon: <EyeOffIcon/>,
        title: 'Hide Attribute',
        onClick: () => table_fns.hideField(column.id)
    })

    return f;
}

const IndeterminateCheckbox = forwardRef(({indeterminate, ...rest}, ref) => {
    const defaultRef = useRef()
    const resolvedRef = ref || defaultRef

    useEffect(() => {
        resolvedRef.current.indeterminate = indeterminate
    }, [resolvedRef, indeterminate])

    return (
        <div className="flex">
            <input className="directory-checkbox" type="checkbox" ref={resolvedRef} {...rest} />
        </div>
    )
});
IndeterminateCheckbox.displayName = "Checkbox";

function buildInitial(arr) {
    let o = {};
    arr.forEach(id=>{
        o[id] = true;
    });
    return o;
}

export function DRList({
                           data,
                           meta,
                           table_fns,
                           context,
                           loading,
                           selected_ids = [],
                           scroll_id = "",
                           ids,
                           can_select = false,
                           goToItem = () => {
                           },
                           updateSelected = () => {
                           },
                           handleToggleProperty = () => {
                           },
                           editItem = () => {
                           },
                           onNextPage = () => {
                           },
                           setNewSort = () => {
                           },
                           definitions,
                           view,
                           suggestions_handler,
                           suggestions,
                           has_more
                       }) {


    const selected_ref = useRef([]);
    const all_ids = useRef([]);

    const all_columns = useMemo(
        () => buildListColumns(view.properties, definitions, context),
        [view]
    );

    useEffect(function () {
        if (!loading) {
            setIsFetching(false)
        }
    }, [loading])

    const all_items = useMemo(
        () => buildData(ids, data),
        [ids, data]
    );

    all_ids.current = ids;

    useEffect(function () {
        selected_ref.current = [...selected_ids];
    }, [selected_ids])

    function handleSelect(id) {
        let fs = [...selected_ref.current];
        if(fs.includes(id)) {
            const index = fs.indexOf(id);
            fs.splice(index,1);
        } else {
            fs.push(id);
        }
        selected_ref.current = fs;
        updateSelected(fs);
    }

    function handleSelectAll() {
        if(selected_ref.current.length>0) {
            selected_ref.current = [];
            updateSelected([]);
        } else {
            selected_ref.current = [...all_ids.current];
            updateSelected([...all_ids.current]);
        }
    }
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        selectedFlatRows,
    } = useTable(
        {
            defaultColumn,
            columns: all_columns,
            data: all_items
        },
        usePagination,
        useFlexLayout,
        useResizeColumns,
        useRowSelect,
        hooks => {
            hooks.visibleColumns.push(columns => [
                {
                    id: 'selection',
                    minWidth: 32,
                    maxWidth: 32,
                    width: 32,
                    Header: (props) => {

                        return <div>
                            <IndeterminateCheckbox checked={false} indeterminate={selected_ref.current.length>0} onChange={(e)=>{
                                handleSelectAll()
                            }} />
                        </div>
                    },
                    Cell: (props) => {
                        const is_selected = selected_ref.current.includes(props.row.original.id);
                        return <div>
                            <IndeterminateCheckbox checked={is_selected} onChange={(e)=>{
                                // toggle
                                handleSelect(props.row.original.id);
                            }} />
                        </div>
                    },
                },
                ...columns,
            ])
        }
    );


    const [isFetching, setIsFetching] = useInfiniteScroll("infinite", scroll_id, {
        padding: 400,
        container: true
    }, () => {
        if (!loading && has_more) {
            onNextPage();
        }
    });

    if (all_items.length === 0) {
        return <div>

        </div>;
    }

    const sort_by = view.sort[0] ? view.sort[0] : {
        dir: "asc",
        field: "last_name"
    };

    const so = definitions.sort_options;
    const cols_len = all_columns.length;

    const attributes = getPropertiesArray(definitions.properties, view.properties);
    const inactive_attributes = attributes.filter(p => !p.active).map(p => {
        return {
            type: 'item',
            title: p.label,
            onClick: () => handleToggleProperty(p.key, !p.active)
        }
    });

    console.log("ROWS",rows)
    return <div className="pb-4 overflow-x-auto">
        <div {...getTableProps()}>

            {headerGroups.map(headerGroup => (
                <div {...headerGroup.getHeaderGroupProps()} className="user-select-none">
                    {headerGroup.headers.map((cc, index) => {
                        const column = getSortable(cc, so);
                        const last = cols_len === (index + 1);
                        const first = index === 1;
                        const right_border = cols_len === (index + 1) || column.id === 'selection' ? "" : "border-r";
                        const hover = first || column.id === 'selection' ? `` : `hover:bg-gray-100 active:bg-gray-200 cursor-pointer`;
                        const position = column.id === 'selection' ? "sticky-important left-0 z-20" : first ? "sticky-important left-8 z-20" : column.id === 'selection' ? "sticky-important left-0 z-20" : "";
                        const prev_prop_id = headerGroup.headers[index - 1] ? headerGroup.headers[index - 1].id : "";
                        const next_prop_id = headerGroup.headers[index + 1] ? headerGroup.headers[index + 1].id : "";
                        const column_options = buildColumnOptions(column, first, last, table_fns, attributes, {
                            prev_prop_id,
                            next_prop_id
                        }, cols_len);
                        return (
                            <div
                                {...column.getHeaderProps()}
                                className={`${right_border} ${hover} transition-colors border-b-2 border-gray-200 pl-2 pb-1.5 pt-1.5 bg-white ${position}`}

                            >
                                <AT_HeaderCell column_options={column_options} inactive_attributes={inactive_attributes}
                                               index={index} sortByColumn={() => {
                                    setNewSort({field: column.sortable, dir: 'desc'});
                                }} reverseSort={() => {
                                    let nd = sort_by.dir === 'asc' ? 'desc' : 'asc';
                                    setNewSort({...sort_by, dir: nd});
                                }} sort_by={sort_by} column={column}/>

                                {!last && column.id !== 'selection' && <div
                                    {...column.getResizerProps()}
                                    className={`table-resizer ${
                                        column.isResizing ? 'isResizing' : ''
                                    }`}
                                />}
                            </div>
                        )
                    })}
                </div>
            ))}

            <div {...getTableBodyProps()}>
                {rows.map((row, row_index) => {
                    prepareRow(row);
                    return (
                        <div {...row.getRowProps()}
                             className={`relative group bg-white `}>
                            {row.cells.map((cell, cell_index) => {
                                const last = cols_len === (cell_index + 1);
                                const first = cell_index === 1;
                                const right_border = last || cell.column.id === 'selection' ? "" : "border-r ";
                                const position = cell.column.id === 'selection' ? "sticky left-0 z-20" : first ? "sticky left-8 z-20" : "pl-2";
                                return (
                                    <div
                                        {...cell.getCellProps()}
                                        className={`${right_border} ${cell.column.field_type === '_add_field' ? "bg-gray-50" : "group-hover:bg-gray-100"} border-b border-gray-100  bg-white ${position}`}
                                    >
                                        <AT_BodyCell is_selected={selected_ids.includes(row.original.id)} row={row} first={first} last={last} row_index={row_index}
                                                     index={cell_index} actions={{
                                            editItem: () => editItem(cell.row.original.id, cell.row.original),
                                            goToItem: () => goToItem(cell.row.original.id, cell.row.original)
                                        }} suggestions_handler={suggestions_handler} suggestions={suggestions}
                                                     context={context} meta={meta} cell={cell}/>
                                    </div>
                                );
                            })}
                        </div>
                    );
                })}
                {loading && <LoadingRows header_groups={headerGroups}/>}
            </div>
        </div>
    </div>

}