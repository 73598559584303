import React from "react";
import {PrivacyText} from "./privacy-text";
import {
    SplashTextContainer,
    AuthTitle,
    SplashContainer,
    SplashInner
} from "../../layout/splash-container";

export function PoliciesPrivacy() {

    return <SplashContainer>
        <SplashInner>
            <SplashTextContainer>
                <AuthTitle>Privacy Policy</AuthTitle>
                <PrivacyText/>
            </SplashTextContainer>
        </SplashInner>
    </SplashContainer>
}