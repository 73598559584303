import React, {useContext, useEffect, useState} from 'react';
import {CommunityContext} from "../community-context";
import {CreateRoleController} from "../create-entities/roles/create";
import {setDocumentTitle} from "../../../library/utilities/set-document-title";
import {Avatar, Button, EntityHeader} from "../../../library/components/core";
import {useIntl} from "react-intl";
import {useIsMobile} from "../../global/global-router";
import {StandardPageFramework, StandardPageHeight} from "../../../library/playground/standard-page-framework";
import styled from "styled-components";
import {useHistory} from "react-router-dom";
import {MemberHoverCard} from "./member-hover-card";
import {PlusIcon} from "@heroicons/react/solid";
import {buildNewPathWithURLParam} from "../directory";
import {GlobalContext} from "../../global/global-context";

function Role({title,member_link,member_id,history,link,image,name}) {

    return <div className="">
        <div>
            <div onClick={()=>history.push(link)} className={`hover:opacity-70 inline-block cursor-pointer transition-opacity`}>
            <Avatar size="3xl" url={`${image}_medium?alt=media`} />
            </div>
        </div>
        <div className="pt-1">
            <div className="text-base leading-5 line-clamp-2 font-bold text-gray-800">
                <a onClick={()=>history.push(link)} className="hover:underline cursor-pointer">{title}</a>
            </div>
            <div className="text-sm text-gray-600 line-clamp-1">
                <MemberHoverCard id={member_id}>
                <a onClick={()=>history.push(member_link)} className="hover:underline cursor-pointer">{name}</a>
                </MemberHoverCard>
            </div>
        </div>
    </div>
}

const Grid = styled.div`
    
`;

function Team({title,purpose,link,roles,history}) {
    return <div className="block md:grid gap-4" style={{gridTemplateColumns:"1fr 3fr"}}>
        <div className="pb-2 pt-1.5 bg-white">
            <div className="text-base text-gray-900 font-bold leading-6">
                <a onClick={()=>history.push(link)} className="hover:underline cursor-pointer">{title}</a>
                </div>
            {purpose&&<div className="text-sm text-gray-700">
                {purpose}
            </div>}
        </div>
        <Grid className="grid gap-4 grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 3xl:grid-cols-6 pt-2">
            {roles.map(role=>{
                return <Role history={history} key={role.id} {...role} />
            })}
        </Grid>
    </div>
}

function buildRole(rid,role_data,ch,dpp) {
    return {
        id: rid,
        title: role_data.name,
        purpose: role_data.purpose,
        link: `/${ch}/role/${rid}`,
        member_id: role_data.assignee,
        member_link: `/${ch}/member/${role_data.assignee_data.handle}`,
        handle: role_data.handle,
        image: role_data.assignee_data.profile_picture?`${role_data.assignee_data.profile_picture}`:`${dpp}`,
        name: role_data.assignee_data.name?role_data.assignee_data.name:"Unassigned",
        assignee_handle: role_data.assignee_data.handle,
    };
}

function buildLeadersContent(roles, teams, community) {
    let sections = [];
    // team.rank

    let not_in_teams = {};

    const r = Object.entries(roles);
    r.forEach(([id,role])=>{
        not_in_teams[id] = true;
    })

    const t = Object.entries(teams).sort((a, b) => (a[1].rank < b[1].rank) ? 1 : -1);

const ch = community.data.handle;

    t.forEach(([id,team],k) => {
        if(team) {

            sections.push({
                title: team.name,
                purpose: team.purpose,
                link: `/${ch}/group/${id}`,
                handle: team.handle,
                id: id,
                roles: []
            });

            if (team.roles_order&&team.roles_order.length>0) {
                team.roles_order.forEach(rid => {
                    const role_data = roles[rid];
                    if(role_data) {
                        if (not_in_teams[rid]) {
                            delete not_in_teams[rid];
                        }
                        sections[k].roles.push(buildRole(rid, role_data, ch, community.default_member_profile_picture));
                    }
                });
            } else {
                let team_roles = [];
                Object.keys(team.entities.roles).forEach(rid => {
                    const role_data = roles[rid];
                    if(role_data) {
                        if (not_in_teams[rid]) {
                            delete not_in_teams[rid];
                        }
                        team_roles.push(buildRole(rid, role_data, ch, community.default_member_profile_picture));
                    }
                });

                sections[sections.length-1].roles = team_roles.sort((a, b) => (a.title > b.title) ? 1 : -1);
            }
        }
    });


    const unassigned_ids = Object.keys(not_in_teams);

    if(unassigned_ids.length>0) {
        sections.push({
            title: "Additional Roles",
            purpose: "Roles not yet assigned to a team.",
            link: `#`,
            handle: "",
            id: "additional",
            roles: []
        });
        let unassigned_roles = [];
        unassigned_ids.forEach(rid=>{
            const role_data = roles[rid];
            if(role_data) {
                unassigned_roles.push(buildRole(rid,role_data,ch,community.default_member_profile_picture));
            }
        });
        sections[sections.length-1].roles = unassigned_roles.sort((a, b) => (a.title > b.title) ? 1 : -1);
    }

    return sections;
}

export function CommunityLeaders(props) {
    const [open, setOpen] = useState(null);
    const {formatMessage: f} = useIntl();
    const global = useContext(GlobalContext);
    const [checked_url, setCheckedUrl] = useState(false);
    const community = useContext(CommunityContext);
    const history = useHistory();
    const is_mobile = useIsMobile();
    setDocumentTitle(`Leaders`, community.data.name);

    useEffect(function () {
        if (!checked_url) {
            const urlParams = new URLSearchParams(window.location.search);
            const action = urlParams.get('action');
            if (action === 'create-team' && community.is_admin) {
                setOpen('team');
            } else if (action === 'create-role' && community.is_admin) {
                setOpen('role');
            }
            setCheckedUrl(true);
        }
    }, []);

    function openCreateRole() {
        global.handleSetRightMenu('create-role',{
            onConfirmed: (ids)=>{
                global.handleCloseRightMenu();
                const new_path = buildNewPathWithURLParam(history, 'reload=true');
                history.push(new_path);
            },
            initial: [],
            flow: 'leaders'
        })
    }

    const actions = is_mobile ? null : <div className="space-x-2">

        {community.member_access.manage_roles&&<Button minimal icon={<PlusIcon />} intent="secondary" onClick={() => openCreateRole()} text="New Role"
                size="sm"/>}
    </div>;

    const sections = buildLeadersContent(community.all_roles, community.all_teams, community);

    return <StandardPageHeight content full>
        <div className="sticky top-0 bg-white z-20">
        <EntityHeader onClick={() => {
            const el = document.getElementById("main-content");
            el.scrollTo(0, 0);
        }} title="Leaders" actions={actions}/>
        </div>
        <div className="pt-2 px-4 pb-20 relative space-y-10">
            {sections.map(team=>{
                return <Team history={history} key={team.id} {...team} />
            })}
        </div>
    </StandardPageHeight>
}