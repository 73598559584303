import React, {useState} from 'react';
import styled from "styled-components";
import {ChevronDownIcon, ChevronLeftIcon, FilterIcon, StarIcon, TagIcon, TrashIcon} from "@heroicons/react/solid";
import {Tabs,List,Button} from "../../../../../library/components/core";
import RoleIcon from "../../../../../library/icons/custom/RoleIcon";

function getIcon(type) {
    if(type==='filters') {
        return <FilterIcon/>
    } else if(type==='member_types') {
        return <TagIcon/>
    } else if(type==='roles') {
        return <RoleIcon/>
    } else {
        return null;
    }
}

export function MenuPanelFolderMembers({goToPanel,entities={},can_manage=false,init="",options=[],context,members,removeEntity,moderators={},goBack,stack_length}) {
    const [tab, setTab] = useState(init?init:options[0]);
    const [mods,setMods] = useState(Object.keys(moderators));
    const [expanded, setExpanded] = useState({});
    const [entities_obj,setEntitiesObj] = useState(entities);
    const [removing,setRemoving] = useState('');
    const current_id = context.member.user_uid;
    // actually can't remove all users singly either..
    const items = Object.entries(entities_obj).map(([type,ids_obj],k)=>{
        return {
            type,
            ids: Object.keys(ids_obj)
        }
    });

    function handleExpand(id) {
        let ne = expanded;
        if(expanded[id]) {
            delete ne[id];
        } else {
            ne[id] = true;
        }
        setExpanded({...ne});
    }
    // todo role moderators
    const final = items.map((it,k)=>{
        return it.ids.map(id=>{
        const is_mod = moderators[id];
        const uid = `${it.type}-${id}`;
        const is_expanded = expanded[uid];

        const can_remove = can_manage&&`${uid}`!==`${it.type}-${current_id}`;
        return { value: `${uid}`, expanded: is_expanded, icon: getIcon(it.type), type: it.type, id: id, title_suffix: null, actions: [
                ((it.type==='filters'||it.type==='member_types')&&<Button onClick={()=>handleExpand(uid)} size="xs" circular left_icon={is_expanded?<ChevronDownIcon/>:<ChevronLeftIcon/>} />),
                (can_remove?<Button loading={removing===`${uid}`} onClick={()=>{
                    setRemoving(`${uid}`);
                    const cb = (data) =>{
                        setRemoving('');
                        setEntitiesObj({...data.entities});
                    };
                    removeEntity(it.type,id,cb);
                }} size="xs" circular left_icon={<TrashIcon/>} />:null)
            ] }
    })});

    // const modified_items = getModifiedItems(final,expanded_filters);

    let flattened = [].concat.apply([],final);
    return <>
        <Tabs intent="secondary" layout="rich-bottom" active={tab} onSelect={(t)=>setTab(t)} tabs={options}/>
        <div className="px-2 py-1.5 max-h-70vh ">

            {tab==='Members'&&<div>
                <List use_entity_info context={context} items={[...flattened]} />
            </div>}
        </div>
    </>
}