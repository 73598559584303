import React, {useState} from 'react';
import Select, {components} from "react-select";
import styled from "styled-components";
import {XIcon,SelectorIcon} from '@heroicons/react/solid';


const IconBox = styled.div`
 height: 24px;
    width: 24px;
    border-radius: 20%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    background-color: ${props => props.color};
  > svg {
    height: 16px;
  }
`;

const IconBoxMini = styled.div`
 height: 18px;
    width: 18px;
    border-radius: 20%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 12px;
    top: 10px;
    color: #fff;
    background-color: ${props => props.color};
  > svg {
    height: 12px;
  }
`;


const DescText = styled.span`
font-weight: 400;
font-size: 12px;
display:block;
`;

const DropdownBox = styled.span`
padding-right: 8px;
> svg {
    height: 20px;
}
`;

const ClearBox = styled.div`
padding: 0;
> svg {
 height: 16px;
}
`;

const is_styles = {
    display: 'none'
};

const ClearIndicator = props => {
    const {
        children = <XIcon/>,
        getStyles,
        innerProps: {ref, ...restInnerProps},
    } = props;
    return (
        <div
            {...restInnerProps}
            ref={ref}
            style={getStyles('clearIndicator', props)}
        >
            <ClearBox>{children}</ClearBox>
        </div>
    );
};

const DropdownIndicator = ({innerProps}) => {
    return <DropdownBox className='text-gray-500' {...innerProps}>
        <SelectorIcon/>
    </DropdownBox>
};

const IndicatorSeparator = ({innerProps}) => {
    return <span style={is_styles} {...innerProps} />;
};

const ControlComponent = props => (
    <components.Control {...props} />
);

const SelectContainer = ({children, ...props}) => {
    return (
        <components.SelectContainer {...props}>
            {children}
        </components.SelectContainer>
    );
};

const SingleValue = props => {
    const val = props.getValue()[0];
    const pad_left = val ? !!val.icon : false;
    return <div>
        {val.icon && <IconBox color={val.color}>{val.icon}</IconBox>}
        <components.SingleValue {...props} pad_left={pad_left}/>
    </div>
};


const Opt = styled.div`
cursor:pointer;
> div {
padding: 0.5rem 1rem;
}
`;

const Option = props => {
    const data = props.data;
    const pad_left = data ? !!data.icon : false;
    return <Opt style={{position: 'relative'}}>
        {data.icon && <IconBoxMini color={data.color}>{data.icon}</IconBoxMini>}
        <components.Option pad_left={pad_left} {...props}>
            <span style={{display: 'contents'}}>{data.label}{data.desc &&
            <DescText>{data.desc}</DescText>}</span>
        </components.Option>
    </Opt>
};


const MultiValueContainer = props => {
    return (
        <components.MultiValueContainer {...props} />
    );
};

const MultiValueLabel = props => {
    return (
        <components.MultiValueLabel {...props} />
    );
};

const RemoveIcon = styled.span`
height: 100%;
    display: flex;
    align-items: center;
> svg {
    margin-top: 1px;
height: 14px;
}
`;

const MultiValueRemove = props => {
    return (
        <components.MultiValueRemove {...props}>
            <RemoveIcon>
                <XIcon/>
            </RemoveIcon>
        </components.MultiValueRemove>
    );
};

const MCont = styled.div`

> div {
overflow: hidden;
}
`;


const Menu = props => {

    return (
        <MCont>
            <components.Menu {...props}>{props.children}</components.Menu>
        </MCont>
    );
};

function getDropddownPadding(s, f, compact) {
    if (s) {
        return '7px';
    } else if (f) {
        return '6px';
    } else if(compact) {
        return '4px';
    } else {
        return '8px';
    }
}

function getMinHeight(m, s, f, compact) {
    if (s) {
        return '34px';
    } else if (f) {
        return '32px';
    } else if(compact) {
        return '20px';
    } else {
        return m;
    }
}

function getBoxShadow(focus, s, f) {
    if (focus) {
        return 'rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(58, 151, 212, 0.36) 0px 0px 0px 4px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(60, 66, 87, 0.16) 0px 0px 0px 1px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px';
    } else if (s) {
        return '';
    } else if (f) {
        return '0 1px 2px 0 #0000000d';
    } else {
        return '0 1px 2px 0 #0000000d';
    }
}

function ensureValue(val,multi,options) {
    if(multi) {
        return val;
    }
    if(typeof val === "string" || typeof val === "number") {
        const item = options.filter(obj => {
            return obj.value === val
        })[0];
        return item;
    } else {
        return val;
    }
}

export function SimpleListbox({ usePortal=false,
                                  openMenuOnFocus = false,
                                  compact,
                                  onEnter=()=>{},
                                  onEscape=()=>{},
    name = "",
                                    layout = 'standard',
                                    onChange, minHeight = '40px', autofocus = false, simple, filter, placeholder = 'Search..', multi = false, searchable = false, disabled = false, loading = false, clearable = false, onBlur = () => {
    }, options = [], value
                                }) {
    const [focus,setFocus] = useState(false);
    const [menu_open,setMenuOpen] = useState(false);
    const [val, setVal] = useState(value ? value : multi ? [] : null);
    const oc = onChange ? onChange : v => setVal(v);
    function handleBlur() {
        onBlur();
        setFocus(false);
    }
    function handleFocus() {
       setFocus(true);
    }
    function handleMenuOpen() {
        setMenuOpen(true);
    }
    function handleMenuClose() {
        setMenuOpen(false);
    }
    function handleKeyDown(e) {
        if(!e) {
            return;
        }
        if (e.keyCode === 13&&!menu_open) {
           onEnter();
        } else if (e.keyCode === 27&&!menu_open) {
            onEscape();
        }
    }

    return <div className={"relative max-w-lg w-full"}>
        <Select onKeyDown={handleKeyDown} onMenuOpen={handleMenuOpen} onMenuClose={handleMenuClose} onFocus={handleFocus} onBlur={handleBlur} onChange={oc} openMenuOnFocus={openMenuOnFocus} value={ensureValue(value,multi,options)}
                isSearchable={searchable} autoFocus={autofocus} menuPlacement={'auto'}
                components={{
                    ControlComponent,
                    Menu,
                    Option,
                    MultiValueContainer,
                    MultiValueLabel,
                    MultiValueRemove,
                    ClearIndicator,
                    IndicatorSeparator,
                    DropdownIndicator,
                    SelectContainer,
                    SingleValue
                }}
                styles={{
                    singleValue: (base, state) => ({
                        ...base,
                        fontSize: '16px',
                        margin: 0,
                        paddingLeft: state.pad_left ? '32px' : ''
                    }),
                    multiValue: (base) => ({
                        ...base,
                        fontSize: '16px',
                        borderRadius: '4px',
                        background: '#444449'
                    }),
                    multiValueLabel: (base, state) => ({
                        ...base,
                        color: '#bababa'
                    }),
                    menuPortal: (base,state) => ({
                       ...base,
                       zIndex: 9999,
                        minWidth: '225px'
                    }),
                    multiValueRemove: (base) => ({
                        ...base,
                        paddingLeft: '2px',
                        background: 'transparent !important',
                        color: '#b3b3b3',
                        cursor: 'pointer'
                    }),
                    valueContainer: (base) => ({
                        ...base,
                        fontSize: layout==='address'?"16px":"16px",
                        padding: '0 6px'
                    }),
                    indicatorsContainer: (base) => ({
                       ...base
                    }),
                    container: base => ({
                        ...base
                    }),
                    clearIndicator: base => ({
                        ...base,
                        cursor: 'pointer'
                    }),
                    dropdownIndicator: base => ({
                        ...base,
                        padding: getDropddownPadding(simple, filter, compact)
                    }),
                    option: (base, state) => ({
                        ...base,
                        fontSize:'14px',
                        whiteSpace: 'nowrap',
                        cursor: 'pointer',
                        background: state.isSelected ? '#1164A3 !important' : state.isFocused ? '#DEDEDE !important' : '#ffffff !important',
                        color: state.isSelected ? 'white' : 'black',
                        paddingLeft: state.pad_left ? '0.5rem' : ''
                    }),
                    control: (base, state) => ({
                        ...base,
                        paddingLeft: compact?'0.25rem':'0.5rem',
                        cursor: 'pointer',
                        borderTopLeftRadius: layout==='address'?"0":"",
                        borderTopRightRadius: layout==='address'?"0":"",
                        borderRadius: '0.375rem',
                       boxShadow: getBoxShadow(state.isFocused, simple, filter),
                        border: state.isFocused ? '1px solid #659adf' : '1px solid #d4d4d8',
                        minHeight: getMinHeight(minHeight, simple, filter, compact)
                    })
                }}
                isMulti={multi}
                menuPortalTarget={usePortal?document.body:null}
                placeholder={placeholder}
                isClearable={clearable}
                isDisabled={disabled}
                isLoading={loading}
                options={options}/>
    </div>;
}
