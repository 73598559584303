import React, {useEffect, useState} from "react";
import {activity_types} from "./types";
import {AdminActivityFeed} from "./feed";
import {FirebaseDB} from "../../../config/setup-firestore";
import {InlineLoader} from "../core";

export function NewAdminActivity({community, member, member_id}) {
    const [ids, setIds] = useState([]);
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState({});

    function handleSnap(snap) {
        if (snap.empty) {
            console.log('snap empty')
        } else {
            const lastVisible = snap.docs[snap.docs.length - 1];
            console.log("last", lastVisible);
        }
        let new_ids = [];
        let new_data = {...data};
        snap.docs.forEach(a => {
            new_ids.push(a.id);
            new_data[a.id] = a.data();
        });
        setData(new_data);
        setLoading(false);
        setIds(new_ids);
    }

    useEffect(function () {
        let sub = () => {
        };

        // start without
        sub = FirebaseDB
            .collection('community_members')
            .doc(community.uid)
            .collection('activity')
            .where('context', '==', 'member')
            .where('context_id', '==', member_id)
            .orderBy('ts', 'desc')
            .limit(50)
            .onSnapshot(s => {
                handleSnap(s, true)
            })

        return () => {
            sub();
        };
    }, [member_id, community.uid]);

    return <div className="px-1 overflow-y-auto " style={{maxHeight: '450px'}}>
        <AdminActivityFeed events={ids.map(id => {
            return data[id]
        })} context={{community_uid: community.uid}}/>
        {loading && <InlineLoader/>}
    </div>
}