import React, {useContext, useEffect, useState} from 'react';
import {authFetch} from "../../../../config/network";
import {CommunityContext} from "../../community-context";
import {SparklesIcon} from "@heroicons/react/solid";
import {Button} from "../../../../library/components/core";

export function AutocompleteTest({id,member,autocompleteAddress=()=>{},loading=false}) {
    const [data,setData] = useState(null);
    const community = useContext(CommunityContext);
    useEffect(function () {

        const payload = {
            member_id: community.member_id,
            community_uid: community.uid,
            id,
            address: member.address,
        };

        const res = (resp) => {
            if(resp&&resp.data) {
                setData(resp.data);
            }
        };

        authFetch("/autocomplete-address", res, res, "POST", {payload});

    }, [id]);

    if(!data) {
return null;
    }

    return <Button onClick={(e) => {
        if(autocompleteAddress) {
            autocompleteAddress(data)
        }
    }} loading={loading} icon={<SparklesIcon />} text='Autocomplete' size='sm' intent='primary'/>
}