import React, {useContext, useEffect, useRef, useState} from "react";
import {Field, Input, TextArea} from "../../../core";
import {PhoneServicesFinder} from "../../../reorganize/phone-services-finder";
import {authFetch} from "../../../../../config/network";
import {CommunityContext} from "../../../../../app/community/community-context";
import {getPhoneServices} from "../../../../../app/community/member";
import {SimpleHandleSelector} from "../../../custom/simple-handle-selector";
import {AdminImageEditorField} from "../../../custom/admin-image-editor-field";
import {DEFAULT_USER_PHOTO} from "../../../../../config/defaults";

export function ObScYourProfile({}) {

    const community = useContext(CommunityContext);
    const val = useRef();
    const [profile_picture, setProfilePicture] = useState(community.member.profile_picture);
    const [profile_picture_color, setProfilePictureColor] = useState(community.member.profile_picture_color);
    const [first_name, setFirstName] = useState(community.member.about.first_name);
    const [location, setLocation] = useState(community.member.about.location||"");
    const [occupation, setOccupation] = useState(community.member.about.occupation||"");
    const [bio, setBio] = useState(community.member.about.bio);
    const [handle, setHandle] = useState(community.member.handle);
    const [last_name, setLastName] = useState(community.member.about.last_name);
    const [phone, setPhone] = useState(community.member.contact.phone);

    useEffect(function () {
        return () => {
            val.current = {
                first_name, phone, profile_picture, occupation, location, profile_picture_color, bio, last_name, handle
            };
        }
    }, [first_name, phone, occupation, location, profile_picture, profile_picture_color, last_name, bio, handle]);

    function handleSave(dt) {
        if (dt && community && community.member_id) {
            const res = () => null;

            const payload = {
                community_uid: community.uid,
                id: community.member_id,
                member_id: community.member_id,
                user_uid: community.member.user_uid,
                about: {
                    ...community.member.about,
                    first_name: dt.first_name,
                    bio: dt.bio,
                    last_name: dt.last_name,
                    location: dt.location,
                    occupation: dt.occupation
                },
                contact: {
                    ...community.member.contact,
                    phone: dt.phone
                },
                _updated_fields: ['about.first_name', 'about.location', 'about.occupation', 'contact.phone', 'about.phone', 'about.last_name', 'about.bio']
            }

            if (dt.profile_picture) {
                const p2 = {
                    community_uid: community.uid,
                    id: community.member_id,
                    member_id: community.member_id,
                    user_uid: community.member.user_uid,
                    color: dt.profile_picture_color,
                    url: dt.profile_picture
                };
                authFetch('/members/set-profile-picture', res, res, "POST", {payload: p2});
            }

            if(dt.handle!==community.member.handle) {
                const p3 = {
                    new_handle: dt.handle,
                    community_uid: community.uid,
                    id: community.member_id,
                    member_id: community.member_id,
                    user_uid: community.member.user_uid,
                };

                authFetch("/members/change-handle", res, res, "POST", {payload: p3})
            }

            authFetch('/members/update-profile', res, res, "POST", {payload});
        }
    }

    useEffect(function () {
        return () => {
            handleSave(val.current);
        }
    }, []);

    const phone_services = getPhoneServices(community.member);

    return <div className="grid gap-8 sm:grid-cols-4">
        <div className="pt-1">
            <AdminImageEditorField handleUrl={(url, color) => {
                setProfilePicture(url)
                setProfilePictureColor(color)
            }} layout="image-only" type="member" url={profile_picture?`${profile_picture}_medium?alt=media`:`${DEFAULT_USER_PHOTO}_medium?alt=media`}/>
        </div>
        <div className="col-span-3 grid gap-4">
            <div className="grid gap-4 grid-cols-2">
                <div>
                    <Field label="First Name">
                        <Input value={first_name} onChange={(v) => setFirstName(v)}/>
                    </Field>
                </div>
                <div>
                    <Field label="Last Name">
                        <Input value={last_name} onChange={(v) => setLastName(v)}/>
                    </Field>
                </div>
            </div>
            <div className="hidden sm:block">
                <Field label="About me">
                    <TextArea value={bio} onChange={(v) => setBio(v)} autoFocus
                              placeholder="Introduce yourself to the community.."/>
                </Field>
            </div>
            <div className="grid gap-4 grid-cols-2">
                <div>
                    <Field label="Location">
                        <Input value={location} onChange={(v) => setLocation(v)}/>
                    </Field>
                </div>
                <div>
                    <Field label="Occupation">
                        <Input value={occupation} onChange={(v) => setOccupation(v)}/>
                    </Field>
                </div>
            </div>
            <div>
                <SimpleHandleSelector check_id={community.member_id} community_uid={community.uid} type={'member'} current_handle={handle}
                                      onChangeHandle={(h) => setHandle(h)}/>
            </div>
            <div>
                <Field label="Phone Number">
                    <Input value={phone} onChange={(v) => setPhone(v)}/>
                    {phone.length > 3 && <div className="pt-1">
                        <PhoneServicesFinder field="phone" can_edit id={community.member_id}
                                             active={phone_services[`phone`]}/>
                    </div>}
                </Field>
            </div>
        </div>
    </div>
}