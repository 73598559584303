import React from 'react';
import styled from "styled-components";
import LinkedInIcon from "../../../../icons/social/LinkedIn";
import FacebookIcon from "../../../../icons/social/Facebook";
import TwitterIcon from "../../../../icons/social/Twitter";
import {CalendarIcon,CakeIcon,LinkIcon,FlagIcon} from '@heroicons/react/solid';
import GithubIcon from "../../../../icons/social/Github";
import DiscordIcon from "../../../../icons/social/Discord";
import SkypeIcon from "../../../../icons/social/Skype";
import RedditIcon from "../../../../icons/social/Reddit";
import EthIcon from "../../../../icons/social/Eth";

const Frame = styled.div`
    padding: 12px 22px;
        display: flex;
            flex-flow: wrap;
            align-items: center;
`;

const Item = styled.div`
    display: flex;
    margin-right: 14px;
    align-items: center;
    margin-bottom: 8px;
`;

const IconItem = styled.div`
height: 22px;

    display: flex;
    align-items: center;
    > svg {
        height: 20px;
    }
`;

const Text = styled.div`
line-height: 24px;
margin-left: 5px;
    font-size: 14px;
`;

const SI = styled.div`
       padding-top: 4px;
    svg {
        height: 18px;
    }
`;

const AnchorLink = styled.a`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 180px;
    display: block;
`;

function getIcon(type,link) {
    switch (type) {
        case 'birthday' : {
            return <CakeIcon/>
        }
        case 'website' : {
            return <LinkIcon/>
        }
        case 'founded' : {
            return <FlagIcon/>
        }
        case 'twitter' : {
            return <SI className='hover-opacity' onClick={()=>{window.open(link,'_blank')}}><TwitterIcon/></SI>
        }
        case 'eth_address' : {
            return <SI className='hover-opacity' onClick={()=>{window.open(link,'_blank')}}><EthIcon/></SI>
        }
        case 'reddit' : {
            return <SI className='hover-opacity' onClick={()=>{window.open(link,'_blank')}}><RedditIcon/></SI>
        }
        case 'skype' : {
            return <SI className='hover-opacity' onClick={()=>{window.open(link,'_blank')}}><SkypeIcon/></SI>
        }
        case 'discord' : {
            return <SI className='hover-opacity' onClick={()=>{window.open(link,'_blank')}}><DiscordIcon/></SI>
        }
        case 'github' : {
            return <SI className='hover-opacity' onClick={()=>{window.open(link,'_blank')}}><GithubIcon/></SI>
        }
        case 'facebook' : {
            return <SI className='hover-opacity' onClick={()=>{window.open(link,'_blank')}}><FacebookIcon/></SI>
        }
        case 'linkedin' : {
            return <SI className='hover-opacity' onClick={()=>{window.open(link,'_blank')}}><LinkedInIcon/></SI>
        }
        case 'joined' : {
            return <CalendarIcon/>
        }
        default : {
            return null;
        }
    }
}

function cleanWebsite(url) {
    let str = url;
    str = str.replace('https://','');
    str = str.replace('http://','');
    str = str.replace('www.','');
    str = str.replace(/\/$/,'');
    return str.trim();
}

function getValue({type,value}) {
    switch (type) {
        case 'birthday' : {
            return value
        }
        case 'founded' : {
            return `Founded in ${value}`
        }
        case 'website' : {
            return <AnchorLink className='color-link' href={value} target={'_blank'}>{cleanWebsite(value)}</AnchorLink>
        }
        case 'joined' : {
            return `Joined ${value}`
        }
        default : {
            return null;
        }
    }
}

export const PrMeta = ({meta=[]}) => {
    if(meta.length===0) {
        return null;
    }
    return <Frame>
        {meta.map((item,i)=>{
            return <Item key={i}>
                <IconItem className='text-gray-600'>
                    {getIcon(item.type, item.value)}
                </IconItem>
                <Text className='text-gray-700'>
                    {getValue(item)}
                </Text>
            </Item>
        })}
    </Frame>
};