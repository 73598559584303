import React, {useState} from 'react';
import {ATPopoverContents} from "../components/shared";
import Select from "../../old/select";
import {Button} from "../../core";
import styled from "styled-components";

function getInit(all) {
    const item = all[0];
    return {
      field: item.field,
      dir: item.default_value
    };
}

function buildFields(arr) {
    return arr.map(item=>{
        return {
          value: item.field,
          text: item.label
        };
    })
}

function buildDirMap(arr) {
    let obj = {};

    for(let i=0;i<arr.length;i++) {
        obj[arr[i].field] = arr[i].options;
    }

    return obj;
}

const AddGrid = styled.div`
    padding: 10px;
    display: grid;
    grid-template-columns: 1.25fr 1fr auto;
    grid-gap: 10px;
    > div {
        display: flex;
        align-items: center;
        > div {
            width: 100%;
        }
    }
`;

export function ATAddSort({addSort,can_delete=true,options}) {
    const [new_sort, setNewSort] = useState(getInit(options));
    const fields = buildFields(options);
    const dirs = buildDirMap(options);

    function handleAddSort() {
        addSort(new_sort);
        setNewSort(getInit(options));
    }

    function handleChange(f,v) {
        // if field chnages
        let item = new_sort;
        item[f] = v;

        if(f==='field') {
            item.dir = dirs[v][0].value
        }
        setNewSort({...item});
    }

    return <ATPopoverContents size='large'>
        <AddGrid>
        <div>
            {fields&&<Select usePortal blank_option={false} placeholder='Sort Field' value={new_sort.field} onChange={(f,val)=>{
                handleChange('field',val);
            }} options={fields}/>}
        </div>
        <div>
            {dirs[new_sort.field]&&<Select usePortal blank_option={false} placeholder='Sort Field' value={new_sort.dir} onChange={(f,val)=>{
                handleChange('dir',val);
            }} options={dirs[new_sort.field]}/>}
        </div>
        <div>
            <Button onClick={()=>handleAddSort()} intent='secondary' text='Add'/>
        </div>
        </AddGrid>
    </ATPopoverContents>
}