import moment from "moment";
import {CheckCircleIcon, ClockIcon, ExclamationCircleIcon, QuestionMarkCircleIcon} from "@heroicons/react/solid";
import React from "react";

const REFERENCE = moment();

const ONE_MONTH = REFERENCE.clone().subtract(1, 'months').startOf('day');
const THREE_MONTHS = REFERENCE.clone().subtract(3, 'months').startOf('day');
const SIX_MONTHS = REFERENCE.clone().subtract(6, 'months').startOf('day');

function isInLastSixMonths(momentDate) {
    return momentDate.isAfter(SIX_MONTHS);
}

export function isInLastThreeMonths(momentDate) {
    return momentDate.isAfter(THREE_MONTHS);
}
export function isInLastMonth(momentDate) {
    return momentDate.isAfter(ONE_MONTH);
}

export const member_profile_data_quality_ref = {
    1: {
        label: 'Unknown',
        bg: 'bg-red-100',
        indicator_bg: 'bg-red-700',
        message: `Never updated`,
        admin_message: `{name}'s profile has never been updated`,
        icon: <QuestionMarkCircleIcon/>,
        icon_color: '',
        color: 'text-red-700',
    },
    2: {
        label: 'Warning',
        bg: 'bg-amber-100',
        indicator_bg: 'bg-amber-600',
        message: `Profile warnings`,
        admin_message: `Someone flagged that {name}'s profile is not up-to-date`,
        icon: <ExclamationCircleIcon/>,
        icon_color: '',
        color: 'text-amber-500',
    },
    3: {
        label: 'Outdated',
        icon: <ClockIcon/>,
        indicator_bg: 'bg-amber-500',
        admin_message: `{name}'s profile was last updated on {date}`,
        bg: 'bg-amber-100',
        icon_color: '',
        message: `Profile outdated`,
        color: 'text-amber-500',
    },
    4: {
        label: 'Outdated',
        bg: 'bg-gray-50',
        indicator_bg: 'bg-gray-700',
        admin_message: `{name}'s profile was last updated on {date}`,
        message: `Outdated`,
        icon: <ClockIcon/>,
        icon_color: '',
        color: 'text-gray-700',
    },
    5: {
        label: 'Up-to-date',
        icon: <CheckCircleIcon/>,
        indicator_bg: 'bg-success-700',
        admin_message: `{name}'s profile was last updated on {date}`,
        bg: 'bg-success-50',
        icon_color: '',
        message: `Profile up-to-date`,
        color: 'text-success-700',
    }
};

// each member 1-5
// 1 = unknown
// 2 = warning
// 3 = out of date long
// 4 = outofdate
// 5 = uptodate


export function getProfileUpdatedScore(u, c, w = []) {
    if (w.length > 0) {
        return 2;
    }

    if (!u) {
        return 1;
    }

    if (u === c) {
        return 1;
    }

    if (isInLastThreeMonths(u)) {
        return 5;
    }

    if (isInLastSixMonths(u)) {
        return 4;
    }

    // updated_at
    // created_at
    return 3;
}