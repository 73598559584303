import React, {useContext, useState} from 'react';
import {Button} from "../../../library/components/core";
import {CheckIcon} from "@heroicons/react/solid";
import {getEntityNotificationPreferences} from "../../../library/utilities/notifications";
import {CommunityContext} from "../community-context";
import {GroupUpdates} from "../group/updates";
export function GroupSubscribeButton({group_id,layout="button"}) {
    const community = useContext(CommunityContext);
    const notification_prefs = getEntityNotificationPreferences(community.member.notifications, 'group', group_id);

    const is_member = community.member.groups[group_id];

    if(!is_member) {
        // todo add quick join method
        return null;
    }
    // if is not member, join & subscribe

    return <GroupUpdates layout={layout} notification_prefs={notification_prefs} id={group_id} member_id={community.member_id}
                         community_uid={community.uid} cb={() => {
    }}/>
}