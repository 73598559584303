import {memoize} from "lodash";
import {FirebaseDB} from "../../../../config/setup-firestore";

export async function waitForResult(ref) {
    return new Promise((resolve, reject) => {
        ref.onSnapshot(doc => {
            if (doc.exists&&doc.data()&&doc.data().response !== null) {
                // Do things
                resolve(doc.data().response);
            } else if(!doc.exists) {
                resolve({results: [],page:{}});
            }
        });
    });
}

export const queryMembersData = memoize(async (request, community_uid) => {
    const ref = await FirebaseDB
        .collection('searches')
        .add({
            type: 'members',
            community_uid: community_uid,
            request,
            response: null
        });

    return await waitForResult(ref);
});

/*
"sort": {
    "title": "desc"
  }

"sort": [
    { "_score": "desc" },
    { "title": "desc" }
  ]
 */

function buildFilters(community_uid, id, options) {
    if(options.type==='interest') {
        return {
            all: [
                {interests: id}
            ]
        }
    } else if(options.not_in_group) {
        return {
            none: [
                {groups:id}
            ]
        }
    }
    return {
        all: [
            {groups: id}
        ]
    }
}

function buildSearchFields(options) {
    if(options.type==='interest') {
        return {
            name: {},
            interests: {}
        }
    }
    return {
        name: {},
        email: {},
        handle: {}
    }
}

function buildSort() {
    return {
        last_name: "asc"
    }
}

function buildResultFields() {
    return {
        name: {raw: {}},
        account_status: {raw: {}},
        member_type: {raw: {}},
        profile_picture: {raw: {}},
        handle: {raw: {}},
    }
}

function buildPage(current=0,options={size:10}) {
    return {
        size: options.size||10,
        current: current||0
    }
}

export async function getGroupMembers(community_uid, group_id, query, current=0, options={
    size: 10,
    not_in_group: false,
    type: 'group'
}) {
    const request = {
        ...buildPage(current,options),
        filters: buildFilters(community_uid,group_id,options),
        result_fields: buildResultFields(options),
        search_fields: buildSearchFields(options),
        sort: buildSort(),
        query: query
    };

    return await queryMembersData(request, community_uid);
}