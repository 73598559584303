import React, {useEffect, useState} from 'react';
import {EditorBlock} from 'draft-js';
import focusCurrentBlock from "../../helpers/focusCurrentBlock";
import {InlineLoader} from "../../../core";

const max_width = 608;
const max_height = 460;

function getImageDimensions(data,mw) {
    const dh = parseInt(data.height);
    const dw = parseInt(data.width);

    const square = dh === dw;
    const vertical = dh > dw;

    const height_exceeds_max = dh > max_height;
    const width_exceeds_max = dw > mw;

    if(square) {
        if(height_exceeds_max) {
            return {
                height: max_height,
                width: max_height
            }
        } else {
            return {
                height: dh,
                width: dw
            }
        }
    } else if(vertical&&height_exceeds_max) {
        return {
         height: max_height,
         width: dw / (dh/max_height)
        }
    } else if(!vertical&&height_exceeds_max&&width_exceeds_max) {
        return {
            maxWidth: '100%',
         width: mw,
         height: dh / (dw/mw)
        }
    } else if(width_exceeds_max) {
        return {
            width: mw,
            height: dh / (dw/mw)
        }
    } else {
        return {
            width: '100%',
            height: 'auto',
            maxHeight: '480px'
        }
    }
}

function getContainerWidth() {
    const el = document.getElementById("message-content");
const minus = 80;
    if(!el) {
        return 608-minus
    } else {
        return el.children[0].clientWidth-minus;
    }
}


export function EB_Image(props) {
    const setEditorState = props.blockProps.setEditorState;
    const read_only = props.blockProps.read_only;
    const currentBlock = props.block;
    const data = Object.fromEntries(currentBlock.getData());
    const text = currentBlock.getText();
    const has_focus = false;

    if(props&&props.blockProps&&props.blockProps.hide) {
        return null;
    }

    let content;

    const {height,width} = getImageDimensions(data,getContainerWidth());
    // 378.6858075040783 608
    content = <div className={`image-block group ${read_only?"pointer-events-none":""}`}>
        <div className={``} contentEditable={false}>
            {data.loading&&<div className="">
                <div className="bg-gray-100 mx-auto rounded-sm flex items-center justify-center" style={{height:`${parseInt(height)}px`,width:`${width}px`,aspectRatio: ""}}>
                    <InlineLoader padding />
                </div>
            </div>}
            {data.url&&<img
                     className={`block border border-gray-200 rounded-md `}
                height={height} width={width} style={{aspectRatio: '',height:'auto',width,maxHeight:`100%`,maxWidth:`100%`}}
                src={data.url}
                alt=""
                onClick={focusCurrentBlock({
                    setEditorState,
                    block: currentBlock
                })}
            />}

        </div>
        {(!read_only||(read_only&&text))&&<div className="text-xs relative mt-1.5 mb-1.5 mx-0.5 max-w-md text-gray-600 text-left">
        <EditorBlock {...props} />
            {!read_only&&!text&&!has_focus&&<span className="absolute top-0 left-0 pointer-events-none text-gray-400">Add a Caption</span>}
        </div>}
    </div>;

    return content;
}