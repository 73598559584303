
import React, {useContext} from "react";
import styled from "styled-components";
import {DEFAULT_USER_PHOTO} from "../../../../config/defaults";
import {CommunityContext} from "../../../../app/community/community-context";


const PPCont = styled.div`
    border-radius: 42%;
    cursor: pointer;
    position: relative;
      background-position: center !important;
    background-size: 101% !important;
`;
const Online = styled.div`
    background-color: #12bd81;
        height: 12px;
    position: absolute;
    bottom: -4px;
    width: 12px;
    right: -4px;
    border-radius: 50%;
    border: 2px solid #fff;
`;
const sizes = {
    'mini': {
        height: '20px',
        width: '20px'
    },
    'small': {
        height: '34px',
        width: '34px'
    },
    'medium': {
        height: '56px',
        width: '56px'
    },
    'large': {
        height: '156px',
        width: '156px'
    },
};

const EmojiItem = styled.div`
line-height: ${props=>props.lineHeight};
    font-size: ${props=>props.fontSize};
    text-align: center;
`;

function getUrl(pp,size) {
    if(pp.indexOf('?alt=media')!==-1) {
        return pp;
    }
    switch (size) {
        case 'large' : {
            return `${pp}?alt=media`;
        }
        case 'medium' : {
            return `${pp}_medium?alt=media`;
        }
        default : {
            return `${pp}_small?alt=media`;
        }
    }
}

function getFontSize(height) {
    let final_height = height.replace('px', '');

    return `${final_height/1.2}px`;
}

export const ProfilePicture = ({image='',onClick=()=>{},emoji='',hide_initials=false,online=false,size='small',height,width,text=''}) => {
    const community = useContext(CommunityContext);
    const type = image !== '' ? 'image' : 'text';
    let sizing = height ? {height,width} : sizes[size];
    let style_item = {};
    if(image!=='') {
        style_item.backgroundImage = `url('${getUrl(image,size)}')`;
    } else if(emoji==='') {
        style_item.backgroundImage = `url('${getUrl(community&&community.default_profile_picture?community.default_profile_picture:DEFAULT_USER_PHOTO,size)}')`;
    }
    return <PPCont onClick={onClick} style={{...style_item, ...sizing}} className={`${type}`}>
        {emoji!==''&&type==='text'&&<EmojiItem lineHeight={sizing.height} fontSize={getFontSize(sizing.height)}>{emoji}</EmojiItem>}
        {online&&<Online />}
    </PPCont>
};