export function us_clearRecentSearches(context) {
    const item = localStorage.getItem(`recent_searches-${context}`);
    if (item) {
        return localStorage.removeItem(`recent_searches-${context}`);
    } else {
        return true;
    }
}
// or community uid
export function us_getRecentSearches(context='global') {
    const ls = localStorage.getItem(`recent_searches-${context}`);
    return ls ? JSON.parse(ls) : [];
}

export function us_addRecentSearch(str,context) {
    let current = us_getRecentSearches(context);
    if(!current.includes(str)) {
        current.splice(0,0,str);
        const final = current.slice(0,4);
        localStorage.setItem(`recent_searches-${context}`, JSON.stringify(final));
    }
   return true;
}