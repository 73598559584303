import React, {useState} from "react";
import styled from "styled-components";
import {SimpleListbox} from "../../core";


export default function Select(props) {
    const {value, disabled, onChange, onEscape=()=>{}, onEnter=()=>{}, field, usePortal=false, compact, fill=true, options, placeholder = 'None', blank_option = true} = props;

    let opts = [

    ];

    if(blank_option) {
        opts.push({
            value: '',
            label: placeholder
        });
    }

    opts = opts.concat(options.map(opt=>{
        return {
            label: opt.text?opt.text:opt.label,
            value: opt.value
        }
    }))

    return (
        <div className={`relative ${disabled?"opacity-70":""}`}>
            <SimpleListbox disabled={disabled} onEnter={onEnter} onEscape={onEscape} compact={compact} autofocus={false} usePortal={usePortal} value={value} onChange={(it) => {
                onChange(field, it.value);
            }} fill={fill} searchable options={opts} />
        </div>
    );
}