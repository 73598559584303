import React, {useEffect, useState} from "react";

export function SearchResults({t,q}) {
    const [type,setType] = useState(t);
    const [query,setQuery] = useState(q);

    useEffect(function() {
        setType(t);
        setQuery(q);
    }, [t,q]);

    return <div>


    </div>
}