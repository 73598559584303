import React, {useContext, useEffect, useState} from 'react';
import {CommunityContext} from "../../../community-context";
import {GlobalContext} from "../../../../global/global-context";
import {FirebaseDB} from "../../../../../config/setup-firestore";
import {setDocumentTitle} from "../../../../../library/utilities/set-document-title";
import {PageContainer} from "../../../../../library/components/old/page-container";
import {AdminEntityInfo, Button, Field, PageHeader} from "../../../../../library/components/core";
import {InboxInIcon} from "@heroicons/react/solid";
import styled from "styled-components";
import EditFieldsSection from "../../../../../library/components/old/edit-fields-section";
import {DoubleCheckModal} from "../../../../../library/components/old/double-check-modal";
import DeleteModal from "../../../../../library/components/old/delete-modal";
import {NewEntitySearch} from "../../../../../library/components/reorganize/entity-search/new-entity-search";
import {authFetch} from "../../../../../config/network";
import {StandardPageHeight} from "../../../../../library/playground/standard-page-framework";
import {EntityInfo} from "../../../../../library/utilities/localstorage/entity-localstorage";

const ProfileContainer = styled.div`
    display: grid;
    grid-template-columns: 600px 1fr;
    grid-gap: 20px;
`;

function getMemberValue(v) {
    if (!v) {
        return null;
    }
    if (typeof v === 'string') {
        return {
            type: 'members',
            value: v
        }
    } else {
        return v;
    }
}

function GroupName({data}) {
    return data.name
}

export function NewMemberApplication(props) {
    const community = useContext(CommunityContext);
    const global = useContext(GlobalContext);
    const [error, setError] = useState(null);
    const [modal, setModal] = useState(null);
    const [replace_member, setReplaceMember] = useState(null);
    const [modal_data, setModalData] = useState(null);
    const [loading, setLoading] = useState('application');
    const [id, setId] = useState(props.id);
    const [data, setData] = useState({});

    useEffect(function () {
        getApplication(id);
    }, [id]);

    function getApplication(id) {
        FirebaseDB
            .collection('community_members')
            .doc(community.uid)
            .collection('applications')
            .doc(id)
            .get()
            .then(handleApplication.bind(this));
    }

    function handleApplication(doc) {
        if (!doc.exists) {
            setError('not-found');
            setLoading(null);
        } else {
            let document = doc.data();
            document.id = doc.id;
            setDocumentTitle(document.name, community.data.name);
            setLoading(null);
            setData({...document});
        }
    }

    function handleReject() {
        if (loading === 'rejecting') {
            return;
        }
        setLoading('rejecting');
        const res = () => {

            global.addToast({text: `Application was rejected.`, intent: 'success'});
            const {history} = props;
            history.push(`/${community.data.handle}/admin/settings/applicants`)
        };

        const err = () => {

            global.addToast({text: `Application could not be rejected.`, intent: 'danger'});
        };

        const payload = {
            community_uid: community.uid,
            id: id,
            message: '',
            member_id: community.member_id
        };

        authFetch("/deny-application", res, err, "POST", {payload});
    }

    function handleApprove() {
        if (loading === 'approving') {
            return;
        }
        setLoading('approving');
        const res = (resp) => {
            global.addToast({text: `Member was added to the community.`, intent: 'success'});
            const {history} = props;
            history.push(`/${community.data.handle}/member/${resp.member.handle}`)
        };

        const err = () => {

            global.addToast({text: `Member could not be added.`, intent: 'danger'});
        };

        const payload = {
            community_uid: community.uid,
            id: id,
            admin_message: '',
            merge_with_member_id: replace_member,
            member_id: community.member_id
        };

        authFetch("/approve-application", res, err, "POST", {payload});
    }

    if (!data.name) {
        return null;
    }

    const obj = {
        data: data,

        type: 'application',
        id: id,
        endpoint: '/update-application',
        update_fields: ['profile','phone_number'],

        sections: [
            {
                title: 'Profile',
                fields: [
                    {
                        field: 'profile.first_name',
                        type: 'text',
                        defer: true,
                        name: 'First Name'
                    },
                    {
                        field: 'profile.last_name',
                        type: 'text',
                        defer: true,
                        name: 'Last Name'
                    },
                    {
                        field: 'profile.middle_name',
                        type: 'text',
                        defer: true,
                        name: 'Middle Name'
                    },
                    {
                        field: 'profile.title',
                        type: 'text',
                        defer: true,
                        name: 'Title'
                    },
                    {
                      field: 'phone_number',
                      type: 'phone',
                      defer: true,
                      name: 'Phone'
                    },
                    {
                        field: 'profile.city',
                        type: 'text',
                        defer: true,
                        name: 'City'
                    },
                    {
                        field: 'profile.country',
                        type: 'country',
                        defer: true,
                        name: 'Country'
                    },
                    {
                        name: 'Profile Picture',
                        edit_only: true,
                        type: 'profile-picture',
                        field: 'url:profile.profile_picture,color:profile.profile_picture_color'
                    },
                ]
            }
        ]
    };

    return <StandardPageHeight full content>
        <div className="pt-2">
            <AdminEntityInfo type_icon={<InboxInIcon/>} id={data.account_email} type_label={"Application"} />

        </div>
<div className="px-4">

        <PageHeader action={<div className="space-x-2 flex">
           <Button onClick={()=>{
            setModal('reject-application');
           }} loading={loading==='rejecting'} text={"Reject"} intent="danger" />
           <Button onClick={()=>{
               setModal('open-approve');
           }} loading={loading==='approving'} text={"Approve"} intent="secondary" />
        </div>} divider size={"2xl"} title={data.name} subtitle={`${data.account_email} (@${data.handle})`} />

</div>
        <div className="px-4">
        <ProfileContainer>
            <div>
                <EditFieldsSection member_types={community.member_types} id={id} member_id={community.member_id}
                                   community_uid={community.uid} history={props.history} {...obj} />
            </div>
            <div className="pt-3 space-y-3 px-1 text-sm text-gray-700">
                <div>
                <strong>{data.profile.first_name}</strong> applied with the message: {data.message}
                </div>
                {data.group_id&&<div className="">

                    Applied to <strong><EntityInfo type="groups" id={data.group_id} community_uid={community.uid}>
                    <GroupName />
                </EntityInfo></strong>
                </div>}
            </div>

        </ProfileContainer>
        </div>
        {modal === 'reject-application' && <DoubleCheckModal onConfirm={() => handleReject()} onClose={() => {
            setModal(null);
            setModalData(null)
        }} onCancel={() => {
            setModal(null);
            setModalData(null)
        }} type='application_reject'/>}

        {modal==='open-approve' && <DeleteModal
            deleting={loading==='approving'}
            intent={'secondary'}
            can_delete={true}
            content={<>
              <Field label="Merge with existing member?" corner_hint="optional" help_text="If you merge this profile with an existing member, that profile will be updated with any new information in this application.">
                <NewEntitySearch placeholder={"Select member to merge"} context={{community_uid:community.uid}} is_multi={false}
                                 searchable={{members: true}}
                                 excluded={{members: {[community.member.user_uid]: true}}} onChange={(val) => {
                    setReplaceMember(val.value);
                }} value={getMemberValue(replace_member)}/>
              </Field>
            </>}
            text={replace_member?'Merge members':'Add new member'}
            title={`Add ${data.profile.first_name} to ${community.data.name}`}
            onDelete={handleApprove.bind(this)}
            onClose={() => {
                setModal(null)
            }}/>}
    </StandardPageHeight>
}