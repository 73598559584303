import React, {useContext} from "react";

import {PageContainer} from "../../../../../library/components/old/page-container";

import {setDocumentTitle} from "../../../../../library/utilities/set-document-title";
import {EntityHeader, PageHeader} from "../../../../../library/components/core";
import {Button} from "../../../../../library/components/core";
import {PlusIcon} from '@heroicons/react/solid';
import {FiltersList} from "./FiltersList";
import {CommunityContext} from "../../../community-context";
import {StandardPageHeight} from "../../../../../library/playground/standard-page-framework";

export default function SettingsFilters(props) {
    const community = useContext(CommunityContext);

    setDocumentTitle('Filters', community.data.name);

    const {history} = props;

    const community_uid = community.uid;

    const base_link = `/${community.data.handle}/admin/settings`;


    return (<StandardPageHeight full content>

        <EntityHeader title="Filters" actions={<Button onClick={() => {
            props.history.push(`/${community.data.handle}/admin/settings/create-filter`)
        }} intent='secondary' icon={<PlusIcon/>} text='Create Filter'/>} goBackTo={()=>history.push(`/${props.community.data.handle}/admin/settings`)}  />


        <FiltersList handle={community.data.handle} history={props.history} community_uid={community_uid}/>
    </StandardPageHeight>);
}