import React, {useContext, useEffect, useState} from 'react';
import {StandardPageFramework} from "../../../library/playground/standard-page-framework";
import {EntityHeader} from "../../../library/components/core";
import {GlobalContext} from "../../global/global-context";
import {CommunityContext} from "../community-context";
import {FirebaseDB} from "../../../config/setup-firestore";
import {Notifications} from "../../../library/components/notifications";

export function CommunityNotifications() {
    const [notifications, setN] = useState([]);
    const global = useContext(GlobalContext);
    const community = useContext(CommunityContext);

    function handleResult(ns) {
        setN([...ns]);
    }

    useEffect(function () {
        let listener = () => {};
        if (community.uid) {
            listener = FirebaseDB
                .collection('community_members')
                .doc(community.uid)
                .collection('notifications')
                .where('context_id','==',`${community.member_id}`)
                .orderBy('ts', 'desc')
                .limit(6)
                .onSnapshot((snap) => {
                    if(snap.empty) {
                        handleResult([]);
                    } else {
                        handleResult(snap.docs.map(d=>{
                            let o = {...d.data()};
                            o.id = d.id;
                            return o;
                        }));
                    }
                })
        } else {
            setN([]);
        }

        return () =>  listener();
    }, [community.uid]);


    const unread = notifications.filter(a => !a.read).map(b => b.id);

    const has_unread = unread.length > 0;

    return <StandardPageFramework layout="sidebar" header={<EntityHeader onClick={() => {
        const el = document.getElementById("main-content");
        if (el) {
            el.scrollTo(0, 0);
        }
    }} title="Notifications"/>}>
        <Notifications updates={notifications} context={{community_handle:community.handle,community_uid:community?community.uid:""}} />
    </StandardPageFramework>
}