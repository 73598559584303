export const teams_suggestions = [
    {
        name: 'Board of Directors',
        purpose: '',
        roles: ['Board Chair', 'Board Secretary'],
        emoji: '',
        tags: ['social']
    },
    {
        name: 'Leadership',
        purpose: '',
        roles: ['President', 'Vice President', 'Secretary', 'Treasurer'],
        emoji: '',
        tags: ['social','non-profit']
    },
    {
        name: 'Advisory Board',
        purpose: '',
        roles: ['Advisor 1', 'Advisor 2', 'Advisor 3'],
        emoji: '',
        tags: []
    },
    {
        name: 'Fundraising',
        purpose: '',
        roles: ['Head of Fundraising'],
        emoji: '',
        tags: ['non-profit','political','sports']
    },
    {
        name: 'Partnerships',
        purpose: '',
        roles: ['Head of Partnerships'],
        emoji: '',
        tags: ['non-profit']
    },
    {
        name: 'Community Life',
        purpose: '',
        roles: ['Head of Community Life'],
        emoji: '',
        tags: ['service']
    },
    {
        name: 'Membership',
        purpose: 'Responsible for proposing programming and resources that contribute to a sense of belonging.',
        emoji: '',
        roles: ['Head of Membership'],
        tags: ['social','service']
    },
    {
        name: 'Recruitment',
        purpose: 'Responsible for proposing policies, processes, and resources that contribute to recruitment and onboarding programs for potential new members.',
        emoji: '',
        roles: ['Head of Recruitment'],
        tags: ['social','service']
    },
    {
        name: 'Education',
        purpose: '',
        roles: ['Head of Education'],
        emoji: '',
        tags: ['social','service','religion']
    },
    {
        name: 'Mission',
        purpose: '',
        roles: ['Head of Mission'],
        emoji: '',
        tags: ['religion']
    },
    {
        name: 'Worship',
        purpose: '',
        emoji: '',
        roles: ['Head of Worship'],
        tags: ['religion']
    },
    {
        name: 'Congregational Life',
        purpose: '',
        emoji: '',
        roles: ['Head of Congregational Life'],
        tags: ['religion']
    }
];