import React from 'react';
import styled from "styled-components";
import {SUPPORT_EMAIL} from "../../../../../config/defaults";

const Frame = styled.div`
        margin-top: 16px;
    font-size: 14px;
    max-width: 400px;
`;

export const CommunityChangeOwner = () => {

    return <Frame className='text-gray-700'>
        If you would like to change the owner or delete the community, please contact us at <a href='mailto:hey@getunaty.com'>hey@getunaty.com</a>.
    </Frame>
};