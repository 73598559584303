import React, {Component} from 'react';
import styled from "styled-components";
import {EntityIconSelector} from "../../core/entity-icon-selector";

const Meta = styled.div`
      margin-top: 6px;
    font-size: 13px;
`;

export class TextAndEntityIcon extends Component {
    constructor(props) {
        super(props);
        this.caught = false;
        this.state = {
            has_focus: false
        };
    }

    handleChange(e) {
        const {changeText,text} = this.props;
        const val = e.target.value;
        if(text===''&&!this.caught&&(val==='n'||val==='N')) {
            this.caught = true;
            return;
        }

        changeText(val);
    }

    render() {
        const {emoji='',meta,highlight=false,selector_type='profile-picture',allowed_types=['color','emoji','image'],changeColor,changeProfilePicture,profile_picture,color,autoFocus=false,onBlur=()=>{},label,placeholder='Set a status',text,changeEmoji,changeText} = this.props;
        return <>
            <EntityIconSelector selector_type={selector_type} allowed_types={allowed_types} onBlur={onBlur} profile_picture={profile_picture} color={color} onChange={(n,t,e,m)=>{
                if(t==='emoji') {
                    changeEmoji(e)
                } else if(t==='profile_picture') {
                    changeProfilePicture(e,m);
                } else if(t==='color') {
                    changeColor(e);
                } else {
                    changeText(n);
                }
            }} label="Name" emoji={emoji} value={text} placeholder={placeholder} />
            {meta&&<Meta className='text-gray-700'>{meta}</Meta>}
        </>
    }
}