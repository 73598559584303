import React, {useContext, useState} from "react";

import {PlusIcon} from '@heroicons/react/solid';
import RichList from "../../../../../library/components/old/rich-list";
import {Button, EntityHeader} from "../../../../../library/components/core";

import {PageHeader} from "../../../../../library/components/core";
import {PageContainer} from "../../../../../library/components/old/page-container";
import {HotkeyWrapper} from "../../../../../library/components/old/hotkey-wrapper";
import {setDocumentTitle} from "../../../../../library/utilities/set-document-title";
import {CommunityContext} from "../../../community-context";
import {CreateSegment} from "./create-segment";
import {StandardPageHeight} from "../../../../../library/playground/standard-page-framework";
import {useHistory} from "react-router-dom";

export default function SettingsMemberSegments(props) {
    const community = useContext(CommunityContext);
    const [open, setOpen] = useState(false);
const history = useHistory();
    setDocumentTitle('Member Segments', community.data.name);

    function goToCreate() {
        setOpen(true);
    }

    const base_link = `/${community.data.handle}/admin/settings`;

    const options = {
        type: 'filters',
        need_to_load_data: true,
        allow_selection: false,
        scroll_style: 'table', // infinite
        per_page: 15,
        query: {
            parent_collection: 'community_entities',
            parent: 'community_entities',
            parent_doc_id: community.uid,
            parent_id: community.uid,
            collection: 'filters',
            where: []
        },

        link_base: `${base_link}/segment`,
        link_id_field: 'docid',

        default_sort: {
            field: 'updated_at',
            dir: 'desc'
        },

        renderer: {
            columns: [
                {
                    text: 'Name',
                    type: 'title',
                    field: 'name',
                    width: '2fr',
                    show: true
                },
                {
                    text: '# of Members',
                    type: 'key-count',
                    field: 'user_uids',
                    width: '1fr',
                    show: true
                },
            ],
            row: {
                actions: []
            }
        },

        sort_options: [
            {
                name: 'Name',
                field: 'name',
                type: 'text',
                modifiers: ['desc', 'asc']
            }
        ],

        filter_options: [],

        header: {
            show_properties: false,
            show_filter: false,
            show_sort: false,
            show_search: false,

            mass_actions: []
        }
    };

    const hotkeys = [
        {
            id: 'N',
            keys: ["n"],
            fn: () => {
                goToCreate();
            }
        }
    ];

    return (<StandardPageHeight content full>

            <EntityHeader title="Segments" actions={<Button onClick={goToCreate.bind(this)} intent='secondary' icon={<PlusIcon/>} shortcut={'N'}
                                                           text='Create'/>} goBackTo={()=>history.push(`/${community.data.handle}/admin/settings`)}  />

                <RichList {...props} options={options}/>

                {open&&<CreateSegment onClose={()=>setOpen(false)} />}
            </StandardPageHeight>
    );
}
