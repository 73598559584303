import React from "react";
import {
    GlobeAltIcon
} from "@heroicons/react/outline";
import {AdminEntitySummary} from "./admin-entity-summary";
import {LanguageBadge} from "./language-badge";
import AdminMemberSecondaryEmails from "../emails";

function buildItems(props) {
    return [
        /*
        {
            icon: <MapIcon/>,
            title: 'Timezone',
            badge: true,
            comp: <TimezoneBadge {...props}  />
        },

         */
        {
            icon: <GlobeAltIcon/>,
            title: 'Language',
            badge: true,
            comp: <LanguageBadge changeLanguage={(nl)=>{
            props.hc('preferences.language',nl);
            }} {...props} />
        }
    ];
}

export function NewAdminMemberAccount({member,pq,hc,member_id,community,meta}) {

    const items = buildItems({community, member, member_id, pq, hc});

    return <div className="space-y-4">
        <AdminEntitySummary items={items} member_id={member_id} community={community} member={member} meta={meta}/>
        <AdminMemberSecondaryEmails hide_title updateMember={() => {}}
                                    user_uid={member.user_uid}/>
    </div>

}