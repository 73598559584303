import {analytics} from "firebase";
import {logEngagement} from "../../../../api/analytics";
import {authFetch} from "../../../../config/network";
export function composer_handleSendReply(payload, {community, global}, res) {
    global.addToast({text: 'Reply posted', intent: 'info'});
    analytics().logEvent('send_invite', {
        event_id: 'post_reply',
        content_type: 'post',
        community_uid: community.uid,
        content_id: community.member.user_uid
    });

    logEngagement("post_reply", {
        community_uid: community.uid,
        member_id: community.member_id,
        user_uid: community.member.user_uid
    });

    const final_payload = {
        user_uid: community.member.user_uid,
        reply_id: payload.reply_to_id,
        member_id: community.member_id,
        community_uid: community.uid,
        raw: payload.raw,
        attachments: payload.attachments,
        images: payload.images,
        reply_to_id: payload.reply_to_id,
        post_id: payload.post_id,
        plain_text: payload.plain_text
    }

    authFetch("/posts/reply", res, res, "POST", {payload: final_payload});
}