import React, {useState} from "react";
import AsyncCreatableSelect from 'react-select/async-creatable';
import {advanced_select_styles} from "../shared/styles";
import { advanced_select_components_simple} from "../shared/components";
import {cleanQuery} from "../../reorganize/entity-search/new-entity-search";
import {FirebaseDB} from "../../../../config/setup-firestore";

async function queryTaxonomyDB(q,taxonomy) {
    return await FirebaseDB
        .collection('taxonomies')
        .doc(taxonomy)
        .collection('options')
        .orderBy('search_name')
        .startAt(q)
        .endAt(q + "\uf8ff")
        .limit(50)
        .get()
        .then((snap)=>{
            let obj = {};

            snap.docs.forEach((doc)=>{
                obj[doc.id] = {
                    id: doc.id,
                    label: doc.data().label
                };
            });
            return obj;
        });
}

function getShowValue(val) {
    return !val ? "" : {
        label: val,
        id: val,
        simple: true
    }
}

function filterResults(res,existing) {
    const me = existing.map(a=>a.label);
    const final = Object.values(res).filter(a=>me.indexOf(a.label)===-1);
    return final;
}

export function CreateableAsyncSelect({
autoFocus = false,
                                          multi = false,
                                          existing = [],
    taxonomy = 'fields_of_study',
    value = null,
    placeholder = "Start typing..",
                                          onChange = () => {}
                                      }) {
    const [query,setQuery] = useState("");
    const [selected,setSelected] = useState(value);
    const [loading, setLoading] = useState(false);
    const [results, setResults] = useState({});
    const [last_q, setLastQ] = useState("");

    function loadOptions(q, cb) {
        const fq = cleanQuery(q);
        if (!loading && fq.length > 0 && last_q !== fq) {
            setLoading(true);

            queryTaxonomyDB(fq,taxonomy).then(res=>{
                const new_results = {...results,...res};
                setResults(new_results);
                setLastQ(fq);
                setLoading(false);

                cb(filterResults(new_results,existing));
            })
        } else {
         cb(filterResults(results,existing));
        }
    }

    function handleInputChange(q) {
        setQuery(q);
    }

    function handleChange(it) {
        if(!it) {
            setSelected('');
            onChange('');
            setQuery("");
        } else {
            if(!multi) {
                setSelected(it.label);
                setQuery("");
            }
            onChange(it.label);
        }
    }

    return (
        <AsyncCreatableSelect
            cacheOptions
            placeholder={placeholder}
            defaultOptions={filterResults(results,existing)}
            onInputChange={(v) => handleInputChange(v)}
            autoFocus={autoFocus}
            menuPortalTarget={document.body}
            styles={advanced_select_styles}
            components={advanced_select_components_simple}
            menuPlacement={"auto"}
            onChange={(v) => handleChange(v)}
            value={getShowValue(selected)}
            menuPosition={'fixed'}
            loadOptions={(q, cb) => loadOptions(q, cb)}

        />
    );
}