import {authFetch} from "../../../config/network";

export async function api_likeReply(community,post_id,reply_id) {
    return await new Promise((resolve, reject) => {
        const res = () => {
            resolve();
        };
        const payload = {
            community_uid: community.uid,
            member_id: community.member_id,
            reply_id,
            post_id: post_id
        };
        authFetch("/posts/like-reply", res, res, "POST", {payload});
    })
}