import React, {Component} from "react";
import styled from "styled-components";

import {authFetch} from "../../../config/network";


import {Button} from "../../../library/components/core";
import Field from "../../../library/components/old/field";

import Form from "../../../library/components/old/form";
import Modal from "../../../library/components/old/modal";
import {TextAndEmoji} from "../../../library/components/old/text-and-status";
import UnProgressBar from "../home/un-progress-bar";
import {FirebaseDB} from "../../../config/setup-firestore";
import {CommunityContext} from "../community-context";

function getSelectedOption(arr, value) {
    return arr.filter(obj => {
        return obj.value === value
    })[0]
}

export const folder_security_options = [
    {
        value: 'public',
        text: 'Public - All Community Members',
        desc: 'Any member will be able to access this folder'
    },
    {
        value: 'private',
        text: 'Private - Only Members I grant access',
        desc: 'Only members you select will be able to access this folder'
    }
];

export const folder_permissions_options = [
    {
        value: 'viewer',
        text: 'View-only',
        desc: 'Members can only view and download files'
    },
    {
        value: 'editor',
        text: 'Edit Files and Folders',
        desc: 'Members can upload, edit, and delete files and folders'
    }
];

const Steps = styled.div`
    line-height: 32px;
    font-size: 14px;
    color: #697386;
`;

const Actions = styled.div`
    display: flex;
    > div {
       margin-left: 12px; 
    }
`;

const ProgressBarCont = styled.div`
    padding-top: 12px;
`;

export function getPassEntities(entities, filters) {
    let to_return = entities;

    to_return.filters = [];

    for (let property in filters) {
        if (filters.hasOwnProperty(property)) {
            // Do things here
            // property === member_type
            // for each item in array
            const item = filters[property];
            for (let i = 0; i < item.length; i++) {
                const it = item[i];
                to_return.filters.push({
                    field: property,
                    value: it.value,
                    modifier: it.modifier,
                    operator: it.operator
                })
            }
        }
    }

    return to_return;
}

export default class NewFolder extends Component {
    static contextType = CommunityContext;

    constructor(props, context) {
        super(props, context);

        this.state = {
            step: 1,
            name: '',
            purpose: '',
            emoji: '',
            color: '',
            folder: '',
            security: 'public',
            permissions_type: 'editor',
            type: 'standard',

            filters: {},

            folder_names: [],

            entities: {
                members: {
                    [context.member.user_uid]: true
                },
                member_types: {},
                filters: {},
                groups: {},
                teams: {},
                roles: {}
            },

            creating: false,

            handle_is_taken: false,
            handle_is_valid: true,
            checked_handle: false
        };
    }

    componentDidMount() {
        FirebaseDB
            .collection('community_entities')
            .doc(this.context.uid)
            .collection('folders')
            .where('folder', '==', this.props.folder)
            .get()
            .then((snap) => {
                this.setState({
                    folder_names: snap.docs.map((doc) => doc.data().name.toLowerCase())
                })
            })
    }

    hc(f, v) {
        this.setState({
            [f]: v
        })
    }

    handleBlur() {
        const name = this.state.name;

        this.setState({
            handle_name: name
        })

    }

    step(n) {
        this.setState({
            step: (this.state.step + n)
        })
    }

    create() {
        const {creating, name, purpose, permissions_type, type, security, filters, emoji, entities} = this.state;
        if (creating) {
            return;
        }

        this.setState({
            creating: true,
            step: 3
        });

        const payload = {
            name,
            purpose,
            emoji,
            type,
            permissions_type,
            folder: this.props.folder,
            security,
            filters,
            entities,
            community_uid: this.context.uid
        };

        authFetch("/folders/create", this.handleResult.bind(this), this.handleError.bind(this), "POST", {payload});
    }

    handleResult(result) {
        this.props.addToast({text: `Folder was created.`, intent: 'success'});

        // if is top level folder and is private
        if(this.props.folder===''&&this.state.security==='private') {
            this.props.history.push(`/${this.context.data.handle}/explore/drive/folder/${result.data.id}?action=add-members`)
        } else {
            this.props.history.push(`/${this.context.data.handle}/explore/drive/folder/${result.data.id}`)
        }
        this.props.onClose();
    }

    handleError() {
        this.props.addToast({text: `Folder could not be created.`, intent: 'error'});
        this.setState({
            creating: false
        });
        this.props.onClose();
    }

    handleChange(f, v) {
        let preferences = this.state.preferences;
        preferences[f] = v;
        this.setState({
            preferences
        })
    }

    disableButton(disable) {
        return this.state.name.length < 2 || disable
    }

    getStepOneButton(security, folder, should_disable) {
        return <Button disabled={should_disable} text={'Create'} intent={'success'} onClick={() => {
            this.create();
        }}/>
    }

    render() {

        const {step, emoji, security, folder_names, permissions_type, name} = this.state;

        const {onClose, open, folder} = this.props;

        let body, footerRight, footerLeft, title;

        const name_taken = folder_names.includes(name.toLowerCase());

        const should_disable = this.disableButton(name_taken);



        switch (step) {
            case 1 : {
                title = "New Folder";
                footerRight = <div className="flex space-x-2">
                    <Button text="Cancel" intent='standard' onClick={onClose}/>
                    {this.getStepOneButton(security, folder, should_disable)}
                </div>;
                body = <Form handleSubmit={(e) => {
                    if(e) {
                        e.stopPropagation();
                    }
                    if(!should_disable) {
                        this.create();
                    }
                }}>

                    <TextAndEmoji meta={name_taken ? `This name is already taken.` : null}
                                  onBlur={this.handleBlur.bind(this)} label='Name' placeholder='Folder Name'
                                  changeEmoji={(emoji) => {
                                      this.setState({emoji: emoji.native})
                                  }} emoji={emoji} text={name} changeText={(val) => {
                        this.setState({name: val})
                    }}/>

                    {folder === '' &&
                    <Field type={"select"} meta={getSelectedOption(folder_security_options, security).desc}
                           options={folder_security_options} blank_option={false} label="Who should have access?"
                           onChange={(v) => {
                               this.setState({security: v})
                           }}
                           value={security}/>}

                </Form>;
                break;
            }

            case 3 : {
                title = "Creating Folder";
                body = <ProgressBarCont>
                    <UnProgressBar seconds={2}/>
                </ProgressBarCont>;
                break;
            }
        }

        return (<Modal open={open} onClose={onClose} title={title}
                       body={body}
                       footerLeft={footerLeft}
                       footerRight={footerRight}
            />
        );
    }
}