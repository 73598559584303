import React from 'react';
import styled from "styled-components";

const Frame = styled.div`
display: flex;
    min-height: 54px;
    justify-content: flex-end;
    padding: 12px 16px;
    > div {
        margin-left: 12px;
    }
`;

export const PrActions = ({actions=null}) => {
    return <Frame>
        {actions}
    </Frame>
};