import React, {useState} from "react";
import styled from "styled-components";
import {useIntl} from "react-intl";
import {ArrowDownIcon, ArrowUpIcon} from "@heroicons/react/outline";

const fontSize = '15px';
const fsc = 'text-base';
const color_important = 'text-gray-900';
const color_title = 'text-gray-800';
const color_default = 'text-gray-700';
const color_subheader = 'text-gray-500';
const color_muted = 'text-gray-400';
const color_inverse = 'text-white';
const bkg_hover = 'bg-gray-100';

const standard_hover = `hover:bg-gray-200 cursor-pointer duration-100 transition-colors`;

const IconContainer = styled.div`
> svg {
  width: 22px;
  height: 22px;
}
`;


export function sidebar_getIconClasses(has_update, muted, active) {
    if (active) {
        return `${color_inverse} `;
    } else if (muted && has_update) {
        return ` ${color_muted}`;
    } else if (has_update) {
        return ` ${color_important}`;
    } else if (muted) {
        return `${color_muted}`;
    } else {
        return `${color_default}`;
    }
}

export function sidebar_getTextClasses(has_update, muted, active) {
    if (active) {
        return `font-semibold`;
    } else if (muted && has_update) {
        return ` font-medium italic`;
    } else if (has_update) {
        return ` font-semibold`;
    } else if (muted) {
        return `font-medium italic`;
    } else {
        return `font-medium`;
    }
}

export function sidebar_getBackgroundClasses(has_update, muted, active) {
    if (active) {
        return ``;
    } else if (muted && has_update) {
        return `hover:${bkg_hover}`;
    } else if (has_update) {
        return `hover:${bkg_hover}`;
    } else if (muted) {
        return `hover:${bkg_hover}`;
    } else {
        return `hover:${bkg_hover}`;
    }
}

function pathIsActive(path, it) {
    if(it.fuzzy_match) {
        return path.startsWith(it.path);
    } else {
        return it.path===path;
    }
}

function MenuSection({children}) {
    return <div className="">
        {children}
    </div>
}

function MenuItems({children, compact}) {

    return <div className="space-y-0.5">
        {children}
    </div>
}

const Cont = styled.div`
  color: ${props=>props.is_active?props.theme.color:props.theme.body_text};
  > div:nth-child(1) > div:nth-child(1) {

    .ci-fill {
      fill: ${props=>props.is_active?props.theme.hover:"#fff"} !important;
    }
    .ci-strokefill {
      fill: ${props=>props.is_active?props.theme.color:"#3f3f46"} !important;
    }
    .ci-stroke {
      stroke: ${props=>props.is_active?props.theme.color:"#3f3f46"} !important;
    }
  }
  
  :hover {
    color: ${props=>props.theme.color};
    > div:nth-child(1) > div:nth-child(1) {

      .ci-strokefill {
        fill: ${props=>props.is_active?props.theme.color:props.theme.color} !important;
      }
      .ci-stroke {
        stroke: ${props=>props.is_active?props.theme.color:props.theme.color} !important;
      }
  }
`;

function SingleItem({onClick,is_active,icon,label,theme,has_update,muted}) {

    return <div onClick={onClick} className={`py-px`}>
        <Cont hover={theme.hover} theme={theme} color={theme.color} is_active={is_active}
            className={`duration-100 group xl:w-auto w-full mx-auto py-1 gap-2 relative px-1.5 flex text-sm transition-colors cursor-pointer`}
            style={{gridTemplateColumns: "24px 1fr auto",backgroundColor:`${is_active?"":""}`}}>
            <div className="flex items-center justify-center w-6">
                <IconContainer is_active={is_active} theme={theme} className={`text-gray-600`}>
                    {icon}
                </IconContainer>
            </div>
            <div className={`${sidebar_getTextClasses(has_update, muted, is_active)} text-smbase flex items-center hidden-1250`}>
                <span className="menu-overflow-span" style={{maxWidth:'180px'}}>{label}</span>
            </div>
            <div className={`${has_update ? "w-4 flex items-center justify-center" : ""} hidden-1250`}>
                {has_update && !muted && <div className="h-2 w-2 bg-blue-400 rounded-full"/>}
            </div>
            <div className={`absolute ${is_active?"flex":"hidden group-hover:flex"}  items-center right-0 top-0 bottom-0`}>
                <div className="w-1 rounded-l-lg h-5 " style={{backgroundColor:`${is_active?theme.color:theme.hover_bold}`}} />
            </div>
        </Cont>
    </div>
}

export function SidebarMainSection({items,theme,goTo,compact,location}) {

    const [show_more,setShowMore] = useState(false);
    const {formatMessage: f} = useIntl();

    let not_shown = 0;

    items.forEach(sec=>{
        sec.items.forEach(it=>{
            if(it.show_more) {
                not_shown++;
            }
        })
    })

    return <div className="">
        {items.map((sec,o) => {
        return <MenuSection key={o}>
            <MenuItems compact={compact}>
            {sec.items.map((it,i) => {
                const is_active = pathIsActive(location.pathname,it);
                if(it.hidden||(!show_more&&it.show_more)) {
                    return null;
                }
                return <SingleItem key={it.key?it.key:i} theme={theme} onClick={()=>goTo(it.path)} {...it} is_active={is_active} label={f({id:`sidebar.${it.label}`})} />

            })}
            </MenuItems>
        </MenuSection>
    })}
    </div>
}