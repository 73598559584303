import React from 'react';

import {PlusIcon, LogoutIcon} from "@heroicons/react/solid";

import {CIMembership} from "../../../icons/custom/Membership";
import {CIUser} from "../../../icons/custom/User";
import {CIHome} from "../../../icons/custom/Home";
import {CIGroups} from "../../../icons/custom/Groups";
import {CILeaders} from "../../../icons/custom/Leaders";
import {CICommunityPreferences} from "../../../icons/custom/CommunityPreferences";
import {CICompass} from "../../../icons/custom/Compass";
import {CIDirectory} from "../../../icons/custom/Directory";
const global_logged_out = {
    value: 'main',
    items: [
/*
        {
            label: 'explore',
            type: 'explore',
            icon: <CIWorld />,
            value: 'explore'
        },


 */

        {
            label: 'sign_in',
            type: 'sign-in',
            icon: <LogoutIcon />,
            value: 'sign-in'
        }
    ]
};

const global = {
    value: 'main',
    items: [
        /*
        {
            label: 'explore',
            type: 'explore',
            icon: <CIWorld />,
            value: 'explore'
        },

         */

        {
            label: 'memberships',
            type: 'memberships',
            icon: <CIMembership/>,
            value: 'memberships'
        },
        {
            label: 'profile',
            type: 'profile',
            icon: <CIUser/>,
            value: ':handle'
        }
    ]
};

const primary = {
    value: 'secondary',
    title: 'Secondary',
    items: [
        {
            label: 'home',
            type: 'home',
            icon: <CIHome />,
            value: 'home'
        },
        {
            label: 'explore',
            type: 'explore',
            fuzzy_match: true,
            icon: <CICompass />,
            value: 'explore'
        },
        {
            label: 'directory',
            type: 'members',
            icon: <CIDirectory />,
            value: 'directory'
        },
        {
            label: 'leaders',
            type: 'leaders',
            icon: <CILeaders />,
            value: 'leaders',
            permission: 'view_teams'
        },
        {
            label: 'profile',
            type: 'profile',
            icon: <CIUser/>,
            value: 'member/:handle'
        },
        /*
        {
            label: 'sent',
            type: 'sent',
            icon: <MailOpenIcon />,
            value: 'sent',
            show_more: true
        },

         */

        /*
        {
            label: 'manage',
            type: 'manage',
            icon: <CICommunityPreferences />,
            value: 'admin',
            permission: 'view_teams'
        }

         */
    ]
};

export function getSidebarBase(context, isAuthenticated) {
    let arr = [];
    if (context === 'community') {

        arr.push(primary);

    } else {
        if (isAuthenticated) {
            arr.push(global);
        } else {
            arr.push(global_logged_out);
        }

    }
    return arr;
}

function getExt(item) {
    switch (item.type) {
        case 'list' : {
            return `l/${item.value}`;
        }
        case 'group' : {
            return `group/${item.value}`;
        }
        case 'member' : {
            return `member/${item.value}`;
        }
        case 'event' : {
            return `event/${item.value}`;
        }
        case 'role' : {
            return `role/${item.value}`;
        }
        case 'team' : {
            return `group/${item.value}`;
        }
        default : {
            return `${item.value}`;
        }
    }
}

function getPath(context, item, {community_handle, handle = ''}) {
    let str = '/';
    if (context === 'community') {
        str += `${community_handle}/`;
    }

    str += getExt(item);

    str = str.replace(':handle', handle);

    return str;
}

function getItem(type,id,mg,roles,teams,smart_views) {
    if(type==='group') {
        return mg[id] ? mg[id] : teams[id] ? teams[id] : {};
    } else if(type==='smart-view') {
        return smart_views[id] ? smart_views[id] : {};
    } else if(type==='role') {
        return roles[id] ? roles[id] : {};
    } else if(type==='team') {
        return teams[id] ? teams[id] : {};
    } else {
        return {};
    }
}

function buildStarred(member={},member_id,mg,roles,teams,smart_views={}) {
    const ms = member.starred ? member.starred : {};
    let s = Object.keys(ms);

const groups_ids = mg ? Object.keys(mg).filter(a=>!ms[`group__${a}`]) : [];

    let final = [];

    let starred = {
        title: 'Starred',
        add_path: '',
        items: []
    };

    const starred_views = Object.keys(ms).filter(a=>a.split('__')[0]==='smart-view');

    const starred_pure = Object.keys(ms).filter(a=>a.split('__')[0]!=='smart-view');

    if(starred_pure.length>0) {

        let items = [];
        for (let i = 0; i < starred_pure.length; i++) {
            const key = starred_pure[i];
            const split = key.split('__');
            const item = getItem(split[0],split[1],mg,roles,teams,smart_views);

            if(!item||!item.name) {
                continue;
            }
            items.push({
                type: split[0],
                key,
                name: item.name,
                meta: item,
                fuzzy_match: true,
                value: split[1]
            });
        }

        starred.items = [...items].sort((a,b)=>a.name>b.name?1:-1);

        final.push(starred);
    }

    let views = {
        title: 'Views',
        base_path: 'directory',
        items: []
    };

    if(starred_views.length>0) {

        let items = [];
        for (let i = 0; i < starred_views.length; i++) {
            const key = starred_views[i];
            const split = key.split('__');
            const item = getItem(split[0],split[1],mg,roles,teams,smart_views);

            if(!item||!item.name) {
                continue;
            }
            items.push({
                type: split[0],
                key,
                name: item.name,
                meta: item,
                fuzzy_match: true,
                value: split[1]
            });
        }

        views.items = [...items].sort((a,b)=>a.name>b.name?1:-1);

        final.push(views);
    }

    let groups = {
        title: 'Groups',
        add_path: 'explore/groups?action=create',
        base_path: 'explore/groups',
        items: []
    };

    if(groups_ids.length>0) {
        let gitems = [];

        groups_ids.forEach(id=>{
            if(mg[id]) {
                gitems.push({
                    type: 'group',
                    key: `group__${id}`,
                    name: mg[id].name,
                    meta: mg[id],
                    fuzzy_match: true,
                    value: id
                });
            }
        });

        groups.items = [...gitems].sort((a,b)=>a.name>b.name?1:-1);
        final.push(groups);
    }

    return final;
}

export function CICreateGroup() {

    return <div style={{height:'24px',width:'24px'}} className="p-px">
        <div style={{height:'22px',width:'22px'}} className="flex bg-gray-200 text-gray-700 rounded-md justify-center items-center" >
            <div className="h-3.5 w-3.5">
                <PlusIcon />
            </div>
        </div>

    </div>
}

function buildSecondarySidebar(context) {
    if(context!=='community') {
        return null;
    }
    return [
        {
            value: 'actions',
            title: '',
            items: [
                {
                    label: 'create_group',
                    type: 'create-group',
                    icon: <CICreateGroup />,
                    value: 'groups?action=create'
                },
                {
                    label: 'my_groups',
                    type: 'groups',
                    icon: <CIGroups />,
                    value: 'groups'
                },

            ]
        }
    ]
}

export function buildFinalSidebar(arr, context, {community_handle, is_member, starred_smart_views, member_id, all_teams, all_roles, my_groups, roles, groups, member, teams, handle}) {
    let to_return = {
        main: arr,
        secondary: null,
        entities: is_member?[...buildStarred(member,member_id,my_groups,all_roles,all_teams,starred_smart_views)]:[]
    };

    for (let i = 0; i < to_return.main.length; i++) {
        const section = to_return.main[i];

        for (let k = 0; k < section.items.length; k++) {
            const item = section.items[k];
            let add = true;

            /*
            if(item.permission) {
                if(!user_permissions[item.permission]) {
                    add = false;
                }
            }

             */

            if (add) {
                to_return.main[i].items[k].path = getPath(context, item, {community_handle, handle});
            } else {
                // to_return.main[i].items[k].hidden = true;
            }
        }
    }

    if(to_return.secondary) {
        for (let i = 0; i < to_return.secondary.length; i++) {
            const section = to_return.secondary[i];

            for (let k = 0; k < section.items.length; k++) {
                const item = section.items[k];
                let add = true;

                /*
                if(item.permission) {
                    if(!user_permissions[item.permission]) {
                        add = false;
                    }
                }

                 */

                if (add) {
                    to_return.secondary[i].items[k].path = getPath(context, item, {community_handle, handle});
                } else {
                    // to_return.main[i].items[k].hidden = true;
                }
            }
        }
    }

    for (let i = 0; i < to_return.entities.length; i++) {
        const section = to_return.entities[i];

        for (let k = 0; k < section.items.length; k++) {
            const item = section.items[k];
            let add = true;
            /*
                      if(item
                      if(item.permission) {
                          if(!user_permissions[item.permission]) {
                              add = false;
                          }
                      }
            */
            if (add) {
                to_return.entities[i].items[k].path = getPath(context, item, {community_handle, handle});
            } else {
                // to_return.entities[i].items[k].hidden = true;
            }
        }
    }

    return to_return;
}