import React, {useState} from 'react';
import styled from "styled-components";
import {ProfilePicture} from "../../profile-picture";
import {PhotoPortal} from "../../../../../app/community/home/PhotoPortal";
import ImageUploader from "../../image-uploader";
import {PhotoSelector} from "../../photo-selector";
import {InlineLoader} from "../../../core";
import {hexToRgbStr} from "../../../../utilities/general/colors";

const Frame = styled.div`
    display: inline-block;
    position: absolute;
    left: 16px;
    border: 5px solid #fff;
    border-radius: 42%;
    top: 90px;
        background: #fff;
    border-radius: 42%;
`;

const Loader = styled.div`
position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 42%;
    background-color: rgba(0,0,0,0.5);
    display: flex;
    align-items: center;
    justify-content: center;
`;

export function getLargeImage(pp) {
    if (pp !== '') {
        return [`${pp}?alt=media`];
    }
    return [];
}

const EditFrame = styled.div`
 z-index: 999;
 position: absolute;
    bottom: 0;
    display: flex;
       
        justify-content: center;
    width: 100%;
`;

const EditButton = styled.div`

        
    > div {
    margin-bottom: 10px;
       display: inline-block;
           padding: 5px 12px;
    border-radius: 8px;
    background: #000000a6;
    color: #fff;
    cursor: pointer;
    font-size: 13px;
    font-weight: 500;
    letter-spacing: 0.25px;
    :hover { 
        opacity: 0.7;
    }
    }
`;

// only open profile viewer if the picture is there
/*
if(profile_picture!==''&&!editing) {
            setOpen(true)
        }
 */
const hex = /^#(?:[0-9a-fA-F]{3}){1,2}$/;

export function getBkgColor(color) {
    if(!color) {
        return '#7f828e';
    } else if(color.includes('rgb(')) {
        return color;
    } else {
        return hexToRgbStr(color);
    }
}
export function PrProfilePicture({profile_picture = '', loading = false, profile_picture_color = 'rgb(0,0,0)', setProfilePicture = () => {
    }, editing, text = '', color = 'dodgerblue'
}) {
    const [open, setOpen] = useState(false);

    let pp = <ProfilePicture onClick={() => {
        if(profile_picture!==''&&!editing) {
            setOpen(true)
        }
    }} image={profile_picture} width={'144px'} height={'144px'} hide_initials size='medium' text={text} color={color}/>;

    return <Frame>
        {editing && <ImageUploader title='Profile Picture' type='profile-picture' handleUrl={setProfilePicture}>
            {pp}
            <EditFrame>
                <PhotoSelector type='profile-picture' setPhoto={setProfilePicture}>
                    <EditButton>
                        <div>Change</div>
                    </EditButton>
                </PhotoSelector>
            </EditFrame>
        </ImageUploader>}
        {!editing && pp}
        {loading && <Loader>
            <InlineLoader />
        </Loader>}
        {open && <PhotoPortal raw_color={profile_picture_color?profile_picture_color:"#7f828e"} bkg_color={getBkgColor(profile_picture_color)} images={getLargeImage(profile_picture)} onClose={() => {
            setOpen(false)
        }}/>}
    </Frame>
}