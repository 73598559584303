import React, {useState} from 'react';
import {ChevronDownIcon,EmojiHappyIcon} from '@heroicons/react/solid';
import styled from "styled-components";
import {Placement, Popover2} from "@blueprintjs/popover2";
import {EmojiPicker} from "../emoji-picker";

const Emoji = styled.div`
  font-size: 22px;
    height: 28px;
    line-height: 28px;
`;

const DownIcon = styled.div`
       display: flex;
    align-items: center;
        margin-left: 2px;
   svg {
    height: 18px;
   }
`;

const Selector = styled.div`
display: flex;
border-right: 1px solid #e0e1e4;
    padding: 6px 6px 6px 8px;
    cursor: pointer;
    border-bottom-left-radius: 6px;
    border-top-left-radius: 6px;
    :hover {
        background-color: #EEEFF2;
    }
`;

const EmojiBlank = styled.div`
       display: flex;
    align-items: center;
    height: 28px;
    width: 22px;
    svg {
        height: 20px;
        width: 20px;
    }
`;

export const EmojiPopupSelector = ({emoji,trigger,layout,usePortal=true,changeEmoji}) => {
    const [open, setOpen] = useState(false);
    const content = trigger ? trigger : <Selector>
        <Emoji>
            {emoji.length<10?emoji:<img src={emoji} className="h-4 w-4" />}
            {emoji===''&&<EmojiBlank>
                <EmojiHappyIcon className='text-gray-700' />
            </EmojiBlank>}
        </Emoji>
        <DownIcon className='text-gray-700'>
            <ChevronDownIcon/>
        </DownIcon>
    </Selector>;
    return <Popover2 content={<EmojiPicker changeEmoji={(emoji)=>{setOpen(false);changeEmoji(emoji);}} />} isOpen={open} usePortal={usePortal} onInteraction={(bool)=>{setOpen(bool)}} lazy popoverClassName={'popup-box-shadow z-999 margin-top-8'} minimal placement={'bottom-start'}>
        {content}
        </Popover2>;
};