import React, {useState} from 'react';
import styled from "styled-components";
import {StarIcon} from "@heroicons/react/solid";

const light_bkg = 'linear-gradient(0deg, rgba(105,157,255,1) 0%, rgba(136,177,255,1) 14%, rgba(136,177,255,1) 86%, rgba(105,157,255,1) 100%)';
const dark_bkg = 'linear-gradient(0deg, rgba(4,5,7,1) 0%, rgba(20,24,38,1) 14%, rgba(20,24,38,1) 86%, rgba(4,5,7,1) 100%)';

const MoonIconBox = styled.div`
    color: #fff;
   background: #141926;
    height: 20px;
    width: 20px;
    position: absolute;
    margin: 0;
    right: 13px;
    top: 4px;
    border-radius: 50%;
    z-index: 2;
    display: inline-flex;
    align-items: center;
`;

const Shell = styled.div`
  position: relative;
  height: 34px;
  outline: none;
  :focus {
  box-shadow: rgba(0,0,0,0) 0px 0px 0px 0px, rgba(58,151,212,0.36) 0px 0px 0px 3px, rgba(0,0,0,0) 0px 0px 0px 0px, rgba(60,66,87,0.16) 0px 0px 0px 1px, rgba(0,0,0,0) 0px 0px 0px 0px, rgba(0,0,0,0) 0px 0px 0px 0px, rgba(0,0,0,0) 0px 0px 0px 0px;
  
  }
  transition: background 0.25s ease, border-color 0.25s ease, opacity 0.1s ease;
  cursor: pointer;
    width: 60px;
background: ${props => props.active ? dark_bkg : light_bkg};;
    border-radius: 16px;
    border: 1px solid;
    border-color: ${props => props.active ? 'rgba(4,5,7,1)' : 'rgba(105,157,255,1)'};
    :hover {
        opacity: 0.85;
    }
`;

const SwitchBox = styled.div`
display: flex;
    align-items: center;
`;

const WhiteBkg = styled.div`
display: flex;
position: absolute;
background-color: #fff;
border-radius: 50%;
opacity: 0.85;
    align-items: center;
`;

const WhiteSvg = styled.div`
position: absolute;
height: 9px;
    > svg {
        fill: #fff;
        opacity: 0.85;
        height: 9px;
       
    }
`;

const Circle = styled.div`
position: absolute;
    background: ${props => props.active ? '#ffffff' : '#ffffff'};
    height: 24px;
     transition: left 2s ease;
    width: 24px;
        top: 4px;
    left: ${props => props.active ? 'auto' : '5px'};
    right: ${props => !props.active ? 'auto' : '5px'};
    border-radius: 50%;
`;

function DayDeco() {
    return <>
        <WhiteBkg style={{height: '8px', width: '8px', top: '4px', left: '40px'}} />
        <WhiteBkg style={{height: '4px', width: '4px', top: '13px', left: '34px'}} />
    </>
}

function DarkDeco() {
    return <>
        <WhiteSvg style={{top: '1px', left: '10px'}}>
            <StarIcon/>
        </WhiteSvg>
        <WhiteBkg style={{height: '4px', width: '4px', top: '8px', left: '27px', zIndex: '9'}} />
        <WhiteBkg style={{height: '4px', width: '4px', top: '21px', left: '8px'}} />
        <WhiteSvg style={{bottom: '10px', left: '19px'}}>
            <StarIcon/>
        </WhiteSvg>
    </>
}
//
function SwitchItem({on, toggle}) {
    return <Shell id={`dark-mode-toggle`} active={on} onClick={() => {
        toggle('dark-mode-toggle')
    }}>
        {on && <MoonIconBox />}
        {on&&<DarkDeco/>}
        {!on&&<DayDeco/>}
        <Circle active={on}/>
    </Shell>
}

const texts = {
    on: {
        title: 'Make Private',
        desc: `<span><b>This cannot be undone.</b> Your group...</span>`
    },
    off: {
        title: 'Make Private',
        desc: `<span>Blah blah</span>`
    }
};

export function PLAYDarkModeSwitcher() {
    const [on, toggleOn] = useState(false);

    function toggleSwitch(id) {
        if (id) {
            document.getElementById(id).tabIndex = 1;
            document.getElementById(id).focus();
        }
        toggleOn(!on);
    }

    return <div>

        <SwitchBox>
            <SwitchItem toggle={(id) => toggleSwitch(id)} on={on}/>
        </SwitchBox>
    </div>
}