import React from "react";
import styled from "styled-components";
import CustomCheckbox from "../../old/checkbox";
import {months} from "../../../data/general/months";
import Select from "../../old/select";
import {years_array} from "../../../data/general/years_since_1900";

const types = [
    'year',
    'month-year'
];

const mock_props = {
    enable_current_check: true,
    current_check_text: `I'm currently working in this role`,
    type: 'year',
    labels: {start:'Start date',end:'End Date'},
    onChange: () => {}
};

// output
// null if not set
const month_year_output = {
    is_valid: false,
    from_year: 1995,
    from_month: 11,
    to_year: null,
    to_month: null,
    current_role: true
};

const year_output = {
    is_valid: false,
    from_year: 1995,
    to_year: null,
    current: true
};

function isValid(type) {

}

const Wrapper = styled.div`
    margin-top: 16px;
  width: 500px;
`;

const Grid = styled.div`
      grid-gap: 12px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding-bottom: 6px;
`;


const Label = styled.div`
line-height: 20px;
    font-size: 14px;
    font-weight: 500;
    color: #3c4257;
`;

const DateGrid = styled.div`
grid-gap: 8px;
    display: grid;
    grid-template-columns: ${props => props.two ? '1fr 1fr' : '1fr'};
`;

const PresentText = styled.div`
font-weight: 600;
font-size: 14px;
line-height: 32px;
`;

const Options = styled.div`
    padding:20px;
    margin-bottom: 12px;
    background: #dadada;
`;

const initial_state = {
    current: true,
    from_year: null,
    from_month: null,
    to_year: null,
    to_month: null
};

function toggleCurrent(range, cb) {
    let new_current = !range.current;
    if (new_current) {
        // also reset end dates
        cb({...range, to_year: null, to_month: null, current: new_current})
    } else {
        cb({...range, current: new_current})
    }
}

function updateDate(range, cb, field, value) {
    let new_range = range;
    new_range[field] = value;

    if (new_range.from_year !== null && new_range.to_year !== null) {

        if (new_range.to_year < new_range.from_year) {
            alert('Whoa! That would go back in time, is that quite right?');
            return;
        }
    }

    cb({...range, [field]: value});
}

function getDuration(from_year, from_month, to_year, to_month) {
    // return in months
    if (from_month !== null) {
        // todo
    } else {
        // years only
        return (to_year - from_year) * 12;
    }
}

export function DateRange(props = mock_props) {
    const {type,labels,onChange, range } = props;

    const {current, from_year, from_month, to_month, to_year} = range;

    function updateRange(new_range) {
        // onChange
        onChange(new_range);
    }

    return <Wrapper>
        {props.enable_current_check&&<div className="pb-2">
            <CustomCheckbox content={props.current_check_text} checked={current} toggleCheck={() => {
                toggleCurrent(range, updateRange)
            }}/>
        </div>}
        <Grid>
            <Label className='text-gray-700'>{labels.start}</Label>
            <Label className='text-gray-700'>{labels.end}</Label>
        </Grid>
        <Grid>
            <DateGrid two={type === 'month-year'}>
                {type === 'month-year' &&
                <Select blank_option={false} value={from_month === null ? '' : from_month} placeholder='Month'
                        field='month' onChange={(field, month) => {
                    updateDate(range, updateRange, 'from_month', parseInt(month));
                }} options={months}/>}
                <Select blank_option={false} value={from_year === null ? '' : from_year} placeholder='Year' field='month'
                        onChange={(field, year) => {
                            updateDate(range, updateRange, 'from_year', parseInt(year));
                        }} options={years_array}/>
            </DateGrid>
            <DateGrid two={type === 'month-year'}>
                {current && props.enable_current_check && <PresentText className='text-gray-800'>Present</PresentText>}
                { !current && type === 'month-year' &&
                <Select blank_option={false} value={to_month === null ? '' : to_month} placeholder='Month' field='month'
                        onChange={(field, month) => {
                            updateDate(range, updateRange, 'to_month', parseInt(month));
                        }} options={months}/>}
                { (!props.enable_current_check || (!current)) &&
                <Select blank_option={false} value={to_year === null ? '' : to_year} placeholder='Year' field='month'
                        onChange={(field, year) => {
                            updateDate(range, updateRange, 'to_year', parseInt(year));
                        }} options={years_array}/>}
            </DateGrid>
        </Grid>
    </Wrapper>
}