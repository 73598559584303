import React, {useContext} from "react";
import {PageContainer} from "../../../../library/components/old/page-container";

import AllEntitiesNotifications from "./all-entities-notifications";
import {CommunityContext} from "../../community-context";
import SecondaryEmails from "../../../global/preferences/notifications/secondary-emails";
import {GlobalContext} from "../../../global/global-context";
import {PageHeader} from "../../../../library/components/core";
import {useIntl} from "react-intl";

export default function MemberPrefsNotifications(props) {
    const global = useContext(GlobalContext);
    const {formatMessage: f} = useIntl();
    const community = useContext(CommunityContext);

    return <div>
        <AllEntitiesNotifications/>

        <SecondaryEmails community_uid={community.uid} user={global.user} />
    </div>

}