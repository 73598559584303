import React from "react";
import styled from "styled-components";

const Frame = styled.div`
    display: grid;
    grid-gap: 24px;
`;

// user profile

function getColumns(layout) {
    switch (layout) {
        case 'member': {
            return '600px 1fr'
        }
        case 'group': {
            return '600px 1fr'
        }
        default : {
            return '1fr 360px'
        }
    }
}

export const SingleBody = ({layout,children}) => {
    return <Frame style={{gridTemplateColumns: getColumns(layout)}}>
        {children}
    </Frame>
};