import React, {Component, useState} from "react";

import styled from "styled-components";
import {authFetch} from "../../config/network";
import {Button, Field} from "../../library/components/core";
import {DeleteUser} from "./delete-user";
import {GetSetPasswordLink} from "./set-password-link";

const Container = styled.div`
  max-width: 1600px;
  padding: 32px 0;
`;

const Box = styled.div`
  border: 1px solid #efefef;
  padding: 8px;
`;

export default class GodMigrations extends Component {
    constructor(props) {
        super(props);

        this.state = {
            user_uid_so: '',
            user_uid: '',
            type: '',
            loading: false
        };
    }

    handleChange(f, v) {
        this.setState({
            [f]: v
        })
    }

    changeDomain() {
        this.setState({
            loading: true
        });

        const res = () => {
            this.setState({
                type: '',
                loading: false
            });
        };

        const payload = this.state;

        authFetch("/superadmin/migrations", res, res, "POST", {payload});
    }

    createToken() {
        this.setState({
            loading: true
        });

        const res = (resp) => {
            console.log("RESP", resp)
            this.setState({
                user_uid: '',
                loading: false
            });
        };

        const payload = {
            user_uid: this.state.user_uid
        };

        authFetch("/superadmin/create-impersonation-token", res, res, "POST", {payload});
    }

    signOut() {
        this.setState({
            loading: true
        });

        const res = (resp) => {
            console.log("RESP", resp)
            this.setState({
                user_uid_so: '',
                loading: false
            });
        };

        const payload = {
            user_uid: this.state.user_uid_so
        };

        authFetch("/superadmin/sign-user-out-of-everywhere", res, res, "POST", {payload});
    }

    realTest() {
        this.setState({
            loading: true
        });

        const res = (resp) => {
            console.log("RESP", resp)
            this.setState({
                type: '',
                loading: false
            });
        };

        const payload = {
            community_uid: "J62oUDAvKbZFS91QiaTj",
            member_id: "9V5ZVEOXEX"
        };

        authFetch("/test", res, res, "POST", {payload});
    }

    render() {

        let {type, loading, user_uid, user_uid_so} = this.state;

        return (<Container>
                <div className="space-y-4">

                    <Box>
                        <input placeholder="migration_id" value={type} onChange={(e) => {
                            this.handleChange('type', e.target.value);
                        }}/>

                        <Button loading={loading} text={'Execute Migration'} onClick={this.changeDomain.bind(this)}/>
                        <div>
                            Valid: system_connections, groups_mods
                        </div>
                    </Box>

                    <Box>
                        <Button loading={loading} text={'Run Test'} onClick={this.realTest.bind(this)}/>
                    </Box>

                    <Box>
                        <Field label="Impersonation Token">

                            <input placeholder="user_uid" value={user_uid} onChange={(e) => {
                                this.handleChange('user_uid', e.target.value);
                            }}/>
                        </Field>

                        <Button loading={loading} text={'Create token'} onClick={this.createToken.bind(this)}/>
                    </Box>


                    <Box>
                        <Field label="Sign user out of everywhere">

                            <input placeholder="user_uid" value={user_uid_so} onChange={(e) => {
                                this.handleChange('user_uid_so', e.target.value);
                            }}/>
                        </Field>

                        <Button loading={loading} text={'Sign out'} onClick={this.signOut.bind(this)}/>
                    </Box>

                    <Box>
                        <Field label="Generate Set PW Link">
                            <GetSetPasswordLink/>
                        </Field>
                    </Box>
                    <Box>
                        <Field label="Delete User">
                            <DeleteUser/>
                        </Field>
                    </Box>
                </div>
            </Container>
        );
    }
}