import React from 'react';
import {SubtitleMetaLink} from "../../../library/components/old/subtitle-meta-link";
import {aa_formatDate} from "../../../library/utilities/general/dates";

function getText(c) {
    return c===1 ? `${c} member` : `${c} members`;
}

function buildCardMeta(data) {
    let meta = [];

    meta.push({
        type: data.closed?"closed-group":"open-group",
        value: data.closed?"Closed Group":"Open Group"
    });

    const count = Object.keys(data.user_uids).length;

    meta.push({
        type: "members-count",
        value: `${count} Member${count!==1?"s":""}`
    });

    if (data.created_at) {
        meta.push({
            type: 'created',
            value: `${aa_formatDate(data.created_at).relative}`
        });
    }

    return meta;
}

export function buildGroupCard(data,features) {
    const len = Object.keys(data.user_uids).length;
    return {
        type: 'group',

        appearance: {
            show_color: true,

            cover_photo: data.cover_photo,
            cover_photo_color: data.cover_photo_color,

            emoji: data.emoji, // group only
            event_date: null,

            block_initial: data.name.charAt(0).toUpperCase(),
            block_color: data.color?data.color:"",

            profile_picture: data.profile_picture?data.profile_picture:"",
            profile_picture_color: data.profile_picture_color?data.profile_picture_color:""
        },

        main: {
            title: data.name,
            status: null,
            subtitle: [
                <SubtitleMetaLink key='text' onClick={()=>{}} text={`@${data.handle}`}/>
            ],
            description: data.purpose,
            meta: buildCardMeta(data),
        },

        actions: [

        ],

        rich_meta: {
            primary: null,
            secondary: null,
            tertiary: null
        },

        stats: [

        ]
    }
}