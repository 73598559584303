import React from 'react';
import {NFIRepliesPreview} from "./replies-preview";
import {NFIOuter, NFIInner} from "./shared";

export function NFIActions({actions}) {

    return null;

    return <NFIOuter className="pt-1.5">
        <NFIInner>
            <div className="grid gap-2" style={{gridTemplateColumns: '1fr auto'}}>
                <div>
                    <NFIRepliesPreview/>
                </div>
                <div>
                    <div>

                    </div>
                </div>
            </div>
        </NFIInner>
    </NFIOuter>
}