import React, {useState} from "react";
import {FixedSizeList as List} from "react-window";
import {LIEBodyRow} from "./body-row";
import {getEntryUpdateID, lie_buildRows} from "../utilities/build-row-data";
import {Toggle} from "../../core";

export function LIEBody({data, block_data, onAddSelectValue=()=>{}, mapping_data, onChangeMapping, onChangeData}) {
    const [open, setOpen] = useState({});
    const [errors_only, setErrorsOnly] = useState(false);
    if (!mapping_data[block_data.id]) {
        return <div className="bg-gray-100" style={{height: '320px'}}>
            <div className="p-10 text-center text-sm text-gray-600">
                Select a matching data field to see a preview
            </div>
        </div>
    }
    const rows_data = lie_buildRows(data, block_data, mapping_data, open);
    return <div className="" style={{height:'320px'}}>
        <List height={288}
                 itemCount={rows_data.rows.length}
                 className="flex-grow even:bg-gray-100 rounded divide-y divide-gray-200 h-72 overflow-y-auto"
                 itemSize={34}
                 width={'100%'}>
        {({index, style}) => {
            const item = rows_data.rows[index];
            // row_id, col_id, new_value
            return <div key={index} style={style}>
                <LIEBodyRow
                style={style}
                mapping_data={mapping_data[block_data.id]}
                onAddSelectValue={onAddSelectValue}
                block_data={block_data}
                handleSelect={(sid)=>{
                    onChangeMapping(block_data.id,item.label,sid)
                }}
                onOpen={(is_open) => {
                    let no = {...open};
                    no[`${getEntryUpdateID(block_data)}-${item.id}`] = is_open;
                    setOpen(no);
                }} errors_only={errors_only} handleDataChange={(v) => onChangeData(item.number, getEntryUpdateID(block_data), v)} striped={(index + 1) % 2 === 0}
                key={index} {...item} />
            </div>;
        }}
    </List>
        <div className="flex items-center space-x-2" style={{height:'32px'}}>
            <div className='flex-grow pl-3'>
                <Toggle size="xs" color="amber-500" checked={errors_only} label="Show warning rows" onToggle={(v)=>setErrorsOnly(!errors_only)} layout="switch" />
            </div>
            <div className="pr-3">

            </div>
        </div>
    </div>;
}