import React, {useContext, useState} from "react";
import {ProfileBadge} from "../../../../../../library/components/profile-badge";
import {RulesInlineSelect} from "../../../../../../library/components/rules/rules-inline-select";
import {languages} from "../../../../../../library/data/general/languages";
import {authFetch} from "../../../../../../config/network";
import {GlobalContext} from "../../../../../global/global-context";

export function LanguageBadge({community,changeLanguage,member,member_id}) {
    const [language, setLanguage] = useState(member.preferences.language);
    const global = useContext(GlobalContext);
    function handleChangeLanguage(nl) {
        if (nl === language) {
            return;
        }

        setLanguage(nl);
        changeLanguage(nl);
        const res = () => {
            global.addToast({text:"Changes saved",intent:'success'});
        };
        const payload = {
            community_uid: community.uid,
            member_id: community.member_id,
            id: member_id,
            _updated_fields: ["preferences.language"],
            preferences: {
                language: nl
            }
        };
        authFetch("/members/update-profile", res, res, "POST", {payload});
    }

    if (!member_id) {
        return null;
    }

    const options = languages.map(l => {
        return {
            id: l.value,
            label: l.text
        }
    });
    return <RulesInlineSelect onSelect={handleChangeLanguage} layout="meta" size="sm" inline searchable={false}
                              options={options} selected={language}/>
}