import React from 'react';
import {Card, Badge, Button} from '../../core';

export default function SGCardsPage() {

    return <div className="space-y-4">
        <div>

            <Card header={{title: 'Simple Title'}}/>
        </div>
        <div>
            <Card header={{title: 'Title with description', subtitle: 'Hello this is a subtitle'}}/>
        </div>
        <div>
            <Card fill header={{
                title: 'With Actions',
                subtitle: 'Hello this is a subtitle',
                actions: <Button text="Publish" intent="secondary"/>
            }}/>
        </div>
        <div>

            <Card fill header={{title: 'Full Width'}}/>
        </div>
            <Card interactive selected>
                <div>
                    <div className="color-gray-800 text-base font-semibold flex items-center">
                <span className="mr-2">
                ACME Privacy Policy
                </span>
                        <Badge text="v2" color="green"/>
                    </div>
                    <div>
                        <div>Last updated July 28, 2020</div>
                        <div>72% Adoption</div>
                    </div>
                </div>
            </Card>
            <Card interactive>
                <div>
                    <div className="color-gray-800 text-base font-semibold flex items-center">
                <span className="mr-2">
                ACME Privacy Policy
                </span>
                        <Badge text="v2" color="green"/>
                    </div>
                    <div>
                        <div>Last updated July 28, 2020</div>
                        <div>72% Adoption</div>
                    </div>
                </div>
            </Card>
    </div>
}