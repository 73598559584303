import React, {useEffect} from "react";
import styled from "styled-components";
import {XIcon} from '@heroicons/react/solid';
import {SimpleOverlay} from "../../simple-overlay";
import {useIsMobile} from "../../../../app/global/global-router";

const ModalContainer = styled.div`
    height: 100vh;
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
        overflow-y: auto;
`;

function getSize(size,is_mobile) {
    if(is_mobile) {
        return '100%';
    }
    if(size.includes('px')) {
        return size;
    }
    switch (size) {
        case 'large' : {
            return '960px'
        }
        case 'plus' : {
            return '700px'
        }
        case 'medium' : {
            return '620px'
        }
        case 'base' : {
            return '500px'
        }
        default : {
            return '440px'
        }
    }
}

const ModalInner = styled.div`
  display: flex;
  flex-flow: column;
    background-color: #fff;
    border-radius: ${props=>props.is_mobile?"":"0.75rem"};
    position: absolute;
    top: ${props=>props.is_mobile?"":"6vh"};
    height: ${props=>props.is_mobile?"100vh":""};
    margin-bottom: ${props=>props.is_mobile?"":"10vh"};
    min-width: ${props=>getSize(props.size,props.is_mobile)};
    max-width: ${props=>getSize(props.size,props.is_mobile)};
`;

const ModalClose = styled.div`
height: 28px;
    width: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 50%;
        color: #8b98a2;
    svg {
        height: 18px;
    }
    :hover {
    background-color: #efefef;
    }
`;

const ModalHeader = styled.div`
    display: grid;
    margin-bottom: ${props=>!props.dividing_margin?"":"16px"};
    grid-template-columns: 1fr auto;
  padding: 1rem 1rem ${props=>props.padded_header?"1rem":"0.5rem"} 1rem;
`;

const ModalBody = styled.div`
  border-radius: ${props=>props.rounded_bottom?"1rem":""};
 margin-bottom: ${props=>props.bottom_margin?"16px":""};
  padding: ${props=>!props.has_padding?"":"1rem"};
  background: #ffffff;
`;

const ModalFooter = styled.div`
display: grid;
    grid-template-columns: 1fr auto;
  padding:  0.5rem 1rem 1rem 1rem;
`;

const FooterLeft = styled.div`

`;

const FooterRight = styled.div`

`;

export default function Modal(props) {
    const {children, rounded_bottom, bottom_margin=false, has_padding=true, topper, capture_dismiss=true, enforceFocus=false,autoFocus=true, padded_header=false, dividing_margin=true, size='medium', usePortal=true, title = 'Modal Title', subtitle, open = false, body = null, footerLeft = null, canEscapeKeyClose = true, canOutsideClickClose=true, footerRight = null, onClose} = props;
    if (!open) {
        return null;
    }

    const is_mobile = useIsMobile();
    return (
        <SimpleOverlay captureDismiss={capture_dismiss} enforceFocus={enforceFocus} autoFocus={autoFocus} portalClassName={`z-9999 ${is_mobile?"mobile-modal":""}`} lazy isOpen={open} usePortal={usePortal} onClose={(e)=>{
        if(e) {
            e.stopPropagation();
        }
            onClose()
        }} canEscapeKeyClose={canEscapeKeyClose} canOutsideClickClose={canOutsideClickClose}
                 hasBackdrop={true}>
            <ModalContainer id="modal-overlay" className="bp4-overlay-content" onClick={(e)=>{
            if(e) {
                e.stopPropagation();
            }
            if(canOutsideClickClose) {
                onClose(e);
            }
            }}>
                <ModalInner onClick={e=>e.stopPropagation()} is_mobile={is_mobile} size={size}>
                    <ModalHeader className="flex-none" padded_header={padded_header} dividing_margin={false} is_mobile={is_mobile}>
                        <div>
                            {topper&&<div>
                                {topper}
                            </div>}
                        <div className="text-gray-800 font-bold text-2xl">
                            {title}
                        </div>
                            {subtitle&&<div className="text-gray-500 text-sm pt-0.5 pb-1">
                                {subtitle}
                            </div>}
                        </div>
                        <ModalClose onClick={onClose}>
                            <XIcon/>
                        </ModalClose>
                    </ModalHeader>
                    <ModalBody rounded_bottom={rounded_bottom} bottom_margin={bottom_margin} is_mobile={is_mobile} has_padding={has_padding}>
                        {body}
                    </ModalBody>
                    {(footerLeft||footerRight)&&<ModalFooter is_mobile={is_mobile}>
                        <FooterLeft>
                            {footerLeft}
                        </FooterLeft>
                        <FooterRight>
                            {footerRight}
                        </FooterRight>
                    </ModalFooter>}
                </ModalInner>
            </ModalContainer>
        </SimpleOverlay>
    );
}
