export const seasons = [
    {
        value: null,
        label: 'Season'
    },
    {
        value: 'spring',
        label: 'Spring'
    },
    {
        value: 'summer',
        label: 'Summer'
    },
    {
        value: 'fall',
        label: 'Fall'
    },
    {
        value: 'winter',
        label: 'Winter'
    }
];

function getObject() {
    let obj = {};
    for(let i=0;i<seasons.length;i++) {
        const item = seasons[i];
        obj[item.value] = item.label;
    }
    return obj;
}

export const seasonsObj = getObject();