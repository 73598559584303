import React, {useState, useEffect, useRef} from 'react';
import classNames from 'classnames';
import styled from "styled-components";
import {SimpleListbox} from "./simple-listbox";
import {ChevronDownIcon} from "@heroicons/react/solid";
import {getPhoneNumberData} from "../../utilities/phone";
import {ColorPicker} from "./color-picker";
import {Popover2} from "@blueprintjs/popover2";

const IconBox = styled.div`
  > svg {
    height: 20px;
  }
`;
const DropdownBox = styled.div`
  > div > div > div[class$="control"] {
    border-top-right-radius: 0 !important;
    background: #fafafa !important;
    border: 1px solid #d4d4d8 !important;
    border-bottom-right-radius: 0 !important;
  }
`;

const ButtonBox = styled.div`

  > div, > button {
    height: 100% !important;
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
    box-shadow: none !important;
    border: 1px solid !important;
    border-color: inherit !important;
    padding: 0 16px !important;
  }
`;

function sanitize(v) {
    return v ? v : '';
}

// https://codepen.io/559wade/pen/LRzEjj currency, type=text, data-type=currency pattern="^\$\d{1,3}(,\d{3})*(\.\d+)?$"

function getPaddingLeft(type, loading, input_type, compact) {
    if (type === 'phone-country-code') {
        return 'pl-phone-number';
    } else if (type === 'phone-country-code-select') {
        return 'pl-phone-number-select'
    } else if (input_type === 'color') {
        return 'pl-10';
    } else if (loading) {
        return 'pl-10'
    } else if (type === 'text') {
        return 'pl-7';
    } else if (type === 'dropdown') {
        return compact ? 'pl-2' : 'pl-3';
    } else if (type === 'emoji') {
        return compact ? 'pl-2' : 'pl-3';
    } else if (type === 'group-icon') {
        return compact ? 'pl-2' : 'pl-3';
    } else if (type === 'icon') {
        return 'pl-10';
    } else if (type === 'add-on') {
        return compact ? 'pl-2' : 'pl-3';
    } else if (type === 'inline-add-on') {
        return 'pl-1';
    } else {
        return 'pl-10';
    }
}

function getPaddingRight(type, loading, input_type, compact) {
    if (type === 'phone-country-code') {
        return 'pr-24';
    } else if (type === 'phone-country-code-select') {
        return 'pr-24'
    } else if (input_type === 'color') {
        return 'pr-10';
    } else if (loading) {
        return 'pr-10'
    } else if (type === 'text') {
        return 'pr-7';
    } else if (type === 'dropdown') {
        return compact ? 'pr-2' : 'pr-3';
    } else if (type === 'emoji') {
        return compact ? 'pr-2' : 'pr-3';
    } else if (type === 'group-icon') {
        return compact ? 'pr-2' : 'pr-3';
    } else if (type === 'icon') {
        return 'pr-10';
    } else if (type === 'add-on') {
        return compact ? 'pr-2' : 'pr-3';
    } else if (type === 'inline-add-on') {
        return 'pr-1';
    } else {
        return 'pr-10';
    }
}

function intentColor(intent) {
    if (intent === 'success') {
        return 'text-emerald-800';
    } else if (intent === 'warning') {
        return 'text-yellow-800';
    } else if (intent === 'danger') {
        return 'text-red-800';
    }
    return 'text-gray-500';
}


function getLeadingComponent(leading, onChange, intent, input_type, value) {

    const type = leading.type;
    if (type === 'text') {
        return <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
            <span className={`${intentColor(intent)} sm:text-sm`}>
            {leading.value}
            </span>
        </div>
    } else if (type === 'color') {
        return <Popover2 usePortal={false} portalClassName="relative" canEscapeKeyClose enforceFocus={false}
                         interactionKind="click" fill
                         content={<ColorPicker color={value} updateColor={(v) => onChange(v)}/>}
                         placement={'bottom-start'} minimal
                         popoverClassName='popup-box-shadow popover-standard my-1 -mx-1 z-9999'>
            <div onClick={(e) => {
                //e.stopPropagation();
            }} className="absolute inset-y-0 cursor-pointer left-0 pl-3 flex items-center">
                <div
                    className="relative cursor-pointer hover:opacity-80 transition-opacity z-10 w-6 -m-1 bg-white h-6 rounded-md border-gray-200 border border-solid"
                    style={{backgroundColor: value}}/>
            </div>
        </Popover2>
    } else if (type === 'dropdown') {
        // leading={{type: 'dropdown', value: {options: [{label: '', value: ''}, {label: '', value: ''}]}}}
        let lp = leading.props ? leading.props : {};
        return <DropdownBox className="w-2/5 relative inset-y-0 flex items-center">
            <SimpleListbox usePortal onChange={leading.onChange} placeholder={leading.placeholder}
                           value={leading.value} {...lp}
                           options={leading.options}/>
        </DropdownBox>
    } else if (type === 'group-icon') {
        return <div style={{width: '3.5rem'}}
                    className="space-x-1 relative border rounded-l-md bg-white border-r px-2 border-gray-300 inset-y-0 flex items-center">
            {leading.value}
        </div>
    } else if (type === 'emoji') {
        return <div
            className="relative border rounded-l-md bg-white border-r-0 border-gray-300 inset-y-0 flex items-center">
            {leading.value}
        </div>
    } else if (type === 'add-on') {
        return <div
            className="relative border rounded-l-md border-r-0 border-gray-300 pr-3 bg-gray-50 inset-y-0 pl-3 flex items-center pointer-events-none">
              <span className={`${intentColor(intent)} sm:text-sm`}>
            {leading.value}
            </span>
        </div>
    } else if (type === 'inline-add-on') {
        return <div
            className="relative border rounded-l-md bg-white border-r-0 border-gray-300 pr-1 inset-y-0 pl-3 flex items-center pointer-events-none">
           <span className={`${intentColor(intent)} sm:text-sm`}>
            {leading.value}
            </span>
        </div>
    } else if (type === 'icon') {
        return <IconBox
            className={`absolute inset-y-0 ${intentColor(intent)} left-0 pl-3 flex items-center pointer-events-none`}>
            {leading.value}
        </IconBox>
    }
}

function getTrailingComponent(trailing, onChange, intent) {
    const type = trailing.type;
    if (type === 'text') {
        return <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
             <span className={`${intentColor(intent)} sm:text-sm`}>
            {trailing.value}
            </span>
        </div>
    } else if (type === 'icon') {
        return <IconBox onClick={(e)=>{
            if(trailing.onClick) {
                trailing.onClick();
            }
        }}
            className={`absolute inset-y-0 ${intentColor(intent)} right-0 pr-3 flex items-center ${trailing.onClick?"cursor-pointer":"pointer-events-none"} `}>
            {trailing.value}
        </IconBox>
    } else if (type === 'button') {
        return <ButtonBox className={`${intentColor(intent)} right-0 flex items-center`}>
            {trailing.value}
        </ButtonBox>
    } else if (type === 'comp') {
        return <div className={`top-0 bottom-0 right-3 absolute flex items-center`}>
            {trailing.value}
        </div>
    }
}

function getContainerClassName(leading, trailing, is_color) {
    if (leading && leading.type === 'add-on') {
        return 'relative flex max-w-lg w-full';
    } else if (is_color) {
        return 'relative flex max-w-lg w-full';
    } else if (leading && leading.type === 'inline-add-on') {
        return 'relative flex max-w-lg w-full';
    } else if (leading && leading.type === 'dropdown') {
        return 'relative flex max-w-lg w-full';
    } else if (leading && leading.type === 'emoji') {
        return 'relative flex max-w-lg w-full';
    } else if (leading && leading.type === 'group-icon') {
        return 'relative flex max-w-lg w-full';
    } else if (trailing && trailing.type === 'button') {
        return 'relative flex max-w-lg w-full';
    }
    return 'relative max-w-lg w-full';
}

function inputClasses(leading, trailing, loading) {
    if (leading && leading.type === 'add-on') {
        return 'rounded-tl-none rounded-bl-none z-10';
    } else if (leading && leading.type === 'inline-add-on') {
        return 'rounded-tl-none border-l-0 rounded-bl-none pl-0 focus:z-10';
    } else if (leading && leading.type === 'dropdown') {
        return 'rounded-tl-none border-l-0 rounded-bl-none pl-0 focus:z-10';
    } else if (leading && leading.type === 'emoji') {
        return 'rounded-tl-none border-l-0 rounded-bl-none pl-0 focus:z-10';
    } else if (leading && leading.type === 'group-icon') {
        return 'rounded-tl-none border-l-0 rounded-bl-none pl-0 focus:z-10';
    } else if (loading) {
    } else if (trailing && trailing.type === 'button') {
        return 'rounded-r-none border-r-0';
    }
    return '';
}

const allowed_types = [
    'date', 'datetime', 'number', 'text', 'url', 'email', 'password'
];

function getType(type) {
    if (allowed_types.indexOf(type) !== -1) {
        return type;
    }
    return 'text';
}

function borderClasses(intent, disabled) {
    if (intent === 'success') {
        return 'border-emerald-600 placeholder-gray-800 text-gray-800';
    } else if (intent === 'warning') {
        return 'border-yellow-500 placeholder-gray-800 text-gray-800';
    } else if (intent === 'danger') {
        return 'border-red-500 placeholder-gray-800 text-gray-800';
    }
    return `border-gray-300 placeholder-gray-500 ${disabled ? "text-gray-500" : "text-gray-800"}`;
}

function getDisplayValue(formatting, value, val, focused, meta) {

    if (formatting === 'phone' && val) {
        const phone_data = getPhoneNumberData(val, meta && meta.country ? meta.country : "");

        return {input_value: phone_data.edit};
    }
    return {input_value: val ? val : ''};
}

function formatValueForParent(value, formatting) {

    return value;
}

export function Input({
                          value,
                          formatting = '',
                          onEnter = () => {
                          },
                          onPaste = () => {
                          },
                          inputRef = null,
                          autoFocus = false,
                          compact = false,
                          onEmptyBackspace = () => {
                          },
                          onFocus = () => {
                          },
                          input_classes = "",
                          container_classes = "",
                          loading,
                          disabled = false,
                          intent = '',
                          leading,
                          trailing,
                          transform,
                          onBlur,
                          custom = {},
                          onChange,
                          meta,
                          placeholder = "",
                          type = "text",
                          name = "",
                          id
                      }) {
    const [val, setVal] = useState(sanitize(value));
    const [focused, setFocused] = useState(false);
    const isFirstRun = useRef(true);
    const [ref, setRef] = useState(inputRef ? null : React.createRef());
    const sanitized_type = getType(type);
    useEffect(function () {
        if (isFirstRun.current) {
            isFirstRun.current = false;
            return;
        }
        setVal(sanitize(value));
    }, [value, formatting, autoFocus]);
    const oc = onChange ? (e) => {
        const v = typeof e === 'string' ? e : e.target.value;
        setVal(v);
        onChange(formatValueForParent(v, formatting))
    } : (e) => {
        const v = typeof e === 'string' ? e : e.target.value;
        if (transform) {
            setVal(transform(v, ref, 'change'));
        } else {
            setVal(v);
        }
    };

    function handleKeyDown(e) {
        if (e.key === 'Enter') {
            onEnter(e);
        } else if (e.keyCode === 8 && !val) {
            onEmptyBackspace();
        }
    }

    function handleFocus() {
        onFocus();
        setFocused(true);
    }

    function handleBlur(e) {
        if (onBlur) {
            onBlur();
        } else {
            if (transform) {
                setVal(transform(e.target.value, ref, 'blur'))
            }
        }
        setFocused(false);
    }

    const display_value = getDisplayValue(formatting, value, val, focused, meta);

    return <div className={classNames(getContainerClassName(leading, trailing, type === 'color'))}>
        {leading && getLeadingComponent(leading, oc, intent, type, val)}
        <input onPaste={onPaste} onKeyDown={handleKeyDown} autoFocus={autoFocus} onFocus={handleFocus}
               type={sanitized_type}
               disabled={disabled} name={name} id={id} data-type={sanitized_type} ref={inputRef ? inputRef : ref}
               onBlur={handleBlur}
               style={{fontSize: compact?"15px":'16px'}}
               className={classNames(trailing ? `${getPaddingRight(trailing.type, loading, type, compact)}` : `${compact ? "pr-2" : "pr-3"}`, disabled ? 'disabled' : '', leading ? `${getPaddingLeft(leading.type, loading, type, compact)}` : type === 'color' ? 'pl-10' : `${compact ? "pl-2" : "pl-3"}`, `block border w-full ${compact ? "py-1" : "py-2"} ${compact?"h-8":"h-10"} outline-none rounded-md shadow-sm new-focus-standard sm:flex-1`, input_classes, borderClasses(intent, disabled), inputClasses(leading, trailing, loading))}
               placeholder={placeholder} value={display_value.input_value} onChange={oc} {...custom} />
        {trailing && getTrailingComponent(trailing, oc, intent)}
        {loading && <div className="flex items-center text-gray-300 absolute right-0 inset-y-0 pr-3">
            <svg version="1.1" id="loader-1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                 width="28px" height="28px" viewBox="0 0 50 50" style={{enableBackground: "new 0 0 50 50"}}>
                <path fill="currentColor"
                      d="M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615c8.072,0,14.615,6.543,14.615,14.615H43.935z">
                    <animateTransform attributeType="xml"
                                      attributeName="transform"
                                      type="rotate"
                                      from="0 25 25"
                                      to="360 25 25"
                                      dur="0.6s"
                                      repeatCount="indefinite"/>
                </path>
            </svg>
        </div>}
    </div>
}