import React from 'react';
import styled from "styled-components";
import moment from "moment";
import {EntityInfo} from "../../../utilities/localstorage/entity-localstorage";

const Container = styled.div`
    padding: 10px 14px;
`;

const Title = styled.div`
font-size: 16px;
margin-bottom: 2px;
font-weight: 600;
`;

const SubContainer = styled.div`
display: flex;
`;

const Subtitle = styled.div`
margin-right: 8px;
font-weight: 600;
font-size: 13px;
`;

const Meta = styled.div`
font-size: 13px;
`;

function Name({data}) {
    return <Subtitle className='text-gray-800'>{data.name}</Subtitle>
}

export function FPAbout({data,editName,context}) {
    const final_id = data.updated_by?data.updated_by:data.created_by;

    return <Container>
        <div>
            <Title onClick={()=>editName()} className='text-gray-900 truncate hover-opacity'>{data.name}</Title>
        </div>
        <SubContainer>
            {final_id&&<EntityInfo id={final_id} type='members' community_uid={context.community_uid}>
                <Name />
            </EntityInfo>}
            <Meta  className='text-gray-700'>{moment(data.updated_at).format('MMMM Do [at] hh:mm')}</Meta>
        </SubContainer>
    </Container>
}