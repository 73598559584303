import {FirebaseDB} from "../../../../config/setup-firestore";
import {
    convertEntityForLocalStorage,
    saveEntityToLocalStorage
} from "../../../utilities/localstorage/entity-localstorage";

export async function getMembersOfType(id, cid) {
    return FirebaseDB
        .collection('community_members')
        .doc(cid)
        .collection('members')
        .where('archived', '==', false)
        .where('member_type', '==', id)
        .limit(100)
        .get()
        .then((snap) => {
            // save to localstorage
            let ids = [];
            snap.docs.forEach(d => {
                ids.push(d.id);
                const lse = convertEntityForLocalStorage(cid, 'members', d.data());
                saveEntityToLocalStorage(cid, 'members', d.id, lse);
            })
            return ids;
        });
}