import React from 'react';
import {ClockIcon, LockClosedIcon, MailIcon, UsersIcon} from "@heroicons/react/solid";
import {GroupDisplayIcon} from "../utilities/groups";
import {QuillIcon} from "../icons/custom/Quill";
import {Button} from "./core";
import {getAdvancedImageBackgroundStyle} from "./reorganize/profile-card/cover";
import {DEFAULT_COVER_PHOTO, MODERATOR_COLOR, UNATY_COLOR} from "../../config/defaults";
import {NewTooltip} from "./reorganize/new-tooltip";
import ModeratorIcon from "./old/moderator-icon";


function Cover({cover,tags,loading,show_title=true}) {

    if(!show_title) {
        return <div className="h-6">

        </div>
    }

    if(loading) {
        return <div style={{backgroundColor:"#e3e8ed"}} className="h-20 relative bg-gray-200 rounded-t-md">

        </div>
    }

    const style = getAdvancedImageBackgroundStyle("group",cover.url,cover.color,'auto','');

    return <div style={style} className="h-20 relative bg-gray-100 rounded-t-md">
        <Tags tags={tags} />
    </div>
}

function Tags({tags}) {
if(!tags||tags.length===0) {
    return null;
}
    return <div className="absolute left-3 top-2 right-3 flex flex-wrap space-x-2">
        {tags.map((tag,j)=>{
            return <div style={{fontSize:'11px'}} className={`${tag.bg} ${tag.color} font-semibold uppercase tracking-wider shadow-sm px-1.5 py-0.5 rounded`} key={j}>
                {tag.label}
            </div>
        })}
    </div>
}

function Stats({stats,loading}) {
    if(loading) {
        return <div className="flex space-x-3">
            <div className="flex space-x-2">
                <div className="bg-gray-400 rounded-md h-3 w-3" />
                <div className="bg-gray-400 rounded-md h-3 w-3" />
            </div>
        </div>
    }
    return <div className="flex space-x-3">
        {stats.map((st,k)=>{
            let content;
            if(st.desc) {
                content = <NewTooltip message={st.desc}>
                    <div className="flex items-center">
                    <div className="text-gray-500 h-svg-4 w-4 flex justify-center">{st.icon}</div>
                    {st.label&&<div className="text-xs font-medium text-gray-500 ml-1">{st.label}</div>}
                </div>
                </NewTooltip>
            } else {
                content = <div className="flex items-center">
                    <div className="text-gray-500 h-svg-4 w-4 flex justify-center">{st.icon}</div>
                    {st.label&&<div className="text-xs font-medium text-gray-500 ml-1">{st.label}</div>}
                </div>;
            }
            return <div key={k} >
                {content}
            </div>
        })}
    </div>
}

function Icon({icon,loading,title}) {
    if(loading) {
        return <div className={``}>
            <GroupDisplayIcon size="md" name={""} />
        </div>
    }
    return <div>
<GroupDisplayIcon  size="md" name={title} {...icon} />
    </div>
}

function Header({title,loading,onClick,subtitle,title_icon,icon}) {

    return <div className="z-10 relative">
        <div className="flex-none w-6 -mt-5 h-6 flex items-center pt-px">
            <Icon loading={loading} title={title} icon={icon} />
        </div>
        <div className={`flex-grow group mt-1`}>
            <Title loading={loading} onClick={onClick} title={title} title_icon={title_icon} />
            <Subtitle loading={loading} onClick={onClick} subtitle={subtitle} />
        </div>
    </div>
}

function Title({title,loading,onClick,title_icon}) {

    if(loading) {
        return <div className="flex items-center h-5">
            <div className="bg-gray-500 rounded-md h-3 w-2/3" />
        </div>
    }

    return <div onClick={onClick} className=" flex item-center group-hover:underline cursor-pointer ">
        <span className="line-clamp-1 text-base text-gray-800 font-bold">
        {title}
            </span>
        {title_icon&&<span className="flex items-center h-svg-4 ml-1.5 text-gray-500">
            {title_icon}
        </span>}
    </div>
}

function Meta({}) {

    return <div>

    </div>
}

function Subtitle({subtitle,loading,onClick}) {

    if(loading) {
        return <div>
            <div className="bg-gray-300 mt-2 rounded-md h-2.5 w-2/3" />
        </div>
    }

    return <div onClick={onClick} style={{lineHeight:1.3}} className="cursor-pointer text-gray-700 text-xssm line-clamp-2">
        {subtitle}
    </div>
}

export function EntityCard(props) {

    const {loading,onClick,action} = props;

    return <div className="rounded-md flex flex-col hover:shadow-md transition-all max-w-lg border border-gray-200 shadow-sm">
        <Cover loading={loading} {...props} />
        <div className="pl-3 pr-3 pt-2">
        <Header loading={loading} {...props} onClick={() => {
            onClick(props.id);
        }} />
        </div>
        <div className="flex flex-grow items-end pb-2 space-x-1.5 pl-3 pr-3">
            <div className="flex-grow">
                <Meta {...props} />
                <div className="flex space-x-2 mt-3 h-8 items-center">
                    <div className="flex-grow">
                    <Stats loading={loading} {...props} />
                    </div>
                    <div>
                        {action}
                    </div>
                </div>
            </div>
        </div>
    </div>
}