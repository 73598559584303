import React, {useContext} from 'react';
import FilePage from "./file";
import {CommunityContext} from "../community-context";
import {GlobalContext} from "../../global/global-context";
import {useHistory} from "react-router-dom";

function getFilesList(files) {
    let a = {
        map: {},
        ordered: [],
        map_names: {}
    };

    files.forEach(file=>{
        a.map_names[file.name] = true;
        a.ordered.push({
            id: file.id
        });
        a.map[file.id] = file;
    })

    return a;
}

export function FilePageWrapper(props) {
    const global = useContext(GlobalContext);
    const community = useContext(CommunityContext);
    const history = useHistory();

    let file_list = getFilesList(props.files);

    const match = {
      params: {
          fileId: props.id
      }
    };

    const base = `/${community.data.handle}/files`;

    return <FilePage global_viewer history={history} handleClose={()=>{
        global.closeFileViewer();
    }} match={match} file_list={file_list} base={base} />
}