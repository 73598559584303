import React from "react";
import {BookOpenIcon, UserCircleIcon, UsersIcon} from "@heroicons/react/solid";

function Item({icon,message}) {
    return <div className="flex space-x-2">
        <div className="w-5 flex-none">
            <div className="h-5 w-5 text-secondary-700">
                {icon}
            </div>
        </div>
        <div className="flex-grow text-sm font-medium text-gray-700">
            {message}
        </div>
    </div>
}

export function ObScWelcomeToCommunity() {

    return <div className="pt-4 grid gap-4 sm:grid-cols-3">
        <Item icon={<UserCircleIcon />} message={"Create and customize your member profile"} />
        <Item icon={<BookOpenIcon />} message={"Find information in the member directory"} />
        <Item icon={<UsersIcon />} message={"Explore and join groups with other members"} />
    </div>
}