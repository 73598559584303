export const folder_modal_about = {
    flow: 'create-folder',
    step: 'about',

    appearance: {
        size: 'medium',
    },

    state: {
        name: '',
        security: false,
        emoji: ''
    },

    header: {
        title: 'New Folder',
        show_close_icon: true
    },

    context: {
        community_uid: ''
    },

    blocks: [
        {
            type: "text-emoji",
            placeholder: 'Important Files',
            value: ["emoji", "name"],
            label: "Name",
            check_name: 'folder_names',
            meta: "This name is already taken."
        },
        {
            texts: {
                on: {
                    title: 'Private Folder',
                    desc: `Only members you add can see this folder.`
                },
                off: {
                    title: 'Make Folder Private',
                    desc: `If your folder is private, only members you invite can see the contents.`
                }
            },
            type: "info-switch",
            value: "security",
            disabled: [

            ],
            hide: [
                {
                    check: 'ctx-value-not-equal',
                    field: 'folder',
                    value: ''
                }
            ]
        }
    ],

    footer: {
        primary: {
            type: 'button',
            intent: 'secondary',
            text: 'Create Folder',
            action: 'proceed',
            disabled: [
                {
                    field: 'name',
                    check: 'length is less than',
                    value: '2'
                },
                {
                    field: 'name',
                    check: 'in',
                    value: 'folder_names'
                }
            ]
        },
        secondary: null,
        tertiary: null
    }
};