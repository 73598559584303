import React, {useState} from "react";
import {Button, RichSelect} from "../core";
import {Tooltip2} from "@blueprintjs/popover2";
import {AdjustmentsIcon, CogIcon, EyeIcon, EyeOffIcon, LockClosedIcon} from "@heroicons/react/solid";

const visibilities = [
    {
        label: 'System',
        desc: 'Visible for all members in the community.',
        value: 'system',
        icon: <CogIcon />
    },
    {
        label: 'All Members',
        desc: 'Visible for every member in the community.',
        value: 'all-members',
        icon: <div className="text-secondary-700 w-5 h-5 mx-auto flex items-center svg-child-height-min"><EyeIcon /></div>
    },
    {
        label: 'Private',
        desc: 'Member and admins can edit.',
        value: 'private',
        icon: <div className="text-success-600 w-5 h-5 mx-auto flex items-center svg-child-height-min"><LockClosedIcon /></div>
    },
    {
        label: 'Read-only',
        desc: 'Member can view, admins can edit.',
        value: 'read-only',
        icon: <div className="text-purple-700 w-5 h-5 mx-auto flex items-center svg-child-height-min"><EyeIcon /></div>
    },
    {
        label: 'Admins only',
        desc: 'Visible only for admins',
        value: 'admins-only',
        icon: <div className="text-purple-700 w-5 h-5 mx-auto flex items-center svg-child-height-min"><AdjustmentsIcon /></div>
    },
    {
        label: 'Hidden',
        desc: 'Not visible for anyone, including admins',
        value: 'hidden',
        icon: <div className="text-red-600 w-5 h-5 mx-auto flex items-center svg-child-height-min"><EyeOffIcon /></div>
    }
];

const options = visibilities.map(v => {
    return v;
})

export function ProfileSectionVisibilitySelector({selection=options[1].value,layout='icon',onSelect=()=>{}}) {

    const [selected, setSelected] = useState(selection);

    const selected_option = options.filter(function (a) {
        return a.value === selected;
    })[0];

    function handleSelect(v) {
        setSelected(v);
        onSelect(v);
    }

    const trigger = <Button disabled={selected==='system'} minimal={layout!=='icon-label'} left_icon={selected_option.icon} text={layout==='icon-label'?selected_option.label:null} />;

    if(selected==='system') {
        return trigger;
    }

    return <RichSelect custom_trigger={trigger} onSelect={handleSelect} options={options.filter(function(a){
        return a.value !== 'system';
    })} selected={selected}/>
}