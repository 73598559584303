const usernameRegex = /^[a-zA-Z0-9-_]+$/;

export function validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

export function validateFullName(name) {
    if(name.length>3&&name.indexOf(' ') >= 0) {
        return true;
    }
    return false;
}

export function usernameIsValid(input) {
    if(input.length<3||input.length>28) {
        return false;
    }
    var regex = new RegExp(usernameRegex );
    return regex.test(input);
}

export function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}