import React, {useState, useEffect} from "react";
import styled from "styled-components";
import {AMHeader} from "./header";
import {AMBody} from "./body";
import {AMFooter} from "./footer";
import {disableField, getModalWidth} from "./utilities";
import {Button, InlineLoader} from "../../core";
import {SimpleOverlay} from "../../simple-overlay";
const Container = styled.div`
  margin: 0 auto;
`;

const Wrapper = styled.div`
   width: ${props => getModalWidth(props.size)};
       box-shadow: 0 0 0 1px rgba(0,0,0,0.05),0 1px 3px 0 rgba(0,0,0,0.1),0 1px 2px 0 rgba(0,0,0,0.06) !important;
       background-color: #fff;
       border-radius: 6px;
       margin: 0 auto;
       padding-top: 8px;
`;

function getFooterItem(item, data, handleAction, ctx, _loading = false) {
    if (!item) {
        return null;
    }

    const is_disabled = disableField(item, data, ctx);

    switch (item.type) {
        case 'button':
            return <Button tabIndex="0" loading={_loading} minimal={item.minimal} disabled={is_disabled} text={item.text}
                           intent={item.intent}
                           onClick={() => handleAction(item.action)}/>;
        default:
            return null;
    }
}

function arraysEqual(a, b) {
    if (a === b) return true;
    if (a == null || b == null) return false;
    if (a.length !== b.length) return false;

    // If you don't care about the order of the elements inside
    // the array, you should sort both arrays here.
    // Please note that calling sort on an array will modify that array.
    // you might want to clone your array first.

    for (var i = 0; i < a.length; ++i) {
        if (a[i] !== b[i]) return false;
    }
    return true;
}

export function cloneObject(obj) {
    if (null == obj || "object" != typeof obj) return obj;
    let copy = obj.constructor();
    for (let attr in obj) {
        if (obj.hasOwnProperty(attr)) copy[attr] = obj[attr];
    }
    return copy;
}

export function AdvancedModal({
                                  phase_data = {}, dev = false, actionHandler = () => {
    }, _loading = false, context = {}
                              }) {
    const {flow, header, blocks, appearance, state, footer} = phase_data;

    const [data, setData] = useState(state);

    useEffect(() => {
        setData(phase_data.state)
    }, [phase_data]);

    function updateData(f, v) {
        let nd = {...data};
        let val = v;
        if (typeof f === 'string') {
            if (f === 'entities') {
                setData({...v});
            } else {
                nd[`${f}`] = val;
                setData({...nd});
            }
        } else {
            nd = {...data, ...f};
            setData({...nd})
        }
    }

    function handleSubmit() {

        if (disableField(footer.primary, data, context)) {
            return;
        }
        actionHandler('proceed', cloneObject(data));
    }

    function handleAction(action) {
        actionHandler(action, cloneObject(data));
    }

    let content;
    // make sure data is there
    // !arraysEqual(Object.keys(data), Object.keys(state))
    if (!arraysEqual(Object.keys(data), Object.keys(state))) {
        content = <Wrapper className="advanced-modal-wrapper" size={appearance.size}>
            <div style={{padding: '32px'}}>
                <InlineLoader />
            </div>
        </Wrapper>;
    } else {

        content = <Wrapper className="advanced-modal-wrapper" size={appearance.size}>
            <AMHeader closeModal={() => handleAction('close-modal')} {...header}/>
            {_loading && <div style={{padding: '48px'}}>
                <InlineLoader />
            </div>}
            {!_loading &&
            <AMBody handleSubmit={handleSubmit} data={data} updateData={updateData} context={context} blocks={blocks}/>}
            <AMFooter primary={getFooterItem(footer.primary, data, handleAction, context, _loading)}
                      secondary={getFooterItem(footer.secondary, data, handleAction, context, _loading)}
                      tertiary={getFooterItem(footer.tertiary, data, handleAction, context, _loading)}/>
        </Wrapper>;
    }

    if (dev) {
        return content;
    }

    return <SimpleOverlay isOpen usePortal enforceFocus={false} onClose={() => handleAction('close-modal')} canEscapeKeyClose={disableField(footer.primary, data, context)}
                    canOutsideClickClose={disableField(footer.primary, data, context)} portalClassName='advanced-modal-portal'
                    hasBackdrop>
        <Container className="md:pt-12">
            {content}
        </Container>
    </SimpleOverlay>
}