import React, {useContext} from 'react';
import {CommunityContext} from "../../community-context";
import {getGreetingFromUserTimezone} from "../../../../library/utilities/time-greeting";
import {useIntl} from "react-intl";
import {ChevronRightIcon} from "@heroicons/react/solid";
import {GroupDisplayIcon} from "../../../../library/utilities/groups";
import {useHistory} from "react-router-dom";
import {EntityHeader} from "../../../../library/components/core";
import {FolderIcon, UserCircleIcon} from "@heroicons/react/outline";

function Item({label,icon,onClick}) {

    return <div onClick={onClick} className="flex items-center hover:opacity-70 transition-opacity space-x-2 p-1.5">
        <div className="flex-none">
            <div className="h-5 w-5 text-gray-500">
                {icon}
            </div>
        </div>
        <div className="flex-grow">
            <div className="text-gray-700 text-base font-medium">{label}</div>
        </div>
        <div className="flex-none">
            <div className="h-5 w-5 text-gray-700">
                <ChevronRightIcon />
            </div>
        </div>
    </div>
}

function GroupItem({onClick,label,profile_picture,emoji,color}) {
    return <div onClick={onClick} className="flex items-center hover:opacity-70 transition-opacity space-x-2 p-1.5">
        <div className="flex-none">
            <GroupDisplayIcon emoji={emoji} profile_picture={profile_picture}
                              profile_picture_color={""} color={color}
                              name={label}/>
        </div>
        <div className="flex-grow">
            <div className="text-gray-700 text-base font-medium">{label}</div>
        </div>
        <div className="flex-none">
            <div className="h-5 w-5 text-gray-700">
                <ChevronRightIcon />
            </div>
        </div>
    </div>
}

function Section({title="",items=[]}) {

    return <div className="px-1.5">
        {title&&<div className="text-sm font-medium text-gray-700 px-1.5">{title}</div>}
        <div>
            {items.map(it=>{
                if(it.type==='item') {
                    return <Item key={it.id} {...it} />
                } else {
                    return <GroupItem key={it.id} {...it} />
                }
            })}
        </div>
    </div>
}

function buildSections(community,history) {
    let arr = [];

    const all_groups_and_teams = Object.entries(community.my_groups);

    const my_groups = all_groups_and_teams.filter(a=>a[1].type==='standard');
    const my_teams = all_groups_and_teams.filter(a=>a[1].type==='team');

    arr.push({
        id: 'actions',
        items: [
            {
                type: 'item',
                id: 'view-profile',
                onClick: () => {
                    history.push(`/${community.data.handle}/member/${community.member.handle}`)
                },
                label: 'View my profile',
                icon: <UserCircleIcon />
            },
            {
                type: 'item',
                id: 'view-drive',
                onClick: () => {
                    history.push(`/${community.data.handle}/explore/drive`)
                },
                label: 'Community Drive',
                icon: <FolderIcon />
            }
        ]
    });

    if(my_groups.length>0) {
        arr.push({
            id: 'groups',
            title: 'Groups',
            items: my_groups.map(([id,data])=> {
                return {
                    label: data.name,
                    id,
                    onClick: ()=> {
                        history.push(`/${community.data.handle}/group/${id}`)
                    },
                    profile_picture: data.profile_picture,
                    emoji: data.emoji,
                    color: data.color
                }
            }).sort((a,b)=>a.label<b.label?-1:1)
        });
    }

    if(my_teams.length>0) {
        arr.push({
            id: 'teams',
            title: 'Teams',
            items: my_teams.map(([id, data]) => {
                return {
                    label: data.name,
                    id,
                    onClick: () => {
                        history.push(`/${community.data.handle}/group/${id}`)
                    },
                    profile_picture: data.profile_picture,
                    emoji: data.emoji,
                    color: data.color
                }
            }).sort((a, b) => a.label < b.label ? -1 : 1)
        });
    }
    return arr;
}

export function MobileHomePage() {
    const community = useContext(CommunityContext);
    const {formatMessage: f} = useIntl();
    const history = useHistory();
    const sections = buildSections(community,history);

    return <div className="pb-12">
                <EntityHeader title="Home"/>
        <div className="space-y-4">
            <div className="p-3">
                <div className="flex-grow">
                    <div className="text-xl font-bold text-gray-800">{f({id:`welcome_card.${getGreetingFromUserTimezone()}`})}, {community.member.name}</div>
                    <div className="text-gray-500 text-base">
                        {f({id:'welcome_card.greeting'})}
                    </div>
                </div>
            </div>
            {sections.map(sec=><Section key={sec.id} {...sec} />)}
        </div>
    </div>
}