import React, {useState, useEffect, useContext} from 'react';
import {useHistory} from 'react-router-dom';
import {ActionWithIcon, Button, Field, InterestTag} from "../../core";
import {reference_interests} from "../../../../app/data-references";
import {PlusIcon} from '@heroicons/react/solid';
import {InterestSelectorModal} from "../../reorganize/interest-selector-modal";
import {CommunityContext} from "../../../../app/community/community-context";
import {EntityInfo} from "../../../utilities/localstorage/entity-localstorage";

function InterestWrapper({data,history,editing,community,handleRemoveInterest,id,active}) {
    if(!data) {
        return null;
    }
    console.log('InterestWrapper',data)
    return <div className="mr-1 mb-1">
        <InterestTag onClick={()=>{
            if(!editing) {
                history.push(`/${community.data.handle}/interests/${id}`)
            }
        }} onRemove={()=>handleRemoveInterest(id)} can_remove={editing} active={active} {...data} />
    </div>
}

export function InterestField({interests,cmi={},my_profile=false,layout='group',can_edit=true,onSave=()=>{},label="Group Interests",max=3}) {
    const [editing, setEditing] = useState(false);
    const [open,setOpen] = useState(false);
    const [new_interests,setNewInterests] = useState(interests);
    const items = new_interests ? Object.keys(new_interests) : [];
    const community = useContext(CommunityContext);
    const history = useHistory();

    function handleRemoveInterest(id) {
        let ni = new_interests;
        if(ni[id]) {
            delete ni[id];
        }
        if(Object.keys(ni).length===0) {
            setEditing(false);
        }
        setNewInterests({...ni});
    }

    useEffect(function () {
        onSave(new_interests);
    }, [new_interests]);

    const content = items.map((id,i)=>{

        const active = cmi[id] || my_profile;

        if(id.startsWith('default_')) {
            const int = reference_interests[id];
            return <div key={int.id} className="mr-1 mb-1">
                <InterestTag onClick={()=>{
                    if(!editing) {
                        history.push(`/${community.data.handle}/interests/${id}`)
                    }
                }} onRemove={()=>handleRemoveInterest(id)} can_remove={editing} active={active} key={int.id} {...int} />
            </div>
        } else {
            return <EntityInfo key={id} id={id} type="interests" community_uid={community.uid}>
                <InterestWrapper history={history} id={id} active={active} community={community} editing={editing} handleRemoveInterest={handleRemoveInterest} />
            </EntityInfo>
        }
    });

    if(!can_edit) {
        return <div className="flex flex-wrap max-w-2xl">
            {content}
        </div>
    }

    const at_max = items.length >= max;

    return <div>
        <Field label={label}
               corner_hint={items.length===0?null:can_edit?<ActionWithIcon text={editing?"Done":"Edit"} onClick={()=>setEditing(!editing)} />:null} help_text={!can_edit?"":`Choose up to ${max}`}>
            <div className={`flex flex-wrap interest-tag-spacing max-w-3xl ${editing?"wobble-items":""}`}>
                {content}
                {!editing&&can_edit&&!at_max&&<div className="-mt-px">
                    <Button minimal text="Add" onClick={()=>setOpen(true)} left_icon={<PlusIcon/>} circular />
                </div>}
            </div>
        </Field>

        {open&&can_edit&&<InterestSelectorModal onClose={()=>setOpen(false)} onSave={(p)=>{setOpen(false);setNewInterests({...p})}} initial={new_interests} max={max} open={open} />}
    </div>
}