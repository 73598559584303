import {map} from "lodash";
import {FirebaseDB} from "../../../config/setup-firestore";
import {useEffect, useState} from "react";
import {at_prepareCustomFields} from "../../../library/utilities/community/custom-fields";
import {getItemFromLocalStorage} from "../../../library/utilities/localstorage";

export const directory_getInitialView = (ls_key) => {
    const urlParams = new URLSearchParams(window.location.search);
    const view_id = urlParams.get('view');

    if (view_id) {
            return view_id;
    }
    return getItemFromLocalStorage(ls_key,"")
}

export function directory_buildConfig(community) {
    return {
        type: 'member',
        general: {
            title: 'Members',
            emoji: '',
            icon: null
        },
        base_query: [
            // this should be an OR if true
            {
                fi: 'archived',
                op: '==',
                va: false
            }
        ],
        selected_actions: [
            // export
        ],
        actions: [
            // invite, export all, import
        ],
        selected_more_options: [],
        more_options: [],
        view_options: {
            list: {
                label: 'List'
            },
            table: {
                label: 'Table'
            },
            gallery: {
                label: 'Gallery'
            }
        },

        features: {
            filter: community.member_access.manage_members,
            group: true,
            edit_fields: community.member_access.manage_members,
            can_set_default_view: community.member_access.manage_members,
            sort: true,
            can_sort: true,
            can_filter: community.member_access.manage_members,
            create_view: true,
            can_edit: false,
            can_select: false,
            search: false
        }
    };
}

export function directory_buildContext(community, props, history, addToast, global) {
    return {
        community_uid: community.uid,
        member_id: community.member_id,
        user_uid: community.member.user_uid,
        handle: community.data.handle,
        data: community.data,
        community,
        global,
        history: history,
        default_profile_picture: community.default_member_profile_picture,
        addToast: addToast,
        is_admin: community.member_access.manage_members,
        custom_fields: at_prepareCustomFields(community.custom_fields),
        time_format: community.member.preferences.time_format ? community.member.preferences.time_format : 'MM/DD/YYYY',
        member_types: community.member_types,
    };
}

export function useSmartViews(bool, community) {
    if (!bool) {
        return {data: []}
    }
    const [data, setData] = useState([]);
    useEffect(() => {
        if (community.member_access.manage_members) {
            FirebaseDB
                .collection('community_entities')
                .doc(community.uid)
                .collection('smart_views')
                .where('type', '==', 'member')
                .onSnapshot(snap => setData([...snap.docs.map(doc => {
                    const dt = doc.data();
                    return {
                        ...dt,
                        config: typeof dt.config === 'string' ? JSON.parse(dt.config) : dt.config,
                    }
                })]))
        } else {
            FirebaseDB
                .collection('community_entities')
                .doc(community.uid)
                .collection('smart_views')
                .where('type', '==', 'member')
                .where('layout', 'in', ['list', 'gallery'])
                .onSnapshot(snap => setData([...snap.docs.map(doc => {
                    const dt = doc.data();
                    return {
                        ...dt,
                        config: typeof dt.config === 'string' ? JSON.parse(dt.config) : dt.config,
                    }
                })]))
        }
    }, [community.uid]);

    return {data};
}