import React from 'react';
import {GeneralEntityItem} from "./reorganize/entity-select/table";
import {DEFAULT_USER_PHOTO} from "../../config/defaults";
import ModeratorIcon from "./old/moderator-icon";
import RoleIcon from "./old/role-icon";
import styled from "styled-components";
import {CIMemberType} from "../icons/custom/MemberType";
import {CISmallSegment} from "../icons/custom/SmallSegment";

const IconCont1 = styled.div`
  width: 1.125rem;
  height: 1.125rem;
 > div > svg {
   height: 18px;
   width: 18px;
 }
`;

export function getEntityTypeIcon(type) {
    switch (type) {
        case 'roles':
            return <IconCont1><RoleIcon /></IconCont1>
        case 'member-types':
            return <IconCont1><CIMemberType /></IconCont1>
        case 'segments':
            return <IconCont1><CISmallSegment /></IconCont1>
        default:
            return <ModeratorIcon />
    }
}