import React, {Component} from "react";
import {SimpleProgressBar} from "../../../library/components/simple-progress-bar";

export default class UnProgressBar extends Component {
    constructor(props) {
        super(props);

        this.state = {
            progress: 0.15
        };
    }

    componentDidMount() {
        this.startTimer()
    }

    startTimer(){
        const {seconds=10} = this.props;
        const ms = seconds * 1000;
        setTimeout(function() { //Start the timer
            this.setState({
                progress: 0.25
            })
        }.bind(this), (ms/8));

        setTimeout(function() { //Start the timer
            this.setState({
                progress: 0.45
            })
        }.bind(this), (ms/5));

        setTimeout(function() { //Start the timer
            this.setState({
                progress: 0.7
            })
        }.bind(this), (ms/2));

        setTimeout(function() { //Start the timer
            this.setState({
                progress: 0.95
            })
        }.bind(this), (ms/1.2));
    }

    render() {
        const {done,real_progress} = this.props;
        const {progress} = this.state;
        let val = real_progress ? real_progress : done?1:progress;
        return <SimpleProgressBar stripes value={val} intent={done ? 'success' : 'secondary'}/>
    }
}