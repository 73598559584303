import React from "react";
import {Tooltip2} from "@blueprintjs/popover2";

function buildContent(message,subtext) {
    let title = <div
        className={`text-sm font-semibold  text-white`}
        tabIndex="-1">{message}</div>;
    if(subtext) {
        return <div className="text-center">
            {title}
            <div
                className={`text-xs font-medium text-gray-400`}
                tabIndex="-1">{subtext}</div>
        </div>
    }
    return title
}

export function NewTooltip({
                               children,
                               subtext,
                               usePortal = true,
                               content_props = {},
                               message = "",
                               on = "hover",
                               margin = false
                           }) {

    let content = buildContent(message, subtext);

    return <Tooltip2 openOnTargetFocus={false} usePortal={usePortal} minimal interactionKind={on}
                     popoverClassName={`${margin ? `m-2` : `m-1`}`} placement='bottom-center'
                     content={<div {...content_props}><div className="rounded-md px-2.5 py-1.5 bg-gray-800 shadow-md border border-gray-800">{content}</div></div>}>
        {children}
    </Tooltip2>
}