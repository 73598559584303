import React, {Component, useContext} from "react";
import {EntityHeader, PageHeader} from "../../../../../library/components/core";
import {PageContainer} from "../../../../../library/components/old/page-container";
import EditFieldsSection from "../../../../../library/components/old/edit-fields-section";
import {CommunityChangeOwner} from "./change-owner";
import CommunityUsage from "./community-usage";
import {CommunityContext} from "../../../community-context";
import {StandardPageHeight} from "../../../../../library/playground/standard-page-framework";

export default function SettingsPlan(props) {
    const community = useContext(CommunityContext);
 
        const {history} = props;

        const base_link = `/${community.data.handle}/admin/settings`;

        const obj = {
            base_link: `/${community.data.handle}/admin/settings`,
            data: community.data.plan,

            endpoint: '/communities/update-plan',
            update_fields: ['level', 'account_owner', 'custom_domain'],

            sections: [
                {
                    title: 'General',
                    fields: [
                        {
                            name: 'Plan',
                            type: 'text',
                            disabled: true,
                            field: 'level'
                        },
                        {
                            name: 'Custom Domain',
                            type: 'text',
                            disabled: true,
                            field: 'custom_domain'
                        },
                        {
                            name: 'Owner',
                            type: 'member',
                            disabled: true,
                            field: 'account_owner'
                        }
                    ]
                }
            ]
        };

        return (<StandardPageHeight content>
                <EntityHeader goBackTo={()=>history.push(`/${community.data.handle}/admin/settings`)} title="Plan" />

                <EditFieldsSection member_types={community.member_types} history={props.history} {...obj} />

                <CommunityUsage community_uid={community.uid} />

                <CommunityChangeOwner/>
            </StandardPageHeight>
        );
}