import React, {useState} from 'react';
import {authFetch} from "../../config/network";
import {Button, Field, Input} from "../../library/components/core";

export function GetSetPasswordLink({}) {
    const [input,setInput] = useState("");
    const [loading,setLoading] = useState("");
    function callAPI() {
        setLoading('loading')
        const res = () => {
            setLoading('')
            setInput("");
        };

        const payload = {
            _user_uid: input
        };

        authFetch("/superadmin/set-pw-link", res, res, "POST", {payload});
    }

    return <div>

            <Input placeholder="User UID" value={input} onChange={v=>setInput(v)} />
        <div>
            <Button onClick={()=>callAPI()} text="Generate Set PW Link" loading={loading==='loading'} />
        </div>
    </div>
}