import React, {useContext, useEffect, useState} from 'react';
import {PreviewTypeSelector} from "../../../../../../library/components/reorganize/preview-type-selector";
import {ProfileFieldsSection} from "../../../../../../library/components/reorganize/profile-fields-section";
import {CommunityContext} from "../../../../community-context";
import {CADGrid, CADPreviewBox} from "../shared";
import {AddCustomFieldsSection} from "../../../../../../library/components/reorganize/add-custom-fields-section";
import {GlobalContext} from "../../../../../global/global-context";
import {authFetch} from "../../../../../../config/network";
import {CADPreview} from "./preview";
import {EyeOffIcon, GlobeIcon, HomeIcon} from "@heroicons/react/outline";
import {EyeIcon, PencilAltIcon} from "@heroicons/react/solid";

function getVisibility(dd, key) {
    return dd && dd[key] ? dd[key] : 'all-members';
}

export const StandardFieldVisibilityOptions = [
    {value: "full", label: "Read & Edit", icon: <PencilAltIcon/>},
    {value: "read-only", label: "Read-only", icon: <EyeIcon/>},
    {value: "hidden", label: "Hidden", icon: <EyeOffIcon/>}
];

export const AddressVisibilityOptions = [
    {value: "full", label: "Full Address", icon: <HomeIcon/>},
    {value: "simple", label: "City & Country", icon: <GlobeIcon/>},
    {value: "hidden", label: "Hidden", icon: <EyeOffIcon/>}
];

function buildSections(community) {
    let sections = [];
    let data = {};
    const cdp = community.data.preferences;
    sections.push('general');
    data['general'] = {
        title: 'General',
        can_edit_name: false,
        can_delete_fields: false,
        can_delete_section: false,
        can_toggle_important: false,
        save_endpoint: '/communities/update-design-defaults',
        can_add_field: false,
        init_visibility: 'system',
        init_field_ids: [
            'profile-picture',
            'cover-photo',
            'display-name',
            'member-type',
            'membership-status',
            'language',
            'birthday',
            'gender'
        ],
        init_field_data: {
            'profile-picture': {
                type: 'profile-picture',
                name: 'Member Picture',
                set: 'system',
                can_expand: true,
                important: true,
                value: {
                    url: community.data.design_defaults ? community.data.design_defaults.member_profile_picture : '',
                    color: community.data.design_defaults ? community.data.design_defaults.member_profile_picture_color : '',
                }
            },
            'cover-photo': {
                type: 'cover-photo',
                name: 'Member Cover Photo',
                set: 'system',
                can_expand: true,
                important: false,
                value: {
                    url: community.data.design_defaults ? community.data.design_defaults.member_cover_photo : '',
                    color: community.data.design_defaults ? community.data.design_defaults.member_cover_photo_color : ''
                }
            },
            'display-name': {
                type: 'display-name',
                name: 'Display Name',
                set: 'system',
                can_expand: true,
                important: false,
                value: {
                    format: community.data.preferences.members.display_name
                }
            },
            'member-type': {
                type: 'member-type',
                name: 'Member Type',
                set: 'system',
                can_expand: true,
                important: false,
                value: {
                    id: community.data.preferences.members.default_member_type
                }
            },
            'membership-status': {
                type: 'membership-status',
                name: 'Membership Status',
                set: 'system',
                can_expand: false,
                important: false,
                value: {
                    id: "active"
                }
            },
            'language': {
                type: 'language',
                name: 'Language',
                set: 'system',
                can_expand: true,
                important: false,
                value: {
                    value: community.data.preferences.timedate.language
                }
            },
            'birthday': {
                type: 'birthday',
                name: 'Birthday',
                set: 'system',
                important: false,
                hidden: cdp?.members?.birthday_visibility ? cdp?.members?.birthday_visibility : "full",
                visibility_options: StandardFieldVisibilityOptions,
                hidden_key: 'birthday_visibility',
                value: {
                    value: cdp?.members?.birthday_visibility
                }
            },
            'gender': {
                type: 'gender',
                name: 'Sex',
                set: 'system',
                can_expand: false,
                important: false,
                hidden: !!community.data.preferences.members.hide_gender,
                hidden_key: 'hide_gender',
                value: {
                    value: community.data.preferences.members.default_gender
                }
            }
        }
    };

    sections.push('basic-info');
    data['basic-info'] = {
        title: 'Basic Info',
        can_edit_name: false,
        can_delete_section: false,
        can_add_field: false,
        save_endpoint: '/communities/update-design-defaults',
        init_visibility: 'system',
        init_field_ids: [
            'first-name',
            'middle-name',
            'last-name',
            'title',
            'bio',
            'address',
            'location',
            'occupation',
            'headline'
        ],
        init_field_data: {
            'first-name': {
                type: 'text',
                name: 'First Name',
                set: 'system',
                can_expand: false,
                important: true
            },
            'last-name': {
                type: 'text',
                name: 'Last Name',
                set: 'system',
                can_expand: false,
                important: true
            },
            'middle-name': {
                type: 'text',
                name: 'Middle Name',
                set: 'system',
                can_expand: false,
                important: false
            },
            'title': {
                type: 'text',
                set: 'system',
                name: 'Title',
                can_expand: false,
                important: false
            },
            'bio': {
                type: 'text',
                name: 'Bio',
                set: 'system',
                can_expand: false,
                important: false
            },
            'headline': {
                type: 'text',
                name: 'Headline',
                set: 'system',
                can_expand: false,
                important: false
            },
            'occupation': {
                type: 'text',
                name: 'Occupation',
                set: 'system',
                can_expand: false,
                important: false
            },
            'location': {
                type: 'text',
                name: 'Location',
                set: 'system',
                can_expand: false,
                important: false
            },
            'address': {
                type: 'text',
                name: 'Address',
                set: 'system',
                important: false,
                hidden: cdp.members.address_visibility ? cdp.members.address_visibility : "full",
                visibility_options: AddressVisibilityOptions,
                hidden_key: 'address_visibility',
                value: {
                    value: cdp.members.address_visibility
                }
            }
        }
    };

    sections.push('contact-info');
    data['contact-info'] = {
        title: 'Contact Info',
        can_edit_name: false,
        save_endpoint: '/communities/update-design-defaults',
        can_delete_section: false,
        can_add_field: false,
        init_visibility: 'system',
        init_field_ids: [
            'email',
            'phone',
            'secondary-phone'
        ],
        init_field_data: {
            'email': {
                type: 'email',
                set: 'system',
                name: 'Email',
                can_expand: false,
                important: true
            },
            'phone': {
                type: 'phone',
                set: 'system',
                name: 'Phone',
                can_expand: false,
                important: true
            },
            'secondary-phone': {
                type: 'phone',
                set: 'system',
                can_expand: false,
                name: 'Secondary Phone',
                important: false
            }
        }
    };

    sections.push('social');
    data['social'] = {
        title: 'Social',
        can_edit_name: false,
        save_endpoint: '/communities/update-design-defaults',
        can_delete_section: false,
        can_add_field: false,
        can_toggle_important: true,
        visibility_key: 'design_defaults.social_visibility',
        init_field_ids: [
            'website',
            'facebook',
            'twitter',
            'linkedin',
            'eth_address',
            'github',
            'reddit',
            'discord',
            'skype',
            'instagram'
        ],
        init_field_data: {
            'website': {
                type: 'url',
                name: 'Website',
                can_expand: false,
                important: !!community.data.preferences.members.imp_website,
                important_key: 'imp_website',
                hidden: !!community.data.preferences.members.hide_website,
                hidden_key: 'hide_website',
            },
            'facebook': {
                type: 'social-facebook',
                name: 'Facebook',
                can_expand: false,
                important: !!community.data.preferences.members.imp_facebook,
                important_key: 'imp_facebook',
                hidden: !!community.data.preferences.members.hide_facebook,
                hidden_key: 'hide_facebook',
            },
            'discord': {
                type: 'social-discord',
                name: 'Discord',
                can_expand: false,
                important: !!community.data.preferences.members.imp_discord,
                important_key: 'imp_discord',
                hidden: !!community.data.preferences.members.hide_discord,
                hidden_key: 'hide_discord',
            },
            'instagram': {
                type: 'social-instagram',
                name: 'Instagram',
                can_expand: false,
                important: !!community.data.preferences.members.imp_instagram,
                important_key: 'imp_instagram',
                hidden: !!community.data.preferences.members.hide_instagram,
                hidden_key: 'hide_instagram',
            },
            'github': {
                type: 'social-github',
                name: 'Github',
                can_expand: false,
                important: !!community.data.preferences.members.imp_github,
                important_key: 'imp_github',
                hidden: !!community.data.preferences.members.hide_github,
                hidden_key: 'hide_github',
            },
            'eth_address': {
                type: 'social-eth',
                name: 'Eth Address',
                can_expand: false,
                important: !!community.data.preferences.members.imp_eth_address,
                important_key: 'imp_eth_address',
                hidden: !!community.data.preferences.members.hide_eth_address,
                hidden_key: 'hide_eth_address',
            },
            'reddit': {
                type: 'social-reddit',
                name: 'Reddit',
                can_expand: false,
                important: !!community.data.preferences.members.imp_reddit,
                important_key: 'imp_reddit',
                hidden: !!community.data.preferences.members.hide_reddit,
                hidden_key: 'hide_reddit',
            },
            'twitter': {
                type: 'social-twitter',
                name: 'Twitter',
                can_expand: false,
                important: !!community.data.preferences.members.imp_twitter,
                important_key: 'imp_twitter',
                hidden: !!community.data.preferences.members.hide_twitter,
                hidden_key: 'hide_twitter',
            },
            'skype': {
                type: 'social-skype',
                name: 'Skype',
                can_expand: false,
                important: !!community.data.preferences.members.imp_skype,
                important_key: 'imp_skype',
                hidden: !!community.data.preferences.members.hide_skype,
                hidden_key: 'hide_skype',
            },
            'linkedin': {
                type: 'social-linkedin',
                name: 'LinkedIn',
                can_expand: false,
                important: !!community.data.preferences.members.imp_linkedin,
                important_key: 'imp_linkedin',
                hidden: !!community.data.preferences.members.hide_linkedin,
                hidden_key: 'hide_linkedin',
            }
        },
        init_visibility: getVisibility(community.data.design_defaults, 'social_visibility')
    };

    const {custom_fields} = community;
    const custom_sections = Object.entries(custom_fields).sort((a, b) => a[1].order - b[1].order);

    for (let i = 0; i < custom_sections.length; i++) {
        const [csid, csdata] = custom_sections[i];

        let init_field_data = {};
        let init_field_ids = [];

        for (let k = 0; k < csdata.fields.length; k++) {
            const fid = csdata.fields[k];
            const fdata = csdata.field_data[fid];
            init_field_data[fid] = {
                type: fdata.type,
                name: fdata.name,
                can_edit_name: true,
                can_expand: true,
                placeholder: fdata.placeholder ? fdata.placeholder : "",
                question: fdata.question ? fdata.question : "",
                hint: fdata.hint ? fdata.hint : "",
                important: community.important_custom_fields.indexOf(fid) !== -1,
                original: fdata,
                value: {
                    options: {}
                }
            };
            if (fdata.is_primary) {
                init_field_data[fid].is_primary = true;
            }
            if (fdata.is_secondary) {
                init_field_data[fid].is_secondary = true;
            }
            if (fdata.type === 'select' || fdata.type === 'multi-select') {
                init_field_data[fid].value.options.choices = fdata.options.choices;
            } else if (fdata.type === 'checkbox') {
                init_field_data[fid].value.options = fdata.options ? fdata.options : {
                    checked_label: "",
                    unchecked_label: ""
                }
            } else if (fdata.type === 'multi-select-taxonomy' || fdata.type === 'select-taxonomy') {
                init_field_data[fid].value.options = fdata.options ? fdata.options : {taxonomy: ""}
            }
            init_field_ids.push(fid);
        }
        sections.push(csid);
        data[csid] = {
            title: csdata.name,
            can_edit_name: true,
            can_toggle_important: true,
            can_delete_fields: true,
            save_endpoint: '/community/customize/update-profile-section',
            can_delete_section: true,
            can_add_field: true,
            init_field_data,
            init_field_ids,
            visibility_key: 'visibility',
            init_visibility: csdata.visibility ? csdata.visibility : 'all-members',
        };
    }

    sections.push('add-section');

    sections.push('education');
    data['education'] = {
        title: 'Education',
        can_edit_name: false,
        save_endpoint: '/communities/update-design-defaults',
        can_delete_section: false,
        can_add_field: false,
        visibility_key: 'design_defaults.education_visibility',
        init_visibility: community.data.design_defaults && community.data.design_defaults.education_visibility ? community.data.design_defaults.education_visibility : 'all-members',
    };

    sections.push('career');
    data['career'] = {
        title: 'Career',
        can_edit_name: false,
        save_endpoint: '/communities/update-design-defaults',
        can_delete_section: false,
        can_add_field: false,
        visibility_key: 'design_defaults.career_visibility',
        init_visibility: community.data.design_defaults && community.data.design_defaults.career_visibility ? community.data.design_defaults.career_visibility : 'all-members',
    };

    sections.push('interests');
    data['interests'] = {
        title: 'Interests',
        save_endpoint: '/communities/update-design-defaults',
        can_edit_name: false,
        can_delete_section: false,
        can_add_field: false,
        visibility_key: 'design_defaults.interests_visibility',
        init_visibility: community.data.design_defaults && community.data.design_defaults.interests_visibility ? community.data.design_defaults.interests_visibility : 'all-members',
    };

    return {sections, data};
}

const swapElements = (array, index1, index2) => {
    let temp = array[index1];
    array[index1] = array[index2];
    array[index2] = temp;
};

function buildAllFieldsData(custom_fields) {
    let obj = {};
    const sections = Object.values(custom_fields);
    sections.forEach(sec => {
        sec.fields.forEach(fi => {
            obj[fi] = sec.field_data[fi];
        })
    })
    return obj;
}

const sys_sections = [
    'general',
    'contact-info',
    'basic-info',
    'education',
    'social',
    'career',
    'interests',
]

export function CADMemberProfile(props) {
    const global = useContext(GlobalContext);
    const community = useContext(CommunityContext);

    const [collapsed, setCollapsed] = useState([
        ...sys_sections
    ]);

    const [preview_type, setPreviewType] = useState('my-profile');

    const [sections, setSections] = useState([]);
    const [section_data, setSectionData] = useState({});

    useEffect(function () {
        // every time community updates
        const {sections, data} = buildSections(community);

        setSections(sections);
        setSectionData(data);
    }, [community]);

    function handleExpand(id) {
        let nc = [...collapsed];
        if (collapsed.indexOf(id) !== -1) {
            nc.splice(collapsed.indexOf(id), 1);
        } else {
            nc.push(id);
        }
        setCollapsed(nc);
    }

    function handleAddSection(p) {
        const {name, visibility} = p;

        let payload = {
            community_uid: community.uid,
            member_id: community.member_id,
            user_uid: community.member.user_uid,
            name,
            order: 100 + sections.length,
            fields: [],
            field_data: {},
            desc: '',
            visibility
        };

        const res = () => {

            global.addToast({text: 'Profile Section added', intent: 'success'});
        }

        authFetch('/community/customize/create-profile-section', res, res, "POST", {payload});
    }

    function handleDeleteSection(p) {
        let payload = {
            community_uid: community.uid,
            member_id: community.member_id,
            user_uid: community.member.user_uid,
            id: p.id
        };
        const res = () => {

            global.addToast({text: 'Changes Saved', intent: 'success'});
        }

        authFetch('/community/customize/delete-profile-section', res, res, "POST", {payload});
    }

    function updateSectionsOrder(updated_sections) {
        let new_sections_data = {
            ...section_data
        };
        let os = [];
        updated_sections.forEach(sec => {
            if (!sys_sections.includes(sec) && sec !== 'add-section') {
                os.push(sec);
            }
        })
        setSectionData(new_sections_data);
        let payload = {
            community_uid: community.uid,
            member_id: community.member_id,
            user_uid: community.member.user_uid,

            ordered_sections: os
        };
        const res = () => {
            console.log("order updated")
        }
        authFetch('/community/customize/update-sections-order', res, res, "POST", {payload});
    }

    const all_fields_data = buildAllFieldsData(community.custom_fields);

    const cf_sections_count = Object.keys(community.custom_fields).length;
    return <CADGrid>
        <div>
            <div className="space-y-2">
                {sections.map((sec, k) => {
                    if (sec === 'add-section') {
                        return <AddCustomFieldsSection handleAdd={handleAddSection} key='add-section'/>
                    }
                    const data = section_data[sec];

                    const cf_index = k - 4;

                    const onMoveUp = cf_sections_count < 2 || sys_sections.includes(sec) || cf_index < 1 ? null : () => {

                        let updated_sections = [...sections];
                        swapElements(updated_sections, k, k - 1);
                        setSections(updated_sections);
                        updateSectionsOrder(updated_sections);
                    };
                    const onMoveDown = cf_sections_count < 2 || sys_sections.includes(sec) || (cf_index + 1 === cf_sections_count) ? null : () => {
                        let updated_sections = [...sections];
                        swapElements(updated_sections, k, k + 1);
                        setSections(updated_sections);
                        updateSectionsOrder(updated_sections);
                    };
                    return <ProfileFieldsSection onMoveUp={onMoveUp} onMoveDown={onMoveDown}
                                                 all_fields_data={all_fields_data} updateSection={(dt) => {
                        let sd = {...section_data};
                        sd[sec] = dt;
                        setSectionData(sd);
                    }} onDelete={handleDeleteSection} context={{community, global}} onExpand={() => handleExpand(sec)}
                                                 expanded={collapsed.indexOf(sec) === -1} key={sec}
                                                 id={sec} {...data} />
                })}
            </div>
        </div>
        <div>
            <CADPreviewBox>

                <div className="absolute top-2 right-2 z-50">
                    <PreviewTypeSelector initial={preview_type} onSelect={(nt) => setPreviewType(nt)}/>
                </div>
                <div>
                    {section_data['general'] && <CADPreview
                        cover_photo_color={section_data['general'].init_field_data['cover-photo'].value.color}
                        visibility_filter={preview_type} sections={sections.filter(function (a) {
                        return (a !== 'add-section') && (a !== 'general')
                    }).map(sec => {
                        const sec_data = section_data[sec];
                        return {
                            name: sec_data.title,
                            visibility: sec_data.init_visibility,
                            fields: sec_data.init_field_ids ? sec_data.init_field_ids.map(id => {
                                return {
                                    name: sec_data.init_field_data[id].name,
                                    required: !!sec_data.init_field_data[id].important
                                }
                            }) : []
                        };
                    })} cover_photo={section_data['general'].init_field_data['cover-photo'].value.url}
                        profile_picture={section_data['general'].init_field_data['profile-picture'].value.url}
                        display_name={section_data['general'].init_field_data['display-name'].value.format}
                    />}
                </div>
            </CADPreviewBox>
        </div>
    </CADGrid>
}