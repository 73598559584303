import {operators} from "../../../app/community/reorganize/filtering-data";
import {queryMembersData} from "../../../app/community/group/members-modal/api";

function filterIsValid(fi, field_info) {
    return operators[field_info.type].filter(a => a.value === fi.operator)[0].isValid(fi.value);
}

function buildValue(value, type, operator) {
    return operators[type].filter(a => a.value === operator)[0].getQueryValue(value);
}

function checkField(field, operator) {
    if (field === 'cf_number_primary') {
        return field;
    } else if (field === 'cf_number_secondary') {
        return field;
    } else if (field.startsWith('address.')) {
        return field.split('address.')[1];
    } else if (field.startsWith('about.')) {
        return field.split('about.')[1];
    } else if (field.startsWith('stats.')) {
        return field.split('stats.')[1];
    } else if (field.startsWith('custom_fields.')) {
        if (operator === 'empty' || operator === 'not_empty') {
            return 'custom_fields_exist';
        }
        return 'custom_fields';
    } else if (field.startsWith('social.')) {
        if (operator === 'empty' || operator === 'not_empty') {
            return 'social_exist';
        }
        return 'social';
    }
    return field;
}

function checkValue(v, field, operator) {
    if (field.startsWith('custom_fields.')) {

        if (operator === 'empty' || operator === 'not_empty') {
            return `${field.split('_fields.')[1]}`;
        }

        if (typeof v === 'object') {
            // array
            if (Array.isArray(v)) {
                return v.map(a => {
                    return `${field.split('_fields.')[1]}=${a}`
                });
            } else {

                return v;
            }

        }
        return `${field.split('_fields.')[1]}=${v}`;
    }
    return v;
}

function getFinalJoin(index, join, f) {
    const len = f.length;

// there's a case where there's only
    if (len > 1 && index === 0 && f[1].join === 'or') {
        return 'or';
    }

    return join
}

function notYetWorking(ff, operator) {
    if (ff.startsWith('custom_fields.') && (operator === '>' || operator === '<' || operator === '>=' || operator === '<=')) {
        return true;
    }
    return false;
}

function buildFilters(view, defs, options, community) {
    const f = view.filters;

    let obj = {
        all: [],
        any: [],
        none: [],
    };

    if (!f) {
        return obj;
    }

    // only show deactivated if enabled or it's explicity listed by a filter
    if (!view.show_deactivated_members && f && f.filter(a => a.field === 'account_status').length === 0) {
        obj.none.push({'account_status': "archived"})
    }

    if (options && options.group_id) {
        obj.all.push({'groups': options.group_id})
    }

    f.forEach((fi, index) => {
        const {field, join, value, operator} = fi;
        const field_info = defs.filter_options[field];
        if (!field_info) {
            console.error('no field info for', field)
            return;
        }
        let filter_field;
        if (field.startsWith('custom_fields.') && field_info.type === 'number') {
            if (community.cf_number_primary && field.includes(community.cf_number_primary)) {
                filter_field = 'cf_number_primary';
            } else if (community.cf_number_secondary && field.includes(community.cf_number_secondary)) {
                filter_field = 'cf_number_secondary';
            }
        } else {
            filter_field = field;
        }

        const {type} = field_info;

        const is_valid = filterIsValid(fi, field_info);

        const operator_info = operators[field_info.type].filter(a => a.value === operator)[0];

        const {query_type} = operator_info;

        const final_join = getFinalJoin(index, join, f);

        if (is_valid) {
            // todo we have a grouping problem (a + (b+c) ) or (a+b) + c
            if (final_join === 'and') {
                if (filter_field.startsWith('custom_fields.') && operator === 'empty') {
                    obj.none.push({
                        ['custom_fields_exist']: filter_field.split('_fields.')[1]
                    });
                } else if (filter_field.startsWith('custom_fields.') && operator === 'not_empty') {
                    obj.all.push({
                        ['custom_fields_exist']: filter_field.split('_fields.')[1]
                    });
                } else if (filter_field.startsWith('social.') && operator === 'empty') {
                    obj.none.push({
                        ['social_exist']: filter_field.split('cial.')[1]
                    });
                } else if (filter_field.startsWith('social.') && operator === 'not_empty') {
                    obj.all.push({
                        ['social_exist']: filter_field.split('cial.')[1]
                    });
                } else if (query_type === 'none') {
                    obj.none.push({
                        [checkField(filter_field, operator)]: checkValue(buildValue(value, type, operator), filter_field, operator)
                    });
                } else {
                    obj.all.push({
                        [checkField(filter_field, operator)]: checkValue(buildValue(value, type, operator), filter_field, operator)
                    });
                }

            } else {
                obj.any.push({
                    [checkField(field, operator)]: checkValue(buildValue(value, type, operator), field, operator)
                });
            }
        }
    })

    return obj;
}

/*
[
    {
        "dir": "asc",
        "type": "text",
        "field": "about.last_name"
    },
    {
        "field": "stats.sessions",
        "dir": "asc"
    }
]
 */

const sort_field_map = {
    "about.first_name": "first_name",
    "last_sign_in": "last_sign_in",
    "profile_updated_at": "profile_updated_at",
    "stats.sessions": "sessions",
    "about.last_name": "last_name",
    "age": "age",

};

function buildSorts(view, defs) {
    const sort = view.sort;
    if (!sort) {
        return {
            last_name: "asc"
        }
    } else if (sort.length === 0) {
        return {
            last_name: "asc"
        }
    } else if (sort.length === 1) {
        return {
            [sort_field_map[sort[0].field]]: sort[0].dir
        }
    }
    return sort.map(s => {
        return {
            [sort_field_map[sort[0].field]]: sort[0].dir
        }
    })
}

function buildSearchFields(q) {
    if (!q) {
        return {
            name: {}
        }
    }
    return {
        name: {},
        email: {},
        first_name: {},
        last_name: {}
    };
}

function processResults(arr) {
    return arr.map(a => {
        return {
            ...a,
            id: a.id.split('-')[1]
        }
    })
}

export async function directoryMembersQuery(request, community_uid) {
    return await queryMembersData(request, community_uid)
        .then(resp => {
            console.log('directoryMembersQuery',resp)
            if (resp.results) {
                return {
                    members: [...processResults(resp.results)],
                    page: resp.page
                }
            } else {
                return null
            }
        });
}

function getDefaultSize(view) {
    const layout = view.layout;
    const height = document.body.clientHeight;
    if (layout === 'gallery') {
        return (Math.ceil(height / 280) + 1) * 6;
    }
    return Math.ceil(820 / 40 * 1.5);
}

export function buildElasticsearchQuery(view, squery = "", defs, page, options = {}, community) {
    let size = options.size || getDefaultSize(view);

    let request = {
        current: page,
        size: size,
        result_fields: {
            name: {raw: {}},
            account_status: {raw: {}},
            member_type: {raw: {}},
            profile_picture: {raw: {}},
            handle: {raw: {}},
        },
        search_fields: buildSearchFields(squery),
        sort: buildSorts(view, defs),
        query: squery,
        filters: buildFilters(view, defs, options, community),
    };

    return request;
}