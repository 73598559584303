import React, {useContext, useEffect, useState} from 'react';
import {CommunityContext} from "../../../app/community/community-context";
import {FirebaseDB} from "../../../config/setup-firestore";
import {InlineLoader} from "../core";
import {PollView} from "./view";

export function PollLiveWrapper({id}) {
    const community = useContext(CommunityContext);
    const [data,setData] = useState(null);

    useEffect(function () {
        let sub = () => {};

        // start without
        sub = FirebaseDB
            .collection('community_content')
            .doc(community.uid)
            .collection('polls')
            .doc(id)
            .onSnapshot(d=> {
                setData({...d.data()})
            })

        return () => {
            sub();
        };
    }, [id])

    if(!data) {
        return <PollView id={id} loading />
    }

    return <div>
        <PollView id={id} livedata={data} />
    </div>
}