import React, {useContext, useState} from 'react';
import Modal from "../../../../../../library/components/old/modal";
import {Button, Field, Input, TextArea} from "../../../../../../library/components/core";

import {CommunityContext} from "../../../../community-context";
import {GlobalContext} from "../../../../../global/global-context";
import {BasicSelect} from "../../../../../../library/components/core/basic-select";
import {PoliciesSelect} from "./index";
import {authFetch} from "../../../../../../config/network";

const mock = {
    internal_name: "",
    type: "",
};

const allowed_types = [
    {label: "Policy", value: "policy"},
    {label: "Opt-In", value: "opt_in"},
    {label: "Onboarding", value: "onboarding"},
];

export async function createGate(data, community) {
    return new Promise(resolve => {
        const res = (data) => {
            resolve(data)
        };

        const payload = {
            ...data,
            community_uid: community.uid,
            member_id: community.member_id,
            user_uid: community.member.user_uid
        };

        authFetch("/communities/gates/create", res, res, "POST", {payload});
    });
}

export async function updateGate(id, data, community) {
    return new Promise(resolve => {
        const res = (data) => {
            resolve(data)
        };

        const payload = {
            id,
            ...data,
            community_uid: community.uid,
            member_id: community.member_id,
            user_uid: community.member.user_uid
        };

        authFetch("/communities/gates/update", res, res, "POST", {payload});
    });
}

export async function deleteGate(id, community) {
    return new Promise(resolve => {
        const res = (data) => {
            resolve(data)
        };

        const payload = {
            id,
            community_uid: community.uid,
            member_id: community.member_id,
            user_uid: community.member.user_uid
        };

        authFetch("/communities/gates/delete", res, res, "POST", {payload});
    });
}

function gateIsValid(data) {
    // needs name and type

    if (!data?.internal_name?.length) {
        return false;
    }

    if (!data?.type?.length) {
        return false;
    }

    // if type = policy, needs policy data
    if (data?.type === 'policy') {
        if (!data?.data?.policy_ids?.length) {
            return false;
        }
    }

    return true;
}

export function CreateGateModal({init_data = {...mock}, id = "", type = "create", onClose, onConfirm}) {
    const community = useContext(CommunityContext);
    const global = useContext(GlobalContext);

    const [loading, setLoading] = useState('');
    const [data, setData] = useState({...init_data || {}});

    function handleCreate() {
        setLoading('adding-gate');
        if (type === 'create') {

            createGate(data, community)
                .then(() => {

                    global.addToast({text: "Gate added", intent: "success"});
                    onConfirm();
                    setLoading('');
                })

        } else {

            updateGate(id, data, community)
                .then(() => {
                    global.addToast({text: "Gate updated", intent: "success"});
                    onConfirm();
                    setLoading('');
                })
        }

    }

    function handleChange(key, value) {
        setData({...(data || {}), [key]: value});
    }

    /*
    Fields should be:
    - internal name which is a string and is required
    - type which is a string from a list of types and should be shown as a dropdown
     */


    const body = <div className="space-y-4">
        <Field label="Internal Name">
            <Input value={data?.internal_name} onChange={v => handleChange('internal_name', v)}/>
        </Field>

        <Field label="Type">
            <BasicSelect value={data?.type ? {
                label: allowed_types.filter(function (b) {
                    return b.value === data?.type;
                })[0].label || "Loading",
                value: data?.type
            } : null} clearable={false} onChange={(val) => {
                handleChange('type', val.value);
            }} options={allowed_types}/>
        </Field>

        <Field label="Status">
            <BasicSelect value={data?.status ? {
                label: data?.status === 'active' ? 'Active' : 'Inactive',
                value: data?.status
            } : null} clearable={false} onChange={(val) => {
                handleChange('status', val.value);
            }} options={[
                {label: 'Active', value: 'active'},
                {label: 'Inactive', value: 'inactive'},
            ]}/>
        </Field>

        {data?.type === 'policy' && <Field label="Policies Data">
            <PoliciesSelect value={data?.data?.policy_ids || []} onChange={parr => {
                console.log("parr", parr)
                handleChange('data', {
                    ...(data?.data || {}),
                    policy_ids: parr
                })
            }}/>
        </Field>}

        {data?.type === 'opt_in' && <Field label="Description">
            <TextArea value={data?.data?.description || ''} onChange={v => {
                handleChange('data', {
                    ...(data?.data || {}),
                    description: v
                })
            }}/>
        </Field>}
    </div>;

    const is_valid = gateIsValid(data);

    return <Modal title={type === 'create' ? "Create Gate" : "Edit Gate"} size='small' body={body}
                  footerRight={<div className="space-x-2">
                      <Button onClick={() => onClose()} text="Cancel"/>
                      <Button onClick={() => handleCreate()} loading={loading === 'adding-gate'} disabled={!is_valid}
                              text={type === 'create' ? "Create" : "Update"} intent="success"/>
                  </div>} footerLeft={null} onClose={() => onClose()} open={true} lazy/>
}