import React from "react";
import {CookiesText} from "./cookies-text";
import {
    AuthTitle,
    SplashContainer,
    SplashInner,
    SplashTextContainer
} from "../../layout/splash-container";

export function PoliciesCookies() {

    return <SplashContainer>
        <SplashInner>
            <SplashTextContainer>
                <AuthTitle>Cookie Policy</AuthTitle>
        <CookiesText />
            </SplashTextContainer>
        </SplashInner>
    </SplashContainer>;
}