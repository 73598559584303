import {Link} from "react-router-dom";
import React from "react";
import styled from "styled-components";
import {useIntl} from "react-intl";


const SectionFooter = styled.div`
display: grid;
grid-template-columns: 1fr auto;
    padding: 12px 16px;
`;

export function UnSectionFooter({children}) {
    return <SectionFooter>
        {children}
    </SectionFooter>;
}

const SectionHeader = styled.div`
border-bottom: 1px solid #EBF0F3;
    padding: 12px 8px;
    display: grid;
    grid-template-columns: 1fr auto;
`;

const SectionTitle = styled.div`
    color: #112D42;
    font-weight: 600;
    font-size: 18px;
`;

const SectionDesc = styled.div`
        color: #112D42;
    font-weight: 400;
    font-size: 13px;
`;

export function SGHeader({title, desc}) {
    return <SectionHeader>
        <div>
        <SectionTitle>
            {title}
        </SectionTitle>
        <SectionDesc>
            {desc}
        </SectionDesc>
        </div>
        <div>

        </div>
    </SectionHeader>;
}

const BodyBox = styled.div`
   padding: 4px 8px 4px 8px;
`;

export function SGBody({children}) {
    return <BodyBox>
        {children}
    </BodyBox>;
}

const SectionFrame = styled.div`
    
`;

export function SGSectionFrame({children}) {
    return <SectionFrame>
        {children}
    </SectionFrame>;
}

const LinkItem = styled.div`
    font-weight: 500;
    font-size: 14px;
    line-height: 1.4;
    margin-bottom: 4px;
    > a {
        
    }
    > a:hover {
        text-decoration: underline;
    }
`;

function SGLink({link,base_link}) {
    const {formatMessage: f} = useIntl();
    return <LinkItem>
        <Link className='text-gray-800' to={`${base_link}/${link.path}`}>
            {f({id:`preferences.${link.text}`})}
        </Link>
    </LinkItem>;
}

const Title = styled.div`
    font-weight: 600;
    font-size: 16px;
    margin-left: 6px;
`;

const IconBox = styled.div`
    
    > svg {
        height: 20px;
    }
`;

const Header = styled.div`
    
`;

const TitleRow = styled.div`
    display: flex;
`;

const Desc = styled.div`
    margin-top: 4px;
    margin-bottom: 4px;
`;

const Frame = styled.div`
    border-top: 1px solid #e4e4e7;
    padding: 14px 12px 16px 16px;
`;

const Links = styled.div`
    margin-top: 8px;
`;

export function SGSection({icon, title, desc, links, base_link}) {
    return <Frame>
        <Header>
            <TitleRow>
            <IconBox className='text-gray-700'>
                {icon}
            </IconBox>
            <Title className='text-gray-900'>
                {title}
            </Title>
            </TitleRow>
            <Desc className='text-gray-800'>
                {desc}
            </Desc>
        </Header>
        <Links>
            {links.map((link) => {
                return <SGLink base_link={base_link} key={link.path} link={link}/>;
            })}
        </Links>
    </Frame>;
}