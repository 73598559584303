import React, {useState} from 'react';
import {ChevronDownIcon, ChevronLeftIcon} from "@heroicons/react/solid";

function Node({
    label = "",
    path = "",
    data = {},
    hasCaret = false,
    isSelected = false,
    isExpanded = false,
                  isOpen = false,
    disabled = false,
                  onNodeClick =()=>{},
                  onNodeCollapse =()=>{},
                  onNodeExpand =()=>{},
    icon = null
              }) {
const depth = path.split('/').length-2;

    return <div className={`flex h-8 px-2 rounded ${disabled?"opacity-50 cursor-not-allowed":`cursor-pointer ${isSelected?"bg-selection":"hover:bg-gray-100"}`}`} style={{paddingLeft:`${0.75*depth}rem`}} onClick={(e)=> {
        e.stopPropagation();
        if(disabled) {
            return;
        }
        onNodeClick(data, path, e);
    }}>
        <div className={`w-8 flex-none flex items-center justify-center ${isSelected?"text-white":"text-gray-600"}`}>
            <div className="w-5 h-5">
                {icon}
            </div>
        </div>
        <div className={`flex-grow h-8 flex items-center font-sm font-medium ${isSelected?"text-white":"text-gray-800"}`}>
            {label}
        </div>
        <div className="flex-none w-6 h-8 flex items-center">
            {hasCaret&&!disabled&&<div className={`w-5 h-5 ${isSelected?"text-gray-200":"text-gray-600"}`} onClick={(e)=>{
                e.stopPropagation();
            if(disabled) {
                return;
            }
            if(isExpanded) {
                onNodeCollapse(data,path,e);
            } else {
                onNodeExpand(data,path,e);
            }
            }}>
                {isExpanded?<ChevronDownIcon />:<ChevronLeftIcon />}
            </div>}
        </div>
    </div>
}

function RenderNodes({nodes,onNodeExpand,onNodeClick,onNodeCollapse}) {
    return nodes.map((n,k)=>{
        return <div key={n.id}>
            <Node path={``} data={n} onNodeExpand={onNodeExpand} onNodeClick={onNodeClick} onNodeCollapse={onNodeCollapse} {...n} />
            {n.isExpanded&&n.childNodes&&<div className="space-y-0.5"><RenderNodes nodes={n.childNodes} onNodeExpand={onNodeExpand} onNodeClick={onNodeClick} onNodeCollapse={onNodeCollapse} /></div>}
        </div>
    })
}

export function Tree(props) {
    const {
        contents = [],
        onNodeClick =()=>{},
        onNodeCollapse =()=>{},
        onNodeExpand =()=>{},
    } = props;
    return <div className="space-y-0.5">
       <RenderNodes nodes={contents} onNodeExpand={onNodeExpand} onNodeClick={onNodeClick} onNodeCollapse={onNodeCollapse} />
    </div>
}