import React from 'react';
import {GlobalProvider} from "./global/global-provider";
import GlobalRouter from "./global/global-router";
import '../index.css';
import './app.css';
// todo can probably load this on demand
import 'moment/locale/de';
import 'draft-js/dist/Draft.css';
import {BrowserRouter as Router} from "react-router-dom";
import packageJson from '../../package.json';
import {externalAuthFetch} from "../config/network";
import {ErrorPage} from "./error";
import {ToastsProvider} from "./toasts/toasts-provider";
import {logError} from "../api/errors/log-error";

export class NewApp extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            error: {},
            maintenance_mode: false,
            hasError: false
        };
    }

    componentDidCatch(err, info) {
        // set the the hasError state to true so on the next render it will display the `<div>Error occured.</div>` in the DOM.
        console.error(err, info);
        const logged = JSON.stringify({err, info},undefined,2);
        logError(logged);
        this.setState({error: {info,err},hasError: true})
    }


    componentDidMount() {
        console.group('Unaty App Info');
        console.log('Version: ', packageJson.version);
        console.groupEnd();
    }

    render() {
        if (this.state.hasError) {
            // if the hasError state boolean is true, it returns this to tell the user an error has occurred
            if (document.getElementById("preloader")) {
                document.getElementById("preloader").remove();
            }
            return <ErrorPage error={this.state.error} />;
        }
/*
        if(this.state.maintenance_mode) {
            if (document.getElementById("preloader")) {
                document.getElementById("preloader").remove();
            }
            return <div className="p-10"><AuthContainer>
                <AuthTitle>
                    Maintenance Mode
                </AuthTitle>
                <div>
                    Unaty is undergoing maintenance <span onClick={()=>this.setState({maintenance_mode:false})}>for</span> a couple hours, check back in a few!
                </div>
            </AuthContainer></div>;
        }
 */

        return <ToastsProvider>
            <GlobalProvider key={'global-provider'}>
                <Router>
                    <GlobalRouter/>
                </Router>
            </GlobalProvider>
        </ToastsProvider>
    }
}