import React, {useState} from "react";
import {Button, Field, RichSelect} from "../core";
import moment from "moment";
import Modal from "../old/modal";

export const export_separator_ref = {
    comma: {
        separator: ',',
        default_region: 'usa'
    },
    semicolon: {
        separator: ';',
        default_region: 'deu'
    }
};

// https://gist.github.com/mlconnor/1887156
export const regional_date_formats = {
    fra: 'DD/MM/YYYY',
    gbr: 'DD/MM/YYYY',
    deu: 'DD.MM.YYYY',
    can: 'DD/MM/YYYY',
    ita: 'DD/MM/YYYY',
    usa: 'M/D/YYYY',
    swe: 'YYYY-MM-DD',
    esp: 'D/MM/YYYY',
};

const options = [
    {
        value: 'comma',
        label: "Comma-separated",
        desc: "US and International standard",
        emoji: "🌐"
    },
    {
        value: 'semicolon',
        label: "Semicolon-separated",
        desc: "European standard",
        emoji: "🇪🇺"
    }
];

const today = moment();

const date_format_options = [
    {
        value: 'usa',
        label: "United States",
        desc: `${regional_date_formats['usa']} (${today.format(regional_date_formats['usa'])})`,
        emoji: "🇺🇸"
    },
    {
        value: 'deu',
        label: "Germany",
        desc: `${regional_date_formats['deu']} (${today.format(regional_date_formats['deu'])})`,
        emoji: "🇩🇪"
    },
    {
        value: 'gbr',
        label: "United Kingdom",
        desc: `${regional_date_formats['gbr']} (${today.format(regional_date_formats['gbr'])})`,
        emoji: "🇬🇧"
    },
    {
        value: 'fra',
        label: "France",
        desc: `${regional_date_formats['fra']} (${today.format(regional_date_formats['fra'])})`,
        emoji: "🇫🇷"
    },
    {
        value: 'ita',
        label: "Italy",
        desc: `${regional_date_formats['ita']} (${today.format(regional_date_formats['ita'])})`,
        emoji: "🇮🇹"
    },
    {
        value: 'esp',
        label: "Spain",
        desc: `${regional_date_formats['esp']} (${today.format(regional_date_formats['esp'])})`,
        emoji: "🇪🇸"
    },
    {
        value: 'can',
        label: "Canada",
        desc: `${regional_date_formats['can']} (${today.format(regional_date_formats['can'])})`,
        emoji: "🇨🇦"
    },

    {
        value: 'swe',
        label: "Sweden",
        desc: `${regional_date_formats['swe']} (${today.format(regional_date_formats['swe'])})`,
        emoji: "🇸🇪"
    }
];

export function IntlExport({onClose=()=>{},handleContinue=()=>{}}) {

    const [delimiter,setDelimiter] = useState(options[0].value);
    const [date_format,setDateFormat] = useState(date_format_options[0].value);

    const body = <div className="space-y-4">
        <Field label="Separator Format" help_text="Data is split up through a delimiter like a comma or semicolon, and this can vary by region.">
        <RichSelect onSelect={(v)=> {
            setDelimiter(v);
            if(date_format===date_format_options[0].value) {
                if(v==='comma') {
                    setDateFormat('usa');
                } else if(v==='semicolon') {
                    setDateFormat('deu');
                }
            }
        }} options={options} selected={delimiter}/>
        </Field>
        <Field label="Date Format">
        <RichSelect onSelect={(v)=>setDateFormat(v)} options={date_format_options} selected={date_format}/>
        </Field>
    </div>;

    function proceed() {
        handleContinue({delimiter,date_format});
    }

    return <Modal size={'small'} open={true} onClose={onClose} title={'Export Members'}
                  body={body}
                  footerLeft={null}
                  footerRight={<div>
                     <Button text="Continue" intent="secondary" onClick={()=>proceed()} />
                  </div>}
    />
}