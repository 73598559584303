function levenstein(a, b) {
    var m = [], i, j, min = Math.min;

    if (!(a && b)) return (b || a).length;

    for (i = 0; i <= b.length; m[i] = [i++]) ;
    for (j = 0; j <= a.length; m[0][j] = j++) ;

    for (i = 1; i <= b.length; i++) {
        for (j = 1; j <= a.length; j++) {
            m[i][j] = b.charAt(i - 1) == a.charAt(j - 1)
                ? m[i - 1][j - 1]
                : m[i][j] = min(
                    m[i - 1][j - 1] + 1,
                    min(m[i][j - 1] + 1, m[i - 1][j] + 1))
        }
    }

    return m[b.length][a.length];
}

function fuzzyContains(a, b, error, score) {
    let matchLength = a.length - b.length;
    let distanceToMatch = levenstein(a, b) - matchLength;
    const final = distanceToMatch - error > 0;
    if (final) {
        return score ? distanceToMatch : false;
    } else {
        return score ? distanceToMatch : true;
    }
}

function testModeratorRolesNamesNoMatch(value, data, community) {
    const all_mod_role_ids = Object.keys(data.moderators.roles);
    const all_role_names = all_mod_role_ids.map(id => {
        return community.all_roles[id].name;
    })
    let match = false;

    all_role_names.forEach(name => {
        const score = levenstein(name, value);
        if (score < 2) {
            match = true;
        }
    })
    return !match;
}

function evaluateTrigger(t, data, data2, community) {
    const {type, value, modifier} = t;
    const hydrated_value = hydrateText(value, data, data2, community)
    if (type === 'name-contains') {
        return data.name.toLowerCase().includes(hydrated_value.toLowerCase());
    } else if (type === 'member-count-greaterthan') {
        return Object.keys(data.user_uids).length > parseInt(hydrated_value);
    } else if (type === 'member-count-lessthan') {
        return Object.keys(data.user_uids).length < parseInt(hydrated_value);
    } else if (type === 'moderator-roles-names-no-match') {
        return testModeratorRolesNamesNoMatch(hydrated_value, data, community);
    } else if (type === 'moderator-roles-count-greaterthan') {
        return Object.keys(data.moderators.roles).length > parseInt(hydrated_value);
    } else if (type === 'moderator-roles-count-lessthan') {
        return Object.keys(data.moderators.roles).length < parseInt(hydrated_value);
    } else {
        return false;
    }
}

function rl_shouldTriggerRule(triggers, data, data2, community) {
    let flag = true;
    triggers.forEach(t => {
        if (!evaluateTrigger(t, data, data2, community)) {
            if (flag) {
                flag = false;
            }
        }
    })
    if (!flag) {
        return false;
    }
    return triggers.length > 0;
}

function extractAllText(str) {
    return str.match(/\[\[(.*?)\]\]/g);
}

function applyHydration(str, ex, data) {
    const cleaned = ex.replace('[[', '').replace(']]', '');
    const split = cleaned.split('!!');
    const [type, value] = split;
    let t1, t2, t3, t4;

    if (type === 'name_after') {
        t1 = data.name.toLowerCase();
        t2 = t1.indexOf(value.toLowerCase());
        t3 = data.name.slice((t2 + value.length), data.name.length);
        t4 = str.replace(ex, '').trim();
        return `${t4} ${t3.trim()}`;
    } else if (type === 'name_before') {
        t1 = data.name.toLowerCase();
        t2 = t1.indexOf(value.toLowerCase());
        t3 = data.name.slice(0, t2);
        t4 = str.replace(ex, '').trim();
        return `${t3.trim()} ${t4.trim()}`;
    }
    return str;
}

function hydrateText(text, data, community) {
    const extractions = extractAllText(text);
    if (!extractions || !extractions.length) {
        return text;
    }
    let str = text;

    extractions.forEach(ex => {
        str = applyHydration(str, ex, data, community);
    })

    return str;
}

function alreadyExists(name, roles_obj, no_match = {}) {
    const roles_arr = Object.entries(roles_obj);

    let match = "";

    roles_arr.forEach(([id, data]) => {
        const score = levenstein(data.name, name);
        if (score < 2 && !no_match[id]) {
            match = id;
        }
    })

    if (match) {
        return match;
    }

    return false;
}

function rl_buildFinalSuggestion(suggestion, data, community) {
    const {type, f1, f2} = suggestion;
    if (type === 'create-role') {
        const name = hydrateText(f1, data, community);
        const already_exists = alreadyExists(name, community.all_roles, data.moderators.roles)
        if (already_exists) {
            return {
                type: 'add-role',
                id: already_exists
            };
        }
        return {
            type: 'create-role',
            name: name
        };
    } else if (type === 'add-role') {
        return {
            type: 'add-role',
            id: f1
        };
    } else {
        return null;
    }
}

function rl_hydrateSuggestions(suggestions, data, community) {
    let f = [];
    suggestions.forEach(sugg => {
        const final_sugg = rl_buildFinalSuggestion(sugg, data, community);
        if (final_sugg) {
            //
            f.push(final_sugg);
        }
    });
    return f;
}

function rl_groupSuggestions(suggs) {
    let final = [];

    suggs.forEach(suggestion => {
        const index = final.findIndex(a => a.type === suggestion.type);
        if (index !== -1) {
            // already exists
            final[index].items.push(suggestion)
        } else {
            // not yet
            final.push({
                type: suggestion.type,
                items: [suggestion]
            })
        }
    })

    return final;
}

export function rl_parseRelevantSuggestions(context, scope, r, data, data2, community) {
    const scoped_rules = r.filter(a => a.scope === scope && a.context === context);

    let suggestions = [];

    scoped_rules.forEach(sr => {
        const triggered = rl_shouldTriggerRule(sr.triggers, data, data2, community);
        if (triggered) {
            const final_suggestions = rl_hydrateSuggestions(sr.suggestions, data, community);
            suggestions = suggestions.concat(final_suggestions)
        }
    })

    return suggestions.length > 0 ? rl_groupSuggestions(suggestions) : null;
}