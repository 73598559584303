import React, {useEffect, useRef, useState} from "react";
import HandleSelector from "../../old/handle-selector";
import InputField from "../../old/input";

export function SimpleHandleSelector({
                                 community_uid = '',
    type = 'member',
                                         check_id = '',
                                         autoFocus = false,
    current_handle = '',
                                 onChangeHandle = ()=>{}
                               }) {

    const [new_handle,setNewHandle] = useState(current_handle);
    const [_checking_handle,setCheckingHandle] = useState(false);

    const isFirstRun = useRef(true);

    useEffect(function () {
        if (isFirstRun.current) {
            isFirstRun.current = false;
            return;
        }
        if(new_handle) {
            onChangeHandle(new_handle)
        }
    }, [new_handle]);

    const scope = 'community';
    const scope_id = community_uid;

    return <HandleSelector checkingHandle={() => setCheckingHandle(true)} check_id={check_id}
                             default_text={current_handle} current_handle={current_handle}
                             setHandle={(h) => {
                                 setNewHandle(h);
                                 setCheckingHandle(false);
                             }} type={type} autoFocus={false}
                             scope={scope} scope_id={scope_id}/>
}