import React, {useState} from 'react';
import {GroupInboxWrapper} from "./wrapper";
import Composer from "../../../../library/components/composer";

export function GroupInbox({is_member,is_moderator,_actions={},folder_id,is_admin,can_send,email_list_power_up,data,id}) {
    const [show_composer,setShowComposer] = useState(true);

    function handleClose() {
        setShowComposer(false);

        setTimeout(function () {
            setShowComposer(true);
        }, 50)
    }

    let composer;

    const email = email_list_power_up ? email_list_power_up.config.email_address : "";

    const to = [{
        count: Object.keys(data.user_uids).length,
        email: email,
        emoji: data.emoji,
        handle: data.handle,
        image: "",
        isDisabled: false,
        label: data.name,
        name: data.name,
        value: id,
        _type: "groups"
    }];

    composer = show_composer && <Composer handleClose={handleClose} message_type="group" layout="group" prefill={{folder_id,group_id:id,to:to,subject:'',mode:'share'}} to={to} folder_id={folder_id} email={email} data={data} id={id} />

    const can_view_messages = (is_member||(data.closed===false&&data.type==='standard'));

    return <div className="pb-24">
        {can_view_messages&&<GroupInboxWrapper _actions={_actions} is_moderator={is_moderator} is_admin={is_admin} is_member={is_member} group={data} group_id={id} composer={composer} />}
        {!can_view_messages&&<div className="p-4 rounded-md text-gray-600 text-sm text-center">
            Only group members can view messages
        </div>}
    </div>
}