import React, {useContext, useEffect, useRef, useState} from "react";
import {USSearchBar} from "./search-bar";
import {DEFAULT_USER_PHOTO} from "../../../config/defaults";
import {CogIcon, ColorSwatchIcon, LightningBoltIcon, SearchIcon, TagIcon} from "@heroicons/react/solid";
import {USItem, USPopupContainer, USResultsSection} from "./components";
import {us_addRecentSearch, us_clearRecentSearches} from "./recent-searches";
import {us_buildResults} from "./build-results";
import {Popover2} from "@blueprintjs/popover2";
import {us_asyncSearch, us_getAsyncSearches} from "./async-search";
import {InlineLoader} from "../core";
import {useHistory} from "react-router-dom";
import {GlobalContext} from "../../../app/global/global-context";

export function us_getResultsLength(arr) {
    let count = 0
    for(let i=0;i<arr.length;i++) {
        count += arr[i].items.length;
    }
    return count;
}

function buildKeyObj(base={},arr,field) {
    let obj = {...base};

    for(let i=0;i<arr.length;i++) {
        const item = arr[i];
        obj[item.id] = item;
        if(field) {
            obj[item.id]['match_str'] = item[field];
        }
    }

    return obj;
}

export function us_handleAsyncItemsUpdate(current, {type,items}, field) {
    let new_items = {...current};

    if(!new_items[type]) {
        new_items[type] = {};
    }

    new_items[type] = buildKeyObj(new_items[type], items, field);

    return new_items;
}

export function us_getSelectedItem(index,sections) {
    for(let i=0;i<sections.length;i++) {
        const sec = sections[i];
        for(let k=0;k<sec.items.length;k++) {
            const it = sec.items[k];
            if(it.id===index) {
                return it;
            }
        }
    }
    return null;
}

// ['admin','owner','member']
export function UniversalSearch({
    search_ctx = ['user'],
    ctx_id = 'global',
    theme,
                                    space_name,
                                    qs_results = {},
    click_ctx = {
        user_handle: '',
        community_handle: '',
        member_handle: ''
    }
                                }) {
    const global = useContext(GlobalContext);
    const history = useHistory();
    const [query,setQuery] = useState('');
    const [completed_queries,setCompletedQueries] = useState({});
    const [items,setItems] = useState({});
    const [index,setIndex] = useState(null);
    const [open,setOpen] = useState(false);
    const [hide_recent,setHideRecent] = useState(false);

    useEffect(function () {
        if(!open&&query) {
            global.closeSearch();
            resetQuery();
        }
    }, [open,query]);

    useEffect(function () {
        if(!open&&global.open_search&&!query) {
            setOpen(true);
        }
    }, [global.open_search,open,query]);

    const results = us_buildResults(query,search_ctx,qs_results,items,ctx_id);

    const results_length = us_getResultsLength(results);

    const is_searching = Object.values(completed_queries).filter(a=>a==='loading').length>0;

    const selected_item = index!==null ? us_getSelectedItem(index,results) : null;

    const value = useRef();
    const cq_value = useRef();

    useEffect(function () {
        // autoselect the most relevant result
        if(query.length>2&&index===null) {
            setIndex(0);
        }
    }, [query,index]);

    useEffect(function () {
        cq_value.current = completed_queries;
    }, [completed_queries]);

    useEffect(function () {
        value.current = items;
    }, [items]);

    useEffect(function () {
        const len = results_length;
        let new_index = index;
        if(index>(len-1)) {
            new_index = 0;
            setIndex(new_index);
        } else if(index<0) {
            new_index = len-1;
            setIndex(new_index);
        }
    }, [query,index])

    function handleIndexMove(int) {
        let new_index;

        const len = results_length;
        // len
        if(index===null) {
            new_index = int>0?0:len-1;
        } else {
            new_index = index+int;
        }
        if(new_index>(len-1)) {
            new_index = 0;
        } else if(new_index<0) {
            new_index = len-1;
        }
        setIndex(new_index);
    }

    function handleEnter() {
        if(query) {
            us_addRecentSearch(query,ctx_id);
        }

        if(selected_item&&selected_item.data_type==='recent_search') {
           // we don't want to do this here
            setIndex(null);
        } else {
            setOpen(false);
            global.closeSearch();
        }

        if(selected_item&&!selected_item.disabled&&selected_item.onClick) {
            selected_item.onClick(history,click_ctx,{
                setQuery: (str) => setQuery(str)
            });
        }
    }

    function resetQuery() {
        setQuery('');
        setIndex(null);
    }

    useEffect(function () {
        if(query.length>1) {
            const searches = us_getAsyncSearches(query.toLowerCase(),search_ctx);
            // should be one or multiple searches?
            if(!searches.async) {
                return;
            }

            for(let i=0;i<searches.queries.length;i++) {
                const sq = searches.queries[i];
                const search_value = sq.value?sq.value:searches.final_query;
                const fq_value = searches.final_query;
                const query_id = [`${sq.type}-${fq_value}`];

                if(!cq_value.current[query_id]&&fq_value) {
                    setCompletedQueries({...cq_value.current,[`${sq.type}-${fq_value}`]: 'loading'});
                    us_asyncSearch(search_value,ctx_id,sq,searches.final_query)
                        .then(resp=>{

                            let new_items = us_handleAsyncItemsUpdate(value.current,resp);
                            setCompletedQueries({...cq_value.current,[`${resp.type}-${fq_value}`]: 'complete'})
                            setItems(new_items)
                        });
                }
            }
        }
    }, [query]);

    const trigger = <USSearchBar border_color={theme.bg_border} space_name={space_name} open_search={global.open_search} onBlur={()=>{
        if(!query&&open) {
            setOpen(false);
        }
    }} open={open} selected_item={selected_item} query={query} onFocus={()=>{
        if(!open) {
            setOpen(true);
        }
    }} onEscape={()=>{
        setOpen(false);
    }} onChange={q=>setQuery(q)} onEnter={handleEnter} onArrowKey={handleIndexMove} />;

    if(!open) {
        return trigger;
    }

    const content = <USPopupContainer>
        {results.map(sec=><USResultsSection right_action={sec.id==='recent-searches'?{
            onClick: () => {
                us_clearRecentSearches(ctx_id);
                setHideRecent(true);
            },
            label: 'clear'
        }:null} title={sec.title} key={sec.id}>
            {sec.items.map(it=><USItem selected={it.id===index} data={it} key={`${sec.id}-${it.id}-${it.data_id}`} query={query} {...it} onClick={()=>{
                if(it.onClick) {
                    it.onClick(history,click_ctx, {
                        setQuery: (str) => setQuery(str)
                    });
                    if(it&&it.data_type==='recent_search') {
                        setIndex(null);
                    } else {
                        global.closeSearch();
                        setOpen(false);
                    }
                }
            }} />)}
        </USResultsSection>)}
        {results.length===0&&!is_searching&&<div className="text-sm text-gray-600 px-3.5 py-3">
            No results found
        </div>}
        {is_searching&&results.length===0&&<div>
            <InlineLoader padding="p-3" mini />
        </div>}
    </USPopupContainer>;

    return <Popover2 portalClassName="z-999" className="w-full" onInteraction={(nextOpenState)=>{
        if(open&&!nextOpenState&&!query) {
            global.closeSearch();
            setOpen(false);
        }
    }} autoFocus={false} onClose={()=>{}} enforceFocus={false} minimal content={content} isOpen={open} fill usePortal placement="bottom-start" style={{maxHeight:'500px'}}>
        {trigger}
    </Popover2>
}