import {authFetch} from "../config/network";

export function logEngagement(event_name,ctx) {
    const payload = {
        ...ctx,
        event_name
    };

    const res = () => {};

    authFetch("/log-engagement", res, res, "POST", {payload});
}