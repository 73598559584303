import {activity_types, activity_types_ref} from "./types";
import React from "react";
import {countries} from "../../data/general/countries";
import {languages_object} from "../../data/general/languages";
import {getMemberJoinDate} from "../reorganize/member-join-date";
import {displayBday} from "../old/edit-fields-section/fields";
import {getPhoneNumberData} from "../../utilities/phone";
import {buildAddress} from "../../utilities/shared";
import {Link} from "react-router-dom";

const positive = /\+\+(.*)\+\+/gim;
const disabled = /--(.*)--/gim;
const bold = /\*\*(.*)\*\*/gim;
const italics = /\*(.*)\*/gim
const link = /\[(.*?)\]\((.*?)\)/gim;

export function getValidPhoneStr(val) {
    if(!val) {
        return '';
    }
    const v = getPhoneNumberData(val);

    if(v.valid) {
        return v.international;
    }
    return val;
}

export function hydrateDynamicText(m, data) {
    m = m.replace(`{member_name}`, data.member_name);
    m = m.replace(`{id}`, data.id);
    m = m.replace(`{phone}`, getValidPhoneStr(data.phone));
    m = m.replace(`{admin_name}`, data.admin_name);
    m = m.replace(`{name}`, data.name);
    m = m.replace(`{label}`, data.label);
    m = m.replace(`{old}`, data.old);
    m = m.replace(`{new}`, data.new);
    m = m.replace(`{join_date}`, getMemberJoinDate(data.join_date));
    m = m.replace(`{birthday}`, displayBday(data.birthday));
    m = m.replace(`{field}`, data.field);
    m = m.replace(`{address}`, buildAddress(data.address));
    m = m.replace(`{email}`, data.email);
    m = m.replace(`{timezone}`, data.timezone);
    m = m.replace(`{language}`, languages_object[data.language]);
    m = m.replace(`{old_handle}`, data.old_handle);
    m = m.replace(`{handle}`, data.handle);
    m = m.replace(`{meta}`, data.meta);
    m = m.replace(`{group_email}`, data.group_email);
    m = m.replace(`{group_name}`, data.group_name);
    m = m.replace(`{policy_version}`, data.policy_version);
    m = m.replace(`{policy_name}`, data.policy_name);
    m = m.replace(`{moved_to_city}`, data.moved_to_city);
    m = m.replace(`{moved_from_city}`, data.moved_from_city);
    m = m.replace(`{moved_from_country}`, countries[data.moved_from_country]);
    m = m.replace(`{moved_to_country}`, countries[data.moved_to_country]);
    return m
        .replace(bold, '##bold:$1##')
        .replace(positive, '##positive:$1##')
        .replace(disabled, '##disabled:$1##')
        .replace(italics, '##italic:$1##')
        .replace(link, '##link:$1data:$2##')
        .trim();
}

const unaty_links = [
  'group',
  'role',
  'member',
  'post',
    'team'
];

const unaty_links_ref = {
  'group': {
      link: (ch,id) => {
          return `/${ch}/group/${id}`
      }
  },
    'role': {
        link: (ch,id) => {
            return `/${ch}/role/${id}`
        }
    },
    'member': {
        link: (ch,id) => {
            return `/${ch}/member-id/${id}`
        }
    },
    'post': {
        link: (ch,id) => {
            return `/${ch}/post/${id}`
        }
    },
    'team': {
        link: (ch,id) => {
            return `/${ch}/group/${id}`
        }
    }
};

export function renderDynamicText(f_arr,styles={size:'4'},ch) {
    return <div style={{lineHeight:'1.15rem'}} className={`text-gray-600 pt-0.5 ${styles.size==='4'?"-mt-0.5":""}`}>
        {f_arr.map((it, k) => {
            if (it.startsWith('bold:')) {
                return <span className={`text-gray-800 font-medium`} key={k}>
                {it.replace('bold:', '')}
            </span>;
            } else if (it.startsWith('italic:')) {
                return <span className={`text-gray-600 italic`} key={k}>
                {it.replace('italic:', '')}
            </span>;
            } else if (it.startsWith('positive:')) {
                return <span className={`text-success-800 py-0.5 -my-0.5 px-0.5 rounded bg-success-50`} key={k}>
                {it.replace('positive:', '')}
            </span>;
            } else if (it.startsWith('disabled:')) {
                return <span className={`text-gray-400 py-0.5 line-through -my-0.5 px-0.5 rounded bg-gray-100`} key={k}>
                {it.replace('disabled:', '')}
            </span>;
            } else if (it.startsWith('link:')) {
                const sp = it.replace('link:', '').split('data:');
                const sp1_split = sp[1].split('=');
                const t = sp1_split[0];
                if(ch&&unaty_links.includes(t)) {
                    return <Link className={`text-link font-medium cursor-pointer`} key={k} to={unaty_links_ref[t].link(ch,sp1_split[1])}>
                        {sp[0]}
                    </Link>
                }
                return <span className={`text-link font-medium cursor-pointer`} key={k}>
                    {sp[0]}
            </span>;
            } else {
                return <span className={``} key={k}>
                {it}
            </span>
            }
        })}
    </div>
}

export function AdminActivityItem({connector = 'none', date_formatted, show_time=false, type, ts, data, context}) {
    const activity_info = activity_types[type];
    if(!activity_info) {
        console.error('missing info',type)
        return null;
    }
    const styles = activity_types_ref[activity_info.type];
    const final = hydrateDynamicText(activity_info.message, data);
    const f_arr = final.split(/##/g);
    return <div className="relative px-1 py-1">
        <div className={`flex space-x-3 z-10`}>
        <div className={`h-8 w-6 flex justify-center z-10 ${styles.size==='4'?"-mt-0.5":""}`}>
            <div className={`w-${parseInt(styles.size) + 1} h-${parseInt(styles.size) + 1} ${styles.size==='4'?"mt-0.5":""} flex justify-center bg-white`}>
            <div
                className={`w-${styles.size} h-${styles.size} flex items-center justify-center text-white rounded-full ${styles.color}`}>
                <div className={`w-${parseInt(styles.size) - 1.5} h-${parseInt(styles.size) - 1.5}`}>
                    {activity_info.icon}
                </div>
            </div>
            </div>
        </div>
        <div className="text-sm">
            {renderDynamicText(f_arr,styles)}
            {show_time&&<div className="text-xs pt-0.5 pb-0.5 text-gray-500">
                {date_formatted}
            </div>}
        </div>
        </div>
        {connector==='line'&&<div className="absolute top-1.5 bottom-0 left-1.5 justify-center flex w-5 ">
<div className="w-0.5 bg-gray-400 h-full" />
        </div>}
    </div>
}