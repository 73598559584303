export const MemberTypePermissionPresets = {
    'internal': {
        value: 'internal',
        title: 'Internal Member',
        permissions: {
            view_members: true,
            view_files: true,
            view_lists: true,
            login: true
        }
    },
    'external': {
        value: 'external',
        title: 'External Guest',
        permissions: {
            login: true
        }
    },
    'custom': {
        value: 'custom',
        title: 'Custom',
        permissions: {
            view_members: true,
            view_files: true,
            login: true
        }
    }
};

function transformToArr() {
    let arr = [];
    
    let values = Object.values(MemberTypePermissionPresets);
    
    for(let i=0;i<values.length;i++) {
        const val = values[i];
        arr.push({
            label: val.title,
            value: val.value
        })
    }
    
    return arr;
}

export const MemberTypePermissionsArr = transformToArr();