import firebase from "firebase/app";
import 'firebase/firestore';
// local only
if (window.location.hostname === "localhost") {
    /*
    db.settings({
        host: "localhost:8080",
        ssl: false
    });

     */
}


export const Firestore = firebase.firestore;
export const FirebaseDB = firebase.firestore();