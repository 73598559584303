import React, {useState} from 'react';

import Modal from "../../components/old/modal";
import {Avatar, Button, InfoState, Tabs} from "../../components/core";
import {DEFAULT_USER_PHOTO} from "../../../config/defaults";
import styled from "styled-components";
import {EntityInfo} from "../../utilities/localstorage/entity-localstorage";

const OverflowBox = styled.div`

`;

export function GeneralDetailItem({title,subtitle,image,action}) {
    return <div className="grid hover:bg-gray-100 transition-colors px-1 py-1 gap-2" style={{gridTemplateColumns:'2.5rem 1fr auto'}}>
        <div className="flex-none p-px" style={{height:'34px'}}>
            <Avatar size="md" url={image} />
        </div>
        <div className="flex-grow">
            <div className="font-semibold text-smbase pt-0.5 text-gray-800">{title}</div>
            <div className="text-sm text-gray-500 line-clamp-1">{subtitle}</div>
        </div>
        <div className="flex-none">
            {action}
        </div>
    </div>
}

function DetailList({children}) {

    return <div className="pt-2 pb-2 px-2.5 space-y-1">
        {children}
    </div>
}

function DataWrapper({data,id,fn}) {
    return fn(data,id)
}

function renderTabContent(tab_data) {
    if(tab_data&&tab_data.type==="member-list") {
        return <div className="overflow-y-auto " style={{maxHeight:'75vh'}}>
            <DetailList>
                {tab_data.ids.map((id,k)=><div key={`member-${id}-${k}`}>
                    <EntityInfo community_uid={tab_data.community_uid} id={id} type={'members'}>
                        <DataWrapper fn={tab_data.renderer} id={id} />
                    </EntityInfo>
                </div>)}
                {tab_data.ids.length===0&&<div>
                    <InfoState subtitle="No results" fill />
                </div>}
            </DetailList>
        </div>
    } else if(tab_data&&tab_data.type==="custom") {
        return <div>
            {tab_data.component}
        </div>
    }
    return <div>
        {tab_data&&tab_data.component}
    </div>
}

export function DetailModal({title="Test",padded_header=false,min_height='160px',size="base",tabs=[],start_tab=0,onClose=()=>{},actions=null}) {
const [t,setT] = useState(tabs[start_tab]?tabs[start_tab].label:"");
    let ftabs = <Tabs onSelect={nt=>setT(nt)} compact layout="medium-bottom" active={t} tabs={tabs.map(t=>t.label)} getSublabel={t=>tabs.filter(a=>a.label===t)[0].label_count} />
const show_tabs = tabs && tabs.length>1;
    const selected_tab = tabs.length===0?[]:tabs.filter(a=>a.label===t)[0];

    let body = <div style={{minHeight:min_height}}>
        {actions&&<div className={`-mt-1`}>
            {actions}
        </div>}
        {show_tabs&&<div className="px-4 -mt-1 shared-bottom-border border-b">
            <div className="-mb-px">
            {ftabs}
            </div>
        </div>}
        <OverflowBox className={` ${show_tabs?"":"border-t shared-top-border"}`}>
            {renderTabContent(selected_tab)}
        </OverflowBox>
    </div>;

    return <Modal rounded_bottom padded_header={padded_header} title={title} size={size} body={body} dividing_margin={false} has_padding={false} footerRight={null} footerLeft={null}
                 onClose={onClose} open={true} lazy/>
}