import React, {useContext, useState} from 'react';
import Modal from "../../../../library/components/old/modal";
import {ActionWithIcon, Button, Field} from "../../../../library/components/core";
import {PlusIcon, TrashIcon} from "@heroicons/react/solid";
import {DateRange} from "../../../../library/components/reorganize/date-range";
import {getInputByType} from "../../../../library/components/old/field";
import {CommunityContext} from "../../community-context";
import {authFetch} from "../../../../config/network";

const mock = {
    member_id: '',
    from_year: null,
    from_month: null,
    to_year: null,
    to_month: null,
};

//
function PastAssigneeEdit({onChange=()=>{},community,range}) {

    function handleUpdate(f,v) {
        let ed = {...range};
        if(f==='range') {
            const final = {...ed,...v};
            onChange({...final})
        } else if(f==='org_data') {
            ed[f]['title'] = v;
            onChange({...ed});
        } else {
            ed[f] = v;
            onChange(ed);
        }
    }

    return <div className="space-y-2">
<div>
    <Field label="Past Assignee" corner_hint={<ActionWithIcon size="xs"
                                                              onClick={() => handleUpdate('member_id', community.member_id)}
                                                              text={"Assign to me"}/>} >
        {getInputByType('member', (v)=>{
            handleUpdate('member_id', v);
        }, () => {}, range.member_id, {meta: {
            community_uid: community.uid
            }})}
    </Field>
    </div>
        <div>
            <DateRange onChange={(v)=>handleUpdate('range',v)} range={{
                current: false,
                ...range
            }} enable_current_check={false} labels={{start: 'Start date', end: 'End Date'}}
                       current_check_text={`I'm currently working in this role`}
                       type='month-year'/>
        </div>
    </div>
}

export function PastAssigneesModal({
                                       init_data = {...mock},
    endpoint = '/',
                                       delete_endpoint = '/',
                                       action = 'create',
                                       onClose=()=>{},
    onSuccess=()=>{},
    onDelete=()=>{},
                                       api_props = {}
                                   }) {
    const [data,setData] = useState(init_data);
    const community = useContext(CommunityContext);
    const [loading,setLoading] = useState('');

    function onSave() {
        if(loading) {
            return;
        }
        const payload = {...data,...api_props};
        setLoading('api');
        const res = (resp) => {
            onSuccess();
            setLoading('');
        };

        authFetch(endpoint, res, res, "POST", {payload});
    }

    function handleDelete() {
        setLoading('delete');
        if(loading) {
            return;
        }
        const payload = {...data,...api_props};

        const res = (resp) => {
            onDelete();
            setLoading('');
        };

        authFetch(delete_endpoint, res, res, "POST", {payload});
    }

    const enabled = data.from_month!==null&&data.from_year!==null&&data.to_month!==null&&data.to_year!==null&&data.member_id;

    return <Modal title={`${action==='edit'?'Edit past assignee':'Add past assignee'}`} size='base' body={<PastAssigneeEdit community={community} range={data} onChange={(nd)=> {
        setData({...nd})
    }} />}
                  footerRight={<div>
                      <Button onClick={onSave} loading={loading==='api'} disabled={!enabled||loading==='api'} text={action==='edit'?"Save":"Add"} intent="secondary" />
                  </div>}
                  footerLeft={<div>
                      {action==='edit'&&<Button onClick={handleDelete} disabled={loading==='delete'} text="Delete" icon={<TrashIcon />} />}
                  </div>}
                  onClose={loading?()=>{}:onClose} open={true}
    />
}