import React, {Component} from "react";
import styled from "styled-components";
import {Toggle} from "../../core";


const Container = styled.div`
    margin-top: ${props=>props.top_margin?"16px":""};
    display: grid;
    grid-template-columns: 30px 1fr;
`;

const CheckboxCont = styled.div`
margin-left: 4px;
    margin-top: 1px;
`;

export default class CustomCheckbox extends Component {
    constructor(props) {
        super(props);

        this.state = {};
    }

    render() {
        const {checked, content = null, top_margin=true, toggleCheck} = this.props;

        return (
            <Container top_margin={top_margin}>
                <CheckboxCont>
                    <Toggle checked={checked} onToggle={toggleCheck}/>
                </CheckboxCont>
                <div className="text-sm pt-0.5 mt-px text-gray-700">
                    {content}
                </div>
            </Container>
        );
    }
}