import React, {useContext, useEffect, useState} from 'react';
import {FirebaseDB} from "../../../../../config/setup-firestore";
import {CommunityContext} from "../../../community-context";

function Applicant({}) {
    return <div className="flex">
        <div>
            img
        </div>
        <div>
            <div>name</div>
            <div>email</div>
        </div>
        <div>
            right arrow
        </div>
    </div>
}

export function NewApplicantsWidget(props) {
    const [loading,setLoading] = useState('');
    const community = useContext(CommunityContext);
    const [applicants,setApplicants] = useState(null);

    useEffect(function () {
        if(!loading&&!applicants) {
            getApplicants();
        }
    }, [loading,applicants]);

    function getApplicants() {
        setLoading('applicants');

        FirebaseDB
            .collection('community_members')
            .doc(community.uid)
            .collection('applications')
            .where('status', '==', 'pending')
            .limit(6)
            .get()
            .then(s=>{
                setApplicants(s.docs.map(d=>{
                    return {
                        id: d.id,
                        ...d.data()
                    }
                }));
                setLoading('done')
            })
    }

    if(!applicants||applicants.length===0) {
        return null;
    }

    // todo
    return null;
}