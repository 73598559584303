import React from 'react';
import {Avatar, Badge} from "../../../components/core";
import {AT_DF_AccountStatus} from "./account-status";
import {displayBday} from "../../../components/old/edit-fields-section/fields";
import moment from "moment";
import {isAnonymousUnatyEmail} from "../../../utilities/anonymous-emails";
import {aa_formatDate} from "../../../utilities/general/dates";
import {fv_getFormattedVal} from "../../../utilities/format-values";
import ImageUploader from "../../../components/old/image-uploader";
import {getMemberJoinDate} from "../../../components/reorganize/member-join-date";
import {cleanSocialForProfile, cleanWebsiteForProfile} from "../../../utilities/shared";
import {EntityInfo} from "../../../utilities/localstorage/entity-localstorage";
import {convertToObj} from "../../../components/old/person-profile";

const general_text_classes = "truncate text-sm text-gray-700";
const disabled_text_classes = "truncate text-sm text-gray-400";

function getOriginalValue(data, field, backup) {
    const v = data[field];
    return v ? v : backup;
}

/*
<ImageUploader wrapper={false} child_styles={{height:'28px'}} hover title={"Change Profile Picture"} type={"profile-picture"}
                                    handleUrl={(url, color) => {
                                       meta.changeImageFn(row.original.id,url,color);
                                    }}>
                    <Avatar onClick={(e)=>{
                        e.preventDefault();
                    }} size="28px"
                            url={`${getOriginalValue(row.original, column.options.image, column.options.default_profile_picture)}_small?alt=media`}/>
                </ImageUploader>
 */

function RenderName({data}) {
    return <span className="truncate">{data.name}</span>;
}

function RenderMember({data}) {
    return <div className="inline-flex items-center tb-entity-value">
        <div className={"-ml-0.5 flex"} style={{height:'18px'}}>
        <Avatar size="18px" url={data.image?`${data.image}_small?alt=media`:""} text={data.name} />
        </div>
        <span className="text-xssm truncate pl-1 text-gray-700">
        {data.name}
            </span>
    </div>;
}

function MultiValueContainer({children}) {
    return <div className="inline-block overflow-x-auto no-scrollbars space-x-1.5">
        {children}
    </div>
}

function RenderGroup({data}) {
    return <div className="inline-flex items-center tb-entity-value">
        <span className="text-xssm truncate text-gray-700">
        {data.name}
            </span>
    </div>;
}

function RenderRole({data}) {
    return <div className="inline-flex items-center tb-entity-value">
        <span className="text-xssm truncate text-gray-700">
        {data.name}
            </span>
    </div>;
}

function RenderOrg({data}) {
    return <span>{data.name}</span>;
}

const debug = [];

export function at_formatRawValues(field_type, value, {column, row}, context = {}, meta = {}) {
    let f1, f2, f3, f4;
    if(debug.includes(field_type)) {
        console.log("++++")
        console.log("at_formatRawValues",field_type)
        console.log("value",value)
        console.log("column",column)
        console.log("++++")
    }
    switch (field_type) {
        case 'name':
            f1 = value ? value : "—";
            if (meta && meta.can_change_image) {
                f2 = <div style={{height:"26px"}} onClick={(e)=>{e.stopPropagation()}}>
                    <Avatar onClick={(e)=>{
                        e.preventDefault();
                    }} size="26px"
                            url={`${getOriginalValue(row.original, column.options.image, column.options.default_profile_picture)}_small?alt=media`}/>
                </div>;
            } else {
                f2 = <Avatar size="26px"
                             url={`${getOriginalValue(row.original, column.options.image, column.options.default_profile_picture)}_small?alt=media`}/>
            }

            return <div className="flex items-center">
                {column.options?.image && <div className={`h-8 w-8 mr-1.5 flex items-center justify-center`}>
                    {f2}
                </div>}
                <div style={{textUnderlineOffset: '0.1rem', textDecorationColor: "#82828d"}}
                     className={`line-clamp-1 cursor-pointer decoration-gray-300 underline-offset-2 underline hover:color-link text-sm font-semibold text-gray-800`}>
                    {f1}
                </div>
            </div>
        case 'tags':
            // <Badge color="blue" text="Hello" size="sm" />
            if (!value) {
                return <div/>
            }
            f1 = typeof value === 'string' ? 'single' : 'multi';
            f2 = f1 === 'single' ? [value] : value;

            return <div>
                {f2.map(a => {
                    const it = column.options.options.filter(b => {
                        return b.id === a;
                    })[0];
                    if (!it) {
                        return <Badge size="sm" color={"gray"} text={"--"} key={"iterror"}/>;
                    }
                    return <Badge size="sm" color={it.color} text={it.label} key={it.id}/>
                })}
            </div>
        case 'multi-member':
            return <div>

            </div>
        case 'member':
            f1 = !!value;
            if(!f1) {
                return <div>

                </div>
            }
            return <EntityInfo community_uid={context.community_uid} id={value} type={'members'}>
                <RenderMember/>
            </EntityInfo>;
        case 'mentor':
            f1 = !!value;
            if(!f1) {
                return <div />
            }
            return <EntityInfo community_uid={context.community_uid} id={value} type={'members'}>
                <RenderMember/>
            </EntityInfo>;
        case 'mentees':
            f1 = !!value;
            if(!f1) {
                return <div />
            }
            return <div>
                mentees
            </div>;
        case 'percent-1':
            f1 = !!value;
            f3 = f1 ? general_text_classes : disabled_text_classes;
            return <div className={`${f3}`}>
                {!value ? "—" : `${(parseFloat(value) * 100)}%`}
            </div>
        case 'timestamp-relative':
            f1 = !!value;
            f3 = f1 ? general_text_classes : disabled_text_classes;
            return <div className={`${f3}`}>
                {!value ? "" : aa_formatDate(value).relative}
            </div>
        case 'joined-date':
            f1 = getMemberJoinDate(value);
            f2 = f1 === 'Unknown' ? disabled_text_classes : general_text_classes;
            return <div className={`${f2}`}>
                {f1}
            </div>
        case 'account-status':
            return <AT_DF_AccountStatus value={value} member={row.original}/>
        case 'email':

            f1 = !!value;
            f2 = f1 ? value : "—";
            f3 = f1 ? `${general_text_classes} tb-handle-link` : disabled_text_classes;
            return <div className={`${f3}`}>
                {f2}
            </div>
        case 'account-email':
            if(value&&isAnonymousUnatyEmail(value)) {
                return <div className={`tb-select-warning text-sm truncate bg-yellow-100 text-yellow-700`}>
                    Anonymous
                </div>
            }
            f1 = !!value;
            f2 = f1 ? value : "—";
            f3 = f1 ? `${general_text_classes} tb-link-value` : disabled_text_classes;
            return <div className={`${f3}`}>
                {f2}
            </div>
        case 'phone':
            f1 = value ? fv_getFormattedVal('phone', value, context, {}) : "";
            f2 = value ? general_text_classes : disabled_text_classes;
            return <div className={`${f2} ${value?"tb-handle-link":""}`}>
                {f1}
            </div>
        case 'profile-picture':
            f1 = value ? value : column.options.default_profile_picture;
            return <div className={`h-8 w-8`}>
                <Avatar size="28px" url={`${f1}_small?alt=media`}/>
            </div>
        case 'birthday':
            f1 = displayBday(value, true, "");
            f2 = f1 !== "—" ? general_text_classes : disabled_text_classes;
            return <div className={`${f2}`}>
                {f1}
            </div>
        case 'date':
            f1 = value ? moment(value) : null;
            if (!f1 || !f1.isValid()) {
                return <div className={`${disabled_text_classes}`}>

                </div>
            } else {
                if(column.options&&column.options.options&&column.options.options.display) {
                    f2 = column.options.options.display;
                    if(f2==='relative') {
                        return <div className={`${general_text_classes}`}>
                            {f1.fromNow()}
                        </div>
                    } else if(f2==='absolute') {
                        return <div className={`${general_text_classes}`}>
                            {aa_formatDate(value).full_date}
                        </div>
                    }
                }
                return <div className={`${general_text_classes}`}>

                </div>
            }
        case 'multi-select':
            f1 = !!value;
            if(!f1) {
                return <div />
            }
            f2 = convertToObj(column.options.choices, true);
            f3 = Object.keys(value);
            if(f3.length===0) {
                return <div />
            }
            return <MultiValueContainer>
                {f3.map(k=><span key={k} className={`${general_text_classes} tb-select-value`}>{f2[k]}</span>)}
            </MultiValueContainer>
        case 'select-taxonomy':
            f1 = value && value.label ? value : "";
            f2 = value ? general_text_classes : disabled_text_classes;
            if(!f1) {
                return <div />
            }
            return <MultiValueContainer>
                <span className={`${f2} tb-select-value`}>{f1}</span>
            </MultiValueContainer>
        case 'multi-select-taxonomy':
            f1 = value && typeof value === 'object' && value.length ? value : "";
            if(!f1) {
                return <div />
            }
            f2 = general_text_classes;
            return <MultiValueContainer>
                {f1.map((it,i)=>{
                    return <span key={`${it.label}-${i}`} className={`${f2} tb-select-value`}>{it.label}</span>
                })}
            </MultiValueContainer>
        case 'select':
            f1 = value ? value : "";
            f2 = value ? general_text_classes : disabled_text_classes;
            if (f1&&column.options&&column.options.options) {
                f3 = column.options.options.filter(a => (a.id&&a.id === f1)||(a.value&&a.value === f1))[0];
            } else if (f1&&column.options&&column.options.choices) {
                f3 = column.options.choices.filter(a => (a.id&&a.id === f1)||(a.value&&a.value === f1))[0];
            }
            if (f3&&f3.label) {
                f4 = f3.label;
            } else if (f3&&f3.text) {
                f4 = f3.text;
            } else {
                f4 = "";
            }
            return <MultiValueContainer>
                <span className={`${f2} ${f4?"tb-select-value":""}`}>{f4}</span>
            </MultiValueContainer>
        case "handle":
            // also text
            f1 = value ? value : "—";
            f2 = !value ? disabled_text_classes : general_text_classes;
            return <div className={`${f2} tb-handle-link`}>
                @{f1}
            </div>
        case "text":
            // also text
            f1 = value ? value : "";
            f2 = !value ? disabled_text_classes : general_text_classes;
            return <div className={`${f2}`}>
                {f1}
            </div>
        case "url":
            // also text
            f1 = value ? cleanWebsiteForProfile(value) : "";
            f2 = !value ? disabled_text_classes : general_text_classes;
            return <div className={`${f2} ${f1?"tb-handle-link":""}`}>
                {f1}
            </div>
        case "social-url":
            // also text
            if(!value||!column.options.sub_type) {
                return "";
            }
            f1 = value ? cleanSocialForProfile(value,column.options.sub_type) : "";
            f2 = !value ? disabled_text_classes : general_text_classes;
            return <div className={`${f2} ${f1?"tb-handle-link":""}`}>
                {f1}
            </div>
        case "number":
            // also text
            f1 = value ? value : "";
            f2 = isNaN(value) ? disabled_text_classes : general_text_classes;
            return <div className={`${f2}`}>
                {f1}
            </div>
        case 'interests':
            // also text
            f1 = value ? value : "";
            f2 = !value ? disabled_text_classes : general_text_classes;
            if(typeof f1 !== 'string') {
                return <div className={`${f2}`}>
                   INTERESTS {JSON.stringify(f1)}
                </div>
            }
            return <div className={`${f2}`}>
                INTERESTS{f1}
            </div>
        case 'groups':
            // also text
            f1 = value ? value : "";
            f2 = f1 && typeof f1==='object' ? Object.keys(f1) : [];
            if(f2.length>0) {
                return <div className={`${f2} flex space-x-1.5 overflow-x-auto no-scrollbars`}>
                    {f2.map(group=>{
                        return <EntityInfo key={group} community_uid={context.community_uid} id={group} type={'groups'}>
                            <RenderGroup   />
                        </EntityInfo>
                    })}
                </div>
            }
            return <div className={``}>

            </div>
        case 'checkbox':
            f1 = typeof value === 'boolean';
            f2 = f1 ? `${value}` : "";
            f3 = f1 ? general_text_classes : disabled_text_classes;
            return <div className={`${f3} ${f1?"tb-select-value":""}`}>
                {f2}
            </div>
        case 'roles':
            // also text
            f1 = value ? value : "";
            f2 = f1 && typeof f1==='object' ? Object.keys(f1) : [];
            if(f2.length>0) {
                return <div className={`${f2} flex space-x-1.5 overflow-x-auto no-scrollbars`}>
                    {f2.map(group=>{
                        return <EntityInfo key={group} community_uid={context.community_uid} id={group} type={'roles'}>
                            <RenderRole   />
                        </EntityInfo>
                    })}
                </div>
            }
            return <div className={``}>

            </div>
        case 'id':
            // also text
            f1 = value ? value : "";
            f2 = !value ? disabled_text_classes : general_text_classes;
            if(typeof f1 !== 'string') {
                return <div className={`${f2}`}>
                    {JSON.stringify(f1)}
                </div>
            }
            return <div className={` font-mono truncate text-xs text-gray-600`}>
                {f1}
            </div>
        default:
            // also text
            f1 = value ? value : "";
            f2 = !value ? disabled_text_classes : general_text_classes;
            if(typeof f1 !== 'string') {
                return <div className={`${f2}`}>
                    {JSON.stringify(f1)}
                </div>
            }
            return <div className={`${f2}`}>
                {f1}
            </div>
    }
}