import React from 'react';
import {DRList} from "./layouts/list";
import {DRGallery} from "./layouts/gallery";
import {InfoState, InlineLoader} from "../../core";
import {SelectorIcon} from "@heroicons/react/outline";

export function NewDirectoryRenderer(props) {

    if(!props.loading&&props.ids.length===0) {
        return <div>
            <InfoState subtitle="No Members found, try another query" fill />
        </div>
    } else if(props.loading&&(!props.ids||props.ids.length===0)) {
        return <div>
            <InlineLoader padding="p-12" />
        </div>
    }

    if(props.view.layout === 'gallery') {
        return <DRGallery {...props} />
    } else {
        return <DRList {...props} />
    }
}