import React, {useState} from 'react';
import {Button, Field, Input} from "../../library/components/core";
import firebase from "firebase/app";

export function SecretSignIn(props) {
    const [token,setToken] = useState("");

    function login() {
        firebase
            .auth()
            .signInWithCustomToken(token)
    }

    return <div>
        Welcome to Secret Sign In
        <Field label="Login Token">
            <Input value={token} onChange={t=>setToken(t)} />
        </Field>
        <Button text="Sign in" disabled={!token} onClick={()=>login()} />
    </div>
}