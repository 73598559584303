import React, {useState} from 'react';
import {InboxPagination} from "../posts-feed/home-wrapper";
import {EntityInfo} from "../../utilities/localstorage/entity-localstorage";

function DemoItem({data}) {

    return <div>

    </div>
}

export function GeneralEntitiesList({
                                        ids = [],
                                        itemRenderer = <DemoItem/>,
                                        type = 'groups',
                                        list_class = '',
    label = 'Members',
                                        community_uid = "",
                                        per_page = 8,
                                        total = 0
                                    }) {
    const [page, setPage] = useState(0);

    const total_show = (page+1) * per_page;
    const start = page * per_page;

    const page_slice = ids.slice(0, total_show);

    return <div>
        <div className={`${list_class}`}>
            {page_slice.map(id => <EntityInfo extras={{mid:id}} key={`${type}-${id}`} community_uid={community_uid} id={id} type={type}>
                {itemRenderer}
            </EntityInfo>)}
        </div>
        {total > per_page && <div className="pt-1 flex justify-start">
            <InboxPagination per_page={per_page}
                                       total={total}
                             type={label}
                             loadMore={()=>{
                                 setPage(page+1)
                             }}
                                       start={total_show-per_page}
                                       onNextPage={() => {
                                           setPage(page + 1);
                                       }}
                                       onPrevPage={() => {
                                           setPage(page - 1);
                                       }}
            /></div>}
    </div>
}