import React, {useContext, useState} from "react";
import {PlusIcon} from '@heroicons/react/solid';
import {Button, Tabs, EntityHeader} from "../../../library/components/core";
import {setDocumentTitle} from "../../../library/utilities/set-document-title";
import {GlobalContext} from "../global-context";
import {PendingMemberships} from "./pending";
import {ActiveMemberships} from "./active";
import {useIsMobile} from "../global-router";
import {StandardPageHeight} from "../../../library/playground/standard-page-framework";

function sortMemberships(ids, data) {
    const arr = ids.map(id => {
        return {
            id,
            ts: data[id] ? data[id].last_sign_in : null
        }
    });
    return arr.sort(function (x, y) {
        return y.ts - x.ts;
    }).map(it => it.id);
}

export default function Communities(props) {

    const global = useContext(GlobalContext);
    const [tab, setTab] = useState("Active");
    const {memberships, member_type_data, member_ids, loading_communities, community_data, members_data} = global;

    const is_mobile = useIsMobile();

    setDocumentTitle('Memberships');

    function goToCreate() {
        props.history.push('/create-community');
    }

    const keys = [
        {
            id: 'N',
            keys: ["n"],
            fn: () => {
                goToCreate();
            }
        }
    ];

    const sorted = sortMemberships(memberships, members_data);

    const members_loaded = Object.keys(members_data).length;
    const memberships_len = memberships.length;
    let loading = loading_communities || (memberships_len !== members_loaded);

    // show tabs if the user has one or more pending applications
    const pending_applications = Object.entries(global.applications).filter(a => a[1] === 'pending').map(a => a[0]);

    let content;

    if (tab === 'Active') {
        content = <ActiveMemberships loading={loading}
                                     sorted={sorted}
                                     loading_communities={loading_communities}
                                     community_data={community_data}
                                     member_ids={member_ids}
                                     members_data={members_data}
                                     member_type_data={member_type_data}/>
    } else {
        content = <PendingMemberships applications={pending_applications}/>
    }
    if (is_mobile) {

        return <div className="mobile-page-height">
            <EntityHeader title="Memberships"/>
            <div className="">
                <div className="mobile-content-padding">
                    {pending_applications.length > 0 &&
                    <div className="pb-1"><Tabs active={tab} onSelect={(nl) => {
                        setTab(nl);
                    }} intent="secondary" tabs={["Active", "Pending"]} layout="pills-solid"/></div>}

                    {content}
                </div>
            </div>
        </div>
    }

    const action = <Button intent="secondary" minimal disabled={global.user.invites === 0}
                           onClick={goToCreate.bind(this)} size="sm" left_icon={<PlusIcon/>}
                           text='Create'/>

    return (<StandardPageHeight full content>
            <EntityHeader sticky onClick={() => {
                const el = document.getElementById("main-content");
                el.scrollTo(0, 0);
            }} title="Memberships" actions={action}/>
            <div className="px-4 pt-3 pb-6">
                {pending_applications.length > 0 &&
                <div className="pb-1"><Tabs active={tab} onSelect={(nl) => {
                    setTab(nl);
                }} intent="secondary" tabs={["Active", "Pending"]} layout="pills-solid"/></div>}

                {content}

            </div>
        </StandardPageHeight>
    );
}