import React from 'react';
import {Avatar} from "../../../core";
import {PhotoSelector} from "../../../old/photo-selector";
import {fv_getFormattedVal} from "../../../../utilities/format-values";

export function HWCSideId({data,id,goToProfile,goToSide,member_type,meta,setProfilePic,profile_pic}) {

    return <div className="w-full h-full flex flex-col">
        <div className="flex pt-4 px-3">
            <div className="flex-grow pt-1.5">
                <div onClick={()=>goToProfile()} className={"hover:opacity-80 cursor-pointer text-xl truncate font-bold text-gray-800"}>{data.name}</div>
                <div className="text-sm font-medium text-gray-500">{member_type}</div>
            </div>
            <div>
                <PhotoSelector type='profile-picture' setPhoto={(url, color) => {
                    setProfilePic(url);
                    data.updateProfilePicture(url, color, () => {
                    });
                }}>
                    <div >
                        <Avatar show_edit url={profile_pic ? `${profile_pic}_medium?alt=media` : ''}
                                text={data.name}
                                size="3xl"/>
                    </div>
                </PhotoSelector>

            </div>
        </div>
        <div className="px-3 flex pb-7 flex-grow">
            <div className="flex-grow flex items-end">
                <div className="space-y-1 text-sm text-gray-600">
                <div onClick={()=>goToSide('contact')} className="truncate cursor-pointer hover:opacity-80">{fv_getFormattedVal('email', data.account_email, meta, {})}</div>
                <div onClick={()=>goToSide('contact')} className="truncate cursor-pointer hover:opacity-80">{fv_getFormattedVal('phone', data.phone, meta, {})}</div>
                </div>
            </div>
            <div className="flex items-end">
<div className="text-xs font-mono text-gray-500 tracking-wider">
    {data.id}
</div>
            </div>
        </div>
    </div>
}