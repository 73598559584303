import React, {useState,useContext} from 'react';
import {AddEducationStep} from "../../../../library/components/reorganize/add-education-step";
import Modal from "../../../../library/components/old/modal";
import {Button} from "../../../../library/components/core";

import {DoubleCheckModal} from "../../../../library/components/old/double-check-modal";
import {HotkeyWrapper} from "../../../../library/components/old/hotkey-wrapper";


function shouldDisable(d) {
    if(d.degree.length<2) {
        return true;
    }
    if(d.field_of_study.length<2) {
        return true;
    }
    return false;
}

export function EditEducationModal({onEdit,onDelete,onClose,loading,data,id}) {
    const [modal,setModal] = useState(null);
    const [education,setEducation] = useState(data);

    function handleUpdateEducation(f,v) {
        let ed = Object.assign({},education);
        if(f==='range') {
            const final = {...ed,...v};
            setEducation({...final})
        } else if(f==='org_data') {
            ed[f]['title'] = v;
            setEducation({...ed});
        } else {
            ed[f] = v;
            setEducation(ed);
        }
    }

    function deleteEducation() {
        onDelete(id);
    }

    const disabled = shouldDisable(education);

    return <Modal title={"Add Education"} size='base' body={<AddEducationStep education={education} updateEducation={handleUpdateEducation.bind(this)} />} footerRight={<div>
        <Button intent='success' loading={loading} disabled={disabled} onClick={()=>{
            onEdit(education,id);
        }} text='Save Changes' />
    </div>} footerLeft={<div>
        <Button intent='danger' onClick={()=>{
            setModal('double-check-delete');
        }} text='Delete Education' />
        {modal==='double-check-delete'&&<DoubleCheckModal onConfirm={()=>deleteEducation()} onClose={()=>setModal(null)} onCancel={()=>setModal(null)} type='education_delete' />}
    </div>}
                  onClose={onClose} open={true} lazy />
}