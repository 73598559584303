import React from 'react';
import {useIsMobile} from "../../../app/global/global-router";

export function StandardPageHeight({children,full,content,id="",display=""}) {
    return <div id={id} style={{maxWidth:'100vw'}} className={`${full?"col-span-2":""} no-mobile-borders ${content?"bg-white border-gray-200 border-l border-r":""} ${display}`}>
        {children}
    </div>
}

export function StandardPageFramework({layout="full",content,sidebar,header,tabs,children}) {
const is_mobile = useIsMobile();

    return <>
        <div style={{maxWidth:"600px"}} className={`${is_mobile?"w-screen":""} no-mobile-borders ${content?"bg-white border-gray-200 border-l border-r":""}`}>
            <div className="sticky z-20 top-0">
                {header}
            </div>
            {tabs&&<div className="z-20 px-4 flex items-end border-b shared-bottom-border shadow-sm h-8 bg-white">
                {tabs}
            </div>}
            {children}
        </div>
        {sidebar&&<div className="pt-2.5 space-y-4">
            {sidebar}
        </div>}
    </>
}