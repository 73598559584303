import React, {useContext, useEffect, useState} from 'react';
import {setDocumentTitle} from "../../../library/utilities/set-document-title";
import {CommunityContext} from "../community-context";
import {Button, EntityHeader, Tabs} from "../../../library/components/core";
import {FirebaseDB} from "../../../config/setup-firestore";
import {BriefcaseIcon, LockClosedIcon, MailIcon, PlusIcon, UsersIcon} from "@heroicons/react/solid";
import {EntityCard} from "../../../library/components/entity-card";
import {QuillIcon} from "../../../library/icons/custom/Quill";
import {GroupJoinSubscribe} from "./group-join-subscribe";
import {useHistory} from "react-router-dom";
import RoleIcon from "../../../library/icons/custom/RoleIcon";
import {isInLastMonth} from "../../../library/components/reorganize/profile-updated-utilities";
import moment from "moment";
import {CreateGroupController} from "../create-entities/create";
import {StandardPageFramework, StandardPageHeight} from "../../../library/playground/standard-page-framework";

const page_size = 18;

function buildStats(ctx, data) {
    let arr = [];

    if (data.type === 'team') {
        arr.push({
            icon: <div className="h-svg-45 -m-0.5 text-gray-500"><RoleIcon fill="currentColor"/></div>,
            label: `${Object.keys(data.entities.roles).length}`,
            desc: "Roles"
        });
    } else {
        arr.push({
            icon: <UsersIcon/>,
            label: `${Object.keys(data.user_uids).length}`,
            desc: "Total Members"
        });
    }

    arr.push({
        icon: <QuillIcon/>,
        label: `${data.total_posts}`,
        desc: "Total Messages"
    });

    if (data['power_ups']['unaty__mailing_lists']) {
        arr.push({
            icon: <MailIcon/>,
            desc: "Group Mailing List"
        });
    }

    return arr;
}

function buildTags(ctx, data) {
    let arr = [];

    if (isInLastMonth(moment(data.created_at))) {
        arr.push({
            label: "NEW",
            bg: "bg-green-600",
            color: "color-white"
        });
    }

    return arr;
}

const transform = (ctx, doc) => {
    const dt = doc.data();

    const id = doc.id;

    return {
        id,
        title: dt.name,
        subtitle: dt.purpose,

        tags: buildTags(ctx, dt),

        icon: {
            emoji: dt.emoji,
            profile_picture: dt.profile_picture,
            profile_picture_color: dt.profile_picture_color,
            color: dt.color,
        },

        cover: {
            url: dt.cover_photo,
            color: dt.cover_photo_color
        },

        title_icon: dt.closed ? <LockClosedIcon/> : null,
        title_icon_description: dt.closed ? "Private Group" : "",

        meta: null,

        action: <GroupJoinSubscribe id={id} member={ctx.community.member} community_uid={ctx.community.uid}
                                    member_id={ctx.community.member_id} group={dt}/>,

        stats: buildStats(ctx, dt)
    }
};

const tabs = [
    {
        label: "Popular",
        where: (ctx) => {
            return [
                `type`,
                "==",
                "standard"
            ]
        },
        params: {order_by: "total_posts", order_by_dir: "desc"},
        extra: {
            base_where: (ctx) => {
                return [
                    `closed`,
                    "==",
                    false
                ]
            }
        }
    },
    {
        label: "Newest",
        where: (ctx) => {
            return [
                `type`,
                "==",
                "standard"
            ]
        },
        params: {order_by: "created_at", order_by_dir: "desc"},
        extra: {
            base_where: (ctx) => {
                return [
                    `closed`,
                    "==",
                    false
                ]
            }
        }
    },
    {
        label: "Teams",
        where: (ctx) => {
            return [
                `type`,
                "==",
                "team"
            ]
        },
        params: {order_by: "last_activity", order_by_dir: "desc"},
        extra: {}
    }
];

function buildSections(my_groups,starred,ctx) {
    // my groups includes teams
    const all_groups = Object.entries(my_groups);
    let a = {
        starred: [],
        my_groups: []
    };
    all_groups.forEach((entry) => {
        const [id,data] = entry;
        const doc = {
            id,
            data: ()=>{
                return data;
            }
        }
        if(starred[`group__${id}`]) {
            a.starred.push(transform(ctx,doc));
        } else {
            a.my_groups.push(transform(ctx,doc));
        }
    });
    return a;
}

/*
       <div className={`mt-3.5 px-4`} style={{minHeight: '1px'}}>
                <GenericInfiniteLoader
                    history={history}
                    container_classes="grid grid-cols-1 pb-6 gap-4 md:grid-cols-2 xl:grid-cols-3"
                    renderer={(it, k) => <EntityCard onClick={(id) => {
                        history.push(`/${community.data.handle}/group/${id}`)
                    }} key={`${it.id}-${k}`} {...it} />}
                    params={tab_data.params}
                    ctx={{community}}
                    where={tab_data.where}
                    extra={tab_data.extra}
                    getData={getData}
                    community={community}/>
            </div>
 */
export function CommunityGroupsPage(props) {
    const history = useHistory();
    const [open, setOpen] = useState(false);
    const community = useContext(CommunityContext);
    const [tab, setTab] = useState(tabs[0].label);
    setDocumentTitle(`Groups`, community.data.name);
    const [checked_url, setCheckedUrl] = useState(false);

    useEffect(function () {
        if (!checked_url) {
            const urlParams = new URLSearchParams(window.location.search);
            const action = urlParams.get('action');
            if (action === 'create') {
                setOpen(true);
            }
            setCheckedUrl(true);
        }
    }, []);


    const action = <div className="flex space-x-1.5">
        <Button minimal intent="secondary" left_icon={<PlusIcon/>} onClick={() => setOpen(true)} text="New Group"
                size="sm"/>
    </div>;

    const sections = buildSections(community.my_groups,community.member.starred?community.member.starred:{},{community});
    return <StandardPageHeight>
        {sections.starred.length>0&&<div>
            <div className="sticky top-0 bg-white z-20">
            <EntityHeader title="Starred"/>
            </div>
        <div className="px-3">
            <div className="grid grid-cols-1 pb-6 gap-4 sm:grid-cols-2 md:grid-cols-3">
                {sections.starred.map((it,k)=>{
                    return <EntityCard onClick={(id) => {
                        history.push(`/${community.data.handle}/group/${id}`)
                    }} key={`${it.id}-${k}`} {...it} />
                })}
            </div>
        </div>
        </div>}
        <div className="sticky top-0 bg-white z-20">
        <EntityHeader title="My Groups" actions={action} />
        </div>
        <div className="px-3">
            <div className="grid grid-cols-1 pb-6 gap-4 md:grid-cols-2 xl:grid-cols-3">
            {sections.my_groups.map((it,k)=>{
                return <EntityCard onClick={(id) => {
                    history.push(`/${community.data.handle}/group/${id}`)
                }} key={`${it.id}-${k}`} {...it} />
            })}
            </div>
        </div>

        {open && <CreateGroupController type='standard' handle={community.handle} context={{
            is_admin: community.is_admin,
            member_id: community.member_id,
            user_uid: community.member.user_uid,
            community_uid: community.uid
        }} closeModal={() => setOpen(false)} history={props.history} open={open}/>}
    </StandardPageHeight>
}