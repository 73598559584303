import React from 'react';

export default function SGColorsPage() {

    return <div className='grid grid-cols-5 mt-4'>
        <div>
            <div className="h-40 w-40 rounded-md bg-primary">

            </div>
            <div className='mt-2'>
                <div className='text-gray-700 font-semibold text-lg'>Primary</div>
                <div className='text-gray-500 text-sm'>#F15F31</div>
            </div>
        </div>
        <div>
            <div className="h-40 w-40 rounded-md bg-secondary">

            </div>
            <div className='mt-2'>
                <div className='text-gray-700 font-semibold text-lg'>Secondary</div>
                <div className='text-gray-500 text-sm'>#31C3F1</div>
            </div>
        </div>
        <div>
            <div className="h-40 w-40 rounded-md bg-success">

            </div>
            <div className='mt-2'>
                <div className='text-gray-700 font-semibold text-lg'>Success</div>
                <div className='text-gray-500 text-sm'>#2d9665</div>
            </div>
        </div>
        <div>
            <div className="h-40 w-40 rounded-md bg-danger">

            </div>
            <div className='mt-2'>
                <div className='text-gray-700 font-semibold text-lg'>Danger</div>
                <div className='text-gray-500 text-sm'>#d84949</div>
            </div>
        </div>
        <div>
            <div className="h-40 w-40 rounded-md bg-selection">

            </div>
            <div className='mt-2'>
                <div className='text-gray-700 font-semibold text-lg'>Selection</div>
                <div className='text-gray-500 text-sm'>#1164A3</div>
            </div>
        </div>
    </div>
}