import React, {useState} from 'react';
import styled from "styled-components";
import {SelectorIcon,DesktopComputerIcon} from "@heroicons/react/solid";
import classNames from 'classnames';
import {Popover2} from "@blueprintjs/popover2";

const MainIcon = styled.div`
  > div, > div > div {
    color: ${props=>props.selected?"#ffffff":""};
  }
> svg {
    height: 24px;
}
`;

const SelectorBox = styled.div`
> svg {
    height: 20px;
}
`;

const MainEmoji = styled.div`

`;

const mock_options = [
    {
        value: '1234',
        label: "Members only",
        desc: "This email list is for members only",
        icon: <DesktopComputerIcon/>
    }
];

const TitleText = styled.div`
  line-height: 1.3;
  margin-top: 3px;
`;

function Item({selected,disabled,disabled_note,handleSelect,data,menu}) {
    return <div onClick={()=> {
        if(disabled) {
            return;
        }
        handleSelect(data.value)
    }} className={classNames("flex py-2 bp4-popover2-dismiss px-3 ",selected?"bg-selection":menu&&!disabled?"hover:bg-gray-200":"",disabled?"bg-gray-200 opacity-70 cursor-not-allowed":"cursor-pointer transition-colors")}>
        {data.icon&&<MainIcon selected={selected} className={classNames(`flex-none flex items-center justify-center`,data.desc?"w-8":"w-4",selected?"text-gray-200":"text-gray-500")}>
            <div className={`${data.desc?"h-5 w-5":"w-4"}`}>
           {data.icon}
            </div>
        </MainIcon>}
        {data.emoji&&<MainEmoji className={classNames("flex-none flex items-center justify-center",data.desc?"w-8":"w-6 h-6")}>
            <span className="emoji-font text-2xl">
            {data.emoji}
                </span>
        </MainEmoji>}
        <div className="flex-1 pl-2.5 pr-2 flex items-start flex-col">
            <TitleText className={classNames("text-sm ",selected?"text-white font-semibold":"font-semibold text-gray-800")}>{data.label}</TitleText>
            {data.desc&&<div className={classNames("text-xs -mt-px ",selected?"text-white":"text-gray-500")}>{disabled_note?disabled_note:data.desc}</div>}
        </div>
    </div>
}

export function RichSelect({selected = mock_options[0].value, usePortal=false, placement = 'bottom-start', custom_trigger, onSelect, options = mock_options}) {
    const [open,setOpen] = useState(false);
    const content = <div className="w-full rounded-md py-1 border border-gray-200 shadow-lg mt-1 bg-white overflow-y-auto" style={{minWidth: '350px', maxHeight: '300px'}}>
        {options.map((opt,i)=><Item disabled={opt.disabled} disabled_note={opt.disabled_note} handleSelect={(v)=>onSelect(v)} menu data={opt} key={opt.value} selected={opt.value===selected} />)}
    </div>;
    const active_item = options.filter(obj => {
        return obj.value === selected
    })[0];
    if(!active_item) {
        console.warn("no active item", selected)
        return null;
    }
    const trigger = custom_trigger ? custom_trigger : <div className={classNames("relative block border w-full text-sm outline-none rounded-md shadow-sm focus-standard sm:flex-1 border-gray-300 placeholder-gray-500 text-gray-800 hover:bg-gray-50 cursor-pointer",open?"focus-border":"")}>
        <Item handleSelect={()=>{}} data={active_item} />
        <SelectorBox className="absolute text-gray-500 pr-2.5 bottom-0 flex items-center right-0 top-0">
            <SelectorIcon/>
        </SelectorBox>
    </div>;
    return <div className={classNames("relative")}>
        <Popover2 usePortal={usePortal} canEscapeKeyClose fill content={content} onOpened={()=>setOpen(true)} onClosed={()=>setOpen(false)} placement={placement} minimal popoverClassName={`minimal-popover ${placement==='bottom-start'?"mt-2":"mb-2"}`}>
            {trigger}
        </Popover2>

    </div>
}