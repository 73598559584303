import {interests_data} from "../library/components/core/interests-data";

function buildDefaultsReference() {
    let obj = {};
    for(let i=0;i<interests_data.length;i++) {
        const it = interests_data[i];
        obj[`default_${it.id}`] = it;
    }
    return obj;
}

export const reference_interests = buildDefaultsReference();