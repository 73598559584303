import React, {useContext, useState} from "react";
import {ActionWithIcon, PageHeader, SectionHeader} from "../../../../library/components/core";
import {ProfileBadge} from "../../../../library/components/profile-badge";
import {CommunityContext} from "../../community-context";
import {ArrowNarrowRightIcon} from "@heroicons/react/solid";
import {getMemberRoles} from "../../../../library/utilities/member-roles";
import {RolesBadge} from "./components/roles-badge";
import {useHistory} from "react-router-dom";
import {MemberTypeBadge} from "./components/member-type-badge";
import {NewAdminMemberSummary} from "./components/summary";
import {AdminPhotoSelector} from "./components/admin-photo-selector";
import {NewAdminActivity} from "../../../../library/components/admin-activity/mocks";
import {GlobalContext} from "../../../global/global-context";
import {ManageDataSuggestions} from "./components/manage-data-suggestions";

// todo email only
export function NewAdminMemberSidebar({member, pc, pq, hc, member_id, meta}) {
    const community = useContext(CommunityContext);
    const global = useContext(GlobalContext);
    const history = useHistory();
    let badges = [];

    // todo switch
    const member_roles = getMemberRoles(member_id, community.all_roles);

    if (member_roles.length > 0) {
        badges.push(<RolesBadge roles={member_roles} community_handle={community.data.handle} history={history}/>)
    }

    badges.push(<MemberTypeBadge member_id={member_id} community={community} member={member}/>)

    if (meta.is_owner) {
        badges.push(<ProfileBadge type="owner"/>);
    }

    if (meta.is_admin) {
        badges.push(<ProfileBadge type="admin"/>);
    }

    if (member.archived) {
        badges.push(<ProfileBadge type="deactivated"/>);
    }

    if (member.suspended) {
        badges.push(<ProfileBadge type="suspended"/>);
    }

    return <div className="space-y-4">
        <div className="flex items-center">
            <div className="flex-none w-16">
                <AdminPhotoSelector member_id={member_id} member={member} community={community} hc={hc} />
            </div>
            <div className="pl-2">
                <PageHeader size="2xl" title={member.name ? member.name : "Anonymous"}
                            subtitle={<ActionWithIcon size="sm"
                                                      onClick={() => {
                                                          global.handleCloseRightMenu();
                                                          history.push(`/${community.data.handle}/member/${member.handle}`)
                                                      }}
                                                      icon_right icon={<ArrowNarrowRightIcon/>}
                                                      text={"Full Profile"}/>}/>
            </div>
        </div>
        {badges.length > 0 && <div className="space-y-0.5">
            {badges.map((b, k) => <div key={k}>
                {b}
            </div>)}
        </div>}
        <div className="space-y-4">
            <SectionHeader divider fill padded={false} size="base" title={"Summary"}>
                <NewAdminMemberSummary pc={pc} hc={hc} pq={pq} member_id={member_id} community={community} member={member}
                                       meta={meta}/>
            </SectionHeader>

            <ManageDataSuggestions community={community} member_id={member_id} />

            <SectionHeader divider fill padded={false} size="base" title={"Activity"}>
                <NewAdminActivity member_id={member_id} community={community} member={member} />
            </SectionHeader>

        </div>
    </div>
}