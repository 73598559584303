import React, {useState,useEffect} from 'react';
import {Field} from './field';
import {PlusIcon} from "@heroicons/react/solid";
import styled from "styled-components";
import {MAX_FILE_SIZE} from "../../../config/defaults";
import classNames from 'classnames';
import {formatFileSize, getFileTypeIcon} from "../old/file-uploader/helpers";
import {ActionWithIcon} from "./index";

const Icon = styled.div`
      
    > svg {
        height: 20px;
    }
`;


export const FilesDropzone = ({children, accept = "*", maxSize = MAX_FILE_SIZE, maxFiles = 1, setFile}) => {
    return <div>{children}</div>;
};

export function FilesInput({specs = "PNG, JPG, GIF up to 10MB", update_parent=()=>{}, help_text, label = "Files"}) {
    const [files, setFiles] = useState([]);
    const len = files.length;
    const border = len === 0 ? "border-2 border-dashed px-6 pt-5 pb-6" : "border border-solid";
    const [fileRef, setRef] = useState(React.createRef());

    useEffect(function () {
        update_parent(files);
    }, [files]);

    function removeFile(index) {
        let nf = files;
        nf.splice(index, 1);
        setFiles([...nf]);
    }

    function selectFile() {
        fileRef.current.click();
    }

    return <div className="max-w-lg w-full">
        <Field label={label} corner_hint={files.length > 0 ? <ActionWithIcon onClick={()=>selectFile()} icon={<PlusIcon/>} text="Add file" /> : ""} help_text={help_text}>
            <div style={{minHeight: '164px'}}
                 className={classNames(" shadow-sm relative mt-1 flex justify-center  border-gray-300  rounded-md", border)}>
                {len === 0 && <div className="space-y-1 text-center">
                    <svg className="mx-auto h-12 w-12 text-gray-400" stroke="currentColor" fill="none"
                         viewBox="0 0 48 48" aria-hidden="true">
                        <path
                            d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                            strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                    <div className="flex text-sm text-gray-600">
                        <label htmlFor="file-upload"
                               className="relative cursor-pointer bg-white rounded-md font-medium text-secondary hover:text-secondary-800 focus-within:outline-none">
                            <span>Upload a file</span>
                            <input id="file-upload" name="file-upload" type="file" className="sr-only"/>
                        </label>
                        <p className="pl-1">or drag and drop</p>
                    </div>
                    <p className="text-xs text-gray-500">
                        {specs}
                    </p>
                </div>}
                {len > 0 && <div style={{maxHeight: '164px'}} className="pointer-events-inherit w-auto overflow-y-auto flex-1">
                    <div className="max-w-lg">
                        {files.map((file, k) => {

                            return <div className="relative pl-2 pr-3 space-x-2 py-2 flex w-auto" key={k}>
                                <Icon className="w-4 flex-none">
                                    {getFileTypeIcon(file.type)}
                                </Icon>
                                <div className="text-gray-700 text-sm truncate flex-1 font-medium">
                                    {file.name}
                                    <span className="text-xs font-normal text-gray-500 pl-1">({formatFileSize(file.size)})</span>
                                </div>
                                <div className="flex w-10 flex-none items-center z-30">
                                    <a onClick={()=>removeFile(k)} className=" text-gray-500 text-xs font-medium">Delete</a>
                                </div>
                            </div>
                        })}
                    </div>
                </div>}
                {len===0&&<FilesDropzone setFile={(f) => setFiles([...files, ...f])} maxFiles={100}/>}
            </div>
            <input onChange={(e)=>setFiles([...files,...e.target.files])} value={[]} ref={fileRef} multiple={true} type="file"
                   accept="*" style={{display: 'none'}}/>
        </Field>
    </div>
}