import React, {useContext, useState} from 'react';
import {GlobalContext} from "../../../app/global/global-context";
import {ActionWithIcon, Badge, Button, Field} from "../core";
import {
    CheckCircleIcon,
    CheckIcon,
    ClockIcon,
    ExclamationCircleIcon,
    MinusIcon,
    QuestionMarkCircleIcon,
    XIcon
} from "@heroicons/react/solid";
import AddSecondaryEmail from "../../../app/global/preferences/notifications/add-email";
import {NewTooltip} from "../reorganize/new-tooltip";
import {DoubleCheckModal} from "../old/double-check-modal";
import {isAnonymousUnatyEmail} from "../../utilities/anonymous-emails";
import {authFetch} from "../../../config/network";
import {ProfileBadge} from "../profile-badge";
import {AdMeActionChangeAccountEmail} from "../../../app/community/admin/member/actions/change-account-email";
import {logError} from "../../../api/errors/log-error";
import {TempTip} from "../../playground/temp-tip";

const status_info_map = {
    'unverified': {
        message: "Unverified",
        icon: <QuestionMarkCircleIcon/>,
        color: "text-gray-500"
    },
    'verified': {
        message: "Verified",
        icon: <CheckCircleIcon/>,
        color: "text-success"
    },
    'pending': {
        message: "Pending",
        icon: <ClockIcon/>,
        color: "text-gray-500"
    },
    'undeliverable': {
        message: "Undeliverable",
        icon: <ExclamationCircleIcon/>,
        color: "text-danger"
    },
    'not-found': {
        message: "Pending",
        icon: <QuestionMarkCircleIcon/>,
        color: "text-amber-500"
    }
};

const StatusItem = ({status = "verified"}) => {
    const it = status_info_map[status];
    if(!it) {
        logError("No Item for Status Item");
        return null;
    }
    const {message, icon, color} = status_info_map[status];
    return <div className="flex items-center">
        <NewTooltip message={`${message}`}>
            <div className={`h-svg-4 ${color}`}>
                {icon}
            </div>
        </NewTooltip>
    </div>
}

function EmailItem({badge, can_make_primary, email, status, can_remove, onRemove}) {
    return <div className="flex space-x-1.5 pb-0.5 pt-1">
        {can_remove && <div onClick={onRemove} className="flex items-center">
            <Button size="xs" intent="danger" no_padding circular icon={<MinusIcon/>} height="16px"/>
        </div>}
        <div className="flex space-x-1 flex-grow">
            <div className="truncate text-base" style={{maxWidth: "65%"}}>{email}</div>
            <StatusItem status={status}/>
            {badge}
        </div>
    </div>
}

function buildEmailsFromUser(user) {
    let arr = [];
    if (isAnonymousUnatyEmail(user.account_email)) {
        return arr;
    }
    if (user.emails_status && user.emails_status[user.account_email]) {
        arr.push({
            email: user.account_email,
            status: user.emails_status[user.account_email].status
        });
    } else {
        arr.push({
            email: user.account_email,
            status: 'unverified'
        })
    }

    if (user.secondary_emails&&user.emails_status) {
        user.secondary_emails.forEach(se => {
            const se_status = user.emails_status[user.account_email] ? user.emails_status[user.account_email].status : 'verified';
            arr.push({
                email: se,
                status: se_status
            });
        });
    }

    return arr;
}

// todo no community
export function UserEmailManager(props) {
    const global = useContext(GlobalContext);

    const {community} = props;
    const emails = buildEmailsFromUser(global.user);
    const [loading, setLoading] = useState('');
    const [editing, setEditing] = useState(false);
    const [modal, setModal] = useState(null);
    const [modal_data, setModalData] = useState(null);

    function removeSecondaryEmail() {
        setLoading('removing-email');
        const res = () => {
            setLoading('');
            setModal(null);
            setModalData(null);
            setEditing(false);
            global.addToast({text: 'Secondary Email Removed', intent: 'success'});
        };
        const payload = {
            email: modal_data.email,
            user_uid: global.user_uid
        };
        authFetch("/users/remove-secondary-email", res, res, "POST", {payload});
    }

    function makePrimary() {
        setLoading('changing-account-email');
        const res = () => {

            setLoading('');
            setModal(null);
            setEditing(false);
            global.addToast({text: 'Primary Email Changed', intent: 'success'});
        };
        const payload = {
            new_email: modal_data.email,
            user_uid: global.user_uid
        };
        authFetch("/users/change-account-email", res, res, "POST", {payload});
    }

    function user_changeAccountEmail(new_email) {
        setLoading('changing-account-email');
        const res = () => {
            setLoading('');
            setModal(null);
            setEditing(false);
            global.addToast({text: 'Primary Email Changed', intent: 'success'});
        };
        const payload = {
            new_email: new_email,
            user_uid: global.user_uid
        };
        authFetch("/users/change-account-email", res, res, "POST", {payload});
    }

    function member_changeAccountEmail(new_email) {
        setLoading('changing-account-email');
        global.addToast({text: `Changing account email`, intent: 'info'});

        const res = (resp) => {
            setLoading('');
            setModal(null);

            if (resp.data.ok === 'yes') {
                // history.push(`/${community.data.handle}/admin/member/${resp.data.id}`);
                global.addToast({text: `Account Email changed`, intent: 'success'});
            } else {
                // no error is shown
            }
        };

        const payload = {
            member_id: community.member_id,
            community_uid: community.uid,
            id: community.member_id,
            new_email,
            member_user_uid: community.member.user_uid,
            user_uid: community.member.user_uid,
        };

        authFetch("/members/change-account-email", res, res, "POST", {payload});
    }

    function changeAccountEmail(new_email) {

        if(community) {
            member_changeAccountEmail(new_email);
        } else {
            user_changeAccountEmail(new_email);
        }

    }

    const can_edit = emails.length > 1 && emails.filter(e => e.status === 'verified').length > 1;
    const action = emails.length < 5 && <ActionWithIcon onClick={() => {
        setModalData(emails.map(em => em.email));
        setModal('add-secondary');
    }} text="Add Secondary Email"/>;

    const edit_action = !can_edit ? null : editing ?
        <ActionWithIcon onClick={() => setEditing(false)} text="Done" inverse/> :
        <ActionWithIcon onClick={() => setEditing(true)} text="Manage" inverse/>;

    const verified_count = emails.filter(a => a.status === 'verified').length;

    return <div className="max-w-md">
        <Field compact label="Email" help_text={action} corner_hint={edit_action}>
            <div className="space-y-1 divide-y divide-gray-300">
                {emails.length===0&&<div>
                    <ProfileBadge onClick={()=>setModal('add-account-email')} type="email-warning" text={`Missing Email`} />
                </div>}
                {emails.map((em, i) => {
                    const can_remove = i > 0 && verified_count > 1 && editing;
                    const can_make_primary = emails.length > 1 && i !== 0 && em.status === 'verified' && editing;
                    const badge = emails.length > 1 && i === 0 ?
                        <Badge color="blue" text="Primary"/> : can_make_primary ? <ActionWithIcon onClick={() => {
                            setModalData({email: em.email});
                            setModal('change-primary');
                        }} text="Make Primary"/> : null;
                    return <EmailItem status={em.status} badge={badge} key={em.email} email={em.email}
                                      can_remove={can_remove} onRemove={() => {
                        setModalData({email: em.email});
                        setModal('delete-email');
                    }}/>
                })}
            </div>
        </Field>
        {modal === "add-secondary" && <AddSecondaryEmail
            community_uid={community?community.uid:""}
            onClose={() => {
            setModal(null);
            setModalData(null);
        }}/>}
        {modal === "delete-email" && <DoubleCheckModal
            onCancel={() => {
                setModal(null);
                setModalData(null);
            }}
            onConfirm={() => {
                removeSecondaryEmail();
            }}
            type="user_remove_email"/>}
        {modal === "change-primary" && <DoubleCheckModal
            onCancel={() => {
                setModal(null);
                setModalData(null);
            }}
            onConfirm={() => {
                makePrimary();
            }}
            type="user_change_primary_email"/>}

        {modal==='add-account-email'&&<AdMeActionChangeAccountEmail
            default_email={""}
            loading={loading === 'changing-account-email'} community_uid={community?community.uid:""}
            onConfirm={(h) => changeAccountEmail(h)}
            onClose={() => {
                setModal(null);
            }} onCancel={() => {
            setModal(null);
        }}
        />}
        {emails.filter(a => a.status === 'unverified').length > 0 && <div className="mt-4">
            <TempTip tip={"verify-emails"} />
        </div>}
    </div>
}