import React, {useContext} from 'react';
import {FullScreenLoader} from "../../library/components/old/full-screen-loader";
import {GlobalContext} from "../global/global-context";

export default function ContextualRedirect(props) {
    const path = props.location.pathname;
    const global = useContext(GlobalContext);

    const parts = path.split('/');
    let handle = parts.length>2 ? parts[1] : '';
    if(parts.length>2) {
        // probably a community,
        if(global.logged_in) {
            // shouldn't be rendering, just need to give it a sec
            return <FullScreenLoader />;
        }
        props.history.push(`/${handle}/sign-in?_redirectTo=${path}`)
    } else {
        if(global.logged_in) {
            // shouldn't be rendering, just need to give it a sec
            return <FullScreenLoader />;
        }
        props.history.push(`/sign-in?_redirectTo=${path}`)
    }

    return <FullScreenLoader />;
}