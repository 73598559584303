import React, {useState, useContext} from 'react';
import Modal from "../modal";
import {Button} from "../../core";
import styled from "styled-components";

const BodyText = styled.div`
    font-size: 15px;
    padding-top: 4px;
    line-height: 1.3;
    padding-bottom: 16px;
`;

const config = {
    'user_remove_email': {
        title: 'Remove Secondary Email',
        body: <div>
            If you remove this email, you will no longer be able to login with it or use it with mailing lists.
            <br/>
            <br/>
            Are you sure you wish to remove it?
        </div>,
        confirm_text: 'Yes, remove this email',
        intent: 'danger'
    },
    'admin_remove': {
        title: 'Remove Admin',
        body: <div>
            If you remove this folder, they will lose access to manage this community and change preferences.
            <br/>
            <br/>
            Are you sure you wish to remove them?
        </div>,
        confirm_text: 'Yes, remove this admin',
        intent: 'danger'
    },
    'folder_delete': {
        title: 'Delete Folder',
        body: <div>
            If you delete this folder, all subfolders and files will be permanently deleted. <b>This cannot be
            undone</b>.
            <br/>
            <br/>
            Are you sure you wish to delete?
        </div>,
        confirm_text: 'Yes, delete this folder',
        intent: 'danger'
    },
    'file_delete': {
        title: 'Delete File',
        body: <div>
            This file will be permanently deleted. <b>This cannot be undone</b>.
            <br/>
            <br/>
            Are you sure you wish to delete?
        </div>,
        confirm_text: 'Yes, delete this file',
        intent: 'danger'
    },
    'group_delete': {
        title: 'Delete Group',
        body: <div>
            If you delete this group, all posts and records will be permanently deleted. <b>This cannot be undone</b>.
            <br/>
            <br/>
            This groups' folder and contents will not be deleted, but made into a standard folder and all current group
            members will have access.
            <br/>
            <br/>
            Are you sure you wish to delete?
        </div>,
        confirm_text: 'Yes, delete this group',
        intent: 'danger'
    },
    'member_type_delete': {
        title: 'Delete Member Type',
        body: <div>
            If you delete this member type, you can select a replacement member type, all members that are currently assigned to the type you're deleting will be reassigned to the replacement member type.
            <br/>
            <br/>
            Are you sure you wish to proceed?
        </div>,
        confirm_text: 'Yes, choose replacement type',
        intent: 'danger'
    },
    'member_delete': {
        title: 'Delete Member',
        body: <div>
            If you delete this member, all their data, posts, and activity will be permanently deleted. <b>This cannot
            be undone</b>.
            <br/>
            <br/>
            Are you sure you wish to delete?
        </div>,
        confirm_text: 'Yes, delete this member',
        intent: 'danger'
    },
    'member_suspend': {
        title: 'Suspend Member',
        body: <div>
            If you suspend this member, they will be unable to login.
            <br/>
            <br/>
            Are you sure you wish to proceed?
        </div>,
        confirm_text: 'Yes, suspend this member',
        intent: 'secondary'
    },
    'remove_moderator': {
        title: 'Remove Moderator',
        body: <div>
            Are you sure you wish to remove this member as a moderator?
        </div>,
        confirm_text: 'Yes, remove this moderator',
        intent: 'secondary'
    },
    'rule_remove_from_group': {
        title: 'Remove Rule',
        body: <div>
            Are you sure you wish to remove this rule and associated members from the group?
        </div>,
        confirm_text: 'Yes, remove this rule',
        intent: 'secondary'
    },
    'member_remove_from_group': {
        title: 'Remove Member',
        body: <div>
            Are you sure you wish to remove this member from the group?
        </div>,
        confirm_text: 'Yes, remove this member',
        intent: 'secondary'
    },
    'member_archive': {
        title: 'Deactivate Member Account',
        body: <div>
            If you deactivate this members account, they will no longer be able to login.
            <br/>
            <br/>
            Are you sure you wish to proceed?
        </div>,
        confirm_text: 'Yes, deactivate this member',
        intent: 'secondary'
    },
    'member_unarchive': {
        title: 'Reactivate Member',
        body: <div>
            If you reactivate this member, they will be able to login again.
            <br/>
            <br/>
            Are you sure you wish to proceed?
        </div>,
        confirm_text: 'Yes, reactivate',
        intent: 'secondary'
    },
    'delete_profile_section': {
        title: 'Delete Profile Section',
        body: <div>
            If you delete this profile section, all data related to it across members will be lost.
            <br/>
            <br/>
            Are you sure you wish to proceed?
        </div>,
        confirm_text: 'Yes, delete this section',
        intent: 'danger'
    },
    'delete_profile_field': {
        title: 'Delete Profile Field',
        body: <div>
            If you delete this profile field, all data related to it across members will be lost.
            <br/>
            <br/>
            Are you sure you wish to proceed?
        </div>,
        confirm_text: 'Yes, delete this field',
        intent: 'danger'
    },
    'education_delete': {
        title: 'Delete Education',
        body: <div>
            If you delete this education, it's data will be lost.
            <br/>
            <br/>
            Are you sure you wish to proceed?
        </div>,
        confirm_text: 'Yes, delete this education',
        intent: 'danger'
    },
    'career_delete': {
        title: 'Delete Position',
        body: <div>
            If you delete this position, it's data will be lost.
            <br/>
            <br/>
            Are you sure you wish to proceed?
        </div>,
        confirm_text: 'Yes, delete this position',
        intent: 'danger'
    },
    'files_folders_delete': {
        title: 'Delete Files & Folders',
        body: <div>
            If you delete these contents, all subfolders and files will be permanently deleted. <b>This cannot be
            undone</b>.
            <br/>
            <br/>
            Are you sure you wish to delete?
        </div>,
        confirm_text: 'Yes, delete it all',
        intent: 'danger'
    },
    'role_delete': {
        title: 'Delete Role',
        body: <div>
            If you delete this role, it's history and data will be lost.
            <br/>
            <br/>
            Are you sure you wish to proceed?
        </div>,
        confirm_text: 'Yes, delete this role',
        intent: 'danger'
    },
    'policy_publish': {
        title: 'Publish a new Policy Version',
        body: <div>
            Updating this policy will trigger an update for all members of the community and may require members to accept the new policy before accessing the commmunity.
            <br/>
            <br/>
            Are you sure you wish to proceed?
        </div>,
        confirm_text: 'Yes, publish policy changes',
        intent: 'secondary'
    },
    'make_group_closed': {
        title: 'Make Private Group',
        body: <div>
            Making this group private means members must be added to join the group and see its content.
            <br/>
            <br/>
            Are you sure you wish to proceed?
        </div>,
        confirm_text: 'Yes',
        intent: 'secondary'
    },
    'make_group_open': {
        title: 'Make Group Public',
        body: <div>
            Making this group public means anyone can find and join this group and see its contents.
            <br/>
            <br/>
            Are you sure you wish to proceed?
        </div>,
        confirm_text: 'Yes',
        intent: 'secondary'
    },
    'application_reject': {
        title: 'Are your sure you want to reject this application?',
        body: <div>
            This cannot be undone, however you can manually add this member anytime to your community.
            <br/>
            <br/>
            The user will be notified about the rejection.
        </div>,
        confirm_text: 'Reject Application',
        intent: 'danger'
    },
    'import_check': {
        title: 'Import Members',
        body: <div>
            Any records from the file you uploaded that match members in your community will update those members profiles. New members will be created for any record not matching an existing member and having at least an email or first name.
            <br/>
            <br/>
            When members are updated, empty values in the records from the file you upload will not overwrite data on a members' Unaty profile.
        </div>,
        confirm_text: 'Yes, start the import!',
        intent: 'success'
    },
    'convert_team_to_group': {
        title: 'Convert Team to Group',
        body: <div>
            Making this team into a Group allows you to add members and filters.
            <br/>
            <br/>
            Are you sure you wish to proceed?
        </div>,
        confirm_text: 'Yes',
        intent: 'secondary'
    },
    'segment_delete': {
        title: 'Delete Segment',
        body: <div>
            Deleting this segment will remove it from all groups where it is used and all members added through those segments alone will also be removed.
            <br/>
            <br/>
            Are you sure you wish to proceed?
        </div>,
        confirm_text: 'Yes',
        intent: 'secondary'
    },
    'convert_group_to_team': {
        title: 'Convert Group to Team',
        body: <div>
            Making this group into a team will remove all members and filters leaving only roles (and their assignees). Also the pinboard will now be publically visible.
            <br/>
            <br/>
            Are you sure you wish to proceed?
        </div>,
        confirm_text: 'Yes',
        intent: 'secondary'
    },
    'user_change_primary_email': {
        title: 'Change Account Email',
        body: <div>
            Changing your account email will mean you get all emails from Unaty and your communities through this new email.
            <br/>
            <br/>
            Are you sure you wish to proceed?
        </div>,
        confirm_text: 'Yes, change primary email',
        intent: 'secondary'
    },
    'gate_delete': {
        title: 'Delete Gate',
        body: <div>
            Are you sure you wish to proceed?
        </div>,
        confirm_text: 'Yes',
        intent: 'secondary'
    }
};

export function DoubleCheckModal(props) {
    const [loading,setLoading] = useState(false);
    const {
        children,
        type = 'member_suspend',
        onClose, onCancel = () => {
        }, onConfirm = () => {
        }
    } = props;
    const {title, intent, body, confirm_text} = config[type];

    const content = <BodyText className='text-gray-800'>
        {children}
        {body}
    </BodyText>;

    const modal_footer = <div className="space-x-2">
        {!loading&&<Button text='Cancel' disabled={loading} onClick={onCancel}/>}
        <Button text={confirm_text} loading={loading} onClick={()=>{
            setLoading(true);
            onConfirm();
        }} intent={intent}/>
    </div>;

    return <Modal title={title} size='small' body={content} footerRight={modal_footer} footerLeft={null}
                  onClose={()=>{
                  if(onClose) {
                      onClose();
                  } else {
                      onCancel();
                  }
                  }} open={true} lazy/>
}