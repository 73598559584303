import React from 'react';
import styled from "styled-components";

const Wrapper = styled.div`
width: 500px;
margin: 0 auto;
padding: 64px;
`;

const Text = styled.div`
font-size: 16px;
line-height: 1.4;
`;

export default function EntityNotFound({type='group'}) {

    return <Wrapper>
        <Text className='text-gray-800'>Our records do not contain the {type} you're looking for so it does not exist.</Text>
    </Wrapper>
}