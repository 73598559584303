import React, {useState} from 'react';
import {InformationCircleIcon, XIcon} from "@heroicons/react/outline";
import {getItemFromLocalStorage, saveItemToLocalStorage} from "../utilities/localstorage";

const messages = {
  'verify-emails': "Verify secondary emails to change your primary account email.",
  'sign-up-apply': "Your account will need to be approved before you can log in to the community.",
  'group-rules': "Rules automatically add and remove group members.",
  'profile-picture-public': "Profile pictures may be publically visible if you're assigned to a role listed on the public community page.",
  'group-role-mods': "Roles can also be moderators and their assignees will automatically become group moderators",
};

export function TempTip({tip,custom,icon}) {
    const [read,setRead] = useState(getItemFromLocalStorage(`temptip-${tip}`,false));

    if(read&&!custom) {
        return null;
    } else if(custom && tip && read) {
        return null;
    }

    function readTip() {
        saveItemToLocalStorage(`temptip-${tip}`, true)
        setRead(true);
    }

    return <div style={{backgroundColor:"#CCE5FF",borderColor: "#C9DEF5"}} className="flex max-w-xl rounded-md p-2 bg-blue-50 border border-blue-100">
        <div className="flex-none h-svg-5 flex items-start" style={{color: "#074075"}}>
            {icon?icon:<InformationCircleIcon />}
        </div>
        <div style={{color: "#074075"}} className="flex-grow pl-2 text-sm">
            {custom?custom:messages[tip]}
        </div>
        {tip&&<div className="pl-2">
            <div onClick={()=>{
                    readTip();
            }} className="text-gray-500 pt-0.5 cursor-pointer hover:text-gray-800 h-svg-45">
                <XIcon />
            </div>
        </div>}
    </div>
}