import React from "react";
import {custom_field_type_icons} from "../../../../app/auth/profile-fields/constants";

export function MatchFieldTitle({
                                    title = "Member Types",
                                    file_name = "xyz.csv",
                                    type = "email"
                                }) {

    return <div className="flex items-center space-x-2 h-8 overflow-x-hidden">
        <div className="flex-none flex justify-end" style={{width: '2rem'}}>
            <div className="bg-teal-600 text-white h-5 w-5 rounded-md flex items-center justify-center">
                <div className="w-4 h-4 text-white">
                    {custom_field_type_icons[type]}
                </div>
            </div>
        </div>
        <div className="flex items-baseline space-x-1.5">
            <div className="text-sm font-medium text-gray-800 whitespace-nowrap">"{title}"</div>
            <div className="text-xs text-gray-500 truncate" style={{maxWidth: '200px'}}>from <strong
                className="text-gray-700">Uploaded Source File</strong></div>
        </div>
    </div>
}