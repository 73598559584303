import React, {useEffect, useState} from 'react';
import {FirebaseDB} from "../../../../../config/setup-firestore";
import styled from "styled-components";

const List = styled.div`
 margin-top: 8px;
`;

const Item = styled.div`
     padding: 8px 12px;
    line-height: 24px;
`;

const Text = styled.div`
    font-size: 16px;
    font-weight: 500;
    line-height: 30px;
    margin-right: 12px;
`;

export function FiltersList({history,handle,community_uid}) {
    const [filters, setFilters] = useState([]);

    useEffect(() => {
        FirebaseDB
            .collection('community_entities')
            .doc(community_uid)
            .collection('collections')
            .get()
            .then((snap) => {
                const fs = snap.docs.map((doc) => {
                    return {id: doc.id, ...doc.data()}
                });
                setFilters(fs);
            });
    }, [community_uid]);

    return <List className='border-top'>
        {filters.map((filter) => {
            return <Item onClick={()=>{
                history.push(`/${handle}/admin/settings/filter/${filter.id}`);
            }} key={filter.id} className='border-bottom hover-opacity'>
                <Text>
                    {filter.name}
                </Text>
            </Item>
        })}
    </List>
}