import React, {useState, useEffect} from 'react';
import {MarkdownViewer} from "./markdown-viewer";

function sanitize(v) {
    return v ? v : '';
}

const example = `
# Heading 1

## Heading 2

### Heading 3

This is Plain text across multiple lines it goes oh yeah halleluja oh yeah keep going my friend

**Bold text**

*Italic text*

This is a list
* List
* List
* List

Numbered List
1. One
2. Two
3. Three

Link
[Link](http://a.com)

Blockquote
> Blockquote

---

\`Inline code\` with backticks

\`\`\`
# code block
print '3 backticks or'
print 'indent 4 spaces'
\`\`\`
`;

export function Markdown({
                             value = "",
                             fill,
                             mode = "viewer",
                             onChange,
                             placeholder = "Start typing..",
                             name = "",
                             id = ""
                         }) {
    const [val, setVal] = useState(sanitize(value));
    useEffect(function () {
        setVal(sanitize(value));
    }, [value]);

    const max_width = fill ? "" : "max-w-lg";

    function handleChange(e) {
        const new_val = sanitize(e?.target?.value || "");
        setVal(new_val);

        if(onChange) {
            onChange(new_val);
        }
    }

    if (mode === 'viewer') {
        return <>
            <MarkdownViewer content={val}/>

        </>
    } else if (mode === 'editor') {
        return <div className="relative">
            <div className={`block  w-full sm:flex-1 ${max_width}`}>
                <div className=" ">
        <textarea name={name} id={id}
                  style={{minHeight: '20vh', maxHeight: "70vh"}}
                  className="w-full border rounded-md py-2 text-sm px-3 placeholder-gray-500 shadow-sm focus-standard border-gray-300"
                  placeholder={placeholder} value={val} onChange={handleChange}/>
                </div>
            </div>

        </div>
    } else {
        return <>
            <div className="mt-1 relative">
                <div className={`block  w-full    sm:flex-1 ${max_width}`}>
                    <div>
        <textarea name={name} id={id}
                  className="resize-none border rounded-md outline-none py-2 text-sm px-3 placeholder-gray-500 shadow-sm focus-standard border-gray-300"
                  placeholder={placeholder} value={val} onChange={oc}/>
                    </div>
                </div>
            </div>
            <div className="mt-2 relative markdown-styles w-full">
                <MarkdownViewer content={val}/>
            </div>

        </>
    }

}