import React from 'react';
import {Avatar} from "../core";
import {DEFAULT_USER_PHOTO} from "../../../config/defaults";
import {notification_types} from "./types";
import {hydrateDynamicText, renderDynamicText} from "../admin-activity/item";
import {aa_formatDate} from "../../utilities/general/dates";

function NotificationAuthor({author}) {

    let url = author.image ? `${author.image}_small?alt=media` : "";

    if(!url) {
        return <div className="p-0.5">
            <Avatar url={`${DEFAULT_USER_PHOTO}_small?alt=media`} size="sm"/>
        </div>
    }

    return <div className="p-0.5">
        <Avatar url={url} size="sm"/>
    </div>
}

function GeneralItem({type, data, author, ctx, date_formatted}) {
    const notification_type = notification_types[type];

    if (!notification_type) {
        console.error('missing info', type)
        return null;
    }

    const final = hydrateDynamicText(notification_type.message, data, ctx);
    const f_arr = final.split(/##/g);

    return <div style={{gridTemplateColumns:"2rem 1fr"}} className="grid gap-3 shared-bottom-border border-b px-2.5 py-2">
        <div className="">
            <NotificationAuthor author={author} />
        </div>
        <div className="">
            <div className="text-gray-800 cursor-pointer line-clamp-2 text-sm">
                {renderDynamicText(f_arr, {size:'4'}, ctx.community_handle)}
            </div>
            <div className="text-gray-500 pt-0.5 text-xs">{date_formatted}</div>
        </div>
    </div>
}

/*
added_to_group - "*MEMBERNAME*" added you to the group *GROUPNAME*" - MEMBER PHOTO

member_joined_your_group - "*MEMBERNAME* joined your group *GROUPNAME*"

member_added_to_your_group - "*MEMBERNAME* was auto-invited to your group *GROUPNAME*"

auto_added_to_group - "You were auto-invited to the group *GROUPNAME*"  - REFRESH ICON

added_to_team- "*MEMBERNAME*" added you to the team *TEAMNAME*" - MEMBER PHOTO

auto_added_to_group - "You were auto-invited to the team "*TEAMNAME*" - REFRESH ICON

invited_member_first_sign_in - "*MEMBERNAME* signed in for the first time" - MEMBER PHOTO

made_moderator  -  "*MEMBERNAME* made you a moderator of *GROUPNAME*" - MEMBER PHOTO

made_admin - "*MEMBERNAME* made you a community admin" - MEMBER PHOTO

auto_made_moderator - "You were made a moderator of the group *GROUPNAME* - MODERATOR ICON

member_type_change - "*MEMBERNAME* changed your member type to *MEMBERTYPENAME*" - MEMBER PHOTO

assigned_role - "*MEMBERNAME* assigned you to the role *ROLENAME*" - MEMBER PHOTO
 */

const mocks = [
    {
        type: "added_to_group",
        text: "*MEMBERNAME* added you to the group *GROUPNAME*"
    },
    {
        type: "assigned_role",
        text: "*MEMBERNAME* assigned you to the role *ROLENAME*"
    },
    {
        type: "added_to_team",
        text: "*MEMBERNAME* added you to the team *TEAMNAME*"
    },
    {
        type: "assigned_role",
        text: "*MEMBERNAME* assigned you to the role *ROLENAME*"
    },
    {
        type: "auto_made_moderator",
        text: "You were made a moderator of the group *GROUPNAME*"
    },
    {
        type: "made_moderator",
        text: "*MEMBERNAME* made you a moderator of *GROUPNAME*"
    },
    {
        type: "member_type_change",
        text: "*MEMBERNAME* changed your member type to *MEMBERTYPENAME*"
    },
    {
        type: "auto_added_to_group",
        text: "You were auto-invited to the group *GROUPNAME*"
    },
    {
        type: "auto_added_to_team",
        text: "You were auto-invited to the team *TEAMNAME*"
    },
    {
        type: "made_admin",
        text: "*MEMBERNAME* made you a community admin"
    }
];

function getConnector(len, index) {
    if ((index + 1) === len) {
        // last one
        return 'none';
    } else {
        return 'line'
    }
}

function NotificationsSection({updates, show_title=true, title, context}) {
    return <div>
        {show_title&&<div className="text-gray-600 text-sm font-medium pt-1.5 px-2.5">
            {title}
        </div>}
        {updates.map((event, k) => {
            const show_time = k === 0 ? true : updates[k - 1].date_formatted !== event.date_formatted;
            return <GeneralItem show_time={show_time} connector={getConnector(updates.length, k)} ctx={context}
                         key={`${event.id}-${k}`} {...event} />
        })}
    </div>
}

export function Notifications({updates = [], context}) {

    const final_updates = updates.map(upd => {
        return {
            ...upd,
            ...aa_formatDate(upd.ts)
        }
    });

    let obj = {
        'this-week': {updates: [], title: 'This Week'},
        'last-month': {updates: [], title: 'Last Month'},
        'last-year': {updates: [], title: 'Last Year'},
        'beyond': {updates: [], title: 'Beyond'},
    };

    final_updates.forEach(evt => {
        obj[evt.sort].updates.push(evt);
    });

    const sections = Object.values(obj).filter(a => a.updates.length > 0);

    if (final_updates.length === 0) {
        return <div className="text-sm text-gray-500 p-6 text-center">
            No notifications found
        </div>
    }

    // <ActionWithIcon inverse text="Mark all as read"/>
    return <div className="">
            {sections.map(sec => <NotificationsSection show_title={false} sections_count={sections.length} title={sec.title} key={sec.title} context={context}
                                                       updates={sec.updates}/>)}
        </div>
}