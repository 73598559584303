import React, {useState, useEffect, useContext} from "react";
import AuthFrame from "../general/AuthFrame";
import {authFetch} from "../../../config/network";
import VerifyAccountBlock from "../verify-account/VerifyAccountBlock";
import {GlobalContext} from "../../global/global-context";
import {FirebaseDB} from "../../../config/setup-firestore";
import {Button} from "../../../library/components/core";
import {SignInWrapper} from "../sign-in/SignInWrapper";

function tokenExpired(date_string) {
    const date = new Date(date_string);
    const now = new Date();
    return date < now;
}

export default function VerifyAccount(props) {

    const global = useContext(GlobalContext);

    const [verified, setVerified] = useState(false);
    const [token, setToken] = useState(props.match.params.token);
    const [token_data, setTokenData] = useState(null);
    const [verifying, setVerifying] = useState(false);
    const [loading, setLoading] = useState(false);
    const [exists, setExists] = useState(false);
    const [expired, setExpired] = useState(false);

    useEffect(function () {
        FirebaseDB
            .collection("verification_tokens")
            .doc(this.token)
            .get()
            .then((doc) => {
                if (doc.exists) {
                    setTokenData(doc.data());
                    setLoading(false);
                    setExpired(tokenExpired(doc.data().expires_at));
                } else {
                    setExists(false);
                    setLoading(false);
                }
            })
    }, [token]);


    function handleSubmit(email, password) {
        global.login(email, password);
    }

    function verifyToken() {
        setVerifying(true);

        const res = (result) => {
            setVerifying(false);
            setVerified(false);
            redirect();
        };

        const err = () => {
            setVerifying(false);
        };

        authFetch("/users/verify-account", res, err, "POST", {token})
    }

    function redirect() {
        setTimeout(() => {
            props.history.push('/home');
        }, 5000);
    }

    const {logged_in, logging_in} = global;
    const {history} = props;

    if (!logged_in || logging_in) {
        return <AuthFrame>
            <div className='header'>
                <div>Please login to continue</div>
            </div>
            <SignInWrapper handleSubmit={handleSubmit.bind(this)} {...props} />
        </AuthFrame>;
    }

    if (loading) {
        return <AuthFrame>
            loading
        </AuthFrame>
    }

    if (!exists) {
        return <AuthFrame>
            <div className='header'>
                <div>Link not found</div>
            </div>
            <Button text='Home' intent={'secondary'} onClick={() => {
                history.push('/home')
            }}/>
        </AuthFrame>;
    }

    if (expired) {
        return <AuthFrame>
            <div className='header'>
                <div>Link Expired</div>
            </div>
            <VerifyAccountBlock/>
        </AuthFrame>;
    }

    if (verified) {
        return <AuthFrame>
            <div className='header'>
                <div>Your email has been verified</div>
            </div>
            <Button text='Home' intent={'secondary'} onClick={() => {
                history.push('/home')
            }}/>
        </AuthFrame>;
    }

    if (!verifying) {
        // only once
        verifyToken();
    }

    return <AuthFrame>
        <div className='header'>
            <div>Verifying..</div>
        </div>
    </AuthFrame>;
}