import React from "react";
import {generateVCard} from "../../../library/utilities/general/create-vcard";
import {displayBday} from "../../../library/components/old/edit-fields-section/fields";
import {getEntityColor} from "../../../library/utilities/localstorage/entity-localstorage";
import {countries, getFlagByCountryCode} from "../../../library/data/general/countries";
import {getMemberJoinDate} from "../../../library/components/reorganize/member-join-date";
import {
    buildAddress,
    cleanWebsiteForProfile,
    formatWebsiteForProfile, parseSocialUrl
} from "../../../library/utilities/shared";
import {buildLocationMeta} from "../../../library/playground/location";

export function getNewLocationValue(co, ci, abbr) {
    let emoji = null;
    let text = "";

    if (co) {
        emoji = getFlagByCountryCode(co);
    }

    if (co && ci) {
        text = `${ci.trim()}, ${countries[co]}`;

        if(text.length>28) {
            text = `${ci.trim()}, ${co.toUpperCase()}`;
        }
    } else if (ci) {
        text = `${ci.trim()}`;
    } else {
        text = `${countries[co]}`;
    }

    return {
        emoji,
        text
    }
}

export function parseMemberLocation(member) {
    if(!member||!member.about) {
        return {
            text: "",
            emoji: ""
        }
    }
    if(member.about.location) {
        const m = buildLocationMeta(member.about.location);

        if(m.label) {
            return {
                text: m.label,
                emoji: m.emoji
            }
        }
    }
    if(!member.address.country&&!member.address.city) {
        return {
            text: "",
            emoji: ""
        }
    }
    if(member.address.country || member.address.city) {
        return getNewLocationValue(member.address.country, member.address.city, true);
    } else {
        return {
            text: "",
            emoji: ""
        };
    }
}

function parseMemberOccupation(member) {
    if(member.about.occupation) {
        return {
            text: member.about.occupation,
            emoji: ""
        };
    }
    return {
        text: "",
        emoji: ""
    };
}

export function handleProfileSocialFields(social) {
    let a = [];
    if (social.website) {
        a.push({
            type: 'website',
            value: `${formatWebsiteForProfile(social.website)}`
        });
    }
    if (social.twitter) {
        a.push({
            type: 'url-twitter',
            value: `${parseSocialUrl(social.twitter)}`
        });
    }

    if (social.facebook) {
        a.push({
            type: 'url-facebook',
            value: `${parseSocialUrl(social.facebook,'facebook.com')}`
        });
    }
    if (social.linkedin) {
        a.push({
            type: 'url-linkedin',
            value: `${parseSocialUrl(social.linkedin,'linkedin.com/in')}`
        });
    }

    if (social.eth_address) {
        a.push({
            type: 'url-eth_address',
            value: `${parseSocialUrl(social.eth_address,'eth_address')}`
        });
    }

    if (social.reddit) {
        a.push({
            type: 'url-reddit',
            value: `${parseSocialUrl(social.reddit,'reddit.com/user')}`
        });
    }

    if (social.github) {
        a.push({
            type: 'url-github',
            value: `${parseSocialUrl(social.github,'github.com')}`
        });
    }

    if (social.instagram) {
        a.push({
            type: 'url-instagram',
            value: `${parseSocialUrl(social.instagram,'instagram.com')}`
        });
    }

    if (social.skype) {
        a.push({
            type: 'url-skype',
            value: `${social.skype}`
        });
    }

    if (social.discord) {
        a.push({
            type: 'url-discord',
            value: `${parseSocialUrl(social.discord,'discordapp.com/users')}`
        });
    }

    return a;
}

export function getMemberMeta(member) {
    let arr = [];
    const loc = parseMemberLocation(member);
    if (loc.text) {
            arr.push({
                type: 'new-location',
                value: loc
            });
    }
    const occ = parseMemberOccupation(member);
    if (occ.text) {
        arr.push({
            type: 'occupation',
            value: occ
        });
    }

    if (member.short_birthday || !!member.birthday.year) {
        const bv = displayBday(member.birthday, true);
        if(bv&&bv!=='-') {
            arr.push({
                type: 'birthday',
                value: displayBday(member.birthday, true)
            });
        }
    }


    if (member.join_date && member.join_date.type) {
        const v = getMemberJoinDate(member.join_date);
        if (!!v & v !== '-' && v !== 'Unknown') {
            arr.push({
                type: 'joined',
                value: `${v}`
            });
        }
    }
    arr = arr.concat(handleProfileSocialFields(member.social))

    return arr;
}

export function buildMemberProfile(member, member_types, community_uid, admins) {

    // subtitle = roles
    return {
        title: member.name,
        profile_picture: member.profile_picture,
        profile_picture_color: member.profile_picture_color ? member.profile_picture_color : '',
        is_admin: admins[member.user_uid],
        cover_photo: member.cover_photo,
        cover_photo_color: member.cover_photo_color ? member.cover_photo_color : '',
        color: getEntityColor(community_uid, member.handle),
        bio: member.about.bio,
        meta: getMemberMeta(member),
        subtitle: `${member_types[member.member_type].singular}`,
        subtitle2: member.status
    }
}

export function downloadProfilebuildBday(b) {
    if (b.date === null || b.year === null || b.month === null) {
        return '';
    }
    return new Date(`${b.month}-${b.date}-${b.year}`);
}

export function downloadProfile() {
    const member = this.state.member;

    const p = {
        first: member.about.first_name,
        last: member.about.last_name,
        title: this.props.member_types[member.member_type].singular,
        middle: member.about.middle_name,
        image: member.profile_picture,
        phone: member.contact.phone,
        birthday: downloadProfilebuildBday(member.birthday),
        email: member.contact.email,
        url: window.location.href,
        organization: this.props.community.name
    };
    generateVCard(p);
}

function buildFullFields(cif, fields_data) {
    let arr = [];

    for (let i = 0; i < cif.length; i++) {
        const f_id = cif[i];
        const data = fields_data[f_id];

        arr.push({
            f_id,
            ...data
        });
    }

    return arr;
}

function extractFieldsData(sections) {
    let obj = {};

    const entries = Object.entries(sections);

    for (let i = 0; i < entries.length; i++) {
        const [id, sec] = entries[i];

        for (let i = 0; i < sec.fields.length; i++) {
            const f_id = sec.fields[i];
            obj[f_id] = sec.field_data[f_id];
        }
    }

    return obj;
}

function getFieldPinItems(type, val, field_data) {
    let arr = [];
    switch (type) {
        case 'number' :
            if (val) {
                arr.push({
                    type: 'number',
                    value: val,
                    onClick: () => {
                    }
                });
            }
            break;
        case 'select' :
            if (val) {
                const it = field_data.options.choices.filter(obj => {
                    return obj.value === val
                })[0];
                arr.push({
                    type: 'tag',
                    value: it.text,
                    onClick: () => {
                    }
                });
            }
            break;
        case 'multi-select' :
            // multiple items
            if (val) {
                for (let k = 0; k < val.length; k++) {
                    const it = field_data.options.choices.filter(obj => {
                        return obj.value === val[k]
                    })[0];
                    arr.push({
                        type: 'tag',
                        value: it.text,
                        onClick: () => {
                        }
                    });
                }
            }
            break;
        case 'member' :
            if (val) {
                arr.push({
                    type: 'member',
                    value: val,
                    onClick: () => {
                    }
                });
            }
            break;
        case 'mentor' :
            arr.push({
                type: 'member',
                value: val,
                onClick: () => {
                }
            });
            break;
        case 'mentees' :
            if (val) {
                const keys = Object.keys(val);
                for (let k = 0; k < keys.length; k++) {
                    arr.push({
                        type: 'member',
                        value: keys[k],
                        onClick: () => {
                        }
                    });
                }
            }
            break;
        case 'text' :
            if (val) {
                arr.push({
                    type: 'text',
                    value: val,
                    onClick: () => {
                    }
                });
            }
            break;
        case 'textarea' :
            if (val) {
                arr.push({
                    type: 'text',
                    value: val,
                    onClick: () => {
                    }
                });
            }
            break;
        case 'url' :
            if (val) {
                arr.push({
                    type: 'website',
                    value: `[${cleanWebsiteForProfile(formatWebsiteForProfile(val))}](${val})`,
                    onClick: () => {
                        const win = window.open(val, '_blank');
                        win.focus();
                    }
                });
            }
            break;
        case 'date' :
            if (val) {
                arr.push({
                    type: 'text',
                    value: val,
                    onClick: () => {
                    }
                });
            }
            break;
        case 'address' :
            if (val) {
                arr.push({
                    type: 'text',
                    value: buildAddress(val),
                    onClick: () => {
                    }
                });
            }
            break;
        case 'select-taxonomy' :
            if (val) {
                arr.push({
                    type: 'tag',
                    value: val.label,
                    onClick: () => {
                    }
                });
            }
            break;
        case 'group' :
            if (val) {
                arr.push({
                    type: 'group',
                    value: val,
                    onClick: () => {
                    }
                });
            }
            break;
        case 'multi-select-taxonomy' :
            if (val) {
                for (let k = 0; k < val.length; k++) {
                    arr.push({
                        type: 'tag',
                        value: val[k].label,
                        onClick: () => {
                        }
                    });
                }
            }
            break;
    }

    return arr;
}

function getCFValue(f_id, data) {
    if (!data.custom_fields[f_id]) {
        return null;
    } else {
        return data.custom_fields[f_id];
    }
}

function getFieldPin(full_field, data) {
    const {name, f_id, type} = full_field;
    return {
        label: name,
        items: getFieldPinItems(type, getCFValue(f_id, data), full_field)
    }
}

export function buildMemberPins(community, member_data) {
    const {important_custom_fields, custom_fields} = community;

    if (important_custom_fields.length === 0) {
        return [];
    }

    const items = buildFullFields(important_custom_fields, extractFieldsData(custom_fields));

    return items.map((fi, k) => {
        return getFieldPin(fi, member_data);
    });
}