import React from 'react';

function buildCardMeta(data,stats) {
    let meta = [];

    if(stats&&stats.count>-1) {
        meta.push({
            type: "members-count",
            value: `${stats.count} Interested`
        });
    }

    return meta;
}
export function buildInterestProfileCard(data,features,stats) {
    return {
        type: 'interest',

        appearance: {
            show_color: true,

            cover_photo: "",
            cover_photo_color: "",

            block_initial: data.name.charAt(0).toUpperCase(),
            block_color: data.color?data.color:"",

            emoji: data.emoji, // group and interest only
            event_date: null,

            profile_picture: data.profile_picture===''?"":data.profile_picture,
            profile_picture_color: data.profile_picture_color===''?'':data.profile_picture_color
        },

        main: {
            emoji: data.emoji,
            title: data.name,
            status: {
                emoji: '',
                text: ''
            },
            subtitle: [

            ],
            description: '',
            meta: buildCardMeta(data,stats),
        },

        actions: [

        ],

        rich_meta: {
            primary: null,
            secondary: null,
            tertiary: null
        },

        stats: [

        ]
    }
}