import React, {useContext} from "react";
import CommunitySearch from "../../../../app/community/home/CommunitySearch";
import {CommunityContext} from "../../../../app/community/community-context";

function parseID(val) {
    const split = val.split('-');
    return split[1];
}

export const MultiMemberSelect = ({value={},_allowed=null,_exclusions={members:{}},onChange,onBlur}) => {
    const community = useContext(CommunityContext);
    // value could be a string, but needs to come in as a object for multi mode
    // needs to be an object with more data for the child
    // this is the processing step for single member, need to take the string and convert it to a format for the platforms
    return <CommunitySearch _allowed={{members:_allowed}} community_uid={community.uid} isMultiMember isMulti={true} onBlur={onBlur} key={'multi-member-search'} selected={value} onChange={(val)=>{
        let values = {};
        if(val===null) {

        } else {
            for(let i=0;i<val.length;i++) {
                values[parseID(val[i].value)] = true;
            }
        }
        onChange(values);
    }} searchable={['members']} exclusions={_exclusions} />;
};