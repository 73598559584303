import React, {useState} from "react";
import {OnboardingActions, OnboardingTitle} from "../../components";
import {OnboardingHelpSection, OnboardingHelpToggle} from "../../components/help";

const mock = {
    title: 'Example large title',
    subtitle: 'This is an example subtitle'
};

export function OnboardingTemplateStandard({
                                               title = mock.title,
                                               onConfirm = () => {},
                                               confirm_text = '',
                                               onCancel = () => {},
    fns = {},
                                               help = null,
    component = <div></div>,
                                               cancel_text = '',
                                               subtitle = mock.subtitle
                                           }) {
    const [show_help,setShowHelp] = useState(false);

    return <div className="relative flex flex-col space-y-4 h-full p-4 sm:p-8">
        <div className="flex-none">
            <OnboardingTitle title={title} subtitle={subtitle}/>
        </div>
        <div className="flex-grow relative">
            {component}
        </div>
        <div className="flex-none">
            <OnboardingActions onConfirm={()=>onConfirm({},fns)} confirm_text={confirm_text} cancel_text={cancel_text} onCancel={()=>onCancel({},fns)} />
        </div>
        {show_help&&<OnboardingHelpSection comp={help} />}
        {help&&<OnboardingHelpToggle on={show_help} onClick={()=>setShowHelp(!show_help)} />}
    </div>
}