import {account_status_filtering_options, data_quality_filtering_options} from "./filters/add-filter";
import {social_fields_array} from "../reorganize/profile-fields-section";
import {account_status_tags, data_integrity_tags} from "../../playground/advanced-table/wrapper";
import {countries_array} from "../../data/general/countries";

export function at_getFieldValue(field,data) {
    if(!field) {
        console.error("ERROR",field,data);
        return "";
    }
    const split = field.split('.');
    const len = split.length;
    if(len === 1) {
        return data[split[0]];
    } else if(len === 2) {
        return data[split[0]][split[1]];
    } else {
        return data[split[0]][split[1]][split[2]];
    }
}


function at_getKeysFromArrayObjects(arr, key) {
    let obj = {};
    for (let i = 0; i < arr.length; i++) {
        obj[arr[i][key]] = true
    }
    return obj;
}

function canEditCustomField(type) {
    return (type==='select'||type==='number'||type==='text') ? true : false;
}

export function at_buildFinalProperties(current = [{id: 'name', active: true}], base = {name: {}}, custom, defs, community_data) {
    let final = current;
    const map_current_ids = at_getKeysFromArrayObjects(current, 'id');

    const all_entries = Object.entries(base);

    for (let i = 0; i < all_entries.length; i++) {
        const [id, item] = all_entries[i];
        if (!map_current_ids[id]) {
            // not already defined in our view
            final.push({
                id,
                col_width: item.col_width,
                active: item.default_on || item.always_on ? true : false
            })
        }
    }

    // add relevant social fields
    social_fields_array.forEach(sf=>{
        if(!community_data.preferences.members[`hide_${sf}`]) {
            final.push({
                id: `social.${sf}`,
                label: social_field_label[sf],
                options: {
                    sub_type: sf
                },
                type: sf==='website'?"url":"social-url",
                editable: true,
                col_width: 150,
                active: false
            });
        }
    })


    const custom_fields = Object.keys(custom);
    for (let i = 0; i < custom_fields.length; i++) {
        const id = custom_fields[i];
        if (!map_current_ids[id]) {
            final.push({
                id: `custom_fields.${id}`,
                custom: true,
                editable: canEditCustomField(custom[id].type),
                options: custom[id].options,
                choices_map: custom[id].choices_map,
                type: custom[id].type,
                label: custom[id].label,
                col_width: 150,
                active: false
            })
        }
    }

    return final;
}

export function at_combineViewProperties(v, all_props, custom_props, definitions, community_data) {
    let new_view = Object.assign({}, v);
    new_view.properties = at_buildFinalProperties(v.properties, all_props, custom_props, definitions, community_data);

    if(!new_view.type) {
        new_view.type = "custom";
    }

    if(!new_view.filters) {
        new_view.filters = [];
    }
    if(!new_view.sort) {
        new_view.sort = [];
    }
    // also add custom fields to group and sort capabilities

    return new_view;
}

function buildOptions(member_types) {
    return Object.entries(member_types).map(([id,ent])=>{
        return {
            label: ent.singular,
            id: id
        }
    });
}

const social_field_label = {
        "eth_address": "Eth Address",
    "twitter": "Twitter",
    "facebook": "Facebook",
    "website": "Website",
    "instagram": "Instagram",
    "discord": "Discord",
    "github": "Github",
    "linkedin": "LinkedIn",
    "skype": "Skype",
    "reddit": "Reddit"
};

export function at_buildFullDefinition(base,custom_fields,member_types,community_data) {
    let defs = Object.assign({},base);

    let cf = Object.entries(custom_fields);
    let sort_addons = {};
    let group_addons = {};
    let filter_addons = {};

    for(let i=0;i<cf.length;i++) {
        const [id,entry] = cf[i];

        if(entry.type==='checkbox') {
            filter_addons[`custom_fields.${id}`] = {
                label: entry.label,
                type: 'boolean',
                placeholder: 'true',
                field: `custom_fields.${id}`
            };
        } else if(entry.type==='number') {

            filter_addons[`custom_fields.${id}`] = {
                label: entry.label,
                type: 'number',
                placeholder: 'Number',
                field: `custom_fields.${id}`
            };
        } else if(entry.type==='select') {
            group_addons[`custom_fields.${id}`] = {
                label: entry.label,
                type: 'select',
                options: entry.choices_map,
                field: `custom_fields.${id}`,
                sort: `custom_fields.${id}`,
                sort_type: 'text',
                dir: 'asc'
            };

            filter_addons[`custom_fields.${id}`] = {
                label: entry.label,
                type: 'select',
                custom: true,
                placeholder: 'Select',
                field: `custom_fields.${id}`,
                options: Object.entries(entry.choices_map).map(([id,item])=>{return {value:id,label:item}}),
                map: entry.choices_map
            };
        } else if(entry.type==='multi-select') {

            filter_addons[`custom_fields.${id}`] = {
                label: entry.label,
                type: 'select',
                custom: true,
                placeholder: 'Select',
                field: `custom_fields.${id}`,
                options: Object.entries(entry.choices_map).map(([id,item])=>{return {value:id,label:item}}),
                map: entry.choices_map
            };
        } else if(entry.type==='select-taxonomy') {
            group_addons[`custom_fields.${id}`] = {
                label: entry.label,
                type: 'text',
                options: {},
                field: `custom_fields.${id}`,
                sort: `custom_fields.${id}`,
                sort_type: 'text',
                dir: 'asc'
            };

            filter_addons[`custom_fields.${id}`] = {
                label: entry.label,
                type: 'text',
                custom: true,
                placeholder: 'Text',
                field: `custom_fields.${id}`,
                options: [],
                map: {}
            };
        } else if(entry.type==='multi-select-taxonomy') {

            filter_addons[`custom_fields.${id}`] = {
                label: entry.label,
                type: 'text',
                custom: true,
                placeholder: 'Text',
                field: `custom_fields.${id}`,
                options: [],
                map: {}
            };
        } else if(entry.type==='text') {
            filter_addons[`custom_fields.${id}`] = {
                label: entry.label,
                type: 'text',
                custom: true,
                placeholder: 'Start typing..',
                field: `custom_fields.${id}`
            };
        } else if(entry.type==='textarea') {
            filter_addons[`custom_fields.${id}`] = {
                label: entry.label,
                type: 'text',
                custom: true,
                placeholder: 'Start typing..',
                field: `custom_fields.${id}`
            };
        } else if(entry.type==='url') {
            filter_addons[`custom_fields.${id}`] = {
                label: entry.label,
                type: 'text',
                custom: true,
                placeholder: 'Start typing..',
                field: `custom_fields.${id}`
            };
        } else if(entry.type==='member') {
            filter_addons[`custom_fields.${id}`] = {
                label: entry.label,
                type: 'text',
                custom: true,
                placeholder: 'Start typing..',
                field: `custom_fields.${id}`
            };
        } else if(entry.type==='mentor') {
            filter_addons[`custom_fields.${id}`] = {
                label: entry.label,
                type: 'text',
                custom: true,
                placeholder: 'Start typing..',
                field: `custom_fields.${id}`
            };
        } else if(entry.type==='mentees') {
            filter_addons[`custom_fields.${id}`] = {
                label: entry.label,
                type: 'text',
                custom: true,
                placeholder: 'Start typing..',
                field: `custom_fields.${id}`
            };
        }
    }

    defs.sort_options = {
      ...defs.sort_options,
      ...sort_addons
    };

    defs.group_options = {
        ...defs.group_options,
        ...group_addons
    };

    defs.filter_options = {
        ...defs.filter_options,
        ...filter_addons
    };

    // populate member type
    defs.filter_options['member_type'].options = buildOptions(member_types);
    defs.properties['member_type'].options = buildOptions(member_types);
    defs.filter_options['account_status'].options = [...account_status_filtering_options];
    defs.properties['account_status'].options = [...account_status_tags];
    defs.filter_options['data_integrity'].options = [...data_quality_filtering_options];
    defs.properties['data_integrity'].options = [...data_integrity_tags];

    defs.properties['address.country'].options = [...countries_array.map(c=>{
        return {
            value: c.value,
            text: c.label
        }
    })];

    social_fields_array.forEach(sf=>{
        if(!community_data.preferences.members[`hide_${sf}`]) {
            defs.filter_options[`social.${sf}`] = {
                type: 'text',
                    label: social_field_label[sf],
                    field: `social.${sf}`
            };
        }
    })

    return defs;
}

export function at_array_move(arr, old_index, new_index) {
    if (new_index >= arr.length) {
        var k = new_index - arr.length + 1;
        while (k--) {
            arr.push(undefined);
        }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    return arr;
}

export function at_updateSort(sarr, action, field, defs) {
    let to_return = sarr;
    if (action === 'reverse-dir') {
        const sort_index = sarr.findIndex(x => x.field === field);
        const current_dir = to_return[sort_index].dir;
        to_return[sort_index].dir = current_dir === 'desc' ? 'asc' : 'desc';
    } else if (action === 'add-sort') {
        const type_field = field.indexOf('.')!==-1?field.split('.')[1]:field;
        to_return.push({
            field,
            type: defs.sort_options[type_field].type,
            dir: 'asc'
        })
    } else if (action === 'remove-sort') {
        const sort_index = sarr.findIndex(x => x.field === field);
        to_return.splice(sort_index, 1);
    }
    return to_return;
}