import React, {useContext, useEffect, useState} from 'react';
import Modal from "../../../library/components/old/modal";
import styled from "styled-components";
import {PlusIcon} from "@heroicons/react/solid";
import {roles_placeholders, roles_placeholders_len} from "../../../library/data/unaty/roles-placeholders";
import {CUSTOM_NANOID} from "../../../config/defaults";
import {Button} from "../../../library/components/core";
import Field from "../../../library/components/old/field";
import {CommunityContext} from "../community-context";

const RowGrid = styled.div`
    display: grid;
    grid-template-columns: 2.5fr 2fr;
    grid-gap: 12px;
    margin-bottom: 6px;
    > div {
        margin-top: 0 !important;
    }
`;

function getPlaceholder(num) {
    const index = num%roles_placeholders_len;
    return roles_placeholders[index];
}

const RoleRow = ({name,assignee,emoji,community_uid,id,valid,num,first=false,onBlur,onChange}) => {
    const pd = getPlaceholder(num);
    return <RowGrid first={first}>
      <Field success={valid} type={"text-emoji"} placeholder={pd.name} onBlur={()=>{onBlur('name');}} label={first&&'Name'} onChange={(v)=>{onChange(id,'name',v)}} value={{name,emoji}} />
      <Field community_uid={community_uid} success={valid} type={"member"} placeholder={""} onBlur={()=>{onBlur('assignee');}} label={first&&'Assignee'} onChange={(v)=>{onChange(id,'assignee',v)}} value={assignee} />
    </RowGrid>
};

function getBlankRole(name='',emoji='',assignee='') {
    return {
        name,
        emoji,
        assignee,
        purpose: '',
        valid: false
    }
}

function generateInits(rows,prop_data) {
    let init_roles = [],init_data={};

    for(let i=0;i<rows;i++) {
        const id = CUSTOM_NANOID();
        init_roles.push(id);
        if(prop_data[i]) {
            init_data[id] = getBlankRole(prop_data[i].name,prop_data[i].emoji,prop_data[i].assignee);
        } else {
            init_data[id] = getBlankRole();
        }
    }

    return {init_roles, init_data};
}

export function RolesCreateMany({
                                    loading = false,
                                    max_rows = -1,
                                    start_rows = 1,
                                    show_add_roles = true,
                                    onProceed = ()=>{},
                                    initial = [],
                                    onClose = ()=>{},
    updateParent=()=>{}
                                }) {
const community = useContext(CommunityContext);
const community_uid = community.uid;
    const {init_roles, init_data} = generateInits(start_rows<initial.length?initial.length:start_rows,initial);

    const [roles,setRoles] = useState(init_roles);

    const [data,setData] = useState(init_data);

    useEffect(function () {
        updateParent(data);
    }, [data]);

    function handleBlur(field_id) {
        let nd = {...data};
        const entries = Object.entries(data);
        for(let i=0;i<entries.length;i++) {
            const [id,role] = entries[i];

            nd[id].valid = role.name.length>1;
        }

       // const fnm = getFinalMembers(Object.values(nd));

        setData(nd);
    }

    function handleAddRole() {
        const new_id = CUSTOM_NANOID();
        setRoles([...roles,new_id]);
        let nd = {...data};
        nd[new_id] = getBlankRole();
        setData(nd);
    }

    function handleDeleteRole(id) {
        let nm = [...roles];
        const index = roles.findIndex(a=>a===id);
        nm.splice(index,1);
        setRoles(nm);
        let nd = {...data};
        delete nd[id];
        setData(nd);
    }

    function handleChange(id,field,value,cb=()=>{}) {
        let nd = {...data};

        if(typeof value==='object') {
            const keys = Object.keys(value);
            keys.forEach(k=>{
                nd[id][k] = value[k];
            })
        } else {
            nd[id][field] = value;
        }
        setData(nd);

        cb();
    }

    const valid_count = Object.values(data).filter(a=>a.valid).length;

    const reached_max_rows = max_rows === -1 ? false : roles.length>(max_rows-1);

    const body = <div>
        {roles.map((id,i)=>{
            const role = data[id];
            return <RoleRow community_uid={community_uid} emoji={role.emoji} id={id} key={i} valid={role.valid} onBlur={handleBlur} num={i} first={i===0} name={role.name} assignee={role.assignee} onChange={handleChange} />
        })}
        {show_add_roles&&!reached_max_rows&&<div className="pt-1">
            <Button minimal text="Add Role" left_icon={<PlusIcon/>} onClick={()=>handleAddRole()} />
        </div>}
    </div>;

    return <Modal title={"Add Multiple Roles"} size='medium' body={body} footerRight={<div>
        <Button loading={loading} onClick={onProceed} intent="success" disabled={valid_count===0} text={`Add ${valid_count} Role${valid_count===1?"":"s"}`} />
    </div>} footerLeft={null}
                  onClose={onClose} open={true} />
}