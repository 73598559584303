import React from 'react';
import styled from "styled-components";
import {CheckIcon} from '@heroicons/react/solid';

import {LTMap} from "../../../icons/layouts/LTMap";
import {CalendarIcon, MenuAlt2Icon, TableIcon, ViewGridIcon} from "@heroicons/react/outline";

export const table_layouts = {
    gallery: {
        label: 'Gallery',
        id: 'gallery',
        desc: 'See large cards',
        icon: <ViewGridIcon />,
        features: {
            sort: true,
            filter: true,
            group: false
        }
    },
    /*
        map: {
            label: 'Map',
            id: 'map',
            desc: 'See a map view',
            icon: <LTMap/>,
            features: {
                sort: false,
                filter: true,
                group: false
            }
        },


        table: {
            label: 'Table',
            id: 'table',
            desc: 'See things in an ordered table',
            icon: <TableIcon />,
            features: {
                sort: true,
                filter: true,
                group: false
            }
        },

     */
    list: {
        label: 'List',
        id: 'list',
        desc: 'See things in a neat list',
        icon: <MenuAlt2Icon />,
        features: {
            sort: true,
            filter: true,
            group: false
        }
    },
    calendar: {
        label: 'Calendar',
        id: 'calendar',
        desc: 'Get a month overview',
        icon: <CalendarIcon />,
        features: {
            sort: true,
            filter: true,
            group: false
        }
    },
    /*
    board: {
        label: 'Board',
        id: 'board',
        desc: '',
        icon: <LTBoard/>,
        features: {
            sort: true,
            filter: true,
            group: false
        }
    },
    timeline: {
        label: 'Timeline',
        id: 'timeline',
        desc: '',
        icon: <LTTimeline/>,
        features: {
            sort: true,
            filter: true,
            group: false
        }
    }

     */
};

const Wrapper = styled.div`
  min-width: 300px;
`;

const Row = styled.div`
  position: relative;
  display: grid;
  cursor: pointer;
  grid-template-columns: 24px 1fr;
  grid-gap: 8px;
     padding: 7px 16px 7px 12px;
`;

const IconBox = styled.div`
  padding: 2px;
  > svg {
    height: 20px;
  }
`;

const Title = styled.div`
  font-size: 14px;
    font-weight: 500;
`;

const Desc = styled.div`
  font-size: 12px;
`;

const Selected = styled.div`
  position: absolute;
    right: 8px;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
  > svg {
    height: 18px;
  }
`;

export function ATLayoutList({selected='list',onChange,layouts=['list']}) {
// onChange
    return <Wrapper>
        {layouts.map((l)=>{
            const data = table_layouts[l];
            return <Row onClick={()=>onChange('layout', l)} className='hover-bkg-1' key={l}>
                <IconBox className='text-gray-800'>
                    {data.icon}
                </IconBox>
                <div>
                    <Title className='text-gray-900'>
                        {data.label}
                    </Title>
                    <Desc className='text-gray-700'>
                        {data.desc}
                    </Desc>
                </div>
                {selected===l&&<Selected className='text-gray-900'><CheckIcon/></Selected>}
            </Row>;
        })}
    </Wrapper>
}