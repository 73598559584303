import React from 'react';
import styled from "styled-components";
import {LockClosedIcon} from '@heroicons/react/solid';
const Icon = styled.div`
      
    > svg {
        height: 18px;
    }
`;


export function IconTag({label="Closed",intent="success",icon=<LockClosedIcon/>}) {
    return  <div className="pl-2 cursor-pointer pr-3 items-center py-1 bg-white shadow-sm border border-solid border-gray-100 rounded-full inline-flex">
        <Icon className={`text-${intent}`}>
            {icon}
        </Icon>
        <div className="font-semibold text-gray-700 text-sm pl-1.5">
            {label}
        </div>
    </div>;
}