import React from 'react';
import {PlusIcon} from '@heroicons/react/solid';
import styled from "styled-components";

const Footer = styled.div`
    padding: 8px 12px;
   display: flex;
   border-bottom-left-radius: 6px;
   border-bottom-right-radius: 6px;
   overflow: none;
   cursor: pointer;
   margin-top: 2px;
`;

const AddIcon = styled.div`
    width: 20px;
    display: flex;
    margin-right: 6px;
    align-items: center;
    justify-content: center;
   > svg {
    height: 16px;
   }
`;

export const ATSwitchBox = styled.div`
    display: flex;
    align-items: center;
        height: 30px;
   > label {
    margin-bottom: 0 !important;
    padding-bottom: 0;
   }
`;

export const ViewNameInput = styled.input`
box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(60, 66, 87, 0.16) 0px 0px 0px 1px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px;
    
    border-radius: 4px;
    
    transition: border 80ms ease-out,box-shadow 80ms ease-out;
    box-sizing: border-box;
        color: #112D42;
      padding: 5px 8px 5px 8px;
    height: 30px;
    font-size: 15px;
    line-height: 1.33333333;
    width: 100%;
    border: none;
    :focus {
        outline: none !important;
    }
    
    :focus-within {
    box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(58, 151, 212, 0.36) 0px 0px 0px 4px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(60, 66, 87, 0.16) 0px 0px 0px 1px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px;
        
    }
`;

export function ATPopoverAddItem({onClick,icon=true,text='Add Property'}) {

    return <Footer onClick={onClick} className='border-top hover-bkg-1 text-sm color-link'>
        {icon&&<AddIcon >
            <PlusIcon/>
        </AddIcon>}
        <div>
            {text}
        </div>
    </Footer>
}

const Meta = styled.div`
 margin-right: 5px;
`;

const Title = styled.div`
   font-weight: 600;
   > div {
   height: 18px;
    margin-left: 2px;
    > div:nth-child(1) {
    >svg {
    
   height: 18px;
    }
    }
     > div:nth-child(2) {
    
    }
   }
`;

const TitleBox = styled.div`
   display: flex;
`;

const TitleWrapper = styled.div`
  padding: 10px 12px 6px 12px;
    display: flex;
`;

export function ATPopoverTitle({meta,title}) {
    return <TitleWrapper >
        <TitleBox className='text-gray-900 text-sm'>
        {meta&&<Meta>{meta}</Meta>}
        <Title className="text-sm">{title}</Title>
        </TitleBox>
    </TitleWrapper>
}

export const ATTextAction = styled.div`
height: 28px;
display: flex;
align-items: center;
font-size: 14px;
padding: 0 6px;
border-radius: 4px;
color: ${props=>props.active?'#357ebf':'#808390'};
font-weight: ${props=>props.active?'500':'400'};
box-shadow: ${props=>props.focus?'rgba(0,0,0,0) 0px 0px 0px 0px, rgba(0,0,0,0) 0px 0px 0px 0px, rgba(0,0,0,0) 0px 0px 0px 0px, rgb(60 66 87 / 36%) 0px 0px 0px 1px, rgba(0,0,0,0) 0px 0px 0px 0px, rgba(0,0,0,0) 0px 0px 0px 0px, rgba(0,0,0,0) 0px 0px 0px 0px':''};
cursor: pointer;
     :hover {
     background-color: ${props=>props.active?'#e6f2fb':props.focus?'#ffffff':'#dcdde2'};
    }
    
> svg {
    height: 20px;
}
`;

function getSize(size) {
    if(size==='xxxl') {
        return '700px';
    } else if(size==='xxl') {
        return '600px';
    } else if(size==='xl') {
        return '500px';
    } else if(size==='large') {
        return '400px';
    } else if(size==='small') {
        return '260px';
    } else {
        return '320px';
    }
}

const Wrapper = styled.div`
  background: #ffffff;
   width: ${props=>getSize(props.size)};
   box-shadow: 0 0 0 1px rgba(0,0,0,.05), 0 1px 3px 0 rgba(0,0,0,.1), 0 1px 2px 0 rgba(0,0,0,.06) !important;
   border-radius: 6px;
   overflow: hidden;
`;

export function ATPopoverContents({children,size='medium'}) {
    return <Wrapper size={size}>
        {children}
    </Wrapper>
}

const OptionWrapper = styled.div`
   
`;

export function ATPopoverOption({children}) {
    return <OptionWrapper>
        {children}
    </OptionWrapper>
}

const ATAFWrapper = styled.div`
       padding: 10px 12px;
    display: grid;
    grid-template-columns: 1fr auto;
`;

const FlexWrap = styled.div`
   display: flex;
`;


export function ATActionsFooter({children,secondary,primary}) {
    return <ATAFWrapper>
        <div>
            {children}
        </div>
        <FlexWrap>
            {secondary&&<div className="flex-grow" style={{marginRight: '6px'}}>
                {secondary}
            </div>}
            {primary}
        </FlexWrap>
    </ATAFWrapper>
}

export const ATAction = styled.div`
    height: ${props=>props.small?'28px':'32px'};
    cursor-pointer;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: ${props=>props.small?'28px':'32px'};
    > svg {
        height: 18px;
    }
`;

export const ATPopoverDivider = styled.div`
height: 1px;
margin: 4px 0;
background: #efefef;
`;

export const ATPopoverAction = styled.div`
display: grid;
padding: 6px 10px;
cursor: pointer;
grid-template-columns: ${props=>props.has_icon?'18px':''} 1fr;
grid-gap: 8px;
    > div:nth-child(1) {
   
    display: flex;
    align-items: center;
        > svg {
            height: 16px;
        }
    }
    > div:nth-child(2) {
   font-weight: 500;
    display: flex;
    align-items: center;
    }    
`;

export const HighlightText = styled.span`
    padding: 2px 4px;
    margin: -2px -4px;
    border-radius: 4px;
    background: #ffffc7;
    `;