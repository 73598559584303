import React, {useState, useEffect, useContext} from 'react';
import {buildUserProfileCard} from "./build-profile-card";
import {ProfileCard} from "../../../library/components/reorganize/profile-card";
import {Button, EntityHeader, Tabs} from "../../../library/components/core";
import {authFetch} from "../../../config/network";
import {GlobalContext} from "../../global/global-context";
import {EditProfileMobile} from "../../../library/components/edit-profile-mobile";
import {useIsMobile} from "../../global/global-router";
import {SubtitleMetaLink} from "../../../library/components/old/subtitle-meta-link";
import {SimplePageGridDivider} from "../../community/group/simple-page-divider";
import {StandardPageHeight} from "../../../library/playground/standard-page-framework";

export default function GlobalUserProfile(props) {
    const global = useContext(GlobalContext);

    const [editing, setEditing] = useState(false);
    const [saving, setSaving] = useState(false);
    const [loading_cp, setLoadingCP] = useState(false);
    const [loading_pp, setLoadingPP] = useState(false);
    const [update, setUpdate] = useState(global.user);
    const [updated_bio, setUpdatedBio] = useState(false);
    const [updated_fields, setUpdatedFields] = useState([]);
    let card = {}, actions = {}, context = {}, features = {};
    card = buildUserProfileCard(update);

    const is_mobile = useIsMobile();

    useEffect(function () {

    }, [update]);

    function startEditing() {
        setEditing(true);
    }

    function saveChanges() {
        if (updated_fields.length === 0 && !updated_bio) {
            setEditing(false);
            return;
        }

        setSaving(true);

        let payload = {
            about: update.about,
            social: update.social,
            contact: update.contact,
            address: update.address,
            birthday: update.birthday,
            community_uid: '',
            id: global.user_uid
        };

        payload.about.bio = update.about.bio;

        const res = () => {
            setSaving(false);
            setUpdatedBio(false);
            setEditing(false);
            setUpdatedFields([]);
            global.addToast({text: "Your changes were saved", intent: 'success'});
        };

        payload._updated_fields = updated_fields;

        if (updated_bio) {
            payload.about.bio = update.about.bio;
            payload._updated_fields.push('about.bio');
        }
        authFetch('/users/update-own-profile', res, res, "POST", {payload});
    }

    actions = {
        setCoverPhoto: (url, color) => {
            let payload = {
                url,
                id: global.user_uid,
                color
            };
            setLoadingCP(true);
            const res = () => {
                setTimeout(function () {
                    setUpdate({
                        ...update,
                        cover_photo: url,
                        cover_photo_color: color
                    });
                    setLoadingCP(false);
                }, 350)
            };
            authFetch('/users/set-cover-photo', res, res, "POST", {payload});
        },
        setProfilePicture: (url, color) => {

            let payload = {
                url,
                id: global.user_uid,
                color
            };
            setLoadingPP(true);
            const res = () => {
                setUpdate({
                    ...update,
                    profile_picture: url,
                    profile_picture_color: color
                });
                setLoadingPP(false);
            };
            authFetch('/users/set-profile-picture', res, res, "POST", {payload});
        },
        updateBio: (str) => {
            setUpdatedBio(true);
            let u = update;
            u['about']['bio'] = str;
            setUpdate({...u});
        },
        startEditing: startEditing.bind(this),
        saveChanges: saveChanges.bind(this)
    };
    context = {
        loading_pp,
        loading_cp
    };
    features = {
        edit_profile_picture: true,
        edit_cover_photo: true,
        can_manage: true
    };

    let save_changes_button = null;

    if (props.my_profile) {
        if (editing) {
            save_changes_button =
                <Button intent='success' onClick={saveChanges.bind(this)} text='Done Editing' loading={saving}/>;
        } else {
            save_changes_button = <Button intent='secondary' onClick={startEditing.bind(this)} text='Edit Profile'/>;
        }
        card.actions = [
            save_changes_button
        ];
    }

    card.main.subtitle.push(
        <SubtitleMetaLink key={"handle"} link={'#'} text={`@${update.handle}`}/>
    );

    return <StandardPageHeight content>

            {is_mobile ? null : <EntityHeader single title={update.name} actions={null}/>}

            <ProfileCard context={context} editing={editing} actions={actions} data={card} features={features}/>

        <div className="sticky px-4 border-b border-gray-200 pt-1.5 top-0 bg-white z-10">
            <div className="-mb-px">
                <Tabs onSelect={t => {

                }} active={"Profile"} tabs={["Profile"]} compact layout="entity"/>
            </div>
        </div>
        {editing && <EditProfileMobile
            actions={<div>
                {save_changes_button}
            </div>}
            important_fields={[]}
            saving={saving}
            meta={{}}
            onSaveChanges={() => saveChanges()}
            onCancel={() => {
                setEditing(false);
            }}
            display_sections={{}}
            layout={is_mobile ? "mobile" : "desktop"}
            init_member={update}
            type={"user"}
            metadata={{
                phone_services: {}, id: props.data.user_uid, update: (b, f, v) => {

                }
            }}
            phone_services={{phone_services: {}}}
            handleUpdate={(ne, fields_changed) => {
                setUpdate({...ne});
                setUpdatedFields([...fields_changed]);
            }}
        />}

    </StandardPageHeight>
}