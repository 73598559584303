import React, {useEffect, useState} from "react";

export function InlineInput({
    value = "",
    display = "",
    onChange = () => {},
                                bg_default = "bg-white"
                            }) {

    const [val,setVal] = useState(value);
    const [focused,setFocused] = useState(false);
    useEffect(function() {
      setVal(value);
    }, [value]);

    function updateParent() {
        onChange(val);
    }

    return <input onBlur={()=> {
        setFocused(false);
        updateParent()
    }} value={!focused&&display?display:val} onFocus={()=>setFocused(true)} onChange={e=>setVal(e.target.value)} style={{minWidth:'164px'}} className={`px-2 py-1 focus-standard rounded-md transition-colors w-full hover:bg-gray-200 border-transparent-imp ${bg_default} cursor-pointer border hover:border-gray-200`} />
}