import React from 'react';
const config = {
    "clientId": "<CLIENT_ID>",
    "apiKey": "<API_KEY>",
    "scope": "https://www.googleapis.com/auth/calendar",
    "discoveryDocs": [
        "https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest"
    ]
};
const SCOPES = 'https://www.googleapis.com/auth/calendar.readonly';

// CLIENT ID 530713394225-vl1n5imh6p8bsntkvbj96cr6ui4seed4.apps.googleusercontent.com
// SECRET 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
export function GoogleCalendarAuth() {

    return <div>

    </div>
}