import React, {Component} from "react";
import {Link} from "react-router-dom";
import {UnatySVG} from "../../../library/icons/unaty/logos";

const Hex = () => {
    return <li className="hex-grid__item">
        <div className="hex-grid__content"/>
    </li>;
};

function generateArr(num) {
    let to_return = [];
    for (let i = 1; i < num + 1; i++) {
        to_return.push(i);
    }
    return to_return;
}

const HexGrid = () => {
    const n = 96;
    const arr = generateArr(n);
    return <div className='hex-grid-container'>
        <ul className="hex-grid__list hex-highlight">
            {arr.map((num) => <Hex key={num}/>)}
        </ul>
    </div>;
};

export default function AuthFrame(props) {

    let {children, splash} = props;

    return <div className='un-authframe'>
        <div className='content'>
            <header>
                <Link to='/' className='logo'>
                    <UnatySVG/>
                </Link>
            </header>
            <main>
                {children}
            </main>
            <footer>
                <div className='footer-links'>
                    <Link to='/' className='link inverted'>© Unaty</Link>
                    <Link to='/terms' target='_blank' className='link inverted'>Terms of Service</Link>
                    <Link to='/privacy' target='_blank' className='link inverted'>Privacy Policy</Link>
                </div>
            </footer>
        </div>
        <HexGrid/>
    </div>;
}