
function getMinHeight(m, s, f) {
    if (s) {
        return '34px';
    } else if (f) {
        return '32px';
    } else {
        return m;
    }
}

function getBoxShadow(focus, s, f) {
    if (focus) {
        return 'rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(58, 151, 212, 0.36) 0px 0px 0px 4px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(60, 66, 87, 0.16) 0px 0px 0px 1px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px';
    } else if (s) {
        return '';
    } else if (f) {
        return '0 1px 2px 0 rgba(0, 0, 0, 0.05)';
    } else {
        return '0 1px 2px 0 rgba(0, 0, 0, 0.05)';
    }
}

function getFontSize(s, f) {
    if (s) {
        return '14px';
    } else if (f) {
        return '14px';
    } else {
        return '15px';
    }
}

export const advanced_select_styles = {
    singleValue: (base, state) => ({
        ...base,
        fontSize: getFontSize(false, false),
        fontWeight: '600',
        paddingLeft: state.pad_left ? '32px' : ''
    }),
    multiValue: (base) => ({
        ...base,
        borderRadius: '4px',
        background: '#444449'
    }),
    multiValueLabel: (base, state) => ({
        ...base,
        color: '#ffffff'
    }),
    menuPortal: (base) => ({
        ...base,
        zIndex: 99999
    }),
    menu: (base) => ({
        ...base,
        zIndex: 99999
    }),
    multiValueRemove: (base) => ({
        ...base,
        paddingLeft: '2px',
        background: 'transparent !important',
        color: '#b3b3b3',
        cursor: 'pointer'
    }),
    valueContainer: (base) => ({
        ...base,
        padding: '2px 6px'
    }),
    container: base => ({
        ...base
    }),
    clearIndicator: base => ({
        ...base,
        cursor: 'pointer'
    }),
    dropdownIndicator: base => ({
        ...base,
        padding: '2px'
    }),
    option: (base, state) => ({
        ...base
    }),
    control: (base, state) => ({
        ...base,
        paddingLeft: '0.5rem',
        borderColor: state.isFocused ? 'transparent !important' : 'rgba(212, 212, 216, 1) !important',
        boxShadow: getBoxShadow(state.isFocused, false, false),
        border: '1px solid rgba(212, 212, 216, 1)',
        minHeight: getMinHeight('40px', false, false)
    })
};

export const advanced_select_styles_minimal = (can_change) => {
    return {
        singleValue: (base, state) => ({
            ...base
        }),
        indicatorsContainer: base => ({
            ...base,
            display: 'none',
            height: '30px'
        }),
        multiValue: (base) => ({
            ...base,
            borderRadius: '8px',
            background: '#DEEBFC',
            "&:hover": {
                background: "#C6DCFE"
            },
        }),
        placeholder: (base) => ({
            ...base,
            fontSize: '0.875rem'
        }),
        multiValueLabel: (base, state) => ({
            ...base,
            fontSize: "0.875rem",
            paddingRight: "6px",
            color: '#34363b'
        }),
        menuPortal: (base) => ({
            ...base,
            zIndex: 99999
        }),
        menu: (base) => ({
            ...base,
            zIndex: 99999
        }),
        multiValueRemove: (base) => ({
            ...base,
            display: can_change?"":"none",
            paddingLeft: '2px',
            background: 'transparent !important',
            color: '#868c99',
            cursor: 'pointer'
        }),
        valueContainer: (base) => ({
            ...base,
            padding: 0
        }),
        container: base => ({
            ...base,
            pointerEvents: can_change?"":"none",
        }),
        clearIndicator: base => ({
            ...base,
            padding: '5px',
            cursor: 'pointer'
        }),
        dropdownIndicator: base => ({
            ...base,
            padding: '2px'
        }),
        option: (base, state) => ({
            ...base
        }),
        control: (base, state) => ({
            ...base,
            background: !can_change?"#f6f6f6":"",
            opacity: !can_change ? "0.8" : "",
            "&:hover": {
                background: !can_change ? "#f6f6f6" : state.isFocused ? "" : "#f0f0f0"
            },
            fontSize: "0.875rem",
            padding: "0 2px",
            borderRadius: "8px",
            minHeight: "30px",
            borderColor: "",
            boxShadow: "",
            border: 'none'
        })
    }
};