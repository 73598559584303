import React from "react";
import AdminRole, {AdminMemberPage} from "./index";
import {StandardPageHeight} from "../../../../library/playground/standard-page-framework";

export function AdminRoleWrapper(props) {
    let id = props.match.params.id;

    return <StandardPageHeight>
        <AdminRole scrollable={true} id={id} />
    </StandardPageHeight>
}