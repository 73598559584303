import React, {Component, useEffect, useRef, useState} from 'react';
import {handleIsValid} from "../../../utilities/handle-is-valid";
import {handleIsUnique} from "../../../utilities/handle-is-unique";

import {sanitizeHandle} from "../../../utilities/sanitize-handle";
import styled from "styled-components";
import {Field, Input} from "../../core";

const Frame = styled.div`
  position: relative;
`;


function getMeta(scope) {
    if (scope === 'community') {
        return '2-80 characters. Letters, numbers, dashes, and underscores only.';
    } else {
        return '5-80 characters. Letters, numbers, dashes, and underscores only.';
    }
}

function getLabel(type, change_mode) {
    if (type === 'community') {
        return 'Domain'
    }
    return change_mode ? "New Handle" : "Handle"
}

export default function HandleSelector({
    // check against
                                           current_handle = '',
                                           type = '',
                                           check_id = '',
                                           invalid,
                                           default_text = '',
                                           autoFocus = false,
                                           show_label = true,
                                           change_mode = false,
                                           scope,
                                           scope_id,
                                           setHandle,
                                           checkingHandle
                                       }) {
    const [handleVal, setHandleVal] = useState(default_text);
    const [valid, setValid] = useState(false);
    const [checking, setChecking] = useState(false);
    const [generating, setGenerating] = useState(false);
    const [checked, setChecked] = useState(false);
    const [meta_handle, setMetaHandle] = useState("");
    const [taken_handle, setTakenHandle] = useState("");
    const [taken, setTaken] = useState(false);

    const isFirstRun = useRef(true);

    useEffect(function () {
        // as long as it's invalid, keep trying to update
        if (isFirstRun.current) {
            isFirstRun.current = false;
            return;
        }

        if (default_text.length > 1 && !valid && !checking && !checked) {
            const handle_new = sanitizeHandle(default_text);
            if (handleIsValid(handle_new, scope)) {
                checkIfHandleExists(handle_new).then(() => {
                });
            }
        }
    }, [default_text, valid, checking]);

    function handleChange(v2, blur = false) {
        let v = v2.replace(/ /g, '');
        setHandleVal(v.toLowerCase().trim());
        setChecked(false);
        setTaken(false);
        setTakenHandle("");
        if (blur) {
            handleBlur();
        }
    }

    function handleBlur() {
        if (handleVal && handleVal === default_text) {
            // is initial
            return;
            setChecking(true);
            checkIfHandleExists(handleVal).then(() => {});
        } else if (!handleIsValid(handleVal, scope)) {
            setHandle('');
            setValid(false);
        } else {
            setValid(true);
            checkIfHandleExists(handleVal).then(() => {
            });
        }
    }

    function checkIfHandleExists(value, skip) {
        if (!skip) {
            setChecking(true);
            if (checkingHandle) {
                checkingHandle();
            }
        }

        if (skip && generating) {
         //
            return;
        } else if (skip && !generating) {
            setGenerating(true);
        }

        return handleIsUnique(value, type, scope, scope_id, check_id).then((taken) => {
            console.log('handleIsUnique',taken, value, type, scope, scope_id)
            console.log('current_handle',current_handle,value)
            // handleIsUnique true sean member community J62oUDAvKbZFS91QiaTj
          if (taken && value === default_text) {
                if (value.length > 3) {
                    let rand = Math.floor(Math.random() * (999 - 100 + 1) + 100);
                    checkIfHandleExists(`${value}-${rand}`, true);
                }
                return false;
            } else if (taken) {
                // let parent know
                const val = Math.floor(1000 + Math.random() * 9000);
                setTakenHandle(handleVal);
                let nh = handleVal?`${handleVal}-${val}`:value?`${value}-${val}`:`group-${val}`;
                setChecking(false);
                setMetaHandle(handleVal);
                setHandleVal(nh);
                setChecked(true);
                setTaken(true);
                setValid(false);
                setHandle('');
                checkIfHandleExists(`${nh}`, true);
            } else {
                setHandleVal(value);
                setChecking(false);
                setChecked(true);
                setTaken(false);
                setValid(true);
                setHandle(value);
            }
            return taken;
        });
    }

    const success = valid && checked && !taken;
    const error = (taken && checked);
    return <Frame>
        <Field help_text={getMeta(scope)} label={getLabel(type, change_mode)}>
            <Input intent={success ? "success" : error ? "danger" : ""} leading={type==='community'?{type: 'add-on', value: 'getunaty.com/'}:{type: 'text', value: '@'}}
                   custom={{minLength: 2, maxLength: 80}} type="handle" placeholder={"my-handle"} autoFocus={autoFocus}
                   value={handleVal} onBlur={handleBlur.bind(this)}
                   onChange={handleChange.bind(this)}/>
        </Field>

        {taken_handle &&
        <div className="red-error-message mt-3"><strong>@{meta_handle}</strong> is already taken</div>}
        {handleVal && checked && !taken && !valid && <div className="red-error-message mt-3">Handle is invalid</div>}
    </Frame>

}