import React, {useContext, useState} from "react";
// split out into steps
import {create_role_modal} from "../create-role";
import {AdvancedModal} from "../../../../library/components/reorganize/advanced-modal";
import {authFetch} from "../../../../config/network";
import {GlobalContext} from "../../../global/global-context";
import {RolesCreateMany} from "../create-many";
import {CUSTOM_NANOID} from "../../../../config/defaults";

function getInitial(dt_obj) {
    if (dt_obj.name) {
        return [
            {
                name: dt_obj.name,
                assignee: dt_obj.assignee,
                emoji: dt_obj.emoji,
            }
        ]
    } else if(dt_obj&&typeof dt_obj === 'object') {
        return Object.values(dt_obj)
    } else {
        return [];
    }
}

function getInitialData(initial) {
    let o = {};
    if (initial.length < 2) {
        let o = {};
        initial.forEach(a => {
            o[CUSTOM_NANOID()] = {
                name: a.name||"",
                assignee: a.assignee||"",
                emoji: a.emoji||"",
                purpose: a.purpose||""
            }
        })
        return o;
    }
    initial.forEach(a => {
        o[CUSTOM_NANOID()] = {
            name: a.name||"",
            assignee: a.assignee||"",
            emoji: a.emoji||"",
            purpose: a.purpose||""
        }
    })
    return o;
}

function hydratePhaseData(modal_data,data) {
    let state = {...modal_data.state};

    if(data) {
        const vals = Object.values(data);
        if(vals[0]) {
            state = {
                ...state,
                ...vals[0]
            }
        }
    }

    return {
        ...modal_data,
        state
    }
}

export function CreateRoleController({open, onConfirmed=()=>{}, initial, history, closeModal, context = {}}) {

    const [data, setData] = useState(getInitialData(initial));

    const global = useContext(GlobalContext);
    const [_loading, setLoading] = useState(false);
    const [phase, setPhase] = useState(initial && initial.length > 1 ? 'create-many' : 'about');

    function createRole(p) {
        setLoading(true);

        const payload = {
            ...p,
            member_id: context.member_id,
            community_uid: context.community_uid
        };

        authFetch("/roles/create", handleResponse, handleError, "POST", {payload});
    }

    function createManyRoles(roles) {
        setLoading(true);

        const payload = {
            roles,
            member_id: context.member_id,
            community_uid: context.community_uid
        };

        authFetch("/roles/create-many", handleResponse, handleError, "POST", {payload});
    }

    function handleResponse(resp) {
        if (!resp || !resp.data.id) {
            global.addToast({text: `Something went wrong..`, intent: 'danger'});
            setLoading(false);
            return;
        }
        actionHandler('created', resp.data);
        setLoading(false);
    }

    function handleError(error) {
        global.addToast({text: `Something went wrong..`, intent: 'danger'});
        setLoading(false);
    }

    function actionHandler(action, payload) {
        switch (action) {
            case 'proceed':
                createRole(payload);
                return;
            case 'create-many':
                setData({name: payload.name, assignee: payload.assignee, emoji: payload.emoji})
                setPhase('create-many');
                return;
            case 'cancel':
                closeModal();
                return;
            case 'close-modal':
                closeModal();
                return;
            case 'created':
                onConfirmed(payload);
                closeModal();
                return;
            default:
                return;
        }
    }

    if (!open) {
        return null;
    }

    if (phase === 'create-many') {
        const finitials = getInitial(data);
        return <RolesCreateMany start_rows={finitials.length>1?finitials.length:(finitials.length+1)} initial={finitials} onProceed={() => {
            createManyRoles(Object.values(data));
        }} updateParent={(dt) => {
            setData(dt);
        }} loading={_loading} onClose={() => closeModal()}/>
    }

    return <AdvancedModal _loading={_loading} context={context} actionHandler={(a, p) => actionHandler(a, p)}
                          phase_data={hydratePhaseData(create_role_modal,data)}/>
}