import {at_buildView} from "../../../../library/components/advanced-table/factory/view";
import {groupview_never_logged_in} from "./views/never-logged-in";
import {groupview_unreachable} from "./views/unreachable";

export function buildGroupDirectoryViews(can_manage_members,smart_views=[],default_view) {

    const default_filters = [

    ];

    const default_sort = [
        {
            dir: 'asc',
            type: 'text',
            field: 'about.last_name'
        }
    ];

    const default_member_props = [
        {
            id: 'account_email',
            active: true,
            col_width: 200
        },
        {
            id: 'member_type',
            active: true,
            col_width: 120
        },
        {
            id: 'birthday',
            active: true,
            col_width: 120
        },
        {
            id: 'contact.phone',
            active: true,
            col_width: 150
        },
        {
            id: 'address.city',
            active: true,
            col_width: 120
        },
        {
            id: 'address.country',
            active: true,
            col_width: 120
        },
    ];

    let obj = {
        starting: 'Default',
        // for rendering order
        views: [
            'Default'
        ],
        data: {
            'Default': default_view?default_view:at_buildView('Default', 'Group Members', 'gallery', default_filters, default_sort, default_member_props, 'member'),
            }
    };

    if(can_manage_members) {
        // add additional viewws
        const to_add = [
            {...groupview_never_logged_in},
            {...groupview_unreachable}
        ];
        to_add.forEach(it=>{
            obj.views.push(it.id);
            obj.data[it.id] = it;
        })
    }

    for(let i=0;i<smart_views.length;i++) {
        const sm = smart_views[i];
        obj.views.push(sm.config.id);
        obj.data[sm.config.id] = at_buildView(sm.config.id, sm.name, sm.layout, sm.config.filters, sm.config.sort, sm.config.properties, 'member', sm.config.group_by, sm.config.footer_calc, sm.emoji, 'custom');
    }


    return obj
}

function getFi(type,id) {

        return `groups.${id}`
}

export function group_buildDirectoryConfig(community,type="group",id) {
    // type
    return {
        type: 'member',
        general: {
            title: 'Members',
            emoji: '',
            icon: null
        },
        base_query: [
            // this should be an OR if true
            {
                fi: getFi(type,id),
                op: '==',
                va: true
            }
        ],
        selected_actions: [
            // export
        ],
        actions: [
            // invite, export all, import
        ],
        selected_more_options: [],
        more_options: [],
        view_options: {
            list: {
                label: 'List'
            },
            table: {
                label: 'Table'
            },
            gallery: {
                label: 'Gallery'
            }
        },

        features: {
            filter: true,
            group: true,
            edit_fields: community.member_access.manage_members,
            sort: true,
            can_sort: true,
            can_filter: community.member_access.manage_members,
            create_view: true,
            can_edit: false,
            can_select: false,
            search: false
        }
    };
}