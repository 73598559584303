import React from 'react';

function buildCardMeta(data) {
    let meta = [];

    return meta;
}
export function buildRoleProfileCard(data) {
    return {
        type: 'role',

        appearance: {
            show_color: true,

            cover_photo: data.cover_photo?data.cover_photo:'',
            cover_photo_color: data.cover_photo_color?data.cover_photo_color:'',

            emoji: '', // group only
            event_date: null,

            block_initial: data.name.charAt(0).toUpperCase(),
            block_color: data.color?data.color:"",

            profile_picture: data.profile_picture,
            profile_picture_color: data.profile_picture_color
        },

        main: {
            title: data.name,
            status: {
                emoji: '',
                text: ''
            },
            subtitle: [

            ],
            description: data.purpose,
            meta: buildCardMeta(data),
        },

        actions: [

        ],

        rich_meta: {
            primary: {
                type: 'parent', // networks (community), moderators (group), organizers (event), education (user/member)
                title: 'Parent Organization', // parent community
                items: [
                    {
                        image: 'https://firebasestorage.googleapis.com/v0/b/unaty-staging.appspot.com/o/profile_pictures%2Fzex6-vXgU_small?alt=media',
                        name: 'Accenture',
                        type: 'community',
                        handle: 'accenture'
                    }
                ]
            },
            secondary: {
                type: 'education', // networks (community), moderators (group), organizers (event), education (user/member)
                title: 'Education', // parent community
                items: [
                    {
                        image: 'https://firebasestorage.googleapis.com/v0/b/unaty-staging.appspot.com/o/profile_pictures%2Fq6bOcf2kH_small?alt=media',
                        name: 'Technische Universität München (TUM)',
                        type: 'community',
                        handle: 'tum'
                    }
                ]
            },
            tertiary: null
        },

        stats: [

        ]
    }
}