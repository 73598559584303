import React, {useContext, useEffect, useRef, useState} from 'react';
import {DevsWebhookModal} from "./manage";
import {ActionWithIcon, Button, InfoState, SectionHeader} from "../../../../../../library/components/core";
import {GlobalContext} from "../../../../../global/global-context";
import {CommunityContext} from "../../../../community-context";
import {uniqBy} from "lodash";
import {FirebaseDB} from "../../../../../../config/setup-firestore";
import {aa_formatDate} from "../../../../../../library/utilities/general/dates";
const page_size = 25;

const statuses_map = {
    'untested': 'Pending',
    'failed': 'Failed',
    'ok': 'Ok'
};

function Status({status_code}) {
    return <div className="uppercase inline-flex text-xs py-0.5 px-1 rounded-md bg-gray-100 font-medium text-gray-600">
        {statuses_map[status_code]}
    </div>
}

function WebhookListItem({active, status, id, url, events, family, ts, onClick}) {
    return <div onClick={onClick} className="relative cursor-pointer group">
        <div className="grid border-b px-2.5 gap-4 border-b shared-bottom-border py-2"
             style={{gridTemplateColumns: '1fr auto auto'}}>
            <div className="text-sm truncate font-medium text-gray-800">
                {url}
            </div>
            <div className="text-sm font-medium text-gray-800">
                <Status status_code={status} />
            </div>
            <div className="flex justify-end items-center text-xs text-gray-600">
                {events.split(" ").length} events
            </div>
        </div>
        <div
            className={`absolute top-1 w-1 left-0 bottom-1 rounded ${active ? "bg-selection" : "group-hover:bg-gray-400"}`}/>
    </div>
}

function WebhooksList({setSelected, has_more, loading, onLoadMore, selected, items}) {

    return <div>
        <div></div>
        <div>
            {items.map((webhook,key) => {
                return <WebhookListItem active={selected===webhook.id} key={`log-${webhook.id}`} onClick={() => setSelected(webhook.id)} {...webhook} />
            })}
            <div className="p-2.5">
                {has_more&&!loading&&<ActionWithIcon text="Load more" onClick={onLoadMore}/>}
            </div>
        </div>
    </div>
}

function Metadata({title, val}) {
    return <div className="grid py-1 grid-cols-5">
        <div className="text-gray-500 text-sm col-span-2">{title}</div>
        <div className="text-gray-800 text-sm col-span-3">{val}</div>
    </div>
}

function CodeBlock({json}) {
    const parsed = JSON.parse(json).reverse();
    return <div className="">
        <pre className="text-xs p-2 bg-gray-100 border-gray-300 rounded-md overflow-y-scroll text-left"
             style={{maxHeight: '400px'}}>{JSON.stringify(parsed, undefined, 2)}</pre>
    </div>
}

function SelectedWebhook({
                           id,
    status,
                             created_at,
                             editWebhook = ()=>{},
    url,
    description,
                             events = "",
                             status_activity = []
                       }) {


    return <div className="space-y-4">
        <div>
            <SectionHeader title={url} padded={false} action={<ActionWithIcon text="Edit" inverse onClick={()=>editWebhook(id)} />} />
            {description&&<div className="text-sm text-gray-600">{description}</div>}
        </div>
        <div>
            <Metadata title="ID" val={`wh_${id}`}/>
            <Metadata title="Status" val={<Status status_code={status} />}/>
            <Metadata title="Events" val={events.split(" ").join(", ")}/>
            <Metadata title="Created" val={aa_formatDate(created_at).date_formatted}/>
        </div>
        <div>
            <SectionHeader size="lg" padded={false} title={"Activity"}/>
            <div className="pt-2">
                <CodeBlock json={JSON.stringify(status_activity)}/>
            </div>
        </div>
    </div>
}

function processDocs(docs) {
    return docs.map(doc => {
        let id = doc.id;
        const dt = doc.data();
        return {
            ...dt,
            id
        }
    })
}

export function DevsWebhooks(props) {
    const global = useContext(GlobalContext);
    const community = useContext(CommunityContext);

    const [modal,setModal] = useState(null);
    const [modal_data,setModalData] = useState(null);

    const [loading, setLoading] = useState(true);
    const [selected, setSelected] = useState(null);
    const [has_more, setHasMore] = useState(false);
    const [data, setData] = useState(null);
    const last_ref = useRef(null)

    useEffect(function () {
        getWebhookItems()
    }, []);

    function getWebhookItems(lr) {
        if(lr&&loading) {
            return
        }
        if(!lr) {
            setData(null);
        }

        getWebhooks(lr)
            .then((snap) => {
                if (snap.size > 0) {
                    last_ref.current = snap.docs[(snap.size - 1)];
                }

                let nd = data && lr ? data : [];
                setData(uniqBy([...nd, ...processDocs(snap.docs)],'id'));
                setHasMore(snap.size === page_size);
                setLoading(false);
            })
    }

    async function getWebhooks(lr) {
        let base = FirebaseDB
            .collection('communities')
            .doc(community.uid)
            .collection('webhooks')
            .orderBy('created_at', 'desc')

        if (lr) {
            base = base.startAfter(lr);
        }
        base = base.limit(page_size);
        return await base
            .get()
            .then(snap => {
                return snap;
            })
    }

    function getMoreWebhooks() {
        setLoading(true);
        getWebhookItems(last_ref.current)
    }

    const selected_data = selected && data ? (data&&data.filter(a => a.id === selected)[0]?data.filter(a => a.id === selected)[0]:{}) : {};

    const can_create_webhook = data && data.length<10;

    return <div className="grid gap-4 grid-cols-2">

        <div className="p-4">
            <SectionHeader title="Webhooks" padded={false} action={can_create_webhook&&<ActionWithIcon text="Add Webhook" inverse onClick={()=>setModal('add-webhook')} />} />
            {data&&data.length===0&&<div>
                <InfoState subtitle="No webhooks found" />
            </div>}
            <WebhooksList selected={selected} setSelected={(sid) => setSelected(sid)} has_more={has_more} items={data?data:[]}
                        onLoadMore={() => getMoreWebhooks()} loading={loading}/>
        </div>
        <div className="p-4">
            {selected && selected_data.url && <SelectedWebhook editWebhook={(id)=>{
                setModalData(data.filter(a => a.id === id)[0]);
                setModal('edit-webhook');
            }} {...selected_data} />}
        </div>
        {modal==='add-webhook'&&<DevsWebhookModal onConfirm={()=>{
        setModal(null);
            getWebhookItems()
        }} onClose={()=> {
            setModal(null)
        }} />}
        {modal==='edit-webhook'&&<DevsWebhookModal onConfirm={()=>{
            setModal(null);

            getWebhookItems()
        }} type="update" init_data={modal_data} onClose={()=> {
            setModal(null)
        }} />}
    </div>
}
