import {
    ArchiveIcon, CakeIcon, CalendarIcon, CheckCircleIcon,
    FlagIcon,
    GlobeAltIcon,
    LocationMarkerIcon,
    LoginIcon,
    OfficeBuildingIcon, TagIcon
} from "@heroicons/react/solid";
import {countries} from "../general/countries";
import React from "react";
import {genders_object} from "../general/genders";

export const segment_conditions = {
    'address.zip_code': {
        type: 'string',
        prefix: 'ZIP code',
        suffix: '',
        default_operator: 'starts_with',
        default_value: '',
        icon: <LocationMarkerIcon />
    },
    'profile_updated_at': {
        type: 'date',
        default_operator: 'is_after',
        prefix: 'Updated profile',
        suffix: '',
        default_value: '',
        icon: <CheckCircleIcon />
    },
    'about.gender': {
        type: 'multi-select',
        prefix: 'Sex',
        suffix: '',
        default_operator: 'is',
        default_value: '',
        icon: <LocationMarkerIcon />,
        options: Object.entries(genders_object).map(([id,label])=>{
            return {
                id,
                label
            }
        })
    },
    'age': {
        type: 'number',
        prefix: 'Age',
        suffix: '',
        default_operator: 'greater_than',
        default_value: '',
        icon: <CakeIcon />
    },
    'stats.sessions': {
        type: 'number',
        prefix: '# of logins',
        suffix: '',
        default_operator: 'equals',
        default_value: '',
        icon: <LoginIcon />
    },
    'address.city': {
        type: 'string',
        prefix: 'City',
        suffix: '',
        default_operator: 'starts_with',
        default_value: '',
        icon: <OfficeBuildingIcon />
    },
    'address.country': {
        type: 'multi-select',
        default_operator: 'is',
        prefix: 'Country',
        suffix: '',
        default_value: '',
        icon: <GlobeAltIcon />,
        options: Object.entries(countries).map(([id,label])=>{
            return {
                id,
                label
            }
        })
    },
    member_type: {
        type: 'multi-select',
        field: 'member_type',
        prefix: 'Type',
        suffix: '',
        default_operator: 'is',
        default_value: '',
        icon: <FlagIcon />,
        options: []
    },
    archived: {
        type: 'boolean',
        field: 'archived',
        default_operator: 'is',
        prefix: 'Status is',
        suffix: '',
        default_value: true,
        icon: <ArchiveIcon />,
        options: [
            {
                id: true,
                label: 'archived'
            },
            {
                id: false,
                label: 'not archived'
            }
        ]
    },
    tags: {
        type: 'multi-select',
        default_operator: 'is_one_of',
        default_value: '',
        prefix: 'Tagged with',
        suffix: '',
        icon: <TagIcon />
    },
    last_sign_in: {
        type: 'date',
        default_operator: 'is_before',
        prefix: 'Last sign in',
        suffix: '',
        default_value: '',
        icon: <CalendarIcon />
    },
};

// Not-equal (!=) and not-in queries exclude documents where the given field does not exist.
// can a boolean be a select? how to display
export const condition_types_ref = {
    string: {
        options: [
            {
                label: 'starts with',
                id: 'starts_with'
            },
            {
                label: 'is',
                id: 'is'
            },
            {
                label: 'is not',
                id: 'is_not'
            },
        ]
    },
    select: {
        options: [
            {
                label: 'is',
                id: 'is'
            },
            {
                label: 'is not',
                id: 'is_not'
            },
            {
                label: 'is one of',
                id: 'is_one_of'
            },
            {
                label: 'is not one of',
                id: 'is_not_one_of'
            },
        ]
    },
    'multi-select': {
        options: [
            {
                label: 'is',
                id: 'is'
            },
            {
                label: 'is not',
                id: 'is_not'
            },
            {
                label: 'is one of',
                id: 'is_one_of'
            },
            {
                label: 'is not one of',
                id: 'is_not_one_of'
            },
        ]
    },
    date: {
        options: [
            {
                label: 'is before',
                id: 'is_before'
            },
            {
                label: 'is after',
                id: 'is_after'
            }
        ]
    },
    number: {
        options: [
            {
                label: 'equals',
                id: 'equals'
            },
            {
                label: 'does not equal',
                id: 'does_not_equal'
            },
            {
                label: 'less than',
                id: 'less_than'
            },
            {
                label: 'greater than',
                id: 'greater_than'
            },
            {
                label: 'less than or equal to',
                id: 'less_than_or_equal'
            },
            {
                label: 'greater than or equal to',
                id: 'greater_than_or_equal'
            },
        ]
    },
    boolean: {
        options: [
            {
                label: 'true',
                id: 'true'
            },
            {
                label: 'false',
                id: 'false'
            },
        ]
    }
};