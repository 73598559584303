import {validateEmail} from "../../utilities/validation";
import {getPhoneNumberData} from "../../utilities/phone";

const base_config = {
    completeness: {
        elements: [
            {
                field: 'about.first_name',
                condition: 'not-empty'
            },
            {
                field: 'about.last_name',
                condition: 'not-empty'
            },
            {
                field: 'contact.email',
                condition: 'valid-email'
            },
            {
                field: 'contact.phone',
                condition: 'valid-phone'
            },
            {
                field: 'address.line_one',
                condition: 'not-empty'
            },
            {
                field: 'address.city',
                condition: 'not-empty'
            },
            {
                field: 'address.zip_code',
                condition: 'not-empty'
            },
            {
                field: 'profile_picture',
                condition: 'not-empty'
            },
        ]
    }
};

const user_config = {
    completeness: {
        elements: [
            {
                field: 'about.first_name',
                condition: 'not-empty'
            },
            {
                field: 'about.last_name',
                condition: 'not-empty'
            },
            {
                field: 'contact.email',
                condition: 'valid-email'
            },
            {
                field: 'profile_picture',
                condition: 'not-empty'
            },
        ]
    }
};

function getValue(member, field) {
    const sp = field.split('.');
    if(sp.length>1) {
        return member[sp[0]][sp[1]];
    } else {
        return member[sp[0]];
    }
}

export function getProfileCompleteness(type, data, important_fields=[]) {
    let p = 0, s = [], e = [];

    const config = type === 'user' ? {...user_config} : {...base_config};

    const count = config.completeness.elements.length + important_fields.length;

    config.completeness.elements.forEach(el=>{
       const v = getValue(data,el.field);
       if(el.condition==='not-empty') {
           if(v) {
               p++;
               s.push(el.field);
           } else {
               e.push(el.field);
           }
       } else if(el.condition==='valid-email') {
           if(validateEmail(v)) {
               p++;
               s.push(el.field);
           } else {
               e.push(el.field);
           }
       } else if(el.condition==='valid-phone') {
           const pd = getPhoneNumberData(v);
           if(pd.valid) {
               p++;
               s.push(el.field);
           } else {
               e.push(el.field);
           }
       }
    });

    important_fields.forEach(c=>{
        const k = `custom_fields.${c}`;
        const v = getValue(data,k);
        if(v) {
            p++;
            s.push(k);
        } else {
            e.push(k);
        }
    })

    return {
        percent: (p / count),
        success: s,
        error: e
    }
}