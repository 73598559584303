import React, {useContext, useEffect, useRef, useState} from "react";
import styled from "styled-components";
import {ESTable} from "./table";

import {ESHeader} from "./header";

import {NewEntitySearch} from "../entity-search/new-entity-search";
import {cloneObject} from "../advanced-modal";
import {CommunityContext} from "../../../../app/community/community-context";
import {AddEntitiesSuggestions} from "../../suggestions/add-entities-suggestions";
import {authFetch} from "../../../../config/network";


const Wrapper = styled.div`

`;

const searchable_default = {members: true, member_types: true, filters: true, roles: true, groups: true, teams: true};

function fixKey(k) {
    return `${k}s`
}

export function NewEntitySelect({onChange, defaults={}, auto_focus=true, show_table=true, suggestions, search_ctx=["group"], placeholder="Add members", meta={existing:{members:{},filters:{},member_types:{},teams:{},groups:{},roles:{}},block_group_id:''}, searchable = searchable_default, value, context={is_admin:false}}) {
    const entities_map = useRef(JSON.parse(JSON.stringify(value)))
    const [updated,setUpdated] = useState(JSON.parse(JSON.stringify(value)));
    const community = useContext(CommunityContext);
    const isFirstRun = React.useRef(true);
    const [not_live,setNotLive] = useState({});
    const [added,setAdded] = useState(false);
    const [loading,setLoading] = useState('');

    useEffect(function () {
        if (isFirstRun.current) {
            isFirstRun.current = false;
            return;
        }

        entities_map.current = JSON.parse(JSON.stringify(value));
        setUpdated(JSON.parse(JSON.stringify(value)));
    }, [value]);

    function handleAdd(live,data) {

        setLoading('adding-suggestion');

        const payload = {
            community_uid: community.uid,
            member_id: community.member_id,
            user_uid: community.member.user_uid,
            ...suggestions
        };

        const err = () => {};

        const l = live;

        const res = (resp) => {
            handleChange({
                type: 'filter',
                id: resp.data.id
            })
            if(!l) {
                handleToggleSegment(resp.data.id);
            }
            setLoading('');
            setAdded(true);
        };

        authFetch('/filters/create', res, err, "POST", {payload});
    }

    function handleChange(val,nl) {
        const clone = cloneObject(value);
        let new_val = clone.entities;
        if(val) {
            new_val[fixKey(val.type)][val.id] = true;
        }
        const nl1 = nl ? nl : not_live;
        new_val.not_live = {...nl1};
        const fv = JSON.parse(JSON.stringify(new_val))
        onChange('entities', fv, val);
    }

    function handleToggleSegment(id) {
        const nl = {...not_live};
        if(nl[id]) {
            delete nl[id];
        } else {
            nl[id] = true;
        }
        setNotLive(nl);
        handleChange(null,nl)
    }

    const show_suggestions = suggestions&&!added;

    return <Wrapper>
        <ESHeader>
            <NewEntitySearch meta={meta} context={context} auto_focus={auto_focus} is_multi={false}
                             searchable={searchable} already_added={updated.entities}
                             search_ctx={search_ctx} allowed={meta.allowed} defaults={defaults}
                             excluded={meta.existing} placeholder={placeholder}
                             onChange={(val) => {
                                 if(val.type) {
                                     if(val.type!=='') {
                                         handleChange(val);
                                     }
                                 }
                             }} value={null}/>
        </ESHeader>
        {show_table&&<ESTable {...updated} non_live_ids={not_live} onToggleSegmentLive={handleToggleSegment} community={community} onRemove={(type, id, index) => {
            const clone = cloneObject(value);
            let ne = clone.entities;

            delete ne[type][id];

            onChange('entities', ne);
        }} community_uid={context.community_uid}/>}
        {show_suggestions&&<div className={"-mb-4"}>
            <AddEntitiesSuggestions community={community} loading={loading==='adding-suggestion'} onAdd={handleAdd} {...suggestions} />
        </div>}
    </Wrapper>
}
