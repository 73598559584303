import React, {createContext} from 'react';

const default_value = {
  user: null,
  refresh_token: '',
  timezone: '',
  language: '',
  auth_updated: null,
  memberships: [],
  community_data: {},
  logging_in: true,
  right_menu: null,
  login_error: null,
  open_search: null,
  logging_out: false,
  suspended: false,
  logged_in: false,
  archived: false,
  handle: '',
  login: () => {},
  setLanguage: () => {},
  setLoginError: () => {},
  closeSearch: () => {},
  openSearch: () => {},
  setTimezone: () => {},
  openFileViewer: () => {},
  closeFileViewer: () => {},
  closeImageViewer: () => {},
  openImageViewer: () => {},
  handleSetRightMenu: () => {},
  handleCloseRightMenu: () => {},
  addToast: () => {},
  logout: () => {},
  user_uid: '',
};

export const GlobalContext = createContext(default_value);