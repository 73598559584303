import React from 'react';
import {LEADER_COLOR} from "../../../config/defaults";

export const CIRole = ({fill=LEADER_COLOR}) => {
    return <svg version="1.1"  x="0" y="0" width="auto" viewBox="0, 0, 24, 24">
        <g id="role" >
            <path fill={fill} d="M14.947,3 C18.29,3 21,5.71 21,9.053 L21,14.947 C21,18.29 18.29,21 14.947,21 L9.053,21 C5.71,21 3,18.29 3,14.947 L3,9.053 C3,5.71 5.71,3 9.053,3 L14.947,3 z M12.659,6 L12.452,6.035 C12.318,6.081 12.201,6.168 12.12,6.284 L7.453,12.951 C7.383,13.051 7.342,13.168 7.334,13.29 C7.327,13.411 7.352,13.533 7.409,13.641 C7.465,13.749 7.55,13.84 7.654,13.903 C7.758,13.966 7.878,14 8,13.999 L10.667,13.999 L10.667,17.333 C10.667,17.475 10.712,17.613 10.796,17.727 C10.88,17.842 10.998,17.927 11.133,17.969 C11.269,18.012 11.414,18.01 11.548,17.964 C11.683,17.919 11.799,17.831 11.88,17.715 L16.547,11.048 C16.617,10.948 16.658,10.831 16.666,10.709 C16.674,10.588 16.648,10.466 16.592,10.358 C16.535,10.25 16.45,10.159 16.346,10.096 C16.242,10.033 16.122,9.999 16,9.999 L13.333,9.999 L13.333,6.666 C13.333,6.524 13.288,6.386 13.204,6.272 C13.12,6.157 13.002,6.073 12.867,6.03 L12.867,6.031 L12.659,6 z" />
        </g>
    </svg>

};