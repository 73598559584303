import React, {useState, useContext} from 'react';
import {authFetch} from "../../config/network";
import {PolicyViewer} from "../community/policy";
import {Button, Toggle} from "../../library/components/core";
import {RemoteMdViewer} from "./remote-md-viewer";
import {LanguageSwitcher} from "../../library/components/layout/topbar/language-switcher";
import {useIsMobile} from "../global/global-router";
import {useHistory} from "react-router-dom";

export function inDemoMode(param) {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const rt = urlParams.get(param);
    return !!rt;
}

export const demo_policy = {
    name: 'Data Privacy Policy',
  slug: 'data_privacy_policy',
  link: 'https://firebasestorage.googleapis.com/v0/b/unaty-prod.appspot.com/o/policies%2Fcurrent%2Funaty-data-privacy-policy-for-users.md?alt=media',
  version: '1',
  link_german: 'https://firebasestorage.googleapis.com/v0/b/unaty-prod.appspot.com/o/policies%2Fcurrent%2Funaty-data-privacy-policy-for-users-de.md?alt=media'
};

export function UserPolicyGate({user, global}) {
    const [loading, setLoading] = useState('');
    const is_mobile = useIsMobile();
    const history = useHistory();
    const [contents, setConsent] = useState(false);
    if (!user||!global.policies_data) {
        return null;
    }

    // check if new policies are available that require their attention

    let needs_to_accept = [];

    const global_user_policies = Object.entries(global.policies_data).map(([id, pd]) => {
        return {
            id,
            ...pd
        }
    }).filter((a) => {
        return a.gate === 'user';
    });
    for (let i = 0; i < global_user_policies.length; i++) {
        const {version, name, link, link_german='', id} = global_user_policies[i];
        if (version === ''||!link||!link_german) {
            // if no version is set, none to accept..
            continue;
        }
        const uid = `${id}__${version}`;
        if (!user.policies || !user.policies[uid]) {
            needs_to_accept.push({
                slug: id,
                version,
                link,
                link_german,
                name
            });
        }
    }

    const demo_mode = inDemoMode("user_policy_demo");

    if (needs_to_accept.length === 0 && !demo_mode) {
        return null;
    }

    function snapToTop() {
        let content_cont = document.getElementById('policy-viewer-container');
        if(content_cont) {
            content_cont.scrollTop = 0;
        }
    }

    function acceptPolicy(slug, version) {
        if(demo_mode) {
            history.push('/');
            return;
        }
        setLoading('accepting');
        const res = (result) => {
            setConsent(false);
            setLoading('');
            snapToTop();
        };

        const payload = {
            slug, version
        };

        authFetch("/users/accept-policy", res, res, "POST", {payload});
    }

    const policy_to_accept = demo_mode ? demo_policy : needs_to_accept[0] ? needs_to_accept[0] : null;

    if (!policy_to_accept && !demo_mode) {
        return null;
    }

    return <div className={`z-9999 fixed top-0 left-0 h-screen right-0 bottom-0 bg-black bg-opacity-70 ${is_mobile?"mobile-policy-gate":""}`}>
        <div className="max-w-2xl mx-auto relative h-screen pt-8 pb-16">
            <div className={`${is_mobile?"h-screen flex flex-col":"rounded-md"} bg-white`}>

                <div id="policy-viewer-container" style={{paddingBottom:'40vh'}} className={`${is_mobile?"flex-grow max-h-100vh":"max-h-70vh"} overflow-y-auto relative`}>
                    <RemoteMdViewer demo={demo_mode} url={global.language==='de'?policy_to_accept.link_german:policy_to_accept.link} />
                    {!is_mobile&&<div className="absolute right-4 bottom-4">
                        <LanguageSwitcher />
                    </div>}
                </div>
                <div
                    className={`${is_mobile?"fixed bottom-0 left-0 right-0 ":"rounded-b-md"} bg-white flex space-x-6 items-center p-4 border-t border-gray-200`}>
                <div className={`space-y-2 flex-grow sm:flex sm:space-x-6 items-center `}>
                        <div className="flex-grow flex sm:justify-end">
                            <Toggle checked={contents} onToggle={(v) => setConsent(v)} label={<>
                                I agree to the terms of the policy above.
                            </>}/>
                        </div>
                        <Button size="lg" disabled={!contents} loading={loading === 'accepting'}
                                onClick={() => acceptPolicy(policy_to_accept.slug, policy_to_accept.version)}
                                text="Accept & Continue" intent="secondary"/>
                    </div>
                </div>
            </div>
        </div>
    </div>
}