import React, {useContext, useEffect, useState} from "react";
import {
    ArrowLeftIcon,
    ArrowRightIcon, DuplicateIcon, KeyIcon,
    XCircleIcon
} from "@heroicons/react/solid";
import {Button, SectionHeader} from "../core";
import {MiniBlockSelector} from "../custom/mini-block-selector";
import {mock_lie_columns, mock_live_import_editor_data} from "./data/mock-data";
import {MatchFieldTitle} from "./match-field-title";
import {RulesInlineSelect} from "../rules/rules-inline-select";
import {member_profile_data_fields_categories} from "./data/data";
import {LIEDirectionArrow, lie_grid_style} from "./components/shared";
import {LIEBody} from "./components/body";
import {LIEFooter} from "./components/footer";
import {
    lie_buildCustomSelectOptions,
    lie_buildDataFieldOptions,
    lie_buildFullProfileFieldData,
    lie_getKeyBlockId
} from "./utilities/shared";
import {getImportSummary} from "./utilities/get-import-summary";
import {CUSTOM_NANOID} from "../../../config/defaults";
import {GlobalContext} from "../../../app/global/global-context";
import {lie_prepareExport} from "./utilities/prepare-export";
import {CommunityContext} from "../../../app/community/community-context";
import {lie_prematchColumns} from "./utilities/prematching";
import {DoubleCheckModal} from "../old/double-check-modal";

function buildBlocks(columns, key_block_id) {
    return columns.map(col => {
        return {
            ...col,
            meta: col.id === key_block_id ? <div className="w-3 h-3"><KeyIcon/></div> : null
        }
    });
}

function getCustomFieldOptions(field_id, cfs) {
    let it = {}, matched = false;
    Object.values(cfs).forEach(sec=>{
       if(sec.field_data[field_id]&&!matched) {
           matched = true;
           it = sec.field_data[field_id];
       }
    });
    return it;
}

export function LiveImportEditor({
                                     onStartImport = () => {},
                                     raw_data = mock_live_import_editor_data,
                                     columns = mock_lie_columns,
                                     file_name = "members.csv"
                                 }) {
    const community = useContext(CommunityContext);
    const global = useContext(GlobalContext);
    const [update_cfs,setUpdateCfs] = useState("")
    const [cfs,setCFS] = useState(community.custom_fields)
    const full_member_profile_field_data = lie_buildFullProfileFieldData(community,community.custom_fields);
    const [all_blocks,setAllBlocks] = useState(columns);
    const [mapping, setMapping] = useState(lie_prematchColumns(columns,full_member_profile_field_data,raw_data));
    const [disabled, setDisabled] = useState([]);
    const [data, setData] = useState(raw_data);
    const [modal, setModal] = useState(null);
    const [block, setBlock] = useState(all_blocks[0].id);
    const block_data = all_blocks.filter(c => c.id === block)[0];

    useEffect(function() {
        if(update_cfs) {
            let nm = {...mapping};
            const bd = all_blocks.filter(c => c.id === block)[0];
            // update right block based on block
            nm[block].field_details.options = lie_buildCustomSelectOptions(getCustomFieldOptions(nm[block].field_details.field_id,cfs));
            const sp = update_cfs.split('||');
            nm[block].mapping[sp[0]] = sp[1];
            setUpdateCfs("");
            setMapping(nm);
        }
    }, [update_cfs,mapping,cfs,block])

    useEffect(function () {
        setCFS(community.custom_fields)
    }, [community.custom_fields]);

    useEffect(function () {
        moveViewerToBlock();
    }, [block])

    function moveViewerToBlock() {
        const el = document.getElementById("columns-selector")
        const offset = (136 * current_columns_number) - 24;
        const total_width = el.scrollWidth;
        const client_width = el.clientWidth;
        const max_left = total_width - client_width;
        if(offset>max_left) {
            el.scrollLeft = max_left;
        } else if(offset<0) {
            el.scrollLeft = 0;
        } else {
            el.scrollLeft = offset;
        }
    }

    function goToNextBlock() {
        const columns_length = all_blocks.length;
        const current_index = all_blocks.findIndex(a => a.id === block);
        if ((current_index + 1) < columns_length) {
            setBlock(all_blocks[(current_index + 1)].id);
        } else {
            setBlock(all_blocks[0].id);
        }
    }

    function goBackOneBlock() {
        const index = all_blocks.findIndex(a=>a.id===block);
        setBlock(all_blocks[(index-1)].id);
    }

    function handleConfirm() {
        // update mapping and move to the next item
        // go on to next block if not last

        // if is account_email and confirmed
        const block_data = all_blocks.filter(a=>a.id===block)[0];

        const mapping_entries = Object.entries(mapping);

        const block_mapping = mapping_entries.filter(a=>a[0]===block)[0];

        if(block_mapping[1].data_field==='account_email'&&!mapping_entries.filter(a=>a[1].data_field==='contact.email')[0]) {
            // if hasn't selected one for email
            // here we go, so we need to do the copy and matching for them and then go on to the next
            handleMatchToAnotherField('contact.email');
            global.addToast({intent:'info',text:'Email mapped'});
        }
        global.addToast({intent:'info',text:`${block_mapping[1].field_details.label} mapped`});
        goToNextBlock();
    }

    function handleIgnore() {
        // move to disabled and move on to the nexxt
        let nd = [...disabled];
        let nm = {...mapping};
        if (nm[block]) {
            delete nm[block];
        }
        if (!nd.includes(block)) {
            nd.push(block);
        }
        setMapping(nm);
        setDisabled(nd);
        goToNextBlock();
    }

    function handleUpdateData(row_id, col_id, new_value) {
        let nd = {...data};
        nd[row_id][col_id] = new_value;
        setData(nd);
    }

    function handleUpdateMapping(block_id, data_field_id, mapping_data, delete_mapping) {
        let nm = {...mapping};

        if (delete_mapping) {
            delete nm[delete_mapping[0]];
        }
        if (!data_field_id && nm[block_id]) {
            delete nm[block_id];
            setMapping(nm);
        } else if (!data_field_id) {

        } else {
            // can we add type here?
            let mdp = mapping_data;
            let fd = full_member_profile_field_data[data_field_id];
            if (fd.default_selection) {
                mdp[""] = fd.default_selection;
            }
            nm[block_id] = {
                data_field: data_field_id,
                field_details: fd,
                mapping: mapping_data
            }
            setMapping(nm);
        }

    }

    function handleMappingChange(block_id, text, sid) {
        let nm = {...mapping};
        nm[block_id].mapping[text] = sid;
        setMapping(nm);
    }

    function handleSelectDataField(id) {
        // id = about.last_name
        let md = mapping[block] ? mapping[block].mapping : {};
        const mapped_values = Object.entries(mapping);
        const exists = mapped_values.filter(a => a[1].data_field === id)[0];

        handleUpdateMapping(block, id, md, exists);
    }

    function onAddSelectValue(item_label,sid) {
        setUpdateCfs(`${item_label}||${sid}`);
    }

    function handleMatchToAnotherField(map_data_field) {
        // create another entry in the data
        // create another column
        const columns_index = all_blocks.findIndex(a=>a.id===block);

        let nd = {...data};
        let nb = [...all_blocks];
        let nm = {...mapping};

        const current_block_data = all_blocks[columns_index];

        const data_entries = Object.entries(nd);

        const new_id = CUSTOM_NANOID();

        for(let i=0;i<data_entries.length;i++) {
            const [row_number,row_data] = data_entries[i];

            nd[row_number][new_id] = row_data[current_block_data.id.split("-")[1]];
        }

        nb.splice((columns_index+1),0,{
            label: `${current_block_data.label} (copy)`,
            id: new_id
        });

        // select this block now
        setData(nd);

        setAllBlocks(nb);

        if(map_data_field) {
            nm[new_id] = {
                data_field: map_data_field,
                field_details: full_member_profile_field_data[map_data_field],
                mapping: {}
            }
            setMapping(nm);
        } else {
            setBlock(new_id);
        }
    }

    function handleStartImport() {
        const payload = lie_prepareExport(data,mapping,all_blocks);
        onStartImport(payload);
    }

    const key_block_id = lie_getKeyBlockId(mapping);

    const can_import = !!key_block_id;

    const import_summary = getImportSummary(data, all_blocks, key_block_id, mapping);

    const total_columns = all_blocks.length;
    const current_columns_number = all_blocks.findIndex(a=>a.id===block);

    const unaty_fields = lie_buildDataFieldOptions(full_member_profile_field_data, mapping);

    return <div id="import-editor-frame" style={{maxWidth: '1076px'}}
                className="w-full">
        <div className="w-full">
            <div  style={{maxWidth: '1076px'}}
                  className="w-full h-14 overflow-x-hidden relative">
                <div className="absolute top-0 left-0 right-0 bottom-0">
        <MiniBlockSelector selected={block} blocks={buildBlocks(all_blocks, key_block_id)} success={Object.keys(mapping)}
                           disabled={disabled} id={"columns-selector"}
                           onSelectBlock={(id) => setBlock(id)}/>
                </div>
            </div>
        </div>
        <div className="pt-4">
            <div className="text-gray-600 font-medium text-xs pb-1 -mb-2">
                Column {current_columns_number+1}/{total_columns}
            </div>
            <SectionHeader baseline action={<div className="flex space-x-2">
                {all_blocks.findIndex(a=>a.id===block)!==0&&<Button left_icon={<ArrowLeftIcon/>} onClick={() => goBackOneBlock()}
                        text="Back" />}
                <Button onClick={() => handleIgnore()} text="Ignore"
                        left_icon={<XCircleIcon className="text-gray-600"/>}/>
                <Button right_icon={<ArrowRightIcon/>} disabled={!mapping[block]} onClick={() => handleConfirm()}
                        text="Next" intent="secondary"/>
            </div>} divider fill padded={false} size="lg" title={`Match "${block_data.label}"`}/>
        </div>
        <div className="grid py-0.5 border-b-2 border-gray-200" style={{...lie_grid_style}}>
            <div className="col-span-2">
                <MatchFieldTitle title={block_data.label}
                                 type={mapping[block] ? mapping[block].field_details.type : 'text'}
                                 file_name={file_name}/>
            </div>
            <div className="flex items-center justify-end">
                <LIEDirectionArrow/>
            </div>
            <div className="flex items-center space-x-3">
                <div className="flex-grow">
                    <div className="flex items-center space-x-1">
                        <RulesInlineSelect highlight={!mapping[block]}
                                           selected={mapping[block] ? mapping[block].data_field : ''}
                                           categories={member_profile_data_fields_categories} onSelect={handleSelectDataField}
                                           size="sm" searchable
                                           options={unaty_fields} inline
                                           layout='minimal'
                                           placeholder="Member Profile Field"/>
                        <div className="h-6 flex items-center pt-px">
                        <div className="text-xs text-gray-500 truncate" style={{maxWidth: '200px'}}>on <strong
                            className="text-gray-700">Unaty</strong></div>
                        </div>
                    </div>

                </div>
                {mapping[block]&&<div onClick={()=>handleMatchToAnotherField()} className="px-2 py-1 flex space-x-1 rounded-md hover:bg-gray-200 transition-colors cursor-pointer">
                    <div className="text-gray-500 text-xs font-medium">
                    Match to another data field
                    </div>
                    <div className="w-4 h-4 text-gray-400">
                        <DuplicateIcon />
                    </div>
                </div>}
            </div>
        </div>
        <LIEBody onChangeMapping={handleMappingChange} mapping_data={mapping} block_data={block_data} data={data}
                 onChangeData={handleUpdateData} onAddSelectValue={onAddSelectValue} />
        <LIEFooter onStartImport={()=>setModal('double-check')} {...import_summary} can_import={can_import}/>

        {modal === 'double-check' &&
        <DoubleCheckModal onConfirm={() => handleStartImport()} onCancel={() => setModal(null)} type='import_check'/>}
    </div>
}