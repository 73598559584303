import React, {Component} from 'react';

import styled from "styled-components";
import {BasicSelect} from "../../core/basic-select";
import {CommunityContext} from "../../../../app/community/community-context";

function buildNameData(member, member_type) {
    let obj = {};

    obj.first_name = member.about.first_name;
    obj.last_name = member.about.last_name;
    obj.middle_name = member.about.middle_name;
    obj.title = member.about.title;
    obj.abbreviation = member_type.abbreviation;
    obj.singular = member_type.singular;
    obj.emoji = member_type.emoji;
    obj.plural = member_type.plural;
    obj.custom = '';
    obj.custom1 = '';
    return obj;
}

function buildDisplayName(format, name_data) {
    let str = format;

    str = str.replace('first_name', name_data['first_name']);
    str = str.replace('last_name', name_data['last_name']);
    str = str.replace('middle_name', name_data['middle_name']);
    str = str.replace('title', name_data['title']);
    str = str.replace('abbreviation', name_data['abbreviation']);
    str = str.replace('singular', name_data['singular']);
    str = str.replace('plural', name_data['plural']);
    str = str.replace('emoji', name_data['emoji']);
    str = str.replace('custom', name_data['custom']);
    str = str.replace('custom1', name_data['custom1']);

    return str;
}

const display_name_options = {
    'first_name': {
        label: 'First Name',
        value:
            'first_name'
    }
    ,
    'last_name': {
        label: 'Last Name',
        value:
            'last_name'
    }
    ,
    'middle_name': {
        label: 'Middle Name',
        value:
            'middle_name'
    }
    ,
    'title': {
        label: 'Title',
        value:
            'title'
    }
    ,
    'abbreviation': {
        label: 'Member Type Abbreviation',
        value:
            'abbreviation'
    }
    ,
    'singular': {
        label: 'Member Type Singular',
        value:
            'singular'
    }
    ,
    'emoji': {
        label: 'Member Type Emoji',
        value:
            'emoji'
    }
};

const Val = styled.span`
font-weight: 500;
    margin-right: 8px;
`;

const Meta = styled.span`
    color: #748490;
`;

function convertStrToArr(value) {
    let split = value.split(' ');
    if(split.length===1&&split[0]==='') {
        return [];
    }
    let arr = [];
    for (let i = 0; i < split.length; i++) {
        let item = split[i];
        arr.push({
            value: item,
            label: display_name_options[item].label
        })
    }
    return arr;
}

function convertArrToStr(arr) {
    let str = '';

    if(!arr) {
        return '';
    }

    for(let i=0;i<arr.length;i++) {
        str += arr[i].value;
        str += ' ';
    }

    return str.trim();
}

export default class ManageDisplayName extends Component {
    static contextType = CommunityContext;

    constructor(props) {
        super(props);

        this.state = {};
    }

    render() {
        const {value, onChange, edit = false} = this.props;
        const {member_types,member} = this.context;
        const example = buildDisplayName(value, buildNameData(member, member_types[member.member_type]));
        if (edit) {
            return <BasicSelect clearable={false} onChange={(val) => {
                onChange(convertArrToStr(val));
            }} multi value={convertStrToArr(value)} options={Object.values(display_name_options)}/>
        }
        return <span>
            <Val>{value}</Val>
            <Meta>({example})</Meta>
        </span>
    }
}