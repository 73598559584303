import React, {useEffect, useState} from 'react';
import {StandardPageHeight} from "../../../../library/playground/standard-page-framework";
import {EntityHeader, InlineLoader} from "../../../../library/components/core";
import {useHistory, useLocation} from "react-router-dom";
import {FirebaseDB} from "../../../../config/setup-firestore";
import {buildGroupCard} from "../../../community/group/utilities";
import {ProfileCard} from "../../../../library/components/reorganize/profile-card";

function GroupProfile({data,community_uid}) {
    let features = {
        edit_profile_picture: false,
        edit_cover_photo: false,
        can_manage: false
    };
    let new_card = buildGroupCard(data, features);
    return <div>
        <ProfileCard context={{community_uid:community_uid}} editing={false} actions={[]} data={new_card}
                     features={features}/>
    </div>
}

export function PublicCommunityGroupPage({id, community_uid}) {
    const history = useHistory();
    const location = useLocation();
    const [data, setData] = useState(null);

    useEffect(function () {
        getGroup();
    }, [id, community_uid])

    function getGroup() {
        FirebaseDB
            .collection('community_entities')
            .doc(community_uid)
            .collection('groups')
            .doc(id)
            .get()
            .then(doc => {
                setData({...doc.data()})
            })
    }

    return <StandardPageHeight content id="group">
        <div className="relative">
            <div className="">
                <EntityHeader single goBackTo={() => {
                    history.push(`${location.pathname}`)
                }} title={"Back to Community"}/>
            </div>
            {!data&&<InlineLoader />}
            {data&&<GroupProfile data={data} community_uid={community_uid} />}
        </div>

    </StandardPageHeight>
}