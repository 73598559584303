import React from 'react';
import {SidebarUserFooter} from "../left-sidebar/community-sidebar-user";
import {TopbarHelpTips} from "./help-and-tips";
import {hexToRgbStr, setAlpha} from "../../../utilities/general/colors";
import {UniversalSearch} from "../../universal-search";
import styled from "styled-components";
import {FeedbackItem} from "./give-feedback";
import {SidebarSpaceInfo} from "../left-sidebar/community-sidebar-topbar";
import {NotificationsIcon} from "./notifications/icon";
import {TopbarNotifications} from "./notifications";
import {CommunitySettingsItem} from "./community-settings";

const AdjustChildSpans = styled.div`

  > span {
    height: 30px !important;
  }
`;

export function AppTopbar({
                              bg = "#027581",
                              space_name = '',
                              community_data,
                              communities,
                              theme,
                              is_admin,
                              is_member,
                              search_props,
                              user,
                              logged_in,
                              member_type,
                              community_handle,
                              member,
                              community,
                              context,
                              history,
                              color = "white"
                          }) {
    const opposite = color === 'white' ? 'black' : 'white';

    const opposite_hex = opposite === 'white' ? '#ffffff' : '#000000';
    const border = setAlpha(hexToRgbStr(opposite_hex), color === 'white' ? 0.1 : 0.4);

    const build_mode_active = community && community.preferences ? community.preferences.build_mode : false;

    return <div style={{height:'3.25rem'}} className="flex px-3 items-center mx-auto app-max-width">
        <div className="grid gap-3" style={{gridTemplateColumns:'auto 1fr'}}>
            <div className="w-9 h-9">
                <SidebarSpaceInfo bg={bg} user={user} goTo={(path) => history.push(path)}
                                  community_data={community_data} theme={theme}
                                  is_admin={is_admin} community_handle={community_handle}
                                  communities={communities}
                                  global={context !== 'community'} community={community}/>
            </div>
            <div className="app-search-main" style={{minWidth:'360px'}}>
                <UniversalSearch theme={theme} {...search_props} space_name={space_name}/>
            </div>
        </div>
        <div className="flex-grow"/>
        <div>
            <AdjustChildSpans className="space-x-0.5 flex items-center">
                {build_mode_active &&
                <div onClick={() => history.push(`/${community_handle}/admin/settings/build-mode`)}
                     className="mr-1 flex items-center">
                    <div
                        className="cursor-pointer hover:opacity-80 transition-opacity bg-yellow-400 flex items-center text-sm font-medium px-2.5 py-1 text-gray-800 rounded-md">
                        Build Mode
                    </div>
                </div>}

                {logged_in&&<FeedbackItem theme={theme} />}

                {logged_in&&<TopbarHelpTips theme={theme}/>}

                {is_admin && <CommunitySettingsItem community_handle={community_handle} theme={theme} />}

                {context === 'community' && member &&
                <TopbarNotifications  theme={theme} community={community} community_uid={member.community_uid}
                                     member_id={member.id}/>}
                {logged_in && (user||member) &&
                <SidebarUserFooter  theme={theme} border={border} global={context !== 'community'} history={history}
                                   data={context !== 'community' ? user : member} is_member={is_member}
                                   community_handle={community_handle} community={community}
                                   goTo={(path) => {
                                       history.push(path)
                                   }} member_type={member_type}/>}
            </AdjustChildSpans>
        </div>
    </div>;
}