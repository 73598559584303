import React from 'react';
import {isAnonymousUnatyEmail} from "../../../../library/utilities/anonymous-emails";
import {social_fields_array} from "../../../../library/components/reorganize/profile-fields-section";
import {custom_field_types} from "../../../../library/utilities/community/custom-fields";
import {ActionWithIcon} from "../../../../library/components/core";

function displaySection(sec,is_admin,my_profile) {
    if(sec==='all-members') {
        return true;
    } else if(is_admin&&sec==='admins-only') {
        return true;
    } else if(is_admin&&sec==='read-only' || my_profile) {
        return true;
    } else if((is_admin&&sec==='private')||my_profile) {
        return true;
    } else {
        return false;
    }
}

export function buildEmailOptions(member) {
    let a = [];

    if(!member.account_email||isAnonymousUnatyEmail(member.account_email)) {
        return [
            {
                hidden: false,
                text: "-",
                value: ""
            }
        ]
    } else {
        a.push({
            hidden: false,
            text: member.account_email,
            value: member.account_email
        });
    }

    if(member.secondary_emails) {
        member.secondary_emails.forEach(se=>{
            a.push({
                hidden: false,
                text: se,
                value: se
            });
        })
    }

    return a;
}

export function communityGetSectionsToDisplay(community, is_admin, my_profile=false) {
    let display_social = true, display_interests = true, display_education = true, display_career = true, display_custom = {};
    let social_vis='all-members',career_vis='all-members',education_vis='all-members',interests_vis='all-members';
    const dd = community.data.design_defaults;
    if(dd) {
        if(community.data.design_defaults.career_visibility) {
            career_vis = community.data.design_defaults.career_visibility;
            display_career = displaySection(community.data.design_defaults.career_visibility,is_admin,my_profile);
        }
        if(community.data.design_defaults.interests_visibility) {
            interests_vis = community.data.design_defaults.interests_visibility;
            display_interests = displaySection(community.data.design_defaults.interests_visibility,is_admin,my_profile);
        }
        if(community.data.design_defaults.education_visibility) {
            education_vis = community.data.design_defaults.education_visibility;
            display_education = displaySection(community.data.design_defaults.education_visibility,is_admin,my_profile);
        }
        if(community.data.design_defaults.social_visibility) {
            social_vis = community.data.design_defaults.social_visibility;
            display_social = displaySection(community.data.design_defaults.social_visibility,is_admin,my_profile);
        }
    }

    const keys = Object.keys(community.custom_fields);

    for(let i=0;i<keys.length;i++) {
        const key = keys[i];
        display_custom[key] = displaySection(community.custom_fields[key].visibility?community.custom_fields[key].visibility:'all-members',is_admin,my_profile);
    }
    return {
        display_social, display_custom, display_career, display_interests, display_education,
        social_vis,career_vis,education_vis,interests_vis
    }
}

function showField(data,fkey) {
    return !data.preferences.members[`hide_${fkey}`];
}

function fieldIsImportant(data,fkey) {
    return !!data.preferences.members[`imp_${fkey}`];
}

export function getSocialFieldsToShow(community) {
    let a = [];

    social_fields_array.forEach(sf=>{
        if(showField(community,sf)) {
            const cd = custom_field_types[`social-${sf}`];
            a.push({
                field: `social.${sf}`,
                type: `social-${sf}`,
                defer: true,
                name: cd&&cd.label?cd.label:"",
                placeholder: cd&&cd.placeholder?cd.placeholder:"",
                important: fieldIsImportant(community,sf)
            })
        }
    });

    return a;
}

// for admins
export function memberEditObj(member,id,profile_sections,community,my_profile,_actions) {

    const {display_social,display_custom,display_career,display_interests,display_education} = communityGetSectionsToDisplay(community,true,my_profile);

    let base = {
        data: member,

        type: 'member',
        id: id,
        endpoint: '/members/update-profile',
        update_fields: ['about', 'birthday', 'permissions', 'custom_fields', 'member_type', 'profile_picture', 'contact', 'social', 'address'],

        sections: [
            {
                title: 'About',
                fields: [
                    {
                        field: 'about.first_name',
                        type: 'text',
                        name: 'First Name'
                    },
                    {
                        field: 'about.last_name',
                        type: 'text',
                        name: 'Last Name'
                    },
                    {
                        field: 'about.title',
                        type: 'text',
                        defer: true,
                        name: 'Title'
                    },
                    {
                        field: 'about.middle_name',
                        type: 'text',
                        defer: true,
                        name: 'Middle Name'
                    },
                    {
                        field: 'about.gender',
                        type: 'gender',
                        defer: true,
                        name: 'Sex'
                    },
                    {
                        field: 'about.location',
                        type: 'location',
                        defer: true,
                        name: 'Location'
                    },
                    {
                        field: 'about.occupation',
                        type: 'occupation',
                        defer: true,
                        name: 'Occupation'
                    },
                    {
                        field: 'about.bio',
                        type: 'textarea',
                        defer: true,
                        col_span: 2,
                        name: 'Bio'
                    }
                ]
            },
            {
                title: 'Contact',
                fields: [
                    // todo build
                    {
                        field: 'contact.email',
                        type: 'select-email',
                        action: <ActionWithIcon text="Change" onClick={()=>_actions.changeAccountEmail()} />,
                        defer: true,
                        name: 'Email',
                        options: {
                            choices: buildEmailOptions(member)
                        }
                    },
                    {
                        field: 'contact.phone',
                        type: 'phone',
                        defer: true,
                        name: 'Phone'
                    },
                    {
                        field: 'contact.secondary_phone',
                        type: 'phone',
                        defer: true,
                        name: 'Secondary Phone'
                    }
                ]
            },
            {
                title: 'Address',
                fields: [
                    {
                        field: 'address.line_one',
                        type: 'text',
                        defer: true,
                        name: 'Line one'
                    },
                    {
                        field: 'address.line_two',
                        type: 'text',
                        defer: true,
                        name: 'Line two'
                    },
                    {
                        field: 'address.city',
                        type: 'text',
                        defer: true,
                        name: 'City'
                    },
                    {
                        field: 'address.zip_code',
                        type: 'text',
                        defer: true,
                        name: 'ZIP Code'
                    },
                    {
                        field: 'address.state',
                        type: 'text',
                        defer: true,
                        name: 'State'
                    },
                    {
                        field: 'address.country',
                        type: 'country',
                        name: 'Country'
                    },
                ]
            },
        ]
    };

    if(display_social) {
        console.log('display_social',display_social,community)
        const sf = getSocialFieldsToShow(community.data);
        if(sf.length>0) {
            base.sections.push({
                title: 'Social',
                fields: sf
            })
        }
    }

    function transformFields(ids,data) {
        let arr = [];
        for(let i=0;i<ids.length;i++) {
            const field = data[ids[i]];
            arr.push({
                field: `custom_fields.${ids[i]}`,
                type: field.type,
                edit_only: field.type==='checkbox',
                options: field.options,
                name: field.name,
                defer: true
            })
        }
        return arr;
    }

    let ps_keys = Object.keys(profile_sections);

    for(let i=0;i<ps_keys.length;i++) {
        const sec = profile_sections[ps_keys[i]];

        const fields_transformed = transformFields(sec.fields,sec.field_data);

        if(display_custom[ps_keys[i]]) {
            base.sections.push({
                title: sec.name,
                visibility: sec.visibility ? sec.visibility : 'all-members',
                custom_id: ps_keys[i],
                fields: fields_transformed
            })
        }
    }

    return base;
}