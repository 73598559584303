import React from "react";
import {XIcon} from "@heroicons/react/solid";

export function RulesFrame({icon,onDelete,children}) {
    return <div className="px-4 py-3 border-2 rounded-xl border-gray-300 shadow-sm">
        <div className="flex space-x-3 text-gray-800">
            <div className='flex-none w-6 flex items-center justify-center'>
                <div className="w-5 h-5">
                    {icon}
                </div>
            </div>
            <div className="flex-grow flex items-center flex-wrap space-x-0.5">
                {children}
            </div>
            {onDelete&&<div className="flex-none">
                <div onClick={onDelete} className="p-1 h-8 w-8 flex text-gray-500 transition-colors cursor-pointer hover:text-gray-700 hover:bg-gray-200 items-center justify-center rounded-md">
                    <div className="w-4 h-4">
                        <XIcon />
                    </div>
                </div>
            </div>}
        </div>
    </div>
}

export function RulesInput({autoFocus=false,value='',onChange=()=>{}}) {

    const style = 'pr-1.5 pl-2 border-2 text-base text-gray-600 mx-1 py-0.5 font-medium rounded-md -my-0.5 bg-gray-100 cursor-pointer hover:bg-white focus-within:bg-white focus-within:border-gray-400 transition-colors';

    return <input autoFocus={autoFocus} className={`${style}`} value={value} onChange={e=>onChange(e.target.value)} />
}

export function RulesTextArea({autoFocus=false,value='',onChange=()=>{}}) {

    const style = 'pr-1.5 pl-2 resize-none border-2 text-base text-gray-600 mx-1 py-0.5 font-medium rounded-md -my-0.5 bg-gray-100 cursor-pointer hover:bg-white focus-within:bg-white focus-within:border-gray-400 transition-colors';

    return <textarea autoFocus={autoFocus} className={`${style}`} value={value} onChange={e=>onChange(e.target.value)} />
}