import React, {useContext, useState} from "react";
import {DEFAULT_USER_PHOTO} from "../../../../config/defaults";
import {Avatar} from "../../core/avatar";
import {Card} from "../../core/card";
import {CheckIcon, PencilIcon, PhotographIcon, XIcon} from "@heroicons/react/solid";
import styled from "styled-components";
import {PFFieldRenderer} from "../../../../app/quick-profile-update/field-renderer";
import {validateEmail} from "../../../utilities/validation";
import {PhotoSelector} from "../../old/photo-selector";
import {cf_getCFValue} from "../../../utilities/community/custom-fields";
import {fv_getFormattedVal} from "../../../utilities/format-values";
import {useIntl} from "react-intl";
import {getGreetingFromUserTimezone} from "../../../utilities/time-greeting";
import {UserEmailManager} from "../../user-email-manager";
import {HandleBadge} from "../../../../app/community/admin/member/components/handle-badge";
import {authFetch} from "../../../../config/network";
import {GlobalContext} from "../../../../app/global/global-context";
import {CommunityContext} from "../../../../app/community/community-context";
import {getMemberProfileFlags} from "../../old/person-profile";
import {getSocialFieldsToShow} from "../../../../app/community/admin/member/edit-obj";
import {HWCSideId} from "./sides/id";
import {HWCSideFields} from "./sides/fields";
import {HWCSideContact} from "./sides/contact";
import {HWCSideAddress} from "./sides/address";
import {HWCSideSocial} from "./sides/social";
import {NewTooltip} from "../new-tooltip";
import {isAnonymousUnatyEmail} from "../../../utilities/anonymous-emails";
import {HWCPrompt} from "./sides/field-prompt";

const mock = {
    name: 'Sean',
    profile_picture: DEFAULT_USER_PHOTO,

    updateEmail: () => {
    },
    updatePhone: () => {
    },
    updateAddress: () => {
    },

    email: 'seanmccall14@gmail.com',
    phone: '+1 262 825-2883',

    address: {
        city: '',
        state: '',
        line_one: '',
        line_two: '',
        country: '',
        zip_code: ''
    },

    notice: null,

    shortcuts: [
        {
            color: '',
            icon: null,
            link: '',
            title: '',
            subtitle: ''
        }
    ]
};

const SIBox = styled.div`
  > svg {
    height: 20px;
  }
`;

const InputItem = styled.div`
  > svg {
    height: 16px;
  }
`;

const SmallIcon = styled.div`
  > svg {
    height: 14px;
  }
`;

function Shortcut({title, subtitle, bg, icon, color, link, history}) {
    const {formatMessage: f} = useIntl();
    return <div className="flex-none" onClick={() => history.push(link)}>
        <div className={`flex py-1 pl-1 pr-6 cursor-pointer hover:bg-gray-100 transition-colors rounded-md`}>
            <div className={`${bg} w-12 px-2 flex justify-center py-3 items-center rounded-md`}>
                <SIBox className={`${color} flex items-center justify-center rounded-full h-8 w-8 text-white`}>
                    {icon}
                </SIBox>
            </div>
            <div className="pt-0.5 pl-2.5" style={{maxWidth: '16rem'}}>
                <div className="text-sm font-semibold text-gray-700">{f({id: title})}</div>
                <div className="text-xs text-gray-500">{f({id: subtitle})}</div>
            </div>
        </div>
    </div>;
}

function validateValue(type, val) {
    if (type === 'email') {
        return validateEmail(val);
    } else if (type === 'phone') {
        return true;
    }

    return true;
}

export function WCField({
                   editing,
                            truncate=true,
                   placeholder,
                   value,
                   handleSave,
                   cf_data = {},
                   id,
                   meta,
                   value_formatted,
                   label,
                   type,
                   setEditing
               }) {
    const [val, setVal] = useState(value);
    const can_save = validateValue(type, val);

    function trySave() {
        if (can_save) {
            setEditing('');
            const cb = () => {
            };
            handleSave(val, id, type, cb);
        }
    }

    return <div className="relative">
        <div className="block flex-grow text-sm  text-gray-600 mb-1 truncate flex">
            <div>{label}</div>
        </div>
        <div onClick={() => setEditing(id)}
             className={`text-base ${truncate?"truncate":""} text-gray-800 -m-1 p-1 rounded-md hover:bg-gray-100 cursor-pointer`}>
            {value_formatted}
        </div>
        {editing !== id && <div className="absolute right-0 top-0">
            <SmallIcon onClick={() => setEditing(id)} className="cursor-pointer hover:text-gray-800 text-gray-300">
                <PencilIcon/>
            </SmallIcon>
        </div>}
        {editing === id && <div className="absolute z-9999 top-5 left-0 right-0" style={{minWidth: '310px'}}>
            <div className="relative max-w-lg">
                <PFFieldRenderer meta={meta} onChange={(v, ex) => {
                    if (typeof v === 'string') {
                        setVal(v);
                    } else if (Array.isArray(v)) {
                        setVal(v);
                    } else {
                        setVal({...v});
                    }
                }} type={type} placeholder={placeholder} input_props={{autoFocus: true, onEnter: trySave}}
                                 cf_data={cf_data}
                                 value={val}/>
                <div className="space-x-1 flex absolute right-0 -bottom-7">
                    <InputItem onClick={() => {
                        setVal(value);
                        setEditing('');
                    }}
                               className={`text-gray-500 bg-white hover:opacity-70 cursor-pointer h-6 w-6 flex items-center justify-center rounded-md shadow`}>
                        <XIcon/>
                    </InputItem>
                    <InputItem onClick={trySave}
                               className={`text-white hover:opacity-70 cursor-pointer ${can_save ? "bg-success" : "bg-gray-400"} h-6 w-6 flex items-center justify-center rounded-md shadow`}>
                        <CheckIcon/>
                    </InputItem>
                </div>
            </div>
        </div>}
    </div>
}

function Tab({active,label,onClick}) {

    return <div>
        <NewTooltip message={label}>
            <div onClick={onClick} className={`rounded-md w-10 hover:bg-gray-300 transition-colors cursor-pointer h-1.5 ${active?"bg-gray-400":"bg-gray-200"}`} />
        </NewTooltip>
    </div>
}

function CardFrame({children,accent,setSide=()=>{},sides=[],active=""}) {

    return <div style={{paddingBottom:'60%'}} className="relative bg-white bg-center bg-cover w-full shadow-lg rounded-lg">
       <div style={{backgroundColor:`${accent}`}} className="absolute h-1.5 rounded-t-lg top-0 left-0 right-0" />
        <div className="absolute top-0 right-0 bottom-0 left-0">
            {children}
        </div>
        {sides&&<div className="absolute bottom-2.5 left-3 right-3">
            <div className="flex space-x-1.5">
                {sides.map(si=>{
                    return <Tab key={si.id} label={si.label} onClick={()=>setSide(si.id)} active={active===si.id}/>
                })}
            </div>
        </div>}
    </div>
}

function buildSides(data,community,extra) {
    const {custom_fields,important_social_fields,address_vis} = extra;
    let base = [
        {
            id: "main",
            label: "Name & Photo",
            type: "id"
        },
        {
            id: "contact",
            label: "Email & Phone",
            type: "contact"
        }
    ];

    if(address_vis==='full'||address_vis==='simple') {
        base.push({
            id: "address",
            label: "Address",
            type: "address"
        });
    }

    const isf_len = important_social_fields.length;
    if(isf_len>0) {
        base.push({
            label: "Social",
            id: "social",
            type: "social",
            fields: important_social_fields
        });
    }

    const custom_count = custom_fields.length;
    const custom_pages = Math.floor(custom_count/3);
    for(let i=0;i<custom_pages;i++) {
        const start = i * 3;
        base.push({
            label: `Custom`,
            id: `custom-${i}`,
            type: "fields",
            fields: [...custom_fields.slice(start,start+3)]
        });
    }
    return base;
}

function getPrompt(data,community) {
return null;
    if(!isAnonymousUnatyEmail(data.account_email)) {
        return {
            id: "no account email",
            label: "Account Email",
            type: "prompt"
        }
    }

    return null;
}

export function HomeWelcomeCard({data = mock, history, meta}) {
    const [editing, setEditing] = useState('');
    const global = useContext(GlobalContext);
    const community = useContext(CommunityContext);
    const {formatMessage: f} = useIntl();
    const {custom_fields = [], custom_fields_data = {}} = data;
const [side,setSide] = useState("main")

    const social_fields = getSocialFieldsToShow(community.data);

    const important_social_fields = social_fields.filter(f => f.important);

    const profile_flags = getMemberProfileFlags({community: community.data});

    let address_vis = profile_flags.address_visibility;

    const sides = buildSides(data,community,{important_social_fields,address_vis,custom_fields});

    const [profile_pic, setProfilePic] = useState(data.profile_picture);

    function handleSave(value, field, type, cb) {
        if (field === 'address') {
            data.updateAddress(value, cb);
        } else if (field === 'phone') {
            data.updatePhone(value, cb);
        } else if (field === 'email') {
            // email
            data.updateEmail(value, cb);
        } else if(field.startsWith('social.')) {
            data.updateSocialField(field, value, cb)
        } else {

            data.updateCustomField(field, value, cb)
        }
    }

    function handleChangeHandle(h) {
        const res = () => {
            global.addToast({
                text: "Handle changed",
                intent: "success"
            });
        };
        global.addToast({
            text: `Changing handle to @${h}`,
            intent: "info"
        });
        const payload = {
            community_uid: community.uid,
            member_id: community.member_id,
            id: community.member_id,
            new_handle: h
        };
        authFetch("/members/change-handle", res, res, "POST", {payload});
    }

    function renderSide(sp,current_side,is_prompt) {
        if(is_prompt) {
            return <HWCPrompt data={current_side} {...sp} />
        } else if(side==='main') {
            return <HWCSideId side={current_side} {...sp} />;
        } else if(side==='contact') {
            return <HWCSideContact side={current_side} {...sp} />;
        } else if(side==='social') {
            return <HWCSideSocial side={current_side} {...sp} />;
        } else if(side==='address') {
            return <HWCSideAddress side={current_side} {...sp} />;
        } else {
            return <HWCSideFields side={current_side} {...sp} />;
        }
    }

    // {f({id:`welcome_card.${getGreetingFromUserTimezone()}`})}, {data.name}
    //{f({id:'welcome_card.greeting'})}


    const side_props = {
        data,
        meta,
        profile_pic,
        community,
        handleSave,editing,setEditing,
        custom_fields,
        important_social_fields,
        goToSide: ns=>setSide(ns),
        goToProfile: ()=>history.push(`/${community.data.handle}/member/${community.member.handle}`),
        address_vis,
        member_type: community.member_types[community.member.member_type]?community.member_types[community.member.member_type].singular:"",
        setProfilePic
    };

    const prompt = getPrompt(community.member);

    if(prompt) {
        return <CardFrame active={side} accent={community.data.branding.color}>
            {renderSide(side_props,prompt,true)}
        </CardFrame>
    }

//   <HWCSideId {...side_props} />
    return <CardFrame setSide={si=>setSide(si)} sides={sides.map(s=> {
        return {
            label: s.label,
            id: s.id
        }
    })} active={side} accent={community.data.branding.color}>
        {renderSide(side_props,sides.filter(si=>si.id===side)[0])}
    </CardFrame>;
}