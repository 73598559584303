import React, {useState} from 'react';
import {Button} from "../core";
import {ArrowRightIcon} from "@heroicons/react/solid";
import {ProfileSectionVisibilitySelector} from "./profile-section-visibility-selector";

export function AddCustomFieldsSection({handleAdd=()=>{}}) {
    const [name,setName] = useState('');
    const [visibility,setVisibility] = useState('all-members');

    function onAdd() {
        if(name.trim().length===0) {
            return;
        }
        handleAdd({name:name.trim(),visibility});
        setName('');
        setVisibility('all-members');
    }

    function handleKeyDown(e) {
        if(e.key === 'Enter') {
            handleAdd(name);
        }
    }

    const input_classes = name === "" ? "bg-gray-100 hover:bg-white focus-within:bg-white border-gray-100" : "bg-white border-gray-300";

    const disabled = name.trim().length===0;

    return <div className={`rounded-md border p-2 shadow-sm space-x-1.5 flex ${name===''?"border-gray-100 bg-gray-100":"bg-gray-100 border-gray-300"}`}>
        <div className="flex-grow flex">
            <input onKeyDown={handleKeyDown} maxLength={48} value={name} onChange={(e)=>setName(e.target.value)} className={`text-base w-full font-medium border text-gray-800 px-2.5 py-1 rounded-md transition-colors focus-standard focus-within:bg-white ${input_classes}`} placeholder="New Section"  />
        </div>
        <div className={`flex-none ${name===''?"opacity-50 cursor-not-allowed pointer-events-none":""}`}>
            <ProfileSectionVisibilitySelector layout="icon-label" selection={visibility} onSelect={(v)=>setVisibility(v)} />
        </div>
        <div className={`flex-none`}>
            <Button onClick={()=>onAdd()} right_icon={<ArrowRightIcon/>} disabled={disabled} text="Add" intent="secondary" />
        </div>
    </div>
}