import React, {useState} from 'react';
import {ATLayoutList} from "./layout-list";
import {ATActionsFooter, ATPopoverContents} from "../components/shared";
import styled from "styled-components";
import {Button} from "../../core";
import {TextAndEmoji} from "../../core";
import {CUSTOM_NANOID} from "../../../../config/defaults";

const NameWrapper = styled.div`
    padding: 12px 12px 8px 12px;
`;


// view = current view
export function ATCreateView({definitions, view, addView}) {

    function getInit() {
        return {
            name: `${view.name} Copy`,
            emoji: '',
            layout: view.layout
        }
    }

    const [value, setValue] = useState(getInit());

    function updateView(f, v) {
        setValue({
            ...value,
            [f]: v
        });
    }

    function updateViewName(e,n) {
        setValue({
            ...value,
            'name': n,
            'emoji': e
        });
    }

    function createView() {
        let final = {
          ...value,
          config: {
              ...view,
          }
        };
        final.config.name = value.name;
        final.config.id = CUSTOM_NANOID();
        // need to add id and name in the config
        addView(final);
        setValue({...getInit()});
    }

    return <ATPopoverContents>
        <NameWrapper>
        <TextAndEmoji usePortal autoFocus onChange={(n,e)=>{
            updateViewName(e,n)
        }} emoji={value.emoji} value={value.name} placeholder="View Name" />
        </NameWrapper>
        <ATLayoutList onChange={updateView} selected={value.layout} layouts={definitions.allowed_layouts}/>
        <ATActionsFooter primary={<Button onClick={()=>createView()} disabled={value.name.length < 2} intent='secondary' text='Create'/>}>

        </ATActionsFooter>
    </ATPopoverContents>
}