import React from 'react';
import styled from "styled-components";
import {InformationCircleIcon} from "@heroicons/react/solid";
import {NewTooltip} from "../reorganize/new-tooltip";

const IconItem = styled.div`
  > svg {
    height: 16px;
    width: 16px;
  }
`;

function getPercent(integer) {
    return `${(integer * 100).toFixed(1)}%`
}

function getColor(delta) {
    if(delta>0) {
        return 'green'
    } else if(delta<0) {
        return 'red'
    } else {
        return 'gray'
    }
}

function getSign(delta) {
    if(delta>0) {
        return '+'
    } else if(delta<0) {
        return ''
    } else {
        return '+'
    }
}

export function SimpleMetricsItem(props) {

    const {
        title = 'Data Quality',
        tooltip_text = '',
        number = 59,
        delta = 0.05,
        description = '3 members joined this month',
        custom = null,
        action = null
    } = props;

    const delta_sign = getSign(delta);
    const delta_color = getColor(delta);

    const delta_item = delta ? <div className={`text-sm`} style={{color:delta_color}}>{delta_sign}{getPercent(delta)}</div> : null;

    return <div className={`pl-3.5 pr-3.5 pt-3 pb-1.5 max-w-xs`}>
        <div className={`flex items-baseline`}>
            <div className={`flex-grow flex items-center space-x-1.5`}>
                <div className={`text-sm font-medium text-gray-600`}>
                {title}
                </div>
                {tooltip_text&&<div>
                    <NewTooltip message={tooltip_text}>
                    <IconItem className={`text-gray-500 hover:text-gray-700 cursor-pointer`}>
                        <InformationCircleIcon />
                    </IconItem>
                    </NewTooltip>
                </div>}

            </div>
            <div className={`flex-none`}>
                {action}
            </div>
        </div>
        <div className={`flex items-baseline space-x-1.5 pt-2.5`}>
            <div className={`font-bold text-3xl text-gray-800`}>{number}</div>
            {delta_item}
        </div>
        <div className="h-8 flex items-center">
            {description&&!custom&&<div className="text-xs text-gray-600">{description}</div>}
            {custom&&<div>{custom}</div>}
        </div>
    </div>;
}