import {authFetch} from "../../config/network";
import {FirebaseDB} from "../../config/setup-firestore";

const mock = {
    emails: []
};

export async function gp_pu_getConfig() {
    return await FirebaseDB
        .collection('product_updates')
        .doc('config')
        .get()
        .then(doc => {
            return doc.exists ? doc.data() : mock
        });
}


export function gp_pu_newProductUpdate(obj, cb) {
    const payload = {
        ...obj
    };
    authFetch("/superadmin/new-product-update", cb, cb, "POST", {payload});
}