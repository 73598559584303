import React from 'react';
import classNames from 'classnames';
import {getInitials} from "../../utilities/get-initials";
import {PencilIcon, PlusIcon} from "@heroicons/react/solid";

function getDimensions(size) {
    if (size.indexOf("px") !== -1) {
        return '';
    }
    switch (size) {
        case 'mini': {
            return 'h-5 w-5'
        }
        case 'md': {
            return 'h-10 w-10';
        }
        case 'sm': {
            return 'h-8 w-8';
        }
        case 'lg': {
            return 'h-12 w-12';
        }
        case 'xs': {
            return 'h-6 w-6';
        }
        case 'xl': {
            return 'h-14 w-14';
        }
        case '2xl': {
            return 'h-16 w-16';
        }
        case '3xl': {
            return 'h-20 w-20';
        }
        case '4xl': {
            return 'h-32 w-32';
        }
        case '5xl': {
            return 'h-36 w-36';
        }
        case '6xl': {
            return 'h-40 w-40';
        }
        case '7xl': {
            return 'h-44 w-44';
        }
        default : {
            return 'h-10 w-10';
        }
    }
}

function getTagSize(size) {
    if (size.indexOf("px") !== -1) {
        return '';
    }
    switch (size) {
        case 'md': {
            return 'h-3.5 w-3.5';
        }
        case 'sm': {
            return 'h-3 w-3';
        }
        case 'lg': {
            return 'h-4 w-4';
        }
        case 'xs': {
            return 'h-2.5 w-2.5';
        }
        case 'mini': {
            return 'h-2 w-2';
        }
        case 'xl': {
            return 'h-5 w-5';
        }
        case '2xl': {
            return 'h-6 w-6';
        }
        case '3xl': {
            return 'h-8 w-8';
        }
        case '4xl': {
            return 'h-10 w-10';
        }
        default : {
            return 'h-4 w-4';
        }
    }
}

function getFontSize(size) {

    switch (size) {
        case 'md': {
            return 'text-base';
        }
        case 'sm': {
            return 'text-sm';
        }
        case 'lg': {
            return 'text-lg';
        }
        case 'xs': {
            return 'text-xs';
        }
        case 'mini': {
            return 'text-xs';
        }
        case 'xl': {
            return 'text-xl';
        }
        case '2xl': {
            return 'text-2xl';
        }
        case '3xl': {
            return 'text-3xl';
        }
        case '4xl': {
            return 'text-4xl';
        }
        default : {
            return 'text-xs';
        }
    }
}

function getRounding(type, size) {
    switch (type) {
        case 'group' : {
            return 'rounded-md';
        }
        case 'member': {
            return 'rounded-42';
        }
        case 'circular': {
            return 'rounded-full';
        }
        case 'community': {
            return 'rounded-xl';
        }
        default : {
            return 'rounded-md';
        }
    }
}

function getBorderWidth(size) {
    if (size === '3xl') {
        return '-3';
    } else if (size === '2xl') {
        return '-3';
    } else if (size === 'xl') {
        return '-3';
    } else if (size === 'sm' || size === 'xs') {
        return '';
    } else if ('34px') {
        return '';
    } else if ('20px') {
        return '';
    } else if ('28px') {
        return '';
    } else {
        return '-2';
    }
}

function getImageBorder(type) {

    if (type === 'community') {
        return '';
    }
    return '';
}

function getEditButtonSizes(size) {

    if (size === '4xl') {
        return {
            h: 'h-10 w-10',
            bd: 'border-4',
            p: '-right-1 -bottom-1',
            b: 'h-6 w-6'
        }
    } else if (size === '138px') {
        return {
            h: 'h-8 w-8',
            bd: 'border-3',
            p: 'right-0 bottom-0',
            b: 'h-4 w-4'
        }
    }

    return {
        h: 'h-6 w-6',
        bd: 'border-2',
        p: '-right-1 -bottom-1',
        b: 'h-3.5 w-3.5'
    }
}

function EditButton({size, empty}) {

    const {h, b, p, bd} = getEditButtonSizes(size);
    return <div
        className={`absolute ${p} rounded-42 cursor-pointer`}>
        <div
            className={`${h} border-white ${bd} text-white rounded-full bg-secondary hover:bg-secondary-800 flex items-center justify-center`}>
            <div className={`${b}`}>
                {empty ? <PlusIcon/> : <PencilIcon/>}
            </div>
        </div>
    </div>
}

export function Avatar({
                           onClick,
                           url = "",
                           image_styles = {},
                           inner_size,
                           text = "John Smith",
                           border_color = "white",
                           border,
                           bg,
                           empty,
                           type = "member",
                           show_online,
                           online,
                           show_edit,
                           size = "md"
                       }) {
    let style = {};
    let style_inner = {};
    if (size.indexOf("px")) {
        style.height = `${size}`;
        style.width = `${size}`;

        style_inner.width = `${size}`;
        style_inner.height = `${size}`;
    }
    if (border) {
        style_inner.width = `${inner_size}`;
        style_inner.height = `${inner_size}`;
        style.border = `1px solid ${border_color}`;
    }
    const initials = getInitials(text);
    const rounded = getRounding(type, size);
//border${getBorderWidth(size)} border-solid
    return <div onClick={(e) => {
        if (!onClick) {
            return;
        }
        onClick(e);
    }} style={style}
                className={classNames("relative inline-flex bg-gray-100", getDimensions(size), rounded, show_edit || !!onClick ? "cursor-pointer" : "")}>
        {url && <div
            style={{...style_inner, backgroundImage: `url('${url}')`, ...image_styles}}
            className={classNames(getDimensions(size), "transition-opacity", onClick ? "hover:opacity-70" : "", rounded, "inline-block bg-cover bg-center bg-gray-200", getImageBorder(type))}
        />}
        {text && !url && <div style={{borderColor: `${bg}`, ...style_inner}}
                              className={classNames(getDimensions(size, border), rounded, getFontSize(size), ` bg-gray-400 tracking-wide flex items-center justify-center text-white font-medium`, getImageBorder(type))}>{initials}</div>}
        {show_online && <div style={{borderColor: `${border_color}`}}
                             className={classNames(getTagSize(size), `border${getBorderWidth(size)} border-solid absolute -right-0.5 -bottom-0.5 rounded-full bg-green-500`)}/>}
        {show_edit && <EditButton empty={empty} size={size}/>}
    </div>
}