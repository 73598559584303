import React, {useContext, useState} from 'react';
import {authFetch} from "../../../../config/network";
import {DetailModalHoverField} from "./modal";
import {GlobalContext} from "../../../global/global-context";
import {useHistory} from "react-router-dom";
import {APP_BASE_URL} from "../../../../config/defaults";
import {GroupRenameModal} from "../../group/settings/rename-modal";

const modal_double_checks = [
    'make_group_closed',
    'make_group_open',
    'group_delete',
    'convert_group_to_team',
    'convert_team_to_group'
];

export function FolderModalAboutTab({community,can_manage=false,setData=()=>{},getFolder=()=>{},cb=()=>{},id,folder_data}) {
    const [modal,setModal] = useState("");
    const [loading,setLoading] = useState("");
    const global = useContext(GlobalContext);
const history = useHistory();
    const community_uid = community.uid;

    function renameFolder(n, e, pp, ppc, co, cb) {
        const payload = {
            community_uid: community.uid,
            name: n,
            emoji: e,
            profile_picture: pp,
            profile_picture_color: ppc,
            member_id: community.member_id,
            color: co,
            id: id,
        };

        setData({
            ...folder_data,
            profile_picture: pp,
            profile_picture_color: ppc,
            color: co,
            name: n,
            emoji: e,
        })

        setModal(null);

        const res = () => {
            getFolder(cb);
            global.addToast({text: 'Name updated', intent: 'success'});
        };

        const err = () => {
            global.addToast({text: 'Name could not be updated', intent: 'danger'});
        };

        authFetch("/folders/rename", res, err, "POST", {payload});
    }

    return <div className="pb-2 pr-1.5">
        <div className="space-y-2">
            <DetailModalHoverField can_edit={can_manage} label="Name" value={folder_data.name} placeholder="Add a Name" onClick={()=>{
                setModal('rename');
            }} />

        </div>

        {modal === 'rename' &&<GroupRenameModal loading={loading === 'rename'} community_uid={community.uid}
                                                data={{name: folder_data.name}}
                                                value={folder_data.name} emoji={folder_data.emoji} color={folder_data.color}
                                                profile_picture_color={folder_data.profile_picture_color}
                                                profile_picture={folder_data.profile_picture}
                                                onConfirm={renameFolder}
                                                onClose={() => {
                                                    setModal(null);
                                                }} onCancel={() => {
            setModal(null);
        }} />}
    </div>
}