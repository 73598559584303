import React, {useContext, useEffect, useState} from 'react';
import {getRules} from "../../admin/settings/hidden";
import {CommunityContext} from "../../community-context";
import {CheckCircleIcon, LightBulbIcon, PaperAirplaneIcon, SparklesIcon} from "@heroicons/react/solid";
import RoleIcon from "../../../../library/icons/custom/RoleIcon";
import {EntityInfo} from "../../../../library/utilities/localstorage/entity-localstorage";
import {ActionWithIcon} from "../../../../library/components/core";
import {rl_parseRelevantSuggestions} from "./rules-logic";

function ActionContainer({children, text="Apply", onClick, icon}) {

    return <div className="flex py-1 px-1 items-center rounded-lg hover:bg-gray-100">
        <div className="h-svg-5 self-start justify-center w-5 flex-none">
            {icon}
        </div>
        <div className="text-xssm pt-px pl-1.5 flex-grow text-gray-700">
            {children}
        </div>
        <div className="flex-none pl-4 pr-2.5">
            <ActionWithIcon inverse text={text} onClick={()=>onClick()} />
        </div>
    </div>
}

function Name({data, comma, and}) {
    return <span><strong>{data.name}</strong>{comma ? "," : ""}{and ? " and" : ""}&nbsp;</span>
}

function MultiMemberMessage({items,message="not been invited.",context}) {
    const len = items.length;
    return <>
        <EntityInfo key={items[0].id} id={items[0].id} community_uid={context.community_uid} type={"members"}>
            <Name and={len===2} comma={len>2}/>
        </EntityInfo>
        {len>1&&<EntityInfo key={items[1].id} id={items[1].id} community_uid={context.community_uid} type={"members"}>
            <Name and={len===3} comma={len>2}/>
        </EntityInfo>}
        {len===3&&<EntityInfo key={items[2].id} id={items[2].id} community_uid={context.community_uid} type={"members"}>
            <Name and={true}/>
        </EntityInfo>}
        {len>3&&`and ${len-2} others `}
        {message}
    </>
}

function DataCheckIcon() {
    return <div className="text-green-700 h-svg-4 pt-px">
        <CheckCircleIcon />
    </div>
}

function CrowdSourceIcon() {
    return <div className="text-yellow-600 h-svg-4 pt-px">
        <LightBulbIcon />
    </div>
}

function InviteIcon() {
    return <div className="text-secondary h-svg-4 pt-px">
        <PaperAirplaneIcon />
    </div>
}

function SuggestionAction({
                              type, onBlur = () => {
    }, _actions, items, context
                          }) {
    if (type === 'create-role') {
        const len = items.length;
        return <ActionContainer onClick={() => {
            _actions.createRoles(items);
            onBlur();
        }} icon={<RoleIcon/>}>
            Create {items.length} new Role{items.length === 1 ? "" : "s"}: {items.map((it, index) => {
            const and = len > 1 && index === (len - 2);
            const comma = len > 2 && index !== (len - 1);
            return <Name data={{name: it.name}} key={it.name} and={and} comma={comma}/>
        })}
        </ActionContainer>
    } else if (type === 'remove-member-moderator') {
        const len = items.length;
        return <ActionContainer onClick={() => {
            _actions.removeMembersAsModerators(items);
            onBlur();
        }} icon={<RoleIcon/>}>
            {items.map((id, index) => {
                const and = len > 1 && index === (len - 2);
                const comma = len > 2 && index !== (len - 1);
                return <EntityInfo key={id} id={id} community_uid={context.community_uid} type={"members"}>
                    <Name and={and} comma={comma}/>
                </EntityInfo>
            })}{len === 1 ? "is" : "are"} already moderator{len === 1 ? "" : "s"} through a role assignment. Want to
            clean up {len === 1 ? "this duplicate" : "these duplicates"}?
        </ActionContainer>
    } else if(type==='suggest-role-order') {
        const len = items.length;
        return <ActionContainer onClick={() => {
            _actions.setRoleOrder(items);
            onBlur();
        }} icon={<RoleIcon/>}>
            Set the role display order to: {items.map((it, index) => {
            const and = len > 1 && index === (len - 2);
            const comma = len > 2 && index !== (len - 1);
            return <EntityInfo key={it} id={it} community_uid={context.community_uid} type={"roles"}>
                <Name and={and} comma={comma}/>
            </EntityInfo>
        })}
        </ActionContainer>
    } else if (type === 'create-new-roles-for-member-mods') {
        const len = items.length;
        return <ActionContainer onClick={() => {
            _actions.createRolesForMembers(items);
            onBlur();
        }} icon={<RoleIcon/>}>
            Create {len === 1 ? "a new role" : "new roles"}, <strong>{items[0].name}</strong>,
            for {items.map((item, index) => {
            const and = len > 1 && index === (len - 2);
            const comma = len > 2 && index !== (len - 1);
            return <EntityInfo key={item.member_id} id={item.member_id} community_uid={context.community_uid}
                               type={"members"}>
                <Name and={and} comma={comma}/>
            </EntityInfo>
        })}
        </ActionContainer>
    } else if (type === 'replace-member-with-existing-role') {
        const len = items.length;
        return <ActionContainer onClick={() => {
            _actions.addRolesAsModerators(items.map(it => it.role_id), true);
            onBlur();
        }} icon={<RoleIcon/>}>
            Add {items.map((it, index) => {
            const and = len > 1 && index === (len - 2);
            const comma = len > 2 && index !== (len - 1);
            return <EntityInfo key={it.role_id} id={it.role_id} community_uid={context.community_uid} type={"roles"}>
                <Name and={and} comma={comma}/>
            </EntityInfo>
        })}as moderator{len === 1 ? "" : "s"}. {items.map((item, index) => {
            const and = len > 1 && index === (len - 2);
            const comma = len > 2 && index !== (len - 1);
            return <EntityInfo key={item.member_id} id={item.member_id} community_uid={context.community_uid}
                               type={"members"}>
                <Name and={and} comma={comma}/>
            </EntityInfo>
        })}will stay {len === 1 ? "moderator" : "moderators"} through the role{len === 1 ? "" : "s"}.
        </ActionContainer>
    } else if (type === 'add-role') {
        const len = items.length;
        return <ActionContainer onClick={() => {
            _actions.addRolesAsModerators(items.map(it => it.id));
            onBlur();
        }} icon={<RoleIcon/>}>
            Add {items.map((it, index) => {
            const and = len > 1 && index === (len - 2);
            const comma = len > 2 && index !== (len - 1);
            return <EntityInfo key={it.id} id={it.id} community_uid={context.community_uid} type={"roles"}>
                <Name and={and} comma={comma}/>
            </EntityInfo>
        })}as {len === 1 ? "a moderator" : "moderators"}
        </ActionContainer>
    } else if (type === 'assign-member-to-role') {
        return <div>
            assign-member-to-role
        </div>
    } else if (type === 'send-invite') {
        const len = items.length;
        return <ActionContainer text="Send Invite" onClick={() => {
            _actions.sendInvite(items);
            onBlur();
        }} icon={<InviteIcon/>}>
            <MultiMemberMessage message={`${len===1?"has":"have"} not been invited.`} items={items} context={context} />
        </ActionContainer>
    } else if (type === 'resend-invite') {
        const len = items.length;
        return <ActionContainer text="Resend Invite" onClick={() => {
            _actions.resendInvite(items);
            onBlur();
        }} icon={<InviteIcon/>}>
            <MultiMemberMessage message={`${len===1?"has":"have"} not logged in.`} items={items} context={context} />
        </ActionContainer>
    } else if (type === 'send-data-check') {
        const len = items.length;
        return <ActionContainer text="Send Data Check" icon={<DataCheckIcon />} onClick={()=>{
            _actions.sendDataCheck(items);
            onBlur();
        }}>
            <MultiMemberMessage message={`${len===1?"profile is":"profiles are"} outdated.`} items={items} context={context} />
        </ActionContainer>
    } else if (type === 'crowdsource-email') {
        const len = items.length;
        return <ActionContainer text="Ask the Group" icon={<CrowdSourceIcon />} onClick={()=>{
            _actions.crowdsourceEmail(items);
            onBlur();
        }}>
            <MultiMemberMessage message={`${len===1?"is":"are"} missing any contact info.`} items={items} context={context} />
        </ActionContainer>
    }
    return null;
}

function checkForOverlap(s1, s2) {
    // s1 = the previous
    let overlaps = false;
    if(s1.type==='suggest-role-order') {
       overlaps = true;
    } else if (s2.type === 'replace-member-with-existing-role' && s1.type === 'add-role') {
        s1.items.forEach(it => {
            s2.items.forEach(it2 => {
                if (it.id === it2.role_id) {
                    overlaps = true;
                }
            })
        })
    } else if(s1.type==='create-new-roles-for-member-mods'&&s2.type==='replace-member-with-existing-role') {
        s1.items.forEach(it => {
            s2.items.forEach(it2 => {
                if (it.member_id === it2.member_id) {
                    overlaps = true;
                }
            })
        })
    }
    return overlaps;
}

function suggestionOverlapsWithPrevious(sugg = {}, prev = []) {
    let overlaps = false;
    prev.forEach(it => {
        const o = checkForOverlap(sugg, it);
        if (o) {
            overlaps = true;
        }
    })

    return overlaps;
}

const get_rules = ['groupmoderators'];

// direct suggestions must come pre grouped
export function RulesSuggestions({context = 'group', direct, _actions = {}, scope = 'moderators', data = {}}) {
    const community = useContext(CommunityContext);
    const [suggestions, setSuggestions] = useState(null);

    const [hidden, setHidden] = useState([]);

    useEffect(function () {
        if(get_rules.includes(`${context}${scope}`)) {
            getRules(community.uid)
                .then(r => {
                    setSuggestions(rl_parseRelevantSuggestions(context, scope, r, data, {}, community));
                })
        }
    }, [context, scope])

    let final = (direct || suggestions) ? [] : null;

    if (direct) {
        final = final.concat(direct);
    }

    if (suggestions) {
        final = final.concat(suggestions);
    }

    if (!final || final.length === 0) {
        return null;
    }

    const non_hidden = final.length - hidden.length;

    if (non_hidden === 0) {
        return null;
    }

    const _context = {
        community_uid: community.uid
    };

    return <div className="pb-2">
        <div className="flex px-1.5">
            <div className="text-primary h-svg-4">
                <SparklesIcon/>
            </div>
            <div className="pl-2 text-primary text-xs font-semibold">Suggestions</div>
        </div>
        <div className="pt-1">
            {final.map((suggestion, index) => {
                if (hidden[index]) {
                    return null;
                }
                if (suggestionOverlapsWithPrevious(suggestion, [...final].slice(0, index))) {
                    return null;
                }
                return <SuggestionAction context={_context} _actions={_actions}
                                         {...suggestion}
                                         onBlur={() => {
                                             let nh = [...hidden];
                                             nh[index] = true;
                                             setHidden(nh);
                                         }} key={suggestion.type}/>
            })}
        </div>
    </div>;
}