
export const create_role_modal = {
    flow: 'create-role',
    step: 'about',

    appearance: {
        size: 'medium',
    },

    state: {
        name: '',
        purpose: '',
        designation: '',
        assignee: '',
        handle: '',
        emoji: '',
        color: '',
        profile_picture: '',
        profile_picture_color: '',

        // added by default
        _checking_handle: false,
        _customizing: false,
        _helper_name: ''
    },

    header: {
        title: 'Create Role',
        subtitle: 'Roles are assigned to members for responsibilities.',
        show_close_icon: true
    },

    blocks: [
        {
            type: "name-emoji-handle",
            placeholder: 'President',
            value: ["emoji", "profile_picture", "profile_picture_color", "color", "handle", "name"],
            label: "Name",
            meta: "",
            handle: {
                scope: 'community',
                scope_id: '',
                type: 'role',
                type_name: 'Role'
            }
        },
        {
            label: "Assignee",
            placeholder: "Select a Member",
            optional: true,
            value: "assignee",
            type: "assignee",
            meta: "Which member is assigned to this role?"
        },
        {
            label: "Purpose",
            placeholder: "",
            optional: true,
            value: "purpose",
            type: "text",
            meta: "What is this role responsible for?"
        }
    ],

    footer: {
        primary: {
            type: 'button',
            intent: 'secondary',
            text: 'Create',
            action: 'proceed',
            disabled: [
                {
                    field: 'name',
                    check: 'length is less than',
                    value: '2'
                }
            ]
        },
        secondary: null,
        tertiary: {
            type: 'button',
            intent: '',
            minimal: true,
            text: 'Create many Roles',
            action: 'create-many'
        }
    }
};