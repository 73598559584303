import React, {useContext, useEffect, useState} from "react";

import {PageContainer} from "../../../../library/components/old/page-container";

import {setDocumentTitle} from "../../../../library/utilities/set-document-title";

import {CommunityContext} from "../../community-context";
import {
    AdminEntityInfo,
    SectionHeader,
    Field,
    Markdown,
    PageHeader,
    Button, InfoState, RichSearch, ActionWithIcon, EntityHeader
} from "../../../../library/components/core";
import {DocumentIcon} from '@heroicons/react/solid';
import {FirebaseDB} from "../../../../config/setup-firestore";
import {authFetch} from "../../../../config/network";
import {GlobalContext} from "../../../global/global-context";
import {ManagePolicyStats} from "./stats";
import {EntityInfo} from "../../../../library/utilities/localstorage/entity-localstorage";
import {DoubleCheckModal} from "../../../../library/components/old/double-check-modal";
import {CommonLayoutProfile} from "../../../../library/components/layout/page/profile";
import {StandardPageFramework, StandardPageHeight} from "../../../../library/playground/standard-page-framework";
import {aa_formatDate} from "../../../../library/utilities/general/dates";

function SearchField({community, setContact, id, type, data}) {

    const value = data ? {label: data.name, value: `${type}s-${id}`} : {label: 'Loading..', value: `${type}s-${id}`};
    return <RichSearch allowed={{members: null, roles: null}} persistent searchable={{members: true, roles: true}}
                       context={{community_uid: community.uid}} onSelect={(type, id) => {

        if (!type || !id) {
            setContact("", "");
        } else {
            setContact(type, id);
        }
    }} value={id === "" ? null : value} placeholder="Type to search.."/>
}

function PolicyContact({community, setContact, data}) {

    const it = <SearchField setContact={setContact} id={data.contact.id} type={data.contact.type}
                            community={community}/>;

    if (data.contact.id) {
        // needs an s
        return <EntityInfo key={`${data.contact.id}`} community_uid={community.uid} id={data.contact.id}
                           type={data.contact.type}>
            {it}
        </EntityInfo>
    } else {
        return it;
    }
}


export default function CommunityManagePolicyPage(props) {
    const community = useContext(CommunityContext);
    const global = useContext(GlobalContext);
    const slug = props.match.params.slug;
    const [mode, setMode] = useState("viewer");
    const [modal, setModal] = useState("");
    const [orig_content, setOrigContent] = useState("");
    const [orig_key_updates, setOrigKeyUpdates] = useState("");
    const [loading, setLoading] = useState("data");
    const [changes_made, setChangesMade] = useState(false);
    const [content_changes, setContentChanges] = useState(false);
    const [data, setData] = useState({});

    useEffect(function () {
        getPolicy();
    }, [community.uid, slug]);

    function getPolicy() {
        // add id

        FirebaseDB
            .collection('communities')
            .doc(community.uid)
            .collection('policies')
            .doc(slug)
            .get()
            .then(doc => {
                setLoading('');
                setOrigContent(doc.data().content);
                setOrigKeyUpdates(doc.data()?.key_updates||"");
                setData({...doc.data()})
            })
    }

    const is_admin = community.is_admin;

    if (loading === 'data' || (loading === '' && !data) || !is_admin) {
        return null;
    }

    function publishPolicy() {
        setLoading('publishing');
        const res = (result) => {
            setLoading('');
            setModal(null);
            setOrigContent(data.content);
            setContentChanges('');
            global.addToast({text: 'Policy Updated & Published', intent: 'success'});
        };

        const err = (error) => {

        };

        const payload = {
            community_uid: community.uid,
            member_id: community.member_id,
            content: data.content,
            key_updates: data?.key_updates || "",
            slug
        };

        authFetch("/communities/publish-policy", res, err, "POST", {payload});
    }

    function updateMeta(np) {
        setLoading('meta');
        const res = (result) => {
            setLoading('');
            global.addToast({text: 'Policy Contact Updated', intent: 'success'});
        };

        const payload = {
            community_uid: community.uid,
            member_id: community.member_id,
            slug,
            key_updates: data?.key_updates || "",
            content: data.content,
            contact: np.contact,
            summary: {}
        };

        authFetch("/communities/update-policy", res, res, "POST", {payload});
    }

    function savePolicyChanges() {
        setLoading('meta');
        const res = (result) => {
            setLoading('');
            global.addToast({text: 'Policy draft updated', intent: 'info'});
        };
        setMode('viewer')
        const payload = {
            community_uid: community.uid,
            member_id: community.member_id,
            slug,
            key_updates: data?.key_updates || "",
            content: data.content,
            contact: data.contact,
            summary: {}
        };

        authFetch("/communities/update-policy", res, res, "POST", {payload});
    }

    setDocumentTitle(data.name, community.data.name);

    let policy_button, publish;

    if (mode === 'viewer') {
        policy_button = <ActionWithIcon inverse size="sm" text="Edit" onClick={() => setMode('editor')}/>;
    } else {
        policy_button = <ActionWithIcon inverse size="sm" text="Save as Draft" onClick={() => savePolicyChanges()}/>;
    }

    const can_publish = data.content !== '' && data.name !== '';

    // when to save
    // when to publish new version
    // can't publish unless new changes to content
    publish = <Button intent="secondary" loading={loading === 'publishing'} disabled={!can_publish}
                      onClick={() => setModal('double-check-publish')} text="Publish New Version"/>;

    if (Object.keys(data).length === 0) {
        return null;
    }

    console.log("DATA",data)
    return (<StandardPageHeight full content>
            <AdminEntityInfo type_icon={<DocumentIcon/>} id={"privacy_policy"} type_label={"Policy"}/>

            <EntityHeader actions={<div className="flex space-x-2">
                <Button onClick={() => props.history.push(`/${community.data.handle}/policies/${slug}`)} text="View"/>
                {content_changes && publish}
            </div>}
                          title={data.name}/>
            <div className="flex space-x-8 pt-2 px-4 pb-12">
                <div className="flex-1">
                    <div className="space-y-4">
                        <SectionHeader divider fill padded={false} size="lg" action={policy_button} title={"Policy"}>
                            <Markdown key={'md-editor'} onChange={(mv) => {
                                setContentChanges(true);
                                setData({...data, content: mv})
                            }} fill mode={mode} value={data.content}
                                      placeholder="Start typing or paste your content.."/>
                            {data.content === '' && mode === 'viewer' &&
                                <InfoState intent="secondary" subtitle="Edit to add Policy Details"/>}
                            <div className="pt-4 ">
                                <a className="text-xs text-link" target={"_blank"} href="https://commonmark.org/help/" rel="noreferrer">Markdown
                                    Reference</a>
                            </div>
                        </SectionHeader>
                    </div>
                </div>

                <div className="w-96 flex-none">
                    <div className="space-y-4">
                        <Field label="Policy Contact"
                               help_text="Who can answer member questions about this policy?">
                            <PolicyContact setContact={(type, id) => {
                                let np = data;
                                np.contact = {
                                    type, id
                                };
                                setChangesMade(true);
                                setData({...np});
                                updateMeta(np);
                            }} data={data} community={community}/>

                        </Field>

                        {data.status === 'published' &&
                            <SectionHeader divider fill padded={false} size="lg" title={"Details"}>
                                <div className="text-sm text-gray-700 space-y-2">
                                    <div>
                                        Version {data?.version} published {aa_formatDate(data?.published_at).full_date}
                                    </div>
                                    <div className='pb-2'>Key updates from past version</div>
                                    <div>
                                        {orig_key_updates && <Markdown key={'ku-editor'} mode="viewer" value={orig_key_updates} />}
                                    </div>
                                </div>

                            </SectionHeader>
                        }

                        {data.status === 'published' &&
                            <SectionHeader divider fill padded={false} size="lg" title={"Adoption"}>
                                <ManagePolicyStats policy={data} slug={slug} community={community}/>
                            </SectionHeader>}
                    </div>
                </div>
            </div>
            {modal === 'double-check-publish' && <DoubleCheckModal type='policy_publish' onConfirm={() => {
                publishPolicy();
            }} onCancel={() => setModal(null)} onClose={() => setModal(null)}>
                <div className="pb-6">
                    <div className="pb-1">Add Key Updates</div>
                    <Markdown key={'md-editor-key-updates'} onChange={(ku) => {
                        setData({...data, key_updates: ku})
                    }} fill mode={"editor"} value={data?.key_updates||""}
                              placeholder="Start typing or paste your content.."/>
                </div>
            </DoubleCheckModal>}

        </StandardPageHeight>
    );
}