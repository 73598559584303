import {FirebaseDB} from "../../config/setup-firestore";

const default_sort = {
    field: 'about.last_name',
    dir: 'asc'
};

function addFilter(ref,filter) {

    return ref.where(filter.field,filter.operator,filter.value);
}

function addSort(ref,sort) {
    return ref.orderBy(sort.field, sort.dir);
}

const limit = 250;

export async function getManyMembers(community_uid,filters,sort=default_sort) {
    let ref = FirebaseDB
        .collection('community_members')
        .doc(community_uid)
        .collection('members');

    filters.forEach(filter=>{
        ref = addFilter(ref,filter);
    });
console.log('gret many',community_uid,filters,sort)
    ref = addSort(ref, sort);

    let has_more = true;
    let last_ref = null;

    let items = [];

    while(has_more) {
        let new_items = [];
        if(last_ref) {
            new_items = await ref
                .startAfter(last_ref)
                .limit(limit)
                .get()
                .then(snap=>{
                    if(snap.size<limit) {
                        has_more = false;
                    }
                    if(snap.size>0) {
                        last_ref = snap.docs[(snap.size-1)];
                    }
                    return snap.docs.map(d=>{
                        let obj = {...d.data()};
                        obj.id = d.id;
                        return obj;
                    })
                })
        } else {
            new_items = await ref
                .limit(limit)
                .get()
                .then(snap=>{
                    if(snap.size<limit) {
                        has_more = false;
                    }
                    if(snap.size>0) {
                        last_ref = snap.docs[(snap.size-1)];
                    }
                    return snap.docs.map(d=>{
                        let obj = {...d.data()};
                        obj.id = d.id;
                        return obj;
                    })
                })
        }
        items = items.concat(new_items);
    }
console.log("RETURN ITEMS",items)
    return items;
}