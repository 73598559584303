import React from "react"; 

export function CookiesText() {
    return <div>
            <div className="normal-secondary-header">Last updated: May 2nd, 2018</div>
            <div>
                At Unaty, we believe in being transparent about how we collect and use data. This policy
                provides information about how and when we use cookies for these purposes. Capitalized terms
                used in this policy but not defined have the meaning set forth in our <a href="https://www.unaty.de/en-us/policies/privacy-policy.html">Privacy Policy</a>, which
                also includes additional details about the collection and use of information at Unaty.
            </div>
            <div className="ui header">
                What is a cookie?
            </div>
            <div>
                Cookies are small text files sent by us to your computer or mobile device. They are unique
                to your account or your browser. Session-based cookies last only while your browser is open
                and are automatically deleted when you close your browser. Persistent cookies last until you
                or your browser delete them or until they expire.
            </div>
            <div>
                Learn more about cookies on this <a href="http://www.allaboutcookies.org/">Website</a>.
            </div>
            <div className="ui header">
                Does Unaty use cookies?
            </div>
            <div>
                Yes. Unaty uses cookies and similar technologies like single-pixel gifs and web beacons. We
                use both session-based and persistent cookies. Unaty sets and accesses our own cookies on
                the domains operated by Unaty and its corporate affiliates (collectively, the "Sites"). In
                addition, we use third party cookies, like Google Analytics.
            </div>
            <div className="ui header">
                How is Unaty using cookies?
            </div>
            <div>
                Some cookies are associated with your account and personal information in order to remember
                that you are logged in and which Communities you are logged into. Other cookies are not tied
                to your account but are unique and allow us to carry out analytics and customization, among
                other similar things.
            </div>
            <div>
                Cookies can be used to recognize you when you visit a Site or use our Services, remember
                your preferences, and give you a personalized experience that's consistent with your
                settings. Cookies also make your interactions faster and more secure.
            </div>
            <div className="ui header">
                Categories of Use and Explanation
            </div>
            <div>
                <strong>Authentication</strong><br/>
                If you're signed in to our Services, cookies help us show you the right
                information and personalize your experience.
            </div>
            <div>
                <strong>Security</strong><br/>
                We use cookies to enable and support our security features, and to help us detect
                malicious activity.
            </div>
            <div>
                <strong>Preferences, features and services</strong><br/>
                Cookies can tell us which language you prefer and what
                your communications preferences are. They can help you fill out forms on our Sites more
                easily. They also provide you with features, insights, and customized content.
            </div>
            <div>
                <strong>Marketing</strong><br/>
                We may use cookies to help us deliver marketing campaigns and track their
                performance (e.g., a user visited our Help Center and then made a purchase). Similarly, our
                partners may use cookies to provide us with information about your interactions with their
                services, but use of those third-party cookies would be subject to the service provider's
                policies.
            </div>
            <div>
                We may use cookies to help us deliver marketing campaigns and track their performance (e.g.,
                a user visited our Help Center and then made a purchase). Similarly, our partners may use
                cookies to provide us with information about your interactions with their services, but use
                of those third-party cookies would be subject to the service provider's policies.
            </div>
            <div>
                <strong>Performance, Analytics and Research</strong><br/>
                Cookies help us learn how well our Sites and Services
                perform. We also use cookies to understand, improve, and research products, features, and
                services, including to create logs and record when you access our Sites and Services from
                different devices, such as your work computer or your mobile device.
            </div>
            <div  className="ui header">
                How are cookies used for advertising purposes?
            </div>
            <div>
                Cookies and other ad technology such as beacons, pixels, and tags help us market more
                effectively to users that we and our partners believe may be interested in Unaty. They also
                help provide us with aggregated auditing, research, and reporting, and know when content has
                been shown to you.
            </div>
            <div className="ui header">
                What can you do if you don't want cookies to be set or want them to be removed, or if you
                want to opt out of interest-based targeting?
            </div>
            <div>
                Some people prefer not to allow cookies, which is why most browsers give you the ability to
                manage cookies to suit you. In some browsers you can set up rules to manage cookies on a
                site-by-site basis, giving you more fine-grained control over your privacy. What this means
                is that you can disallow cookies from all sites except those that you trust.
            </div>
            <div>
                Browser manufacturers provide help pages relating to cookie management in their products.
            </div>
            <div>
                Please see below for more information.
            </div>
            <div>
                <ul>
                    <li><a href="https://support.google.com/chrome/answer/95647?hl=en-GB">Google Chrome</a></li>
                    <li><a href="https://support.microsoft.com/en-us/kb/260971">Internet Explorer</a></li>
                    <li><a href="https://support.mozilla.org/en-US/kb/cookies-information-websites-store-on-your-computer">Mozilla Firefox</a></li>
                    <li><a href="https://support.apple.com/kb/PH5042?locale=en_US">Safari (Desktop)</a></li>
                    <li><a href="https://support.apple.com/en-us/HT201265">Safari (Mobil)</a></li>
                    <li><a href="http://support.google.com/ics/nexus/bin/answer.py?hl=en&answer=2425067">Android Browser</a></li>
                    <li><a href="http://www.opera.com/help">Opera</a></li>
                    <li><a href="http://www.opera.com/help/mobile/android#privacy">Opera Mobile</a></li>
                </ul>
            </div>
            <div>
                For other browsers, please consult the documentation that your browser manufacturer
                provides.
            </div>
            <div>
                You may opt-out of third party cookies from Google Analytics on its website.
            </div>
            <div>
                You can opt out of interest-based targeting provided by participating ad servers through the
                Digital Advertising Alliance (<a href="http://youradchoices.com">http://youradchoices.com</a>). In addition, on your iPhone, iPad
                or Android, you can change your device settings to control whether you see online
                interest-based ads.
            </div>
            <div>
                If you limit the ability of websites and applications to set cookies, you may worsen your
                overall user experience and/or lose the ability to access the services, since it will no
                longer be personalized to you. It may also stop you from saving customized settings, like
                login information.
            </div>
            <div className="ui header">
                Does Unaty respond to Do Not Track Signals?
            </div>
            <div>
                Our Sites and Services do not collect personal information about your online activities over
                time and across third-party websites or online services. Therefore, "do not track" signals
                transmitted from web browsers do not apply to our Sites or Services, and we do not alter any
                of our data collection and use practices upon receipt of such a signal.
            </div>
        </div>;
}