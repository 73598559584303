import {FirebaseDB} from "../../config/setup-firestore";
import {merge} from 'lodash';

function getDates() {
    const d = new Date();
    const mm = d.getUTCMonth()+1;
    const dd = d.getUTCDate();
    const yy = d.getUTCFullYear();
    return {
        mm, dd, yy
    }
}

function getDatesTwoWeeksAgo() {
    const d = new Date(Date.now() - 12096e5);
    const mm = d.getUTCMonth()+1;
    const dd = d.getUTCDate();
    const yy = d.getUTCFullYear();
    return {
        mm, dd, yy
    }
}

const actions = {
        'create_group': {
            label: 'Created a group'
        },
    'add_member': {
            label: 'Added a member'
    },
    'export_members': {
        label: 'Exported members'
    },
    'add_member_to_group': {
        label: 'Added members to a group'
    },
    'sent_member_invite': {
        label: 'Sent a member invite'
    },
    'update_member_profile_as_admin': {
        label: 'Updated a member profile'
    },
    'create_group_mailing_list': {
        label: 'Created a group mailing list'
    },
    'send_group_email': {
        label: 'Sent a group email'
    },
    'sent_post': {
        label: 'Sent a post'
    },
};

function pad(num, size) {
    num = num.toString();
    while (num.length < size) num = "0" + num;
    return num;
}

export async function getSuperAdminMetrics() {
    let items = [];

    const {mm, dd, yy} = getDatesTwoWeeksAgo();

    const two_weeks_ago = new Date(`${yy}-${pad(mm,2)}-${pad(dd,2)}T12:00:00`);

    const data_entries = await FirebaseDB
        .collection('analytics')
        .where('date_sort', '>', two_weeks_ago)
        .get()
        .then(snap=>{
            return snap.docs.map(d=>{
                let o = {...d.data()};
                o.id = d.id;
                return o;
            })
        })

    let temp_active_user_uids = {};

    let temp = {};

    const action_entries = Object.entries(actions);

    data_entries.forEach(de=>{
        if(de.active_users) {
            temp_active_user_uids = merge(temp_active_user_uids,de.active_users);
        }
        action_entries.forEach(ent=>{
            const key = `${ent[0]}_users`;
            if(!temp[key]) {
                temp[key] = {};
            }
            if(de[key]) {
                temp[key] = merge(temp[key],de[key])
            }
        })
    });

    const active_user_uids = Object.keys(temp_active_user_uids);

    items.push({
        label: "Active users",
        desc: "",
        value: active_user_uids.length,
        keys: active_user_uids
    })

    action_entries.forEach(ent=>{
        const keys = Object.keys(temp[`${ent[0]}_users`])
        items.push({
            label: ent[1].label,
            desc: ent[1].desc,
            value: keys.length,
            keys: keys
        })
    })

    return items;
}