import React, {useEffect, useState} from "react";
import {LocalSearchBar} from "../local-search-bar";
import {Tabs} from "../../core";
import {member_types_suggestions} from "../../education/suggestions/member-types";
import {CheckIcon, PlusIcon} from "@heroicons/react/solid";

function SelectButton({selected,onSelect}) {
    const classes = selected ? `bg-success text-white border-success` : `bg-gray-100 border-gray-300 text-gray-400`;
    return <div onClick={onSelect} className={`h-6 w-6 border flex items-center justify-center rounded-md shadow hover:opacity-80 cursor-pointer  ${classes}`}>
        <div className={selected?"h-4 w-4":"h-5 w-5"}>
        {selected?<CheckIcon />:<PlusIcon/>}
        </div>
    </div>
}

function Suggestion({emoji='',title='Title',subtitle=[],onSelect=()=>{},selected=false}) {

    return <div className="bg-white py-2 px-3 space-x-2 flex rounded-md shadow-sm border">
        <div className="flex-none w-4">
            {emoji&&<div className="text-base w-4 h-5 flex items-center justify-center">
                {emoji}
            </div>}
        </div>
        <div className="flex-grow">
            <div className="text-sm font-bold text-gray-800 pb-0.5">{title}</div>
            <div className="flex space-x-2">
                {subtitle.map(st=><div key={st.text} className="text-xs text-gray-600 font-medium">{st.text}</div>)}
            </div>

        </div>
        <div className="flex-none">
            <SelectButton selected={selected} onSelect={onSelect} />
        </div>
    </div>
}

function filterTags(query, filter, arr) {
    if (filter === 'All'&&!query) {
        return arr.filter(a => !a.tag_only);
    } else if(filter==='All') {
        return arr.filter(a => {
            if(!a.tag_only) {
                return a.search.indexOf(query) !== -1
            }
        });
    }
    return arr.filter(a => {
        if (a.tags.includes(filter.toLowerCase())) {
            return a.search.indexOf(query) !== -1
        }
    })
}

export function SearchableSuggestions(props) {
    const {
        tabs = [],
        suggestions = []
    } = props;
    const [query, setQuery] = useState('');
    const [selected,setSelected] = useState(props.init_selected);
    const [filter, setFilter] = useState(tabs[0]);

    const filtered = filterTags(query, filter, suggestions).sort((a, b) => b.title > a.title ? -1 : 1);

    function handleSelect(it) {
        if(!selected.includes(it.title.toLowerCase())) {
            setSelected([...selected,it.title.toLowerCase()]);
            props.onSelect(it);
        }
    }

    return <div style={{maxWidth: '420px', maxHeight: '380px', height: '380px'}}
                className="p-3 flex space-y-2 flex-col rounded-md border border-gray-200 bg-gray-100">

        <div className="flex-none">
            <LocalSearchBar onChange={(q)=>setQuery(q.toLowerCase())} />
        </div>
        <div className="flex-none overflow-x-auto">
            <Tabs active={filter} onSelect={(t) => setFilter(t)} tabs={tabs} layout="pills-solid"/>
        </div>
        <div className="flex-grow flex flex-col space-y-2 overflow-y-auto">
            {query&&filtered.length===0&&<div className="p-4 text-gray-500 text-sm text-center">
                No results
            </div>}
            {filtered.map(fi=><Suggestion onSelect={()=>handleSelect(fi)} selected={selected.includes(fi.title.toLowerCase())} key={`${fi.title}${fi.tags.join(",")}`} {...fi} />)}
        </div>
    </div>
}