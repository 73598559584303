import {analytics} from "firebase";
import {logEngagement} from "../../../api/analytics";
import {authFetch} from "../../../config/network";

export function composer_handleSendInvite(payload, {community, global}, res) {
    global.addToast({text: 'Invites are being sent', intent: 'info'});
    analytics().logEvent('send_invite', {
        event_id: 'send_invite',
        content_type: 'email',
        community_uid: community.uid,
        content_id: community.member.user_uid
    });

    logEngagement("send_invite", {
        community_uid: community.uid,
        member_id: community.member_id,
        user_uid: community.member.user_uid
    });

    const final_payload = {
        ...payload,
        user_uid: community.member.user_uid,
        member_id: community.member_id,
        community_uid: community.uid,
        raw: payload.raw,
        settings: payload.settings,
        subject: payload.subject,
        ids: payload.to_data.map(a => {
            let o = {};
            o.id = a.id ? a.id : a.value;
            return o;
        }),
        flow: 'personalized',
    }

  authFetch("/members/bulk/invite", res, res, "POST", {payload: final_payload});
}