import React, {useState,useContext,useEffect} from 'react';
import styled from "styled-components";
import {FirebaseDB} from "../../../config/setup-firestore";
import {authFetch} from "../../../config/network";
import {GlobalContext} from "../../global/global-context";
import {Link} from "react-router-dom";

const Frame = styled.div`
      max-width: 960px;
    margin: 0 auto;
    padding: 0 24px;
`;


const Title = styled.div`

`;

const error_reasons = {
    'token-not-found': 'Token not found',
    'token-expired': 'Token has expired',
    'no-token': 'No Token defined',
};

function getToken() {
    const urlParams = new URLSearchParams(window.location.search);
    const action = urlParams.get('token');
    return action;
}

export default function VerifySecondaryEmail(props) {
    const global = useContext(GlobalContext);
    const [token,setToken] = useState(getToken());
    const [loading,setLoading] = useState('token');
    const [token_data,setTokenData] = useState(null);
    const [error,setError] = useState(null);

    function redirect() {
        setTimeout(() => {
            props.history.push('/')
        }, 3000);
    }

    async function verifyToken(t,td) {
        setLoading('verifying');
        const res = () => {
            global.addToast({text:'Email Verified',intent:'success'});
            setLoading('');
            redirect();
        };

        const payload = {
            email: td.email, token: t
        };
        authFetch("/users/verify-secondary-email", res, res, "POST", {payload});
    }

    async function getTokenData(t) {
        return FirebaseDB
            .collection('secondary_email_tokens')
            .doc(t)
            .get()
            .then(doc=>{
                if(doc.exists) {
                    return doc.data();
                } else {
                    return null;
                }
            })
    }

    useEffect(function() {
        // get token
        // todo should later get from server
        if(!token_data&&loading==='token'&&token) {
            getTokenData(token)
                .then(res=>{
                    if(res) {
                        setTokenData(res);
                        verifyToken(token,res)
                            .then(()=>{

                            })
                    } else {
                        setLoading('');
                        setError('token-not-found');
                        redirect();
                    }
                })
        } else if(!token) {
            setLoading('');
            setError('no-token');
            redirect();
        }
    },[loading,token_data]);

    if(error) {
        return <Frame>
            <Title>
                {error_reasons[error]}
            </Title>
        </Frame>
    }

    if(loading==='token') {
        return <Frame>
            Loading..
        </Frame>
    }

    if(loading==='verifying') {
        return <Frame>
            Loading..
        </Frame>
    }

    if(!token_data||!token_data.email) {
        return <Frame>
            <Title>
                Something went wrong, <Link to="/">back to home</Link>.
            </Title>
        </Frame>
    }

    return <Frame>
        <Title>
Awesome, you can now login and send to mailing lists with {token_data.email}. You'll be redirected shortly to the home page.
        </Title>
    </Frame>
}