import {
    AtSymbolIcon,
    GlobeIcon,
    IdentificationIcon,
    KeyIcon,
    LocationMarkerIcon,
    PlusIcon,
    UserCircleIcon
} from "@heroicons/react/solid";
import React from "react";
export const member_profile_data_fields = {
    'account_email': {
        label: 'Account Email',
        type: 'email',
        categories: ['key','account']
    },
    'docid': {
      label: 'Member ID',
      type: 'id',
      categories: ['key','account']
    },
    'full_name': {
        label: 'Full Name',
        type: 'full-name',
        categories: ['basic']
    },
    'about.first_name': {
        label: 'First Name',
        type: 'string',
        categories: ['key','account','basic']
    },
    'about.last_name': {
        label: 'Last Name',
        type: 'string',
        categories: ['account','basic']
    },
    'member_type': {
        label: 'Member Type',
        type: 'select',
        categories: ['account']
    },
    'about.middle_name': {
        label: 'Middle Name',
        type: 'string',
        categories: ['basic']
    },
    'about.title': {
        label: 'Title',
        type: 'string',
        categories: ['basic']
    },
    'about.bio': {
        label: 'Bio',
        type: 'string',
        categories: ['basic']
    },
    'birthday': {
        label: 'Birthday',
        type: 'birthday',
        categories: ['basic']
    },

    'contact.email': {
        label: 'Email',
        type: 'email',
        categories: ['contact']
    },
    'contact.phone': {
        label: 'Phone',
        type: 'phone',
        categories: ['contact']
    },
    'contact.secondary_phone': {
        label: 'Secondary Phone',
        type: 'phone',
        categories: ['contact']
    },

    'address.line_one': {
        label: 'Line One',
        type: 'string',
        categories: ['address']
    },
    'address.line_two': {
        label: 'Line Two',
        type: 'string',
        categories: ['address']
    },
    'secondary_emails': {
        label: 'Secondary Emails',
        type: 'multi-email',
        categories: ['account']
    },
    'address.zip_code': {
        label: 'ZIP Code',
        type: 'string',
        categories: ['address']
    },
    'address.city': {
        label: 'City',
        type: 'string',
        categories: ['address']
    },
    'address.state': {
        label: 'State',
        type: 'string',
        categories: ['address']
    },
    'address.country': {
        label: 'Country',
        type: 'country',
        categories: ['address']
    },

    'social.facebook': {
        label: 'Facebook',
        type: 'url',
        categories: ['social']
    },
    'social.website': {
        label: 'Website',
        type: 'url',
        categories: ['social']
    },
    'social.linkedin': {
        label: 'LinkedIn',
        type: 'url',
        categories: ['social']
    },
    'social.instagram': {
        label: 'Instagram',
        type: 'url',
        categories: ['social']
    },
    'social.twitter': {
        label: 'Twitter',
        type: 'url',
        categories: ['social']
    }
}

export const member_profile_data_fields_categories = {
  'account': {
      label: 'Account',
      icon: <UserCircleIcon />
  },
    'basic': {
        label: 'Basic',
        icon: <IdentificationIcon />
    },
    'contact': {
        label: 'Contact',
      icon: <AtSymbolIcon />
    },

    'address': {
        label: 'Address',
        icon: <LocationMarkerIcon />
    },
    'social': {
        label: 'Social',
      icon: <GlobeIcon />
    },
    'custom': {
        label: 'Custom',
      icon: <PlusIcon />
    }
};