import React from 'react';
import {EntityInfo} from "../../../utilities/localstorage/entity-localstorage";
import {getPrettyTime} from "../../../utilities/general/dates";
import moment from "moment";
import {UserAddIcon} from "@heroicons/react/outline";
import {getPhoneNumberData} from "../../../utilities/phone";
import {FeedItemPostContainer} from "../feed-item";
import {Avatar} from "../../core";
import {countries} from "../../../data/general/countries";

function MemberName({data}) {
    return <span className="font-medium text-gray-800">
        {data.name}
    </span>
}

// <EntityInfo community_uid={community.uid} id={group.created_by} type="member"><MemberName /></EntityInfo>
export function MATupdatedPhone(props) {
    const {group,community,activity_meta,author_id,context_id,metadata,is_moderator,_actions={},is_member,is_admin} = props;
    const pn = getPhoneNumberData(metadata.number);
    return <FeedItemPostContainer {...props} author_id={context_id}>
        <div style={{fontSize:'14px',lineHeight:1.4}} className="text-gray-700 pt-px line-clamp-3">
            new primary phone number: <strong>{pn.display}</strong>
        </div>
    </FeedItemPostContainer>
}

export function MATupdatedEmail(props) {
    const {group,community,activity_meta,author_id,context_id,metadata,is_moderator,_actions={},is_member,is_admin} = props;

    return <FeedItemPostContainer {...props} author_id={context_id}>
        <div style={{fontSize:'14px',lineHeight:1.4}} className="text-gray-700 pt-px line-clamp-3">
            new primary email: <strong>{metadata.email}</strong>
        </div>
    </FeedItemPostContainer>
}

export function MATupdatedMemberType(props) {
    const {group,community,activity_meta,author_id,context_id,metadata,is_moderator,_actions={},is_member,is_admin} = props;

    return <FeedItemPostContainer {...props} author_id={context_id}>
        <div style={{fontSize:'14px',lineHeight:1.4}} className="text-gray-700 pt-px line-clamp-3">
            has a new member type: <strong>{metadata.info.singular}</strong>
        </div>
    </FeedItemPostContainer>
}

export function MATupdatedCity(props) {
    const {group,community,activity_meta,author_id,context_id,metadata,is_moderator,_actions={},is_member,is_admin} = props;

    return <FeedItemPostContainer {...props} author_id={context_id}>
        <div style={{fontSize:'14px',lineHeight:1.4}} className="text-gray-700 pt-px line-clamp-3">
            moved to {metadata.address.city}
        </div>
    </FeedItemPostContainer>
}

export function MATupdatedCountry(props) {
    const {group,community,activity_meta,author_id,context_id,metadata,is_moderator,_actions={},is_member,is_admin} = props;
if(!metadata.address.country || !metadata.address.city) return null;
    return <FeedItemPostContainer {...props} author_id={context_id}>
        <div style={{fontSize:'14px',lineHeight:1.4}} className="text-gray-700 pt-px line-clamp-3">
            moved to {metadata.address.city}, {countries[metadata.address.country]}
        </div>
    </FeedItemPostContainer>
}

export function MATfirstLogin(props) {
    const {group,community,activity_meta,author_id,context_id,metadata,is_moderator,_actions={},is_member,is_admin} = props;

    return <FeedItemPostContainer {...props} author_id={context_id}>
        <div style={{fontSize:'14px',lineHeight:1.4}} className="text-gray-700 pt-px line-clamp-3">
            logged in for the first time
        </div>
    </FeedItemPostContainer>
}

export function MATdeactivated(props) {
    const {group,community,activity_meta,author_id,context_id,metadata,is_moderator,_actions={},is_member,is_admin} = props;
return null;
    return <FeedItemPostContainer {...props} author_id={context_id}>
        <div style={{fontSize:'14px',lineHeight:1.4}} className="text-gray-700 pt-px line-clamp-3">
            account was deactivated
        </div>
    </FeedItemPostContainer>
}

export function MATreactivated(props) {
    const {group,community,activity_meta,author_id,context_id,metadata,is_moderator,_actions={},is_member,is_admin} = props;

    return <FeedItemPostContainer {...props} author_id={context_id}>
        <div style={{fontSize:'14px',lineHeight:1.4}} className="text-gray-700 pt-px line-clamp-3">
            account was reactivated
        </div>
    </FeedItemPostContainer>
}

export function MATsentInvite(props) {
    const {group,community,activity_meta,author_id,context_id,metadata,is_moderator,_actions={},is_member,is_admin} = props;

    return <FeedItemPostContainer {...props} author_id={context_id}>
        <div style={{fontSize:'14px',lineHeight:1.4}} className="text-gray-700 pt-px line-clamp-3">
            was sent an invite
        </div>
    </FeedItemPostContainer>
}

export function MATassignedToRole(props) {
    const {group,community,activity_meta,author_id,context_id,metadata,is_moderator,_actions={},is_member,is_admin} = props;

    return <FeedItemPostContainer {...props} author_id={context_id}>
        <div style={{fontSize:'14px',lineHeight:1.4}} className="text-gray-700 pt-px line-clamp-3">
            was assigned to the role: <strong>--</strong>
        </div>
    </FeedItemPostContainer>
}

export function MATbirthday(props) {
    const {group,community,activity_meta,author_id,context_id,metadata,is_moderator,_actions={},is_member,is_admin} = props;

    return <FeedItemPostContainer {...props} author_id={context_id}>
        <div style={{fontSize:'14px',lineHeight:1.4}} className="text-gray-700 pt-px line-clamp-3">
            birthday was today
        </div>
    </FeedItemPostContainer>
}

export function MATjoinedGroup(props) {
    const {group,community,activity_meta,author_id,context_id,metadata,is_moderator,_actions={},is_member,is_admin} = props;

    return <FeedItemPostContainer {...props} author_id={context_id}>
        <div style={{fontSize:'14px',lineHeight:1.4}} className="text-gray-700 pt-px line-clamp-3">
            joined <strong><EntityInfo community_uid={activity_meta.community.uid} id={metadata.group_id} type={'groups'}>
            <MemberName />
        </EntityInfo>
        </strong>
        </div>
    </FeedItemPostContainer>
}

export function MATunassignedFromRole(props) {
    const {group,community,activity_meta,author_id,context_id,metadata,is_moderator,_actions={},is_member,is_admin} = props;

    return <FeedItemPostContainer {...props} author_id={context_id}>
        <div style={{fontSize:'14px',lineHeight:1.4}} className="text-gray-700 pt-px line-clamp-3">
            is not longer assigned to the role: <strong>--</strong>
        </div>
    </FeedItemPostContainer>
}

export function MATupdatedPicture(props) {
    const {group,community,activity_meta,author_id,context_id,metadata,is_moderator,_actions={},is_member,is_admin} = props;
const {profile_picture} = metadata;
    return <FeedItemPostContainer {...props} author_id={context_id}>
        <div style={{fontSize:'14px',lineHeight:1.4}} className="text-gray-700 pt-px line-clamp-3">
           set a new profile picture
        </div>
        <div className="pt-3">
            <div className="w-full">
                <Avatar type="standard" size="7xl" url={`${profile_picture}?alt=media`} />
            </div>
        </div>
    </FeedItemPostContainer>
}