import React, {useContext, useEffect, useRef, useState} from 'react';
import {CommunityContext} from "../../community-context";
import {LocalSearchBar} from "../../../../library/components/custom/local-search-bar";
import {ActionWithIcon, Avatar, InfoState, InlineLoader} from "../../../../library/components/core";
import {RefreshIcon, UserAddIcon, XIcon} from "@heroicons/react/outline";
import {getGroupMembers} from "./api";
import useInfiniteScroll from "../../../../library/playground/advanced-table/use-infinite-scroll";
import {DoubleCheckModal} from "../../../../library/components/old/double-check-modal";
import ModeratorIcon from "../../../../library/components/old/moderator-icon";
import {MODERATOR_COLOR} from "../../../../config/defaults";
import {GlobalContext} from "../../../global/global-context";
import {useHistory} from "react-router-dom";
import {validateEmail} from "../../../../library/utilities/validation";
import {authFetch} from "../../../../config/network";
import {ChevronDownIcon, ChevronLeftIcon} from "@heroicons/react/solid";
import {NewTooltip} from "../../../../library/components/reorganize/new-tooltip";
import styled from "styled-components";
import {ConvertToIcon} from "../../../../library/playground/convert-to-icon";
import {FirebaseDB} from "../../../../config/setup-firestore";
import {singleMembersQuery} from "../../../../library/components/mobile-directory/load-members";

function EntryContainer({children, onClick}) {
    return <div onClick={() => {
        if (onClick) {
            onClick();
        }
    }}
                className={`grid gap-2.5 hover:bg-gray-100 transition-colors -m-1 p-1 rounded-xl ${onClick ? "cursor-pointer" : ""}`}
                style={{gridTemplateColumns: '2.5rem 1fr auto'}}>
        {children}
    </div>
}

const Action = styled.div`
  outline: none;

  > svg {
    height: 16px;
  }
`;

function getActContent(act, member_id, _fns) {
    if (act === 'remove') {
        return <NewTooltip usePortal={false} message={"Remove"}>
            <Action onClick={() => _fns.removeMember(member_id)}
                    className='h-6 w-6 text-gray-500 flex items-center justify-center rounded-md hover:bg-gray-200 cursor-pointer transition-colors'>
                <XIcon/>
            </Action>
        </NewTooltip>
    } else if (act === 'make-mod') {
        return <NewTooltip usePortal={false} message={"Make Moderator"}>
            <Action onClick={() => _fns.makeModerator(member_id)}
                    className='h-6 w-6 bg-white text-gray-500 flex items-center justify-center rounded-md hover:bg-gray-200 cursor-pointer transition-colors'>
                <ConvertToIcon type="moderator"/>
            </Action>
        </NewTooltip>
    } else if (act === 'is-mod') {
        return <ActionWithIcon key="mod-erator" text="Moderator" disabled/>
    } else if (act === 'mod-remove') {
        return <ActionWithIcon key="mod-remove" onClick={() => _fns.removeModerator(member_id)}
                               text="Remove"/>
    } else if (act === 'mod-rule') {
        return <NewTooltip usePortal={false} message={"Added by Rule"}>
            <Action onClick={() => {
            }}
                    className='h-6 w-6 text-gray-400 flex items-center justify-center rounded-md transition-colors'>
                <RefreshIcon/>
            </Action>
        </NewTooltip>
    } else if (act === 'add') {
        return <ActionWithIcon key="add" inverse onClick={() => _fns.addMember(member_id)} text="Add"/>
    } else if (act === 'rule') {
        return <NewTooltip usePortal={false} message={"Added by Rule"}>
            <Action onClick={() => {
            }}
                    className='h-6 w-6 text-gray-400 flex items-center justify-center rounded-md transition-colors'>
                <RefreshIcon/>
            </Action>
        </NewTooltip>
    } else if (act === 'you') {
        return <span key="you" className="text-xs text-gray-600">(you)</span>
    } else if (act === 'archived') {
        return <ActionWithIcon key="archived" text="Deactivated" disabled/>
    } else {
        return null;
    }
}

export function MemberListEntry({
                                    loading,
                                    added,
                                    label,
                                    label_suffix,
                                    description,
                                    index,
                                    handle,
                                    image,
                                    id,
                                    member_id,
                                    fallback_image,
                                    _fns = {},
                                    actions = []
                                }) {

    const name_clickable = !!_fns.onClickName;
    return <EntryContainer>
        <div className="h-10">
            <Avatar url={`${image ? image : fallback_image}_medium?alt=media`} size="md"/>
        </div>
        <div>
            <div onClick={() => {
                if (_fns.onClickName) {
                    _fns.onClickName(handle);
                }
            }} className={`${name_clickable ? "cursor-pointer hover:underline" : ""} flex items-center space-x-1`}>
                <div className="font-semibold pt-px text-gray-800 text-smbase truncate">{label}</div>
                {label_suffix}
            </div>
            <div className="text-sm line-clamp-2 text-gray-500">{description}</div>
        </div>
        <div className="pr-2 flex items-center space-x-1.5">
            {!loading && actions.map(act => {
                return <div key={act}>
                    {getActContent(act, member_id, _fns)}
                </div>
            })}
            {loading && <div>
                <InlineLoader mini padding="p-0"/>
            </div>}

        </div>
    </EntryContainer>
}

function AddMembers({onClick}) {

    return <EntryContainer onClick={() => onClick()}>
        <div>
            <div className={"h-10 w-10 h-svg-5 text-selection rounded-42 bg-blue-100 flex items-center justify-center"}>
                <UserAddIcon/>
            </div>
        </div>
        <div className="flex items-center">
            <div className="font-semibold pt-px text-gray-800 text-smbase truncate">Add Members</div>
        </div>
        <div className="pr-2 flex items-center">

        </div>
    </EntryContainer>
}

function Subtitle({text = "Not in Group"}) {
    return <div>
        <div className="text-xs font-semibold text-gray-500">{text}</div>
    </div>
}

export function MembersListContainer({children}) {
    return <div className="space-y-1">
        {children}
    </div>
}

const per_page = 25;

function memberAddedByRule(id, group) {
    const is_member = group.user_uids[id];

    if (!is_member) {
        return false;
    }

    return !group.entities.members[id];
}

function ModeratorTag() {
    return <span style={{height: "16px"}}>
        <ModeratorIcon height={16} fill={MODERATOR_COLOR}/>
    </span>
}

export function buildMemberEntryProps(mm, group, community, view = 'members', type = 'group', added) {
    const member_id = mm.id.split('-')[1];

    let added_by_rule = false, is_member = false, is_moderator = false;

    if (type === 'group') {
        added_by_rule = memberAddedByRule(member_id, group);

        is_member = group.user_uids[member_id] || added;

        is_moderator = group.moderators.user_uids[member_id];
    }

    const is_me = member_id === community.member_id;

    let actions = [];

    if (type === 'interest') {
        //
        if (is_me) {
            actions.push('you')
        }
    } else if (view === 'members') {
        if (!is_moderator) {
            actions.push('make-mod');
        }

        if (!added_by_rule && !is_me && is_member && !is_moderator) {
            actions.push('remove')
        } else if (!added_by_rule && !is_me && is_member && is_moderator) {
            actions.push('is-mod')
        }

        if (added_by_rule && !is_me) {
            actions.push('rule')
        }

        if (is_me) {
            actions.push('you')
        }

        if (mm.account_status === 'archived') {
            actions.push('archived')
        } else if (!is_member) {
            actions.push('add')
        }
    } else {

        if (is_me) {
            actions.push('you')
        }
        /*
             if(!group.moderators.members[member_id]&&!is_me) {
                 actions.push('mod-rule')
             }

             if(group.moderators.members[member_id]&&!is_me) {
                 actions.push('mod-remove')
             }
         */
    }

    return {
        id: member_id,
        member_id,
        label: mm.name,
        label_suffix: is_moderator ? <ModeratorTag/> : null,
        description: community.member_types[mm.member_type].singular,
        image: mm.profile_picture,
        fallback_image: community.default_member_profile_picture,
        is_me,
        added_by_rule,
        is_moderator,
        actions
    }
}

// both ordered properly, a1 comes before a2.
const mergeArrays = (a1, a2) => {
    let arr1 = [];

    a1.forEach(it => {
        const arr2_index = a2.findIndex(a => a.id === it.id);
        if (arr2_index !== -1) {
            arr1.push(a2[arr2_index]);
        } else {
            arr1.push(it);
        }
    });

    a2.forEach(it => {
        const arr1_index = arr1.findIndex(a => a.id === it.id);
        if (arr1_index === -1) {
            arr1.push(it);
        }
    })

    return arr1;
};

function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

function getInitialMemberCreate(query) {
    let first_name = "";
    let last_name = "";
    let account_email = "";

    let q2 = query.trim();

    if (validateEmail(q2)) {
        account_email = q2.trim().toLowerCase();
    } else {
        if (q2.indexOf(' ') !== -1) {
            first_name = capitalizeFirstLetter(q2.substring(0, q2.lastIndexOf(" ") + 1)).trim();
            last_name = capitalizeFirstLetter(q2.substring(q2.lastIndexOf(" ") + 1, q2.length)).trim();
        } else {
            first_name = capitalizeFirstLetter(q2).trim();
        }
    }

    return {
        first_name,
        last_name,
        account_email
    }
}

function getNewExtras(extra, type, ids) {
    let ex = {...extra};

    let fids = typeof ids === 'string' ? [ids] : ids;

    fids.forEach(id => {
        if (ex[type].includes(id)) {
            //
        } else {
            ex[type].push(id);
        }

        if (type === 'removed') {
            if (ex['added']) {
                ex['added'].splice(ex['added'].indexOf(id), 1);
            }
        } else {
            if (ex['removed']) {
                ex['removed'].splice(ex['removed'].indexOf(id), 1);
            }
        }

    })

    return ex;
}

export function GMMMembers({
                               type = 'group',
                               addEntities = () => {
                               },
                               removeEntity = () => {
                               },
                               handleMakeMemberModerator = () => {
                               },
                               openAddMembers = () => {
                               },
                               can_manage,
                               data,
                               id
                           }) {
    const global = useContext(GlobalContext);
    const community = useContext(CommunityContext);
    const history = useHistory();
    const [cached,setCached] = useState({});
    const [query, setQuery] = useState("");
    const [term, setTerm] = useState("");
    const [has_more, setHasMore] = useState(true);
    const page_val = useRef(null);
    const [loading, setLoading] = useState(true);
    const [members, setMembers] = useState([]);
    const [o_members, setOMembers] = useState([]);
    const [extra, setExtra] = useState({
        removed: [],
        added: [],
        loading: []
    })
    const [non_members, setNonMembers] = useState([]);

    const [modal, setModal] = useState(null);
    const [modal_data, setModalData] = useState(null);

    const scroll_id = "member-list";

    useEffect(function () {
        setExtra({
            removed: [],
            added: [],
            loading: []
        })
        refresh();
    }, [data.user_uids]);

    useEffect(function () {
        setLoading(true);
        setMembers([]);
        setOMembers([]);
    }, [query]);

    const [isFetching, setIsFetching] = useInfiniteScroll("infinite", scroll_id, {
        padding: 240,
        container: true
    }, () => {
        if (!loading && has_more) {
            handleLoadMore();
        }
    });

    useEffect(function () {
        //  const current_page = Math.floor(members.length / 10);
        page_val.current = null;
        setLoading(true);
        setMembers([]);
        setHasMore(true);
        getMembers(term);
    }, [term])

    function handleLoadMore() {

        getMembers(term)
    }

    function getMembers(q, refresh_token) {
        const page = page_val.current && page_val.current.current ? page_val.current.current : 0;
        if (!has_more && page_val.current && !refresh_token) {
            return;
        }
        setLoading(true);

        if(cached[q]) {
            setMembers(cached[q]);
        }

        // if group has less than 250 members, just get them all
        const count = Object.keys(data.user_uids).length;
        if(!q&&count<300) {
            singleMembersQuery(community.uid, 300, id, null)
                .then(snap=>{
                    const final = snap.docs.map(d=>{
                        return {
                            ...d.data(),
                            id:`${community.uid}-${d.id}`
                        }
                    }).sort((a, b) => (a.about.last_name > b.about.last_name) ? 1 : -1);
                    setMembers(final);
                    setLoading(false);
                    page_val.current = 1;
                    setHasMore(false);
                    setIsFetching(false);
                    setCached({...cached,[q]:final})
                })
        } else {
            getGroupMembers(community.uid, id, q, page + (refresh_token ? 0 : 1), {size: q ? 4 : per_page, type})
                .then(resp => {
                    if (resp.results) {
                        let final;
                        if (resp.page.current === 1) {
                            final = [...resp.results];
                            setMembers(final);
                        } else {
                            final = mergeArrays(members, resp.results);
                            setMembers(final);
                        }
                        setLoading(false);
                        page_val.current = resp.page;
                        setHasMore(resp.page.current < resp.page.total_pages);
                        setIsFetching(false);
                        setCached({...cached,[q]:final})
                    }
                })
        }

        if (can_manage && q && can_manage) {
            getOMembers(q)
        }
    }

    function getOMembers(q) {
        getGroupMembers(community.uid, id, q, 1, {size: 4, not_in_group: true})
            .then(resp => {
                if (resp.results) {
                    setOMembers([...resp.results]);
                }
            })
    }

    function handleSearchSubmit(t) {
        setTerm(t);
    }

    function handleClearResults() {
        setTerm("");
        setQuery("");
    }

    function updateExtra(type = 'removed', ids) {
        let ex = getNewExtras(extra, type, ids);

        console.log("new extras", ex)
        setExtra({...ex});
    }

    function startLoadingMember(mid) {
        let ex = {...extra};
        ex.loading.push(mid);
        setExtra(ex);
    }

    function confirmRemove() {
        startLoadingMember(modal_data.id);
        removeEntity('members', modal_data.id, () => {
            updateExtra('removed', modal_data.id);
            refresh();
        });

        setModal(null);
        setModalData(null);

        global.addToast({
            intent: 'info',
            text: 'Removing member from group'
        });
    }

    function refresh() {
        setTimeout(function () {
            setLoading(true);
            setMembers([]);
            page_val.current = null;
            setExtra({
                loading: [],
                added: [],
                removed: []
            })
            setHasMore(true);
            getMembers(term, true);
        }, 200)
    }

    const _fns = {
        onClickName: (handle) => {
            history.push(`/${community.data.handle}/member/${handle}`);
        },
        makeModerator: (id) => {
            handleMakeMemberModerator(id);
        },
        removeMember: (id) => {
            setModalData({
                type: "member_remove_from_group",
                id
            });
            setModal('double-check');
        },
        addMember: (id) => {
            startLoadingMember(id);
            addEntities({members: {[id]: true}}, () => {
                refresh();
            })
        },
    };

    function addToGroup(member_ids, lcb) {
        if (!member_ids || member_ids.length === 0) {
            return;
        }

        const obj = {};

        member_ids.forEach(id => {
            obj[id] = true;
        })

        const payload = {
            entities: {
                members: obj
            },
            id: id,
            member_id: community.member_id,
            community_uid: community.uid
        };

        const res = () => {
            if (lcb) {
                lcb();
            }
            global.addToast({text: 'Members added to group', intent: 'success'});
        };

        authFetch("/groups/add-new-entities", res, res, "POST", {payload});
    }

    const total_results = page_val.current && page_val.current.total_results || 0;

    const custom_action = <div className="flex justify-center">
        <ActionWithIcon onClick={() => {
            global.handleSetRightMenu('create-members', {
                onConfirmed: (ids) => {
                    if (ids.length > 0) {
                        addToGroup(ids, () => {

                        });
                        updateExtra('added', ids)
                    }
                    global.handleCloseRightMenu();
                    setQuery('');
                },
                flow: 'add-to-group',
                initial: [
                    getInitialMemberCreate(query)
                ]
            })
        }} inverse text={`Create a new Member "${query}"`}/>
    </div>

    return <div id="member-list"
                className="pt-3 px-4 pb-3 space-y-3 flex flex-col overflow-y-auto adaptive-modal-height">
        <div className="flex-none">
            <LocalSearchBar query={query} onChange={v => setQuery(v)} onSearchSubmit={handleSearchSubmit}
                            clearResults={handleClearResults}
                            async/>
        </div>

        <div className="flex-grow space-y-3 pb-20">
            <MembersListContainer>
                {term && !loading && can_manage && members.length > 0 && <Subtitle text="In Group"/>}
                {!query && !loading && can_manage && <AddMembers onClick={() => openAddMembers(() => {
                    refresh();
                })}/>}
                {(!query || (term && !loading)) && members.map((mm, k) => {
                    const mid = mm.id.split('-')[1];
                    if (extra.removed.includes(mid)) {
                        return null;
                    }
                    return <MemberListEntry index={k} handle={mm.handle} member_id={mid} _fns={_fns}
                                            loading={extra.loading.includes(mid)}
                                            key={mid} {...buildMemberEntryProps(mm, data, community, "members", type, extra.added.includes(mid))} />
                })}
                {!loading && members.length === 0 &&
                <InfoState subtitle="No members found" button={custom_action} fill/>}
                {loading && <div>
                    <InlineLoader/>
                </div>}
            </MembersListContainer>
            {!loading && can_manage && term && o_members.length > 0 && <MembersListContainer>
                <Subtitle text="Not in Group"/>
                {o_members.map((mm, k) => {
                    const mid = mm.id.split('-')[1];
                    return <MemberListEntry index={k} handle={mm.handle} member_id={mid}
                                            loading={extra.loading.includes(mid)} _fns={_fns}
                                            key={mid} {...buildMemberEntryProps(mm, data, community, "members", type)} />
                })}
            </MembersListContainer>}
        </div>


        {modal === 'double-check' && <DoubleCheckModal onConfirm={() => confirmRemove()} onClose={() => {
            setModal(null);
            setModalData(null)
        }} onCancel={() => {
            setModal(null);
            setModalData(null)
        }} type={modal_data.type}/>}
    </div>
}