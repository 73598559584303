import React from 'react';

export function NFIOuter(props) {
    return <div className={`px-4 ${props.className||""}`}>
        {props.children}
    </div>
}

export function NFIInner(props) {
    return <div className={`${props.className||""}`}>
        {props.children}
    </div>
}

export function NFIGrowGrid(props) {
    return <div className={`grid gap-2.5 ${props.className||""}`} style={{gridTemplateColumns:'40px minmax(200px,1fr) auto'}}>
        {props.children}
    </div>
}

export function NFIReadMore({setShowMore}) {

    return <div>
        <span onClick={(e)=>{
            if(e) {
                e.stopPropagation();
                e.preventDefault();
            }
            setShowMore()
        }} className="cursor-pointer py-0.5 text-sm font-medium text-gray-600 hover:text-selection">Read more</span>
    </div>
}