import React, {useState,useContext} from 'react';
import Modal from "../../../../library/components/old/modal";
import {Button} from "../../../../library/components/core";
import {GlobalContext} from "../../../global/global-context";
import HandleSelector from "../../../../library/components/old/handle-selector";


export function ChangeHandleModal({onAdd,onClose,message,type='role',loading=false,data={},community_uid='',onCancel,onConfirm}) {
    const global = useContext(GlobalContext);
    const [new_handle,setNewHandle] = useState('');
    const [_checking_handle,setCheckingHandle] = useState(false);

    const scope = community_uid === '' ? 'global' : 'community';
    const scope_id = community_uid === '' ? '' : community_uid;

    const body = <div>
        <div className="text-gray-600 text-sm pb-4">
            <span>Current handle:</span> <span className="font-semibold">@{data.handle}</span>
        </div>
        <div>
            <HandleSelector checkingHandle={() => setCheckingHandle(true)}
                            default_text={""} change_mode
                            setHandle={(h) => {
                                setNewHandle(h);
                                setCheckingHandle(false);
                            }} type={type} autoFocus
                            scope={scope} scope_id={scope_id}/>
        </div>
        {message&&<div className="pt-2">
            {message}
        </div>}
    </div>;

    const should_disable = new_handle==='';

    return <Modal title={"Change Handle"} size='small' body={body} footerRight={<div>
        <Button intent={should_disable?'secondary':`success`} loading={loading} onClick={()=>{
            if(new_handle) {
                onConfirm(new_handle);
            }
        }} text={should_disable?"Check":`Change`} />
    </div>} footerLeft={null}
                  onClose={onClose} open={true} lazy />
}