import React, {useContext} from "react";
import {SettingsGrid} from "../../../../library/components/old/settings-grid";
import {InformationCircleIcon} from '@heroicons/react/solid';
import {PageContainer} from "../../../../library/components/old/page-container";
import {setDocumentTitle} from "../../../../library/utilities/set-document-title";

import {CommunityContext} from "../../community-context";
import {useIntl} from "react-intl";
import {EntityHeader, PageHeader} from "../../../../library/components/core";
import {StandardPageFramework} from "../../../../library/playground/standard-page-framework";

const items = [
    {
        title: 'account',
        icon: <InformationCircleIcon/>,
        links: {
            about: {
                text: 'general',
                desc: '',
                path: 'account'
            },
            branding: {
                text: 'notifications',
                desc: '',
                path: 'notifications'
            },
        time_date: {
            text: 'time_date',
            desc: '',
            path: 'time-date'
        }
        }
    }
];

export default function MemberPrefsOverview(props) {
    const community = useContext(CommunityContext);
    const {formatMessage: f} = useIntl();

    setDocumentTitle('Preferences', community.data.name);

    return <StandardPageFramework header={<EntityHeader onClick={() => {
        const el = document.getElementById("main-content");
        el.scrollTo(0, 0);
    }} title={f({id:'preferences.title'})} actions={null} />}>
        <SettingsGrid base_link={`/${community.data.handle}/preferences`} items={items}/>
    </StandardPageFramework>;
}