import React from 'react';
import styled from "styled-components";

import './aif.scss';

import moment from "moment";
import {parsePhoneNumberFromString} from 'libphonenumber-js/min';
import {languages_object} from "../../../data/general/languages";
import {countries} from "../../../data/general/countries";
import {validateEmail} from "../../../utilities/validation";
import ManageDisplayName from "./manage-display-name";
import {ProfilePicture} from "../profile-picture";
import {getInputByType} from "../field";
import {organization_types_object} from "../../../data/unaty/organization_types";
import {EntityInfo} from "../../../utilities/localstorage/entity-localstorage";
import {ViewPermissions} from "../view-permissions";
import {MemberTypePermissionPresets} from "../../../data/unaty/member_type_permission_presets";
import {MemberTypeDirectoryVisibility} from "../../../data/unaty/member_type_directory_visibility";
import {buildMultiSelectString} from "../person-profile";
import {genders_object} from "../../../data/general/genders";
import {useIntl} from "react-intl";
import {week_days_object} from "../../../data/general/week-days";
import {getMemberJoinDate} from "../../reorganize/member-join-date";
import {PhoneServicesFinder} from "../../reorganize/phone-services-finder";
import {months} from "../../../data/general/months";
import {EmailValidationStatusTag} from "../../custom/email-validation-status-tag";
import {AdminImageEditorField} from "../../reorganize/membership-card-designer";
import {isAnonymousUnatyEmail} from "../../../utilities/anonymous-emails";
import {EmptyFieldWarning} from "../../empty-field-warning";
import {displayOrganizationType} from "../../custom/organization-type-selectors";
import {buildAddress} from "../../../utilities/shared";

const Symbol = styled.div`
  font-size: 22px;
  line-height: 19px;
`;


const IDText = styled.div`
  font-family: "Lucida Console", Monaco, monospace;
  font-size: 13px;
  line-height: 19px;
`;


const ColorItem = styled.div`
  background-color: #fff;
  height: 26px;
  width: 26px;
  border-radius: 34%;
  border: 2px solid #e3e8ed;
  margin-left: -6px;
  margin-top: -4px;
  margin-bottom: -4px;
`;

// todo use right date format
export function displayBday(dt, show_year = true, empty = '') {
    if (!dt) {
        return empty
    }

    const {date, month, year} = dt;

    if (year && !date && month === null) {
        return `${year}`;
    } else if (!year && date && month) {
        const mi = typeof month === 'string' ? (parseInt(month) + 1) : month + 1;
        return `${months[mi].label} ${date}`;
    } else if (year && month && !date) {
        const mi = typeof month === 'string' ? (parseInt(month) + 1) : month + 1;
        return `${months[mi].label} ${year}`;
    } else if (month === null && date && year) {
        return `${year}`;
    } else if (!date && month === null && !year) {
        return empty;
    } else if (!year) {
        return empty;
    }
    const format = show_year ? 'MMM Do, YYYY' : 'MMM Do';
    return moment().year(year).month(month).date(date).format(format);
}

export function getAge({date, month, year}) {
    if (year && !date && month === null) {
        return null;
    } else if (!year && date && month) {
        return null;
    } else if (year && month && !date) {
        return null;
    } else if (month === null && date && year) {
        return null;
    } else if (!date && month === null && !year) {
        return null;
    } else if (!year) {
        return null;
    }
    const now = moment();
    return now.diff(moment().year(year).month(month).date(date), 'years');
}

function RenderName({data, apostrophe = false}) {
    return <span>{data.name}{apostrophe ? ', ' : ''}</span>;
}

export function getDisplayValue(type, val, ex, field = '') {
    let keys = [];
    let value = !val ? '' : val;
    let final_val = '';
    const {formatMessage: f} = useIntl();
    const startEditHandler = (e) => {
        e.stopPropagation();
        e.preventDefault();
        ex.startEditing();
    };
    switch (type) {
        case 'join-date': {
            final_val = getMemberJoinDate(val);
            return final_val;
        }
        case 'text': {
            return <span onFocus={startEditHandler} tabIndex="0">{value}</span>;
        }
        case 'id': {
            return value ? <IDText>{value}</IDText> : '';
        }
        case 'permissions': {
            return <ViewPermissions permissions={value}/>;
        }
        case 'week-starts-on': {
            if (value) {
                return week_days_object[value];
            } else {
                return 'Sunday';
            }
        }
        case 'textarea': {
            return <span onFocus={startEditHandler} tabIndex="0">{value}</span>;
        }
        case 'member': {
            if (value) {
                return <EntityInfo community_uid={ex.community_uid} id={value} type={'members'}>
                    <RenderName/>
                </EntityInfo>;
            } else {
                return '';
            }
        }
        case 'group': {
            if (value) {
                return <EntityInfo community_uid={ex.community_uid} id={value} type={'groups'}>
                    <RenderName/>
                </EntityInfo>;
            } else {
                return '';
            }
        }
        case 'birthday': {
            return displayBday(value, true);
        }
        case 'select': {
            final_val = (value === '' || !value) ? '-' : ex.obj[value] ? ex.obj[value] : "";
            return <span onFocus={startEditHandler} tabIndex="0">{final_val}</span>;
        }
        case 'country' : {
            final_val = value === '' ? '-' : countries[value];
            return <span onFocus={startEditHandler} tabIndex="0">{final_val}</span>;
        }
        case 'gender' : {
            final_val = value === '' ? '' : genders_object[value];
            return <span onFocus={startEditHandler} tabIndex="0">{final_val}</span>;
        }
        case 'language' : {
            return value === '' ? '-' : languages_object[value];
        }
        case 'timezone' : {
            return value === '' ? '-' : value;
        }
        case 'switch' : {
            return <div>
                {value ? 'On' : 'Off'}
            </div>
        }
        case 'checkbox' : {
            return <div>
                {value ? 'On' : 'Off'}
            </div>
        }
        case 'symbol' : {
            return value ? <Symbol>{value}</Symbol> : '';
        }
        case 'date': {
            return value ? moment(value).format(ex.time_format ? ex.time_format : 'MM-DD-YYYY') : '';
        }
        case 'permissions-type' : {
            return MemberTypePermissionPresets[value].title;
        }
        case 'directory-visibility' : {
            return MemberTypeDirectoryVisibility[value].title;
        }
        case 'number': {
            return <span onFocus={startEditHandler} tabIndex="0">{value}</span>;
        }
        case 'email': {
            // todo
            if (isAnonymousUnatyEmail(value) && field) {
                final_val = <EmptyFieldWarning type="Email" can_suggest={false} field={field}/>
            } else {
                final_val = validateEmail(value) ? <a onClick={(e) => {
                    e.stopPropagation();
                }} href={`mailto:${value}`}>{value}</a> : value;
            }
            return <span onFocus={startEditHandler} tabIndex="0">{final_val}</span>;
        }
        case 'url': {
            return <span onFocus={startEditHandler} tabIndex="0">{value}</span>;
        }
        case 'handle': {
            return value ? `@${value}` : '';
        }
        case 'user-handle': {
            return value ? `@${value}` : '';
        }
        case 'member-handle': {
            return value ? `@${value}` : '';
        }
        case 'list-handle': {
            return value ? `@${value}` : '';
        }
        case 'phone': {
            // formatInternational
            // getURI
            if (value === '' || !value) {
                return '';
            }
            // change default
            const pn = parsePhoneNumberFromString(value);
            if (!pn) {
                return value ? value : '';
            }
            final_val = pn.isValid() ? <a href={pn.getURI()}>{pn.formatNational()}</a> : value ? value : '';
            return <span onFocus={startEditHandler} tabIndex="0">{final_val}</span>;
        }
        case 'multi-select': {
            if (!value) {
                return '';
            }

            let ms_keys = Object.keys(value);
            const ms_keys_len = ms_keys.length;
            if (ms_keys_len === 0) {
                return '';
            }

            return buildMultiSelectString(ms_keys, ex.obj);
        }
        case 'address': {
            if (!value) {
                return '';
            }
            return buildAddress(value)
        }
        case 'multi-select-taxonomy': {
            if (!value) {
                return '';
            }

            const ms_keys_len = value.length;
            if (ms_keys_len === 0) {
                return '';
            }

            return value.map((key, i) => {
                return <span key={`${i}${key.label}`}>{key.label}{(i + 1) !== ms_keys_len && ', '}</span>
            });
        }
        case 'select-taxonomy': {
            if (!value) {
                return '';
            }
            return value.label;
        }
        case 'member-type': {
            return ex.mt[value].singular;
        }
        case 'organization-category' : {
            return displayOrganizationType(value.category, value.type, '-')
        }
        case 'organization-type' : {
            return value === '' ? '-' : organization_types_object[value];
        }
        case 'display-name': {
            return <ManageDisplayName value={value}/>;
        }
        case 'profile-picture' : {
            return <div>
                <ProfilePicture image={value} hide_initials size='medium' text={''} color={'dodgerblue'}/>
            </div>
        }
        case 'community-logo' : {
            return <div>
                <ProfilePicture image={value} hide_initials size='medium' text={''} color={'dodgerblue'}/>
            </div>
        }
        case 'color' : {
            return <ColorItem style={{background: `${value}`}}/>
        }
        case 'mentor' : {
            if (value) {
                return <EntityInfo community_uid={ex.community_uid} id={value} type={'members'}>
                    <RenderName/>
                </EntityInfo>;
            } else {
                return '';
            }
        }
        case 'mentees' : {
            if (value) {
                keys = Object.keys(value);
                return keys.map((user_uid, i) => {
                    return <EntityInfo key={user_uid} community_uid={ex.community_uid} id={user_uid} type={'members'}>
                        <RenderName apostrophe={keys.length !== (i + 1)}/>
                    </EntityInfo>;
                });
            } else {
                return '';
            }
        }
        case 'community' : {
            return <div>community</div>
        }
        case 'multi-community' : {
            return <div>multi-community</div>
        }
        default : {
            return JSON.stringify(value);
        }
    }
}

// todo unify with new rendering system
export function Field(props) {
    const {
        name,
        type,
        edit_only = false,
        field,
        placeholder="",
        disabled,
        metadata = {},
        meta = {time_format: 'MM/DD/YYYY'},
        value,
        user_uid = '',
        member_id = '',
        community_uid = '',
        editing,
        edit,
        blur,
        change,
        member_types = {},
        options = [],
        obj = {}
    } = props;

    return <div className="">
        <div className="text-xs mb-1 font-medium text-gray-500">{name}</div>
        {getInputByType(type, (val) => {
            change(field, val)
        }, () => {
            blur(field);
        }, value, {
            autoFocus: false,
            options_obj: obj,
            blank_option: true,
            placeholder,
            disabled,
            options, ...meta,
            meta: {user_uid, member_id, community_uid}
        }, field)}

        {type === 'phone' && value && metadata && metadata.phone_services && metadata.phone_services &&
        <div className="pt-0.5 pl-1 pb-1">
            <PhoneServicesFinder field={field.replace('contact.', '')} updateMember={metadata.updateMember}
                                 id={metadata.id} can_edit
                                 active={metadata.phone_services[`${field.replace('contact.', '')}`]}/>
        </div>}
        {type === 'email' && field === 'account_email' && value && metadata && metadata.email_validation &&
        <div className="pt-0.5 pl-1 pb-1">
            <EmailValidationStatusTag {...metadata.ev_data} />
        </div>}
    </div>
}