import React, {useEffect, useState} from "react";
import {MarkdownViewer} from "../../library/components/core";

export function handleSetMD(md) {
    return md
        .replace('(./images/','(https://firebasestorage.googleapis.com/v0/b/unaty-prod.appspot.com/o/policies%2Fcurrent%2Fimages%2F')
        .replace('.png)', '.png?alt=media)')
        .replace('.jpg)', '.jpg?alt=media)')
        .replace('.jpeg)', '.jpeg?alt=media)')
}

export function RemoteMdViewer({url='',border,demo=false}) {
    const [data,setData] = useState(null);

    useEffect(function () {

            fetch(demo?"/cookie-consent.md":url)
                .then(response => response.text())
                .then((dt) => {
                    // jsonData is parsed json object received from url
                    if(typeof dt === 'string') {
                        //
                        setData(handleSetMD(dt));
                    } else {
                        setData('File could not be loaded because you are in staging');
                    }
                })
                .catch((err)=>{
                    setData(`File could not be loaded because you are in staging for: ${url}`);
                })
    }, [url,demo]);

    if(!data) {
        return null;
    }

    return <div className={border?`border border-gray-200 rounded-md p-4 md:p-6 bg-gray-100`:`p-4 md:p-6`}>
        <MarkdownViewer content={data} />
    </div>
}