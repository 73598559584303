import React, {useState} from 'react';
import {ProfileSectionVisibilitySelector} from "./profile-section-visibility-selector";
import {Button} from "../core";
import {ArrowRightIcon} from "@heroicons/react/solid";
import {CustomFieldTypeSelector} from "./custom-field-type-selector";

export function AddCustomField({handleAdd=()=>{},all_fields_data,existing_names=[]}) {
    const [name,setName] = useState('');
    const [type,setType] = useState('text');

    const number_field_type_count = Object.values(all_fields_data).filter(f=>f.type==='number').length;

    let disabled_types = {};
    if(number_field_type_count>1) {
        disabled_types['number'] = true;
    }
    function onAdd() {
        if(name.trim().length===0) {
            return;
        }
        handleAdd({name:name.trim(),type});
        setName('');
        setType('text');
    }

    function handleKeyDown(e) {
        if(e.key === 'Enter') {
            onAdd();
        }
    }

    const input_classes = name === "" ? "bg-white focus-within:bg-white border-gray-300" : "bg-white border-gray-300";

    const disabled = name.trim().length===0||existing_names.indexOf(name)!==-1;

    return <div className={`rounded-b-md border py-1.5 px-2.5 shadow-sm space-x-1.5 flex ${name===''?"border-gray-200 bg-gray-100":"bg-gray-100 border-gray-300"}`}>
        <div className="flex-grow flex">
            <input onKeyDown={handleKeyDown} maxLength={48} value={name} onChange={(e)=>setName(e.target.value)} className={`text-sm w-full font-medium border text-gray-800 px-2 py-0.5 rounded-md transition-colors focus-standard focus-within:bg-white ${input_classes}`} placeholder="New Field"  />
        </div>
        <div className={`flex-none ${name===''?"":""}`}>
            <CustomFieldTypeSelector disabled_types={disabled_types} onSelect={(t)=>setType(t)} type={type} />
        </div>
        <div className={`flex-none`}>
            <Button onClick={()=>onAdd()} right_icon={<ArrowRightIcon/>} disabled={disabled} text="Add" intent="secondary" />
        </div>
    </div>
}