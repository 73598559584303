import {authFetch} from "../../../../../config/network";
import {analytics} from "firebase";

function allFields(icf, secs) {
    if (!secs) {
        return {};
    }
    const entries = Object.entries(secs);

    let fields = {};

    for (let i = 0; i < entries.length; i++) {
        const sec = entries[i][1];
        for (let k = 0; k < sec.fields.length; k++) {
            const fi = sec.fields[k];
            fields[fi] = sec.field_data[fi];
        }
    }

    return fields;
}

function getCustomFields(icf, all_fields) {
    let arr = [];
    for (let k = 0; k < icf.length; k++) {
        const data = all_fields[icf[k]];
        arr.push({
            ...data
        });
    }

    return arr;
}

function getName(member,community) {
    if(community.preferences.members.display_name==='first_name last_name') {
        return member.name;
    } else {
        return member.name;
    }
}



export function processDataForWelcomeCard(member, member_id, community, community_uid, icf, secs, updateMember) {
    const all_fields = allFields(icf, secs);
    return {
        name: getName(member,community),
        handle: member.handle,
        profile_picture: member.profile_picture,

        social: member.social,

        custom_fields: getCustomFields(icf, all_fields),
        custom_field_data: member.custom_fields,

        updateCustomField: (id, val, cb=()=>{}) => {
            let payload = {
                community_uid,
                member_id: member_id,
                id: member_id,
                custom_fields: {},
                _updated_fields: [`${id}`]
            };
            const sp = id.split('.');
            payload['custom_fields'][sp[1]] = val;
            updateMember({
                ...member,
                [`custom_fields.${id}`]: val
            });
            const res = () => {

                cb();
            };
            authFetch('/members/update-own-profile', res, res, "POST", {payload});
        },

        updateSocialField: (id, val, cb=()=>{}) => {
            let payload = {
                community_uid,
                member_id: member_id,
                id: member_id,
                social: {},
                _updated_fields: [`${id}`]
            };
            const sp = id.split('.');
            payload['social'][sp[1]] = val;
            updateMember({
                ...member,
                [`${id}`]: val
            });
            const res = () => {
                cb();
            };
            authFetch('/members/update-own-profile', res, res, "POST", {payload});
        },

        updateProfilePicture: (val, color, cb) => {
            let payload = {
                url: val,
                community_uid: community_uid,
                member_id: member_id,
                id: member_id,
                color
            };
            updateMember({
                ...member,
                [`profile_picture`]: val
            });
            const res = () => {

                cb();
            };
            analytics().logEvent('welcome_card_update_profile_picture', {
                event_id: 'welcome_card_update_profile_picture',
                content_type: 'member',
                community_uid: community_uid,
                member_id: member_id
            });
            authFetch('/members/set-profile-picture', res, res, "POST", {payload});
        },
        updateEmail: (val, cb) => {
            let payload = {
                community_uid,
                member_id: member_id,
                id: member_id,
                contact: {},
                _updated_fields: ['contact.email']
            };
            payload['contact']['email'] = val;
            updateMember({
                ...member,
                [`contact.email`]: val
            });
            const res = () => {
                cb();
            };
            analytics().logEvent('welcome_card_update_email', {
                event_id: 'welcome_card_update_email',
                content_type: 'member',
                community_uid: community_uid,
                content_id: member_id
            });
            authFetch('/members/update-own-profile', res, res, "POST", {payload});
        },
        updatePhone: (val, cb) => {
            let payload = {
                community_uid,
                member_id: member_id,
                id: member_id,
                contact: {},
                _updated_fields: ['contact.phone']
            };
            payload['contact']['phone'] = val;
            updateMember({
                ...member,
                [`contact.phone`]: val
            });
            const res = () => {

                cb();
            };
            analytics().logEvent('welcome_card_update_phone', {
                event_id: 'welcome_card_update_phone',
                content_type: 'member',
                community_uid: community_uid,
                content_id: member_id
            });
            authFetch('/members/update-own-profile', res, res, "POST", {payload});
        },
        updateAddress: (val, cb) => {
            let payload = {
                community_uid,
                member_id: member_id,
                id: member_id,
                address: {},
                _updated_fields: ['address']
            };
            payload['address'] = val;
            updateMember({
                ...member,
                [`address`]: val
            });
            const res = () => {

                cb();
            };
            analytics().logEvent('welcome_card_update_address', {
                event_id: 'welcome_card_update_address',
                content_type: 'member',
                community_uid: community_uid,
                content_id: member_id
            });
            authFetch('/members/update-own-profile', res, res, "POST", {payload});
        },

        account_email: member.account_email,
        email: member.contact.email,
        phone: member.contact.phone,

        id: member_id,
        address: member.address,

        notice: null,

        shortcuts: []
    };
}