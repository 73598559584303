import React, {useState, useCallback, useRef, useEffect, useContext, useMemo, Component} from 'react';
import {
    EditorState,
    Entity,
    SelectionState,
    AtomicBlockUtils,
    convertToRaw,
    DefaultDraftBlockRenderMap,
    RichUtils,
    convertFromRaw,
    convertFromHTML,
    ContentState,
    Modifier
} from "draft-js";
import {getSelectedBlocksList, getSelectionEntity, getSelectionText} from "draftjs-utils";
import Editor from "@draft-js-plugins/editor";

import createMentionPlugin, {
    defaultSuggestionsFilter,
} from '@draft-js-plugins/mention';

import '@draft-js-plugins/mention/lib/plugin.css';
import '@draft-js-plugins/emoji/lib/plugin.css';
import '@draft-js-plugins/inline-toolbar/lib/plugin.css';
import '@draft-js-plugins/linkify/lib/plugin.css';
import {buildSearchQueries, Button, convertSearchObjToArr, Field, queryMembersAndRoles} from "../core";

import {ScEditorContent} from "./styles/editor";
import {scEditorKeyBindingFn, scOpenKeyBindingFn} from "./editor/key-bindings";
import {scEditorBlockRenderer} from "./editor/block-renderer";
import {scEditorHandleAddLink} from "./editor/handle-add-link";
import {scPluginLinkify} from "./editor/plugins/linkify";
import {scPluginLink} from "./editor/plugins/anchor";
import {sc_editor_style_map} from "./editor/style-map";
import {scPluginEmoji} from "./editor/plugins/emoji";
import {sc_block_render_map} from "./editor/block-render-map";
import {ScStaticToolbar} from "./editor/plugins/toolbar";
import {GlobalContext} from "../../../app/global/global-context";
import {
    CUSTOM_DOMAIN_DEFAULT,
    CUSTOM_NANOID, DEFAULT_USER_PHOTO,
    MAX_FILE_SIZE,
    POST_ACCEPTED_ATTACHMENT_TYPES,
    POST_ACCEPTED_IMAGE_TYPES
} from "../../../config/defaults";
import firebase from "firebase/app";
import {CommunityContext} from "../../../app/community/community-context";
import {AddLinkModal} from "./editor/add-link-modal";
import DeleteModal from "../old/delete-modal";
import FolderPicker from "../reorganize/folder-picker";
import {FileItem} from "../old/file-uploader/item";
import {es_handleReturn} from "./helpers/handleReturn";
import {es_insertCustomBlock} from "./helpers/insert-custom-block";
import {sc_handleConvertToHeader} from "./helpers/handle-convert-to-header";
import {api_createFile} from "../../../api/community/files/create";
import moment from "moment";
import {MentionEntry} from "./editor/plugins/mention-entry";
import styled from "styled-components";
import createInlineToolbarPlugin from "@draft-js-plugins/inline-toolbar";
import createDividerPlugin from '@draft-js-plugins/divider';
import {sc_handleConvertToEm} from "./helpers/handle-convert-to-em";
import {cleanQuery} from "../reorganize/entity-search/new-entity-search";
import {DragDropContext, Droppable} from "react-beautiful-dnd";
import {LinkIcon} from "@heroicons/react/solid";
import {ChevronDownIcon} from "@heroicons/react/outline";
import {imageResizeRequest} from "../../../config/network";
import {NewTooltip} from "../reorganize/new-tooltip";
import {api_getPost} from "../../../api/community/posts/get-post";
import {parseRawContent} from "../posts-feed/api";

const dividerPlugin = createDividerPlugin();

const B = () => <svg height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
    <path
        d="M15.6 10.79c.97-.67 1.65-1.77 1.65-2.79 0-2.26-1.75-4-4-4H7v14h7.04c2.09 0 3.71-1.7 3.71-3.79 0-1.52-.86-2.82-2.15-3.42zM10 6.5h3c.83 0 1.5.67 1.5 1.5s-.67 1.5-1.5 1.5h-3v-3zm3.5 9H10v-3h3.5c.83 0 1.5.67 1.5 1.5s-.67 1.5-1.5 1.5z"/>
    <path d="M0 0h24v24H0z" fill="none"/>
</svg>;

const UL = () => <svg height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
    <path
        d="M23.062,4.287 C23.58,4.287 24,4.707 24,5.225 C24,5.744 23.58,6.164 23.062,6.164 L9.536,6.164 C9.018,6.164 8.598,5.744 8.598,5.225 C8.598,4.707 9.018,4.287 9.536,4.287 L23.062,4.287 z M23.062,11.011 L9.536,11.011 C9.018,11.011 8.598,11.431 8.598,11.949 C8.598,12.467 9.018,12.888 9.536,12.888 L23.062,12.888 C23.58,12.888 24,12.467 24,11.949 C24,11.431 23.58,11.011 23.062,11.011 z M23.062,17.735 L9.536,17.735 C9.018,17.735 8.598,18.155 8.598,18.673 C8.598,19.191 9.018,19.611 9.536,19.611 L23.062,19.611 C23.58,19.611 24,19.191 24,18.673 C24,18.155 23.58,17.735 23.062,17.735 z M1.798,3.529 C0.805,3.529 0,4.334 0,5.327 C0,6.32 0.805,7.125 1.798,7.125 C2.791,7.125 3.596,6.32 3.596,5.327 C3.596,4.334 2.791,3.529 1.798,3.529 z M1.798,10.073 C0.805,10.073 0,10.878 0,11.871 C0,12.864 0.805,13.669 1.798,13.669 C2.791,13.669 3.596,12.864 3.596,11.871 C3.596,10.878 2.791,10.073 1.798,10.073 z M1.798,16.875 C0.805,16.875 0,17.68 0,18.673 C0,19.666 0.805,20.471 1.798,20.471 C2.791,20.471 3.596,19.666 3.596,18.673 C3.596,17.68 2.791,16.875 1.798,16.875 z"/>
</svg>;

const OL = () => <svg height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
    <path
        d="M2.464,13.74 C3.899,13.74 4.866,14.553 4.866,15.757 C4.866,16.517 4.467,17.167 3.292,18.293 L1.935,19.588 L1.935,19.674 L4.972,19.674 L4.972,20.825 L0.077,20.825 L0.077,19.867 L2.339,17.629 C3.287,16.681 3.533,16.315 3.533,15.848 C3.533,15.246 3.095,14.852 2.421,14.852 C1.733,14.852 1.271,15.304 1.271,15.968 L1.271,16.002 L0,16.002 L0,15.973 C0,14.645 1.006,13.74 2.464,13.74 z M23.076,17.821 L9.753,17.821 C9.239,17.873 8.85,18.205 8.829,18.745 C8.881,19.26 9.213,19.649 9.753,19.67 L23.076,19.67 C23.586,19.67 24,19.256 24,18.745 C24,18.235 23.586,17.821 23.076,17.821 z M3.412,3.175 L2.074,3.175 L0.197,4.431 L0.197,5.721 L1.988,4.533 L2.074,4.533 L2.074,8.98 L0.202,8.98 L0.202,10.12 L5.188,10.12 L5.188,8.98 L3.412,8.98 L3.412,3.175 z M9.753,6.424 L23.076,6.424 C23.586,6.424 24,6.01 24,5.5 C24,4.99 23.586,4.576 23.076,4.576 L9.753,4.576 C9.239,4.628 8.85,4.96 8.829,5.5 C8.881,6.015 9.213,6.403 9.753,6.424 z M9.753,12.924 L23.076,12.924 C23.586,12.924 24,12.51 24,12 C24,11.49 23.586,11.076 23.076,11.076 L9.753,11.076 C9.239,11.128 8.85,11.46 8.829,12 C8.881,12.515 9.213,12.904 9.753,12.924 z"/>
</svg>;

const BQ = () => <svg height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">

    <path
        d="M20.239,3.907 C20.789,3.907 21.235,4.352 21.235,4.902 C21.235,5.452 20.789,5.898 20.239,5.898 L0.996,5.898 C0.446,5.898 0,5.452 -0,4.902 C0,4.352 0.446,3.907 0.996,3.907 L20.239,3.907 z M23.004,10.959 L7.633,10.959 C7.083,10.959 6.637,11.404 6.637,11.954 C6.637,12.504 7.083,12.95 7.633,12.95 L23.004,12.95 C23.554,12.95 24,12.504 24,11.954 C24,11.404 23.554,10.959 23.004,10.959 z M18.801,18.093 L7.633,18.093 C7.083,18.093 6.637,18.539 6.637,19.089 C6.637,19.639 7.083,20.085 7.633,20.085 L18.801,20.085 C19.351,20.085 19.797,19.639 19.797,19.089 C19.797,18.539 19.351,18.093 18.801,18.093 z M0.996,10.046 C0.446,10.046 0,10.492 0,11.042 L0,20.129 C0,20.679 0.446,21.125 0.996,21.125 C1.545,21.125 1.991,20.679 1.991,20.129 L1.991,11.042 C1.991,10.492 1.545,10.046 0.996,10.046 z"/>
</svg>;

const I = () => <svg height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 0h24v24H0z" fill="none"/>
    <path d="M10 4v3h2.21l-3.42 8H6v3h8v-3h-2.21l3.42-8H18V4z"/>
</svg>;

const U = () => <svg height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 0h24v24H0z" fill="none"/>
    <path
        d="M12 17c3.31 0 6-2.69 6-6V3h-2.5v8c0 1.93-1.57 3.5-3.5 3.5S8.5 12.93 8.5 11V3H6v8c0 3.31 2.69 6 6 6zm-7 2v2h14v-2H5z"/>
</svg>;

const Btn = styled.button`
  height: 26px;
  width: 26px;
  display: flex;
  align-items: center;
  justify-content: center;

  > svg {
    fill: ${props => props.fill};
    height: ${props => props.icon_size === "sm" ? "16px" : "18px"};
    width: ${props => props.icon_size === "sm" ? "16px" : "18px"};
  }
`;

function CustomButton({onClick, tooltip, icon_size = "md", fill = "auto", active, icon, theme}) {

    const btn = <Btn icon_size={icon_size} fill={fill} active={active} onMouseDown={(e) => e.preventDefault()}
                     onClick={onClick}
                     className={`${active ? "bg-gray-700 text-gray-200" : "text-gray-400 hover:text-gray-200 hover:bg-gray-700"} sc-toolbar-button`}>
        {icon}
    </Btn>

    if (tooltip) {
        return <NewTooltip message={tooltip.title} subtext={tooltip.subtitle}>
            {btn}
        </NewTooltip>
    }

    return btn;
}

const Separator = styled.div`
  width: 1px;
  background: #3e3e3c;
  margin-top: 4px;
  margin-bottom: 4px;
  margin-left: 0.5rem !important;
  margin-right: 0.5rem !important;
`;

const ToolbarStyles = styled.div`

  > div {
    border-radius: 6px;
    background: #151513;
    padding: 2px 3px !important;
    border: 1px solid #2d2d2a;

    > svg {
      fill: #6B6B6B;
    }
  }

  > div::before {
    border: none;
  }

  > div::after {
    border-top-color: #2d2d2a;
  }
`;

// todo
/*
- create table of contents from markdown
- custom table of members as block from group?
- GIF support
- colors as inline entities like #059df4
- mentions don't work sometimes
- remove formatting
- colors for text https://codepen.io/Kiwka/pen/oBpVve
 */

/*

    function updateEntityData(es, dt, ek) {
        const content_state = es.getCurrentContent();

        const mergedContent = content_state.mergeEntityData(ek, dt);

        const changedContentState = Modifier.applyEntity(
            mergedContent,
            es.getSelection(),
            ek
        );
        return EditorState.push(es, changedContentState, 'insert-characters');
    }

    function insertImage(editorState, url, meta) {

        const contentState = editorState.getCurrentContent();
        const contentStateWithEntity = contentState.createEntity(
            'image',
            'IMMUTABLE',
            {src: url, ...meta},
        );
        const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
        const newEditorState = EditorState.set(
            editorState,
            {currentContent: contentStateWithEntity},
        );
        return {
            entity_key: entityKey,
            editor_state: AtomicBlockUtils.insertAtomicBlock(newEditorState, entityKey, ' ')
        };
    }
 */

// example with audio, image, video
// https://github.com/facebook/draft-js/blob/master/examples/draft-0-10-0/media/media.html

export const extendedBlockRenderMap = DefaultDraftBlockRenderMap.merge(sc_block_render_map);

// todo markdown plugin fix
// https://ask.csdn.net/questions/6877426

function buildSignature(member, all_roles) {
    let a = `${member.name}`;
    let my_roles = Object.values(all_roles).filter(b => b.assignee === member.id).map(c => c.name);
    if (my_roles.length > 0) {
        a += `\n${my_roles.join(', ')}`;
    }
    if (member.account_email) {
        a += `\n${member.account_email}`
    }
    if (member.contact.phone) {
        a += `\n${member.contact.phone}`
    }
    return a;
}

const {EmojiSuggestions} = scPluginEmoji;

const storage = firebase.storage().ref();

export function ensureExtension(str) {
    return str;
}

function cleanDownloadURL(str) {
    return str.split('&token')[0];
}

const button_theme = {
    active: 'sc-toolbar-button-active',
    button: 'sc-toolbar-button',
    buttonWrapper: 'sc-toolbar-button-wrapper'
};

function FSItem(props) {
    const color = props.active ? `text-gray-200 bg-gray-700` : `text-gray-400 hover:text-gray-200 hover:bg-gray-700`;
    return <div onClick={() => {
        if (props.onClick) {
            props.onClick();
        }
    }} style={{height: '26px', borderRadius: "0.25rem"}}
                className={`px-2 flex items-center text-sm cursor-pointer font-medium ${color}`}>
        {props.children}
    </div>
}

class FontSizePicker extends Component {
    componentDidMount() {
        setTimeout(() => {
            window.addEventListener('click', this.onWindowClick);
        });
    }

    componentWillUnmount() {
        window.removeEventListener('click', this.onWindowClick);
    }

    onWindowClick = () => {
        // Call `onOverrideContent` again with `undefined`
        // so the toolbar can show its regular content again.
        this.props.onOverrideContent(undefined);
    }

    render() {
        const {active = '', _actions} = this.props;
        return (
            <div onMouseDown={(e) => e.preventDefault()} className="flex space-x-1" style={{height: '1.65rem'}}>
                <FSItem onClick={() => _actions._onTextClick()} active={active === 'unstyled'}>Text</FSItem>
                <FSItem onClick={() => _actions._onHeader1Click()} active={active === 'header-one'}>H1</FSItem>
                <FSItem onClick={() => _actions._onHeader2Click()} active={active === 'header-two'}>H2</FSItem>
                <FSItem onClick={() => _actions._onHeader3Click()} active={active === 'header-three'}>H3</FSItem>
            </div>
        );
    }
}

function renderSize(active) {
    if (active) {
        if (active === 'header-one') {
            return "H1"
        } else if (active === 'header-two') {
            return "H2"
        } else if (active === 'header-three') {
            return "H3"
        }
    }
    return "Text"
}

class FontSizeButton extends Component {
    // When using a click event inside overridden content, mouse down
    // events needs to be prevented so the focus stays in the editor
    // and the toolbar remains visible  onMouseDown = (event) => event.preventDefault()
    onMouseDown = (event) => {
        event.preventDefault()
    };

    onClick = () =>
        // A button can call `onOverrideContent` to replace the content
        // of the toolbar. This can be useful for displaying sub
        // menus or requesting additional information from the user.
        this.props.onOverrideContent((props) => <FontSizePicker {...this.props} {...props} />);

    render() {
        const {active} = this.props;
        return (
            <div
                style={{height: '26px'}}
                onMouseDown={this.onMouseDown}
                className={"flex items-center "}
            >
                <div style={{height: '26px', borderRadius: "0.25rem"}} onClick={() => this.onClick()}
                     className="text-sm py-0.5 flex items-center font-medium px-1 -mr-0.5 hover:bg-gray-600 cursor-pointer">
                    <span className="text-gray-200">{renderSize(active)}</span>
                    <span className="pl-1 text-gray-400 h-svg-3"><ChevronDownIcon/></span>
                </div>
            </div>
        );
    }
}


async function getImageDimensions(file) {
    return new Promise((resolve, reject) => {
        const fr = new FileReader;

        fr.onload = function () { // file is loaded
            const img = new Image;

            img.onload = function () {

                resolve({width: img.width, height: img.height});
            };

            img.onerror = function () {
                reject();
            }

            img.src = fr.result; // is the data URL because called with readAsDataURL
        };

        fr.readAsDataURL(file)
    })
}

const StyledMention = styled.span`
  background: #FEE3C8;
  color: #555560;
  border-radius: 6px !important;
  padding: 0 4px;
`;

const StyledMergeTag = styled.span`
  background: #EBEDF1;
  color: #525657;
  border-radius: 6px !important;
  padding: 0 4px;
`;

const mentionPlugin = createMentionPlugin({
    mentionComponent: InlineEntry,
    supportWhitespace: true,
    entityMutability: 'IMMUTABLE',
    theme: {mentionSuggestionsPopup: "mentions-popup-styles"}
});

const mergeTagPlugin = createMentionPlugin({
    mentionComponent: MergeTag,
    supportWhitespace: false,
    mentionTrigger: "{{",
    entityMutability: 'IMMUTABLE',
    theme: {mentionSuggestionsPopup: "mentions-popup-styles"}
});

function MergeTag({entityKey, mention, className, decoratedText}) {
    return <StyledMergeTag key={entityKey} className={`${className}`}>

        {decoratedText}
    </StyledMergeTag>
}

function InlineEntry({entityKey, mention, className, decoratedText}) {
    return <StyledMention key={entityKey} className={`${className}`}>

        {decoratedText}
    </StyledMention>
}

function reduceGroups(merge_tags, raw) {
    let a = [];
    merge_tags.forEach(group => {
        group.tags.forEach(tag => {
            a.push({
                example: tag.fn(raw.member, raw.community, raw.sender||{}),
                name: tag.subtitle,
                id: tag.subtitle
            });
        })
    })
    return a;
}

// todo max image resizing / optimizing

function addSummaryBlock() {

}

function addMemberListBlock() {

}

export function RichTextEditor({
                                   updateAttachments = () => {
                                   },
                                   style = {},
                                   merge_tags = [],
                                   initial_action = '',
                                   onChange = () => {
                                   },
                                   handleClose = () => {
                                   },
                                   debug = false,
                                   mode = "",
                                   upload_files_to_folder = false,
                                   actions,
                                   right_actions,
                                   folder_id = "",
                                   fc,
                                   action_bar,
                                   placeholder = "",
                                   template,
                                   content_state,
                                   viewer = false,
                                   auto_focus = false
                               }) {
    const [editorState, setEditorState] = React.useState(
        content_state ? () => EditorState.createWithContent(content_state) : () => EditorState.createEmpty()
    );
    const global = useContext(GlobalContext);
    const community = useContext(CommunityContext);
    const [open, setOpen] = useState('');
    // todo set editor to readonly while using custom block
    const [read_only, setReadOnly] = useState(viewer);
    const [files_value,setFV] = useState('');
    const [attachments_folder, setAttachmentsFolder] = useState(folder_id === 'posts' ? "" : folder_id);
    const [meta, setMeta] = useState(null);
    const [extras, setExtras] = useState({});
    const [es_update, setUpdate] = useState(null);
    const [modal, setModal] = useState(null);
    const [files, setFiles] = useState([]);
    const [attachments, setAttachments] = useState({});
    const attach_ref = useRef({});
    const editor = useRef(null);
    const [ref, setRef] = useState(React.createRef());
    const [ref2, setRef2] = useState(React.createRef());
    const [merge_tag_suggestions, setMergeTagSuggestions] = useState([]);
    const [suggestions, setSuggestions] = useState([]);

    const last_key = useRef(null);

    useEffect(function () {
        setTimeout(function () {
            if (initial_action === 'upload-image') {
                openImageSelector();
            } else if (initial_action === 'upload-attachment') {
                openFileSelector();
            }
        }, 50)
    }, [initial_action])

    const [{ plugins, InlineToolbar, MentionSuggestions, MergeTagSuggestions }] = useState(() => {
        const toolbarPlugin = createInlineToolbarPlugin();
        const { InlineToolbar } = toolbarPlugin;
        const plugins = [toolbarPlugin, scPluginEmoji, mentionPlugin, mergeTagPlugin, scPluginLinkify(read_only), scPluginLink, dividerPlugin];
        return {
            plugins,
            InlineToolbar,
            MentionSuggestions: mentionPlugin.MentionSuggestions,
            MergeTagSuggestions: mergeTagPlugin.MentionSuggestions
        };
    });

    const onOpenChange = useCallback((_open) => {
        setOpen(_open ? 'mention' : '');
    }, []);
    const onOpenMergeTagChange = useCallback((_open) => {
        setOpen(_open ? 'merge-tag' : '');
    }, []);
    const onOpenChangeEmoji = useCallback(() => {
        setOpen('emoji');

    }, []);
    const onCloseChangeEmoji = useCallback(() => {
        setOpen('');
    }, []);

    const onSearchMergeTagChange = useCallback(({value}) => {
        const arr = reduceGroups(merge_tags, {community: community.data, sender: community.member, member: community.member});
        setMergeTagSuggestions(defaultSuggestionsFilter(value, arr));
    }, []);

    const onSearchChange = useCallback(({value}) => {
        const cq = cleanQuery(value);
        const searchable = {members: true, roles: true};
        const context = {
            community_uid: community.uid
        };
        const queries = buildSearchQueries(searchable);
        queryMembersAndRoles(cq, queries, context, searchable)
            .then((result) => {
                const arr = convertSearchObjToArr(result);
                setSuggestions(defaultSuggestionsFilter(cq, arr.map(a => {
                    return {
                        handle: a.handle,
                        type: a._type,
                        id: a.value,
                        name: a.label,
                        image: a.image
                    }
                })));
            })
    }, []);

    useEffect(function () {
        if (auto_focus) {
            focus();
        }
    }, []);

    useEffect(function () {
        if (fc > 0) {
            focus();
        }

    }, [fc])

    useEffect(function () {
        if (template) {
            if (template.raw) {
                const new_content_state = convertFromRaw(parseRawContent(template.raw));
                const new_editor_state = EditorState.push(editorState, new_content_state, "change-block-type");
                setEditorState(new_editor_state);
            }
        }
    }, [template]);

    useEffect(function () {
        if (es_update) {

            if (es_update.type === 'update-block-data' && es_update.block_key && es_update.data.url) {
                const new_update = es_update;
                const cs = editorState.getCurrentContent();

                const block_data = cs.getBlockForKey(es_update.block_key);

                if (!block_data) {
                    // deleted..
                    return;
                }

                const current_selection = editorState.getSelection();

                const contentBlockSelectionState = SelectionState.createEmpty(es_update.block_key);
                const new_content_state = Modifier.mergeBlockData(cs, contentBlockSelectionState, new_update.data);

                const newEditorState = EditorState.push(editorState, new_content_state, 'change-block-data');

                setUpdate(null);
                setEditorState(EditorState.forceSelection(newEditorState, current_selection));
            }
        }
    }, [es_update, editorState])

    useEffect(function () {
        onChange(editorState)
    }, [editorState])

    useEffect(function () {
        attach_ref.current = attachments;
        updateAttachments(attachments);
    }, [attachments])

    function addBlankSpace() {
        let _sel = editorState.getSelection();

        let _cs = Modifier.insertText(
            editorState.getCurrentContent(),
            _sel,
            ' '
        );
        setEditorState(EditorState.push(
            editorState,
            _cs,
            'insert-text'
        ));
    }

    function insertTag(tag) {
        const raw = {community: community.data, sender: community.member, member: community.member};
        const all_tags = reduceGroups(merge_tags, raw);
        const item = all_tags.filter(at => at.id === tag)[0];
        const stateWithEntity = editorState.getCurrentContent().createEntity(
            '{{mention',
            'IMMUTABLE',
            {
                mention: {id: tag, name: tag, example: item.example},
            },
        );
        let _sel = editorState.getSelection();
        const entityKey = stateWithEntity.getLastCreatedEntityKey()
        const stateWithText = Modifier.insertText(stateWithEntity, editorState.getSelection(), tag, null, entityKey);
        const new_content = stateWithText.merge({
            selectionBefore: _sel,
            selectionAfter: stateWithText.getSelectionAfter().set('hasFocus', true)
        });
        const new_editor_state = EditorState.push(editorState, new_content);
        const final_editor_state = EditorState.moveSelectionToEnd(new_editor_state);
        setEditorState(EditorState.forceSelection(final_editor_state, final_editor_state.getSelection()));
    }

    function handleMetaChange(c) {
        setMeta({
            ...meta,
            ...c
        });
    }

    function scEditorHandleKeyCommand(command) {
        if (command === 'add-link') {
            // here we should open the modal to add a link
            /*
            setMeta({
                type: 'add-link',
                selection_text: getSelectionText(editorState),
                selection_link: ''
            })

             */
            setOpen('add-link');
            return 'handled';
        } else if (command === "em") {
            setEditorState(
                sc_handleConvertToEm(editorState)
            )
            return "handled";
        } else if (command === "convertToImage") {
            setEditorState(
                RichUtils.toggleBlockType(editorState, "image-with-caption")
            );
            return "handled";
        } else if (command === 'convert-to-h2') {
            setEditorState(
                sc_handleConvertToHeader(editorState, 'header-two')
            );
            return 'handled';
        } else if (command === 'convert-to-h1') {
            setEditorState(
                sc_handleConvertToHeader(editorState, 'header-one')
            );
            return 'handled';
        } else if (command === 'convert-to-blockquote') {
            setEditorState(
                sc_handleConvertToHeader(editorState, 'blockquote')
            )
        } else if (command === 'convert-to-unordered-list-item') {
            setEditorState(
                sc_handleConvertToHeader(editorState, 'unordered-list-item')
            )
        } else if (command === 'convert-to-ordered-list-item') {
            setEditorState(
                sc_handleConvertToHeader(editorState, 'ordered-list-item')
            )
        } else if (command === 'bold') {
            setEditorState(
                RichUtils.toggleInlineStyle(editorState, 'BOLD')
            );
            return 'handled';
        } else if (command === 'underline') {
            setEditorState(
                RichUtils.toggleInlineStyle(editorState, 'UNDERLINE')
            );
            return 'handled';
        } else if (command === 'strikethrough') {
            setEditorState(
                RichUtils.toggleInlineStyle(editorState, 'STRIKETHROUGH')
            );
            return 'handled';
        } else if (command === 'italic') {
            setEditorState(
                RichUtils.toggleInlineStyle(editorState, 'ITALIC')
            );
            return 'handled';
        }
        return 'not-handled';
    }

    const focus = () => {
        if (editor && editor.current) {
            editor.current.focus()
        }
    }

    function handleAddBlock(es, block_type, data = {}, character = '', direction = 'after') {

        const {editor_state, block_key} = es_insertCustomBlock(
            es,
            block_type,
            character,
            data,
            direction
        )

        return {
            editor_state, block_key
        }
    }

    function openImageSelector() {
        ref.current.click();
    }

    function openFileSelector() {
        ref2.current.click();
    }

    // todo add support for up to 6 files
    function fileSelected(e) {

        const ff = Array.from(e.target ? e.target.files : e);

        if (!ff[0]) {
            return;
        }

        // reset input field value
        setFV(ff[0]);

        let final_files = [];
        let images = [];

        ff.forEach(file => {
            let filesize = ((file.size / 1024) / 1024).toFixed(6); // MB

            const type_image = POST_ACCEPTED_IMAGE_TYPES.indexOf(file.type) !== -1;
            const type_file = !type_image;

            if (filesize > MAX_FILE_SIZE) {
                global.addToast({
                    text: `${file.name} (${filesize}) is too large, it must be under 20 MB`,
                    intent: 'danger'
                });
            } else if (!type_image && !type_file) {
                global.addToast({
                    text: `${file.name} - invalid file type (${file.type})`,
                    intent: 'danger'
                });
            } else {

                if (type_image) {
                    if (images.length === 0) {
                        images.push(file);
                    }
                } else {
                    if (final_files.length > 5) {
                        global.addToast({
                            text: `You can upload up to six files, ${file.name} is being ignored`,
                            intent: 'danger'
                        });
                    } else {
                        final_files.push(file)
                    }
                }
            }
        })

        if (final_files.length === 0 && images.length === 0) {
            global.addToast({text: `No valid files found`, intent: 'danger'});
            return;
        }

        if (images.length > 0) {
            global.addToast({text: 'Uploading images..', intent: 'info'});
        }

        images.forEach(img => {
            loadSingleImage(img)
                .then(a => {
                    const {block_key, editor_state} = handleAddBlock(editorState, "image-with-caption", {
                        url: "", ...a,
                        caption: "",
                        loading: true
                    });

                    setEditorState(editor_state);
                    const cb = (url, meta, extra, color, images) => {
                        setUpdate({
                            type: 'update-block-data',
                            block_key: extra.block_key,
                            data: {url, color, loading: false}
                        })

                        //
                    };
                    uploadAndResizeImage(img, img.type, community.uid, "posts", img.name, img.size, {block_key}, cb)
                })
        });

        if (final_files.length > 0) {
            setFiles(final_files);
            setFV('');
            if (upload_files_to_folder) {
                setModal('select-attachments-folder');
            } else {
                setTimeout(function () {
                    handleUploadAttachments(final_files);

                }, 100)

            }
        }
    }

    function testLoadPost() {
        api_getPost(community.uid,"6ZJlkWifqzeVD8vkPEm2")
            .then(p=>{
                console.log("PP",p)
                const new_content_state = convertFromRaw(parseRawContent(p.raw));
                const new_editor_state = EditorState.push(editorState, new_content_state, "change-block-type");
                setEditorState(new_editor_state);
            })
    }

    function addSignature() {
        const {block_key, editor_state} = handleAddBlock(editorState,
            "unstyled",
            {},
            buildSignature(community.member, community.all_roles),
            'before'
        );
        setEditorState(editor_state);
        setExtras({...extras, added_signature: true})
    }

    async function loadSingleImage(file) {
        return await getImageDimensions(file);
    }

    function handleUploadAttachments(fl_a) {
        setModal("");
        let na = {...attachments};
        const files_iterate = fl_a ? fl_a : files;
        files_iterate.forEach(file => {
            na[`${file.name}____${file.type}`] = {
                status: "loading",
                name: file.name,
                type: file.type,
                size: file.size
            };
            const cb = (url, meta) => {
                const pl = {
                    name: file.name,
                    type: file.type,
                    folder: attachments_folder,
                    url: url,
                    size: file.size,
                };
                if (upload_files_to_folder) {
                    api_createFile(community, pl)
                        .then((res) => {
                            let nad = {...attach_ref.current};
                            nad[`${meta.name}____${meta.type}`] = {
                                status: "done",
                                name: meta.name,
                                id: res && res.data ? res.data.id : "",
                                type: meta.type,
                                url,
                                folder: attachments_folder,
                                size: meta.size
                            };
                            setAttachments(nad);
                            setFiles([]);
                        })
                } else {
                    let nad = {...attach_ref.current};
                    nad[`${meta.name}____${meta.type}`] = {
                        status: "done",
                        name: meta.name,
                        id: "",
                        type: meta.type,
                        url,
                        folder: attachments_folder,
                        size: meta.size
                    };
                    setAttachments(nad);
                    setFiles([]);
                }
            };
            uploadFile(file, file.type, community.uid, attachments_folder, file.name, file.size, {}, cb);
        });
        setAttachments(na);
    }

    function uploadAndResizeImage(file, type, scope_id, folder_id, name, size, extra = {}, cb) {

        const res = (resp) => {
            if (resp && resp.data) {
                cb(resp.data.url, {type}, extra, resp.data.color, resp.data.images);
            }
        };
        const err = () => {
          // todo
        };
        const uid = CUSTOM_NANOID();
        const fid = upload_files_to_folder && folder_id ? folder_id : `${moment().format('DD-MM-YYYY')}`;
        const path = `${scope_id}/${fid}/${uid}-${ensureExtension(name)}`;
        const payload = {
            uid, fid, path
        };
        imageResizeRequest(file, res, "POST", payload);
    }

    function uploadFile(file, type, scope_id, folder_id, name, size, extra = {}, cb) {
        const uid = CUSTOM_NANOID();
        const fid = upload_files_to_folder && folder_id ? folder_id : `${moment().format('DD-MM-YYYY')}`;
        const path = `${scope_id}/${fid}/${uid}-${ensureExtension(name)}`;
        const task = storage.child(path).put(file, {
            contentType: type,
        });

        const meta = {
            type,
            size,
            name
        };

        task.on(
            firebase.storage.TaskEvent.STATE_CHANGED,
            snap => {
            },
            err => {
            },
            (res) => fileUploaded(path, meta, extra, cb)
        );
    }

    function fileUploaded(path, meta = {}, extra = {}, cb) {
        const m = meta;
        storage
            .child(path)
            .getDownloadURL()
            .then((url) => {
                cb(url, m, extra);
            })
    }

    function _changeInlineStyle(style = "BOLD") {
        setEditorState(RichUtils.toggleInlineStyle(editorState, style));
    }

    function handlePastedText(text, html) {
        if (html) {
            const blocksFromHTML = convertFromHTML(html);
            const new_state = ContentState.createFromBlockArray(
                blocksFromHTML.contentBlocks,
                blocksFromHTML.entityMap,
            );
            const new_content_state = Modifier.replaceWithFragment(editorState.getCurrentContent(), editorState.getSelection(), new_state.getBlockMap())
            setEditorState(EditorState.moveSelectionToEnd(EditorState.push(editorState, new_content_state, 'insert-fragment')))
            return true;
        } else if (text) {
            const new_state = ContentState.createFromText(text);
            const new_content_state = Modifier.replaceWithFragment(editorState.getCurrentContent(), editorState.getSelection(), new_state.getBlockMap())
            setEditorState(EditorState.moveSelectionToEnd(EditorState.push(editorState, new_content_state, 'insert-fragment')))
            return true;
        } else {
            return false;
        }
    }

    function _toggleBlockType(type) {
        // blockquote, unordered-list-item, ordered-list-item, header-one, header-two, header-three
        setEditorState(RichUtils.toggleBlockType(editorState, type));
    }

    function handleDragEnd({source, destination}) {
        if (!destination) return;
        if(source.index===destination.index) {
            return setEditorState(EditorState.forceSelection(editorState, editorState.getCurrentContent().getSelectionAfter()));
        }
        //   let nc = at_array_move([...choices], source.index, destination.index);
        const current_content_state = editorState.getCurrentContent();
        const omap = current_content_state.getBlockMap();

        // need to get block id
        const source_block = omap.toIndexedSeq().get(source.index);
        const dest_block = omap.toIndexedSeq().get(destination.index);
        const target_range = SelectionState.createEmpty(dest_block.getKey());

        const direction = destination.index > source.index ? 'after' : 'before';
        console.log("source", source)
        console.log("destination", destination)
        console.log("direction", direction)
        let newEditorState = AtomicBlockUtils.moveAtomicBlock(editorState, source_block, target_range, direction);

        setEditorState(EditorState.forceSelection(newEditorState, newEditorState.getCurrentContent().getSelectionAfter()));
    }

    const toolbar_actions = {
        onAddCustomBlock: () => {
            const {block_key, editor_state} = handleAddBlock(editorState,
                "members-list",
                {
                    members: [
                        {
                            name: "Sean McCall",
                            id: "412f3fw",
                            image: `${DEFAULT_USER_PHOTO}_small?alt=media`,
                            email: 'seanmccall14@gmail.com',
                            link: `${window.location.host}/${community.data.handle}/member/handle`
                        },
                        {
                            name: "Amelie McCall",
                            id: "4234dfsd",
                            image: `${DEFAULT_USER_PHOTO}_small?alt=media`,
                            email: 'amelie@he.com',
                            link: `${window.location.host}/${community.data.handle}/member/handle`
                        }
                    ]
                },
                "",
                'before'
            );
            setEditorState(editor_state);
        },
        _onOrderedListClick: () => _toggleBlockType('ordered-list-item'),
        _onUnorderedListClick: () => _toggleBlockType('unordered-list-item'),
        _onBlockquoteClick: () => _toggleBlockType('blockquote'),
        _onTextClick: () => _toggleBlockType('unstyled'),
        _onHeader1Click: () => _toggleBlockType('header-one'),
        _onHeader2Click: () => _toggleBlockType('header-two'),
        _onHeader3Click: () => _toggleBlockType('header-three'),
        onAddDivider: () => {
            let contentState = editorState.getCurrentContent();
            let contentStateWithEntity = contentState.createEntity("divider", 'IMMUTABLE', {});
            let entityKey = contentStateWithEntity.getLastCreatedEntityKey();
            let newEditorState = AtomicBlockUtils.insertAtomicBlock(editorState, entityKey, ' ');
            setEditorState(EditorState.forceSelection(newEditorState, newEditorState.getCurrentContent().getSelectionAfter()));
        },
        _onBoldClick: () => _changeInlineStyle("BOLD"),
        _onItalicClick: () => _changeInlineStyle("ITALIC"),
        _onUnderlineClick: () => _changeInlineStyle("UNDERLINE"),
        onAddSignature: () => addSignature(),
        onAddLink: () => {
            const _ss = editorState.getSelection();
            const blocks = getSelectedBlocksList(editorState);
            const selection_text = getSelectionText(editorState);

            if (blocks.size === 1 && selection_text.length > 0) {
                setMeta({
                    selection_text, selection_start: _ss.getStartKey()
                });
                setOpen('add-link');
            }
        },
        onRemoveLink: () => {
            const ccs = editorState.getCurrentContent();

            const ncs = Modifier.applyEntity(ccs, editorState.getSelection(), null);

            setEditorState(EditorState.push(editorState, ncs, 'apply-entity'));
        },
        onAddAttachment: () => {
            openFileSelector();
        },
        onAddImage: () => {
            openImageSelector();
        }
    };
    /*
       <DeleteModal
            deleting={false}
            can_delete={true}
            usePortal={false}
            content={<>
                <Field label="Where do you want to upload these files?">

                </Field>
            </>}
            title={`Choose Folder`}
            intent='secondary'
            text='Select'
            onDelete={() => {}}
            onClose={() => {

            }}/>
     */

    const ae = Object.entries(attachments);

    const debug_mode = debug;

    const toolbar_item = !read_only ?
        <ScStaticToolbar insertTag={(t) => insertTag(t)} merge_tags={merge_tags} {...toolbar_actions} /> : null;

    const attachments_still_uploading = Object.entries(attachments).filter(a => a[1].status !== 'done').length;

    const current_content = editorState.getCurrentContent();

    const inline_style = editorState.getCurrentInlineStyle();

    const is_bold = inline_style.has("BOLD");
    const is_italic = inline_style.has("ITALIC");
    const is_underline = inline_style.has("UNDERLINE");
    const selection_entity = getSelectionEntity(editorState);
    const selected_entity_data = selection_entity ? current_content.getEntity(selection_entity) : null;
    const is_link = selected_entity_data ? selected_entity_data.getType() === 'LINK' : false;

    const current_block = current_content.getBlockForKey(editorState.getSelection().getStartKey());

    // {{mention or mention is TYPE
    return <div className="relative">
        <div className="z-10 bg-white flex items-center h-7 py-3">
            <div className="flex-none pr-2 text-xs text-gray-500">Insert:</div>
            <div>{toolbar_item}</div>
        </div>
        <ScEditorContent className={`${mode === 'compose' ? "-mt-5 px-3.5 pb-6" : ""} prose prose-base text-gray-800`}>
            <DragDropContext onDragEnd={handleDragEnd}>
                <Droppable droppableId={`dnd-draft-edit`}>
                    {(provided, snapshot) => (
                        <div {...provided.droppableProps} ref={provided.innerRef}>
                            <Editor
                                customStyleMap={sc_editor_style_map}
                                readOnly={read_only}
                                plugins={plugins}
                                ref={editor}
                                onBlur={() => {
                                    last_key.current = null;
                                }}
                                handleReturn={es_handleReturn(setEditorState, open)}
                                blockRenderMap={extendedBlockRenderMap}
                               // handlePastedText={handlePastedText}
                                handleKeyCommand={scEditorHandleKeyCommand}
                                keyBindingFn={(open) ? undefined : scEditorKeyBindingFn.bind(this, editorState, (sel_text, selection_start) => {
                                    const m2 = {
                                        selection_text: sel_text,
                                        selection_start
                                    };
                                    setMeta(m2);
                                }, last_key.current, (kc) => {
                                    last_key.current = kc;
                                })}
                                placeholder={placeholder}
                                blockRendererFn={scEditorBlockRenderer(setEditorState, read_only, current_content)}
                                editorState={editorState}
                                onChange={(nes) => {
                                    setEditorState(nes);
                                }}
                            />
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
            <ToolbarStyles>
                <InlineToolbar>
                    {
                        (externalProps) => {
                            return (
                                <div className="flex " style={{height: '1.65rem'}}>
                                    <FontSizeButton _actions={toolbar_actions}
                                                    active={current_block.getType()} {...externalProps} />
                                    <Separator/>
                                    <div className="flex space-x-px">
                                        <CustomButton {...externalProps} tooltip={{title: "Bold", subtitle: "Cmd + B"}}
                                                      fill="currentColor" active={is_bold}
                                                      icon={<B/>} theme={button_theme}
                                                      onClick={() => toolbar_actions._onBoldClick()}/>
                                        <CustomButton {...externalProps}
                                                      tooltip={{title: "Italics", subtitle: "Cmd + I"}}
                                                      fill="currentColor" active={is_italic}
                                                      icon={<I/>} theme={button_theme}
                                                      onClick={() => toolbar_actions._onItalicClick()}/>
                                        <CustomButton {...externalProps}
                                                      tooltip={{title: "Underline", subtitle: "Cmd + U"}}
                                                      fill="currentColor" active={is_underline}
                                                      icon={<U/>} theme={button_theme}
                                                      onClick={() => toolbar_actions._onUnderlineClick()}/>
                                        <CustomButton {...externalProps}
                                                      tooltip={{title: "Add Link", subtitle: "Cmd + K"}} icon_size="sm"
                                                      active={is_link}
                                                      icon={<LinkIcon/>} theme={button_theme} onClick={(e) => {
                                            if (is_link) {
                                                toolbar_actions.onRemoveLink();
                                            } else {
                                                toolbar_actions.onAddLink();
                                            }
                                        }}/>
                                    </div>
                                    <Separator/>
                                    <div className="flex space-x-1 ">
                                        <CustomButton {...externalProps} tooltip={{title: "Numbered List"}}
                                                      fill="currentColor" active={is_underline}
                                                      icon={<OL/>} theme={button_theme}
                                                      onClick={() => toolbar_actions._onOrderedListClick()}/>
                                        <CustomButton {...externalProps} tooltip={{title: "Bullet List"}}
                                                      fill="currentColor" active={is_underline}
                                                      icon={<UL/>} theme={button_theme}
                                                      onClick={() => toolbar_actions._onUnorderedListClick()}/>
                                        <CustomButton {...externalProps} tooltip={{title: "Blockquote"}}
                                                      fill="currentColor" active={is_underline}
                                                      icon={<BQ/>} theme={button_theme}
                                                      onClick={() => toolbar_actions._onBlockquoteClick()}/>
                                    </div>
                                </div>
                            )
                        }
                    }
                </InlineToolbar>
            </ToolbarStyles>

            <MentionSuggestions
                open={open === 'mention'}
                onOpenChange={onOpenChange}
                suggestions={suggestions}
                entryComponent={MentionEntry}
                onSearchChange={onSearchChange}
                renderEmptyPopup={true}
                onAddMention={(new_mention) => {
                    // get the mention object selected
                    console.log("ADD MENTION", new_mention)
                    // update editorstate
                    // addBlankSpace();
                }}
            />
            <MergeTagSuggestions
                open={open === 'merge-tag'}
                onOpenChange={onOpenMergeTagChange}
                suggestions={merge_tag_suggestions}
                entryComponent={MentionEntry}
                onSearchChange={onSearchMergeTagChange}
                renderEmptyPopup={true}
                onAddMention={(new_mention) => {
                    // get the mention object selected
                    console.log("ADD MENTION", new_mention)
                    // addBlankSpace();
                }}
            />
            <EmojiSuggestions onOpen={onOpenChangeEmoji} onClose={onCloseChangeEmoji} open={true}/>

        </ScEditorContent>


        {ae.length > 0 && <div>
            <div className="text-sm font-medium text-gray-700 mt-4">
                {ae.length} Attachment{ae.length > 1 ? "s" : ""}{attachments_still_uploading > 0 ? ` (uploading)` : ""}
            </div>
            <div className="flex space-x-2 overflow-x-auto pr-4 py-3 ">
                {ae.map(it => {
                    const file_data = it[1];
                    return <div key={it[0]}>
                        <FileItem can_download={false} compact onDelete={() => {
                            let na = {...attachments};
                            delete na[it[0]];
                            setAttachments(na);
                        }} type={file_data.type} url={file_data.url} name={file_data.name} size={file_data.size}/>
                    </div>
                })}
            </div>
        </div>}

        {open === 'add-link' && meta && <AddLinkModal onClose={() => {
            setOpen(null);
            setMeta(null);
        }} meta={meta} handleMetaChange={handleMetaChange} onAdd={() => {
            scEditorHandleAddLink(meta, editorState, (twe) => {
                const current_selection = editorState.getSelection();

                const newContent = twe.merge({
                    selectionBefore: current_selection,
                    selectionAfter: twe.getSelectionAfter().set('hasFocus', true)
                });
                setEditorState(EditorState.push(editorState, newContent, 'insert-characters'));

                setOpen(null);
                setMeta(null);
            })
        }}/>}

        {modal === 'select-attachments-folder' && <DeleteModal
            can_delete={true}
            usePortal={false}
            content={<>
                <Field label="Where do you want to upload attachments?">
                    <FolderPicker type="group-drive-upload" value={attachments_folder} current_folder={folder_id}
                                  handleSelect={(folder) => {
                                      setAttachmentsFolder(folder);
                                  }}/>
                </Field>
            </>}
            title={`Choose Folder`}
            intent='secondary'
            text='Select & Upload'
            onDelete={() => {
                handleUploadAttachments();
            }}
            onClose={() => {
                setFiles([]);
                setModal(null);
            }}/>}

        {!read_only &&
        <input id='new-attachment' multiple type="file" name='attachments' onChange={fileSelected.bind(this)} ref={ref2}
               accept={POST_ACCEPTED_ATTACHMENT_TYPES} style={{display: 'none'}}/>}

        {!read_only &&
        <input multiple={false} id='new-image' type="file" name='image' onChange={fileSelected.bind(this)} ref={ref}
               accept={POST_ACCEPTED_IMAGE_TYPES} style={{display: 'none'}}/>}

        {debug_mode && <pre className="text-xs" style={{maxHeight: '400px', overflowY: 'auto'}}>
                {JSON.stringify(convertToRaw(editorState.getCurrentContent()), undefined, 2)}
            </pre>}
    </div>
}