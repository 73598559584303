import React, {forwardRef, useContext, useEffect, useRef, useState} from 'react';
import {at_formatRawValues} from "./display-formats";
import {
    ArrowCircleRightIcon,
    ArrowNarrowDownIcon,
    ArrowNarrowUpIcon,
    CheckIcon,
    ChevronDownIcon,
    XIcon
} from "@heroicons/react/solid";
import {PopupMenu} from "../../components/old/popup-menu";
import {ActionWithIcon, Button, SectionHeader} from "../../components/core";
import {Popover2} from "@blueprintjs/popover2";
import {
    GlobeAltIcon,
    LocationMarkerIcon,
    MenuAlt2Icon,
    PhoneIcon,
    PlusIcon,
    SelectorIcon
} from "@heroicons/react/outline";
import {authFetch} from "../../../config/network";
import {CommunityContext} from "../../../app/community/community-context";
import {buildAddress} from "../../utilities/shared";
import {AT_property_types} from "../../components/advanced-table/property-types";
import {getPropertiesArray} from "../../components/advanced-table/properties/properties";
import {ATPopup} from "../../components/advanced-table/filters/pretty-filters";


function AddFieldHeader({inactive_attributes}) {
    const [open, setOpen] = useState(false);

    return <ATPopup trigger={<div className="text-xs hover:text-gray-700 flex space-x-1 items-center text-gray-400">
        <ActionWithIcon stopPropogation={false} inverse text="Add Attribute" icon={<PlusIcon/>}/>
    </div>} content={<div>
        <PopupMenu small items={inactive_attributes}/>
    </div>} open={open} onOpen={() => setOpen(true)} onClose={() => setOpen(false)} placement='bottom-end'
                    interactionKind='click' minimal popoverClassName='minimal-popover py-3'
                    targetClassName='popover-target-span'/>
}

export function AT_HeaderCell({
                                  column,
                                  column_options,
                                  inactive_attributes,
                                  index,
                                  sortByColumn,
                                  reverseSort,
                                  sort_by
                              }) {
    const {field_type, accessor, sortable, id} = column;

    if (id === 'selection') {
        return <div className="h-4 pt-px pl-0.5">
            {column.render('Header')}
        </div>
    }

    if (field_type === '_add_field') {
        return <AddFieldHeader inactive_attributes={inactive_attributes}/>
    }

    const sort_active = sortable && sortable === sort_by.field;

    const can_interact = !!sortable;
    let c1 = can_interact ? "hover:opacity-70 transition-opacity cursor-pointer " : "";
    const info = AT_property_types[column.field_type];
    if (!info) {
        console.warn("no info for ", column.field_type)
    }
    const content = <div onClick={() => {
        if (sortable && !sort_active) {
            sortByColumn();
        } else if (sort_active) {
            reverseSort();
        }
    }} className={`text-xs font-medium flex space-x-1 ${c1} items-center text-gray-600 ${index === 0 ? "pl-7" : ""}`}>
        {info && <div className="">
            <div className="h-svg-4 text-gray-400">
                {info.icon}
            </div>
        </div>}
        <div className="pl-0.5">
            {column.render('Header')}
        </div>
        {sort_active && <div className="h-svg-3">
            {sort_by.dir === 'asc' ? <ArrowNarrowDownIcon/> : <ArrowNarrowUpIcon/>}
        </div>}
    </div>;
    if (column_options.length === 0) {
        return content;
    }
    return <div className="">
        <Popover2 content={<PopupMenu items={column_options}/>} placement={'bottom-start'} minimal
                  popoverClassName='minimal-popover my-1.5 -ml-2'>
            {content}
        </Popover2>
    </div>
}

const s_actions_spec = {
    'format-phone-number': {
        icon: <PhoneIcon/>,
        title: 'Format Phone Number'
    },
    'autocomplete-address': {
        icon: <LocationMarkerIcon/>,
        title: 'Autocomplete Address'
    },
    'phone-number-country': {
        icon: <GlobeAltIcon/>,
        title: 'Set Country'
    },
    'first-name': {
        icon: <MenuAlt2Icon/>,
        title: 'Set First Name'
    },
    'last-name': {
        icon: <MenuAlt2Icon/>,
        title: 'Set Last Name'
    }
};

export function AutocompleteAddressPreview({member_id, address, id, community_uid}) {
    const [data, setData] = useState(null);
    const community = useContext(CommunityContext);
    useEffect(function () {

        acAddress();
    }, []);

    function acAddress() {
        const payload = {
            member_id: community.member_id,
            community_uid: community_uid,
            id,
            address: address,
        };

        const res = (resp) => {
            if (resp && resp.data) {
                setData(resp.data);
            }
        };

        authFetch("/autocomplete-address", res, res, "POST", {payload});
    }

    return <div>
        {!data && ".."}
        {data && data.address && buildAddress(data.address)}
    </div>
}

function getSActionDescription(type, description, data) {
    switch (type) {
        case 'first-name':
            return <div>
                {description}
            </div>
        case 'last-name':
            return <div>
                {description}
            </div>
        case 'autocomplete-address':
            return <AutocompleteAddressPreview community_uid={data.community_uid} id={data.id} address={data.address}/>
        case 'phone-number-country':
            return <div>
                {description}
            </div>
        case 'format-phone-number':
            return <div>
                {description}
            </div>
        default:
            return null;
    }
}

function SAction({type = 'format-phone-number', dismiss, accept, description, _data}) {
    const meta = s_actions_spec[type];
    return <div className="flex items-center space-x-1.5 px-1.5">
        <div className=" flex-none">
            <div className="h-svg-4 text-gray-600">
                {meta.icon}
            </div>
        </div>
        <div className="flex-grow">
            <div className="text-sm font-semibold text-gray-800">{meta.title}</div>
            <div className="text-xs line-clamp-2 text-gray-500">
                {getSActionDescription(type, description, _data)}
            </div>
        </div>
        <div className="space-x-1.5 flex-none">
            <Button onClick={dismiss} size="xs" icon={<XIcon/>}/>
            <Button onClick={accept} size="xs" intent="success" icon={<CheckIcon/>}/>
        </div>
    </div>
}

function SuggestedActions({children, row, suggestions, _data, handleAcceptAll, handleDismiss, handleAccept}) {

    const content = <div className="shadow-md w-96 px-0.5 rounded-xl border border-gray-100 bg-white max-w-sm">
        <SectionHeader title="Data Suggestions" action={<ActionWithIcon text="Apply all" inverse
                                                                        onClick={() => handleAcceptAll(_data, row.index, suggestions)}/>}/>
        <div className="pt-0.5 pb-2 space-y-2 px-1">
            {suggestions.map((s, k) => {
                return <SAction key={`${s.type}`} dismiss={() => handleDismiss(_data, row.index, s, k)}
                                accept={() => handleAccept(_data, row.index, s, k)} _data={_data} {...s} />
            })}
        </div>
    </div>;
    return <div className="absolute right-0 pr-2.5 pl-1.5 flex items-center top-0 bottom-0">
        <Popover2 popoverClassName="" key={'import'} placement="bottom-start" content={content}>
            {children}
        </Popover2>
    </div>
}

function Number({number}) {
    return <div className="w-5 h-5 bg-white rounded-full p-0.5">
        <div
            className="h-4 w-4 hover:opacity-80 transition-opacity rounded-full bg-selection text-white text-xs flex items-center justify-center font-medium">
            {number}
        </div>
    </div>
}

function Expander({
                      handleClick = () => {
                      }
                  }) {

    return <div onClick={(e) => handleClick(e)}
                className="bg-gray-200 cursor-pointer inline-flex justify-center items-center h-5 w-5 rounded-md text-gray-600 hover:bg-gray-300">
        <div className="h-svg-5" style={{transform: 'rotate(45deg)'}}>
            <SelectorIcon/>
        </div>
    </div>
}

function RowSelector({
                         index, selected, onSelect = () => {
    }
                     }) {

    return <div className="w-6 inline-flex justify-center items-center">
        <span className="text-xs text-gray-500">{(index + 1)}</span>
    </div>;
}

export function AT_BodyCell({
                                cell,
                                is_selected,
                                row_index,
                                first,
                                last,
                                index,
                                actions,
                                suggestions,
                                suggestions_handler,
                                meta,
                                context
                            }) {
    const {column, value, id, row} = cell;

    const {field_type} = column;

    if (column.id === 'selection') {
        // selection
        return <div className="relative flex items-center h-9 justify-center">
            {!is_selected && <div className="flex group-hover:hidden"><RowSelector index={row_index}/></div>}
            <div className={is_selected ? "" : "hidden group-hover:flex"}>{cell.render('Cell')}</div>
        </div>
    }

    if (field_type === '_add_field') {
        return <div className={"h-9 bg-gray-50 py-0.5 px-0.5 flex relative items-center"}>

        </div>
    }

    let content = at_formatRawValues(field_type, value, {column, row}, context, meta);

    const s = suggestions ? row.original[suggestions] : [];

    return <div onClick={(e) => {
        e.stopPropagation();
        actions.goToItem();
    }} className="h-9 py-0.5 px-0.5 flex relative items-center">
        {content}
        {field_type === 'name' && <div className="hidden absolute right-2 top-0 bottom-0 items-center group-hover:flex">
            <Expander handleClick={(e) => {
                e.stopPropagation();
                actions.editItem();
            }}/>
        </div>}
        {field_type === 'name' && s.length > 0 && <div onClick={e => e.stopPropagation()} className="ml-2">
            <SuggestedActions row={row} handleAcceptAll={suggestions_handler.handleAcceptAll}
                              handleAccept={suggestions_handler.handleAccept}
                              handleDismiss={suggestions_handler.handleDismiss} suggestions={s} _data={row.original}>
                <Number number={s.length}/>
            </SuggestedActions>
        </div>}
    </div>
}