import React from 'react';
import styled from "styled-components";
import {ArrowLeftIcon,ArrowRightIcon} from '@heroicons/react/solid';
import {PDFPreview} from "./pdf-preview";
import {TXTPreview} from "./txt-preview";
import {Button} from "../../core";


const Content = styled.div`
position: relative;
height: 100%;
border-bottom-left-radius: 6px;
border-bottom-right-radius: 6px;
`;


const Inner = styled.div`
position: relative;
    height: ${props=>props.is_mobile?"100%":`${window.innerHeight-108}px`};
    overflow: hidden;
    :hover {
        .show-arrow {
            opacity: 1 !important;
        }
    }
`;

const ImageBox = styled.div`
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    > img {
    max-height: 95%;
    max-width: 95%;
    }
`;

const ArrowButton = styled.div`
position: absolute;
    height: 44px;
    width: 44px;
    transition: 0.1s opacity ease;
    opacity: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    top: 48%;
    left: ${props=>props.dir==='left'?'20px':''};
    right: ${props=>props.dir==='left'?'':'20px'};
background-color: #ffffff;
border-radius: 50%;
box-shadow: 0 0 0 1px rgba(0,0,0,0.05),0 1px 3px 0 rgba(0,0,0,0.1),0 1px 2px 0 rgba(0,0,0,0.06) !important;
    > svg {
        height: 24px;
    }
`;

function ImagePreview({type,url,size}) {
    return <ImageBox>
        <img src={url} />
    </ImageBox>
}

const NonIdealState = styled.div`
max-width: 200px;
text-align: center;
    margin: 0 auto;
    margin-top: 10vh;
`;

const NISTitle = styled.div`
    font-size: 15px;
    line-height: 1.4;
    margin-bottom: 16px;
`;

function PreviewNotPossible({type,url,size}) {
    return <NonIdealState>
        <NISTitle className='text-gray-900'>
            We can't show a preview for these kinds of files yet.
        </NISTitle>
        <div>
            <Button onClick={()=>{
                window.open(url, '_blank');
            }} text='Download File' intent='secondary' />
        </div>
    </NonIdealState>
}

function ShowPreview(props) {
    switch (props.type) {
        case 'image/bmp':
            return <ImagePreview {...props} />;
        case 'image/png':
            return <ImagePreview {...props} />;
        case 'image/svg+xml':
            return <ImagePreview {...props} />;
        case 'image/jpeg':
            return <ImagePreview {...props} />;
        case 'image/jpg':
            return <ImagePreview {...props} />;
        case 'image/gif':
            return <ImagePreview {...props} />;
        case 'image/tiff':
            return <ImagePreview {...props} />;
        case 'text/csv' :
            return<PreviewNotPossible {...props} />;
        case 'application/msword' :
            return<PreviewNotPossible {...props} />;
        case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
            return<PreviewNotPossible {...props} />;
        case 'text/html' :
            return<PreviewNotPossible {...props} />;
        case ('text/javascript'||'application/json'||'application/x-httpd-php') :
            return<PreviewNotPossible {...props} />;
        case ('audio/mpeg') :
            return<PreviewNotPossible {...props} />;
        case ('video/mpeg') :
            return<PreviewNotPossible {...props} />;
        case ('application/vnd.ms-powerpoint'||'application/vnd.openxmlformats-officedocument.presentationml.presentation') :
            return<PreviewNotPossible {...props} />;
        case ('application/vnd.rar'||'application/zip') :
            return<PreviewNotPossible {...props} />;
        case 'text/plain' :
            return <TXTPreview url={props.url} />;
        case ('application/vnd.ms-excel'||'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') :
            return <PreviewNotPossible {...props} />;
        case 'application/pdf' :
            return <PDFPreview {...props} />;
        default:
            return <PreviewNotPossible {...props} />;
    }
}

export function FPPreviewer({url,is_mobile,nav,meta={type:'',ext:'',size:0}}) {
    return <Content className='color-bkg-1'>
        <Inner is_mobile={is_mobile} className={`${is_mobile?"":"rounded-b-md"}`}>
            <ShowPreview url={url} type={meta.type} size={meta.size} />
            {nav.prev&&!is_mobile&&<ArrowButton onClick={()=>nav.onPrev()} className='show-arrow text-gray-800 hover-bkg-1' dir='left'>
            <ArrowLeftIcon/>
        </ArrowButton>}
            {nav.next&&!is_mobile&&<ArrowButton onClick={()=>nav.onNext()} className='show-arrow text-gray-800 hover-bkg-1' dir='right'>
            <ArrowRightIcon/>
        </ArrowButton>}
        </Inner>
    </Content>;
}