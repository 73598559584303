import React from "react";
import {EmojiPopupSelector} from "../emoji-popup-selector";
import styled from "styled-components";

const Frame = styled.div`
  
   height: 29px;
   .bp4-popover2-target > div {
   padding: 1px 8px;
    border-radius: 4px !important;
    border-right: none !important;
   }
`;

export const SymbolEditor = ({value,onChange}) => {

  return <Frame>
      <EmojiPopupSelector emoji={value} changeEmoji={onChange} />
  </Frame>;
};