import React, {useState, useEffect} from "react";
import styled from "styled-components";

const Input = styled.input`
  box-shadow: 0 1px 2px 0 #0000000d;
  border-color: #d4d4d8;
  border-width: 1px;
  border-style: solid;
  
    border-radius: 0.375rem;
    
    transition: border 80ms ease-out,box-shadow 80ms ease-out;
    box-sizing: border-box;
        color: #112D42;
  padding: 0.6rem 0.875rem;
    height: 40px;
    font-size: 16px;
    line-height: 1.33333333;
    width: 100%;
  
 
    :focus {
        outline: none !important;
    }
  :hover {
    border-color: #b3b3b3;
  }
    :focus-within {
    box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(58, 151, 212, 0.36) 0px 0px 0px 4px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(60, 66, 87, 0.16) 0px 0px 0px 1px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px;
        border-color: #659adf;
    }
`;

const Container = styled.div`
    position: relative;
    > span {
    font-size: 16px;
    }
`;

export default function InputField(props) {
    const [inputRef, setRef] = useState(React.createRef());
    const [focused, setFocus] = useState(false);
    const {type = 'text', autoComplete = '', autoSelect, disabled, placeholder, min, max, onBlur = ()=>{}, onChange, autoFocus = false} = props;
    useEffect(function () {
        if(props.value) {
            if (props.value.length > 2 && type==='text' && props.value.indexOf('.') > 0 && !disabled && focused) {
                if(type==='text') {
                    inputRef.current.setSelectionRange(0, props.value.lastIndexOf('.'));
                }
            }
        }
    }, [focused]);

    return (
        <Container className={`inputtype-${type}`}>
            {type === 'handle' && <span className='text-gray-700'>@</span>}
            <Input onFocus={() => setFocus(true)} ref={inputRef} type={type==='handle'?'text':type} disabled={disabled} min={min}
                   placeholder={placeholder} max={max} autoFocus={autoFocus} onBlur={() => {
                setFocus(false);
                onBlur()
            }} autoComplete={autoComplete} value={props.value} onChange={(e) => {
                onChange(e.target.value)
            }}/>
        </Container>
    );
}