import React, {useContext, useEffect, useState} from "react";
import {RulesInlineSelect} from "../rules-inline-select";
import {Input} from "../../core";
import {RulesFrame, RulesInput} from "../../rules-components";
import {condition_types_ref} from "../../../data/community/segments";
import {ExclamationIcon, QuestionMarkCircleIcon, ShieldExclamationIcon} from "@heroicons/react/solid";

function Operator({
                      type, operator, segment_id_data, setOperator = () => {
    }, options
                  }) {
    switch (type) {
        case 'string':
            return <div>
                <RulesInlineSelect selected={operator} options={options}
                                   onSelect={(no) => setOperator(no)}/>
            </div>;
        case 'select':
            return <div>
                <RulesInlineSelect selected={operator} options={options}
                                   onSelect={(no) => setOperator(no)}/>
            </div>;
        case 'multi-select':
            return <div>
                <RulesInlineSelect selected={operator} options={options}
                                   onSelect={(no) => setOperator(no)}/>
            </div>;
        case 'date':
            return <div>
                <RulesInlineSelect selected={operator} options={options}
                                   onSelect={(no) => setOperator(no)}/>
            </div>;
        case 'number':
            return <div>
                <RulesInlineSelect selected={operator} options={options}
                                   onSelect={(no) => setOperator(no)}/>
            </div>;
        case 'boolean':
            return null;
        default:
            return <div>
                todo
            </div>;
    }
}

function Value({
                   type, value, operator = '', setValue = () => {
    }, segment_id_data
               }) {
    let pass_value, hold;
    switch (type) {
        case 'string':
            return <div className="flex items-center">
                <div>
                    <RulesInput value={value} onChange={(v) => setValue(v)} autoFocus/>
                </div>
            </div>;
        case 'date':
            return <div className="flex items-center">
                <div className="-my-1">
                    <Input type='date' value={value} onChange={(v) => setValue(v)} autoFocus compact/>
                </div>
            </div>;
        case 'number':
            return <div className="flex items-center">
                <div className="-my-1">
                    <Input type="number" value={value.toString()} onChange={(v) => {
                        if (!isNaN(v)) {
                            setValue(parseInt(v));
                        } else {
                            setValue('');
                        }
                    }} autoFocus compact/>
                </div>
            </div>;
        case 'boolean':
            return <div>
                <RulesInlineSelect selected={value} options={segment_id_data.options}
                                   onSelect={(no) => setValue(no)}/>
            </div>;
        case 'multi-select':
            hold = operator === 'is_one_of' || operator === 'is_not_one_of';
            if (hold) {
                const new_value = !value ? [] : typeof value === 'string' ? [value] : value;
                return <RulesInlineSelect
                    searchable={segment_id_data.options ? segment_id_data.options.length > 5 : false} multi={true}
                    selected={new_value} options={segment_id_data.options}
                    onSelect={(no) => {
                        if (typeof no === 'string') {
                            if (no) {
                                setValue([no])
                            } else {
                                setValue([])
                            }
                        } else {
                            setValue(no)
                        }
                    }}/>
            }

            return <RulesInlineSelect searchable={segment_id_data.options ? segment_id_data.options.length > 5 : false}
                                      multi={hold} selected={typeof value !== 'string' ? value[0] : value}
                                      options={segment_id_data.options}
                                      onSelect={(no) => {
                                          if (typeof no === 'string') {
                                              setValue(no)
                                          } else {
                                              setValue(no[0])
                                          }
                                      }}/>
        case 'select':
            return <RulesInlineSelect selected={value} options={segment_id_data.options}
                                      onSelect={(no) => setValue(no)}/>
        default:
            return <div>
                todo
            </div>;
    }
}


export function RulesItem({
                              field = 'address.zip_code',
                              operator = "",
                              value = "",
                              modifier = "",
                              sc = {},
                              onDelete = () => {
                              },
                              updateParent = () => {
                              },
                          }) {
    const [op, setOperator] = useState(operator);
    const [val, setValue] = useState(value);

    useEffect(function () {
        updateParent({value: val, operator: op});
    }, [val, op]);

    const segment_id_data = sc[field];

    if (!sc[field]) {
        return <RulesFrame onDelete={onDelete} icon={<ExclamationIcon/>}>
            <div className="text-base font-medium">
                Custom field filter was deleted
            </div>
        </RulesFrame>
    }

    const {type, suffix, prefix} = segment_id_data;

    if (!condition_types_ref[type]) {
        console.warn('CANNOT FIND', type);
        return null;
    }

    const condition_type_data = condition_types_ref[type];

    return <RulesFrame onDelete={onDelete} icon={segment_id_data.icon}>
        <div className="text-base font-medium">
            {prefix}
        </div>
        <Operator segment_id_data={segment_id_data} operator={op} type={type} options={condition_type_data.options}
                  setOperator={(op) => setOperator(op)}/>
        <Value segment_id_data={segment_id_data} operator={op} value={val} type={type} setValue={(nv) => setValue(nv)}/>
        {suffix && <div className="pl-1 text-base font-medium">
            {suffix}
        </div>}
    </RulesFrame>;
}