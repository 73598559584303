import React from 'react';
import classNames from 'classnames';
import styled from "styled-components";
import {XIcon} from "@heroicons/react/solid";

function getHeight(size) {
    if (size === 'xs') {
        return '14';
    } else if (size === 'sm') {
        return '15';
    } else if (size === 'md') {
        return '16';
    } else if (size === 'lg') {
        return '17';
    }
    return '18'
}

const IconBox = styled.div`

> svg {
    height: ${props=>getHeight(props.size)}px;
}
`;

function getPadding(size, select) {
    if(select) {
        return 'select-badge-padding';
    }
    if (size === 'xs') {
return 'py-0.5 px-1.5';
    } else if (size === 'sm') {
        return 'py-0.5 px-2.5';
    } else if (size === 'md') {
return 'py-1 px-3'
    } else if (size === 'lg') {
return 'py-1.5 px-4';
    }
    return 'py-1 px-3'
}

function getRemoveMargin(size, select) {
    if (size === 'xs') {
        return 'ml-0.5 -mr-0.5';
    } else if (size === 'sm') {
        return 'ml-1 -mr-1';
    } else if (size === 'md') {
        return 'ml-1 -mr-1'
    } else if (size === 'lg') {
        return 'ml-1 -mr-1';
    }
    return 'ml-1 -mr-1'
}

function getBkg(color) {
    switch (color) {
        case 'purple' :
            return 'bg-purple-100';
        case 'blue' :
            return 'bg-blue-100';
        case 'emerald' :
            return 'bg-emerald-100';
        case 'orange' :
            return 'bg-orange-100';
        case 'green' :
            return 'bg-green-100';
        case 'red' :
            return 'bg-red-100';
        case 'yellow' :
            return 'bg-yellow-100';
        case 'indigo' :
            return 'bg-indigo-100';
        case 'pink' :
            return 'bg-pink-100';
        case 'amber' :
            return 'bg-amber-100';
        case 'teal' :
            return 'bg-teal-100';
        case 'dark' :
            return 'bg-gray-400';
        case 'lightdark' :
            return 'bg-gray-300';
        case 'rose' :
            return 'bg-rose-100';
        default :
                return 'bg-gray-100'
    }
}

function getTextColor(color) {
    switch (color) {
        case 'purple' :
            return 'text-purple-700';
        case 'blue' :
            return 'text-blue-700';
        case 'orange' :
            return 'text-orange-700';
        case 'green' :
            return 'text-green-700';
        case 'emerald' :
            return 'text-emerald-700';
        case 'red' :
            return 'text-red-700';
        case 'yellow' :
            return 'text-yellow-700';
        case 'indigo' :
            return 'text-indigo-700';
        case 'pink' :
            return 'text-pink-700';
        case 'amber' :
            return 'text-amber-700';
        case 'teal' :
            return 'text-teal-700';
        case 'rose' :
            return 'text-rose-700';
        case 'dark' :
            return 'text-white';
        case 'lightgray' :
            return 'text-gray-500';
        default :
            return 'text-gray-700'
    }
}

export function Badge({text = "Badge", onClick, icon, select, handleRemove=()=>{}, uppercase=false, color = "gray", rounded = false, size = "xs", can_remove = false}) {

    const text_color = getTextColor(color);

    return <div onClick={()=>{
        if(onClick) {
            onClick();
        }
    }}
        className={classNames("inline-flex space-x-1 items-center",`${onClick?"cursor-pointer transition-opacity hover:opacity-70":""}`,`${getPadding(size,select)}`,uppercase?"uppercase font-semibold tracking-wide":"font-medium",`rounded-${rounded ? 'md' : 'full'}`,`text-${size}`,text_color,getBkg(color),can_remove ? 'cursor-pointer' : '')}>
        {icon&&<div className="w-4 h-4 -ml-0.5">{icon}</div>}
        <div className={`${icon?"pr-0.5":""} truncate`}>{text}</div>
        {can_remove && <IconBox onClick={handleRemove} className={`flex items-center ${getRemoveMargin(size,select)} ${text_color}`} size={size} color={color}>
            <XIcon className="opacity-80 transition-opacity hover:opacity-50 cursor-pointer"/>
        </IconBox>}
    </div>
}