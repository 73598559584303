import React from "react";
import {getAdvancedImageBackgroundStyle} from "./reorganize/profile-card/cover";

const data = {
    name: 'Corps Isaria',
    color: '#12683b',
    logo: 'https://firebasestorage.googleapis.com/v0/b/unaty-prod.appspot.com/o/profile_pictures%2F8hvqXXAzF_medium?alt=media'
};

const mock = {
    type: 'community-sign-in-cover',
    url: 'pattern_topography_#000000',
    color: '#ffffff'
};

/*
 <div>
                    <Avatar text={data.name} type="community" url={data.logo} size="2xl"/>
                </div>
                <div className="text-2xl sm:text-4xl text-gray-800 font-black">
                    <span>Welcome back to&nbsp;</span>
                    <span style={{color: `${data.color}`}}>Corps Isaria</span>
                </div>
                <div className="text-base text-gray-700">
                    Sign in to your account below
                </div>

                <div>
                    Don't have an account? <a className="text-link">Register</a>
                </div>
 */

export function CommunitySignInScreen({
    url = "",
    color = "#d7d7d7",
    children
                                      }) {

    const type = 'community-sign-in-cover';

    let style = getAdvancedImageBackgroundStyle(type, url, color);

    return <div className="block bg-white md:grid h-screen" style={{gridTemplateColumns: '440px 1fr'}}>
        <div className="bg-white md:border-r border-gray-200 h-screen overflow-y-auto">
            <div className="px-4 md:px-10 py-4 md:pt-12 md:pb-24 flex md:items-center space-y-6" style={{minHeight:'100vh'}}>
                <div className="w-full mx-auto" style={{maxWidth:'450px'}}>
                    {children}
                </div>
            </div>
        </div>
        <div className={`hidden sm:block`} style={style}>

        </div>
    </div>
}