import {isPossiblePhoneNumber, parsePhoneNumberWithError} from "libphonenumber-js/min";

function cleanNumber(input) {
    let str = input.replace(/-/g, '').replace(/ /g, '');
    const len = str.length;

    if (len > 10 && str.startsWith('00')) {
        return `+${str.substring(2, len)}`;
    }

    if (len > 10 && !str.includes('+') && !str.startsWith('0')) {
        return `+${str}`;
    }

    if (len > 10 && str.startsWith('0')) {
        // likely german
        return `+49${str}`;
    }

    if (len === 10) {
        // likely us
        return `+1${str}`;
    }

    return str;
}

export function getPhoneNumberSuggestion(input) {
    if (!input || input.length < 5) {
        return null;
    }

    let cleaned_str = cleanNumber(input);

    let suggestion;
    try {
        suggestion = parsePhoneNumberWithError(cleaned_str)
    } catch (e) {
        suggestion = null;
    }

    if (!suggestion) {
        return null;
    }

    // todo see if we can use min library
    const number_is_valid = isPossiblePhoneNumber(suggestion.number);

    return number_is_valid ? suggestion.number : null;
}