import React, {useContext, useState} from "react";
import {PasswordField} from "../../../library/components/old/password-field";
import ButtonSection from "../../../library/components/old/button-section";
import {Button} from "../../../library/components/core";
import {useIntl} from "react-intl";
import {GlobalContext} from "../../global/global-context";
import firebase from "firebase/app";
import {externalAuthFetch} from "../../../config/network";


export function checkPasswordStrength(pw) {
    const len = pw.length;
    let strength = 0;
    let checks = {
      special: false,
      caps: false,
      numbers: false,
      small: false
    };

    let key_code_map = {};

    if(len<7) {
        return 0;
    } else if(len>16) {
        strength += 3;
    } else if(len>12) {
        strength += 2;
    } else if(len>6) {
        strength += 1;
    }

    for(let i=0;i<len;i++) {
        const char = pw.charCodeAt(i);
        key_code_map[char] = true;
        if(!checks.caps && char >= 65 && char <= 90) {
            checks.caps = true;
        } else if(!checks.numbers && char >=48 && char <= 57){
            checks.numbers = true;
        } else if(!checks.small && char >=97 && char <= 122){
            checks.small = true;
        }else if(!checks.special && (char >=33 && char <= 47) || (char >=58 && char <= 64)) {
            checks.special = true;
        }
    }

    if(checks.small&&checks.caps) {
        strength += 1;
    } else if(checks.special) {
        strength += 1;
    } else if(checks.numbers) {
        strength += 1;
    }

    const variety = Object.keys(key_code_map).length;

    if(variety<3) {
        return 0;
    } else if(variety<4) {
        return strength>1?1:strength;
    } else if(variety<7) {
        return strength>2?2:strength;
    }

    return {score:strength>5?5:strength}
}

export function SetYourPassword({
    username = "",
    temp_code = "",
                                    color = "",
                                }) {
    const {formatMessage: f} = useIntl();
    const global = useContext(GlobalContext);
    const [loading,setLoading] = useState("");
    const [password,setPassword] = useState("");

    let result = checkPasswordStrength(password);

    const disable = result.score<2;

    function handleSubmit() {
        if(disable) {
            return;
        }
            setLoading('saving');
            firebase.auth().confirmPasswordReset(temp_code, password)
                .then((result)=>{
                    // need to sign in after this
                    externalAuthFetch('/confirm-password-reset', ()=>{}, () => {}, 'POST', {email:username});
                    global.addToast({text: "Your Password was updated", intent: 'success'});
                    global.login(username, password);
                    return true;
                })
                .catch((error)=>{
                    console.log('error', error);
                })
    }

    return <div className="mt-4 space-y-4">
        <PasswordField onEnter={()=>handleSubmit()} autoFocus label="Set your password" onChange={(nv)=>setPassword(nv)} score={result.score} value={password} />
        <ButtonSection className='auth-actions' color={color}>
            <Button intent='secondary' fill size='large' disabled={disable}
                    onClick={handleSubmit.bind(this, disable)} text={f({id:'auth.signin.signin_button'})}
                    loading={global.logging_in||loading==='saving'}/>
        </ButtonSection>
    </div>
}