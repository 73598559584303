import React, {useState} from 'react';
import {GeneralEntitiesList} from "../../../../library/components/general-entities-list";
import {GroupDisplayIcon} from "../../../../library/utilities/groups";
import {getEntityNotificationPreferences} from "../../../../library/utilities/notifications";

function GroupItem({data,member}) {
    if(!data||!data.id) {
        return null;
    }
const prefs = getEntityNotificationPreferences(member, 'group', data.id);
    return <div className="flex space-x-2 items-center px-1 py-1">
        <div className="flex-none">
            <GroupDisplayIcon emoji={data.emoji} profile_picture={data.image}
                              profile_picture_color={data.profile_picture_color} color={data.color}
                              name={data.name}/>
        </div>
        <div className="flex-grow">
            <div className="text-sm line-clamp-1 font-bold text-gray-800">{data.name}</div>
        </div>
        <div className="text-sm text-gray-700">
            {!prefs.muted?<div>
                Subscribed
            </div>:<div>
                Not subscribed
            </div>}
        </div>
    </div>
}

// this could be generalized table
export function AMGroupsList({community_uid,member,group_ids=[]}) {
    const groups_count = group_ids.length;

    if(groups_count.length===0) {
        return null;
    }

    return <div className="space-y-1 pt-1.5 pb-1.5">
        <GeneralEntitiesList
            itemRenderer={<GroupItem member={member} />}
        community_uid={community_uid}
        total={groups_count}
        type={"groups"}
            label={"Groups"}
        per_page={12}
        ids={group_ids}
    />
    </div>
}