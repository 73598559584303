import React, {useContext, useState} from "react";
import {Avatar} from "../../../../../../library/components/core";
import {PhotoSelector} from "../../../../../../library/components/old/photo-selector";
import {GlobalContext} from "../../../../../global/global-context";
import {authFetch} from "../../../../../../config/network";

export function UserProfilePictureSelector({updateParent=()=>{},profile_picture="",text=""}) {
    const global = useContext(GlobalContext);
    const [loading,setLoading] = useState(null);

    function changeProfilePicture(u,c) {
updateParent(u,c)
    }
    return <PhotoSelector type='profile-picture' setPhoto={(url, color) => changeProfilePicture(url,color)}>
        <Avatar show_edit url={profile_picture ? `${profile_picture}_medium?alt=media` : ''}
                text={text}
                size="3xl"/>
    </PhotoSelector>
}