import {downloadFile} from "../download-file";

var vCard = require('vcards-js');

// REFERENCE: https://github.com/enesser/vCards-js
export async function generateVCard(payload) {
    let newCard = vCard();

    const {first='',last='',birthday,phone='',image='',title='',middle='',url='',email='',organization=''} = payload;

//set properties
    newCard.firstName = first;
    newCard.middleName = 'J';
    newCard.lastName = last;
    newCard.organization = organization;
    if(image!=='') {
        newCard.photo.attachFromUrl(image, 'PNG');
    }

    newCard.workPhone = phone;
    if(birthday) {
        newCard.birthday = birthday;
    }
    newCard.title = title;
    newCard.email = email;
    newCard.url = url;
    // newCard.note = 'Notes on Eric';

//save to file
    const blob = new Blob([newCard.getFormattedString()],{type:'vcf'});

    const href = await URL.createObjectURL(blob);

    const name = `${first}${last}-${organization}.vcf`;

    downloadFile(href, name);

    return newCard;
}