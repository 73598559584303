import React, {useState} from 'react';
import Modal from "../../../../library/components/old/modal";
import {Button} from "../../../../library/components/core";
import {EntityIconSelector} from "../../../../library/components/core/entity-icon-selector";

export function GroupRenameModal({loading,onConfirm,onClose,onCancel,
                                     emoji,color,profile_picture,profile_picture_color,value=""
                                 }) {
    const [name,setName] = useState(value);
    const [emo,setEmoji] = useState(emoji);
    const [pp,setProfilePicture] = useState(profile_picture);
    const [ppc,setProfilePictureColor] = useState(profile_picture_color);
    const [co,setColor] = useState(color);

    const disabled = name.length<1;
    const body = <div>
        <EntityIconSelector profile_picture={pp} color={co} onChange={(n,t,e,m)=>{
            if(t==='emoji') {
                setEmoji(e);
                setProfilePictureColor("");
                setProfilePicture("");
                setColor("");
                setName(n);
            } else if(t==='profile_picture') {
                setEmoji("");
                setProfilePicture(e);
                setProfilePictureColor(m);
                setColor("");
                setName(n);
            } else if(t==='name') {
                setName(n);
            } else {
                setEmoji("");
                setProfilePictureColor("");
                setProfilePicture("");
                setColor(e);
                setName(n);
            }
        }} label="Name" emoji={emo} value={name} placeholder="Group Name" />
    </div>;

    return <Modal title={"Rename Group"} size='small' body={body} footerRight={<div className="space-x-2 flex">
        <Button onClick={()=>{
            onClose();
        }} text='Cancel' />
        <Button intent='success' loading={loading} disabled={disabled} onClick={()=>{
            onConfirm(name,emo,pp,ppc,co,()=>onClose());
        }} text='Change' />
    </div>} footerLeft={null}
                  onClose={onClose} open={true} lazy />
}