import React, {useEffect, useRef, useState} from "react";
import {SimpleProgressBar} from "../../simple-progress-bar";

export function TimedProgressBar({
                                     seconds = 10
                                 }) {

    const step = (100 / seconds / 20);
    const [progress, setProgress] = useState(0);

    const value = useRef();

    useEffect(function () {
        value.current = progress;
    }, [progress]);

    useEffect(() => {
        let timer = setInterval(() => {
            const new_progress = value.current+step;
            setProgress(new_progress);
            if(new_progress>100) {
                setProgress(100);
                clearInterval(timer);
            }
        }, 50)
        return () => {
            if (timer) clearInterval(timer);
        }
    }, []);

    return <SimpleProgressBar stripes value={(progress/100)} intent={progress > 90 ? 'success' : 'secondary'}/>;
}