import React, {useContext, useState} from "react";
import {ProfileBadge} from "../../../../../../library/components/profile-badge";
import {NewTooltip} from "../../../../../../library/components/reorganize/new-tooltip";
import {ChangeHandleModal} from "../../../role/change-handle";
import {authFetch} from "../../../../../../config/network";
import {GlobalContext} from "../../../../../global/global-context";
import {ActionWithIcon, Field} from "../../../../../../library/components/core";

export function HandleBadge({member,compact,handleChange=()=>{},layout="",community,member_id}) {
    const global = useContext(GlobalContext);
const [loading,setLoading] = useState(null);
const [open,setOpen] = useState(null);
function changeHandle(h) {
    setLoading('handle');
    const res = (resp) => {
        setTimeout(() => {
            setLoading('');
            setOpen(null);
            handleChange(resp.data.handle);
            global.addToast({text: `Member handle changed`, intent: 'success'});
        }, 1000);
    };

    const payload = {
        new_handle: h,
        community_uid: community.uid,
        member_id: community.member_id,
        id: member_id,
        user_uid: community.member.user_uid,
    };

    authFetch("/members/change-handle", res, res, "POST", {payload})
}

let c;

if(layout==="field") {
    c = <Field label="Handle" corner_hint={<ActionWithIcon onClick={() => setOpen(true)} text="Change" inverse/>}>
        <div className="text-base text-gray-900">
            @{member.handle}
        </div>
    </Field>
} else {
    c = <NewTooltip message="Click to change handle">
        <ProfileBadge compact={compact} onClick={()=>setOpen(true)} type="text" text={`@${member.handle}`} />
    </NewTooltip>
}

    return <>
        {c}

        {open&&<ChangeHandleModal loading={loading === 'handle'} community_uid={community.uid} data={{handle:member.handle}}
                           onConfirm={(h) => changeHandle(h)}
                           onClose={() => setOpen(null)} onCancel={() => {
            setOpen(null);
        }} type='member'/>}
        </>
}